// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICVehicleOwnershipAndAssignmentComponent__registeredOwnerDropdown :where(div:has(>a),button+div[aria-labelledby]){position:relative;top:auto}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-pa-react/components/WMICVehicleOwnershipAndAssignment/WMICVehicleOwnershipAndAssignmentComponent.module.scss"],"names":[],"mappings":"AAGI,0HACI,iBAAA,CACA,QAAA","sourcesContent":["\n.registeredOwnerDropdown {\n    /* drills down into dropdown component to remove the absolute position*/\n    :where(div:has(> a), button + div[aria-labelledby]) {\n        position: relative;\n        top: auto;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registeredOwnerDropdown": "app__WMICVehicleOwnershipAndAssignmentComponent__registeredOwnerDropdown"
};
export default ___CSS_LOADER_EXPORT___;
