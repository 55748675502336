/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable class-methods-use-this */
import appConfig from 'app-config';
import moment from 'moment';
import { APP_NAMES } from './StringConstants';
import WMICApplicationUtil from './WMICApplicationUtil';
import WMICFeatureFlagUtil from './WMICFeatureFlagUtil/WMICFeatureFlagUtil';
import { getProxiedUrl } from 'wmic-portals-url-js';

const APP_AMP_NAME_SHORT = 'Acct-Mgmt-Portal';
const APP_RQB_NAME_SHORT = 'renters-quote-buy';
const {
    exceptionLoggerConfig,
    xhrLoggerConfig,
    logLoggerConfig
} = appConfig;
const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
const operations = {
    log: 'log',
    warn: 'warn',
    info: 'info',
    error: 'error',
    debug: 'debug'
};

const getFormattedDateTimeForLogging = (unixTimeMilliseconds) => {
    return moment(unixTimeMilliseconds).format('YYYY-MM-DD HH:mm:ss.SSS Z'); // 2021-11-24 11:33:42.279 -08:00
};

const buildLogPayload = (
    logLevel,
    category,
    message,
    stackTrace,
    pageName,
    url,
    unixTimeMilliseconds,
    authData,
    cause
) => {
    let userId;
    if (authData) {
        userId = authData.user_id;
    }

    let auxMessage = message;
    if (typeof message === 'object') {
        auxMessage = JSON.stringify(message);
    }

    const payload = {
        level: logLevel,
        logCategory: category,
        message: auxMessage,
        stackTrace: stackTrace ? { value: stackTrace } : undefined,
        cause: cause,
        userId: userId,
        pageName: pageName,
        pageUrl: url,
        ts: getFormattedDateTimeForLogging(unixTimeMilliseconds)
    };

    return payload;
};

const buildXhrLogPayload = (
    message,
    serverResponse,
    errorUrl,
    errorMethod,
    unixTimeMilliseconds
) => {
    let auxMessage = message;
    if (typeof message === 'object') {
        auxMessage = JSON.stringify(message);
    }

    const payload = {
        level: 'error',
        logCategory: 'xhr',
        message: auxMessage,
        serverResponse: serverResponse,
        errorUrl: errorUrl,
        errorMethod: errorMethod,
        ts: getFormattedDateTimeForLogging(unixTimeMilliseconds)
    };

    return payload;
};

const buildHecBody = (payload, source, unixTimeMilliseconds, host) => {
    return {
        event: payload,
        source: source,
        host: host,
        time: unixTimeMilliseconds / 1000
    };
};

const getFeatureAMPAvailability = (featureName, ldFlags) => {
    const response = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureName);
    return response;
};

const shouldThrottle = (throttle, windowInSeconds, maxExceptionsPerWindow) => {
    const currentDate = new Date();
    const currentSeconds = currentDate.getSeconds();
    let loggedExceptionsCount = 0;
    this.throttle.history[currentSeconds] = (currentSeconds in throttle.history)
        ? throttle.history[currentSeconds] + 1
        : 1;
    for (let i = (currentSeconds - windowInSeconds) + 1;
        i <= currentSeconds;
        // eslint-disable-next-line no-plusplus
        i++
    ) {
        if (i in this.throttle.history) {
            loggedExceptionsCount += this.throttle.history[i];
        }
    }

    return loggedExceptionsCount > maxExceptionsPerWindow;
};

class WMICRemoteLogger {
    static getWMICRemoteLogger(loggerName, pageName, location, ldFlags, authData) {
        return new WMICRemoteLogger(loggerName, pageName, location, ldFlags, authData);
    }

    constructor(loggerName, pageName, location, ldFlags, authData) {
        this.name = loggerName;
        let auxPageName = pageName;
        if (pageName.startsWith('/')) {
            auxPageName = pageName.substring(1);
        }
        this.pageName = auxPageName;
        this.location = location;
        this.ldFlags = ldFlags;
        this.authData = authData;
        this.loggerAuthData = logLoggerConfig.authHeader;
        this.throttle = {
            history: {}
        };
        const appName = WMICApplicationUtil.getAppName();
        switch (appName) {
            case APP_NAMES.CE:
                this.appShortName = APP_RQB_NAME_SHORT;
                break;
            case APP_NAMES.AMP:
                this.appShortName = APP_AMP_NAME_SHORT;
                break;
            default:
                this.appShortName = undefined;
        }
    }

    setLocation({pageName, location}) {
        this.pageName = pageName;
        this.location = location;
    }

    remotelyLogXHRException(rejection, cloudLogging) {
        // If the rejection comes from trying to log the error itself,
        // don't retry otherwise we end up in infinity loop trying to log
        if (rejection.config.url === xhrLoggerConfig.remoteLogUrl || rejection.config.url === xhrLoggerConfig.cloudRemoteLogUrl) {
            return;
        }

        const errorMethod = rejection.config.data ? rejection.config.data.method : '';

        const config = {
            method: 'post'
        };

        const currentUnixTimeMilliseconds = Date.now();
        const payload = buildXhrLogPayload(`${rejection.status} ${rejection.statusText}`, rejection.data, rejection.config.url, errorMethod, currentUnixTimeMilliseconds);

        if (cloudLogging) {
            config.headers = xhrLoggerConfig.authHeader;
            config.url = xhrLoggerConfig.cloudRemoteLogUrl;
        } else {
            config.url = xhrLoggerConfig.remoteLogUrl;
        }
        config.data = buildHecBody(
            payload,
            `${this.appShortName}`,
            currentUnixTimeMilliseconds,
            this.location.host
        );

        new Promise(() => {
            const request = new XMLHttpRequest();
            request.open(config.method, config.url, true);
            if (cloudLogging) {
                // TODO - review this header when a more permanent solution is found for Splunk CORS error
                // request.setRequestHeader('Content-Type', 'application/json');
                request.setRequestHeader('Authorization', config.headers);
            }
            request.send(JSON.stringify(config.data));
        }).catch(() => {
            console.log('The http interceptor logging has failed!');
        });
    }

    responseError(rejection) {
        const needToThrottle = shouldThrottle(this.throttle, xhrLoggerConfig.windowInSeconds, xhrLoggerConfig.maxExceptionsPerWindow);
        return new Promise((resolve, reject) => {
            if (xhrLoggerConfig.enabled) {
                if (!needToThrottle) {
                    const availability = getFeatureAMPAvailability(featureFlags.LOGGINGTOSPLUNK, this.ldFlags);
                    if (availability) {
                        this.remotelyLogXHRException(rejection, availability.isAvailable);
                    }
                } else {
                    console.log(`Too many exceptions in the last ${xhrLoggerConfig.windowInSeconds} seconds, skipping remote logging`);
                }
            }
            return reject(rejection);
        });
    }

    remotelyLogLog(message, logType, cloudLogging, pageName, location) {
        const config = {
            method: 'post'
        };

        const currentUnixTimeMilliseconds = Date.now();
        const payload = buildLogPayload(
            logType,
            'log',
            message.message || message,
            message.stack,
            pageName,
            location.pathname,
            currentUnixTimeMilliseconds,
            this.authData
        );

        if (cloudLogging) {
            config.headers = logLoggerConfig.authHeader;
            config.url = logLoggerConfig.cloudRemoteLogUrl;
        } else {
            config.url = logLoggerConfig.remoteLogUrl;
        }
        config.data = buildHecBody(
            payload,
            `${this.appShortName}`,
            currentUnixTimeMilliseconds,
            this.location.host
        );

        return new Promise(() => {
            const request = new XMLHttpRequest();
            request.open(config.method, config.url, true);
            if (cloudLogging) {
                // TODO - review this header when a more permanent solution is found for Splunk CORS error
                // request.setRequestHeader('Content-Type', 'application/json');
                request.setRequestHeader('Authorization', config.headers);
            }
            request.send(JSON.stringify(config.data));
        }).catch(() => {
            console.log('Failed to remotely post log!');
        });
    }

    logOperation(message, operation) {
        if (logLoggerConfig.enabled.global && logLoggerConfig.enabled[operation]) {
            const availability = getFeatureAMPAvailability(featureFlags.LOGGINGTOSPLUNK, this.ldFlags);
            if (availability) {
                this.remotelyLogLog(
                    message,
                    operation,
                    availability.isAvailable,
                    this.pageName,
                    this.location
                );
            }
        }
        if (logLoggerConfig.enabled.console) {
            console.log(message);
        }
    }

    warn(message) { this.logOperation(message, operations.warn); }

    error(message) { this.logOperation(message, operations.error); }

    info(message) { this.logOperation(message, operations.info); }

    log(message) { this.logOperation(message, operations.log); }

    debug(message) { this.logOperation(message, operations.debug); }

    remotelyLogException(exception, cause, cloudLogging) {
        const message = exception.message || exception;
        const config = {
            method: 'post'
        };
        const currentUnixTimeMilliseconds = Date.now();
        const payload = buildLogPayload(
            'error',
            'exception',
            message,
            exception.stack,
            this.pageName,
            this.location.pathname,
            currentUnixTimeMilliseconds,
            this.authData,
            cause
        );

        if (cloudLogging) {
            config.headers = exceptionLoggerConfig.authHeader;
            config.url = exceptionLoggerConfig.cloudRemoteLogUrl;
        } else {
            config.url = exceptionLoggerConfig.remoteLogUrl;
        }
        config.data = buildHecBody(
            payload,
            `${this.appShortName}`,
            currentUnixTimeMilliseconds,
            this.location.host
        );

        return new Promise(() => {
            const request = new XMLHttpRequest();
            request.open(config.method, config.url, true);
            if (cloudLogging) {
                // TODO - review this header when a more permanent solution is found for Splunk CORS error
                // request.setRequestHeader('Content-Type', 'application/json');
                request.setRequestHeader('Authorization', config.headers);
            }
            request.send(JSON.stringify(config.data));
        }).catch(() => {
            console.log('Failed to remotely log exception!');
        });
    }

    logException(exception, cause) {
        if (exceptionLoggerConfig.enabled) {
            if (!shouldThrottle(this.throttle, exceptionLoggerConfig.windowInSeconds, exceptionLoggerConfig.maxExceptionsPerWindow)) {
                const availability = getFeatureAMPAvailability(featureFlags.LOGGINGTOSPLUNK, this.ldFlags);
                if (availability) {
                    this.remotelyLogException(exception, cause, availability.isAvailable);
                }
            } else {
                console.log(`Too many exceptions in the last ${exceptionLoggerConfig.windowInSeconds} seconds, skipping remote logging`);
            }
        }
    }
}

export default WMICRemoteLogger;