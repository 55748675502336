// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPAAdditionalInterests__fullWidth{width:100%}.app__WMICPAAdditionalInterests__newAIDropdownMenuButton{position:static}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-pa-react/components/WMICVehicleAdditionalInfo/WMICPAAdditionalInterests/WMICPAAdditionalInterests.module.scss"],"names":[],"mappings":"AAAA,2CACI,UAAA,CAGJ,yDACI,eAAA","sourcesContent":[".fullWidth {\n    width: 100%;\n}\n\n.newAIDropdownMenuButton {\n    position: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "app__WMICPAAdditionalInterests__fullWidth",
	"newAIDropdownMenuButton": "app__WMICPAAdditionalInterests__newAIDropdownMenuButton"
};
export default ___CSS_LOADER_EXPORT___;
