/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import {TranslatorContext} from '@jutro/locale'
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICUnderwritingIssues } from 'wmic-pe-components-platform-react';
import { WMICLogger, CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WMICDocuments } from 'wmic-pe-capability-gateway-common-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICPAQuotePage.metadata.json5';

function WMICPAQuotePage(props) {
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData, markStepSubmitted, stopErrorNavigation } = props;
    const { isComponentValid, onValidate, initialValidation, registerInitialComponentValidation } = useValidation('QuotePage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { isSkipping } = useContext(WizardContext);
    const { setWizardLoading } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const [userDocuments, setUserDocuments] = useState([]);
    const [underwritingIssues, updateUnderwritingIssues] = useState(_.get(submissionVM, 'errorsAndWarnings.underwritingIssues.value'));

    const isBMSi = _.get(submissionVM, "isFromBMS_WMIC.value");
    const defaultedPropertiesToReview = _.get(submissionVM, "defaultedProperties_WMIC.value", []).filter((p) => p.propertyDisplayName && p.status !== CONSTANTS.REVIEWED);

    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await DocumentService.getDocumentsForJobNumber(submissionVM.quoteID.value, authHeader)

            if (userDocuments.length !== documents.length){
                setUserDocuments(documents);
            }
        } catch(err) {
            WMICLogger.error('WMICPAQuotePage getDocumentsForJobNumber', err);
        }
    }, [authHeader, submissionVM, userDocuments]);

    const validateSubmission = useCallback(async () => {
        setWizardLoading(!isSkipping);
        const result = await LoadSaveService.validateSubmissionForIssue(submissionVM.value, authHeader);
        _.extend(submissionVM.value, result);
        updateWizardData(submissionVM);
        markStepSubmitted(); // the step needs to be marked as submitted for the errors associated with this step to show up
        setWizardLoading(false);
        // Omitting updateWizardData from the dependency array because it causes an infinite loop when called from the useEffect below.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setWizardLoading, LoadSaveService, submissionVM, authHeader, viewModelService, markStepSubmitted]);

    useEffect(() => {
        registerInitialComponentValidation(() => !WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM));
        stopErrorNavigation()
    }, [registerInitialComponentValidation, stopErrorNavigation, submissionVM]);

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments, userDocuments]);

    useEffect(() => {
        // All default values should be reviewed to avoid
        // causing warning messages to disappear unintentionally.
        if (!isBMSi || (isBMSi && defaultedPropertiesToReview.length === 0)) {
            validateSubmission();
        }
    }, [defaultedPropertiesToReview.length, isBMSi, validateSubmission]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const initialValidationQuotePage = async () => {
        setWizardLoading(!isSkipping);
        await validateSubmission();
        const isInvalid = WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM) || WMICQuoteUtil.hasValidationErrors(submissionVM);
        setWizardLoading(false);
        return isInvalid ? false : initialValidation;
    };

    /**
     * Update submission and then navigate to quote summary screen
     */
    const onNext = useCallback(async () => {
        try {
            setWizardLoading(true);

            if (!isComponentValid) {
                return false;
            }

            if (
                WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM) ||
                WMICQuoteUtil.hasValidationErrors(submissionVM)
            ) {
                scrollToTop();
                return false;
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, setWizardLoading, submissionVM]);

    const overrideProps = {
        wmicFlexAlertMessage: {
            jobVM: submissionVM,
        },
        wmicQuoteAlertMessage: {
            jobVM: submissionVM
        },
        quotePagePremiumCosts: {
            premium: _.get(submissionVM, 'quoteData.offeredQuotes.value[0].premium', {}),
            jobVM: submissionVM,
            enableFlex: true,
            enableBrokerFees: true,
            updateJobVM: updateWizardData
        },
        quotePageUWIssues: {
            underwritingIssues,
            quoteID: _.get(submissionVM, 'quoteID.value'),
            authHeader,
            jobVM: submissionVM
        },
        quotePageSummary: {
            jobVM: submissionVM,
            lobData: _.get(submissionVM, 'lobData.personalAuto', {}),
            updateWizardData
        },
        quotePageUploadDocuments: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: true,
                jobNumber: submissionVM.quoteID.value
            }
        }
    };
    const resolvers = {
        componentMap: {
            WMICUnderwritingIssues,
            WMICDocuments
        }
    }

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidationQuotePage}
            cancelLabel={translator(commonMessages.saveAndExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.componentMap}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPAQuotePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICPAQuotePage;
