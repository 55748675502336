/* eslint-disable indent */
import PropTypes from 'prop-types';
import React, { useCallback, useReducer, useContext, useRef, useEffect } from 'react';
import { WMICTempStorageService, CONSTANTS, WMICAmpUtil } from 'wmic-portals-utils-js';
import { useTranslator, IntlContext } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICLoader } from 'gw-components-platform-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICEndorsementUtil, WMICPolicyChangeStateService } from 'wmic-capability-policychange';
import { useAuthentication } from 'wmic-digital-auth-react';
import { INITIAL_MODEL_STATE, INITIAL_VIEW_STATE, STATE_KEYS, VIEW_KEYS, STEPS, PREFILLED_STORAGE_KEYS, MESSAGE_TYPES } from './WMICRemoveVehicleWizardConsts';
import { changeCurrentState, mapStateData } from './WMICRemoveVehicleWizardUtils';
import { getMessageContent } from './WMICRemoveVehicleWizardSteps/index';
import styles from './WMICRemoveVehicleWizard.module.scss';
import metadata from './WMICRemoveVehicleWizard.metadata.json5';
import WMICPreparingQuoteLoader from '../../WMICPreparingQuoteLoader/WMICPreparingQuoteLoader';

const WMICRemoveVehicleWizard = (props) => {
    const  { match, policy, policyTransactions, removeVehiclePAComponentSetState } = props;
    const [modelState, modelStateDispatch] = useReducer((state, action) => changeCurrentState(state, action), INITIAL_MODEL_STATE);
    const [viewState, viewStateDispatch] = useReducer((state, action) => changeCurrentState(state, action), INITIAL_VIEW_STATE);

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const errorMessage = useRef(null);
    const intl = useContext(IntlContext);
    const isLoading = viewState[VIEW_KEYS.IS_LOADING] || viewState[VIEW_KEYS.PREPARING_QUOTE];

    const startOrResumePolicyChange = useCallback((policyVM, vehicleNumberToRemove, editDeleteVehicleState, changeModel, header) => {
        Promise.resolve(
            editDeleteVehicleState ? 
                WMICEndorsementUtil.getPolicyChangeModelByJobNumber(editDeleteVehicleState?.jobId, viewModelService, authHeader) :
                WMICEndorsementUtil.startPolicyChange(policyVM?.currentPeriod?.policyNumber, policyVM?.currentPeriod?.termNumber_WMIC, viewModelService, CONSTANTS.POLICY_CHANGE_TYPE.SELF_SERVE_REMOVE_VEHICLE, header)
        ).then((data) => {
            if (editDeleteVehicleState && editDeleteVehicleState.step !== STEPS.PICK_VEHICLE) {                
                WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER, editDeleteVehicleState?.jobId);
                const { vehicles, drivers } = data.lobData?.personalAuto?.coverables;
                mapStateData(vehicleNumberToRemove, vehicles?.value, drivers?.value, null, changeModel, modelStateDispatch, viewStateDispatch, editDeleteVehicleState);
            } else if (editDeleteVehicleState?.step === STEPS.PICK_VEHICLE) { 
                const { vehicles, drivers, vehicleDrivers } = data.lobData?.personalAuto?.coverables;
                mapStateData(vehicleNumberToRemove, vehicles?.value, drivers?.value, vehicleDrivers?.value, null, modelStateDispatch, viewStateDispatch);
                WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER, data?.value.jobID);
                modelStateDispatch({ type: STATE_KEYS.JOB_ID, value: data?.value.jobID });
            } else {
                const { vehicles, drivers, vehicleDrivers } = data.policyChange.lobData?.personalAuto?.coverables;
                mapStateData(vehicleNumberToRemove, vehicles, drivers, vehicleDrivers, null, modelStateDispatch, viewStateDispatch);
                WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER, data.policyChange.jobID);
                modelStateDispatch({ type: STATE_KEYS.JOB_ID, value: data.policyChange.jobID })

            }
        })
        .catch((error) => {
            errorMessage.current = getMessageContent('defaultError', translator);

            if (error.message === MESSAGE_TYPES.LAST_EFFECTIVE_DAY_OF_TERM_BEFORE_RENEWAL) {
                errorMessage.current = getMessageContent(MESSAGE_TYPES.LAST_EFFECTIVE_DAY_OF_TERM_BEFORE_RENEWAL, translator, intl, modelState, policyVM);
            }
            
            viewStateDispatch({ type: VIEW_KEYS.STEP, value: STEPS.ERROR });
        })
        .finally(() => {
            viewStateDispatch({ type: VIEW_KEYS.IS_LOADING, value: false });
        });
    }, [authHeader, translator, viewModelService]);

    useEffect(() => {
        const deleteVehicleState = WMICPolicyChangeStateService.getState();
        const deleteVehicleChangeRequest = WMICPolicyChangeStateService.getChangeRequest('deleteVehicle', 'delete-vehicle');
        const changeModelVehicleNumber = deleteVehicleChangeRequest?.changeModel?.vehicle?.vehicleNumber;
        const vehicleNumberToRemove = (deleteVehicleState?.editDeleteVehicle?.step === STEPS.PICK_VEHICLE) ? props.vehicleNumberToRemove 
                : (changeModelVehicleNumber || props.vehicleNumberToRemove);
        WMICAmpUtil.scrollToTop();
        startOrResumePolicyChange(policy, vehicleNumberToRemove, deleteVehicleState?.editDeleteVehicle, deleteVehicleChangeRequest?.changeModel, authHeader);
    }, [authHeader, policy, props.vehicleNumberToRemove, startOrResumePolicyChange]);

    const overrideProps = {
        wmicLoader: {
            visible: viewState[VIEW_KEYS.IS_LOADING],
        },
        wmicPreparingQuote: {
            visible: viewState[VIEW_KEYS.PREPARING_QUOTE]
        },
        initialSubpage: {
            visible: viewState.step === STEPS.INITIAL && !isLoading,
            modelState: modelState,
            modelStateDispatch: modelStateDispatch
        },
        setEffectiveDateSubpage: {
            visible: viewState.step === STEPS.EFFECTIVE_DATE && !isLoading,
            modelState: modelState,
            modelStateDispatch: modelStateDispatch,
            policyTransactions: policyTransactions
         },
        vehicleUsingSubpage: {
            visible: viewState.step === STEPS.VEHICLE_USING && !isLoading,
            modelState: modelState,
            modelStateDispatch: modelStateDispatch,
            currentVehicleNumber: viewState.currentVehicleNumber
        },
        assignPrimaryDriversSubpage: {
            visible: viewState.step === STEPS.ASSIGN_PRIMARY_DRIVERS && !isLoading,
            viewState: viewState,
            viewStateDispatch: viewStateDispatch,
            modelState: modelState,
            modelStateDispatch: modelStateDispatch
        },
        assignOccasionalDriversSubpage: {
            visible: viewState.step === STEPS.ASSIGN_OCCASIONAL_DRIVERS && !isLoading,
            modelState: modelState,
            modelStateDispatch: modelStateDispatch,
            currentVehicleNumber: viewState.currentVehicleNumber
        },
        errorSubpage: {
            visible: viewState.step === STEPS.ERROR,
            errorMessage: errorMessage.current
        },
        removeVehicleListButtonSection: {
            visible: !isLoading,
            policy: policy,
            match: match,
            modelState: modelState,
            modelStateDispatch: modelStateDispatch,
            viewState: viewState,
            viewStateDispatch: viewStateDispatch,
            policyTransactions: policyTransactions,
            errorMessage: errorMessage,
            currentVehicleNumber: viewState.currentVehicleNumber,
            removeVehiclePAComponentSetState: removeVehiclePAComponentSetState
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICLoaderComponent: WMICLoader,
            WMICPreparingQuoteComponent: WMICPreparingQuoteLoader,
            WMICButton
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={props.policy}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

WMICRemoveVehicleWizard.propTypes = {
    match: PropTypes.shape({}).isRequired,
    vehicleNumberToRemove: PropTypes.any,
    policy: PropTypes.any
};

export default WMICRemoveVehicleWizard;