import React from 'react';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import cx from 'classnames';
import styles from './WMICCheckbox.module.scss';
import { CheckboxField } from '@jutro/legacy/components';

const WMICCheckbox = (props) => {
    const translator = useTranslator();

    const renderCheckbox = (checkboxProps) => {
        const {
            value, label, labelClassName, children, className, hideCheckbox, ...otherProps
        } = checkboxProps;
        return (
            <Flex direction="column">
                <Flex className={styles.row}>
                    <CheckboxField
                        {...otherProps}
                        value={value}
                        controlClassName={cx(styles.icon, hideCheckbox && styles.hidenCheckboxIcon)}
                        className={cx(className, styles.checkbox, hideCheckbox && styles.hidenCheckbox)}
                    />
                    <span className={cx(labelClassName, styles.label, hideCheckbox && styles.hidenCheckboxLabel)}>{translator(label)}</span>
                </Flex>
                {
                    (children && value)
                    && (
                        <Flex direction="column" className={styles.childContainer}>
                            {
                                children.map((child) => renderCheckbox(child.props))
                            }
                        </Flex>
                    )
                }
            </Flex>
        );
    };

    return (
        <Flex>
            {renderCheckbox(props)}
        </Flex>
    );
};

WMICCheckbox.propTypes = {
    ...CheckboxField.PropTypes,
};


export default WMICCheckbox;
