import { LobIconUtil } from '@xengage/gw-portals-util-js';

const UMBRELLA_ICON = 'fa-umbrella';

const lobKeys = {
    PersonalAuto: 'PA',
    PersonalAutoLine: 'PA',
    // eslint-disable-next-line camelcase
    auto_per: 'PA',
    Homeowners: 'HO',
    homeowners: 'HO',
    HomeOwners: 'HO',
    // eslint-disable-next-line camelcase
    HomeownersLine_HOE: 'HO',
    HomeownersLine: 'HO',
    HOPLine: 'HO',
    HOLine: 'HO',
    HOPHomeowners: 'HO',
    GeneralLiability: 'GL',
    GeneralLiabilityLine: 'GL',
    GLLine: 'GL',
    BusinessOwners: 'BO',
    BusinessOwnersLine: 'BO',
    BOPLine: 'BO',
    InlandMarine: 'IM',
    InlandMarineLine: 'IM',
    IMLine: 'IM',
    CommercialProperty: 'CP',
    CommercialPropertyLine: 'CP',
    CPLine: 'CP',
    CommercialAuto: 'CA',
    BusinessAuto: 'CA',
    BusinessAutoLine: 'CA',
    WorkersComp: 'WC',
    WorkersCompLine: 'WC',
    WC7WorkersComp: 'WC',
    CommercialPackage: 'CPKG',
    CommercialPackageLine: 'CPKG',
    // eslint-disable-next-line camelcase
    travel_perLine: 'TRVL',
    PersonalUmbrellaLine: 'PUL'
};

const wmicLobIcons = {
    PA: {
        image: 'personal-auto',
        font: 'fa-car'
    },
    HO: {
        image: 'home-owners',
        font: 'fa-home'
    },
    PUL: {
        image: 'no-icon',
        font: 'fa-umbrella'
    },
    default: {
        image: 'no-icon',
        font: 'mi-receipt'
    }   
};

const getWMICObject = (lobCode) => {
    const normalisedKey = lobKeys[lobCode] || 'default';
    return wmicLobIcons[normalisedKey];
};

const getMaterialOrUmbrellaIcon = (policyLinesLength, lobCode) => {
    if (policyLinesLength === undefined) {
        return '';
    }
    return policyLinesLength > 1 ? UMBRELLA_ICON : LobIconUtil.getMaterialIcon(lobCode);
};

const getWmicIcon = (lobCode) => {
    return getWMICObject(lobCode).font;
};

/**
 * Check the given class is a material icon class
 * @param {String} className to check
 * @returns {bool} true if the given class is a material icon class
 */
const isMir = (className) => {
    return className.includes('mir') || className.indexOf('mi-') > -1;
}

/**
 * Convert material icon class to fontawesome class
 * @param {String} mirClass is the material icon class name
 * @returns {String} converted class name with fontawesome prefix
 */
const convertMirToFa = (mirClass) => {
    let faClassName = mirClass;
    if (mirClass === 'mir') {
        faClassName = 'fas';
    } else if (mirClass.indexOf('mi-') > -1) {
        // expect the class will be splitted into ['mi-', 'fa-xxx']
        const convertedClassName = mirClass.split('mi-');
        faClassName = convertedClassName[1];
    }
    return faClassName;
}

export default {
    // Platform LobIconUtil functions
    getImageIcon: LobIconUtil.getImageIcon,
    getFontIcon: LobIconUtil.getFontIcon,
    getMaterialIcon: LobIconUtil.getMaterialIcon,
    // Wawanesa LobIconUtil functions
    getMaterialOrUmbrellaIcon,
    getWmicIcon,
    isMir,
    convertMirToFa
};
