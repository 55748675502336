// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ScheduleItemsComponent__scheduleItemsTable{margin-top:var(--GW-LAYOUT-6);padding:var(--GW-SPACING-3) 0}.app__ScheduleItemsComponent__scheduleItemsTable th{text-align:left}td.app__ScheduleItemsComponent__gwTableCell{--table-cell-border-width:1px;--table-border-color:var(--GW-BORDER-COLOR);--table-cell-width:200px;border:var(--gw-quote-table-border);vertical-align:top;width:var(--table-cell-width)}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/ScheduleItemsComponent/ScheduleItemsComponent.module.scss"],"names":[],"mappings":"AAAA,iDACI,6BAAA,CACA,6BAAA,CAEA,oDACI,eAAA,CAIR,4CACI,6BAAA,CACA,2CAAA,CACA,wBAAA,CAIA,mCAAA,CADA,kBAAA,CADA,6BAEA","sourcesContent":[".scheduleItemsTable {\n    margin-top: var(--GW-LAYOUT-6);\n    padding: var(--GW-SPACING-3) 0;\n\n    & th {\n        text-align: left;\n    }\n}\n\ntd.gwTableCell {\n    --table-cell-border-width: 1px;\n    --table-border-color: var(--GW-BORDER-COLOR);\n    --table-cell-width: 200px;\n\n    width: var(--table-cell-width);\n    vertical-align: top;\n    border: var(--gw-quote-table-border);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scheduleItemsTable": "app__ScheduleItemsComponent__scheduleItemsTable",
	"gwTableCell": "app__ScheduleItemsComponent__gwTableCell"
};
export default ___CSS_LOADER_EXPORT___;
