// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICBankAccountDetails__wmicHintBelowDate,.app__WMICBankAccountDetails__wmicHintBelowEmail{display:grid;margin-bottom:11.5px;margin-top:0}.app__WMICBankAccountDetails__wmicHintBelowDate input[disabled],.app__WMICBankAccountDetails__wmicHintBelowDate.app__WMICBankAccountDetails__disabled,.app__WMICBankAccountDetails__wmicHintBelowEmail input[disabled],.app__WMICBankAccountDetails__wmicHintBelowEmail.app__WMICBankAccountDetails__disabled{opacity:1}.app__WMICBankAccountDetails__wmicBankDetailsContainer{display:grid;width:100%}.app__WMICBankAccountDetails__wmicDateTooltipHint,.app__WMICBankAccountDetails__wmicEmailTooltipHint{border:0}.app__WMICBankAccountDetails__wmicDateTooltipHint:after,.app__WMICBankAccountDetails__wmicDateTooltipHint:before,.app__WMICBankAccountDetails__wmicEmailTooltipHint:after,.app__WMICBankAccountDetails__wmicEmailTooltipHint:before{border:0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-common-react/components/WMICBankAccountDetails/WMICBankAccountDetails.module.scss"],"names":[],"mappings":"AAEA,iGAGI,YAAA,CADA,oBAAA,CADA,YAEA,CAEA,8SAEI,SAAA,CAIR,uDAEI,YAAA,CADA,UACA,CAGJ,qGACI,QAAA,CAEA,oOACI,QAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n\n.wmicHintBelowEmail, .wmicHintBelowDate {\n    margin-top: 0;\n    margin-bottom: 11.5px;\n    display: grid;\n\n    input[disabled], \n    &.disabled {\n        opacity: 1;\n    }\n}\n\n.wmicBankDetailsContainer {\n    width: 100%;\n    display: grid;\n}\n\n.wmicEmailTooltipHint, .wmicDateTooltipHint {\n    border: 0;\n\n    &::before, &::after{\n        border: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicHintBelowDate": "app__WMICBankAccountDetails__wmicHintBelowDate",
	"wmicHintBelowEmail": "app__WMICBankAccountDetails__wmicHintBelowEmail",
	"disabled": "app__WMICBankAccountDetails__disabled",
	"wmicBankDetailsContainer": "app__WMICBankAccountDetails__wmicBankDetailsContainer",
	"wmicDateTooltipHint": "app__WMICBankAccountDetails__wmicDateTooltipHint",
	"wmicEmailTooltipHint": "app__WMICBankAccountDetails__wmicEmailTooltipHint"
};
export default ___CSS_LOADER_EXPORT___;
