import React, { useContext } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Accordion } from '@jutro/legacy/components';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import WMICHOWatercraftDetails from '../WMICHOWatercraftDetails/WMICHOWatercraftDetails';
import WMICHOAdditionalInterests from '../WMICHOAdditionalInterests/WMICHOAdditionalInterests';
import metadata from './WMICHOWatercraftDetailView.metadata.json5';
import './WMICHOWatercraftDetailView.messages';

function WatercraftDetailView(props) {
    const { value, updateModel, isEditing, readOnly, showErrors, onValidate } = props;

    const { wizardData : jobVM } = useContext(WizardContext);
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);

    const commonWatercraftAccordionProps = {
        jobVM,
        onValidate: onValidateAccordion,
        showErrors: showErrors,
        onRiskViewChange: updateModel,
        readOnly: readOnly || !isEditing,
        isEditMode: isEditing,
        riskView: value
    }

    const overrideProps = {
        watercraftDetailsAccordion: {
            ...commonWatercraftAccordionProps,
            isValid: isAccordionValid('watercraftDetailsAccordionContent'),
            isEditMode: isEditing
        },
        watercraftDetailsAccordionContent: {
            ...commonWatercraftAccordionProps,
            isFromBms: false,
            updateWatercraftRiskView: updateModel,
            watercraftRiskView: value,
            jobVM
        },
        watercraftAdditionalInterestsAccordion: {
            ...commonWatercraftAccordionProps,
            isValid: isAccordionValid('watercraftAdditionalInterestsAccordionContent')
        },
        watercraftAdditionalInterestsAccordionContent: {
            ...commonWatercraftAccordionProps,
            category: 'HOWatercraftAddlInt_WMIC',
            risk: value
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHOAdditionalInterests,
            WMICHOWatercraftDetails,
            Accordion
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={value}
            overrideProps={overrideProps}
            onModelChange={updateModel}
            onValidationChange={onValidateAccordion}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    )
}

export default WatercraftDetailView;
