export default class WMICKitchenBathValidator {

    get kitchenCode() { return 'kitchens'; }

    get bathroomsExcludingBasementCode() { return 'bathrooms_excluding_basement'; }

    get basementBathroomsCode() { return 'bathrooms_basement'; }

    get bathroomsCodes() { return [this.bathroomsExcludingBasementCode, this.basementBathroomsCode]; }

    get fullBathsFilterName() { return 'full_baths'; }

    get halfBathsFilterName() { return 'half_baths'; }

    get threeQuarterBathsFilterName() { return 'three_quarter_baths'; }

    get otherBathCode() { return 'other'; }

    get noDescriptionValuesForBathroom() { return ['', null, undefined]; }

    get kitchensKey() { return `${this.kitchenCode}:${this.kitchenCode}`; }

    get fullBathroomsExcludingBasementKey() { return `${this.bathroomsExcludingBasementCode}:${this.fullBathsFilterName}`; }

    get halfBathroomsExcludingBasementKey() { return `${this.bathroomsExcludingBasementCode}:${this.halfBathsFilterName}`; }

    get threeQuarterBathroomsExcludingBasementKey() { return `${this.bathroomsExcludingBasementCode}:${this.threeQuarterBathsFilterName}`; }

    get otherBathroomsExcludingBasementKey() { return `${this.bathroomsExcludingBasementCode}:${this.otherBathCode}`; }

    get fullBasementBathroomsKey() { return `${this.basementBathroomsCode}:${this.fullBathsFilterName}`; }

    get halfBasementBathroomsKey() { return `${this.basementBathroomsCode}:${this.halfBathsFilterName}`; }

    get threeQuarterBasementBathroomsKey() { return `${this.basementBathroomsCode}:${this.threeQuarterBathsFilterName}`; }

    get otherBasementBathroomsKey() { return `${this.basementBathroomsCode}:${this.otherBathCode}`; }


    constructor(kitchenBathFeatureTypeList) {

        this.kitchenBathData = [
            { key: this.kitchensKey,
                code: this.kitchenCode,
                subTypeCodes: kitchenBathFeatureTypeList.getCodesForCategory({ code: this.kitchenCode, typelist: {name: 'DWIGKitchenBathCatgy_WMIC'}}).map((subType) => subType.code)
                    .concat(this.emptySubTypeCodes),
                min: 1,
                max: 9,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Kitchens',
                count: null},

            { key: this.fullBathroomsExcludingBasementKey,
                code: this.bathroomsExcludingBasementCode,
                subTypeCodes: kitchenBathFeatureTypeList.getFilter(this.fullBathsFilterName).codes.map((subType) => subType.code),
                min: 1,
                max: 99,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Full Bathrooms Excluding Basement',
                count: null},

            { key: this.halfBathroomsExcludingBasementKey,
                code: this.bathroomsExcludingBasementCode,
                subTypeCodes: kitchenBathFeatureTypeList.getFilter(this.halfBathsFilterName).codes.map((subType) => subType.code),
                min: 1,
                max: 9,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Half Bathrooms Excluding Basement',
                count: null},

            { key: this.threeQuarterBathroomsExcludingBasementKey,
                code: this.bathroomsExcludingBasementCode,
                subTypeCodes: kitchenBathFeatureTypeList.getFilter(this.threeQuarterBathsFilterName).codes.map((subType) => subType.code),
                min: 1,
                max: 9,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Three Quarter Bathrooms Excluding Basement',
                count: null},

            { key: this.otherBathroomsExcludingBasementKey,
                code: this.bathroomsExcludingBasementCode,
                subTypeCodes: [this.otherBathCode],
                min: 1,
                max: 9,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Other Bathrooms Excluding Basement',
                count: null},

            { key: this.fullBasementBathroomsKey,
                code: this.basementBathroomsCode,
                subTypeCodes: kitchenBathFeatureTypeList.getFilter(this.fullBathsFilterName).codes.map((subType) => subType.code),
                min: 1,
                max: 99,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Full Basement Bathrooms',
                count: null},

            { key: this.halfBasementBathroomsKey,
                code: this.basementBathroomsCode,
                subTypeCodes: kitchenBathFeatureTypeList.getFilter(this.halfBathsFilterName).codes.map((subType) => subType.code),
                min: 1,
                max: 9,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Half Basement Bathrooms',
                count: null},

            { key: this.threeQuarterBasementBathroomsKey,
                code: this.basementBathroomsCode,
                subTypeCodes: kitchenBathFeatureTypeList.getFilter(this.threeQuarterBathsFilterName).codes.map((subType) => subType.code),
                min: 1,
                max: 9,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Three Quarter Basement Bathrooms',
                count: null},

            { key: this.otherBasementBathroomsKey,
                code: this.basementBathroomsCode,
                subTypeCodes: [this.otherBathCode],
                min: 1,
                max: 9,
                message: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Other Basement Bathrooms',
                count: null}
        ];
    }

    getBathKey(bathroom) {
        const foundBathroom = this.kitchenBathData.find((kitchenBathEntry) => kitchenBathEntry.code === bathroom.materialOrFeature && kitchenBathEntry.subTypeCodes.includes(bathroom.description));
        return !foundBathroom ? null : foundBathroom.key;
    }

    getKitchenBathKey(kitchenBath) {
        switch (kitchenBath.materialOrFeature) {
            case this.kitchenCode : return this.kitchensKey;
            case this.bathroomsExcludingBasementCode:
            case this.basementBathroomsCode: return this.getBathKey(kitchenBath);
            default : return null;
        }
    }

    removeDuplicates(data) {
        return data.reduce((a, b) => {
            if (a.indexOf(b) < 0) {
                a.push(b);
            }
            return a;
        }, []);
    }

    isKitchenBathValidatable(kitchenBath) {
        return (kitchenBath.recordExistsInPreviousPeriod !== true) || (kitchenBath.count !== kitchenBath.countInPreviousPeriod);
    }

    getValidatableKitchenBathTypes(kitchenBathInfos) {
        if (this.isNotOrEmptyArray(kitchenBathInfos)) {
            return [];
        }
        const validatableKitchenBathTypes = kitchenBathInfos
            .filter((kitchenBath) => this.isKitchenBathValidatable(kitchenBath))
            .map((kitchenBath) => this.getKitchenBathKey(kitchenBath))
            .filter((kitchenBathKey) => kitchenBathKey);
        return this.removeDuplicates(validatableKitchenBathTypes);
    }

    isNumberOfRoomsValid(key) {
        const kitchenBathInfo = this.getKitchenBathDataEntryByKey(key);
        if (kitchenBathInfo && kitchenBathInfo.count) {
            return kitchenBathInfo.count >= kitchenBathInfo.min && kitchenBathInfo.count <= kitchenBathInfo.max;
        }
        return true;
    }

    getKitchenBathDataEntryByKey(key) { return this.kitchenBathData.find((entry) => entry.key === key); }

    convertStringToNumber(number) {
        if (number) {
            const value = parseInt(number, 10);
            return Number.isNaN(value) ? 0 : value;
        }
        return 0;
    }

    isBathroomWithNoDescription(bathroom) {
        return this.bathroomsCodes.includes(bathroom.materialOrFeature) && this.noDescriptionValuesForBathroom.includes(bathroom.description);
    }

    isNotOrEmptyArray(objectToCheck) {
        return !objectToCheck || !Array.isArray(objectToCheck) || objectToCheck.length === 0;
    }

    getValidationMessages(kitchenBathRecords) {
        this.kitchenBathData.forEach((kitchenBathEntry) => { kitchenBathEntry.count = null; });

        if (this.isNotOrEmptyArray(kitchenBathRecords)) {
            return [];
        }

        const kitchenBathRecordsToValidate = kitchenBathRecords.filter((kitchenBath) => !this.isBathroomWithNoDescription(kitchenBath));

        const validatableKitchenBathroomTypes = this.getValidatableKitchenBathTypes(kitchenBathRecordsToValidate);

        if (this.isNotOrEmptyArray(validatableKitchenBathroomTypes)) {
            return [];
        }

        kitchenBathRecordsToValidate.forEach((kitchenBath) => {
            const key = this.getKitchenBathKey(kitchenBath);
            if (validatableKitchenBathroomTypes.includes(key)) {
                const kitchenBathDataToUpdate = this.getKitchenBathDataEntryByKey(key);
                if (kitchenBathDataToUpdate) {
                    kitchenBathDataToUpdate.count = this.convertStringToNumber(kitchenBathDataToUpdate.count) + this.convertStringToNumber(kitchenBath.count);
                }
            }
        }
        );

        const validationMessages = [];
        for (const key of validatableKitchenBathroomTypes) {
            if (!this.isNumberOfRoomsValid(key)) {
                const kitchenBath = this.getKitchenBathDataEntryByKey(key);
                if (kitchenBath) {
                    validationMessages.push({message: kitchenBath.message, min: kitchenBath.min, max: kitchenBath.max});
                }
            }
        }
        return validationMessages;
    }

    getValidationMessagesForDwelling(dwelling) {
        if (dwelling?.construction?.dwellingWings[0]?.constructionDetail) {
            const {kitchenBathInfo} = dwelling.construction.dwellingWings[0].constructionDetail;
            return this.getValidationMessages(kitchenBathInfo);
        }
        return [];
    }
}
