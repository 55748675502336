import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { WMICReCaptchaService } from 'wmic-portals-recaptcha-js';

export default class WMICJsonRPCService {
    static send(url, method, data, additionalHeaders={}) {
        
        return WMICReCaptchaService.fetchReCaptchaToken(method).then((token)=> {
            additionalHeaders['x-captcha'] = token
            return JsonRPCService.send(url, method, data, additionalHeaders).then(
                (response) => {
                    return response;
                },
                (error) => {
                    throw error;
                }
            );
        })
        
    }
}