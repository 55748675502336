// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__CreditCardExpiryDate__dateContainer{align-items:flex-end;display:flex}.app__CreditCardExpiryDate__date{width:100%}.app__CreditCardExpiryDate__expirationYear{padding-left:var(--GW-SPACING-2);width:100%}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/CreditCardExpiryDate/CreditCardExpiryDate.module.scss"],"names":[],"mappings":"AAAA,0CAEI,oBAAA,CADA,YACA,CAEJ,iCACI,UAAA,CAEJ,2CAEI,gCAAA,CADA,UACA","sourcesContent":[".dateContainer {\n    display: flex;\n    align-items: flex-end;\n}\n.date {\n    width: 100%;\n}\n.expirationYear {\n    width: 100%;\n    padding-left: var(--GW-SPACING-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateContainer": "app__CreditCardExpiryDate__dateContainer",
	"date": "app__CreditCardExpiryDate__date",
	"expirationYear": "app__CreditCardExpiryDate__expirationYear"
};
export default ___CSS_LOADER_EXPORT___;
