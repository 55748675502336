import _ from 'lodash';

const retrieveClaimStates = () => {
    const claimStates = {
        OPEN: 'open',
        CLOSED: 'closed'
    };

    return claimStates;
};

const autoClaimTypeDisplayName = (claimLossCause, claimCauseDescription, claimEvent) => {
    /*
     * AMPP-669: Claim Type value will be determined by the combination of Loss Cause, Cause Description and Event.
     *
     * In Claim Center:
     *
     * - Loss Cause
     *      column: Claim.eti:LossCause
     *      typekey: LossCause.wmic.ttx, LossCause.ttx
     *
     * - Cause Description
     *      column: Claim.wmic.etx:PerilGroupCategory_WMIC
     *      typekey: PerilGroupCategory_WMIC.tti
     *
     * - Event
     *      column: Claim.wmic.etx:PerilGroupEvent_WMIC
     *      typekey: PerilGroupEvent_WMIC.tti
     */

    /*
     * code -> name mappings
     *
     * Loss Cause
     * ----------------------
     *
     * earthquakedamage -> Earthquake Damage
     * firesmokedamage -> Fire, Lightning, Explosion or Smoke Damage
     * glassdamage -> Glass Damage
     * hailwindstormdamage -> Hail, Wind or Storm Damage
     * roadsideassistance -> Roadside Assistance
     * theftvandalismriotdamage -> Theft, Vandalism, Riot Damage or Mysterious Dissappearance
     * physicaldamage -> Vehicle, Machinery or Equipment Physical Damage
     * waterdamage -> Water Damage
     *
     * Cause Description
     * ----------------------
     *
     * ed_Earthquake -> Earthquake
     * fsd_Explosion -> Explosion
     * fsd_Fire -> Fire
     * gd_Glass -> Glass
     * hwd_Hail -> Hail
     * hwd_ice_snow -> Ice/Snow
     * fsd_Lightning -> Lightning
     * wd_OverflowFromBodyWaterFluvial -> Overflow from Body of Water (Fluvial)
     * tvr_RiotVandalismMaliciousActs -> Riot, Vandalism or Malicious Acts
     * rsa_roadsideassistance -> Roadside Assistance
     * fsd_Smoke -> Smoke
     * wd_SurfaceWaterPluvial -> Surface Water (Pluvial)
     * tvr_Theft -> Theft
     * pd_Collision -> Vehicle Collision Damage
     * pd_NonCollision -> Vehicle Non-Collision Damage
     * wd_AllOther -> Water All Other
     * hwd_Wind -> Wind
     *
     * Event
     * ----------------------
     *
     * fs_f_appliance -> Appliance
     * fs_s_appliance -> Appliance
     * fs_f_arson_suspected -> Arson or Suspected Arson
     * fs_s_arson_suspected_arson -> Arson or Suspected Arson
     * fs_s_bonfire_pit -> Bonfire/Fire Pit (Ember/Ash)
     * fs_f_bonfire_pit -> Bonfire/Fire Pit (Ember/Ash)
     * rsa_roadsideassistance_boost -> Boost
     * tvr_rwma_civil_disturbance -> Civil Disturbance/Riot
     * pd_collision_with_building -> Collision with Building or Structure
     * pd_collision_with_cyclist -> Collision with Cyclist
     * pd_collision_with_pedestrian -> Collision with Pedestrian
     * pd_collision_with_animal -> Collision with Wildlife or Domestic Animal
     * pd_noncollision_comp_not_otherwise_classified -> Comprehensive - not otherwise classified
     * fs_s_smudging -> Controlled Burn or Smudging
     * fs_f_cooking_incident -> Cooking Incident
     * fs_s_cooking_incident -> Cooking Incident
     * ed_e_earthquake -> Earthquake
     * fs_f_electrical_fire -> Electrical Fire
     * fs_explosion_explosion -> Explosion
     * fs_lightning_fallen_tree_branch -> Fallen Tree/Branch
     * hws_w_fallen_tree_branch -> Fallen Tree/Branch
     * fs_f_not_otherwise_classified -> Fire - not otherwise classified
     * ed_e_fire_following_earthquake -> Fire Following Earthquake
     * fs_lightning_fire_from -> Fire From Lightning
     * fs_f_storage_chemicals_fuels -> Fire from Storage & Handling of Chemicals/Fuels
     * rsa_roadsideassistance_flat_tire -> Flat Tire
     * fs_f_neighboring_premises -> From Neighboring Premises
     * fs_s_neighboring_premises -> From Neighboring Premises
     * gd_glass_breakage -> Glass Breakage
     * hws_h_hail_wind -> Hail & Wind
     * hws_h_hail -> Hail
     * fs_f_heating_device -> Heating Device
     * fs_s_heating_device -> Heating Device
     * pd_collision_hit_and_run -> Hit and Run
     * hws_w_hurricane_tropical_storm -> Hurricane/Tropical Storm
     * hwd_ice_snow_storm -> Ice Storm
     * ed_e_event_landslide_snowslide_earthquake -> Landslide/Snowslide following Earthquake
     * fs_lightning_not_otherwise_classified -> Lightning - not otherwise classified
     * rsa_roadsideassistance_lock_out -> Lock Out Services
     * pd_collision_multi_vehicle_upset_2_more -> Multi-Vehicle Collision/Upset (2 or more)
     * wd_overflow_not_otherwise_classified -> Overflow - not otherwise classified
     * wd_overflow_from_fresh_body_water_fluvial -> Overflow from Fresh Body of Water (Fluvial)
     * wd_overflow_from_salt_body_water_fluvial -> Overflow from Salt Body of Water (Fluvial)
     * fs_lightning_power_surge -> Power Surge
     * rsa_roadsideassistance_not_otherwise_classified -> Roadside Assistance - not otherwise classified
     * pd_collision_single_vehicle -> Single Vehicle Collision/Upset - not otherwise classified
     * fs_s_not_otherwise_classified -> Smoke - not otherwise classified
     * fs_f_smoking_cigarette_or_other -> Smoking (cigarette or other)
     * fs_s_smoking_cigarette_or_other -> Smoking (cigarette or other)
     * hwd_ice_snow_blizzard -> Snow Storm/Blizzard
     * gd_stone_chip -> Stone Chip
     * wd_sw_surface_water_pluvial -> Surface Water (Pluvial)
     * fs_explosion_terrorist_attack -> Terrorism or Act of War
     * tvr_t_theft_entire_vehicle -> Theft of Entire Vehicle
     * tvr_t_theft_attemptedtheft_vehicle -> Theft or Attempted Theft of Vehicle
     * hws_w_tornado -> Tornado
     * rsa_roadsideassistance_tow -> Tow
     * fs_f_undetermined -> Undetermined
     * tvr_rwma_vandalism -> Vandalism or Malicious Acts
     * wd_all_other -> Water All Other
     * fs_f_wild_fire -> Wild Fire
     * fs_s_wild_fire -> Wild Fire
     * rsa_roadsideassistance_winch -> Winch
     * hws_w_wind -> Wind
     *
     */
    const lookupKey = `${claimLossCause}-:-${claimCauseDescription}-:-${claimEvent}`;

    const lookupTable = {
        'earthquakedamage-:-ed_Earthquake-:-ed_e_earthquake': 'Earthquake',
        'earthquakedamage-:-ed_Earthquake-:-ed_e_fire_following_earthquake': 'Earthquake',
        'earthquakedamage-:-ed_Earthquake-:-ed_e_event_landslide_snowslide_earthquake': 'Earthquake',
        'firesmokedamage-:-fsd_Explosion-:-fs_explosion_explosion': 'Explosion',
        'firesmokedamage-:-fsd_Explosion-:-fs_explosion_terrorist_attack': 'Explosion',
        'firesmokedamage-:-fsd_Fire-:-fs_f_appliance': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_arson_suspected': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_bonfire_pit': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_cooking_incident': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_electrical_fire': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_not_otherwise_classified': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_storage_chemicals_fuels': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_neighboring_premises': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_heating_device': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_smoking_cigarette_or_other': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_undetermined': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_wild_fire': 'Fire',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_fallen_tree_branch': 'Lightning ',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_fire_from': 'Lightning ',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_not_otherwise_classified': 'Lightning ',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_power_surge': 'Lightning ',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_appliance': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_arson_suspected_arson': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_bonfire_pit': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_smudging': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_cooking_incident': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_neighboring_premises': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_heating_device': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_not_otherwise_classified': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_smoking_cigarette_or_other': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_wild_fire': 'Smoke',
        'glassdamage-:-gd_Glass-:-gd_glass_breakage': 'Glass',
        'glassdamage-:-gd_Glass-:-gd_stone_chip': 'Glass',
        'hailwindstormdamage-:-hwd_Hail-:-hws_h_hail': 'Hail',
        'hailwindstormdamage-:-hwd_Hail-:-hws_h_hail_wind': 'Hail',
        'hailwindstormdamage-:-hwd_ice_snow-:-hwd_ice_snow_storm': 'Ice/Snow',
        'hailwindstormdamage-:-hwd_ice_snow-:-hwd_ice_snow_blizzard': 'Ice/Snow',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_fallen_tree_branch': 'Wind',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_hurricane_tropical_storm': 'Wind',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_tornado': 'Wind',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_wind': 'Wind',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_boost': 'Roadside Assistance',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_flat_tire': 'Roadside Assistance',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_lock_out': 'Roadside Assistance',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_not_otherwise_classified': 'Roadside Assistance',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_tow': 'Roadside Assistance',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_winch': 'Roadside Assistance',
        'theftvandalismriotdamage-:-tvr_RiotVandalismMaliciousActs-:-tvr_rwma_civil_disturbance': 'Riot, Vandalism or Malicious Acts',
        'theftvandalismriotdamage-:-tvr_RiotVandalismMaliciousActs-:-tvr_rwma_vandalism': 'Riot, Vandalism or Malicious Acts',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_theft_entire_vehicle': 'Theft',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_theft_attemptedtheft_vehicle': 'Theft',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_building': 'Collision',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_cyclist': 'Collision',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_pedestrian': 'Collision',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_animal': 'Collision',
        'physicaldamage-:-pd_Collision-:-pd_collision_hit_and_run': 'Collision',
        'physicaldamage-:-pd_Collision-:-pd_collision_multi_vehicle_upset_2_more': 'Collision',
        'physicaldamage-:-pd_Collision-:-pd_collision_single_vehicle': 'Collision',
        'physicaldamage-:-pd_NonCollision-:-pd_noncollision_comp_not_otherwise_classified': 'Non-Collision Damage',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_not_otherwise_classified': 'Water',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_from_fresh_body_water_fluvial': 'Water',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_from_salt_body_water_fluvial': 'Water',
        'waterdamage-:-wd_SurfaceWaterPluvial-:-wd_sw_surface_water_pluvial': 'Water',
        'waterdamage-:-wd_AllOther-:-wd_all_other': 'Water'
    };

    const value = lookupTable[lookupKey];

    return value;
};

const homeClaimTypeDisplayName = (claimLossCause, claimCauseDescription, claimEvent) => {
    /*
     * AMPP-697: Claim Type value will be determined by the combination of Loss Cause, Cause Description and Event.
     *
     * In Claim Center:
     *
     * - Loss Cause
     *      column: Claim.eti:LossCause
     *      typekey: LossCause.wmic.ttx, LossCause.ttx
     *
     * - Cause Description
     *      column: Claim.wmic.etx:PerilGroupCategory_WMIC
     *      typekey: PerilGroupCategory_WMIC.tti
     *
     * - Event
     *      column: Claim.wmic.etx:PerilGroupEvent_WMIC
     *      typekey: PerilGroupEvent_WMIC.tti
     */

    /*
     * code -> name mappings
     *
     * Loss Cause
     * ----------------------
     *
     * collapse -> Collapse Damage
     * earthquakedamage -> Earthquake Damage
     * firesmokedamage -> Fire, Lightning, Explosion or Smoke Damage
     * glassdamage -> Glass Damage
     * hailwindstormdamage -> Hail, Wind or Storm Damage
     * impactdamage -> Aircraft/Vehicle Impact Damage
     * otherlossdamages:Other Loss or Damage
     * personalliability -> Personal/Farm Liability
     * theftvandalismriotdamage -> Theft, Vandalism, Riot Damage or Mysterious Disappearance
     * transportationdamage -> Transportation Damage
     * waterdamage -> Water Damage
     *
     * Cause Description
     * ----------------------
     *
     * a_AllOthers -> All Other
     * a_AllOthers_equipment -> Equipment Breakdown
     * c_Collapse -> Collapse
     * ed_Earthquake -> Earthquake
     * fsd_Explosion -> Explosion
     * fsd_Fire -> Fire
     * fsd_Lightning -> Lightning
     * fsd_Smoke -> Smoke
     * gd_Glass -> Glass
     * hwd_Hail -> Hail
     * hwd_Wind -> Wind
     * hwd_ice_snow -> Ice/Snow
     * id_Impact -> Impact
     * pl_BodilyInjury -> Bodily Injury
     * pl_PropertyDamage -> Property Damage
     * td_Transportation -> Transportation
     * tvr_BR -> Break & Enter or Robbery
     * tvr_MysteriousDisappearance -> Mysterious Disappearance
     * tvr_RiotVandalismMaliciousActs -> Riot, Vandalism or Malicious Acts
     * tvr_Theft -> Theft
     * wd_AboveGroundWater -> Above Ground Water
     * wd_AllOther -> Water All Other
     * wd_BelowGroundWater -> Below Ground Water
     * wd_IceDam -> Ice Dam
     * wd_OverflowFromBodyWaterFluvial -> Overflow from Body of Water (Fluvial)
     * wd_Sewer -> Sewer
     * wd_SurfaceWaterPluvial -> Surface Water (Pluvial)
     * wd_WaterEscape -> Water Escape
     *
     *
     * Event
     * ----------------------
     *
     * a_AllOthers_equipment_breakdown -> Equipment Breakdown
     * a_allothers_all_other_losses -> All Other Losses
     * a_allothers_breakdown_corrosion_oil_tank -> Breakdown/Corrosion of Oil Tank
     * a_allothers_damage_cause_by_pets -> Damage Caused by Animals
     * a_allothers_damage_caused_by_bears -> Damage Caused by Bears
     * a_allothers_equipment_electronic_impairment -> Electronic Circuitry Impairment
     * a_allothers_food_freezer_spoilage -> Food Freezer Spoilage
     * a_allothers_oil_tank_neighboring -> Oil Tank from Neighboring Premises
     * a_allothers_oil_tanks_other_losses -> Oil Tanks - All other losses
     * a_allothers_service_line_failure -> Service Line Failure
     * c_collapse_earth_movement -> Earth Movement (other than earthquake)
     * c_collapse_not_otherwise_classified -> Collapse - not otherwise classified
     * c_collapse_structural_failure -> Structural Failure
     * c_collapse_weight_ice_snow -> Weight of Snow/Ice
     * ed_e_earthquake -> Earthquake
     * ed_e_event_landslide_snowslide_earthquake -> Landslide/Snowslide following Earthquake
     * ed_e_fire_following_earthquake -> Fire Following Earthquake
     * fs_explosion_explosion -> Explosion
     * fs_explosion_terrorist_attack -> Terrorism or Act of War
     * fs_f_appliance -> Appliance
     * fs_f_arson_suspected -> Arson or Suspected Arson
     * fs_f_bonfire_pit -> Bonfire/Fire Pit (Ember/Ash)
     * fs_f_chimney_fireplace -> Fire from Chimney/Fireplace
     * fs_f_cooking_incident -> Cooking Incident
     * fs_f_electrical_fire -> Electrical Fire
     * fs_f_heating_device -> Heating Device
     * fs_f_not_otherwise_classified -> Fire - not otherwise classified
     * fs_f_smoking_cigarette_or_other -> Smoking (cigarette or other)
     * fs_f_spontaneous_combustion -> Spontaneous Combustion
     * fs_f_storage_chemicals_fuels -> Fire from Storage & Handling of Chemicals/Fuels
     * fs_f_undetermined -> Undetermined
     * fs_f_wild_fire -> Wild Fire
     * fs_f_woodstove -> Fire from Woodstove or Solid Fuel Burning
     * fs_lightning_fallen_tree_branch -> Fallen Tree/Branch
     * fs_lightning_fire_from -> Fire From Lightning
     * fs_lightning_not_otherwise_classified -> Lightning - not otherwise classified
     * fs_lightning_power_surge -> Power Surge
     * fs_s_appliance -> Appliance
     * fs_s_arson_suspected_arson -> Arson or Suspected Arson
     * fs_s_bonfire_pit -> Bonfire/Fire Pit (Ember/Ash)
     * fs_s_escape_from_chimney_fireplace -> Smoke Escape from Chimney/Fireplace
     * fs_s_escape_woodstove -> Smoke Escape from Woodstove or Solid Fuel Burning
     * fs_s_heating_device -> Heating Device
     * fs_s_neighboring_premises -> From Neighboring Premises
     * fs_s_not_otherwise_classified -> Smoke - not otherwise classified
     * fs_s_smoking_cigarette_or_other -> Smoking (cigarette or other)
     * fs_s_smudging -> Controlled Burn or Smudging
     * fs_s_wild_fire -> Wild Fire
     * gd_glass_breakage -> Glass Breakage
     * hwd_ice_snow_blizzard -> Snow Storm/Blizzard
     * hwd_ice_snow_storm -> Ice Storm
     * hws_h_hail -> Hail
     * hws_h_hail_wind -> Hail & Wind
     * hws_w_fallen_tree_branch -> Fallen Tree/Branch
     * hws_w_hurricane_tropical_storm -> Hurricane/Tropical Storm
     * hws_w_tornado -> Tornado
     * hws_w_wind -> Wind
     * id_impact_falling_flying_objects -> Falling/Flying objects (including aircraft or parts of)
     * id_impact_not_otherwise_classified -> Impact - not otherwise classified
     * id_impact_third_party_vehicle_impact -> Third Party Vehicle Impact
     * id_impact_vehicle_impact_by_insured -> Vehicle Impact by Insured
     * pl_bodilyinjury_dog_bite -> Dog Bite (Pet)
     * pl_bodilyinjury_failure_equipment -> Operation and/or Failure of Equipment
     * pl_bodilyinjury_inadequate_storage -> Inadequate or Improper Housekeeping/Maintenance/Storage
     * pl_bodilyinjury_negligent_act -> Negligent Act - not otherwise classified
     * pl_bodilyinjury_resulting_from_product -> Resulting from Product or Work
     * pl_bodilyinjury_slip_fall -> Slip & Fall
     * pl_propertydamage_failure_equipment -> Operation and/or Failure of Equipment
     * pl_propertydamage_farm_vehicle_impact -> Impact by Farm Vehicle or Machinery
     * pl_propertydamage_inadequate_storage -> Inadequate or Improper Housekeeping/Maintenance/Storage
     * pl_propertydamage_oil_tanks_all_other_losses -> Oil Tanks - All other losses
     * pl_propertydamage_other_act -> Negligent Act - not otherwise classified
     * pl_propertydamage_pollution_other -> Pollution other than oil
     * pl_propertydamage_resulting_from_product -> Resulting from Product or Work
     * pl_propertydamage_vehicle_impact -> Impact by Boat or Motorized Vehicle
     * td_transportation_by_insured -> Transportation by Insured
     * td_transportation_incident_carrier_land_water -> Transported by Common Carrier (Land or Water)
     * td_transportation_third_party -> Transportation by Third Party not for hire
     * tvr_br_burglary -> Break & Enter (Burglary)
     * tvr_br_robbery_bodily_harm -> Robbery - Bodily Harm/Threatened Bodily Harm
     * tvr_md_misplaced_articles -> Lost or Misplaced Articles
     * tvr_rwma_civil_disturbance -> Civil Disturbance/Riot
     * tvr_rwma_vandalism -> Vandalism or Malicious Acts
     * tvr_rwma_vandalism_by_tenant -> Vandalism or Malicious Acts Caused by Tenant
     * tvr_t_identify_theft -> Identity Theft
     * tvr_t_theft -> Theft
     * tvr_t_theft_employee -> Theft by Employee
     * tvr_t_theft_tenant -> Theft by Tenant
     * wd_agw_not_otherwise_classified -> Above Ground Water - not otherwise classified
     * wd_agw_water_through_roof_eavestrough_downspout -> Water entering through Roof, Roof Opening, Eavestrough, Downspout or Rainwater Leader
     * wd_agw_water_through_walls_doors_windows -> Water entering through Walls, Doors, Windows or Window Wells
     * wd_all_other -> Water All Other
     * wd_bgw_groundwater_infiltration -> Groundwater Infiltration
     * wd_escape_domestic_water_container -> Escape from Domestic Water Container
     * wd_escape_freezing_plumbing -> Escape caused by Freezing of Plumbing System, Heating, Air Conditioning or Fire Suppression System
     * wd_escape_from_plumbing_heating_aircond -> Escape from Plumbing System, Heating, Air Conditioning or Fire Suppression System
     * wd_escape_neighboring_premises -> Escape from Neighboring Premises
     * wd_escape_not_otherwise_classified -> Water Escape - not otherwise classified
     * wd_escape_overflow_appliance_fixture -> Escape/Overflow from Appliance or Fixture
     * wd_escape_overflow_swimming_pool -> Escape/Overflow from Swimming Pool
     * wd_escape_watermain -> Escape from Watermain
     * wd_ice_dam -> Ice Dam
     * wd_overflow_from_fresh_body_water_fluvial -> Overflow from Fresh Body of Water (Fluvial)
     * wd_overflow_from_salt_body_water_fluvial -> Overflow from Salt Body of Water (Fluvial)
     * wd_overflow_not_otherwise_classified -> Overflow - not otherwise classified
     * wd_sewer_backup -> Sewer Backup
     * wd_sewer_septic_system_backup -> Septic System Backup
     * wd_sewer_undetermined -> Sewer - undetermined
     * wd_sw_surface_water_pluvial -> Surface Water (Pluvial)
     *
     */
    const lookupKey = `${claimLossCause}-:-${claimCauseDescription}-:-${claimEvent}`;

    const lookupTable = {
        'impactdamage-:-id_Impact-:-id_impact_falling_flying_objects': 'Aircraft / Vehicle Impact',
        'impactdamage-:-id_Impact-:-id_impact_not_otherwise_classified': 'Aircraft / Vehicle Impact',
        'impactdamage-:-id_Impact-:-id_impact_third_party_vehicle_impact': 'Aircraft / Vehicle Impact',
        'impactdamage-:-id_Impact-:-id_impact_vehicle_impact_by_insured': 'Aircraft / Vehicle Impact',
        'collapse-:-c_Collapse-:-c_collapse_not_otherwise_classified': 'Collapse',
        'collapse-:-c_Collapse-:-c_collapse_earth_movement': 'Collapse',
        'collapse-:-c_Collapse-:-c_collapse_structural_failure': 'Collapse',
        'collapse-:-c_Collapse-:-c_collapse_weight_ice_snow': 'Collapse',
        'earthquakedamage-:-ed_Earthquake-:-ed_e_earthquake': 'Earthquake',
        'earthquakedamage-:-ed_Earthquake-:-ed_e_fire_following_earthquake': 'Earthquake',
        'earthquakedamage-:-ed_Earthquake-:-ed_e_event_landslide_snowslide_earthquake': 'Earthquake',
        'firesmokedamage-:-fsd_Fire-:-fs_f_appliance': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_appliance': 'Smoke',
        'firesmokedamage-:-fsd_Fire-:-fs_f_arson_suspected': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-ffs_s_arson_suspected_arson': 'Smoke',
        'firesmokedamage-:-fsd_Fire-:-fs_f_bonfire_pit': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_bonfire_pit': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_smudging': 'Smoke',
        'firesmokedamage-:-fsd_Fire-:-fs_f_cooking_incident': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_cooking_incident': 'Smoke',
        'firesmokedamage-:-fsd_Fire-:-fs_f_electrical_fire': 'Fire',
        'firesmokedamage-:-fsd_Explosion-:-fs_explosion_explosion': 'Explosion',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_fallen_tree_branch': 'Lightning',
        'firesmokedamage-:-fsd_Fire-:-fs_f_not_otherwise_classified': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_chimney_fireplace': 'Fire',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_fire_from': 'Lightning',
        'firesmokedamage-:-fsd_Fire-:-ffs_f_storage_chemicals_fuels': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_woodstove': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_neighboring_premises': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_neighboring_premises': 'Smoke',
        'firesmokedamage-:-fsd_Fire-:-fs_f_heating_device': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_heating_device': 'Smoke',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_not_otherwise_classified': 'Lightning',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_power_surge': 'Lightning',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_not_otherwise_classified': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_escape_from_chimney_fireplace': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_escape_woodstove': 'Smoke',
        'firesmokedamage-:-fsd_Fire-:-fs_f_smoking_cigarette_or_other': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_smoking_cigarette_or_other': 'Smoke',
        'firesmokedamage-:-fsd_Fire-:-fs_f_spontaneous_combustion': 'Fire',
        'firesmokedamage-:-fsd_Explosion-:-fs_explosion_terrorist_attack': 'Explosion',
        'firesmokedamage-:-fsd_Fire-:-fs_f_undetermined': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_wild_fire': 'Fire',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_wild_fire': 'Smoke',
        'glassdamage-:-gd_Glass-:-gd_glass_breakage': 'Glass',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_fallen_tree_branch': 'Wind',
        'hailwindstormdamage-:-hwd_Hail-:-hws_h_hail': 'Hail',
        'hailwindstormdamage-:-hwd_Hail-:-hws_h_hail_wind': 'Hail',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_hurricane_tropical_storm': 'Wind',
        'hailwindstormdamage-:-hwd_ice_snow-:-hwd_ice_snow_storm': 'Ice/Snow',
        'hailwindstormdamage-:-hwd_ice_snow-:-hwd_ice_snow_blizzard': 'Ice/Snow',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_tornado': 'Wind',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_wind': 'Wind',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_all_other_losses': 'Property Loss or Damage',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_breakdown_corrosion_oil_tank': 'Oil Tank',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_damage_caused_by_bears': 'Damage Caused by Bears',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_damage_cause_by_pets': 'Damage Caused by Pets',
        'otherlossdamages-:-a_AllOthers_equipment-:-a_allothers_equipment_electronic_impairment': 'Equipment Breakdown',
        'otherlossdamages-:-a_AllOthers_equipment-:-a_AllOthers_equipment_breakdown': 'Equipment Breakdown',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_food_freezer_spoilage': 'Food Freezer Spoilage',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_oil_tank_neighboring': 'Oil Tank',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_oil_tanks_other_losses': 'Oil Tank',
        'otherlossdamages-:-a_AllOthers-:-pl_propertydamage_oil_tanks_all_other_losses': 'Oil Tank',
        'otherlossdamages-:-a_AllOthers-:-a_allothers_service_line_failure': 'Service Line Failure',
        'otherlossdamages-:-a_AllOthers_personalcyber-:-a_allothers_personalcyber_cyberattack': 'Cyber Attack',
        'otherlossdamages-:-a_AllOthers_personalcyber-:-a_allothers_personalcyber_cyberextortion': 'Cyber Attack',
        'otherlossdamages-:-a_AllOthers_personalcyber-:-a_allothers_personalcyber_other': 'Cyber Attack',
        'otherlossdamages-:-a_AllOthers_personalcyber-:-a_allothers_personalcyber_theft_information': 'Cyber Attack',
        'otherlossdamages-:-a_AllOthers_personalcyber-:-a_allothers_personalcyber_personal_information': 'Cyber Attack',
        'personalliability-:-pl_BodilyInjury-:-pl_bodilyinjury_dog_bite': 'Injury',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_vehicle_impact': 'Property Damage',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_farm_vehicle_impact': 'Property Damage',
        'personalliability-:-pl_BodilyInjury-:-pl_bodilyinjury_inadequate_storage': 'Injury',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_inadequate_storage': 'Property Damage',
        'personalliability-:-pl_BodilyInjury-:-pl_bodilyinjury_negligent_act': 'Injury',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_other_act': 'Property Damage',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_oil_tanks_all_other_losses': 'Property Damage',
        'personalliability-:-pl_BodilyInjury-:-pl_bodilyinjury_failure_equipment': 'Injury',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_failure_equipment': 'Property Damage',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_pollution_other': 'Property Damage',
        'personalliability-:-pl_BodilyInjury-:-pl_bodilyinjury_resulting_from_product': 'Injury',
        'personalliability-:-pl_PropertyDamage-:-pl_propertydamage_resulting_from_product': 'Property Damage',
        'personalliability-:-pl_BodilyInjury-:-pl_bodilyinjury_slip_fall': 'Injury',
        'theftvandalismriotdamage-:-tvr_BR-:-tvr_br_burglary': 'Break & Enter',
        'theftvandalismriotdamage-:-tvr_RiotVandalismMaliciousActs-:-tvr_rwma_civil_disturbance': 'Riot, Vandalism or Malicious Acts',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_identify_theft': 'Theft',
        'theftvandalismriotdamage-:-tvr_MysteriousDisappearance-:-tvr_md_misplaced_articles': 'Lost or Misplaced Items',
        'theftvandalismriotdamage-:-tvr_BR-:-tvr_br_robbery_bodily_harm': 'Robbery',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_theft': 'Theft',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_theft_employee': 'Theft',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_theft_tenant': 'Theft',
        'theftvandalismriotdamage-:-tvr_RiotVandalismMaliciousActs-:-tvr_rwma_vandalism': 'Riot, Vandalism or Malicious Acts',
        'theftvandalismriotdamage-:-tvr_RiotVandalismMaliciousActs-:-tvr_rwma_vandalism_by_tenant': 'Riot, Vandalism or Malicious Acts',
        'transportationdamage-:-td_Transportation-:-td_transportation_by_insured': 'Transportation',
        'transportationdamage-:-td_Transportation-:-td_transportation_third_party': 'Transportation',
        'transportationdamage-:-td_Transportation-:-td_transportation_incident_carrier_land_water': 'Transportation',
        'waterdamage-:-wd_AboveGroundWater-:-wd_agw_not_otherwise_classified': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_overflow_appliance_fixture': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_overflow_swimming_pool': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_freezing_plumbing': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_domestic_water_container': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_neighboring_premises': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_from_plumbing_heating_aircond': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_watermain': 'Water',
        'waterdamage-:-wd_BelowGroundWater-:-wd_bgw_groundwater_infiltration': 'Water',
        'waterdamage-:-wd_IceDam-:-wd_ice_dam': 'Ice Dam',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_not_otherwise_classified': 'Water',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_from_fresh_body_water_fluvial': 'Water',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_from_salt_body_water_fluvial': 'Water',
        'waterdamage-:-wd_Sewer-:-wd_sewer_septic_system_backup': 'Sewer',
        'waterdamage-:-wd_Sewer-:-wd_sewer_undetermined': 'Sewer',
        'waterdamage-:-wd_Sewer-:-wd_sewer_backup': 'Sewer',
        'waterdamage-:-wd_SurfaceWaterPluvial-:-wd_sw_surface_water_pluvial': 'Water',
        'waterdamage-:-wd_AllOther-:-wd_all_other': 'Water',
        'waterdamage-:-wd_AboveGroundWater-:-wd_agw_water_through_walls_doors_windows': 'Water',
        'waterdamage-:-wd_AboveGroundWater-:-wd_agw_water_through_roof_eavestrough_downspout': 'Water',
        'waterdamage-:-wd_WaterEscape-:-wd_escape_not_otherwise_classified': 'Water'
    };

    const value = lookupTable[lookupKey];

    return value;
};

/*
 * AMPP-673: The detailed claim type will be a value defined on the Claim Events tab;
 * it will be determined by the combination of Loss Cause, Cause Description and Event.
 *
 * List of Loss Cause, Cause Description and Event combinations is the same as for AMPP-669
 * which is implemented in autoClaimTypeDisplayName, the only difference are mapped values
 * for each combination.
 *
 */
const autoDetailClaimTypeDisplayName = (claimLossCause, claimCauseDescription, claimEvent) => {
    const lookupKey = `${claimLossCause}-:-${claimCauseDescription}-:-${claimEvent}`;

    const lookupTable = {
        'earthquakedamage-:-ed_Earthquake-:-ed_e_earthquake': 'Earthquake',
        'earthquakedamage-:-ed_Earthquake-:-ed_e_fire_following_earthquake': 'Fire Following Earthquake',
        'earthquakedamage-:-ed_Earthquake-:-ed_e_event_landslide_snowslide_earthquake': 'Landslide/Snowslide following Earthquake',
        'firesmokedamage-:-fsd_Explosion-:-fs_explosion_explosion': 'Explosion',
        'firesmokedamage-:-fsd_Explosion-:-fs_explosion_terrorist_attack': 'Explosion from Terrorism or Act of War',
        'firesmokedamage-:-fsd_Fire-:-fs_f_appliance': 'Appliance Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_arson_suspected': 'Arson or Suspected Arson',
        'firesmokedamage-:-fsd_Fire-:-fs_f_bonfire_pit': 'Bonfire/Fire Pit (Ember/Ash)',
        'firesmokedamage-:-fsd_Fire-:-fs_f_cooking_incident': 'Cooking incident',
        'firesmokedamage-:-fsd_Fire-:-fs_f_electrical_fire': 'Electrical Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_not_otherwise_classified': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_storage_chemicals_fuels': 'Storage or Handling of Chemicals/Fuels',
        'firesmokedamage-:-fsd_Fire-:-fs_f_neighboring_premises': 'Fire From Neighboring Premises',
        'firesmokedamage-:-fsd_Fire-:-fs_f_heating_device': 'Fire From Heating Device',
        'firesmokedamage-:-fsd_Fire-:-fs_f_smoking_cigarette_or_other': 'Smoking (cigarette or other)',
        'firesmokedamage-:-fsd_Fire-:-fs_f_undetermined': 'Fire',
        'firesmokedamage-:-fsd_Fire-:-fs_f_wild_fire': 'Wild Fire',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_fallen_tree_branch': 'Fallen Tree/Branch ',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_fire_from': 'Fire From Lightning ',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_not_otherwise_classified': 'Lightning ',
        'firesmokedamage-:-fsd_Lightning-:-fs_lightning_power_surge': 'Power Surge ',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_appliance': 'Smoke from Appliance',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_arson_suspected_arson': 'Arson or Suspected Arson',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_bonfire_pit': 'Bonfire/Fire Pit (Ember/Ash)',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_smudging': 'Controlled Burn or Smudging',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_cooking_incident': 'Cooking incident',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_neighboring_premises': 'Smoke From Neighboring Premises',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_heating_device': 'Smoke From Heating Device',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_not_otherwise_classified': 'Smoke',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_smoking_cigarette_or_other': 'Smoking (cigarette or other)',
        'firesmokedamage-:-fsd_Smoke-:-fs_s_wild_fire': 'Wild Fire',
        'glassdamage-:-gd_Glass-:-gd_glass_breakage': 'Glass Breakage',
        'glassdamage-:-gd_Glass-:-gd_stone_chip': 'Stone Chip',
        'hailwindstormdamage-:-hwd_Hail-:-hws_h_hail': 'Hail',
        'hailwindstormdamage-:-hwd_Hail-:-hws_h_hail_wind': 'Hail & Wind',
        'hailwindstormdamage-:-hwd_ice_snow-:-hwd_ice_snow_storm': 'Ice Storm',
        'hailwindstormdamage-:-hwd_ice_snow-:-hwd_ice_snow_blizzard': 'Snow Storm/Blizzard',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_fallen_tree_branch': 'Fallen Tree/Branch',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_hurricane_tropical_storm': 'Hurricane/Tropical Storm',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_tornado': 'Tornado',
        'hailwindstormdamage-:-hwd_Wind-:-hws_w_wind': 'Wind',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_boost': 'Boost',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_flat_tire': 'Flat Tire',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_lock_out': 'Lock Out Services',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_not_otherwise_classified': 'Roadside Assistance',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_tow': 'Tow',
        'roadsideassistance-:-rsa_roadsideassistance-:-rsa_roadsideassistance_winch': 'Winch',
        'theftvandalismriotdamage-:-tvr_RiotVandalismMaliciousActs-:-tvr_rwma_civil_disturbance': 'Civil Disturbance/Riot',
        'theftvandalismriotdamage-:-tvr_RiotVandalismMaliciousActs-:-tvr_rwma_vandalism': 'Vandalism or Malicious Acts',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_theft_entire_vehicle': 'Theft',
        'theftvandalismriotdamage-:-tvr_Theft-:-tvr_t_theft_attemptedtheft_vehicle': 'Theft',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_building': 'Collision with Building or Structure',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_cyclist': 'Collision with Cyclist',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_pedestrian': 'Collision with Pedestrian',
        'physicaldamage-:-pd_Collision-:-pd_collision_with_animal': 'Collision with Wildlife or Domestic Animal',
        'physicaldamage-:-pd_Collision-:-pd_collision_hit_and_run': 'Hit and Run',
        'physicaldamage-:-pd_Collision-:-pd_collision_multi_vehicle_upset_2_more': 'Multi-Vehicle Collision',
        'physicaldamage-:-pd_Collision-:-pd_collision_single_vehicle': 'Single Vehicle Collision',
        'physicaldamage-:-pd_NonCollision-:-pd_noncollision_comp_not_otherwise_classified': 'Non-Collision Damage',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_not_otherwise_classified': 'Overflow from Body of Water',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_from_fresh_body_water_fluvial': 'Overflow from Body of Water',
        'waterdamage-:-wd_OverflowFromBodyWaterFluvial-:-wd_overflow_from_salt_body_water_fluvial': 'Overflow from Body of Water',
        'waterdamage-:-wd_SurfaceWaterPluvial-:-wd_sw_surface_water_pluvial': 'Surface Water',
        'waterdamage-:-wd_AllOther-:-wd_all_other': 'Water'
    };

    const value = lookupTable[lookupKey];

    return value;
};

const lineOfBusinessDisplayName = (code) => {
    switch (code) {
        case 'PersonalAutoLine_WMIC':
            return 'Auto';

        case 'HomeownersLine_WMIC':
            return 'Homeowners';

        default:
    }

    return code;
};

const classNameForLOB = (lobCode) => {
    switch (lobCode) {
        case 'PersonalAutoLine_WMIC':
            return 'fa-car';

        case 'HomeownersLine_WMIC':
            return 'fa-home';

        default:
    }

    return lobCode;
};

const claimStatusDisplayName = (claimSummaryDTOViewModel, translator, state) => {
    const value = _.find(claimSummaryDTOViewModel.claimState.aspects.availableValues, {
        code: state
    });

    if (value) {
        return translator({ id: value.name });
    }

    return state;
};

const perilGroupCategoryDisplayName = (claimSummaryDTOViewModel, translator, perilGroupCategory) => {
    const value = _.find(claimSummaryDTOViewModel.perilGroupCategory_WMIC.aspects.availableValues, {
        code: perilGroupCategory
    });
    if (value) {
        return translator({ id: value.name });
    }

    return perilGroupCategory;
};

const perilGroupEventDisplayName = (claimSummaryDTOViewModel, translator, perilGroupEvent) => {
    const value = _.find(claimSummaryDTOViewModel.perilGroupEvent_WMIC.aspects.availableValues, {
        code: perilGroupEvent
    });
    if (value) {
        return translator({ id: value.name });
    }

    return perilGroupEvent;
};

const claimTypeOfLossDisplayName = (claimSummaryDTOViewModel, translator, lossTypeCode) => {
    const value = _.find(claimSummaryDTOViewModel.aIALossType_wmic.aspects.availableValues, {
        code: lossTypeCode
    });
    if (value) {
        return translator({ id: value.name });
    }

    return lossTypeCode;
};

export default {
    retrieveClaimStates: retrieveClaimStates,
    lineOfBusinessDisplayName: lineOfBusinessDisplayName,
    claimStatusDisplayName: claimStatusDisplayName,
    classNameForLOB: classNameForLOB,
    perilGroupCategoryDisplayName: perilGroupCategoryDisplayName,
    perilGroupEventDisplayName: perilGroupEventDisplayName,
    claimTypeOfLossDisplayName: claimTypeOfLossDisplayName,
    autoClaimTypeDisplayName: autoClaimTypeDisplayName,
    homeClaimTypeDisplayName: homeClaimTypeDisplayName,
    autoDetailClaimTypeDisplayName: autoDetailClaimTypeDisplayName
};