import React, {
    useEffect,
    useState,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTracking } from './hooks';

const MONITORED_EVENTS = ['click', 'mousemove', 'scroll', 'keydown'];

function InactivityTracker({ onActivityChange, inactivityAllowedSecs }) {
    const [lastInteraction, setLastInteraction] = useState(Date.now());
    const [isActive, setIsActive] = useState(true);

    // the function below should only be created once,
    // however the `throttle` will always return a new function,
    // so useCallback will still improve the efficiency in this case
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const trackInteraction = useCallback(_.throttle(() => {
        setLastInteraction(Date.now());
    }, 500), []);

    useTracking({
        events: MONITORED_EVENTS,
        trackingFunction: trackInteraction,
    });

    useEffect(() => {
        onActivityChange({ isActive });
    }, [isActive, onActivityChange]);

    const setUserInactive = useCallback(() => {
        setIsActive(false);
    }, []);

    useEffect(() => {
        setIsActive(true);
        // every time we have an interaction we register the user is active
    }, [lastInteraction]);

    useEffect(() => {
        if (inactivityAllowedSecs) {
            const userActivityTimeout = setTimeout(setUserInactive, inactivityAllowedSecs * 1000);
            return () => {
                clearTimeout(userActivityTimeout);
            };
        }
        
    }, [
        inactivityAllowedSecs,
        // every time we have an interaction we register the user is active
        lastInteraction,
        setUserInactive
    ]);

    return <React.Fragment />;
}

InactivityTracker.propTypes = {
    onActivityChange: PropTypes.func.isRequired,
    inactivityAllowedSecs: PropTypes.number
};

InactivityTracker.defaultProps = {
    inactivityAllowedSecs: 60 * 60 // 1 hour
};

export default InactivityTracker;
