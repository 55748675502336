import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WMICRenewalChangeSummaryPage from './pages/ChangeSummary/WMICRenewalChangeSummaryPage';
import WMICPolicyRenewalContactDetailsPage from './pages/ContactDetails/WMICPolicyRenewalContactDetailsPage';
import WMICPolicyRenewalQuote from "./pages/Quote/WMICPolicyRenewalQuote";

setComponentMapOverrides(
    {
        WMICRenewalChangeSummaryPage: { component: 'WMICRenewalChangeSummaryPage' },
        WMICPolicyRenewalContactDetailsPage: { component: 'WMICPolicyRenewalContactDetailsPage' },
        WMICPolicyRenewalQuote: { component: 'WMICPolicyRenewalQuote'}
    },
    {
        WMICPolicyRenewalContactDetailsPage,
        WMICRenewalChangeSummaryPage,
        WMICPolicyRenewalQuote
    }
);

export { default as WMICRenewalChangeSummaryPage } from './pages/ChangeSummary/WMICRenewalChangeSummaryPage';
export { default as WMICPolicyRenewalQuote } from "./pages/Quote/WMICPolicyRenewalQuote";
export { default as messages } from './PolicyRenewalCommon.messages';
export { default as WMICLapseRenewalModal } from './components/WMICLapseRenewalModal/WMICLapseRenewalModal';
export { default as WMICRevertLapseModal } from './components/WMICRevertLapseModal/WMICRevertLapseModal';
