/* eslint-disable no-secrets/no-secrets */
import React, { useState, useContext, useEffect }from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS, ACTION_TYPES } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import WMICPUPVehicleExposureForm from './WMICPUPVehicleExposureForm/WMICPUPVehicleExposureForm';
import metadata from './WMICPUPVehicleExposures.metadata.json5';
import messages from './WMICPUPVehicleExposures.messages.js';

function WMICPUPVehicleExposures(props) {
    const {
        id,
        jobVM,
        exposuresVM,
        isUnderEditing,
        updateExposuresVM,
        readOnly,
        showErrorsParent,
    } = props;

    const UNDERLYING_POLICY_PATH = 'lobData.personalUmbrella.coverables.underlyingPolicies';

    const viewModelService = useContext(ViewModelServiceContext);
    const [excessPolicyValue, setExcessPolicyValue] = useState(null);
    const [excessPolicyAvailableValues, setExcessPolicyAvailableValues] =useState([]);
    const [exposureVM, setExposureVM] = useState();
    const [exposureIndex, setExposureIndex] = useState(-1);
    const [showErrors, setShowErrors] = useState(false);
    const [backupExposure, setBackupExposure] = useState();
    const [exposureAction, setExposureAction] = useState(ACTION_TYPES.NONE);
    const { showConfirm } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const { isComponentValid, onValidate } = useValidation(id);

    const cancelExposure = () => {
        if (exposureAction === ACTION_TYPES.ADD) {
            setExposureVM(undefined);
        } else {
            _.set(exposuresVM, `value.[${exposureIndex}].vehicleExposure`, backupExposure);
            updateExposuresVM(exposuresVM);

            _.set(exposureVM, 'value', backupExposure);
            setExposureVM(exposureVM);
        }

        setExcessPolicyValue(null);
        setExposureAction(ACTION_TYPES.NONE);
        setShowErrors(false);
    };

    const saveExposure = () => {
        const vehicleExposure = {
            vehicleNumber: exposuresVM.children.length,
            ...exposureVM.value
        };
        if (isComponentValid) {
            if(exposureAction === ACTION_TYPES.ADD) {
                exposuresVM.value.push({ vehicleExposure });
            } else {
                _.set(exposuresVM, `value.[${exposureIndex}].vehicleExposure`, exposureVM.value);
                updateExposuresVM(exposuresVM);
            };

            updateExposuresVM(exposuresVM);
            setExposureVM(undefined);
            setExcessPolicyValue(null);
            setExposureAction(ACTION_TYPES.NONE);
        } else {
            setShowErrors(true);
        }
    };

    const removeExposure = async (exposure, index) => {
        const response = await showConfirm({
            title: translator(messages.wmicUnderlyingPoliciesRemoveVehicleExposure),
            message: translator(messages.wmicUnderlyingPoliciesRemoveVehicleExposureConfirmationMsg,
                {vehicleNumber: _.get(exposuresVM, `value[${index}].vehicleExposure.vehicleNumber`)})
        })
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            if(exposureIndex === index) {
                setExposureVM(undefined);
            }
            exposuresVM.value.splice(index, 1);
            updateExposuresVM(exposuresVM);
            setShowErrors(false);
        }
    };

    const getExcessPolicyValuesIfApplicable = () => {
        const underLyingPoliciesList = _.get(jobVM, `${UNDERLYING_POLICY_PATH}.children`, []);
        const excessPolicyDropdownAvailableValues = [];
        const noExcessPolicySelected = {
            code: '0',
            displayName: messages.noExcessPolicyDisplayText.defaultMessage
        }
        excessPolicyDropdownAvailableValues.push(noExcessPolicySelected);
        if (underLyingPoliciesList.length > 0) {
            underLyingPoliciesList.forEach(policy => {
                if (policy.isExcessPolicy.value === true) {
                    const excessPolicyDropdownValue = {
                        code: policy.publicID.value,
                        displayName: `${policy.policyNumber.value} - ${policy.carrierNameDisplay.value}`,
                    };
                    excessPolicyDropdownAvailableValues.push(
                        excessPolicyDropdownValue
                    );
                }
            });
        }
        setExcessPolicyAvailableValues(excessPolicyDropdownAvailableValues);
        return null;
    }

    useEffect(() => {
        getExcessPolicyValuesIfApplicable()
    }, []);

    const onExcessPolicyChange = (value) => {
        _.set(exposureVM, 'underlyingPolicyExcessPublicID', value);
        setExcessPolicyValue(value);
        setExposureVM(exposureVM);
    }

    const selectExposure = (index, edit) => {
        const exposure = exposuresVM.children[index].vehicleExposure

        if(edit) {
            setExposureAction(ACTION_TYPES.EDIT);
            setBackupExposure(_.cloneDeep(exposure.value));
        }

        setExposureIndex(index);
        setExposureVM(exposure);
    };

    const addExposure = () => {
        const exposure = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.exposure.VehicleExposureDTO_WMIC',
            jobVM.aspects.context()
        );

        setExposureAction(ACTION_TYPES.ADD);
        setExposureVM(exposure);
        getExcessPolicyValuesIfApplicable();
    };

    const getExcessPolicyDisplayText = exposure => {
        let displayName = messages.noExcessPolicyDisplayText;
        if (excessPolicyAvailableValues.length > 0) {
            excessPolicyAvailableValues.forEach(policy => {
                if (
                    policy.code ===
                    exposure.vehicleExposure?.underlyingPolicyExcessPublicID
                ) {
                    displayName = policy.displayName;
                }
            });
        }
        return displayName;
    };

    const overrideProps =  {
        '@field':{
            parentNode: exposuresVM,
            readOnly
        },
        vehicleExposureForm: {
            visible: !_.isUndefined(exposureVM),
            exposureVM,
            cancelExposure,
            saveExposure,
            setExposureVM,
            isEditing:
                exposureAction === ACTION_TYPES.ADD ||
                exposureAction === ACTION_TYPES.EDIT,
            isParentUnderEditing: isUnderEditing,
            excessPolicyValue,
            excessPolicyAvailableValues,
            onValidate,
            showErrors: showErrors || showErrorsParent,
            onExcessPolicyChange,
        },
        vehicleExposuresDataList: {
            VMList: _.get(exposuresVM, 'children', []),
            VMData: [
                {
                    headerText: translator(messages.vehicleTypeDisplayHeading),
                    path: 'vehicleExposure.vehicleType'
                },
                {
                    headerText: translator(messages.vehicleExcessPolicy),
                    getData: (exposure) => translator(getExcessPolicyDisplayText(exposure.value))
                }
            ],
            onEditAction: (exposure, index) => selectExposure(index, true),
            onRemoveAction: removeExposure,
            updateSelectedCardIndex: (index) => selectExposure(index),
            selectedCardIndex: exposureIndex,
            isEditing:
                exposureAction === ACTION_TYPES.ADD ||
                exposureAction === ACTION_TYPES.EDIT,
            readOnly: !isUnderEditing
        },
        addVehicleButton: {
            visible: !readOnly
        }
    }
    const resolvers = {
        resolveCallbackMap: {
            addExposure,
        },
        resolveComponentMap: {
            WMICPUPVehicleExposureForm
        }
    };
    return (
        <ViewModelForm
            model={exposuresVM}
            uiProps={metadata.componentContent}
            onValidationChange={onValidate}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            showErrors={showErrors || showErrorsParent}
        />
    );
}

WMICPUPVehicleExposures.propTypes = {
    exposures: PropTypes.array.isRequired,
    isUnderEditing: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    jurisdiction: PropTypes.string.isRequired,
    underlyingPolicies: PropTypes.array.isRequired
};

WMICPUPVehicleExposures.defaultProps = {
};

export default WMICPUPVehicleExposures;
