/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { WMICPremiumBox } from 'gw-capability-quoteandbind-common-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WMICQuoteNumberComponent } from 'gw-portals-wizard-components-ui';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import styles from './WMICWizardSidebarQnB.module.scss';
import { onPageJump, isRecurringPaymentDetailsHidden } from './WMICSidebarNavigationUtil'
/**
 * @typedef {import('gw-portals-edge-validation-js/errors').GenericUIIssue} GenericUIIssue
 */

/**
 * Renders an icon for steps with errors
 * @param {Array<GenericUIIssue>} errorsAndWarnings array
 * @returns {Object}
 */
function renderErrors(errorsAndWarnings = []) {
    const highestLevel = errorsAndWarnings
        .map((err) => err.level)
        .reduce((currentHighest, currentError) => {
            if (!currentHighest) {
                return currentError;
            }
            if (currentHighest === ErrorLevel.LEVEL_WARN) {
                // currentError can only Greater or Equal than currentHighest
                return currentError;
            }
            return currentHighest;
        }, null);
    switch (highestLevel) {
        case ErrorLevel.LEVEL_ERROR:
            return <Icon icon="mi-remove_circle_outline" className={styles.errorIssue} />;
        case ErrorLevel.LEVEL_WARN:
            return <Icon icon="mi-warning" className={styles.warningIssue} />;
        default:
            return null;
    }
}

function getStepIcon(step, index, currentStepIndex) {
    if (step.visited && index < currentStepIndex) {
        return ('fa fa-li fa-check-circle');
    }
    return ('fa fa-li fa-circle-thin');
}

function renderSteps(
    translator,
    steps,
    currentStepIndex,
    stepsWithErrors,
    wizardContext,
    viewModelService
) {
    return steps.map((step, index) => {
        const isStepHidden = step.hideFromSidebar || isRecurringPaymentDetailsHidden(step, steps[currentStepIndex]);
        const liClassName = classNames('', {
            current: index === currentStepIndex,
            visited: ( step.visited && index <= currentStepIndex ) || index === currentStepIndex,
            'not-visited': !step.visited || index > currentStepIndex,
            'element-hidden': isStepHidden
        });
        return (
            <li className={liClassName} key={step.id} onClick={() => onPageJump(index, wizardContext, viewModelService)}>
                <span>
                    <i className="fa fa-li fa-circle" />
                    <i className={getStepIcon(step, index, currentStepIndex)} />
                </span>
                <button
                    className={styles.navigationButton}
                    disabled={!step.visited}
                    type="button"
                >
                    {translator(step.title)}
                    {renderErrors(stepsWithErrors[step.id])}
                </button>
            </li>
        );
    });
}

function WMICWizardSidebarQnB(props) {
    const wizardContext = useContext(WizardContext);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const getMonthlyAmount =  _.get(wizardContext.wizardData.value, 'quoteData.offeredQuotes.0.premium.monthlyPremium');
    const getFullAmount = _.get(wizardContext.wizardData.value, 'quoteData.offeredQuotes.0.premium.total');
    const {
        steps, currentStepIndex, stepsWithErrors, wizardTitle
    } = wizardContext;

    const {
        quoteStatus,
        recalculate,
        validCoverages
    } = props;
    const showPremiumBox = _.get(wizardContext, 'currentStep.id') === 'WMICQuotePage';

    return (
        <div>
            <WMICPremiumBox
                isStale={showPremiumBox && quoteStatus.isStale}
                showLoading={showPremiumBox && quoteStatus.showLoading}
                displayed={showPremiumBox && quoteStatus.displayed}
                recalculate={recalculate}
                monthlyAmount={getMonthlyAmount}
                fullAmount={getFullAmount}
                validCoverages={validCoverages}
            />
            <div className="wizard-sidebar-steps-wrapper quote-progress">
                <p className="section-title">{translator(wizardTitle)}</p>
                <WMICQuoteNumberComponent />
                <hr className="wmicSidebarSeparator" />
                <div className="wizard-sidebar-steps" role="navigation" aria-label={translator(wizardTitle)}>
                    <ul className="steps fa-ul">
                        {renderSteps(
                            translator,
                            steps,
                            currentStepIndex,
                            stepsWithErrors,
                            wizardContext,
                            viewModelService
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

WMICWizardSidebarQnB.propTypes = {
    customMessageModal: PropTypes.shape({}),
    quoteStatus: {
        calculatingQuote: PropTypes.bool,
        showLoading: PropTypes.bool,
        displayed: PropTypes.bool,
        isStale: PropTypes.bool,
        isEmailQuoteButtonDisabled: PropTypes.bool
    },
    recalculate: PropTypes.func,
    validCoverages: PropTypes.bool
};
WMICWizardSidebarQnB.defaultProps = {
    customMessageModal: {},
    quoteStatus: {
        calculatingQuote: false,
        showLoading: false,
        displayed: true,
        isStale: false,
        isEmailQuoteButtonDisabled: false
    },
    recalculate: () => { }
};
export default WMICWizardSidebarQnB;