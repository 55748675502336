import React, { useState } from 'react';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';

function WMICUpdateFinanceFormModal(props) {
    const {
        title,
        subtitle,
        primaryButtonMessage,
        secondaryButtonMessage,
        onPrimaryAction,
        isOpen,
        onReject,
        onResolve
    } = props;
    const translator = useTranslator();

    const handleClose = () => {
        onReject();
    };
    
    return (
        <ModalNext 
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc>
            <ModalHeader
                title={title}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: 'true',
                    }
                }}
            />
            <ModalBody>
                <div>
                    <p>
                        {subtitle}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject() }}
                    type="outlined"
                >
                    {translator(secondaryButtonMessage)}
                </WMICButton>
                <WMICButton
                    onClick={() => { onResolve(); onPrimaryAction(); }}
                    type="primary"
                >
                    {translator(primaryButtonMessage)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
}

WMICUpdateFinanceFormModal.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    primaryButtonMessage: PropTypes.string.isRequired,
    secondaryButtonMessage: PropTypes.string.isRequired,
    onPrimaryAction: PropTypes.func.isRequired
};


export default WMICUpdateFinanceFormModal;