/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { useTranslator } from '@jutro/locale';
import messages from './WMICCancelEditCoveragesModal.messages';
import styles from './WMICCancelEditCoveragesModal.module.scss';

const WMICCancelEditCoveragesModal = (props) => {
    const { onCancelEdit, onReject, onResolve, isOpen } = props;
    const translator = useTranslator();

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader
                title={translator(messages.areYouSure)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true
                    }
                }}
            />
            <ModalBody>
                <div>
                    <p className={styles['wmic-payment-text']}>
                        {translator(messages.ifYouLeaveNowAnyChangesYouveSelectedWillNotBeSaved)}
                        <br />
                        {translator(messages.areYouSureYouWishToCancel)}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onCancelEdit(); onResolve(); }}
                    type="outlined"
                >
                    {translator(messages.yesCancelChanges)}
                </WMICButton>
                <WMICButton
                    onClick={() => { 
                        onReject();
                    }}                    
                    type="primary"
                >
                    {translator(messages.noKeepEditing)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICCancelEditCoveragesModal.propTypes = {
    onCancelEdit: PropTypes.func.isRequired
};

export default WMICCancelEditCoveragesModal;
