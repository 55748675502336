import { EntityUtil } from '@xengage/gw-portals-util-js';
/**
 * Utilities used to deal with entity model in portals.
 *
 * Entity is an object representing some domain object on the backend size. Thus
 * such entity usually have public ID or other way to uniquely distinguish from all
 * other entities. New entities could have tempId property which is used to reference
 * new items contained in collections or inside other objects.
 */

export default {
    // Platform EntityUtil functions
    findMatchingEntity: EntityUtil.findMatchingEntity,
    nextId: EntityUtil.nextId
};
