import { defineMessages } from 'react-intl';

export default defineMessages({
    pniConsentReceived: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyInsuranceHistoryConsentComponent.PNI Credit Consent Received',
        defaultMessage: 'Primary Named Insured Credit Consent Received'
    },
    aniConsentReceived: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyInsuranceHistoryConsentComponent.ANI Credit Consent Received',
        defaultMessage: 'Additional Named Insured Credit Consent Received'
    },
});
