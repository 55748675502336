import React, { useCallback, useEffect, useState, useContext, useRef, useMemo } from 'react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardChangeOrRenewalPage, useAccordionValidation, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import { PAConstants, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    WMICDriverEndorsementsComponent,
    WMICPAVehicleCoverages,
    WMICPAPolicyCoverages
} from 'wmic-pe-capability-gateway-common-pa-react';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import _ from 'lodash';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import messages from './WMICPCPACoveragesPage.messages';
import metadata from './WMICPCPACoveragesPage.metadata.json5';

const structureCustomQuote = (policyChangeVM, affectedQuote, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: policyChangeVM.jobID.value,
        sessionUUID: policyChangeVM.sessionUUID.value,
        periodStart: policyChangeVM.baseData.periodStartDate.value,
        periodEnd: policyChangeVM.baseData.periodEndDate.value,
        coverages: clauses.personalAuto
    };
};

const generateColumnData = (policyChangeVM) => {
    const lobOfferingPath = 'lobData.personalAuto.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(policyChangeVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(policyChangeVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];
            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ quote }) => !_.isUndefined(quote.data));
    return _.sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

function WMICPCPACoveragesPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        underwritingIssues
    } = props;

    const translator = useContext(TranslatorContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { onValidate, disregardFieldValidation, initialValidation, isComponentValid } = useValidation("WMICPCPACoveragesPage");
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialPolicyChangeVM, setInitialPolicyChangeVM] = useState(undefined);
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState();
    const { setWizardLoading } = useWizardModals();
    const initialized = useRef(false);

    const canEditPolicyChange = useMemo(
        () => WMICUserAccessUtil.canEditPolicyChange(currentUser.roles),
        [currentUser]
    );

    useEffect(() => {
        setInitialPolicyChangeVM(viewModelService.clone(policyChangeVM));
        initialized.current = true;
        // Store the initial PolicyChangeVM when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(policyChangeVM, lobPath, quotePath, lobName, true);
            
            return EndorsementService.updateCoveragesAndPolicyChangeData([], { personalAuto: customQuote.coverages }, policyChangeVM.value, authHeader)
                .then((response) => {
                    const newPolicyChangeVM = viewModelService.clone(policyChangeVM);
                    newPolicyChangeVM.value = response;

                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        policyChangeVM,
                        newPolicyChangeVM,
                        `${lobPath}.coverages.lineCoverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        policyChangeVM,
                        newPolicyChangeVM,
                        `${lobPath}.coverages.vehicleCoverages`
                    );
                    const removedFieldsFromDriverEndorsementCoverages = ClausesUtil.getRemovedClausesID(
                        policyChangeVM,
                        newPolicyChangeVM,
                        `${lobPath}.coverages.driverEndorsements_WMIC`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages,
                        ...removedFieldsFromDriverEndorsementCoverages
                    ];
                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(newPolicyChangeVM);
                }
                );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [policyChangeVM, EndorsementService, authHeader, viewModelService, disregardFieldValidation]
    );

    const syncCoverages = useCallback(
        (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, PAConstants.PACoveragesPaths.OFFERINGS_PATH, PAConstants.PACoveragesPaths.OFFERINGS_PATH);
        },
        [onClauseChange]
    );

    const changePolicyChange = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(policyChangeVM, value, changedPath));
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [policyChangeVM]
    );

    const changePolicyChangeAndSync = useCallback(
        (value, changedPath) => {
            changePolicyChange(value, changedPath);
            return syncCoverages(value, changedPath, PAConstants.PACoveragesPaths.OFFERINGS_PATH, PAConstants.PACoveragesPaths.OFFERINGS_PATH);
        },
        [changePolicyChange, syncCoverages]
    );

    const columnData = generateColumnData(policyChangeVM);

    // props for the accordions themselves
    const commonAccordionProps = {
    }

    // props for the accordion content components
    const commonAccordionContentProps = {
        baseData: _.get(policyChangeVM, 'baseData'),
        authHeader,
        policyChangeVM,
        updateWizardData,
        onClauseChange,
        syncCoverages,
        changeSubmissionAndSync: changePolicyChangeAndSync,
        onValidate: onValidateAccordion,
        readOnly: !canEditPolicyChange,
    };

    const getThirdPartyLiabilityWarning = () => {
        return [{
            id: "warningThirdPartyLiabilityContainer",
            type: "element",
            component: "div",
            content: [
                {
                    id: "exclamationcircleIcon",
                    type: "element",
                    component: "Icon",
                    componentProps: {
                        icon: "mi-error",
                        className: "exclamation-icon gw-mr-1 iconAlignment"
                    },
                },
                {
                    id: "warningThirdPartyLiabilityMessage",
                    type: "element",
                    component: "span",
                    content: {
                        id: "quoteandbind.pa.views.pa-coverage-term.Third Party Liability Applies to All vehicles",
                        defaultMessage: translator(messages.thirdPartyLiabilityWarning)
                    }
                }
            ]
        }];
    }

    const getCustomTerms = (clause) => {
        if (PAConstants.thirdPartyLiabililityLimitNames.includes(clause.publicID)) {
            return getThirdPartyLiabilityWarning();
        }
        return null;
    }

    const onStaleQuoteBranchCode = useCallback(() => {
        return staleQuoteBranchCode;
    }, [staleQuoteBranchCode]);

    const commonOverrides = {
        'coverageColumnData': columnData,
        'quoteID': policyChangeVM.jobID.value,
        'changeSubmissionAndSync': changePolicyChangeAndSync,
        'changeSubmission': changePolicyChange,
        'onClauseChange': onClauseChange,
        'syncCoverages': syncCoverages,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'onValidate': onValidate,
        'getCustomTerms': getCustomTerms,
        'readOnly': !canEditPolicyChange,
    }

    const getOrderedVehiclesList = () => {
        const vehicles = _.get(policyChangeVM, `${PAConstants.PACoveragesPaths.VEHICLES_PATH}.children`, [])
        return _.sortBy(vehicles, ['vehicleNumber_WMIC.value'])
    }

    const _iterableProps = () => {
        const driverEndorsements = _.get(policyChangeVM, `${PAConstants.PACoveragesPaths.OFFERINGS_PATH}.coverages.driverEndorsements_WMIC.value`);
        const driverEndorsementsForVehicle = driverEndorsements.filter((endorsement) => WMICPACoverageUtil.getIsVehicleDriverEndorsement(endorsement.publicID));
        const vehicleOverrides = getOrderedVehiclesList().map((vehicle, index) => {
            const vehicleCoverages = WMICPACoverageUtil.getVehicleCoveragesForRisk(_.get(policyChangeVM, 'lobData.personalAuto'), vehicle.publicID.value);
            const vehicleOverride = {
                [`vehicleCoveragesIterableComponent${index}`]: {
                    'vehicleVM': vehicle,
                    'vehicleIndex': index,
                    'baseData': policyChangeVM.baseData,
                    vehicleCoverages,
                    driverEndorsementsForVehicle,
                    jobVM: policyChangeVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, vehicleOverride);
        })

        return Object.assign({}, ...vehicleOverrides);
    }

    const overrideProps = {
        vehicleCoveragesIterableAccordionContainer: {
            data: getOrderedVehiclesList()
        },
        policyCoveragesContainer: {
            jobVM: policyChangeVM,
            lineCoverages: _.get(policyChangeVM, 'lobData.personalAuto.offerings.children[0].coverages.lineCoverages'),
            updateWizardData,
            ...commonOverrides
        },
        driverEndorsementsAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('driverEndorsementsComponent')
        },
        driverEndorsementsComponent: {
            accountHolder: _.get(policyChangeVM, 'baseData.accountHolder.value'),
            jobVM: policyChangeVM,
            ...commonAccordionContentProps,
        },
        ..._iterableProps()
    };

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true);
            const newPolicyChangeVM = _.cloneDeep(policyChangeVM.value);
            policyChangeVM.value = await EndorsementService.saveEndorsement(
                [newPolicyChangeVM],
                authHeader
            );

            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
    }, [EndorsementService, authHeader, isComponentValid, policyChangeVM, setWizardLoading]);

    const resolvers = {
        resolveComponentMap: {
            WMICDriverEndorsementsComponent,
            WMICPAVehicleCoverages,
            WMICPAPolicyCoverages,
            Accordion
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            skipWhen={initialValidation}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPCPACoveragesPage.propTypes = wizardProps;

export default withViewModelService(WMICPCPACoveragesPage);
