import _ from 'lodash';
import React, { useContext, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { WMICButton } from 'wmic-pe-components-platform-react';
import WMICDocumentUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICDocumentUtil';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { Flex } from '@jutro/layout';
import { InlineLoader } from "@jutro/components";

import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import styles from './WMICHeading.module.scss';
import { messages } from './WMICHeading.messages';

const WMICHeading = ({ title, underlined, bold, variant, size, className, isPrintablePage, isQuoteDownloadable, downloadQuoteDocument, color }) => {

    const translator = useContext(TranslatorContext);
    const headingSize = size || variant;
    const [isLoadingQuoteDocument, setIsLoadingQuoteDocument] = useState(false);
    const { authHeader } = useAuthentication();
    const { showError } = useWizardModals();

    const getTitle = () => {
        if (typeof title === 'string') {
            return title;
        }
        if (typeof title === 'object') {
            return translator(title)
        }
        return '';
    };

    const containerClassName = cx(styles.printablePageHeader,styles.headingUnderlined, 'gw-pb-1')

    const headingClassName = cx(
        styles.wmicHeading,
        className,
        {
            [styles.headingUnderlined]: underlined,
            [styles.headingBold]: bold,
            [styles.headingRegular]: !bold,
            [styles.h1Heading]: headingSize === 'h1',
            [styles.h2Heading]: headingSize === 'h2',
            [styles.h3Heading]: headingSize === 'h3',
            [styles.h4Heading]: headingSize === 'h4',
            [styles.colorAlert]: color === 'alert',
        },
    );

    const printPage = () => {
        window.print();
    }

    const createDownloadLinkForDocument = (doc) => {
        const url = doc?.link;
        if (url) {
            const linkElement = document.createElement("a");
            linkElement.href = url;
            linkElement.target = "_blank";
            linkElement.click();
            setIsLoadingQuoteDocument(false);
        } else {
            throw new Error();
        }
    }

    const showDocGenerationErrorModal = () => {
        showError({
            title: translator(messages.documentGenerationError),
            message: translator(messages.documentFailed)
        })
    };

    const downloadDocument = (doc) => {
        WMICDocumentUtil.documentReadyForDownload(doc, authHeader).then((result) => {
            if (result === true) {
                try {
                    createDownloadLinkForDocument(doc);    
                } catch (error) {
                    showDocGenerationErrorModal();
                }
            } else {
                showDocGenerationErrorModal();
            }
        }).catch(() => {
            showDocGenerationErrorModal();
        }).finally(() => {
            setIsLoadingQuoteDocument(false);
        });
    }

    const callDownloadQuoteDocument = (e) => {
        setIsLoadingQuoteDocument(true);
        e.preventDefault();
        downloadQuoteDocument()
            .then((doc) => {
                if (doc && doc.link) {
                    downloadDocument(doc);
                } else {
                    setIsLoadingQuoteDocument(false);
                    showDocGenerationErrorModal();
                }
            }).catch(() => {
                setIsLoadingQuoteDocument(false);
                showDocGenerationErrorModal();
            });
    };

    function renderLoader(isLoading) {
        return isLoading ? <InlineLoader loading={isLoading} /> : null;
    }

    const getHeadingVariant = () => {
        switch (variant) {
            case 'h1':
                return <h1 className={headingClassName}>{getTitle()}</h1>
            case 'h2':
                return <h2 className={headingClassName}>{getTitle()}</h2>
            case 'h3':
                return <h3 className={headingClassName}>{getTitle()}</h3>
            case 'h4':
                return <h4 className={headingClassName}>{getTitle()}</h4>
            default:
                return null;
        }
    }

    let printBtn;
    let downloadQuoteBtn;
    if (isPrintablePage) {
        printBtn = <WMICButton icon="mi-print" className="noPrint" onClick={printPage}>{translator(messages.print)}</WMICButton>
    }
    if (isQuoteDownloadable) {
        downloadQuoteBtn = <WMICButton icon="gw-file-download" className="noPrint" onClick={callDownloadQuoteDocument} disabled={isLoadingQuoteDocument}>
            {translator(messages.downloadFullQuote)}
            {renderLoader(isLoadingQuoteDocument)}
        </WMICButton>
    }

    return (printBtn || downloadQuoteBtn)
        ? <Flex justifyContent="between" className={containerClassName}>
                {getHeadingVariant()}
                <React.Fragment>
                    {printBtn}
                    {downloadQuoteBtn}
                </React.Fragment>
            </Flex>
        : getHeadingVariant();
};

WMICHeading.propTypes = {
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
    size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4',]),
    isPrintablePage: PropTypes.bool,
    isQuoteDownloadable: PropTypes.bool,
    downloadQuoteDocument: PropTypes.func,
    color: PropTypes.oneOf(['alert']),
}

WMICHeading.defaultProps = {
    bold: true,
    underlined: false,
    variant: 'h3',
    isPrintablePage: false,
    isQuoteDownloadable: false,
    downloadQuoteDocument: undefined,
    color: undefined,
}

export default WMICHeading;
