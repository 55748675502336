import { defineMessages } from 'react-intl';

export default defineMessages({
    editBankAccount: {
        id: 'payment.views.manage-banking-information.Edit Bank Account',
        defaultMessage: 'Edit Bank Account'
    },
    bankAccountSaved: {
        id: 'payment.views.manage-banking-information.Bank Account Saved',
        defaultMessage: 'Bank Account Saved'
    },
    thereWasAnErrorTitle: {
        id: 'payment.views.manage-banking-information.There was an error Title',
        defaultMessage: 'There was an error'
    },
    invalidRoutingTransitNumber: {
        id: 'payment.views.manage-banking-information.Invalid Routing Transit Number',
        defaultMessage: 'Invalid Routing Transit Number'
    },
    deleteBankAccount: {
        id: 'payment.views.manage-banking-information.Delete Bank Account?',
        defaultMessage: 'Delete Bank Account?'
    },
    deletePaymentMethod: {
        id: 'payment.views.manage-banking-information.Delete Payment Method?',
        defaultMessage: 'Delete Payment Method?'
    },
    bankAccountDeleted: {
        id: 'payment.views.manage-banking-information.Bank Account Deleted',
        defaultMessage: 'Bank Account Deleted'
    },
    paymentMethodDeleted: {
        id: 'payment.views.manage-banking-information.Payment Method Deleted',
        defaultMessage: 'Payment Method Deleted'
    },
    deletionUnsuccessful: {
        id: 'payment.views.manage-banking-information.Deletion Unsuccessful',
        defaultMessage: 'Deletion Unsuccessful'
    },
    manageBankAccounts: {
        id: 'payment.views.manage-banking-information.Manage bank accounts',
        defaultMessage: 'Manage bank accounts'
    },
    accountNickname: {
        id: 'payment.views.manage-banking-information.Account Nickname',
        defaultMessage: 'Account Nickname: '
    },
    accountType: {
        id: 'payment.views.manage-banking-information.Account Type',
        defaultMessage: 'Account Type: '
    },
    accountNumber: {
        id: 'payment.views.manage-banking-information.Account Number',
        defaultMessage: 'Account Number: '
    },
    thisIsPrimary: {
        id: 'payment.views.manage-banking-information.This is your primary account.',
        defaultMessage: 'This is your primary account.'
    },
    primaryAccountWillBeUsed: {
        id: 'payment.views.manage-banking-information.Your primary account will be used',
        defaultMessage: 'Your primary account will be used when Wawanesa sends you an electronic payment.'
    },
    setAsPrimary: {
        id: 'payment.views.manage-banking-information.Set as primary account.',
        defaultMessage: 'Set as primary account.'
    },
    savingPrimaryUnsuccessful: {
        id: 'payment.views.manage-banking-information.Saving Primary Account Unsuccessful',
        defaultMessage: 'Saving Primary Account Unsuccessful '
    },
    forMARPolicy: {
        id: 'payment.views.manage-banking-information.This account is used for MAR',
        defaultMessage: 'This account is used for Monthly Automated Recurring payments. If you need to edit or delete it, please contact us at 1-888-363-7984 Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    edit: {
        id: 'payment.views.manage-banking-information.Edit',
        defaultMessage: 'Edit'
    },
    delete: {
        id: 'payment.views.manage-banking-information.Delete',
        defaultMessage: 'Delete'
    },
    nameOnAccount: {
        id: 'payment.views.manage-banking-information.Name on Account',
        defaultMessage: 'Name on Account'
    },
    authorization: {
        id: 'payment.views.manage-banking-information.Authorization',
        defaultMessage: 'Authorization'
    },
    iAuthorize: {
        id: 'payment.views.manage-banking-information.I authorize Wawanesa to store the bank account information',
        defaultMessage: 'I authorize Wawanesa to store the bank account information indicated on the Authorization Form for the purpose of making future one-time payments to Wawanesa. This Authorization will remain in effect until Wawanesa has received written notice from you of its termination.'
    },
    enterBankAccountInformation: {
        id: 'payment.views.manage-banking-information.Enter Bank Account Information',
        defaultMessage: 'Enter Bank Account Information'
    },
    checking: {
        id: 'payment.views.manage-banking-information.Checking',
        defaultMessage: 'Checking'
    },
    savings: {
        id: 'payment.views.manage-banking-information.Savings',
        defaultMessage: 'Savings'
    },
    bankAccountType: {
        id: 'payment.views.manage-banking-information.Bank Account Type',
        defaultMessage: 'Bank Account Type'
    },
    pleaseChooseAnAccountTypeBeforeProceeding: {
        id: 'payment.views.manage-banking-information.Please choose an account type before proceeding.',
        defaultMessage: 'Please choose an account type before proceeding.'
    },
    nonSufficientFundsFeeWillBeAssessed: {
        id: 'payment.views.manage-banking-information. A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.',
        defaultMessage: ' A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.'
    },
    chequeSample: {
        id: 'payment.views.manage-banking-information.Cheque sample',
        defaultMessage: 'Cheque sample'
    },
    checkNumberIsNotARequiredField: {
        id: 'payment.views.manage-banking-information.* Check number is not a required field.',
        defaultMessage: '* Check number is not a required field.'
    },
    routingTransitNumber: {
        id: 'payment.views.manage-banking-information.Routing Transit Number',
        defaultMessage: 'Routing Transit Number'
    },
    bankAccountNumber: {
        id: 'payment.views.manage-banking-information.Bank Account Number',
        defaultMessage: 'Bank Account Number'
    },
    routingTransitNumberMustBe9DigitsLong: {
        id: 'payment.views.manage-banking-information.Routing transit number must be 9 digits long.',
        defaultMessage: 'Routing transit number must be 9 digits long.'
    },
    bankAccountNumberMustBe1To17Digits: {
        id: 'payment.views.manage-banking-information.Bank account number must be 1 to 17 digits.',
        defaultMessage: 'Bank account number must be 1 to 17 digits.'
    },
    yourCurrentAccountNumber: {
        id: 'payment.views.manage-banking-information.Your current account number is set to:',
        defaultMessage: 'Your current account number is set to:'
    },
    nickname: {
        id: 'payment.views.manage-banking-information.Nickname',
        defaultMessage: 'Account Nickname (optional)'
    },
    forYourSecurity: {
        id: 'payment.views.manage-banking-information.For your security',
        defaultMessage: 'For your security, account nicknames should not contain confidential or sensitive information (e.g. bank account number or any other information that should be kept safe). This field will be fully displayed when you access this site.'
    },
    makeThisPrimary: {
        id: 'payment.views.manage-banking-information.Make this my primary Account',
        defaultMessage: 'Make this my primary Account'
    },
    yourPrimaryAccountWillBeUsed: {
        id: 'payment.views.manage-banking-information.Your primary account will be used when Wawanesa sends you an electronic payment.',
        defaultMessage: 'Your primary account will be used when Wawanesa sends you an electronic payment.'
    },
    agreeSaveBankAccount: {
        id: 'payment.views.manage-banking-information.I AGREE',
        defaultMessage: 'I AGREE'
    },
    bankAccountExists: {
        id: 'payment.views.manage-banking-information.Bank Account Exists',
        defaultMessage: 'Bank Account Exists'
    },
    bankAccountExistsText: {
        id: 'payment.views.manage-banking-information.Bank Account Exists text',
        defaultMessage: 'A bank account with the routing and bank account number submitted is already saved to your account. Please enter a different routing or account number to continue.'
    },
    bankAccountNicknameExists: {
        id: 'payment.views.manage-banking-information.Bank Account Nickname Exists',
        defaultMessage: 'Bank Account Nickname Exists'
    },
    bankAccountNicknameExistsText: {
        id: 'payment.views.manage-banking-information.Bank Account Nickname Exists text',
        defaultMessage: 'A bank account with the nickname submitted is already saved to your account. Please enter a different nickname to continue.'
    },
    errorSavingBankingInfo: {
        id: 'payment.views.manage-banking-information.There was an error saving your banking information',
        defaultMessage: 'There was an error saving your banking information. Contact us for assistance at 1-800-640-2920 Monday – Friday 7:30 AM – 7:30 PM and Saturday 8:00 AM – 4:30 PM.'
    },
    successSavingBankingInfo: {
        id: 'payment.views.manage-banking-information.Thank you, your bank account has been successfully saved',
        defaultMessage: 'Thank you, your bank account has been successfully saved for future use.'
    },
    routingTransitNumberInvalid: {
        id: 'payment.views.manage-banking-information.The Routing Transit Number entered is not valid',
        defaultMessage: 'The Routing Transit Number entered is not valid. Please re-enter the transit number'
    },
    pleaseConfirmDelete: {
        id: 'payment.views.manage-banking-information.Please confirm you want to delete the following information',
        defaultMessage: 'Please confirm you want to delete the following information:'
    },
    accountSuccessfullyDeleted: {
        id: 'payment.views.manage-banking-information.Your bank account has been successfully deleted.',
        defaultMessage: 'Your bank account has been successfully deleted.'
    },
    paymentMethodSuccessfullyDeleted: {
        id: 'payment.views.manage-banking-information.Your payment method has been successfully deleted.',
        defaultMessage: 'Your payment method has been successfully deleted.'
    },
    errorDeletingBankAccount: {
        id: 'payment.views.manage-banking-information.There was an error deleting your Bank Account.',
        defaultMessage: 'There was an error deleting your Bank Account. Contact us for assistance at 1-800-640-2920 Monday – Friday 7:30 AM – 7:30 PM and Saturday 8:00 AM – 4:30 PM.'
    },
    errorDeletingPaymentMethod: {
        id: 'payment.views.manage-banking-information.There was an error deleting your payment method.',
        defaultMessage: 'There was an error deleting your payment method. Contact us for assistance at 1-800-640-2920 Monday – Friday 7:30 AM – 7:30 PM and Saturday 8:00 AM – 4:30 PM.'
    },
    contactForAssistance: {
        id: 'payment.views.manage-banking-information.Contact us for assistance',
        defaultMessage: 'Contact us for assistance at 1-888-3637984 Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM'
    },
    back: {
        id: 'payment.views.manage-banking-information.Back',
        defaultMessage: 'Back'
    },
    cancel: {
        id: 'payment.views.manage-banking-information.Cancel',
        defaultMessage: 'Cancel'
    },
    yesDelete: {
        id: 'payment.views.manage-banking-information.Yes, Delete',
        defaultMessage: 'Yes, Delete'
    },
    ok: {
        id: 'payment.views.manage-banking-information.OK',
        defaultMessage: 'OK'
    },
    submit: {
        id: 'payment.views.manage-banking-information.Submit',
        defaultMessage: 'Submit'
    },
    close: {
        id: 'payment.views.manage-banking-information.Close',
        defaultMessage: 'Close'
    },
    managePaymentMethods: {
        id: 'payment.views.manage-banking-information.Manage payment methods',
        defaultMessage: 'Manage payment methods'
    },
    visitYourProfilePage: {
        id: 'payment.views.manage-banking-information.To edit a saved payment method',
        defaultMessage: 'To edit a saved payment method, please visit Your Profile'
    },
    bankAccountNumberLastFour: {
        id: 'payment.views.manage-banking-information.Account Number ****',
        defaultMessage: 'Account Number: ****  '
    },
    paymentMethodDisplay: {
        id: 'payment.views.manage-banking-information.Payment Method Display',
        defaultMessage: '{bankName} {paymentMethodType}&nbsp;&nbsp; ****{lastFour}'
    }
});