import { defineMessages } from 'react-intl';

export default defineMessages({
    removeVehicle: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Remove Vehicle',
        defaultMessage: 'Remove Vehicle'
    },
    removeVehicleAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Remove Vehicle Aria',
        defaultMessage: 'Remove Vehicle'
    },
    selectTheVehicleToRemoveFromYourPolicy: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Select the vehicle to remove from your policy',
        defaultMessage: 'Select the vehicle to remove from your policy'
    },
    remove: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Remove',
        defaultMessage: 'Remove'
    },
    cancel: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Cancel',
        defaultMessage: 'Cancel'
    },
    pleaseProvideFurtherDetailsAboutThisChange: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Please provide further details about this change',
        defaultMessage: 'Please provide further details about this change'
    },
    removingFromYourPolicy: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Removing from your policy',
        defaultMessage: 'Removing {vehicle} from your policy'
    },
    continue: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Continue',
        defaultMessage: 'Continue'
    },
    whatDateDidThisOccur: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.What date did this occur? (MM-DD-YYYY)',
        defaultMessage: 'What date did this occur? (MM-DD-YYYY)'
    },
    enterAValidInformation: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Enter a valid information.',
        defaultMessage: 'Enter a valid information.'
    },
    pleaseCallUsToComplete: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-call.Please call us to complete this change',
        defaultMessage: 'Please call us to complete this change'
    },
    use: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-call.Use',
        defaultMessage: 'Use:' 
    },
    annualMileage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-call.Annual Mileage',
        defaultMessage: 'Annual Mileage:' 
    },
    removeVehicleButtonAriaMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Remove Vehicle Button Aria Message',
        defaultMessage: 'Remove Vehicle {vehicleName} used for {vehicleUseage} with {annualMileage} miles.'
    },
    yourPolicyChangeCannotBeProcessed: {
        id: 'wmic.policy-change.component.remove-vehicle-pa.Your policy change cannot be processed',
        defaultMessage: 'Your policy change cannot be processed online and requires additional review by our team. Please call us at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:1-800-640-2920"&gt;800-640-2920&lt;/a&gt; to speak with our customer service team Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30 pm PT.'
    },
});