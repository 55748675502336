/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import { 
    withRouter,
    useHistory
} from 'react-router-dom';
import PropTypes from 'prop-types';
import _, { truncate } from 'lodash';
import classNames from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { WMICLoader } from 'gw-components-platform-react';
import { WMICCustomCircularCheckbox } from 'wmic-components-platform-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS, WMICRichTextUtil } from 'wmic-portals-utils-js';
import metadata from './WMICPolicyChangePaymentPlanBox.metadata.json5';
import messages from './WMICPolicyChangePaymentPlanBox.messages';
import styles from './WMICPolicyChangePaymentPlanBox.module.scss';

function WMICPolicyChangePaymentPlanBox(props) {
    const modalApi = useModal();
    const {
        billingVM,
        match
    } = props;
    const history = useHistory();
    const [loadingState, setLoadingState] = useState(true);
    const translator = useTranslator();
    const [paymentTypeCheckBoxValue, setPaymentTypeCheckBoxValue] = useState(CONSTANTS.MONTHLY);
    const [currentPaymentPlan, setCurrentPaymentPlan] = useState(CONSTANTS.TWO_PAYMENTS); // TODO send data from policy change component when the button submit is done
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');

    useEffect(() => {
        setLoadingState(true);
        Promise.all([
            //TODO Calls to the services that will be used
        ]).finally(() => {
            setLoadingState(false);
        });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    const handleChange = useCallback(
        (value) => {
            setPaymentTypeCheckBoxValue(value);
        },
        []
    );

    const handleCancel = () => {
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/auto/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    };

    const onTriggerNextButton = () => {
        let body = translator(messages.confirmationTextIntro);
        const msg1 = translator(messages.confirmationTextChangePaymentTo);
        body = body.concat(msg1);
        if (paymentTypeCheckBoxValue == CONSTANTS.MONTHLY) {
            body = body.concat(' ', translator(messages.monthlyOptionWarningMessage));
        } else if (paymentTypeCheckBoxValue == CONSTANTS.FULL_PAYMENT) {
            body = body.concat(' ', translator(messages.fullPaymentOptionWarningMessage));
        } else {
            body = body.concat(' ', translator(messages.twoPaymentsOptionWarningMessage));
        }
        body = body.concat(translator(messages.onceSubmitted));
        body = WMICRichTextUtil.safeUnescape(body);
        modalApi.showConfirm({
            title: translator(messages.confirmationTitle),
            message: body,
            status: 'warning',
            confirmButtonText: translator(messages.submit),
            cancelButtonText: translator(messages.cancel)
        }).then(async (results) => {
            if (results === 'cancel') {
                return _.noop();
            }
            _.set(billingVM, 'paymentPlan', currentPaymentPlan);
            return true;
        }, _.noop);
    };

    const getMonthlyPaymentOption = () => {
        let msg1 = translator(messages.monthlyOptionFirst);
        const msg2 = translator(messages.monthlyOptionSecond);
        const msg4 = translator(messages.currentPaymentPlan);
        msg1 = msg1.concat(msg2);
        if (currentPaymentPlan == CONSTANTS.MONTHLY) {
            msg1 = msg1.concat(msg4);
        }
        return WMICRichTextUtil.safeUnescape(msg1);
    };

    const getFullPaymentOption = () => {
        let msg1 = translator(messages.fullPaymentOptionFirst);
        const msg2 = translator(messages.fullPaymentOptionSecond);
        const msg3 = translator(messages.fullPaymentOptionThird);
        const msg4 = translator(messages.currentPaymentPlan);
        msg1 = msg1.concat(msg2).concat(msg3);
        if (currentPaymentPlan == CONSTANTS.FULL_PAYMENT) {
            msg1 = msg1.concat(msg4);
        }
        return WMICRichTextUtil.safeUnescape(msg1);
    };

    const getTwoPaymentsOption = () => {
        let msg1 = translator(messages.twoPaymentsOptionFirst);
        const msg2 = translator(messages.twoPaymentsOptionSecond);
        const msg3 = translator(messages.twoPaymentsOptionThird);
        const msg4 = translator(messages.currentPaymentPlan);
        msg1 = msg1.concat(msg2).concat(msg3);
        if (currentPaymentPlan == CONSTANTS.TWO_PAYMENTS) {
            msg1 = msg1.concat(msg4);
        }
        return WMICRichTextUtil.safeUnescape(msg1);
    };


    const avalValues = [
        {
            code: 'monthly',
            name: messages.monthlyOption,
            subtitle: messages.monthlyOptionSubtitle
        },
        {
            code: 'fullPayment',
            name: messages.fullPaymentOption,
            subtitle: messages.fullPaymentOptionSubtitle,
            displayName: messages.fullPaymentOptionSecondSubtitle
        },
        {
            code: 'twoPayments',
            name: messages.twoPaymentsOption
        }
    ];

    const getTitle = () => {
        return (
            <span>
                <i className={classNames("fa fa-file-text", styles['wmic-title-icon'])} />
                <strong>{ translator(messages.changePaymentPlan) }</strong>
            </span>
        );
    };

    const overrideProps = {
        wmicLoader: {
            visible: loadingState
        },
        pageTitle: {
            content: getTitle(),
            className: styles['ww-change-payment-title']
        },
        description: {
            content: translator(messages.description)
        },
        nextButton: {
            visible: truncate,
            onClick: () => {
                return onTriggerNextButton();
            }
        },
        wmicCheckboxMonthlyPayment: {
            value: CONSTANTS.MONTHLY,
            onValueChange: () => setPaymentTypeCheckBoxValue(CONSTANTS.MONTHLY),
            label: getMonthlyPaymentOption()
        },
        wmicCheckboxFullPayment: {
            value: CONSTANTS.FULL_PAYMENT,
            onValueChange: () => setPaymentTypeCheckBoxValue(CONSTANTS.FULL_PAYMENT),
            label: getFullPaymentOption()
        },
        wmicCheckboxTwoPayments: {
            value: CONSTANTS.TWO_PAYMENTS,
            onValueChange: () => setPaymentTypeCheckBoxValue(CONSTANTS.TWO_PAYMENTS),
            label: getTwoPaymentsOption()
        },
        cancelButton: {
            onClick: () => handleCancel()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICLoaderComponent: WMICLoader,
            wmicCheckbox: WMICCustomCircularCheckbox
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICPolicyChangePaymentPlanBox.propTypes = {
    billingVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired
};

export default withRouter(WMICPolicyChangePaymentPlanBox);