// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICIterableDriverComponent__iterableDriverContainer{border:1px solid #ccc;border-radius:6px;padding:19px 20px}.app__WMICIterableDriverComponent__driverUpperDetailsContainer{padding-bottom:15px}.app__WMICIterableDriverComponent__riskNumberField{padding-right:20px}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/components/PA/WMICIterableDriverComponent/WMICIterableDriverComponent.module.scss"],"names":[],"mappings":"AAIA,2DACI,qBAAA,CACA,iBAAA,CACA,iBAAA,CAGJ,+DACI,mBAAA,CAGJ,mDACI,kBAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\n.iterableDriverContainer{\n    border: 1px solid $filter-type-section-color;\n    border-radius: 6px;\n    padding: 19px 20px;\n}\n\n.driverUpperDetailsContainer{\n    padding-bottom: 15px;\n}\n\n.riskNumberField{\n    padding-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iterableDriverContainer": "app__WMICIterableDriverComponent__iterableDriverContainer",
	"driverUpperDetailsContainer": "app__WMICIterableDriverComponent__driverUpperDetailsContainer",
	"riskNumberField": "app__WMICIterableDriverComponent__riskNumberField"
};
export default ___CSS_LOADER_EXPORT___;
