import React, { useContext } from 'react';
import { ModalNext, ModalBody, ModalFooter, ModalHeader } from '@jutro/components';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { Flex } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import messages from './WMICLapseRenewalModal.messages';

import { InputField } from '@jutro/legacy/components';

function WMICLapseRenewalModal(props) {
    const { isOpen, onResolve, setHasLapseStateChangedFlag, RenewalService, lapseData, insured, policy, mailingAddress, reason, authHeader } = props;
    const translator = useContext(TranslatorContext);

    const handleCancel = () => {
        onResolve('Cancelled');
    }

    const handleSubmit = () => {
        RenewalService.addPreRenewalDirection(lapseData, authHeader)
            .then(() => {
                onResolve('Submitted');
                setHasLapseStateChangedFlag((prev) => !prev);
            })
            .catch((e) => {
                if(typeof e === 'object') {
                    WMICErrorHandler.processAsModal(e).then(
                        _.noop
                    );
                }
            })
    }

    return (
        <ModalNext isOpen={isOpen}>
            <Flex direction="column" id="modalRoot">
                <ModalHeader status="info" title={translator(messages.lapseRenewal)} />
                <ModalBody>
                    <InputField 
                        id="lapseInsuredInput"
                        label={translator(messages.insured)}
                        labelPosition="left"
                        value={insured}
                        readOnly
                    />
                    <InputField 
                        id="lapsePolicyInput"
                        label={translator(messages.policy)}
                        labelPosition="left"
                        value={policy}
                        readOnly
                    />
                    <InputField 
                        id="lapseMailingAddressInput"
                        label={translator(messages.mailingAddress)}
                        labelPosition="left"
                        value={mailingAddress}
                        readOnly
                    />
                    <InputField 
                        id="lapseReasonInput"
                        label={translator(messages.lapseReason)}
                        labelPosition="left"
                        value={reason}
                        readOnly
                    />
                </ModalBody>
                <ModalFooter>
                    <WMICButton
                        id="lapse-modal-cancel"
                        onClick={handleCancel}
                        type="primary-outlined"
                    >
                        {translator(messages.cancel)}
                    </WMICButton>
                    <WMICButton
                        id="lapse-modal-confirm"
                        onClick={handleSubmit}
                        type="primary"
                    >
                        {translator(messages.submit)}
                    </WMICButton>
                </ModalFooter>
            </Flex>
        </ModalNext>
    );
}

WMICLapseRenewalModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default WMICLapseRenewalModal;
