import React, { Fragment, useState, useEffect, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { WMICButton } from 'wmic-pe-components-platform-react';
import messages from './WMICBackToTopButton.messages';
import styles from './WMICBackToTopButton.module.scss';

function WMICBackToTopButton() {

    const [isBackToTopVisible, setBackToTopVisible] = useState(false);
    const translator = useContext(TranslatorContext);

    const toggleVisibility = () => {
        if(window.pageYOffset > 200) {
            setBackToTopVisible(true);
        }
        else if(isBackToTopVisible) {
            setBackToTopVisible(false);
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", toggleVisibility);

        return () => {
            document.removeEventListener("scroll", toggleVisibility);
        }
    });

    const returnToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });  
    }

    return (
        <Fragment>
            { isBackToTopVisible &&
                <WMICButton
                    icon="mi-arrow-upward"
                    size="medium"
                    className={styles.backToTop} 
                    onClick={returnToTop}
                    aria-label={translator(messages.backToTopLabel)}
                />
            }
        </Fragment>
    );
}

export default WMICBackToTopButton;
