import { defineMessages } from 'react-intl';

export default defineMessages({
    addNewPaymentMethods: {
        id: 'usersprofile.views.amp-users-profile.Add New Payment Methods',
        defaultMessage: 'Add New Payment Methods'
    },
    noPaymentMethods: {
        id: 'usersprofile.views.bank-accounts-list.You do not have any Payment Methods saved to your Profile.',
        defaultMessage: 'You do not have any Payment Methods saved to your Profile.'
    },
    yourPrimaryAcc: {
        id: 'usersprofile.views.bank-accounts-list.This is your primary account.',
        defaultMessage: 'This is your primary account.'
    },
    primarAccWillBeUsed: {
        id: 'usersprofile.views.bank-accounts-list.tooltips.Your primary account will be used when Wawanesa sends you an electronic payment.',
        defaultMessage: 'Your primary account will be used when Wawanesa sends you an electronic payment.'
    },
    usedForMonthlyAutomatedRecurringPayments: {
        id: 'usersprofile.views.bank-accounts-list.This account is used for Monthly Automated Recurring payments.',
        defaultMessage: 'This account is used for Monthly Automated Recurring payments.'
    },
    pleaseNote: {
        id: 'usersprofile.views.bank-accounts-list.Please Note',
        defaultMessage: 'Please Note: '
    },
    ifYouNeedToEditOrDeleteIt: {
        id: 'usersprofile.views.bank-accounts-list.If you need to edit or delete it, please contact us at 1-888-363-7984 Monday – Friday 7:30 AM – 7:30 PM and Saturday 8:00 AM – 4:30 PM.',
        defaultMessage: 'If you need to change the bank account used for your monthly automated recurring payments, please call us at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday to Friday 7:30 am – 7:30 pm PT and Saturday 8:00 am – 4:30 pm PT.'
    },
    delete: {
        id: 'usersprofile.views.bank-accounts-list.Delete',
        defaultMessage: 'Delete'
    },
    edit: {
        id: 'usersprofile.views.bank-accounts-list.Edit',
        defaultMessage: 'Edit'
    }
});