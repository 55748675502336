/* eslint-disable no-secrets/no-secrets */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ClauseService } from 'wmic-pe-capability-policycommon';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ModalNextContext, Loader } from '@jutro/components';
import { CONSTANTS, WIZARD_STEP_PATHS, WMICLogger, WMICValidationUtil, MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';
import { MockUpUtil } from '@xengage/gw-portals-util-js'
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { WMICWizardHandler } from 'wmic-pe-capability-gateway-common-react';
import { WizardBMSIModal, messages as commonSubmissionMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';

import messages from './WMICPEHOWizard.messages';
import wizardConfig from './config/ho-wizard-config.json5';
import wizardStepToFieldMapping from './config/ho-wizard-step-to-field-mapping.json5';
import styles from './WMICPEHOWizard.module.scss';

const PATH_TO_MOCK_DATA = 'quote.ho';
const MOCK_DATA_TO_SET = ['bindData.contactPhone'];
const MOCK_DATA_TO_REMOVE = ['bindData.contactPhone'];

function getTargetPageIndex(steps, isBMSi) {
    const WMICQuotePageRegex = /(QuotePage)$/;

    if (isBMSi) {
        for (let stepIndex = 0; stepIndex < steps.length; stepIndex += 1) {

            if (WMICQuotePageRegex.test(steps[stepIndex].id)) {
                return stepIndex;
            }
        }
    }

    return undefined
}

const checkValidity = (submissionVM, extractVMObject, currentStep) => {
    const checkValidityPathsForCurrentStep = _.get(currentStep, 'checkValidityPaths', []);

    // Cloning checkValidityPaths to avoid adding duplicates to array
    const checkValidityPaths = _.clone(checkValidityPathsForCurrentStep);

    // For Risk step, validate only dwelling.yourHome path for each dwelling child
    if (currentStep.path === WIZARD_STEP_PATHS.HO.YOUR_HOME) {
        if (!_.get(submissionVM, 'isEditingPage.value', false)) {
            return true;
        }
        const dwellings = _.get(submissionVM, "lobData.homeowners.coverables.dwellings.children");
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < dwellings.length; index++) {
            const validityPath = `lobData.homeowners.coverables.dwellings.children[${index}].yourHome`;
            checkValidityPaths.push(validityPath);
        }
    }

    // For Construction step, only validate if user has started editing the page
    if (currentStep.path === WIZARD_STEP_PATHS.HO.CONSTRUCTION) {
        if (!_.get(submissionVM, 'isEditingPage.value', false)) {
            return true;
        }
    }

    return WMICValidationUtil.checkValidity(submissionVM, checkValidityPaths);
}

function WMICPEHOWizard(props) {
    const { steps, title } = wizardConfig;
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [showBMSIModal, setShowBMSIModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCustomStepsInvalidated, setIsCustomStepsInvalidated] = useState(false);
    const { viewModelService } = props;
    const modalNext = useContext(ModalNextContext);
    const { showConfirm, showWarning, setDefaultMessage } = useWizardModals();
    const history = useHistory();
    const location = useLocation();
    const translator = useContext(TranslatorContext);

    useEffect(
        () => {
            const viewModelContext = {
                AccountEmailRequired: false,
                DriverEmailRequired: true,
                AccountDOBRequired: false
            };
            if (!location.state) {
                history.push('/');
                return;
            }

            setDefaultMessage(translator(commonSubmissionMessages.savingQuoteSubmissionDetails));

            const { quoteentry } = location.state;
            const { postalCode, quoteID } = quoteentry;
            const requestData = {
                quoteID,
                postalCode
            };
            LoadSaveService.retrieveSubmission(requestData, authHeader)
                .then((response) => {
                    setShowBMSIModal(true);
                    return MockUpUtil.cleanUpMockedProperties(
                        response,
                        PATH_TO_MOCK_DATA,
                        ...MOCK_DATA_TO_REMOVE
                    );
                })
                .then((response) => {
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(response, 'quoteID'),
                        authHeader
                    );
                    const submission = viewModelService.create(
                        response,
                        'pc',
                        'wmic.edge.ca.capabilities.quote.submission.dto.QuoteDataDTO',
                        viewModelContext
                    );

                    const fieldErrors = _.get(submission, 'errorsAndWarnings.validationIssues.fieldIssues.value');
                    if (fieldErrors) {
                        WMICLogger.error(fieldErrors);
                    }

                    steps.forEach((step) => _.set(step, 'stepToFieldMapping', wizardStepToFieldMapping[step.id]))

                    setInitialSubmission(submission);
                    setIsLoading(false);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const isBMSI = _.get(initialSubmission, "isFromBMS_WMIC.value");
    const validationIssues = _.get(initialSubmission, "errorsAndWarnings.validationIssues.issues.value");
    const defaultedPropertiesToReview = _.get(initialSubmission, "defaultedProperties_WMIC.value", []).filter((p) => p.propertyDisplayName && p.status !== CONSTANTS.REVIEWED);
    const status = _.get(initialSubmission, "quoteData.value.offeredQuotes[0].status", '');
    const isQuoted = status.toLowerCase() === CONSTANTS.QUOTED.toLowerCase();

    const handleCancel = useCallback(
        (cancelProps) => {
            const skipModal = CONSTANTS.SKIP_CANCEL_MODAL;
            const {
                wizardSnapshot,
                param: nextPath = '',
                currentStepIndex,
                wizardData
            } = cancelProps;
            const lastPathName = nextPath.replace(/\/?.*\//g, '');

            if (
                currentStepIndex === steps.length - 1
                || skipModal.includes(lastPathName)
                || hasErrorOccurred
            ) {
                return true;
            }

            return showConfirm({
                title: commonSubmissionMessages.leaveWithoutSaving,
                message: commonSubmissionMessages.infoWillBeSavedAsDraft,
                status: MODAL_CONSTANTS.STATUS.WARNING,
                icon: MODAL_CONSTANTS.ICON.WARNING,
                confirmButtonText: commonMessages.yes,
                cancelButtonText: commonMessages.no
            }).then((results) => {
                if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                    return _.noop();
                }
                const isStatusQuoted = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === CONSTANTS.QUOTED;

                let serverCall = LoadSaveService.updateDraftSubmission;

                if (isStatusQuoted) {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const isValid = wizardData.lobData.aspects.valid
                    && wizardData.lobData.aspects.subtreeValid;
                const payload = isValid
                    ? _.get(wizardData, 'value')
                    : _.get(wizardSnapshot, 'value');
                const payloadBeforeSave = _.omit(payload, 'bindData.paymentDetails');
                const dataToSave = MockUpUtil.setMockData(
                    payloadBeforeSave,
                    PATH_TO_MOCK_DATA,
                    ...MOCK_DATA_TO_SET
                );

                setIsLoading(true);

                const redirectPath = `/quotes/${dataToSave.quoteID}/summary`;

                serverCall(dataToSave, authHeader)
                    .then(() => {
                        history.push(nextPath || redirectPath);
                    })
                    .catch(() => {
                        showWarning({
                            title: messages.anErrorOccurredTitle,
                            message: messages.anErrorOccurred,
                            status: MODAL_CONSTANTS.STATUS.WARNING,
                            icon: MODAL_CONSTANTS.ICON.ERROR,
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                        history.push(nextPath || redirectPath);
                    });

                return true;
            }, _.noop);
        },
        [authHeader, hasErrorOccurred, showConfirm, showWarning, history, steps.length]
    );

    const handleError = useCallback((error) => {
        WMICWizardHandler.handleSubmissionError(error, location, history, setHasErrorOccurred);
    }, [history, location]);

    const handleKnockout = useCallback(({ knockOutErrors, wizardData }) => {
        const quoteID = wizardData.quoteID.value;
        history.push('/knockoutpage', {
            underwritingIssues: knockOutErrors,
            quoteID
        });
    }, [history]);

    const handleFinish = useCallback((wizardProps) => {
        const {
            wizardData
        } = wizardProps;
        history.push({
            pathname: `/quotes/${wizardData.value.quoteID}/summary`,
            props: wizardData.value
        });
    }, [history]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    if (showBMSIModal && isBMSI && !isLoading && (validationIssues?.length > 0 || defaultedPropertiesToReview.length > 0)) {
        modalNext.showModal(<WizardBMSIModal validationIssues={validationIssues} defaultedProperties={defaultedPropertiesToReview}/>).then(() => {
            setShowBMSIModal(false);
        });
    }

    return (
        <div className={styles.PEHOWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    onKnockOut={handleKnockout}
                    checkValidity={
                        // In the case where BMSi has been created and the submission is in the Quoted status,
                        // if it is user's first visit or if they revisit without making any changes,
                        // the validation is bypassed to allow freely jump between steps.
                        isBMSI &&
                        isQuoted &&
                        defaultedPropertiesToReview.length > 0
                            ? () => true
                            : checkValidity
                    }
                    initialData={initialSubmission}
                    onCancel={handleCancel}
                    onFinish={handleFinish}
                    skipCompletedSteps
                    maintainFurtherStepsVisitedSubmitted={{
                        flag: isBMSI && !isCustomStepsInvalidated,
                        targetStep: getTargetPageIndex(steps, isBMSI),
                    }}
                    setIsCustomStepsInvalidated={setIsCustomStepsInvalidated}
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    onPreviousModalProps={{
                        title: commonSubmissionMessages.wantToJump,
                        message: commonSubmissionMessages.wantToJumpMessage,
                        status: MODAL_CONSTANTS.STATUS.WARNING,
                        icon: MODAL_CONSTANTS.ICON.WARNING,
                        confirmButtonText: commonMessages.yesModel,
                        cancelButtonText: commonMessages.cancelModel,
                    }}
                />
            </ErrorBoundary>
        </div>
    );
}

WMICPEHOWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        })
    }).isRequired
};

export default withViewModelService(WMICPEHOWizard);
