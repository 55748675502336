import { APP_NAMES } from './StringConstants';


const CONSTANTS = {
    APPLICATION_NAME_KEY: 'applicationName'
};

/**
 * Function that sets the application name
 * @param {*} appName 'producer-engage', 'account-management', 'quote-and-buy'
 */
const setAppName = (appName) => {
    localStorage.setItem(CONSTANTS.APPLICATION_NAME_KEY, appName);
};

/**
 * Function to retrieve application name from local storage
 * @returns {string} region value
 */
const getAppName = () => {
    const appName = localStorage.getItem(CONSTANTS.APPLICATION_NAME_KEY);

    return appName;
};

const isCE = () => {
    return getAppName() === APP_NAMES.CE;
};

const isPE = () => {
    return getAppName() === APP_NAMES.PE;
};

const isAMP = () => {
    return getAppName() === APP_NAMES.AMP;
};

export default {
    setAppName,
    getAppName,
    isCE,
    isPE,
    isAMP
};
