import React from 'react';
import _ from 'lodash';

function WMICNoticesComponent({ notices }) {
    const noticeElements = _.map(notices, (notice) => {
        return (
            <div className="ww-card__content ww-card-notice ww-payment-notice">
                <p className="ww-payment-header">
                    <i className="fa fa-exclamation-circle"></i>
                    {notice.header}
                </p>
                <p>{notice.body}</p>
            </div>
        );
    });
    return (
        noticeElements
    );
}

export default WMICNoticesComponent;