// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__GoogleAddressLookupComponent__addressTypeAhead div[id^=addressTypeAhead]{display:none}.app__GoogleAddressLookupComponent__labelContainer{margin-bottom:0}", "",{"version":3,"sources":["webpack://./../common/modules-react/gw-ext-google-tools/address-lookup-component/GoogleAddressLookupComponent.module.scss"],"names":[],"mappings":"AACI,+EACE,YAAA,CAIN,mDACI,eAAA","sourcesContent":[".addressTypeAhead {\n    div[id^=\"addressTypeAhead\"] {\n      display: none;\n    }\n  }\n\n.labelContainer {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressTypeAhead": "app__GoogleAddressLookupComponent__addressTypeAhead",
	"labelContainer": "app__GoogleAddressLookupComponent__labelContainer"
};
export default ___CSS_LOADER_EXPORT___;
