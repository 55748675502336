import { LocalDateUtil } from '@xengage/gw-portals-util-js';

/**
 * This class is used in conjunction with the Expression Language only. Do not call directly.
 */
export default {
    // Platform LocalDateUtil functions
    daysInMonth: LocalDateUtil.daysInMonth, // Month is start from 0
    daysInLocalDate: LocalDateUtil.daysInLocalDate,
    toMidnightDate: LocalDateUtil.toMidnightDate,
    today: LocalDateUtil.today
};
