// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICTradeNameComponent__tradeNameContainer{display:flex}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-quoteandbind-cp-react/components/WMICTradeNameComponent/WMICTradeNameComponent.module.scss"],"names":[],"mappings":"AAIA,iDACI,YAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\n.tradeNameContainer {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tradeNameContainer": "app__WMICTradeNameComponent__tradeNameContainer"
};
export default ___CSS_LOADER_EXPORT___;
