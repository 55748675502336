// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div[class*=wmicCard][class*=nonCoverageCostTableCard]{margin-bottom:var(--GW-LAYOUT-8);padding:0}.app__ReadOnlyNonCoverageCostTable__indentCell{padding-left:2em}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/components/NonCoverageCosts/ReadOnlyNonCoverageCostTable/ReadOnlyNonCoverageCostTable.module.scss"],"names":[],"mappings":"AAIA,sDAEE,gCAAA,CADA,SACA,CAGF,+CACE,gBAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\ndiv[class*=\"wmicCard\"][class*=\"nonCoverageCostTableCard\"] {\n  padding: 0;\n  margin-bottom: var(--GW-LAYOUT-8);\n}\n\n.indentCell {\n  padding-left: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indentCell": "app__ReadOnlyNonCoverageCostTable__indentCell"
};
export default ___CSS_LOADER_EXPORT___;
