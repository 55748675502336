/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { WMICLoader } from 'gw-components-platform-react';
import { ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import classNames from 'classnames';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';

const WMICProcessingPaymentModal = () => {
    const translator = useTranslator();

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(messages.processingPayment)}</h3>
            <ModalBody>
                <div>
                    <div className="ww-content-limit">
                        <div className="ww-center">
                            <div className={styles['wmic-loader-div']}>
                                <WMICLoader />
                            </div>
                            <p className={classNames(styles['wmic-modal-first-paragraph'], styles['wmic-payment-text'])}>
                                {translator(messages.pleaseDoNotNavigateAwayFromThisPageOrCloseYourBrowser)}
                            </p>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter />
        </Fragment>
    );
};

export default WMICProcessingPaymentModal;
