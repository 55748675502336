import { getDefaultCurrency } from '@jutro/locale';
import _ from "lodash";
import { CurrencyField } from '@jutro/legacy/components';

function formatPremiumAmount(pAmount, pCurrency = getDefaultCurrency(), pShowFractions = true) {

    return pAmount !== 0 ?
        <CurrencyField
            value = {
                {
                    amount: pAmount,
                    currency: pCurrency
                }
            }
            showFractions = { pShowFractions }
            readOnly = { true }
        /> : ''
}

function getPremiumAmount(coverage) {
    const amt = _.get(coverage, 'amount.amount', 0);
    const curr = _.get(coverage, 'amount.currency');

    return formatPremiumAmount(amt, curr);
}

function getProratedPremiumAmount(cost) {
    const amt = _.get(cost, 'proratedPremium.amount', 0);
    const curr = _.get(cost, 'proratedPremium.currency');

    return formatPremiumAmount(amt, curr);
}

export default {
    getPremiumAmount,
    getProratedPremiumAmount,
    formatPremiumAmount
}
