import React, { useCallback, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Accordion } from '@jutro/legacy/components';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { HOConstants } from 'wmic-pe-portals-utils-js';
import { renderDwellingAddressCell } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import { WMICHOAdditionalInterests, WMICHODwellingAdditionalDetails, WMICHODwellingProtectionDetails, WMICHOWatercraftDetails } from 'wmic-pe-capability-gateway-common-ho-react';
import {WMICHOPolicyDwellingPropertyDetails} from 'wmic-pe-capability-policy-common-react';
import WMICHORiskUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil";
import metadata from './WMICPolicyHoRiskPage.metadata.json5';
import messages from './WMICPolicyHoRiskPage.messages';

const DWELLINGS_PATH = 'lobs.homeOwners.dwellingsDTO_WMIC';
const WATERCRAFT_PATH = 'lobs.homeOwners.watercraftsDTO_WMIC';

function WMICPolicyHoRiskPage(props) {

    const {
        wizardData: { policyVM },
    } = props;

    const translator = useContext(TranslatorContext);
    const dwellings = _.get(policyVM, `${DWELLINGS_PATH}.children`);

    const [selectedDwellingIndex, setSelectedDwellingIndex] = useState(0);
    const [dwellingRiskView, setDwellingRiskView] = useState(dwellings[selectedDwellingIndex]);

    const [selectedWatercraftIndex, setSelectedWatercraftIndex] = useState(-1);
    const [watercraftRiskView, setWatercraftRiskView] = useState();

    const [risk, setRisk] = useState({
        action: 'VIEW',
        subtype: HOConstants.riskSubTypeDwelling
    });

    const selectRisk = useCallback((riskIndex, isViewMode, riskSubtype) => {
        const isDwellingRisk = riskSubtype === HOConstants.riskSubTypeDwelling;

        const currentRisk = isDwellingRisk
            ? _.get(policyVM, `${DWELLINGS_PATH}.children[${riskIndex}]`)
            : _.get(policyVM, `${WATERCRAFT_PATH}.children[${riskIndex}]`);

        if (isDwellingRisk
            && currentRisk.yourHome.locationAddress
            && currentRisk.yourHome.locationAddress.value
            && currentRisk.yourHome.locationAddress.value.addressLine1
        ) {
            currentRisk.riskLocationIndex = _.findIndex(
                policyVM.account.accountHolder.primaryAddress.value,
                (address) => address.displayName === currentRisk.yourHome.locationAddress.value.displayName
            );
        }
        setRisk({
            action: 'VIEW',
            subtype: riskSubtype
        });
        currentRisk.isUnderEditing = !isViewMode;

        if (isDwellingRisk) {
            setDwellingRiskView(currentRisk);
        } else {
            setWatercraftRiskView(currentRisk);
        }
        return currentRisk;
    }, [policyVM]);

    const viewDwelling = useCallback((index) => {
        setSelectedDwellingIndex(index);
        setSelectedWatercraftIndex(-1);
        selectRisk(index, true, HOConstants.riskSubTypeDwelling);
    }, [selectRisk]);

    const viewWatercraft = useCallback((index) => {
        setSelectedWatercraftIndex(index);
        setSelectedDwellingIndex(-1);
        selectRisk(index, true, HOConstants.riskSubTypeWatercraft);
    }, [selectRisk]);

    const isDwelling = useCallback(() => (risk.subtype === HOConstants.riskSubTypeDwelling), [risk]);

    const isWatercraft = useCallback(() => (risk.subtype === HOConstants.riskSubTypeWatercraft), [risk]);

    const commonDwellingAccordionProps = {
        jobVM: policyVM,
        onRiskViewChange: setDwellingRiskView,
        readOnly: true,
        isEditMode: false,
        riskView: dwellingRiskView
    }

    const commonWatercraftAccordionProps = {
        submissionVM: policyVM,
        readOnly: true,
        isEditMode: false,
        riskView: watercraftRiskView
    }

    const overrideProps = {
        dwellingDataList: {
            VMList: _.get(policyVM, `${DWELLINGS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.dwellingUseHeader),
                    path: 'yourHome.dwellingUsage'
                },
                {
                    headerText: translator(messages.riskHeader),
                    path: 'yourHome.dwellingNumber_EXT',
                    getData: (item, path) => _.get(item, `${path}.value`, '')
                },
                {
                    headerText: translator(messages.addressHeader),
                    path: 'yourHome.locationAddress.displayName',
                    getData: (item) => renderDwellingAddressCell(item, translator)
                },
                {
                    headerText: translator(messages.riskTypeHeader),
                    path: 'rating.hoPolicyType'
                }
            ],
            readOnly: true,
            selectedCardIndex: selectedDwellingIndex,
            updateSelectedCardIndex: viewDwelling
        },
        dwellingAccordionsContainer: {
            visible: isDwelling(risk),
        },
        propertyDetailsAccordion: {
            ...commonDwellingAccordionProps
        },
        propertyDetailsAccordionContent: {
            visible: dwellingRiskView !== undefined,
            ...commonDwellingAccordionProps
        },
        additionalDetailsAccordion: {
            ...commonDwellingAccordionProps,
        },
        additionalDetailsAccordionContent: {
            visible: dwellingRiskView !== undefined,
            ...commonDwellingAccordionProps,
        },
        protectionDetailsAccordion: {
            ...commonDwellingAccordionProps,
        },
        protectionDetailsAccordionContent: {
            ...commonDwellingAccordionProps
        },
        dwellingAdditionalInterestsAccordion: {
            ...commonDwellingAccordionProps,
        },
        dwellingAdditionalInterestsAccordionContent: {
            ...commonDwellingAccordionProps,
            category: 'HODwellingAddlInt_HOE',
            risk,
            showAddNewAIButtonActive: false
        },
        waterCraftDataList: {
            VMList: _.get(policyVM, `${WATERCRAFT_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.riskHeader),
                    path: 'riskNumber'
                },
                {
                    headerText: translator(messages.riskDescription),
                    path: 'description',
                    getData: WMICHORiskUtil.getWaterCraftDescription
                },
                {
                    headerText: translator(messages.riskTypeHeader),
                    path: 'watercraftType'
                },
            ],
            readOnly: true,
            selectedCardIndex: selectedWatercraftIndex,
            updateSelectedCardIndex: viewWatercraft
        },
        watercraftAccordionsContainer: {
            visible: isWatercraft(risk) && selectedWatercraftIndex >= 0
        },
        watercraftDetailsAccordion: {
            ...commonWatercraftAccordionProps
        },
        watercraftDetailsAccordionContent: {
            ...commonWatercraftAccordionProps,
            isFromBms: false,
            watercraftRiskView,
            submissionVM: policyVM
        },
        watercraftAdditionalInterestsAccordion: {
            ...commonWatercraftAccordionProps
        },
        watercraftAdditionalInterestsAccordionContent: {
            ...commonWatercraftAccordionProps,
            category: 'HOWatercraftAddlInt_WMIC',
            risk,
            showAddNewAIButtonActive: false
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICHOPolicyDwellingPropertyDetails,
            WMICHODwellingAdditionalDetails,
            WMICHODwellingProtectionDetails,
            WMICHOAdditionalInterests,
            WMICHOWatercraftDetails,
            Accordion
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyHoRiskPage.propTypes = wizardProps;
export default WMICPolicyHoRiskPage;
