import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import htmlParser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WMICControllerUtil } from 'wmic-capability-policychange';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';

import metadata from './WMICSuccessPolicyChangeComponent.metadata.json5';
import messages from './WMICSuccessPolicyChangeComponent.messages';
import styles from './WMICSuccessPolicyChangeComponent.module.scss';

function WMICSuccessPolicyChangeComponent(props) {
    const {
        policyVM,
        match,
        loadingState,
        setLoadingState
    } = props;

    const translator = useTranslator();
    const history = useHistory();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');

    useEffect(() => {
        setLoadingState(true);
        const controllerUtilProps = {
            policyNumber: policyNumber,
            termNumber: termNumber,
            policyType: policyType,
            history: history
        };

        WMICControllerUtil.initVars(controllerUtilProps);

        setLoadingState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getNotificationSubtitle = () => {
        const title = translator(messages.successSubtitle);

        return (
            <Fragment>
                {title}
            </Fragment>
        );
    };

    const getNotificationDescription = () => (
            <><p>{htmlParser(translator(messages.successBody))}</p><p>{WMICRichTextUtil.translateRichText(translator(messages.ifYouNeedAssistanceWithText))}</p></>
        );

    const overrideProps = {
        successPolicyChangeBodyContainer: {
            visible: !loadingState,
            className: styles.bodyContainer
        },
        successHeader: {
            content: translator(messages.successHeader),
            className: styles.successHeader
        },
        successNotificationSubtitle: {
            content: getNotificationSubtitle()
        },
        notificationDescription: {
            content: getNotificationDescription()
        },
        goToPolicyDetailsButton: {
            content: translator(messages.goToPolicyDetails),
            onClick: () => history.push(`/account-policy-details/${policyNumber}/${termNumber}`)
        },
        keepEditingLink: {
            content: translator(messages.keepEditing),
            onClick: () => WMICControllerUtil.gotoChangeSelectionPage(policyNumber, termNumber, policyType)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICSuccessPolicyChangeComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired
};

export default WMICSuccessPolicyChangeComponent;