// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1.app__Preferences__preferenceTitle{margin-bottom:var(--GW-LAYOUT-6)}.app__Preferences__preferenceContent{padding:var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6)}h2.app__Preferences__accountSettingsSectionTitle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:var(--GW-SPACING-6);padding-bottom:var(--GW-SPACING-4)}.app__Preferences__globalizationChooserField{margin-top:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./node_modules/gw-pages-platform-react/pages/Preferences/Preferences.module.scss"],"names":[],"mappings":"AAAA,qCACI,gCAAA,CAGJ,qCACI,mFAAA,CAGJ,iDAGI,iEAAA,CAFA,iCAAA,CACA,kCACA,CAGJ,6CACI,8BAAA","sourcesContent":["h1.preferenceTitle {\n    margin-bottom: var(--GW-LAYOUT-6);\n}\n\n.preferenceContent {\n    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);\n}\n\nh2.accountSettingsSectionTitle {\n    margin-bottom: var(--GW-SPACING-6);\n    padding-bottom: var(--GW-SPACING-4);\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n}\n\n.globalizationChooserField {\n    margin-top: var(--GW-SPACING-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preferenceTitle": "app__Preferences__preferenceTitle",
	"preferenceContent": "app__Preferences__preferenceContent",
	"accountSettingsSectionTitle": "app__Preferences__accountSettingsSectionTitle",
	"globalizationChooserField": "app__Preferences__globalizationChooserField"
};
export default ___CSS_LOADER_EXPORT___;
