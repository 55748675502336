import React, {useState, useContext, useEffect} from 'react';
import {
    ModalNext,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@jutro/components';

import _ from "lodash";
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import { ViewModelForm} from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICButton } from 'wmic-pe-components-platform-react';
import { WMICRichTextUtil, LINE_OF_BUSINESS, WMICLogger, JobType } from 'wmic-pe-portals-utils-js';

import WMICHORiskUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil";

import { WMICProgressModal } from 'wmic-pe-capability-quoteandbind-common-react';
import { useFlexAndBrokerRecalculate } from 'wmic-pe-capability-gateway-common-react';
import  WMICIterableFlexRiskItem from '../WMICIterableFlexRiskItem/WMICIterableFlexRiskItem';
import messages from './WMICFlexProgramModal.messages';
import metadata from './WMICFlexProgramModal.metadata.json5';
import styles from './WMICFlexProgramModal.module.scss';

const  WMICFlexProgramModal = ({
    id,
    isOpen,
    onResolve,
    onReject,
    modalVM,
    updateJobVM,
    viewModelService,
    authHeader
}) => {

    const [ flexModalVM, setFlexModalVM ] = useState(modalVM);
    const lob = _.get(modalVM, 'lob.value.code');
    const [isFetching, setIsFetching] = useState(false);
    const [ showErrors, updateShowErrors ] = useState(false)
    const translator = useContext(TranslatorContext);
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    const { saveAndRecalculate, quoteID } = useFlexAndBrokerRecalculate(flexModalVM);

    useEffect(() => {
        setFlexModalVM(viewModelService.clone(modalVM));
    }, [modalVM, viewModelService])

    const getRiskData = (vm) => {
        switch(_.get(vm, 'lob.value.code')) {
            case LINE_OF_BUSINESS.HOME_OWNER:
                return _.get(vm, 'lobData.homeowners.coverables.dwellings.children');
            case LINE_OF_BUSINESS.PERSONAL_AUTO:
                return _.get(vm, 'lobData.personalAuto.coverables.vehicles.children');
            default:
                return null;
        }
    }

    async function handleRecalc() {
        if (!isComponentValid) {
            updateShowErrors(true);
            return;
        }
        try {
            setIsFetching(true);
            const riskData = getRiskData(flexModalVM);

            const response = await saveAndRecalculate(
                quoteID,
                riskData.map((risk) => risk.flexDTO.value),
                _.get(flexModalVM, 'baseData.waiveBrokerFee_WMIC.value'),
                authHeader
            );
            _.extend(flexModalVM, response);
            updateJobVM(flexModalVM);
            updateShowErrors(false);
            onResolve();
        } catch(err) {
            onReject({
                error: err,
                quoteID,
                jobType: _.get(modalVM, 'baseData.jobType.value.code')
            });
        } finally {
            setIsFetching(false);
        }
    }

    const updateModalData = (newModalVM) => {
        setFlexModalVM(viewModelService.clone(newModalVM));
    }

    const getRiskHeader = (risk) => {
        switch(lob){
            case LINE_OF_BUSINESS.HOME_OWNER:
                const address = _.get(risk, 'yourHome.locationAddress.displayName');
                const dwellingNumber = _.get(risk, 'yourHome.dwellingNumber_EXT');
                return translator(messages.flexRiskHeader, {riskNumber: dwellingNumber, displayName: WMICHORiskUtil.formatDwellingLocation(address)})
            case LINE_OF_BUSINESS.PERSONAL_AUTO:
                const vehicleNumber = _.get(risk, 'vehicleNumber_WMIC');
                const vehicleDescription = `${risk.year ? `${risk.year} ` : ''} ${risk.make ? `${risk.make} ` : ''} ${risk.model ? `${risk.model} ` : ''} &lt;span class="normal"&gt;(${risk.vin})&lt;/span&gt;`
                return translator(messages.flexRiskHeader, {riskNumber: vehicleNumber, displayName: vehicleDescription})
            default:
                WMICLogger.error('Unknown Risk...', risk);
                return '';
        }
    }

    const getSortColumn = () => {
        switch (lob) {
            case LINE_OF_BUSINESS.HOME_OWNER:
                return 'yourHome.dwellingNumber_EXT.value';
            case LINE_OF_BUSINESS.PERSONAL_AUTO:
                return 'vehicleNumber_WMIC.value';
            default:
                return ''
        }
    }

    const getRiskOverrides = (riskData, riskType) => {
        const coverables = _.get(riskData.coverables, `${riskType}s.children`);
        return _.orderBy(coverables, getSortColumn()).map((risk, index) => {
            const riskOverride = {
                [`riskIterableComponent${index}`]: {
                    id: `flexRisk_${index}`,
                    showErrors,
                    viewModelService,
                    onValidate: setComponentValidation,
                    flexVM: risk.flexDTO,
                    updateFlexVM: (flexVM) => {
                        _.set(risk, 'flexDTO.value', flexVM.value);
                        updateModalData(flexModalVM);
                    },
                    riskType: _.capitalize(riskType),
                    riskHeader: WMICRichTextUtil.translateRichText(getRiskHeader(risk.value)),
                    readOnly: !risk.flexDTO.isFlexEditable.value,
                }
            }

            return Object.assign({}, riskOverride);
        })
    }

    const iterableProps = () => {
        switch(lob) {
            case LINE_OF_BUSINESS.HOME_OWNER:
                const dwellingOverrides = getRiskOverrides(_.get(flexModalVM, 'lobData.homeowners'), 'dwelling');
                return Object.assign({}, ...dwellingOverrides);
            case LINE_OF_BUSINESS.PERSONAL_AUTO:
                const vehicleOverrides = getRiskOverrides(_.get(flexModalVM, 'lobData.personalAuto'), 'vehicle');
                return Object.assign({}, ...vehicleOverrides);
            default:
                return null;
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICIterableFlexRiskItem,
            Accordion
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            parentNode: flexModalVM,
        },
        riskIterableContainer: {
            data: getRiskData(flexModalVM)
        },
        ...iterableProps()
    };

    if (isFetching) {
        return <WMICProgressModal
            modalTitle={translator(messages.flexSaving)}
            isOpen={isFetching}
        />
    }

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onResolve}
            className={styles.flexProgramModal}
            contentLayout={{
                component: 'flex',
                componentProps: {
                    direction: 'column',
                    alignContent: 'stretch'
                },
            }}
        >
            <ModalHeader
                icon='gw-info-outline'
                title={messages.flexProgram}
                status='info'
                onClose={onResolve}
                ariaLabel={messages.cancelBtnLabel}
            />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    model={flexModalVM}
                    onModelChange={setFlexModalVM}
                    onValidationChange={setComponentValidation}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter className={styles.flexModalFooter}>
                <WMICButton type='primary-outlined' onClick={onResolve}>
                    {messages.cancelBtnLabel}
                </WMICButton>
                <WMICButton type='primary' onClick={handleRecalc}>
                    {messages.recalculateBtnLabel}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    )
}

export default WMICFlexProgramModal;
