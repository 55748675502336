/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './WMICCancelEditReviewPolicyChangeModal.messages';
import styles from './WMICCancelEditReviewPolicyChangeModal.module.scss';

const WMICCancelEditReviewPolicyChangeModal = ({ onCancelEdit, onReject, onResolve, isOpen }) => {
    const translator = useContext(TranslatorContext);
    return (
        <ModalNext 
            isOpen={isOpen} 
            shouldCloseOnEsc='true'
            onRequestClose={() => { onReject(); }}
        >
            <ModalHeader
                title={translator(messages.areYouSure)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true
                    }
                }}
            />
            <ModalBody>
                <div>
                    <p className={styles['wmic-payment-text']}>
                        {translator(messages.ifYouLeaveNowAnyChangesYouveSelectedWillNotBeSaved)}
                        <br />
                        {translator(messages.areYouSureYouWishToCancel)}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => {  onCancelEdit(); onResolve(); }}
                    type="outlined"
                >
                    {translator(messages.yesCancelChanges)}
                </WMICButton>
                <WMICButton
                    onClick={() => { onReject(); }}
                    type="primary"
                >
                    {translator(messages.noKeepEditing)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICCancelEditReviewPolicyChangeModal.propTypes = {
    onCancelEdit: PropTypes.func.isRequired
};

export default WMICCancelEditReviewPolicyChangeModal;