// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PolicyDocumentError__exclamationIcon{margin-right:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-document-react/pages/PolicyDocumentError/PolicyDocumentError.module.scss"],"names":[],"mappings":"AACA,2CACI,gCAAA","sourcesContent":["\n.exclamationIcon{\n    margin-right: var(--GW-SPACING-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exclamationIcon": "app__PolicyDocumentError__exclamationIcon"
};
export default ___CSS_LOADER_EXPORT___;
