import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'quoteandbind.views.wmicPaymentList.table.header.Name',
        defaultMessage: 'Name',
    },
    downPayment: {
        id: 'quoteandbind.views.wmicPaymentList.table.header.Down payment',
        defaultMessage: 'Initial Payment'
    },
    installment: {
        id: 'quoteandbind.views.wmicPaymentList.table.header.Installment',
        defaultMessage: 'Installments'
    },
    total: {
        id: 'quoteandbind.views.wmicPaymentList.table.header.Total',
        defaultMessage: 'Policy Total'
    },
    paymentPlans: {
        id: 'quoteandbind.views.payment-details.Select A Payment Plan',
        defaultMessage: 'Select A Payment Plan'
    },
    paymentPlan: {
        id: 'quoteandbind.views.payment-details.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    threePayDescription: {
        id: 'quoteandbind.views.payment-details.Three Pay.description',
        defaultMessage: '- A $5.00 installment fee is included within each future installment.'
    },
    monthlyAutomatedRecurringDescription: {
        id: 'quoteandbind.views.payment-details.Monthly Automated Recurring.description',
        defaultMessage: '- Payments will automatically be withdrawn from your financial institution once your Monthly Automated Recurring Payment Authorization form is electronically signed (next step).\n- A $1.00 installment fee is included within each future installment.\n- Installments may be rounded to the nearest cent.'
    },
    onlineServiceUnavailable: {
        id: 'quoteandbind.views.payment-details.Online Service Unavailable',
        defaultMessage: 'The online service to complete this transaction is temporarily unavailable.'
    },
    onlinePaymentPlanUnavailable: {
        id: 'quoteandbind.views.payment-details.Online Payment Plan Unavailable',
        defaultMessage: 'To complete your purchase and enroll in our Monthly Automated Recurring Payment Plan, contact our Quote Specialists directly at {phoneNumber}. Please have your &lt;b&gt;Quote Number {quoteNumber}&lt;/b&gt; ready.\n\n To complete your purchase online, please select another payment plan.'
    }
});
