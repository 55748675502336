import React, {
    useCallback,
    useMemo,
    useContext,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { TranslatorContext } from '@jutro/locale';
import { Flex, FlexItem } from '@jutro/layout';
import { Icon } from '@jutro/components';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICListView, WMICAddressDetails, WMICScrollToError } from 'wmic-pe-components-platform-react';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { CONSTANTS, WMICValidationUtil, WMICVariousUtil, FlowStepId } from 'wmic-pe-portals-utils-js';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICCPLocationsBuildingsPage.metadata.json5';
import messages from './WMICCPLocationsBuildingsPage.messages';
import styles from './WMICCPLocationsBuildingsPage.module.scss';

import WMICLocationDetailView from './WMICLocationDetailView/WMICLocationDetailView';

const LOCATIONS_PATH = 'lobData.commercialProperty.coverables.locations';

function WMICCPLocationsBuildingsPage(props) {
    const translator = useContext(TranslatorContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { isComponentValid, onValidate, initialValidation, registerInitialComponentValidation } = useValidation('WMICCPLocationsBuildingsPage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { setWizardLoading, showConfirm, showError } = useWizardModals();
    const [scrollToError, setScrollToError] = useState(false); // we toggle this when we want to scroll to the first error on the page
    const [showErrors, setShowErrors] = useState(false);
    const [showErrorsLocation, setShowErrorsLocation] = useState(false);
    const [locationVM, setLocation] = useState()
    const [loading, setLoading] = useState(true);

    useDocumentTitle(translator(messages.title), submissionVM);

    const createVM = useCallback((model) => viewModelService.create(
            model,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.LocationDTO',
            submissionVM.aspects.context()
        ), [submissionVM.aspects, viewModelService]);

    const validateInitialComponent = useCallback(() => {
        const locations = _.get(submissionVM, `${LOCATIONS_PATH}.children`);

        return WMICValidationUtil.validatePageEntities(submissionVM, [`${LOCATIONS_PATH}`])
            && _.every(locations, (location) => location.buildings.children.length > 0)
            && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_LOCATIONBUILDINGS);
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialComponent);
    }, [registerInitialComponentValidation, validateInitialComponent]);

    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    useEffect(() => {
        const currentLocations = _.get(submissionVM, `${LOCATIONS_PATH}.value`);
        const primaryLocation = currentLocations.find((location) => location.isPrimary === true)

        setLocation(createVM(primaryLocation));
        setLoading(false);
    }, [createVM,submissionVM]);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.CP_LOCATIONBUILDINGS;
    }, [submissionVM.value]);

    /**
     * Update submission and then navigate to next screen
     */
    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true);
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader)

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_LOCATIONBUILDINGS)) {
                WMICVariousUtil.scrollToTop();
                return false
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);

    const updateLocationVM = useCallback(() => {
        updateWizardData(submissionVM);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM]);

    const toCreateClicked = useCallback(() => {
        const locationAddress = {
            country: CONSTANTS.COUNTRY.CA,
            streetAddressPlaceHolder: '',
            addressLine1: '',
            city: '',
            state: '',
            postalCode: '',
        };

        const newVM = createVM({
            'address': locationAddress,
            'buildings': []
        });

        submissionVM.lobData.commercialProperty.coverables.value.locations.push(newVM.value)
        setLocation(newVM)

        setShowErrorsLocation(false)
        setShowErrors(false);
        updateWizardData(submissionVM);

        return newVM;
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createVM, submissionVM]);

    const getAddress = useCallback((index) => {
        const addressline = index.address.addressLine1.value
        const city = index.address.city.value
        let province
        if (index.address.state.value != null) {
            province = index.address.state.value.code
        }
        return `${addressline}, ${city}, ${province}`
    }, []);

    const saveSubmission = useCallback(async (newSubmission) => {

        setWizardLoading(true);

        return LoadSaveService.updateDraftSubmission(
            newSubmission,
            authHeader
        ).then((result) => {
            submissionVM.value = result;
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_LOCATIONBUILDINGS)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        }).finally(() => {
            setWizardLoading(false);
        });
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, setWizardLoading, submissionVM]);



    const onDeleteLocation = useCallback(async (locVM, index) => {
        if (!locVM.isPrimary.value) {
            const name = getAddress(locVM)
            const response = await showConfirm({
                title: translator(messages.removeLocationShort),
                message: translator(messages.removeLocationLong, { displayName: name })
            })
            if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const newSubmission = _.cloneDeep(submissionVM.value);
                const currentLocations = _.get(newSubmission, LOCATIONS_PATH)
                currentLocations.splice(index, 1)
                _.set(newSubmission, LOCATIONS_PATH, currentLocations);
                return saveSubmission(newSubmission)
            }
        } else {
            showError({
                title: translator(messages.error),
                message: translator(messages.removePrimaryLocationError),
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            });
        }
    }, [getAddress, saveSubmission, showConfirm, submissionVM, translator, showError]);

    const onSaveClicked = useCallback(async (locVM, index) => {
        if (locVM.aspects.valid && locVM.aspects.subtreeValid) {
            const locations = _.get(submissionVM, LOCATIONS_PATH)

            if (locVM.value.isPrimary) {
                for(let locationIndex = 0; locationIndex < locations.value.length; locationIndex++) {
                    const location = locations.value[locationIndex];
                    if(location.publicID !== locVM.value.publicID) {
                        location.isPrimary = false;
                    }
                }
            }

            if (locVM.publicID.value != null) {
                locations[index] = locVM.value
            }

            return saveSubmission(submissionVM.value).then((result) => {
                setLocation(_.get(submissionVM, `${LOCATIONS_PATH}.value[${index}]`));
                return result
            })
        } else {
            setShowErrorsLocation(true);
        }
    }, [saveSubmission, submissionVM]);

    const getName = useCallback((location) => `${translator(messages.locationNumber)}${location.locationNum.value}`, [translator]);

    const getNumber = useCallback((location) => location.locationNum.value, []);

    const anyLocationEdgeLayerErrors = useMemo(() => {
        const issues = _.get(submissionVM, 'errorsAndWarnings.validationIssues.issues.value', []);
        return _.some(issues, (issue) => issue.flowStepId.value === 'CPLocations');
    }, [submissionVM]);


    const overrideProps = {
        '@field': {
            parentNode: locationVM
        },
        locationListView: {
            clickable: true,
            startOpen: true,
            readOnly: false,
            editEnabled: true,
            value: _.get(submissionVM, `${LOCATIONS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.number),
                    getData: getNumber,
                },
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.address),
                    getData: getAddress
                },
                {
                    headerText: translator(messages.primaryLocation),
                    path: 'isPrimary'
                },
                {
                    isVerticallyCentered: true,
                    visibilityCondition: (data) => anyLocationEdgeLayerErrors && data.buildings.children.length === 0,
                    getData: () => <Flex gap="small" justifyContent="right">
                                    <FlexItem alignSelf="center" className={styles.inlineMessageContainer}>
                                        <div className={styles.messageContainer}>
                                            <Icon icon='mi-error' className={styles.messageIcon} />
                                            { translator(messages.addBuilding) }
                                        </div>
                                    </FlexItem>
                                </Flex>
                }
            ],
            detailViewComponent: WMICLocationDetailView,
            detailViewComponentProps: {
                jobVM: submissionVM,
                updateWizardData,
                showErrorsLocation
            },
            onValidate,
            toCreate: toCreateClicked,
            toUndoCreate: () => {
                const locations = _.get(submissionVM.value, LOCATIONS_PATH)
                locations.splice(locations.length-1, 1);
                _.set(submissionVM.value, LOCATIONS_PATH, locations);
                setShowErrorsLocation(false)
                updateWizardData(submissionVM);
            },
            onDelete: onDeleteLocation,
            onSave: onSaveClicked,
            showErrors
        },
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICListView,
            WMICAddressDetails
        }
    };

    if (loading) {
        return null;
    }

    return (
        <WMICWizardSubmissionPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={commonMessages.saveAndExit}
            onNext={onNext}
            flowStepId={FlowStepId.CP_LOCATIONBUILDINGS}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={locationVM}
                overrideProps={overrideProps}
                onModelChange={updateLocationVM}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICCPLocationsBuildingsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};


export default WMICCPLocationsBuildingsPage;
