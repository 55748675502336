import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import _ from "lodash";
import styles from './WMICDataView.module.scss';

const dataListPropTypes = {
    /**
     * This component will be updated to use the new DataView Jutro Component when available
     */
    VMList: PropTypes.array.isRequired,
    VMData: PropTypes.arrayOf(
        PropTypes.shape({
            headerText: PropTypes.string,
            path: PropTypes.string,
        })
    ).isRequired,
};

function WMICDataView(props) {
    const {
        VMList,
        VMData
    } = props;

    if (_.isEmpty(VMList) || _.isEmpty(VMData)) {
        return null;
    }

    const dataTableProps = useMemo(()=> {
        return {
            data: VMList,
            showSearch: false,
            showPagination: false,
            columnsConfigurable: false,
            ...props
        }
    }, [VMList]);

    const displayColumns = useMemo(()=> {
        const columns = VMData.map((column, columnIndex) => {
            return {
                id: `column_${columnIndex}`,
                sortable: false,
                clickable: false,
                path: `${column.path}`,
                header: column.headerText,
                renderCell: column.renderCell,
                columnProportion: column.columnProportion?? 1,
                visible: column.visible
            }
        })

        return columns.map((column) =>
            <DisplayColumn {...column} />
        )
    }, [VMData]);


    return VMList !== undefined && VMList.length > 0 ?
        <div className={styles.simpleTableWMIC}>
            <DataTable {...dataTableProps}>
                {displayColumns}
            </DataTable>
        </div>
        : null;
}

WMICDataView.propTypes = dataListPropTypes;

export default WMICDataView;
