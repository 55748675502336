/* eslint-disable no-param-reassign */
import React, { useMemo, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import util from './WMICAdjusterInfoBoxUtil';

import metadata from './WMICAdjusterInfoBox.metadata.json5';
import styles from './WMICAdjusterInfoBox.module.scss';
import messages from './WMICAdjusterInfoBox.messages';

const WMICAdjusterInfoBox = (props) => {
    const { claimData: claimInfo } = props;
    
    const translator = useTranslator();

    const [adjusters] = useState(util.getAdjusters(claimInfo));

    const getListAdjusterCoverages = (adjuster) => util.getListAdjusterCoverages(adjuster);

    const getScreenReaderMessage = useCallback((adj) => {
        let message = '';

        message += translator(messages.NameAria, {name: adj?.name});

        if (adj.phoneNumber) {
            message +=` ${  translator(messages.PhoneNumberAria, {phoneNumber: adj?.phoneNumberDisplayable})}`;
        }

        if (adj.email) {
            message += ` ${  translator(messages.EmailAddressAria, {email: adj?.email})}`;
        }

        const listAdjusterCoverages =  getListAdjusterCoverages(adj);

        if (listAdjusterCoverages.length > 0) {
            message += ` ${  translator(messages.ContactAboutAria, {adjusters: listAdjusterCoverages.join(' ')})}`;
        }
        
        return message;
    }, [translator]);

    const iterableOverrides = useMemo(() => {
        const overrides = adjusters.map((adj, index) => {
            const srMessage = getScreenReaderMessage(adj);

            const listAdjusterCoverages =  getListAdjusterCoverages(adj);

            return ({
                [`adjusterNameInput${index}`]: {
                    value: adj?.name
                },
                [`adjusterPhoneNumberColumn${index}`]: {
                    visible: !!adj?.phoneNumber,
                    onClick: () => { window.location.href = `tel:${adj?.phoneNumber}`; }
                },
                [`adjusterPhoneNumberInput${index}`]: {
                    value: adj?.phoneNumberDisplayable
                },
                [`adjusterEmailAdressColumn${index}`]: {
                    visible: !!adj?.email,
                    onClick: () => { window.location.href = `mailto:${adj?.email}`; }
                },
                [`adjusterEmailAddressInput${index}`]: {
                    value: adj?.email
                },
                [`adjusterContactAboutColumn${index}`]: {
                    visible: listAdjusterCoverages.length > 0
                },
                [`adjusterContactAboutInput${index}`]: {
                    value: listAdjusterCoverages
                },
                [`adjusterDetailAria${index}`]: {
                    content: srMessage
                }
            })});
            
        return Object.assign({}, ...overrides);
    }, [adjusters, getScreenReaderMessage]);

    const overrides = {
        adjusterIterable: {
            data: adjusters
        },
        ...iterableOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrides}
            model={claimInfo}
        />
    );
};

WMICAdjusterInfoBox.propTypes = {
    claimData: PropTypes.shape({})
};

WMICAdjusterInfoBox.defaultProps = {
    claimData: {}
};

export default WMICAdjusterInfoBox;