import React, { Fragment, useCallback } from 'react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import messages from './WMICContactUs.messages';
import styles from './WMICContactUs.module.scss';



const WMICContactUs = () => {
    const translator = useTranslator();
    const { errorCode } = useParams();
    const history = useHistory();

    const getError = errorCode ? `error${errorCode}` : 'ifYouHaveQuestion';

    const onBtnClick = useCallback(() => history.push('/'), [history]);

    return (
        <Fragment>
            <div className="ww-content-limit ww-page-header">
                <div className="ww-card--100w">
                    <h1>{translator(messages.contactUs)}</h1>
                </div>
            </div>

            <div className="ww-content-limit ww-main-content">
                <div className={`${styles.wwCard66w} ww-card-vertical-root`}>
                    <div className="ww-card ww-card-footer" id="policy-details">
                        <div className="ww-card__content">
                            <p>
                                <span>{WMICRichTextUtil.translateRichText(translator(messages[getError]))}</span>
                                <br />
                                <br />
                                {translator(messages.WeLikeToHelp)}
                            </p>
                            <br />
                            <div>
                                <WMICButton type="primary" onClick={onBtnClick}>
                                    {translator(messages.backToHome)}
                                </WMICButton>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
};

WMICContactUs.propTypes = {
    data: PropTypes.shape({})
};

WMICContactUs.defaultProps = {
    data: {}
};

export default WMICContactUs;
