// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__RenewalWarning__warningIcon{color:var(--GW-COLOR-WARNING)}.app__RenewalWarning__warningIconContainer{display:flex}.app__RenewalWarning__warningContainer{margin-bottom:var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-policychange-common-react/components/RenewalWarning/RenewalWarning.module.scss"],"names":[],"mappings":"AAAA,kCACI,6BAAA,CAEJ,2CACI,YAAA,CAEJ,uCACI,gCAAA","sourcesContent":[".warningIcon {\n    color: var(--GW-COLOR-WARNING);\n}\n.warningIconContainer {\n    display: flex;\n}\n.warningContainer {\n    margin-bottom: var(--GW-LAYOUT-4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningIcon": "app__RenewalWarning__warningIcon",
	"warningIconContainer": "app__RenewalWarning__warningIconContainer",
	"warningContainer": "app__RenewalWarning__warningContainer"
};
export default ___CSS_LOADER_EXPORT___;
