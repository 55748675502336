/* eslint-disable no-warning-comments */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import {
    WMICHOPolicyCoverages,
    WMICHODwellingCoverages,
    WMICHOWatercraftCoverages
} from 'wmic-pe-capability-gateway-common-ho-react';

import appConfig from 'app-config';
import { useDocumentTitle, useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { FlowStepId } from "wmic-pe-portals-utils-js";
import WMICHOCoverageUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil";
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import messages from './WMICHOCoveragesPage.messages';
import styles from './WMICHOCoveragesPage.module.scss';
import metadata from './WMICHOCoveragesPage.metadata.json5';


const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';
const WATERCRAFT_PATH = 'lobData.homeowners.coverables.watercrafts';

const structureCustomQuote = (submissionVM, affectedQuote, clauses) =>
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    })
;

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.homeowners.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    return lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];
        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            }
        };
    }).filter(({ quote }) => !_.isUndefined(quote.data));
};

const getSelectedRiskId = (submissionVM, basePath) => {
    const riskPath = basePath.substring(0, basePath.indexOf('.coverages.children['));
    const riskVM = _.get(submissionVM, `${riskPath}.value`);
    return _.get(riskVM, 'publicID');
}

const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

function WMICHOCoveragesPage(props) {
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState(); // we toggle this when we want to scroll to the first error on the page

    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { setWizardLoading } = useWizardModals();
    const { authHeader } = useAuthentication();
    const {
        disregardFieldValidation,
        registerInitialComponentValidation,
        initialValidation,
        onValidate,
        isComponentValid} = useValidation('QuotePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const {
        wizardSnapshot,
        wizardData: submissionVM,
        updateWizardData,
        underwritingIssues,
    } = props;

    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);
    const initialized = useRef(false);

    useDocumentTitle('Coverages', submissionVM);

    // reset show errors to false whenever the component is back to a valid state or we stop editing
    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    useEffect(() => {
        registerInitialComponentValidation(() => WMICHOCoverageUtil.isDwellingSupportingCoverageDataValid(submissionVM));
    }, [])

    useEffect(() => {
        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));
        initialized.current = true;
        // Store the initial SubmissionVM when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.HO_COVERAGES;
    }, [submissionVM.value]);

    const writeValue = (value, path) => {
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }

    const onUpdateCustomQuote = (_basePath, lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);
        return CustomQuoteService.updateCustomQuoteCoveragesAndQuoteData(customQuote, submissionVM.value, authHeader).then(
            (response) => {
                const riskID = getSelectedRiskId(submissionVM, _basePath);
                const dwellingCoverageIndex = _.get(submissionVM, `${lobPath}.coverages.dwellingCoverages.value`).findIndex((dwellingCoverage) => dwellingCoverage.publicID === riskID);
                const watercraftCoverageIndex = _.get(submissionVM, `${lobPath}.coverages.watercraftCoverages.value`).findIndex((watercraftCoverage) => watercraftCoverage.publicID === riskID);

                const newSubmissionVM = viewModelService.clone(submissionVM);
                newSubmissionVM.value = response;
                
                const removedFieldsFromLineCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.lineCoverages`
                );
                const removedFieldsFromDwellingCoverages = dwellingCoverageIndex >= 0 ? ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.dwellingCoverages.children[${dwellingCoverageIndex}].coverages`
                ): [];
                const removedFieldsFromWatercraftCoverages = watercraftCoverageIndex >= 0 ?
                    ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.watercraftCoverages.children[${watercraftCoverageIndex}].coverages`
                    ): []

                const allRemovedFields = [
                    ...removedFieldsFromLineCoverages,
                    ...removedFieldsFromDwellingCoverages,
                    ...removedFieldsFromWatercraftCoverages
                ];
                disregardFieldValidation(allRemovedFields);
                updateWizardData(newSubmissionVM);
            }
        );
    }

    const syncCoverages = (value, changedPath, lobPath, quotePath) => {
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        // const baseObject = _.get(submissionVM, basePath);
        // const clauses = _.get(submissionVM, `coverages.value`);

        // if (ClausesUtil.shouldClauseUpdate(baseObject, clauses)) {
        return onUpdateCustomQuote(basePath, lobPath, quotePath);
        // }
    }

    const onScheduleChange = (schedule, path) => {
        const lobOfferingPath = 'lobData.homeowners.offerings.children[0]';
        const quoteOfferingPath = 'quoteData.offeredQuotes.children[0]';
        writeValue(schedule, `${path}.value`);
        const offering = _.get(submissionVM, `${lobOfferingPath}.value`);
        setStaleQuoteBranchCode(offering.branchCode);
        return onUpdateCustomQuote({}, lobOfferingPath, quoteOfferingPath);
    }

    const changeSubmission = (value, changedPath) => {
        updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
    }

    const changeSubmissionAndSync = (value, changedPath, lobPath, quotePath) => {
        changeSubmission(value, changedPath);
        return syncCoverages(value, changedPath, lobPath, quotePath);
    }

    const resetQuote = (lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

        return CustomQuoteService.forceUpdateCustomQuoteCoveragesAndQuoteData(customQuote, initialSubmissionVM.value, authHeader).then(
            (response) => {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                newSubmissionVM.value = response;
                updateWizardData(newSubmissionVM);
            }
        );
    }

    const recalculate = (lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

        return CustomQuoteService.updateCustomQuoteAndQuoteData(customQuote, authHeader).then(
            (response) => {
                setStaleQuoteBranchCode(undefined);
                const newSubmissionVM = viewModelService.clone(submissionVM);
                newSubmissionVM.value = response;
                updateWizardData(newSubmissionVM);
                return response;
            }
        );
    }

    const columnData = generateColumnData(submissionVM);

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    const commonOverrides = {
        'coverageColumnData': columnData,
        'quoteID': submissionVM.quoteID.value,
        'changeSubmissionAndSync': changeSubmissionAndSync,
        'changeSubmission': changeSubmission,
        'syncCoverages': syncCoverages,
        'recalculate': recalculate,
        'resetQuote': resetQuote,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'onValidate': onValidate,
        'showErrors': showErrors,
        'readOnly': false

    }

    const _iterableProps = () => {
        const dwellingOverrides = _.get(submissionVM, `${DWELLINGS_PATH}.children`).map((dwelling, index) => {
            const dwellingCoverages = WMICHOCoverageUtil.getDwellingCoveragesForRisk(_.get(submissionVM, 'lobData.homeowners'), dwelling.publicID.value);
            const dwellingOverride = {
                [`dwellingCoveragesIterableComponent${index}`]: {
                    'dwellingVM': dwelling,
                    'dwellingCoverages': dwellingCoverages,
                    'dwellingIndex': index,
                    'baseData': submissionVM.baseData,
                    jobVM: submissionVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, dwellingOverride);
        })

        const watercraftOverrides = _.get(submissionVM, `${WATERCRAFT_PATH}.children`).map((watercraft, index) => {
            const watercraftCoverages = WMICHOCoverageUtil.getWatercraftCoveragesForRisk(_.get(submissionVM, 'lobData.homeowners'), watercraft.publicID.value);
            const watercraftOverride = {
                [`watercraftCoveragesIterableComponent${index}`]: {
                    'watercraftVM': watercraft,
                    'watercraftCoverages': watercraftCoverages,
                    'watercraftIndex': index,
                    ...commonOverrides
                }
            }
            return Object.assign({}, watercraftOverride);
        })

        return Object.assign({}, ...dwellingOverrides, ...watercraftOverrides);
    }

    const overrideProps = {
        policyCoveragesContainer: {
            jobVM: submissionVM,
            lineCoverages: _.get(submissionVM, 'lobData.homeowners.offerings.children[0].coverages.lineCoverages'),
            ...commonOverrides
        },
        dwellingCoveragesIterableAccordionContainer: {
            data: _.get(submissionVM, `${DWELLINGS_PATH}.children`)
        },
        watercraftCoveragesIterableAccordionContainer: {
            visible: (_.get(submissionVM, `${WATERCRAFT_PATH}.children`, []).length > 0),
            data: _.get(submissionVM, `${WATERCRAFT_PATH}.children`)
        },
        startDate: {
            visible: showQuoteStartDate
        },
        printPage: {
            visible: !showQuoteStartDate
        },
        ..._iterableProps()
    };

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now()); // force scroll to first error
                return false;
            }
            setWizardLoading(true);
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader)
            updateWizardData(submissionVM);
            return submissionVM;
        } catch (error) {
            WMICErrorHandler.processAsModal(error);
            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onChangeSubmission: changeSubmission,
            onSyncCoverages: syncCoverages,
            onScheduleChange,
            onStaleQuoteBranchCode,
            onPrint: window.print
        },
        resolveComponentMap: {
            WMICHODwellingCoverages,
            WMICHOPolicyCoverages,
            WMICHOWatercraftCoverages,
            Accordion
        }
    };

    return initialized ? (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
            flowStepId={FlowStepId.HO_COVERAGES}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    ) : null;
}

WMICHOCoveragesPage.propTypes = wizardProps;
export default WMICHOCoveragesPage;
