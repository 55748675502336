import React, { Component } from 'react';
import { Chevron } from '@jutro/components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import _ from 'lodash';
import {
    TranslatorContext, withIntl
} from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PolicyService, UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { JobUtil, DatatableUtil } from 'wmic-portals-utils-js';
import { WMICLink } from 'wmic-pe-components-platform-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import CoveragesIterableComponent from '../../Components/CoveragesIterableComponent/CoveragesIterableComponent';
import PolicyDwellingInfo from './ho/PolicyDwellingInfo/PolicyDwellingInfo';
import PolicyVehiclesDriversInfo from './pa/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import Cancellation from '../Cancellation/Cancellation';
import ChangePolicy from '../ChangePolicy/ChangePolicy';
import metadata from './Summary.metadata.json5';
import styles from './Summary.module.scss';
import messages from './Summary.messages';
import gatewayMessages from '../../gateway.messages';

import { Link } from '@jutro/router';

function getCell(items, index, property) {
    return items[property.id];
}

function transactionTypeIsViewableInXCenter(transaction) {
    return (
        transaction.type.toLowerCase() !== 'reinstatement'
        && transaction.type.toLowerCase() !== 'rewrite'
    );
}

function canUserOpenJobInXCenter(transaction) {
    return transaction.canUserView && transactionTypeIsViewableInXCenter(transaction);
}

class Summary extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string
                })
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired,
    };

    static contextType = TranslatorContext;

    state = {
        policyData: '',
        disableCancelLink: true,
        disableChangePolicyLink: true,
        policyTransactionData: [],
        policyTransactionStatusValues: [],
        selectedTransactionStatus: 'all',
        searchTransactionKeyword: undefined,
        canAdvanceCancellation: false,
        canEditPolicy: false
    };

    componentDidMount = () => {
        this.hasAdvanceCancellationPermission();
        this.hasEditPolicyPermission();
        this.setPolicyCoveragesDetails();
        this.getPolicyTransactions();
    };

    setPolicyCoveragesDetails = () => {
        const {
            fromAccountLanding: { policyDetailsData }
        } = this.props;
        const policyData = _.cloneDeep(policyDetailsData.policyResponse);
        this.setState({ policyData });
    };

    getPolicyTransactions = async () => {
        const { authHeader, fromAccountLanding } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const transactionResponse = await PolicyService.getPolicyTransactionsForPolicy(
            policyNumber,
            authHeader
        );
        this.getMappedTransactionData(transactionResponse);
        const policyTransactionStatusValues = this.getPolicyTransactionStatus();
        this.setState({
            transactionResponse,
            policyTransactionStatusValues,
            selectedTransactionStatus: policyTransactionStatusValues[0].code
        });
    };

    getFormattedCurrency = (premiumAmount, index) => {
        return (
            <CurrencyField
                id={`currency${index}`}
                value={premiumAmount}
                readOnly
                hideLabel
                showOptional={false}
                className={styles.currencyStyle}
            />
        );
    };

    getMappedTransactionData = (policyTransactionResponse, filter) => {
        const selectedStatus = _.get(filter, 'selectedTransactionStatus');
        const searchKeyword = _.get(filter, 'searchTransactionKeyword');
        const transactionData = _.cloneDeep(policyTransactionResponse);

        let policyTransactionData = transactionData.map((policy, index) => {
            return {
                jobNumber: policy.jobNumber,
                status: policy.status,
                displayType: policy.displayType,
                policyDisplayStatus: policy.policyDisplayStatus,
                totalPremium: this.getFormattedCurrency(policy.totalPremium, index),
                policyEffectiveDate: policy.policyEffectiveDate,
                canUserView: policy.canUserView,
                type: policy.type
            };
        }).sort((a, b) => {
            return new Date(b.policyEffectiveDate).getTime()
                - new Date(a.policyEffectiveDate).getTime();
        });
        if (selectedStatus && selectedStatus !== 'all') {
            policyTransactionData = _.filter(policyTransactionData, (transaction) => {
                return transaction.status === selectedStatus;
            });
        }
        if (searchKeyword) {
            policyTransactionData = this.getSearchFilterValues(
                policyTransactionData,
                searchKeyword
            );
        }
        this.setState({
            policyTransactionData
        });
    };

    hasAdvanceCancellationPermission = () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'advancecancellation'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
            (canAdvanceCancellation) => {
                this.setState({ canAdvanceCancellation });
            }
        );
    };

    hasEditPolicyPermission = () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'editpolchange'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
            (canEditPolicy) => this.setState({ canEditPolicy })
        );
    };

    getSearchFilterValues = (transactionData, keyword) => {
        const lowerCaseFilterValue = keyword.toLocaleLowerCase();
        return _.filter(transactionData, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    getJobNumberLink = (item, index, property) => {
        if (canUserOpenJobInXCenter(item)) {
            if (this.showOpenJobInXCenter(item)) {
                return (
                    <WMICLink
                        href="/"
                        onClick={() => JobUtil.openJobInXCenter(item.jobNumber)}
                    >
                        {item[property.id]}
                    </WMICLink>
                );
            }
            return (
                <WMICLink to={JobUtil.getJobDetailURLByJobType(item.type, item.jobNumber)}>
                    {item[property.id]}
                </WMICLink>
            );
        }
        return <span>{item.jobNumber}</span>;
    };

    showOpenJobInXCenter = (item) => {
        return (
            item.type !== 'Submission'
            && item.type !== 'PolicyChange'
            && item.type !== 'Cancellation'
            && item.type !== 'Renewal'
        );
    };

    onChangePolicy = async () => {
        const { policyData } = this.state;
        const { fromAccountLanding, history } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const { lobEndorsementURL, endorsementProducts } = appConfig;
        const productCode = _.get(policyData, 'product.productCode');
        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode], { policyNumber });
        } else {
            this.setState({ disableChangePolicyLink: false });
        }
    };

    getJobNumber = async (policyNumber) => {
        const { authHeader } = this.props;
        const renewalVM = await PolicyService.createNewPolicyRenewalTransaction(
            [policyNumber],
            authHeader
        );
        return _.get(renewalVM, 'jobNumber');
    };

    onRenewalPolicy = async () => {
        const { fromAccountLanding, history } = this.props;
        const { lobRenewalURL } = appConfig;
        const policy = _.get(fromAccountLanding, 'policyDetailsData.policyResponse');
        const policyNumber = _.get(policy, 'policyNumber');
        const lobCode = _.get(policy, 'latestPeriod.policyLines[0].lineOfBusinessCode');
        const jobNumber = await this.getJobNumber(policyNumber);
        if (!_.isEmpty(lobRenewalURL[lobCode])) {
            history.push(lobRenewalURL[lobCode], { jobNumber });
        } else {
            JobUtil.openJobInXCenter(jobNumber);
        }
    };

    handleCancelButtonClick = () => {
        this.setState({ disableCancelLink: false });
    };

    onDoNotCancel = () => {
        this.setState({ disableCancelLink: true });
    };

    onDoNotChangePolicy = () => {
        this.setState({ disableChangePolicyLink: true });
    };

    getResponse = async () => {
        const result = await PolicyService.getPolicy();
        return result;
    };

    getHeaderSection = (title) => {
        return (isOpen) => (
            <React.Fragment>
                <Chevron isOpen={isOpen} />
                <h2 className={`${styles.title} ${styles.gwOverrideDefaultMargin}`}>{title}</h2>
            </React.Fragment>
        );
    };

    isGeneralCoverage = () => {
        const { policyData } = this.state;
        return (
            [
                'PersonalAuto',
                'Homeowners',
                'HOPHomeowners',
                'homeowners',
                'HomeownersLine_HOE'
            ].indexOf(policyData.product.productCode) === -1
        );
    };

    getPolicyTransactionStatus = () => {
        return [
            {
                code: 'all',
                name: gatewayMessages.all
            },
            {
                code: 'Draft',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Draft',
                    defaultMessage: 'Draft'
                }
            }
        ];
    };

    handleFilterValueChange = (value) => {
        const { transactionResponse, searchTransactionKeyword } = this.state;
        this.setState({ selectedTransactionStatus: value });
        this.getMappedTransactionData(transactionResponse, {
            selectedTransactionStatus: value,
            searchTransactionKeyword
        });
    };

    handleSearchValueChange = (value) => {
        const { transactionResponse, selectedTransactionStatus } = this.state;
        this.setState({ searchTransactionKeyword: value });
        this.getMappedTransactionData(transactionResponse, {
            searchTransactionKeyword: value,
            selectedTransactionStatus
        });
    };

    generateOverrides = () => {
        const { policyData } = this.state;
        const overrides = policyData.coverables.map((coverable, i) => {
            return {
                [`namesGrid${i}`]: {
                    visible: !_.isEmpty(coverable.coverages)
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    render() {
        const {
            policyData,
            disableCancelLink,
            policyTransactionData,
            policyTransactionStatusValues,
            selectedTransactionStatus,
            searchTransactionKeyword,
            disableChangePolicyLink,
            canAdvanceCancellation,
            canEditPolicy
        } = this.state;
        const { authHeader, history, fromAccountLanding } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const productCode = _.get(policyData, 'product.productCode');
        if (_.isEmpty(policyData)) {
            return null;
        }
        const translator = this.context;
        const preRenewalDirection = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.latestPeriod.preRenewalDirection'
        );
        const overrides = {
            summaryPolicyTransactionsTableGrid: {
                data: policyTransactionData
            },
            coveragesSectionContainer: {
                visible: this.isGeneralCoverage()
            },
            coveragesSection: {
                renderHeader: this.getHeaderSection(translator(messages.Coverages))
            },
            cancellationContacts: {
                visible: !disableCancelLink && disableChangePolicyLink,
                history,
                authHeader,
                policyNumber: policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyContacts: {
                visible: !disableChangePolicyLink && disableCancelLink,
                history,
                authHeader,
                policyNumber: policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible: !policyData.latestPeriod.canceled && policyData.latestPeriod.canChange && canEditPolicy
            },
            renewPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible: !policyData.latestPeriod.canceled && policyData.latestPeriod.canRenew
            },
            cancelPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible:
                    !policyData.latestPeriod.canceled
                    && policyData.latestPeriod.canCancel
                    && canAdvanceCancellation
            },
            policyVehicleInfo: {
                visible: policyData.product.productCode === 'PersonalAuto',
                value: policyData.lobs.personalAuto
            },
            policyHouseOwnersInfo: {
                visible:
                    productCode === 'Homeowners'
                    || productCode === 'HOPHomeowners'
                    || productCode === 'homeowners'
                    || productCode === 'HomeownersLine_HOE',
                value: policyData.lobs.homeOwners
            },
            policyTransactionStatus: {
                availableValues: policyTransactionStatusValues,
                value: selectedTransactionStatus
            },
            searchFilter: {
                value: searchTransactionKeyword
            },
            preRenewalDirectionValueId: {
                value: !_.isNil(
                    preRenewalDirection
                ) ? [preRenewalDirection]
                    : translator(messages.SummaryDetailsPreRenewalDirectionNone)
            },
            producerOfRecordValueId: {
                value: `${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfRecordOrg'
                )}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfRecord'
                )})`
            },
            producerOfServiceValueId: {
                value: `${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfServiceOrg'
                )}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfService'
                )})`
            },
            ...this.generateOverrides()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: getCell,
                getJobNumberLink: this.getJobNumberLink,
                onChangePolicy: this.onChangePolicy,
                onRenewalPolicy: this.onRenewalPolicy,
                handleCancelButtonClick: this.handleCancelButtonClick,
                onDoNotCancel: this.onDoNotCancel,
                handleFilterValueChange: this.handleFilterValueChange,
                handleSearchValueChange: this.handleSearchValueChange,
                onDoNotChangePolicy: this.onDoNotChangePolicy,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortCurrency: DatatableUtil.sortCurrency,
                sortNumber: DatatableUtil.sortNumber
            },
            resolveComponentMap: {
                cancelcomponent: Cancellation,
                policypacomponent: PolicyVehiclesDriversInfo,
                changepolicycomponent: ChangePolicy,
                policydwellingcomponent: PolicyDwellingInfo,
                coveragesiterablecomponent: CoveragesIterableComponent,
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, policyData, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        );
    }
}

export const SummaryComponent = Summary;
export default withRouter(withIntl(withAuthenticationContext(Summary)));
