import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
import 'wmic-pe-capability-gateway-policyrenewal-common-react'
import WMICPUPPolicyRenewalPolicyDetailsPage from './pages/PolicyDetails/WMICPUPPolicyRenewalPolicyDetailsPage';
import WMICPUPRenewalHouseholdMemberPage from './pages/WMICPUPRenewalHouseholdMemberPage/WMICPUPRenewalHouseholdMemberPage';
import WMICPUPPolicyRenewalsUnderlyingPoliciesPage from './pages/UnderlyingPolicies/WMICPUPPolicyRenewalsUnderlyingPoliciesPage';
import { WMICPolicyRenewalQuote } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import './WMICPEPUPRenewalWizard.messages';

setComponentMapOverrides(
    {
        WMICPUPPolicyRenewalPolicyDetailsPage: { component: 'WMICPUPPolicyRenewalPolicyDetailsPage' },
        WMICPUPPolicyRenewalsUnderlyingPoliciesPage: { component: 'WMICPUPPolicyRenewalsUnderlyingPoliciesPage' },
        WMICPUPRenewalHouseholdMemberPage: { component: 'WMICPUPRenewalHouseholdMemberPage' },
        WMICPolicyRenewalQuote: { component: 'WMICPolicyRenewalQuote'}
    },
    {
        WMICPUPPolicyRenewalPolicyDetailsPage,
        WMICPUPPolicyRenewalsUnderlyingPoliciesPage,
        WMICPUPRenewalHouseholdMemberPage,
        WMICPolicyRenewalQuote
    }
);
// eslint-disable-next-line import/prefer-default-export
export { default as WMICPEPUPRenewalWizard } from './WMICPEPUPRenewalWizard';
export { default as messages } from './WMICPEPUPRenewalWizard.messages';
export { default as WMICPUPPolicyRenewalsUnderlyingPoliciesPage } from './pages/UnderlyingPolicies/WMICPUPPolicyRenewalsUnderlyingPoliciesPage';
