// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICCPLocationsBuildingsPage__inlineMessageContainer{display:flex;position:relative}.app__WMICCPLocationsBuildingsPage__inlineMessageContainer .app__WMICCPLocationsBuildingsPage__messageContainer{align-items:center;color:var(--wmic-warning-alert-color);display:flex;gap:3px}.app__WMICCPLocationsBuildingsPage__inlineMessageContainer .app__WMICCPLocationsBuildingsPage__messageContainer .app__WMICCPLocationsBuildingsPage__messageIcon{font-size:1rem}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-quoteandbind-cp-react/pages/WMICCPLocationsBuildingsPage/WMICCPLocationsBuildingsPage.module.scss"],"names":[],"mappings":"AACA,2DAEE,YAAA,CADA,iBACA,CAEA,gHAEI,kBAAA,CAEA,qCAAA,CADA,YAAA,CAFA,OAGA,CAEA,gKACI,cAAA","sourcesContent":["\n.inlineMessageContainer {\n  position: relative;\n  display: flex;\n\n  .messageContainer {\n      gap: 3px;\n      align-items: center;\n      display: flex;\n      color: var(--wmic-warning-alert-color);\n\n      .messageIcon {\n          font-size: 1rem;\n      }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineMessageContainer": "app__WMICCPLocationsBuildingsPage__inlineMessageContainer",
	"messageContainer": "app__WMICCPLocationsBuildingsPage__messageContainer",
	"messageIcon": "app__WMICCPLocationsBuildingsPage__messageIcon"
};
export default ___CSS_LOADER_EXPORT___;
