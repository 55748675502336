import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
import WMICPCPAPolicyDetailsPage from './pages/PolicyDetails/WMICPCPAPolicyDetailsPage';
import WMICPCPADriversDetailsPage from './pages/Drivers/WMICPCPADriversDetailsPage';
import WMICPCPAVehiclesPage from './pages/Vehicles/WMICPCPAVehiclesPage';
import WMICPCPACoveragesPage from './pages/Coverages/WMICPCPACoveragesPage';
import WMICPCPAInsuranceHistoryPage from './pages/InsuranceHistory/WMICPCPAInsuranceHistoryPage';

setComponentMapOverrides(
    {
        WMICPCPAPolicyDetailsPage: { component: 'WMICPCPAPolicyDetailsPage' },
        WMICPCPADriversDetailsPage: { component: 'WMICPCPADriversDetailsPage' },
        WMICPCPAVehiclesPage: { component: 'WMICPCPAVehiclesPage' },
        WMICPCPACoveragesPage: { component: 'WMICPCPACoveragesPage' },
        WMICPCPAInsuranceHistoryPage: { component: 'WMICPCPAInsuranceHistoryPage' },
    },
    {
        WMICPCPAPolicyDetailsPage,
        WMICPCPADriversDetailsPage,
        WMICPCPAVehiclesPage,
        WMICPCPACoveragesPage,
        WMICPCPAInsuranceHistoryPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PAPolicyChangeMessages } from './WMICPEPAPolicyChangeWizard.messages';
export { default as WMICPEPAPolicyChangeWizard } from './WMICPEPAPolicyChangeWizard';
export { default as WMICPCPAPolicyDetailsPage } from './pages/PolicyDetails/WMICPCPAPolicyDetailsPage';
export { default as WMICPCPADriversDetailsPage } from './pages/Drivers/WMICPCPADriversDetailsPage';
export { default as WMICPCPAVehiclesPage } from './pages/Vehicles/WMICPCPAVehiclesPage';
export { default as WMICPCPACoveragesPage } from './pages/Coverages/WMICPCPACoveragesPage';
export { default as WMICPCPAInsuranceHistoryPage } from './pages/InsuranceHistory/WMICPCPAInsuranceHistoryPage';
