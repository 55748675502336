/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';

const WMICInProgressPaymentModal = (props) => {
    const { onReject, saveBankAccount, isBankAccountSaved } = props;
    const translator = useTranslator();

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(messages.paymentInProgress)}</h3>
            <ModalBody>
                <div className={styles.successFailureModalBody}>
                    {!saveBankAccount && (
                        <div>
                            <p>{translator(messages.weHaveNotYetReceivedSuccessfulNotificationOfYourPaymentFromTheBank)}</p>
                            <p>{translator(messages.inTheEventThatYourPaymentIsNotAppliedToYourPolicyWithin24Hours)}</p>
                        </div>
                    )}
                    {saveBankAccount && (
                        <div>
                            <p>
                                {translator(messages.weHaveNotYetReceivedSuccessfulNotificationOfYourPaymentFromTheBank)}
                                {translator(messages.inTheEventThatYourPaymentIsNotAppliedToYourPolicyWithin24Hours)}
                            </p>
                            <br />
                            {isBankAccountSaved && (
                                <p>{translator(messages.bankingInformationSaved)}</p>
                            )}
                            {!isBankAccountSaved && (
                                <p>
                                    {translator(messages.bankUnableToBeSaved)}
                                    {translator(messages.contactUsForAssistance)}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject(); }}
                    type="primary"
                >
                    {translator(messages.ok)}
                </WMICButton>
            </ModalFooter>
        </Fragment>
    );
};

WMICInProgressPaymentModal.propTypes = {
    onReject: PropTypes.func.isRequired,
    saveBankAccount: PropTypes.bool.isRequired,
    isBankAccountSaved: PropTypes.bool.isRequired
};

export default WMICInProgressPaymentModal;
