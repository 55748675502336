/* eslint-disable no-secrets/no-secrets */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { CONSTANTS, WMICRPCUtil, HOConstants } from 'wmic-pe-portals-utils-js';
import WMICHOConstructionHeatingDetails from './WMICHOConstructionHeatingDetails/WMICHOConstructionHeatingDetails';

import metadata from './WMICHeatingDetailView.metadata.json5';
import './WMICHeatingDetailView.messages';

function WMICHeatingDetailView(props){
    const {
        id,
        riskView,
        value: currentHeatingVM,
        updateModel: updateHeatingVM,
        jobVM,
        onValidate,
        availableHeatingUses,
        availableHeatingTypes,
        setAvailableHeatingTypes,
        getAvailableHeatingTypes,
        availableFuelTypes,
        setAvailableFuelTypes,
        getAvailableFuelTypes,
        readOnly,
        isEditing,
        showErrors: pShowErrors
    } = props;

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);

    const prevHeaterTypeRef = useRef();
    const prevPrimaryFuelRef = useRef();
    const prevSecondaryFuelRef = useRef();
    const prevPrimaryOilTanksNumberRef = useRef();
    const prevSecondaryOilTanksNumberRef = useRef();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, id]);

    const setPrimaryFuelSourceVM = (model) => {
        const newHeatingVM = viewModelService.clone(currentHeatingVM);
        _.set(newHeatingVM, 'primaryFuelSource', model.value);
        updateHeatingVM(newHeatingVM);
    }

    const setSecondaryFuelSourceVM = (model) => {
        const newHeatingVM = viewModelService.clone(currentHeatingVM);
        _.set(newHeatingVM, 'secondaryFuelSource', model.value);
        updateHeatingVM(newHeatingVM);
    }

    const isFuelType = (type) => _.get(currentHeatingVM, 'primaryFuelSource.fuelType.value.code') === type

    const isRPC1671Effective = () => WMICRPCUtil.getIsRPCEffective(
        _.get(riskView, 'rating.ratingJurisdiction.value.code'),
        _.get(jobVM, 'baseData.rateAsOfDate.value'),
        '1671');

    const isHeatLocationVisible = () => isFuelType(CONSTANTS.HEATING_FUEL_TYPES.WOOD) && isRPC1671Effective()

    const isPrimarySourceFuelAvailable = () => {
        const heaterTypeCode = _.get(currentHeatingVM, 'heaterType.value.code');
        const fuelTypeAvailableValues = _.get(currentHeatingVM, 'primaryFuelSource.fuelType.aspects.availableValues', []);

        return heaterTypeCode && fuelTypeAvailableValues.length > 0;
    };

    const isPrimarySourceFuelVisible = () => {
        const fuelTypeValue = _.get(currentHeatingVM, 'primaryFuelSource.fuelType.value');

        return isPrimarySourceFuelAvailable() || fuelTypeValue
    };

    const isPrimaryFuelSurveyVisible = () => {
        const fuelTypeValue = _.get(currentHeatingVM, 'primaryFuelSource.fuelType.value');
        const heaterTypeCode = _.get(currentHeatingVM, 'heaterType.value.code');

        return fuelTypeValue || [CONSTANTS.HEATING_TYPES.HEAT_PUMP, CONSTANTS.HEATING_TYPES.HEATED_FLOOR_SLAB].includes(heaterTypeCode);
    };

    const isPrimarySourceFuelSectionVisible = () => isPrimarySourceFuelVisible() || isPrimaryFuelSurveyVisible()

    const isSecondaryFuelSurveyVisible = () => {
        const fuelTypeValue = _.get(currentHeatingVM, 'secondaryFuelSource.fuelType.value.code');
        return !!fuelTypeValue;
    };

    const isHeaterType = (selectedHeaterType) => {
        const heaterTypeCode = _.get(currentHeatingVM, 'heaterType.value.code');
        return heaterTypeCode === selectedHeaterType;
    };

    const isSecondaryFuelAvailable = () => {
        const fuelTypeAvailableValues = _.get(currentHeatingVM, 'secondaryFuelSource.fuelType.aspects.availableValues', []);
        return isHeaterType(HOConstants.combinationFurnaceHeaterType) && fuelTypeAvailableValues.length > 0;
    };

    const isSecondarySourceFuelSectionVisible = () => {
        const fuelTypeValue = _.get(currentHeatingVM, 'secondaryFuelSource.fuelType.value');
        return isSecondaryFuelAvailable() || !!fuelTypeValue
    };

    const defaultFuelTypeIfNecessary =() => {
        if (isHeaterType(HOConstants.electricBaseboardHeaterType)) {
            _.set(currentHeatingVM, 'primaryFuelSource.fuelType.value', HOConstants.electricFuelType)
        }
    };

    // Store the initial values and reset the values for the input fields that follow only if these were changed
    useEffect(()=> {
        prevHeaterTypeRef.current = _.get(currentHeatingVM, 'heaterType.value');
        prevPrimaryFuelRef.current = _.get(currentHeatingVM, 'primaryFuelSource.fuelType.value');
        prevSecondaryFuelRef.current = _.get(currentHeatingVM, 'secondaryFuelSource.fuelType.value');
        prevPrimaryOilTanksNumberRef.current = _.get(currentHeatingVM, 'primaryFuelSource.fuelSurvey.oilTankNumber.value');
        prevSecondaryOilTanksNumberRef.current = _.get(currentHeatingVM, 'secondaryFuelSource.fuelSurvey.oilTankNumber.value');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHeatingUseChange = (value, path) => {
        _.set(currentHeatingVM, path, value);
        updateHeatingVM(currentHeatingVM);
        setAvailableHeatingTypes(getAvailableHeatingTypes(currentHeatingVM));
    }

    const createNewHeaterSurveyDetail = (heaterFuel, isPrimary) => {
        const surveyDetail =  viewModelService.create(
            {
                'isPrimary': isPrimary,
                'heatingCertificates': [],
                'isHeaterSurveyDetailsExistingInPreviousPeriod': false
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.HeaterSurveyDetailsDTO_WMIC',
            {
                ...heaterFuel.aspects.context(),
                ...jobVM.aspects.context()
            }
        );
        return surveyDetail;
    };

    const createNewHeaterFuelSurvey = (heaterFuel) => {
        const newHeaterFuelSurvey = viewModelService.create(
            {'chimneyCertificates': [],
                'oilTankNumber': (_.get(heaterFuel, 'fuelType.value.code') === CONSTANTS.HEATING_FUEL_TYPES.OIL || _.get(heaterFuel, 'fuelType.value.code') === undefined)
                    ? 1 : undefined,
                'cordsOfWoodUsed': _.get(heaterFuel, 'fuelType.value.code') === CONSTANTS.HEATING_FUEL_TYPES.WOOD
                    ? 0 : undefined,
                'bagsOfAnthraciteUsed': _.get(heaterFuel, 'fuelType.value.code') === CONSTANTS.HEATING_FUEL_TYPES.ANTHRACITE
                    ? 0 : undefined,
                'bagsOfPelletUsed': _.get(heaterFuel, 'fuelType.value.code') === CONSTANTS.HEATING_FUEL_TYPES.PELLET
                    ? 0 : undefined,
                'isHeaterFuelSurveyExistingInPreviousPeriod': false
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.HeaterFuelSurveyDTO_WMIC',
            {
                ...heaterFuel.aspects.context(),
                ...jobVM.aspects.context()
            }
        );
        newHeaterFuelSurvey.primarySurveyDetail = createNewHeaterSurveyDetail(heaterFuel, true).value;
        newHeaterFuelSurvey.secondarySurveyDetail = createNewHeaterSurveyDetail(heaterFuel, false).value;
        return newHeaterFuelSurvey;
    };

    const createNewHeaterFuel = (heater, isPrimary) => {
        const heaterFuel = viewModelService.create(
            {'primary': isPrimary},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.HeaterFuelDTO_WMIC',
            {
                ...heater.aspects.context(),
                ...jobVM.aspects.context()
            }
        );
        heaterFuel.fuelSurvey = createNewHeaterFuelSurvey(heaterFuel).value;
        return heaterFuel;
    };

    useEffect(()=> {
        setAvailableFuelTypes(getAvailableFuelTypes(currentHeatingVM));

        if ((_.get(prevHeaterTypeRef, 'current.code') !== _.get(currentHeatingVM, 'heaterType.value.code'))) {
            prevHeaterTypeRef.current = _.get(currentHeatingVM, 'heaterType.value');

            // initialize primary fuel source
            currentHeatingVM.primaryFuelSource = createNewHeaterFuel(currentHeatingVM, true).value;

            // initialize secondary fuel source if needed
            if (isHeaterType(HOConstants.combinationFurnaceHeaterType)) {
                currentHeatingVM.secondaryFuelSource = createNewHeaterFuel(currentHeatingVM, false).value;
            }
            defaultFuelTypeIfNecessary();

            currentHeatingVM.heaterTypeDescription.value = undefined;
        }
        updateHeatingVM(currentHeatingVM);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.get(currentHeatingVM, 'heaterType.value')]);

    useEffect(()=> {
        if (!_.isUndefined(prevPrimaryFuelRef.current) && (prevPrimaryFuelRef.current.code !== _.get(currentHeatingVM, 'primaryFuelSource.fuelType.value.code'))) {
            prevPrimaryFuelRef.current = _.get(currentHeatingVM, 'primaryFuelSource.fuelType.value');
            currentHeatingVM.primaryFuelSource.fuelSurvey = createNewHeaterFuelSurvey(currentHeatingVM.primaryFuelSource).value;
            updateHeatingVM(currentHeatingVM);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.get(currentHeatingVM, 'primaryFuelSource.fuelType.value')]);

    useEffect(()=> {
        if (!_.isUndefined(prevSecondaryFuelRef.current) && (prevSecondaryFuelRef.current.code !== _.get(currentHeatingVM, 'secondaryFuelSource.fuelType.value.code'))) {
            prevSecondaryFuelRef.current = _.get(currentHeatingVM, 'secondaryFuelSource.fuelType.value');
            currentHeatingVM.secondaryFuelSource.fuelSurvey = createNewHeaterFuelSurvey(currentHeatingVM.secondaryFuelSource).value;
            updateHeatingVM(currentHeatingVM);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.get(currentHeatingVM, 'secondaryFuelSource.fuelType.value')]);

    const primaryFuelSourceOilTankNumber = _.get(currentHeatingVM, 'primaryFuelSource.fuelSurvey.oilTankNumber.value');
    useEffect(()=> {
        if ((prevPrimaryOilTanksNumberRef.current !== primaryFuelSourceOilTankNumber)
            && (primaryFuelSourceOilTankNumber >= 2)
        ) {
            prevPrimaryOilTanksNumberRef.current = primaryFuelSourceOilTankNumber;
            const secondarySurveyDetail = createNewHeaterSurveyDetail(currentHeatingVM.primaryFuelSource, false).value;
            _.set(currentHeatingVM, 'primaryFuelSource.fuelSurvey.secondarySurveyDetail',  secondarySurveyDetail);

            updateHeatingVM(currentHeatingVM);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryFuelSourceOilTankNumber]);

    const secondaryFuelSourceOilTankNumber = _.get(currentHeatingVM, 'secondaryFuelSource.fuelSurvey.oilTankNumber.value');
    useEffect(()=> {
        if ((prevSecondaryOilTanksNumberRef.current !== secondaryFuelSourceOilTankNumber)
            && (secondaryFuelSourceOilTankNumber >= 2)
        ) {
            prevSecondaryOilTanksNumberRef.current = secondaryFuelSourceOilTankNumber;
            const secondarySurveyDetail = createNewHeaterSurveyDetail(currentHeatingVM.secondaryFuelSource, false).value;
            _.set(currentHeatingVM, 'secondaryFuelSource.fuelSurvey.secondarySurveyDetail',  secondarySurveyDetail);

            updateHeatingVM(currentHeatingVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryFuelSourceOilTankNumber]);


    const resolvers = {
        resolveComponentMap: {
            WMICHOConstructionHeatingDetails
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: currentHeatingVM,
            readOnly: readOnly || !isEditing,
        },
        heatingUse: {
            availableValues: availableHeatingUses,
            onValueChange: onHeatingUseChange
        },
        heatLocation: {
            visible: isHeatLocationVisible()
        },
        heaterType: {
            availableValues: availableHeatingTypes
        },
        primaryFuelContainer: {
            visible: isPrimarySourceFuelSectionVisible()
        },
        primaryFuel: {
            availableValues: availableFuelTypes
        },
        primaryFuelSourceComponent: {
            fuelSourceVM: currentHeatingVM.primaryFuelSource,
            setFuelSourceVM: setPrimaryFuelSourceVM,
            showErrors: pShowErrors,
            onValidate: setComponentValidation,
            readOnly: readOnly || !isEditing,
            jobVM,
            riskView,
            visible: isPrimaryFuelSurveyVisible()
        },
        secondaryFuelContainer: {
            visible: isSecondarySourceFuelSectionVisible()
        },
        secondaryFuel: {
            availableValues: availableFuelTypes
        },
        secondaryFuelSourceComponent: {
            fuelSourceVM: currentHeatingVM.secondaryFuelSource,
            setFuelSourceVM: setSecondaryFuelSourceVM,
            showErrors: pShowErrors,
            onValidate: setComponentValidation,
            readOnly: readOnly || !isEditing,
            jobVM,
            riskView,
            visible: isSecondaryFuelSurveyVisible()
        },
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentHeatingVM}
            overrideProps={overrideProps}
            onModelChange={updateHeatingVM}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={pShowErrors}
        />
    )
}

WMICHeatingDetailView.propTypes = {
    id: PropTypes.string.isRequired,
    heatingVM: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICHeatingDetailView;