// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__FacebookShare__facebookContent{display:flex;justify-content:center;padding:var(--GW-LAYOUT-8) 0}.app__FacebookShare__facebookShare{--gw-facebook-share-width:300px;border:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);border-radius:var(--GW-BORDER-RADIUS);max-width:var(--gw-facebook-share-width);padding:var(--GW-LAYOUT-6);text-align:center}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/FacebookShare/FacebookShare.module.scss"],"names":[],"mappings":"AAAA,qCACG,YAAA,CACA,sBAAA,CACA,4BAAA,CAGH,mCACG,+BAAA,CACA,0DAAA,CACA,qCAAA,CACA,wCAAA,CAEA,0BAAA,CADA,iBACA","sourcesContent":[".facebookContent {\n   display: flex;\n   justify-content: center;\n   padding: var(--GW-LAYOUT-8) 0;\n}\n\n.facebookShare {\n   --gw-facebook-share-width: 300px;\n   border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n   border-radius: var(--GW-BORDER-RADIUS);\n   max-width: var(--gw-facebook-share-width);\n   text-align: center;\n   padding: var(--GW-LAYOUT-6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"facebookContent": "app__FacebookShare__facebookContent",
	"facebookShare": "app__FacebookShare__facebookShare"
};
export default ___CSS_LOADER_EXPORT___;
