import { useEffect } from 'react';
import _ from 'lodash';

export function useTracking({ events, trackingFunction }) {
    const eventsArr = _.castArray(events);
    useEffect(() => {
        eventsArr.forEach((event) => {
            document.addEventListener(event, trackingFunction);
        });
        return () => {
            eventsArr.forEach((event) => {
                document.removeEventListener(event, trackingFunction);
            });
        };
    }, [trackingFunction, eventsArr]);
}
