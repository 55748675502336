// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICDropdownMenuButton__wmicDropdownButton{line-height:inherit}.app__WMICDropdownMenuButton__wmicDropdownLink{align-items:flex-start;display:block;white-space:normal}.app__WMICDropdownMenuButton__wmicDropdownLink i{margin-right:var(--GW-LAYOUT-3)}.app__WMICDropdownMenuButton__wmicDropdownLink span{display:inline-block;vertical-align:top;width:80%}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICDropdownMenuButton/WMICDropdownMenuButton.module.scss"],"names":[],"mappings":"AAAA,iDAGI,mBAAA,CAGJ,+CAEI,sBAAA,CADA,aAAA,CAEA,kBAAA,CACA,iDACI,+BAAA,CAEJ,oDACI,oBAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":[".wmicDropdownButton {\n    /* .jut__Button__materialIcon hard-codes line-height to 1.5*/\n    /* which messes with vertical positioning*/\n    line-height: inherit; \n}\n\n.wmicDropdownLink {\n    display: block;\n    align-items: flex-start;\n    white-space: normal;\n    i {\n        margin-right: var(--GW-LAYOUT-3);\n    }\n    span {\n        display: inline-block;\n        vertical-align: top;\n        width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicDropdownButton": "app__WMICDropdownMenuButton__wmicDropdownButton",
	"wmicDropdownLink": "app__WMICDropdownMenuButton__wmicDropdownLink"
};
export default ___CSS_LOADER_EXPORT___;
