import React, { Fragment } from 'react';
import _ from 'lodash';
import { WMICLink, WMICIntlFormattedDateCell } from 'wmic-pe-components-platform-react'
import { FORMAT_CODES, WMICVariousUtil, LOBConstants } from 'wmic-pe-portals-utils-js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import messages from '../WMICLanding.messages';

dayjs.extend(relativeTime);

const inferProductName = (productCode) => { 
  switch (productCode) {
      case LOBConstants.PA:
          return messages.autoMobileLink;
      case LOBConstants.PP:
          return messages.personalPropertyLink;
      case LOBConstants.PUP:
          return messages.personalUmbrellaLink;
      case LOBConstants.CP:
          return messages.commercialSmallBusinessLink;
      default:
          return "";
  }
};

/**
 * Gets passed in object containing row of data in the form
 * {
 *  jobNumber: String,
 *  periodStartdate: String,
 *  name: String,
 *  status: String,
 *  broker: String,
 *  policyLines: String
 * }
 * @param {*} rowData
 * @returns Link component
 */
const getPolicyNumberLink = (rowData, translator) => {
    const icon = WMICVariousUtil.getLobIcon(rowData.productCode);
    const disableLink = _.get(rowData, 'disableLink', false);
    return (
        <WMICLink
            displayOnly
            disabled={disableLink}
            id={`policy${rowData.policyNumber}`}
            icon={icon}
            iconProps={{'aria-label': translator(inferProductName(rowData.productCode))}}
        >
            {rowData.policyNumber}
        </WMICLink>
    );
};

/**
 * Gets passed in object containing row of data in the form
 * {
 *  jobNumber: String,
 *  periodStartdate: String,
 *  name: String,
 *  status: String,
 *  broker: String,
 *  policyLines: String
 * }
 * @param {*} rowData
 * @returns Link component
 */
const getSubmissionNumberLink = (rowData, index, columnId, translator) => {
    const { productCode } = rowData;
    const icon = WMICVariousUtil.getLobIcon(productCode);
    return (
        <WMICLink
            displayOnly
            id={`policy${rowData[columnId]}${index}`}
            icon={icon}
            iconProps={{'aria-label': translator(inferProductName(rowData.productCode))}}
        >
            {rowData[columnId]}
        </WMICLink>
    );
};

const compareByJobNumberDesc = (a, b) => {
    if (a.jobNumber > b.jobNumber) {
        return -1;
    } if (a.jobNumber < b.jobNumber) {
        return 1;
    }
    return 0;
};

const compareByJobNumberAsc = (a, b) => (compareByJobNumberDesc(a, b) * -1);

const sortByJobNumber = (data, orderingDirectionDesc) => {
    if (orderingDirectionDesc) {
        return data.sort(compareByJobNumberDesc);
    }
    return data.sort(compareByJobNumberAsc);
};

const compareByCreatedDesc = (a, b) => {
    if (a.periodStartDate > b.periodStartDate) {
        return -1;
    } if (a.periodStartDate < b.periodStartDate) {
        return 1;
    }
    return 0;
};

const compareByCreatedAsc = (a, b) => (compareByCreatedDesc(a, b) * -1);

const sortByCreated = (data, orderingDirectionDesc) => {
    if (orderingDirectionDesc) {
        return data.sort(compareByCreatedDesc);
    }
    return data.sort(compareByCreatedAsc);
};

const formatSearchBy = (code) => {
    switch (code) {
        case FORMAT_CODES.myWork:
            return FORMAT_CODES.FOR_CURRENT_USER;
        case FORMAT_CODES.all:
            return FORMAT_CODES.ALL;
        default:
            return FORMAT_CODES.BY_PRODUCER_CODES;
    }
};

const formatProducerCodes = (code) => {
    if ((code === FORMAT_CODES.myWork) || (code === FORMAT_CODES.all)) {
        return [];
    }
    return [code];
};

const getTableData = (showData) => {
    if (showData && showData.length > 0) {
        return showData;
    }
    return [
        {
            empty: 'emptyData'
        }
    ];
};

const formatTimeRemaining = (policyRenewalDate) => {
    const msFiveDays = 432000000;
    const msDifference = Math.abs(dayjs().diff(dayjs(policyRenewalDate)));
    const warningClass = 'timeRemainingWarning';

    return <span className={msDifference <= msFiveDays ? warningClass : null}>{dayjs().from(dayjs(policyRenewalDate), true)}</span>;
}
const getFormattedDateCell = (date) => (<WMICIntlFormattedDateCell date={date} />)

const getFormattedNameAddressCell = (rowData) => <Fragment>{rowData.insured}<br />{rowData.policyAddress}</Fragment>;

const getItemData = (item, index, itemProperty) => {
    if (item.empty) {
        return (<Fragment> </Fragment>);
    }
    return item[itemProperty];
};

export default {
    getPolicyNumberLink,
    getSubmissionNumberLink,
    sortByJobNumber,
    sortByCreated,
    formatSearchBy,
    formatProducerCodes,
    getTableData,
    formatTimeRemaining,
    getFormattedDateCell,
    getFormattedNameAddressCell,
    getItemData
};
