import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { withTracking } from '@xengage/gw-portals-tracking-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { Icon } from '@jutro/components';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import { WMICButton, WMICHeading, WMICLink } from 'wmic-pe-components-platform-react';
import { WMICVariousUtil, WMICRichTextUtil, LOBConstants, ICON_NAMES } from 'wmic-pe-portals-utils-js';
import styles from './KnockOutPage.module.scss';
import messages from './KnockOutPage.messages';

function FsraContent({ fsraDeclineIssueMessages }) {
    return (
        <TranslatorContext.Consumer>
            {(translator) => (
                <div className="gw-container gw-content">
                    <section>
                        <div className="gw-agent-page">
                            <div className="ww-row-stretched">
                                <h1 className="gw-titles-title">
                                    <i className="ww-titles-title-icon fa fa-fw fa-exclamation-triangle" />
                                    <span id="Qualification">
                                        {translator(messages.qualification)}
                                    </span>
                                </h1>
                            </div>
                            <section className="gw-error-contact">
                                <section className="gw-error-message gw-hmargin15" />
                                <section className="gw-main-content">
                                    <div className="control-label gw-strong">
                                        {fsraDeclineIssueMessages}
                                    </div>
                                </section>
                            </section>
                        </div>
                    </section>
                </div>
            )}
        </TranslatorContext.Consumer>
    );
}

function DoNotWriteContent(props) {
    return (
        <TranslatorContext.Consumer>
            {(translator) => (
                <div className="gw-container gw-content">
                    <section>
                        <div className="gw-agent-page">

                            <div className="ww-row-stretched">
                                <h1 className="gw-titles-title">
                                    <i className="ww-titles-title-icon fa fa-fw fa-exclamation-triangle" />
                                    <span id="Qualification">
                                        {translator(messages.qualification)}
                                    </span>
                                </h1>
                            </div>
                            <section className="gw-error-contact">
                                <section className="gw-error-message gw-hmargin15" />
                                <section className="gw-main-content">
                                    <div className="control-label gw-strong">
                                        {translator(props.message)}
                                    </div>
                                </section>
                            </section>
                        </div>

                    </section>
                </div>
            )}
        </TranslatorContext.Consumer>
    );
}

function UnderwriterWriteContent(props) {
    return (
        <TranslatorContext.Consumer>
            {(translator) => (
                <div className="gw-container gw-content">
                    <section>
                        <div className="gw-agent-page" ng-if="underwriterWriteFlag">
                            <div className="ww-row-stretched">
                                <h1 className="gw-titles-title">
                                    <i className="ww-titles-title-icon fa fa-fw fa-exclamation-triangle" />
                                    <span id="Qualification">
                                        {translator(messages.qualification)}
                                    </span>
                                </h1>
                            </div>
                            <section className="gw-error-contact">
                                <section className="gw-error-message gw-hmargin15" />
                                <section className="gw-main-content">
                                    <div>{translator(messages.underwriterProceed1)}</div>
                                    <div>{translator(messages.underwriterProceed2)}</div>
                                    <div className="gw-control-group gw-reference-number">
                                        <div className="gw-control-label gw-strong">{translator(messages.referenceNumber)}</div>
                                        <div className="gw-controls">{props.quoteID}</div>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </section>
                </div>
            )}
        </TranslatorContext.Consumer>
    );
}

function CPUnderwriterWriteContent(props) {
    const icon = WMICVariousUtil.getLobIcon(LOBConstants.CP)
    const routePath = `/accounts/${props.account.accountNumber}/summary`;

    return (
        <TranslatorContext.Consumer>
            {(translator) => (
                <div className="gw-container gw-content">
                    <div className={cx("wizardHeaderJobDetails", 'gw-mb-5')}>
                        <Icon icon={icon} id="lobIcon" size="large" className={cx("lobIcon", "gw-mr-2")} />
                        <WMICHeading variant="h2" title={translator(messages.cpTitle, {submission: props.quoteID})} />
                    </div>
                    <div>
                        <div className={cx(styles.accountHolderName)}>{props.account.displayName}</div>
                        <p className={styles.accountAddress}>
                            {props.account.displayAddress}
                            <WMICLink to={routePath}>
                                <Icon
                                    icon={ICON_NAMES.ICON_EDIT}
                                    id="iconEdit"
                                    size="small"
                                    className="gw-ml-1"
                                />
                                <span className={styles.editAccount}>{translator(messages.cpEditAccount)}</span>
                            </WMICLink>
                        </p>
                        <WMICHeading
                            title={translator(messages.cpHeader)}
                            variant='h1'
                            underlined
                        />
                    </div>
                    <div id="knockoutContent">
                        <div>
                            <div id="knockoutContainerWrapper" className={cx(styles.wmicKnockoutContainerWrapper)}>
                                <div id="knockoutContainer" className={cx(styles.wmicKnockoutContainer)}>
                                    <Icon icon={ICON_NAMES.ICON_INFO} id="statusIcon" className={cx(styles.wmicKnockoutIcon, "gw-mr-2")} />
                                    <div id="knockoutMessageContainer">
                                        <p id="knockoutMessageHeading" className={cx(styles.wmicKnockoutMessageHeader, "gw-mb-1")}>{translator(messages.cpAlertTitle)}</p>
                                        <p id="knockoutMessageBody" className={cx("gw-mb-0")}>{WMICRichTextUtil.translateRichText(translator(messages.cpAlertBody, {submission: props.quoteID}))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cx(styles.knockoutButtons)}>
                            <div className={cx(styles.knockoutButtonContainer)}>
                                <WMICButton onClick={ () => { window.location="/" } } type="primary-outlined">
                                    {translator(messages.cpBackToPolicyButton)}
                                </WMICButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </TranslatorContext.Consumer>
    );
}

class KnockOutPage extends Component {
    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})),
                quoteID: PropTypes.string,
                lob: PropTypes.string,
                account: PropTypes.shape({
                    displayName: PropTypes.string,
                    displayAddress: PropTypes.string,
                    accountNumber : PropTypes.string
                })
            })
        }).isRequired
    };

    static contextType = TranslatorContext;

    constructor(props) {
        super(props);
        this.state = {
            issueRuleCodeTypeList: [],
            underwritingIssues: '',
            quoteID: '',
            lob: '',
            account: {}
        };
    }


    componentDidMount() {
        const {
            viewModelService,
            location: {
                state: {
                    underwritingIssues,
                    quoteID,
                    lob,
                    account
                }
            }
        } = this.props;

        const validationIssueDTO = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policycommon.validation.dto.ValidationIssueDTO'
        );

        const issueRuleCodeTypeList = _.get(validationIssueDTO,
            'issueRuleCode.aspects.availableValues[0].typelist');
        this.setState({
            issueRuleCodeTypeList: issueRuleCodeTypeList,
            underwritingIssues: underwritingIssues,
            quoteID: quoteID,
            lob: lob,
            account: account
        });
    }

    doNotWriteMessage = () => {
        const {
            issueRuleCodeTypeList,
            underwritingIssues
        } = this.state;

        const DoNotWrite = {
            typelist: {
                name: 'BOTHandling_WMIC'
            },
            code: 'DoNotWrite'
        };

        if (underwritingIssues && Array.isArray(underwritingIssues)) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < underwritingIssues.length; index++) {
                const valCode = issueRuleCodeTypeList.getCode(
                    underwritingIssues[index].issueRuleCode
                );
                if (valCode.belongsToCategory(DoNotWrite) && valCode.code !== CONSTANTS.ISSPECIALVEHICLERISK_CODE) {
                    return messages.notMeetCriteria;
                } else if (valCode.code === CONSTANTS.ISSPECIALVEHICLERISK_CODE) {
                    return messages.notEligibleNeedUWAssistance;
                }
            }
        }
        return undefined;
    };

    fsraDeclineIssueMessages = () => {
        const { underwritingIssues } = this.state;
        const exclusionCodes = CONSTANTS.FSRA_PREQUAL_QUESTION_EXCLUSIONS;
        const fsraDeclineIssueMessages = new Set();

        if (underwritingIssues && Array.isArray(underwritingIssues)) {
            underwritingIssues.forEach((underwritingIssue) => {
                const issueRuleCode = underwritingIssue.issueRuleCode;
                const exclusions = exclusionCodes.some(exclusion => issueRuleCode.includes(exclusion));

                if (!exclusions && issueRuleCode.includes("Fsra")) {
                    fsraDeclineIssueMessages.add(<div key={issueRuleCode}>{underwritingIssue.issueMessage}</div>);
                }
            })
        }
        return fsraDeclineIssueMessages;
    }

    render() {
        const { underwritingIssues, quoteID, lob, account } = this.state;
        const doNotWriteMessage = this.doNotWriteMessage();
        const fsraDeclineIssueMessages = this.fsraDeclineIssueMessages();
        const underwriterWriteFlag = underwritingIssues
            && Array.isArray(underwritingIssues) && !doNotWriteMessage;

        if (fsraDeclineIssueMessages?.size > 0) {
            return <FsraContent fsraDeclineIssueMessages={fsraDeclineIssueMessages} />
        }
        if (doNotWriteMessage) {
            return <DoNotWriteContent message={doNotWriteMessage} />;
        }
        if (underwriterWriteFlag) {
            if(lob) {
                return <CPUnderwriterWriteContent quoteID={quoteID} account={account} />;
            }

            return <UnderwriterWriteContent quoteID={quoteID} />;
        }
        return <section />;
    }
}

FsraContent.propTypes = {
    fsraDeclineIssueMessages: PropTypes.objectOf(PropTypes.element).isRequired
};

UnderwriterWriteContent.propTypes = {
    quoteID: PropTypes.string.isRequired
};

CPUnderwriterWriteContent.propTypes = {
    quoteID: PropTypes.string.isRequired,
    account: PropTypes.shape({
        displayName: PropTypes.string,
        displayAddress: PropTypes.string,
        accountNumber : PropTypes.string
    }).isRequired
};

export default withViewModelService(withRouter(withTracking(KnockOutPage)));
