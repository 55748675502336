import _ from 'lodash';
import moment from 'moment';
import appConfig from 'app-config';
import { CONSTANTS, LOBConstants, WMICAccountContactUtil } from 'wmic-portals-utils-js';
import { InactivityTimerService } from '@xengage/gw-portals-auth-inactivity-timer-js';
import { PolicyService } from 'gw-capability-policy';
import { EndorsementService } from 'gw-capability-policychange';
import PolicyChangeRequestModel from '../models/PolicyChangeRequestModel';
import PolicyDetailsModel from '../models/PolicyDetailsModel';
import AssistedPolicyChangeModel from '../models/AssistedPolicyChangeModel';
import WMICPolicyChangeService from '../services/WMICPolicyChangeService';
import WMICPolicyChangeStateService from '../services/WMICPolicyChangeStateService';
import WMICPolicyChangeUtilsService from '../services/WMICPolicyChangeUtilsService';
import WMICPolicyChangeTransformationService from '../services/WMICPolicyChangeTransformationService';

export default class WMICControllerUtil {
    constructor(props) {
        this.initVars(props);
    }

    static initVars(props) {
        this.history = props.history;
        this.policyData = props.policyData;
        this.policyNumber = props.policyNumber;
        this.termNumber = props.termNumber;
        this.policyType = props.policyType;
        this.accountInfo = props.accountHolder;
        this.authHeader = props.authHeader;
        this.authUserData = props.authUserData
        this.userInfo = props.userInfo
        this.user = props.authUserData ? props.authUserData : props.userInfo
        this.policyChangeSubmitConfig = appConfig.policyChangeSubmitConfig;
    }

    static isHomePolicy(polType) {
        return polType === LOBConstants.HOME;
    }

    static getPolicyHolder(policyData) {
        return policyData.currentPeriod.contacts.filter((currentContact) => {
            return currentContact.contactRole === CONSTANTS.PRIMARY_NAMED_INSURED;
        })[0].contact;
    }

    static groupChanges(changes) {
        return changes.reduce((res, change) => {
            if (!res[change.objectType]) {
                res[change.objectType] = [];
            }

            if (Array.isArray(change.changeModel)) {
                res[change.objectType] = res[change.objectType].concat(change.changeModel);
            } else {
                res[change.objectType].push(change.changeModel);
            }
            return res;
        }, {});
    }

    static groupChangesByChangeType(changes) {
        return changes.reduce((res, change) => {
            if (!res[change.changeType]) {
                res[change.changeType] = [];
            }

            if (Array.isArray(change.changeModel)) {
                res[change.changeType] = res[change.changeType].concat(change.changeModel);
            } else {
                res[change.changeType].push(change.changeModel);
            }
            return res;
        }, {});
    }

    static getPolicyData(props) {
        this.initVars(props);
        const inactivityTimer = new InactivityTimerService({});
        inactivityTimer.resetInactivityTimer();
        if (this.policyData && this.policyData.currentPeriod) {
            return new Promise((resolve) => {
                resolve(this.policyData);
            });
        }

        return PolicyService.getAccountPolicyDetails_WMIC(
            this.policyNumber,
            this.termNumber,
            this.authHeader,
            this.user.firstName,
            this.user.lastName
        ).then((polData) => {
            this.policyData = polData;
            WMICPolicyChangeStateService.addState({ currentPeriod: polData.currentPeriod });
            return polData;
        }).catch((e) => {
            console.log('Error on retrieving account information', e);
            this.history.push('/technicalErrorPage/error');
        });
    }

    static gotoChangeSelectionPage(policyNumber, termNumber, policyType) {
        this.history.push(`/account-policy-change/${policyNumber}/${termNumber}/${policyType}/select-type`);
    }

    static showWarningMessage(policyNumber, value, subOptions) {
        if (subOptions) {
            return subOptions.options.map((val) => {
                return WMICPolicyChangeUtilsService.hasSubmittedChange(
                    policyNumber,
                    AssistedPolicyChangeModel.getChangeType(val)
                )
            }).find((v) => { return v; }) || false;
        }
        return WMICPolicyChangeUtilsService.hasSubmittedChange(
            policyNumber,
            AssistedPolicyChangeModel.getChangeType(value)
        );
    }

    static getMessage(props, translator){
        this.initVars(props);
        const getPolicyHolder =  this.getPolicyHolder;
        const policyData = this.policyData;

        const accInfoRequest = WMICControllerUtil.matchContact(this.policyData, this.authUserData);

        return accInfoRequest.then((contact) => {
            if(contact) {
                const policyHolderContact = getPolicyHolder(policyData);
                const effectiveDate = new Date(policyData.currentPeriod.effective);
                const changes = WMICPolicyChangeStateService.getChangeRequests();
                const submitted = moment().format('MM/DD/YYYY hh:mm A');

                const model = new PolicyDetailsModel({
                    policyHolder: `${policyHolderContact.firstName} ${policyHolderContact.lastName}`,
                    policyNumber: policyData.currentPeriod.policyNumber,
                    policyHolderEmail: contact.emailAddress1,
                    effectiveDate: effectiveDate,
                    homePhone: contact.homeNumber,
                    workPhone: contact.workNumber,
                    requester: `${contact.firstName} ${contact.lastName}`
                });

                const reqToString = WMICPolicyChangeTransformationService.changesRequestToString(this.groupChanges(changes), translator);

                return `
${model.toString(translator)}
Submitted: ${submitted}
--------------------------------------------------------------------------
The details of the requested change(s) are as follows:
${reqToString}
--------------------------------------------------------------------------
            `;
            }});
    }

    static matchContact(policyData, authUserData) {
        return new Promise((resolve) => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const policyContact of policyData?.currentPeriod?.contacts) {
                if (policyContact?.contact?.firstName === authUserData?.firstName &&
                    policyContact?.contact?.lastName === authUserData?.lastName) {
                    resolve(policyContact.contact);
                }
            }

            resolve(null)
        });
    }

    static handleSubmit(props, translator) { // getAccountPolicyDetails
        this.initVars(props);
        const policy = this.policyData;
        const accInfoRequest = WMICAccountContactUtil.getPickedContact(this.authHeader, this.user, policy.currentPeriod.contacts);
        let loaded = false;
        accInfoRequest.then((accountInfo) => {
            const requesterContact = accountInfo;
            const policyHolderContact = this.getPolicyHolder(this.policyData);
            const effectiveDate = new Date(this.policyData.currentPeriod.effective);
            const changes = WMICPolicyChangeStateService.getChangeRequests();

            const message = WMICPolicyChangeTransformationService.transform(
                new PolicyChangeRequestModel({
                    sender: `${requesterContact.firstName} ${requesterContact.lastName}`,
                    senderEmail: CONSTANTS.EMAIL_SETTINGS.SENDER_EMAIL,
                    recipient: CONSTANTS.EMAIL_SETTINGS.RECIPIENT_EMAIL,
                    policyDetails: new PolicyDetailsModel({
                        policyHolder: `${policyHolderContact.firstName} ${policyHolderContact.lastName}`,
                        policyNumber: this.policyData.currentPeriod.policyNumber,
                        policyHolderEmail: requesterContact.emailAddress1,
                        effectiveDate: effectiveDate,
                        homePhone: requesterContact.homeNumber,
                        workPhone: requesterContact.workNumber,
                        requester: `${requesterContact.firstName} ${requesterContact.lastName}`
                    })
                }), this.groupChanges(changes),
                translator
            );
            const inactivityTimer = new InactivityTimerService({});
            inactivityTimer.resetInactivityTimer();

            const basicAuthHeader = window.btoa(`${this.policyChangeSubmitConfig.username}:${this.policyChangeSubmitConfig.password}`);

            const policychangeHeader = {
                Authorization: `Basic ${basicAuthHeader}`,
                AuthorizationEdge: this.authHeader.Authorization
            };
            WMICPolicyChangeService.submitDataChange(message, policychangeHeader).then((response) => {
                if (response) {
                    const postAssistedChanges = Object.entries(
                        this.groupChangesByChangeType(changes)
                    ).map(([key]) => AssistedPolicyChangeModel.getChangeType(key));

                    const model = new AssistedPolicyChangeModel({
                        policyNumber: this.policyData.currentPeriod.policyNumber,
                        changes: postAssistedChanges,
                        requesterName: `${requesterContact.firstName} ${requesterContact.lastName}`,
                        requesterEmail: requesterContact.emailAddress1
                    });
                    WMICPolicyChangeUtilsService.setSubmittedChange(model);

                    EndorsementService.postAssistedPolicyChange(model, this.authHeader).then((result) => {
                        console.debug('[postAssistedPolicyChange] result', result);
                    }).catch((e) => {
                        console.error(e);
                    });

                    WMICPolicyChangeStateService.clearState();
                    this.history.push(`/account-policy-change/${this.policyNumber}/${this.termNumber}/${this.policyType}/success-page`);
                }
            }).catch((e) => {
                console.error('Server post error', e);
                loaded = true;
                this.history.push('/technicalErrorPage/error');
                _.noop();
            });
        },
        (error) => {
            console.error('account info retrieval failed', error);
            loaded = true;
            this.history.push('/technicalErrorPage/error');
            _.noop();
        });
    }
}
