import React, { useEffect, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import WMICHOMotorInfoDetails from '../WMICHOMotorInfoDetails/WMICHOMotorInfoDetails';
import metadata from './WMICHOWatercraftOutboardMotorDetails.metadata.json5';

function WMICHOWatercraftOutboardMotorDetails({
    id,
    onValidate,
    showErrors,
    isEditMode,
}) {
    const {
        onValidate: setComponentValidation,
        isComponentValid,
    } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const { updateWatercraftRiskView, watercraftRiskView, jobVM } = useContext(WatercraftContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [id, onValidate, isComponentValid]);

    const createMotorComponent = useCallback(() => {
        const motor = {
            subtype: 'HOWatercraftMotor_WMIC',
            equipmentInfo: {
                motorInfo: {
                    motorType: 'Outboard',
                },
            },
        };
        const newComponent = viewModelService.create(
            motor,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.HOWatercraftComponentDTO_WMIC',
            {...jobVM.aspects.context(), ...watercraftRiskView.aspects.context()}
        );
        _.set(watercraftRiskView, 'isAddingBoatMotor', true);
        _.set(watercraftRiskView, 'watercraftComponents', [])
        return newComponent;
    }, [viewModelService]);

    const numOfMotors = watercraftRiskView.watercraftComponents.length;
    const loading = numOfMotors === 0;
    useEffect(() => {
        if (numOfMotors === 0) {
            const newMotor = createMotorComponent();
            watercraftRiskView.watercraftComponents.pushElement(newMotor);
            updateWatercraftRiskView(watercraftRiskView);
        }
    }, [numOfMotors])

    const overrideProps = {
        '@field': {
            readOnly: !isEditMode,
            parentNode: watercraftRiskView,
        },
        wmichoMotorInfoDetails: {
            motorTypeReadOnly: true,
            readOnly: !isEditMode,
            value: watercraftRiskView.watercraftComponents.getElement(0),
            onValidate: setComponentValidation,
            showErrors,
            updateModel: updateWatercraftRiskView
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHOMotorInfoDetails,
        },
    };

    if (loading) {
        return null;
    }

    return (
        <ViewModelForm
            model={watercraftRiskView.watercraftComponents.getElement(0)}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            onModelChange={updateWatercraftRiskView}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOWatercraftOutboardMotorDetails.propTypes = {
    equipment: PropTypes.shape({}).isRequired,
    updateWatercraftModel: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

WMICHOWatercraftOutboardMotorDetails.defaultProps = {
    readOnly: false,
};

export default WMICHOWatercraftOutboardMotorDetails;
