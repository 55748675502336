import { defineMessages } from 'react-intl';

export default defineMessages({
    drivers: {
        id: 'wmic.component.drivers-info-box-component.drivers',
        defaultMessage: 'DRIVERS'
    },
    assignedDrivers: {
        id: 'wmic.component.drivers-info-box-component.assignedDrivers',
        defaultMessage: 'Assigned Drivers'
    },
    assignedDriversTooltip: {
        id: 'wmic.component.drivers-info-box-component.assignedDriversTooltip',
        defaultMessage: 'Assigned drivers are primary or occasional drivers of your vehicle(s). They are covered by your insurance policy.'
    },
    dob: {
        id: 'wmic.component.drivers-info-box-component.dob',
        defaultMessage: 'DOB: '
    },
    unassignedDrivers: {
        id: 'wmic.component.drivers-info-box-component.unassignedDrivers',
        defaultMessage: 'Unassigned Drivers'
    },
    unassignedDriversTooltip: {
        id: 'wmic.component.drivers-info-box-component.unassignedDriversTooltip',
        defaultMessage: 'Unassigned drivers are listed on your policy, but are not rated. Typically, these are members of your household who do not hold a valid driver\'s license.'
    },
    excludedDrivers: {
        id: 'wmic.component.drivers-info-box-component.excludedDrivers',
        defaultMessage: 'Excluded Drivers'
    },
    excludedDriversTooltip: {
        id: 'wmic.component.drivers-info-box-component.excludedDriversTooltip',
        defaultMessage: 'Excluded drivers have been intentionally removed from your insurance policy. Your coverage will not apply if this person uses your car either with or without your permission.'
    }
});
