// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__QuickQuoteNavigation__stepsNav{display:flex;justify-content:center}.app__QuickQuoteNavigation__navigationButton{--gw-nav-buttons-size:16px;background:inherit;background-color:var(--GW-BACKGROUND-COLOR-BODY);border-color:var(--GW-SEPARATOR-COLOR);border-radius:50%;cursor:pointer;display:block;height:var(--gw-nav-buttons-size);margin:var(--GW-SPACING-1);padding:var(--GW-SPACING-2);text-align:inherit;width:var(--gw-nav-buttons-size)}.app__QuickQuoteNavigation__navigationButton.app__QuickQuoteNavigation__active{background-color:var(--GW-BRAND-COLOR-1);color:var(--GW-COLOR-SUCCESS-CONTRAST);position:relative}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-common-react/components/Wizard/QuickQuoteNavigation.module.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,sBAAA,CAGF,6CACE,0BAAA,CAKA,kBAAA,CAMA,gDAAA,CACA,sCAAA,CARA,iBAAA,CADA,cAAA,CADA,aAAA,CAMA,iCAAA,CACA,0BAAA,CACA,2BAAA,CAJA,kBAAA,CACA,gCAKA,CAEA,+EAGE,wCAAA,CADA,sCAAA,CADA,iBAEA","sourcesContent":[".stepsNav {\n  display: flex;\n  justify-content: center;\n}\n\n.navigationButton {\n  --gw-nav-buttons-size: 16px;\n\n  display: block;\n  cursor: pointer;\n  border-radius: 50%;\n  background: inherit;\n  text-align: inherit;\n  width: var(--gw-nav-buttons-size);\n  height: var(--gw-nav-buttons-size);\n  margin: var(--GW-SPACING-1);\n  padding: var(--GW-SPACING-2);\n  background-color: var(--GW-BACKGROUND-COLOR-BODY);\n  border-color: var(--GW-SEPARATOR-COLOR);\n\n  &.active {\n    position: relative;\n    color: var(--GW-COLOR-SUCCESS-CONTRAST);\n    background-color: var(--GW-BRAND-COLOR-1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepsNav": "app__QuickQuoteNavigation__stepsNav",
	"navigationButton": "app__QuickQuoteNavigation__navigationButton",
	"active": "app__QuickQuoteNavigation__active"
};
export default ___CSS_LOADER_EXPORT___;
