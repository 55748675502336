/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { PaymentPlanID, WMICRichTextUtil, PAYMENT_METHOD } from 'wmic-portals-utils-js';
import CurrencyField from '../CurrencyField/CurrencyField';
import messages from './WMICPaymentList.messages';
import styles from './WMICPaymentList.module.scss';


const TOTAL_THRESHOLD_FOR_MAR = 120;

function WMICPaymentList(props) {
    const {
        submissionVM, hasDoubleAmountInstallment, isTwelvePayDisabledRegion, isTwelvePayAndTwelvePayIsDisabledOutage
    } = props;
    const translator = useTranslator();
    const termMonths = _.get(submissionVM, 'quoteData.offeredQuotes.children[0].premium.termMonths.value');
    const selectedPaymentPlan = _.get(submissionVM, 'bindData.selectedPaymentPlan.value');
    const { phoneNumber } = appConfig;
    const defaultAmount =  {amount : 0, currency : 'usd'}

    const getPaymentPlans = () => {
        let allPlans = _.get(submissionVM, 'bindData.paymentPlans.value');

        const premiumTotal = _.get(submissionVM, 'quoteData.offeredQuotes.children[0].premium.total.amount.value');
        // CSS-1391 - remove MAR from list of available payment plans if total is less than $120
        if (premiumTotal < TOTAL_THRESHOLD_FOR_MAR) {
            allPlans = allPlans.filter((plan) => {
                return plan.billingId !== PaymentPlanID.TWELVE_INSTALLMENT;
            });
        }

        if (termMonths > 1) {
            return allPlans;
        }

        return [
            _.find(allPlans, (plan) => {
                return _.get(plan, 'installment.amount') === 0;
            })
        ];
    };

    const paymentPlans = getPaymentPlans();

    const paymentPlanIsTwelveInstallments = (planId) => {
        return planId === PaymentPlanID.TWELVE_INSTALLMENT;
    };

    const updateTotal = (total) => {
        return hasDoubleAmountInstallment.value ? { amount: total.amount - 1, currency: total.currency } : total;
    };

    const handlePaymentPlanChange = (event) => {
        const { onValueChange } = props;
        // set bank account as mayment method only for MAR
        const isMAR = event.currentTarget.value === PaymentPlanID.TWELVE_INSTALLMENT;
        submissionVM.bindData.paymentDetails.paymentMethod.value = isMAR ? PAYMENT_METHOD.AUTO_RECURRING : '';
        if (onValueChange) {
            if (_.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === 'creditcard') { // Remove unused payment data to avoid validation exception
                onValueChange(null, 'bindData.paymentDetails.bankAccountData.value');
            }
            return onValueChange(event.currentTarget.value, 'bindData.selectedPaymentPlan.value');
        }
        return null;
    };

    const generateRadioButtonColumn = (row) => {
        const id = _.get(row, 'name');
        return (
            <td>
                <div>
                    <input
                        id={id}
                        type="radio"
                        name="plan"
                        value={_.get(row, 'billingId')}
                        checked={selectedPaymentPlan === _.get(row, 'billingId')}
                        onChange={handlePaymentPlanChange}
                    />
                    <label htmlFor={id} />
                </div>
            </td>
        );
    };

    const generateNameColumn = (row) => {
        return (
            <td title={translator(messages.name)}>
                {row.name}
            </td>
        );
    };

    const generateDownPayment = (row, rowIndex) => {

        return (
            <CurrencyField
                id={`downpayment_${rowIndex}`}
                value={row.downPayment ? row.downPayment : defaultAmount}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyField}
            />
        );
    };

    const generateInstallmentPayment = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`installment_${rowIndex}`}
                value={row.installment ? row.installment : defaultAmount}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyField}
            />
        );
    };

    const generateTotalPayment = (row, rowIndex) => {
        let { total } = row;
        if (paymentPlanIsTwelveInstallments(row.billingId)) {
            total = updateTotal(total);
        }
        return (
            <CurrencyField
                id={`totalpayment_${rowIndex}`}
                value={total ? total : defaultAmount}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyField}
            />
        );
    };

    const generateRowColumns = (row, index, displayRadioColumn) => {
        if(row !== undefined) {
            return (
                <>
                    {displayRadioColumn && generateRadioButtonColumn(row, index)}
                    {generateNameColumn(row)}
                    <td title={translator(messages.downPayment)}>
                        {generateDownPayment(row, index)}
                    </td>
                    <td title={translator(messages.installment)}>
                        {generateInstallmentPayment(row, index)}
                    </td>
                    <td title={translator(messages.total)}>
                        {generateTotalPayment(row, index)}
                    </td>
                </>
            );
        }
    };

    const generateDescriptionRow = (description) => {
        return (
            <tr>
                <td colSpan="5" className="preserve-line-breaks notitle">{description}</td>
            </tr>
        );
    };

    const generateDescription = (billingID) => {
        let description = '';
        if (billingID === PaymentPlanID.THREE_PAY) {
            description = translator(messages.threePayDescription);
        }
        if (billingID === PaymentPlanID.TWELVE_INSTALLMENT) {
            description = translator(messages.monthlyAutomatedRecurringDescription);
        }
        return description;
    };

    return (
        <>
            {termMonths !== 1 && (
                <div className="mb20">
                    <h2 className="page-section-title">{translator(messages.paymentPlans)}</h2>
                    <div className="payment-selection">
                        <table className="gw-table mobile-same-line payment-details-table">
                            <thead>
                                <tr>
                                    <th />
                                    <th>{translator(messages.name)}</th>
                                    <th>{translator(messages.downPayment)}</th>
                                    <th>{translator(messages.installment)}</th>
                                    <th>{translator(messages.total)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentPlans.map((paymentPlan, index) => {
                                    if (paymentPlan !== undefined) {
                                        const description = generateDescription(_.get(paymentPlan, 'billingId'));
                                        return (
                                            <>
                                                <tr>
                                                    {generateRowColumns(paymentPlan, index, true)}
                                                </tr>
                                                {(paymentPlanIsTwelveInstallments(paymentPlan.billingId) && isTwelvePayAndTwelvePayIsDisabledOutage()) && (
                                                    <tr>
                                                        <td colSpan="5" className="preserve-line-breaks notitle twelve-pay-outage">
                                                            <b>
                                                                <span>
                                                                    {translator(messages.onlineServiceUnavailable)}
                                                                </span>
                                                            </b>
                                                            <br />
                                                            <span>
                                                                {WMICRichTextUtil.translateRichText(translator(messages.onlinePaymentPlanUnavailable, {phoneNumber: phoneNumber, quoteNumber: _.get(submissionVM, 'quoteID.value') }))}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )}
                                                {(paymentPlan.billingId === selectedPaymentPlan && description && !(isTwelvePayDisabledRegion() && paymentPlanIsTwelveInstallments(paymentPlan.billingId))) && (
                                                    generateDescriptionRow(description)
                                                )}
                                            </>
                                        );
                                    }
                                })}
                            </tbody>

                        </table>
                    </div>
                </div>
            )}
            {termMonths === 1 && (
                <div className="mb20">
                    <h2 className="page-section-title">{translator(messages.paymentPlan)}</h2>
                    <div className="payment-selection">
                        <table className="gw-table">
                            <thead>
                                <tr>
                                    <th />
                                    <th>{translator(messages.name)}</th>
                                    <th>{translator(messages.downPayment)}</th>
                                    <th>{translator(messages.installment)}</th>
                                    <th>{translator(messages.total)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentPlans.map((paymentPlan, index) => {
                                    return (
                                        <>
                                            <tr>
                                                {generateRowColumns(paymentPlan, index, false)}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>

                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

WMICPaymentList.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    hasDoubleAmountInstallment: PropTypes.shape({
        value: PropTypes.bool
    }).isRequired,
    isTwelvePayDisabledRegion: PropTypes.func.isRequired,
    isTwelvePayAndTwelvePayIsDisabledOutage: PropTypes.func.isRequired,
};

export default WMICPaymentList;
