// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICClaimDetails__uppercaseText{text-transform:uppercase}.app__WMICClaimDetails__pageContainer{width:100%}.app__WMICClaimDetails__claimDetailsWrapper{--claim-details-padding:25px;padding-top:var(--claim-details-padding)}@media(max-width:25em){.app__WMICClaimDetails__headerGrid{padding-left:17.5px;padding-right:17.5px}.app__WMICClaimDetails__headerGrid .app__WMICClaimDetails__pageTitle{font-size:3rem!important}}.app__WMICClaimDetails__claimMenuContent a{color:var(--GW-LINK-COLOR)!important;padding:.3rem 0}.app__WMICClaimDetails__claimMenuContentGrid{margin-block-end:15px}h2[id=claimMenuTitle]{font-weight:600}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-claim-react/pages/WMICClaimDetails/WMICClaimDetails.module.scss"],"names":[],"mappings":"AAAA,sCACI,wBAAA,CAGJ,sCACI,UAAA,CAGJ,4CACI,4BAAA,CACA,wCAAA,CAGJ,uBACI,mCACI,mBAAA,CACA,oBAAA,CAEA,qEACI,wBAAA,CAAA,CAMR,2CAEI,oCAAA,CADA,eACA,CAIR,6CACI,qBAAA,CAGJ,sBACI,eAAA","sourcesContent":[".uppercaseText {\n    text-transform: uppercase;\n}\n\n.pageContainer {\n    width: 100%;\n}\n\n.claimDetailsWrapper {\n    --claim-details-padding: 25px;\n    padding-top: var(--claim-details-padding);\n}\n\n@media (max-width: 25em) {\n    .headerGrid {\n        padding-left: 17.5px;\n        padding-right: 17.5px;\n\n        .pageTitle {\n            font-size: 3rem !important;\n        }\n    }\n}\n\n.claimMenuContent {\n    a {\n        padding: 0.3rem 0;\n        color: var(--GW-LINK-COLOR) !important\n    }\n}\n\n.claimMenuContentGrid {\n    margin-block-end: 15px;\n}\n\nh2[id=claimMenuTitle] {\n    font-weight: 600;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uppercaseText": "app__WMICClaimDetails__uppercaseText",
	"pageContainer": "app__WMICClaimDetails__pageContainer",
	"claimDetailsWrapper": "app__WMICClaimDetails__claimDetailsWrapper",
	"headerGrid": "app__WMICClaimDetails__headerGrid",
	"pageTitle": "app__WMICClaimDetails__pageTitle",
	"claimMenuContent": "app__WMICClaimDetails__claimMenuContent",
	"claimMenuContentGrid": "app__WMICClaimDetails__claimMenuContentGrid"
};
export default ___CSS_LOADER_EXPORT___;
