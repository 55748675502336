// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PolicyVehiclesDriversInfo__totalPremiumContainer{display:flex;justify-content:flex-end;padding:0 var(--GW-LAYOUT-8)}.app__PolicyVehiclesDriversInfo__totalPremiumContainer div{flex:0 1;white-space:nowrap}.app__PolicyVehiclesDriversInfo__policyDriverContainer:not(:last-child){margin-bottom:var(--GW-LAYOUT-10)}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policy-common-react/components/PA/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo.module.scss"],"names":[],"mappings":"AAAA,uDACI,YAAA,CACA,wBAAA,CACA,4BAAA,CAEA,2DACI,QAAA,CACA,kBAAA,CAIR,wEACI,iCAAA","sourcesContent":[".totalPremiumContainer {\n    display: flex;\n    justify-content: flex-end;\n    padding: 0 var(--GW-LAYOUT-8);\n\n    div {\n        flex: 0;\n        white-space: nowrap;\n    }\n}\n\n.policyDriverContainer:not(:last-child) {\n    margin-bottom: var(--GW-LAYOUT-10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalPremiumContainer": "app__PolicyVehiclesDriversInfo__totalPremiumContainer",
	"policyDriverContainer": "app__PolicyVehiclesDriversInfo__policyDriverContainer"
};
export default ___CSS_LOADER_EXPORT___;
