// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICNamedInsuredComponent__addInsuredContainer{display:flex}.app__WMICNamedInsuredComponent__addInsuredContainer .app__WMICNamedInsuredComponent__namedInsuredDropdown{display:flex;justify-content:center}.app__WMICNamedInsuredComponent__addInsuredContainer .app__WMICNamedInsuredComponent__addInsuredFormButtonsRow{display:flex;flex-direction:row;justify-content:flex-start;padding-top:var(--GW-SPACING-3)}.app__WMICNamedInsuredComponent__addInsuredContainer .app__WMICNamedInsuredComponent__addInsuredFormButtonsRow .app__WMICNamedInsuredComponent__addInsuredFormButtonContainer{display:flex;flex-direction:row;justify-content:space-around;width:30%}.app__WMICNamedInsuredComponent__newAIDropdownMenuButton{position:static}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICNamedInsuredComponent/WMICNamedInsuredComponent.module.scss"],"names":[],"mappings":"AAIA,qDACI,YAAA,CACA,2GACI,YAAA,CACA,sBAAA,CAEJ,+GACI,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,+BAAA,CACA,8KAEI,YAAA,CACA,kBAAA,CACA,4BAAA,CAHA,SAGA,CAKZ,yDACI,eAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\n.addInsuredContainer {\n    display: flex;\n    .namedInsuredDropdown {\n        display: flex;\n        justify-content: center;\n    }\n    .addInsuredFormButtonsRow {\n        display: flex;\n        flex-direction: row;\n        justify-content: flex-start;\n        padding-top: var(--GW-SPACING-3);\n        .addInsuredFormButtonContainer {\n            width: 30%;\n            display: flex;\n            flex-direction: row;\n            justify-content: space-around;\n        }\n    }\n}\n\n.newAIDropdownMenuButton {\n    position: unset;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addInsuredContainer": "app__WMICNamedInsuredComponent__addInsuredContainer",
	"namedInsuredDropdown": "app__WMICNamedInsuredComponent__namedInsuredDropdown",
	"addInsuredFormButtonsRow": "app__WMICNamedInsuredComponent__addInsuredFormButtonsRow",
	"addInsuredFormButtonContainer": "app__WMICNamedInsuredComponent__addInsuredFormButtonContainer",
	"newAIDropdownMenuButton": "app__WMICNamedInsuredComponent__newAIDropdownMenuButton"
};
export default ___CSS_LOADER_EXPORT___;
