import React from 'react';
import { useTranslator } from '@jutro/locale';

import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import util from './WMICAdditionalVehicleInfoBoxUtil';

import metadata from './WMICAdditionalVehicleInfoBox.metadata.json5';
import messages from './WMICAdditionalVehicleInfoBox.messages';
import styles from './WMICAdditionalVehicleInfoBox.module.scss';

const WMIAdditionalVehicleInfoBox = (props) => {
    const { claimData: claimInfo } = props;
    const translator = useTranslator();

    const getOtherInsuredVehicles = (info) => {
        return util.getOtherInsuredVehicles(info, translator, messages);
    };

    const enhanceClaimInfo = (data) => {
        // eslint-disable-next-line no-param-reassign
        data.otherInsuredVehicles = getOtherInsuredVehicles(data);
    };
    enhanceClaimInfo(claimInfo);

    const overrides = {
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrides}
            model={claimInfo}
        />
    );
};

WMIAdditionalVehicleInfoBox.propTypes = {
    claimData: PropTypes.shape({})
};

WMIAdditionalVehicleInfoBox.defaultProps = {
    claimData: {}
};

export default WMIAdditionalVehicleInfoBox;