import { defineMessages } from 'react-intl';


export default defineMessages({
    updateFinanceFormTitle: {
        id: 'wmic.policy-change.component.update-finance-form-pa.add-finance',
        defaultMessage: 'Add finance or leasing companies using the form below'
    },
    updateFinanceFormVehicleDropdownPlaceholder: {
        id: 'wmic.policy-change.component.update-finance-form-pa.vehicle-dropdown-placeholder',
        defaultMessage: 'Select a vehicle'
    },
    updateFinanceFormVehicleDropdownLabel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.vehicle-dropdown-label',
        defaultMessage: 'Vehicle'
    },
    updateFinanceFormCompanyLabel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.company-label',
        defaultMessage: 'Company Name'
    },
    updateFinanceFormAccountLabel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.account-label',
        defaultMessage: 'Loan or Account #'
    },
    updateFinanceFormStreetLabel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.street-label',
        defaultMessage: 'Street'
    },
    updateFinanceFormCityLabel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.city-label',
        defaultMessage: 'City'
    },
    updateFinanceFormStateLabel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.state-label',
        defaultMessage: 'State'
    },
    updateFinanceFormZIPLabel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.zip-label',
        defaultMessage: 'ZIP'
    },
    cancel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.cancel',
        defaultMessage: 'Cancel'
    },
    continue: {
        id: 'wmic.policy-change.component.update-finance-form-pa.continue',
        defaultMessage: 'Continue'
    },
    updateFinanceFormCancelModalTitle: {
        id: 'wmic.policy-change.component.update-finance-form-pa.cancel-modal-title',
        defaultMessage: 'Changes will be lost'
    },
    updateFinanceFormCancelModalSubtitle: {
        id: 'wmic.policy-change.component.update-finance-form-pa.cancel-modal-subtitle',
        defaultMessage: 'If you cancel now you\'ll lose any changes you\'ve made.'
    },
    updateFinanceFormCancelModalKeepEditing: {
        id: 'wmic.policy-change.component.update-finance-form-pa.cancel-modal-keep-editing',
        defaultMessage: 'Keep Editing'
    },
    updateFinanceFormCancelModalCancel: {
        id: 'wmic.policy-change.component.update-finance-form-cancel-pa.modal-cancel-changes',
        defaultMessage: 'Cancel Changes'
    },
    updateFinanceFormConfirmModalTitle: {
        id: 'wmic.policy-change.component.update-finance-form-pa.confirm-modal-title',
        defaultMessage: 'Your policy change is ready to submit'
    },
    updateFinanceFormConfirmModalSubtitle: {
        id: 'wmic.policy-change.component.update-finance-form-pa.confirm-modal-subtitle',
        defaultMessage: 'Once submitted, an agent will review and finish processing this change. You will recieve updated documents confirming when the change has taken effect'
    },
    updateFinanceFormConfirmModalCancel: {
        id: 'wmic.policy-change.component.update-finance-form-pa.confirm-modal-cancel',
        defaultMessage: 'Cancel'
    },
    updateFinanceFormConfirmModalSubmit: {
        id: 'wmic.policy-change.component.update-finance-form-pa.confirm-modal-submit',
        defaultMessage: 'Submit'
    },
    zipCodeIncorrect: {
        id: 'wmic.policy-change.component.update-finance-form-pa.zipCodeIncorrect',
        defaultMessage: 'Zip code must be 5 or 9 digits.'
    }
});