// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICIcon__wmicIconLarge{font-size:1.5rem}.app__WMICIcon__wmicIconMedium{font-size:1.25rem}.app__WMICIcon__wmicIconSmall{font-size:1rem}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICIcon/WMICIcon.module.scss"],"names":[],"mappings":"AAEE,8BACE,gBAAA,CAGF,+BACE,iBAAA,CAGF,8BACE,cAAA","sourcesContent":[".wmicIcon{\n\n  &Large{\n    font-size: 1.5rem;\n  }\n\n  &Medium{\n    font-size: 1.25rem;\n  }\n\n  &Small{\n    font-size: 1rem;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicIconLarge": "app__WMICIcon__wmicIconLarge",
	"wmicIconMedium": "app__WMICIcon__wmicIconMedium",
	"wmicIconSmall": "app__WMICIcon__wmicIconSmall"
};
export default ___CSS_LOADER_EXPORT___;
