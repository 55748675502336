import React, {useContext, useMemo} from 'react';
import _ from 'lodash';
import {WizardContext} from 'wmic-pe-portals-custom-wizard-react';
import {ViewModelForm} from '@xengage/gw-portals-viewmodel-react';
import {
    CONSTANTS,
    LOBConstants, POLICY_DETAILS,
    WMICCreditConsentUtil, WMICDateTimeService, WMICRichTextUtil,
} from 'wmic-pe-portals-utils-js';

import metadata from './WMICPolicyInsuranceHistoryCreditConsent.metadata.json5';
import messages from "../WMICPolicyInsuranceHistoryCreditConsent/WMICPolicyInsuranceHistoryCreditConsent.messages";
import {PAConsentMessages} from "wmic-pe-capability-gateway-quoteandbind-pa-react";
import {TranslatorContext} from "../../../../../../../../../platform/@jutro/locale";

function WMICPolicyInsuranceHistoryCreditConsent(props) {
    const {data: selectedInsured} = props;
    const {wizardData: {policyVM}} = useContext(WizardContext);
    const translator = useContext(TranslatorContext);

    const isPersonalProperty = useMemo(() => _.get(policyVM, 'product.productCode.value') === LOBConstants.PP, [policyVM]);
    const creditConsentReceived = useMemo(() => _.get(selectedInsured, 'creditConsentReceived.value.code', false), [selectedInsured]);

    const showCreditInfo = useMemo(() => {
        return WMICCreditConsentUtil.isPersonCreditConsentable(selectedInsured)
    }, [selectedInsured]);

    const isPNI = useMemo(
        () => _.get(selectedInsured, 'isPrimaryInsured.value'),
        [selectedInsured]
    );
    const consentReceivedNoOrWithdrawn = useMemo(() => creditConsentReceived === CONSTANTS.CREDIT_CONSENT_CODES.NO || creditConsentReceived === CONSTANTS.CREDIT_CONSENT_CODES.WITHDRAWN, [creditConsentReceived])
    const consentReceivedYes = useMemo(() => creditConsentReceived === POLICY_DETAILS.YES, [selectedInsured, creditConsentReceived])
    const displayName = useMemo(() => _.get(selectedInsured, 'displayName.value', ''), [selectedInsured]);

    const overrideProps = {
        '@field': {
            parentNode: selectedInsured,
            readOnly: true,
        },
        creditConsentContainer: {
            visible: showCreditInfo && creditConsentReceived
        },
        creditConsentReceived: {
            label: isPNI ? translator(messages.pniConsentReceived) : translator(messages.aniConsentReceived)
        },
        creditConsentDeclinedDate: {
            visible: consentReceivedNoOrWithdrawn
        },
        creditConsentWithdrawalForm: {
            visible: consentReceivedNoOrWithdrawn
        },
        creditConsentDate: {
            visible: consentReceivedYes,
        },
        creditConsentFormReceived: {
            visible: consentReceivedYes,
        },
        creditConsentDOB: {
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.dateOfBirthFor, {name: selectedInsured.firstName.value})),
            value: WMICDateTimeService.toMidnightDate(_.get(selectedInsured, "dateOfBirth.value")),
            visible: consentReceivedYes && isPersonalProperty
        },
        creditConsentHeading: {
            title: displayName,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={selectedInsured}
            overrideProps={overrideProps}
        />
    );
}

export default WMICPolicyInsuranceHistoryCreditConsent;
