import { defineMessages } from 'react-intl';

export default defineMessages({
    removeVehicle: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.Remove vehicle',
        defaultMessage: 'Remove vehicle'
    },
    pleaseConfirmTheDeletionOfTheFollowingVehicle: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.Please confirm the deletion of the following vehicle:',
        defaultMessage: 'Please confirm the deletion of the following vehicle:'
    },
    vin: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.VIN: ',
        defaultMessage: 'VIN: '
    },
    reason: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.Reason: ',
        defaultMessage: 'Reason: '
    },
    date: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.Date: ',
        defaultMessage: 'Date: '
    },
    onceSubmittedAnAgentWillContactYouShortly: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.Once submitted, an agent will contact you shortly to finish processing this change. After it is fully processed, the agent will confirm with you when the change will take effect.',
        defaultMessage: 'Once submitted, an agent will contact you shortly to finish processing this change. After it is fully processed, the agent will confirm with you when the change will take effect.'
    },
    cancel: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.Cancel',
        defaultMessage: 'Cancel'
    },
    yesRemove: {
        id: 'wmic.policy-change.component.confirm-remove-vehicle-pa.modal.Yes, Remove',
        defaultMessage: 'Yes, Remove'
    }
});