/**
 * Creates a digital authorisation context (based on a user info)
 *
 * @memberof module:gw-digital-auth-react
 * @function transformToDigitalAuthContext
 * @private
 * @param {Object} jutroAuthContext - a jutro authorisation context
 * @param {String} token - an authorisation token
 * @returns {Object} authorisation context:
 * <pre>
 *  {user_id, user_name, name, email, emai_verified, previous_logon_time,
 *  sub, accessToken, authHeader (Authorization), isAuthInProgress,
 *  isLoggedIn, logout, useJutroAuthContext}
 * </pre>
 */

const transformToDigitalAuthContext = (jutroAuthContext, token) => {
    return {
        ...jutroAuthContext,
        authHeader: {
            Authorization: `Bearer ${token}`,
        }
    };
};

/**
 * Returns a digital authorisation context built on a user info from the authorisation context and token
 *
 * @memberof module:gw-digital-auth-react
 * @function getDigitalAuthContext
 * @private
 * @param {Object} jutroAuthContext - a jutro authorisation context
 * @returns {Object} - digital authorisation context
 */

const getDigitalAuthContext = jutroAuth => {
    try {
        const { accessToken } = jutroAuth;

        return transformToDigitalAuthContext(jutroAuth, accessToken);
    } catch (e) {
        throw e;
    }
};

export default {
    transformToDigitalAuthContext,
    getDigitalAuthContext
};
