/* eslint-disable react/prop-types */
import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNext
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import messages from './WMICUIIssuesModal.messages';
import metadata from './WMICUIIssuesModal.metadata.json5'
import paymentPageMessages from '../WMICPaymentPage.messages';

const WMICUIIssuesModal = ({
    isOpen,
    onResolve,
    uwIssues
}) => {
    const translator = useContext(TranslatorContext);
    const [uwIssuesList, setUwIssuesList] = useState([]);

    const handleClose = () => {
        onResolve();
    };

    useEffect(() => {
        const uwIssuesMessagesList = [];
        _.each(uwIssues, (uwIssue) => {
            uwIssuesMessagesList.push(
                { message: uwIssue }
            )
        });
        setUwIssuesList(uwIssuesMessagesList);
    }, []);

    const overrideProps = {
        uwIssuesList: {
            data: uwIssuesList
        }
    }

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose}
            status="error"
        >
            <ModalHeader
                title={translator(paymentPageMessages.error)}
                status="error"
            />
            <ModalBody>
                <MetadataContent uiProps={metadata.pageContent} overrideProps={overrideProps} />
            </ModalBody>
            <ModalFooter>
                <div>
                    <WMICButton
                        onClick={handleClose}
                        type="primary"
                    >
                        {translator(commonMessages.ok)}
                    </WMICButton>
                </div>
            </ModalFooter>
        </ModalNext>
    );
};

WMICUIIssuesModal.propTypes = {
    uwIssues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default WMICUIIssuesModal;