/* eslint-disable max-len */
import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import appConfig from 'app-config';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { useHistory, Prompt } from 'react-router-dom';
import { pageTemplateProps, WizardContext } from '@xengage/gw-portals-wizard-react';
import { WMICSidebarBoxes, WMICQuoteNumberComponent } from 'gw-portals-wizard-components-ui';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { Tooltip } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WMICButton } from 'wmic-components-platform-react';
import messages from './WMICDigitalWizardPageTemplateQnB.messages';
import styles from './WMICDigitalWizardPageTemplateQnB.module.scss';
import WMICWizardSidebarQnB from '../components/WMICWizardSidebarQnB';
import MobileWizardSteps from '../components/MobileWizardSteps/MobileWizardSteps';
import WMICMailQuote from '../components/WMICMailQuote';
import WMICPrintQuote from '../components/WMICPrintQuote';

function renderLoader(isLoading) {
    return isLoading ? <i className='fa fa-spinner fa-spin gw-inline-loader' /> : null;
}

function WMICDigitalWizardPageTemplateQnB(props) {
    const translator = useTranslator();
    const wizardContext = useContext(WizardContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = useMediaQuery({ query: '(max-width: 640px)' });
    const showProgressDots = useMediaQuery({ query: '(max-width: 641px)' });
    const [showSidebar, setShowSidebar] = useState(false);

    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        isLoadingPrevious,
        isLoadingCancel,
        renderContextComponent,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        customMessageModal,
        showMandatoryInfo,
        mandatoryInfoLabel,
        quoteStatus,
        setQuoteStatus,
        recalculate,
        showQuoteDisclaimer,
        showEmailQuoteButton,
        showCaliforniaLawDisclaimer,
        validCoverages,
        onCancel,
        customWizardButton,
        showBottomFeeMessage
    } = props;
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            const region = WMICRegionUrlUtil.getRegion();
            let result = appConfig.applicationDetails.applicationName;
            if (region === CONSTANTS.JURISDICTIONS.CA) {
                result = CONSTANTS.TITLE.CA;
            } else if (region === CONSTANTS.JURISDICTIONS.OR) {
                result = CONSTANTS.TITLE.OR;
            } else {
                result = CONSTANTS.TITLE.DEFAULT;
            }
            document.title = result;
        }, 100);
    }, []);

    useEffect(() => {
        switch (breakpoint) {
            case 'phone':
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const toggleSideBar = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar]);

    const handlePrevious = useCallback((location, action) => {
        if (action === 'POP') {
            if( wizardContext.currentStepIndex > 0) {
                wizardContext.goPrevious();
                return false;
            }
            onCancel();
            return _.isNil(wizardContext.wizardData.quoteID.value);
        }
        return true;        
    }, [onCancel, wizardContext]);

    const contextComponent = useCallback(() => {
        return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    }, [renderContextComponent, wizardSnapshot]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const nextButton = useMemo(() => (
        <WMICButton
            id="gw-wizard-Next"
            className={cx("wizard-btn mobile-hidden wmicFloatRight", styles.nextBtn)}
            disabled={disableNext || isPerformingTransiton}
            onClick={onNext}
        >
            {translator(nextLabel)}
            {renderLoader(isLoadingNext)}
        </WMICButton>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, onNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;

    const wizardStepsWithContainer = () => {
        return (
            <div className="wmicWizardProgress">
                <MobileWizardSteps toggleSideBar={toggleSideBar} />
            </div>
        );
    };

    const currentWizardPage = _.get(history, 'location.pathname');
    const renderReturnLaterButton = currentWizardPage !== '/quote-ho/your-info';
    const buttonsContainerClass = currentWizardPage ? 'page-buttons vmargin-page-buttons mobile-hidden fwidth' : 'page-buttons vmargin-page-buttons';
    const showPrintEmailQuote = wizardSnapshot?.quoteData?.value?.offeredQuotes[0]?.status === 'Quoted';
    const showQuotePageButtons = currentWizardPage === '/quote-ho/quote';
    const quotePageButtonsClass = showQuotePageButtons ? styles.quotePageButtons : ''
    // separate print email components
    return (
        <div className="main-wrapper">
            <div className="main">
                <div className="content-wrapper">
                    <div className="container content wmicContentDisplayBlock">
                        <div className="quotewizard">
                            {showContextComponent && contextComponent()}
                            <div className="wizard">
                                <div className="wizard-main">
                                    <div className="page box">
                                        {showProgressDots && (
                                            <WMICQuoteNumberComponent />
                                        )}
                                        {showProgressDots ? wizardStepsWithContainer() : null}
                                        {showWizardHeader && contextComponent()}
                                        {content}
                                        {showMandatoryInfo && (
                                            <div className="ww-row-stretched">
                                                <span className="ww-required-message ww-ease-animate">
                                                    <span className="ww-required-color">*</span>
                                                    <span>{translator(mandatoryInfoLabel)}</span>
                                                </span>
                                            </div>
                                        )}
                                        <div className={`${buttonsContainerClass} ${styles.buttonsContainer} ${quotePageButtonsClass}`}>
                                            {showPrintEmailQuote && showEmailQuoteButton ? (
                                                <WMICMailQuote
                                                    isStale={quoteStatus?.isStale}
                                                    calculatingQuote={quoteStatus?.calculatingQuote}
                                                    classNames={`${styles.emailQuoteButton} wizard-btn`}
                                                />) : null}
                                            {renderReturnLaterButton && (
                                                <WMICButton
                                                    id="gw-wizard-return-later"
                                                    type="outlined"
                                                    onClick={onCancel}
                                                    className={`${styles.wmicReturnLaterButton} wizard-btn`}
                                                >
                                                    {translator(messages.returnLaterButtonLabel)}
                                                </WMICButton>
                                            )}
                                            {(showNext && !customWizardButton?.hideWizardNextButton) && renderNextButton}
                                            {customWizardButton?.enabled && (
                                                <WMICButton
                                                    id="gw-wizard-Custom"
                                                    className={cx("wizard-btn mobile-hidden wmicFloatRight", styles.nextBtn)}
                                                    onClick={customWizardButton?.callbackFunction}
                                                >
                                                    {translator(customWizardButton?.label)}
                                                </WMICButton>
                                            )}
                                            {showQuotePageButtons ? (<WMICPrintQuote isStale={quoteStatus?.isStale} calculatingQuote={quoteStatus?.calculatingQuote} />) : null}
                                        </div>
                                        {showBottomFeeMessage && (
                                            <div id='bottomFeeContainer'>
                                                <div id='bottomFeeText'>
                                                    {translator(messages.bottomCreditCardFee)}
                                                </div>
                                            </div>
                                        )}
                                        <p>
                                            {showQuotePageButtons && showPrintEmailQuote && showQuoteDisclaimer ? translator(messages.rentersQuoteDisclaimer) : null}
                                        </p>
                                        {showCaliforniaLawDisclaimer && (
                                            <p>
                                                {translator(messages.californiaLawRequires)}
                                                <br />
                                                {translator(messages.californiaLawDisclaimer)}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="wizard-sidebar noprint">
                                    {!isPhone ? (
                                        <WMICWizardSidebarQnB
                                            customMessageModal={customMessageModal}
                                            quoteStatus={quoteStatus}
                                            setQuoteStatus={setQuoteStatus}
                                            recalculate={recalculate}
                                            validCoverages={validCoverages}
                                        />
                                    ) : null}
                                    <WMICSidebarBoxes />
                                    <Prompt
                                        message={(location, action) => {
                                            return handlePrevious(location, action);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

WMICDigitalWizardPageTemplateQnB.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string,
    showMandatoryInfo: PropTypes.bool,
    mandatoryInfoLabel: PropTypes.string,
    showQuoteDisclaimer: PropTypes.bool,
    showEmailQuoteButton: PropTypes.bool,
    showCaliforniaLawDisclaimer: PropTypes.bool,
    showBottomFeeMessage: PropTypes.bool,
    customWizardButton: PropTypes.shape({
        callbackFunction: PropTypes.func,
        enabled: PropTypes.bool,
        label: PropTypes.string,
        hideWizardNextButton: PropTypes.bool
    })

};

WMICDigitalWizardPageTemplateQnB.defaultProps = {
    renderContextComponent: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    nextButtonTooltip: '',
    showMandatoryInfo: false,
    mandatoryInfoLabel: '',
    showQuoteDisclaimer: true,
    showEmailQuoteButton: true,
    showCaliforniaLawDisclaimer: false,
    showBottomFeeMessage: false,
    customWizardButton: {
        callbackFunction: () => null,
        enabled: false,
        label: '',
        hideWizardNextButton: false
    }
};

export default WMICDigitalWizardPageTemplateQnB;