import React, { useContext } from 'react';
import _ from 'lodash';

import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WMICRichTextUtil, JobType } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';

import metadata from './WMICHODisappearingDeductible.metadata.json5'
import messages from './WMICHODisappearingDeductible.messages.js'

function WMICHODisappearingDeductible({disappearingDeductibleReducedValue, baseData}) {
    const translator = useContext(TranslatorContext);

    const overrideProps = {
        disappearingDeductibleContainer: {
            visible: !_.isUndefined(disappearingDeductibleReducedValue) && _.get(baseData, 'jobType.value.code') !== JobType.SUBMISSION
        },
        disappearingDeductibleReduced: {
            visible: disappearingDeductibleReducedValue !== "",
            content: WMICRichTextUtil.translateRichText(translator(messages.disappearingDeductibleReduced, {disappearingDeductibleValue: disappearingDeductibleReducedValue}))
        },
    }

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} />;
}

export default WMICHODisappearingDeductible