// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__HelpTabComponent__quickLinkTabs{background-color:var(--GW-BACKGROUND-COLOR);border-radius:var(--GW-BORDER-RADIUS);box-shadow:var(--GW-SHADOW-1)}.app__HelpTabComponent__gw-agent-page h1.app__HelpTabComponent__gw-titles-title{margin-bottom:var(--GW-LAYOUT-4)}.app__HelpTabComponent__gw-tabbable button.app__HelpTabComponent__tabButton{padding:var(--GW-LAYOUT-5) var(--GW-LAYOUT-3) 0}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-react/Components/HelpPage/HelpTabComponent.module.scss"],"names":[],"mappings":"AAOA,sCAGI,2CAAA,CAFA,qCAAA,CACA,6BACA,CAGJ,gFACI,gCAAA,CAGJ,4EACI,+CAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n\n.gw-tabbable button.tabButton {\n    padding: var(--GW-LAYOUT-5) var(--GW-LAYOUT-3) 0;\n  }\n\n.quickLinkTabs {\n    border-radius: var(--GW-BORDER-RADIUS);\n    box-shadow: var(--GW-SHADOW-1);\n    background-color: var(--GW-BACKGROUND-COLOR);\n  }\n\n.gw-agent-page h1.gw-titles-title {\n    margin-bottom: var(--GW-LAYOUT-4);\n  }\n\n.gw-tabbable button.tabButton {\n    padding: var(--GW-LAYOUT-5) var(--GW-LAYOUT-3) 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quickLinkTabs": "app__HelpTabComponent__quickLinkTabs",
	"gw-agent-page": "app__HelpTabComponent__gw-agent-page",
	"gw-titles-title": "app__HelpTabComponent__gw-titles-title",
	"gw-tabbable": "app__HelpTabComponent__gw-tabbable",
	"tabButton": "app__HelpTabComponent__tabButton"
};
export default ___CSS_LOADER_EXPORT___;
