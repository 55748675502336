// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICCancelEditReviewPolicyChangeModal__wmic-payment-text{font-size:1.3rem!important;margin-bottom:30px!important}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policychange-common-react/components/WMICReviewPolicyChangePA/WMICCancelEditReviewPolicyChangeModal/WMICCancelEditReviewPolicyChangeModal.module.scss"],"names":[],"mappings":"AAAA,+DACI,0BAAA,CACA,4BAAA","sourcesContent":[".wmic-payment-text {\n    font-size: 1.3rem !important;\n    margin-bottom: 30px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-payment-text": "app__WMICCancelEditReviewPolicyChangeModal__wmic-payment-text"
};
export default ___CSS_LOADER_EXPORT___;
