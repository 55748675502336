import { defineMessages } from 'react-intl';

export default defineMessages({
    clickEdit: {
        id: 'payment.views.make-a-payment-modal.The following payment amounts are currently due. To modify your payment amount or make payments toward future invoices, click Edit.',
        defaultMessage: 'The following payment amounts are currently due. To modify your payment amount or make payments toward future invoices, click "Edit Amount".'
    },
    clickEditAmount: {
        id: 'payment.views.make-a-payment-modal.The following payment amount(s) are the upcoming payments for your policy. To modify your payment amount, click Edit Amount.',
        defaultMessage: 'The following payment amount(s) are the upcoming payments for your policy. To modify your payment amount, click "Edit Amount".'
    },
    canBePaid: {
        id: 'payment.views.make-a-payment-modal.Required payments must be paid in full before upcoming payments can be paid',
        defaultMessage: 'Required payments must be paid in full before upcoming payments can be paid. Any payments made will be applied to the required payments total before being applied to the upcoming payment amounts. \n\nYou can choose to pay less than the required total payment but risk cancellation or suspension of your policy. For more information see your policy declaration.'
    },
    makeAPayment: {
        id: 'payment.views.payment-status-modal.Make a payment',
        defaultMessage: 'Make a payment'
    },
    confirmPaymentAmount: {
        id: 'payment.views.make-a-payment-modal.Confirm Payment Amount',
        defaultMessage: 'Confirm Payment Amount'
    },
    editPaymentAmount: {
        id: 'payment.views.make-a-payment-modal.Edit Payment Amount',
        defaultMessage: 'Edit Payment Amount'
    },
    continue: {
        id: 'payment.views.make-a-payment-modal.Continue',
        defaultMessage: 'Continue'
    },
    processPayment: {
        id: 'payment.views.make-a-payment-modal.Process Payment',
        defaultMessage: 'Process Payment'
    },
    cancel: {
        id: 'payment.views.make-a-payment-modal.Cancel',
        defaultMessage: 'Cancel'
    },
    canceled: {
        id: 'payment.views.make-a-payment-modal.canceled',
        defaultMessage: 'canceled'
    },
    close: {
        id: 'payment.views.make-a-payment-modal.Close',
        defaultMessage: 'Close'
    },
    ok: {
        id: 'payment.views.make-a-payment-modal.OK',
        defaultMessage: 'OK'
    },
    saveChanges: {
        id: 'payment.views.make-a-payment-modal.Save Changes',
        defaultMessage: 'Save Changes'
    },
    totalPayment: {
        id: 'payment.views.make-a-payment-modal.Total Payment',
        defaultMessage: 'Total Payment'
    },
    yourSelectedPayment: {
        id: 'payment.views.make-a-payment-modal.Your Selected Payment',
        defaultMessage: 'Your Selected Payment'
    },
    editAmount: {
        id: 'payment.views.make-a-payment-modal.Edit Amount',
        defaultMessage: 'Edit Amount'
    },
    modifyAmount: {
        id: 'payment.views.make-a-payment-modal.To modify your payment amount click edit',
        defaultMessage: 'To modify your payment amount click "Edit Amount"'
    },
    yourCustomPayment: {
        id: 'payment.views.make-a-payment-modal.Your Custom Payment',
        defaultMessage: 'Your Custom Payment'
    },
    requiredPayments: {
        id: 'payment.views.make-a-payment-modal.Required Payments',
        defaultMessage: 'Required Payments'
    },
    totalRequiredPayments: {
        id: 'payment.views.make-a-payment-modal.Total Required Payments',
        defaultMessage: 'Total Required Payments'
    },
    upcomingPayments: {
        id: 'payment.views.make-a-payment-modal.Upcoming Payments',
        defaultMessage: 'Upcoming Payments'
    },
    totalRemainingBalance: {
        id: 'payment.views.make-a-payment-modal.Total Remaining Balance',
        defaultMessage: 'Total Remaining Balance'
    },
    overdueAmount: {
        id: 'payment.views.make-a-payment-modal.Overdue amount',
        defaultMessage: 'Overdue Amount'
    },
    paymentDue: {
        id: 'payment.views.make-a-payment-modal.Payment Due (date)',
        defaultMessage: 'Payment Due ({date})'
    },
    nextPayment: {
        id: 'payment.views.make-a-payment-modal.Next Payment (date)',
        defaultMessage: 'Next Payment ({date})'
    },
    renewPaymentDue: {
        id: 'payment.views.make-a-payment-modal.Renewal Payment Due (date)',
        defaultMessage: 'Renewal Payment Due ({date})'
    },
    futurePayments: {
        id: 'payment.views.make-a-payment-modal.Future Payment',
        defaultMessage: 'Future Payments'
    },
    paymentAmount: {
        id: 'payment.views.make-a-payment-modal.Payment Amount',
        defaultMessage: 'Payment Amount: '
    },
    selectAPaymentMethod: {
        id: 'payment.views.make-a-payment-modal.Select a Payment Method',
        defaultMessage: 'Select a Payment Method'
    },
    creditCardOrDebitCard: {
        id: 'portal.views.claim-summary-box.Credit Card or Debit Card',
        defaultMessage: 'Credit Card or Debit Card'
    },
    theMaximumPaymentPerTransactionIs1500: {
        id: 'portal.views.claim-summary-box.The maximum payment per transaction is $1,500.00.',
        defaultMessage: 'The maximum payment per transaction is $1,500.00.'
    },
    checkingOrSavingsAccountOneTimeWithdrawalNoProcessingFee: {
        id: 'portal.views.claim-summary-box.Checking or Savings Account - One-time Withdrawal (No Processing Fee)',
        defaultMessage: 'Checking or Savings Account - One-time Withdrawal (No Processing Fee)'
    },
    noFeesOrTransactionLimitsApplyToPaymentsMadeFromYourBankAccount: {
        id: 'portal.views.claim-summary-box.No fees or transaction limits apply to payments made from your bank account.',
        defaultMessage: 'No fees or transaction limits apply to payments made from your bank account.'
    },
    select: {
        id: 'payment.views.make-a-payment-modal.Select',
        defaultMessage: 'Select'
    },
    creditCardOrDebitCard499ProcessingFee: {
        id: 'payment.views.make-a-payment-modal.Credit Card or Debit Card ($4.99 Processing Fee)',
        defaultMessage: 'Credit Card or Debit Card ($4.99 Processing Fee)'
    },
    checkingOrSavingsAccountNoProcessingFee: {
        id: 'payment.views.make-a-payment-modal.Checking or Savings Account (No Processing Fee)',
        defaultMessage: 'Checking or Savings Account (No Processing Fee)'
    },
    masterCardVisaDiscover: {
        id: 'payment.views.make-a-payment-modal.Master Card, Visa, Discover',
        defaultMessage: 'Master Card, Visa, Discover'
    },
    noteTheCreditCardProcessingCompanyWillChargeYouANonRefundable499ProcessingConvenienceFee: {
        id: 'payment.views.make-a-payment-modal.Note: The credit card processing company will charge you a non-refundable $4.99 processing (convenience) fee. As an alternative, bank payments have no additional costs.',
        defaultMessage: 'Note: The credit card processing company will charge you a non-refundable $4.99 processing (convenience) fee. As an alternative, bank payments have no additional costs.'
    },
    uponClickingContinueYouWillBeRedirectedToAThirdPartyWebsite: {
        id: 'payment.views.make-a-payment-modal.Upon clicking "Continue" you will be redirected to a third party website, which is not owned or operated by The Wawanesa General Insurance Company. Although we carefully select the web links we place on our websites, the content, products, and information contained on third party websites are not owned or controlled by The Wawanesa General Insurance Company.',
        defaultMessage: 'Upon clicking "Continue" you will be redirected to a third party website, which is not owned or operated by The Wawanesa General Insurance Company. Although we carefully select the web links we place on our websites, the content, products, and information contained on third party websites are not owned or controlled by The Wawanesa General Insurance Company.'
    },
    selectPaymentDate: {
        id: 'payment.views.make-a-payment-modal.Select Payment Date',
        defaultMessage: 'Select Payment Date'
    },
    bankAccountType: {
        id: 'payment.views.make-a-payment-modal.Bank Account Type',
        defaultMessage: 'Bank Account Type'
    },
    checking: {
        id: 'payment.views.make-a-payment-modal.Checking',
        defaultMessage: 'Checking'
    },
    savings: {
        id: 'payment.views.make-a-payment-modal.Savings',
        defaultMessage: 'Savings'
    },
    pleaseChooseAnAccountTypeBeforeProceeding: {
        id: 'payment.views.make-a-payment-modal.Please choose an account type before proceeding.',
        defaultMessage: 'Please choose an account type before proceeding.'
    },
    nonSufficientFundsFeeWillBeAssessed: {
        id: 'payment.views.make-a-payment-modal. A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.',
        defaultMessage: ' A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.'
    },
    chequeSample: {
        id: 'payment.views.make-a-payment-modal.Cheque sample',
        defaultMessage: 'Cheque sample'
    },
    checkNumberIsNotARequiredField: {
        id: 'payment.views.make-a-payment-modal.* Check number is not a required field.',
        defaultMessage: '* Check number is not a required field.'
    },
    enterBankAccountInformation: {
        id: 'payment.views.make-a-payment-modal.Enter Bank Account Information',
        defaultMessage: 'Enter Bank Account Information'
    },
    pleaseNoteThatTheSelectedTransactionDateIsPastYourCurrentPaymentDueDate: {
        id: 'payment.views.make-a-payment-modal.Please note that the selected transaction date is past your current payment due date',
        defaultMessage: 'Please note that the selected transaction date &lt;strong&gt;{transactionDate}&lt;/strong&gt; is past your current payment due date &lt;strong&gt;{paymentDueDate}&lt;/strong&gt;. Your policy will be overdue if the payment is not received by &lt;strong&gt;{paymentDueDate}&lt;/strong&gt;.'
    },
    routingTransitNumber: {
        id: 'payment.views.make-a-payment-modal.Routing Transit Number',
        defaultMessage: 'Routing Transit Number'
    },
    bankAccountNumber: {
        id: 'payment.views.make-a-payment-modal.Bank Account Number',
        defaultMessage: 'Bank Account Number'
    },
    today: {
        id: 'payment.views.make-a-payment-modal.Today:',
        defaultMessage: 'Today:'
    },
    primaryNamedInsured: {
        id: 'payment.views.make-a-payment-modal.Primary Named Insured',
        defaultMessage: 'Primary Named Insured'
    },
    namedInsured: {
        id: 'payment.views.make-a-payment-modal.Named Insured',
        defaultMessage: 'Named Insured'
    },
    routingTransitNumberMustBe9DigitsLong: {
        id: 'payment.views.make-a-payment-modal.Routing transit number must be 9 digits long.',
        defaultMessage: 'Routing transit number must be 9 digits long.'
    },
    bankAccountNumberMustBe1To17Digits: {
        id: 'payment.views.make-a-payment-modal.Bank account number must be 1 to 17 digits.',
        defaultMessage: 'Bank account number must be 1 to 17 digits.'
    },
    overDueAmountPaid: {
        id: 'payment.views.make-a-payment-modal.Please note that your policy will remain in delinquent status until the overdue amount has been paid in full.',
        defaultMessage: 'Please note that your policy will remain in delinquent status until the overdue amount of {overDueAmount} has been paid in full.'
    },
    afterPayment: {
        id: 'payment.views.make-a-payment-modal.Please note you cannot leave amounts less than $1.00 remaining after payment...',
        defaultMessage: 'Please note you cannot leave amounts less than $1.00 remaining after payment. Please update your selected payment amount.'
    },
    outstandingPolicy: {
        id: 'payment.views.make-a-payment-modal.You have entered an amount greater than the total amount outstanding for your policy',
        defaultMessage: 'You have entered an amount greater than the total amount outstanding for your policy'
    },
    paidByThirdParty: {
        id: 'payment.views.make-a-payment-modal.Note that your policy is currently being paid by a third party',
        defaultMessage: 'Our records indicate that your mortgage company has already been billed. You may proceed to make a payment or contact your lender for your past due policy. Please note it may take up to 3 business days for the payment to be reflected on your account.'
    },
    multipleTransactions: {
        id: 'payment.views.make-a-payment-modal.Please note that this payment amount is higher than the maximum payment amount allowed for Credit or Debit card transactions',
        defaultMessage: 'Please note that this payment amount is higher than the maximum payment amount allowed for Credit or Debit card transactions. If you plan to pay with a Credit Card or Debit card, please adjust your payment amount to an amount between $1.00 and $1500.00, and make your payment over multiple transactions.'
    },
    amountMore: {
        id: 'payment.views.make-a-payment-modal.Please enter a payment amount of $1.00 or more.',
        defaultMessage: 'Please enter a payment amount of $1.00 or more.'
    },
    reinstateCancelledPolicy: {
        id: 'payment.views.make-a-payment-modal.Please note that making a payment will not automatically reinstate your cancelled policy.',
        defaultMessage: 'Please note that making a payment will not automatically reinstate your cancelled policy. If you have any questions about reinstating a cancelled policy, please call us at 888-363-7984 Monday to Friday 7:30 am to 7:30 pm or Saturdays 8:00 am to 4:30 pm.'
    },
    weAreProcessingTheInformationSubmitted: {
        id: 'payment.views.make-a-payment-modal.We are processing the information submitted.',
        defaultMessage: 'We are processing the information submitted.'
    },
    doNotNavigateAwayFromThisPageOrCloseYourBrowser: {
        id: 'payment.views.make-a-payment-modal.Do not navigate away from this page or close your browser.',
        defaultMessage: 'Do not navigate away from this page or close your browser.'
    },
    paymentUnsuccessful: {
        id: 'payment.views.make-a-payment-modal.Payment unsuccessful',
        defaultMessage: 'Payment unsuccessful'
    },
    thePaymentSystemIsCurrentlyUnavailablePleaseChooseAnotherPaymentMethodOrTryAgainLater: {
        id: 'payment.views.make-a-payment-modal.The payment system is currently unavailable. Please choose another payment method, or try again later.',
        defaultMessage: 'The payment system is currently unavailable. Please choose another payment method, or try again later.'
    },
    invalidRoutingTransitNumber: {
        id: 'payment.views.make-a-payment-modal.Invalid routing transit number',
        defaultMessage: 'Invalid routing transit number'
    },
    theRoutingTransitNumberEnteredIsNotValid: {
        id: 'payment.views.make-a-payment-modal.The Routing Transit Number entered is not valid. Please re-enter the transit number to try your payment again or select another payment method.',
        defaultMessage: 'The Routing Transit Number entered is not valid. Please re-enter the transit number to try your payment again or select another payment method.'
    },
    paymentSuccessful: {
        id: 'payment.views.make-a-payment-modal.Payment successful',
        defaultMessage: 'Payment successful'
    },
    thankYouForYourPaymentYouWillReceiveYourReceiptViaEmailShortly: {
        id: 'payment.views.make-a-payment-modal.Thank you for your payment. You will receive your receipt via email shortly.',
        defaultMessage: 'Thank you for your payment. You will receive your receipt via email shortly. '
    },
    pleaseAllowUpTo24HoursForYourPaymentToAppearOnYourBankStatement: {
        id: 'payment.views.make-a-payment-modal.Please allow up to 24 hours for your payment to appear on your bank statement.',
        defaultMessage: 'Please allow up to 24 hours for your payment to appear on your bank statement.'
    },
    bankingInformationSaved: {
        id: 'payment.views.make-a-payment-modal.Your banking information has also been saved successfully.',
        defaultMessage: 'Your banking information has also been saved successfully.'
    },
    bankUnableToBeSaved: {
        id: 'payment.views.make-a-payment-modal.While your payment was successful, your bank account was unable to be saved for future use.',
        defaultMessage: 'While your payment was successful, your bank account was unable to be saved for future use.'
    },
    contactUsForAssistance: {
        id: 'payment.views.make-a-payment-modal.Contact us for assistance',
        defaultMessage: 'Contact us for assistance at 888-363-7984 Monday - Friday 7:30 am - 7:30 pm and Saturday 8:00 am - 4:30 pm.'
    },
    paymentInProgress: {
        id: 'payment.views.make-a-payment-modal.Payment in Progress',
        defaultMessage: 'Payment in Progress'
    },
    weHaveNotYetReceivedSuccessfulNotificationOfYourPaymentFromTheBank: {
        id: 'payment.views.make-a-payment-modal.We have not yet received successful notification of your payment from the bank. If you have received a payment receipt by email, your payment will be applied to your policy over the next 24 hours.',
        defaultMessage: 'We have not yet received successful notification of your payment from the bank. If you have received a payment receipt by email, your payment will be applied to your policy over the next 24 hours.'
    },
    paymentSuccessfullyScheduled: {
        id: 'payment.views.make-a-payment-modal.Payment Successfully Scheduled',
        defaultMessage: 'Payment Successfully Scheduled'
    },
    thankYouForSchedulingYourPaymentItWillBeWithdrawnFromYourBankAccountOn: {
        id: 'payment.views.make-a-payment-modal.Thank you for scheduling your payment. It will be withdrawn from your bank account on &lt;strong&gt;{paymentDate}&lt;/strong&gt;. You’ll receive a receipt via email once this payment has been processed.',
        defaultMessage: 'Thank you for scheduling your payment. It will be withdrawn from your bank account on &lt;strong&gt;{paymentDate}&lt;/strong&gt;. You’ll receive a receipt via email once this payment has been processed.'
    },
    onceYouveReceivedThisReceiptPleaseAllowUpTo24Hours: {
        id: 'payment.views.make-a-payment-modal.Once you’ve received this receipt, please allow up to 24 hours for the payment to appear on your bank statement.',
        defaultMessage: 'Once you’ve received this receipt, please allow up to 24 hours for the payment to appear on your bank statement.'
    },
    saveBankAccountForFuturePayments: {
        id: 'payment.views.make-a-payment-modal.I want to save this bank account for future payments.',
        defaultMessage: 'I want to save this bank account for future payments.'
    },
    agreeSaveBankAccount: {
        id: 'payment.views.make-a-payment-modal.I AGREE',
        defaultMessage: 'I AGREE'
    },    
    bankInfoStorageConsentTitle: {
        id: 'payment.views.make-a-payment-modal.Bank Info Storage Consent Title',
        defaultMessage: 'Authorization'
    },
    bankInfoStorageText: {
        id: 'payment.views.make-a-payment-modal.Bank Info Storage Text',
        defaultMessage: 'I authorize Wawanesa General Insurance Company ("Wawawnesa") to debit the bank account indicated in this Authorization Form on the payment date and amount listed above. I understand that because this is an electronic transaction, funds may be withdrawn from my account as soon as the payment date listed above. If an ACH transaction is rejected for nonsufficient funds (NSF) I understand that I will be charged an NSF fee. I acknowledge that the origination of ACH transactions to my account must comply with the provisions of U.S. law. I represent that I am an authorized user of this account and will not dispute this one-time electronic fund transfer from my account, so long as the transaction corresponds to the terms indicated in this Authorization Form.'
    },
    bankInfoStorageTextPart2: {
        id: 'payment.views.make-a-payment-modal.Bank Info Storage Text Part 2',
        defaultMessage: 'I also authorize Wawanesa to store the bank account information indicated on the Authorization Form for the purpose of making future one-time payments to Wawanesa. This Authorization will remain in effect until Wawanesa has received written notice from you of its termination'
    },
    bankInfoStorageAgreement: {
        id: 'payment.views.make-a-payment-modal.I agree  to the Bank Info Storage above',
        defaultMessage: 'I agree  to the Bank Info Storage above'
    },
    selectABankAccount: {
        id: 'payment.views.make-a-payment-modal.Select One',
        defaultMessage: 'Select One'
    },
    payWithAnotherBankAccount: {
        id: 'payment.views.make-a-payment-modal.Pay with another bank account',
        defaultMessage: 'Pay with another bank account'
    },
    manageBankAccounts: {
        id: 'payment.views.make-a-payment-modal.Manage bank accounts',
        defaultMessage: 'Manage bank accounts'
    },
    bankOptionName: {
        id: 'payment.views.make-a-payment-modal.BankOptionName',
        defaultMessage: '{accountType} Account {accountNumber}'
    },
    paymentSuccessfulCamelCase: {
        id: 'payment.views.make-a-payment-modal.Payment Successful',
        defaultMessage: 'Payment Successful'
    },
    paymentUnsuccessfulCamelCase: {
        id: 'payment.views.make-a-payment-modal.Payment Unsuccessful',
        defaultMessage: 'Payment Unsuccessful'
    },
    processingPayment: {
        id: 'payment.views.make-a-payment-modal.Processing payment',
        defaultMessage: 'Processing payment'
    },
    pleaseAllowUpTo5BusinessDaysForYourPaymentToAppearOnYourCreditCardStatement: {
        id: 'payment.views.make-a-payment-modal.Please allow up to 5 business days for your payment to appear on your credit card statement.',
        defaultMessage: 'Please allow up to 5 business days for your payment to appear on your credit card statement.'
    },
    thePaymentSystemIsCurrentlyUnavailablePleaseChooseAnotherPaymentMethodOrTryAgainLaterIfYouHaveAnyQuestions: {
        id: 'payment.views.make-a-payment-modal.The payment system is currently unavailable. Please choose another payment method, or try again later. If you have any questions about this error, please call us at 800-640-2920.',
        defaultMessage: 'The payment system is currently unavailable. Please choose another payment method, or try again later. If you have any questions about this error, please call us at 800-640-2920.'
    },
    weHaveNotYetReceivedSuccessfulNotificationOfYourPaymentFromTheCreditCardCompanyIfYouHaveReceived: {
        id: 'payment.views.make-a-payment-modal.We have not yet received successful notification of your payment from the credit card company. If you have received a payment receipt, your payment will be applied to your policy over the next 24 hours.',
        defaultMessage: 'We have not yet received successful notification of your payment from the credit card company. If you have received a payment receipt, your payment will be applied to your policy over the next 24 hours.'
    },
    inTheEventThatYourPaymentIsNotAppliedToYourPolicyWithin24Hours: {
        id: 'payment.views.make-a-payment-modal.In the event that your payment is not applied to your policy within 24 hours, please call us at 800-640-2920 with your payment confirmation details.',
        defaultMessage: 'In the event that your payment is not applied to your policy within 24 hours, please call us at 800-640-2920 with your payment confirmation details.'
    },
    pleaseDoNotNavigateAwayFromThisPageOrCloseYourBrowser: {
        id: 'payment.views.make-a-payment-modal.Please do not navigate away from this page or close your browser.',
        defaultMessage: 'Please do not navigate away from this page or close your browser.'
    },
    paymentSystemUnavailable: {
        id: 'payment.views.make-a-payment-modal.Payment system unavailable',
        defaultMessage: 'Payment system unavailable'
    },
    paymentSystemCurrentlyUnavailable: {
        id: 'payment.views.make-a-payment-modal.The payment system is currently unavailable.  Please try again...',
        defaultMessage: 'The payment system is currently unavailable.  Please try again later or call us for assistance at &lt;a href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday to Friday 7:30 am to 7:30 pm PT or Saturday 8:00 am to 4:30 pm PT.'
    },
    contactYourMortgageCompany: {
        id: 'payment.views.make-a-payment-modal.Before making a payment, we recommend contacting your mortgage company',
        defaultMessage: 'Before making a payment, we recommend contacting your mortgage company. Your mortgage company has already been billed, but a payment has not been received. If you\'d still like to make a payment, please allow up to 5 business days for your account to be updated.'
    },
    contactYourMortgageCompanyAria: {
        id: 'payment.views.make-a-payment-modal.Before making a payment, we recommend contacting your mortgage company Aria',
        defaultMessage: 'Before making a payment on {policyType} policy #{policyNumber}, we recommend contacting your mortgage company. Your mortgage company has already been billed, but a payment has not been received. If you\'d still like to make a payment, please allow up to 5 business days for your account to be updated.'
    },
    noAutomaticReinstate: {
        id: 'payment.views.make-a-payment-modal.Please note that making a payment will not automatically reinstate your cancelled policy',
        defaultMessage: 'Please note that making a payment will not automatically reinstate your cancelled policy. If you have any questions about reinstating a cancelled policy, please call us at &lt;a href=\"tel:800-640-2920\"&gt;800-640-2920&lt;/a&gt; Monday to Friday 7:30 am to 7:30 pm PT or Saturday 8:00 am to 4:30 pm PT.'
    },
    noAutomaticReinstateAria: {
        id: 'payment.views.make-a-payment-modal.Please note that making a payment will not automatically reinstate your cancelled policy Aria',
        defaultMessage: 'Please note that making a payment on {policyType} policy #{policyNumber} will not automatically reinstate your cancelled policy. If you have any questions about reinstating a cancelled policy, please call us at &lt;a href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday to Friday 7:30 am to 7:30 pm PT or Saturday 8:00 am to 4:30 pm PT.'
    },
    remainInDelinquentStatus: {
        id: 'payment.views.make-a-payment-modal.Please note that your policy will remain in delinquent status until the overdue amount of (overdueAmount)...',
        defaultMessage: 'Please note that your policy will remain in delinquent status until the overdue amount of {overdueAmount} has been paid in full.'
    },
    remainInDelinquentStatusAria: {
        id: 'payment.views.make-a-payment-modal.Please note that your policy will remain in delinquent status until the overdue amount of (overdueAmount)... Aria',
        defaultMessage: 'Please note that your policy will remain in delinquent status until the overdue amount of {overdueAmount} has been paid in full for {policyType} policy #{policyNumber}.'
    },
    alertIconAria: {
        id: 'payment.views.make-a-payment-modal.alertIconAria',
        defaultMessage: 'alert icon'
    }
});
