import { defineMessages } from 'react-intl';

export default defineMessages({
    yourAutoInsuranceIncreased: {
        id: 'portal.views.rcn.yourAutoInsuranceIncreased',
        defaultMessage: 'Your auto insurance premium has increased'
    },
    policy: {
        id: 'portal.views.rcn.policy',
        defaultMessage: 'Policy #{policyNumber}'
    },
    reviewRenewal: {
        id: 'portal.views.rcn.reviewRenewal',
        defaultMessage: 'Please review important information about your upcoming policy renewal #{policyNumber}'
    },
    renewalMessage: {
        id: 'portal.views.rcn.renewalMessage',
        defaultMessage: 'Thank you for being a valued Wawanesa member. The premium on your renewal offer has increased, and we\'d like to offer more information about why costs are significantly increasing throughout the auto insurance industry. Please review the Price Increase Notice and your Offer to Renew for more information on these changes. At Wawanesa, we truly appreciate your decision to entrust us with your insurance needs and are committed to providing you with the protection and service you\'ve come to expect at a competitive price.'
    },
    priceIncreaseNotice: {
        id: 'portal.views.rcn.priceIncreaseNotice',
        defaultMessage: 'Price Increase Notice (PDF)'
    },
    offerToRenew: {
        id: 'portal.views.rcn.offerToRenew',
        defaultMessage: 'Offer to Renew (PDF)'
    },
    dontShowAgain: {
        id: 'portal.views.rcn.dontShowAgain',
        defaultMessage: 'Don\'t show again'
    },
    close: {
        id: 'portal.views.rcn.close',
        defaultMessage: 'Close'
    }
});