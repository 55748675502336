import { defineMessages } from 'react-intl';

export default defineMessages({
    changeRoadsideAssistanceTitle: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.Change Roadside Assistance',
        defaultMessage: 'Change Roadside Assistance'
    },
    includeRoadsideAssistance: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.Include Roadside Assistance for the following vehicles',
        defaultMessage: 'Include Roadside Assistance for the following vehicles?'
    },
    addRoadsideAssistanceTooltipMessageFirst: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.addRoadsideAssistanceTooltipMessageFirst',
        defaultMessage: 'This coverage pays up to $100 for each disablement of your vehicle when using our Roadside Assistance Sign and Drive Option. Maximum reimbursement using any other licensed service provider is $50 per disablement. Lockout Service is limited to $100.',
    },
    addRoadsideAssistanceTooltipMessageSecond: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.addRoadsideAssistanceTooltipMessageSecond',
        defaultMessage: 'Roadside Assistance is not available for Travel Trailer/Recreational Trailers or Motorhomes or for vehicles older than 14 years. There is a limit of one (1) reimbursement per disablement and a total of two (2) incidents per covered vehicle per policy period.'
    },
    addRoadsideAssistanceTooltipMessageAfterRPC1578: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.addRoadsideAssistanceTooltipMessageAfterRPC1578',
        defaultMessage: 'This coverage pays up to $200 for each disablement of your vehicle when using our Roadside Assistance Sign and Drive Option. Maximum reimbursement using any other licensed service provider is $100 per disablement. Lockout Service is limited to $100.',
    },
    cancel: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.cancel',
        defaultMessage: 'Cancel'
    },
    continue: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.continue',
        defaultMessage: 'Continue'
    },
    confirmPolicyChangesModalTitle: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.confirmPolicyChangesModalTitle',
        defaultMessage: 'Modify roadside assistance'
    },
    confirmPolicyChangesModalBodyTitle: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.confirmPolicyChangesModalBodyTitle',
        defaultMessage: 'Please confirm the changes to your roadside assistance coverage:'
    },
    confirmPolicyChangesModalButtonOk: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.confirmPolicyChangesModalButtonOk',
        defaultMessage: 'Yes, confirm'
    },
    roadsideAssistanceConfirmTrackingText: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.tracking.confirmPolicyChangesModalButtonOk',
        defaultMessage: 'Policy Change - {changeType} - Roadside Assistance'
    },
    policychangeButtonCancel: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.policychangeButtonCancel',
        defaultMessage: 'Cancel'
    },
    submitTrackingTextRoadsideAssistance: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.submitTrackingTextRoadsideAssistance',
        defaultMessage: 'Policy Change - Submit - Roadside Assistance'
    },
    noVehicleEntitled: {
        id: 'wmic.policy-change.component.changeRoadsideAssistance.noVehicleEntitled',
        defaultMessage: 'No vehicle is entitled to roadside assistance service.'
    },
    // aaa: {
    //     id: 'wmic.policy-change.component.changeRoadsideAssistance.',
    //     defaultMessage: ''
    // },
});
