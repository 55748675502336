// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICFormButtons__wmicCard{border-radius:var(--wmic-border-radius-card)}.app__WMICFormButtons__wmicCardRaised{border:none;box-shadow:var(--GW-SHADOW-2)}.app__WMICFormButtons__editFormButtonsRow{display:flex;flex-direction:row;justify-content:flex-start;padding-top:1rem;width:100%}.app__WMICFormButtons__editFormButtonsRow .app__WMICFormButtons__editFormButtonsContainer{display:flex;flex-direction:row;justify-content:flex-end;width:100%}.app__WMICFormButtons__editFormButtonsRow .app__WMICFormButtons__editFormButtonsContainer button:first-child{margin-right:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICFormButtons/WMICFormButtons.module.scss"],"names":[],"mappings":"AAAA,gCAGE,4CAAA,CAGF,sCACE,WAAA,CACA,6BAAA,CAGF,0CAEE,YAAA,CACA,kBAAA,CACA,0BAAA,CAHA,gBAAA,CAIA,UAAA,CAEA,0FACE,YAAA,CACA,kBAAA,CAEA,wBAAA,CADA,UACA,CAEA,6GACE,gCAAA","sourcesContent":[".wmicCard {\n  /* Do not over-ride --GW-BORDER-RADIUS here*/\n  /* as all card children will inherit*/\n  border-radius: var(--wmic-border-radius-card);\n}\n\n.wmicCardRaised {\n  border: none;\n  box-shadow: var(--GW-SHADOW-2);\n}\n\n.editFormButtonsRow {\n  padding-top: 1rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  width: 100%;\n\n  .editFormButtonsContainer {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: flex-end;\n\n    button:first-child {\n      margin-right: var(--GW-SPACING-3);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicCard": "app__WMICFormButtons__wmicCard",
	"wmicCardRaised": "app__WMICFormButtons__wmicCardRaised",
	"editFormButtonsRow": "app__WMICFormButtons__editFormButtonsRow",
	"editFormButtonsContainer": "app__WMICFormButtons__editFormButtonsContainer"
};
export default ___CSS_LOADER_EXPORT___;
