/* eslint-disable max-len */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ModalNext, ModalFooter } from '@jutro/components';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICButton, WMICAnimatedLoaderButton } from 'wmic-components-platform-react';
import classNames from 'classnames';
import userProfileMessages from '../WMICUserProfile.messages';
import messages from './WMICEditContactModal.messages';
import styles from './WMICEditContactModal.module.scss';

import { RadioButtonField } from '@jutro/legacy/components';

const WMICEditContactModal = (props) => {
    const {
        isOpen, onReject, onResolve, email, refresh, updateRefresh, distributionType, updateDistributionType
    } = props;

    const { authHeader, userInfo: authUserData } = useAuthentication();
    const { EnrollmentService } = useDependencies('EnrollmentService');
    const translator = useTranslator();
    const [selectedType, updateType] = useState(distributionType);
    const [isConfirmAnimated, setConfirmAnimated] = useState(false);

    const onSubmit = useCallback(async () => {
        setConfirmAnimated(true);
        const payload = {
            applicationFlow: 'userProfile',
            consentType: 'distributionMethod',
            distributionType: selectedType,
            systemOrigin: 'amp'
        };
        const updateContactApi = async () => {
            await EnrollmentService
                .updateDistributionTypeWithConsent_WMIC(payload, authHeader, authUserData.firstName, authUserData.lastName);
            onResolve();
            updateDistributionType(selectedType);
            updateRefresh(!refresh);
        };
        await updateContactApi();
        setConfirmAnimated(false);
    }, [selectedType, EnrollmentService, authHeader, onResolve, updateDistributionType, updateRefresh, refresh]);

    const getOption = (translatedText) => {
        return <div className={styles.radioOption}>{WMICRichTextUtil.translateRichText(translatedText)}</div>
    }

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc           
            className={styles.editContactModal}
            contentLayout={{
                component: 'Grid',
                componentProps: {
                    rows: ['auto'],
                },
            }}
        >
            <div className={styles.modal}>
                <h1 className={styles.modalHeader}>{translator(userProfileMessages.preferredContact)}</h1>
                <div className="ww-email-consent">
                    <p className={styles["wmic-sm-footing"]}>
                        {`${translator(messages.withYourConsent)} ${translator(messages.oregonPolicyholders)}`}
                    </p>
                    <p className={styles["wmic-sm-footing"]}>{translator(messages.electronicallyDelivered)}</p>
                    <p className={styles["wmic-sm-footing"]}>{WMICRichTextUtil.translateRichText(translator(messages.emailUs))}</p>
                    <ul className={classNames("ww-base-ul", styles.list)}>
                        <li>{translator(messages.optOut)}</li>
                        <li>{translator(messages.updateEmail)}</li>
                        <li>{translator(messages.requestFree)}</li>
                    </ul>
                    <h2 className={styles.consentSubhead}>{translator(messages.communicationEmail)}</h2>
                    <div>
                        <RadioButtonField
                            availableValues={[
                                {
                                    code: 'email',
                                    name: getOption(translator(messages.receiveDocs, { email }))
                                },
                                {
                                    code: 'mail',
                                    name: getOption(translator(messages.donotWantreceiveDocs))
                                }
                            ]}
                            className="selectType"
                            id="selectType"
                            hideLabel
                            value={selectedType}
                            onValueChange={(value) => updateType(value)}
                        />
                    </div>
                </div>
                <ModalFooter>
                    <WMICButton
                        onClick={() => { onReject(); }}
                        type="outlined"
                    >
                        {translator(messages.cancel)}
                    </WMICButton>
                    <WMICAnimatedLoaderButton
                        onClick={() => onSubmit()}
                        type="primary"
                        disabled={distributionType === selectedType}
                        isAnimated={isConfirmAnimated}
                    >
                        {translator(messages.submit)}
                    </WMICAnimatedLoaderButton>
                </ModalFooter>
            </div>
        </ModalNext>
    );
};

WMICEditContactModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    refresh: PropTypes.bool.isRequired,
    updateRefresh: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    distributionType: PropTypes.string.isRequired,
    updateDistributionType: PropTypes.func.isRequired
};

export default WMICEditContactModal;