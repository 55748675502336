import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InlineLoader, Tooltip,Loader } from '@jutro/components';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { pageTemplateProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';

import styles from './WMICPolicyWizardPageTemplate.module.scss';
import WMICPolicyWizardSidebar from '../components/WMICPolicyWizardSidebar';

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function WMICPolicyWizardPageTemplate(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);

    const isTablet = ['phone', 'phoneWide', 'tablet'].includes(breakpoint)
    const [showSidebar, setShowSidebar] = useState(false);
    const [wizardIsLoading, setWizardIsLoadingClass] = useState(true);
    const { isSkipping } = useContext(WizardContext);

    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        customMessageModal
    } = props;

    const { policyVM } = wizardData;

    useEffect(() => {
        switch (breakpoint) {
            case 'phone':
            case 'phoneWide':
            case 'tablet' :
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const contextComponent = useCallback(() =>
        <div className={styles.wizardContext}>
            {renderContextComponent(wizardSnapshot)}
        </div>
    , [renderContextComponent, wizardSnapshot]);

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
    ), [showCancel, showNext, showPrevious]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar && isTablet
    });

    const nextButton = useMemo(() => (
        <WMICButton
            id="gw-wizard-Next"
            type="primary"
            className={styles.nextbutton}
            disabled={disableNext || isPerformingTransiton}
            onClick={onNext}
        >
            {translator(nextLabel)}
            {renderLoader(isLoadingNext)}
        </WMICButton>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, onNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isTablet
    });

    const getWizardIsLoadingClass = wizardIsLoading ? 'wizardIsLoading' : '';
    useEffect(() => { setWizardIsLoadingClass(false); }, []);

    return (<div className={classNames(styles.digitalWizard, getWizardIsLoadingClass)}>
            {isSkipping ? <Loader />
                : <>
                    {showContextComponent && contextComponent()}
                    <div className={wizardClassName}>
                        {!isTablet || showSidebar ? (
                            <div className={classNames(styles.wizardNavigation, 'hiddenPrintElement')}>
                                <WMICPolicyWizardSidebar customMessageModal={customMessageModal} />
                                <div className={styles.wizardWidgets}>
                                    {renderWidgets(policyVM)}
                                </div>
                            </div>
                        ) : null}
                        <div className={styles.wizardContent}>
                            {isTablet && <WMICPolicyWizardSidebar />}
                            {showWizardHeader && contextComponent()}
                            {content}
                            <div className={classNames({ [styles.navButtons]: shouldShowButtons }, 'hiddenPrintElement')}>
                                {showCancel && (
                                    <WMICButton
                                        id="gw-wizard-cancel"
                                        disabled={disableCancel || isPerformingTransiton}
                                        type="secondary-outlined"
                                        onClick={onCancel}
                                    >
                                        {translator(cancelLabel)}
                                        {renderLoader(isLoadingCancel)}
                                    </WMICButton>
                                )}
                                <div className={buttonContainerClassName}>
                                    {showPrevious && (
                                        <WMICButton
                                            id="gw-wizard-previous"
                                            disabled={disablePrevious || isPerformingTransiton}
                                            type="primary-outlined"
                                            onClick={onPrevious}
                                        >
                                            {translator(previousLabel)}
                                            {renderLoader(isLoadingPrevious)}
                                        </WMICButton>
                                    )}
                                    {showNext && renderNextButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

WMICPolicyWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts policyVM to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts policyVM to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string
};

WMICPolicyWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    nextButtonTooltip: ''
};

export default WMICPolicyWizardPageTemplate;
