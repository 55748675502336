import React, { useContext } from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { getConfigValue } from '@jutro/config';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DateUtil } from 'wmic-portals-utils-js';

import metadata from './WMICLocationAndIncidentInfoBox.metadata.json5';
import styles from './WMICLocationAndIncidentInfoBox.module.scss';
import messages from './WMICLocationAndIncidentInfoBox.messages';

const WMICLocationAndIncidentInfoBox = (props) => {
    const { claimData: claimInfo } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const reportedDate = intl.formatDate(new Date(claimInfo?.claimReporter.reportedDate_WMIC), { year: 'numeric', month: '2-digit', day: '2-digit' });
    const incidentDate = intl.formatDate(new Date(DateUtil.localDateDTOToDate(claimInfo?.localLossDate_WMIC)), { year: 'numeric', month: '2-digit', day: '2-digit' });
    
    const overrides = {
        reportedByValueDiv: {
            content: claimInfo?.claimReporter?.reportedBy?.displayName
        },
        reportedDateValueDiv: {
            content: reportedDate
        },
        dateOfIncidentValueDiv: {
            content: incidentDate
        },
        locationValueDiv: {
            content: claimInfo?.lossLocation.displayName
        },
        mapContent: {
            visible: !!claimInfo?.lossLocation
        },
        mapArea: {
            onGoogleMapsApiKey: () => getConfigValue('GOOGLE_MAPS_API_KEY', appConfig.credentials.googleMapsApiKey),
            value: claimInfo?.lossLocation.displayName
        },
        locationAndIncidentInformationAria: {
            content: translator(
                messages.LocationAndIncidentInformationAria, 
                { 
                    name: claimInfo?.claimReporter?.reportedBy?.displayName, 
                    reportedDate,
                    incidentDate,
                    location: claimInfo?.lossLocation.displayName
                })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrides}
            model={claimInfo}
        />
    );
};

WMICLocationAndIncidentInfoBox.propTypes = {
    claimData: PropTypes.shape({})
};

WMICLocationAndIncidentInfoBox.defaultProps = {
    claimData: {}
};

export default WMICLocationAndIncidentInfoBox;
