import React from 'react';
import _ from 'lodash';
import { QuoteClauseTable, WMICHeading, ClausesComponentVM } from 'wmic-pe-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Accordion } from '@jutro/legacy/components';
import { Position } from "wmic-pe-portals-utils-js";

import WMICGLCoverageUtil from "wmic-pe-capability-gateway-quoteandbind-gl-react/util/WMICGLCoverageUtil";
import metadata from './WMICGLLiabilityCoverages.metadata.json5';

function WMICGLLiabilityCoverages(props) {
    const {
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        jobVM,
        onValidate,
        isBoundPolicy,
        readOnly
    } = props;

    const lobDataPath = isBoundPolicy ? 'lobs' : 'lobData';
    const coveragePath = `${lobDataPath}.generalLiability`;

    const lineCoverages = WMICGLCoverageUtil.getLiabilityLineCoverages(
        _.get(jobVM, coveragePath)
    );

    const generateClauseData = (coverageColumnData) => {
        return coverageColumnData.map(({ lob, code }) => {
            return {
                code: code,
                path: `${lob.path}.coverages.commercialGeneralLiabilityCoverages`,
                clauses: lineCoverages
            };
        });
    }

    const getLineCoverages = () => {
        // only show these categories
        const categoryMap = ['commercialgeneralliability', 'ripandtear', 'veterinarians' ];

        const categorizedCoverages = WMICGLCoverageUtil.categorizeLiabilityLineCoverages(lineCoverages);
        const coverageAccordions = categoryMap.map((categoryKey) => {
            const lineCoverage = _.get(categorizedCoverages, categoryKey);

            if(lineCoverage.coverages.length === 0) {
                return (<></>)
            }

            if (!isBoundPolicy) {
                const coverageColumnData = WMICGLCoverageUtil.generateColumnData(jobVM);
                const coverageTableData = [{
                    data: lineCoverage.coverages,
                    tableContent: generateClauseData(coverageColumnData)
                }]

                return (<>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={lineCoverage.categoryName} />
                    <QuoteClauseTable
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={readOnly}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        showTooltip={false}
                        onValidate={onValidate}
                    />
                </>);
            }

            return (
                <>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={lineCoverage.categoryName}
                    />
                    <ClausesComponentVM
                        showAmount={false}
                        isEditable
                        readOnly
                        path={`${categoryKey}.coverages`}
                        value={lineCoverage.coverages}
                    />
                </>
            );
        });

        return coverageAccordions;
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: jobVM
        },
        liabilityCoveragesAccordions: {
            content: getLineCoverages()
        }
    }

    const resolvers = {
        resolveComponentMap: {
            Accordion
        }
    }

    return (
        <div id="liabilityContainer">
            <ViewModelForm
                model={jobVM}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

export default WMICGLLiabilityCoverages;
