/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { WMICBulletPointComponent } from 'gw-capability-policy-common-react';
import { CONSTANTS } from 'wmic-portals-utils-js';
import QuestionSetBulletPointUtil from './WMICQuestionSetBulletPointUtil';
import QuestionSetsViewModel from './WMICQuestionSetsViewModel';

/**
 * Retrieves questionSet from QuestionSets metadata
 * @param {string} qsName
 * @param {object} metadata
 * @throws Will throw an error if questionSet does not exist in QuestionSets metadata
 * @returns {object} - The single question from questionSets metadata
 */
function getQuestionSet(qsName, metadata) {
    const questionSet = metadata[qsName];
    if (!questionSet || !questionSet.orderedQuestions) {
        throw new Error(`There is no question set loaded with the name ${qsName}`);
    }
    return questionSet;
}

function shouldBeOmitted(questionsToOmit, code) {
    return questionsToOmit && questionsToOmit.includes(code);
};

export default class QuestionSetsParser {
    /**
     * @throws Will throw an error if questionSetsSubmissionValue is not provided
     * @param {object} questionSetsSubmissionValue
     * @param {object} metadata
     * @param {function} translator
     * @param {boolean} usesRadioButtons true if yes/no questions use radio buttons instead of toggle
     * @param {object} questionsToOmit contains an array of question code to filter questions
     */
    constructor(questionSetsSubmissionValue, metadata, translator, usesRadioButtons, questionsToOmit) {
        // eslint-disable-next-line no-underscore-dangle
        this._viewModel = {};
        this.translator = translator;
        this.usesRadioButtons = false;

        if (usesRadioButtons === true) {
            this.usesRadioButtons = true;
        }

        if (
            !_.isObject(questionSetsSubmissionValue)
            && !_.isArray(questionSetsSubmissionValue)
        ) {
            throw new Error('A model object is required for storing answers');
        }

        if (_.isArray(questionSetsSubmissionValue)) {
            questionSetsSubmissionValue.forEach((submissionValue) => {
                // eslint-disable-next-line no-underscore-dangle
                this._createViewModel(submissionValue, metadata, questionsToOmit);
            });
        } else {
            // eslint-disable-next-line no-underscore-dangle
            this._createViewModel(questionSetsSubmissionValue, metadata, questionsToOmit);
        }
    }

    /**
     * Creates viewModel based on questionSet
     * @param {object} submissionValue - The submission value object
     * @param {string} submissionValue.code - QuestionSet code
     * @param {array} submissionValue.answers - QuestionSet available answers
     * @param {object} metadata
     * @returns {object} - viewModel
     */
    // eslint-disable-next-line consistent-return, no-underscore-dangle
    _createViewModel(submissionValue, metadata, questionsToOmit) {
        // if view model already created then just return that object
        if (submissionValue.aspects
            && submissionValue.questions
            && _.isArray(submissionValue.questions)) {
            return submissionValue;
        }

        const questionSet = getQuestionSet(submissionValue.code, metadata);

        const allQuestions = questionSet.orderedQuestions.sort((a, b) => a.order - b.order);
        const availableQuestions = _.filter(allQuestions, (question) => {
            return submissionValue.answers[question.code] !== undefined && !shouldBeOmitted(questionsToOmit, question.code);
        });
        availableQuestions.forEach((question) => {
            const answer = submissionValue.answers[question.code];
            if (typeof answer === 'boolean') {
                // eslint-disable-next-line no-param-reassign
                submissionValue.answers[question.code] = answer ? 'true' : 'false';
            }
            if (question.questionType === CONSTANTS.QUESTION_TYPES.INTEGER && answer === null) {
                // eslint-disable-next-line no-param-reassign
                submissionValue.answers[question.code] = '';
            }
            // eslint-disable-next-line no-underscore-dangle
            this._viewModel[question.code] = new QuestionSetsViewModel(
                question, submissionValue, this.translator
            );
            const values = question.choices.map((choice) => {
                return {
                    code: choice.choiceCode,
                    name: this.translator({
                        id: choice.displayKey,
                        defaultMessage: choice.choiceCode
                    })
                };
            });
            // eslint-disable-next-line no-underscore-dangle
            _.extend(this._viewModel[question.code].aspects.availableValues, values);
        });
        // eslint-disable-next-line no-underscore-dangle
        this._viewModel = _.merge({}, this._viewModel, submissionValue);
    }

    /**
     * Returns the viewModel created via parser
     * @returns {ViewModel} - The viewModel type based on questionSets
     */
    get viewModel() {
        // eslint-disable-next-line no-underscore-dangle
        return this._viewModel;
    }

    /**
     * Returns presentation metadata based on viewModel and questionSets properties
     * @returns {object} - Presentation metadata used to render questionSets fields
     */
    get presentationMetadata() {
        const pageContent = [];
        // eslint-disable-next-line no-underscore-dangle
        _.forOwn(this._viewModel, (question, key) => {
            // eslint-disable-next-line no-underscore-dangle
            if (QuestionSetsParser.filterQuestionSets(key) && this._viewModel[key]) {
                // eslint-disable-next-line no-underscore-dangle
                const field = this._viewModel[key];
                let metadataContent = {};

                if (this.usesRadioButtons && question.question.questionType === CONSTANTS.QUESTION_TYPES.BOOLEAN) {
                    metadataContent = {
                        id: key,
                        type: 'field',
                        component: 'wmicCustomRadioButton',
                        componentProps: {
                            label: { id: field.label, defaultMessage: field.label },
                            className: 'control-group wmicWizardInput wmicDisplayBlock',
                            path: key
                        }
                    };
                } else {
                    metadataContent = {
                        id: key,
                        type: 'field',
                        componentProps: {
                            label: { id: field.label, defaultMessage: field.label },
                            className: 'wmic-prequal-field',
                            layout: 'reversed',
                            path: key,
                            showRequired: true
                        }
                    };
                }

                if (field.aspects.inputCtrlType === 'typelist') {
                    metadataContent.componentProps.placeholder = {
                        id: 'platform.questionsets.question-set.-- Choose --',
                        defaultMessage: '-- Choose --'
                    };
                }

                if (QuestionSetBulletPointUtil.isQuestionWithBulletPoints(key)) {
                    const { translator } = this;
                    const bulletPoints = QuestionSetBulletPointUtil.getBulletPoints(key, translator);
                    const bulletContent = <WMICBulletPointComponent bulletPoints={bulletPoints} />;
                    pageContent.push(metadataContent, bulletContent);
                } else {
                    pageContent.push(metadataContent);
                }
            }
        });
        return {
            content: [
                {
                    id: 'questionSetGridId',
                    type: 'container',
                    component: 'div',
                    contentLayout: { component: 'grid' },
                    content: pageContent
                }
            ]
        };
    }

    /**
     * Search for questions only, ignoring answers and code
     * @param {string} key
     * @returns {boolean}
     */
    static filterQuestionSets(key) {
        return key !== 'answers' && key !== 'code';
    }
}
