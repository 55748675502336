import React, { useContext, useCallback, useState, useEffect } from 'react';
import { set, get, isEmpty, isNumber } from 'lodash';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { Flex } from '@jutro/layout';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { messages } from './WMICFilterBar.messages';
import internalStyles from './WMICFilterBar.module.scss';

const WMICFilterBarContentInternal = ({
    styles,
    metadata,
    onFiltersChange,
    componentMap,
    callbackMap,
    isFiltersEmpty,
    initialFilters = {},
}) => {
    const translator = useContext(TranslatorContext);
    const [formData, setFormData] = useState(initialFilters);
    const isFormEmpty = Object.values(formData).every(
        (value) => !isNumber(value) && isEmpty(value)
    );
    const readValue = (id, path) => get(formData, path);
    const overrideProps = {
        '@field': {
            className: internalStyles.filterItem,
        },
    };

    useEffect(() => {
        setFormData(initialFilters);
    }, [initialFilters, onFiltersChange]);

    const handleDataChange = (value, path) => {
        setFormData((prevState) => {
            const newState = { ...prevState };

            set(newState, path, value);

            return newState;
        });
    };

    const applyFilters = useCallback(() => {
        onFiltersChange(
            Object.entries(formData).reduce((newFilters, [path, value]) => {
                if (
                    !(
                        value === undefined ||
                        value === null ||
                        value.length === 0
                    )
                ) {
                    // eslint-disable-next-line no-param-reassign
                    newFilters[path] = value;
                }

                return newFilters;
            }, {})
        );
    }, [formData, onFiltersChange]);

    const handleClearFilter = useCallback(() => {
        setFormData({});
        onFiltersChange({});
    }, [onFiltersChange, setFormData]);

    const handleFormEnter = useCallback(
        (event) => {
            if (event.key === 'Enter' && !event.defaultPrevented) {
                applyFilters();
            }
        },
        [applyFilters]
    );

    return (
        <div className={internalStyles.filterBar}>
            <div className={internalStyles.titleBar}>
                <div className={internalStyles.filterTitle}>
                    {translator(messages.title)}
                </div>
                <div>
                    <WMICButton
                        id="clearFilter"
                        type="secondary-text"
                        size="small"
                        disabled={isFiltersEmpty}
                        onClick={handleClearFilter}
                        className={internalStyles.filterBtn}
                    >
                        {translator(messages.clearFilters)}
                    </WMICButton>
                    <WMICButton
                        id="applyFilter"
                        type="secondary-text"
                        size="small"
                        disabled={isFormEmpty && isFiltersEmpty}
                        onClick={applyFilters}
                        className={internalStyles.filterBtn}
                    >
                        {translator(messages.applyFilters)}
                    </WMICButton>
                </div>
            </div>
            <hr className={internalStyles.titleDivider} />
            <Flex  onKeyDown={handleFormEnter} role="presentation" className={internalStyles.filtersContainer}>
                <MetadataForm
                    uiProps={metadata}
                    onDataChange={handleDataChange}
                    classNameMap={styles}
                    resolveValue={readValue}
                    callbackMap={callbackMap}
                    componentMap={componentMap}
                    overrideProps={overrideProps}
                    data={formData}
                />
            </Flex>
        </div>
    );
};

WMICFilterBarContentInternal.displayName = 'WMICFilterBarContent';

export const WMICFilterBarContent = React.memo(WMICFilterBarContentInternal);
