import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// eslint-disable-next-line valid-jsdoc
/**
 * ScrollToError is an Jutro component.
 *
 * This is a helper component that can be added to
 * a page where form fields are displayed and validated.
 * This doesn't render anything, but uses the side effects
 * of React lifecycle to inspect the DOM and scroll to the
 * first field in error.
 *
 * @example
 * this.setState({errorTimestamp: Date.now()});
 * <ScrollToError counter={this.state.errorTimestamp} />
 *
 * @extends PureComponent<{}>
 */
export default class WMICScrollToError extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        counter: PropTypes.number,
        timeout: PropTypes.number,
    };

    static defaultProps = {
        counter: 0,
        timeout: 0,
    };

    /**
     * React lifecycle method that renders after update to 'counter' property
     */
    componentDidUpdate() {
        this.scrollToError(document);
    }

    isVisible = (element) => {
        const elementStyle = window.getComputedStyle(element);
        return !(
            element.getAttribute('hidden') === ''
            || elementStyle.display === 'none'
            || elementStyle.visibility === 'hidden'
            || elementStyle.visibility === 'collapse'
            || !element.offsetWidth
            || !element.offsetHeight
            || !element.getClientRects().length
        );
    };

    getFocusableElement = (firstErrorField) => {
        const { parentElement } = firstErrorField;
        const elementsWithTabIndexSet = Array.from(
            parentElement.querySelectorAll("[tabindex]:not([tabindex='-1'])")
        );
        const elementsWithTabIndexEmbedded = Array.from(
            parentElement.querySelectorAll(
                'input, button, select, textarea, a[href]'
            )
        );
        const possiblyFocusableElements = elementsWithTabIndexSet.concat(
            elementsWithTabIndexEmbedded
        );
        const [firstFound] = possiblyFocusableElements
            .filter(
                (el) => el.tabIndex >= 0 && !el.disabled && this.isVisible(el)
            )
            .sort((el1, el2) => el1.tabIndex - el2.tabIndex);
        return firstFound || parentElement;
    };

    scrollToError(
        containerNode,
        initialQuery = 'div[role=alert][class*=error]'
    ) {
        const { timeout } = this.props;
        if (containerNode) {
            const errorFields = Array.from(
                containerNode.querySelectorAll(initialQuery)
            );
            if (
                errorFields.length === 0
                && initialQuery === 'div[role=alert][class*=error]'
            ) {
                // no fields found so it could be using @jutro/validation which needs a different query
                this.scrollToError(
                    containerNode,
                    'div[role=alert][class*=fieldMessage]'
                );
                return;
            }
            // find the first element visible that contains error
            const firstErrorField = errorFields.find(
                (elem) => elem.offsetTop > 0 && elem.offsetHeight > 0
            ) || errorFields[0];
            if (firstErrorField) {
                // find the element that should receive focus
                const element = this.getFocusableElement(firstErrorField);
                if (element) {
                    element.focus({ preventScroll: true });
                }
                setTimeout(() => {
                    firstErrorField.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }, timeout);
            }
        }
    }

    render() {
        // Nothing to render; this is just for managing the scroll to error DOM logic
        return '';
    }
}
