import React, { useState, Fragment } from 'react';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';


function WMICUpdateFinancePAModal(props) {
    const {
        title,
        subtitle,
        data,
        footerText,
        primaryButtonMessage,
        secondaryButtonMessage,
        onPrimaryAction,
        isOpen,
        onReject,
        onResolve
    } = props;
    const translator = useTranslator();

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext 
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc>
            <ModalHeader
                title={title}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: 'true',
                    }
                }}
            />
            <ModalBody>
                <div>
                    <p>
                        {subtitle}
                    </p>
                    <p>
                        {data.map((element) => (
                                <Fragment key={element.key}>
                                    <span>
                                        <strong>{element.key}</strong>
                                        { `: ${element.value} `}
                                    </span>
                                    <br />
                                </Fragment>
                            ))}
                    </p>
                    <p>
                        {footerText}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject(); }}
                    type="outlined"
                >
                    {translator(secondaryButtonMessage)}
                </WMICButton>
                <WMICButton
                    onClick={() => { onResolve(); onPrimaryAction(); }}
                    type="primary"
                >
                    {translator(primaryButtonMessage)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
}

WMICUpdateFinancePAModal.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    data: PropTypes.shape([]).isRequired,
    footerText: PropTypes.string.isRequired,
    primaryButtonMessage: PropTypes.string.isRequired,
    secondaryButtonMessage: PropTypes.string.isRequired,
    onPrimaryAction: PropTypes.func.isRequired
};


export default WMICUpdateFinancePAModal;