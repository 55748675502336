// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICFlexAlertMessage__wmicAlertContainer{margin-bottom:var(--GW-LAYOUT-5)}.app__WMICFlexAlertMessage__wmicAlertContainer .app__WMICFlexAlertMessage__wmicAlertType{align-items:center;background-color:var(--wmic-warning-alert-fill);border:1px solid var(--wmic-warning-alert-color);border-radius:var(--GW-BORDER-RADIUS);display:flex;flex-direction:row;justify-content:flex-start}.app__WMICFlexAlertMessage__wmicAlertContainer .app__WMICFlexAlertMessage__wmicAlertMessageContainer{align-items:center;display:flex;padding:var(--GW-LAYOUT-4)}.app__WMICFlexAlertMessage__wmicAlertContainer .app__WMICFlexAlertMessage__wmicAlertMessageContainer p{margin:0}.app__WMICFlexAlertMessage__wmicAlertContainer .app__WMICFlexAlertMessage__wmicAlertIcon{color:var(--wmic-warning-alert-color);margin-right:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICFlexAlertMessage/WMICFlexAlertMessage.module.scss"],"names":[],"mappings":"AAAA,+CACI,gCAAA,CAEA,yFAIE,kBAAA,CAGA,+CAAA,CADA,gDAAA,CADA,qCAAA,CAJA,YAAA,CACA,kBAAA,CACA,0BAIA,CAGF,qGAGE,kBAAA,CAFA,YAAA,CACA,0BACA,CAEA,uGACE,QAAA,CAIJ,yFAEE,qCAAA,CADA,gCACA","sourcesContent":[".wmicAlertContainer {\n    margin-bottom: var(--GW-LAYOUT-5);\n  \n    .wmicAlertType {\n      display: flex;\n      flex-direction: row;\n      justify-content: flex-start;\n      align-items: center;\n      border-radius: var(--GW-BORDER-RADIUS);\n      border: 1px solid var(--wmic-warning-alert-color);\n      background-color: var(--wmic-warning-alert-fill);\n    }\n  \n    .wmicAlertMessageContainer {\n      display: flex;\n      padding: var(--GW-LAYOUT-4);\n      align-items: center;\n  \n      p {\n        margin: 0;\n      }\n    }\n  \n    .wmicAlertIcon {\n      margin-right: var(--GW-SPACING-3);\n      color: var(--wmic-warning-alert-color);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicAlertContainer": "app__WMICFlexAlertMessage__wmicAlertContainer",
	"wmicAlertType": "app__WMICFlexAlertMessage__wmicAlertType",
	"wmicAlertMessageContainer": "app__WMICFlexAlertMessage__wmicAlertMessageContainer",
	"wmicAlertIcon": "app__WMICFlexAlertMessage__wmicAlertIcon"
};
export default ___CSS_LOADER_EXPORT___;
