import React, { useContext, useCallback, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICQuestionSetComponent, WMICRequiredText } from 'wmic-pe-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { FlowStepId, LOBConstants } from 'wmic-pe-portals-utils-js';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICQualificationPage.metadata.json5';
import styles from './WMICQualificationPage.module.scss';
import messages from './WMICQualificationPage.messages';

const LOB_PATH = {
    PersonalAuto: 'lobData.personalAuto.preQualQuestionSets.children[0].value',
    Homeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    HOPHomeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    BusinessOwners: 'lobData.businessOwners.preQualQuestionSets.children[0].value',
    BusinessAuto: 'lobData.businessOwners.preQualQuestionSets.children[0].value',
    PersonalUmbrella: 'lobData.personalUmbrella.preQualQuestionSets.children[0].value',
    CommercialPackage: 'lobData.commercialProperty.preQualQuestionSets.children[0].value'
};

const ANIS_PATH = 'baseData.additionalNamedInsureds_WMIC'

function WMICQualificationPage(props) {
    const translator = useContext(TranslatorContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const history = useHistory();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('QualificationPage');
    const [showErrors, setShowErrors] = useState(false);
    const lobCode = _.get(submissionVM.value, 'baseData.productCode');
    const { setWizardLoading } = useWizardModals();

    useDocumentTitle("Qualification Questions", submissionVM);

    const additionalNamedInsureds = _.get(submissionVM.value, ANIS_PATH)

    const onNext = useCallback(async () => {
        if (isComponentValid) {
            try {
                setWizardLoading(true);
                _.unset(submissionVM.value, 'bindData');

                submissionVM.value.flowStepId_WMIC = FlowStepId.PRE_QUALIFICATION;
                const result = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );

                _.extend(submissionVM.value, result);

                _.set(submissionVM, `${ANIS_PATH}.value`, additionalNamedInsureds);

                const declinedMessages = submissionVM.value.declineMessages_WMIC;
                const {quoteID} = submissionVM.value;

                if (declinedMessages && declinedMessages.length > 0 ) {
                    let parms = {
                        underwritingIssues: declinedMessages,
                        quoteID
                    };

                    if (lobCode === LOBConstants.CP) {
                        parms = {
                            underwritingIssues: declinedMessages,
                            quoteID,
                            lob: 'cp',
                            account: {
                                displayName: _.get(submissionVM.value, 'baseData.accountHolder.displayName'),
                                displayAddress: _.get(submissionVM.value, 'baseData.accountHolder.primaryAddress.displayName') || '',
                                accountNumber: _.get(submissionVM.value, 'baseData.accountNumber')
                            }
                        };
                    }

                    history.push('/knockoutpage', parms);

                    return false;
                }

                updateWizardData(submissionVM);

                return submissionVM;

            } finally {
                setWizardLoading(false);
            }
        }

        setShowErrors(true);

        return false;
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, submissionVM, LoadSaveService, authHeader, additionalNamedInsureds, lobCode, history]);

    const overrideProps = {
        questionSets: {
            path: LOB_PATH[lobCode],
            showErrors,
            baseState: submissionVM.baseData.baseState.value,
            lobCode
        },
        PupQuestionSets: {
            path: 'lobData.personalUmbrella.preQualQuestionSets.children[1].value',
            showErrors,
            baseState: submissionVM.baseData.baseState.value,
            lobCode,
            visible: lobCode === LOBConstants.PUP
        },
        qualificationHeader: translator(messages.wmicPaQualificationTitle)
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: { questionset: WMICQuestionSetComponent, WMICRequiredText },
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={commonMessages.saveAndExit}
            showMandatoryInfo
            showRequired
            hideBmsiWarning
            flowStepId={FlowStepId.PRE_QUALIFICATION}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICQualificationPage.propTypes = wizardProps;
export default WMICQualificationPage;
