import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicles: {
        id: 'policycommon.component.policy-vehicle-info.vehicles',
        defaultMessage: 'Vehicles'
    },
    vehicleInfoPolicy: {
        id: 'policycommon.component.policy-vehicle-info.Policy',
        defaultMessage: 'Policy'
    },
    hideVehicleCoverages: {
        id: 'policycommon.component.policy-vehicle-info.hide vehicle coverages',
        defaultMessage: 'Hide Vehicle Coverages'
    },
    showVehicleCoverages: {
        id: 'policycommon.component.policy-vehicle-info.show vehicle coverages',
        defaultMessage: 'Show Vehicle Coverages'
    },
    coveredDrivers: {
        id: 'policycommon.component.policy-vehicle-info.Covered Drivers',
        defaultMessage: 'Covered Drivers'
    },
    make: {
        id: 'policycommon.component.policy-vehicle-info.make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'policycommon.component.policy-vehicle-info.model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'policycommon.component.policy-vehicle-info.year',
        defaultMessage: 'Year'
    },
    licensePlate: {
        id: 'policycommon.component.policy-vehicle-info.license plate',
        defaultMessage: 'License plate'
    },
    totalPremium: {
        id: 'policycommon.component.policy-vehicle-info.Total Premium',
        defaultMessage: 'Total Premium:'
    },
    name: {
        id: 'policycommon.component.policy-vehicle-info.Name',
        defaultMessage: 'Name'
    },
    license: {
        id: 'policycommon.component.policy-vehicle-info.License',
        defaultMessage: 'License'
    },
    dob: {
        id: 'policycommon.component.policy-vehicle-info.Date of Birth',
        defaultMessage: 'Date of Birth'
    }

});
