import React, { useEffect, useMemo, useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { WMICDropdownSelect } from 'wmic-components-platform-react';
import { WMICLoader } from 'gw-components-platform-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import messages from './WMICManagePaymentPlan.messages';
import styles from './WMICManagePaymentPlan.module.scss';

const PAYMENT_TYPES = {
    MONTHLY_PAY: 'monthly automated recurring'
};
const POLICY_STATUS = {
    SCHEDULED: 'scheduled'
};

const WMICSelectPaymentPlanModal = (props) => {
    const [availablePlans, setAvailablePlans] = useState(undefined);
    const [isLoading, updateLoading] = useState(true);
    const {
        billingData,
        paymentOption: value,
        updatePaymentOption: onValueChange,
        updateNewPaymentPlan,
        setMARPaymentPlanName,
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {policyId, termNumber, paymentPlan = ''} = _.get(props, 'billingData', {});
    const currentPaymentPlan = _.get(billingData, 'paymentPlan');


    const hasMARPayPlanCurrentAndRenewalTerms = useMemo(() => {
        const renewalPolicyStatus = _.get(billingData, 'renewalPolicyStatus', '');
        const renewalPaymentPlan = _.get(billingData, 'renewalPaymentDetails.paymentPlan', '');

        if (
            paymentPlan.toLowerCase() === PAYMENT_TYPES.MONTHLY_PAY
            && renewalPolicyStatus.toLowerCase() === POLICY_STATUS.SCHEDULED
            && renewalPaymentPlan.toLowerCase() === PAYMENT_TYPES.MONTHLY_PAY
        ) {
            return true;
        }
        
        return false;
    }, [billingData, paymentPlan]);

    const onUpdatePaymentPlan = useCallback((val) => {
        onValueChange(val);
    }, [onValueChange]);

    useEffect(() => {
        AccountBillingDetailsService
            .getPaymentPlanChangesWithInvoices_WMIC(policyId, termNumber, authHeader)
            .then((data) => {
                // ENV related name of the MAR Payment plan "Annual Twelve Installments", "Monthly Automated Recurring"
                const _paymentPlanMAR = data?.paymentPlanChanges?.find((p) => 
                    p.paymentPlanDisplayName === 'Monthly Automated Recurring' || 
                    ["Annual Twelve Installments", "Monthly Automated Recurring"].includes(p.paymentPlanName));
                setMARPaymentPlanName(_paymentPlanMAR?.paymentPlanName || 'Monthly Automated Recurring');
                
                const filterPlans = _.filter(data?.paymentPlanChanges, (plan) => {
                    if (plan.invoices.length) {
                        return true;
                    }
                    
                    return false;
                });
        
                updateNewPaymentPlan(filterPlans);
        
                const availableAllPlans = filterPlans.filter((plan) => plan.paymentPlanDisplayName !== currentPaymentPlan)
                    .map((plan) => ({ name: plan.paymentPlanDisplayName, code: plan.paymentPlanDisplayName }));

                setAvailablePlans(availableAllPlans);

            })
            .finally(() => updateLoading(false));

    }, [authHeader, currentPaymentPlan, policyId, setMARPaymentPlanName, termNumber, updateNewPaymentPlan]);

    if (isLoading || !availablePlans) {
        return isLoading ? <WMICLoader isInline /> : null;
    }

    return (
        <div>
            <p className={styles["wmic-sm-footing"]}>
                <strong>
                    {`${translator(messages.currentPaymentPlan)}: `}
                </strong>
                {currentPaymentPlan}
            </p>
            {
                hasMARPayPlanCurrentAndRenewalTerms && (
                    <p className="ww-p-warning">
                        <i className="fa fa-exclamation-circle ww-icon-warning" />
                        <span>{translator(messages.noteChanging)}</span>
                    </p>
                )
            }

            <WMICDropdownSelect
                id="selectPlan-pp"
                value={value}
                availableValues={availablePlans}
                label={translator(messages.newPlan)}
                onValueChange={onUpdatePaymentPlan}
                className={styles['wmic-dropdown-fit-content']}
            />
        </div>
    );
};

WMICSelectPaymentPlanModal.propTypes = {
    billingData: PropTypes.shape({}),
    paymentOption: PropTypes.string,
    updatePaymentOption: PropTypes.func.isRequired,
    updateNewPaymentPlan: PropTypes.func.isRequired
};

WMICSelectPaymentPlanModal.defaultProps = {
    billingData: {},
    paymentOption: ''
};

export default WMICSelectPaymentPlanModal;