// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICAdditionalVehicleInfoBox__capitalizeText{text-transform:capitalize}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-claim-react/components/WMICAdditionalVehicleInfoBox/WMICAdditionalVehicleInfoBox.module.scss"],"names":[],"mappings":"AAIA,mDACE,yBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n\n.capitalizeText {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"capitalizeText": "app__WMICAdditionalVehicleInfoBox__capitalizeText"
};
export default ___CSS_LOADER_EXPORT___;
