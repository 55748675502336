// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ListIterableComponent__listItemContainer{padding-bottom:var(--GW-SPACING);padding-left:calc(var(--GW-SPACING-2)*3);width:85%}.app__ListIterableComponent__listItemContainer .app__ListIterableComponent__unorderedList{list-style-type:none;vertical-align:middle}.app__ListIterableComponent__listItemContainer .app__ListIterableComponent__icon{color:var(--GW-LINK-COLOR);height:30px;margin-right:.5rem;vertical-align:-webkit-baseline-middle}.app__ListIterableComponent__listItemContainer .app__ListIterableComponent__listData{color:var(--GW-LINK-COLOR);font-size:var(--GW-FONT-SIZE-BODY)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-react/Components/HelpPage/ListIterableComponent/ListIterableComponent.module.scss"],"names":[],"mappings":"AAGA,+CAGI,gCAAA,CAFA,wCAAA,CACA,SACA,CAEA,0FACI,oBAAA,CACA,qBAAA,CAGJ,iFAII,0BAAA,CAHA,WAAA,CAEA,kBAAA,CADA,sCAEA,CAEJ,qFAEI,0BAAA,CADA,kCACA","sourcesContent":["@import \"~producer-engage/src/assets/sass/helpers.scss\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n\n.listItemContainer {\n    padding-left: calc(3 * var(--GW-SPACING-2));\n    width: 85%;\n    padding-bottom: var(--GW-SPACING);\n\n    .unorderedList {\n        list-style-type: none;\n        vertical-align: middle;\n    }\n    \n    .icon {\n        height: 30px;\n        vertical-align: -webkit-baseline-middle;\n        margin-right: 0.5rem;\n        color: var(--GW-LINK-COLOR);\n    }\n    .listData {\n        font-size: var(--GW-FONT-SIZE-BODY);\n        color: var(--GW-LINK-COLOR);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItemContainer": "app__ListIterableComponent__listItemContainer",
	"unorderedList": "app__ListIterableComponent__unorderedList",
	"icon": "app__ListIterableComponent__icon",
	"listData": "app__ListIterableComponent__listData"
};
export default ___CSS_LOADER_EXPORT___;
