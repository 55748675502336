import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { GatewayDocumentService } from 'wmic-pe-capability-gateway-document';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PENDING_TRANSACTION_STATUS, COMPLETED_TRANSACTION_STATUS, TRANSACTION_DESTINATIONS, JobType, WMICDateTimeService, PRODUCT, RENEWAL_DISPLAY_STATUSES } from 'wmic-pe-portals-utils-js';
import { WMICLink, WMICIntlFormattedDateCell } from 'wmic-pe-components-platform-react';
import { WMICWizardViewPage, useWizardModals } from "wmic-pe-portals-wizard-components-ui";
import _ from 'lodash';
import messages from './WMICPolicyTransactions.messages';
import metadata from './WMICPolicyTransactions.metadata.json5';
import styles from './WMICPolicyTransactions.module.scss';

function WMICPolicyTransactions(props) {

    const {
        wizardData: { policyVM },
    } = props;

    const history = useHistory();
    const { showInfo } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const isCommercialPackage = _.get(policyVM, "product.productCode.value") === PRODUCT.COMMERCIAL_PACKAGE

    const [completedTransactions, setCompletedTransactions] = useState([]);
    const [pendingTransactions, setPendingTransactions] = useState([]);

    const setCorrectTransactionStatus = (transaction) => {
        const correctStatus = transaction.type === JobType.RENEWAL ? transaction.policyDisplayStatus : transaction.status;
        _.set(transaction, 'displayStatus', correctStatus);
        return transaction;
    };

    const getPendingTransactions = (transactions) => transactions
            .filter((transaction) => PENDING_TRANSACTION_STATUS.includes(transaction.periodStatus))
            .map((t) => setCorrectTransactionStatus(t))

    const getCompletedTransactions = (transactions) => transactions
            .filter((transaction) => COMPLETED_TRANSACTION_STATUS.includes(transaction.periodStatus))
            .map((t) => setCorrectTransactionStatus(t))

    useEffect(() => {
        const transactions = _.orderBy(_.get(policyVM, 'jobs.value', []), 'closeDate', 'desc');

        setCompletedTransactions(getCompletedTransactions(transactions));
        setPendingTransactions(getPendingTransactions(transactions));
    }, [policyVM]);

    const getFormattedDateCell = (date) => {
        const formattedDate = WMICDateTimeService.localDate2Date(date);
        return  (
            <WMICIntlFormattedDateCell date={formattedDate} />
        )
    }

    const renderPolicyEffectiveDate = (data) => getFormattedDateCell(data.policyEffectiveDate)

    const renderTransactionEffectiveDate = (data) => getFormattedDateCell(data.transactionEffectiveDate)

    const handleViewClick = async (data) => {
        await showInfo({
            title: translator(messages.policyChangeDescription),
            message: data.description
        })
    }

    const handleJobNumberClick = (data) => {
        let rowClickDestination = TRANSACTION_DESTINATIONS.QUOTE;

        switch (data.type) {
            case JobType.SUBMISSION: {
                rowClickDestination = TRANSACTION_DESTINATIONS.QUOTE;
                break;
            }
            case JobType.POLICY_CHANGE: {
                rowClickDestination = TRANSACTION_DESTINATIONS.CHANGE;
                break;
            }
            case JobType.RENEWAL: {
                rowClickDestination = TRANSACTION_DESTINATIONS.RENEWAL;
                break;
            }
            case JobType.CANCELLATION: {
                rowClickDestination = TRANSACTION_DESTINATIONS.CANCELLATION;
                break;
            }
            default: {
                break;
            }
        }

        history.push(`/${rowClickDestination}/${data.jobNumber}/summary`);
    }

    // eslint-disable-next-line consistent-return
    const renderDescriptionCell = (data) => {
        if (data.description) {
            return(
                <WMICLink onClick={() => handleViewClick(data)} className={styles.link}>
                    {translator(messages.view)}
                </WMICLink>
            );
        }
    }

    const renderJobNumber = (data) => {
        if (data.isTransactionAccessibleByBroker) {
            return (
                <WMICLink onClick={() => handleJobNumberClick(data)} className={styles.link}>
                    {data.jobNumber}
                </WMICLink>
            );
        } else {
            return (
                <span>
                    {data.jobNumber} {data.isTransactionPendingWithUW ? '*' : ''}
                </span>
            );
        }
    }

    const renderDocumentLink = (documentData, message) => {
        if (!documentData?.docUID) {
            return null;
        }

        const {publicID, sessionID} = documentData;
        const link = GatewayDocumentService.downloadDocument(publicID, sessionID);

        return (
            <WMICLink href={link} icon="mi-insert_drive_file" target="_blank" className={styles.link}>
                {translator(message)}
            </WMICLink>
        );
    }

    const renderDocumentLinks = (data) => {
        const result = [];

        const declarationOfPolicyLink = renderDocumentLink(data.declarationOfPolicy, messages.documentPdf);
        if (declarationOfPolicyLink) {
            result.push(declarationOfPolicyLink);
        }

        const idCardLink = renderDocumentLink(data.idCard, messages.idCardPdf);
        if (idCardLink) {
            result.push(idCardLink);
        }

        return <div className={styles.flexContainer}>{result}</div>;
    }

    const override = {
        '@element': {
            headerClassName: 'transactionsTable',
            cellClassName: 'transactionsTable'
        },
        completedTransactionsTable: {
            data: completedTransactions
        },
        pendingTransactionsTable: {
            data: pendingTransactions
        },
        description: {
            visible: !isCommercialPackage
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            renderDescriptionCell,
            renderPolicyEffectiveDate,
            renderTransactionEffectiveDate,
            renderJobNumber,
            renderDocumentLinks
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                callbackMap={resolvers.resolveCallbackMap}
                overrideProps={override}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyTransactions.propTypes = wizardProps;
export default WMICPolicyTransactions;
