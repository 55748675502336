/* eslint-disable max-len */
import React, { useState } from 'react';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Tooltip } from '@jutro/components';
import PropTypes from 'prop-types';


import metadata from './WMICSwitchIconTooltip.metadata.json5';
import styles from './WMICSwitchIconTooltip.module.scss';


import { IconButton } from '@jutro/legacy/components';


const WMICSwitchIconTooltip = (props) => {
    const {
        visible,
        className,
        buttonClassName,
        // eslint-disable-next-line react/prop-types
        setTooltipVisibility
    } = props;

    const resolvers = {
        resolveClassNameMap: styles
    };

    const [iconClass, setIconClass] = useState('fas fa-question-circle wmicIconMedium');

    // eslint-disable-next-line no-unused-vars
    const onClickHandle = (event) => {
        if (iconClass === 'fas fa-question-circle wmicIconMedium') {
            setIconClass('fa fa-times-circle wmicIconMedium');
            setTooltipVisibility(true);
        } else {
            setIconClass('fas fa-question-circle wmicIconMedium');
            setTooltipVisibility(false);
        }
    };

    const switchIconTooltip = (componentProps) => {
        return (
            <span>
                <a className={buttonClassName} href onClick={onClickHandle}>
                    <i className={iconClass} />
                </a>
            </span>
        );
    };

    const overrides = {
        componentContentWrapper: {
            content: switchIconTooltip(props),
            visible: visible,
            className: className
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrides}
            model={props}
        />
    );
};

WMICSwitchIconTooltip.propTypes = {
    visible: PropTypes.bool,
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    buttonClassName: PropTypes.string
};

WMICSwitchIconTooltip.defaultProps = {
    visible: true,
    buttonClassName: 'btn help-button'
};


export default WMICSwitchIconTooltip;
