import React, { useContext, useMemo } from 'react';
import { DataTable } from '@jutro/legacy/datatable';
import { Flex, FlexItem } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import styles from './WMICDataTable.module.scss'
import { InputField } from '@jutro/legacy/components';

const WMICDataTable = (props) => {
    const { headingClass, withSearch, headingText, pShowSearch, searchValue, searchPlacholder, onSearchUpdate, children } = props;
    const translator = useContext(TranslatorContext);
    const headSection = useMemo(() => {
        if (headingText || withSearch) {
            return <Flex justifyContent="between" alignItems="center">
                { 
                    headingText &&
                        <FlexItem>
                            <h2 className={headingClass}>{translator(headingText)}</h2>
                        </FlexItem>

                }
                {
                    withSearch && 
                        <FlexItem className={styles.searchFieldContainer}>
                            <InputField  
                                icon="mi-search"
                                iconPosition="right" 
                                value={searchValue} 
                                onValueChange={onSearchUpdate}
                                placeholder={translator(searchPlacholder)}
                                
                            />
                        </FlexItem>
                }
            </Flex>
        }
        return null
    }, [headingClass, headingText, onSearchUpdate, searchPlacholder, searchValue, translator, withSearch])

    return (
        <>
            {headSection}
            <DataTable {...props} showSearch={!withSearch && pShowSearch}>
                {children}
            </DataTable>
        </>
    );
}

WMICDataTable.propTypes = {
};

export default WMICDataTable;
