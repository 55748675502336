import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import _ from 'lodash';
import { HOConstants, CONSTANTS, JURISDICTIONS, WMICRichTextUtil, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import WMICHOConstructionHeatingUpgradeComponent from './WMICHOConstructionHeatingUpgradeComponent/WMICHOConstructionHeatingUpgradeComponent';
import WMICHeatingDetailView from './WMICHeatingDetailView/WMICHeatingDetailView';
import messages from './WMICHOConstructionHeating.messages';
import metadata from './WMICHOConstructionHeating.metadata.json5';
import styles from './WMICHOConstructionHeating.module.scss';

function WMICHOConstructionHeating(props) {
    const { riskView, id, showErrors, jobVM, updateDetails, isEditMode, onValidate } = props;

    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals();
    const { refreshData } = useDataRefresh();

    const ratingJurisdiction = _.get(riskView, 'rating.ratingJurisdiction.value.code');
    const hoPolicyType = _.get(riskView, 'rating.hoPolicyType.value.code');

    const updateDetailsData = useCallback(
        (data) => {
            refreshData();
            updateDetails(data);
        },
        [refreshData, updateDetails]
    );

    const newHeatingShape = {
        primaryFuelSource: {
            primary: true,
            fuelSurvey: {
                primarySurveyDetail: { isPrimary: true, heatingCertificates: [] },
                secondarySurveyDetail: { isPrimary: false, heatingCertificates: [] }
            }
        },
        secondaryFuelSource: {
            primary: false,
            fuelSurvey: {
                primarySurveyDetail: { isPrimary: true, heatingCertificates: [] },
                secondarySurveyDetail: { isPrimary: false, heatingCertificates: [] }
            }
        }
    };

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);

    const [availableHeatingUses, setAvailableHeatingUses] = useState([]);
    const [availableHeatingTypes, setAvailableHeatingTypes] = useState([]);
    const [availableFuelTypes, setAvailableFuelTypes] = useState([]);

    const allHeaters = _.get(riskView, 'construction.heaters.children', []);
    const isPrimaryHeatingUsePresent = allHeaters.some((heater) => heater?.heatingUse?.value?.code === CONSTANTS.HEATER_TYPES.PRIMARY);

    const createVM = (model) => viewModelService.create(
            model,
            'pc',
            // eslint-disable-next-line no-secrets/no-secrets
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.HeaterDTO_WMIC',
            {
                ...jobVM.aspects.context(),
                ...riskView.aspects.context()
            }
        );

    const getAvailableHeatingUses = (isSelectedHeaterPrimary, availableValues) => {
        const filteredHeatingUseValues = isPrimaryHeatingUsePresent && !isSelectedHeaterPrimary
            ? availableValues.filter((heatingUse) => heatingUse.code !== CONSTANTS.HEATER_TYPES.PRIMARY)
            : availableValues;
        const mappedFilteredHeatingUseValues = filteredHeatingUseValues.map((heatingUse) => ({
                ...heatingUse,
                name: {
                    id: heatingUse.name,
                    defaultMessage: heatingUse.name
                }
            }));
        return mappedFilteredHeatingUseValues;
    };

    const getAvailableFuelTypes = (currentHeatingVM) => {
        const availableFuelTypesValues = currentHeatingVM.primaryFuelSource.fuelType.aspects.availableValues;
        const mappedFilteredFuelTypeValues = availableFuelTypesValues.map((fuelType) => ({
                ...fuelType,
                name: {
                    id: fuelType.name,
                    defaultMessage: fuelType.name
                }
            }));
        return _.filter(mappedFilteredFuelTypeValues, (item) => item.code !== CONSTANTS.HEATING_FUEL_TYPES.UNKNOWN);
    };

    const isRPC1732EffectiveForRiskType = () => {
        const rateAsOfDate = _.get(jobVM, 'baseData.rateAsOfDate.value');

        const isEffective = WMICRPCUtil.getIsRPCEffectiveForRiskType(ratingJurisdiction, rateAsOfDate, hoPolicyType, '1732');

        const isValidRisk = [HOConstants.mobileHomeownerPolicyType, HOConstants.revenuePropertyPolicyType, HOConstants.homeownerPolicyType].includes(hoPolicyType);
        return isEffective && isValidRisk;
    }

    const isMaritimeRiskLocation = (maritimeRatingJurisdiction) => JURISDICTIONS.MARITIMES.includes(maritimeRatingJurisdiction);

    const getAvailableHeatingTypes = (currentHeatingVM) => {
        let availableHeaterTypes = currentHeatingVM.heaterType.aspects.availableValues;
        const isSelectedHeaterAuxiliary = !_.isUndefined(currentHeatingVM.heatingUse.value)
            && currentHeatingVM.heatingUse.value.code === CONSTANTS.HEATER_TYPES.AUXILIARY;

        if (isRPC1732EffectiveForRiskType()) {
            availableHeaterTypes = availableHeaterTypes.filter((item) => item.code !== CONSTANTS.HEATING_TYPES.NONE);
        }

        const mappedFilteredHeatingTypeValues = availableHeaterTypes.map((heaterType) => ({
            ...heaterType,
            name: {
                id: heaterType.name,
                defaultMessage: heaterType.name
            }
        }));

        if (isMaritimeRiskLocation(ratingJurisdiction) && isSelectedHeaterAuxiliary) {
            return mappedFilteredHeatingTypeValues;
        }

        return _.filter(mappedFilteredHeatingTypeValues, (item) => item.code !== CONSTANTS.HEATING_TYPES.MINISPLIT);
    };

    const nonDwellingCoverage = useCallback(() => [HOConstants.revenuePropertyPolicyType, HOConstants.seasonalPolicyType].includes(hoPolicyType)
            && (!_.get(riskView, 'yourHome.coverageFor.value') || _.get(riskView, 'yourHome.coverageFor.value.code') !== HOConstants.riskSubTypeDwelling), [hoPolicyType, riskView]);

    useEffect(() => {
        if (onValidate) {
            if(_.isNil(isComponentValid)) {
                onValidate(false, id);
            }
            else if (ratingJurisdiction === JURISDICTIONS.NEW_BRUNSWICK && nonDwellingCoverage()) {
                onValidate(isComponentValid, id);
            } else {
                onValidate(isComponentValid && isPrimaryHeatingUsePresent, id);
            }
        }
    }, [id, isComponentValid, isPrimaryHeatingUsePresent, onValidate, ratingJurisdiction, nonDwellingCoverage]);

    const handleSaveNewHeating = useCallback((currentHeatingVM) => {
        updateDetailsData(riskView);

        const isPrimaryPresent = currentHeatingVM.value.heatingUse === CONSTANTS.HEATER_TYPES.PRIMARY || isPrimaryHeatingUsePresent;
        if (onValidate) {
            onValidate(isPrimaryPresent, id);
        }

        return true;
    }, [id, isPrimaryHeatingUsePresent, onValidate, riskView, updateDetailsData]);

    const handleClickAddNewHeating = () => {
        const newHeatingVM = createVM({...newHeatingShape});

        setAvailableHeatingUses(getAvailableHeatingUses(false, newHeatingVM.heatingUse.aspects.availableValues));
        setAvailableHeatingTypes(getAvailableHeatingTypes(newHeatingVM));

        riskView.construction.heaters.value.push(newHeatingVM.value);

        return newHeatingVM;
    }

    const handleEditHeater = (heaterVM) => {
        const newHeaterVM = createVM(heaterVM.value);
        const isSelectedHeaterPrimary =  _.get(heaterVM, 'heatingUse.value.code') === CONSTANTS.HEATER_TYPES.PRIMARY;

        setAvailableHeatingUses(getAvailableHeatingUses(isSelectedHeaterPrimary, newHeaterVM.heatingUse.aspects.availableValues));
        setAvailableHeatingTypes(getAvailableHeatingTypes(newHeaterVM));
        setAvailableFuelTypes(getAvailableFuelTypes(newHeaterVM));

        updateDetailsData(riskView);
    }

    const handleRemoveHeater = async (row, index) => {
        const response = await showConfirm({
            title: translator(messages.removeHeater),
            message: translator(messages.removeHeaterConfirmation)
        });

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const allHeatersArray = _.get(riskView, 'construction.heaters.value', []);
            allHeatersArray.splice(index, 1);
            // eslint-disable-next-line no-unused-expressions
            !isPrimaryHeatingUsePresent ? onValidate(false, id) : _.noop();

            _.set(riskView, 'construction.heaters', allHeatersArray);
            updateDetailsData(riskView);
        }

        return _.noop();
    }

    const setHeatingUpgradeVM = (model) => {
        const newRiskViewVM = viewModelService.clone(riskView);
        _.set(newRiskViewVM, 'construction.dwellingWings.value[0].constructionDetail.heatingInfo', model.value);
        updateDetailsData(newRiskViewVM);
    }

    const overrideProps = {
        '@field': {
            parentNode: riskView,
        },
        atLeastOnePrimaryHeatingContainer: {
            visible: !nonDwellingCoverage() && !isPrimaryHeatingUsePresent
        },
        atLeastOnePrimaryHeatingText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.atLeastOnePrimaryHeating)),
        },
        heatingListView: {
            VMData: [
                {
                    headerText: translator(messages.heatingUse),
                    path: 'heatingUse'
                },
                {
                    headerText: translator(messages.heatingType),
                    path: 'heaterType'
                },
                {
                    headerText: translator(messages.heatingPrimaryFuel),
                    path: 'primaryFuelSource.fuelType'
                },
                {
                    headerText: translator(messages.heatingSecondaryFuel),
                    path: 'secondaryFuelSource.fuelType'
                },
            ],
            startOpen: false,
            toCreate: handleClickAddNewHeating,
            toEdit: handleEditHeater,
            toUndoCreate: () => {
                const heaters = _.get(riskView.value, 'construction.heaters');
                heaters.splice(heaters.length - 1, 1);
                _.set(riskView.value, 'construction.heaters', heaters);
                updateDetailsData(riskView);
            },
            onSave: handleSaveNewHeating,
            onDelete: handleRemoveHeater,
            // needed to set up the available values
            onClickAction: handleEditHeater,
            onValidate,
            readOnly: !isEditMode,
            clickable: true,
            flatCards: true,
            detailViewComponent: WMICHeatingDetailView,
            detailViewComponentProps: {
                riskView,
                jobVM,
                availableHeatingUses,
                availableHeatingTypes,
                setAvailableHeatingTypes,
                getAvailableHeatingTypes,
                availableFuelTypes,
                setAvailableFuelTypes,
                getAvailableFuelTypes,
            }
        },
        heatingUpgradeComponent: {
            heatingUpgradeVM: _.get(riskView, 'construction.dwellingWings.children[0].constructionDetail.heatingInfo'),
            setHeatingUpgradeVM,
            showErrors,
            onValidate,
            readOnly: !isEditMode
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddNewHeating: handleClickAddNewHeating
        },
        resolveComponentMap: {
            WMICHOConstructionHeatingUpgradeComponent,
            WMICHeatingDetailView
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            onModelChange={updateDetailsData}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );
}

export default WMICHOConstructionHeating;

