import React, { useEffect, useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { useModal, ModalNextEmitter } from '@jutro/components';
import { useBreakpoint } from '@jutro/layout';
import './App.scss';
import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AccurateBreakpointPropagation } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { routeConfirmationModal, DropdownMenuLink } from '@jutro/router';
import vmTranslator, { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    platformComponents,
    platformComponentMap,
    ImageComponent,
} from 'gw-components-platform-react';
import { pePlatformComponentMap, pePlatformComponents, WMICBackToTopButton } from 'wmic-pe-components-platform-react';
import {
    Policies,
    Activities,
    Analytics,
    PolicyDetails,
    NewQuoteAccountSearch,
    NewQuotePage,
    SearchResults,
    AccountBillingAndPayment,
    MakePayment,
    ContactUnderwriter,
    PaymentConfirmation,
    producerEngageComponentMap,
    producerEngageAdditionalComponents,
} from 'gw-capability-gateway-react';
import { SearchService } from 'wmic-pe-capability-gateway';
import WMICPELandingPage, {
    WMICAccounts,
    WMICPageHeaderComponent,
    WMICCoBrowseButton,
    WMICNewQuotePage,
    WMICNewQuoteAccountSearch,
    HelpTabComponent,
    WMICAccountSummary,
    WMICQuoteDetailsPage,
    WMICEndorsementDetailsPage,
    WMICRenewalDetailsPage,
    WMICCancellationDetailsPage
} from 'wmic-pe-capability-gateway-react';
import { WMICPolicyWizard,  WMICPolicyWizardPageWithTitle } from 'wmic-pe-capability-policy-common-react';
import { SelectProducerContextProvider } from 'gw-gateway-common-react';
import { Redirect, useHistory, Router } from 'react-router-dom';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';


import {
    useAuthentication,
    withAuthenticationProvider
} from '@xengage/gw-digital-auth-react';
import { GatewayRenewalService } from 'wmic-pe-capability-gateway-policyrenewal';
import { GatewayEndorsementService, GatewayPolicyChangeService } from 'wmic-pe-capability-gateway-policychange';
import { GatewayCancellationService } from 'wmic-pe-capability-gateway-policycancel';
import { BillingService } from 'wmic-pe-capability-gateway-billing';
import { CustomQuoteService } from 'gw-capability-gateway-quoteandbind';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { UserService, SubmissionService, JobService } from 'gw-capability-gateway';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import { AvailabilityService } from 'wmic-pe-capability-policycommon';
import { GatewayDocumentService } from 'wmic-pe-capability-gateway-document';

import { WMICWizardPageTemplateWithTitle, WizardModalsProvider } from 'wmic-pe-portals-wizard-components-ui';
import { filterCapabilityRoutes } from '@xengage/gw-portals-config-js';
import '@jutro/legacy/dataview';
import '@jutro/legacy/address';
import { PolicyDocumentError } from 'gw-capability-document-react';
import { ContactAgentPage, Preferences } from 'gw-pages-platform-react';
import { WMICPEHOWizard } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import { WMICPEHOPolicyChangeWizard } from 'wmic-pe-capability-gateway-policychange-ho-react';
import { WMICPEPAPolicyChangeWizard } from 'wmic-pe-capability-gateway-policychange-pa-react';
import { WMICPEPUPPolicyChangeWizard } from 'wmic-pe-capability-gateway-policychange-pup-react';
import { WMICPEHORenewalWizard } from 'wmic-pe-capability-gateway-policyrenewal-ho-react';
import { WMICPEPARenewalWizard } from 'wmic-pe-capability-gateway-policyrenewal-pa-react';
import { WMICPEPUPRenewalWizard } from 'wmic-pe-capability-gateway-policyrenewal-pup-react';
import { WMICPAWizard } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { PEPUPWizard } from 'wmic-pe-capability-gateway-quoteandbind-pup-react';
import { PECPWizard } from 'wmic-pe-capability-gateway-quoteandbind-cp-react';
import { WMICReceiptPage, WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';
import appConfig from 'app-config';

import KnockOutPage from '../pages/KnockOutPage/KnockOutPage';

import messages from './App.messages';

import appRoutes from './App.routes.metadata.json5';
import { AppFloorPlan } from '@jutro/legacy/floorplan';


const { capabilitiesConfig, env, gatewayAnalytics, applicationDetails } = appConfig;

const componentMap = {
    landingpage: WMICPELandingPage,
    receiptPage: WMICReceiptPage,
    accounts: WMICAccounts,
    policies: Policies,
    activities: Activities,
    analytics: Analytics,
    accountdetails: WMICAccountSummary,
    policydetails: PolicyDetails,
    newquoteaccountsearch: NewQuoteAccountSearch,
    newquotepage: NewQuotePage,
    searchresults: SearchResults,
    quotedetails: WMICQuoteDetailsPage,
    renewal: WMICRenewalDetailsPage,
    endorsement: WMICEndorsementDetailsPage,
    cancellation: WMICCancellationDetailsPage,
    accountbillingandpayment: AccountBillingAndPayment,
    makepayment: MakePayment,
    contactunderwriter: ContactUnderwriter,
    paymentconfirmation: PaymentConfirmation,
    contactagentpage: ContactAgentPage,
    preferences: Preferences,
    pehowizard: WMICPEHOWizard,
    pecpwizard: PECPWizard,
    papolicychangewizard: WMICPEPAPolicyChangeWizard,
    hopolicychangewizard: WMICPEHOPolicyChangeWizard,
    puppolicychangewizard: WMICPEPUPPolicyChangeWizard,
    horenewalwizard: WMICPEHORenewalWizard,
    parenewalwizard: WMICPEPARenewalWizard,
    puprenewalwizard: WMICPEPUPRenewalWizard,
    policywizard: WMICPolicyWizard,
    knockoutpage: KnockOutPage,
    policydocumenterror: PolicyDocumentError,
    pepupwizard: PEPUPWizard,
    wmicpawizard: WMICPAWizard,
    wmicnewquoteaccountsearch: WMICNewQuoteAccountSearch,
    wmicnewquotepage: WMICNewQuotePage,
    help: HelpTabComponent
};

setComponentMapOverrides({
    ...platformComponentMap,
    ...producerEngageComponentMap,
    ...pePlatformComponentMap,
    WMICPEWizardPageTemplateWithTitle: { component: 'WMICPEWizardPageTemplateWithTitle' },
    // eslint-disable-next-line no-secrets/no-secrets
    WMICPolicyWizardPageWithTitle: { component: 'WMICPolicyWizardPageWithTitle' },
    WMICButton: { component: 'WMICButton' },
    // replace the native IMG component with a proxied version
    img: { component: 'img' }
},
{
    ...platformComponents,
    ...producerEngageAdditionalComponents,
    ...pePlatformComponents,
    WMICPEWizardPageTemplateWithTitle: WMICWizardPageTemplateWithTitle,
    WMICPolicyWizardPageWithTitle,
    img: ImageComponent
});

const defaultRoutes = filterCapabilityRoutes(capabilitiesConfig, appRoutes.routes);

const getUserAuthData = (authData) => UserService.getGatewayCurrentUser(authData.authHeader).catch(() => {
        if(authData) {
            authData.logout();
        }
    });

const modalEmitter = new ModalNextEmitter();

function App() {
    const {
        showError
    } = useModal();
    const history = useHistory();
    const auth = useAuthentication();
    const translator = useTranslator();
    const [viewModelService, updateViewModelService] = useState(undefined);
    const [permissionCheck, updatePermissionCheck] = useState(false);
    const [routes, updateRoutes] = useState(defaultRoutes);

    const { breakpoint } = useBreakpoint({});
    const getLogoSrc = useCallback(() => {
        let logoName = applicationDetails.applicationLogo;

        if(breakpoint === 'phone' || breakpoint === 'phoneWide'){
            logoName = applicationDetails.applicationLogoMobile;
        }

        const prefix = _.get(env, 'DEPLOYMENT_PATH', '').replace(/\/$/, '');

        return `${prefix}/${logoName}`;
    }, [breakpoint]);

    useEffect(() => {
        if (window.self === window.top) {
            const theBody = document.getElementsByTagName('body')[0];

            theBody.className += ' wmicPERoot';
        }
    }, [])

    useEffect(
        () => {
            const translatorFn = vmTranslator(translator);

            import(
                /* webpackChunkName: "product-metadata" */

                'product-metadata'
            ).then((productMetadata) => {
                if(!productMetadata || _.isEmpty(productMetadata.default)) {
                    throw new Error(translator(messages.errorMetadataMessage));
                }

                const { default: result } = productMetadata;
                const newViewmodeService = ViewModelServiceFactory.getViewModelService(result, translatorFn);

                updateViewModelService(newViewmodeService);
                _.set(window, 'wmic.viewmodel.metadata', newViewmodeService.productMetadata);
            });
        },
        [translator]
    );

    const handleError = useCallback((error = {}) => {
        const state = {
            pathname: '/',
            state: error
        };
        const onSubmit = () => <Redirect to={state} />;

        // error modals could fail to render if there is already a modal open when we handle the exception
        if (typeof error === 'object') {
            return WMICErrorHandler.processAsModal(error).then(
                _.noop,
                onSubmit
            );
        }

        return showError({
            title: messages.error,
            message: messages.errorMessage,
            status: MODAL_CONSTANTS.STATUS.ERROR,
            icon: MODAL_CONSTANTS.ICON.ERROR,
            confirmButtonText: commonMessages.ok
        }).then(
            onSubmit,
            _.noop
        );
    }, [showError]);

    const avatarLinks = useMemo(() => [
            <DropdownMenuLink key="faq" to="/faq">{translator(messages.faq)}</DropdownMenuLink>,
            <DropdownMenuLink key="preferences" to="/preferences">{translator(messages.preferences)}</DropdownMenuLink>,
        ], [translator]);

    const checkViewCommissionsAnalyticsPermission = useCallback(() => {
        const commissionsDTO = {
            permission: 'viewcommissions'
        };

        const reportsDTO = {
            permission: 'gpaviewreports'
        };

        if (_.isEmpty(auth.authHeader) || permissionCheck) {
            return;
        }

        updatePermissionCheck(true);

        const commissionPermission = UserService.hasUserSystemPermission(commissionsDTO, auth.authHeader);

        const analyticsPermission = UserService.hasUserSystemPermission(reportsDTO, auth.authHeader);

        Promise.all([commissionPermission, analyticsPermission])
            .then(([hasCommission, hasAnalytics]) => {

                const routesToBeUpdated = ['/commission', '/analytics'];
                let updatedRoutes = 0;

                for (const route of routes) {
                    if (routesToBeUpdated.includes(route.path)) {
                        updatedRoutes += 1;
                        route.showOnNavBar = (
                            route.path === '/commission' ? hasCommission : gatewayAnalytics && hasAnalytics
                        );
                    }

                    if (routesToBeUpdated.length === updatedRoutes) {
                        break;
                    }
                }

                updateRoutes([...routes]);
            });


    }, [auth.authHeader, permissionCheck, routes]);

    const renderCustomHeader = useCallback(() => <WMICPageHeaderComponent navRoutes={routes} />, [routes]);

    const onSearchValueChange = useCallback((selectedValue) => {
        const searchText = _.get(selectedValue, 'id', '');

        if (!_.isEmpty(searchText)){
            let redirectPath = `/search/${searchText}`;

            if (_.get(selectedValue, 'type', '') === 'account') {
                redirectPath = `/accounts/${searchText}/summary`;
            }

            if (_.get(selectedValue, 'type', '') === 'policy') {
                redirectPath = `/policies/${searchText}/summary`
            }

            history.push(redirectPath);
        }
    }, [history]);

    const formatSearchValues = useCallback((displayName, id, type) => {
        const availableObject = {
            displayName,
            id,
            type
        };

        return availableObject;
    }, []);

    const handleLoadValues = useCallback(async (searchParam) => {
        const searchObj = {
            searchParam
        };
        const displayName = translator(messages.viewAll, { Number: searchParam});
        const viewAll = formatSearchValues(displayName, searchParam, '');
        const searchResults = await SearchService.search(searchObj, auth.authHeader);
        const availableValues = [viewAll];

        if (!_.isEmpty(searchResults)) {
            const { accounts, policies } = searchResults;

            if (!_.isEmpty(accounts)) {
                _.each(accounts, (account) => {
                    availableValues.push(formatSearchValues(`${account.accountHolder} - ${account.accountNumber}`, account.accountNumber, 'account'));
                })
            }

            if (!_.isEmpty(policies)) {
                _.each(policies, (policy) => {
                    availableValues.push(formatSearchValues(`${policy.accountNumber} - ${policy.policyNumber}`, policy.policyNumber, 'policy'));
                })
            }
        }

        return availableValues;
    }, [auth.authHeader, formatSearchValues, translator]);
    
    const isCobrowseEnabled = appConfig.env?.COBROWSE_ENABLED == "true" ?? false

    const floorPlansConfig = useMemo(() => [{
            scrollContent: false,
            showFooter: false,
            routes,
            className: "wmicAppFloorPlan",
            showHeader: true,
            showSubHeader: true,
            subHeader: {
                className: 'noPrint',
                renderCustomComponent: () => (
                    isCobrowseEnabled && <WMICCoBrowseButton/>
                ),
            },
            renderHeader: renderCustomHeader,
            header: {
                avatarChildren: avatarLinks,
                avatarProps: {
                    username: auth.userInfo.name,
                    title: _.get(auth, 'authUserData.displayName'),
                    subtitle: _.capitalize(_.get(auth, 'authUserData.userType'))
                },
                className: 'appHeader',
                logoSrc: getLogoSrc(),
                onSearchValueChange,
                onLoadValues: handleLoadValues,
                showAppSwitcher: false,
                showNotifications: false,
                searchFieldPlaceholder: messages.searchPlaceHolder,
                searchOptionTypes:[
                    {
                      "type": "account",
                      "icon": "gw-account-box",
                      "displayName": translator(messages.account)
                    },
                    {
                      "type": "policy",
                      "icon": "gw-policy",
                      "displayName": translator(messages.policy)
                    },
                    {
                        "type": "",
                        "displayName": " "
                    }
                  ]
            }
        }], [routes, renderCustomHeader, avatarLinks, auth, getLogoSrc, onSearchValueChange, handleLoadValues, translator]);

    useEffect(() => {
        checkViewCommissionsAnalyticsPermission();
    }, [checkViewCommissionsAnalyticsPermission]);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    LoadSaveService,
                    CustomQuoteService,
                    AvailabilityService,
                    RenewalService: GatewayRenewalService,
                    DocumentService: GatewayDocumentService,
                    EndorsementService: GatewayEndorsementService,
                    PolicyChangeService: GatewayPolicyChangeService,
                    CancellationService: GatewayCancellationService,
                    BillingService,
                    AccountService,
                    UserService,
                    quoteUnderWritingService: {
                        referToUnderwriter: SubmissionService.referToUnderwriter,
                        withdrawJobByJobNumber: JobService.withdrawJobByJobNumber,
                        approveUnderwritingIssue: LoadSaveService.approveUnderwritingIssue
                    }
                }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <ErrorBoundary onError={handleError}>
                        <SelectProducerContextProvider>
                            <Router history={history} basename="/producer-engage" getUserConfirmation={(message,callback)=>routeConfirmationModal(message,callback,modalEmitter)}>
                                <WizardModalsProvider>
                                    <AppFloorPlan
                                        componentMap={componentMap}
                                        callbackMap={{ renderCustomHeader }}
                                        floorPlans={floorPlansConfig}
                                    />
                                </WizardModalsProvider>
                            </Router>
                        </SelectProducerContextProvider>
                    </ErrorBoundary>
                </ViewModelServiceContext.Provider>
                <WMICBackToTopButton />
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthenticationProvider({ onAuthDataCreation: getUserAuthData })(App);
