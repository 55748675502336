/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import messages from './WMICChangePaymentPlanErrorModal.messages';
import styles from './WMICChangePaymentPlanErrorModal.module.scss';

const WMICChangePaymentPlanErrorModal = (props) => {
    const { onClose, invalidRoutingTransitNumber } = props;
    const translator = useTranslator();
    const title = invalidRoutingTransitNumber ? messages.invalidRoutingTransitNumber :
        messages.planChangeUnsuccessful;
    const message = invalidRoutingTransitNumber ? messages.theRoutingTransitNumberEnteredIsNotValid :
        messages.somethingWentWrong;

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(title)}</h3>
            <ModalBody>
                <div>
                    <p className={styles['wmic-payment-text']}>{translator(message)}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onClose(); }}
                    type="primary"
                >
                    {translator(messages.close)}
                </WMICButton>
            </ModalFooter>
        </Fragment>
    );
};

WMICChangePaymentPlanErrorModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    invalidRoutingTransitNumber: PropTypes
};

export default WMICChangePaymentPlanErrorModal;
