import React, { useContext, Fragment, useMemo, useCallback } from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import { ClaimLookups } from 'gw-capability-claim-react';

import { LobIconUtil } from 'wmic-portals-utils-js';
import metadata from './ClaimSummaryBox.metadata.json5';
import messages from './ClaimSummaryBox.messages';
import styles from './ClaimSummaryBox.module.scss';
import WMICSection from "../WMICSection/WMICSection";
import { Link } from '@jutro/router';

const AUTO_LOSS_TYPE = 'AUTO';


const ClaimSummaryBox = (props) => {
    const { data: aClaim, title } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const lobs = {
        AUTO: 'PersonalAuto',
        PR: 'Homeowners'
    };


    const claimNumberLink = useMemo(() => (
        <Link to={{ pathname: `/claims/${aClaim.claimNumber}` }}
            aria-label={translator( messages.claimNumberAria, {claimNumber: aClaim.claimNumber} )}
            trackbuttonidentifier={translator(
                aClaim.lossType === AUTO_LOSS_TYPE ?
                    messages.ClaimDetailsAutoTrackButtonIdentifier :
                    messages.ClaimDetailsTrackButtonIdentifier
            )}>{aClaim.claimNumber}
        </Link>), [aClaim, translator]);

    /**
     * Given a claim, it returns an array of policy vehicles if the claim is a PA claim.
     * @param {Object} claim object
     * @returns {Array} an array of policy vehicles
     */
    const incidentVehicles = useMemo(() => {
        if (aClaim && aClaim.lobs_WMIC.personalAuto) {
            const vehicleIncidents = _.filter(aClaim.lobs_WMIC.personalAuto.vehicleIncidents, (vi) => vi.vehicle !== undefined );
            let results = _.map(vehicleIncidents, (i) => {
                const incidentVehicle = i.vehicle;
                incidentVehicle.wasParked = i.vehicleParked_WMIC;
                incidentVehicle.driver = i.driver;
                incidentVehicle.repairProgram = i.damageRepairProgram_WMIC;
                return incidentVehicle;
            });

            results = _.filter(results, (vehicle) => vehicle.policyVehicle);

            return results;
        }
        return [];
    }, [aClaim]);

    const vehicleDisplayValue = useMemo(() => {
        let insuredVehicle;

        if (incidentVehicles.length > 1) {
            insuredVehicle = translator(messages.multipleVehicles);
        } else if (incidentVehicles.length === 1) {
            const incidentVehicleDetail = incidentVehicles[0];
            insuredVehicle = `${incidentVehicleDetail.year} ${incidentVehicleDetail.make} ${incidentVehicleDetail.model}`;
        } else {
            insuredVehicle = translator(messages.pending);
        }

        return insuredVehicle;
    }, [translator, incidentVehicles]);

    const readableVehicleDisplayValue = useMemo(() => {
        let insuredVehicle = '';

        if (aClaim) {
            if (incidentVehicles.length > 1) {
                insuredVehicle = translator(messages.multipleVehiclesAria, {claimNumber: aClaim.claimNumber});
            } else if (incidentVehicles.length === 1) {
                const incidentVehicleDetail = incidentVehicles[0];
                insuredVehicle = translator(messages.vehicleAria, {
                    claimNumber: aClaim.claimNumber,
                    vehicleDescription: `${incidentVehicleDetail.year} ${incidentVehicleDetail.make} ${incidentVehicleDetail.model}`
                });
            } else {
                insuredVehicle = translator(messages.pendingAria, {claimNumber: aClaim.claimNumber});
            }
        }

        return insuredVehicle;
    }, [aClaim, incidentVehicles, translator])

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICSection
        }
    };

    const getClaimTitle = () => {
        const icon = LobIconUtil.getWmicIcon(lobs[_.get(aClaim, 'lossType')]);
        return (
            <Fragment>
                <div>
                    <i className={`fa ${icon}`} />
                </div>
                <div>
                    <h3 className={styles['wmic-claim-title']}>
                        {(
                            aClaim.lossType === AUTO_LOSS_TYPE
                                ? translator(messages.automobileClaimNumber, { claimNumber: aClaim.claimNumber })
                                : `${title} claim #${aClaim.claimNumber}`
                        )}
                    </h3>
                </div>
            </Fragment>
        );
    };
    const formatIncidentLocation = useCallback((lossLocation) => {
        const { city, state, postalCode } = lossLocation;
        const locationArray = lossLocation.displayName.split(',');
        const country = locationArray[locationArray.length - 1];
        const cityName = city && `${city},`;
    
        const addressLine1 = lossLocation.addressLine1;
        const addressLine2 = [cityName, state, postalCode].filter((val) => !_.isEmpty(val) && val);

        return (
            <Fragment>
                {addressLine1}
                {addressLine1 && addressLine1.length > 0 && <br />}
                {addressLine2.join(' ')}
                {addressLine2 && addressLine2.length > 0 && <br />}
                {country}
            </Fragment>
        );
    }, []);

    const isLossLocationEmpty = useCallback((lossLocation) => {
        let isEmptyString = true;
        const modifiedLossLoc = lossLocation.displayName.replace(/ /g, '');
        if (modifiedLossLoc.length === 0) {
            isEmptyString = false;
        }
        return isEmptyString;
    }, []);

    const claimTypeDisplayName = useMemo(() => {
        if (viewModelService) {
            const claimSummaryDTOVM = viewModelService.create(
                {},
                'cc',
                'edge.capabilities.claim.summary.dto.ClaimSummaryDTO'
            );
            const lossCause = _.get(aClaim, 'lossCause_WMIC');
            const aIALossType = _.get(aClaim, 'aIALossType_wmic');
            const perilGroupCategory = _.get(aClaim, 'perilGroupCategory_WMIC');
            const perilGroupEvent = _.get(aClaim, 'perilGroupEvent_WMIC');
            let claimType = aClaim.lossType === AUTO_LOSS_TYPE
                ? ClaimLookups.autoClaimTypeDisplayName(lossCause, perilGroupCategory, perilGroupEvent)
                : ClaimLookups.homeClaimTypeDisplayName(lossCause, perilGroupCategory, perilGroupEvent);

            if (claimType !== undefined) {
                claimType = translator({
                    id: `wmic.claim.common.lookups.claim-type.${claimType}`
                });
            } else {
                // This is August 2017, months start from 0, ¯\_(ツ)_/¯
                const cutoffDate = new Date(2017, 7);
                const createTime = new Date(_.get(aClaim, 'createTime_WMIC'));

                if (createTime < cutoffDate) {
                    claimType = ClaimLookups
                        .claimTypeOfLossDisplayName(claimSummaryDTOVM, translator, aIALossType);
                    claimType = claimType
                        ? translator({
                            id: `wmic.claim.common.lookups.claim-type-of-loss.${claimType}`
                        })
                        : translator(messages.pending);
                } else {
                    claimType = ClaimLookups
                        .perilGroupCategoryDisplayName(claimSummaryDTOVM, translator, perilGroupCategory);
                    claimType = claimType
                        ? translator({
                            id: `wmic.claim.common.lookups.peril-group-category.${claimType}`
                        })
                        : translator(messages.pending);
                }
            }

            return claimType;
        }
        return translator(messages.pending);
    }, [aClaim, viewModelService, translator]);

    const overrides = {
        claimSummaryIcon: {
            icon: LobIconUtil.getWmicIcon(lobs[_.get(aClaim, 'lossType')])
        },
        claimTitle: {
            content: getClaimTitle()
        },
        claimNumberValueDiv: {
            content: aClaim.lossType === AUTO_LOSS_TYPE ? claimNumberLink : aClaim.claimNumber,
            'aria-hidden':  aClaim.lossType === AUTO_LOSS_TYPE ? 'false' : 'true'
        },
        claimNumberValueDivAria: {
            content: translator(messages.claimNumberAria, { claimNumber: aClaim.claimNumber }),
            visible: aClaim.lossType !== AUTO_LOSS_TYPE
        },
        policyNumberValueDiv: {
            content: aClaim.policyNumber
        },
        policyNumberValueDivAria: {
            content: translator(messages.policyNumberAria, { claimNumber: aClaim.claimNumber, policyNumber: aClaim.policyNumber })
        },
        claimStatusColumn: {
            visible: aClaim.claimState !== 'closed'
        },
        claimStatusValueDiv: {
            content: aClaim.claimState
        },
        claimStatusValueDivAria: {
            content: translator(messages.claimStatusAria, { claimNumber: aClaim.claimNumber, status: aClaim.claimState })
        },
        completedDateColumn: {
            visible: aClaim.claimState === 'closed'
        },
        completedDateValueDiv: {
            content: intl.formatDate(new Date(aClaim.completedDate_WMIC), { year: 'numeric', month: '2-digit', day: '2-digit' })
        },
        completedDateValueDivAria: {
            content: translator(messages.completedDateAria, {
                claimNumber: aClaim.claimNumber,
                completedDate: intl.formatDate(new Date(aClaim.completedDate_WMIC), {
                    year: 'numeric', month: 'short', day: 'numeric'
                })
            })
        },
        dateOfIncidentValueDiv: {
            content: intl.formatDate(new Date(aClaim.lossDate), { year: 'numeric', month: '2-digit', day: '2-digit' })
        },
        dateOfIncidentValueDivAria: {
            content: translator(messages.dateOfIncidentAria, {
                claimNumber: aClaim.claimNumber,
                incidentDate: intl.formatDate(new Date(aClaim.lossDate), {
                    year: 'numeric', month: 'short', day: 'numeric'
                })
            })
        },
        vehicleColumn: {
            visible: aClaim.lossType === AUTO_LOSS_TYPE
        },
        vehicleValueDiv: {
            content: vehicleDisplayValue
        },
        vehicleValueDivAria: {
            content: readableVehicleDisplayValue
        },
        incidentLocationColumn: {
            visible: aClaim.lossType === 'PR' && isLossLocationEmpty(aClaim.lossLocation_WMIC)
        },
        incidentLocationValueDiv: {
            content: formatIncidentLocation(aClaim.lossLocation_WMIC)
        },
        incidentLocationValueDivAria: {
            content: translator(messages.incidentLocationAria, {
                claimNumber: aClaim.claimNumber, locationDescription: aClaim.lossLocation_WMIC?.displayName
            })
        },
        claimTypeValueDiv: {
            content: claimTypeDisplayName
        },
        claimTypeValueDivAria: {
            content: translator(messages.claimTypeAria, { claimNumber: aClaim.claimNumber, claimType: claimTypeDisplayName })
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={aClaim}
        />
    );
};

ClaimSummaryBox.propTypes = {
    data: PropTypes.shape({})
};

ClaimSummaryBox.defaultProps = {
    data: {}
};

export default ClaimSummaryBox;
