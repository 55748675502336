import { defineMessages } from 'react-intl';

export default defineMessages({

    policyAddress: {
        id: 'wmic.policy-change.component.address-pa.Policy Address',
        defaultMessage: 'Policy Address'
    },

    editButton: {
        id: 'wmic.policy-change.component.address-pa.Edit',
        defaultMessage: 'Edit'
    },

    cancel: {
        id: 'wmic.policy-change.component.address-pa.Cancel',
        defaultMessage: 'Cancel'
    }

});
