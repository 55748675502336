import { defineMessages } from 'react-intl';

export default defineMessages({
    AreYouSureYoudLikeToCancelTheFollowingPayment: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Are you sure youd like to cancel the following payment?',
        defaultMessage: 'Are you sure you’d like to cancel the following payment?'
    },
    OneTimeBankWithdrawal: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.One Time Bank Withdrawal',
        defaultMessage: 'One Time Bank Withdrawal'
    },
    Amount: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Amount',
        defaultMessage: 'Amount: '
    },
    ConfirmCancellation: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Confirm Cancellation',
        defaultMessage: 'Confirm Cancellation'
    },
    YesCancelPayment: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Yes, Cancel Payment',
        defaultMessage: 'Yes, Cancel Payment'
    },
    NoKeepPayment: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.No, Keep Payment',
        defaultMessage: 'No, Keep Payment'
    },
    WeAreProcessingYourRequest: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.We are processing your request.',
        defaultMessage: 'We are processing your request.'
    },
    DoNotNavigateAwayFromThisPageOrCloseYourBrowser: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Do not navigate away from this page or close your browser.',
        defaultMessage: 'Do not navigate away from this page or close your browser.'
    },
    PleaseWait: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Please wait...',
        defaultMessage: 'Please wait...'
    },
    WeAreSorry: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.We are Sorry',
        defaultMessage: 'We\'re Sorry'
    },
    AnErrorOccurredWhileProcessingYourRequest: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.An error occurred while processing your request. Please close this window and try again.',
        defaultMessage: 'An error occurred while processing your request. Please close this window and try again.'
    },
    Close: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Close',
        defaultMessage: 'Close'
    },
    Success: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.Success',
        defaultMessage: 'Success'
    },
    YouHaveSuccessfullyCanceledThePaymentBelow: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.You have successfully canceled the payment below.',
        defaultMessage: 'You have successfully canceled the payment below.'
    },
    paymentEntryDescriptionAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.paymentEntryDescriptionAria',
        defaultMessage: 'Confirm Cancellation'
    },
    paymentProgressDescriptionAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.paymentProgressDescriptionAria',
        defaultMessage: ''
    },
    paymentSuccessDescriptionAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.paymentSuccessDescriptionAria',
        defaultMessage: 'Success'
    },
    paymentErrorDescriptionAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.paymentErrorDescriptionAria',
        defaultMessage: 'We\'re Sorry'
    },
    pendingPaymentAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.pendingPaymentAria',
        defaultMessage: 'You have successfully canceled the payment of {amount} currently scheduled for {eventDate}.'
    },
    anErrorPendingPaymentAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.anErrorPendingPaymentAria',
        defaultMessage: 'An error occurred while canceling the payment of {amount} currently scheduled for {eventDate}. Please close this window and try again.'
    },
    pendingPaymentEntryAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.pendingPaymentEntryAria',
        defaultMessage: 'Are you sure you’d like to cancel the the payment of {amount} currently scheduled for {eventDate}?'
    },
    AnErrorOccurredWhileProcessingYourRequestAria: {
        id: 'portal.views.modal.wmic-pending-payment-cancellation.An error occurred while processing your request. Please close this window and try again aria.',
        defaultMessage: 'An error occurred while processing your request to cancel the the payment of {amount} currently scheduled for {eventDate}. Please close this window and try again.'
    },
});
