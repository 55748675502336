// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICDataList__listViewHover:hover,.app__WMICDataList__selectedCard{background-color:var(--GW-HIGHLIGHT-COLOR-1)}.app__WMICDataList__listView,.app__WMICDataList__listViewHover{display:flex;flex-direction:column}.app__WMICDataList__listViewHover:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICDataList/WMICDataList.module.scss"],"names":[],"mappings":"AAAA,yEACI,4CAAA,CAGJ,+DACE,YAAA,CACA,qBAAA,CAKA,wCACE,cAAA","sourcesContent":[".selectedCard {\n    background-color: var(--GW-HIGHLIGHT-COLOR-1);\n}\n\n.listView {\n  display: flex;\n  flex-direction: column;\n}\n\n.listViewHover {\n  @extend .listView;\n  &:hover {\n    cursor: pointer;\n    @extend .selectedCard;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listViewHover": "app__WMICDataList__listViewHover",
	"selectedCard": "app__WMICDataList__selectedCard",
	"listView": "app__WMICDataList__listView"
};
export default ___CSS_LOADER_EXPORT___;
