import React, {useContext, useMemo} from 'react';
import _ from 'lodash';
import {WizardContext} from 'wmic-pe-portals-custom-wizard-react';
import {ViewModelForm} from '@xengage/gw-portals-viewmodel-react';
import {
    WMICAddressDetails,
    WMICContactDetails,
    WMICDataList,
    WMICPhoneDetails,
} from 'wmic-pe-components-platform-react';
import {CONSTANTS, PRODUCT} from 'wmic-pe-portals-utils-js';
import {TranslatorContext} from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';

import messages from './WMICPolicyInsuredDetailView.messages'
import metadata from './WMICPolicyInsuredDetailView.metadata.json5';

function WMICPolicyInsuredDetailView(props) {
    const {value: selectedInsured} = props;
    const {wizardData: {policyVM}} = useContext(WizardContext);
    const translator = useContext(TranslatorContext);

    const selectedInsuredWithSubTypeCorrection = useMemo(() => {
        if (_.get(selectedInsured, "subtype.value").toLowerCase() === CONSTANTS.Person.toLowerCase()) {
            _.set(selectedInsured, "subtype.value", CONSTANTS.Person)
            return selectedInsured;
        }

        return selectedInsured;
    }, [selectedInsured])

    const isPerson = selectedInsuredWithSubTypeCorrection.subtype.value === CONSTANTS.Person

    const addressHeader = useMemo(() => {
        return (isPerson) ? translator(messages.personAddressHeading) : translator(messages.companyAddressHeading)
    }, [selectedInsuredWithSubTypeCorrection, translator])

    const isCommercialPackage = _.get(policyVM, "product.productCode.value") === PRODUCT.COMMERCIAL_PACKAGE

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            readOnly: true,
        },
        addressDetailsComponent: {
            addressVM: _.get(selectedInsured, 'address', {}),
            hideBorder: true,
            hideButtons: true,
        },
        insuredAddressHeading: {
            title: addressHeader,
            visible: !isCommercialPackage
        },
        contactDetailsComponent: {
            contactVM: selectedInsuredWithSubTypeCorrection,
            showContactInfo: false,
            visible: isPerson
        },
        companyContactDetailsNumber: {
            visible: !isCommercialPackage
        },
        companyContactDetailsFrame: {
            visible: !isPerson
        },
        companyContactDetailsName: {
            parentNode: selectedInsured,
            value: _.get(selectedInsured, "displayName.value")
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICDataList,
            WMICAddressDetails,
            WMICContactDetails,
            WMICPhoneDetails,
            Accordion
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPolicyInsuredDetailView;
