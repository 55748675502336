// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.app__WMICHOConstructionDetails__tla-message-alert{margin-top:-1.65rem}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-ho-react/components/WMICHOConstructionDetails/WMICHOConstructionDetails.module.scss"],"names":[],"mappings":"AAAA,sDACE,mBAAA","sourcesContent":["div.tla-message-alert {\n  margin-top: -1.65rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tla-message-alert": "app__WMICHOConstructionDetails__tla-message-alert"
};
export default ___CSS_LOADER_EXPORT___;
