// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Summary__removeLinkStyle{color:var(--GW-LINK-COLOR);text-decoration:none}.app__Summary__quotedBoundContainer div[class*=jut__FieldLabel__left]{flex:2 1}.app__Summary__summaryDetailGrid{margin-bottom:var(--GW-LAYOUT-8)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/Endorsement/Summary/Summary.module.scss"],"names":[],"mappings":"AAAA,+BACI,0BAAA,CACA,oBAAA,CAIA,sEACI,QAAA,CAIR,iCACI,gCAAA","sourcesContent":[".removeLinkStyle {\n    color: var(--GW-LINK-COLOR);\n    text-decoration: none;\n}\n\n.quotedBoundContainer {\n    div[class*='jut__FieldLabel__left'] {\n        flex: 2 1;\n    }\n}\n\n.summaryDetailGrid {\n    margin-bottom: var(--GW-LAYOUT-8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeLinkStyle": "app__Summary__removeLinkStyle",
	"quotedBoundContainer": "app__Summary__quotedBoundContainer",
	"summaryDetailGrid": "app__Summary__summaryDetailGrid"
};
export default ___CSS_LOADER_EXPORT___;
