import React from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';
import { BUTTON_CONSTANTS } from 'wmic-pe-portals-utils-js';
import { Button } from '@jutro/legacy/components';


const WMICButton = ({ type, className: passedClassName, size, ...props }) => {
    const [buttonType, buttonModifier = BUTTON_CONSTANTS.BUTTON_DEFAULT_MODIFIER] = type.split('-');
    const buttonClass = `${BUTTON_CONSTANTS.BUTTON_BASE_CLASSNAME}-${buttonType}`;
    const buttonModifierClass = buttonModifier ? `${BUTTON_CONSTANTS.BUTTON_BASE_CLASSNAME}-${buttonModifier}` : '';

    return (
        <Button
            {...props}
            size={size}
            type={buttonModifier}
            className={cx(buttonClass, buttonModifierClass, passedClassName)}
        />
    );
};

WMICButton.propTypes = {
    type: PropTypes.oneOf(BUTTON_CONSTANTS.BUTTON_ALLOWED_TYPES),
    size: PropTypes.oneOf(BUTTON_CONSTANTS.BUTTON_ALLOWED_SIZES)
};

WMICButton.defaultProps = {
    type: BUTTON_CONSTANTS.BUTTON_DEFAULT_TYPE,
    size: BUTTON_CONSTANTS.BUTTON_DEFAULT_SIZE
}

export default WMICButton;
