import React, { useCallback, useContext, useState } from 'react';
import { WMICButton } from 'wmic-components-platform-react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { EmailQuoteService } from 'gw-capability-quoteandbind';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { WMICModal } from 'gw-capability-quoteandbind-common-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from './WMICMailQuote.messages';
import styles from './WMICMailQuote.module.scss';

function WMICMailQuote({isStale, calculatingQuote, classNames}) {
    const modalApi = useModal();
    const translator = useTranslator();
    const wizardContext = useContext(WizardContext);
    const { wizardSnapshot: submissionVM } = wizardContext;
    const [mailButtonDisabled, setMailButtonDisabled] = useState(false);

    const showEmailAddress = useCallback(() => {
        return !!_.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');
    }, [submissionVM]);

    const emailQuote = useCallback(() => {
        const quoteID = _.get(submissionVM, 'quoteID.value');
        const sessionUUID = _.get(submissionVM, 'sessionUUID.value');
        const emailAddress = _.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');

        const emailInfo = {
            quoteID,
            sessionID: sessionUUID
        };

        EmailQuoteService.emailQuote(emailInfo)
            .then(() => {
                setMailButtonDisabled(true);
                modalApi.showModal(
                    <WMICModal
                        title={messages.quoteMailed}
                        message={translator(messages.emailQuoteDescription, { emailAddress })}
                        iconClass="error-text fa-3x fa fa-exclamation-circle"
                    />
                ).then(_.noop);
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.error,
                    message: messages.cantSendQuoteDetailsAtThisTime,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            });
    }, [submissionVM, translator]);

    const shouldShowEmailButton = showEmailAddress();

    if (!shouldShowEmailButton) {
        return null;
    }
    // TODO change icon after cortina upgrade: fa fa-envelope-o
    return (
        <WMICButton
            type="outlined"
            icon="cust-envelope-regular"
            onClick={emailQuote}
            disabled={mailButtonDisabled || isStale || calculatingQuote}
            className={`${styles.wmicMailQuoteButton} ${classNames} ${styles.wmicEmailBtn}`}
            iconClassName={styles.emailButtonIconClass}
        >
            {translator(messages.emailQuote)}
        </WMICButton>
    );
}

export default WMICMailQuote;