/* eslint-disable max-len */
import React, { useState } from 'react';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Tooltip } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import metadata from './WMICClickableIconTooltipComponent.metadata.json5';
import messages from './WMICClickableIconTooltipComponent.messages';
import styles from './WMICClickableIconTooltipComponent.module.scss';


import { Link } from '@jutro/router';


const WMICClickableIconTooltipComponent = (props) => {
    const {
        visible: isVisible,
        placement,
        tooltipAriaLabel,
        tooltipMessageAriaLabel
    } = props;
    const translator = useTranslator();
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const placementAdaptive = isMobile ? 'top' : (placement || 'right');
    const [showTooltip, setShowTooltip] = useState(undefined);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const closeTooltip = () => {
        setShowTooltip(false);
    }

    const openTooltip = () => {
        if (showTooltip === false) {
            setShowTooltip(true);
            setShowTooltip(undefined);
        }
    }

    const getTooltipMessage = (message) => (
            <div role="alert">
                <p aria-label={tooltipMessageAriaLabel || ''} aria-live='assertive'>
                    {message}
                    <div className={styles.tooltipContentLink} />
                </p>
            </div>
        );

    const clickableIconTooltipMetadata = (componentProps) => (
            <Tooltip
                animation={componentProps.animation ? componentProps.animation : 'fade'}
                content={getTooltipMessage(componentProps.tooltipMessage)}
                delay={[
                    0,
                    20
                ]}
                duration={[
                    300,
                    300
                ]}
                flipBehavior={[
                    'right',
                    'bottom',
                    'top',
                    'left',
                    'right'
                ]}
                followCursor={false}
                hideOnClick={false}
                id={componentProps.tooltipId || 'tooltipId'}
                placement={placementAdaptive}
                showOnInit={false}
                sticky
                trigger="mouseenter focus"
                visible={showTooltip}
            >
                <span className={styles['icon-wrapper']} tabIndex="0">
                    <i className="fas fa-question-circle" />
                </span>
            </Tooltip>
        );

   
    const overrides = {
        componentContentWrapper: {
            content: clickableIconTooltipMetadata(props),
            visible: isVisible,
            'aria-label': tooltipAriaLabel || ''
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrides}
            model={props}
        />
    );
};

WMICClickableIconTooltipComponent.propTypes = {
    visible: PropTypes.bool,
    tooltipMessage: PropTypes.string,
    tooltipId: PropTypes.string
};

WMICClickableIconTooltipComponent.defaultProps = {
    visible: true,
    tooltipMessage: 'default message',
    tooltipId: 'defaultTooltipId'
};


export default WMICClickableIconTooltipComponent;
