import messages from './Models.messages';

export default class ChangeAddressModel {
    constructor({
        type, oldAddress, newAddress, mailingPurpose
    }) {
        this.type = type;
        this.oldAddress = oldAddress;
        this.newAddress = newAddress;
        this.mailingPurpose = mailingPurpose || false;
    }

    toString(translator) {
        if (!this.valid()) {
            throw new Error(translator(messages.oldAndNewAddressRequired));
        }

        let text;

        if (this.oldAddress === undefined) {
            if (this.type === 'auto') {
                text = `
Garaging Address:
${this.newAddress}
\n`;
            } else if (this.type === 'home') {
                text = `
Property Address:
${this.newAddress}
\n`;
            }
            return text;
        }

        text = `Change of Address:
Here is the old address information:
${this.oldAddress}

Here is the new address information:
${this.newAddress}
`;

        if (this.mailingPurpose !== undefined) {
            if (this.type === 'auto') {
                text += `My vehicles are typically kept at this address: ${this.mailingPurpose ? 'Yes' : 'No'}\n`;
            } else if (this.type === 'home') {
                text += `Is your property address the same as your policy address: ${this.mailingPurpose ? 'Yes' : 'No'}\n`;
            }
        }

        return text;
    }

    valid() {
        const required = [this.newAddress];
        return required.every((v) => v && v.valid());
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangeAddressRequest);
    }
}