// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICDataTableTab__wmicDataTableWrapper{margin:var(--GW-SPACING-4) var(--GW-SPACING-4);position:relative}.app__WMICDataTableTab__wmicDataTableWrapper .app__WMICDataTableTab__wmicTableLoadingText{background-color:hsla(0,0%,100%,.75);display:none;inset:0;position:absolute;text-align:center;z-index:var(--GW-LOADER-Z-INDEX)}.app__WMICDataTableTab__wmicDataTableWrapper .app__WMICDataTableTab__wmicTableLoadingText.app__WMICDataTableTab__isLoading{align-items:center;display:flex;justify-content:center}.app__WMICDataTableTab__wmicDataTableWrapper .timeRemainingWarning{color:var(--GW-COLOR-ERROR)}div[class*=pagination] a[class*=paginationMenuItem][value=All]{display:none}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-react/LandingPage/components/WMICDataTableTab/WMICDataTableTab.module.scss"],"names":[],"mappings":"AAAA,6CACI,8CAAA,CACA,iBAAA,CAEA,0FAKI,oCAAA,CAJA,YAAA,CAEA,OAAA,CADA,iBAAA,CAEA,iBAAA,CAEA,gCAAA,CAEA,2HAEI,kBAAA,CADA,YAAA,CAEA,sBAAA,CAIR,mEACI,2BAAA,CAKR,+DACI,YAAA","sourcesContent":[".wmicDataTableWrapper {\n    margin: var(--GW-SPACING-4) var(--GW-SPACING-4);\n    position: relative;\n\n    .wmicTableLoadingText {\n        display: none;\n        position: absolute;\n        inset: 0;\n        text-align: center;\n        background-color: rgba(255,255,255,0.75);\n        z-index: var(--GW-LOADER-Z-INDEX);\n\n        &.isLoading {\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n    }\n\n    :global(.timeRemainingWarning) {\n        color: var(--GW-COLOR-ERROR);\n    }\n}\n\n/* This removed the \"Show all\" selection from the pagination dropdown*/\ndiv[class*=\"pagination\"] a[class*=\"paginationMenuItem\"][value=\"All\"] {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicDataTableWrapper": "app__WMICDataTableTab__wmicDataTableWrapper",
	"wmicTableLoadingText": "app__WMICDataTableTab__wmicTableLoadingText",
	"isLoading": "app__WMICDataTableTab__isLoading"
};
export default ___CSS_LOADER_EXPORT___;
