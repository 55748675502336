import React from 'react';
import { ModalNext } from '@jutro/components';
import { Flex } from '@jutro/layout';
import PropTypes from 'prop-types';
import htmlParser from 'html-react-parser';
import { intlMessageShape } from '@jutro/prop-types';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import WMICModalFooter from './WMICModalFooter';
import styles from './WMICModal.module.scss';

/**
 * Modal is wrapping ModalNext - so also has access to it's props:
 * isOpen,
 * onAfterOpen,
 * onAfterClose,
 * onRequestClose,
 * closeTimeoutMS,
 * contentLabel,
 * overlayClassName,
 * className,
 * shouldFocusAfterRender,
 * shouldCloseOnOverlayClick,
 * shouldCloseOnEsc,
 * shouldReturnFocusAfterClose,
 * parentSelector,
 * ariaLabelledby,
 * ariaDescribedby,
 * @returns {React.Element}
 */
const WMICModal = ({
    onResolve,
    onReject,
    cancelButtonText,
    confirmButtonText,
    id,
    modalBody,
    modalContainerClassname,
    modalFooter,
    modalHeader,
    onCancelCallback,
    onConfirmCallback,
    cancelButtonDisabled,
    confirmButtonDisabled,
    cancelButtonType = 'outlined',
    confirmButtonType = 'primary',
    ...rest
}) => {
    const translator = useTranslator();
    const renderHeader = () => typeof modalHeader === 'string' ? (
            <div id={`modal-header-${id}`} className={styles.headerContainer}>
                <h1>{translator(modalHeader)}</h1>
            </div>
        ) : modalHeader;
        
    const renderBody = () => <div id={`modal-body-${id}`} className={styles.bodyContainer}>{typeof modalBody === 'string' ? htmlParser(translator(modalBody)) : modalBody}</div>;
    const renderFooter = () => {
        const footerButtonActions = {
            cancel: {
                onClick: () => {
                    if (onCancelCallback) {
                        onCancelCallback();
                    }
                    onReject();
                },
                hidden: !onCancelCallback,
                disabled: cancelButtonDisabled,
                content: cancelButtonText,
                type: cancelButtonType,
            },
            confirm: {
                onClick: () => {
                    if (onConfirmCallback) {
                        onConfirmCallback();
                    }
                    onResolve();
                },
                hidden: !onConfirmCallback,
                disabled: confirmButtonDisabled,
                content: confirmButtonText,
                type: confirmButtonType,
            }
        };

        return modalFooter || (
            <WMICModalFooter
                actions={footerButtonActions}
                id={id}
            />
        );
    };
    
    const handleClose = () => {
        onReject();
    };

    const renderModal = () => (
            <ModalNext className={cx(styles.wawaModal, modalContainerClassname)} 
                onRequestClose={handleClose} 
                shouldCloseOnEsc
                {...rest}>
                <Flex direction="column">
                    {renderHeader()}
                    {renderBody()}
                    {renderFooter()}
                </Flex>
            </ModalNext>
        );

    return renderModal();
};

WMICModal.propTypes = {
    /**
     * Body can either be a string or a react element if formatting needed
     */
    modalBody: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * Cancel button text which defaults to 'Cancel' if not given
     */
    cancelButtonText: PropTypes.oneOfType([PropTypes.string, intlMessageShape]),
    /**
     * Confirm button text which defaults to 'Submit' if not given
     */
    confirmButtonText: PropTypes.oneOfType([PropTypes.string, intlMessageShape]),
    /**
     * Custom footer can be supplied - otherwise default is used
     */
    modalFooter: PropTypes.node,
    /**
     * Modal id
     */
    id: PropTypes.string.isRequired,
    /**
     * Callback for cancel button onClick
     */
    onCancelCallback: PropTypes.func,
    /**
     * Callback for confirm button onClick - is required as modal does not control it's isOpen state
     */
    onConfirmCallback: PropTypes.func,
    /**
     * Header for the modal can be either a string or react element
     */
    modalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * Should the cancel button be rendered in a disabled state
     */
    cancelButtonDisabled: PropTypes.bool,
    /**
     * Should the confirm button be rendered in a diabled state
     */
    confirmButtonDisabled: PropTypes.bool,
};

export default WMICModal;
