/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';

const WMICSuccessPaymentModal = (props) => {
    const { fetchBillingData, onReject, onResolve, saveBankAccount, isBankAccountSaved, isOpenFromOverdueContainer } = props;
    const translator = useTranslator();

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(messages.paymentSuccessful)}</h3>
            <ModalBody>
                <div className={styles.successFailureModalBody}>
                    {!saveBankAccount && (
                        <div>
                            <p>{translator(messages.thankYouForYourPaymentYouWillReceiveYourReceiptViaEmailShortly)}</p>
                            <p>{translator(messages.pleaseAllowUpTo24HoursForYourPaymentToAppearOnYourBankStatement)}</p>
                        </div>
                    )}
                    {saveBankAccount && (
                        <div>
                            <p>
                                {translator(messages.thankYouForYourPaymentYouWillReceiveYourReceiptViaEmailShortly)}
                                {translator(messages.pleaseAllowUpTo24HoursForYourPaymentToAppearOnYourBankStatement)}
                            </p>
                            <br />
                            {isBankAccountSaved && (
                                <p>{translator(messages.bankingInformationSaved)}</p>
                            )}
                            {!isBankAccountSaved && (
                                <p>
                                    {translator(messages.bankUnableToBeSaved)}
                                    {translator(messages.contactUsForAssistance)}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => {
                        onResolve();
                        if (isOpenFromOverdueContainer) localStorage.setItem('overduePoliciesNotShown', JSON.stringify(null));
                        if (fetchBillingData) fetchBillingData();
                    }}
                    type="primary"
                >
                    {translator(messages.ok)}
                </WMICButton>
            </ModalFooter>
        </Fragment>
    );
};

WMICSuccessPaymentModal.propTypes = {
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    saveBankAccount: PropTypes.bool.isRequired,
    isBankAccountSaved: PropTypes.bool.isRequired,
    fetchBillingData: PropTypes.func,
    isOpenFromOverdueContainer: PropTypes.bool,
};

WMICSuccessPaymentModal.defaultProps = {
    isOpenFromOverdueContainer: false
};

export default WMICSuccessPaymentModal;
