import { WMICRPCUtil } from 'wmic-pe-portals-utils-js';

const NUMBER_OF_STORIES_DEFAULT = 9999;
const FLOOR_OF_UNIT_DEFAULT = 9999;
const MIN_NUMBER_OF_STORIES = 1;
const MAX_NUMBER_OF_STORIES = 99;
const MIN_FLOOR_OF_UNIT = 2;
const MAX_FLOOR_OF_UNIT = 99;

const getPrimaryWings = (dwellingWings) => dwellingWings.find((dwellingWing) => dwellingWing.primaryWing);

const isAllowedDefaultNumberOfStories = (prevDwelling, prevNumberOfStories, jurisdiction, rateAsOfDate, dwellingType) => prevDwelling
        && (WMICRPCUtil.firstYearEligibleRPC1378RenewalDefaulting(jurisdiction, rateAsOfDate, prevDwelling.prevRateAsOfDate, dwellingType)
            || WMICRPCUtil.firstYearEligibleRPC1379RenewalDefaulting(jurisdiction, rateAsOfDate, prevDwelling.prevRateAsOfDate, dwellingType)
            || prevNumberOfStories === NUMBER_OF_STORIES_DEFAULT);

const getPreviousConstructionDetails = (dwellingWings) => dwellingWings ? getPrimaryWings(dwellingWings).constructionDetail : undefined;

const isValidNumberOfStoriesInRange = (numberOfStories) => numberOfStories
        && numberOfStories >= MIN_NUMBER_OF_STORIES
        && numberOfStories <= MAX_NUMBER_OF_STORIES
        && numberOfStories % 1 === 0;

const isValidNumberOfStories = (prevDwelling, numberOfStories, prevNumberOfStories, jurisdiction, rateAsOfDate, dwellingType) => isAllowedDefaultNumberOfStories(prevDwelling, prevNumberOfStories, jurisdiction, rateAsOfDate, dwellingType)
        ? isValidNumberOfStoriesInRange(numberOfStories) || numberOfStories === NUMBER_OF_STORIES_DEFAULT
        : isValidNumberOfStoriesInRange(numberOfStories);

const isValidFloorOfUnitInRange = (floorOfUnit) => floorOfUnit
        && floorOfUnit >= MIN_FLOOR_OF_UNIT
        && floorOfUnit <= MAX_FLOOR_OF_UNIT;

const isAllowedDefaultFloorOfUnit = (prevDwelling, prevFloorOfUnit, jurisdiction, rateAsOfDate, dwellingType) => prevDwelling
        && (WMICRPCUtil.firstYearEligibleRPC1378RenewalDefaulting(jurisdiction, rateAsOfDate, prevDwelling.prevRateAsOfDate, dwellingType)
            || WMICRPCUtil.firstYearEligibleRPC1379RenewalDefaulting(jurisdiction, rateAsOfDate, prevDwelling.prevRateAsOfDate, dwellingType)
            || prevFloorOfUnit === FLOOR_OF_UNIT_DEFAULT);

const isValidFloorOfUnit = (prevDwelling, floorOfUnit, prevFloorOfUnit, jurisdiction, rateAsOfDate, dwellingType) => isAllowedDefaultFloorOfUnit(prevDwelling, prevFloorOfUnit, jurisdiction, rateAsOfDate, dwellingType)
        ? isValidFloorOfUnitInRange(floorOfUnit) || floorOfUnit === FLOOR_OF_UNIT_DEFAULT
        : isValidFloorOfUnitInRange(floorOfUnit);

export default {
    validateNumberOfUnits: (jurisdiction, periodStartDate) => {
        let result;

        if (periodStartDate) {
            const isEffective = WMICRPCUtil.getIsRPCEffective(jurisdiction.code, periodStartDate, 1218);
            if (isEffective) {
                result = 9999;
            } else {
                result = 9999999999;
            }
        }

        return result;
    },
    isNumberStoriesMinimumValid: (numberOfStories, jurisdiction, periodStartDate, dwellingType) => {
        if (periodStartDate) {
            if (numberOfStories < 1) {
                return !(WMICRPCUtil.getIsAnyOfRPCsEffectiveForRiskType(jurisdiction, periodStartDate, dwellingType, '1378', '1379'));
            }
        }
        return true;
    },
    isNumberStoriesMaximumValid: (numberOfStories, jurisdiction, periodStartDate, dwellingType) => {
        if (periodStartDate) {
            if (numberOfStories > 99) {
                return !(WMICRPCUtil.getIsAnyOfRPCsEffectiveForRiskType(jurisdiction, periodStartDate, dwellingType, '1378', '1379'));
            }
        }
        return true;
    },
    isNumberStoriesDecimalValueValid: (numberOfStories, jurisdiction, periodStartDate, dwellingType) => {
        if (periodStartDate) {
            if (numberOfStories % 1 !== 0) {
                return !(WMICRPCUtil.getIsAnyOfRPCsEffectiveForRiskType(jurisdiction, periodStartDate, dwellingType, '1378', '1379'));
            }
        }
        return true;
    },
    isRelationshipBetweenNumberStoriesAndFloorValid: (numberOfStories, floors, jurisdiction, periodStartDate, dwellingType) => {
        if (periodStartDate && numberOfStories && floors) {
            if (numberOfStories < floors) {
                return !(WMICRPCUtil.getIsAnyOfRPCsEffectiveForRiskType(jurisdiction?.code, periodStartDate, dwellingType?.code, '1378', '1379'));
            }
        }
        return true;
    },
    shouldValidateDwellingType: (dwellingType, residenceType, coverageLimitation, formType) => {
        if (!residenceType || !dwellingType) {
            return true;
        }

        if (['Mobile', 'Manufactured_wmic', 'Minihome_wmic', 'MobileMinihome_WMIC'].includes(residenceType.code)) {
            return false;
        }

        if (['Mobile_wmic', 'HO4', 'HO6'].includes(dwellingType.code)) {
            return false;
        }

        if (dwellingType.code === 'Basic_wmic') {
            return coverageLimitation && coverageLimitation.code === 'dwelling';
        }

        if (dwellingType.code === 'Seasonal_wmic') {
            return coverageLimitation && coverageLimitation.code === 'dwelling'
                && (!formType || !['broad', 'basic'].includes(formType.code));
        }

        return true;
    },
    floorOfUnitPassesValidation: (prevDwelling, floorOfUnit, jurisdiction, rateAsOfDate, dwellingType) => {
        const prevConstructionDetails = prevDwelling ? getPreviousConstructionDetails(prevDwelling.dwellingWings) : undefined;
        const prevFloorOfUnit = prevConstructionDetails ? prevConstructionDetails.floor : undefined;
        return WMICRPCUtil.getIsAnyOfRPCsEffectiveForRiskType(jurisdiction?.code, rateAsOfDate, dwellingType?.code, '1378', '1379')
            ? isValidFloorOfUnit(prevDwelling, floorOfUnit, prevFloorOfUnit, jurisdiction, rateAsOfDate, dwellingType)
            : true;
    },
    numberOfStoriesPassesValidation: (prevDwelling, numberOfStories, jurisdiction, rateAsOfDate, dwellingType) => {
        const prevConstructionDetails = prevDwelling ? getPreviousConstructionDetails(prevDwelling.dwellingWings) : undefined;
        const prevNumberOfStories = prevConstructionDetails ? prevConstructionDetails.storiesNumber : undefined;
        return WMICRPCUtil.getIsAnyOfRPCsEffectiveForRiskType(jurisdiction?.code, rateAsOfDate, dwellingType?.code, '1378', '1379')
            ? isValidNumberOfStories(prevDwelling, numberOfStories, prevNumberOfStories, jurisdiction, rateAsOfDate, dwellingType)
            : true;
    },
    isElevationRequired: () => false
};
