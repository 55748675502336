import { defineMessages } from 'react-intl';

export default defineMessages({
    PaClaimNumber: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.PaClaim #',
        defaultMessage: ' Automobile Claim #{claimNumber}'
    },
    ClaimMenu: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.ClaimMenu',
        defaultMessage: 'Claim Menu'
    },
    ClaimAndVehicleInformation: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Claim & Vehicle Information',
        defaultMessage: 'Claim & Vehicle Information'
    },
    AdjusterInformation: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Adjuster Information',
        defaultMessage: 'Adjuster Information'
    },
    EstimateOrRepairFacilityInformation: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Estimate or Repair Facility Information',
        defaultMessage: 'Estimate or Repair Facility Information'
    },
    AdditionalInsuredVehiclesAndDrivers: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Additional Insured Vehicles and Drivers',
        defaultMessage: 'Additional Insured Vehicles and Drivers'
    },
    LocationAndIncidentInformation: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Location & Incident Information',
        defaultMessage: 'Location & Incident Information'
    },
    ClaimsPaymentHistory: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Claims Payment History',
        defaultMessage: 'Claims Payment History'
    },
    RentalVehicleInformation: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Rental Vehicle Information',
        defaultMessage: 'Rental Vehicle Information'
    },
    claimDocuments: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Claim Documents',
        defaultMessage: 'Claim documents'
    },
    viewDocument: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.View Document',
        defaultMessage: 'View Document'
    },
    benefitsletter: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter',
        defaultMessage: 'Benefits Letter'
    },
    benefitsleterrumpd: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter umpd',
        defaultMessage: 'Benefits Letter'
    },
    benefitsletternocollissionorcomp: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter no collission',
        defaultMessage: 'Benefits Letter'
    },
    benefitsletterorglassloss: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter glass loss',
        defaultMessage: 'Benefits Letter'
    },
    oregonbenefitsletter: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter oregon',
        defaultMessage: 'Benefits Letter'
    },
    oregonbenefitsletterumpd: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter oregon umpd',
        defaultMessage: 'Benefits Letter'
    },
    oregonbenefitsletterdoctor: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter oregon doctor',
        defaultMessage: 'Benefits Letter'
    },
    oregonbenefitsletterglassloss: {
        id: 'claim.views.claim-detail.claim.views.wmic-claim-detail.Benefits Letter oregon glass loss',
        defaultMessage: 'Benefits Letter'
    }
});
