/* eslint-disable no-warning-comments */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { DateField, ToggleField, LookupField, Icon, InputMaskField } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BreakpointTrackerContext, Flex } from '@jutro/layout';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { MASTER_INPUT, DeviceBreakpoint, Position, CONSTANTS, JobType, WMICLogger, WMICBMSIUtil } from 'wmic-pe-portals-utils-js';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { messages } from './WMICMasterInput.messages';
import styles from './WMICMasterInput.module.scss';
import useDefaultedProperties from '../hooks/useDefaultedProperties';

import {
    TextAreaField,
    CheckboxField,
    CheckboxGroupField,
    RadioButtonField,
    InputField,
    CurrencyField,
    DropdownSelectField,
    InputNumberField,
} from '@jutro/legacy/components';

const CONTROL_TYPES = {
    DROPDOWN_SELECT: 'dropdown_select',
    TEXT_INPUT: 'text_input',
    NUMBER_INPUT: 'number_input',
    DATE: 'date',
    CHECKBOX: 'checkbox',
    TOGGLE: 'toggle',
    RADIO: 'radio',
    CHECKBOX_GROUP: 'checkbox_group',
    YEAR_INPUT: 'year',
    CURRENCY: 'currency_field',
    SEARCH: 'typeahead',
    TEXT_AREA: 'text_area'
}
const logDebugMessages = (...msgs) => {
    if (WMICLogger.isDebugEnabled()) {
        console.group("%cWMICMasterInput Error", 'color:orangered;font-weight:900;font-size:1.25em;text-decoration:underline;')
        Array.prototype.map.call(msgs, (message) => {
            WMICLogger.debug(message);
        })
        console.groupEnd();
    }
};
const logErrorMessageQuietly = (message) => {
    WMICLogger.debug(`%cWMICMasterInput Error\n${message}`);
};

const getControlType = (currentNode, vmControlType, passedControlType) => {
    const isCurrency = (node) => {
        const validationRule = _.find(node._metadataInfo.elementMetadata._unnamedArray, (rule) => rule.value?.expression?.name === 'isCurrency')
        return validationRule !== undefined;
    }
    if (passedControlType === "typeahead") { return CONTROL_TYPES.SEARCH; }
    if (vmControlType === 'text' && passedControlType === 'text_area') { return CONTROL_TYPES.TEXT_AREA; }
    if (passedControlType === 'checkbox_group') { return CONTROL_TYPES.CHECKBOX_GROUP; }
    if (((vmControlType === 'boolean' || MASTER_INPUT.TOGGLE_TYPES.includes(passedControlType))
        && !MASTER_INPUT.CHECKBOX_TYPES.includes(passedControlType) && !MASTER_INPUT.RADIO_TYPES.includes(passedControlType))) { return CONTROL_TYPES.TOGGLE; }
    if (MASTER_INPUT.RADIO_TYPES.includes(passedControlType)) { return CONTROL_TYPES.RADIO; }
    if ((vmControlType === 'typelist' || MASTER_INPUT.DROPDOWN_TYPES.includes(passedControlType))) { return CONTROL_TYPES.DROPDOWN_SELECT; }
    // there is no date control type so we need to check the currentNode object
    if (vmControlType === 'text' && !('day' in currentNode && 'month' in currentNode) && !('amount' in currentNode && 'currency' in currentNode)) { return CONTROL_TYPES.TEXT_INPUT; }
    if (vmControlType === 'text' && 'day' in currentNode && 'month' in currentNode) { return CONTROL_TYPES.DATE; }
    if (vmControlType === 'boolean' && MASTER_INPUT.CHECKBOX_TYPES.includes(passedControlType)) { return CONTROL_TYPES.CHECKBOX; }
    if (vmControlType === 'text' && 'amount' in currentNode && 'currency' in currentNode) { return CONTROL_TYPES.CURRENCY; }
    if (vmControlType === 'number') { return isCurrency(currentNode) ? CONTROL_TYPES.CURRENCY : CONTROL_TYPES.NUMBER_INPUT; }
}

const WMICMasterInput = (props) => {
    const {
        id,
        parentNode,
        path,
        twoThirdsLabel,
        availableValues: pAvailableValues,
        label: pLabel,
        forceLabel,
        readOnly: pReadOnly,
        required: pRequired,
        tooltip: pTooltip,
        controlType: pControlType,
        onValueChange: pOnValueChange,
        onPostOnChange: pOnPostOnChange,
        labelPosition: pLabelPosition,
        showRequired,
        showFractions,
        secondaryLabel,
        value
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const { RenewalService } = useDependencies('RenewalService');
    const { EndorsementService } = useDependencies('EndorsementService');

    // needed for PostOnChange
    const { wizardData: jobVM, updateWizardData: updateJobVM } = useContext(WizardContext) || {};
    const isPolicyView = jobVM?.policyVM?._dtoName === 'wmic.edge.ca.capabilities.gateway.policy.dto.PolicyDTO';
    const jobType = _.get(jobVM , 'baseData.jobType.value.code');
    const currentNode = _.get(parentNode, path);
    const [pristine, setPristine] = useState(true);
    const [originalValue, setOriginalValue] = useState(_.get(currentNode, 'value'));
    const hasViewModel = useMemo(() => {
        if ((!_.isObject(currentNode) && pControlType === undefined)
            || (_.isObject(currentNode) && currentNode.aspects === undefined)) {
            logDebugMessages(`NOT HOOKED UP TO VM for ${path}`, `Element ID: ${id}`);
            return false;
        }
        return true;
    }, [currentNode, id, pControlType, path])
    const { setWizardLoading } = useWizardModals();

    const controlType = useMemo(
        () => getControlType(currentNode, _.get(currentNode, 'aspects.inputCtrlType'), _.toLower(pControlType))
    , [currentNode, pControlType]);

    const maybeRemoveFieldValue = (isHidden) => {
        const nullWhenBlank = _.get(currentNode, 'aspects.ocularPresent') ? _.get(currentNode, 'aspects.nullWhenBlank') : true;
        // TODO - we need to tap into the isLoading of the VM and only perform this after loading (e.g. onReady)
        if (isHidden && nullWhenBlank) {// && scope.nullWhenBlankOverride === undefined) {
            _.set(currentNode, 'value', undefined);
            logErrorMessageQuietly(`SETTING TO NULL ${path}`);
        }
    };

    const isBMSI = _.get(jobVM, "isFromBMS_WMIC.value");
    let nodeDefaultedProperties;
    let isNodeDefaulted = false;
    let confirmed = false;
    if (isBMSI) {
        nodeDefaultedProperties = useDefaultedProperties(currentNode);
        isNodeDefaulted = nodeDefaultedProperties.isDefaulted;
        confirmed = isNodeDefaulted && nodeDefaultedProperties.isAccepted;
    }

    const getLabel = () => {
        if (hasViewModel) {
            const aspect = _.get(currentNode, 'aspects.display');
            const exprDisplay = _.get(currentNode, 'aspects.exprDisplay');
            // eslint-disable-next-line no-nested-ternary
            return forceLabel || exprDisplay || aspect || pLabel;
        }
        return pLabel;
    }

    const getVisible = () => {
        if (!hasViewModel) { return }

        // TODO: look into issues. Updating this to return true if the ocular element isn't present.
        // We need to look at this in the future to make sure it will work for Augments as well as QuestionSets
        const aspect = _.get(currentNode, 'aspects.ocular');
        if (aspect !== undefined) {
            maybeRemoveFieldValue(!aspect);
            return aspect;
        }
        return true;
    }

    const getEditable = () => {
        // since the flag is readOnly, we have to reverse the passed in version
        if (hasViewModel) {
            const aspect = _.get(currentNode, 'aspects.editable');
            const annotationPresent = _.get(currentNode, 'aspects.editablePresent');
            // FE readonly logic should have higher precedence if field is editable
            if (pReadOnly !== undefined) {
                return pReadOnly
            }

            // we only care about the editable aspect if it was annotated that way on the DTO, so we check for the presence
            if (aspect !== undefined && annotationPresent) {
                return !aspect
            }
        }

        return pReadOnly;
    }

    const getRequired = () => {
        if (hasViewModel) {
            const aspect = _.get(currentNode, 'aspects.required');
            return aspect || pRequired;
        }
        return pRequired;
    }

    const getTooltip = () => {
        if (hasViewModel) {
            const aspect = _.get(currentNode, 'aspects.tooltip');
            return aspect || pTooltip
        }
        return pTooltip;
    }

    const confirmDefaultedValue = () => {
        confirmed = true;
        _.set(jobVM.defaultedProperties_WMIC.value[nodeDefaultedProperties?.defaultedPropertiesArrayIndex], 'status', CONSTANTS.REVIEWED);
        updateJobVM(jobVM);
    };

    const updateViewModel = useCallback(() => {
        if (hasViewModel) {
            // check to see if we have a BE PostOnChange needing to be called
            if (_.get(currentNode, 'aspects.ajaxChange') && _.get(currentNode, 'aspects.valid')
                && _.get(currentNode, 'aspects.subtreeValid')) {
                _.set(jobVM, 'postOnChangeField', {
                    fieldName: path,
                    methodName: currentNode.aspects.ajaxChange.method
                });

                let requestService
                switch(jobType) {
                    case JobType.POLICY_CHANGE:
                        requestService = EndorsementService;
                        break;
                    case JobType.RENEWAL:
                        requestService = RenewalService;
                        break;
                    default:
                        requestService = LoadSaveService
                }

                setWizardLoading(true, translator(messages.postOnChangeLoadingText), true);
                requestService.postOnChange(jobVM.value, authHeader).then((response) => {
                    jobVM.value = response;
                    updateJobVM(jobVM);

                    if (pOnPostOnChange) {
                        pOnPostOnChange();
                    }
                }).finally(() => {
                    setWizardLoading(false);
                });
            }
        }

        if (isNodeDefaulted) {
            confirmDefaultedValue();
        }
    }, []);
    const debouncedUpdateViewModel = useMemo(() => _.debounce(updateViewModel, 300), [updateViewModel]);

    const onValueChange = async (newValue, pathInVM) => {
        const isPristine = !pristine ? false : currentNode.value === originalValue;
        setPristine(isPristine);
        setOriginalValue(currentNode.value);
        if (pOnValueChange) {
            await pOnValueChange(newValue, pathInVM)
        }

        debouncedUpdateViewModel();
    }

    const onChange = async (_event, newValue) => {
        await onValueChange(newValue, path);
    }

    const labelPosition = useMemo(() => {
        if (pLabelPosition) {
            return pLabelPosition
        }
        return breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP;
    }, [breakpoint, pLabelPosition])

    const defaultToggleValues = useMemo(() => [
        {
            code: 'true',
            name: translator({
                'id': 'platform.inputs.Yes',
                'defaultMessage': 'Yes'
            }, []),
        },
        {
            code: 'false',
            name: translator({
                'id': 'platform.inputs.No',
                'defaultMessage': 'No'
            }, []),
        }
    ], [translator])

    const isToggleTypelist = () => {
        if (pControlType === CONTROL_TYPES.TOGGLE) {
            return true
        }
        return pAvailableValues.length ? _.has(pAvailableValues[0], 'typelist') : false
    }

    const isAvailableValuesAcceptable = () => {
        if (pAvailableValues && pAvailableValues.length > 0) {
            return !isToggleTypelist()
        }
        return false;
    }

    const mapYesNoValues = (newValue) => {
        if (['Yes', 'yes'].includes(newValue)) {
            return true
        }
        if (['No', 'no'].includes(newValue)) {
            return false
        }
        return null
    }

    const writeToggleValue = (newValue, localPath) => {
        if (isToggleTypelist()) {
            const availableValueYes = _.find(_.get(currentNode, 'aspects.availableValues'),
                (code) => ['Yes', 'yes'].includes(code.code))
            const availableValueNo = _.find(_.get(currentNode, 'aspects.availableValues'),
                (code) => ['No', 'no'].includes(code.code))

            if (availableValueNo && availableValueYes) {
                if (newValue) {
                    onValueChange(availableValueYes.code, localPath)
                } else {
                    onValueChange(availableValueNo.code, localPath)
                }
            }
        } else {
            onValueChange(newValue, localPath)
        }
    }

    if (!getVisible()) {
        if (isNodeDefaulted && WMICBMSIUtil.propertiesToBeAutoReviewed.includes(currentNode._propertyName)) {
            _.set(jobVM.defaultedProperties_WMIC.value[nodeDefaultedProperties?.defaultedPropertiesArrayIndex], 'status', CONSTANTS.REVIEWED);
        }
        return null;
    }

    // taking care of initialization.  In the event that there is no parentNode (e.g. initializing) we need to bail
    if (_.isEmpty(parentNode)) {
        logDebugMessages(`NO parentNode defined for ${path} on parent page!!`, `Element ID: ${id}`);
        return null
    }
    // Do not display the field in Policy View if there is no value in it
    if (_.isUndefined(currentNode?.value) && isPolicyView) {
        return null;
    }

    const commonProps = {
        visible: getVisible(),
        label: getLabel(),
        secondaryLabel,
        readOnly: getEditable(),
        required: getRequired(),
        tooltip: getTooltip(),
        onValueChange,
        labelContainerClassName: cx(
            props.labelContainerClassName,
            { 'twoThirdsLabel': !!twoThirdsLabel }
        ),
        showRequired: showRequired ?? true,
        labelPosition,
        // Leaving out for the time being indented:  _.get(currentNode, 'aspects.detailOf')
        showFractions: showFractions ?? false,
        isNodeDefaulted
    }

    // in the event that we have data which isn't part of a category filter, we need to render the control with undefined
    // and force the user to fix the data
    const getValueIfAvailable = () => {
        if (pAvailableValues === undefined) {
            return value;
        }
        const code = value?.code ? value.code : value;
        // treat code as equivalent regardless of string or int
        // eslint-disable-next-line eqeqeq
        if (pAvailableValues.find(val => val?.code == code) !== undefined) {
            return value;
        }

        return undefined;
    }

    const getControl = (type) => {
        const confirmableControlClass = commonProps.isNodeDefaulted && !confirmed ? styles.confirmableControl : undefined;

        switch (type) {
            case CONTROL_TYPES.DROPDOWN_SELECT:
                return (
                    <DropdownSelectField
                        {...props}
                        {...commonProps}
                        value={getValueIfAvailable()}
                        availableValues={pAvailableValues}
                        className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        contentContainerClassName="wmicMasterInputDropdown"
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                    />
                );
            case CONTROL_TYPES.TEXT_INPUT:
                if (props.mask) {
                    return (
                        <InputMaskField
                            {...props}
                            {...commonProps}
                            onChange={onChange}
                            className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                            labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                        />
                    );
                }
                return (
                    <InputField
                        {...props}
                        {...commonProps}
                        className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                    />
                );
            case CONTROL_TYPES.DATE:
                return (
                    <DateField
                        {...props}
                        {...commonProps}
                        calendarClassName={cx('wmicMasterInputCalendar')}
                        className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                    />
                );
            case CONTROL_TYPES.CHECKBOX:
                return (
                    <CheckboxField
                        {...props}
                        {...commonProps}
                        className={cx('wmicMasterInputCheckbox', {
                            'checkBoxReadOnly': props.readOnly,
                            'wmicMasterInputIndentedField': commonProps.indented
                        }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                    />
                );
            case CONTROL_TYPES.TOGGLE:
                return (
                    <ToggleField
                        {...props}
                        {...commonProps}
                        availableValues={isAvailableValuesAcceptable() ? pAvailableValues : defaultToggleValues}
                        className={cx('wmicMasterInputInputField',
                            'wmicMasterInputInputToggleField',
                            props.className,
                            { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                        controlClassName={cx('wmicMasterInputInputToggleFieldControl')}
                        onValueChange={writeToggleValue}
                        value={isToggleTypelist() ? mapYesNoValues(_.get(currentNode, 'value.code')) : _.get(currentNode, 'value')}
                    />
                );
            case CONTROL_TYPES.SEARCH:
                return (
                    <LookupField
                        {...props}
                        {...commonProps}
                        availableValues={pAvailableValues}
                        className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                        contentContainerClassName="wmicMasterInputTypeAhead"
                        value={_.get(currentNode, 'value')}
                    />
                );
            case CONTROL_TYPES.NUMBER_INPUT:
                return (
                    <InputNumberField
                        {...props}
                        {...commonProps}
                        className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                        showGrouping={pControlType !== CONTROL_TYPES.YEAR_INPUT}
                    />
                );
            case CONTROL_TYPES.CURRENCY:
                const dataType = ('amount' in currentNode && 'currency' in currentNode) ? 'object' : 'number';
                return (
                    <CurrencyField
                        {...props}
                        {...commonProps}
                        className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                        dataType={dataType}
                    />
                );
            case CONTROL_TYPES.RADIO:
                return (
                    <RadioButtonField
                        {...props}
                        {...commonProps}
                        availableValues={pAvailableValues}
                        className={cx(
                            'wmicMasterInputInputField',
                            'wmicMasterInputInputToggleField',
                            props.className,
                            { 'wmicMasterInputIndentedField': commonProps.indented },
                            confirmableControlClass
                        )}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                    />
                );
            case CONTROL_TYPES.CHECKBOX_GROUP:
                return (
                    <CheckboxGroupField
                        {...props}
                        {...commonProps}
                        availableValues={pAvailableValues}
                        className={cx('wmicMasterInputCheckbox', {
                            'checkBoxReadOnly': props.readOnly,
                            'wmicMasterInputIndentedField': commonProps.indented
                        }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                    />
                );
            case CONTROL_TYPES.TEXT_AREA:
                return (
                    <TextAreaField
                        {...props}
                        {...commonProps}
                        className={cx('wmicMasterInputInputField', props.className, { 'wmicMasterInputIndentedField': commonProps.indented }, confirmableControlClass)}
                        labelClassName={cx('wmicMasterInputFieldLabel', props.labelClassName)}
                    />
                );
            default:
                return <div>{translator(messages.invalidControlType, { types: [pControlType, _.get(currentNode, 'aspects.inputCtrlType')], path })}</div>
        }
    };

    if (commonProps.isNodeDefaulted) {
        return (
            <Flex data-bmsi-confirmed={confirmed}>
                <div className="wmicMasterInputControlElement">
                    {getControl(controlType)}
                </div>
                <div className={styles.actionElement}>
                    <WMICButton
                        type="primary"
                        icon='gw-error'
                        iconClassName={styles.actionIcon}
                        className={cx(styles.confirmButton, confirmed ? styles.hiddenButton : undefined)}
                        onClick={() => { confirmDefaultedValue(); }}
                        disabled={props.readOnly}
                    >
                        {translator(messages.confirmDefault)}
                    </WMICButton>
                    <div className={cx(styles.reviewed, confirmed ? undefined : styles.hiddenReviewed)}>
                        <Icon icon='gw-check-circle' className={styles.actionIcon} />
                        {translator(messages.reviewed)}
                    </div>
                </div>
            </Flex>
        );
    }

    return (
        <div className="wmicMasterInputControlElement">
            {getControl(controlType)}
        </div>
    );
};

WMICMasterInput.propTypes = {
    ...InputField.PropTypes,
    parentNode: PropTypes.object.isRequired,
    path: PropTypes.string,
    controlType: PropTypes.oneOf(MASTER_INPUT.CHECKBOX_TYPES
        .concat(MASTER_INPUT.TOGGLE_TYPES)
        .concat(MASTER_INPUT.DROPDOWN_TYPES)
        .concat(MASTER_INPUT.TEXT_AREA)
        .concat(MASTER_INPUT.TYPE_AHEAD)
        .concat(MASTER_INPUT.YEAR)
        .concat(MASTER_INPUT.RADIO_TYPES)
    ),
    twoThirdsLabel: PropTypes.bool,
    showRequired: PropTypes.bool,
    labelPosition: PropTypes.string,
    showFractions: PropTypes.bool,
    searchable: PropTypes.bool,
};

WMICMasterInput.defaultProps = {
    searchable: true,
}

export default WMICMasterInput;
