import React, { useCallback, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useDataRefresh, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { renderPrimaryCell, renderDwellingAddressCell } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import {
    WMICHOConstructionDetails,
    WMICHOConstructionHeating,
    WMICHOConstructionRoofElectricalPlumbing,
    WMICHOWaterUpdates,
} from 'wmic-pe-capability-gateway-common-ho-react';

import metadata from './WMICPolicyHoConstructionPage.metadata.json5'
import messages from './WMICPolicyHoConstructionPage.messages';

const DWELLINGS_PATH = 'lobs.homeOwners.dwellingsDTO_WMIC';

function WMICPolicyHoConstructionPage(props) {
    const translator = useContext(TranslatorContext);
    const { refreshData } = useDataRefresh();

    const {
        wizardData: { policyVM },
    } = props;

    const [isReadOnlyUser] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const dwellings = _.get(policyVM, `${DWELLINGS_PATH}.children`);
    const [dwellingView, setDwellingView] = useState(dwellings[selectedIndex]);
    const [dwellingWingView, setDwellingWingView] = useState(_.get(dwellingView, `construction.dwellingWings.children[0]`));
    const [detailsValidity, setDetailsValidity] = useState({
        roofValid: true,
        minimumWallsValid: false,
        minimumKitchensAndBathsValid: false,
        wallsPercentageValid: false,
        hasPrimaryHeating: false
    });

    const selectCurrentRisk = useCallback((index) => {
        const dwelling = dwellings[index];
        setDwellingView(dwelling);

        const dwellingWing = _.get(dwelling, `construction.dwellingWings.children[0]`);
        setDwellingWingView(dwellingWing)

        setSelectedIndex(index);
    }, [dwellings])

    useEffect(() => {
        selectCurrentRisk(selectedIndex);
    });

    const updateRiskView = (riskVM) => {
        refreshData()
        setDwellingWingView(riskVM);
    };

    const commonAccordionContentProps = {
        riskView: dwellingWingView,
        onRiskViewChange: updateRiskView,
        jobVM: policyVM,
        updateDetails: setDwellingWingView,
        isEditMode: false,
        detailsValidity,
        updateDetailsValidity: setDetailsValidity,
        isReadOnlyUser
    };

    const overrideProps = {
        risksDataList: {
            VMList: _.get(policyVM, `${DWELLINGS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.riskNumber),
                    path: 'yourHome.dwellingNumber_EXT',
                    getData: (item, path) => _.get(item, `${path}.value`, '')
                },
                {
                    headerText: translator(messages.riskPrimary),
                    path: 'yourHome.primaryDwelling_EXT',
                    getData: renderPrimaryCell
                },
                {
                    headerText: translator(messages.riskDescription),
                    path: 'yourHome.locationAddress.displayName',
                    getData: (item) => renderDwellingAddressCell(item, translator)
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'rating.hoPolicyType'
                }
            ],
            readOnly: true,
            selectedCardIndex: selectedIndex,
            updateSelectedCardIndex: selectCurrentRisk
        },
        detailsAccordionContent: {
            ...commonAccordionContentProps
        },
        roofElectricalPlumbingAccordionContent: {
            ...commonAccordionContentProps
        },
        waterUpdatesContent: {
            ...commonAccordionContentProps
        },
        heatingAccordionContent: {
            ...commonAccordionContentProps,
            selectedCardIndex: selectedIndex,
            riskView: dwellingView
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICHOConstructionDetails,
            WMICHOConstructionHeating,
            WMICHOConstructionRoofElectricalPlumbing,
            WMICHOWaterUpdates,
            Accordion
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyHoConstructionPage.propTypes = wizardProps;
export default WMICPolicyHoConstructionPage;
