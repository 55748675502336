// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPaymentList__currencyField{--GW-FONT-WEIGHT-SEMI-BOLD:400;--GW-FIELD-COMPONENT-COLOR:$table-body-color}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/WMICPaymentList/WMICPaymentList.module.scss"],"names":[],"mappings":"AAAA,qCACI,8BAAA,CACA,4CAAA","sourcesContent":[".currencyField {\n    --GW-FONT-WEIGHT-SEMI-BOLD: 400;\n    --GW-FIELD-COMPONENT-COLOR: $table-body-color;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencyField": "app__WMICPaymentList__currencyField"
};
export default ___CSS_LOADER_EXPORT___;
