import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { WMICJsonRPCService } from 'wmic-portals-transport-js';

export default class AccountBillingDetailsService {
    static getPolicyBillingSummary(policyNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPolicyBillingSummary', [policyNumber], additionalHeaders);
    }

    // eslint-disable-next-line camelcase
    static getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, additionalHeaders = {}) {
        // eslint-disable-next-line max-len
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPolicyPaymentSummaryByPolicyNumber_WMIC', [policyNumber], additionalHeaders);
    }
}
