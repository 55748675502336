import React, {
    useCallback,
    useEffect,
    useState,
    useContext,
    useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useAuth, decodeToken } from '@jutro/auth';
import { getConfigValue } from '@jutro/config';
import { useModal } from '@jutro/components/widgets/modal-next/ModalNextProvider';
import { Loader } from '@jutro/components/widgets/loading/loader/Loader';
import appConfig from 'app-config';
import { getGrantedAuthorities } from './utils/GrantedAuthoritiesUtil';
import { InactiveConfirmModal } from './LogoutWarningModal';
import { AuthContext as DigitalAuthContext } from './AuthenticationContext';
import contextTransformer from './contextTransformer';
import InactivityTracker from './InactivityTracker';
import { useTokenRenewal, useTokenExpiration, useTokenRefresher } from './hooks';

const MODAL_CONFIRMATION_INTERVAL_MS = 90 * 1000;
const DISABLE_INACTIVITY_TRACKING = 0;

const shouldCheckGrantedAuthorities = getConfigValue('GRANTED_AUTHORITIES_ENABLED', false);

const getGrantedAuthorityContext = async (digitalAuthContext) => {
    if (shouldCheckGrantedAuthorities) {
        const grantedAuthorities = await Promise.resolve(
            getGrantedAuthorities(digitalAuthContext)
        );
        return { grantedAuthorities };
    }
    return {};
};

const getAuthData = async (onAuthDataCreation, digitalAuthContext) => {
    if (onAuthDataCreation) {
        // application specific logic
        const authUserData = await onAuthDataCreation(digitalAuthContext);

        return { authUserData };
    }
    return {};
};

const getAuthState = async (jutroAuthContext, onAuthDataCreation) => {
    const digitalAuthContext = await contextTransformer.getDigitalAuthContext(jutroAuthContext);
    const { accessToken } = digitalAuthContext;
    if (!accessToken) {
        // if we have no authHeader we'll have to rely on information
        // we already have about the token
        return digitalAuthContext;
    }
    const authData = await getAuthData(onAuthDataCreation, digitalAuthContext);
    const grantedAuthorities = await getGrantedAuthorityContext(digitalAuthContext);

    return Object.assign({}, authData, grantedAuthorities, digitalAuthContext);
};

/**
 * Wrapper for the application content.
 *
 * This allows to access more easily the jutro context without having to inline multiple components.
 *
 * @memberof module:gw-digital-auth-react
 * @function ContextWrapper
 * @param {ReactElement} children - components to be wrapped with Authentication Context Provider
 * @param {Function} onAuthDataCreation - function gets authorisation details
 * @returns {ReactElement} children components wrapped by Cloud Authentication Context Provider
 */
function ContentWrapper({ children, onAuthDataCreation }) {
    const modalApi = useModal();
    const auth = useAuth();

    const [digitalContextValue, setDigitalContextValue] = useState();
    const [isUserActive, setIsUserActive] = useState(true);
    const { oktaConfig } = appConfig;

    const { forceTokenUpdate, tokenLastUpdated } = useTokenRefresher();
    useEffect(() => {
        getAuthState(auth, onAuthDataCreation)
            .then(setDigitalContextValue);
    }, [
        auth,
        // we want to recompute every time the local token value gets updated
        tokenLastUpdated,
        auth.userInfo,
        onAuthDataCreation
    ]);

    const { isAuthenticated } = auth;

    const logout = useCallback(() => {
        window.location.href = `${oktaConfig.logout}?fromURI=${window.location.origin}/logout`;
    }, []);

    const showModal = useCallback(() => {
        modalApi.showModal(
            <InactiveConfirmModal
                logoutConfirmationInterval={MODAL_CONFIRMATION_INTERVAL_MS}
            />
        ).then(_.noop, logout);
    }, [logout]);

    /* const onAccessTokenExpired = useCallback(async () => {
        await tokenManager.renew('accessToken');
    }, [tokenManager]); */

    //useTokenExpiration({ tokenManager, onAccessTokenExpired });

    const onActivityChange = useCallback(({ isActive }) => {
        setIsUserActive(isActive);
    }, []);

    const isPending = _.get(digitalContextValue, 'isPending', true);

    useEffect(() => {
        if (!isUserActive) {
            showModal();
        }
    }, [isUserActive, showModal]);

    const onTokenRenewed = useCallback(() => {
        // force reloading the context with the new token value
        forceTokenUpdate(Date.now());
    }, [forceTokenUpdate]);

    // useTokenRenewal({ tokenManager, onTokenRenewed });

    const accessToken = _.get(digitalContextValue, 'accessToken');

    const tokenDuration = useMemo(() => {
        if (!accessToken) {
            return null;
        }
        const { exp: expiration, iat: issued } = decodeToken(accessToken).payload;
        return expiration - issued;
    }, [accessToken, decodeToken]);

    if (isPending) {
        return <Loader />;
    }

    const timeoutDurationInSecs = isAuthenticated ? appConfig.sessionConfig.inactivityIntervalMins * 60 : DISABLE_INACTIVITY_TRACKING;
    return (
        <DigitalAuthContext.Provider value={digitalContextValue}>
            <InactivityTracker
                onActivityChange={onActivityChange}
                inactivityAllowedSecs={timeoutDurationInSecs}
            />
            { children }
        </DigitalAuthContext.Provider>
    );
}

ContentWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    onAuthDataCreation: PropTypes.func,
};

ContentWrapper.defaultProps = {
    onAuthDataCreation: _.noop,
};


/**
 * Provides authorisation context for cloud (okta)
 *
 * @memberof module:gw-digital-auth-react
 * @function CloudAuthenticationContext
 * @param {ReactElement} children - components to be wrapped with Authentication Context Provider
 * @param {Function} onAuthDataCreation - function gets authorisation details
 * @returns {ReactElement} children components wrapped by Cloud Authentication Context Provider
 */
function CloudAuthenticationContext({ onAuthDataCreation, children }) {
    return (
        <ContentWrapper onAuthDataCreation={onAuthDataCreation}>
            {children}
        </ContentWrapper>

    );
}

CloudAuthenticationContext.propTypes = {
    children: PropTypes.node.isRequired,
    onAuthDataCreation: PropTypes.func
};

CloudAuthenticationContext.defaultProps = {
    onAuthDataCreation: undefined
};

export default CloudAuthenticationContext;
