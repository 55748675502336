// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPolicyCancelModal__policyCancelModal{width:50vw}.app__WMICPolicyCancelModal__policyCancelModal span[class*=title]{color:var(--GW-FIELD-COMPONENT-COLOR);font-size:var(--GW-FONT-SIZE-H1);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);margin:var(--GW-LAYOUT-4) 0}.app__WMICPolicyCancelModal__policyCancelModal .app__WMICPolicyCancelModal__policyDetail{font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);margin-bottom:var(--GW-LAYOUT-8)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICPolicyCancelModal/WMICPolicyCancelModal.module.scss"],"names":[],"mappings":"AAAA,+CACE,UAAA,CACA,kEAGE,qCAAA,CAFA,gCAAA,CACA,2CAAA,CAEA,2BAAA,CAGF,yFACE,2CAAA,CACA,gCAAA","sourcesContent":[".policyCancelModal {\n  width: 50vw;\n  span[class*='title'] {\n    font-size: var(--GW-FONT-SIZE-H1);\n    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n    color: var(--GW-FIELD-COMPONENT-COLOR);\n    margin: var(--GW-LAYOUT-4) 0;\n  }\n\n  .policyDetail {\n    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n    margin-bottom: var(--GW-LAYOUT-8);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyCancelModal": "app__WMICPolicyCancelModal__policyCancelModal",
	"policyDetail": "app__WMICPolicyCancelModal__policyDetail"
};
export default ___CSS_LOADER_EXPORT___;
