import { getConfigValue } from '@jutro/config';
import appConfig from 'app-config';

const tokenMap = new Map();
const { captchaConfig } = appConfig;

export default class WMICReCaptchaService {

    static async waitForReCaptchaToBeLoaded() {
        return new Promise((resolve, reject) => {
            const timerId = setInterval(() => {
                if (grecaptcha) {
                    resolve(true);
                    clearInterval(timerId);
                }
            }, 1000);

            // after 20 seconds stop
            setTimeout(() => {
                clearInterval(timerId);
                if (!grecaptcha) {
                    reject();
                }
            }, 20000);
        });
    };

    static async fetchReCaptchaToken(method, overrideCachedToken=false) {
        const currentTime = Date.now();
        const cachedToken = tokenMap.get(method);
        if (cachedToken && currentTime - cachedToken.timeStamp < captchaConfig.timeout && !overrideCachedToken) {
            return cachedToken.token;
        }

        return new Promise(((resolve, reject) => {
            const captchaApiKey = getConfigValue('CAPTCHA_API_KEY');

            function getToken(captchaApiKey, method, resolve, reject, currentTime, currentAttempt) {
                // eslint-disable-next-line no-undef
                grecaptcha.execute(captchaApiKey, {action: method})
                    .then((token) => {
                        resolve(token);
                        tokenMap.set(method, {token, timeStamp: currentTime});
                        return token;
                    }, (error) => {
                        if (currentAttempt + 1 > captchaConfig.tokenRetrievalRetryMaxAttempts) {
                            reject(`grecaptcha.execute failed. Details: ${error}`);
                        } else {
                            // grecaptcha.execute failed for some reason. There is very little documentation about
                            // why this would fail and the error is null. The best course of action is to retry
                            // the execute operation after a short timeout.
                            setTimeout(getToken, captchaConfig.tokenRetrievalRetryTimeout, captchaApiKey, method, resolve, reject, currentTime, currentAttempt + 1);
                        }
                    });
            }

            return this.waitForReCaptchaToBeLoaded().then(() => {
                // eslint-disable-next-line no-undef
                return grecaptcha.ready(() => {
                    getToken(captchaApiKey, method, resolve, reject, currentTime, 1)
                });
            });
        }));
    }
}