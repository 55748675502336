/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import {TranslatorContext} from '@jutro/locale'
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage  } from 'wmic-pe-portals-wizard-components-ui';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICUserAccessUtil, FlowStepId, WMICVariousUtil, CONSTANTS, WMICLogger, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import messages from './WMICPolicyDetailsPage.messages'

function WMICPolicyDetailsPage(props) {
    const { isComponentValid, onValidate: setComponentValidation, initialValidation, registerInitialComponentValidation } = useValidation('PolicyDetailsPage');
    const { wizardData: submissionVM, updateWizardData } = props;
    const { setWizardLoading } = useWizardModals();
    const { authHeader, authUserData } = useAuthentication();
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const translator = useContext(TranslatorContext);
    const isBMSi = _.get(submissionVM, "isFromBMS_WMIC.value");
    const status = _.get(submissionVM, "quoteData.value.offeredQuotes[0].status", '');
    const isQuoted = status.toLowerCase() === CONSTANTS.QUOTED.toLowerCase();

    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    useDocumentTitle("Policy Details", submissionVM);

    const validateInitialTableForm = useCallback(() => {
        const answers = _.get(submissionVM, 'lobData.homeowners.preQualQuestionSets.children[0].value.answers');
        // If the qualification questions were not answered, start wizard from page 1 (Policy Details page)
        // However, in the case where BMSi has been created and the submission is in the Quoted status,
        // if it is user's first visit or if they revisit without making any changes,
        // the initial validation is bypassed to allow freely jump between steps.
        return isBMSi && isQuoted
            ? true
            : Object.values(answers).find(value => !_.isNil(value))
                && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.POLICY_DETAILS);
    }, [submissionVM, isBMSi, isQuoted]);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialTableForm);
    }, [registerInitialComponentValidation, validateInitialTableForm]);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.POLICY_DETAILS;
    }, [submissionVM.value]);

    const launchDefaultAnswers = useCallback(async () => {
        try{
            setWizardLoading(true, translator(messages.defaultAnswers))
            const newVM = _.clone(submissionVM);
            newVM.value = await LoadSaveService
                .createDefaultAnswers_WMIC(submissionVM.value, authHeader);
            updateWizardData(newVM);
        }catch (e) {
            WMICLogger.error('Error launching default answers', e)
        }finally {
            setWizardLoading(false)
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setWizardLoading, translator, submissionVM, authHeader]);

    const onNext = useCallback(async () => {
        if (isComponentValid) {
            try {
                setWizardLoading(true);
                _.unset(submissionVM.value, 'bindData');

                submissionVM.value = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );
                updateWizardData(submissionVM);

                if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.POLICY_DETAILS)) {
                    WMICVariousUtil.scrollToTop();
                    return false
                }

                return submissionVM;
            } finally {
                setWizardLoading(false);
            }
        } else {
            setShowErrors(true)
            return false;
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, submissionVM, authHeader]);

    return (
        <WMICWizardSubmissionPage
            showPrevious={false}
            skipWhen={initialValidation}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={submissionVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={isReadOnlyUser}
                authHeader={authHeader}
                launchDefaultAnswers={launchDefaultAnswers}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPolicyDetailsPage.propTypes = wizardProps;
export default WMICPolicyDetailsPage;
