// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICBackToTopButton__backToTop{--GW-ICON-FONT-SIZE-2:2.1rem;--button-position:var(--GW-SPACING-8);--button-size:50px;--icon-position:var(--GW-SPACING-8);align-items:center;border-radius:50%;bottom:var(--button-position);display:flex;height:var(--button-size);justify-content:center;padding:0;position:fixed;right:var(--icon-position);width:var(--button-size)}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICBackToTopButton/WMICBackToTopButton.module.scss"],"names":[],"mappings":"AAAA,qCACI,4BAAA,CACA,qCAAA,CACA,kBAAA,CACA,mCAAA,CAKA,kBAAA,CAHA,iBAAA,CACA,6BAAA,CACA,YAAA,CAMA,yBAAA,CAJA,sBAAA,CAEA,SAAA,CADA,cAAA,CAEA,0BAAA,CAEA,wBAAA","sourcesContent":[".backToTop {\n    --GW-ICON-FONT-SIZE-2: 2.1rem;\n    --button-position: var(--GW-SPACING-8);\n    --button-size: 50px;\n    --icon-position: var(--GW-SPACING-8);\n\n    border-radius: 50%;\n    bottom: var(--button-position);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: fixed;\n    padding: 0;\n    right: var(--icon-position);\n    height: var(--button-size);\n    width: var(--button-size);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backToTop": "app__WMICBackToTopButton__backToTop"
};
export default ___CSS_LOADER_EXPORT___;
