import { CONSTANTS } from 'wmic-portals-utils-js';

function getRepairProgram(info) {
    if (info?.lobs?.personalAuto?.vehicleIncidents.length > 0) {
        return info.lobs.personalAuto.vehicleIncidents[0].damageRepairProgram_WMIC;
    }
    return undefined;
}

function isVendorTypeIndependent(info) {
    const repairProgram = getRepairProgram(info);
    if (repairProgram) {
        return repairProgram.vendorType === CONSTANTS.VENDOR_TYPES.INDEPENDENT;
    }
    return false;
}

function isEstimating(info) {
    const repairProgram = getRepairProgram(info);
    if (repairProgram) {
        return repairProgram.repairStatus === CONSTANTS.REPAIR_STATUS.ESTIMATING;
    }
    return false;
}

export default {
    getRepairProgram,
    isVendorTypeIndependent,
    isEstimating
};