/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import PropTypes from 'prop-types';

import { TranslatorContext } from '@jutro/locale';
import { Flex } from '@jutro/layout';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { MockUpUtil } from '@xengage/gw-portals-util-js'

import { WMICDropdownMenuButton, WMICScrollToError } from 'wmic-pe-components-platform-react';
import { CONSTANTS, WMICValidationUtil, WMICVariousUtil, FlowStepId } from 'wmic-pe-portals-utils-js';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICGLLiabilityCoverages, WMICGLLiabilityDetails } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';
import { WMICCPAdditionalInterestDetailView } from "wmic-pe-capability-gateway-quoteandbind-cp-react"

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICGLLiabilityPage.metadata.json5';
import messages from './WMICGLLiabilityPage.messages';
import styles from './WMICGLLiabilityPage.module.scss';

const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

const structureCustomQuote = (submissionVM, affectedQuote, clauses) =>
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    })
;

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

function WMICGLLiabilityPage(props) {
    const { wizardSnapshot, wizardData: submissionVM, updateWizardData, underwritingIssues } = props;
    const translator = useContext(TranslatorContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [scrollToError, setScrollToError] = useState(false); // we toggle this when we want to scroll to the first error on the page

    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { setWizardLoading, showConfirm } = useWizardModals();
    const { authHeader } = useAuthentication();
    const { onValidate, disregardFieldValidation, initialValidation, registerInitialComponentValidation, isComponentValid } = useValidation("WMICGLLiabilityPage");
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);
    const [showErrorsAddlIns, setShowErrorsAddlIns] = useState(false)

    useDocumentTitle(translator(messages.title), submissionVM);

    const validateTableForm = useCallback(() => WMICValidationUtil.validatePageEntities(submissionVM, [
            "lobData.generalLiability.policyDetails",
            "lobData.generalLiability.additionalInsureds"
        ]) && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_LIABILITY)
    , [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateTableForm);
    }, [registerInitialComponentValidation, validateTableForm]);


    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.CP_LIABILITY;
    }, [submissionVM.value]);

    const getRemovedFieldsForLob = useCallback((lobName, lobPath, newSubmissionVM) => {

        let allRemovedFields = [];

        const removedFieldsFromPropertyLineCoverages = ClausesUtil.getRemovedClausesID(
            submissionVM,
            newSubmissionVM,
            `${lobPath}.coverages.commercialGeneralLiabilityCoverages`
        );

        allRemovedFields = [
            ...removedFieldsFromPropertyLineCoverages,
        ];

        return allRemovedFields;
    }, [submissionVM]);

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);

            return CustomQuoteService.updateCustomQuoteCoveragesAndQuoteData(customQuote, submissionVM.value, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;

                    const allRemovedFields = getRemovedFieldsForLob(lobName, lobPath, newSubmissionVM);

                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        [
            submissionVM,
            CustomQuoteService,
            authHeader,
            viewModelService,
            disregardFieldValidation,
            updateWizardData,
            getRemovedFieldsForLob
        ]
    );

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, lobPath, lobPath);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);
            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.forceUpdateCustomQuoteCoveragesAndQuoteData(customQuote, initialSubmissionVM.value, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initialSubmissionVM, submissionVM, CustomQuoteService, authHeader]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;
                    updateWizardData(newSubmissionVM);
                    return response;
                }
            );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM, CustomQuoteService, authHeader]
    );

    const renderAddAIButton = useCallback(({ isEditing, onClick }) => (<Flex className="gw-mb-6" justifyContent="left">
            <WMICDropdownMenuButton
                id="addNewAdditionalInsuredButton"
                type="secondary"
                size="medium"
                buttonText={translator(messages.addAdditionalInsured)}
                alignRight
                onItemClick={onClick}
                items={
                    [
                        { id: 'newPerson', text: translator(messages.aiPerson), code: _.lowerCase(CONSTANTS.Person), icon: 'mi-person' },
                        { id: 'newCompany', text: translator(messages.aiCompany), code: _.lowerCase(CONSTANTS.Company), icon: 'mi-home' },
                    ]
                }
                disabled={isEditing}
            />
        </Flex>)
    , []);

    const toCreateClicked = useCallback((index) => {
        const additionalInsuredAddress = {
            country: CONSTANTS.COUNTRY.CA,
            city: '',
            province: '',
            postalCode: '',
        };
        const newVM = viewModelService.create(
            {
                'contactType': index,
                'locationAddress': additionalInsuredAddress,
                'isEditable': true,
                'subType': CONSTANTS.POLICY_ADDL_INSURED
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.CPAdditionalInterest_WMICDTO',
            submissionVM.aspects.context()
        );
        submissionVM.value.lobData.generalLiability.additionalInsureds.push(newVM.value)

        return newVM
    }, [viewModelService, submissionVM.aspects]);

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const saveSubmission = useCallback(async (newSubmission) => {
        setWizardLoading(true);
        _.unset(newSubmission, 'errorsAndWarnings');

        return LoadSaveService.updateDraftSubmission(
            newSubmission,
            authHeader
        ).then((result) => {
            submissionVM.value = result;
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_LIABILITY)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        }).finally(() => {
            setWizardLoading(false);
        });

    }, [LoadSaveService, authHeader, setWizardLoading, submissionVM, updateWizardData]);

    const onSaveClickedAddtionalInsured = useCallback(async (additionalInsuredVM, index) => {
        if (additionalInsuredVM.aspects.valid && additionalInsuredVM.aspects.subtreeValid) {
            const additionalInsureds = _.get(submissionVM, 'lobData.generalLiability.additionalInsureds.value')
            if (additionalInsuredVM.publicID.value != null) {
                additionalInsureds[index] = additionalInsuredVM.value
            }
            return saveSubmission(submissionVM.value).then((result) => {
                setShowErrorsAddlIns(false)
                return result
            })
        } else {
            setShowErrorsAddlIns(true)
        }
    }, [saveSubmission, submissionVM]);

    const getName = useCallback((additionalInsured) => {
        if (additionalInsured.contactType.value.code === _.lowerCase(CONSTANTS.Person)) {
            const firstName = additionalInsured.firstName.value;
            const lastName = additionalInsured.lastName.value;
            return `${firstName} ${lastName}`;
        }
        if (additionalInsured.contactType.value.code === _.lowerCase(CONSTANTS.Company)) {
            const company = additionalInsured.companyName.value;
            return company;
        }
    }, []);

    const onDeleteAdditionalInsured = useCallback(async (additionalInsuredVM, index) => {
        const name = getName(additionalInsuredVM)
        const response = await showConfirm({
            title: translator(messages.removeAdditionalInsuredShort),
            message: translator(messages.removeAdditionalInsuredLong, { displayName: name })
        })
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const newSubmission = _.cloneDeep(submissionVM.value);
            const additionalInsureds = newSubmission.lobData.generalLiability.additionalInsureds;
            additionalInsureds.splice(index, 1)
            return saveSubmission(newSubmission)
        }
    }, [getName, saveSubmission, showConfirm, submissionVM.value, translator]);

    const buyNow = useCallback(
        async (lobPath, quotePath) => {
            const lobOffering = _.get(submissionVM, `${lobPath}.value`);
            const quoteOffering = _.get(submissionVM, `${quotePath}.value`);
            const quoteID = _.get(submissionVM, 'quoteID.value');
            const sessionUUID = _.get(submissionVM, 'sessionUUID.value');

            await CustomQuoteService.setSelectedVersionOnSubmission(
                quoteID,
                lobOffering.branchName,
                sessionUUID,
                authHeader
            );

            // set chosen quote
            _.set(submissionVM, 'bindData.chosenQuote.value', quoteOffering.publicID);
            submissionVM.value = MockUpUtil.cleanUpMockedProperties(
                submissionVM.value,
                'quote.cp',
                ...MOCK_DATA_TO_REMOVE
            );
            updateWizardData(submissionVM);
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM, CustomQuoteService, authHeader]
    );

    const getCustomTerms = () => null;

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    const commonOverrides = {
        'quoteID': submissionVM.quoteID.value,
        'changeSubmissionAndSync': changeSubmissionAndSync,
        'changeSubmission': changeSubmission,
        'onClauseChange': onClauseChange,
        'syncCoverages': syncCoverages,
        'recalculate': recalculate,
        'resetQuote': resetQuote,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'onValidate': onValidate,
        'getCustomTerms': getCustomTerms,
        'buyNow' : buyNow,
    }

    const overrideProps = {
        startDate: {
            visible: showQuoteStartDate
        },
        print: {
            visible: !showQuoteStartDate
        },
        liabilityCoverages: {
            ...commonOverrides,
            jobVM: submissionVM,
        },
        liabilityDetails: {
            updateWizardData,
            onValidate,
            jobVM: submissionVM
        },
        additionalInsuredListView: {
            clickable: true,
            startOpen: true,
            readOnly: false,
            editEnabled: true,
            hideBorder: true,
            value: _.get(submissionVM, `lobData.generalLiability.additionalInsureds.children`, []),
            VMData: [
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.numberOfDaysNotice),
                    path: 'numberOfDays'
                },
            ],
            detailViewComponent: WMICCPAdditionalInterestDetailView,
            detailViewComponentProps: {
                jobVM: submissionVM,
                showErrorsAddlInt: showErrorsAddlIns,
                updateWizardData,
            },
            onValidate,
            renderAddButton: renderAddAIButton,
            toCreate: toCreateClicked,
            toUndoCreate: () => {
                const additionalInsureds = _.get(submissionVM, `lobData.generalLiability.additionalInsureds.value`)
                additionalInsureds.splice(additionalInsureds.length - 1, 1);
                updateWizardData(submissionVM);
                setShowErrorsAddlIns(false)
            },
            onDelete: onDeleteAdditionalInsured,
            onSave: onSaveClickedAddtionalInsured,
        },
    };

    useEffect(() => {
        const offerings = _.get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = _.filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = _.map(draftOffers, 'branchCode');
        setStaleQuoteBranchCode(draftBranchCodes);
        // Store the initial SubmissionVM when component is mounted
        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        try {

            if (!isComponentValid) {
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true);
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader)

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_LIABILITY)) {
                WMICVariousUtil.scrollToTop();
                return false
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
            onStaleQuoteBranchCode,
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onChangeSubmission: changeSubmission,
            onSyncCoverages: syncCoverages,
            onPrint: handlePrint
        },
        resolveComponentMap: {
            WMICGLLiabilityCoverages,
            WMICGLLiabilityDetails
        }
    };

    return (
        <WMICWizardSubmissionPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={commonMessages.saveAndExit}
            onNext={onNext}
            flowStepId={FlowStepId.CP_LIABILITY}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICGLLiabilityPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICGLLiabilityPage;
