/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import _ from 'lodash';

export default {
    getDuplicatedDriverClassTypes: (types) => {
        if (!types) {
            return {};
        }
        const seen = {};
        const duplicates = {};
        types.forEach((item) => {
            const code = item.driverClassType_WMIC;
            // eslint-disable-next-line no-prototype-builtins
            if (seen.hasOwnProperty(code)) {
                duplicates[code] = 1;
            } else {
                seen[code] = 1;
            }
        });
        return duplicates;
    },

    driverClassHistoryContainsClassType: (types) => {
        if (!types) {
            return {};
        }

        const motorcycleClasses = {};
        _.each(types, (v) => {
            if (v.driverClassType_WMIC === 'TC_6') {
                motorcycleClasses.push(v);
            }
        });

        return motorcycleClasses;
    },
    isEmpty: (m) => {
        return _.keys(m).length === 0;
    },
    isNotEmpty: (m) => {
        return _.keys(m).length !== 0;
    },

    notIn: (v, m) => {
        // eslint-disable-next-line no-prototype-builtins
        return !m.hasOwnProperty(v);
    }
};
