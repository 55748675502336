/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { WMICDropdownSelect } from 'wmic-components-platform-react';
import { CONSTANTS, WMICRichTextUtil } from 'wmic-portals-utils-js';
import _ from 'lodash';
import classNames from 'classnames';
import { WMICEndorsementUtil } from 'wmic-capability-policychange';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import messages from './WMICCoverageTerm.messages';
import styles from './WMICCoverageTerm.module.scss';

const WMICCoverageTerm = (props) => {
    const { coverage, term, originalTerm, coverageTermChanges, coverageTermUpdated, coverageTermErrorMessages } = props;
    const translator = useTranslator();
    const termVm = coverage?.terms?.findFirstElement(term);

    const getCoverageLabel = (id, origTerm) => {
        const coverageAmount = origTerm?.chosenTermValue;
        if (origTerm) {
            switch (id.toLowerCase()) {
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_RENTALEXPCOVLIMIT.toLowerCase():
                    return translator(messages.rentalExpCovLimitMessage, {
                        limitPerDay: coverageAmount?.split('/')[0].trim(),
                        limitPerOccurrence: coverageAmount?.split('/')[1].trim()
                    });
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVDED.toLowerCase():
                    return translator(messages.collisionCovDedMessage, { coverageAmount: coverageAmount });
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVDED.toLowerCase():
                    return translator(messages.comprehensiveCovDedMessage, { coverageAmount: coverageAmount });
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVRENTALEXPLIMIT.toLowerCase():
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVRENTALEXPLIMIT.toLowerCase():
                default:
                    return undefined;
            }
        }
        return undefined;
    };

    const getErrorMessages = (errors) => {
        const content = errors?.map((error) => {
            return (
                <div className="error">
                    {error}
                </div>
            );
        });

        return (
            <Fragment>
                { content }
            </Fragment>
        );
    };

    const hideCoverage = (code) => {
        if (code === CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVRENTALEXPLIMIT || code === CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVRENTALEXPLIMIT) {
            return true;
        }
        return false;
    };

    const getOptions = (t, origTerm) => {
        let filteredOptions = [];
        if (origTerm) {
            const originalChosenTermNameAsFloat = parseFloat(origTerm.chosenTermValue.replace(/,/g, ''));
            switch (t?.value?.publicID.toLowerCase()) {
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVDED.toLowerCase():
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVDED.toLowerCase(): {
                    filteredOptions = _.filter(_.cloneDeep(t.options.value), (option) => {
                        const optionNameAsFloat = parseFloat(option.name.replace(/,/g, ''));
                        if (optionNameAsFloat >= originalChosenTermNameAsFloat) {
                            option.name = `$${option.name} ${translator(messages.deductible)}`;
                            return true;
                        }
                        return false;
                    });
                    break;
                }
                case CONSTANTS.COVERAGE_TERM_NAMES.PA_RENTALEXPCOVLIMIT.toLowerCase(): {
                    filteredOptions = _.filter(_.cloneDeep(t.options.value), (option) => {
                        const splitValue = option.name.split('/');
                        const limitPerDay = splitValue[0].trim();
                        const limitPerOccurrence = splitValue[1].trim();
                        option.name = `$${limitPerDay} ${translator(messages.perDay)} / $${limitPerOccurrence} ${translator(messages.perOccurrence)}`;
                        return true;
                    });
                    break;
                }
                default: {
                    filteredOptions = _.cloneDeep(t.options.value);
                    break;
                }
            }
        }

        return filteredOptions;
    };

    const coverageTermOptionChanged = (t, coverageTermCode) => {
        if (coverageTermCode) {
            const selectedTermOption = _.find(t.options, { code: coverageTermCode });
            t.chosenTermValue = selectedTermOption.name;
            t.chosenTerm = coverageTermCode;
            t.updated = true;
            coverage.updated = true;
            const latestChanges = WMICEndorsementUtil.updateCoverageTermChanges(originalTerm, t, coverageTermChanges.terms);
            coverageTermUpdated({ terms: latestChanges });
        }
    };

    const getCoverageTooltip = (t) => {
        switch (t.value.publicID.toLowerCase()) {
            case CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVDED.toLowerCase(): {
                return WMICRichTextUtil.translateRichText(translator(messages.comprehensiveCovDedTooltip));
            }
            case CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVDED.toLowerCase(): {
                return WMICRichTextUtil.translateRichText(translator(messages.collisionCovDedTooltip));
            }
            default: {
                return undefined;
            }
        }
    };

    return (
        <div>
            <div>
                { getCoverageLabel(term.publicID, originalTerm) !== undefined && (
                    <p>{getCoverageLabel(term.publicID, originalTerm)}</p>
                )}
            </div>
            { coverageTermErrorMessages?.length > 0 && (
                <Fragment>
                    {getErrorMessages(coverageTermErrorMessages)}
                </Fragment>
            )}
            <div className="ww-edit-field-container">
                { !hideCoverage(term.patternCode) && (
                    <WMICDropdownSelect
                        id={`coverage_term_${term.patternCode}`}
                        availableValues={getOptions(termVm, originalTerm)}
                        className={styles.dropdownField}
                        onValueChange={(chosenTerm) => coverageTermOptionChanged(term, chosenTerm)}
                        value={term.chosenTerm}
                    />
                )}
                { !!getCoverageTooltip(termVm) && (
                    <span className={classNames('ww-help-icon', styles['wmic-icon-wrapper'])}>
                        <WMICClickableIconTooltipComponent tooltipMessage={getCoverageTooltip(termVm)} />
                    </span>
                )}
            </div>
        </div>
    );
};

WMICCoverageTerm.propTypes = {
    coverage: PropTypes.shape({}).isRequired,
    term: PropTypes.shape({}).isRequired,
    originalTerm: PropTypes.shape({}).isRequired,
    coverageTermChanges: PropTypes.shape({}).isRequired,
    coverageTermUpdated: PropTypes.func.isRequired,
    coverageTermErrorMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default WMICCoverageTerm;