import messages from './Models.messages';

export default class DesignatePersonModel {
    constructor({
        name, street, unit, city, state, zip, permission, country
    }) {
        this.name = name;
        this.street = street;
        this.unit = unit || '';
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.country = country;
        this.permission = permission || false;
    }

    toHTML() {
        return `
<b>Name: </b>${this.name}</br>
<b>Street: </b>${this.street}</br>
<b>Apt/Unit: </b>${this.unit}</br>
<b>City: </b>${this.city}</br>
<b>State: </b>${this.state}</br>
<b>Zip: </b>${this.zip}<br/>
<b>Grant permission to discuss billing information with designee:</b> ${this.permission ? 'Yes' : 'No'}
`;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.nameStreetCityStateZipPermissionRequired);
        }

        return `
Designated Additional Person information:
Name: ${this.name}
Street Address: ${this.street}
Unit: ${this.unit}
City: ${this.city}
State: ${this.state}
Zip: ${this.zip}

Permission to discuss billing information: ${this.permission ? 'Yes' : 'No'}
--
`;
    }

    valid() {
        const required = [this.name, this.street, this.city, this.state, this.zip];
        return required.every((req) => req);
    }
}
