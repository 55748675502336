import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useHistory } from "react-router-dom";
import { CONSTANTS } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';

import metadata from './WMICChangeAddressViewComponent.metadata.json5';
import messages from './WMICChangeAddressViewComponent.messages';
import styles from './WMICChangeAddressViewComponent.module.scss';

const paragraphStyle = {marginBottom: '1.5rem'};
function WMICChangeAddressViewComponent(props) {
    const { policyVM, match, setEditMode } = props;
    const translator = useTranslator();
    const history = useHistory();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');

    const handleCancel = () => {
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/${policyType}/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    };

    const addressObj = policyVM.currentPeriod.policyAddress;

    const editButtonAction = () => {
        setEditMode(true);
    };

    const getSubHeadingContent = () => {
        return (
            <p style={paragraphStyle}>
                <strong>{translator(messages.policyAddress)}</strong>
            </p>
        );
    };

    const getAddressContent = () => {
        return (
            <p style={paragraphStyle}>
                {addressObj.addressLine1} <br />
                {addressObj.city}, {addressObj.state} {addressObj.postalCode}{' '}
                <br />
                {addressObj.country}
            </p>
        );
    };

    const getEditAddressButtonSection = () => {
        return (
            <Fragment>
                <WMICButton onClick={handleCancel} type="outlined">
                    { translator(messages.cancel) }
                </WMICButton>
            </Fragment>
        );
    };

    const overrideProps = {
        addressParagraph: {
            content: getAddressContent()
        },

        editAddressSubHeading: {
            content: getSubHeadingContent()
        },

        editButton: {
            content: messages.editButton,
            onClick: editButtonAction
        },

        editAddressButtonSection: {
            content: getEditAddressButtonSection()
        }

    };

    const resolvers = {
        resolveComponentMap: {
            WMICButton
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeAddressViewComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    setEditMode: PropTypes.func.isRequired
};

export default WMICChangeAddressViewComponent;