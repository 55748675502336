import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import messages from './Models.messages';

export default class BodilyInjuryModel {
    constructor({
        bodilyInjuryDeductible,
        propertyDamageDeductible,
        uninsuredBIDeductible,
        personalInjuryDeductible,
        country,
        state
    }) {
        this.bodilyInjuryDeductible = bodilyInjuryDeductible;
        this.propertyDamageDeductible = propertyDamageDeductible;
        this.uninsuredBIDeductible = uninsuredBIDeductible;
        this.personalInjuryDeductible = personalInjuryDeductible;
        this.country = country;
        this.state = state;
    }

    isCalifornia() {
        return this.country === CONSTANTS.COUNTRY.US && this.state === CONSTANTS.STATE.CA;
    }

    toString(translator) {
        if (!this.valid()) {
            throw new Error(translator(messages.countryStateRequired));
        }

        let result = `${translator(messages.applyPolicyLevelCoverageChanges)}`;
        if (this.bodilyInjuryDeductible) {
            result += `Bodily Injury Liability: ${this.bodilyInjuryDeductible}\n`;
        }

        if (this.propertyDamageDeductible) {
            result += `Property Damage Liability: ${this.propertyDamageDeductible}\n`;
        }

        if (this.personalInjuryDeductible) {
            result += `${this.isCalifornia() ? 'Medical Payments Coverage:' : 'Personal Injury Protection Coverage:'} ${this.personalInjuryDeductible}\n`;
        }

        if (this.uninsuredBIDeductible) {
            result += `${this.isCalifornia() ? 'Uninsured Motorist Bodily Injury:' : 'Uninsured/Underinsured Motorist Bodily Injury:'} ${this.uninsuredBIDeductible}\n`;
        }

        result += '\n';

        return result;
    }

    toHTML(translator) {
        if (!this.valid()) {
            throw new Error(translator(messages.countryStateRequired));
        }

        let result = '<br/><span>\n';
        if (this.bodilyInjuryDeductible) {
            result += `<strong>Bodily Injury Liability:</strong> ${this.bodilyInjuryDeductible}<br/>\n`;
        }

        if (this.propertyDamageDeductible) {
            result += `<strong>Property Damage Liability:</strong> ${this.propertyDamageDeductible}<br/>\n`;
        }

        if (this.personalInjuryDeductible) {
            result += `<strong>${this.isCalifornia() ? 'Medical Payments Coverage:' : 'Personal Injury Protection Coverage:'}</strong> ${this.personalInjuryDeductible}<br/>\n`;
        }

        if (this.uninsuredBIDeductible) {
            result += `<strong>${this.isCalifornia() ? 'Uninsured/Underinsured Motorist Bodily Injury:' : 'Uninsured Motorist Bodily Injury:'}</strong> ${this.uninsuredBIDeductible}<br/>\n`;
        }

        result += '</span>';
        return result;
    }

    valid() {
        const required = [this.country, this.state];
        return required.every((v) => v);
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangeOfCoverageRequest);
    }
}

BodilyInjuryModel.coverageInfo = {
    bodilyInjuryDeductible: 'PA_BICov',
    propertyDamageDeductible: 'PA_PDCov',
    uninsuredBIDeductible: 'PA_UNIMSBICov',
    personalInjuryDeductible: 'PA_MedPayCov'
};