import AddressInfoModel from './AddressInfoModel';
import messages from './Models.messages';


export default class AdditionalInterestInfoModel {
    constructor({ name, contractNumber, address }) {
        this.name = name;
        this.contractNumber = contractNumber || 'N/A';
        this.address = address;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.nameAndAddressRequired);
        }

        return `Company Name: ${this.name}
Account Number: ${this.contractNumber}
Street Address: ${this.address.street}
City: ${this.address.city}
State: ${this.address.state}
Zip: ${this.address.zip}`;
    }

    toHTML() {
        return `<span>
<b>Company Name: </b>${this.name}<br/>
<b>Loan or Account#: </b>${this.contractNumber}<br/>
<b>Street: </b>${this.address.street}<br/>
<b>City: </b>${this.address.city}<br/>
<b>State: </b>${this.address.state}<br/>
<b>Zip: </b>${this.address.zip}<br/>
</span>
`;
    }

    valid() {
        const required = [this.name, this.address];
        return required.every((v) => v) && this.address.valid();
    }

    static toObjectModel(obj) {
        const model = Object.setPrototypeOf(obj, AdditionalInterestInfoModel.prototype);
        model.address = Object.setPrototypeOf(obj.address, AddressInfoModel.prototype);
        return model;
    }
}
