// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Contacts__tableContainer{margin-bottom:var(--GW-LAYOUT-6);padding:var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8)}.app__Contacts__tableContainer .app__Contacts__gwTitleStyle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:var(--GW-LAYOUT-2);padding-bottom:var(--GW-LAYOUT-2)}.app__Contacts__gwSectionTitle{--gw-contacts-header-page-bottom-line:2px;border-bottom:var(--gw-contacts-header-page-bottom-line) solid var(--GW-BORDER-COLOR);padding-bottom:var(--GW-LAYOUT-3)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/Policies/Contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA,+BAEE,gCAAA,CADA,gEACA,CAEA,4DAEE,iEAAA,CACA,gCAAA,CAFA,iCAEA,CAIJ,+BACE,yCAAA,CACA,qFAAA,CACA,iCAAA","sourcesContent":[".tableContainer {\n  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);\n  margin-bottom: var(--GW-LAYOUT-6);\n\n  .gwTitleStyle {\n    padding-bottom: var(--GW-LAYOUT-2);\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n    margin-bottom: var(--GW-LAYOUT-2);\n  }\n}\n\n.gwSectionTitle {\n  --gw-contacts-header-page-bottom-line: 2px;\n  border-bottom: var(--gw-contacts-header-page-bottom-line) solid var(--GW-BORDER-COLOR);\n  padding-bottom: var(--GW-LAYOUT-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "app__Contacts__tableContainer",
	"gwTitleStyle": "app__Contacts__gwTitleStyle",
	"gwSectionTitle": "app__Contacts__gwSectionTitle"
};
export default ___CSS_LOADER_EXPORT___;
