import messages from './Models.messages';

export default class ChangePaymentPlanModel {
    constructor({ paymentPlan }) {
        this.paymentPlan = paymentPlan;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.paymentPlanRequired);
        }
        return `New Payment Plan: ${this.paymentPlan}`;
    }

    toHTML() {
        if (!this.valid()) {
            throw new Error(messages.paymentPlanRequired);
        }
        return `<span>
            <b>Change payment plan to:</b> ${this.paymentPlan}<br/>
        </span>`;
    }

    valid() {
        const required = [this.paymentPlan];
        return required.every((v) => v);
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangePaymentPlanRequest);
    }
}
