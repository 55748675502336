// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-quoteContainer-margin:0 auto;--gw-quoteContainer-max-width:50em;--gw-buttonFooter-width:100%;--gw-buttonFooter-margin-top:15px;--gw-buttonFooter-margin-left:1%}.app__NewSubmissionPage__quoteContainer{margin:0 auto;margin:var(--gw-quoteContainer-margin);max-width:50em;max-width:var(--gw-quoteContainer-max-width)}.app__NewSubmissionPage__buttonFooter{display:flex;justify-content:space-between;margin-top:15px;margin-top:var(--gw-buttonFooter-margin-top);width:100%;width:var(--gw-buttonFooter-width)}.app__NewSubmissionPage__buttonStyle{margin-left:1%;margin-left:var(--gw-buttonFooter-margin-left)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/NewQuote/NewQuote/NewSubmission/NewSubmissionPage.module.scss"],"names":[],"mappings":"AAAA,MACI,iCAAA,CACA,kCAAA,CACA,4BAAA,CACA,iCAAA,CACA,gCAAA,CAGJ,wCACI,aAAA,CAAA,sCAAA,CACA,cAAA,CAAA,4CAAA,CAGJ,sCAGI,YAAA,CACA,6BAAA,CAFA,eAAA,CAAA,4CAAA,CADA,UAAA,CAAA,kCAGA,CAEJ,qCACI,cAAA,CAAA,8CAAA","sourcesContent":[":root{\n    --gw-quoteContainer-margin:0 auto;\n    --gw-quoteContainer-max-width: 50em;\n    --gw-buttonFooter-width: 100%;\n    --gw-buttonFooter-margin-top: 15px;\n    --gw-buttonFooter-margin-left: 1%;\n}\n\n.quoteContainer{\n    margin: var(--gw-quoteContainer-margin);\n    max-width: var(--gw-quoteContainer-max-width);\n}\n\n.buttonFooter{\n    width: var(--gw-buttonFooter-width);\n    margin-top: var(--gw-buttonFooter-margin-top);\n    display: flex;\n    justify-content: space-between\n}\n.buttonStyle{\n    margin-left:var(--gw-buttonFooter-margin-left);\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quoteContainer": "app__NewSubmissionPage__quoteContainer",
	"buttonFooter": "app__NewSubmissionPage__buttonFooter",
	"buttonStyle": "app__NewSubmissionPage__buttonStyle"
};
export default ___CSS_LOADER_EXPORT___;
