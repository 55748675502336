/* eslint-disable max-len */
import React, {
    useContext, useState, useEffect, Fragment
} from 'react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICCheckbox } from 'wmic-components-platform-react';
import chequeImg from 'wmic-amp-digital-theme-styles/images/cheque-image.gif';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import classNames from 'classnames';
import messages from './WMICManageBankingInformation.messages';
import styles from './WMICManageBankingInformation.module.scss';

import { RadioButtonField, InputField } from '@jutro/legacy/components';

const WMICBankAccount = (props) => {
    const {
        bankInfo,
        onChange,
        newData
    } = props;

    const DOM_ELEMENT_IDS = {
        BANK_ACCOUNT_TYPE_RADIO: 'bankAccountTypeRadio',
        ROUTING_TRANSIT_NUMBER_INPUT: 'routingTransitNumber',
        BANK_ACCOUNT_NUMBER_INPUT: 'bankAccountNumber',
        BANK_ACCOUNT_NICKNAME_INPUT: 'bankAccountNickname',
        AGREE_BANKING_INFO_STORAGE_CHECKBOX: 'agreeBankingInfoStorage'
    };
    const VALIDATORS = {
        ROUTING_TRANSIT_NUMBER: /^\d{9}$/,
        BANK_ACCOUNT_NUMBER: /^\d{1,17}$/,
        POSTAL_CODE: /^\d{5}-\d{4}$/
    };

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const [bankAccountData, updateBankAccountData] = useState(undefined);
    const [initialBankAccountData, updateInitialBankAccountData] = useState(undefined);
    const [bankAccountPristine, updateBankAccountPristine] = useState(undefined);
    const [bankAccountType, updateBankAccountType] = useState(undefined);
    const [routingTransitNumber, updateRoutingTransitNumber] = useState(undefined);
    const [bankAccountNumber, updateBankAccountNumber] = useState(undefined);
    const [currentBankAccountNumber, updateCurrentBankAccountNumber] = useState(undefined);
    const [bankAccountNickname, updateBankAccountNickname] = useState(undefined);
    const [isPrimary, updateIsPrimary] = useState(undefined);

    const initBankAccountPristine = () => {
        return {
            bankAccountTypePristine: _.get(bankAccountData, 'bankAccountType'),
            routingTransitNumberPristine: _.get(bankAccountData, 'bankABANumber'),
            bankAccountNumberPristine: _.get(bankAccountData, 'bankAccountNumber'),
            bankAccountNicknamePristine: _.get(bankAccountData, 'nickName_WMIC'),
            isPrimaryPristine: _.get(bankAccountData, 'isPrimary_WMIC')
        };
    };

    const initBankAccountData = () => {
        if (!_.isUndefined(bankInfo)) {
            return {
                bankAccountNumber: newData ? bankInfo?.bankAccountNumber : '',
                nickName_WMIC: bankInfo?.nickName_WMIC || '',
                isPrimary_WMIC: !!bankInfo?.isPrimary_WMIC,
                bankABANumber: bankInfo?.bankABANumber,
                bankAccountType: bankInfo?.bankAccountType,
                bankName: bankInfo?.bankName,
                publicId_WMIC: bankInfo?.publicId_WMIC
            };
        }
        return {
            bankABANumber: '',
            bankAccountNumber: '',
            bankAccountType: undefined,
            bankName: undefined,
            nickName_WMIC: undefined,
            isPrimary_WMIC: false,
            publicId_WMIC: ''
        };
    };

    useEffect(() => {
        updateCurrentBankAccountNumber(bankInfo ? bankInfo.bankAccountNumber : '');
        const initializedBankAccountData = initBankAccountData();
        updateBankAccountData(initializedBankAccountData);
        updateInitialBankAccountData({ ...initializedBankAccountData });
        updateBankAccountType(initializedBankAccountData?.bankAccountType);
        const accountBankDetailVM = viewModelService.create(
            initializedBankAccountData,
            'bc',
            'edge.capabilities.billing.dto.AccountBankDetailsDTO'
        ).value;
        updateRoutingTransitNumber(accountBankDetailVM?.bankABANumber);
        updateBankAccountNumber(accountBankDetailVM?.bankAccountNumber);
        updateBankAccountNickname(accountBankDetailVM?.nickName_WMIC);
        updateIsPrimary(accountBankDetailVM?.isPrimary_WMIC);
        updateBankAccountData(accountBankDetailVM);
        updateBankAccountPristine(initBankAccountPristine());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isBankAccountDataChanged = () => {
        return !(
            (bankAccountPristine?.bankAccountTypePristine === _.get(bankAccountData, 'bankAccountType'))
            && (bankAccountPristine?.routingTransitNumberPristine === _.get(bankAccountData, 'bankABANumber'))
            && (_.get(bankAccountData, 'bankAccountNumber') === '')
            && (bankAccountPristine?.bankAccountNicknamePristine === _.get(bankAccountData, 'nickName_WMIC'))
            && (bankAccountPristine?.isPrimaryPristine ===  _.get(bankAccountData, 'isPrimary_WMIC'))
        );
    };

    const isBankAccountNumberValid = () => VALIDATORS.BANK_ACCOUNT_NUMBER.test(bankAccountData?.bankAccountNumber);

    const isBankAccountFormValid = () => {
        const bankAccountNumberValid = newData ? isBankAccountNumberValid()
            : bankAccountData?.bankAccountNumber === '' || isBankAccountNumberValid();
        return VALIDATORS.ROUTING_TRANSIT_NUMBER.test(bankAccountData?.bankABANumber) && bankAccountNumberValid;
    };

    const canUserSubmit = () => {
        const isValid = isBankAccountFormValid() && isBankAccountDataChanged();
        onChange(bankAccountData, isValid);
    };

    const bankAccountTypeEdited = (newValue) => {
        updateBankAccountType(newValue);
        _.set(bankAccountData, 'bankAccountType', newValue);
        canUserSubmit();
    };

    const bankAccountRoutingNumberEdited = (newValue) => {
        updateRoutingTransitNumber(newValue);
        _.set(bankAccountData, 'bankABANumber', newValue);
        canUserSubmit();
    };

    const bankAccountNumberEdited = (newValue) => {
        updateBankAccountNumber(newValue);
        _.set(bankAccountData, 'bankAccountNumber', newValue);
        canUserSubmit();
    };

    const bankAccountNicknameEdited = (newValue) => {
        updateBankAccountNickname(newValue);
        _.set(bankAccountData, 'nickName_WMIC', newValue);
        canUserSubmit();
    };

    const bankAccountIsPrimaryEdited = (newValue) => {
        updateIsPrimary(newValue);
        _.set(bankAccountData, 'isPrimary_WMIC', newValue);
        canUserSubmit();
    };

    const getAgreeLabel = () => {
        const bold = styles['ww-span-bold'];
        const italic = styles['ww-span-italic'];
        const text1 = translator(messages.makeThisPrimary);
        const text2 = translator(messages.yourPrimaryAccountWillBeUsed);
        return (
            <span className={styles['ww-agree-label-container']}>
                <span className={bold}>{text1}</span>
                <span className={italic}>{text2}</span>
            </span>
        );
    };

    return (
        <Fragment>
            <div>
                <h3 className="ww-payment-subhead">
                    {translator(messages.enterBankAccountInformation)}
                </h3>
                <div className={styles['wmic-radio-wrap']}>
                    <RadioButtonField
                        availableValues={[
                            {
                                code: 'checking',
                                name: translator(messages.checking)
                            },
                            {
                                code: 'savings',
                                name: translator(messages.savings)
                            }
                        ]}
                        id={DOM_ELEMENT_IDS.BANK_ACCOUNT_TYPE_RADIO}
                        value={bankAccountType}
                        onValueChange={(newValue) => bankAccountTypeEdited(newValue)}
                        label={translator(messages.bankAccountType)}
                        labelClassName={styles['wmic-radio-button-label']}
                    />
                </div>
                { bankAccountType === undefined && (
                    <p className={classNames('ww-subscript', styles['wmic-payment-text'])}>
                        {translator(messages.pleaseChooseAnAccountTypeBeforeProceeding)}
                    </p>
                )}
                { bankAccountType !== undefined && (
                    <div>
                        <p className={styles['wmic-large-footer']}>
                            <i className={classNames('fa fa-exclamation-circle', styles['wmic-icon-notification'])} />
                            {translator(messages.nonSufficientFundsFeeWillBeAssessed)}
                            <img src={chequeImg} alt={translator(messages.chequeSample)} />
                            <span className="ww-subscript">{translator(messages.checkNumberIsNotARequiredField)}</span>
                        </p>
                        <div className={styles['wmic-input-container']}>
                            <InputField
                                id={DOM_ELEMENT_IDS.ROUTING_TRANSIT_NUMBER_INPUT}
                                value={routingTransitNumber}
                                label={translator(messages.routingTransitNumber)}
                                onValueChange={(newValue) => bankAccountRoutingNumberEdited(newValue)}
                                validator={{
                                    pattern: VALIDATORS.ROUTING_TRANSIT_NUMBER,
                                    message: translator(messages.routingTransitNumberMustBe9DigitsLong)
                                }}
                            />
                        </div>
                        <div className={styles['wmic-input-container']}>
                            <InputField
                                id={DOM_ELEMENT_IDS.BANK_ACCOUNT_NUMBER_INPUT}
                                value={bankAccountNumber}
                                label={translator(messages.bankAccountNumber)}
                                onValueChange={(newValue) => bankAccountNumberEdited(newValue)}
                                className={styles['wmic-input-field']}
                                labelClassName={styles['wmic-input-field-label']}
                                validator={{
                                    pattern: VALIDATORS.BANK_ACCOUNT_NUMBER,
                                    message: translator(messages.bankAccountNumberMustBe1To17Digits)
                                }}
                            />
                        </div>
                        { !newData && (
                            <div>
                                <div>
                                    <strong>{translator(messages.yourCurrentAccountNumber)}</strong>
                                    <span>{ currentBankAccountNumber }</span>
                                </div>
                                <div className={styles['wmic-input-container-with-tooltip']}>
                                    <label htmlFor={DOM_ELEMENT_IDS.BANK_ACCOUNT_NICKNAME_INPUT}>
                                        <span>{translator(messages.nickname)}</span>
                                        <WMICClickableIconTooltipComponent
                                            tooltipMessage={translator(messages.forYourSecurity)}
                                        />
                                    </label>
                                    <InputField
                                        id={DOM_ELEMENT_IDS.BANK_ACCOUNT_NICKNAME_INPUT}
                                        value={bankAccountNickname}
                                        onValueChange={(newValue) => bankAccountNicknameEdited(newValue)}
                                        className={styles['wmic-input-field']}
                                    />
                                </div>
                                <WMICCheckbox
                                    id={DOM_ELEMENT_IDS.AGREE_BANKING_INFO_STORAGE_CHECKBOX}
                                    value={isPrimary}
                                    label={getAgreeLabel()}
                                    onValueChange={
                                        (newValue) => bankAccountIsPrimaryEdited(newValue)
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    );
};

WMICBankAccount.propTypes = {
    bankInfo: PropTypes.shape({
        bankABANumber: PropTypes.string,
        bankAccountNumber: PropTypes.string,
        bankAccountType: PropTypes.string,
        bankName: PropTypes.string,
        nickName_WMIC: PropTypes.string,
        isPrimary_WMIC: PropTypes.string,
        publicId_WMIC: PropTypes.string
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    newData: PropTypes.bool.isRequired
};

export default WMICBankAccount;