// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICProgressModal__WMICProgressModal{border-radius:6px;width:40%}.app__WMICProgressModal__WMICModal__overlay{background-color:rgba(0,0,0,.7)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-common-react/components/WMICProgressModal/WMICProgressModal.module.scss"],"names":[],"mappings":"AAAA,2CAEI,iBAAA,CADA,SACA,CAGJ,4CACI,+BAAA","sourcesContent":[".WMICProgressModal {\n    width: 40%;\n    border-radius: 6px;\n}\n\n.WMICModal__overlay {\n    background-color: rgba(0,0,0,0.7);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WMICProgressModal": "app__WMICProgressModal__WMICProgressModal",
	"WMICModal__overlay": "app__WMICProgressModal__WMICModal__overlay"
};
export default ___CSS_LOADER_EXPORT___;
