/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { publish, JUTRO_TOPICS } from '@jutro/events';
import {
    ModalNext, ModalHeader, ModalBody, ModalFooter
} from '@jutro/components';
import { WalletService } from 'gw-capability-policy';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICButton } from 'wmic-components-platform-react';
import googlePlayBadge from 'wmic-amp-digital-theme-styles/images/google-play-badge.png';
import userProfileMessages from '../WMICInsuranceCardModal.messages';
import messages from './WMICAndroidStepsModal.messages';
import styles from './WMICAndroidStepsModal.module.scss';
import {WMICLink} from "wmic-components-amp-common-react";

const WMICAndroidStepsModal = (props) => {
    const {
        isOpen, onReject, policyNumber, termNumber, vehicles, authHeader
    } = props;
    const translator = useTranslator();
    const [googlePayJwtTokens, updateGooglePayJwtTokens] = useState([]);
    const [loadJwtTokenPromises, updateLoadJwtTokenPromises] = useState([]);
    const retrievedGPayTokens = useRef([]);

    // eslint-disable-next-line no-unused-vars
    const googlePaySuccessHandler = () => {
        // when the pass is successfully added to someone's account, this function is called. Consider adding tracking in here.
    };

    const renderGooglePayButton = useCallback ((vehicleNumber, googlePayJwtToken) => {
        if (window.gapi) {
            if (googlePayJwtToken) {
                window.gapi.savetoandroidpay.render(`saveToAndroidPay${vehicleNumber}`, {
                    jwt: googlePayJwtToken,
                    onsuccess: 'googlePaySuccessHandler'
                });
            }
        }
    }, []);

    const getGooglePlayJwtToken = useCallback ((vehicleNumber) => {
        const tokenPromises = [...loadJwtTokenPromises];
        tokenPromises[vehicleNumber] = WalletService.getJwtToken(policyNumber, termNumber, vehicleNumber, authHeader);
        tokenPromises[vehicleNumber].then((response) => response.json()).then((response) => {
            if (response && response.token) {
                const tokens = [...retrievedGPayTokens.current];
                tokens[vehicleNumber] = response.token;
                retrievedGPayTokens.current = tokens;
                updateGooglePayJwtTokens(retrievedGPayTokens.current);
                renderGooglePayButton(vehicleNumber, response.token);
            }
        });
        updateLoadJwtTokenPromises(tokenPromises)
    }, [authHeader, renderGooglePayButton,googlePayJwtTokens, loadJwtTokenPromises]);

    useEffect(() => {
        const loadGoogleAPI = async () => {
            new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.async = true;
                document.head.appendChild(script);
                script.src = 'https://apis.google.com/js/platform.js';
                script.type = 'text/javascript';
                script.onerror = (err) => {
                    reject(err);
                };
                script.onload = () => {
                    resolve(window.gapi.savetoandroidpay);
                };
            }).then(() => {
                // loop through all vehicles and get the JWT token for them in preparation to render the button
                vehicles.forEach((vehicle) => {
                    getGooglePlayJwtToken(vehicle.vehicleNumber);
                });
            });
        };

        loadGoogleAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendClickEvent = () => {
        publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.downloadAndroidIdCardTrackButtonIdentifier)});
    };

    const handleClose = () => {
        onReject();
    };
    
    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose} shouldCloseOnEsc>
            <ModalHeader
                title={translator(userProfileMessages.insuranceIDCard)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: 'true'
                    }
                }}
            />
            <ModalBody>
                <div className={styles['digital-wallet']}>
                    <h3 className={styles['ww-editorial-subhead']}>{translator(messages.followTheseSteps)}</h3>
                    <ol className={styles['wmic-ol']}>
                        <li>
                            {translator(messages.usingYourAndroidDevice)}
                            <br/>
                            <WMICLink className={styles['ww-store-badge']} trackButtonIdentifier={translator(messages.downloadGooglePayTrackButtonIdentifier)} target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.walletnfcrel">
                                <img src={googlePlayBadge} />
                            </WMICLink>
                        </li>
                        <li>{translator(messages.onceGooglePayInstalled)}</li>
                        <li>{translator(messages.theCardIsNowAdded)}</li>
                    </ol>
                    <h3 className={styles['ww-editorial-subhead']}>{translator(messages.selectTheVehicle)}</h3>
                    <div>
                        {vehicles.map((vehicle) => (
                                <div id={`googlePayButtonWrapper${vehicle.vehicleNumber}`} key={`poiButton${vehicle.vehicleNumber}`}
                                    onClick={sendClickEvent}>
                                    <p className={styles["wmic-sm-footing"]}>
                                        <strong>
                                            {translator(messages.download, {
                                                year: vehicle.year, make: vehicle.make, model: vehicle.model, vehicleNumber: vehicle.vehicleNumber
                                            })}
                                        </strong>
                                    </p>
                                    <div id={`saveToAndroidPay${vehicle.vehicleNumber}`} />
                                    {!googlePayJwtTokens[vehicle.vehicleNumber] && (
                                        <WMICLoader isInline>&nbsp;</WMICLoader>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    type="primary"
                    onClick={() => onReject()}
                >
                    {translator(messages.close)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICAndroidStepsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    policyNumber: PropTypes.string.isRequired,
    termNumber: PropTypes.number.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default WMICAndroidStepsModal;
