import { defineMessages } from 'react-intl';

export default defineMessages({
    youCanEditYourVehicleDeductibles: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.You can edit your vehicle deductibles',
        defaultMessage: 'You can edit your vehicle deductibles and rental car coverage from your online account. Give us a call if you want to make other policy changes and look for more policy editing options in the future.'
    },
    editVehicleCoverages: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Edit Vehicle Coverages',
        defaultMessage: 'Edit Vehicle Coverages'
    },
    editVehicleCoverage: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Edit Vehicle Coverage',
        defaultMessage: 'Edit Vehicle Coverage'
    },
    coverageChangesWillOnlyApplyToTheVehicleYouveSelected: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Coverage changes will only apply to the vehicle you’ve selected. Select a vehicle to get started.',
        defaultMessage: 'Coverage changes will only apply to the vehicle you’ve selected. Select a vehicle to get started.'
    },
    yourChangesHaveBeenSavedClickContinueToGetAQuote: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Your changes have been saved. Click "Continue" to get a quote.',
        defaultMessage: 'Your changes have been saved. Click "Continue" to get a quote.'
    },
    yourChangesHaveBeenSavedClickContinueToGetAQuoteOrEditAnotherVehicle: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Your changes have been saved. Click "Continue" to get a quote or edit another vehicle.',
        defaultMessage: 'Your changes have been saved. Click "Continue" to get a quote or edit another vehicle.'
    },
    youllBeAbleToPeviewYourUpdatedPremiumBeforeYouConfirmTheseChanges: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.You’ll be able to review your updated premium before you confirm these changes.',
        defaultMessage: 'You’ll be able to review your updated premium before you confirm these changes.'
    },
    cancelEdit: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Cancel Edit',
        defaultMessage: 'Cancel Edit'
    },
    cancel: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Cancel',
        defaultMessage: 'Cancel'
    },
    continue: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Continue',
        defaultMessage: 'Continue'
    },
    saveAndContinue: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Save and Continue',
        defaultMessage: 'Save and Continue'
    },
    vehicleCoverages: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages'
    },
    selectVehicleCoveragesForYour: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Select vehicle coverages for your',
        defaultMessage: 'Select vehicle coverages for your'
    },
    insuredTrailerLimits: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Insured Trailer Limits',
        defaultMessage: 'Insured Trailer Limits'
    },
    trailersMustHaveMatchingCollisionAndComprehensiveCoverageDeductibles: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Trailers must have matching collision and comprehensive coverage deductibles.',
        defaultMessage: 'Trailers must have matching collision and comprehensive coverage deductibles.'
    },
    deductiblesCannotBeLoweredFromTheirCurrentAmountsOnlineForAssistancePleaseCall: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Deductibles cannot be lowered from their current amounts online. For assistance, please call',
        defaultMessage: 'Deductibles cannot be lowered from their current amounts online. For assistance, please call '
    },
    assistancePhoneNumber: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.1-800-640-2920',
        defaultMessage: '1-800-640-2920'
    },
    assistanceHours: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Monday – Friday 7:30 am – 7:30 pm and Saturday 8:00 am – 4:30 pm.',
        defaultMessage: ' Monday – Friday 7:30 am – 7:30 pm and Saturday 8:00 am – 4:30 pm.'
    },
    trailerCoverageDeductiblesMustMatch: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.Trailer coverage deductibles must match.',
        defaultMessage: 'Trailer coverage deductibles must match.'
    }
});