import { defineMessages } from 'react-intl';

export default defineMessages({
    sectionTitle: {
        id: 'policy.common.component.contacts.Policy Contacts',
        defaultMessage: 'Policy Contacts'
    },
    contactName: {
        id: 'policy.common.component.contacts.Name',
        defaultMessage: 'Name'
    },
    contactRole: {
        id: 'policy.common.component.contacts.Role',
        defaultMessage: 'Role'
    },
    contactEmail: {
        id: 'policy.common.component.contacts.Email',
        defaultMessage: 'Email'
    },
    contactPhone: {
        id: 'policy.common.component.contacts.Phone Number',
        defaultMessage: 'Phone Number'
    },
    contactAddress: {
        id: 'policy.common.component.contacts.Address',
        defaultMessage: 'Address'
    },
    contactDOB: {
        id: 'policy.common.component.contacts.Date of Birth',
        defaultMessage: 'Date of Birth'
    }
});
