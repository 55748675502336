import React from 'react';
import PropTypes from 'prop-types';
import { Flex, FlexItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { WMICButton } from 'wmic-components-platform-react';
import styles from './WMICIDCardOption.module.scss';
import messages from './WMICIDCardOption.messages';

const WMICIDCardOption = ({
    icon,
    title,
    subtitle,
    buttons,
    emailSent
}) => {
    const translator = useTranslator();
    const renderEmailSent = () => {
        return emailSent && (
            <div>
                <p className={styles.wwEmailSent}>
                    {translator(messages.emailSent)}
                </p>
            </div>
        );
    };

    return (
        <Flex
            direction="column"
            alignItems="center"
            gap="none"
            className={styles.wrapper}
            tablet={{ className: styles.mobileWrapper }}
        >
            <FlexItem>
                {icon}
            </FlexItem>
            <FlexItem>
                <h2 className={styles.title}>{translator(title)}</h2>
            </FlexItem>
            <FlexItem>
                <div className={styles.subtitle}>{translator(subtitle)}</div>
            </FlexItem>
            {
                buttons.map(
                    ({ children, onClick }, index) => (
                        <FlexItem key={index} className={styles.buttonContainer}>
                            {
                                React.isValidElement(children) 
                                    ? (children) 
                                    : (
                                        <WMICButton
                                            onClick={onClick}
                                            type="secondary"
                                        >
                                            {children}
                                        </WMICButton>
                                    )
                            }
                        </FlexItem>

                    )
                )
            }
            { renderEmailSent() }
        </Flex>
    );
};

WMICIDCardOption.propTypes = {
    icon: PropTypes.node.isRequired,
    title: intlMessageShape.isRequired,
    subtitle: intlMessageShape.isRequired,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            children: PropTypes.oneOfType([intlMessageShape, PropTypes.node]),
            onClick: PropTypes.func
        })
    ).isRequired,
    emailSent: PropTypes.bool
};

WMICIDCardOption.defaultProps = {
    emailSent: false
};

export default WMICIDCardOption;
