import { defineMessages } from 'react-intl';

export default defineMessages({
    deductible: {
        id: 'wmic.component.vehicle-info-box-component.Deductible',
        defaultMessage: 'Deductible'
    },
    includedVehicleCoverage: {
        id: 'wmic.component.vehicle-info-box-component.IncludedVehicleCoverage',
        defaultMessage: 'INCLUDED VEHICLE COVERAGE'
    },
    vehicleDetails: {
        id: 'wmic.component.vehicle-info-box-component.VehicleDetails',
        defaultMessage: 'VEHICLE DETAILS'
    },
    assignedDrivers: {
        id: 'wmic.component.vehicle-info-box-component.AssignedDrivers',
        defaultMessage: 'ASSIGNED DRIVERS'
    },
    primaryUse: {
        id: 'wmic.component.vehicle-info-box-component.PrimaryUse',
        defaultMessage: 'Primary Use'
    },
    primaryUseTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PrimaryUseTooltip',
        defaultMessage: 'A vehicle\'s primary use is the actual use of the vehicle by the principal operator, or by the operator who uses the vehicle most frequently. (i.e. Business, Pleasure, Drive to Work/School).'
    },
    annualMileage: {
        id: 'wmic.component.vehicle-info-box-component.AnnualMileage',
        defaultMessage: 'Annual Mileage'
    },
    lastOdometerReading: {
        id: 'wmic.component.vehicle-info-box-component.LastOdometerReading',
        defaultMessage: 'Last Odometer Reading'
    },
    lastOdometerReadingDate: {
        id: 'wmic.component.vehicle-info-box-component.LastOdometerReadingDate',
        defaultMessage: 'Last Odometer Reading Date'
    },
    vehiclePremium: {
        id: 'wmic.component.vehicle-info-box-component.VehiclePremium',
        defaultMessage: 'Vehicle Premium'
    },
    vehiclePremiumTooltip: {
        id: 'wmic.component.vehicle-info-box-component.VehiclePremiumTooltip',
        defaultMessage: 'The Vehicle Premium amount represents the total cost per vehicle for your entire policy term period. If you made any policy changes mid-term, the Vehicle Premium amount displayed is not prorated based on your policy change(s).'
    },
    comprehensive: {
        id: 'wmic.component.vehicle-info-box-component.Comprehensive',
        defaultMessage: 'Comprehensive'
    },
    collision: {
        id: 'wmic.component.vehicle-info-box-component.Collision',
        defaultMessage: 'Collision'
    },
    rentalExpense: {
        id: 'wmic.component.vehicle-info-box-component.RentalExpense',
        defaultMessage: 'Rental Expense'
    },
    limitPerDay: {
        id: 'wmic.component.vehicle-info-box-component.LimitPerDay',
        defaultMessage: 'limit per day'
    },
    limitPerOccurrence: {
        id: 'wmic.component.vehicle-info-box-component.LimitPerOccurrence',
        defaultMessage: 'limit per occurrence'
    },
    unims: {
        id: 'wmic.component.vehicle-info-box-component.Unims',
        defaultMessage: 'Uninsured Motorist Property Damage'
    },
    limit: {
        id: 'wmic.component.vehicle-info-box-component.Limit',
        defaultMessage: 'Limit'
    },
    notTaken: {
        id: 'wmic.component.vehicle-info-box-component.NotTaken',
        defaultMessage: 'Not Taken'
    },
    unimsColl: {
        id: 'wmic.component.vehicle-info-box-component.UnimsColl',
        defaultMessage: 'Uninsured Motorist Collision'
    },
    specialVehicleEquipmentCoverage: {
        id: 'wmic.component.vehicle-info-box-component.SpecialVehicleEquipmentCoverage',
        defaultMessage: 'Special Vehicle Equipment Coverage'
    },
    roadsideAssistance: {
        id: 'wmic.component.vehicle-info-box-component.RoadsideAssistance',
        defaultMessage: 'Roadside Assistance'
    },
    included: {
        id: 'wmic.component.vehicle-info-box-component.Included',
        defaultMessage: 'Included'
    },
    vehicleManufacturerReplacementParts: {
        id: 'wmic.component.vehicle-info-box-component.VehicleManufacturerReplacementParts',
        defaultMessage: 'Vehicle Manufacturer Replacement Parts'
    },
    paComprehensiveCovTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaComprehensiveCovTooltip',
        defaultMessage: 'Pays for loss or damage to your vehicle by the following causes, excluding collision: fire, theft, earthquake, explosion, glass breakage, riot, vandalism and wind storm. Subject to your deductible.'
    },
    paCollisionCovTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaCollisionCovTooltip',
        defaultMessage: 'Provides protection for loss or damage to your vehicle caused by upset or collision with another vehicle or object. Subject to your deductible.'
    },
    paUnimsCovTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaUnimsCovTooltip',
        defaultMessage: 'Available on all vehicles not insured for Collision. Provides up to $3,500 towards damages caused by collision with an uninsured vehicle where the driver or vehicle is identified and legally liable.'
    },
    paUnimsCovTooltipCA: {
        id: 'wmic.component.vehicle-info-box-component.PaUnimsCovTooltipCA',
        defaultMessage: 'Available on all vehicles not insured for Collision. Provides up to $3,500 towards damages caused by collision with an uninsured vehicle where the driver or vehicle is identified and legally liable.'
    },
    paUnimsCovTooltipOR: {
        id: 'wmic.component.vehicle-info-box-component.PaUnimsCovTooltipOR',
        defaultMessage: 'Available on all vehicles and provides up to $20,000 towards the lesser of either repairs or to the actual cash value of your vehicle for damages caused by an uninsured vehicle.'
    },
    paUnimsCollTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaUnimsCollTooltip',
        defaultMessage: 'This deductible applies when damage to your vehicle is caused by direct physical contact with an uninsured vehicle where that driver or vehicle is identified and legally liable.'
    },
    paRentalExpenseCovTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaRentalExpenseCovTooltip',
        defaultMessage: 'Provides for expenses for a rental vehicle, public transit, or even rideshare services while your vehicle is being repaired due to an incident covered under Collision or Comprehensive coverage.'
    },
    paSpecialVehicleEquipmentCoverageCovTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaSpecialVehicleEquipmentCoverageCovTooltip',
        defaultMessage: 'Coverage is included up to a limit of $1500 including custom parts and equipment for your auto. This limit is increased by purchasing additional coverage.'
    },
    paRoadsideAssistanceCovTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaRoadsideAssistanceCovTooltip',
        defaultMessage: 'Pays up to $100 per disablement when using our Roadside Assistance Sign and Drive Option. Max reimbursement using any other licensed provider is $50 per disablement. Lockout Service limited to $100.'
    },
    paRoadsideAssistanceCovTooltipAfterRPC1578: {
        id: 'wmic.component.vehicle-info-box-component.paRoadsideAssistanceCovTooltipAfterRPC1578',
        defaultMessage: 'Pays up to $200 per disablement when using our Roadside Assistance Sign and Drive Option. Max reimbursement using any other licensed provider is $100 per disablement. Lockout Service limited to $100.'
    },
    paVehicleManufacturerReplacementPartsCovTooltip: {
        id: 'wmic.component.vehicle-info-box-component.PaVehicleManufacturerReplacementPartsCovTooltip',
        defaultMessage: 'Provides coverage to repair or replace property or parts with those made for or by the Original Equipment Manufacturer.'
    },
    pleasureUse: {
        id: 'wmic.component.vehicle-info-box-component.PleasureUse',
        defaultMessage: 'Pleasure Use'
    },
    businessUse: {
        id: 'wmic.component.vehicle-info-box-component.BusinessUse',
        defaultMessage: 'Business Use'
    },
    commutingUse: {
        id: 'wmic.component.vehicle-info-box-component.CommutingUse',
        defaultMessage: 'Drive to Work/School'
    },
    mi: {
        id: 'wmic.component.vehicle-info-box-component.mi',
        defaultMessage: 'mi'
    }
});
