/* eslint-disable react/no-unknown-property */
 
import React from 'react';
import {ICON_NAMES, LOBConstants, JobType, REDIRECT_PATHS_FOR_JOB_TYPES} from "wmic-pe-portals-utils-js";
import { LobIconUtil } from '@xengage/gw-portals-util-js'

const isElementVisible = (element) => {
    return !(element === null || element === '' || element === undefined);
};

const getTextWithIcon = (iconClass, aMessage, messageClass) => {
    const getMessageHTML = () => {
        if (messageClass === undefined) {
            return aMessage;
        }
        return (<span class={messageClass}>{aMessage}</span>);
    };

    return (
        <span>
            <icon class={iconClass} /> {getMessageHTML()}
        </span>
    );
};

const getLobIcon = (productCode) =>{
    switch (productCode) {
        case LOBConstants.CP:
            return ICON_NAMES.ICON_COMMERCIAL;
        case LOBConstants.PUP:
            return ICON_NAMES.ICON_UMBRELLA;
        default:
            return LobIconUtil.getMaterialIcon(productCode);
    }
}

const getRedirectURLForJob = (jobVM) => {
    const jobType = _.get(jobVM.value, 'baseData.jobType');
    
    let jobID;

    if (jobType === JobType.SUBMISSION) {
        jobID = _.get(jobVM.value, 'quoteID');
    } else {
        jobID = _.get(jobVM.value, 'jobID');
    }

    let redirectURL = '';

    switch (jobType) {
        case JobType.SUBMISSION:
            redirectURL = REDIRECT_PATHS_FOR_JOB_TYPES.QUOTE_PAGE_URL(jobID);
            break;
        case JobType.POLICY_CHANGE:
            redirectURL = REDIRECT_PATHS_FOR_JOB_TYPES.POLICYCHANGE_URL(jobID);
            break;
        case JobType.CANCELLATION:
            redirectURL = REDIRECT_PATHS_FOR_JOB_TYPES.CANCELLATION_URL(jobID);
            break;
        case JobType.RENEWAL:
            redirectURL = REDIRECT_PATHS_FOR_JOB_TYPES.RENEWAL_URL(jobID);
            break;
        default:
            break;
    }

    return redirectURL;
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
}

function scrollToElement(element) {
    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
    }
}

function scrollToId(id) {
    const element = document.getElementById(id);
    scrollToElement(element);
}


export default {
    isElementVisible: (element) => isElementVisible(element),
    isElementAccessible: (element) => isElementVisible(element),
    getTextWithIcon,
    getLobIcon,
    getRedirectURLForJob,
    scrollToTop,
    scrollToElement,
    scrollToId
};
