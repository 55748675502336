// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICHOAdditionalInterests__newAIFormWrapper{border:1px solid #ccc;border-radius:var(--GW-BORDER-RADIUS);flex-wrap:nowrap;padding:var(--GW-SPACING-10);width:100%}.app__WMICHOAdditionalInterests__fullWidth{width:100%}.app__WMICHOAdditionalInterests__newAIDropdownMenuButton{position:static}.app__WMICHOAdditionalInterests__newAICompanyHeading{border-bottom:1px solid var(--GW-BORDER-COLOR-1);margin-bottom:0}.app__WMICHOAdditionalInterests__addNewCompanyButton{width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-ho-react/components/WMICHOAdditionalInterests/WMICHOAdditionalInterests.module.scss"],"names":[],"mappings":"AAEA,kDAEI,qBAAA,CACA,qCAAA,CAEA,gBAAA,CADA,4BAAA,CAHA,UAIA,CAGJ,2CACE,UAAA,CAGF,yDACI,eAAA,CAGJ,qDACE,gDAAA,CACA,eAAA,CAGF,qDACE,sBAAA,CAAA,iBAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n\n.newAIFormWrapper {\n    width: 100%;\n    border: 1px solid $filter-type-section-color;\n    border-radius: var(--GW-BORDER-RADIUS);\n    padding: var(--GW-SPACING-10);\n    flex-wrap: nowrap;\n}\n\n.fullWidth {\n  width: 100%;\n}\n\n.newAIDropdownMenuButton {\n    position: unset;\n}\n\n.newAICompanyHeading {\n  border-bottom: 1px solid var(--GW-BORDER-COLOR-1);\n  margin-bottom: 0rem;\n}\n\n.addNewCompanyButton {\n  width: fit-content;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newAIFormWrapper": "app__WMICHOAdditionalInterests__newAIFormWrapper",
	"fullWidth": "app__WMICHOAdditionalInterests__fullWidth",
	"newAIDropdownMenuButton": "app__WMICHOAdditionalInterests__newAIDropdownMenuButton",
	"newAICompanyHeading": "app__WMICHOAdditionalInterests__newAICompanyHeading",
	"addNewCompanyButton": "app__WMICHOAdditionalInterests__addNewCompanyButton"
};
export default ___CSS_LOADER_EXPORT___;
