import { defineMessages } from 'react-intl';

export default defineMessages({
    policyCoverageLimits: {
        id: 'wmic.component.policy-coverage-info-box-component.policyCoverageLimits',
        defaultMessage: 'POLICY COVERAGE LIMITS'
    },
    bodilyInjuryLiability: {
        id: 'wmic.component.policy-coverage-info-box-component.bodilyInjuryLiability',
        defaultMessage: 'Bodily Injury Liability'
    },
    bodilyInjuryLiabilityTooltip: {
        id: 'wmic.component.policy-coverage-info-box-component.bodilyInjuryLiabilityTooltip',
        defaultMessage: 'Protects against financial loss should your vehicle injure or kill anyone and you are legally liable. Includes related legal defense, court and investigative costs.'
    },
    perPerson: {
        id: 'wmic.component.policy-coverage-info-box-component.perPerson',
        defaultMessage: ' per person'
    },
    perOccurrence: {
        id: 'wmic.component.policy-coverage-info-box-component.perOccurrence',
        defaultMessage: ' per occurrence'
    },
    propertyDamageLiability: {
        id: 'wmic.component.policy-coverage-info-box-component.propertyDamageLiability',
        defaultMessage: 'Property Damage Liability'
    },
    propertyDamageLiabilityTooltip: {
        id: 'wmic.component.policy-coverage-info-box-component.propertyDamageLiabilityTooltip',
        defaultMessage: 'If you are legally liable, this coverage protects you against financial loss when your vehicle damages another person\'s property, such as their vehicle.'
    },
    personalInjuryProtection: {
        id: 'wmic.component.policy-coverage-info-box-component.personalInjuryProtection',
        defaultMessage: 'Personal Injury Protection'
    },
    personalInjuryProtectionTooltip: {
        id: 'wmic.component.policy-coverage-info-box-component.personalInjuryProtectionTooltip',
        defaultMessage: 'Provides reimbursement of medical and hospitalization expenses, payment for income continuation, loss of essential services, child care, and funeral expenses incurred due to an accident.'
    },
    medicalPayments: {
        id: 'wmic.component.policy-coverage-info-box-component.medicalPayments',
        defaultMessage: 'Medical Payments'
    },
    medicalPaymentsTooltip: {
        id: 'wmic.component.policy-coverage-info-box-component.medicalPaymentsTooltip',
        defaultMessage: 'Pays medical, dental, hospitalization and funeral expenses for yourself and passengers, up to your limit. Also protects you as a pedestrian in the event you are struck by a vehicle.'
    },
    uninsuredUnderinsuredMotoristBodilyInjury: {
        id: 'wmic.component.policy-coverage-info-box-component.uninsuredUnderinsuredMotoristBodilyInjury',
        defaultMessage: 'Uninsured/Underinsured Motorist Bodily Injury'
    },
    uninsuredUnderinsuredMotoristBodilyInjuryTooltip: {
        id: 'wmic.component.policy-coverage-info-box-component.uninsuredUnderinsuredMotoristBodilyInjuryTooltip',
        defaultMessage: 'Provides protection against the hazard of bodily injury caused by someone who has no auto liability insurance or whose bodily injury limits are lower than your chosen limit for this coverage.'
    },
    notTaken: {
        id: 'wmic.component.policy-coverage-info-box-component.notTaken',
        defaultMessage: 'Not Taken'
    },
    policy: {
        id: 'wmic.component.policy-coverage-info-box-component.Policy',
        defaultMessage: 'Policy #'
    },
});
