import { defineMessages } from 'react-intl';

export default defineMessages({
    automobilePolicyNumber: {
        id: 'portal.views.policy-summary-box.Automobile Policy Number Heading',
        defaultMessage: 'Automobile policy #{policyNumber}'
    },
    rentersPolicyNumber: {
        id: 'portal.views.policy-summary-box.Renters Policy Number Heading',
        defaultMessage: 'Renters policy #{policyNumber}'
    },
    homeownersPolicyNumber: {
        id: 'portal.views.policy-summary-box.Homeowners Policy Number Heading',
        defaultMessage: 'Homeowners policy #{policyNumber}'
    },
    condominiumPolicyNumber: {
        id: 'portal.views.policy-summary-box.Condominium Policy Number Heading',
        defaultMessage: 'Condominium policy #{policyNumber}'
    },
    genericPolicyNumber: {
        id: 'portal.views.policy-summary-box.Generic Policy Number Heading',
        defaultMessage: 'Policy #{policyNumber}'
    },
    statusActive: {
        id: 'portal.views.policy-summary-box.Status Active',
        defaultMessage: 'Active'
    },
    statusInactive: {
        id: 'portal.views.policy-summary-box.Status Inactive',
        defaultMessage: 'Inactive'
    },
    statusNew: {
        id: 'portal.views.policy-summary-box.Status New',
        defaultMessage: 'New'
    },
    statusRenewing: {
        id: 'portal.views.policy-summary-box.Status Renewing',
        defaultMessage: 'Renewing'
    },
    policyUpForRenewal: {
        id: 'portal.views.policy-summary-box.Policy Up For Renewal',
        defaultMessage: 'Your policy is up for renewal'
    },
    activeTerm: {
        id: 'portal.views.policy-summary-box.Active Term',
        defaultMessage: 'Active Term'
    },
    policyNumber: {
        id: 'portal.views.policy-summary-box.Policy Number',
        defaultMessage: 'Policy #'
    },
    status: {
        id: 'portal.views.policy-summary-box.Status',
        defaultMessage: 'Status'
    },
    term: {
        id: 'portal.views.policy-summary-box.Term',
        defaultMessage: 'Term'
    },
    paymentPlan: {
        id: 'portal.views.policy-summary-box.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    termTotal: {
        id: 'portal.views.policy-summary-box.Policy Term Total',
        defaultMessage: 'Policy Term Total'
    },
    amountOutstanding: {
        id: 'portal.views.policy-summary-box.Amount Outstanding',
        defaultMessage: 'Amount Outstanding'
    },
    viewRenewal: {
        id: 'portal.views.policy-summary-box.View Renewal',
        defaultMessage: 'View Renewal'
    },
    insuranceIdCard: {
        id: 'portal.views.policy-summary-box.Insurance ID Card',
        defaultMessage: 'Insurance ID Card'
    },
    payNow: {
        id: 'portal.views.policy-summary-box.Pay Now',
        defaultMessage: 'Pay Now'
    },
    payNowTrackButtonIdentifier: {
        id: 'portal.views.policy-summary-box.tracking.Pay Now',
        defaultMessage: 'Home Page - {policyActiveStatus} Policies - Pay Now'
    },
    payNowListBillTrackButtonIdentifier: {
        id: 'portal.views.policy-summary-box.tracking.Pay Now (List Bill)',
        defaultMessage: 'Home Page - {policyActiveStatus} Policies - Pay Now (List Bill)'
    },
    proofOfInsuranceFriendlyName: {
        id: 'policy.common.views.account-policy-details.Proof of Insurance": "Download a PDF version',
        defaultMessage: 'Download a PDF version'
    },
    insuranceIDModalSorry: {
        id: "policy.common.views.account-policy-details.We're Sorry",
        defaultMessage: "We're sorry",
    },
    insuranceIDModalErrorMessageBody: {
        id: 'policy.common.views.account-policy-details.Your document cannot be accessed at this time. Please try again later or call us at 888-363-7984 or email us at service.us@wawanesa.com.  Our customer service team is available Monday - Friday 7:30 am - 7:30 pm and Saturday 8:00 am - 4:30 pm.',
        defaultMessage: 'Your document cannot be accessed at this time. &lt;br/&gt;&lt;br/&gt;Please try again later or call us at &lt;a wmic-track-button-click="Modal - Document Error - Support Phone Number" href="tel:888-363-7984"&gt;888-363-7984&lt;/a&gt; or email us at &lt;a href="mailto:service.us@wawanesa.com\"&gt;service.us@wawanesa.com&lt;/a&gt;.  Our customer service team is available Monday - Friday 7:30 am - 7:30 pm and Saturday 8:00 am - 4:30 pm.'
    },
    close: {
        id: 'portal.views.policy-summary-box.Close',
        defaultMessage: 'Close'
    },
    inactiveReinstateMessage: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.Please note that making a payment will not automatically reinstate your cancelled policy...',
        defaultMessage: 'Please note that making a payment will not automatically reinstate your cancelled policy. If you have any questions about reinstating a cancelled policy, please call us at 888-363-7984 Monday to Friday 7:30 am to 7:30 pm or Saturdays 8:00 am to 4:30 pm.'
    },
    marPaymentsMessage: {
        id: 'portal.views.policy-summary-box.Monthly Automated Recurring payments are automatically withdrawn',
        defaultMessage: 'Monthly Automated Recurring payments are automatically withdrawn from your bank account. Only overdue payments can be made online.'
    },
    policyNumberAriaLabel: {
        id: 'portal.views.policy-summary-box.Policy Number Aria Label',
        defaultMessage: 'View Details for Policy # {policyNumber}'
    },
    policyStatusAriaLabel: {
        id: 'portal.views.policy-summary-box.Policy Status Aria Label',
        defaultMessage: 'Policy {policyNumber} has a status of {status}'
    },
    policyTermAriaLabel: {
        id: 'portal.views.policy-summary-box.Policy Term Aria Label',
        defaultMessage: 'Policy {policyNumber} has a term {termStartDate} to {termEndDate}'
    },
    paymentPlanAriaLabel: {
        id: 'portal.views.policy-summary-box.Payment Plan Aria Label',
        defaultMessage: 'Payment plan for policy {policyNumber} is {paymentPlanName}'
    },
    policyTermTotalAriaLabel: {
        id: 'portal.views.policy-summary-box.Policy Term Total Aria Label',
        defaultMessage: 'Policy Term Total for policy {policyNumber} is {policyTermTotalAmount}'
    },
    amountOutstandingAriaLabel: {
        id: 'portal.views.policy-summary-box.Amount Outstanding Aria Label',
        defaultMessage: 'Amount outstanding for policy {policyNumber} is {outstandingAmount}'
    },
    buttonPayNowAriaLabel: {
        id: 'portal.views.policy-summary-box.Pay Now Aria Label',
        defaultMessage: 'Make a payment on policy #{policyNumber}'
    },
    buttonPayNowOverdueAriaLabel: {
        id: 'portal.views.policy-summary-box.Pay Now Overdue Aria Label',
        defaultMessage: 'Pay the overdue amount of ${amount} now, on policy #{policyNumber}'
    },
    insuranceIDCardAriaLabel: {
        id: 'portal.views.policy-summary-box.Insurance ID Card Aria Label',
        defaultMessage: 'Request insurance ID card for policy #{policyNumber}'
    }
});
