import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    POLICY_DETAILS,
    WMICRichTextUtil,
    JobType,
    CONSTANTS,
    WMICDateTimeService
} from 'wmic-pe-portals-utils-js';
import { useViewModelClone } from 'wmic-pe-components-platform-react';
import { PAConsentMessages } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import metadata from './WMICCreditConsentANIComponent.metadata.json5';
import '../WMICCreditConsent.messages';

function WMICCreditConsentANIComponent(props) {
    const { id, index,  data, setData, onValidateConsentMap, showErrors, jobVM, readOnly } = props;
    const translator = useContext(TranslatorContext);
    const cloneViewModel = useViewModelClone();

    const consentDate = _.get(data, 'creditConsentDate.value');
    const consentRcvd = _.get(data, 'creditConsentReceived.value');
    const isCreditConsentReceived = _.get(consentRcvd, 'code', '') === POLICY_DETAILS.YES
    const isCreditConsentNotReceived = _.get(consentRcvd, 'code', '') === POLICY_DETAILS.NO
    const isPolicyChangeOrRenewal =
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE ||
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.RENEWAL;

    const writeValue = (value, path) => {
        const newConsentVM = cloneViewModel(data);
        _.set(newConsentVM, path, value);
        setData(newConsentVM, index);
    }

    useEffect(() => {
        writeValue(true, "validateCreditConsentReceived");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onValidate = useCallback((isValid) => {
        onValidateConsentMap(isValid, id);
    }, [id, onValidateConsentMap]);

    const setCreditConsentInfoANI = useCallback((consent) => {
        if (!_.isNil(consentRcvd)) {
            if (_.isNil(consentDate)) {
                _.set(data, 'creditConsentDate.value', WMICDateTimeService.today());
                setData(data, index);
            }

            return consentRcvd.code === consent;
        }

        return false;
    }, [consentDate, consentRcvd, data, index, setData]);

    const isPersonPNI = _.upperFirst(_.get(data, 'subtype.value.code', '')) === CONSTANTS.Person;

    const isConsentReceivedANI = useMemo(() => isPersonPNI && setCreditConsentInfoANI(POLICY_DETAILS.YES),
        [isPersonPNI, setCreditConsentInfoANI]);

    const displayName = useMemo(() => _.get(data, 'displayName.value', ''), [data]);

    const overrideProps = {
        '@field': {
            parentNode: data,
            readOnly,
        },
        creditConsentHeading: {
            title: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.consentFor, {name: displayName}))
        },
        creditConsentReceived: {
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.creditConsentReceivedFrom, {name: displayName}))
        },
        personalInfoConsentForm: {
            visible: isConsentReceivedANI
        },
        creditConsentDate: {
            visible: isCreditConsentReceived
        },
        creditConsentDOB: {
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.dateOfBirthFor , {name: displayName})),
            visible: isCreditConsentReceived && !isPolicyChangeOrRenewal
        },
        withdrawalCreditConsentDate: {
            visible: isCreditConsentNotReceived
        },
        creditInfoWithdrawalConsent: {
            visible: isCreditConsentNotReceived
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={data}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );

}

export default WMICCreditConsentANIComponent;
