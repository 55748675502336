/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';


const WMICScheduledPaymentModal = (props) => {
    const { onReject, paymentDate, saveBankAccount, isBankAccountSaved } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(messages.paymentSuccessfullyScheduled)}</h3>
            <ModalBody className={styles.successFailureModalBody}>
                <div className={styles.successFailureModalBody}>
                    <div>
                        <p className={styles['wmic-payment-text']}>{WMICRichTextUtil.translateRichText(translator(messages.thankYouForSchedulingYourPaymentItWillBeWithdrawnFromYourBankAccountOn, { paymentDate: intl.formatDate(new Date(paymentDate), { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) }))}</p>
                        <p className={styles['wmic-payment-text']}>{translator(messages.onceYouveReceivedThisReceiptPleaseAllowUpTo24Hours)}</p>
                    </div>
                    {saveBankAccount && (
                        <div>
                            {isBankAccountSaved && (
                                <p>{translator(messages.bankingInformationSaved)}</p>
                            )}
                            {!isBankAccountSaved && (
                                <p>
                                    {translator(messages.bankUnableToBeSaved)}
                                    {translator(messages.contactUsForAssistance)}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject(); }}
                    type="primary"
                >
                    {translator(messages.ok)}
                </WMICButton>
            </ModalFooter>
        </Fragment>
    );
};

WMICScheduledPaymentModal.propTypes = {
    onReject: PropTypes.func.isRequired,
    paymentDate: PropTypes.shape({}).isRequired,
    saveBankAccount: PropTypes.bool.isRequired,
    isBankAccountSaved: PropTypes.bool.isRequired
};

export default WMICScheduledPaymentModal;
