// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICCard__wmicCard{border-radius:var(--wmic-border-radius-card)}.app__WMICCard__wmicCard.app__WMICCard__wmicCardRaised{border:none;box-shadow:var(--GW-SHADOW-2)}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICCard/WMICCard.module.scss"],"names":[],"mappings":"AAAA,yBAGI,4CAAA,CAEA,uDACI,WAAA,CACA,6BAAA","sourcesContent":[".wmicCard {\n    /* Do not over-ride --GW-BORDER-RADIUS here*/\n    /* as all card children will inherit*/\n    border-radius: var(--wmic-border-radius-card);\n    \n    &.wmicCardRaised {\n        border: none;\n        box-shadow: var(--GW-SHADOW-2);\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicCard": "app__WMICCard__wmicCard",
	"wmicCardRaised": "app__WMICCard__wmicCardRaised"
};
export default ___CSS_LOADER_EXPORT___;
