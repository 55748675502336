import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const WMICStickyMessageComponent = ({ children }) => {
    const { ref, inView, entry } = useInView({threshold: 1});

    // show fixed positioned message only when original one is 'above' current viewport
    const displayFixed = !inView && entry && entry.boundingClientRect.top < 0;

    return (
        <Fragment>
            <div
                ref={ref}
            >
                {children && children}
            </div>
            {
                displayFixed && <div className='ww-card-notice-sticky'>{children}</div>
            }
        </Fragment>
    );
};

WMICStickyMessageComponent.defaultProps = {
    children: <></>
};

WMICStickyMessageComponent.propTypes = {
    children: PropTypes.element
};


export default WMICStickyMessageComponent;