import { defineMessages } from 'react-intl';

export default defineMessages({
    hello: {
        id: 'portal.views.custom-header.hello',
        defaultMessage: 'Hello,'
    },
    helloAria: {
        id: 'portal.views.custom-header.helloAria',
        defaultMessage: 'Hello, {name}'
    },
    yourProfile: {
        id: 'portal.views.custom-header.yourProfile',
        defaultMessage: 'Your Profile'
    },
    logout: {
        id: 'portal.views.custom-header.logout',
        defaultMessage: 'Log Out'
    },
    home: {
        id: 'portal.views.custom-header.home',
        defaultMessage: 'Home'
    },
    menu: {
        id: 'portal.views.custom-header.menu',
        defaultMessage: 'Menu'
    },
    policyMenu: {
        id: 'portal.views.custom-header.policyMenu',
        defaultMessage: 'Policy Menu'
    }
});
