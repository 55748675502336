import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';

function WMICPUPPolicyRenewalPolicyDetailsPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData
    } = props;

    const { RenewalService } = useDependencies('RenewalService');
    const { setWizardLoading } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const { authHeader, authUserData } = useAuthentication();
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [showErrors, setShowErrors] = useState(false);

    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation('WMICPUPPolicyRenewalPolicyDetailsPage');

    useDocumentTitle(commonMessages.policyDetailsTitle, renewalVM);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }
        try {
            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));

            const newRenewalVM = _.cloneDeep(renewalVM.value);

            renewalVM.value = await RenewalService.saveRenewal(
                [newRenewalVM],
                authHeader
            );
            updateWizardData(renewalVM);

            return renewalVM;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, translator, renewalVM, RenewalService, authHeader]);

    return (
        <WMICWizardChangeOrRenewalPage
            showPrevious={false}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={renewalVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={isReadOnlyUser}
                authHeader={authHeader}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPUPPolicyRenewalPolicyDetailsPage.propTypes = wizardProps;

export default withViewModelService(WMICPUPPolicyRenewalPolicyDetailsPage);
