import { defineMessages } from 'react-intl';

export default defineMessages({

    cancel: {
        id: 'wmic.policy-change.component.address-pa-edit.Cancel',
        defaultMessage: 'Cancel'
    },

    continue: {
        id: 'wmic.policy-change.component.address-pa-edit.Continue',
        defaultMessage: 'Continue'
    },

    cancellationModalTitle: {
        id: 'wmic.policy-change.component.address-pa-edit.Cancellation Modal Title',
        defaultMessage: 'Changes will be lost'
    },

    cancellationModalBody: {
        id: 'wmic.policy-change.component.address-pa-edit.Cancellation Modal Body',
        defaultMessage: "If you cancel now you'll lose any changes you've made."
    },

    cancellationModalConfirm: {
        id: 'wmic.policy-change.component.address-pa-edit.Cancellation Modal Confirm',
        defaultMessage: 'Cancel Changes'
    },

    cancellationModalCancel: {
        id: 'wmic.policy-change.component.address-pa-edit.Cancellation Modal Cancel',
        defaultMessage: 'Keep Editing'
    },

    submissionModalConfirm: {
        id: 'wmic.policy-change.component.address-pa-edit.Submission Modal Confirm',
        defaultMessage: 'Your policy change is ready to submit'
    },

    submissionModalBody: {
        id: 'wmic.policy-change.component.address-pa-edit.Submission Modal Body',
        defaultMessage: 'Once submitted, an agent will contact you shortly to finish processing this change. After it is fully processed, the agent will confirm with you when the change will take effect.'
    },

    submissionModalSubmit: {
        id: 'wmic.policy-change.component.address-pa-edit.Submission Modal Submit',
        defaultMessage: 'Submit'
    },

    submissionModalCancel: {
        id: 'wmic.policy-change.component.address-pa-edit.Submission Modal Cancel',
        defaultMessage: 'Cancel'
    },

    submissionModalTrackingText: {
        id: 'wmic.policy-change.component.address-pa-edit.tracking.Submit Change of Address',
        defaultMessage: 'Policy Change - Submit - Change of address'
    },

    editMailingAddress: {
        id: 'wmic.policy-change.component.address-pa-edit.Edit Mailing Address',
        defaultMessage: 'Edit your mailing address using the form below'
    },

    sameVehicleAddress: {
        id: 'wmic.policy-change.component.address-pa-edit.Same Vehicle Address',
        defaultMessage: 'My vehicles are typically parked or kept at this address'
    },

    samePropertyAddress: {
        id: 'wmic.policy-change.component.address-pa-edit.Same Property Address',
        defaultMessage: 'Is your property address the same as your policy address'
    },

    addVehicleAddress: {
        id: 'wmic.policy-change.component.address-pa-edit.Add Vehicle Address',
        defaultMessage: 'Add vehicle address'
    },

    addPropertyAddress: {
        id: 'wmic.policy-change.component.address-pa-edit.Add Property Address',
        defaultMessage: 'Add property address'
    },
    sameAddressToggleYes: {
        id: 'wmic.policy-change.component.address-pa-edit.toggle.Yes',
        defaultMessage: 'Yes'
    },

    sameAddressToggleNo: {
        id: 'wmic.policy-change.component.address-pa-edit.toggle.No',
        defaultMessage: 'No'
    },
    errorToggle: {
        id: 'wmic.policy-change.component.address-pa-edit.toggle.error',
        defaultMessage:'Answering this question is required to submit your changes',
    }
});
