import { defineMessages } from 'react-intl';

export default defineMessages({
    withYourConsent: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.With your consent Wawanesa Insurance will electronically',
        defaultMessage: 'With your consent Wawanesa Insurance will electronically deliver your policy documents, forms, billing statements, notices, claim-related documents and any other documents and communications permitted or required by law. Communications may contain documents or may provide access to an online account where a document or communication is available. Your consent is voluntary.'
    },
    oregonPolicyholders: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Oregon policyholders',
        defaultMessage: 'Oregon policyholders receiving a discount related to electronic delivery of documents, will no longer receive the discount if authorization is cancelled.'
    },
    electronicallyDelivered: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.To receive electronically delivered documents you must have a computer or other',
        defaultMessage: 'To receive electronically delivered documents you must have a computer or other access device with an internet connection, an active email account, and software which permits you to receive, access and retain Portable Document Format or (“PDF”) Files.'
    },
    emailUs: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.You may email us at or call us at to do any of the following',
        defaultMessage: 'You may email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt; or call us at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; to do any of the following:'
    },
    optOut: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Opt-out of receiving electronic documents at any time',
        defaultMessage: 'Opt-out of receiving electronic documents at any time'
    },
    updateEmail: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Update your email address to ensure you receive all communications',
        defaultMessage: 'Update your email address to ensure you receive all communications'
    },
    requestFree: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Request free paper copies of your policy documents and communications',
        defaultMessage: 'Request free paper copies of your policy or claim-related documents and communications'
    },
    communicationEmail: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Would you like to receive your documents and communication by email',
        defaultMessage: 'Would you like to receive your documents and communication by email?'
    },
    receiveDocs: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.I want to receive my documents electronically. By clicking here I confirm that I have',
        defaultMessage: '&lt;strong&gt;YES&lt;/strong&gt; - I want to receive my documents electronically. By clicking here I confirm that I have read the information above and consent to the electronic delivery of documents and communications from Wawanesa Insurance which will be sent to {email}.'
    },
    donotWantreceiveDocs: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.I do not want to receive my documents electronically and want to receive communications via US mail',
        defaultMessage: '&lt;strong&gt;NO&lt;/strong&gt; -  I do not want to receive my documents electronically and want to receive communications via US mail.'
    },
    yes: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.YES',
        defaultMessage: 'YES'
    },
    no: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.NO',
        defaultMessage: 'NO'
    },
    cancel: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Cancel',
        defaultMessage: 'Cancel'
    },
    submit: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Submit',
        defaultMessage: 'Submit'
    },
    edit: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.Edit Method of Contact',
        defaultMessage: 'Edit Method of Contact'
    }
});
