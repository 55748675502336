import { defineMessages } from 'react-intl';

export default defineMessages({
    contact: {
        id: 'wmic.component.policy-change-info-box-component.Contact',
        defaultMessage: 'Contact us '
    },
    phone: {
        id: 'wmic.component.policy-change-info-box-component.Phone',
        defaultMessage: '1-888-363-7984'
    },
    callUs: {
        id: 'wmic.component.policy-change-info-box-component.CallUs',
        defaultMessage: 'Call us at 1-888-363-7984'
    },
    detailsMenuQuestions: {
        id: 'wmic.component.policy-change-info-box-component.EditPolicyMenuQuestions',
        defaultMessage: 'Questions?'
    },
    editPolicyNumber: {
        id: 'policy.view.policy-change.HoPolicyNumber',
        defaultMessage: 'Edit {policyType} Policy # {policyNumber}'
    },
    editCoverages: {
        id: 'policy.view.policy-change.EditCoverages',
        defaultMessage: 'Edit Coverages'
    },
    homeowners: {
        id: 'policy.view.policy-change.Homeowners',
        defaultMessage: 'Homeowners'
    },
    renters: {
        id: 'policy.view.policy-change.Renters',
        defaultMessage: 'Renters'
    },
    condominium: {
        id: 'policy.view.policy-change.Condominium',
        defaultMessage: 'Condominium'
    },
    automobile: {
        id: 'policy.view.policy-change.Automobile',
        defaultMessage: 'Automobile'
    },
    mondayFriday: {
        id: 'wmic.component.policy-change-info-box-component.Monday to Friday',
        defaultMessage: 'Monday to Friday'
    },
    h730AM730PMand: {
        id: 'wmic.component.policy-change-info-box-component.7:30 AM to 7:30 PM and',
        defaultMessage: '7:30 AM to 7:30 PM and'
    },
    saturday800AM430PM: {
        id: 'wmic.component.policy-change-info-box-component.Saturday 8:00 AM to 4:30 PM',
        defaultMessage: 'Saturday 8:00 AM to 4:30 PM'
    }
});