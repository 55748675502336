/* eslint-disable object-curly-newline */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable max-len */
import React, { useContext, useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import { Decimal } from 'decimal.js-light';
import { ModalFooter } from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Currency as CurrencyField, WMICLoader } from 'gw-components-platform-react';
import ccImg from 'wmic-amp-digital-theme-styles/images/credit-cards.png';
import chequeImg from 'wmic-amp-digital-theme-styles/images/cheque-image.gif';
import { WMICCheckbox, WMICButton } from 'wmic-components-platform-react';
import {
    DateUtil,
    WMICPaymentUtil,
    WMICPaymentService,
    WMICTempStorageService,
    WMICFeatureFlagUtil,
    WMICRichTextUtil
} from 'wmic-portals-utils-js';
import { PolicyService } from 'gw-capability-policy';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import ReactDOM from 'react-dom';
import appConfig from 'app-config';
import classNames from 'classnames';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';

import { RadioButtonField, InputField } from '@jutro/legacy/components';

const WMICMakeAPaymentModal = (props) => {
    const {
        data,
        selectedPaymentAmount,
        onReject,
        onManageBankingInformation,
        onPaymentProcessing,
        onPaymentFailed,
        onInvalidRoutingTransitNumber,
        onPaymentSuccess,
        onPaymentInProgress,
        onPaymentScheduled
    } = props;
    const { env } = appConfig;
    const billingData = _.get(data, 'billingData', {});
    const policyData = _.get(data, 'policyData', {});
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();    
    const DOM_ELEMENT_IDS = {
        PAYMENT_METHOD_DROPDOWN_SELECT: 'paymentMethodSelect',
        PAYMENT_DATE_DROPDOWN_SELECT: 'paymentDateSelect',
        BANK_ACCOUNT_TYPE_RADIO: 'bankAccountTypeRadio',
        ROUTING_TRANSIT_NUMBER_INPUT: 'routingTransitNumber',
        BANK_ACCOUNT_NUMBER_INPUT: 'bankAccountNumber',
        BANK_ACCOUNT_DROPDOWN_SELECT: 'bankAccountSelect',
        SAVE_BANKING_INFO_CHECKBOX: 'saveBankingInfo',
        AGREE_BANKING_INFO_STORAGE_CHECKBOX: 'agreeBankingInfoStorage',
    };
    const PAYMENT_METHODS = {
        CC: 'creditcard',
        WIRE: 'wire'
    };
    const VALIDATORS = {
        ROUTING_TRANSIT_NUMBER: /^\d{9}$/,
        BANK_ACCOUNT_NUMBER: /^\d{1,17}$/,
        POSTAL_CODE: /^\d{5}-\d{4}$/
    };
    const PREFILLED_STORAGE_KEY_SEED = 'WMICMakeAPaymentModal_prefilledStorageKey';
    const PREFILLED_STORAGE_KEYS = {
        PAYMENT_METHOD: `${PREFILLED_STORAGE_KEY_SEED}_PAYMENT_METHOD`,
        PAYMENT_DATE: `${PREFILLED_STORAGE_KEY_SEED}_PAYMENT_DATE`,
        BANK_ACCOUNT_TYPE: `${PREFILLED_STORAGE_KEY_SEED}_BANK_ACCOUNT_TYPE`,
        ROUTING_TRANSIT_NUMBER: `${PREFILLED_STORAGE_KEY_SEED}_ROUTING_TRANSIT_NUMBER`,
        BANK_ACCOUNT_NUMBER: `${PREFILLED_STORAGE_KEY_SEED}_BANK_ACCOUNT_NUMBER`
    };
    const GW_OTW_SETUP_FAILED_BANK_TRANSIT_NUMBER = '619';
    const SUCCESSFUL_PAYMENT_STATUS = 'closed';
    const NEW_BANK_ACCOUNT_OPTION = 'useNewBankAccount';
    const PROCESS_STATUS = {
        INITIATED: 'initiated',
        IN_PROGRESS: 'inprogress',
        FAILED: 'failed',
        COMPLETED: 'completed',
        OPTIMISTIC: 'optimistic'
    };
    const today = DateUtil.trimToMidnight(new Date()).toISOString();
    const [accountContact, updateAccountContact] = useState(undefined);
    const [directBillDTO, updateDirectBillDTO] = useState(undefined);
    const [paymentMethod, updatePaymentMethod] = useState(undefined);
    const [selectBankPaymentMethod, updateSelectBankPaymentMethod] = useState(undefined);
    const [bankAccountType, updateBankAccountType] = useState(undefined);
    const [routingTransitNumber, updateRoutingTransitNumber] = useState(undefined);
    const [bankAccountNumber, updateBankAccountNumber] = useState(undefined);
    const [saveBankAccount, updateSaveBankAccount] = useState(false);
    const [agreeBankingInfoStorage, updateAgreeBankingInfoStorage] = useState(false);
    const [bankAccounts, updateBankAccounts] = useState([]);
    const [bankAccountsLoading, updateBankAccountsLoading] = useState(false);
    const [useNewBankAccount, updateUseNewBankAccount] = useState(false);
    const [paymentDueDate, updatePaymentDueDate] = useState(undefined);
    const [paymentDate, updatePaymentDate] = useState(today);
    const [localPaymentDueDate, updateLocalPaymentDueDate] = useState(undefined);
    const [nextPlannedAmountPaymentDate, updateNextPlannedAmountPaymentDate] = useState(undefined);
    const [localNextPlannedAmountPaymentDate, updateLocalNextPlannedAmountPaymentDate] = useState(undefined);
    const [ldFlags, setLdFlags] = useState({});
    const NUM_OF_DAYS_IN_FUTURE_PAYMENTS = 7;
    const CHECK_PAYMENT_STATUS_TIME = 5000; // Milliseconds between checks of the payment status
    const CHECK_PAYMENT_STATUS_ATTEMPTS = 3; // Number of times to check the payment status
    let finishedCheckingPaymentStatus = false;
    let isBankAccountSaved = false;
    let checkPaymentInterval;
    let checkPaymentIntervalInteraction;

    const futureDatePaymentAvailability = useCallback(() => {
        return WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.FUTUREDATEDOTWPAYMENTS);
    },[ldFlags, featureFlags.FUTUREDATEDOTWPAYMENTS]);

    
    const manageBankAccountsAvailable = useCallback(() => {
        return WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.MANAGEBANKACCOUNTS);
    },[ldFlags, featureFlags.MANAGEBANKACCOUNTS]);

    const getPrimaryPhoneType = (contact) => {
        let phoneNumber = '';
        switch (contact?.primaryPhoneType) {
            case CONSTANTS.PHONE_TYPES.HOME:
                phoneNumber = contact.homeNumber;
                break;
            case CONSTANTS.PHONE_TYPES.WORK:
                phoneNumber = contact.workNumber;
                break;
            case CONSTANTS.PHONE_TYPES.MOBILE:
                phoneNumber = contact.cellNumber;
                break;
            default:
                phoneNumber = '';
                break;
        }
        return phoneNumber;
    };

    const trimLongFormZipCodes = (postalCode) => {
        if (postalCode && VALIDATORS.POSTAL_CODE.test(postalCode)) {
            return postalCode.substr(0, 5);
        }
        return postalCode;
    };

    const resetBankAccountInfo = () => {
        directBillDTO.paymentInstrument.bankAccountData = {
            bankName: null,
            publicId_WMIC: '',
            nickName_WMIC: null
        };

        updateRoutingTransitNumber('');
        updateBankAccountType(undefined);
        updateBankAccountNumber('');
        updateSaveBankAccount(false);
        updateAgreeBankingInfoStorage(false);
    };

    const selectBankAccount = (selectedBankAccount) => {
        resetBankAccountInfo();

        const bankAccount = _.find(bankAccounts, { publicId_WMIC: selectedBankAccount });
        if (bankAccount) {
            directBillDTO.paymentInstrument.paymentMethod = selectBankPaymentMethod;
            directBillDTO.paymentInstrument.bankAccountData = {
                bankName: bankAccount.bankName,
                publicId_WMIC: bankAccount.publicId_WMIC,
                nickName_WMIC: bankAccount.nickName_WMIC
            };
            updateRoutingTransitNumber(bankAccount.bankABANumber);
            updateBankAccountType(bankAccount.bankAccountType);
            updateBankAccountNumber('0'); // populating the bank account number at pay time in the BE, we shouldn't transmit plain text account number
            updateUseNewBankAccount(false);
        }

        if (selectedBankAccount === NEW_BANK_ACCOUNT_OPTION) {
            resetBankAccountInfo();
            updateUseNewBankAccount(true);
        }

        return bankAccount;
    };

    const splitFullName = (fullName, part) => {
        return fullName?.split(' ')[part] || '';
    };

    const paymentDateWarningPaymentDue = () => {
        return localPaymentDueDate && new Date(directBillDTO?.value?.paymentDate_WMIC).getTime() > localPaymentDueDate.getTime();
    };

    const paymentDateWarningNextPlannedDate = () => {
        return localNextPlannedAmountPaymentDate && new Date(directBillDTO?.value?.paymentDate_WMIC).getTime() > localNextPlannedAmountPaymentDate.getTime();
    };

    const getAccountBillingDetailsForPolicy = () => {
        updateBankAccounts([]);
        if (!manageBankAccountsAvailable().isAvailable) {
            return;
        }

        updateBankAccountsLoading(true);
        const bankAccountInfo = AccountBillingDetailsService.getAccountBillingDetailsForPolicy(
            billingData.policyId, billingData.termNumber, accountContact?.publicID, authHeader
        );
        bankAccountInfo.then((response) => {
            _.each(response, (entry) => {
                bankAccounts.push(entry); // all bank accounts of client, to be displayed on the UI
            });
            updateBankAccounts(bankAccounts);
            updateUseNewBankAccount(bankAccounts.length === 0);
            updateBankAccountsLoading(false);
        });
    };

    // This function populates the form with previous values (if any) as soon as the appropriate DOM elements are rendered
    useMemo(() => {
        if (document.getElementById(DOM_ELEMENT_IDS.PAYMENT_METHOD_DROPDOWN_SELECT)) {
            const previousPaymentMethod = WMICTempStorageService.pop(PREFILLED_STORAGE_KEYS.PAYMENT_METHOD);
            if (previousPaymentMethod) {
                updatePaymentMethod(previousPaymentMethod);
                document.getElementById(DOM_ELEMENT_IDS.PAYMENT_METHOD_DROPDOWN_SELECT).value = previousPaymentMethod;
            }
        }

        if (document.getElementById(DOM_ELEMENT_IDS.PAYMENT_DATE_DROPDOWN_SELECT)) {
            const previousPaymentDate = WMICTempStorageService.pop(PREFILLED_STORAGE_KEYS.PAYMENT_DATE);
            if (previousPaymentDate) {
                updatePaymentDate(previousPaymentDate);
                document.getElementById(DOM_ELEMENT_IDS.PAYMENT_DATE_DROPDOWN_SELECT).value = previousPaymentDate;
            }
        }

        if (document.getElementById(DOM_ELEMENT_IDS.BANK_ACCOUNT_TYPE_RADIO)) {
            const previousBankAccountType = WMICTempStorageService.pop(PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_TYPE);
            if (previousBankAccountType) {
                updateBankAccountType(previousBankAccountType);
            }
        }

        if (document.getElementById(DOM_ELEMENT_IDS.ROUTING_TRANSIT_NUMBER_INPUT)) {
            const previousRoutingTransitNumber = WMICTempStorageService.pop(PREFILLED_STORAGE_KEYS.ROUTING_TRANSIT_NUMBER);
            if (previousRoutingTransitNumber) {
                updateRoutingTransitNumber(previousRoutingTransitNumber);
            }
        }

        if (document.getElementById(DOM_ELEMENT_IDS.BANK_ACCOUNT_NUMBER_INPUT)) {
            const previousBankAccountNumber = WMICTempStorageService.pop(PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_NUMBER);
            if (previousBankAccountNumber) {
                updateBankAccountNumber(previousBankAccountNumber);
            }
        }
    }, [
        document.getElementById(DOM_ELEMENT_IDS.PAYMENT_METHOD_DROPDOWN_SELECT),
        document.getElementById(DOM_ELEMENT_IDS.PAYMENT_DATE_DROPDOWN_SELECT),
        document.getElementById(DOM_ELEMENT_IDS.BANK_ACCOUNT_TYPE_RADIO),
        document.getElementById(DOM_ELEMENT_IDS.ROUTING_TRANSIT_NUMBER_INPUT),
        document.getElementById(DOM_ELEMENT_IDS.BANK_ACCOUNT_NUMBER_INPUT)
    ]);

    const saveFilledInfo = () => {
        WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.PAYMENT_METHOD, paymentMethod);
        WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.PAYMENT_DATE, paymentDate);
        WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_TYPE, bankAccountType);
        if (useNewBankAccount || !manageBankAccountsAvailable()) {
            WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.ROUTING_TRANSIT_NUMBER, routingTransitNumber);
            WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_NUMBER, bankAccountNumber);
        }
    };

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    useEffect(() => {
        initFeatureFlags();
        PolicyService.getAccountPolicyDetails_WMIC(billingData.policyId, billingData.termNumber, authHeader, authUserData.firstName, authUserData.lastName).then((policy) => {
            const enrollmentContact = _.find(policy.currentPeriod.contacts, { isEnrollmentContact_WMIC: true });
            if (enrollmentContact) {
                updateAccountContact(enrollmentContact?.contact);
            } else {
                // this is just the fallback plan. If the user was properly enrolled then they shouldn't get in here
                policy.currentPeriod.contacts.forEach((currentContact) => {
                    if (currentContact.contact.emailAddress1 === authUserData.email
                        && (currentContact.contactRole === translator(messages.primaryNamedInsured)
                            || currentContact.contactRole === translator(messages.namedInsured)
                            || currentContact.contactRole === CONSTANTS.CONTACT_ROLES.ACCOUNT_HOLDER)) {
                        updateAccountContact(currentContact.contact);
                    }
                });
            }

            if (billingData.amountDue) {
                const nextDuePaymentDate = billingData.nextDuePaymentDate && new Date(billingData.nextDuePaymentDate).toISOString();
                const nextPlannedAmountPaymentDateValue = billingData.nextPlannedAmountPaymentDate && new Date(billingData.nextPlannedAmountPaymentDate).toISOString();
                if (nextDuePaymentDate) {
                    let paymentDateToUse = nextDuePaymentDate;
                    let localPaymentDateToUse = billingData.localNextDuePaymentDate;

                    if (nextPlannedAmountPaymentDateValue && (nextPlannedAmountPaymentDateValue < nextDuePaymentDate)) {
                        paymentDateToUse = nextPlannedAmountPaymentDateValue;
                        localPaymentDateToUse = billingData.localNextPlannedAmountPaymentDate;
                    }
                    updatePaymentDueDate(paymentDateToUse);
                    updateLocalPaymentDueDate(DateUtil.trimToMidnight(localPaymentDateToUse));
                } else if (nextPlannedAmountPaymentDateValue) {
                    updateNextPlannedAmountPaymentDate(nextPlannedAmountPaymentDateValue);
                    updateLocalNextPlannedAmountPaymentDate(DateUtil.trimToMidnight(billingData.localNextPlannedAmountPaymentDate));
                }
            }
        });

        const paymentMethodTypelist = viewModelService.productMetadata.get('bc').types.getTypelist('PaymentMethod');
        const oneTimeWithdrawal = paymentMethodTypelist.getCode('OneTimeWithdrawal').code;
        updateSelectBankPaymentMethod(oneTimeWithdrawal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const directBillDTOModel = viewModelService.create({
            invoiceIds: '',
            amountPaid: undefined,
            paymentDate_WMIC: paymentDate,
            paymentInstrument: {
                paymentMethod: undefined,
                bankAccountData: {
                    bankABANumber: '',
                    bankAccountNumber: '',
                    publicId_WMIC: '',
                    bankAccountType: undefined,
                    bankName: undefined
                },
                creditCardData: undefined
            },
            accountID_WMIC: billingData?.accountSummary?.accountNumber,
            payor_WMIC: {
                emailAddress: accountContact?.emailAddress1,
                phoneNumber: getPrimaryPhoneType(accountContact),
                primaryAddress: {
                    displayName: accountContact?.displayName,
                    addressLine1: accountContact?.primaryAddress?.addressLine1,
                    city: accountContact?.primaryAddress?.city,
                    state: accountContact?.primaryAddress?.state,
                    postalCode: trimLongFormZipCodes(accountContact?.primaryAddress.postalCode),
                    country: accountContact?.primaryAddress?.country
                },
                accountHolder: true,
                firstName: splitFullName(accountContact?.displayName, 0),
                lastName: splitFullName(accountContact?.displayName, 1)
            }
        }, 'bc', 'wmic.edge.capabilities.billing.dto.DirectBillPaymentDTO_WMIC');
        updateDirectBillDTO(directBillDTOModel);
        if (accountContact !== undefined) {
            getAccountBillingDetailsForPolicy();
        }
    }, [accountContact]);

    const getPaymentDates = () => {
        const res = [];
        const todayDate = DateUtil.trimToMidnight(new Date());
        res.push({ value: todayDate.toISOString(), displayName: `${translator(messages.today)} ${intl.formatDate(todayDate, { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}` });

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= NUM_OF_DAYS_IN_FUTURE_PAYMENTS; i++) {
            const d = DateUtil.trimToMidnight(DateUtil.addDays(new Date(), i));
            res.push({ value: d.toISOString(), displayName: intl.formatDate(d, { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) });
        }
        return res;
    };

    const isBankAccountPayment = () => paymentMethod === PAYMENT_METHODS.WIRE;

    const isCreditCardPayment = () => paymentMethod === PAYMENT_METHODS.CC;

    const isPaymentMethodSelected = () => isBankAccountPayment() || isCreditCardPayment();

    const isBankAccountFormValid = () => VALIDATORS.ROUTING_TRANSIT_NUMBER.test(routingTransitNumber) && VALIDATORS.BANK_ACCOUNT_NUMBER.test(bankAccountNumber);

    const isManageBankAccounts = () => bankAccounts.length > 0 && manageBankAccountsAvailable().isAvailable;

    const hasBankAccountTypeSet = () => isBankAccountPayment() && directBillDTO.paymentInstrument.bankAccountData.bankAccountType !== undefined;

    const getAccountTypeText = (type) => {
        switch (type) {
            case 'checking':
                return translator(messages.checking);
            case 'savings':
                return translator(messages.savings);
            default:
                return '';
        }
    };

    const getBankAccountOption = (bankAccount) => bankAccount.nickName_WMIC || translator(messages.bankOptionName, {
        accountType: getAccountTypeText(bankAccount.bankAccountType), accountNumber: bankAccount.bankAccountNumber
    });

    const isProcessBankAccountPaymentButtonEnabled = () => {
        if (isPaymentMethodSelected()) {
            const result = hasBankAccountTypeSet() && isBankAccountFormValid();
            if (isBankAccountPayment()) {
                if (useNewBankAccount) {
                    return result && (!saveBankAccount || agreeBankingInfoStorage);
                }
                return result;
            }
        }
        return false;
    };

    const round = (amount) => {
        if (amount === null || amount === '' || isNaN(amount) || amount === undefined) {
            return null;
        }
        const num = new Decimal(amount);
        return num.toDecimalPlaces(2).toString();
    };

    const stopPaymentCheck = () => {
        if (checkPaymentInterval) {
            clearInterval(checkPaymentInterval);
            checkPaymentInterval = undefined;
        }
        finishedCheckingPaymentStatus = true;
    };

    const checkPaymentStatus = (processID) => {
        if (checkPaymentIntervalInteraction < CHECK_PAYMENT_STATUS_ATTEMPTS) {
            checkPaymentIntervalInteraction++;
            // Check the payment status
            AccountBillingDetailsService.getOTWPaymentStatus_WMIC(processID, authHeader).then((response) => {
                if (response && response.paymentStatus && response.processStatus && !finishedCheckingPaymentStatus) {
                    if (response.paymentStatus.toLowerCase() === SUCCESSFUL_PAYMENT_STATUS && response.processStatus.toLowerCase() === PROCESS_STATUS.COMPLETED) {
                        // If payment complete
                        stopPaymentCheck();
                        onPaymentSuccess(saveBankAccount, isBankAccountSaved);
                    } else if (response.processStatus.toLowerCase() === PROCESS_STATUS.FAILED) {
                        stopPaymentCheck();
                        if (response.paymentTransactionMessage && response.paymentTransactionMessage.toLowerCase().includes('routing')) {
                            saveFilledInfo();
                            onInvalidRoutingTransitNumber();
                        } else {
                            onPaymentFailed();
                        }
                    } else if (response.processStatus.toLowerCase() === PROCESS_STATUS.OPTIMISTIC) {
                        stopPaymentCheck();
                        if (paymentDate === today) {
                            onPaymentInProgress(saveBankAccount, isBankAccountSaved);
                        } else {
                            onPaymentScheduled(paymentDate, saveBankAccount, isBankAccountSaved);
                        }
                    }
                }
            });
        } else {
            // Checked for payment the maximum number of times. Change the message to the user.
            stopPaymentCheck();
            if (paymentDate === today) {
                onPaymentInProgress(saveBankAccount, isBankAccountSaved);
            } else {
                onPaymentScheduled(paymentDate, saveBankAccount, isBankAccountSaved);
            }
        }
    };

    const addNewBankingInfo = () => {
        WMICPaymentService.validateABA(routingTransitNumber)
            .then((response) => response.json())
            .then((response) => {
                if (response?.ABAResult === 'true') { // don't save the bank account unless valid
                    directBillDTO.value.paymentInstrument.bankAccountData.bankName = response?.ABABankName;
                    directBillDTO.value.paymentInstrument.bankAccountData = {
                        bankABANumber: directBillDTO.value.paymentInstrument.bankAccountData.bankABANumber,
                        bankAccountNumber: directBillDTO.value.paymentInstrument.bankAccountData.bankAccountNumber,
                        bankAccountType: directBillDTO.value.paymentInstrument.bankAccountData.bankAccountType,
                        bankName: directBillDTO.value.paymentInstrument.bankAccountData.bankName
                    };

                    AccountBillingDetailsService.addNewBankingInfo(
                        billingData?.accountSummary?.accountNumber,
                        directBillDTO.value.paymentInstrument.bankAccountData,
                        accountContact?.publicID, authHeader
                    ).then((isBankAccountAdded) => {
                        isBankAccountSaved = isBankAccountAdded.result;
                    });
                }
            });
    };

    const handleInitialPaymentResponse = (paymentTransactionStatusDTO) => {
        if (paymentTransactionStatusDTO) {
            finishedCheckingPaymentStatus = false;
            if (paymentTransactionStatusDTO.errorCode === GW_OTW_SETUP_FAILED_BANK_TRANSIT_NUMBER) {
                saveFilledInfo();
                onInvalidRoutingTransitNumber();
            } else if (paymentTransactionStatusDTO.paymentStatus.toLowerCase() === SUCCESSFUL_PAYMENT_STATUS) {
                if (paymentTransactionStatusDTO.processStatus.toLowerCase() === PROCESS_STATUS.INITIATED
                    || paymentTransactionStatusDTO.processStatus.toLowerCase() === PROCESS_STATUS.IN_PROGRESS) {
                    // The happy path will go here. Payment process has been initiated or is in progress. Need to poll to see if it's done
                    checkPaymentIntervalInteraction = 0;
                    checkPaymentInterval = setInterval(checkPaymentStatus, CHECK_PAYMENT_STATUS_TIME, paymentTransactionStatusDTO.processID);

                    if (saveBankAccount && agreeBankingInfoStorage) {
                        addNewBankingInfo();
                    }
                } else if (paymentTransactionStatusDTO.processStatus.toLowerCase() === PROCESS_STATUS.COMPLETED
                    && !finishedCheckingPaymentStatus) {
                    // Payment happened really fast since we JUST sent the request. I guess this is possible.
                    stopPaymentCheck();
                    onPaymentSuccess(saveBankAccount, isBankAccountSaved);

                    if (saveBankAccount && agreeBankingInfoStorage) {
                        addNewBankingInfo();
                    }
                } else if (!finishedCheckingPaymentStatus) {
                    // Something is wrong. It shouldn't get here.
                    onPaymentFailed();
                    stopPaymentCheck();
                }
            } else {
                // General error message
                onPaymentFailed();
                stopPaymentCheck();
            }
        } else {
            onPaymentFailed();
            stopPaymentCheck();
        }
    };

    const makeOTW = () => {
        AccountBillingDetailsService.makeOneTimeBankAccountPayment_WMIC(directBillDTO.value, authHeader)
            .then((paymentTransactionStatusDTO) => {
                // The payment request was submitted. Need to poll for the successful process and payment
                handleInitialPaymentResponse(paymentTransactionStatusDTO);
            })
            .catch(() => {
                onPaymentFailed();
            });
    };

    const filterNonDigits = (handler) => (value) => {
        const filteredInput = value.replace(/[^\d]/g, '');
        handler(filteredInput);
    };

    const isListBill = () => {
        return WMICPaymentUtil.isListBill(billingData);
    };

    const manageBankingInformation = () => {
        if (isManageBankAccounts()) {
            const accountInfo = {
                accountNumber: billingData?.accountSummary?.accountNumber,
                firstName: accountContact?.firstName,
                lastName: accountContact?.lastName,
                policyId: billingData.policyId,
                termNumber: billingData.termNumber,
                publicId: accountContact?.publicID
            };
            saveFilledInfo();
            onManageBankingInformation(accountInfo);
        }
    };

    const makeBankAccountPayment = () => {
        directBillDTO.value.paymentInstrument.bankAccountData.bankABANumber = routingTransitNumber;
        directBillDTO.value.paymentInstrument.bankAccountData.bankAccountNumber = bankAccountNumber;
        directBillDTO.value.paymentInstrument.bankAccountData.bankAccountType = bankAccountType;
        directBillDTO.value.amountPaid = round(selectedPaymentAmount.amount);
        directBillDTO.value.paymentDate_WMIC = paymentDate;

        const paymentMethodType = viewModelService.productMetadata.get('bc').types.getTypelist('PaymentMethod').getCode(CONSTANTS.PAYMENT_METHODS.ONE_TIME_WITHDRAWAL);
        directBillDTO.value.paymentInstrument.paymentMethod = paymentMethodType.code;
        const invoiceStreams = new Array(WMICPaymentUtil.getInvoiceStreamId(policyData, billingData, billingData.policyStatus));
        directBillDTO.value.invoiceIds = invoiceStreams;
        directBillDTO.value.isListBill_WMIC = isListBill();
        onPaymentProcessing();

        if (today !== paymentDate) {
            WMICPaymentService.validateABA(routingTransitNumber)
                .then((response) => response.json())
                .then((response) => {
                    if (response?.ABAResult === 'true') {
                        makeOTW();
                    } else if (response?.ABAResult === 'false') {
                        saveFilledInfo();
                        onInvalidRoutingTransitNumber();
                    } else {
                        onPaymentFailed();
                    }
                })
                .catch(() => {
                    onPaymentFailed();
                });
        } else {
            makeOTW();
        }
    };

    const makeCreditCardPayment = () => {
        const encodeQueryString = (queryString) => {
            return encodeURIComponent(Buffer.from(queryString).toString('base64'));
        };

        const stateForURL = `state=${JSON.stringify({
            stateName: 'account-policy-details',
            stateParams: { policyNumber: `${billingData.policyId}/${billingData.termNumber}` }
        })}`;
        const invoiceStreamId = WMICPaymentUtil.getInvoiceStreamId(policyData, billingData, billingData.policyStatus);
        const entityForURL = `entity=${invoiceStreamId}`;
        const redirectUrl = `${env.DEPLOYMENT_URL}/account-management/home`;
        const completeURL = `${redirectUrl}?params=${encodeQueryString(`paymentstatus=complete&${stateForURL}&${entityForURL}`)}`;
        const cancelURL = `${redirectUrl}?params=${encodeQueryString(`paymentstatus=cancel&${stateForURL}`)}`;
        const errorURL = `${redirectUrl}?params=${encodeQueryString(`paymentstatus=error&${stateForURL}&${entityForURL}`)}`;

        const params = {
            entityId: invoiceStreamId,
            payAmount: round(selectedPaymentAmount.amount),
            owingAmount: round(WMICPaymentUtil.getSuggestedPaymentAmount(billingData).amount),
            amount: round(selectedPaymentAmount.amount),
            postalCode: trimLongFormZipCodes(accountContact?.primaryAddress.postalCode),
            currency: selectedPaymentAmount.currency,
            state: accountContact?.primaryAddress.state,
            city: accountContact?.primaryAddress.city,
            country: accountContact?.primaryAddress.country,
            email: accountContact?.emailAddress1,
            name: accountContact?.displayName,
            addressLine1: accountContact?.primaryAddress.addressLine1,
            userphone: getPrimaryPhoneType(accountContact),
            selectPaymentMethod: 'CREDIT_CARD',
            urlComplete: completeURL,
            urlCancel: cancelURL,
            urlError: errorURL
        };

        ReactDOM.render(
            WMICPaymentService.createPaymentForm(params),
            document.getElementById('creditCardPaymentForm')
        );
    };

    const onProcessPayment = () => {
        if (isCreditCardPayment()) {
            makeCreditCardPayment();
        } else if (isBankAccountPayment()) {
            makeBankAccountPayment();
        }
    };

    const onUpdatePaymentMethod = (event) => {
        if (paymentMethod === PAYMENT_METHODS.WIRE && event.target.value === PAYMENT_METHODS.CC) {
            const oldPaymentDate = paymentDate;
            resetBankAccountInfo();
            WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.PAYMENT_DATE, oldPaymentDate);
        }
        updatePaymentMethod(event.target.value);
    };

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(messages.makeAPayment)}</h3>
            <div className={styles['ww-payment-modal-body']}>
                <p className={styles['wmic-large-footer']}>
                    <strong>{ translator(messages.paymentAmount) }</strong>
                    <CurrencyField
                        id="selectedPaymentAmountCurrencyField"
                        value={selectedPaymentAmount}
                        dataType="object"
                        readOnly
                        hideLabel
                        className={styles['wmic-pending-payment-value']}
                    />
                </p>
                <label htmlFor={DOM_ELEMENT_IDS.PAYMENT_METHOD_DROPDOWN_SELECT} className={styles['wmic-input-container']}>
                    {translator(messages.selectAPaymentMethod)}
                    <select id={DOM_ELEMENT_IDS.PAYMENT_METHOD_DROPDOWN_SELECT} onChange={onUpdatePaymentMethod} className={styles.select}>
                        <option value="" disabled="disabled" selected>{translator(messages.select)}</option>
                        <option
                            value={PAYMENT_METHODS.CC}
                            disabled={WMICPaymentUtil.isAmountOverCreditCardLimit(selectedPaymentAmount)}
                        >
                            { translator(messages.creditCardOrDebitCard499ProcessingFee) }
                        </option>
                        { !WMICPaymentUtil.isListBill(billingData) && (
                            <option value={PAYMENT_METHODS.WIRE}>
                                {translator(messages.checkingOrSavingsAccountNoProcessingFee)}
                            </option>
                        )}
                    </select>
                    { paymentMethod === PAYMENT_METHODS.CC && (
                        <img className={styles['wmic-payment-options-img']} src={ccImg} alt={translator(messages.masterCardVisaDiscover)} />
                    )}
                </label>
                { paymentMethod === PAYMENT_METHODS.CC && (
                    <div>
                        <p className={classNames('ww-card-subhead-alert', styles['wmic-payment-text'])}>
                            {translator(messages.noteTheCreditCardProcessingCompanyWillChargeYouANonRefundable499ProcessingConvenienceFee)}
                        </p>
                        <p className={classNames('ww-subscript', styles['wmic-payment-text'])}>
                            {translator(messages.uponClickingContinueYouWillBeRedirectedToAThirdPartyWebsite)}
                        </p>
                        <div id="creditCardPaymentForm" />
                    </div>
                )}
                { paymentMethod === undefined && (
                    <div>
                        <p className={styles['wmic-large-footer']}>
                            <strong>{translator(messages.creditCardOrDebitCard)}</strong>
                            <br />
                            {translator(messages.theMaximumPaymentPerTransactionIs1500)}
                            <br />
                            <img className={styles['wmic-payment-options-img']} src={ccImg} alt={translator(messages.masterCardVisaDiscover)} />
                        </p>
                        <p className={styles['wmic-large-footer']}>
                            <strong>{translator(messages.checkingOrSavingsAccountOneTimeWithdrawalNoProcessingFee)}</strong>
                            <br />
                            {translator(messages.noFeesOrTransactionLimitsApplyToPaymentsMadeFromYourBankAccount)}
                        </p>
                    </div>
                )}
                <div style={paymentMethod === PAYMENT_METHODS.WIRE ? {} : { display: 'none' }}>
                    { (futureDatePaymentAvailability().isAvailable) && (
                        <div>
                            <label htmlFor={DOM_ELEMENT_IDS.PAYMENT_DATE_DROPDOWN_SELECT}>
                                {translator(messages.selectPaymentDate)}
                                <select
                                    id={DOM_ELEMENT_IDS.PAYMENT_DATE_DROPDOWN_SELECT}
                                    onChange={(event) => { updatePaymentDate(event.target.value); }}
                                    className={classNames(styles.select, styles['wmic-dropdown-select'])}
                                >
                                    {
                                        getPaymentDates().map((d) => {
                                            return (
                                                <option value={d.value}>
                                                    {d.displayName}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </label>
                            { billingData?.amountOverDue?.amount < 1 && (
                                <p>
                                    { paymentDateWarningPaymentDue() && (
                                        <p>
                                            <i className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                            <span>{WMICRichTextUtil.translateRichText(translator(messages.pleaseNoteThatTheSelectedTransactionDateIsPastYourCurrentPaymentDueDate, { transactionDate: intl.formatDate(new Date(directBillDTO.value.paymentDate_WMIC), { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }), paymentDueDate: intl.formatDate(new Date(paymentDueDate), { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) }))}</span>
                                        </p>
                                    )}
                                    { paymentDateWarningNextPlannedDate() && (
                                        <p>
                                            <i className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                            <span>{WMICRichTextUtil.translateRichText(translator(messages.pleaseNoteThatTheSelectedTransactionDateIsPastYourCurrentPaymentDueDate, { transactionDate: intl.formatDate(new Date(directBillDTO.value.paymentDate_WMIC), { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }), paymentDueDate: intl.formatDate(new Date(nextPlannedAmountPaymentDate), { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) }))}</span>
                                        </p>
                                    )}
                                </p>
                            )}
                            <h3 className={styles['wmic-payment-subhead']}>{translator(messages.enterBankAccountInformation)}</h3>
                        </div>
                    )}
                    {bankAccountsLoading && (
                        <WMICLoader />
                    )}
                    { (!bankAccountsLoading && isManageBankAccounts()) && (
                        <div>
                            <label htmlFor={DOM_ELEMENT_IDS.BANK_ACCOUNT_DROPDOWN_SELECT}>
                                {translator(messages.selectABankAccount)}
                                <select
                                    id={DOM_ELEMENT_IDS.BANK_ACCOUNT_DROPDOWN_SELECT}
                                    onChange={(event) => { selectBankAccount(event.target.value); }}
                                    className={classNames(styles.select, styles['wmic-dropdown-select'])}
                                >
                                    <option value="" disabled="disabled" selected>{translator(messages.select)}</option>
                                    {
                                        bankAccounts.map((ba) => {
                                            return (
                                                <option value={ba.publicId_WMIC}>
                                                    {getBankAccountOption(ba)}
                                                </option>
                                            );
                                        })
                                    }
                                    <option value={NEW_BANK_ACCOUNT_OPTION}>
                                        {translator(messages.payWithAnotherBankAccount)}
                                    </option>
                                </select>
                            </label>
                            <a
                                className={styles.manageBankingInformation}
                                onClick={() => manageBankingInformation()}
                            >
                                {translator(messages.manageBankAccounts)}
                            </a>
                        </div>
                    )}
                    { ((!bankAccountsLoading && useNewBankAccount) || !manageBankAccountsAvailable().isAvailable) && (
                        <div>
                            <RadioButtonField
                                availableValues={[
                                    {
                                        code: 'checking',
                                        name: translator(messages.checking)
                                    },
                                    {
                                        code: 'savings',
                                        name: translator(messages.savings)
                                    }
                                ]}
                                id={DOM_ELEMENT_IDS.BANK_ACCOUNT_TYPE_RADIO}
                                value={bankAccountType}
                                onValueChange={updateBankAccountType}
                                label={translator(messages.bankAccountType)}
                                labelClassName={styles['wmic-radio-button-label']}
                                className={styles['wmic-radio-wrap']}
                            />

                            { bankAccountType === undefined && (
                                <p className={classNames('ww-subscript', styles['wmic-payment-text'])}>
                                    {translator(messages.pleaseChooseAnAccountTypeBeforeProceeding)}
                                </p>
                            )}
                            { paymentMethod === PAYMENT_METHODS.WIRE && bankAccountType !== undefined && (
                                <div>
                                    <p className={styles['wmic-large-footer']}>
                                        <i className={classNames('fa fa-exclamation-circle', styles['wmic-icon-notification'])} />
                                        {translator(messages.nonSufficientFundsFeeWillBeAssessed)}
                                        <img src={chequeImg} alt={translator(messages.chequeSample)} />
                                        <span className="ww-subscript">{translator(messages.checkNumberIsNotARequiredField)}</span>
                                    </p>
                                    <InputField
                                        id={DOM_ELEMENT_IDS.ROUTING_TRANSIT_NUMBER_INPUT}
                                        value={routingTransitNumber}
                                        label={translator(messages.routingTransitNumber)}
                                        onValueChange={filterNonDigits(updateRoutingTransitNumber)}
                                        className={styles['wmic-input-field']}
                                        labelClassName={styles['wmic-input-field-label']}
                                        validator={{
                                            pattern: VALIDATORS.ROUTING_TRANSIT_NUMBER,
                                            message: translator(messages.routingTransitNumberMustBe9DigitsLong)
                                        }}
                                    />
                                    <InputField
                                        id={DOM_ELEMENT_IDS.BANK_ACCOUNT_NUMBER_INPUT}
                                        value={bankAccountNumber}
                                        label={translator(messages.bankAccountNumber)}
                                        onValueChange={filterNonDigits(updateBankAccountNumber)}
                                        className={styles['wmic-input-field']}
                                        labelClassName={styles['wmic-input-field-label']}
                                        validator={{
                                            pattern: VALIDATORS.BANK_ACCOUNT_NUMBER,
                                            message: translator(messages.bankAccountNumberMustBe1To17Digits)
                                        }}
                                    />
                                    {manageBankAccountsAvailable().isAvailable && (
                                        <div>
                                            <WMICCheckbox
                                                id={DOM_ELEMENT_IDS.SAVE_BANKING_INFO_CHECKBOX}
                                                value={saveBankAccount}
                                                labelClassName={styles.agreeText}
                                                label={translator(messages.saveBankAccountForFuturePayments)}
                                                onValueChange={(newValue) => {
                                                    updateSaveBankAccount(newValue);
                                                    if (!newValue) {
                                                        updateAgreeBankingInfoStorage(false);
                                                    }
                                                }}
                                            />
                                            {saveBankAccount && (
                                                <div>
                                                    <div className="wmic-authorization-disclaimer-container">
                                                        <h4 className="wmic-authorization-disclaimer-title">{translator(messages.bankInfoStorageConsentTitle)}</h4>
                                                        <p className="wmic-authorization-disclaimer-legal-text">{translator(messages.bankInfoStorageText)}</p>
                                                        <p className="wmic-authorization-disclaimer-legal-text">{translator(messages.bankInfoStorageTextPart2)}</p>
                                                        <WMICCheckbox
                                                            id={DOM_ELEMENT_IDS.AGREE_BANKING_INFO_STORAGE_CHECKBOX}
                                                            value={agreeBankingInfoStorage}
                                                            label={translator(messages.agreeSaveBankAccount)}
                                                            labelClassName="wmic-authorization-disclaimer-agree-text"
                                                            onValueChange={updateAgreeBankingInfoStorage}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject(); }}
                    type="outlined"
                >
                    {translator(messages.cancel)}
                </WMICButton>
                { (paymentMethod !== PAYMENT_METHODS.WIRE || (paymentMethod === PAYMENT_METHODS.WIRE && bankAccountType === undefined)) && (
                    <WMICButton
                        onClick={() => { onProcessPayment(); }}
                        type="primary"
                        disabled={paymentMethod !== PAYMENT_METHODS.CC}
                    >
                        {translator(messages.continue)}
                    </WMICButton>
                )}
                { paymentMethod === PAYMENT_METHODS.WIRE && bankAccountType !== undefined && (
                    <WMICButton
                        onClick={() => { onProcessPayment(); }}
                        type="primary"
                        disabled={!isProcessBankAccountPaymentButtonEnabled()}
                    >
                        {translator(messages.processPayment)}
                    </WMICButton>
                )}
            </ModalFooter>
        </Fragment>
    );
};

WMICMakeAPaymentModal.propTypes = {
    data: PropTypes.shape({}).isRequired,
    onReject: PropTypes.func.isRequired,
    onManageBankingInformation: PropTypes.func.isRequired,
    onPaymentProcessing: PropTypes.func.isRequired,
    onPaymentFailed: PropTypes.func.isRequired,
    onInvalidRoutingTransitNumber: PropTypes.func.isRequired,
    onPaymentSuccess: PropTypes.func.isRequired,
    onPaymentInProgress: PropTypes.func.isRequired,
    onPaymentScheduled: PropTypes.func.isRequired,
    selectedPaymentAmount: PropTypes.shape({}).isRequired
};

export default WMICMakeAPaymentModal;