import _ from 'lodash';

function formatAdjusterPhoneNumber(adjuster, format) {
    let number = '';
    if (adjuster.phoneNumberExtension) {
        number = number.concat(adjuster.phoneNumber, format, adjuster.phoneNumberExtension);
    } else {
        number = adjuster.phoneNumber;
    }
    return number;
}

function getDisplayablePhoneNumberWithExtension(adjuster) {
    return formatAdjusterPhoneNumber(adjuster, ' ext');
}

function getAdjusters(data) {
    const adjusters = data?.lobs?.personalAuto?.adjusters_WMIC || [];

    _.each(adjusters, (adjuster) => {
        _.set(adjuster, 'phoneNumberDisplayable', getDisplayablePhoneNumberWithExtension(adjuster));
    });

    return adjusters;
}

function getListAdjusterCoverages(adjuster) {
    const coverages = adjuster?.coverages || [];
    if (coverages?.length > 0) {
        let displayableCoverages = [];
        _.each(coverages, (cov, index) => {
            displayableCoverages = displayableCoverages.concat(cov.name);
            if (index < coverages.length - 1) {
                displayableCoverages = displayableCoverages.concat(', ');
            }
        });
        return displayableCoverages;
    }
    return [];
}

export default {
    formatAdjusterPhoneNumber,
    getDisplayablePhoneNumberWithExtension,
    getAdjusters,
    getListAdjusterCoverages
};