import _ from 'lodash';

function WMICPolicyChangesVehicleVM(data) {
    if (data) {
        _.extend(this, data);
        if (!this.coverageTermChanges) {
            this.coverageTermChanges = { terms: [] };
        }
    } else {
        this.vehicleFixedId = '';
        this.coverageTermChanges = { terms: [] };
    }
}

export default WMICPolicyChangesVehicleVM;