import React from 'react';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { WMICButton } from 'wmic-components-platform-react';
import { isFunction } from 'lodash';
import messages from './WMICModalFooter.messages';
import styles from './WMICModalFooter.module.scss';

const actionShape = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        onClick: PropTypes.func,
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        content: PropTypes.node,
        className: PropTypes.string
    })
]);

const WMICModalFooter = ({
    actions,
    id
}) => {
    const translator = useTranslator();

    return (
        <Flex className={styles.footerContainer}>
            {
                Object.entries(actions).map(
                    ([key, value]) => {
                        const {
                            hidden,
                            disabled,
                            onClick,
                            content,
                            className,
                            type
                        } = isFunction(value) ? { onClick: value } : value;

                        return (
                            !hidden && (
                                <WMICButton
                                    id={`footer-button-${key}-${id}`}
                                    disabled={disabled}
                                    onClick={onClick}
                                    type={type}
                                >
                                    {translator(content) || translator(messages[key])}
                                </WMICButton>
                            )
                        );
                    }
                )
            }
        </Flex>
    );
};

WMICModalFooter.propTypes = {
    actions: PropTypes.shape({
        cancel: actionShape,
        continue: actionShape,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default WMICModalFooter;
