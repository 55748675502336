import { defineMessages } from 'react-intl';

export default defineMessages({
    showAllDocuments: {
        id: 'policy.common.directives.templates.policy-documents.Show All Documents',
        defaultMessage: 'Show All Documents'
    },
    showAllDocumentsTrackButtonIdentifier: {
        id: 'policy.common.directives.templates.policy-documents.tracking.Show All Documents',
        defaultMessage: 'Policy Details - Show All Policy Documents'
    },
    showAllAdditionalDocumentsTrackButtonIdentifier: {
        id: 'policy.common.directives.templates.policy-documents.tracking.Show All Additional Documents',
        defaultMessage: 'Policy Details - Show All Additional Documents'
    },
    policyDocuments: {
        id: 'policy.common.views.account-policy-details.Policy Documents',
        defaultMessage: 'POLICY DOCUMENTS'
    },
    policyDocumentsAria: {
        id: 'policy.common.views.account-policy-details.Policy Documents Aria',
        defaultMessage: 'Click to open document {documentTitle} dated {date}'
    },
    additionalDocuments: {
        id: 'policy.common.views.account-policy-details.Additional Documents',
        defaultMessage: 'ADDITIONAL DOCUMENTS'
    },
    additionalDocumentsCollapsed: {
        id: 'policy.common.views.account-policy-details.tracking.Additional Documents Collapsed',
        defaultMessage: 'Policy Details - Additional Documents Section Collapsed'
    },
    additionalDocumentsExpanded: {
        id: 'policy.common.views.account-policy-details.tracking.Additional Documents Expanded',
        defaultMessage: 'Policy Details - Additional Documents Section Expanded'
    },
    policyDocumentsTrackButtonIdentifier: {
        id: 'policy.common.views.account-policy-details.tracking.Policy Document Clicked',
        defaultMessage: 'Policy Details - Policy Documents - {documentName}'
    },
    statementofaccount_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.statementofaccount_wmic',
        defaultMessage: 'Statement of Account'
    },
    auto_recurring_payment_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.auto_recurring_payment_wmic',
        defaultMessage: 'Automated Recurring Payment Notice'
    },
    remindernotice_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.remindernotice_wmic',
        defaultMessage: 'Reminder Notice'
    },
    lapsenotice_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.lapsenotice_wmic',
        defaultMessage: 'Lapse Notice'
    },
    renewalremindernotice_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.renewalremindernotice_wmic',
        defaultMessage: 'Renewal Reminder Notice'
    },
    rescindingnotice_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.rescindingnotice_wmic',
        defaultMessage: 'Rescinding Notice'
    },
    noticeofintenttocancel_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.noticeofintenttocancel_wmic',
        defaultMessage: 'Notice of Intent to Cancel'
    },
    noticeofcancellation_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.noticeofcancellation_wmic',
        defaultMessage: 'Notice of Cancellation'
    },
    nonnegotiablepayment_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.nonnegotiablepayment_wmic',
        defaultMessage: 'Non Negotiable Payment Notice'
    },
    premiumduecollectionnotice_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.premiumduecollectionnotice_wmic',
        defaultMessage: 'Premium Due Collection Notice'
    },
    delinquentcollectionnotice_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.delinquentcollectionnotice_wmic',
        defaultMessage: 'Delinquent Collection Notice'
    },
    listbill_insured_wmic: {
        id: 'policy.common.views.account-policy-details.friendlyName.listbill_insured_wmic',
        defaultMessage: 'Property Policy Mortgagee Notice'
    },
    propertyPolicyMortgageeNotice: {
        id: 'policy.common.views.account-policy-details.friendlyName.Property Policy Mortgagee Notice',
        defaultMessage: 'Property Policy Mortgagee Notice'
    },
    viewDocument: {
        id: 'policy.common.directives.templates.policy-documents.View Document',
        defaultMessage: 'View Document'
    },
    correspondence: {
        id: 'policy.common.directives.templates.policy-documents.Correspondence',
        defaultMessage: 'Correspondence'
    }
});