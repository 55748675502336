// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICInsuranceHistoryPoliciesComponent__inlineMessaging{align-items:center;display:flex}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICInsuranceHistoryPolicies/WMICInsuranceHistoryPoliciesComponent.module.scss"],"names":[],"mappings":"AACA,6DAEI,kBAAA,CADA,YACA","sourcesContent":["\n.inlineMessaging {\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineMessaging": "app__WMICInsuranceHistoryPoliciesComponent__inlineMessaging"
};
export default ___CSS_LOADER_EXPORT___;
