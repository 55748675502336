import { defineMessages } from 'react-intl';

export default defineMessages({
    addPoliciesHeader: {
        id: 'portal.views.add-policy-modal.Add Policies to Your Profile',
        defaultMessage: 'Add Policies to Your Profile'
    },
    useFormToAddPolicies: {
        id: 'portal.views.add-policy-modal.Use the form below to add additional policies to your profile.',
        defaultMessage: 'Use the form below to add additional policies to your profile.'
    },
    accountOrPolicyNumber: {
        id: 'portal.views.add-policy-modal.Account or Policy Number:',
        defaultMessage: 'Account or Policy Number:'
    },
    enterTheAccountOrPolicyNumberAria: {
        id: 'portal.views.add-policy-modal.Enter the account or policy number aria',
        defaultMessage: 'Enter the account or policy number'
    },
    removeNumberAria: {
        id: 'portal.views.add-policy-modal.remove number aria',
        defaultMessage: 'Remove {selectedNumber} from the list of entered account or policy numbers for linking.'
    },
    addAnotherPolicy: {
        id: 'portal.views.add-policy-modal.Add another policy',
        defaultMessage: '+ Add another policy'
    },
    policyNumberLocation: {
        id: 'portal.views.add-policy-modal.Policy Number Location',
        defaultMessage: 'Policy Number Location'
    },
    cancel: {
        id: 'portal.views.add-policy-modal.Cancel',
        defaultMessage: 'Cancel'
    },
    close: {
        id: 'portal.views.add-policy-modal.Close',
        defaultMessage: 'Close'
    },
    next: {
        id: 'portal.views.add-policy-modal.Next',
        defaultMessage: 'Next'
    },
    submit: {
        id: 'wmic.components.addPolicyConsentModal.submit',
        defaultMessage: 'Submit'
    },
    missingPolicy: {
        id: 'portal.views.add-policy-modal.Please enter your Account or Policy number.',
        defaultMessage: 'Please enter your Account or Policy number.'
    },
    wrongPolicy: {
        id: 'portal.views.add-policy-modal.Please enter a valid Account or Policy number.',
        defaultMessage: 'Please enter a valid Account or Policy number.'
    },
    duplicatedPolicy: {
        id: 'portal.views.add-policy-modal.The number entered is a duplicate. Please enter another Account or Policy number.',
        defaultMessage: 'The number entered is a duplicate. Please enter another Account or Policy number.'
    },
    addMorePolicies: {
        id: 'portal.views.add-policy-modal.Add More Policies',
        defaultMessage: 'Add More Policies'
    },
    unableToAddPolicies: {
        id: 'portal.views.add-policy-modal.We were unable to add additional policies based on the information you provided.',
        defaultMessage: 'We were unable to add additional policies based on the information you provided.'
    },
    youEnteredTheFollowing: {
        id: 'portal.views.add-policy-modal.You entered the following information:',
        defaultMessage: 'You entered the following information: {info}.'
    },
    foundPolicies: {
        id: 'portal.views.add-policy-modal.We were able to add the following policies to your portal account based on the information you provided.',
        defaultMessage: 'We were able to add the following policies to your portal account based on the information you provided.'
    },
    setContactPreference: {
        id: 'wmic.components.addPolicyConsentModal.Set Your Contact Preference',
        defaultMessage: 'Set Your Contact Preference'
    },
    paragraphOne: {
        id: 'wmic.components.addPolicyConsentModal.paragraphOne',
        defaultMessage: 'We\'ve found the following policies with the information you\'ve provided:'
    },
    paragraphTwo: {
        id: 'wmic.components.addPolicyConsentModal.paragraphTwo',
        defaultMessage: 'To complete linking your policies, confirm your preferred method of contact for all of your policies, including the policies currently listed in your online account.'
    },
    consentParagraphOne: {
        id: 'wmic.components.addPolicyConsentModal.consentParagraphOne',
        defaultMessage: 'With your consent Wawanesa Insurance will electronically deliver your policy documents, forms, billing statements, notices, claim-related documents and any other documents and communications permitted or required by law. Communications may contain documents or may provide access to an online account where a document or communication is available. Your consent is voluntary. Oregon policyholders receiving a discount related to electronic delivery of documents, will no longer receive the discount if authoriztion is cancelled.'
    },
    consentParagraphTwo: {
        id: 'wmic.components.addPolicyConsentModal.consentParagraphTwo',
        defaultMessage: 'To receive electronically delivered documents you must have a computer or other access device with an internet connection, an active email account, and software which permits you to receive, access and retain Portable Document Format or ("PDF") Files.'
    },
    consentParagraphThree: {
        id: 'wmic.components.addPolicyConsentModal.consentParagraphThree',
        defaultMessage: 'You may email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt; or call us at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; to do any of the following:'
    },
    consentListItemOne: {
        id: 'wmic.components.addPolicyConsentModal.consentListItemOne',
        defaultMessage: 'Opt-out of receiving electronic documents at any time'
    },
    consentListItemTwo: {
        id: 'wmic.components.addPolicyConsentModal.consentListItemTwo',
        defaultMessage: 'Update your email address to ensure you receive all communications'
    },
    consentListItemThree: {
        id: 'wmic.components.addPolicyConsentModal.consentListItemThree',
        defaultMessage: 'Request free paper copies of your policy or claim-related documents and communications'
    },
    consentQuestion: {
        id: 'wmic.components.addPolicyConsentModal.consentQuestion',
        defaultMessage: 'Would you like to receive your documents and communication by email?'
    },
    consentQuestionDescriptionAria: {
        id: 'wmic.components.addPolicyConsentModal.consentQuestionDescriptionAria',
        defaultMessage: 'Choose your document delivery preference given the two choices below.'
    },
    enteredPolicyNumberToTheListAria: {
        id: 'wmic.components.addPolicyConsentModal.enteredPolicyNumberToTheListAria',
        defaultMessage: 'Entered {pNumber} to the list'
    },
    consentYes: {
        id: 'wmic.components.addPolicyConsentModal.consentYes',
        defaultMessage: '&lt;pre&gt;YES&lt;/pre&gt;&lt;strong&gt; - I want to receive my documents electronically. By clicking here I confirm that I have read the information above and consent to the electronic delivery of documents and communications from Wawanesa Insurance which will be sent to {email}.&lt;/strong&gt;'
    },
    consentNo: {
        id: 'wmic.components.addPolicyConsentModal.consentNo',
        defaultMessage: '&lt;pre&gt;NO&lt;/pre&gt;&lt;strong&gt; - &lt;/strong&gt;I do not want to receive my documents electronically and want to receive communications via US mail.'
    },
    logAddingPolicyNumbers: {
        id: 'wmic.components.addPolicyConsentModal.Adding Policy Numbers to the list',
        defaultMessage: 'Adding {policyNumbers} to the list'
    },
    logUnhandledError: {
        id: 'wmic.components.addPolicyConsentModal.Possibly unhandled rejection',
        defaultMessage: 'Possibly unhandled rejection'
    },
    back: {
        id: 'portal.views.add-policy-modal.Back',
        defaultMessage: 'Back'
    }
});