// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICVehicleUsageComponent__addLocationBtn{display:flex;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-pa-react/components/WMICVehiclesUsage/WMICVehicleUsageComponent.module.scss"],"names":[],"mappings":"AACA,gDACI,YAAA,CACA,wBAAA","sourcesContent":["\n.addLocationBtn {\n    display: flex;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addLocationBtn": "app__WMICVehicleUsageComponent__addLocationBtn"
};
export default ___CSS_LOADER_EXPORT___;
