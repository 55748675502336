import { defineMessages } from 'react-intl';

export default defineMessages({
    twoOrMoreAtFaultLossesPriorThreeYears: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.Two or More At Fault Losses Prior Three Years',
        defaultMessage: 'Applicant, Named Insured or Named Operator with any combination of 2 or more hit and run or at fault losses in the prior 3 years'
    },
    criminalCodeOrSeriousConviction: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.Criminal Code or Serious Conviciton',
        defaultMessage: 'Applicant or Named Operator have 1 Criminal Code or Serious conviction in the prior 4 years'
    },
    twoOrMoreAtFaultLossesPriorFiveYears: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.Two or More At Fault Losses Prior Five Years',
        defaultMessage: 'Named Insured or Named Operator have 2 or more at-fault losses in the prior 5 years'
    },
    fourOrMoreConvictions: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.Four or More Convictions',
        defaultMessage: 'Applicant or Named Operator have 4 or more convictions of any type in the prior 3 years'
    },
    disctractedDrivingConviction: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.Distracted Driving Conviction',
        defaultMessage: 'Applicant, Named Insured or Named Operator have 1 distracted driving conviction in the prior 3 years'
    },
    oneAtFaultTwoMinorOneSeriousConviction: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.One At Fault Two Minor One Serious Conviction',
        defaultMessage: 'Applicant or Named Operator have 1 at-fault loss and 2 or more minor convictions or 1 or more serious conviction in the prior 3 years'
    },
    highPerformanceExceed500hp: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.High Performance vehicle exceeding 500hp',
        defaultMessage: 'High Performance car exceeding 500hp'
    },
    highPerformance: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.High PerformanceTurbo vehicle',
        defaultMessage: 'High Performance/Turbo vehicle'
    },
    KITCustomcars: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.KIT cars',
        defaultMessage: 'KIT cars / Custom built car'
    },
    KITcars: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.KIT cars',
        defaultMessage: 'KIT cars'
    },
    customizedVehicle: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicleRisk_Ext.Customized Vehicle',
        defaultMessage: 'Vehicle that is customized or modified with increased value or performance'
    },
    foreignExoticOrSportsCars: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Foreign exotic or sports cars',
        defaultMessage: 'Foreign exotic or sports cars'
    },
    antiqueOrClassicVehicle: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Antique or classic vehicle',
        defaultMessage: 'Antique or classic vehicle'
    },
    rightHandDriveVehicle: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Right-hand drive vehicle',
        defaultMessage: 'Right-hand drive vehicle'
    },
    miniOrPocketBike: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Mini or Pocket Bike',
        defaultMessage: 'Mini or Pocket Bike'
    },
    motardSupermotard: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Motard/Supermotard',
        defaultMessage: 'Motard/Supermotard'
    },
    offRoadMotocross: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Off-road Motocross',
        defaultMessage: 'Off-road Motocross'
    },
    allTerrainVehicles: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Three wheel All-Terrain Vehicles',
        defaultMessage: 'Three wheel All-Terrain Vehicles'
    },
    amphibiousVehicle: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Amphibious vehicle',
        defaultMessage: 'Amphibious vehicle'
    },
    allTerrainBuggy: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.All-Terrain Buggy',
        defaultMessage: 'All-Terrain Buggy'
    },
    childSizeOrJuniorSnowVehicle: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Child size or Junior Snow Vehicle',
        defaultMessage: 'Child size or Junior Snow Vehicle'
    },
    actor: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Actor',
        defaultMessage: 'Actor'
    },
    author: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Author',
        defaultMessage: 'Author'
    },
    broadcaster: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Broadcaster',
        defaultMessage: 'Broadcaster'
    },
    journalist: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Journalist in any media form',
        defaultMessage: 'Journalist in any media form'
    },
    labourUnionLeaders: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Labour/Union Leaders',
        defaultMessage: 'Labour/Union Leaders'
    },
    newsmediaEditor: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Newsmedia Editor',
        defaultMessage: 'Newsmedia Editor'
    },
    politician: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Politician',
        defaultMessage: 'Politician'
    },
    professionalAthlete: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Professional Athlete',
        defaultMessage: 'Professional Athlete'
    },
    professionalEntertainer: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Professional Entertainer',
        defaultMessage: 'Professional Entertainer'
    },
    highHazard: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISForUndesirableCommercialPurposes_wmic.highHazard',
        defaultMessage: 'High hazard'
    },
    longHaul: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISForUndesirableCommercialPurposes_wmic.longHaul',
        defaultMessage: 'Long haul'
    },
    usedOutsideCanada: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.ISForUndesirableCommercialPurposes_wmic.usedOutsideCanada',
        defaultMessage: 'Used outside of Canada'
    },
    singleWalledReconditioned: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Single Walled Reconditioned',
        defaultMessage: 'Single-walled or reconditioned'
    },
    locatedUnderground: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Located Underground',
        defaultMessage: 'Located underground'
    },
    uncertified: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Uncertified',
        defaultMessage: 'Uncertified'
    },
    notProfessionallyInstalled: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Not Professionally Installed',
        defaultMessage: 'Not professionally installed'
    },
    manufacturedByDTE: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Manufactured By DTE',
        defaultMessage: 'Manufactured by DTE'
    },
    cpAircraft: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.Aircraft',
        defaultMessage: 'Aircraft, airport, or marine work'
    },
    cpAbestos: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.Abestos',
        defaultMessage: 'Asbestos remediation'
    },
    cpBlasting: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.Blasting',
        defaultMessage: 'Blasting or use of explosives'
    },
    cpSprayPainting: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.SprayPainting',
        defaultMessage: 'Exterior spray painting or sandblasting'
    },
    cpWelding: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.Welding',
        defaultMessage: 'Off-premises welding'
    },
    cpOilfield: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.Oilfield',
        defaultMessage: 'Oilfield work'
    },
    cpPileDriving: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.PileDriving',
        defaultMessage: 'Pile driving'
    },
    cpStructuralDemolition: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.StructuralDemolition',
        defaultMessage: 'Structural demolition'
    },
    cpUnderpinning: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.Underpinning',
        defaultMessage: 'Underpinning'
    },
    cpWaterproofing: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.Waterproofing',
        defaultMessage: 'Waterproofing'
    },
    cpWorkOver4Storeys: {
        id: 'wmic-pe.quoteandbind.views.prequal-questions.BPASSCPPrequalPerformOperations_WMIC.cpWorkOver4Storeys',
        defaultMessage: 'Work over 4 storeys'
    },
});
