import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CoverageTermsIterableComponent.metadata.json5';

function CoverageTermsIterableComponent(props) {
    const { data: coveragesTermsData } = props;

    const generateCoverageTermsOverrides = useCallback(() => {
        const overrides = coveragesTermsData.terms.map((term, tindex) => {
            return {
                [`coveragesTermsContent${tindex}`]: {
                    label: term.name
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [coveragesTermsData]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        ...generateCoverageTermsOverrides()
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveragesTermsData}
            overrideProps={overrideProps}
        />
    );
}

CoverageTermsIterableComponent.propTypes = {
    data: PropTypes.shape({}).isRequired
};

export default CoverageTermsIterableComponent;
