import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react'
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { WMICLink, WMICHeading ,WMICIcon} from 'wmic-pe-components-platform-react';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import { CONSTANTS, WMICVariousUtil, WMICRichTextUtil, LOBConstants, MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';

import cx from 'classnames';

import { LOBmessages } from 'wmic-pe-capability-gateway-react';
import messages from './WMICNewQuoteAccountSearch.messages';
import styles from './WMICNewQuoteAccountSearch.module.scss';
import metadata from './WMICNewQuoteAccountSearch.metadata.json5';

class WMICNewQuoteAccountSearch extends Component {
    static contextType = TranslatorContext;

    state = {
        searchResult: false,
        submissionVM: {},
        existingSearchResult: [],
        typeOfAccount: ""
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                prevLocation: PropTypes.object,
                productCode: PropTypes.string
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        ...validationPropTypes
    };

    componentDidMount() {
        let { submissionVM } = this.state;
        const model = {};
        submissionVM = this.createVM(model);

        _.set(submissionVM, 'searchBy', 'byFirstLastName');
        this.setState({ submissionVM });
    };

    searchAccounts = async () => {
        const { submissionVM, typeOfAccount } = this.state;
        const { authHeader } = this.props;
        let { searchResult, existingSearchResult } = this.state;
        let accountResult = {};

        accountResult = await AccountService.accountSearchAccessibleForCurrentUser_WMIC(
            submissionVM.value,
            authHeader
        );
        searchResult = true;
        existingSearchResult = accountResult.accounts;

        if (existingSearchResult.length === 0) {
            this.newAccount()
        } else{
            const filteredAccounts = existingSearchResult.filter((currentAccount) => {
                if (typeOfAccount === CONSTANTS.Company.toLowerCase()) {
                    return currentAccount.isCompanyAccount
                }
                return currentAccount.isPersonalAccount;
            })

            existingSearchResult = filteredAccounts

            this.setState({
                searchResult,
                existingSearchResult
            });
        }
    };

    getTitleIcon = (productCode) => {
        const icon = WMICVariousUtil.getLobIcon(productCode)
        return <WMICIcon
            icon={icon}
            id="lobIcon"
            size="large"
            className={cx("lobIcon", "gw-mr-2")}
        />
    }

    getTitle = () => {
        const { history } = this.props;
        const { productCode } = history.location.state;
        const translator = this.context;

        return (
            <div className={cx("wizardHeaderJobDetails", 'gw-mb-5')}>
                {this.getTitleIcon(productCode)}
                <WMICHeading variant="h2" title={translator(LOBmessages[`${productCode}Title`])}/>
            </div>
        )
    }

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(
            model,
            'pc',
            'wmic.edge.ca.capabilities.gateway.account.search.dto.AccountSearchByCriteriaDTO_WMIC'
        );
    };

    writeValue = (value, path) => {
        const { submissionVM } = this.state;
        _.set(submissionVM, path, value);
        this.setState({ submissionVM });
    };

    handleCancel = () => {
        const { history } = this.props;
        this.props.modalContext.showConfirm({
            title: messages.cancelSubmission,
            message: messages.cancelMessage,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            cancelButtonText: messages.no,
            confirmButtonText: messages.yes,
        }).then((results) => {
            if (results === CONSTANTS.MODAL_RESULT.CANCEL|| results === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }
            return history.push('/');
        }, _.noop);
    };

    handleClear = () => {
        const { submissionVM } = this.state;
        this.setState({
            searchResult: false,
        });
        _.set(submissionVM, 'firstName.value', '');
        _.set(submissionVM, 'lastName.value', '');
        _.set(submissionVM, 'companyName.value', '');
        _.set(submissionVM, 'phone.value', '');
        this.setState({ submissionVM });
    };

    newAccount = () => {
        const { history } = this.props;
        const { productCode } = history.location.state;
        const { submissionVM } = this.state;
        const nextLocation = {
            accountSearchCriteria: _.get(submissionVM, 'value'),
            productCode
        };
        history.push('/wmic-new-quote', nextLocation);
    };

    navigateToAccount = (existingAccountData) => {
        const { history } = this.props;
        const { productCode } = history.location.state;
        const nextLocation = {
            productCode
        };
        history.push(`/accounts/${existingAccountData.accountNumber}/summary`, nextLocation);
    };

    getSearchResultTextContent = () => {
        const translator = this.context;
        const { submissionVM } = this.state;
        return (
            <p>
                {WMICRichTextUtil.translateRichText(translator(messages.searchResults, {name: this.getFullName(submissionVM)}))}
            </p>
        );
    };

    getFullName = (VM) => {
        if (VM.searchBy?.value.code === "byCompanyName") {
            return `${VM.companyName?.value}`
        }
        return `${VM.firstName?.value} ${VM.lastName?.value}`;
    }

    renderNameCell = (rowData) => {
        const icon = this.props.history.location.state.productCode === LOBConstants.CP && rowData.isCompanyAccount ? "mi-storefront" : "mi-person"
        return(
            <WMICLink icon={icon} displayOnly>
                {rowData.accountHolderName}
            </WMICLink>
        );
    }

    handleRowClick = (rowData) => {
        this.navigateToAccount(rowData);
    }

    onAccountTypeChange = (value, path) => {
        let { typeOfAccount } = this.state;
        typeOfAccount = value
        const { submissionVM } = this.state;
        _.set(submissionVM, path, value);
        if(value ===  CONSTANTS.Person.toLowerCase()) {
            _.set(submissionVM, 'searchBy', 'byFirstLastName');
        }
        else if(value ===  CONSTANTS.Company.toLowerCase()) {
            _.set(submissionVM, 'searchBy', 'byCompanyName');
        }
        this.setState({ submissionVM, typeOfAccount });
    }

    render() {
        const { submissionVM, searchResult, existingSearchResult } = this.state;
        const { setComponentValidation, isComponentValid } = this.props;
        const { productCode } = this.props.history.location.state;
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showRequired: true,
                parentNode: submissionVM,
            },
            searchButton: {
                disabled: !isComponentValid
            },
            newQuoteHeader: {
                content: this.getTitle()
            },
            newQuoteContent: {
                visible: !searchResult
            },
            buttonContainer: {
                visible: !searchResult
            },
            AccountsFoundContainer: {
                visible: searchResult
            },
            AccountsFoundSubtitle:{
                content: productCode === LOBConstants.CP ? messages.AccountAlreadyInSystemCommercialPackage : messages.AccountAlreadyInSystem
            },
            AccountsFoundTable: {
                data: existingSearchResult,
                onRowClick: this.handleRowClick
            },
            SearchResultsText: {
                content: this.getSearchResultTextContent()
            },
            personQuestionsContainer: {
                visible: _.get(submissionVM, 'contactType.value.code') === CONSTANTS.Person.toLowerCase()
                    || _.get(submissionVM, 'contactType.value.code') === undefined
            },
            companyQuestionsContainer: {
                visible: _.get(submissionVM, 'contactType.value.code') === CONSTANTS.Company.toLowerCase()
                && productCode !== LOBConstants.CP
            },
            companyQuestionsContainerCommercialLine: {
                visible: productCode === LOBConstants.CP
                    && _.get(submissionVM, 'contactType.value.code') === CONSTANTS.Company.toLowerCase()
            },
            typeOfAccount: {
                onValueChange: this.onAccountTypeChange
            }
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onSearchAccounts: this.searchAccounts,
                getCell: this.getCell,
                onSearchAgain: this.onSearchAgain,
                onCancel: this.handleCancel,
                onClear: this.handleClear,
                renderNameCell: this.renderNameCell,
                newAccount: this.newAccount
            },
        };

        const readValue = (id, path) =>
            readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                onValidationChange={setComponentValidation}
                onValueChange={this.writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}
export const WMICNewQuoteAccountSearchComponent = WMICNewQuoteAccountSearch;
export default withModalContext(withValidation(
        withViewModelService(withAuthenticationContext(withRouter(WMICNewQuoteAccountSearch))
    ))
);
