// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Cancellation__gwCancelPolicyTitleContainer{border-bottom:solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);margin-bottom:var(--GW-LAYOUT-8)}.app__Cancellation__gwPolicyCancellationContainer{padding-top:var(--GW-LAYOUT-8)}.app__Cancellation__cancellationMessage [class*=FieldMessage]{display:none}.app__Cancellation__gwEffectiveDateErrorMessage{color:var(--GW-COLOR-ERROR)}.app__Cancellation__cancelationInlineNotification{background-color:var(--GW-HIGHLIGHT-COLOR-1);border:1px solid var(--GW-SEPARATOR-COLOR);color:var(--GW-TEXT-COLOR-1)}.app__Cancellation__cancelationInlineNotification i[class*=mi-info]{color:var(--GW-BRAND-COLOR-2)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/Policies/Cancellation/Cancellation.module.scss"],"names":[],"mappings":"AAAA,iDACC,iEAAA,CACA,gCAAA,CAED,kDACC,8BAAA,CAGA,8DACC,YAAA,CAGF,gDACI,2BAAA,CAEJ,kDAIC,4CAAA,CAEA,0CAAA,CADA,4BACA,CALA,oEACC,6BAAA","sourcesContent":[".gwCancelPolicyTitleContainer {\n\tborder-bottom: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);\n\tmargin-bottom: var(--GW-LAYOUT-8);\n}\n.gwPolicyCancellationContainer {\n\tpadding-top: var(--GW-LAYOUT-8);\n}\n.cancellationMessage {\n\t[class*=FieldMessage] {\n\t\tdisplay: none;\n\t}\n}\n.gwEffectiveDateErrorMessage {\n    color: var(--GW-COLOR-ERROR);\n}\n.cancelationInlineNotification {\n\ti[class*=mi-info]{\n\t\tcolor: var(--GW-BRAND-COLOR-2);\n\t}\n\tbackground-color: var(--GW-HIGHLIGHT-COLOR-1);\n\tcolor: var(--GW-TEXT-COLOR-1);\n\tborder: solid 1px var(--GW-SEPARATOR-COLOR);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwCancelPolicyTitleContainer": "app__Cancellation__gwCancelPolicyTitleContainer",
	"gwPolicyCancellationContainer": "app__Cancellation__gwPolicyCancellationContainer",
	"cancellationMessage": "app__Cancellation__cancellationMessage",
	"gwEffectiveDateErrorMessage": "app__Cancellation__gwEffectiveDateErrorMessage",
	"cancelationInlineNotification": "app__Cancellation__cancelationInlineNotification"
};
export default ___CSS_LOADER_EXPORT___;
