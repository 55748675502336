import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FieldComponent, Icon } from '@jutro/components';
import classNames from 'classnames';
import styles from './AddressLookupTypeaheadComponent.module.scss';
import TypeaheadDropdownComponent from './AddressLookupTypeaheadDropdown';

import { InputField } from '@jutro/legacy/components';

/**
 * Searches the items for the query.
 * If *searchByProperty* is passed, only such property is searched in the items
 *
 * @param {Array} items items to search
 * @param {string} query the query entered by the user
 * @param {string} searchByProperty a property name available in the elements passed in *items*
 * @returns {Array} an array with items filtered by the user query
 *
 */
function searchItems(items, query, searchByProperty) {
    if (_.isNil(query)) {
        return [];
    }

    const filteredItems = items.filter((item) => {
        if (searchByProperty) {
            return _.get(item, searchByProperty, '').toLowerCase().includes(query.toLowerCase());
        }
        return (item || '').includes(query.toLowerCase());
    });

    return filteredItems;
}

class AddressLookupTypeaheadComponent extends FieldComponent {
    /**
     * @static
     * @memberof AddressLookupTypeaheadComponent
     * @mixes InputField.propTypes
     * @prop {Array} items the items to be searched by the user.
     *                      The array can contain strings or objects
     * @prop {function(userInput) => (Array|Promise<Array>)} onSearchItems
     *                      a function used to search the items based on user input
     *                      The function can return an array or a promise that
     *                      will resolve to an array
     * @prop {string} renderAs a path to a property in *item* that is used to
     *                                      render the result or a function that, given an item,
     *                                      returns a string
     * @prop {function(item): string} onRenderItem a function used to get the string to be used
     *                                      when rendering an item. If **renderAs** is provided
     *                                      this is **ignored**
     * @prop {string} searchBy **only** if *items* is provided as an Array
     *                          this represents the property used when searching for user input
     * @prop {number} throttleMs number of milliseconds to wait before
     *                           re-evaluating a user input change
     * @prop {number} maxResults  maximum number of results to show in the dropdown
     */
    static propTypes = {
        ...InputField.propTypes,
        items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
        onSearchItems: PropTypes.func,
        onClearTypeaheadInput: PropTypes.func,
        renderAs: PropTypes.string,
        onRendonClearTypeaheadInputerItem: PropTypes.func,
        searchBy: PropTypes.string,
        throttleMs: PropTypes.number,
        maxResults: PropTypes.number,
        id: PropTypes.string.isRequired,
        showClearIcon: PropTypes.bool,
        onCancelSelection: PropTypes.func
    };

    static defaultProps = {
        ...InputField.defaultProps,
        items: [],
        onSearchItems: undefined,
        onClearTypeaheadInput: undefined,
        renderAs: undefined,
        onRenderItem: undefined,
        throttleMs: 100,
        maxResults: undefined,
        showClearIcon: true,
        onCancelSelection: undefined
    };

    constructor(props, context) {
        super(props, context);
        const { onSearchItems, throttleMs } = props;

        // NEEDED THE LAST VALUE PASSED IN CASE THE INPUT VALUE CHANGED
        this.state = {
            rawInput: '',
            resolvedItems: [],
            initialRawInput: '',
            lastValue: ''
        };

        this.dropdownRef = null;

        if (_.isFunction(onSearchItems)) {
            this.searchItems = _.throttle(onSearchItems, throttleMs);
        }
    }

    handleCancelSelection = () => {
        this.setState({
            resolvedItems: []
        });
        const { rawInput } = this.state;
        const { onCancelSelection } = this.props;
        if (onCancelSelection) {
            onCancelSelection(rawInput);
        }
    };

    handleRawInputChange = (value) => {
        const { items, searchBy } = this.props;

        // reset potentially resolved items
        this.setState({
            rawInput: value,
            resolvedItems: []
        });

        // case items is array
        if (_.isArray(items) && !_.isEmpty(items)) {
            this.setState({
                resolvedItems: searchItems(items, value, searchBy)
            });
        }

        // case we need to search with a function
        if (_.isFunction(this.searchItems)) {
            /*
                wrap the function in a promise, so:
                - if the function returns a value, we'll be able to treat it as a promise
                - if the function returns a promise, we'll transparently use the resulting promise
            */
            Promise.resolve(this.searchItems(value)).then((resolvedItems) => {
                this.setState({
                    resolvedItems: resolvedItems
                });
            });
        }
    };

    handleUserSelects = (item) => {
        const {
            onValueChange, path, renderAs, onRenderItem
        } = this.props;

        let renderableItem;

        if (!_.isNil(renderAs)) {
            renderableItem = _.get(item, renderAs);
        } else if (!_.isNil(onRenderItem)) {
            renderableItem = onRenderItem(item);
        } else {
            renderableItem = item;
        }

        this.setState({
            rawInput: renderableItem,
            resolvedItems: []
        });
        if (onValueChange) {
            onValueChange(item, path);
        }
        const { onBlur } = this.props
        onBlur && onBlur();
    };

    handleOnFocus = () => {
        const { rawInput } = this.state;
        const { onFocus } = this.props
        onFocus && onFocus();
        this.handleRawInputChange(rawInput);
    };

    handleOnBlur = (event) => {
        if(event?.relatedTarget?.id !== 'addressTypeAhead_typeaheadDropdownList') {
            const { onBlur } = this.props
            onBlur && onBlur();
            this.handleCancelSelection();
        }
    }

    handleKeyDown = (event) => {
        switch (event.key) {
            case 'ArrowDown':
                if (this.dropdownRef) {
                    event.preventDefault();
                    this.dropdownRef.focus();
                }
                break;
            case 'Enter':
            case 'Tab':
            case 'Escape':
                this.handleCancelSelection();
                break;
            default: // do nothing
        }
    };

    setupDropdownRef = (domRef) => {
        this.dropdownRef = domRef;
    };

    // NEEDED THE RENDERED VALUE IN MULTIPLE PLACES
    getRenderedValue = () => {
        const { value, renderAs } = this.props;
        if (!_.isEmpty(renderAs) && _.isString(renderAs)) {
            return _.get(value, renderAs, '');
        }
        return value;
    }

    // NEEDED TO RESET THE INITIALRAWINPUT AND THE RAWINPUT
    // IN THE CASE WHERE THE VALUE CHANGED WITHOUT RE-RENDER
    maybeResetInitialRawValue = () => {
        const { value } = this.props;
        const { lastValue, initialRawInput } = this.state;
        if (!_.isEmpty(value)) {
            const renderedValue = this.getRenderedValue();
            if (lastValue !== renderedValue) {
                if (!_.isEmpty(initialRawInput)) {
                    this.setState({
                        lastValue: renderedValue,
                        rawInput: this.getRenderedValue(),
                        initialRawInput: this.getRenderedValue()
                    });
                    return true;
                }
                this.setState({ lastValue: renderedValue });
            }
        }
        return false;
    }

    // GETTING THE RAW INPUT HAD TO RESPECT IF THE INPUT VALUE HAD CHANGED
    // WITHOUT RE-RENDERING THE PAGE
    getRawInputValue = () => {
        const initialRawReset = this.maybeResetInitialRawValue();
        const { rawInput, initialRawInput } = this.state;
        let valueForRawInput;
        if (_.isEmpty(initialRawInput) || initialRawReset) {
            valueForRawInput = this.getRenderedValue();
        } else {
            valueForRawInput = rawInput;
        }
        if (!initialRawReset && rawInput) {
            this.setState({ initialRawInput: rawInput });
        }
        return valueForRawInput;
    };

    render() {
        return super.render();
    }

    setInputEvents = (element) => {
        if (element) {
            const input = element.querySelector('input');
            input.addEventListener('keydown', this.handleKeyDown);
        }
    };

    clearSelectionAndInput = () => {
        const { onClearTypeaheadInput, path } = this.props;

        this.setState({
            rawInput: '',
            initialRawInput: '',
            resolvedItems: []
        });
        if (onClearTypeaheadInput) {
            onClearTypeaheadInput(undefined, path);
        }
    };

    renderControl() {
        const { resolvedItems, rawInput } = this.state;
        const {
            renderAs, maxResults, onRenderItem, value, id, showClearIcon
        } = this.props;
        const inputProps = {
            ...this.props,
            hideLabel: true,
            onValueChange: this.handleRawInputChange,
            onKeyDown: this.handleKeyDown,
            onFocus: this.handleOnFocus,
            layout: 'full-width',
            value: this.getRawInputValue(),
            containerClass: styles.zeroPadding,
            onBlur: this.handleOnBlur,
            id: "addressInput"
        };

        const searchStyles = classNames(styles.icon);
        const clearStyles = classNames(styles.icon, styles.clearIcon);

        const icon = _.isEmpty(rawInput) && _.isEmpty(value) ? (
            <Icon className={searchStyles} />
        ) : (
            <Icon
                id={`${id}_clearTypeaheadSearch`}
                onClick={this.clearSelectionAndInput}
                className={clearStyles}
                icon="mi-close"
            />
        );

        return (
            <div ref={this.setInputEvents} className={styles.gwTypeahead}>
                <div className={styles.gwTypeaheadWrap}>
                    <InputField {...inputProps} />
                    {showClearIcon && icon}
                </div>
                <TypeaheadDropdownComponent
                    id={id}
                    setupRef={this.setupDropdownRef}
                    items={resolvedItems}
                    onItemSelected={this.handleUserSelects}
                    onCancelSelection={this.handleCancelSelection}
                    renderAs={renderAs}
                    maxResults={maxResults}
                    onRenderItem={onRenderItem}
                    onBlur={this.handleOnBlur}
                    focusTypeAhead={this.focusTypeAhead}
                />
            </div>
        );
    }
}

export default AddressLookupTypeaheadComponent;
