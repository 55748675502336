// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__HelpIcon__helpIcon{--button-position:var(--GW-SPACING-8);--button-size:56px;--button-bg-color:var(--GW-COLOR-INFO);--icon-position:var(--GW-SPACING-8);align-items:center;border-radius:50%;bottom:var(--button-position);display:flex;height:var(--button-size);justify-content:center;padding:0;position:fixed;right:var(--icon-position);width:var(--button-size)}.app__HelpIcon__helpIcon:hover{--button-hover-bg-color:var(--GW-COLOR-INFO)}.app__HelpIcon__helpIcon:active{--button-active-color:var(--GW-COLOR-INFO)}.app__HelpIcon__helpIcon:focus{--button-focus-border-color:var(--GW-COLOR-INFO)}.app__HelpIcon__helpIcon i{--gw-help-icon-size:1.5rem;font-size:var(--gw-help-icon-size)}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/HelpIcon/HelpIcon.module.scss"],"names":[],"mappings":"AAAA,yBACE,qCAAA,CACA,kBAAA,CACA,sCAAA,CACA,mCAAA,CAKA,kBAAA,CAHA,iBAAA,CACA,6BAAA,CACA,YAAA,CAMA,yBAAA,CAJA,sBAAA,CAEA,SAAA,CADA,cAAA,CAEA,0BAAA,CAEA,wBAAA,CAEA,+BACE,4CAAA,CAEF,gCACE,0CAAA,CAEF,+BACE,gDAAA,CAEF,2BACE,0BAAA,CACA,kCAAA","sourcesContent":[".helpIcon {\n  --button-position: var(--GW-SPACING-8);\n  --button-size: 56px;\n  --button-bg-color: var(--GW-COLOR-INFO);\n  --icon-position: var(--GW-SPACING-8);\n\n  border-radius: 50%;\n  bottom: var(--button-position);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  padding: 0;\n  right: var(--icon-position);\n  height: var(--button-size);\n  width: var(--button-size);\n\n  &:hover {\n    --button-hover-bg-color: var(--GW-COLOR-INFO);\n  }\n  &:active {\n    --button-active-color: var(--GW-COLOR-INFO);\n  }\n  &:focus {\n    --button-focus-border-color: var(--GW-COLOR-INFO);\n  }\n  i {\n    --gw-help-icon-size: 1.5rem;\n    font-size: var(--gw-help-icon-size);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpIcon": "app__HelpIcon__helpIcon"
};
export default ___CSS_LOADER_EXPORT___;
