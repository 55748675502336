import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICWizardChangeOrRenewalPage, useWizardModals, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { WMICUserAccessUtil, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import useGetSaveMethod from '../../hooks/useGetSaveMethod';

function WMICHOPolicyDetailsPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;

    const { setWizardLoading } = useWizardModals();
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const translator = useContext(TranslatorContext);
    const { authHeader, authUserData } = useAuthentication();
    const [showErrors, setShowErrors] = useState(false);
    const saveMethod = useGetSaveMethod(policyChangeVM);

    const {
        onValidate: setComponentValidation,
        initialValidation,
        isComponentValid
    } = useValidation('WMICHOPolicyDetailsPage');

    useDocumentTitle(translator(commonMessages.policyDetailsTitle), policyChangeVM);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }

        try {
            setWizardLoading(true);

            const newPolicyChange = _.cloneDeep(policyChangeVM.value);

            policyChangeVM.value = await saveMethod(
                [newPolicyChange],
                authHeader
            );
            updateWizardData(policyChangeVM);

            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, policyChangeVM, saveMethod, authHeader]);

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={policyChangeVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={isReadOnlyUser}
                authHeader={authHeader}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICHOPolicyDetailsPage.propTypes = wizardProps;

export default withViewModelService(WMICHOPolicyDetailsPage);
