import _ from "lodash";

const getPickedContact = async (authHeader, authUserData, accountContacts) => {
    let pickedContact;
    const contactWithEmailMatches = [];
    const contactWithNameMatches = [];

    // PC sends back rows of the same contact based on different roles of that contact. This variable indicated that we only would find the same contact in the response.
    let onlyOneContactFoundSameEmail = true;

    // filter out contacts which are not 'Primary Named Insured' or 'Named Insured'
    const namedInsureds = accountContacts.filter((contact) => contact.contactRole?.toLowerCase().match('named insured'))

    const uniqueNamedInsureds = [];

    // filter duplicate contacts
    namedInsureds.forEach((additionalContact) => {

        const result = uniqueNamedInsureds.find(contact => contact?.contact?.publicID === additionalContact.contact.publicID)

        if (!result) {
            uniqueNamedInsureds.push(additionalContact)
        }
    });

    // iterate through Named Insureds to find the correct Contact
    uniqueNamedInsureds.forEach((namedInsured) => {

        if (namedInsured.contact?.emailAddress1?.toLowerCase() === authUserData?.email?.toLowerCase()
            && namedInsured.contact?.publicID !== undefined) {

            if (contactWithEmailMatches?.length > 0
                && ((contactWithEmailMatches[contactWithEmailMatches.length - 1].contact?.emailAddress1?.toLowerCase() !== namedInsured.contact?.emailAddress1?.toLowerCase())
                    ||
                    (
                        contactWithEmailMatches[contactWithEmailMatches.length - 1].contact?.firstName?.toLowerCase() !== namedInsured.contact?.firstName?.toLowerCase()
                        ||
                        contactWithEmailMatches[contactWithEmailMatches.length - 1].contact?.lastName?.toLowerCase() !== namedInsured.contact?.lastName?.toLowerCase()
                    ))) {
                onlyOneContactFoundSameEmail = false;
            }

            contactWithEmailMatches.push(namedInsured);
        }
    })

    if (contactWithEmailMatches.length === 1 || contactWithEmailMatches.length > 1 && onlyOneContactFoundSameEmail) {
        pickedContact = _.find(contactWithEmailMatches, (contact) =>
            contact.contactRole?.toLowerCase().match('named insured'));
    } else {
        // Matching on first and last name
        uniqueNamedInsureds.forEach((contactDTO) => {
            if (contactDTO?.contact?.firstName?.toLowerCase() === authUserData?.firstName?.toLowerCase()
                && contactDTO?.contact?.lastName?.toLowerCase() === authUserData?.lastName?.toLowerCase()
                && contactDTO?.contact?.publicID !== undefined) {
                contactWithNameMatches.push(contactDTO);
            }
        });

        if (contactWithNameMatches.length > 0) {
            // Always pick the first contact since the array is sorted by the most recently updated record, which takes care of the multiple matches case.
            [ pickedContact ] = contactWithNameMatches;
        } else {
            // Last attempt to match on publicID, first and last name. This is essentially a fallback in the case of an ANI that changed his email address.
            const ampContact = _.find(contactWithEmailMatches, (contact) =>
                contact.contactRole?.toLowerCase().match('named insured'));

            if (ampContact?.contact?.firstName?.toLowerCase() === authUserData?.firstName?.toLowerCase()
                && ampContact?.contact?.lastName?.toLowerCase() === authUserData?.lastName?.toLowerCase()
                && ampContact?.contact?.publicID !== undefined) {
                pickedContact = ampContact;
            }
        }
    }

    if (pickedContact) {
        pickedContact = pickedContact.contact;
    }

    return pickedContact;
}

export default {
    getPickedContact
}