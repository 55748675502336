import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withRouter } from 'react-router-dom';
import styles from './WMICLoader.module.scss';

function WMICLoader({
    isInline,
    title,
}) {
    return (
        <div className={styles.wmicLoadContainer}>
            <div
                className={
                    cx(styles.wmicLoadingMessage, {
                        [styles.fullPage]: !isInline
                    })
                }
            >
                {title ? (
                    <>
                        <div className={styles.titleHidden}>
                            {title}
                        </div>
                        <div className={styles.wmicButtonLoader}>
                            <div className={styles.wmicProgressBounce}/>
                        </div>
                    </>
                ) : (
                    <div className={styles.wmicProgress}>
                        <div className={styles.wmicProgressBounce} />
                    </div>
                )
            }
            </div>
        </div>
    );
}

WMICLoader.propTypes = {
    isInline: PropTypes.bool
};

WMICLoader.defaultProps = {
    isInline: false
};

export default withRouter(WMICLoader);