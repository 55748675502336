import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { WMICJsonRPCService } from 'wmic-portals-transport-js';
import { DocumentUploadService } from '@xengage/gw-portals-document-js';

export default class PolicyService {
    static getAccountPolicySummaries_WMIC(authHeader = {}, authFirstName, authLastName) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicySummaries_WMIC', [authFirstName, authLastName], authHeader);
    }

    static getAccountPolicyDetails(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicyDetails', [policyNumber], authHeader);
    }

    // eslint-disable-next-line camelcase
    static getAccountPolicyDetails_WMIC(policyNumber, termNumber, authHeader = {}, authFirstName, authLastName) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicyDetails_WMIC', [policyNumber, termNumber, authFirstName, authLastName], authHeader);
    }

    // eslint-disable-next-line camelcase
    static getContactSummary_WMIC(authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'getContactSummary_WMIC', [], authHeader);
    }

    static removeDocument(publicID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('removePolicyDocument'), 'removeDocument', [publicID], authHeader);
    }

    static uploadDocument(document, documentMetadata, authHeader = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('uploadPolicyDocument'), document, documentMetadata, authHeader);
    }

    static getDocumentUploadToken(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('generateUploadToken'), 'generateUploadToken', params, additionalHeaders);
    }

    // eslint-disable-next-line camelcase
    static getAccountPolicyTransactions_WMIC(policyNumber, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicyTransactions_WMIC', [policyNumber], authHeader);
    }

    // eslint-disable-next-line camelcase
    static sendDocumentViaEmail_WMIC(request, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'sendDocumentViaEmail_WMIC', [request], authHeader);
    }

    // eslint-disable-next-line camelcase
    static updateDistributionTypeWithConsent_WMIC(request, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policyUS'), 'updateDistributionTypeWithConsent_WMIC', [request], authHeader);
    }

    // eslint-disable-next-line camelcase
    static getIssuedPolicyPeriodSummaries(policyNumber, termNumber = '', authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'getIssuedPolicyPeriodSummaries_WMIC', [policyNumber, termNumber], authHeader);
    }

    // eslint-disable-next-line camelcase
    static getPeriodicUpdates(request, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'getPeriodicUpdates', [request], authHeader);
    }

    // eslint-disable-next-line camelcase
    static postPeriodicUpdates(request, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'postPeriodicUpdates', [request], authHeader);
    }

    static isEdocsReconsentRequired(authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policy'), 'isEdocsReconsentRequired', [], authHeader);
    }
}
