import { defineMessages } from 'react-intl';

export default defineMessages({
    documentErrorTitle: {
        id: 'portal.views.document-download.documentErrorTitle',
        defaultMessage: "We're Sorry"
    },
    documentErrorMessage: {
        id: 'portal.views.document-download.documentErrorMessage',
        defaultMessage: 'Your document cannot be accessed at this time. &lt;br/&gt;&lt;br/&gt;Please try again later or call us at &lt;a wmic-track-button-click=\"Modal - Document Error - Support Phone Number\" href=\"tel:1-888-363-7984\"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href=\"mailto:service.us@wawanesa.com\"&gt;service.us@wawanesa.com&lt;/a&gt;.  Our customer service team is available Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    documentClose: {
        id: 'portal.views.document-download.documentClose',
        defaultMessage: 'Close'
    },
    ariaLabel: {
        id: 'portal.views.document-download.ariaLabel',
        defaultMessage: 'Open the {documentName} document for Policy #{policyNumber}'
    }
})