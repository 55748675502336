import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { LobIconUtil, LOBConstants } from 'wmic-portals-utils-js';
import { WMICChangeAddressViewComponent, WMICChangeAddressEditComponent } from 'gw-capability-policychange-common-react';

import metadata from './WMICChangeAddressComponent.metadata.json5';
import messages from './WMICChangeAddressComponent.messages';
import styles from './WMICChangeAddressComponent.module.scss';

function WMICChangeAddressComponent(props) {
    const { policyVM, match, loadingState, setLoadingState } = props;
    const translator = useTranslator();
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts')
        .filter((contact) => { return contact.contact.accountHolder === true; })[0];
    const [isEditMode, setEditMode] = useState(false);

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('addAddressHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])
    
    const getChangeAddressTitle = () => {
        const title = translator(messages.changeAddress);
        const policyType = _.get(match, 'params.policyType');
        const icon = policyType === 'home' ? LobIconUtil.getWmicIcon('Homeowners') : LobIconUtil.getWmicIcon(LOBConstants.PA);

        return (
            <Fragment>
                <i className={`fa ${icon} ${styles.titleIconBlue}`} />
                <h2 className={styles['wmic-policy-title']}>{title}</h2>
            </Fragment>
        );
    };

    const overrideProps = {
        editAddressTitle: {
            content: getChangeAddressTitle()
        },
        editAddressSection:{
            visible: !loadingState
        },
        WMICChangeAddressViewComponent: {
            visible: !isEditMode,
            policyVM: policyVM,
            match: match,
            setEditMode: setEditMode
        },

        wmicChangeAddressEditComponent: {
            visible: isEditMode,
            address: policyVM.currentPeriod.policyAddress,
            setEditMode: setEditMode,
            policyData: policyVM,
            accountHolder: accountHolder,
            match: match,
            handleChangeLoading: setLoadingState,
            isLoading: loadingState
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICChangeAddressViewComponent: WMICChangeAddressViewComponent,
            wmicChangeAddressEditComponent: WMICChangeAddressEditComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeAddressComponent.propTypes = {
    policyVM: PropTypes.shape({
        currentPeriod: PropTypes.shape({
            policyAddress: PropTypes.shape({})
        })
    }).isRequired,
    match: PropTypes.shape({}).isRequired
};

export default WMICChangeAddressComponent;
