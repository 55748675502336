import React, {
    useContext,
    useCallback
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import messages from './WMICErrorModal.messages';


import { ButtonLink } from '@jutro/legacy/router';


import { Button } from '@jutro/legacy/components';


function WMICErrorModal(props) {
    const {
        message,
        description,
        iconClass,
        onAfterClose,
        isOpen,
        onReject,
        showAdditionalErrorInfo
    } = props;
    const translator = useContext(TranslatorContext);

    const handleClose = useCallback(() => {
        onReject();
    }, [onReject]);

    const showTryAgainButton = () => {
        return !!(description.jobID && !description.isPrebind);
    };

    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose} onAfterClose={onAfterClose} className="gw-modal gw-fade">
            <ModalHeader status="error" title={translator(platformMessages.genericError)} />
            <ModalBody>
                <div className="gw-modal-body">
                    <span className="gw-iconWrapper">
                        <span className={`gw-iconSpan ${iconClass}`} />
                    </span>
                    <div className="gw-message">
                        {translator(message)}
                    </div>
                    {showAdditionalErrorInfo && <div className="ww-box-grey gw-vmargin15">
                        <p>{translator(messages.referenceError)}</p>
                        <hr />
                        <div className="monospace-font">
                            {description.isEntityValidation && (
                                <div className="gw-vmargin">
                                    <span>{translator(messages.underwriterShouldVerify)}</span>
                                </div>
                            )}
                            {description.tidyError && (
                                <div className="flex-no-wrap">
                                    <span className="contact-us-label">{translator(messages.message)}</span>
                                    <div>{description.tidyError}</div>
                                </div>
                            )}
                            {description.jobID && (
                                <div className="flex-no-wrap">
                                    <span className="contact-us-label">{translator(messages.quoteID)}</span>
                                    <div>{description.jobID}</div>
                                </div>
                            )}
                            {description.status && (
                                <div className="flex-no-wrap">
                                    <span className="contact-us-label">{translator(messages.status)}</span>
                                    <div>{description.status}</div>
                                </div>
                            )}
                            {description.method && (
                                <div className="flex-no-wrap">
                                    <span className="contact-us-label">{translator(messages.method)}</span>
                                    <div>{description.method}</div>
                                </div>
                            )}
                            {description.url && (
                                <div className="flex-no-wrap">
                                    <span className="contact-us-label">{translator(messages.url)}</span>
                                    <div>{description.url}</div>
                                </div>
                            )}
                        </div>
                    </div>}
                </div>
            </ModalBody>
            <ModalFooter
                className="gw-modal-footer"
            >
                <Button
                    className={`ww-btn ${description.isPrebind ? 'ww-btn-primary' : 'ww-btn-cancel'}`}
                    type="tertiary"
                    onClick={handleClose}
                >
                    {translator(platformMessages.close)}
                </Button>
                {showTryAgainButton() && (
                    <ButtonLink
                        className="ww-btn ww-btn-primary"
                        to={description.ref}
                        onClick={handleClose}
                    >
                        {translator(messages.tryAgain)}
                    </ButtonLink>
                )}
            </ModalFooter>
        </ModalNext>
    );
}

WMICErrorModal.propTypes = {
    message: PropTypes.shape({
        id: 'empty',
        defaultMessage: ''
    }),
    description: PropTypes.shape(
        {
            isEntityValidation: PropTypes.string,
            tidyError: PropTypes.string,
            jobID: PropTypes.string,
            status: PropTypes.string,
            method: PropTypes.string,
            url: PropTypes.string,
            ref: PropTypes.string,
            isPrebind: PropTypes.bool
        }
    ),
    iconClass: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onAfterClose: PropTypes.func,
    showAdditionalErrorInfo: PropTypes.bool
};

WMICErrorModal.defaultProps = {
    message: '',
    iconClass: 'gw-messageErrorIcon',
    description: {},
    onAfterClose: _.noop,
    isOpen: false,
    onReject: _.noop,
    showAdditionalErrorInfo: true
};

export default WMICErrorModal;
