import { defineMessages } from 'react-intl';

export default defineMessages({
    paPolicyNumber: {
        id: 'policy.view.policy-details.PaPolicyNumber',
        defaultMessage: ' Automobile Policy # {policyNumber}'
    },
    hoPolicyNumber: {
        id: 'policy.view.policy-details.HoPolicyNumber',
        defaultMessage: ' {policyType} Policy # {policyNumber}'
    },
    editCoverages: {
        id: 'policy.view.policy-details.EditCoverages',
        defaultMessage: 'Edit Coverages'
    },
    editPolicy: {
        id: 'policy.view.policy-details.Edit Policy',
        defaultMessage: 'Edit Policy'
    },
    editPolicyNumber: {
        id: 'policy.view.policy-details.Edit Policy Number',
        defaultMessage: 'Edit Policy # {policyNumber}'
    },
    editPolicyTrackButtonIdentifier: {
        id: 'policy.view.policy-details.tracking.Edit Policy',
        defaultMessage: 'Edit Policy'
    },
    submenuItem: {
        id: 'policy.view.policy-details.tracking.Sub-Menu item',
        defaultMessage: 'Sub-Menu item'
    },
    policyMenu: {
        id: 'policy.view.policy-details.PolicyMenu',
        defaultMessage: 'Policy Menu'
    },
    policyDetails: {
        id: 'policy.view.policy-details.PolicyDetails',
        defaultMessage: 'Policy Details'
    },
    billing: {
        id: 'policy.view.policy-details.Billing',
        defaultMessage: 'Billing'
    },
    drivers: {
        id: 'policy.view.policy-details.Drivers',
        defaultMessage: 'Drivers'
    },
    policyDocuments: {
        id: 'policy.view.policy-details.PolicyDocuments',
        defaultMessage: 'Policy Documents'
    },
    homeowners: {
        id: 'policy.view.policy-details.Homeowners',
        defaultMessage: 'Homeowners'
    },
    propertyDetailsAria: {
        id: 'policy.view.policy-details.PropertyDetailsAria',
        defaultMessage: 'button Details for property at the {address}'
    },
    renters: {
        id: 'policy.view.policy-details.Renters',
        defaultMessage: 'Renters'
    },
    condominium: {
        id: 'policy.view.policy-details.Condominium',
        defaultMessage: 'Condominium'
    },
    notice: {
        id: 'policy.common.views.account-policy-details.Notice',
        defaultMessage: 'NOTICE:'
    },
    viewRenewalTerm: {
        id: 'policy.common.views.account-policy-details.View Renewal Term',
        defaultMessage: 'You are viewing the upcoming renewal term of your policy.'
    },
    pendingPolicyChange: {
        id: 'policy.view.policy-details.You have a pending policy change that will occur on',
        defaultMessage: 'You have a pending policy change that will occur on {date}. Online policy changes are temporarily unavailable, until {nextDay}, when your pending change will be completed. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    pendingPolicyChangeTempUnavailable: {
        id: 'policy.view.policy-details.You have a pending policy change. Online policy changes are temporarily unavailable...',
        defaultMessage: 'You have a pending policy change. Online policy changes are temporarily unavailable, until your pending change is completed. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    policyAboutToRenew: {
        id: 'policy.view.policy-details.Your policy is about to renew',
        defaultMessage: 'Your policy is about to renew on {date} and online policy changes are temporarily unavailable until your policy renewal. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    policyAboutToRenewTempUnavailable: {
        id: 'policy.view.policy-details.Your policy is about to renew. Online policy changes are temporarily unavailable until your policy is renewed. Please call us if you’d like to make a policy change and our agents can assist you',
        defaultMessage: 'Your policy is about to renew. Online policy changes are temporarily unavailable until your policy is renewed. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    policyChangeCannotBeStarted: {
        id: 'policy.view.policy-details.Policy Change cannot be started',
        defaultMessage: 'Policy Change cannot be started.'
    },
    youCanReachUsAt: {
        id: 'policy.view.policy-details.You can reach us at',
        defaultMessage: 'You can reach us by phone &lt;a href="tel:1-800-640-2920"&gt;1-800-640-2920&lt;/a&gt; Monday to Friday 7:30 am to 7:30 pm PT and Saturday 8:00 am to 4:30 pm PT.'
    },
    delinquencyModalTitle: {
        id: 'policy.view.policy-details.Policy Changes Are Temporarily Unavailable',
        defaultMessage: 'Policy Changes Are Temporarily Unavailable'
    },
    delinquencyModalBody: {
        id: 'policy.view.policy-details.Payment is due on your policy in the amount of (amount). Please make your payment below...',
        defaultMessage: 'Payment is due on your policy in the amount of {amount}. Please make your payment below or give us a call. Online policy changes are temporarily unavailable until payment is made.'
    },
    close: {
        id: 'policy.view.policy-details.Close',
        defaultMessage: 'Close'
    },
    offerOfEarthquakeCoverage: {
        id: 'policy.common.directives.templates.policy-documents.Offer of Earthquake Coverage',
        defaultMessage: 'Offer of Earthquake Coverage'
    },
    policyDeclaration: {
        id: 'policy.common.directives.templates.policy-documents.Policy Declaration',
        defaultMessage: 'Policy Declaration'
    }
});
