/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PRODUCT, JURISDICTIONS } from 'wmic-pe-portals-utils-js';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { CurrencyField } from '@jutro/legacy/components';
import { WMICBrokerFeesComponent, WMICFlexProgramComponent } from 'wmic-pe-capability-gateway-common-react';
import { formatCurrency } from '@jutro/components';

import _ from 'lodash';

import metadata from './WMICPremiumCosts.metadata.json5';
import messages from './WMICPremiumCosts.messages';
import styles from './WMICPremiumCosts.module.scss';

const WMICPremiumCosts = ({premium, jobVM, policyChange, enableFlex= false, enableBrokerFees = false, updateJobVM, currentCostsTitle}) => {

    const [showItems, setShowItems] = useState(false);
    const translator = useContext(TranslatorContext);
    const intl = useContext(IntlContext);

    const isPolicyChange = !!policyChange;
    const LOB = _.get(jobVM, isPolicyChange? 'productCode' : 'baseData.productCode.value');
    const PACurrent_COVERAGES_PATH = isPolicyChange? 'policy.lobs.personalAuto' : 'lobData.personalAuto.offerings.children[0].coverages';
    const PANew_COVERAGES_PATH = 'lobData.personalAuto.offerings.children[0].coverages';
    const HOCurrent_COVERAGES_PATH = isPolicyChange? 'policy.lobs.homeOwners' : 'lobData.homeowners.offerings.children[0].coverages';
    const HONew_COVERAGES_PATH = 'lobData.homeowners.offerings.children[0].coverages';
    const CP_COVERAGES_PATH = 'lobData.commercialProperty.offerings.children[0].coverages';
    const GL_COVERAGES_PATH = 'lobData.generalLiability.offerings.children[0].coverages';
    const IM_COVERAGES_PATH = 'lobData.inlandMarine.offerings.children[0].coverages';
    const valuePath = isPolicyChange? '' : '.value';

    const toggleShowItems = () => {
        setShowItems(!showItems);
    }

    const showFlex = () => {
        if (!enableFlex) return false;

        switch(LOB){
            case PRODUCT.PERSONAL_AUTO:
                return _.get(isPolicyChange? policyChange: jobVM, 'lobData.personalAuto.coverables.vehicles.children[0].flexDTO.isFlexEligible.value') !== undefined;
            case PRODUCT.HOME_OWNER:
                return _.get(isPolicyChange? policyChange: jobVM, 'lobData.homeowners.coverables.dwellings.children[0].flexDTO.isFlexEligible.value') !== undefined;
            default:
                return false;
        }
    }

    const showBrokerFees = () => {
        if (!enableBrokerFees) return false;

        switch(LOB){
            case PRODUCT.PERSONAL_AUTO:
            case PRODUCT.HOME_OWNER:
                return showFlex();
            case PRODUCT.COMMERCIAL_PACKAGE:
                return _.get(jobVM, 'baseData.baseState.value.code') === JURISDICTIONS.QUEBEC;
            default:
                return false;
        }
    }

    const premiumDM = {
        current: {
            premium,
            lobData: {
                homeowners: {
                    allDwellingsCoveragesTotal: {amount: _.get(jobVM, `${HOCurrent_COVERAGES_PATH}.allDwellingsCoveragesTotal${valuePath}`, 0)},
                    lineCoveragesTotal: {amount: _.get(jobVM, `${HOCurrent_COVERAGES_PATH}.lineCoveragesTotal${valuePath}`, 0) +
                            _.get(jobVM, `lobData.homeowners.coverables.scheduledItemsTotal${valuePath}`, 0)},
                    allWatercraftsCoveragesTotal: {amount: _.get(jobVM, `${HOCurrent_COVERAGES_PATH}.allWatercraftsCoveragesTotal${valuePath}`, 0)}
                },
                personalAuto: {
                    allVehiclesCoveragesTotal: {amount: _.get(jobVM, `${PACurrent_COVERAGES_PATH}.allVehiclesCoveragesTotal${valuePath}`, 0)},
                    lineCoveragesTotal: {amount: _.get(jobVM, `${PACurrent_COVERAGES_PATH}.lineCoveragesTotal${valuePath}`, 0)},
                    driverCoveragesTotal: {amount: _.get(jobVM, `${PACurrent_COVERAGES_PATH}.driverCoveragesTotal${valuePath}`, 0)}
                },
                commercialProperty: {
                    propertyCoverageTotal: {amount: _.get(jobVM, `${CP_COVERAGES_PATH}.premiumAmount.amount${valuePath}`, 0)},
                    liabilityCoverageTotal: {amount: _.get(jobVM, `${GL_COVERAGES_PATH}.premiumAmount.amount${valuePath}`, 0)},
                    inlandMarineCoverageTotal: {amount: _.get(jobVM, `${IM_COVERAGES_PATH}.premiumAmount.amount${valuePath}`, 0)},
                }
            }
        },
        new: {
            premium: _.get(policyChange, 'quoteData.offeredQuotes.children[0].premium.value'),
            lobData: {
                homeowners: {
                    allDwellingsCoveragesTotal: {amount: _.get(policyChange, `${HONew_COVERAGES_PATH}.allDwellingsCoveragesTotal.value`, 0)},
                    lineCoveragesTotal: {amount: _.get(policyChange, `${HONew_COVERAGES_PATH}.lineCoveragesTotal.value`, 0) +
                            _.get(policyChange, 'lobData.homeowners.coverables.scheduledItemsTotal.value', 0)},
                    allWatercraftsCoveragesTotal: {amount: _.get(policyChange, `${HONew_COVERAGES_PATH}.allWatercraftsCoveragesTotal.value`, 0)}
                },
                personalAuto: {
                    allVehiclesCoveragesTotal: {amount: _.get(policyChange, `${PANew_COVERAGES_PATH}.allVehiclesCoveragesTotal.value`, 0)},
                    lineCoveragesTotal: {amount: _.get(policyChange, `${PANew_COVERAGES_PATH}.lineCoveragesTotal.value`, 0)},
                    driverCoveragesTotal: {amount: _.get(policyChange, `${PANew_COVERAGES_PATH}.driverCoveragesTotal.value`, 0)}
                },
                commercialProperty: {
                    propertyCoverageTotal: {amount: _.get(jobVM, `${CP_COVERAGES_PATH}.premiumAmount.amount${valuePath}`, 0)},
                    liabilityCoverageTotal: {amount: _.get(jobVM, `${GL_COVERAGES_PATH}.premiumAmount.amount${valuePath}`, 0)},
                    inlandMarineCoverageTotal: {amount: _.get(jobVM, `${IM_COVERAGES_PATH}.premiumAmount.amount${valuePath}`, 0)},
                }
            }
        }
    }

    const changeInCostAmount =
        formatCurrency(intl, {
            value: _.get(policyChange, 'value.policyPeriods[0].changesInCost.amount') || "0",
            showFractions: 2}
        )

    const overrideProps = {
        '@field': {
            readOnly: true,
            currencyDisplay: 'symbol',
            labelClassName: 'amountLabel',
            contentContainerClassName: 'amount',
            labelPosition: 'left',
            defaultValue: {
                amount: 0
            }
        },
        '@layout': {
            columns: isPolicyChange? [4,1] : [1]
        },
        expandIcon: {
            icon: showItems ? 'mi-expand-less' : 'mi-expand-more',
            visible: LOB === PRODUCT.PERSONAL_AUTO || LOB === PRODUCT.HOME_OWNER || LOB === PRODUCT.COMMERCIAL_PACKAGE
        },
        PACoveragesContainer: {
            visible: showItems && LOB === PRODUCT.PERSONAL_AUTO
        },
        HOCoveragesContainer: {
            visible: showItems && LOB === PRODUCT.HOME_OWNER
        },
        CPCoveragesContainer: {
            visible: showItems && LOB === PRODUCT.COMMERCIAL_PACKAGE
        },
        quoteValidDaysMessage: {
            visible: LOB === PRODUCT.COMMERCIAL_PACKAGE
        },
        premiumHeaderRowContainer: { visible: isPolicyChange },
        newPremiumAmount: { visible: isPolicyChange },
        newTaxesAmount: { visible: isPolicyChange },
        newFeesAmount: { visible: isPolicyChange },
        newTotalAmount: { visible: isPolicyChange },

        // PA Coverages
        newVehicleCoverageAmount: { visible: isPolicyChange },
        newDriverCoverageAmount: { visible: isPolicyChange },
        newPolicyCoveragePAAmount: { visible: isPolicyChange },
        // HO Coverages
        newLocationCoverageAmount: { visible: isPolicyChange },
        newPolicyCoverageHOAmount: { visible: isPolicyChange },
        newWatercraftCoverageAmount: { visible: isPolicyChange },

        changeInCostContainer: { visible: isPolicyChange },
        changeInCostNote: {
            content: translator(messages.changeInCostNote, {changeInCostAmount})
        },
        brokerFeeLink: {
            jobVM: isPolicyChange? policyChange: jobVM,
            visible: showBrokerFees(),
            updateJobVM,
        },
        flexProgramLink: {
            jobVM: isPolicyChange? policyChange: jobVM,
            visible: showFlex(),
            updateJobVM
        },
        quotePagePremiumCostsCardTitle: {
            title: currentCostsTitle ? translator(messages.currentCostsLabel) : translator(messages.premiumCostsLabel)
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            toggleShowItems
        },
        resolveComponentMap: {
            WMICBrokerFeesComponent,
            WMICFlexProgramComponent,
            CurrencyField
        },
        resolveClassNameMap: styles
    };


    return (
        <ViewModelForm
            model={premiumDM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICPremiumCosts.propTypes = {

}

WMICPremiumCosts.defaultProps = {

}

export default WMICPremiumCosts;
