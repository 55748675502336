/* eslint-disable max-len */
import React, {
    Fragment,
    useContext,
    useState,
    useEffect,
    useCallback,
} from 'react';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';

import PropTypes from 'prop-types';
import { useAuthentication } from 'wmic-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS, SUITES, WMICRichTextUtil, WMICFeatureFlagUtil, DateUtil } from 'wmic-portals-utils-js';
import { useModal } from '@jutro/components';
import { WMICDocumentDownloadButton } from 'gw-capability-document-react';
import { WMICButton } from 'wmic-components-platform-react';
import WMICModal from '../WMICModal/WMICModal';
import WMICTriggerInsuranceCardModal from '../WMICInsuranceCardModal/WMICTriggerInsuranceCardModal';
import WMICPolicyDetailsUtil from '../WMICPolicyDetailsUtil';

import metadata from './WMICPolicyInfoBox.metadata.json5';
import messages from './WMICPolicyInfoBox.messages';
import styles from './WMICPolicyInfoBox.module.scss';

const BUTTON_NAMES = {
    ROAD_ASSISTANCE: 'Roadside Assistance',
    PROOF_OF_INSURANCE: 'Proof Of Insurance'
};

const WMICPolicyInfoBox = (props) => {
    const modalApi = useModal();
    const {
        billingData: billingInfo,
        policyData: policyInfo,
        isRenewal,
        policyDocs
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const [documents, setDocuments] = useState({});
    const [periodData, setPeriodData] = useState({});
    const [policyNumber, setPolicyNumber] = useState('');
    const [termNumber, setTermNumber] = useState('');
    const [ldFlags, setLdFlags] = useState({});

    const currentDocuments = isRenewal ? policyInfo.renewedPeriod.documentDTOs : policyInfo.currentPeriod.documentDTOs;
    const proofOfInsurance = WMICPolicyDetailsUtil.getProofOfInsurance(currentDocuments);
    const policyDeclaration = WMICPolicyDetailsUtil.getPolicyDeclaration(currentDocuments);
    const roadsideAssistanceCard = WMICPolicyDetailsUtil.getRoadsideAssistanceCard(currentDocuments);
    const futureDatedPolicyChangeDeclaration = WMICPolicyDetailsUtil.getFutureDatedPolicyChangeDeclaration(
        WMICPolicyDetailsUtil.reverseChronologicalDocumentsByDateCompleted(policyDocs),
        periodData,
        translator(messages.policyDeclaration)
    );

    const [walletPassAvailable, setWalletPassAvailable] = useState(false);
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        initFeatureFlags();
    },[]);

    useEffect(() => {
        const walletPassAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.WALLETPASS);
        setWalletPassAvailable(walletPassAvailability && walletPassAvailability.isAvailable);
    }, [ldFlags, featureFlags]);

    const findElement = useCallback((collection, element) => {
        return _.filter(collection, (item) => {
            return item.patternCode_WMIC === element;
        });
    }, []);

    const isCoverageExist = useCallback((coverageCode) => {
        let coverage = false;
        if(periodData.coverables) {
            periodData.coverables.forEach((coverable) => {
                coverage = coverage || findElement(coverable.coverages, coverageCode).length > 0;
            });
        };
        return coverage;
    }, [periodData, findElement]);

    const isButtonVisible = useCallback((lobStatus, buttonName) => {
        let coverage = true;
        if (buttonName === BUTTON_NAMES.ROAD_ASSISTANCE) {
            coverage = isCoverageExist(CONSTANTS.COVERAGE_NAMES.PA_ROADASSIST);
        }
        return lobStatus ? lobStatus.toLowerCase() !== CONSTANTS.EXPIRED && lobStatus.toLowerCase() !== CONSTANTS.CANCELED && coverage : false;
    }, [isCoverageExist]);

    const getPrimaryLob = (period) => {
        const { lobs } = period;
        return _.get(period, 'lobs.personalAuto.coverageDTOs') ? lobs.personalAuto : lobs.homeOwners;
    };

    const getPolicyStatusTitle = (period) => {
        const primaryLob = getPrimaryLob(period);
        const lobStatus = primaryLob.status.toLowerCase();

        switch (lobStatus) {
            case CONSTANTS.IN_FORCE:
                return translator(messages.active);
            case CONSTANTS.SCHEDULED:
                if (isRenewal) {
                    return translator(messages.renewing);
                }
                return translator(messages.new);
            case CONSTANTS.CANCELED:
                return translator(messages.inactive);
            case CONSTANTS.EXPIRED:
                return translator(messages.inactive);
            default:
                return '';
        }
    };

    const isPA = (policy) => !!policy.currentPeriod.lobs.personalAuto?.coverageDTOs;

    const showRoadsideAssistanceCardButton = (policy) => {
        return isPA(policy) && documents?.roadsideAssistanceCard?.visible;
    };

    const showButtonInsuranceIdCardButton = (policy) => {
        // TODO-eagnilleri to be implemented when the document card will be ready (AMPDM-621)
        const primaryLob = getPrimaryLob(policy.currentPeriod);
        const lobStatus = primaryLob.status.toLowerCase();
        return isPA(policy) && (lobStatus === CONSTANTS.IN_FORCE || lobStatus === CONSTANTS.SCHEDULED);
    };

    /**
    * Returns a string with the current policy term,
    * or with the renewed term if the policy is a renewal.
    * @param {Object} policy object
    * @param {Boolean} renewal boolean
    * @returns {String} a string indicating the period term
    */
    const getPolicyTerm = (policy, renewal) => {
        const period = renewal ? policy.renewedPeriod : policy.currentPeriod;
        return `${intl.formatDate(new Date(period.effective), { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'US/Central' })} - ${intl.formatDate(new Date(period.expiration), { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'US/Central' })}`;
    };

    const getReadableDate = (theDate) => {
        let formattedDate = '';
        if (theDate) {
            formattedDate = intl.formatDate(new Date(theDate), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'US/Central' });
        }
        return formattedDate;
    };

    const onTriggerInsuranceCardModal = (aPolicyInfo) => {
        const primaryLob = getPrimaryLob(periodData);
        if (proofOfInsurance && isPA(aPolicyInfo) && primaryLob.vehicleDTOs && primaryLob.vehicleDTOs.length > 0) {
            WMICTriggerInsuranceCardModal({
                policyNumber,
                termNumber,
                documents,
                vehicles: primaryLob.vehicleDTOs,
                walletPassAvailable,
                authHeader
            });
        } else {
            const body = WMICRichTextUtil.translateRichText(translator(messages.documentErrorMessage));
            const title = translator(messages.wereSorry);
            modalApi.showModal(
                <WMICModal
                    id="insuranceIDCardModal"
                    modalHeader={title}
                    modalBody={body}
                    onConfirmCallback={() => _.noop()}
                    confirmButtonText={translator(messages.closeButtonText)}
                />
            );
        }
    };

    const renderPolicyContent = useCallback((message, value) => {
        return (
            <Fragment>
                <strong>{translator(message)}</strong>
                <br />
                {value}
            </Fragment>
        );
    }, [translator]);

    const renderAccountNumberSrContent = useCallback((account, accountNumber, policy, policyNumber) => {
        return (
            <Fragment>
                {translator(account) + accountNumber +" for " + translator(policy) + policyNumber}
            </Fragment>
        );
    }, [translator]);

    const renderPolicyNumberSrContent = useCallback((policy, policyNumber) => {
        return (
            <Fragment>
                {translator(policy) + policyNumber}
            </Fragment>
        );
    }, [translator]);

    const renderPolicyStatusSrContent = useCallback((policyStatus, policy, policyStatusTitle, policyNumber) => {
        return (
            <Fragment>
                {translator(policyStatus) + " is " + policyStatusTitle + " for " + translator(policy) + policyNumber}
            </Fragment>
        );
    }, [translator]);

    const renderTermColumnSrContent = useCallback((policy, policyNumber, effectiveDate, expirationDate) => {
        const formattedEffectiveDate = getReadableDate(effectiveDate);
        const formattedExpirationDate = getReadableDate(expirationDate);

        return (
            <Fragment>
                {`The term for ${translator(policy)} ${policyNumber} is from ${formattedEffectiveDate} to ${formattedExpirationDate}`}
            </Fragment>
        );
    }, [translator, getReadableDate]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICDocumentDownloadButton,
            WMICButton
        }

    };

    const overrides = {
        accountNumberDiv: {
            content: renderPolicyContent(
                messages.accountNumber, billingInfo.accountSummary.accountNumber
            )
        },
        policyNumberDiv: {
            content: renderPolicyContent(
                messages.policyNumber, policyInfo.currentPeriod.policyNumber
            )
        },
        policyStatusDiv: {
            content: renderPolicyContent(
                messages.policyStatus, getPolicyStatusTitle(
                    isRenewal ? policyInfo.renewedPeriod : policyInfo.currentPeriod
                )
            )
        },
        termDiv: {
            content: renderPolicyContent(
                messages.term, getPolicyTerm(policyInfo, isRenewal)
            )
        },
        buttonInsuranceIdCard: {
            visible: showButtonInsuranceIdCardButton(policyInfo),
            onClick: () => {
                return onTriggerInsuranceCardModal(policyInfo);
            },
            trackButtonIdentifier: translator(messages.insuranceIdCardButtonTrackButtonIdentifier),
            'aria-label': translator(messages.insuranceIdCardAriaLabel, {
                policyNumber: policyInfo?.currentPeriod.policyNumber
            })
        },
        buttonRoadsideAssistanceCard: {
            visible: showRoadsideAssistanceCardButton(policyInfo),
            targetDocument: documents.roadsideAssistanceCard,
            trackButtonIdentifier: translator(messages.roadsideAssistanceCardTrackButtonIdentifier)
        },
        futurePolicyDocContainer: {
            visible: _.get(documents, 'futureDatedPolicyChangeDeclarationDocument.visible', false),
        },
        futurePolicyDocButton: {
            targetDocument: documents.futureDatedPolicyChangeDeclarationDocument,
            visible: !_.isEmpty(_.get(documents, 'futureDatedPolicyChangeDeclarationDocument')),
            trackButtonIdentifier: translator(messages.futurePolicyDeclarationButtonTrackButtonIdentifier)
        },
        futurePolicyDocTextContainer: {
            content: translator(messages.futureDatedChangeMessage,
                { date: intl.formatDate(documents?.futureDatedPolicyChangeDeclarationDocument?.friendlyDateCompleted, { year: 'numeric', month: 'short', day: '2-digit' }) })
        },
        buttonPolicyDeclaration: {
            visible: !_.isEmpty(_.get(documents, 'policyDeclarationDocument')),
            targetDocument: documents.policyDeclarationDocument,
            trackButtonIdentifier: translator(messages.policyDeclarationButtonTrackButtonIdentifier),
            policyNumber: policyInfo.currentPeriod.policyNumber
        },
        accountNumberSrOnly: {
            content: renderAccountNumberSrContent(
                messages.accountNumber, billingInfo.accountSummary.accountNumber, messages.policyNumber, policyInfo.currentPeriod.policyNumber
            )
        },
        policyNumberSrOnly: {
            content: renderPolicyNumberSrContent(
                messages.policyNumber, policyInfo.currentPeriod.policyNumber
            )
        },
        policyStatusSrOnly: {
            content: renderPolicyStatusSrContent(
                messages.policyStatus, 
                messages.policyNumber, 
                getPolicyStatusTitle(
                    isRenewal ? policyInfo.renewedPeriod : policyInfo.currentPeriod
                ), 
                policyInfo.currentPeriod.policyNumber
            )
        },
        termColumnSrOnly: {
            content: renderTermColumnSrContent(
                messages.policyNumber, 
                policyInfo.currentPeriod.policyNumber, 
                isRenewal ? policyInfo.renewedPeriod.effective : policyInfo.currentPeriod.effective,
                isRenewal ? policyInfo.renewedPeriod.expiration : policyInfo.currentPeriod.expiration
            )
        }
    };

    useEffect(() => {
        const docs = {};
        let aPeriodData = {};
        if (isRenewal) {
            aPeriodData = policyInfo.renewedPeriod;
        } else {
            aPeriodData = policyInfo.currentPeriod;
        }
        setPeriodData(aPeriodData);
        const primaryLob = getPrimaryLob(aPeriodData);
        docs.proofOfInsuranceDocument = {
            id: proofOfInsurance ? proofOfInsurance.workingPublicID : undefined,
            friendlyName: translator(messages.poiFriendlyName),
            sessionID: proofOfInsurance ? proofOfInsurance.sessionID : undefined,
            fileName: proofOfInsurance ? proofOfInsurance.name : '',
            visible: isButtonVisible(primaryLob.status, BUTTON_NAMES.PROOF_OF_INSURANCE),
            type: SUITES.PC
        };
        docs.policyDeclarationDocument = {
            id: policyDeclaration ? policyDeclaration.workingPublicID : undefined,
            friendlyName: translator(messages.policyDeclaration),
            sessionID: policyDeclaration ? policyDeclaration.sessionID : undefined,
            fileName: policyDeclaration ? policyDeclaration.name : '',
            type: SUITES.PC
        };
        docs.futureDatedPolicyChangeDeclarationDocument = {
            id: futureDatedPolicyChangeDeclaration ? futureDatedPolicyChangeDeclaration.id : undefined,
            friendlyName: translator(messages.viewDocument),
            sessionID: futureDatedPolicyChangeDeclaration ? futureDatedPolicyChangeDeclaration.sessionID : undefined,
            fileName: futureDatedPolicyChangeDeclaration ? futureDatedPolicyChangeDeclaration.name : '',
            visible: futureDatedPolicyChangeDeclaration ? true : undefined,
            dateCompleted: futureDatedPolicyChangeDeclaration ? futureDatedPolicyChangeDeclaration.dateCompleted : undefined,
            localDateCompleted: futureDatedPolicyChangeDeclaration ? futureDatedPolicyChangeDeclaration.localDateCompleted : undefined,
            friendlyDateCompleted: futureDatedPolicyChangeDeclaration && futureDatedPolicyChangeDeclaration.localDateCompleted ? DateUtil.localDateDTOToDate(futureDatedPolicyChangeDeclaration.localDateCompleted) : undefined,
            type: SUITES.PC
        };
        docs.roadsideAssistanceCard = {
            id: roadsideAssistanceCard ? roadsideAssistanceCard.workingPublicID : undefined,
            friendlyName: translator(messages.roadsideAssistanceCard),
            sessionID: roadsideAssistanceCard ? roadsideAssistanceCard.sessionID : undefined,
            fileName: roadsideAssistanceCard ? roadsideAssistanceCard.name : '',
            visible: isButtonVisible(primaryLob.status, BUTTON_NAMES.ROAD_ASSISTANCE),
            type: SUITES.PC
        };

        const isDocumentChanged = !(_.isEqual(docs, documents));
        if (isDocumentChanged) {
            setDocuments(docs);
        }

        setPolicyNumber(periodData.policyNumber);
        setTermNumber(periodData.termNumber_WMIC);
    }, [policyInfo, translator, isButtonVisible, isRenewal, periodData, proofOfInsurance, policyDeclaration, futureDatedPolicyChangeDeclaration, roadsideAssistanceCard, documents, props]);

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={billingInfo}
        />
    );
};

WMICPolicyInfoBox.propTypes = {
    billingData: PropTypes.shape({}),
    policyData: PropTypes.shape({}),
    isRenewal: PropTypes.bool.isRequired
};

WMICPolicyInfoBox.defaultProps = {
    billingData: {},
    policyData: {}
};

export default WMICPolicyInfoBox;
