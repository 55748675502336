import React, { useContext, useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import { WMICDropdownMenuButton } from 'wmic-pe-components-platform-react';
import { CONSTANTS, WMICLogger, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';

import WMICCPAdditionalInterestDetailView from "../WMICCPAdditionalInterestDetailView/WMICCPAdditionalInterestDetailView"
import metadata from './WMICBuildingsDetailView.metadata.json5';
import messages from './WMICBuildingsDetailView.messages';

const LOCATION_PATH = 'lobData.commercialProperty.coverables.locations';

function WMICBuildingsDetailView(props) {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [subtypeAI, setSubtypeAI] = useState()
    const [dropdown, setDropdown] = useState()
    const [showErrorsAddlInt, setShowErrorsAddlInt] = useState(false);
    const [addressReadOnly, setAddressReadOnly] = useState(true)
    const { authHeader, authUserData } = useAuthentication();
    const { setWizardLoading, showConfirm } = useWizardModals();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { value: buildingVM, isEditing, updateModel, showErrors, jobVM, updateWizardData, showErrorsBldg, isBoundPolicy } = props;

    const { isComponentValid, onValidate } = useValidation('WMICBuildingsDetailView');

    const yearValueChanged = (value, path) => {
        const yearBuiltValue = _.get(buildingVM, 'yearBuilt.value')

        if ((_.get(buildingVM, 'publicID.value')) == null && (`${yearBuiltValue}`).length < 4) {
            const primiaryRoofCoveringValue = _.get(buildingVM, 'primaryRoofCoveringYear.value')
            if ((primiaryRoofCoveringValue == null || (`${primiaryRoofCoveringValue}`).length < 4)) {
                _.set(buildingVM, 'primaryRoofCoveringYear.value', value)
            }

            const primaryElectricalYearValue = _.get(buildingVM, 'primaryElectricalYear.value')
            if (primaryElectricalYearValue == null || (`${primaryElectricalYearValue}`).length < 4) {
                _.set(buildingVM, 'primaryElectricalYear.value', value)
            }

            const primaryHeatingYearValue = _.get(buildingVM, 'primaryHeatingYear.value')
            if (primaryHeatingYearValue == null || (`${primaryHeatingYearValue}`).length < 4) {
                _.set(buildingVM, 'primaryHeatingYear.value', value)
            }

            const primaryPlumbingYearValue = _.get(buildingVM, 'primaryPlumbingYear.value')
            if (primaryPlumbingYearValue == null || (`${primaryPlumbingYearValue}`).length < 4) {
                _.set(buildingVM, 'primaryPlumbingYear.value', value)
            }
        }

        _.set(buildingVM, path, value)
        updateWizardData(jobVM)
    }

    // TODO - move this logic to BE
    const heatTypeValueChanged = (value, path) => {
        const primaryHeatDistributionAvailableValues = buildingVM.primaryHeatDistribution.aspects.availableValues
        if (value === 'NoHeat') {
            const heatDistribution = _.find(primaryHeatDistributionAvailableValues, (heatDist) => {
                return heatDist.code === 'NoHeatingDistribution';
            });
            _.set(buildingVM, 'primaryHeatDistribution.value', heatDistribution)
        }
        _.set(buildingVM, path, value)
        updateWizardData(jobVM)
    }

    const renderAddAIButton = isBoundPolicy ? _.noop : useCallback(({ isEditing, onClick }) => {
        const anis = _.clone(jobVM.baseData.additionalNamedInsureds_WMIC.value)
        const tradeNames = _.clone(jobVM.lobData.commercialProperty.tradeNames.value)
        const pni = _.clone(jobVM.baseData.primaryNamedInsured_WMIC.value)
        const namedInsureds = [...anis, ...tradeNames, pni]
        const currentLocs = _.clone(jobVM.lobData.commercialProperty.coverables.locations)
        let addlInterests = []
        const currentBuildings = currentLocs.forEach(loc => {
            if (loc.buildings) {
                const buildings = loc.buildings
                buildings.forEach(building => {
                    if (building.value.additionalInterests) {
                        addlInterests = building.value.additionalInterests.map(addlInt => addlInt.existingContactPublicID).filter(addlInterest => addlInterest != undefined)
                    }
                })
            }
        })
        if (addlInterests) {
            addlInterests.forEach(elt => {
                const currentInd = namedInsureds.findIndex(inn => inn.contactPublicID != null ? inn.contactPublicID == elt : inn.publicID == elt)
                if (currentInd >= 0) {
                    namedInsureds.splice(currentInd, 1)
                }
            })
        }

        const additionalInterestDropdownOptions = [
            { id: 'newPerson', text: translator(messages.aiPerson), code: _.lowerCase(CONSTANTS.Person), icon: 'mi-person' },
            { id: 'newCompany', text: translator(messages.aiCompany), code: _.lowerCase(CONSTANTS.Company), icon: 'mi-home' },
            ...namedInsureds.map((option) => ({ id: option.publicID, text: option.contactName ?? (option.companyName ?? `${option.firstName} ${option.lastName}`), code: option.contactPublicID ?? option.publicID }))
        ];
        return (<Flex className="gw-mb-6" justifyContent="left">
            <WMICDropdownMenuButton
                id="addNewAdditionalInterestButton"
                type="secondary"
                size="medium"
                buttonText={translator(messages.additionalInterestButton)}
                alignRight
                onItemClick={onClick}
                items={
                    additionalInterestDropdownOptions
                }
                disabled={isEditing}
            />
        </Flex>)
    }, [jobVM.baseData.additionalNamedInsureds_WMIC.value, jobVM.baseData.primaryNamedInsured_WMIC.value, jobVM.lobData.commercialProperty.coverables.locations, jobVM.lobData.commercialProperty.tradeNames.value]);

    const getName = useCallback((additionalInterest) => {
        if (additionalInterest.contactType.value.code == _.lowerCase(CONSTANTS.Person)) {
            const firstName = additionalInterest.firstName.value
            const lastName = additionalInterest.lastName.value
            return `${firstName} ${lastName}`
        }
        if (additionalInterest.contactType.value.code == _.lowerCase(CONSTANTS.Company)) {
            const company = additionalInterest.companyName.value
            return `${company}`
        }
    }, []);

    const saveSubmission = useCallback(async (newSubmission, onError = _.noop) => {
        setWizardLoading(true);
        return LoadSaveService.saveWithNoValidationRuleCheck(
            newSubmission,
            authHeader
        ).then((result) => {
            _.extend(newSubmission, result);
            jobVM.value = newSubmission;
            updateWizardData(jobVM);
            return true;
        }).catch((error) => {
            WMICLogger.error('Save additional interest failed', error);
            onError();
            return false;
        }).finally(() => {
            setWizardLoading(false);
        });
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, jobVM, setWizardLoading]);

    const onSaveClickedAddtionalInterest = useCallback(async (additionalInterestVM, index) => {
        if (additionalInterestVM.aspects.valid && additionalInterestVM.aspects.subtreeValid) {
            if (_.get(buildingVM, 'publicID.value') != null) {
                const currentLocations = _.get(jobVM, `${LOCATION_PATH}.value`)
                const locationIndex = currentLocations.findIndex(loc => loc.publicID == buildingVM.locationId.value)
                const buildingIndex = currentLocations[locationIndex].buildings.findIndex(building => building.publicID == buildingVM.publicID.value)
                if (additionalInterestVM.publicID.value != null) {
                    const additionalInterests = currentLocations[locationIndex].buildings[buildingIndex].additionalInterests
                    const additionalInterestIndex = additionalInterests.findIndex(addlInt => addlInt.publicID == additionalInterestVM.publicID.value)
                    additionalInterests[additionalInterestIndex] = additionalInterestVM.value
                    _.set(jobVM, `additionalInterests`, additionalInterests);
                }
            }
            return saveSubmission(jobVM.value).then((result) => {
                if (_.get(buildingVM, 'publicID.value') != null) {
                    const currentLocations = _.get(jobVM, `${LOCATION_PATH}.value`)
                    const locationIndex = currentLocations.findIndex(loc => loc.publicID == buildingVM.locationId.value)
                    const buildingIndex = currentLocations[locationIndex].buildings.findIndex(building => building.publicID == buildingVM.publicID.value)
                    _.set(buildingVM, `additionalInterests.value`, _.get(jobVM, `${LOCATION_PATH}.value[${locationIndex}].buildings[${buildingIndex}].additionalInterests`));
                }
                setShowErrorsAddlInt(false)
                return result
            })
        } else {
            setShowErrorsAddlInt(true)
        }
    }, [buildingVM, jobVM, saveSubmission]);

    const onDeleteAdditionalInterest = useCallback(async (additionalInterestVM) => {
        const name = getName(additionalInterestVM)
        const response = await showConfirm({
            title: translator(messages.removeAdditionalInterestShort),
            message: translator(messages.removeAdditionalInterestLong, { displayName: name })
        })
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const newSubmission = _.cloneDeep(jobVM.value);
            const currentLocations = _.get(newSubmission, `${LOCATION_PATH}`)
            const location = currentLocations.find(loc => loc.publicID == buildingVM.locationId.value)
            const locationIndex = currentLocations.findIndex(loc => loc.publicID == buildingVM.locationId.value)
            const buildingIndex = location.buildings.findIndex(loc => loc.publicID == buildingVM.publicID.value)
            const additionalInterests = location.buildings[buildingIndex].additionalInterests
            const additionalInterestIndex = additionalInterests.findIndex(addlInt => addlInt.publicID == additionalInterestVM.publicID.value)
            additionalInterests.splice(additionalInterestIndex, 1)
            _.set(newSubmission, `${LOCATION_PATH}[${locationIndex}].buildings[${buildingIndex}.additionalInterests]`, additionalInterests);
            _.set(buildingVM, 'additionalInterests.value', additionalInterests)
            return saveSubmission(newSubmission)
        }
    }, [buildingVM, jobVM.value, saveSubmission, showConfirm, translator]);

    const toCreateClicked = useCallback((index) => {
        let newVM
        if (index == _.lowerCase(CONSTANTS.Person) || index == _.lowerCase(CONSTANTS.Company)) {
            const additionalInterestaddress = {
                country: CONSTANTS.COUNTRY.CA,
                city: '',
                state: '',
                postalCode: '',
            };
            newVM = viewModelService.create(
                {
                    'contactType': index,
                    'locationAddress': additionalInterestaddress,
                    'isEditable': true,
                    'subType': CONSTANTS.POLICY_ADDL_INTEREST,
                    'detailType': CONSTANTS.CP_BUILDING_ADDL_INTEREST
                },
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.CPAdditionalInterest_WMICDTO',
                jobVM.aspects.context()
            );
            setAddressReadOnly(false)
        } else {
            const namedInsuredList = [...jobVM.baseData.additionalNamedInsureds_WMIC.value, ...jobVM.lobData.commercialProperty.tradeNames.value, jobVM.baseData.primaryNamedInsured_WMIC.value];
            const contact = namedInsuredList.find(insured => insured.contactPublicID != null ? insured.contactPublicID == index : insured.publicID == index)
            const contactAddress = contact.locationAddress == null ? contact.primaryAddress : contact.locationAddress
            const contactSubtype = contact.subtype
            const contactName = contact.companyName == null ? contact.contactName : contact.companyName
            newVM = viewModelService.create(
                {
                    'contactType': _.lowerCase(contactSubtype),
                    'locationAddress': contactAddress,
                    'companyName': contactName,
                    'firstName': contact.firstName,
                    'lastName': contact.lastName,
                    'existingContactPublicID': contact.contactPublicID != null ? contact.contactPublicID : contact.publicID,
                    'isEditable': false,
                    'subType': CONSTANTS.POLICY_ADDL_INTEREST,
                    'detailType': CONSTANTS.CP_BUILDING_ADDL_INTEREST
                },
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.CPAdditionalInterest_WMICDTO',
                jobVM.aspects.context()
            );
            setAddressReadOnly(true)
        }
        if (_.get(buildingVM, 'publicID.value') != null) {
            const locationID = _.get(buildingVM, 'locationId.value')
            const locationIndex = (_.get(jobVM, 'lobData.commercialProperty.coverables.locations.value')).findIndex(loc => loc.publicID == locationID)
            const buidingID = _.get(buildingVM, 'publicID.value')
            const buildingIndex = (_.get(jobVM, `lobData.commercialProperty.coverables.locations.value[${locationIndex}].buildings`)).findIndex(building => building.publicID == buidingID)
            jobVM.lobData.commercialProperty.coverables.locations.value[locationIndex].buildings[buildingIndex].additionalInterests.push(newVM.value)
            _.set(buildingVM, `additionalInterests.value`, _.get(jobVM, `lobData.commercialProperty.coverables.locations.value[${locationIndex}].buildings[${buildingIndex}].additionalInterests`));
        } else {
            buildingVM.additionalInterests.value.push(newVM.value)
        }


        return newVM
    }, [jobVM, buildingVM, viewModelService]);

    const overrideProps = {
        '@field': {
            parentNode: buildingVM,
            readOnly: !isEditing,
            showErrors: showErrorsBldg
        },
        buildingContainer: {
            onValidate,
        },
        yearBuilt: {
            onValueChange: yearValueChanged
        },
        heatingType: {
            onValueChange: heatTypeValueChanged
        },
        additionalInterestListView: {
            clickable: true,
            startOpen: false,
            readOnly: !isEditing,
            editEnabled: true,
            value: _.get(buildingVM, `additionalInterests.children`, []),
            VMData: [
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.interestType),
                    path: 'interestType'
                },
            ],
            detailViewComponent: WMICCPAdditionalInterestDetailView,
            detailViewComponentProps: {
                jobVM: jobVM,
                updateWizardData,
                showErrorsAddlInt,
                addressReadOnly: addressReadOnly,
                isBoundPolicy
            },
            onValidate,
            renderAddButton: renderAddAIButton,
            toCreate: toCreateClicked,
            toUndoCreate: () => {
                let additionalInterests = []
                if (_.get(buildingVM, 'publicID.value') != null) {
                    const locationID = _.get(buildingVM, 'locationId.value')
                    const locationIndex = (_.get(jobVM, 'lobData.commercialProperty.coverables.locations.value')).findIndex(loc => loc.publicID == locationID)
                    const buidingID = _.get(buildingVM, 'publicID.value')
                    const buildingIndex = (_.get(jobVM, `lobData.commercialProperty.coverables.locations.value[${locationIndex}].buildings`)).findIndex(building => building.publicID == buidingID)
                    additionalInterests = _.get(jobVM, `lobData.commercialProperty.coverables.locations.value[${locationIndex}].buildings[${buildingIndex}].additionalInterests`)
                    additionalInterests.splice(additionalInterests.length - 1, 1);
                    _.set(jobVM.value, `lobData.commercialProperty.coverables.locations.value[${locationIndex}].buildings[${buildingIndex}].additionalInterests`, additionalInterests)
                } else {
                    additionalInterests = buildingVM.additionalInterests.value
                    additionalInterests.splice(additionalInterests.length - 1, 1);
                    _.set(buildingVM.value, `additionalInterests`, additionalInterests)
                }
                setAddressReadOnly(true)
                updateWizardData(jobVM);
                setShowErrorsAddlInt(false)
            },
            onDelete: onDeleteAdditionalInterest,
            onSave: onSaveClickedAddtionalInterest,
            showErrorsAddlInt
        },
        buildingTitle: {
            title: _.get(buildingVM, 'buildingNumber.value') != null ? `${translator(messages.buildingNumber)}${buildingVM.buildingNumber.value}` : translator(messages.buildingTitle)
        },
        additionalInterests: {
            visible: _.get(buildingVM, 'additionalInterests.length') > 0 || isEditing
        }
    }

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={buildingVM}
        onModelChange={updateModel}
        onValidationChange={onValidate}
        showErrors={showErrors}
        overrideProps={overrideProps}
    />
}

export default WMICBuildingsDetailView;
