import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'wmic.component.policy-info-box-component.PolicyDetails',
        defaultMessage: 'Policy Details'
    },
    accountNumber: {
        id: 'wmic.component.policy-info-box-component.AccountNumber',
        defaultMessage: 'Account #'
    },
    policyNumber: {
        id: 'wmic.component.policy-info-box-component.PolicyNumber',
        defaultMessage: 'Policy #'
    },
    policyStatus: {
        id: 'wmic.component.policy-info-box-component.PolicyStatus',
        defaultMessage: 'Policy Status'
    },
    term: {
        id: 'wmic.component.policy-info-box-component.Term',
        defaultMessage: 'Term'
    },
    active: {
        id: 'wmic.component.policy-info-box-component.Active',
        defaultMessage: 'Active'
    },
    renewing: {
        id: 'wmic.component.policy-info-box-component.Renewing',
        defaultMessage: 'Renewing'
    },
    new: {
        id: 'wmic.component.policy-info-box-component.New',
        defaultMessage: 'New'
    },
    inactive: {
        id: 'wmic.component.policy-info-box-component.Inactive',
        defaultMessage: 'Inactive'
    },
    insuranceIdCard: {
        id: 'wmic.component.policy-info-box-component.InsuranceIdCard',
        defaultMessage: 'Insurance ID Card'
    },
    insuranceIdCardButtonTrackButtonIdentifier: {
        id: 'wmic.component.policy-info-box-component.tracking.InsuranceIdCard',
        defaultMessage: 'Policy Details - Proof of Insurance'
    },
    policyDeclaration: {
        id: 'wmic.component.policy-info-box-component.PolicyDeclaration',
        defaultMessage: 'Policy Declaration'
    },
    policyDeclarationButtonTrackButtonIdentifier: {
        id: 'wmic.component.policy-info-box-component.tracking.PolicyDeclaration',
        defaultMessage: 'Policy Details - Policy Declaration'
    },
    futurePolicyDeclarationButtonTrackButtonIdentifier: {
        id: 'wmic.component.policy-info-box-component.tracking.FutureDatedPolicyDeclaration',
        defaultMessage: 'Policy Details - Future Dated Policy Declaration'
    },
    roadsideAssistanceCard: {
        id: 'wmic.component.policy-info-box-component.RoadsideAssistanceCard',
        defaultMessage: 'Roadside Assistance Card'
    },
    roadsideAssistanceCardTrackButtonIdentifier: {
        id: 'wmic.component.policy-info-box-component.tracking.RoadsideAssistanceCard',
        defaultMessage: 'Policy Details - Roadside Assistance Card'
    },
    viewDocument: {
        id: 'wmic.component.policy-info-box-component.ViewDocument',
        defaultMessage: 'View Document'
    },
    futureDatedChangeMessage: {
        id: 'wmic.component.policy-info-box-component.FutureDatedChangeMessage',
        defaultMessage: 'A future dated change is scheduled on this policy. These changes will become effective {date}.'
    },
    wereSorry: {
        id: "platform.documentdownload.We're Sorry",
        defaultMessage: "We're sorry",
    },
    documentErrorMessage: {
        id: 'platform.documentdownload.Your document cannot be accessed at this time. Please try again later or call us at 1-888-363-7984 or email us at service.us@wawanesa.com.  Our customer service team is available Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.',
        defaultMessage: 'Your document cannot be accessed at this time. &lt;br/&gt;&lt;br/&gt;Please try again later or call us at &lt;a wmic-track-button-click=\"Modal - Document Error - Support Phone Number\" href=\"tel:1-888-363-7984\"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href=\"mailto:service.us@wawanesa.com\"&gt;service.us@wawanesa.com&lt;/a&gt;.  Our customer service team is available Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    closeButtonText: {
        id: 'policy.info.box.Close',
        defaultMessage: 'Close'
    },
    poiFriendlyName: {
        id: 'policy.info.box.Proof of Insurance": "Download a PDF version',
        defaultMessage: 'Download a PDF version'
    },
    insuranceIdCardAriaLabel: {
        id: 'wmic.component.policy-info-box-component.InsuranceIdCardAriaLabel',
        defaultMessage: 'Request Insurance ID Card for Policy #{policyNumber}'
    }
});
