import moment from 'moment';
import * as models from '../models';
import messages from './Services.messages';

function changesRequestToString(changes, translator) {
    let changeRequestToString = '';
    Object.entries(changes).forEach(([key, values]) => {
        const obj = models.default[key];
        // eslint-disable-next-line no-prototype-builtins
        if (!obj.hasOwnProperty('getMessageTitle')) {
            throw new Error(translator(messages.shouldDefineGetMessageTitle), key);
        }

        const title = obj.getMessageTitle(translator);
        changeRequestToString += `${title}

${values.map((v) => v.toString(translator)).join('')}

--------------------------------------------------------------------------
`;
    });
    return changeRequestToString;
}

export default class WMICPolicyChangeTransformationService {
    static changesRequestToString(changes, translator) {
        return changesRequestToString(changes, translator);
    };

    static transform(policyChangeRequest, changes, translator) { 
        const currentTimeCST = policyChangeRequest.currentTime.toLocaleString('en-US', { timeZone: 'PST' });
        const timestamp = moment(currentTimeCST).format('MMM DD YYYY hh:mm:ss A');
        const submitted = moment(currentTimeCST).format('MM/DD/YYYY hh:mm A');
        const reqToString = changesRequestToString(changes, translator);

        return `
<message xmlns="http://tempuri.org/MessageDS.xsd">
    <sender>${policyChangeRequest.sender}</sender>
    <timestamp>${timestamp}</timestamp>
    <messageType>${policyChangeRequest.messageType}</messageType>
    <properties>
        <property>
            <key>sender</key>
            <value>${policyChangeRequest.senderEmail}</value>
        </property>
        <property>
            <key>subject</key>
            <value>${policyChangeRequest.subject}</value>
        </property>
        <property>
            <key>recipient</key>
            <value>${policyChangeRequest.recipient}</value>
        </property>
        <property>
            <key>body</key>
            <value><![CDATA[A policy change has been requested from:
--------------------------------------------------------------------------
${policyChangeRequest.policyDetails.toString(translator)}

Submitted: ${submitted}
--------------------------------------------------------------------------


The details of the requested change(s) are as follows:
--------------------------------------------------------------------------
${reqToString}
]]></value>
        </property>
    </properties>
</message>
        `;
    }
}
