import { defineMessages } from 'react-intl';

export default defineMessages({
    ClaimsPaymentHistory: {
        id: 'wmic.component.payment-info-box-component.Claims Payment History',
        defaultMessage: 'Claims Payment History'
    },
    Date: {
        id: 'wmic.component.payment-info-box-component.Date',
        defaultMessage: 'Date'
    },
    PaidTo: {
        id: 'wmic.component.payment-info-box-component.Paid To',
        defaultMessage: 'Paid To'
    },
    Coverage: {
        id: 'wmic.component.payment-info-box-component.Coverage',
        defaultMessage: 'Coverage'
    },
    Amount: {
        id: 'wmic.component.payment-info-box-component.Amount',
        defaultMessage: 'Amount'
    },
    PaymentAria: {
        id: 'wmic.component.payment-info-box-component.Payment Aria',
        defaultMessage: 'A payment of ${amount}  was made on {date} to {paidTo}, coverage {coverage}'
    },
    Multiple: {
        id: 'wmic.component.payment-info-box-component.Multiple',
        defaultMessage: 'Multiple'
    },
    ThePaymentDisplayedMayNotBeAccuratePlease: {
        id: 'wmic.component.payment-info-box-component.The payment displayed may not be accurate please',
        defaultMessage: 'The payment displayed may not be accurate please '
    },
    ContactYourAdjuster: {
        id: 'wmic.component.payment-info-box-component.contact your adjuster',
        defaultMessage: 'contact your adjuster'
    },
    ForMoreInformation: {
        id: 'wmic.component.payment-info-box-component.for more information',
        defaultMessage: ' for more information'
    },
    ThePaymentDisplayedMayNotBeAccuratePleaseAria: {
        id: 'wmic.component.payment-info-box-component.The payment displayed may not be accurate please aria',
        defaultMessage: 'The payment displayed may not be accurate please contact your adjuster for more information'
    },
    YourCheckWasVoidedOn: {
        id: 'wmic.component.payment-info-box-component.Your check was voided on',
        defaultMessage: 'Your check was voided on '
    },
    YourCheckWasVoidedOnAria: {
        id: 'wmic.component.payment-info-box-component.Your check was voided on aria',
        defaultMessage: 'Your check was voided on {date}'
    },
    YourPaymentWasDepositedOn: {
        id: 'wmic.component.payment-info-box-component.Your payment was deposited on',
        defaultMessage: 'Your payment was deposited on '
    },
    YourPaymentWasDepositedOnAria: {
        id: 'wmic.component.payment-info-box-component.Your payment was deposited on aria',
        defaultMessage: 'Your payment was deposited on {date}'
    },
    YourCheckWasMaileddOn: {
        id: 'wmic.component.payment-info-box-component.Your check was mailed on',
        defaultMessage: 'Your check was mailed on '
    },
    YourCheckWasMaileddOnAria: {
        id: 'wmic.component.payment-info-box-component.Your check was mailed on aria',
        defaultMessage: 'Your check was mailed on {date}'
    },
    YourCheckWasDepositedOn: {
        id: 'wmic.component.payment-info-box-component.Your check was deposited on',
        defaultMessage: 'Your check was deposited on '
    },
    YourCheckWasDepositedOnAria: {
        id: 'wmic.component.payment-info-box-component.Your check was deposited on aria',
        defaultMessage: 'Your check was deposited on {date}'
    },
    MailingAddress: {
        id: 'wmic.component.payment-info-box-component.Mailing Address',
        defaultMessage: 'Mailing Address'
    },
    MailingAddressAria: {
        id: 'wmic.component.payment-info-box-component.Mailing Address aria',
        defaultMessage: 'Mailing Address {mailTo} {address}'
    },
    CheckNumber: {
        id: 'wmic.component.payment-info-box-component.Check Number',
        defaultMessage: 'Check Number'
    },
    CheckNumberAria: {
        id: 'wmic.component.payment-info-box-component.Check Number aria',
        defaultMessage: 'Check Number {checkNumber}'
    },
    AccountName: {
        id: 'wmic.component.payment-info-box-component.Account Name',
        defaultMessage: 'Account Name'
    },
    AccountNameAria: {
        id: 'wmic.component.payment-info-box-component.Account Name aria',
        defaultMessage: 'Account Name {accountName}'
    },
    BankName: {
        id: 'wmic.component.payment-info-box-component.Bank Name',
        defaultMessage: 'Bank Name'
    },
    BankNameAria: {
        id: 'wmic.component.payment-info-box-component.Bank Name aria',
        defaultMessage: 'Bank Name {bankName}'
    },
    AccountNumber: {
        id: 'wmic.component.payment-info-box-component.Account Number',
        defaultMessage: 'Account Number'
    },
    AccountNumberAria: {
        id: 'wmic.component.payment-info-box-component.Account Number aria',
        defaultMessage: 'Account Number {accountNumber}'
    },
    ReferenceID: {
        id: 'wmic.component.payment-info-box-component.Reference ID',
        defaultMessage: 'Reference ID'
    },
    ReferenceIDAria: {
        id: 'wmic.component.payment-info-box-component.Reference ID aria',
        defaultMessage: 'Reference ID {referenceID}'
    },
    Status: {
        id: 'wmic.component.payment-info-box-component.Status',
        defaultMessage: 'Status'
    },
    StatusAria: {
        id: 'wmic.component.payment-info-box-component.Status aria',
        defaultMessage: 'Status {status}'
    },
    PaymentBreakdown: {
        id: 'wmic.component.payment-info-box-component.PAYMENT BREAKDOWN',
        defaultMessage: 'PAYMENT BREAKDOWN'
    },
    PaymentBreakdownAria: {
        id: 'wmic.component.payment-info-box-component.PAYMENT BREAKDOWN aria',
        defaultMessage: 'PAYMENT of ${amount} for {coverage}'
    },
    PaymentBreakdownTooltip: {
        id: 'wmic.component.payment-info-box-component.Payment Breakdown Tooltip',
        defaultMessage: 'There were multiple coverages for this payment. This breakdown provides the payment distribution. If you have questions please call your adjuster.'
    },
    SortBy: {
        id: 'wmic.component.payment-info-box-component.Sort By:',
        defaultMessage: 'Sort By:'
    },
    Sort: {
        id: 'wmic.component.payment-info-box-component.Sort',
        defaultMessage: 'Sort'
    }
});
