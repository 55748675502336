import moment from 'moment';
import PolicyDetailsModel from './PolicyDetailsModel';

export default class PolicyChangeRequestModel {
    constructor({
        sender, currentTime, messageType, senderEmail, subject, recipient, policyDetails
    }) {
        let currentTimeCST = false;
        if (currentTime) {
            currentTimeCST = currentTime.toLocaleString('en-US', { timeZone: 'PST' });
        }
        this.sender = sender || 'iswebportaldev@wawanesa.com';
        this.currentTime = currentTimeCST || new Date().toLocaleString('en-US', { timeZone: 'PST' });
        this.messageType = messageType || 'policy-change-request-usa-change-summary';
        this.senderEmail = senderEmail || 'iswebportaldev@wawanesa.com';
        this.subject = subject || `Account Management Portal - Policy Change Request : ${moment(this.currentTime).format('MM/DD/YYYY hh:mm A')}`;
        this.recipient = recipient || 'qagtestnotifications@wawadev.com';
        this.policyDetails = policyDetails;
    }

    static toObjectModel(obj) {
        const model = Object.setPrototypeOf(obj, PolicyChangeRequestModel.prototype);
        model.policyDetails = Object.setPrototypeOf(
            obj.policyDetails,
            PolicyDetailsModel.prototype
        );
        return model;
    }
}
