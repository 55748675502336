// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Accounts__gwRowLayout{margin-bottom:var(--GW-LAYOUT-6)}.app__Accounts__gwAccountPageTileContainer{padding-top:var(--GW-LAYOUT-3)}.app__Accounts__gwProducerCodeDivider{border-left:solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);height:calc(var(--GW-HEADER-HEIGHT-SM)*.6)}.app__Accounts__gwAccountsListTableContainer{padding:var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8)}.app__Accounts__gwTitleStyle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);padding-bottom:var(--GW-LAYOUT-3)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/Accounts/Accounts.module.scss"],"names":[],"mappings":"AAAA,4BACI,gCAAA,CAGJ,2CACI,8BAAA,CAGJ,sCAEI,+DAAA,CADA,0CACA,CAGJ,6CACI,gEAAA,CAGJ,6BAEI,iEAAA,CADA,iCACA","sourcesContent":[".gwRowLayout {\n    margin-bottom: var(--GW-LAYOUT-6);\n}\n\n.gwAccountPageTileContainer {\n    padding-top: var(--GW-LAYOUT-3);\n}\n\n.gwProducerCodeDivider {\n    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));\n    border-left: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);\n}\n\n.gwAccountsListTableContainer {\n    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);\n}\n\n.gwTitleStyle {\n    padding-bottom: var(--GW-LAYOUT-3);\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwRowLayout": "app__Accounts__gwRowLayout",
	"gwAccountPageTileContainer": "app__Accounts__gwAccountPageTileContainer",
	"gwProducerCodeDivider": "app__Accounts__gwProducerCodeDivider",
	"gwAccountsListTableContainer": "app__Accounts__gwAccountsListTableContainer",
	"gwTitleStyle": "app__Accounts__gwTitleStyle"
};
export default ___CSS_LOADER_EXPORT___;
