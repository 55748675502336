import PolicyCoverages from './components/PolicyCoverages/PolicyCoverages';
import PolicyCommonDetails from './components/PolicyCommonDetails/PolicyCommonDetails';
import PolicyCommonCoverables from './components/PolicyCommonCoverables/PolicyCommonCoverables';
import PolicyCommonDocuments from './components/PolicyCommonDocuments/PolicyCommonDocuments';
import PolicyCommonContacts from './components/PolicyCommonContacts/PolicyCommonContacts';
import WMICBulletPointComponent from './components/WMICBulletPointComponent/WMICBulletPointComponent';
import WMICTabSetBadgeHeadingComponent from './components/WMICTabSetBadgeHeadingComponent/WMICTabSetBadgeHeadingComponent';
import WMICClickableIconTooltipComponent from './components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';
import WMICStickyMessageComponent from './components/WMICStickyMessageComponent/WMICStickyMessageComponent';
import WMICNoticesComponent from './components/WMICNoticesComponent/WMICNoticesComponent';

const policyCommonComponentMap = {
    PolicyCoveragesComponent: { component: 'PolicyCoverages' },
    PolicyCommonDetailsComponent: { component: 'PolicyCommonDetails' },
    PolicyCommonCoverablesComponent: { component: 'PolicyCommonCoverables' },
    PolicyCommonDocuments: { component: 'PolicyCommonDocuments' },
    PolicyCommonContacts: { component: 'PolicyCommonContacts' },
    WMICBulletPointComponent: { component: 'WMICBulletPointComponent' },
    WMICTabSetBadgeHeadingComponent: { component: 'WMICTabSetBadgeHeadingComponent' },
    WMICClickableIconTooltipComponent: { component: 'WMICClickableIconTooltipComponent' },
    WMICStickyMessageComponent: { component: 'WMICStickyMessageComponent ' },
    WMICNoticesComponent: { component: 'WMICNotices' }
};

const policyCommonComponents = {
    PolicyCoverages,
    PolicyCommonDetails,
    PolicyCommonCoverables,
    PolicyCommonDocuments,
    PolicyCommonContacts,
    WMICBulletPointComponent,
    WMICTabSetBadgeHeadingComponent,
    WMICClickableIconTooltipComponent,
    WMICStickyMessageComponent,
    WMICNoticesComponent
};

export {
    PolicyCoverages,
    PolicyCommonDetails,
    PolicyCommonDocuments,
    PolicyCommonContacts,
    policyCommonComponentMap,
    policyCommonComponents,
    WMICBulletPointComponent,
    WMICTabSetBadgeHeadingComponent,
    WMICClickableIconTooltipComponent,
    WMICStickyMessageComponent,
    WMICNoticesComponent
};
