import { StringUtil } from '@xengage/gw-portals-util-js';

/**
 * String utility functions
 */
export default {
    // Platform StringUtil functions
    capitalizeFirstLetter: StringUtil.capitalizeFirstLetter,
    lowercaseFirstLetter: StringUtil.lowercaseFirstLetter,
    toSerializedPropertyName: StringUtil.toSerializedPropertyName
};
