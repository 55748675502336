const scrollToIndex = (e, id, offset=0) => {
    const scrollTo = (i, scrollOffset) => {
        setTimeout(() => {
            const element = document.getElementById(i);
            if (element) {
                window.scrollTo({
                    top: element.offsetTop - (scrollOffset || 0),
                    behavior: 'smooth'
                });
            }
        }, 10);
    };
    e.preventDefault();
    scrollTo(id, offset);
};

export default {
    scrollToIndex
};