// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICIterableFlexRiskItem__flexPremiumsGrid label{color:var(--GW-FIELD-COMPONENT-COLOR);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}.app__WMICIterableFlexRiskItem__flexPremiumsGrid div[class*=jut__FieldComponent__controlReadOnly]{font-weight:var(--GW-FONT-WEIGHT-REGULAR)}.app__WMICIterableFlexRiskItem__flexAccordionHeader{font-size:var(--GW-FONT-SIZE-H2)}.app__WMICIterableFlexRiskItem__flexAccordionHeader span[class=normal]{font-weight:var(--GW-FONT-WEIGHT-REGULAR)}.app__WMICIterableFlexRiskItem__flexAccordionHeader h2[class*=WMICHeading]{margin-bottom:0}.app__WMICIterableFlexRiskItem__flexRiskCard{margin-bottom:16px;padding:10px 0 1px}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICFlexProgramComponent/WMICIterableFlexRiskItem/WMICIterableFlexRiskItem.module.scss"],"names":[],"mappings":"AACE,uDAEE,qCAAA,CADA,2CACA,CAGF,kGACE,yCAAA,CAIJ,oDACE,gCAAA,CAEA,uEACE,yCAAA,CAGF,2EACE,eAAA,CAIJ,6CAEE,kBAAA,CADA,kBACA","sourcesContent":[".flexPremiumsGrid {\n  label {\n    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n    color: var(--GW-FIELD-COMPONENT-COLOR);\n  }\n\n  div[class*='jut__FieldComponent__controlReadOnly'] {\n    font-weight: var(--GW-FONT-WEIGHT-REGULAR);\n  }\n}\n\n.flexAccordionHeader {\n  font-size: var(--GW-FONT-SIZE-H2);\n\n  span[class='normal'] {\n    font-weight: var(--GW-FONT-WEIGHT-REGULAR);\n  }\n\n  h2[class*='WMICHeading'] {\n    margin-bottom: 0;\n  }\n}\n\n.flexRiskCard {\n  padding: 10px 0 1px 0;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexPremiumsGrid": "app__WMICIterableFlexRiskItem__flexPremiumsGrid",
	"flexAccordionHeader": "app__WMICIterableFlexRiskItem__flexAccordionHeader",
	"flexRiskCard": "app__WMICIterableFlexRiskItem__flexRiskCard"
};
export default ___CSS_LOADER_EXPORT___;
