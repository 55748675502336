import { MapUtil } from '@xengage/gw-portals-util-js';
import appConfig from 'app-config';
import _ from 'lodash';

function getApiKey() {
    try {
        // https://developers.google.com/maps/documentation/javascript/get-api-key
        const { GOOGLE_MAPS_API_KEY: googleMapsApiKey } = appConfig.credentials;

        if (_.isNil(googleMapsApiKey) || _.isEmpty(googleMapsApiKey) || googleMapsApiKey === 'ENTER GOOGLE MAPS API KEY HERE') {
            throw new Error('No API key applied');
        }

        return googleMapsApiKey;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Please include a google maps API key to the config.json file in the config directory.');
        return undefined;
    }
}

export default {
    // Platform MapUtil functions
    get: MapUtil.get,
    toUniqueMap: MapUtil.toUniqueMap,
    getApiKey: getApiKey,
    getLibraries: MapUtil.getLibraries,
    getMapVersion: MapUtil.getMapVersion
};
