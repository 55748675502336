import React, { useContext } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';

import metadata from './WMICHOPrintableAgreement.metadata.json5';
import messages from './WMICHOPrintableAgreement.messages';

function WMICHOPrintableAgreement(props) {

    const translator = useContext(TranslatorContext);

    const renderRemarksLines = () => {
        const remarksArray = [...Array(10)].map((x, i) => i);
        return remarksArray.map((r) => <div id={`remarkLine${r}`}/>)
    }

    const overrideProps = {
        remarksContainer: {
            content: renderRemarksLines()
        },
        fullDisclosureExQuebec: {
            content: WMICRichTextUtil.translateRichText(translator(messages.fullDisclosureExQuebec))
        },
        fullDisclosureQuebec: {
            content: WMICRichTextUtil.translateRichText(translator(messages.fullDisclosureQuebec))
        },
        fullDisclosureAllProvinces: {
            content: WMICRichTextUtil.translateRichText(translator(messages.fullDisclosureAllProvinces))
        }
    };

    const resolvers = {};

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} {...resolvers} />;
}

WMICHOPrintableAgreement.propTypes = {

};

WMICHOPrintableAgreement.defaultProps = {

};

export default WMICHOPrintableAgreement;