/* eslint-disable no-secrets/no-secrets */
import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { CONSTANTS, HOConstants, WMICUserAccessUtil, WMICValidationUtil, WMICLogger, MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';
import { WMICHOAdditionalInterests, WMICHOWatercraftDetails, DwellingDetailView, WatercraftDetailView } from 'wmic-pe-capability-gateway-common-ho-react';
import WMICHORiskUtil from 'wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';

import { messages as policyRenewalCommonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import * as WMICHOYourHomeUtil from 'wmic-pe-capability-gateway-quoteandbind-ho-react/util/WMICHOYourHomeUtil'
import defaultRisk from 'wmic-pe-capability-gateway-quoteandbind-ho-react/util/defaultRisk.json5';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import useGetSaveMethod from '../../hooks/useGetSaveMethod';
import metadata from './WMICPolicyChangeHoRiskPage.metadata.json5';
import messages from './WMICPolicyChangeHoRiskPage.messages';

const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';
const WATERCRAFT_PATH = 'lobData.homeowners.coverables.watercrafts';
const YOURHOMEMAP = `yourHome.${CONSTANTS.METADATAMAP}`;
const RATINGMAP = `rating.${CONSTANTS.METADATAMAP}`;
const ADLINTMAP = `additionalInterests.${CONSTANTS.METADATAMAP}`;

function recalculatePrimaryDwelling(policyChange, dwelling) {
    const dwellings = _.get(policyChange, 'lobData.homeowners.coverables.dwellings');
    const isPrimary = _.get(dwelling, 'yourHome.primaryDwelling_EXT');

    dwelling && isPrimary && dwellings.forEach((item) => {
        if(!_.isEqual(item, dwelling)) {
            _.set(item, 'yourHome.primaryDwelling_EXT', false);
        }
    })
}

function WMICPolicyChangeHoRiskPage({
    wizardData: policyChangeVM,
    updateWizardData
}) {

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const {
        registerInitialComponentValidation,
        onValidate,
        initialValidation,
        isComponentValid,
    } = useValidation('PEHOYourHomePage');
    const { authHeader, authUserData } = useAuthentication();
    const { setWizardLoading } = useWizardModals();
    const { showConfirm } = useWizardModals();
    const saveMethod = useGetSaveMethod(policyChangeVM);

    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);

    // PAGE STATE
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState()
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [selectedDwellingIndex, setSelectedDwellingIndex] = useState();

    // initialise the data for the page
    useEffect(() => {
        registerInitialComponentValidation(() => WMICHOYourHomeUtil.presentDwellingRiskList(policyChangeVM));
        if (WMICHOYourHomeUtil.presentDwellingRiskList(policyChangeVM)) {
            setSelectedDwellingIndex(0);
        }
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditDwelling = useCallback(()=> {
        _.set(policyChangeVM, 'isEditingPage.value', true);
    }, [policyChangeVM]);

    const toCreateDwelling = useCallback( async () => {
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
        }
        updateWizardData(policyChangeVM);

        const skeleton = await EndorsementService.getSkeletonStructure(
            policyChangeVM.value,
            authHeader
        );

        const _dwellingRiskViewVM = viewModelService.create(
            defaultRisk,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.DwellingDTO_WMIC',
            policyChangeVM.aspects.context()
        );
        // copy in skeletons for the Radiant data meta maps
        _.set(_dwellingRiskViewVM, YOURHOMEMAP, _.get(skeleton, `${DWELLINGS_PATH}[0].${YOURHOMEMAP}`));
        _.set(_dwellingRiskViewVM, RATINGMAP, _.get(skeleton, `${DWELLINGS_PATH}[0].${RATINGMAP}`));
        _.set(_dwellingRiskViewVM, ADLINTMAP, _.get(skeleton, `${DWELLINGS_PATH}[0].${ADLINTMAP}`));

        if (!WMICHOYourHomeUtil.presentDwellingRiskList(policyChangeVM)) {
            _.set(_dwellingRiskViewVM, 'yourHome.primaryDwelling_EXT.value', true);
        }

        _dwellingRiskViewVM.value.isUnderEditing = true;
        _dwellingRiskViewVM.value.isAddingLocation = false;
        _dwellingRiskViewVM.value.isAddingAdditionalInterest = false;
        _dwellingRiskViewVM.value.isAddingPetOnPremise = false;
        onEditDwelling();
        policyChangeVM.lobData.homeowners.coverables.dwellings.value.push(_dwellingRiskViewVM.value);
        return _dwellingRiskViewVM;
    }, [EndorsementService, authHeader, maintainFurtherStepsVisitedSubmitted, onEditDwelling, policyChangeVM, setIsCustomStepsInvalidated, updateWizardData, viewModelService]);


    // PAGE HELPER FUNCTIONS
    // ADD NEW RISKx

    const toCreateDwellingWithModal = useCallback(() => showConfirm({
        title: policyRenewalCommonMessages.revalidationRequiredTitle,
        message: policyRenewalCommonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.cancelModel,
    }).then( (results) => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
            updateWizardData(policyChangeVM);
        }
        return toCreateDwelling();
    }), [maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated, updateWizardData, policyChangeVM, showConfirm, toCreateDwelling])

    const savePolicyChange = useCallback(newPolicyChangeVM => {
        setWizardLoading(true, translator(messages.savingRisk));

        return EndorsementService.saveWithNoValidationRuleCheck(
            [newPolicyChangeVM],
            authHeader
        ).then((result) => {
            _.extend(newPolicyChangeVM, result);
            policyChangeVM.value = newPolicyChangeVM;
            updateWizardData(policyChangeVM);
            return true;
        }).catch((error) => {
            WMICLogger.error('Save risk failed', error);
            return false;
        }).finally(() => {
            setWizardLoading(false);
        });
    }, [setWizardLoading, translator, EndorsementService, authHeader, policyChangeVM, updateWizardData]);

    const onDeleteDwelling = useCallback((item, index) => {
        return showConfirm({
            title: messages.removeRiskTitle,
            message: messages.removeRiskMessage,
        })
            .then((response) => {
                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    const dwellings = _.get(policyChangeVM.value, DWELLINGS_PATH);

                    if (item && (
                        (item.yourHome && item.yourHome.residenceType === HOConstants.residenceTypeLaneway)
                         || (item.rating && (item.rating.hoPolicyType === HOConstants.revenuePropertyPolicyType
                             || item.rating.hoPolicyType === HOConstants.homeownerPolicyType)))) {
                        const associatedDwellingDeleted = _.find(policyChangeVM.value.lobData.homeowners.coverables.dwellings,
                            (dwelling) => dwelling.associatedDwellingPublicID === item.publicID);
                        if (associatedDwellingDeleted) {
                            associatedDwellingDeleted.associatedDwellingPublicID = undefined;
                        }
                    }
                    dwellings.splice(index, 1);
                    _.set(policyChangeVM.value, DWELLINGS_PATH, dwellings);
                    return savePolicyChange(policyChangeVM.value);
                }
                return false;
            });
    }, [savePolicyChange, showConfirm, policyChangeVM]);

    const onSaveDwelling = useCallback((dwellingVM, index) => {
        const dwellingPath = `${DWELLINGS_PATH}.${index}`;
        recalculatePrimaryDwelling(policyChangeVM.value, dwellingVM.value);
        _.set(policyChangeVM.value, `${dwellingPath}.value.isUnderEditing`, false);
        _.set(policyChangeVM, 'isEditingPage.value', false);
        return savePolicyChange(policyChangeVM.value);
    }, [savePolicyChange, policyChangeVM]);

    const onEditWatercraft = useCallback(()=> {
        _.set(policyChangeVM, 'isEditingPage.value', true);
    }, [policyChangeVM]);

    const toCreateWatercraft = useCallback(() => {
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
        }
        updateWizardData(policyChangeVM);

        const _watercraftRisk = {
            watercraftType: HOConstants.boatEquipmentTrailer,
            watercraftComponents: [],
            additionalInterests: []
        };
        const _watercraftRiskViewVM = viewModelService.create(
            _watercraftRisk,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.WatercraftRiskDTO_WMIC',
            policyChangeVM.aspects.context()
        );
        // _.set(_watercraftRiskviewVM, ADLINTMAP, _.get(skeletons, `${DWELLINGS_PATH}[0].${ADLINTMAP}`)); todo: needed when we fix the POC
        _.set(_watercraftRiskViewVM, ADLINTMAP, null);
        _watercraftRiskViewVM.value.isUnderEditing = true;
        _watercraftRiskViewVM.value.isAddingAdditionalInterest = false;
        _watercraftRiskViewVM.value.isAddingBoatMotor = false;
        onEditWatercraft();
        policyChangeVM.lobData.homeowners.coverables.watercrafts.value.push(_watercraftRiskViewVM.value);
        return _watercraftRiskViewVM;
    }, [maintainFurtherStepsVisitedSubmitted, onEditWatercraft, policyChangeVM, setIsCustomStepsInvalidated, updateWizardData, viewModelService]);


    const toCreateWatercraftWithModal = useCallback(() => showConfirm({
        title: policyRenewalCommonMessages.revalidationRequiredTitle,
        message: policyRenewalCommonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.cancelModel,
    }).then( (results) => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
            updateWizardData(policyChangeVM);
        }
        return toCreateWatercraft();
    }), [maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated, updateWizardData, policyChangeVM, showConfirm, toCreateWatercraft])

    const onDeleteWatercraft = useCallback((item, index) => {
        return showConfirm({
            title: messages.removeRiskTitle,
            message: messages.removeRiskMessage,
        })
            .then((response) => {
                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    const watercrafts = _.get(policyChangeVM.value, WATERCRAFT_PATH);
                    watercrafts.splice(index, 1);
                    return savePolicyChange(policyChangeVM.value);
                }
                return false;
            });
    }, [savePolicyChange, showConfirm, policyChangeVM]);

    const onSaveWatercraft = useCallback((watercraftVM, index) => {
        const watercraftPath = `${WATERCRAFT_PATH}.${index}`;
        _.set(policyChangeVM.value, `${watercraftPath}.value.isUnderEditing`, false);
        _.set(policyChangeVM, 'isEditingPage.value', false);
        return savePolicyChange(policyChangeVM.value);
    }, [savePolicyChange, policyChangeVM]);

    const onCancel = () => {
        _.set(policyChangeVM, 'isEditingPage.value', false);
        updateWizardData(policyChangeVM);
    }

    const onNext = useCallback(async () => {
        if (!isComponentValid || !WMICHOYourHomeUtil.presentDwellingRiskList(policyChangeVM)) {
            setShowErrors(true);
            setScrollToError(Date.now())
            return false;
        }

        try {
            setWizardLoading(true);

            const newPolicyChange = _.cloneDeep(policyChangeVM.value);

            policyChangeVM.value = await saveMethod(
                [newPolicyChange],
                authHeader
            );
            updateWizardData(policyChangeVM);

            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, policyChangeVM, setWizardLoading, saveMethod, authHeader]);

    const overrideProps = {
        inlineNotification: {
            visible: WMICHOYourHomeUtil.presentWatercraftRiskList(policyChangeVM) && !WMICHOYourHomeUtil.presentDwellingRiskList(policyChangeVM)
        },
        trailerMonetaryValue: {
            parent: policyChangeVM.trailer
        },

        // DWELLING
        dwellingRequiredText: {
            visible: !WMICHOYourHomeUtil.presentDwellingRiskList(policyChangeVM),
            message: translator(messages.dwellingRequired)
        },
        dwellingListView: {
            clickable: true,
            readOnly: isReadOnlyUser,
            VMData: [
                {
                    headerText: translator(messages.dwellingHeader),
                    path: 'yourHome.dwellingUsage',
                },
                {
                    headerText: translator(messages.riskHeader),
                    path: 'yourHome.dwellingNumber_EXT',
                    getData: (item, path) => WMICHOYourHomeUtil.renderDwellingRiskNumberCell(item, path, policyChangeVM)
                },
                {
                    headerText: translator(messages.addressHeader),
                    path: 'yourHome.locationAddress.displayName',
                    getData: (item) => WMICHOYourHomeUtil.renderDwellingAddressCell(item, translator)
                },
                {
                    headerText: translator(messages.riskTypeHeader),
                    path: 'rating.hoPolicyType'
                }
            ],
            detailViewComponent: DwellingDetailView,
            onValidate,
            toCreate: toCreateDwellingWithModal,
            toEdit: onEditDwelling,
            toUndoCreate: () => {
                const dwellings = _.get(policyChangeVM.value, DWELLINGS_PATH);
                dwellings.splice(dwellings.length-1, 1);
                _.set(policyChangeVM.value, DWELLINGS_PATH, dwellings);
                _.set(policyChangeVM, 'isEditingPage.value', false);
                updateWizardData(policyChangeVM);
            },
            onCancel,
            onSave: onSaveDwelling,
            onDelete: onDeleteDwelling,
            showCancelModal: true
        },
        watercraftListView: {
            clickable: true,
            readOnly: isReadOnlyUser,
            startOpen: false,
            VMData: [
                {
                    headerText: translator(messages.riskHeader),
                    path: 'riskNumber'
                },
                {
                    headerText: translator(messages.riskDescription),
                    path: 'description',
                    getData: WMICHORiskUtil.getWaterCraftDescription
                },
                {
                    headerText: translator(messages.riskTypeHeader),
                    path: 'watercraftType'
                },
            ],
            detailViewComponent: WatercraftDetailView,
            onValidate,
            toCreate: toCreateWatercraftWithModal,
            toEdit: onEditWatercraft,
            toUndoCreate: () => {
                const watercrafts = _.get(policyChangeVM.value, WATERCRAFT_PATH);
                watercrafts.splice(watercrafts.length-1, 1);
                _.set(policyChangeVM.value, WATERCRAFT_PATH, watercrafts);
                _.set(policyChangeVM, 'isEditingPage.value', false);
                updateWizardData(policyChangeVM);
            },
            onCancel,
            onSave: onSaveWatercraft,
            onDelete: onDeleteWatercraft,
            showCancelModal: true
        }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            WMICHOAdditionalInterests,
            WMICHOWatercraftDetails
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPolicyChangeHoRiskPage.propTypes = wizardProps;

export default withViewModelService(WMICPolicyChangeHoRiskPage);
