import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import VehicleModel from './VehicleModel';
import messages from './Models.messages';

export default class EditUninsuredModel {
    constructor({
        vehicle,
        collisionDeductibleWaiver,
        propertyDamage,
        vehicleManufacturerReplacementParts,
        country,
        state
    }) {
        this.vehicle = vehicle;
        this.collisionDeductibleWaiver = collisionDeductibleWaiver;
        this.propertyDamage = propertyDamage;
        this.vehicleManufacturerReplacementParts = vehicleManufacturerReplacementParts;
        this.country = country;
        this.state = state;
    }

    isCalifornia() {
        return this.country === CONSTANTS.COUNTRY.US && this.state === CONSTANTS.STATE.CA;
    }

    toString() {
        const isCA = this.isCalifornia();
        if (!this.valid()) {
            if (isCA) {
                // eslint-disable-next-line max-len
                throw new Error(messages.vehicleCollisionDeductibleWaiverPropertyDamageVehicleManufacturerReplacementPartsRequired);
            }

            // eslint-disable-next-line max-len
            throw new Error(messages.vehiclePropertyDamageVehicleManufacturerReplacementPartsRequired);
        }

        let text = 'Please apply the following Vehicle level coverage changes:\n';
        text += `Vehicle Description: ${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}\n`;
        text += this.propertyDamage ? `Coverage Uninsured Motorist Property Damage: ${this.propertyDamage === 'Add' ? 'Yes' : 'No'}\n` : '';
        text += this.collisionDeductibleWaiver ? `Coverage Uninsured Collision Deductible Waiver: ${this.collisionDeductibleWaiver === 'Add' ? 'Yes' : 'No'}\n` : '';
        text += this.vehicleManufacturerReplacementParts ? `Coverage Vehicle Manufacturer Replacement Parts: ${this.vehicleManufacturerReplacementParts === 'Add' ? 'Yes' : 'No'}\n` : '';
        text += '---\n\n';

        return text;
    }

    toHTML() {
        let text = '<span>';
        text += `\n<strong>Vehicle</strong>: ${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}<br/>`;
        text += this.propertyDamage ? `<strong>Uninsured Motorist Property Damage</strong>: ${this.propertyDamage === 'Add' ? 'Yes' : 'No'}<br/>` : '';
        text += this.collisionDeductibleWaiver ? `<strong>Uninsured Motorist Collision Deductible Waiver</strong>: ${this.collisionDeductibleWaiver === 'Add' ? 'Yes' : 'No'}<br/>` : '';
        text += this.vehicleManufacturerReplacementParts ? `<strong>Vehicle Manufacturer Replacement Parts</strong>: ${this.vehicleManufacturerReplacementParts === 'Add' ? 'Yes' : 'No'}<br/>` : '';
        text += '\n</span>';

        return text;
    }

    valid() {
        const required = [this.vehicle, this.country, this.state];
        return required.every((v) => v) && this.vehicle.valid();
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangeOfCoverageRequest);
    }

    static toObjectModel(obj) {
        const c = Object.setPrototypeOf(obj, EditUninsuredModel.prototype);
        c.vehicle = Object.setPrototypeOf(obj.vehicle, VehicleModel.prototype);
        return c;
    }
}

EditUninsuredModel.coverageInfo = {
    collisionDeductibleWaiver: 'PA_UNIMSCollCov',
    propertyDamage: 'PA_UNIMSPDCov',
    vehicleManufacturerReplacementParts: 'PA_VMRPCov'
};