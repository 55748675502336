import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { ActivitiesService } from 'gw-capability-gateway';
import OpenActivitiesComponent from '../../Components/OpenActivitiesComponent/OpenActivitiesComponent';
import metadata from './OpenActivities.metadata.json5';

class AccountOpenActivities extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            }),
            accountActivitiesData: PropTypes.shape({
                getUpdateCountInfo: PropTypes.func.isRequired
            })
        }).isRequired
    };

    getActivities = () => {
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const getAvailableActivities = ActivitiesService.getActivitiesForAccount(
            accountNumber,
            authHeader
        );
        return getAvailableActivities;
    };

    createNewActivity = async (newActivityData) => {
        const { authHeader } = this.props;
        const addResponse = await ActivitiesService.createNewActivity(
            newActivityData,
            null,
            authHeader
        );
        return addResponse;
    };

    getActivitiesTileUpdatedCount = () => {
        const {
            fromAccountLanding: {
                accountActivitiesData: { getUpdateCountInfo }
            }
        } = this.props;
        getUpdateCountInfo('OpenActivities');
    };

    getMetadataImplementation = () => {
        const {
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const overrides = {
            accountDetailsActivitiesContainer: {
                getActivities: this.getActivities,
                createNewActivity: this.createNewActivity,
                activityEntity: 'account',
                activityEntityId: accountNumber,
                getActivitiesTileUpdatedCount: this.getActivitiesTileUpdatedCount
            }
        };
        const resolvers = {
            resolveComponentMap: {
                openactivitiescomponent: OpenActivitiesComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    };

    render() {
        return this.getMetadataImplementation();
    }
}

export default withAuthenticationContext(AccountOpenActivities);
