/* eslint-disable react/prop-types */
import React, {
    useContext,
    useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNext
} from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICPaymentUtil, WMICRichTextUtil } from 'wmic-portals-utils-js';

import messages from './WMICDelinquencyModal.messages';
import styles from './WMICDelinquencyModal.module.scss';

let payPolicyAfterClose = false;

const WMICDelinquencyModal = ({
    isOpen,
    onResolve,
    termNumber,
    billingData,
    policyData,
    onPayNowClick
}) => {
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const showPayNowButton = useCallback(() => {
        const isViewingCurrentTerm = _.get(billingData, 'termNumber') === parseInt(termNumber, 10);
        return WMICPaymentUtil.canShowPayNowButton(billingData, _.get(billingData, 'policyStatus'), isViewingCurrentTerm);
    }, [billingData, termNumber]);

    const handleClose = () => {
        onResolve();
        payPolicyAfterClose = false;
    };

    const handlePay = () => {
        onResolve();
        payPolicyAfterClose = true;
    };

    const onDelinquencyModalClose = useCallback(() => {
        if (payPolicyAfterClose) {
            const policyModalData = {
                billingData: billingData,
                policyData: policyData
            };
            onPayNowClick(policyModalData, true);
        }
    }, [billingData, onPayNowClick, policyData]);

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc
            onAfterClose={onDelinquencyModalClose}
        >
            <ModalHeader
                title={translator(messages.policyChangesTempUnavailable)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true
                    }
                }}
            />
            <ModalBody>
                <div>
                    <p>
                        { translator(messages.paymentIsDue, {
                            amount: intl.formatNumber(
                                _.get(billingData, 'amountOverDue.amount'),
                                {
                                    style: 'currency',
                                    currency: _.get(billingData, 'amountOverDue.currency'),
                                    currencyDisplay: 'symbol'
                                }
                            )
                        })}
                        <br />
                        <br />
                        <span>{ WMICRichTextUtil.translateRichText(translator(messages.reachUs)) }</span>
                    </p>
                    { showPayNowButton() && (
                        <div>
                            <br />
                            <br />
                            <WMICButton
                                onClick={() => handlePay()}
                                type="danger"
                                size="small"
                                icon="cust-dollar-sign"
                                iconClassName="payNowIcon"
                            >
                                {translator(messages.payNow)}
                            </WMICButton>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <div>
                    <WMICButton
                        onClick={handleClose}
                        type="primary"
                    >
                        {translator(messages.close)}
                    </WMICButton>
                </div>
            </ModalFooter>
        </ModalNext>
    );
};

WMICDelinquencyModal.propTypes = {
    termNumber: PropTypes.string.isRequired,
    billingData: PropTypes.shape({}).isRequired,
    policyData: PropTypes.shape({}).isRequired,
    onPayNowClick: PropTypes.func.isRequired
};

export default WMICDelinquencyModal;