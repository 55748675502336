import _ from 'lodash';
import VehicleModel from './VehicleModel';
import messages from './Models.messages';

export default class RoadsideAssistanceModel {
    constructor({ changeType, vehicle }) {
        this.changeType = changeType;
        this.vehicle = vehicle;
        this.CHANGE_TYPE = {
            ADD: 'Add',
            DELETE: 'Delete',
        };
    }

    getRequestType() {
        return `I would like to ${this.CHANGE_TYPE[this.changeType]} the Roadside Assistance coverage ${this.changeType === 'ADD' ? 'to' : 'from'} the following vehicle:`;
    }

    toHTML(translator = _.noop()) {
        return `
<span>
<strong>${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}</strong></br>
<strong>Coverage:</strong> ${this.changeType === 'ADD' ? 'Roadside assistance selected' : 'No roadside assistance'}

</span>`;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.changeTypeVehicleRequired);
        }

        return `${this.getRequestType()}
${this.vehicle}
---

`;
    }

    valid() {
        const required = [this.changeType, this.vehicle];
        return required.every((req) => !!req) && this.vehicle.valid();
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangeRoadsideAssistanceRequest);
    }

    static toObjectModel(obj) {
        const model = Object.setPrototypeOf(obj, RoadsideAssistanceModel.prototype);
        model.vehicle = Object.setPrototypeOf(obj.vehicle, VehicleModel.prototype);
        return model;
    }
}
