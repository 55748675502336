// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICDocumentDownloadButton__buttonPolicyDeclaration{font-size:12px}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-document-react/components/WMICDocumentDownloadButton/WMICDocumentDownloadButton.module.scss"],"names":[],"mappings":"AAAA,0DACI,cAAA","sourcesContent":[".buttonPolicyDeclaration {\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonPolicyDeclaration": "app__WMICDocumentDownloadButton__buttonPolicyDeclaration"
};
export default ___CSS_LOADER_EXPORT___;
