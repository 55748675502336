import React, {
    useEffect,
    useCallback,
    useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICAddCustomizationComponent } from 'wmic-pe-capability-gateway-common-pa-react';
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { WMICRPCUtil, PAConstants } from 'wmic-pe-portals-utils-js';
import WMICPAAdditionalInterests from './WMICPAAdditionalInterests/WMICPAAdditionalInterests';
import customizationMessages from './WMICAddCustomization/WMICAddCustomizationComponent.messages';
import messages from './WMICVehicleAdditionalInfoComponent.messages';
import metadata from './WMICVehicleAdditionalInfoComponent.metadata.json5';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

function WMICVehicleAdditionalInfoComponent(props) {
    const {
        id,
        vehicleVM,
        skeletonStruct,
        jobVM,
        baseData,
        updateVehicle,
        onValidate,
        isEditMode,
        showErrors,
        setShowErrors,
        isReadOnlyUser
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const intl = useContext(IntlContext);
    const { refreshData } = useDataRefresh();

    const [selectedCustomization, updateSelectedCustomization] = useState();
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [addingCustomization, setAddingCustomization] = useState(false);

    const isRPC1722Effective = () => {
        return [PAConstants.personalAutoVehicleType, PAConstants.trailerVehicleType].includes(_.get(vehicleVM, 'vehicleType.value.code')) &&
            _.get(vehicleVM, 'businessSegment_WMIC.value.code') === PAConstants.vehicleBusinessSegmentCommercial &&
            WMICRPCUtil.getIsRPCEffective(_.get(vehicleVM, 'ratingJurisdiction.value.code'), _.get(baseData, 'rateAsOfDate.value'), '1722');
    };

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, id]);


    const updateVehicleData = useCallback(
        (data) => {
            refreshData();
            updateVehicle(data);
        },
        [refreshData, updateVehicle]
    );


    const addCustomization = () => {
        const newCustomization = viewModelService.create(
            {
                metaDataMap: _.get(vehicleVM, 'customizationsForm.metaDataMap.value')
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleCustomizationDTO_WMIC',
            {
                ...jobVM.aspects.context(),
                ...vehicleVM.aspects.context()
            }
        );

        updateSelectedCustomization(newCustomization);

        _.set(vehicleVM, 'isAddingCustomization.value', true);
        updateVehicle(vehicleVM);

        setAddingCustomization(true);
    }

    const saveCustomization = (data) => {
        if (!vehicleVM.customizations_WMIC.value) {
            _.set(vehicleVM, 'customizations_WMIC.value', []);
        }
        vehicleVM.customizations_WMIC.value.push(data.value);
        _.set(vehicleVM, 'isAddingCustomization.value', false);
        updateVehicle(vehicleVM);

        setAddingCustomization(false);
    }

    const cancelCustomization = () => {
        updateSelectedCustomization({});
        _.set(vehicleVM, 'isAddingCustomization.value', false);

        updateVehicle(vehicleVM);

        setAddingCustomization(false);
    }

    const removeCustomization = (item, index) => {
        const customizations = _.get(vehicleVM, 'customizations_WMIC.value');
        if (index >= 0) {
            customizations.splice(index, 1);
        }
        updateVehicle(vehicleVM);
    }

    const getCurrencyFormattedNumber = (currAmount) => {
        const currency = 'cad';

        const formattedNumber = intl.formatNumber(
            currAmount,
            {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol'
            }
        );

        return formattedNumber;
    }

    const getCustomizationTotal = () => {
        let total = 0;

        if (vehicleVM.customizations_WMIC.value) {
            for (let customizationIndex = 0; customizationIndex < vehicleVM.customizations_WMIC.value.length; customizationIndex++) {
                total += Number(vehicleVM.customizations_WMIC.getElement(customizationIndex).customizationValue.value);
            }
        }
        return getCurrencyFormattedNumber(total);
    };

    const getFormattedCustomizationValue = (currAmount) => {
        const amount = _.get(currAmount, 'customizationValue.value');
        return getCurrencyFormattedNumber(amount);
    }

    const isExcludingOperationOfAttachedMachineryVisibleForExistingCustomization = (vehicleAdditionalCustomization) => {
        return _.get(vehicleAdditionalCustomization, 'excludingOperationOfAttachedMachinery.aspects.ocular', false)
    }

    const getVehicleTypeOrDescription = (value) => {
        const msg = translator({id: _.get(value, 'vehicleCustType_WMIC.value.name')})
        return isRPC1722Effective() ? `${msg} ${_.get(value, 'description.value')}`: msg
    }

    const displayAdditionalCoverages = (customization) => {
        const display = [];

        if (customization.attachedMachineryExclusion30A.value) {
            display.push(translator(customizationMessages.AttachedMachineryExclusion30A));
        }

        if (customization.attachedMachineryExclusion30B.value) {
            display.push(translator(customizationMessages.AttachedMachineryExclusion30B));
        }

        return display.join('\n\n');
    };
    
    const getAdditionalCovOrDescription = (value) => {
        return isRPC1722Effective()
            ? displayAdditionalCoverages(value) : _.get(value, 'description.value')
    }

    const displayExcludingAttachedMachinery = (value)=> {
        return _.get(value, 'excludingOperationOfAttachedMachinery.value') ? commonMessages.yesModel : commonMessages.noModel;
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate,
            addCustomization
        },
        resolveComponentMap: {
            WMICPAAdditionalInterests,
            WMICAddCustomizationComponent
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: vehicleVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        additionalInterests: {
            baseData,
            vehicleVM,
            skeletonStruct,
            isEditMode,
            updateVehicle,
            setShowErrors,
            showErrors,
        },
        customizationDataList: {
            VMList: _.get(vehicleVM, 'customizations_WMIC.children', []),
            VMData: [
                {
                    headerText: isRPC1722Effective() 
                        ? translator(customizationMessages.vehicleCustomizationDescription)
                        : translator(customizationMessages.vehicleCustomizationType),
                    getData: getVehicleTypeOrDescription,
                },
                {
                    headerText: isRPC1722Effective() 
                        ? translator(customizationMessages.additionalCoverages)
                        : translator(customizationMessages.vehicleCustomizationDescription),
                    getData: getAdditionalCovOrDescription
                },
                {
                    headerText: translator(customizationMessages.vehicleCustomizationValue),
                    path: 'customizationValue',
                    getData: getFormattedCustomizationValue
                },
                {
                    headerText: translator(customizationMessages.vehicleCustomizationAttachedMachinery),
                    getData: displayExcludingAttachedMachinery,
                    visibilityCondition: isExcludingOperationOfAttachedMachineryVisibleForExistingCustomization
                },
            ],
            onRemoveAction: removeCustomization,
            flatCards: true,
            clickable: isEditMode,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: !isEditMode,
            readOnly: !isEditMode
        },
        customizationTotalAmount: {
            content: `${translator(messages.customizationTotal)}  ${getCustomizationTotal()}`
        },
        addCustomizationButton: {
            disabled: !isEditMode || addingCustomization,
            visible: isEditMode
        },
        customizationForm: {
            visible: addingCustomization,
            selectedCustomization,
            saveCustomization,
            cancelCustomization,
        }

    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onModelChange={updateVehicleData}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICVehicleAdditionalInfoComponent.propTypes = {
    id: PropTypes.string.isRequired,
    vehicleVM: PropTypes.shape({}).isRequired,
    baseData: PropTypes.shape({}).isRequired,
    updateVehicle: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    skeletonStruct: PropTypes.shape({}).isRequired
};

export default WMICVehicleAdditionalInfoComponent;
