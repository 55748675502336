// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICEditForm__editFormBorder{border:1px solid var(--GW-BORDER-COLOR);padding:var(--GW-SPACING-5)}.app__WMICEditForm__wmicEditForm{display:flex;flex-direction:column;justify-content:space-between}.app__WMICEditForm__wmicEditForm .app__WMICEditForm__editFormButtonsRow{display:flex;flex-direction:row;justify-content:flex-start;width:100%}.app__WMICEditForm__wmicEditForm .app__WMICEditForm__editFormButtonsRow .app__WMICEditForm__editFormButtonsContainer{display:flex;flex-direction:row;justify-content:flex-end;width:100%}.app__WMICEditForm__wmicEditForm .app__WMICEditForm__editFormButtonsRow .app__WMICEditForm__editFormButtonsContainer button:first-child{margin-right:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICEditForm/WMICEditForm.module.scss"],"names":[],"mappings":"AAAA,mCACI,uCAAA,CACA,2BAAA,CAGJ,iCACI,YAAA,CACA,qBAAA,CACA,6BAAA,CAEA,wEACI,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,UAAA,CACA,qHACI,YAAA,CACA,kBAAA,CAEA,wBAAA,CADA,UACA,CACA,wIACI,gCAAA","sourcesContent":[".editFormBorder {\n    border: 1px solid var(--GW-BORDER-COLOR);\n    padding: var(--GW-SPACING-5);\n}\n\n.wmicEditForm {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    .editFormButtonsRow{\n        display: flex;\n        flex-direction: row;\n        justify-content: flex-start;\n        width: 100%;\n        .editFormButtonsContainer {\n            display: flex;\n            flex-direction: row;\n            width: 100%;\n            justify-content: flex-end;\n            button:first-child {\n                margin-right: var(--GW-SPACING-3);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editFormBorder": "app__WMICEditForm__editFormBorder",
	"wmicEditForm": "app__WMICEditForm__wmicEditForm",
	"editFormButtonsRow": "app__WMICEditForm__editFormButtonsRow",
	"editFormButtonsContainer": "app__WMICEditForm__editFormButtonsContainer"
};
export default ___CSS_LOADER_EXPORT___;
