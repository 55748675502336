/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { CONSTANTS, WMICPaymentService } from 'wmic-portals-utils-js';
import { WMICLoader } from 'gw-components-platform-react';
import classNames from 'classnames';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';

const WMICLandingPagePaymentStatusModal = (props) => {
    const { onClose, paymentStatus, invoiceStreamId } = props;
    const translator = useTranslator();
    const [isOpen, setIsOpen] = useState(true);
    const MODAL_DISPLAY_MODES = {
        COMPLETE: 'complete',
        PROCESSING: 'processing',
        IN_PROGRESS: 'inProgress',
        ERROR: 'error'
    };
    const INT_COMPLETE_RESPONSE_CODE = 'COMPLETE';
    const CHECK_PAYMENT_STATUS_TIME = 5000; // Milliseconds between checks of the payment status
    const CHECK_PAYMENT_STATUS_ATTEMPTS = 3; // Number of times to check the payment status
    let checkPaymentInterval;
    let checkPaymentIntervalInteraction = 0;
    let finishedCheckingPaymentStatus = false;

    const getInitialDisplayMode = (status) => {
        switch (status) {
            case CONSTANTS.INTEGRATION_CC_PAYMENT_STATUS.COMPLETE:
                return MODAL_DISPLAY_MODES.PROCESSING;
            case CONSTANTS.INTEGRATION_CC_PAYMENT_STATUS.ERROR:
                return MODAL_DISPLAY_MODES.ERROR;
            default:
                return '';
        }
    };

    const [displayMode, setDisplayMode] = useState(getInitialDisplayMode(paymentStatus));

    const stopPaymentCheck = () => {
        if (checkPaymentInterval) {
            clearInterval(checkPaymentInterval);
            checkPaymentInterval = undefined;
        }
        finishedCheckingPaymentStatus = true;
    };

    const checkPaymentStatus = (entityId) => {
        if (checkPaymentIntervalInteraction < CHECK_PAYMENT_STATUS_ATTEMPTS && !finishedCheckingPaymentStatus) {
            checkPaymentIntervalInteraction++;
            // Check the payment status
            WMICPaymentService.getPayment(entityId).then((response) => {
                if (response?.data) {
                    const payload = response.data[0];
                    if (payload?.action === INT_COMPLETE_RESPONSE_CODE) {
                        // If paymente is complete
                        stopPaymentCheck();
                        setDisplayMode(MODAL_DISPLAY_MODES.COMPLETE);
                    }
                }
            }).catch(() => {});
        } else {
            // Checked for payment the maximum number of times. Change the message to the user.
            stopPaymentCheck();
            if (displayMode === MODAL_DISPLAY_MODES.PROCESSING) {
                setDisplayMode(MODAL_DISPLAY_MODES.IN_PROGRESS);
            }
        }
    };

    useEffect(() => {
        if (displayMode !== MODAL_DISPLAY_MODES.ERROR) {
            checkPaymentStatus();
            checkPaymentInterval = setInterval(checkPaymentStatus, CHECK_PAYMENT_STATUS_TIME, invoiceStreamId);
        }
    }, []);

    const getTitle = (mode) => {
        switch (mode) {
            case MODAL_DISPLAY_MODES.COMPLETE:
                return translator(messages.paymentSuccessfulCamelCase);
            case MODAL_DISPLAY_MODES.ERROR:
                return translator(messages.paymentUnsuccessfulCamelCase);
            case MODAL_DISPLAY_MODES.IN_PROGRESS:
                return translator(messages.paymentInProgress);
            case MODAL_DISPLAY_MODES.PROCESSING:
                return translator(messages.processingPayment);
            default:
                return '';
        }
    };

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader
                title={getTitle(displayMode)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true,
                        justifyContent: displayMode === MODAL_DISPLAY_MODES.PROCESSING ? 'center' : 'left'
                    }
                }}
            />
            <ModalBody>
                {displayMode === MODAL_DISPLAY_MODES.COMPLETE && (
                    <div>
                        <p className={styles['wmic-payment-text']}>{translator(messages.thankYouForYourPaymentYouWillReceiveYourReceiptViaEmailShortly)}</p>
                        <p className={styles['wmic-payment-text']}>{translator(messages.pleaseAllowUpTo5BusinessDaysForYourPaymentToAppearOnYourCreditCardStatement)}</p>
                    </div>
                )}
                {displayMode === MODAL_DISPLAY_MODES.ERROR && (
                    <div>
                        <p className={styles['wmic-payment-text']}>{translator(messages.thePaymentSystemIsCurrentlyUnavailablePleaseChooseAnotherPaymentMethodOrTryAgainLaterIfYouHaveAnyQuestions)}</p>
                    </div>
                )}
                {displayMode === MODAL_DISPLAY_MODES.IN_PROGRESS && (
                    <div>
                        <p className={styles['wmic-payment-text']}>{translator(messages.weHaveNotYetReceivedSuccessfulNotificationOfYourPaymentFromTheCreditCardCompanyIfYouHaveReceived)}</p>
                        <p className={styles['wmic-payment-text']}>{translator(messages.inTheEventThatYourPaymentIsNotAppliedToYourPolicyWithin24Hours)}</p>
                    </div>
                )}
                {displayMode === MODAL_DISPLAY_MODES.PROCESSING && (
                    <div>
                        <div className={styles['wmic-loader-div']}>
                            <WMICLoader />
                        </div>
                        <p className={classNames(styles['wmic-payment-text'], styles['wmic-text-processing'])}>{translator(messages.pleaseDoNotNavigateAwayFromThisPageOrCloseYourBrowser)}</p>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                {displayMode !== MODAL_DISPLAY_MODES.PROCESSING && (
                    <WMICButton
                        onClick={() => { setIsOpen(false); onClose(); }}
                        type="filled"
                    >
                        {translator(messages.ok)}
                    </WMICButton>
                )}
            </ModalFooter>
        </ModalNext>
    );
};

WMICLandingPagePaymentStatusModal.propTypes = {
    onClose: PropTypes.func,
    paymentStatus: PropTypes.string,
    invoiceStreamId: PropTypes.string
};

WMICLandingPagePaymentStatusModal.defaultProps = {
    onClose: () => {},
    paymentStatus: undefined,
    invoiceStreamId: undefined
};

export default WMICLandingPagePaymentStatusModal;