import { defineMessages } from "react-intl";

export default defineMessages({
    removedVehicle: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Removed Vehicle",
        defaultMessage: "Vehicle You're Removing",
    },
    vehiclesRemainingOnPolicy: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Vehicles Remaining on Policy",
        defaultMessage: "Vehicle(s) Remaining on Policy",
    },
    editDrivers: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Edit Drivers",
        defaultMessage: "Edit Drivers",
    },
    editVehicles: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Edit Vehicles",
        defaultMessage: "Edit Vehicles",
    },
    use: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Use",
        defaultMessage: "Use:",
    },
    annualMileage: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Annual Mileage",
        defaultMessage: "Annual Mileage:",
    },
    primaryDriver: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Primary Driver",
        defaultMessage: "Primary Driver:",
    },
    occasionalDrivers: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.policy-change-review-vehicle-edit.Occasional Drivers",
        defaultMessage: "Occasional Drivers:",
    },
});
