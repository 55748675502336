import { defineMessages } from 'react-intl';

export default defineMessages({
    addDesignatedPersonInfo: {
        id: 'wmic.policy-change.component.designated-person-action.addDesignatedPersonInfo',
        defaultMessage: 'Designate Additional Person to Receive Notice of Cancellation or Nonrenewal for Nonpayment of Premium'
    },
    editDesignatedPersonInfo: {
        id: 'wmic.policy-change.component.designated-person-action.editDesignatedPersonInfo',
        defaultMessage: 'Edit Additional Person to Receive Notice of Cancellation or Nonrenewal for Nonpayment of Premium'
    },
    currentContactOnRecord: {
        id: 'wmic.policy-change.component.designated-person-action.currentContactOnRecord',
        defaultMessage: 'Current contact on record'
    },
    addContactButton: {
        id: 'wmic.policy-change.component.designated-person-action.addContactButton',
        defaultMessage: 'Add Contact'
    },
    cancel: {
        id: 'wmic.policy-change.component.designated-person-action.cancel',
        defaultMessage: 'Cancel'
    },
    edit: {
        id: 'wmic.policy-change.component.designated-person-action.edit',
        defaultMessage: 'Edit'
    },
    remove: {
        id: 'wmic.policy-change.component.designated-person-action.remove',
        defaultMessage: 'Remove'
    },
    removeContact: {
        id: 'wmic.policy-change.component.designated-person-action.removeContact',
        defaultMessage: 'Remove Contact'
    },
    pleaseConfirmTheDeletion: {
        id: 'wmic.policy-change.component.designated-person-action.pleaseConfirmTheDeletion',
        defaultMessage: 'Please confirm the deletion of the following contact:'
    },
    yesRemove: {
        id: 'wmic.policy-change.component.designated-person-action.yesRemove',
        defaultMessage: 'Yes, Remove'
    },
    delete: {
        id: 'wmic.policy-change.component.designated-person-action.delete',
        defaultMessage: 'Policy Change - Delete - Recipient for Notice of Cancellation or Nonrenewal'
    }

});