import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './PolicyCommonDetails.metadata.json5';
import './PolicyCommonDetails.messages';
import styles from './PolicyCommonDetails.module.scss';

function PolicyCommonDetails(props) {
    const { value: policyCommonDetails } = props;

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyCommonDetails}
            classNameMap={styles}
        />
    );
}

PolicyCommonDetails.propTypes = {
    value: PropTypes.shape({})
};

PolicyCommonDetails.defaultProps = {
    value: {}
};

export default PolicyCommonDetails;
