// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICScheduledItemComponent__newAIDropdownMenuButton{position:static}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-ho-react/components/WMICScheduledItemComponent/WMICScheduledItemComponent.module.scss"],"names":[],"mappings":"AAAA,0DACI,eAAA","sourcesContent":[".newAIDropdownMenuButton {\n    position: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newAIDropdownMenuButton": "app__WMICScheduledItemComponent__newAIDropdownMenuButton"
};
export default ___CSS_LOADER_EXPORT___;
