import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WMICPolicyWizard from './WMICPolicyWizard/WMICPolicyWizard';
import WMICPolicySummaryPage from './WMICPolicyWizard/pages/WMICPolicySummary/WMICPolicySummaryPage';
import WMICPolicyInsuredPage from './WMICPolicyWizard/pages/WMICPolicyInsured/WMICPolicyInsuredPage'
import WMICPolicyContacts from './WMICPolicyWizard/pages/WMICPolicyContacts/WMICPolicyContacts';
import WMICPolicyInsuranceHistory from './WMICPolicyWizard/pages/WMICPolicyInsuranceHistory/WMICPolicyInsuranceHistory';
import WMICPolicyBilling from './WMICPolicyWizard/pages/WMICPolicyBilling/WMICPolicyBilling';
import WMICPolicyDocuments from './WMICPolicyWizard/pages/WMICPolicyDocuments/WMICPolicyDocuments';
import WMICPolicyTransactions from './WMICPolicyWizard/pages/WMICPolicyTransactions/WMICPolicyTransactions';
import WMICPolicyHoRiskPage from './WMICPolicyWizard/pages/lob/Homeowners/WMICPolicyHoRiskPage/WMICPolicyHoRiskPage';
import WMICPolicyHoConstructionPage from './WMICPolicyWizard/pages/lob/Homeowners/WMICPolicyHoConstructionPage/WMICPolicyHoConstructionPage';
import WMICPolicyHoCoveragesPage from './WMICPolicyWizard/pages/lob/Homeowners/WMICPolicyHoCoveragesPage/WMICPolicyHoCoveragesPage';
import WMICPolicyScheduledItemsPage from './WMICPolicyWizard/pages/lob/Homeowners/WMICPolicyScheduledItemsPage/WMICPolicyScheduledItemsPage';
import WMICPolicyPuUnderlyingPolicies from './WMICPolicyWizard/pages/lob/PersonalUmbrella/WMICPolicyPuUnderlyingPolicies/WMICPolicyPuUnderlyingPolicies';
import WMICPolicyPaDriversPage from './WMICPolicyWizard/pages/lob/PersonalAuto/WMICPolicyPaDriversPage/WMICPolicyPaDriversPage';
import WMICPolicyPaDriverPersonalInfoComponent from './WMICPolicyWizard/components/WMICPolicyPaDriverPersonalInfo/WMICPolicyPaDriverPersonalInfoComponent';
import WMICPolicyPaLicenseInfoComponent from './WMICPolicyWizard/components/WMICPolicyPaLicenseInfo/WMICPolicyPaLicenseInfoComponent';
import WMICPolicyPaMVRComponent from './WMICPolicyWizard/components/WMICPolicyPaMVR/WMICPolicyPaMVRComponent';
import WMICPolicyPaDriverPolicyHistoryComponent from './WMICPolicyWizard/components/WMICPolicyPADriverPolicyHistory/WMICPolicyPADriverPolicyHistoryComponent';
import WMICPolicyPaCommercialDriverComponent from './WMICPolicyWizard/components/WMICPolicyPaCommercialDriver/WMICPolicyPaCommercialDriverComponent';
import WMICPAPolicyCoveragesPage from './WMICPolicyWizard/pages/lob/PersonalAuto/WMICPAPolicyCoveragesPage/WMICPAPolicyCoveragesPage';
import WMICPolicyPaDriversDetailView from './WMICPolicyWizard/components/WMICPolicyPaDriversDetailView/WMICPolicyPaDriversDetailView';
import WMICHOPolicyDwellingPropertyDetails from './WMICPolicyWizard/components/WMICHOPolicyDwellingPropertyDetails/WMICHOPolicyDwellingPropertyDetails';
import WMICPolicyVehicles from './WMICPolicyWizard/pages/WMICPolicyVehicles/WMICPolicyVehicles';
import WMICPAPolicyLossHistory from './WMICPolicyWizard/components/WMICPAPolicyLossHistory/WMICPAPolicyLossHistory';
import WMICHOPolicyLossHistory from './WMICPolicyWizard/components/WMICHOPolicyLossHistory/WMICHOPolicyLossHistory';
import WMICPolicyLossHistoryPayout from './WMICPolicyWizard/components/WMICPolicyLossHistoryPayout/WMICPolicyLossHistoryPayout';

import WMICPolicyCPInsuranceHistory from './WMICPolicyWizard/pages/lob/CommercialProperty/WMICPolicyCPInsuranceHistory/WMICPolicyCPInsuranceHistory'
import WMICPolicyCPLocationsBuildingsPage from './WMICPolicyWizard/pages/lob/CommercialProperty/WMICPolicyCPLocationsBuildingsPage/WMICPolicyCPLocationsBuildingsPage'
import WMICPolicyPropertyPage from './WMICPolicyWizard/pages/lob/CommercialProperty/WMICPolicyPropertyPage/WMICPolicyPropertyPage'
import WMICPolicyGLAdditionalCoveragesPage from './WMICPolicyWizard/pages/lob/GeneralLiability/WMICPolicyGLAdditionalCoveragesPage/WMICPolicyGLAdditionalCoveragesPage'
import WMICPolicyGLBusinessOperationPage from './WMICPolicyWizard/pages/lob/GeneralLiability/WMICPolicyGLBusinessOperationPage/WMICPolicyGLBusinessOperationPage'
import WMICGlPolicyLiabilityPage from './WMICPolicyWizard/pages/lob/GeneralLiability/WMICGlPolicyLiabilityPage/WMICGlPolicyLiabilityPage'

export { default as WMICPolicyPuUnderlyingPoliciesComponent } from './WMICPolicyWizard/components/WMICPolicyPuUnderlyingPoliciesComponent/WMICPolicyPuUnderlyingPoliciesComponent';
import WMICPolicyPupHouseholdMembers from './WMICPolicyWizard/pages/lob/PersonalUmbrella/WMICPolicyPupHouseholdMembers/WMICPolicyPupHouseholdMembers';

export { default as WMICPolicyWizardPageWithTitle } from './PolicyWizardTemplate/templates/WMICPolicyWizardPageWithTitleTemplate/WMICPolicyWizardPageWithTitleTemplate';


setComponentMapOverrides(
    {
        WMICPolicySummaryPage: { component: 'WMICPolicySummaryPage' },
        WMICPolicyInsuredPage: { component: 'WMICPolicyInsuredPage'},
        WMICPolicyContactsPage: { component: 'WMICPolicyContactsPage' },
        WMICPolicyInsuranceHistoryPage: { component: 'WMICPolicyInsuranceHistoryPage' },
        WMICPolicyBillingPage: { component: 'WMICPolicyBillingPage' },
        WMICPolicyDocumentsPage: { component: 'WMICPolicyDocumentsPage' },
        WMICPolicyVehiclesPage: { component: 'WMICPolicyVehiclesPage'},
        WMICPolicyTransactionsPage: { component: 'WMICPolicyTransactionsPage' },
        WMICPolicyHoRiskPage: { component: 'WMICPolicyHoRiskPage' },
        WMICPolicyHoConstructionPage: { component: 'WMICPolicyHoConstructionPage' },
        WMICPolicyHoCoveragesPage: { component: 'WMICPolicyHoCoveragesPage'},
        WMICPolicyScheduledItemsPage: { component: 'WMICPolicyScheduledItemsPage' },
        WMICPolicyPuUnderlyingPoliciesPage: { component: 'WMICPolicyPuUnderlyingPoliciesPage' },
        WMICPolicyPaDriversPage: { component: 'WMICPolicyPaDriversPage' },
        WMICPolicyPaDriverPersonalInfoComponent: { component: 'WMICPolicyPaDriverPersonalInfoComponent'},
        WMICPolicyPaLicenseInfoComponent: { component: 'WMICPolicyPaLicenseInfoComponent'},
        WMICPolicyPaMVRComponent: { component: 'WMICPolicyPaMVRComponent'},
        WMICPolicyPaDriverPolicyHistoryComponent: { component: 'WMICPolicyPaDriverPolicyHistoryComponent'},
        WMICPolicyPaCommercialDriverComponent: { component: 'WMICPolicyPaCommercialDriverComponent'},
        WMICPAPolicyCoveragesPage: { component: 'WMICPAPolicyCoveragesPage'},
        WMICPolicyPaDriversDetailView: { component: 'WMICPolicyPaDriversDetailView'},
        WMICPolicyPupHouseholdMembers: { component: 'WMICPolicyPupHouseholdMembers' },
        WMICHOPolicyDwellingPropertyDetails: {component: 'WMICHOPolicyDwellingPropertyDetails'},
        WMICPAPolicyLossHistory: { component: 'WMICPAPolicyLossHistory' },
        WMICHOPolicyLossHistory: { component: 'WMICHOPolicyLossHistory' },
        WMICPolicyLossHistoryPayout: { component: 'WMICPolicyLossHistoryPayout' },
        // commercial package / general liability
        WMICPolicyCPInsuranceHistory: { component: 'WMICPolicyCPInsuranceHistory' },
        WMICPolicyCPLocationsBuildingsPage: { component: 'WMICPolicyCPLocationsBuildingsPage' },
        WMICPolicyPropertyPage: { component: 'WMICPolicyPropertyPage' },
        WMICPolicyGLAdditionalCoveragesPage: { component: 'WMICPolicyGLAdditionalCoveragesPage' },
        WMICPolicyGLBusinessOperationPage: { component: 'WMICPolicyGLBusinessOperationPage' },
        WMICGlPolicyLiabilityPage: { component : 'WMICGlPolicyLiabilityPage' }
    },
    {
        WMICPAPolicyCoveragesPage,
        WMICPolicySummaryPage,
        WMICPolicyInsuredPage,
        WMICPolicyContactsPage: WMICPolicyContacts,
        WMICPolicyInsuranceHistoryPage: WMICPolicyInsuranceHistory,
        WMICPolicyDocumentsPage: WMICPolicyDocuments,
        WMICPolicyVehiclesPage: WMICPolicyVehicles,
        WMICPolicyBillingPage: WMICPolicyBilling,
        WMICPolicyTransactionsPage: WMICPolicyTransactions,
        WMICPolicyHoRiskPage,
        WMICPolicyHoConstructionPage,
        WMICPolicyHoCoveragesPage,
        WMICPolicyScheduledItemsPage,
        WMICPolicyPuUnderlyingPoliciesPage: WMICPolicyPuUnderlyingPolicies,
        WMICPolicyPaDriversPage,
        WMICPolicyPaDriverPersonalInfoComponent,
        WMICPolicyPaLicenseInfoComponent,
        WMICPolicyPaMVRComponent,
        WMICPolicyPaDriverPolicyHistoryComponent,
        WMICPolicyPaCommercialDriverComponent,
        WMICPolicyPaDriversDetailView,
        WMICPolicyPupHouseholdMembers: WMICPolicyPupHouseholdMembers,
        WMICHOPolicyDwellingPropertyDetails,
        WMICPAPolicyLossHistory,
        WMICHOPolicyLossHistory,
        WMICPolicyLossHistoryPayout,
        // commercial package / general liability
        WMICPolicyCPInsuranceHistory,
        WMICPolicyCPLocationsBuildingsPage,
        WMICPolicyPropertyPage,
        WMICPolicyGLAdditionalCoveragesPage,
        WMICPolicyGLBusinessOperationPage,
        WMICGlPolicyLiabilityPage
    }
);
export {
    WMICPolicyWizard,
    WMICPolicyPaDriverPersonalInfoComponent,
    WMICPolicyPaLicenseInfoComponent,
    WMICPolicyPaMVRComponent,
    WMICPolicyPaDriverPolicyHistoryComponent,
    WMICPolicyPaCommercialDriverComponent,
    WMICPolicyPaDriversDetailView,
    WMICHOPolicyDwellingPropertyDetails,
    WMICPAPolicyLossHistory,
    WMICHOPolicyLossHistory,
    WMICPolicyLossHistoryPayout
};
