import { defineMessages } from 'react-intl';

export default defineMessages({
    changePaymentPlan: {
        id: 'wmic.component.change-payment-plan-box.title',
        defaultMessage: 'CHANGE PAYMENT PLAN'
    },
    description: {
        id: 'wmic.component.change-payment-plan-box.description',
        defaultMessage: 'Change or modify your selected payment plan\n'
    },
    fullPayment: {
        id: 'wmic.component.change-payment-plan-box.fullPayment',
        defaultMessage: '&lt;strong&gt;Full Payment 100%&lt;/strong&gt;\nof total premium down; no installment fee.'
    },
    twoPayments: {
        id: 'wmic.component.change-payment-plan-box.twoPayments',
        defaultMessage: 'Two Payments This is your current payment plan 50% of total premium down; 1 installment, plus installment fee.'
    },
    cancel: {
        id: 'wmic.component.change-payment-plan-box.cancel',
        defaultMessage: 'Cancel'
    },
    continue: {
        id: 'wmic.component.change-payment-plan-box.continue',
        defaultMessage: 'Continue'
    },
    confirmationTitle: {
        id: 'wmic.component.change-payment-plan-box.confirmation-title',
        defaultMessage: 'Confirm payment plan change'
    },
    confirmationTextIntro: {
        id: 'wmic.component.change-payment-plan-box.confirmation-text-intro',
        defaultMessage: 'Please confirm the following changes to your policy\n\n'
    },
    submit: {
        id: 'wmic.component.change-payment-plan-box.submit',
        defaultMessage: 'Submit'
    },
    monthlyOptionWarningMessage: {
        id: 'wmic.component.change-payment-plan-box.monthly-option-warning',
        defaultMessage: 'Monthly Payment'
    },
    fullPaymentOptionWarningMessage: {
        id: 'wmic.component.change-payment-plan-box.full-payment-option-warning',
        defaultMessage: 'Full Payment'
    },
    twoPaymentsOptionWarningMessage: {
        id: 'wmic.component.change-payment-plan-box.two-payments-option-warning',
        defaultMessage: 'Two Payments'
    },
    monthlyOptionFirst: {
        id: 'wmic.component.change-payment-plan-box.monthly-option',
        defaultMessage: '&lt;strong&gt;Monthly Automated Recurring Payments&lt;/strong&gt;&lt;br&gt;'
    },
    monthlyOptionSecond: {
        id: 'wmic.component.change-payment-plan-box.monthly-option-second',
        defaultMessage: 'Equal monthly installments; plus installment fee.'
    },
    fullPaymentOptionFirst: {
        id: 'wmic.component.change-payment-plan-box.full-payment-option',
        defaultMessage: '&lt;strong&gt;Full Payment&lt;/strong&gt;&lt;br&gt;'
    },
    fullPaymentOptionSecond: {
        id: 'wmic.component.change-payment-plan-box.full-payment-option-second',
        defaultMessage: '100% of total premium down;&lt;br&gt;'
    },
    fullPaymentOptionThird: {
        id: 'wmic.component.change-payment-plan-box.full-payment-option-second-third',
        defaultMessage: 'no installment fee.'
    },
    twoPaymentsOptionFirst: {
        id: 'wmic.component.change-payment-plan-box.two-paymenst-option',
        defaultMessage: '&lt;strong&gt;Two Payments&lt;/strong&gt;&lt;br&gt;'
    },
    twoPaymentsOptionSecond: {
        id: 'wmic.component.change-payment-plan-box.two-paymenst-option-second',
        defaultMessage: '50% of total premium down;&lt;br&gt;'
    },
    twoPaymentsOptionThird: {
        id: 'wmic.component.change-payment-plan-box.two-paymenst-option-third',
        defaultMessage: 'installment, plus installment fee.'
    },
    confirmationTextChangePaymentTo: {
        id: 'wmic.component.change-payment-plan-box.change-payment-to',
        defaultMessage: '&lt;strong&gt;Change payment plan to:&lt;/strong&gt;'
    },
    onceSubmitted: {
        id: 'wmic.component.change-payment-plan-box.once-submitted',
        defaultMessage: '\n\nOnce submitted, an agent will contact you shortly to finish processing this change. After it is fully processed, the agent will confirm with you when the change will take effect'
    },
    currentPaymentPlan: {
        id: 'wmic.component.change-payment-plan-box.current-plan',
        defaultMessage: '&lt;br&gt;&lt;strong style="color:#FFA500"&gt;This is your current payment plan&lt;/strong&gt;&lt;/p&gt;'
    }
});