/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl';

export default defineMessages({
    rentalExpCovLimitMessage: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.Your current coverage limits are x per day and x per occurrence.',
        defaultMessage: 'Your current coverage limits are ${limitPerDay} per day and ${limitPerOccurrence} per occurrence.'
    },
    collisionCovDedMessage: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.Your current collision coverage deductible is x',
        defaultMessage: 'Your current collision coverage deductible is ${coverageAmount}'
    },
    comprehensiveCovDedMessage: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.Your current comprehensive coverage deductible is x',
        defaultMessage: 'Your current comprehensive coverage deductible is ${coverageAmount}'
    },
    deductible: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.deductible',
        defaultMessage: 'deductible'
    },
    perDay: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.per day',
        defaultMessage: 'per day'
    },
    perOccurrence: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.per occurrence',
        defaultMessage: 'per occurrence'
    },
    comprehensiveCovDedTooltip: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.Comprehensive coverage deductibles cannot be lowered online. For assistance, please call &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.',
        defaultMessage: 'Comprehensive coverage deductibles cannot be lowered online. For assistance, please call &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    collisionCovDedTooltip: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-term.Collision coverage deductibles cannot be lowered online. For assistance, please call &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.',
        defaultMessage: 'Collision coverage deductibles cannot be lowered online. For assistance, please call &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    }
});