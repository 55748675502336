import { defineMessages } from 'react-intl';

export default defineMessages({

    cancel: {
        id: 'wmic.policy-change.component.add-designated-person-view.Cancel',
        defaultMessage: 'Cancel'
    },

    continue: {
        id: 'wmic.policy-change.component.add-designated-person-view.Continue',
        defaultMessage: 'Continue'
    },

    cancellationModalTitle: {
        id: 'wmic.policy-change.component.add-designated-person-view.Cancellation Modal Title',
        defaultMessage: 'Changes will be lost'
    },

    cancellationModalBody: {
        id: 'wmic.policy-change.component.add-designated-person-view.Cancellation Modal Body',
        defaultMessage: "If you cancel now you'll lose any changes you've made."
    },

    cancellationModalConfirm: {
        id: 'wmic.policy-change.component.add-designated-person-view.Cancellation Modal Confirm',
        defaultMessage: 'Cancel Changes'
    },

    cancellationModalCancel: {
        id: 'wmic.policy-change.component.add-designated-person-view.Cancellation Modal Cancel',
        defaultMessage: 'Keep Editing'
    },

    submissionModalConfirm: {
        id: 'wmic.policy-change.component.add-designated-person-view.Submission Modal Confirm',
        defaultMessage: 'Your policy change is ready to submit'
    },

    submissionModalBody: {
        id: 'wmic.policy-change.component.add-designated-person-view.Submission Modal Body',
        defaultMessage: 'Once submitted, an agent will contact you shortly to finish processing this change. After it is fully processed, the agent will confirm with you when the change will take effect.'
    },

    submissionModalSubmit: {
        id: 'wmic.policy-change.component.add-designated-person-view.Submission Modal Submit',
        defaultMessage: 'Submit'
    },

    submissionModalCancel: {
        id: 'wmic.policy-change.component.add-designated-person-view.Submission Modal Cancel',
        defaultMessage: 'Cancel'
    },

    submissionModalTrackingText: {
        id: 'wmic.policy-change.component.add-designated-person-view.Submission Modal Tracking Text',
        defaultMessage: 'Policy Change - Submit - Change of address'
    }
});