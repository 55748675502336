import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WMICPaymentPage from './pages/WMICPaymentPage/WMICPaymentPage';
import WMICReceiptPage from './pages/WMICReceiptPage/WMICReceiptPage';
import WMICCoverages from './components/WMICCoverages/WMICCoverages';
import ReadOnlyCoverageTable from './components/Coverages/ReadOnlyCoverageTable/ReadOnlyCoverageTable';
import ReadOnlyNonCoverageCostTable from './components/NonCoverageCosts/ReadOnlyNonCoverageCostTable/ReadOnlyNonCoverageCostTable';
import AdditionalInterestTable from './components/AdditionalInterestTable/AdditionalInterestTable';
import WMICContactDetailsPage from './pages/WMICContactDetailsPage/WMICContactDetailsPage';

setComponentMapOverrides(
    {
        WMICPaymentPage: { component: 'WMICPaymentPage' },
        WMICReceiptPage: { component: 'WMICReceiptPage' },
        WMICCoverages: { component: 'WMICCoverages'},
        ReadOnlyCoverageTable: { component: 'ReadOnlyCoverageTable'},
        ReadOnlyNonCoverageCostTable: { component: 'ReadOnlyNonCoverageCostTable'},
        AdditionalInterestTable: {component: 'AdditionalInterestTable'},
        WMICContactDetailsPage: { component: 'WMICContactDetailsPage' },
    },
    {
        WMICPaymentPage,
        WMICReceiptPage,
        WMICCoverages,
        WMICContactDetailsPage,
        ReadOnlyCoverageTable,
        ReadOnlyNonCoverageCostTable,
        AdditionalInterestTable,
    }
);

export { default as WMICQualificationPage } from './pages/WMICQualification/WMICQualificationPage';
export { default as WMICErrorHandler } from './util/WMICErrorHandler/WMICErrorHandler';
export { default as WMICProgressModal } from './components/WMICProgressModal/WMICProgressModal';
export { default as WMICPaymentPage } from './pages/WMICPaymentPage/WMICPaymentPage';
export { default as WMICReceiptPage } from './pages/WMICReceiptPage/WMICReceiptPage';
export { default as WMICBulletPointComponent } from './components/WMICBulletPointComponent/WMICBulletPointComponent';
export { default as WMICCoverages } from './components/WMICCoverages/WMICCoverages'
export { default as WMICContactDetailsPage } from './pages/WMICContactDetailsPage/WMICContactDetailsPage';
export { default as ReadOnlyCoverageTable } from './components/Coverages/ReadOnlyCoverageTable/ReadOnlyCoverageTable';
export { default as ReadOnlyNonCoverageCostTable } from './components/NonCoverageCosts/ReadOnlyNonCoverageCostTable/ReadOnlyNonCoverageCostTable';
export { default as AdditionalInterestTable } from './components/AdditionalInterestTable/AdditionalInterestTable';
