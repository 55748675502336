/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { useTranslator } from '@jutro/locale';
import messages from './WMICCancelRemoveVehicleModal.messages';
import styles from './WMICCancelRemoveVehicleModal.module.scss';

const WMICCancelRemoveVehicleModal = ({
    onCancelEdit,
    onResolve,
    onReject,
    isOpen
}) => {
    const translator = useTranslator();

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext 
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc>
            <ModalHeader
                title={translator(messages.changesWillBeLost)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true,
                        className: styles['wmic-title']
                    }
                }}
            />
            <ModalBody>
                <div>
                    <p className={styles['wmic-paragraph-text']}>
                        {translator(messages.ifYouCancelNowYoullLoseAnyChangesYouveMade)}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="ww-btn-group-gap-right">
                    <WMICButton
                        onClick={() => { 
                            onReject();
                        }}
                        type="outlined"
                        id="no-margin"
                    >
                        {translator(messages.keepEditing)}
                    </WMICButton>
                    <WMICButton
                        onClick={() => { 
                            onCancelEdit();
                            onResolve();
                        }}
                        type="primary"
                    >
                        {translator(messages.cancelChanges)}
                    </WMICButton>
                </div>
            </ModalFooter>
        </ModalNext>
    );
};

WMICCancelRemoveVehicleModal.propTypes = {
    onCancelEdit: PropTypes.func.isRequired
};

export default WMICCancelRemoveVehicleModal;