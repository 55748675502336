import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ServiceManager } from '@jutro/legacy/services';
import { Link, withRouter } from 'react-router-dom';
import { withModalContext } from '@jutro/components';
import { AccountService } from 'gw-capability-gateway-policy';
import messages from './NewQuoteAccountSearch.messages';
import gatewayMessages from '../gateway.messages';
import styles from './NewQuoteAccountSearch.module.scss';
import metadata from './NewQuoteAccountSearch.metadata.json5';

const ACCOUNT_TYPE = {
    commercial: 'company',
    personal: 'person'
};

class NewQuoteAccountSearchWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    state = {
        searchResult: false,
        submissionVM: {},
        existingSearchResult: [],
        showErrorContent: false,
        showNoMatch: false,
        showNoLengthMatch: false,
        showAccountMatches: false
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({})
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        ...validationPropTypes
    };

    showValidations = (value) => {
        let showContent = false;
        if (value.contactType === 'person') {
            if (value.firstName.length < 5 || value.lastName.length < 5) {
                showContent = true;
            }
        }
        return showContent;
    };

    searchAccounts = async () => {
        const { submissionVM } = this.state;
        const {
            submissionVM: { value }
        } = this.state;
        const { authHeader } = this.props;
        let { searchResult, existingSearchResult } = this.state;
        let accounts = [];
        try {
            accounts = await AccountService.getPotentialExistingAccounts(
                submissionVM.value,
                authHeader
            );
            searchResult = true;
        } catch {
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: gatewayMessages.gatewayViewsModalErrorData,
                status: 'error',
                icon: 'mi-error-outline'
            }).catch(_.noop);
        }
        existingSearchResult = this.getResponse(accounts);
        this.setState({
            searchResult,
            existingSearchResult
        });
        if (existingSearchResult.length === 0) {
            if (value.contactType === 'company') {
                if (value.contactName.length < 5) {
                    this.setState({
                        showNoLengthMatch: true,
                        showErrorContent: false,
                        showNoMatch: false,
                        showAccountMatches: false
                    });
                } else {
                    this.setState({
                        showNoMatch: true,
                        showErrorContent: false,
                        showNoLengthMatch: false,
                        showAccountMatches: false
                    });
                }
            } else if (this.showValidations(value) === true) {
                this.setState({
                    showErrorContent: true,
                    showNoMatch: false,
                    showNoLengthMatch: false,
                    showAccountMatches: false
                });
            } else {
                this.setState({
                    showNoMatch: true,
                    showErrorContent: false,
                    showNoLengthMatch: false,
                    showAccountMatches: false
                });
            }
        } else {
            this.setState({
                showAccountMatches: true,
                showNoMatch: false,
                showNoLengthMatch: false,
                showErrorContent: false
            });
        }
    };

    componentDidMount = () => {
        let { submissionVM } = this.state;
        const localeService = ServiceManager.getService('locale-service');
        const model = {
            contactType: ACCOUNT_TYPE.commercial,
            country: localeService.getDefaultCountryCode()
        };
        submissionVM = this.createVM(model);
        this.setState({
            submissionVM
        });
    };

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.account.search.dto.AccountSearchCriteriaDTO'
        );
    };

    writeValue = (value, path) => {
        const { submissionVM } = this.state;
        _.set(submissionVM, path, value);
        this.setState({ submissionVM });
    };

    onSearchAgain = () => {
        let { searchResult } = this.state;
        searchResult = false;
        this.setState({
            searchResult
        });
    };

    getCell = (item, index, { id: property }) => {
        return item[property];
    };

    getResponse = (searchResponse) => {
        const translator = this.context;
        const userList = searchResponse.map((result) => {
            const user = {
                startNewQuote: `${translator(messages.startNewQuote)}`,
                accountNumber: result.accountNumber,
                displayName: result.accountHolder.primaryAddress.displayName,
                contactName: result.accountHolder.displayName,
                canView: result.canUserView && result.canUserCreateSubmission
            };

            return user;
        });
        return userList;
    };

    newCustomerBtnClick = () => {
        const { history } = this.props;
        const { submissionVM } = this.state;
        const nextLocation = {
            accountSearchCriteria: _.get(submissionVM, 'value')
        };
        history.push('/new-quote', nextLocation);
    };

    startNewQuoteLink = (item, index, { id: property }) => {
        const value = item[property];
        const { submissionVM } = this.state;
        const { accountNumber } = item;
        if (item.canView) {
            return (
                <Link
                    to={{
                        pathname: `/new-quote/${accountNumber}`,
                        state: {
                            accountSearchCriteria: submissionVM.value
                        }
                    }}
                >
                    {value}
                </Link>
            );
        }
        return <h4>{value}</h4>;
    };

    handleNewQuoteCancel = () => {
        const { history } = this.props;
        history.push('/');
    };

    handleCancel = () => {
        this.props.modalContext.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }
            return this.handleNewQuoteCancel();
        }, _.noop);
    };

    render() {
        const {
            submissionVM,
            searchResult,
            existingSearchResult,
            showErrorContent,
            showNoMatch,
            showNoLengthMatch,
            showAccountMatches
        } = this.state;
        const { setComponentValidation, isComponentValid } = this.props;
        const accountType = _.get(submissionVM, `${'contactType'}.value.code`);
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showOptional: true,
            },
            accountTypeToggle: {
                value: accountType
            },
            commercialContainer: {
                visible: accountType === ACCOUNT_TYPE.commercial
            },
            personalContainer: {
                visible: accountType === ACCOUNT_TYPE.personal
            },
            exisitingUserTable: {
                data: existingSearchResult
            },
            newQuoteContainer: {
                visible: !searchResult
            },
            existingSearchResult: {
                visible: searchResult
            },
            noMatch: {
                visible: showNoMatch
            },
            detailsaccount: {
                visible: showAccountMatches
            },
            possibleBaseQuote: {
                visible: showAccountMatches
            },
            noLengthMatch: {
                visible: showErrorContent
            },
            noCompanyLengthMatch: {
                visible: showNoLengthMatch
            },
            search: {
                disabled: !isComponentValid
            }
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onSearchAccounts: this.searchAccounts,
                getCell: this.getCell,
                startNewQuoteLink: this.startNewQuoteLink,
                onSearchAgain: this.onSearchAgain,
                onCancel: this.handleCancel,
                newCustomerBtnClick: this.newCustomerBtnClick
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                onValidationChange={setComponentValidation}
                onValueChange={this.writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}
const NewQuoteAccountSearch = withModalContext(NewQuoteAccountSearchWithoutModalContext);
export const NewQuoteAccountSearchComponent = NewQuoteAccountSearch;
export default withRouter(withValidation(
    withViewModelService(withAuthenticationContext(NewQuoteAccountSearch)))
);
