import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { DisplayColumn } from '@jutro/legacy/datatable';
import { TranslatorContext, LanguageContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { ServiceManager } from '@jutro/legacy/services';

import { PolicyService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { SelectProducerCode, ProducerContext } from 'gw-gateway-common-react';
import { LOBConstants } from 'wmic-pe-portals-utils-js';

import { WMICFilterBar } from 'wmic-pe-components-platform-react/WMICFilterBar/WMICFilterBar';
import { TypeaheadMultiSelectField } from '@jutro/legacy/components';
import constants from '../../../constants';
import metadata from './WMICDataTableTab.metadata.json5';
import messages from './WMICDataTableTab.messages';
import useAsyncFiltering from '../../hooks/useAsyncFiltering'
import styles from './WMICDataTableTab.module.scss';
import landingPageMessages from '../../WMICLanding.messages'

function WMICDataTableTab({
    handleSearchByValueChange,
    displayColumns,
    loadDataAsync,
    producerCodeValue,
    hideFilters,
    tabHeading
}) {
    const history = useHistory();
    const [availableStatusFilters, setAvailableStatusFilters] = useState({[constants.LOCALE_KEY_ENGLISH]: [], [constants.LOCALE_KEY_FRENCH]: []});
    const [filters, setFilters] = useState(undefined);
    const { authHeader } = useAuthentication();
    const languageContext = useContext(LanguageContext);
    const translator = useContext(TranslatorContext);
    const isOpenSubmissionsTab = landingPageMessages.openSubmissionsTab.id === tabHeading.id;
    const { sessionStorage } = window;
    const { producerCode, setProducerCode } = useContext(ProducerContext);
    const LocaleService = ServiceManager.getService('locale-service');

    useEffect(() => {
        PolicyService.getBOTPolicyStatusOptions(authHeader).then((resp) => {
            // eslint-disable-next-line no-secrets/no-secrets
            // getBOTPolicyStatusOptions response is a single array with english and french status options in one array.
            // As it is always even, we can split it in half and destructure.
            const [englishOptions, frenchOptions] = _.chunk(resp, resp.length / 2);
            setAvailableStatusFilters({
                [constants.LOCALE_KEY_ENGLISH]: englishOptions,
                [constants.LOCALE_KEY_FRENCH]: frenchOptions,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const statusFilterSubmissionsValue = sessionStorage.getItem('statusFilterOpenSubmissions');
        const statusFilterPolicyTransactionsValue = sessionStorage.getItem('statusFilterOpenPolicyTransactions');
        const lobFilterSubmissionsValue = sessionStorage.getItem('lobFilterOpenSubmissions');
        const lobFilterPolicyTransactionsValue = sessionStorage.getItem('lobFilterOpenPolicyTransactions');

        const statusFilterOpenSubmissions = statusFilterSubmissionsValue && statusFilterSubmissionsValue.split(',');
        const statusFilterOpenPolicyTransactions = statusFilterPolicyTransactionsValue && statusFilterPolicyTransactionsValue.split(',');
        const lobFilterOpenSubmissions = lobFilterSubmissionsValue && lobFilterSubmissionsValue.split(',');
        const lobFilterOpenPolicyTransactions = lobFilterPolicyTransactionsValue && lobFilterPolicyTransactionsValue.split(',');
        // Condition to check if atleast one filter has been applied.
        if(statusFilterOpenSubmissions || lobFilterOpenSubmissions || statusFilterOpenPolicyTransactions || lobFilterOpenPolicyTransactions){
            const filtersValue = {
                status: isOpenSubmissionsTab?  statusFilterOpenSubmissions : statusFilterOpenPolicyTransactions,
                productCode: isOpenSubmissionsTab?  lobFilterOpenSubmissions : lobFilterOpenPolicyTransactions
            }
            if (!filtersValue.status) {
                delete filtersValue.status;
            }
            if (!filtersValue.productCode) {
                delete filtersValue.productCode
            }
            setFilters(filtersValue)
        } else {
            setFilters({})
        }
    },[isOpenSubmissionsTab, sessionStorage]);

    const {
        data: asyncTableData,
        isLoading: asyncIsLoading,
        config,
        handleConfigChange,
        totalDataCount
    } = useAsyncFiltering(loadDataAsync, {});

    useEffect(() => {
        // wait until previous filters are loaded from session storage
        if (filters !== undefined) {
            handleConfigChange({page: 0, pageSize: constants.PAGE_SIZE, producerCode: producerCodeValue, filters});
        }
    }, [handleConfigChange, producerCodeValue, filters]);

    useEffect(() => {
        // wait until previous filters are loaded from session storage
        if (filters !== undefined) {
            sessionStorage.setItem('tabHeading', tabHeading.id);
            const statusFilterKey =  isOpenSubmissionsTab ? 'statusFilterOpenSubmissions' : 'statusFilterOpenPolicyTransactions';
            const lobFilterKey = isOpenSubmissionsTab ? 'lobFilterOpenSubmissions' : 'lobFilterOpenPolicyTransactions';

            if (Object.prototype.hasOwnProperty.call(filters,'status')) {
                sessionStorage.setItem(statusFilterKey, filters.status);
            } else {
                sessionStorage.removeItem(statusFilterKey)
            }

            if (Object.prototype.hasOwnProperty.call(filters, 'productCode')) {
                sessionStorage.setItem(lobFilterKey, filters.productCode);
            } else {
                sessionStorage.removeItem(lobFilterKey)
            }
        }
    }, [filters, isOpenSubmissionsTab, sessionStorage, tabHeading.id]);

    useEffect(() => {
        // Get the Currently Selected ProducerCode and Search by code on first load
        handleSearchByValueChange(producerCode);
    }, [handleSearchByValueChange, producerCode]);

    const getLineOfBusinessFilters = useMemo(() => [
            { name: translator(messages.automobileFilter), code: LOBConstants.PA },
            { name: translator(messages.personalPropertyFilter), code: LOBConstants.PP },
            { name: translator(messages.personalUmbrellaFilter), code: LOBConstants.PUP },
            { name: translator(messages.commercialSmallBusinessFilter), code: LOBConstants.CP },
        ], [translator]);

    const StatusFilterDropdown = (props) => (
            <TypeaheadMultiSelectField
                {...props}
                // languageContext is updated on language switching- initially empty, prioritised
                // LocaleService gets the localstorage language- fallback
                availableValues={availableStatusFilters[languageContext.language || LocaleService.getStoredLanguage()]?.map((statusFilterOption) => ({
                    code: statusFilterOption,
                    name: statusFilterOption
                }))}
            />
        )

    const LineOfBusinessFilterDropdown = (props) => (
            <TypeaheadMultiSelectField
                {...props}
                id="lineOfBusinessFilter"
                availableValues={getLineOfBusinessFilters}
            />
        );

    const onRowClick = (rowData) => {
        const rowClickDestination = _.get(rowData, "rowClickDestination");
        if (rowClickDestination) {
            const disableLink = _.get(rowData, "disableLink", false);
            if (!disableLink) {
                history.push(`/${rowData.rowClickDestination}/${rowData.jobNumber}/summary`)
            }
        }
    };

    const renderHeaders = useCallback(() => displayColumns.map((displayColumn) =>
            <DisplayColumn
                key={displayColumn.id}
                sortable={displayColumn.id === 'created'}
                cellClassName='wmicDataTableCell'
                {...displayColumn}
                textAlign="left" />
        )
    , [displayColumns]);

    const override = {
        filterBarComponent: {
            uiProps: !hideFilters && metadata['datatable.filter-bar'],
            onFiltersChange: setFilters,
            initialFilters: filters,
            componentMap: {
                StatusFilterDropdown,
                LineOfBusinessFilterDropdown
            }
        },
        wmicTabTable: {
            data: asyncTableData,
            config,
            onConfigChange: handleConfigChange,
            numberOfRows: totalDataCount,
            content: renderHeaders(),
            onRowClick,
            noDataText : asyncIsLoading ? "" : messages.noDataText
        },
        wmicTableLoadingText: {
            className: cx(styles.wmicTableLoadingText, asyncIsLoading ? styles.isLoading : null)
        }
    };

    const onChangeProducerCodeFilter = (value) => {
        setProducerCode(value);
        handleSearchByValueChange(value);
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeProducerCodeFilter
        },
        resolveComponentMap: {
            WMICFilterBar,
            SelectProducerCode
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={override}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICDataTableTab.propTypes = {
    data: PropTypes.shape({}),
    handleSearchByValueChange: PropTypes.func.isRequired,
    displayColumns: PropTypes.array.isRequired,
    producerCodeValue: PropTypes.string,
    hideFilters: PropTypes.bool
};

WMICDataTableTab.defaultProps = {
    data: {},
    hideFilters: false
};

export default WMICDataTableTab;
