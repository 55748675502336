import React, {useEffect, useState, useContext, useCallback, useMemo} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { useMediaQuery } from 'react-responsive';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { WMICAccordionCard } from 'wmic-components-amp-common-react';
import classNames from 'classnames';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';
import WMICPaymentUtil from 'wmic-portals-utils-js/WMICPaymentUtil';
import metadata from './WMICVehicleInfoBox.metadata.json5';
import messages from './WMICVehicleInfoBox.messages';
import styles from './WMICVehicleInfoBox.module.scss';

const WMICVehicleInfoBox = (props) => {
    const { data } = props;
    const { vehicle: aVehicle, jurisdiction, policyData } = data;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const [paComprehensiveCov, setPaComprehensiveCov] = useState(undefined);
    const [paCollisionCov, setPaCollisionCov] = useState(undefined);
    const [paRentalCov, setPaRentalCov] = useState(undefined);
    const [paUnimspdCov, setPaUnimspdCov] = useState(undefined);
    const [paUmPropertyCovWaiver, setPaUmPropertyCovWaiver] = useState(undefined);
    const [paUnimsCollCov, setPaUnimsCollCov] = useState(undefined);
    const [paUmCollisionCovWaiver, setPaUmCollisionCovWaiver] = useState(undefined);
    const [paSveCov, setPaSveCov] = useState(undefined);
    const [paRoadAssistCovValue, setPaRoadAssistCovValue] = useState(undefined);
    const [paVmrpCov, setPaVmrpCov] = useState(undefined);
    const isMobile = useMediaQuery({ query: '(max-width: 580px)' });
    const [vehicleAccordionsOpnenedIDs, setVehicleAccordionsOpnenedIDs] = useState(undefined);
    const [paComprehensiveAccordionOpenIDs, setPaComprehensiveAccordionOpenIDs] = useState(undefined);
    const [driverAccordionOpenIDs, setDriverAccordionOpenIDs] = useState(undefined);

    const getCoverageByCoverageCode = (vehicle, code) => {
        return _.find(vehicle.coverages, {
            patternCode_WMIC: code
        });
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent,
            WMICAccordionCard,
        }
    };

    const getPCOrMobileRentalExpenseStyle = () => {
        if (isMobile) {
            return 'wmic-rental-expense-top-text-mobile';
        }
        return 'wmic-rental-expense-top-text';
    }

    const getPCOrMobileRentalExpenseBottomStyle = () => {
        if (isMobile) {
            return;
        }
        return 'wmic-rental-expense-bottom-text';
    }

    const getUnimsCovTooltipText = (jur) => {
        switch (jur) {
            case CONSTANTS.JURISDICTIONS.CA:
                return translator(messages.paUnimsCovTooltipCA);
            case CONSTANTS.JURISDICTIONS.OR:
                return translator(messages.paUnimsCovTooltipOR);
            default:
                return translator(messages.paUnimsCovTooltip);
        }
    };

    const primaryUseDisplayName = (primaryUse) => {
        const displayName = primaryUse ? primaryUse.toLowerCase() : '';
        switch (displayName) {
            case CONSTANTS.VEHICLE_PRIMARY_USE.PLEASURE:
                return translator(messages.pleasureUse);
            case CONSTANTS.VEHICLE_PRIMARY_USE.BUSINESS:
                return translator(messages.businessUse);
            case CONSTANTS.VEHICLE_PRIMARY_USE.COMMUTING:
                return translator(messages.commutingUse);
            default:
                return '';
        }
    };

    const renderVehicleTitle = (vehicle) => {
        return (
            <h2 className={styles.vehicleModelTitle}>
                <i className="fas fa-fw fa-car fa-lg" />
                &nbsp;
                {vehicle.year}
                {' '}
                {vehicle.make}
                {' '}
                {vehicle.model}
                {' '}
                (
                {vehicle.vehicleNumber}
                )
            </h2>
        );
    };

    const getRentalExpenseFormatted = () => {
        return (
            <div className={styles['wmic-rental-expense-value']}>
                <div className={classNames(styles[getPCOrMobileRentalExpenseStyle()])}>
                    {`${'$'.concat(translator(paRentalCov?.paRentalExpCovPP))}`}
                </div>
                <div className={classNames(styles[getPCOrMobileRentalExpenseBottomStyle()])}>
                    {`${'$'.concat(translator(paRentalCov?.paRentalExpCovPO))}`}
                </div>
            </div>
        );
    };

    const formattedAmount = (amount) => intl.formatNumber(amount);
    const getMessageWithHeader = (msg) => {
        return(
            <h3 className={styles.vehicleModelSubheader}>{msg}</h3>
        );
    }

    useEffect(() => {
        const compCov = getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_COMPREHENSIVE);
        if (compCov && compCov.terms.length > 0 && compCov.terms[0].amount.toString().toUpperCase() === CONSTANTS.NO_DEDUCTIBLE.toUpperCase()) {
            compCov.terms[0].amount = 0;
        }
        setPaComprehensiveCov(compCov);


        const collCov = getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_COLLISION);
        if (collCov) {
            if (collCov.terms.length > 0 && collCov.terms[0].amount.toString().toUpperCase() === CONSTANTS.NO_DEDUCTIBLE.toUpperCase()) {
                collCov.terms[0].amount = 0;
            }
            if (collCov.deductible.toString().toUpperCase() === CONSTANTS.NO_DEDUCTIBLE.toUpperCase()) {
                collCov.deductible = 0;
            }
        }
        setPaCollisionCov(collCov);

        const rentalCov = getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_RENTAL);
        if (rentalCov && rentalCov.limit.split(' / ').length === 2) {
            const rentalLimits = rentalCov.limit.split(' / ').map((item) => formattedAmount(Number(item)));
            rentalCov.paRentalExpCovPP = `${rentalLimits[0]} ${translator(messages.limitPerDay)}`;
            rentalCov.paRentalExpCovPO = `${rentalLimits[1]} ${translator(messages.limitPerOccurrence)}`;
        }
        setPaRentalCov(rentalCov);

        setPaVmrpCov(getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_VMRP));
        setPaUnimspdCov(getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_UNIMSPD));
        setPaUnimsCollCov(getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_UNIMSCOLL));
        setPaUmCollisionCovWaiver(getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_UMCOLLISIONWAIVER));
        setPaSveCov(getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_SVE));
        setPaRoadAssistCovValue(getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_ROADASSIST));
        setPaUmPropertyCovWaiver(getCoverageByCoverageCode(aVehicle, CONSTANTS.COVERAGE_NAMES.PA_UMPROPERTYWAIVER));
    }, [aVehicle, translator]);

    const driverOverrideProps = useCallback(() => {
        const overrides = aVehicle.drivers_WMIC.map((driver, index) => {
            return {
                [`driverTypeLabel${index}`]: {
                    content: translator({
                        id: `typekey.DriverType_WMIC.${driver.driverType}`,
                        defaultMessage: driver.driverType
                    }),
                },
                [`driverNameLabel${index}`]: {
                    content:  driver.displayName
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [translator, aVehicle]);

    const getRoadAssistCovTooltip = () => {
        if (jurisdiction === CONSTANTS.JURISDICTIONS.OR) {
            const rpcDate = new Date(CONSTANTS.DATE_CONSTANTS.RPC1578_ROADSIDE_ASSIST_LIMIT_INCREASE_DATE);
            const effDate = new Date(policyData.effective);
            if (effDate >= rpcDate) {
                return translator(messages.paRoadsideAssistanceCovTooltipAfterRPC1578);
            }
        }

        return translator(messages.paRoadsideAssistanceCovTooltip);
    };

    const totalPremiumCurrencyAmount = useMemo(() => {
        return WMICPaymentUtil.getShapedCurrencyAmount(aVehicle.totalPremimum_WMIC);
    }, [aVehicle]);

    const overrideProps = {
        vehicleInfoBoxTitle: {
            content: renderVehicleTitle(aVehicle)
        },
        paComprehensiveAccordionCard: {
            title: getMessageWithHeader(translator(messages.includedVehicleCoverage))
        },
        vehicleAccordionCard: {
            title: getMessageWithHeader(translator(messages.vehicleDetails))
        },
        driverAccordionCard: {
            visible: !!(aVehicle.drivers_WMIC && aVehicle.drivers_WMIC.length > 0),
            title: getMessageWithHeader(translator(messages.assignedDrivers))
        },
        primaryUseTooltip: {
            tooltipMessage: translator(messages.primaryUseTooltip)
        },
        primaryUseValue: {
            content: primaryUseDisplayName(aVehicle.primaryUse_WMIC)
        },
        annualMileageValue: {
            content: `${formattedAmount(aVehicle.annualMileage_WMIC)} ${translator(messages.mi)}`
        },
        lastOdometerReadingDateContainer: {
            visible: Boolean(aVehicle.odometerReadingDate_WMIC)
        },
        lastOdometerReadingContainer: {
            visible: aVehicle.odometerReading_WMIC !== 0
        },       
        lastOdometerReadingValue: {
            content: `${formattedAmount(aVehicle.odometerReading_WMIC)} ${translator(messages.mi)}`
        },
        lastOdometerReadingDateValue: {
            content: intl.formatDate(new Date(aVehicle.odometerReadingDate_WMIC), { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'US/Central' })
        },
        vehiclePremiumTooltip: {
            tooltipMessage: translator(messages.vehiclePremiumTooltip)
        },
        vehiclePremiumCurrencyValue: {
            value: totalPremiumCurrencyAmount,
            showFractions: true
        },
        paComprehensiveCovContainer: {
            visible: !!paComprehensiveCov
        },
        paComprehensiveCovTooltip: {
            tooltipMessage: translator(messages.paComprehensiveCovTooltip)
        },
        paComprehensiveCovValue: {
            content: `$${paComprehensiveCov?.terms[0].amount} ${paComprehensiveCov?.terms[0].modelType}`
        },
        paCollisionCovContainer: {
            visible: !!paCollisionCov
        },
        paCollisionCovTooltip: {
            tooltipMessage: translator(messages.paCollisionCovTooltip)
        },
        paCollisionCovValue: {
            content: `$${paCollisionCov?.terms[0].amount} ${paCollisionCov?.terms[0].modelType}`
        },
        paUnimsCovContainer: {
            visible: !!paUnimspdCov
        },
        paUnimsCovTooltip: {
            tooltipMessage: getUnimsCovTooltipText(jurisdiction)
        },
        paUnimsCovValue: {
            content: `$${paUnimspdCov?.limit} ${translator(messages.limit)}`
        },
        paUmPropertyCovWaiverContainer: {
            visible: !!paUmPropertyCovWaiver && jurisdiction === CONSTANTS.JURISDICTIONS.CA
        },
        paUmPropertyCovWaiverTooltip: {
            tooltipMessage: translator(messages.paUnimsCovTooltipCA)
        },
        paUnimsCollCovGrid: {
            visible: !!paUnimsCollCov
        },
        paUnimsCollCovTooltip: {
            tooltipMessage: translator(messages.paUnimsCollTooltip)
        },
        paUnimsCollCovValue: {
            content: `$0 ${translator(messages.deductible)}`
        },
        paUmCollisionCovWaiverContainer: {
            visible: !!paUmCollisionCovWaiver
        },
        paUmCollisionCovWaiverTooltip: {
            tooltipMessage: translator(messages.paUnimsCollTooltip)
        },
        paUmCollisionCovWaiverValue: {
            content: `$${paCollisionCov?.deductible} ${translator(messages.deductible)}`
        },
        paRentalCovContainer: {
            visible: !!paRentalCov
        },
        paRentalCovTooltip: {
            tooltipMessage: translator(messages.paRentalExpenseCovTooltip)
        },
        paRentalCovValue: {
            content: paRentalCov?.paRentalExpCovPP && paRentalCov?.paRentalExpCovPO ? getRentalExpenseFormatted() : paRentalCov?.limit
        },
        paSveCovContainer: {
            visible: !!paSveCov
        },
        paSveCovTooltip: {
            tooltipMessage: translator(messages.paSpecialVehicleEquipmentCoverageCovTooltip)
        },
        paSveCovValue: {
            content: `${paSveCov?.limit} ${translator(messages.limit)}`
        },
        paRoadAssistCovContainer: {
            visible: !!paRoadAssistCovValue
        },
        paRoadAssistCovTooltip: {
            tooltipMessage: getRoadAssistCovTooltip()
        },
        paVmrpCovContainer: {
            visible: !!paVmrpCov
        },
        paVmrpCovContainerTooltip: {
            tooltipMessage: translator(messages.paVehicleManufacturerReplacementPartsCovTooltip)
        },
        vehicleInfoBoxContainerMobile: {
            visible: isMobile
        },
        vehicleInfoBoxContainer: {
            visible: !isMobile
        },
        paUmPropertyCovWaiverLabel: {
        },
        paComprehensiveAccordion: {
            accordionStates: paComprehensiveAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setPaComprehensiveAccordionOpenIDs(openCardID)} 
        },
        vehicleAccordion: {
            accordionStates: vehicleAccordionsOpnenedIDs,
            onUpdateAccordionStates: (openCardID) => { setVehicleAccordionsOpnenedIDs(openCardID)}
        },
        driverAccordion: {
            accordionStates: driverAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setDriverAccordionOpenIDs(openCardID)}
        },
        ...driverOverrideProps()
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
            model={aVehicle}
        />
    );
};

WMICVehicleInfoBox.propTypes = {
    data: PropTypes.shape({
        vehicle: PropTypes.array,
        jurisdiction: PropTypes.string,
        policyData: PropTypes.shape({})
    }).isRequired
};

export default WMICVehicleInfoBox;