import * as models from '../models';
import messages from './Services.messages';

const stateKey = 'pc_current_state';

// converts a JSON Object to it's respective Object Model
// (ex.: RoadsideAssistanceModel, ChangeAddressModel)
const convertToObjectModel = () => {
    const state = this.currentState;
    if (!state.uniqueChangeRequests) {
        return;
    }

    Object.entries(state.uniqueChangeRequests).forEach(([, val]) => {
        Object.values(val).forEach((value) => {
            if (!value.objectType) {
                throw new Error(messages.modelShouldImplementObjectType, value);
            }
            const objectType = models.default[value.objectType];
            if (!objectType) {
                throw new Error(messages.noModelFound, value.objectType);
            }

            // eslint-disable-next-line no-prototype-builtins
            if (objectType.hasOwnProperty('toObjectModel')) {
                if (Array.isArray(value.changeModel)) {
                    value.changeModel.map((v) => objectType.toObjectModel(v));
                    return;
                }
                objectType.toObjectModel(value.changeModel);
            } else {
                if (Array.isArray(value.changeModel)) {
                    value.changeModel.map((v) => Object.setPrototypeOf(v, objectType.prototype));
                    return;
                }
                Object.setPrototypeOf(value.changeModel, objectType.prototype);
            }
        });
    });
};

// As a good practice when using WMICPolicyChangeStateService,
// remember to call clearState when executing $onDestroy()
export default class WMICPolicyChangeStateService {
    constructor(props) {
        this.currentState = props.currentState;
        this.init();
    }

    static init() {
        if (this.currentState === undefined) {
            const sessionStorageState = window.sessionStorage.getItem(stateKey);

            if (sessionStorageState) {
                this.updateCurrentState(JSON.parse(sessionStorageState));
                convertToObjectModel();
            }

            if (this.currentState === undefined) {
                this.updateCurrentState({ uniqueChangeRequests: {}, changeRequests: [] });
            }
        }
        if (this.currentState !== undefined
            && this.currentState.uniqueChangeRequests === undefined) {
            this.updateCurrentState({ uniqueChangeRequests: {} });
        }
    }

    static updateCurrentState(currState) {
        this.currentState = currState;
    }

    static getState(item = undefined) {
        this.init();
        const state = this.currentState;
        return item ? state[item] : state;
    }

    static addState(newState) {
        this.updateCurrentState(Object.assign({}, this.currentState, newState));
    }

    static clearState() {
        this.updateCurrentState({});
    }

    static clearChangeRequest() {
        this.init();
        const state = this.currentState;
        if (state.uniqueChangeRequests) {
            state.uniqueChangeRequests = undefined;
            this.updateCurrentState(state);
        }
    }

    static addChangeRequest(key, id, changeRequest) {
        this.init();
        const state = this.currentState;
        if (!state.uniqueChangeRequests[key]) {
            const obj = {};
            obj[key] = {};
            obj[key][id] = changeRequest;
            state.uniqueChangeRequests = Object.assign({}, state.uniqueChangeRequests, obj);
        } else {
            state.uniqueChangeRequests[key][id] = Object.assign(
                {},
                state.uniqueChangeRequests[key][id],
                changeRequest
            );
        }
        this.updateCurrentState(state);
    }

    static getChangeRequest(key, id) {
        this.init();
        const state = this.currentState;
        if (id) {
            return state.uniqueChangeRequests[key]
                ? state.uniqueChangeRequests[key][id]
                : null;
        }
        return state.uniqueChangeRequests[key];
    }

    static removeChangeRequest(key, id) {
        this.init();
        const state = this.currentState;
        if (!state.uniqueChangeRequests[key]) {
            return;
        }

        if (id) {
            if (state.uniqueChangeRequests[key][id]) {
                state.uniqueChangeRequests[key][id] = undefined;
                if (Object.keys(state.uniqueChangeRequests[key]).length === 0) {
                    state.uniqueChangeRequests[key] = undefined;
                }
                this.updateCurrentState(state);
            }
            return;
        }

        state.uniqueChangeRequests[key] = undefined;
        this.updateCurrentState(state);
    }

    static getChangeRequests() {
        this.init();
        const state = this.currentState;
        const result = state.changeRequests || [];
        if (!state.uniqueChangeRequests) {
            return result;
        }

        return Object.entries(state.uniqueChangeRequests)
            // eslint-disable-next-line no-unused-vars
            .reduce((array, [k, v]) => array.concat(Object.values(v)), result);
    }
}
