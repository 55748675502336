import _ from "lodash";

export default {
    move: (oldIndex, newIndex, array) => {
        if (oldIndex >= 0 && oldIndex < array.length) {
            if (newIndex >= array.length) {
                let k = newIndex - array.length;
                // eslint-disable-next-line no-plusplus
                while ((k--) + 1) {
                    array.push(undefined);
                }
            }
            array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
        }
    },
    mergeByProperty: (array1, array2, prop) => {
        _.each(array2, (array2Item) => {
            const foundArray1Object = _.find(array1, (array1Item) => {
                return array1Item[prop] === array2Item[prop];
            });

            foundArray1Object ? _.extend(foundArray1Object, array2Item) : array1.push(array2Item);
        });
    }
};
