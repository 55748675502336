// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-loader-wrapper-large-min-height:4.4em;--gw-loader-wrapper-large-min-width:1.2em}.app__PolicyLevelCoverages__claimCoverageStyle{margin:0 auto var(--GW-LAYOUT-1) 0}.app__PolicyLevelCoverages__gwInlineBackNav{margin-bottom:var(--GW-LAYOUT-6)}.app__PolicyLevelCoverages__gwInlineBackNav .app__PolicyLevelCoverages__policyTitle{margin-bottom:0}.app__PolicyLevelCoverages__gwInlineBackNav .app__PolicyLevelCoverages__gwBackToClaim{justify-self:start}.app__PolicyLevelCoverages__policySectionStyle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:var(--GW-SPACING-10);padding-bottom:var(--GW-SPACING-5)}.app__PolicyLevelCoverages__gwInlineBackNav{position:relative}.app__PolicyLevelCoverages__backToHomeImage{border:0;height:auto;max-width:100%;vertical-align:top;vertical-align:middle}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-claim-react/pages/PolicyLevelCoverages/PolicyLevelCoverages.module.scss"],"names":[],"mappings":"AAAA,MACC,0CAAA,CACA,yCAAA,CAGD,+CACC,kCAAA,CAGD,4CACC,gCAAA,CACA,oFACC,eAAA,CAED,sFACC,kBAAA,CAIF,+CAEC,iEAAA,CACA,kCAAA,CAFA,kCAEA,CAGD,4CACC,iBAAA,CAGD,4CAKC,QAAA,CAFA,WAAA,CADA,cAAA,CADA,kBAAA,CAGA,qBACA","sourcesContent":[":root {\n\t--gw-loader-wrapper-large-min-height: 4.4em;\n\t--gw-loader-wrapper-large-min-width: 1.2em;\n}\n\n.claimCoverageStyle {\n\tmargin: 0 auto var(--GW-LAYOUT-1) 0px;\n}\n\n.gwInlineBackNav {\n\tmargin-bottom: var(--GW-LAYOUT-6);\n\t.policyTitle {\n\t\tmargin-bottom: 0;\n\t}\n\t.gwBackToClaim {\n\t\tjustify-self: start;\n\t}\n}\n\n.policySectionStyle {\n\tpadding-bottom: var(--GW-SPACING-5);\n\tborder-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n\tmargin-bottom: var(--GW-SPACING-10);\n}\n\n.gwInlineBackNav {\n\tposition: relative;\n}\n\n.backToHomeImage {\n\tvertical-align: top;\n\tmax-width: 100%;\n\theight: auto;\n\tvertical-align: middle;\n\tborder: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"claimCoverageStyle": "app__PolicyLevelCoverages__claimCoverageStyle",
	"gwInlineBackNav": "app__PolicyLevelCoverages__gwInlineBackNav",
	"policyTitle": "app__PolicyLevelCoverages__policyTitle",
	"gwBackToClaim": "app__PolicyLevelCoverages__gwBackToClaim",
	"policySectionStyle": "app__PolicyLevelCoverages__policySectionStyle",
	"backToHomeImage": "app__PolicyLevelCoverages__backToHomeImage"
};
export default ___CSS_LOADER_EXPORT___;
