import { defineMessages } from 'react-intl';

export default defineMessages({
    selectAPolicyChange: {
        id: 'wmic.policy-change.component.coverage-type-selector.Select a Policy Change',
        defaultMessage: 'Select a Policy Change'
    },
    editPolicySelfService: {
        id: 'wmic.policy-change.component.coverage-type-selector.Comprehensive Deductible, CollisionDeductible, Rental Expense',
        defaultMessage: 'Comprehensive Deductible, Collision Deductible, Rental Expense'
    },
    policyChangeSelectTrackButtonIdentifier: {
        id: 'wmic.policy-change.component.coverage-type-selector.tracking.Policy Change Select',
        defaultMessage: 'Policy Change - Select - {selectedChange}'
    },
    editUninsuredOR: {
        id: 'wmic.policy-change.component.coverage-type-selector.Uninsured Motorist Property Damage, Vehicle Manufacturer Replacement Parts',
        defaultMessage: 'Uninsured Motorist Property Damage, Vehicle Manufacturer Replacement Parts'
    },
    editUninsured: {
        id: 'wmic.policy-change.component.coverage-type-selector.Uninsured Motorist Property Damage, Uninsured Collision Deductible Waiver, Vehicle Manufacturer Replacement Parts',
        defaultMessage: 'Uninsured Motorist Property Damage, Uninsured Collision Deductible Waiver, Vehicle Manufacturer Replacement Parts'
    },
    bodilyInjuryOR: {
        id: 'wmic.policy-change.component.coverage-type-selector.Bodily Injury Liability, Property Damage Liability, Uninsured Motorist Bodily Injury',
        defaultMessage: 'Bodily Injury Liability, Property Damage Liability, Uninsured Motorist Bodily Injury'
    },
    bodilyInjury: {
        id: 'wmic.policy-change.component.coverage-type-selector.Bodily Injury Liability, Property Damage Liability, Uninsured/Underinsured Motorist Bodily Injury, Medical Payments Coverage',
        defaultMessage: 'Bodily Injury Liability, Property Damage Liability, Uninsured/Underinsured Motorist Bodily Injury, Medical Payments Coverage'
    },
    pendingPolicyChange: {
        id: 'wmic.policy-change.component.coverage-type-selector.You have a pending policy change that will occur on (date)',
        defaultMessage: 'You have a pending policy change that will occur on {date}. Online policy changes are temporarily unavailable, until {nextDay}, when your pending change will be completed. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    pendingPolicyChangeTempUnavailable: {
        id: 'wmic.policy-change.component.coverage-type-selector.You have a pending policy change. Online policy changes are temporarily unavailable...',
        defaultMessage: 'You have a pending policy change. Online policy changes are temporarily unavailable, until your pending change is completed. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    policyAboutToRenew: {
        id: 'wmic.policy-change.component.coverage-type-selector.Your policy is about to renew on (date) and online policy changes are temporarily unavailable...',
        defaultMessage: 'Your policy is about to renew on {date} and online policy changes are temporarily unavailable until your policy renewal. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    policyAboutToRenewTempUnavailable: {
        id: 'wmic.policy-change.component.coverage-type-selector.Your policy is about to renew. Online policy changes are temporarily unavailable until your policy is renewed...',
        defaultMessage: 'Your policy is about to renew. Online policy changes are temporarily unavailable until your policy is renewed. Please call us if you’d like to make a policy change and our agents can assist you.'
    },
    policyChangeCannotBeStarted: {
        id: 'wmic.policy-change.component.coverage-type-selector.Policy Change cannot be started',
        defaultMessage: 'Policy Change cannot be started.'
    },
    youCanReachUsAt: {
        id: 'wmic.policy-change.component.coverage-type-selector.You can reach us at 1-800-640-2920...',
        defaultMessage: 'You can reach us by phone at &lt;a href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday to Friday 7:30 am to 7:30 pm PT and Saturday 8:00 am to 4:30 pm PT.'
    },
    delinquencyModalTitle: {
        id: 'wmic.policy-change.component.coverage-type-selector.Policy Changes Are Temporarily Unavailable',
        defaultMessage: 'Policy Changes Are Temporarily Unavailable'
    },
    delinquencyModalBody: {
        id: 'wmic.policy-change.component.coverage-type-selector.Payment is due on your policy in the amount of (amount). Please make your payment below...',
        defaultMessage: 'Payment is due on your policy in the amount of {amount}. Please make your payment below or give us a call. Online policy changes are temporarily unavailable until payment is made.'
    },
    close: {
        id: 'wmic.policy-change.component.coverage-type-selector.Close',
        defaultMessage: 'Close'
    },
    pendingPolicyChanges: {
        id: 'wmic.policy-change.component.coverage-type-selector.You have pending policy changes',
        defaultMessage: 'You have pending policy changes'
    },
    previouslySubmittedChanges: {
        id: 'wmic.policy-change.component.coverage-type-selector.Weve detected you have previously submitted policy changes',
        defaultMessage: 'We\'ve detected you have previously submitted policy changes. Please note some changes can take a few business days to complete and must be processed manually by an agent.'
    }
});
