import { defineMessages } from 'react-intl';

export default defineMessages({
    needHelp: {
        id: 'quoteandbind.views.contact-us.Need Help?',
        defaultMessage: 'Need Help?',
    },
    quickLinks: {
        id: 'quoteandbind.views.contact-us.Quick Links',
        defaultMessage: 'Quick Links',
    },
    rentersCoverage: {
        id: 'quoteandbind.views.contact-us.Renters Coverage',
        defaultMessage: 'Renters Coverage',
    },
    rentersDiscounts: {
        id: 'quoteandbind.views.contact-us.Renters Discounts',
        defaultMessage: 'Renters Discounts',
    },
    rentersInsurance: {
        id: 'quoteandbind.views.contact-us.Renters Insurance - FAQ',
        defaultMessage: 'Renters Insurance - FAQ',
    },
    callForHelp: {
        id: 'quoteandbind.views.contact-us.Call',
        defaultMessage: '&lt;a href="tel:{phoneNumberRaw}"&gt;&lt;i class="fa fa-phone fa-2x"&gt;&lt;/i&gt;Call {phoneNumber}&lt;/a&gt;'
    }
});
