import React, { useCallback, useContext, useMemo, useEffect, useState } from "react";
import { useAuthentication } from 'wmic-digital-auth-react';
import _ from "lodash";
import { useTranslator } from "@jutro/locale";
import PropTypes from "prop-types";
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import messages from "./WMICEditPhone.messages";
import metadata from "./WMICEditPhone.metadata.json5";
import styles from "./WMICEditPhone.module.scss";
import {WMICAmpUtil} from "wmic-portals-utils-js";

const DISABLED_PHONE_TYPES = ['nophone_wmic'];

function WMICEditPhone(props) {
    const translator = useTranslator();
    const { onValidate, isComponentValid, onValueChange } = props;
    const { authHeader, userInfo: authUserData} = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [contactSummary, setContactSummary] = useState({})

    useEffect(() => {
        const makeApiCall = async () => {
            const getContactsSummary = await WMICAmpUtil.getAccountContact(authHeader, authUserData);
            const submission = viewModelService.create(
                getContactsSummary,
                'pc',
                'wmic.edge.us.capabilities.profileinfo.user.dto.AccountSummaryDTO'
            );

            setContactSummary(submission);
        }

        makeApiCall();
    },[viewModelService, authHeader]);

    const phoneType = useMemo(() => {
        return contactSummary.accountContact ? contactSummary.accountContact.primaryPhoneType.value.code : null;
    }, [contactSummary]);

    const optionsList = useMemo(() => {
        const options = _.get(
            contactSummary,
            "accountContact.primaryPhoneType.aspects.availableValues",
            []
        ).map((typeCode) => {
            return {
                code: typeCode.code,
                name: translator({
                    id: typeCode.name,
                    defaultMessage: typeCode.name,
                }),
                disabled: DISABLED_PHONE_TYPES.includes(typeCode.code)
            };
        });
        return options;
    }, [translator, contactSummary]);

    const writeValue = useCallback(
        (value, path) => {
            const newContactSummary = _.clone(contactSummary);
            _.set(newContactSummary, path, value);
            setContactSummary(newContactSummary);
            onValueChange(newContactSummary.accountContact.value)
        },
        [contactSummary, onValueChange]
    );

    const onPrimaryChange =  (event) => {
        const availablePhoneTypeValues = _.get(
            contactSummary,
            "accountContact.primaryPhoneType.aspects.availableValues",
            []
        )
        const newPrimaryPhoneType = _.find(availablePhoneTypeValues, {
            code: event.target.value,
        });
        writeValue(newPrimaryPhoneType, "accountContact.primaryPhoneType.value");
    }


    const getPrimaryDropdownContent = () => 
        // this rule is deprecated, but we still have to tell eslint to ignore it
        // eslint-disable-next-line jsx-a11y/no-onchange
        <select className={styles.primaryPhoneSelect} onChange={onPrimaryChange}>
            {
                !phoneType && 
                    <option value disabled selected>
                        {translator({
                            id: "common.components.wmic-dropdown.Please Select",
                            defaultMessage: "Dropwdown Placeholder",
                        })}
                    </option>
            }
            {
                optionsList.map((option) => 
                    <option 
                        key={option.code} 
                        value={option.code} 
                        disabled={option.disabled} 
                        selected={option.code === phoneType}
                    >
                        {option.name}
                    </option>)
            }
        </select>

    const getPhonePropsOverride = (getPhoneType) => {
        const isRequired = phoneType === getPhoneType;
        return {
            required: isRequired,
            showRequired: isRequired,
            messageProps: {
                invalidPhone: translator(messages.phoneValidationError),
                incompleteInput: translator(messages.phoneValidationError),
            },
        };
    };

    const overrideProps = {
        primaryPhoneField: {
            content: getPrimaryDropdownContent(),
        },
        homeNumberField: getPhonePropsOverride("home"),
        workNumberField: getPhonePropsOverride("work"),
        mobileNumberField: getPhonePropsOverride("mobile"),
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={contactSummary.accountContact}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={!isComponentValid}
        />
    );
}

WMICEditPhone.propTypes = {
    onValidate: PropTypes.func.isRequired,
    isComponentValid: PropTypes.bool.isRequired,
    onValueChange: PropTypes.func.isRequired,
};

export default WMICEditPhone;
