/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, {
    useContext, Fragment, useState, useEffect, useCallback
} from 'react';
import {
    ModalBody, ModalFooter
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WMICLoader } from 'gw-components-platform-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICPaymentService, WMICFeatureFlagUtil, WMICRichTextUtil, WMICBillingUtil } from 'wmic-portals-utils-js';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import { WMICCheckbox, WMICButton } from 'wmic-components-platform-react';
import WMICBankAccount from './WMICBankAccount';
import messages from './WMICManageBankingInformation.messages';
import styles from './WMICManageBankingInformation.module.scss';

const WMICManageBankingInformation = (props) => {
    const {
        accountInfo,
        bankAccountInfo,
        mode,
        onBack,
        onCloseAfterAction,
        applicationFlow,
        policyNumber,
        ldFlags
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const DOM_ELEMENT_IDS = {
        IS_PRIMARY_TOOLTIP: 'isPrimaryTooltip',
        NOT_PRIMARY_TOOLTIP: 'notPrimaryTooltip',
        MODAL_TOOLTIP_ID: 'modalTooltipId',
        AGREE_BANKING_INFO_STORAGE_CHECKBOX: 'agreeBankingInfoStorage',
    };
    const DISPLAY_MODES = {
        SHOW_BANK_ACCOUNTS: 'showBankAccounts',
        EDIT_BANK_ACCOUNT: 'editBankAccount',
        DELETE_BANK_ACCOUNT: 'deleteBankAccount',
        BANK_ACCOUNT_DELETE_SUCCESS: 'bankAccountDeleteSuccess',
        BANK_ACCOUNT_DELETE_FAILURE: 'bankAccountDeleteFailure',
        BANK_ACCOUNT_EDIT_INVALID_ROUTING: 'bankAccountEditInvalidRouting',
        BANK_ACCOUNT_EDIT_SUCCESS: 'bankAccountEditSuccess',
        BANK_ACCOUNT_EDIT_FAILURE: 'bankAccountEditFailure',
        BANK_ACCOUNT_EDIT_DUPLICATE: 'bankAccountEditDuplicate',
    };
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [bankAccountData, updateBankAccountData] = useState(bankAccountInfo);
    const [bankAccounts, updateBankAccounts] = useState([]);
    const [bankAccountsLoading, updateBankAccountsLoading] = useState(false);
    const [primaryAccountChanged, updatePrimaryAccountChanged] = useState(undefined);
    const [primaryAccountChangedError, updatePrimaryAccountChangedError] = useState(undefined);
    const [errorMessageText, updateErrorMessageText] = useState(undefined);
    const [agreeBankingInfoStorage, updateAgreeBankingInfoStorage] = useState(false);
    const [isFormValid, updateIsFormValid] = useState(false);
    const [displayMode, updateDisplayMode] = useState(mode || DISPLAY_MODES.SHOW_BANK_ACCOUNTS);
    const viewModelService = useContext(ViewModelServiceContext);

    const getBankAccounts = (callback) => {
        updateBankAccountsLoading(true);
            const paymentInstrumentTokenSummaryPromise = AccountBillingDetailsService.getPaymentInstrumentTokenSummary(
                accountInfo.accountNumber,
                accountInfo.publicId,
                null,
                authHeader
            );
            paymentInstrumentTokenSummaryPromise.then((oneIncPaymentInstruments) => {
                const bankAccountTypelist = viewModelService.productMetadata.get('bc').types.getTypelist('BankAccountType');
                const creditCardTypelist =  viewModelService.productMetadata.get('bc').types.getTypelist('CreditCardType');
                const filteredPaymentInstruments = WMICBillingUtil.filterPaymentInstruments(oneIncPaymentInstruments.paymentInstrumentTokens, 'eft');
                const bankAccts = bankAccounts;
                _.each(filteredPaymentInstruments, (paymentInstrumentToken) => {
                    if (paymentInstrumentToken.token !== undefined && paymentInstrumentToken.token !== '') {
                        const bankAccountTypeName = paymentInstrumentToken.bankAccountType ? bankAccountTypelist.getCode(paymentInstrumentToken.bankAccountType) : undefined;
                        const creditCardTypeName = paymentInstrumentToken.creditCardType ? creditCardTypelist.getCode(paymentInstrumentToken.creditCardType) : undefined;
                        const bankRecord = {
                            token: paymentInstrumentToken.token,
                            bankAccountNumber: paymentInstrumentToken.lastFour,
                            bankAccountType: paymentInstrumentToken.bankAccountType,
                            bankName: paymentInstrumentToken.bankName,
                            publicId: paymentInstrumentToken.publicID,
                            forMarPolicy: paymentInstrumentToken.forMarPolicy,
                            paymentMethodTypeName: undefined,
                            lastFour: paymentInstrumentToken.lastFour
                        }
                        if (bankAccountTypeName) {
                            bankRecord.paymentMethodTypeName = bankAccountTypeName.name;
                        } else if (creditCardTypeName) {
                            bankRecord.paymentMethodTypeName = creditCardTypeName.name;
                        }
                        bankAccts.push(bankRecord);
                    }
                });
                if (callback) {
                    callback(bankAccts.length);
                }
                updateBankAccounts(bankAccts);
            }).finally(() => {
                updateBankAccountsLoading(false);
            });
        
    };

    const resetForm = () => {
        updateAgreeBankingInfoStorage(false);
        updatePrimaryAccountChangedError(undefined);
        updateIsFormValid(false);
        updatePrimaryAccountChanged(undefined);
        updateBankAccountData(undefined);
    };

    const editBankingInformation = (bankInfo) => {
        resetForm();
        updateBankAccountData(bankInfo);
        updateDisplayMode(DISPLAY_MODES.EDIT_BANK_ACCOUNT);
        updateBankAccountsLoading(false);
    };

    const deleteBankingInformation = (bankInfo) => {
        resetForm();
        updateBankAccountData(bankInfo);
        updateDisplayMode(DISPLAY_MODES.DELETE_BANK_ACCOUNT);
        updateBankAccountsLoading(false);
    };

    useEffect(() => {
        updateBankAccountsLoading(true);
        if (mode === DISPLAY_MODES.EDIT_BANK_ACCOUNT) {
            editBankingInformation(bankAccountInfo);
        } else if (mode === DISPLAY_MODES.DELETE_BANK_ACCOUNT) {
            deleteBankingInformation(bankAccountInfo);
        } else {
            getBankAccounts();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getModalHeader = () => {
        switch (displayMode) {
            case DISPLAY_MODES.EDIT_BANK_ACCOUNT:
                return translator(messages.editBankAccount);
            case DISPLAY_MODES.BANK_ACCOUNT_EDIT_SUCCESS:
                return translator(messages.bankAccountSaved);
            case DISPLAY_MODES.BANK_ACCOUNT_EDIT_FAILURE:
            case DISPLAY_MODES.BANK_ACCOUNT_EDIT_DUPLICATE:
                if (!errorMessageText?.title) {
                    return translator(messages.thereWasAnErrorTitle);
                }
                return errorMessageText?.title;
            case DISPLAY_MODES.BANK_ACCOUNT_EDIT_INVALID_ROUTING:
                return translator(messages.invalidRoutingTransitNumber);
            case DISPLAY_MODES.DELETE_BANK_ACCOUNT:
                const deleteMessage = translator(messages.deletePaymentMethod)
                return deleteMessage;
            case DISPLAY_MODES.BANK_ACCOUNT_DELETE_SUCCESS:
                const confirmDeleteMessage = translator(messages.paymentMethodDeleted);
                return confirmDeleteMessage;
            case DISPLAY_MODES.BANK_ACCOUNT_DELETE_FAILURE:
                return translator(messages.deletionUnsuccessful);
            default:
                return translator(messages.managePaymentMethods);
        }
    };

    const getAccountTypeText = (type) => {
        switch (type) {
            case 'checking':
                return translator(messages.checking);
            case 'savings':
                return translator(messages.savings);
            default:
                return '';
        }
    };

    const setErrorMessageForBankAccount = () => {
        updateBankAccountsLoading(false);
        updatePrimaryAccountChangedError(translator(messages.errorSavingBankingInfo))
    }

    const setIsPrimary = (bankInfo) => {
        AccountBillingDetailsService.editBankingInfoWithConsentType(
            accountInfo?.accountNumber, bankInfo, applicationFlow, policyNumber, authHeader
        ).then((res) => {
            if (res.result) {
                getBankAccounts(() => {
                    updatePrimaryAccountChangedError(undefined);
                });
            } else {
                setErrorMessageForBankAccount();
            }
        },
        () => {
            setErrorMessageForBankAccount();
        });
    };

    const setAsPreferredBankingInformation = (bankInfo) => {
        updateBankAccountsLoading(true);
        updatePrimaryAccountChanged(bankInfo);
        WMICPaymentService.validateABA(
            bankInfo?.bankABANumber
        ).then((response) => response.json()).then((response) => {
            if (response?.ABAResult === 'true') {
                const bankName = bankInfo?.bankName && bankInfo.bankName.slice(-1) === '.'
                    ? response.ABABankName
                    : `${response.ABABankName || ''}.`;
                _.set(bankInfo, 'bankName', bankName);
                setIsPrimary({
                    bankABANumber: bankInfo.bankABANumber,
                    bankAccountNumber: 0,
                    bankAccountType: bankInfo.bankAccountType,
                    bankName: bankInfo.bankName,
                    nickName_WMIC: bankInfo.nickName_WMIC,
                    isPrimary_WMIC: true,
                    publicId_WMIC: bankInfo.publicId_WMIC
                });
            } else {
                updateBankAccountsLoading(false);
                updatePrimaryAccountChangedError(translator(messages.routingTransitNumberInvalid));
            }
        }, () => {
            setErrorMessageForBankAccount();
        });
    };

    const isSetPrimary = (bankInfo) => {
        return primaryAccountChanged
            && bankInfo.bankABANumber === primaryAccountChanged.bankABANumber
            && bankInfo.nickName_WMIC === primaryAccountChanged.nickName_WMIC
            && bankInfo.bankAccountType === primaryAccountChanged.bankAccountType;
    };

    const onClose = () => {
        if (displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_INVALID_ROUTING
            || displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_DUPLICATE) {
            updateDisplayMode(DISPLAY_MODES.EDIT_BANK_ACCOUNT);
        } else if (typeof onCloseAfterAction === 'function') {
            resetForm();
            onCloseAfterAction();
        } else {
            resetForm();
            updateDisplayMode(DISPLAY_MODES.SHOW_BANK_ACCOUNTS);
            getBankAccounts((bankAccountCount) => {
                if (bankAccountCount === 0) {
                    onBack();
                }
            });
        }
    };

    const onCloseAfterDelete = () => {
        resetForm();
        if (typeof onCloseAfterAction === 'function') {
            onCloseAfterAction();
        } else {
            getBankAccounts((bankAccountCount) => {
                if (bankAccountCount > 0) {
                    updateDisplayMode(DISPLAY_MODES.SHOW_BANK_ACCOUNTS);
                } else {
                    onBack();
                }
            });
        }
    };

    const saveBankingInfo = () => {
        // don't save the bank account unless valid
        const bankAccData = { ...bankAccountData };
        const bankAccountNumber = bankAccountData?.bankAccountNumber > 0
            ? bankAccountData.bankAccountNumber
            : 0;
        _.set(bankAccData, 'bankAccountNumber', bankAccountNumber);
        AccountBillingDetailsService.editBankingInfoWithConsentType(
            accountInfo?.accountNumber, bankAccData, applicationFlow, policyNumber, authHeader
        ).then((res) => {
            if (!res.isDuplicate && !res.nickNameExists && res.result) {
                updateBankAccountData(undefined);
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_EDIT_SUCCESS);
            } else if (!res.result) {
                updateErrorMessageText(translator(messages.errorSavingBankingInfo));
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_EDIT_FAILURE);
            } else {
                const errorMessage = res.isDuplicate ? {
                    title: translator(messages.bankAccountExistsTitle),
                    text: translator(messages.bankAccountExistsText)
                } : {
                    title: translator(messages.bankAccountNicknameExists),
                    text: translator(messages.bankAccountNicknameExistsText)
                };
                updateErrorMessageText(errorMessage);
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_EDIT_DUPLICATE);
            }
        }).finally(() => {
            updateBankAccountsLoading(false);
        });
    };

    const submitBankingInfo = () => {
        updateBankAccountsLoading(true);
        resetForm();
        WMICPaymentService.validateABA(bankAccountData?.bankABANumber)
            .then((response) => response.json())
            .then((response) => {
                if (response?.ABAResult === 'true') {
                    const bankName = bankAccountData?.bankName && bankAccountData.bankName.slice(-1) === '.'
                        ? response.ABABankName
                        : `${response.ABABankName || ''}.`;
                    _.set(bankAccountData, 'bankName', bankName);
                    saveBankingInfo();
                } else {
                    updateBankAccountsLoading(false);
                    updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_EDIT_INVALID_ROUTING);
                }
            }, () => {
                updateBankAccountsLoading(false);
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_EDIT_FAILURE);
            });
    };

    const deleteBankAccount = () => {
        updateBankAccountsLoading(true);
        AccountBillingDetailsService.deleteBankAccount(
            bankAccountData?.publicId_WMIC, authHeader
        ).then((response) => {
            if (response) {
                updateBankAccountData(undefined);
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_DELETE_SUCCESS);
                updateBankAccountsLoading(false);
            } else {
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_DELETE_FAILURE);
            }
        }).finally(() => {
            updateBankAccountsLoading(false);
        });
    };

    const canUserSubmit = () => {
        return agreeBankingInfoStorage && isFormValid && !bankAccountsLoading;
    };

    const onBankAccountChange = (changeData, isValid) => {
        updateIsFormValid(isValid);
        updateBankAccountData(changeData);
    };

    const getButtonContent = (message) => {
        return bankAccountsLoading ? <WMICLoader isInline /> : translator(message);
    };

    const getMessageOnSuccessDelete = () => {
        return translator(messages.paymentMethodSuccessfullyDeleted);
    };

    const getMessageOnFailedDelete = () => {
        return translator(messages.errorDeletingPaymentMethod);
    };

    const deletePaymentInstrument = () => {
        updateBankAccountsLoading(true);
        AccountBillingDetailsService.deletePaymentInstrument_Ext(
            bankAccountData.token,
            accountInfo.accountNumber,
            authHeader
        ).then((response) => {
            if (response) {
                updateBankAccountData(undefined);
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_DELETE_SUCCESS);
            } else {
                updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_DELETE_FAILURE);
            }
        }).catch(() => {
            updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_DELETE_FAILURE);
        }).finally(() => {
            updateBankAccountsLoading(false);
        })
    };

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{getModalHeader()}</h3>
            <ModalBody>
                {displayMode === DISPLAY_MODES.SHOW_BANK_ACCOUNTS && (
                    <div>
                        {bankAccountsLoading && (
                            <WMICLoader />
                        )}
                        { !bankAccountsLoading && (
                            <div>
                                <strong>{`${translator(messages.visitYourProfilePage)} `}</strong><br />
                            </div>
                        )}
                        {!bankAccountsLoading && (
                            <div>
                                {
                                    bankAccounts.map((bankInfo) => {
                                        return (
                                            <div className={styles['ww-detail-payment-row']}>
                                                { bankInfo.nickName_WMIC && bankInfo.nickName_WMIC !== '' && (
                                                    <div>
                                                        <strong>{`${translator(messages.accountNickname)} `}</strong>
                                                        <span>{bankInfo.nickName_WMIC}</span>
                                                    </div>
                                                )}
                                                { bankInfo.bankName && bankInfo.bankName !== '' && (
                                                    <div>
                                                        <span>{bankInfo.bankName}</span>
                                                    </div>
                                                )}
                                                <div>
                                                    <strong>{`${translator(messages.accountType)} `}</strong>
                                                    <span>
                                                        {getAccountTypeText(bankInfo.bankAccountType)}
                                                    </span>
                                                </div>
                                                {(
                                                    <div className={bankInfo.forMarPolicy ? styles['ww-form-item-marge-xxs'] : ''}>
                                                        <strong>{`${translator(messages.bankAccountNumberLastFour)} `}</strong>
                                                        <span>
                                                            {bankInfo.bankAccountNumber}
                                                        </span>
                                                    </div>
                                                )}
                                                { bankInfo.isPrimary_WMIC && (
                                                    <div className={styles['ww-form-item-marge-xxs']}>
                                                        {translator(messages.thisIsPrimary)}
                                                        <WMICClickableIconTooltipComponent
                                                            className={styles['modal-tooltip']}
                                                            id={DOM_ELEMENT_IDS.IS_PRIMARY_TOOLTIP}
                                                            tooltipId={DOM_ELEMENT_IDS.MODAL_TOOLTIP_ID}
                                                            tooltipMessage={translator(messages.primaryAccountWillBeUsed)}
                                                        />
                                                    </div>
                                                )}
                                                { isSetPrimary(bankInfo) && (
                                                    <div>
                                                        { primaryAccountChangedError && (
                                                            <p className="ww-subscript ww-subscript-em">
                                                                {translator(messages.savingPrimaryUnsuccessful)}
                                                                <span>{primaryAccountChangedError}</span>
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                                { bankInfo.forMarPolicy && (
                                                    <div>
                                                        <p className="ww-subscript ww-subscript-em ww-no-footer">{translator(messages.forMARPolicy)}</p>
                                                    </div>
                                                )}
                                                { !bankInfo.forMarPolicy && (
                                                    <div className="ww-btn-group-gap-2">
                                                        <WMICButton
                                                            onClick={() => deleteBankingInformation(bankInfo)}
                                                            type="secondary"
                                                            size="small"
                                                        >
                                                            {translator(messages.delete)}
                                                        </WMICButton>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )}
                    </div>
                )}
                {displayMode === DISPLAY_MODES.EDIT_BANK_ACCOUNT && (
                    <div>
                        <p className={styles['wmic-sm-footer']}>
                            <strong>{translator(messages.nameOnAccount)}</strong>
                            <br />
                            <span>{`${accountInfo.firstName} ${accountInfo.lastName}`}</span>
                        </p>
                        <WMICBankAccount
                            bankInfo={bankAccountData}
                            onChange={onBankAccountChange}
                            newData={false}
                        />
                        <div className="wmic-authorization-disclaimer-container">
                            <h4 className="wmic-authorization-disclaimer-title">{translator(messages.authorization)}</h4>
                            <p className="wmic-authorization-disclaimer-legal-text">{translator(messages.iAuthorize)}</p>
                            <WMICCheckbox
                                id={DOM_ELEMENT_IDS.AGREE_BANKING_INFO_STORAGE_CHECKBOX}
                                value={agreeBankingInfoStorage}
                                label={translator(messages.agreeSaveBankAccount)}
                                labelClassName="wmic-authorization-disclaimer-agree-text"
                                onValueChange={
                                    (newValue) => updateAgreeBankingInfoStorage(newValue)
                                }
                            />
                        </div>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_SUCCESS && (
                    <div>
                        <p>{translator(messages.successSavingBankingInfo)}</p>
                    </div>
                )}
                {(displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_FAILURE
                    || displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_DUPLICATE)
                    && errorMessageText?.text && (
                    <div>
                        <p>{errorMessageText.text}</p>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_INVALID_ROUTING && (
                    <div>
                        <p>{translator(messages.routingTransitNumberInvalid)}</p>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.DELETE_BANK_ACCOUNT && (
                    <div>
                        <p className={styles['wmic-sm-footer']}>
                            {translator(messages.pleaseConfirmDelete)}
                        </p>
                        <p>
                            <div>
                                {bankAccountData && bankAccountData.paymentMethodTypeName && bankAccountData.paymentMethodTypeName !== '' && (
                                    <div>
                                        <span>
                                            {WMICRichTextUtil.translateRichText(translator(messages.paymentMethodDisplay, {
                                                bankName: bankAccountData.bankName,
                                                paymentMethodType: translator({
                                                    id: bankAccountData.paymentMethodTypeName,
                                                    defaultMessage: bankAccountData.paymentMethodTypeName
                                                }),
                                                lastFour: bankAccountData.lastFour
                                            }))}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </p>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_DELETE_SUCCESS && (
                    <div>
                        <p>{getMessageOnSuccessDelete()}</p>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_DELETE_FAILURE && (
                    <div>
                        <p>{getMessageOnFailedDelete()}</p>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                {displayMode === DISPLAY_MODES.SHOW_BANK_ACCOUNTS && !bankAccountsLoading && (
                    <WMICButton
                        onClick={() => { onBack(); }}
                        type="outlined"
                    >
                        {translator(messages.back)}
                    </WMICButton>
                )}
                {displayMode === DISPLAY_MODES.DELETE_BANK_ACCOUNT && !bankAccountsLoading && (
                    <div className="ww-btn-group-gap-2">
                        <WMICButton
                            onClick={() => { onClose(); }}
                            disabled={bankAccountsLoading}
                            type="outlined"
                        >
                            {translator(messages.cancel)}
                        </WMICButton>
                        <WMICButton
                            onClick={() => { deletePaymentInstrument(); }}
                            disabled={bankAccountsLoading}
                            type="primary"
                        >
                            {getButtonContent(messages.yesDelete)}
                        </WMICButton>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_DELETE_SUCCESS && (
                    <div className="ww-btn-group-gap-2">
                        <WMICButton
                            onClick={() => { onCloseAfterDelete(); }}
                            type="primary"
                            size="small"
                        >
                            {translator(messages.ok)}
                        </WMICButton>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_DELETE_FAILURE && (
                    <div className="ww-btn-group-gap-2">
                        <WMICButton
                            onClick={() => { onClose(); }}
                            type="primary"
                            size="small"
                        >
                            {translator(messages.ok)}
                        </WMICButton>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.EDIT_BANK_ACCOUNT && (
                    <div className="ww-btn-group-gap-2">
                        <WMICButton
                            onClick={() => { onClose(); }}
                            type="outlined"
                            disabled={bankAccountsLoading}
                        >
                            {translator(messages.cancel)}
                        </WMICButton>
                        <WMICButton
                            onClick={() => { submitBankingInfo(); }}
                            disabled={!canUserSubmit()}
                            type="primary"
                        >
                            {getButtonContent(messages.submit)}
                        </WMICButton>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_INVALID_ROUTING && (
                    <div className="ww-btn-group-gap-2">
                        <WMICButton
                            onClick={() => { onClose(); }}
                            type="outlined"
                        >
                            {translator(messages.close)}
                        </WMICButton>
                    </div>
                )}
                {displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_SUCCESS && (
                    <div>
                        <WMICButton
                            onClick={() => { onClose(); }}
                            type="primary"
                            size="small"
                        >
                            {translator(messages.ok)}
                        </WMICButton>
                    </div>
                )}
                {(displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_FAILURE
                || displayMode === DISPLAY_MODES.BANK_ACCOUNT_EDIT_DUPLICATE) && (
                    <div>
                        <WMICButton
                            onClick={() => { onClose(); }}
                            type="primary"
                            size="small"
                        >
                            {translator(messages.ok)}
                        </WMICButton>
                    </div>
                )}
            </ModalFooter>
        </Fragment>
    );
};

WMICManageBankingInformation.propTypes = {
    accountInfo: PropTypes.shape({
        policyId: PropTypes.string,
        termNumber: PropTypes.string,
        publicId: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        accountNumber: PropTypes.string
    }).isRequired,
    bankAccountInfo: PropTypes.shape({}),
    mode: PropTypes.string,
    onBack: PropTypes.func.isRequired,
    onCloseAfterAction: PropTypes.func,
    applicationFlow: PropTypes.string,
    policyNumber: PropTypes.string,
    ldFlags: PropTypes.shape({}).isRequired
};

WMICManageBankingInformation.defaultProps = {
    bankAccountInfo: undefined,
    mode: undefined,
    onCloseAfterAction: undefined,
    applicationFlow: 'MakeAPayment',
    policyNumber: undefined
};

export default WMICManageBankingInformation;