/* eslint-disable max-len */
import React, { useContext, useMemo, useCallback } from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS } from 'wmic-portals-utils-js';
import util from './WMICClaimAndVehicleInfoBoxUtil';
import ClaimLookups from '../../utils/ClaimLookups';

import metadata from './WMICClaimAndVehicleInfoBox.metadata.json5';
import messages from './WMICClaimAndVehicleInfoBox.messages';
import styles from './WMICClaimAndVehicleInfoBox.module.scss';

const WMICClaimAndVehicleInfoBox = (props) => {
    const { claimData: claimInfo } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const getFirstInsuredVehicle = useCallback((info) => util.getFirstInsuredVehicle(info, translator, messages), [translator]);

    const getClaimType = useMemo(() => {
        let claimType = ClaimLookups.autoDetailClaimTypeDisplayName(claimInfo.lossCause, claimInfo.perilGroupCategory_WMIC, claimInfo.perilGroupEvent_WMIC);
        
        if (viewModelService) {
            const claimSummaryDTOVM = viewModelService.create(
                {},
                'cc',
                'edge.capabilities.claim.summary.dto.ClaimSummaryDTO'
            );

            if (claimType !== undefined) {
                claimType = translator(messages[_.camelCase(claimType)]);
            } else {
                claimType = ClaimLookups.perilGroupEventDisplayName(claimSummaryDTOVM, translator, claimInfo.perilGroupEvent_WMIC);

                const getMsgKey = `${_.camelCase(claimType)}GroupEvent]`;

                claimType = claimType ? translator(messages[getMsgKey]):translator(messages.pending);
            }
        }

        return claimType;

    }, [claimInfo, translator, viewModelService]);

    const srMessage = useMemo(() => {
        let message = '';

        if (claimInfo?.claimState !== CONSTANTS.CLAIM_STATUS.CLOSED) {
            message += ` ${  translator(messages.ClaimStatusAria, {claimState: claimInfo?.claimState})}`;
        }
        
        if (claimInfo?.completedDate_WMIC) {
            message += ` ${  translator(messages.CompletedDateAria, {
                date: intl.formatDate(new Date(claimInfo?.completedDate_WMIC), { year: 'numeric', month: 'numeric', day: 'numeric' })
            })}`;
        }

        message += ` ${  translator(messages.PolicyNumberAria, {policyNumber: claimInfo?.policy?.policyNumber})}`;

        message += ` ${  translator(messages.ClaimTypeAria, {claimType: getClaimType})}`;

        message += ` ${  translator(messages.InsuredVehicleAria, {
            vehicle: getFirstInsuredVehicle(claimInfo)?.vehicle || translator(messages.Pending)
        })}`;

        message += ` ${  translator(messages.InsuredDriverAria, {
            driver: getFirstInsuredVehicle(claimInfo)?.driver || translator(messages.Pending)
        })}`;

        message += ` ${  translator(messages.DeductibleAria, {
            amount: claimInfo?.deductible_WMIC ? `$${claimInfo?.deductible_WMIC?.amount}` : translator(messages.NotApplicable)
        })}`;

        return message;
    }, [claimInfo, getClaimType, getFirstInsuredVehicle, intl, translator]);

    const overrides = {
        claimStatusColumn: {
            visible: claimInfo?.claimState !== CONSTANTS.CLAIM_STATUS.CLOSED
        },
        claimStatusValueDiv: {
            content: claimInfo.claimState
        },
        completedDateColumn: {
            // eslint-disable-next-line camelcase
            visible: !!claimInfo?.completedDate_WMIC
        },
        completedDateValueDiv: {
            // eslint-disable-next-line camelcase
            content: intl.formatDate(new Date(claimInfo?.completedDate_WMIC), { year: 'numeric', month: 'numeric', day: 'numeric' })
        },
        policyNumberValueDiv: {
            content: claimInfo?.policy?.policyNumber
        },
        claimTypeValueDiv: {
            content: getClaimType
        },
        insuredVehicleValueDiv: {
            content: getFirstInsuredVehicle(claimInfo)?.vehicle || translator(messages.Pending)
        },
        insuredDriverValueDiv: {
            content: getFirstInsuredVehicle(claimInfo)?.driver || translator(messages.Pending)
        },
        deductibleTooltip: {
            tooltipMessage: translator(messages.DeductibleTooltip)
        },
        deductibleValueDiv: {
            content: claimInfo?.deductible_WMIC ? `$${claimInfo?.deductible_WMIC?.amount}` : translator(messages.NotApplicable)
        },
        claimAndVehicleInfoBoxContentAria: {
            content: srMessage
        }
    };

    const resolvers = {
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={claimInfo}
        />
    );
};

WMICClaimAndVehicleInfoBox.propTypes = {
    claimData: PropTypes.shape({})
};

WMICClaimAndVehicleInfoBox.defaultProps = {
    claimData: {}
};

export default WMICClaimAndVehicleInfoBox;