import { defineMessages } from 'react-intl';

export default defineMessages({
    policyCommercialVehicleHeading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Commercial Vehicle Details',
        defaultMessage: 'Commercial Vehicle Details'
    },
    policyBodyType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.BodyType',
        defaultMessage: 'Body Type'
    },
    policyOtherBodyTypeDesc: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Commercial Vehicle Body Type Other',
        defaultMessage: 'Describe Other'
    },
    policyTypeOfUse: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Type of Use',
        defaultMessage: 'Type of Use'
    },
    policyOtherTypeOfUseDesc: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Describe Other',
        defaultMessage: 'Describe Other'
    },
    policySubTypeOfUse: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Sub Type of Use',
        defaultMessage: 'Sub Type of Use'
    },
    policyCommercialVehicleClass: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Commercial Vehicle Class',
        defaultMessage: 'Commercial Vehicle Class'
    },
    policyUsedForDelivery: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Used for Delivery',
        defaultMessage: 'Used for Delivery'
    },
    policyDeliveryType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Delivery Type',
        defaultMessage: 'Delivery Type'
    },
    policyGrossVehicleWeight: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Gross Vehicle Weight',
        defaultMessage: 'Gross Vehicle Weight'
    },
    policyRadiusOfOperation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Radius of Operation',
        defaultMessage: 'Radius of Operation'
    },
    policyTripsPerMonth: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.TripsPerMonth',
        defaultMessage: 'How many trips per month extend beyond the normal radius'
    },
    policyNonOwnedTrailer: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Does this vehicle tow a non-owned trailer',
        defaultMessage: 'Does this vehicle tow a non-owned trailer?'
    },
    policyPassengersCarried: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Number of passengers carried per service',
        defaultMessage: 'Number of passengers carried per service'
    },
    policyTowingVehicle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Towing Vehicle',
        defaultMessage: 'Towing Vehicle'
    },
    policyHighHazardHeading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.High Hazard Operations',
        defaultMessage: 'High Hazard Operations'
    },
    policyHighHazardAlternateHeading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Additional Exposure Questions',
        defaultMessage: 'Additional Exposure Questions'
    },
    policyBulkLiquids: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Vehicle haul bulk liquids?',
        defaultMessage: 'Does this vehicle haul Bulk Liquids?'
    },
    policyBulkLiquidsAlternate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Vehicle haul non-hazardous Bulk Liquids?',
        defaultMessage: 'Does this vehicle haul non-hazardous Bulk Liquids (i.e. Dairy, Water)?'
    },
    policyDangerousGoods: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Vehicle haul Dangerous Goods?',
        defaultMessage: 'Does this vehicle haul Dangerous Goods?'
    },
    policyDangerousGoodsType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Type of Dangerous Goods?',
        defaultMessage: 'Type of Dangerous Goods?'
    },
    policyOtherDangerousGoodsDesc: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Describe Other',
        defaultMessage: 'Describe Other'
    },
    policyOilFields: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Vehicle operate in Oil Fields?',
        defaultMessage: 'Does this vehicle operate in Oil Fields?'
    },
    policyOversizedLoads: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Vehicle haul Oversized Loads?',
        defaultMessage: 'Does this vehicles haul Oversized Loads?'
    },
    policySandStoneGravelEarth: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Vehicle haul Sand, Stone, Gravel and Earth?',
        defaultMessage: 'Does this vehicle haul Sand, Stone, Gravel and Earth?'
    },
    policyWinterRoads: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Vehicle operate on Winter / Ice Roads?',
        defaultMessage: 'Does this vehicle operate on Winter/ Ice Roads?'
    },
    policyHaulLogs: {
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Haul Logs?",
        defaultMessage: "Does this vehicle haul Logs?"
    },
    policyHaulLumber: {
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Haul Lumber?",
        defaultMessage: "Does this vehicle haul Lumber?"
    },
    policyHaulLivestock: {
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Haul Livestock?",
        defaultMessage: "Does this vehicle haul Livestock?"
    },
    policyPerformMovingStorage: {
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Perform Moving Storage?",
        defaultMessage: "Does this vehicle perform any Moving or Storage?"
    },
    policyMaxCarryableVehicles: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.Maximum number of vehicles the tow truck/trailer can carry',
        defaultMessage: 'Maximum number of vehicles the tow truck/trailer can carry'
    },
    policyHighHazardOperationsQuestions_Ext: {
        "id": "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsageCommercial.HighHazardOperationsQuestions_Ext",
        "defaultMessage": "Are any of these high hazard operations applicable to the insured?"
    }
})