/* eslint-disable no-secrets/no-secrets */
import React, { useContext, useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { RadioButton, RadioButtonField } from '@jutro/legacy/components';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICRichTextUtil,
    LOBConstants,
    EMAIL_VALIDATION_STATUS,
    WMICAddressDetailsUtil,
    WMICLogger,
    WMICVariousUtil,
    WMICValidationUtil
} from 'wmic-pe-portals-utils-js';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';

import cx from 'classnames';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';

import metadata from './WMICContactDetailsPage.metadata.json5';
import styles from './WMICContactDetailsPage.module.scss';
import messages from './WMICContactDetailsPage.messages';

const STATES_WITH_PREFERRED_LANGUAGE_SELECTION = ['NB', 'NS', 'PE', 'QC'];
const STATES_WITH_PREFERRED_LANGUAGE_SELECTION_EXCEPT_AUTO = ['BC', 'AB', 'SK', 'MB', 'ON', 'YT'];

function WMICContactDetailsPage(props) {
    const translator = useContext(TranslatorContext);
    const { wizardData: submissionVM, updateWizardData, updateWizardSnapshot, stopErrorNavigation } = props;
    const {
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation,
        onValidate: setComponentValidation
    } = useValidation('ContactDetailsPage');
    const { setWizardLoading } = useWizardModals();

    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const accountEmailProxy = _.get(submissionVM, 'accountEmailAddressProxy_WMIC.value');
    const edeliveryEnrolled = _.get(submissionVM, 'edeliveryEnrolled_WMIC.value', false);
    const isQuoted = _.get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted';
    const [initialAddress, setInitialAddress] = useState();
    const [isAddressVisible, setAddressVisible] = useState(false);
    const [isEditFormOpen, setEditFormOpen] = useState(false);
    const [originalEmailAddress, setOriginalEmailAddress] = useState(accountEmailProxy);
    const [emailValidationStatus, setEmailValidationStatus] = useState(_.get(submissionVM, 'baseData.emailValidationStatus.value.code'));
    const [showErrors, setShowErrors] = useState(false);
    const isPA = _.get(submissionVM, 'lob.value.code') === LOBConstants.PA_LINE;
    const isGL = _.get(submissionVM, 'lob.value.code') === LOBConstants.GL_LINE;

    useEffect(() => {
        registerInitialComponentValidation(() => {

            const hasNoDtoValidationErrors = !WMICValidationUtil.hasDtoValidationErrors(submissionVM);

            if (edeliveryEnrolled) {
                return isQuoted && !_.isEmpty(accountEmailProxy) && hasNoDtoValidationErrors;

            }
            return isQuoted && hasNoDtoValidationErrors;
        });
        stopErrorNavigation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(accountEmailProxy === originalEmailAddress){
            submissionVM.confirmAccountEmailAddress.value = accountEmailProxy;
        } else {
            submissionVM.confirmAccountEmailAddress.value = "";
        }
    }, [accountEmailProxy, submissionVM.confirmAccountEmailAddress, originalEmailAddress])

    useEffect(() => {
        if (originalEmailAddress !== accountEmailProxy) {
            setEmailValidationStatus(EMAIL_VALIDATION_STATUS.DRAFT)
        }

    }, [originalEmailAddress, accountEmailProxy])

    const toggleAddressVisible = useCallback((addressWillBeVisible) => {
        if (addressWillBeVisible) {
            const policyAddress = _.get(submissionVM, 'baseData.policyAddress.value');
            const addressOnOpen = _.cloneDeep(policyAddress);
            if (!_.isNil(addressOnOpen)) {
                setInitialAddress(addressOnOpen);
            }
        } else if (!_.isNil(initialAddress)) {
            _.set(submissionVM, "baseData.policyAddress.value", initialAddress);
        }
        setAddressVisible(addressWillBeVisible);
        setEditFormOpen(addressWillBeVisible);
    }, [initialAddress, setAddressVisible, setEditFormOpen, submissionVM]);

    const areFieldsValid = useCallback(() => {
        const addressVM = _.get(submissionVM, 'baseData.policyAddress')

        if (!addressVM) {
            return true;
        }

        return addressVM.aspects && addressVM.aspects.valid && addressVM.aspects.subtreeValid;
    }, [submissionVM]);

    const handleSaveAddress = useCallback(async () => {
        if (areFieldsValid()) {
            setWizardLoading(true, translator(messages.updatingContactDetails));
            try {
                const policyAddress = _.get(submissionVM, "baseData.policyAddress");
                policyAddress.displayName = WMICAddressDetailsUtil.formatAddress(policyAddress);
                _.set(submissionVM, "baseData.policyAddress", policyAddress);

                submissionVM.value = await LoadSaveService.saveAndSyncInsurance_WMIC(submissionVM.value, authHeader);
                updateWizardData(submissionVM);
                updateWizardSnapshot(submissionVM);
                setAddressVisible(false);
                setEditFormOpen(false);
            } catch (err) {
                WMICLogger.error("Save address failed", err);
                throw new Error(err);
            } finally {
                setWizardLoading(false);
            }
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areFieldsValid, setWizardLoading, translator, submissionVM, authHeader, updateWizardSnapshot]);

    const validateEmail = useCallback(() => {
        if (accountEmailProxy === _.get(submissionVM, 'confirmAccountEmailAddress.value')) {
            AccountService.validateEmail(accountEmailProxy, _.get(submissionVM, 'quoteID.value'), authHeader)
                .then((res) => {
                    _.set(submissionVM, 'baseData.emailValidationStatus.value', res.emailValidationStatus);
                    setEmailValidationStatus(res.emailValidationStatus);
                    setOriginalEmailAddress(accountEmailProxy);
                }).catch(() => {
                    _.set(submissionVM, 'baseData.emailValidationStatus.value', EMAIL_VALIDATION_STATUS.ERROR);
                    setEmailValidationStatus(EMAIL_VALIDATION_STATUS.ERROR);
                });
        }
    }, [submissionVM, authHeader, accountEmailProxy]);

    const getValidationStatus = useCallback(() => {
        switch (emailValidationStatus) {
            case EMAIL_VALIDATION_STATUS.VALID:
                return messages.emailValid;
            case EMAIL_VALIDATION_STATUS.INVALID_TLS:
                return messages.invalidtls;
            case EMAIL_VALIDATION_STATUS.ERROR:
                return messages.unavailable;
            case EMAIL_VALIDATION_STATUS.INVALID:
                return messages.invalid;
            case EMAIL_VALIDATION_STATUS.EMPTY:
                return messages.emptyEmail;
            default:
                return messages.unavailable;
        }
    }, [emailValidationStatus]);

    const polDocPrefLangVisible = useCallback(() =>
        !isGL && (STATES_WITH_PREFERRED_LANGUAGE_SELECTION.includes(_.get(submissionVM, 'baseData.baseState.value.code'))
            || (!isPA && STATES_WITH_PREFERRED_LANGUAGE_SELECTION_EXCEPT_AUTO.includes(_.get(submissionVM, 'baseData.baseState.value.code'))))
    , [submissionVM, isPA, isGL]);

    const accountEmailContainerVisible = _.get(submissionVM, 'lobData.personalAuto.epoiEnrolled_WMIC.value', false)
            || (_.get(submissionVM, 'edeliveryEnrolled_WMIC.value', false) && !_.get(submissionVM, 'isBrokerOwned_WMIC.value', false));

    const validateEmailIsNotEmpty = () => {
        const emailAddress1 = _.get(submissionVM, 'baseData.primaryNamedInsured_WMIC.emailAddress1.value');
        const confirmEmailAddress = _.get(submissionVM, 'baseData.primaryNamedInsured_WMIC.confirmEmailAddress.value');

        if (!confirmEmailAddress && !emailAddress1) {
            setEmailValidationStatus(EMAIL_VALIDATION_STATUS.EMPTY);
            setShowErrors(true);
        }
    }

    const overrideProps = {
        '@field': {
            parentNode: submissionVM,
            showRequired: true
        },
        edeliveryEnrolled_WMIC: {
            visible: !_.get(submissionVM, 'isBrokerOwned_WMIC.value', false)
        },
        polDocPrefLang_WMIC: {
            visible: polDocPrefLangVisible()
        },
        epoiContainer: {
            visible: isPA
        },
        statusContainer: {
            className: _.get(submissionVM, 'isBrokerOwned_WMIC.value', false) ? `${styles.wmicStatusContainer} ${styles.wmicCenteredContent}` : styles.wmicStatusContainer
        },
        statusMessageHeading: {
            visible: !_.get(submissionVM, 'isBrokerOwned_WMIC.value', false)
        },
        statusMessageBody: {
            content: _.get(submissionVM, 'isBrokerOwned_WMIC.value', false) ? translator(messages.brokerDistributed) : WMICRichTextUtil.translateRichText(translator(messages.consent))
        },
        addressContainer: {
            visible: isAddressVisible
        },
        editItalicize: {
            onClick: () => toggleAddressVisible(!isAddressVisible)
        },
        policyDetailsPageAddressComponent: {
            addressVM: _.get(submissionVM, 'baseData.policyAddress', {}),
            onValidate: setComponentValidation,
            showErrors,
            hideBorder: false,
            hideButtons: false,
            onSave: handleSaveAddress,
            onCancel: () => toggleAddressVisible(false),
        },
        accountEmailContainer: {
            visible: accountEmailContainerVisible
        },
        confirmEmail: {
            visible: originalEmailAddress !== accountEmailProxy,
            onBlur: validateEmailIsNotEmpty
        },
        accountEmail: {
            onBlur: validateEmailIsNotEmpty
        },
        emailValidationIcon: {
            visible: emailValidationStatus === EMAIL_VALIDATION_STATUS.VALID
        },
        emailValidationStatus: {
            visible: emailValidationStatus !== EMAIL_VALIDATION_STATUS.DRAFT,
            content: translator(getValidationStatus()),
            className: cx(styles.gwAlert, {
                [styles.error]: emailValidationStatus !== EMAIL_VALIDATION_STATUS.VALID
            })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICAddressDetails,
            RadioButtonField
        },
        resolveCallbackMap: {
            verifyEmail: validateEmail
        }
    };

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                return false;
            }
            setWizardLoading(true, translator(isGL ? messages.updatingContactDetails : commonMessages.quoting));

            if (isGL) {
                submissionVM.value = await LoadSaveService.updateQuotedSubmission(submissionVM.value, authHeader)
            } else {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.unset(newSubmissionVM.value, 'bindData');

                submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(newSubmissionVM.value, authHeader)
            }
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM)) {
                WMICVariousUtil.scrollToTop();
                return false
            }

            return submissionVM;
        } finally {
            setShowErrors(true);
            setWizardLoading(false);
        }

        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGL, translator, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);

    return (
        <WMICWizardSubmissionPage
            cancelLabel={commonMessages.saveAndExit}
            showMandatoryInfo
            skipWhen={initialValidation}
            disableNext={isEditFormOpen}
            onNext={onNext}
            nextLabel={isGL ? commonMessages.next : commonMessages.quote}
            showRequired
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={setComponentValidation}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICContactDetailsPage.propTypes = wizardProps;
export default WMICContactDetailsPage;
