import React, {
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WMICDriverUtil } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICCommercialDriverComponent.metadata.json5';
import messages from './WMICCommercialDriverComponent.messages.js';

const COMMERCIAL_DRIVER_PATHS = {
    AUTH_REQUIRED: 'commercialDriver_WMIC.isDriverAuthRequiredForMvr',
    AUTH_FORM_SIGNED: 'commercialDriver_WMIC.isDriverAuthFormSigned',
    HAUL_GOODS: 'commercialDriver_WMIC.haulGoods',
    GOODS_HAULED_TYPE: 'commercialDriver_WMIC.goodsHauledType',
    OTHER_GOODS_HAULED: 'commercialDriver_WMIC.otherGoodsHauledDesc',
    HIGH_HAZARD_OPS: 'commercialDriver_WMIC.isInvolvedInHighHazardOps',
    ICE_ROAD_EXPOSURE: 'commercialDriver_WMIC.winterIceRoadExposure',
    COMPL_TRAINING_COURSE: 'commercialDriver_WMIC.completedTrainingCourse',
    TRAINING_COURSE_COMPL_DATE: 'commercialDriver_WMIC.trainingCourseCompletionDate',
    ACCREDITED_BY: 'commercialDriver_WMIC.accreditedBy',
    OTHER_ACCREDITED_BY: 'commercialDriver_WMIC.otherAccreditedByDesc',
    HEAVY_VEHICLE_EXP: 'commercialDriver_WMIC.heavyVehicleExperience',

    // containers
    AUTH_CONTAINER: 'commercialDriver_WMIC.authContainer',
    TRAINING_CONTAINER: 'commercialDriver_WMIC.trainingContainer',
    COMPLETED_TRAINING_CONTAINER: 'commercialDriver_WMIC.completedTrainingContainer',
    HEAVY_VEHICLE_EXP_CONTAINER: 'commercialDriver_WMIC.heavyVehicleExpContainer'
}
function WMICCommercialDriverComponent(props) {
    const {
        id,
        driverVM,
        onValidate,
        isEditMode,
        updateDriver,
        showErrors,
        isReadOnlyUser,
        baseData
    } = props;

    const translator = useContext(TranslatorContext);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, driverVM, id]);

    const resetHaulingFields = () => {
        _.set(driverVM, `${COMMERCIAL_DRIVER_PATHS.GOODS_HAULED_TYPE}.value`, undefined);
        _.set(driverVM, `${COMMERCIAL_DRIVER_PATHS.OTHER_GOODS_HAULED}.value`, undefined);
    }

    const resetCommercialTrainingFields = () => {
        _.set(driverVM, `${COMMERCIAL_DRIVER_PATHS.TRAINING_COURSE_COMPL_DATE}.value`, undefined);
        _.set(driverVM, `${COMMERCIAL_DRIVER_PATHS.ACCREDITED_BY}.value`, undefined);
        _.set(driverVM, `${COMMERCIAL_DRIVER_PATHS.OTHER_ACCREDITED_BY}.value`, undefined);
    }

    const resetAllCommercialTrainingFields = () => {
        _.set(driverVM, `${COMMERCIAL_DRIVER_PATHS.COMPL_TRAINING_COURSE}.value`, undefined);
        resetCommercialTrainingFields();
    }

    const resetHaulingAndCommercialTrainingFields = () => {
        resetHaulingFields();
        resetAllCommercialTrainingFields();
    };

    const verifyDriverAuthFormSigned = (value, path) => {
        _.set(driverVM, path, value);
        if (!value) {
            _.set(driverVM, 'commercialDriver_WMIC.isDriverAuthFormSigned', undefined);
        };
        updateDriver(driverVM);
    };

    const resetSpecificFields = (value, path) => {
        _.set(driverVM, path, value);
        if (path === COMMERCIAL_DRIVER_PATHS.HAUL_GOODS) {
            resetHaulingAndCommercialTrainingFields();
        } else if (path === COMMERCIAL_DRIVER_PATHS.GOODS_HAULED_TYPE || path === COMMERCIAL_DRIVER_PATHS.HIGH_HAZARD_OPS) {
            resetAllCommercialTrainingFields();
        } else if (path === COMMERCIAL_DRIVER_PATHS.COMPL_TRAINING_COURSE) {
            resetCommercialTrainingFields();
        }
        updateDriver(driverVM);
    };

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        requiredMsg: {
            content: translator(messages.requiredMsg),
        },
        authSectionContainer: {
            visible: _.get(driverVM, `${COMMERCIAL_DRIVER_PATHS.AUTH_CONTAINER}.aspects.ocular`)
        },
        trainingSectionContainer: {
            visible: _.get(driverVM, `${COMMERCIAL_DRIVER_PATHS.TRAINING_CONTAINER}.aspects.ocular`) && WMICDriverUtil.yearsLicensedLessThanThreeYears(driverVM, baseData.periodStartDate)
        },
        completedTrainingContainer: {
            visible: _.get(driverVM, `${COMMERCIAL_DRIVER_PATHS.COMPLETED_TRAINING_CONTAINER}.aspects.ocular`)
        },
        heavyVehicleExpSectionContainer: {
            visible:_.get(driverVM, `${COMMERCIAL_DRIVER_PATHS.HEAVY_VEHICLE_EXP_CONTAINER}.aspects.ocular`)
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            resetSpecificFields,
            verifyDriverAuthFormSigned
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            onModelChange={updateDriver}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            showErrors={showErrors}
        />
    );
}

WMICCommercialDriverComponent.propTypes = {
    id: PropTypes.string.isRequired,
    driverVM: PropTypes.shape({}).isRequired,
    updateDriver: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    baseData: PropTypes.shape({}).isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

export default WMICCommercialDriverComponent;
