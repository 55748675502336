import { defineMessages } from 'react-intl';

export default defineMessages({
    backToHome: {
        id: 'auth.components.ContactUs.Back to home',
        defaultMessage: 'Back to Home'
    },
    contactUs: {
        id: 'auth.components.ContactUs.Contact Us',
        defaultMessage: 'Contact Us'
    },
    ifYouHaveQuestion: {
        id: 'auth.components.ContactUs.If you have a question or need assistance with your policy, please call us at',
        defaultMessage: 'If you have a question or need assistance with your policy, please call us at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt;.'
    },
    WeLikeToHelp: {
        id: 'auth.components.ContactUs.We like to help and our customer service team is available',
        defaultMessage: 'We’d like to help and our customer service team is available Monday – Friday 7:30 AM – 7:30 PM and Saturday 8:00 AM – 4:30 PM.'
    },
    error500: {
        id: 'auth.components.ContactUs.error.500',
        defaultMessage: 'Contact our Online Account Services toll free at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt;.'
    },
    error634: {
        id: 'auth.components.ContactUs.error.634',
        defaultMessage: 'Sorry, the address submitted does not match our records. To complete your registration, please contact our Online Account Services toll free at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt;.'
    },
    error635: {
        id: 'auth.components.ContactUs.error.635',
        defaultMessage: 'Sorry we are unable to complete your registration. Please contact our Online Account Services toll free at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt;.'
    }
});
