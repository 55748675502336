import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';

import paMetadata from './WMICPABrokerInfo.metadata.json5';
import hoMetadata from './WMICHOBrokerInfo.metadata.json5';

function WMICBrokerInfo({ id, brokerInfo, readOnly, onValidate, updateBrokerInfo, showErrors, authUserData, lob }) {
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])

    const setBrokerInfo = useCallback(() => {
        const brokerName = _.get(brokerInfo, 'brokerName.value');

        if (_.isUndefined(brokerName)) {
            _.set(brokerInfo, 'brokerName', authUserData.displayName);
            updateBrokerInfo(brokerInfo);
        }
    }, [brokerInfo, authUserData, updateBrokerInfo]);

    useEffect(() => {
        setBrokerInfo();
    }, [setBrokerInfo])

    const overrideProps = {
        '@field': {
            readOnly,
            parentNode: brokerInfo,
        },
    };

    const metadataToRender = lob === LINE_OF_BUSINESS.PERSONAL_AUTO ? paMetadata.componentContent : hoMetadata.componentContent

    return (
        <ViewModelForm
            model={brokerInfo}
            uiProps={metadataToRender}
            overrideProps={overrideProps}
            onModelChange={updateBrokerInfo}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
};

WMICBrokerInfo.propTypes = {
    brokerInfo: PropTypes.shape({}).isRequired,
    updateBrokerInfo: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

WMICBrokerInfo.defaultProps = {
    readOnly: false
};

export default WMICBrokerInfo;
