// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICHOConstructionHeating__wmicHOConstructionHeatingContainer>:not(:last-child){margin-bottom:var(--GW-LAYOUT-6)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-ho-react/components/WMICHOConstructionHeating/WMICHOConstructionHeating.module.scss"],"names":[],"mappings":"AACI,sFACI,gCAAA","sourcesContent":[".wmicHOConstructionHeatingContainer {\n    & > *:not(:last-child) {\n        margin-bottom: var(--GW-LAYOUT-6); \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicHOConstructionHeatingContainer": "app__WMICHOConstructionHeating__wmicHOConstructionHeatingContainer"
};
export default ___CSS_LOADER_EXPORT___;
