import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import htmlParser from 'html-react-parser';
import {
    Icon,
    ModalNext,
    ModalFooter,
} from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WMICRichTextUtil, CONSTANTS } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import dayjs from 'dayjs';
import styles from './WMICOverduePolicyModal.module.scss';
import messages from './WMICOverduePolicies.messages';

let payPolicyAfterClose = false;
let policyToPay = { billingData: {}, policyData: {} };

const WMICOverduePolicyModal = ({
    isOpen,
    onResolve,
    policies,
    onPayPolicyClick,
    onClose
}) => {
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const handleClose = () => {
        onResolve();
        payPolicyAfterClose = false;
        policyToPay = { billingData: {}, policyData: {} };
    };

    const onPolicyPay = (details) => {
        onResolve();
        payPolicyAfterClose = true;
        policyToPay = details;
    };

    const onModalClose = () => {
        if (payPolicyAfterClose) {
            onPayPolicyClick(policyToPay);
        }
        onClose();
    };

    const hasMultiplePolicies = policies.length > 1;

    const getHeader = () => {
        if (hasMultiplePolicies) {
            return translator(messages.yourPaymentsAreOverdue);
        }

        if (policies.length > 0 && policies[0].details && policies[0].details.isInactive) {
            return translator(messages.yourPolicyHasBeenCancelled);
        }

        return translator(messages.yourPaymentIsOverdue);
    };

    const getPeriodActiveInactiveText = (policyDetails, activeText, inactiveText) => {
        return policyDetails.isInactive ? inactiveText : activeText;
    }
    
    const getFormattedOverdueAmount = (policyDetails) => {
        return intl.formatNumber(
            policyDetails.overdueAmount,
            {
                style: 'currency',
                currency: policyDetails.currency,
                currencyDisplay: 'symbol'
            }
        );
    }

    const getOverdueMessage = (msgType, policy, shouldBeReadable) => {
        const overdueAmount = getFormattedOverdueAmount(policy.details);

        const getPolicyTypeTitle = (pType) => {
            switch (pType) {
                case 'Personal Auto':
                    return translator(messages.automobilePolicyShort);
                case 'Renters':
                    return translator(messages.rentersPolicy);
                case 'Homeowners':
                    return translator(messages.homeownersPolicy);
                case 'Condominium':
                    return translator(messages.condominiumPolicy);
                default:
                    return translator(messages.genericPolicy);
            }
        };

        const isListBill = (aPeriod) => {
            return (aPeriod.billingData?.billingMethod === CONSTANTS.LIST_BILL);
        }

        const formatDate = (date) => {
            let formattedDate = '';
            if (date !== undefined) {
                // Convert Date string to date format
                formattedDate = (typeof date === 'string' && date[date.length - 1] === 'Z') ? date : dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
                // Take the date only
                formattedDate = formattedDate.substring(0, formattedDate.indexOf('T'));
                // Set date's time to midngiht, so MM/DD/YYYYT00:00:00
                formattedDate = dayjs(`${formattedDate}T00:00:00`);
                // Format to display
                formattedDate = shouldBeReadable ?
                    intl.formatDate(new Date(formattedDate), { year: 'numeric', month: 'short', day: 'numeric' }) :
                    intl.formatDate(new Date(formattedDate), { year: 'numeric', month: '2-digit', day: '2-digit' });
                return formattedDate;
            }

            return formattedDate
        }

        const delinquencyText = () => {
            const msg = {
                subHeader: () => {
                    return getPeriodActiveInactiveText(policy.details,
                        translator(messages.isAtRiskOfBeingCancelled, {
                            policyType: getPolicyTypeTitle(policy.details.policyType),
                            policyNumber: policy.id
                        }),
                        translator(messages.cancelledDueToAnOutstandingBalance, {
                            policyType: getPolicyTypeTitle(policy.details.policyType),
                            policyNumber: policy.id
                        })
                    );
                },
                text: () => {
                    let activeText = WMICRichTextUtil.safeUnescape(translator(messages.policyHasAnOverdueAmount, {
                        overdueAmount: overdueAmount
                    }));
                    const cancellationDate = policy.details.billingData.delinquencyInfo.targetCancellationDate
                        ? formatDate(policy.details.billingData.delinquencyInfo.targetCancellationDate)
                        : false;
                    // message with cancellation date
                    if (policy.details.isPendingCancellation) {
                        activeText = WMICRichTextUtil.safeUnescape(translator(messages.hasAnOverdueAmountWhichMustBePaidBefore, {
                            overdueAmount: overdueAmount,
                            cancellationDate: cancellationDate
                        }));
                    }

                    return getPeriodActiveInactiveText(policy.details,
                        activeText,
                        WMICRichTextUtil.safeUnescape(translator(messages.hasAnOverdueAmountWhichIsInactive, {
                            overdueAmount: overdueAmount
                        }))
                    );
                },
                contactInfo: () => {
                    if (isListBill(policy.details) && !policy.details.isInactive) {
                        return shouldBeReadable ?
                            WMICRichTextUtil.safeUnescape(translator(messages.contactAria)) :
                            WMICRichTextUtil.safeUnescape(translator(messages.contact));
                    }
                    return shouldBeReadable ?
                        WMICRichTextUtil.safeUnescape(translator(messages.makeAPaymentContactAria)) :
                        WMICRichTextUtil.safeUnescape(translator(messages.makeAPaymentContact))
                }
            };

            return msg[msgType]();
        };

        const delinquencyTextNotTaken = () => {
            const msg = {
                subHeader: () => {
                    return getPeriodActiveInactiveText(policy.details,
                        translator(messages.subHeaderNotTakenInForce, {
                            policyType: getPolicyTypeTitle(policy.details.policyType),
                            policyNumber: policy.id
                        }),
                        translator(messages.subHeaderNotTakenCancelled, {
                            policyType: getPolicyTypeTitle(policy.details.policyType),
                            policyNumber: policy.id
                        })
                    );
                },
                text: () => {
                    const cancellationDate = policy.details.billingData.delinquencyInfo.targetCancellationDate
                        ? formatDate(policy.details.billingData.delinquencyInfo.targetCancellationDate)
                        : false;
                    const effDate = intl.formatDate(new Date(policy.details.effective), { year: 'numeric', month: '2-digit', day: '2-digit' });

                    // List Bill policies
                    if (isListBill(policy.details)) {
                        return WMICRichTextUtil.safeUnescape(getPeriodActiveInactiveText(policy.details,
                            translator(messages.yourPolicyHasExpiredAsOf, {
                                effDate: effDate,
                                overdueAmount: overdueAmount,
                                cancellationDate: cancellationDate
                            }),
                            translator(messages.aPaymentWasNotReceivedFromYourMortgageCompany, {
                                overdueAmount: overdueAmount
                            })
                        ));
                    }

                    if (!cancellationDate) {
                        return WMICRichTextUtil.safeUnescape(translator(messages.messageNotTakenInForceNoDate, {
                            overdueAmount: overdueAmount,
                            effDate: effDate
                        }));
                    }

                    return getPeriodActiveInactiveText(policy.details,
                        WMICRichTextUtil.safeUnescape(translator(messages.messageNotTakenInForce, {
                            overdueAmount: overdueAmount,
                            effDate: effDate,
                            cancellationDate: cancellationDate
                        })),
                        WMICRichTextUtil.safeUnescape(translator(messages.messageNotTakenCancelled, {
                            overdueAmount: overdueAmount
                        }))
                    );
                },
                contactInfo: () => {
                    return shouldBeReadable ?
                        delinquencyText('contactInfoAria') :
                        delinquencyText('contactInfo');
                    }
            };

            return msg[msgType]();
        };

        const delinquencyTextPastDue = () => {
            const msg = {
                subHeader: () => { return delinquencyText('subHeader'); },
                text: () => {
                    const cancellationDate = policy.details.billingData.delinquencyInfo.targetCancellationDate
                        ? formatDate(policy.details.billingData.delinquencyInfo.targetCancellationDate)
                        : false;

                    // List Bill policies
                    if (isListBill(policy.details)) {
                        return getPeriodActiveInactiveText(policy.details,
                            WMICRichTextUtil.safeUnescape(translator(messages.aPaymentWasNotReceivedFromYourMortgageCompany2, {
                                overdueAmount: overdueAmount,
                                cancellationDate: cancellationDate
                            })),
                            WMICRichTextUtil.safeUnescape(translator(messages.aPaymentWasNotReceivedFromYourMortgageCompany, {
                                overdueAmount: overdueAmount
                            }))
                        );
                    }

                    return getPeriodActiveInactiveText(policy.details,
                        WMICRichTextUtil.safeUnescape(translator(messages.hasAnOverdueAmountWhichMustBePaidBefore, {
                            overdueAmount: overdueAmount,
                            cancellationDate: cancellationDate
                        })),
                        WMICRichTextUtil.safeUnescape(translator(messages.overdueAmountCallToReinstate, {
                            overdueAmount: overdueAmount
                        }))
                    );
                },
                contactInfo: () => {
                    return shouldBeReadable ?
                        delinquencyText('contactInfoAria') :
                        delinquencyText('contactInfo');
                }
            };

            return msg[msgType]();
        };

        const delinquencyTextNSF = () => {
            const msg = {
                subHeader: () => { return delinquencyText('subHeader'); },
                text: () => {
                    // List Bill policies
                    if (isListBill(policy.details)) {
                        const cancellationDate = policy.details.billingData.delinquencyInfo.targetCancellationDate
                            ? formatDate(policy.details.billingData.delinquencyInfo.targetCancellationDate)
                            : false;

                        return getPeriodActiveInactiveText(policy.details,
                            WMICRichTextUtil.safeUnescape(translator(messages.aPaymentHasBeenReceivedButWasReturned, {
                                overdueAmount: overdueAmount,
                                cancellationDate: cancellationDate
                            })),
                            WMICRichTextUtil.safeUnescape(translator(messages.aPaymentWasReceivedButWasReturned, {
                                overdueAmount: overdueAmount
                            }))
                        );
                    }

                    return delinquencyText('text');                    
                },
                contactInfo: () => {
                    return shouldBeReadable ?
                        delinquencyText('contactInfoAria') :
                        delinquencyText('contactInfo');
                }
            };

            return msg[msgType]();
        };

        switch (policy.details.billingData.delinquencyInfo.type) {
            case CONSTANTS.CTA_DELINQUENCY_TYPE.NOT_TAKEN: return delinquencyTextNotTaken(msgType);
            case CONSTANTS.CTA_DELINQUENCY_TYPE.PAST_DUE: return delinquencyTextPastDue(msgType);
            case CONSTANTS.CTA_DELINQUENCY_TYPE.NSF: return delinquencyTextNSF(msgType);
            default: return delinquencyText(msgType);
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            shouldCloseOnEsc
            onRequestClose={handleClose}
            className={styles.modal}
            overlayClassName={styles.modalOverlay}
            onAfterClose={onModalClose}
            shouldCloseOnOverlayClick={false}
            contentLayout={{
                component: 'Grid',
                componentProps: {
                    rows: ['auto'],
                },
            }}
        >
            
            <h1 className='modal'>
                <Icon icon='cust-exclamation-triangle' className={styles.icon}/>
                {translator(getHeader())}
            </h1>
            <div className={styles.modalBody}>
                {
                    policies.map(({ id, details }) => (
                        <div key={id} className={styles.policy}>
                            <div>
                                <h2 className={styles.header}>
                                    {htmlParser(getOverdueMessage('subHeader', { id, details }))}
                                </h2>
                                <p aria-hidden='true'>
                                    {htmlParser(getOverdueMessage('text', { id, details }))}
                                </p>
                                <p className='sr-only'>
                                    {htmlParser(getOverdueMessage('text', { id, details }, true))}
                                </p>
                            </div>
                            <div className={styles.contactInfo} aria-hidden='true'>
                                {htmlParser(getOverdueMessage('contactInfo', { id, details }))}
                            </div>
                            <div className='sr-only'>
                                {htmlParser(getOverdueMessage('contactInfo', { id, details }, true))}
                            </div>
                            <div className={styles.buttonWrapper}>
                                <WMICButton
                                    type='danger'
                                    size='small'
                                    icon='cust-dollar-sign'
                                    iconClassName='payNowIcon'
                                    onClick={() => onPolicyPay(details)}
                                    className={styles.button}
                                    aria-label={translator(messages.payNowAria, {policyNumber: id, overdueAmount: getFormattedOverdueAmount(details)})}
                                    trackButtonIdentifier={translator(messages.payNowTrackButtonIdentifier,
                                        {
                                            policyActiveStatus: getPeriodActiveInactiveText(
                                                details,
                                                translator(messages.active),
                                                translator(messages.inactive))
                                        })
                                    }
                                >
                                    {translator(messages.payNow)}
                                </WMICButton>
                                {hasMultiplePolicies && <strong aria-hidden='true'>{translator(messages.policy, { policyNumber: id })}</strong>}
                            </div>
                        </div>
                    ))
                }
            </div>
            <ModalFooter>
                <WMICButton
                    type='primary'
                    onClick={handleClose}
                >
                    {translator(messages.close)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICOverduePolicyModal.propTypes = {
    policies: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            details: PropTypes.object
        })
    ).isRequired,
    onPayPolicyClick: PropTypes.func.isRequired
};

export default WMICOverduePolicyModal;