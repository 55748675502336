import React, { Component } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext, withIntl } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AccountService } from 'gw-capability-gateway-policy';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { DatatableUtil } from 'wmic-portals-utils-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { Icon } from '@jutro/components';
import { SelectProducerCode, withProducerContext } from 'gw-gateway-common-react';
import metadata from './Accounts.metadata.json5';
import styles from './Accounts.module.scss';
import '../Policies/Policies.messages';
import messages from './Accounts.messages';

const { gatewayParamConfig = {} } = appConfig;

class Accounts extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired,
        children: PropTypes.shape({}).isRequired,
        authUserData: PropTypes.shape({ publicID: PropTypes.string }).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            currentView: 'recentlyViewedTile',
            selectedProducerCode: 'all',
            selectedHeaderContent: messages.RecentlyViewed
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { producerCode } = props;
        const { selectedProducerCode } = state;
        if (producerCode !== selectedProducerCode) {
            return {
                selectedProducerCode: producerCode
            };
        }
        return null;
    }

    getQueryParam = (selectedProducerCode) => {
        const { authUserData } = this.props;
        if (selectedProducerCode === 'myWork') {
            return [
                {
                    propertyName: 'CreateUser',
                    entityName: 'User',
                    entityPublicId: authUserData.publicID,
                    isDbProperty: true,
                    orOperation: false
                }
            ];
        }
        if (selectedProducerCode !== 'all') {
            return selectedProducerCode;
        }
        return null;
    };

    processResponseData = (accountData) => {
        const { intl } = this.props;
        const accountArray = accountData.map((accountInfo) => {
            const isPersonal = accountInfo.isPersonalAccount;
            const account = {
                type: isPersonal ? 'Personal' : 'Commercial',
                insuredName: accountInfo.accountHolder,
                policy: accountInfo.policySummaries.length,
                dateCreated: accountInfo.accountCreatedDate,
                address: accountInfo.accountHolderAddress.displayName,
                openActivities: accountInfo.numberOfOpenActivities,
                accountNumber: accountInfo.accountNumber
            };
            return account;
        });
        return accountArray;
    };

    getCell = (items, index, { id: property }) => {
        const translator = this.context;
        let toolTipMessage = '';
        switch (property) {
            case 'dateCreated':
                toolTipMessage = translator(messages.DateCreated);
                break;
            case 'address':
                toolTipMessage = translator(messages.Address);
                break;
            default:
                toolTipMessage = '';
        }
        return <span title={toolTipMessage}>{items[property]}</span>;
    };

    getAccount = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.Account);
        return (
            <FieldLinkComponent
                id={`account${item[property]}`}
                value={item[property]}
                to={`/accounts/${item.accountNumber}/summary`}
                title={toolTipMessage}
            />
        );
    };

    getIcon = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.Account);
        const icon = item.type === 'Personal' ? 'mi-account_circle' : 'mi-business';
        return (
            <Icon
                id={`account${item[property]}`}
                icon={icon}
                title={toolTipMessage}
            />
        );
    };

    getPolicyLink = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = property === 'policy' ? messages.Policies : messages.OpenActivities;
        const redirectPath = property === 'policy' ? 'summary' : 'activities';
        return (
            <FieldLinkComponent
                id={`policy${item[property]}`}
                value={item[property]}
                to={{
                    pathname: `/accounts/${item.accountNumber}/${redirectPath}`,
                    state: redirectPath
                }}
                title={translator(toolTipMessage)}
            />
        );
    };

    handleValueChange = (producerCode) => {
        this.setState({
            selectedProducerCode: producerCode
        });
    };

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    recentView = (sorted) => {
        const { authHeader } = this.props;
        return AccountService.getRecentlyViewedAccounts(authHeader).then((response) => {
            this.setState({
                selectedHeaderContent: messages.RecentlyViewed
            });
            const data = this.processResponseData(response);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: sortedData.length
            };
        });
    };

    passingProducerCode = (paramProducerCode) => {
        const isProducerCodeNotSpecific = ['all', 'myWork'].indexOf(paramProducerCode) > -1;
        return isProducerCodeNotSpecific || _.isNull(paramProducerCode);
    };

    recentCreated = (paginationParams, paramProducerCode, sorted) => {
        const { authHeader } = this.props;

        const queryParam = this.getQueryParam(paramProducerCode);
        let accountsPromise = null;
        if (this.passingProducerCode(paramProducerCode)) {
            accountsPromise = AccountService.getAccountsForCurrentUser(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        } else {
            accountsPromise = AccountService.getAccountsForProducerCode(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        }

        return accountsPromise.then((response) => {
            this.setState({
                selectedHeaderContent: messages.RecentlyCreated
            });
            const data = this.processResponseData(response.accounts);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    personalAccounts = (paginationParams, paramProducerCode, sorted) => {
        const { authHeader } = this.props;
        const queryParam = this.getQueryParam(paramProducerCode);
        let accountsPromise = null;
        if (this.passingProducerCode(paramProducerCode)) {
            accountsPromise = AccountService.getPersonalAccountsForCurrentUser(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        } else {
            accountsPromise = AccountService.getPersonalAccountsForProducerCode(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        }

        return accountsPromise.then((response) => {
            this.setState({
                selectedHeaderContent: messages.PersonalAccounts
            });
            const data = this.processResponseData(response.accounts);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    commercialAccounts = (paginationParams, paramProducerCode, sorted) => {
        const { authHeader } = this.props;
        const queryParam = this.getQueryParam(paramProducerCode);
        let accountsPromise = null;
        if (this.passingProducerCode(paramProducerCode)) {
            accountsPromise = AccountService.getCommercialAccountsForCurrentUser(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        } else {
            accountsPromise = AccountService.getCommercialAccountsForProducerCode(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        }

        return accountsPromise.then((response) => {
            this.setState({
                selectedHeaderContent: messages.CommercialAccounts
            });
            const data = this.processResponseData(response.accounts);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    loadData = ({ sorted, pageSize, page }) => {
        const { currentView, selectedProducerCode } = this.state;
        const paramProducerCode = selectedProducerCode === 'all' ? null : selectedProducerCode;
        let tile = 'recentlyViewedTile';
        if (currentView) {
            tile = currentView;
        }

        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy: gatewayParamConfig.landingOrderBy,
            orderByDescending: gatewayParamConfig.landingOrderByDescending
        };
        if (tile === 'recentlyViewedTile') {
            return this.recentView(sorted);
        }
        if (tile === 'recentlyCreatedTile') {
            return this.recentCreated(paginationParams, paramProducerCode, sorted);
        }
        if (tile === 'personalAccountsTile') {
            return this.personalAccounts(paginationParams, paramProducerCode, sorted);
        }
        if (tile === 'commercialAccountsTile') {
            return this.commercialAccounts(paginationParams, paramProducerCode, sorted);
        }
        return { rows: [], numberOfRows: 0 };
    };

    render() {
        const translator = this.context;
        const { selectedHeaderContent, currentView, selectedProducerCode } = this.state;
        const tableKey = currentView + selectedProducerCode;
        const overrides = {
            accountsListTable: {
                onFetchData: this.loadData,
                key: tableKey
            },
            accountListHeader: {
                content: translator(selectedHeaderContent)
            },
            [currentView]: {
                active: true
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getAccount: this.getAccount,
                getIcon: this.getIcon,
                getPolicyLink: this.getPolicyLink,
                handleTilesOnClick: this.handleTilesOnClick,
                handleValueChange: this.handleValueChange,
                sortNumber: DatatableUtil.sortNumber,
                sortString: DatatableUtil.sortString,
                sortDate: DatatableUtil.sortDate
            },
            resolveComponentMap: {
                selectproducercode: SelectProducerCode
            }
        };

        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

export const AccountsComponent = Accounts;
export default withIntl(withAuthenticationContext(withProducerContext(Accounts)));
