// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__LineOfBusiness__lobContainer{--line-of-business-space:10px;--line-of-business-width:250px;--line-of-business-item-width:150px;display:grid;grid-template-columns:100%;justify-content:center;padding:var(--line-of-business-space);width:var(--line-of-business-width);grid-row-gap:var(--line-of-business-space);grid-template-areas:\"image\" \"title\" \"description\" \"quoteEntry\"}.app__LineOfBusiness__productImage{grid-area:image}.app__LineOfBusiness__productName{grid-area:title;text-align:center}.app__LineOfBusiness__productDescription{grid-area:description;text-align:center}.app__LineOfBusiness__quoteEntry{grid-area:quoteEntry}.app__LineOfBusiness__startQuote{margin:auto}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-common-react/components/LineOfBusiness/LineOfBusiness.module.scss"],"names":[],"mappings":"AAAA,mCACE,6BAAA,CACA,8BAAA,CACA,mCAAA,CAGA,YAAA,CACA,0BAAA,CACA,sBAAA,CAHA,qCAAA,CADA,mCAAA,CAKA,0CAAA,CACA,8DACE,CAMJ,mCACE,eAAA,CAGF,kCAEE,eAAA,CADA,iBACA,CAGF,yCAEE,qBAAA,CADA,iBACA,CAGF,iCACE,oBAAA,CAGF,iCACE,WAAA","sourcesContent":[".lobContainer {\n  --line-of-business-space: 10px;\n  --line-of-business-width: 250px;\n  --line-of-business-item-width: 150px;\n  width: var(--line-of-business-width);\n  padding: var(--line-of-business-space);\n  display: grid;\n  grid-template-columns: 100%;\n  justify-content: center;\n  grid-row-gap: var(--line-of-business-space);\n  grid-template-areas:\n    \"image\"\n    \"title\"\n    \"description\"\n    \"quoteEntry\";\n}\n\n.productImage {\n  grid-area: image;\n}\n\n.productName {\n  text-align: center;\n  grid-area: title;\n}\n\n.productDescription {\n  text-align: center;\n  grid-area: description;\n}\n\n.quoteEntry {\n  grid-area: quoteEntry;\n}\n\n.startQuote {\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobContainer": "app__LineOfBusiness__lobContainer",
	"productImage": "app__LineOfBusiness__productImage",
	"productName": "app__LineOfBusiness__productName",
	"productDescription": "app__LineOfBusiness__productDescription",
	"quoteEntry": "app__LineOfBusiness__quoteEntry",
	"startQuote": "app__LineOfBusiness__startQuote"
};
export default ___CSS_LOADER_EXPORT___;
