import { defineMessages } from 'react-intl';

export default defineMessages({
    automobileClaimNumber: {
        id: 'portal.views.claim-summary-box.AutomobileClaimNumber',
        defaultMessage: 'Automobile claim #{claimNumber}'
    },
    rentersClaimNumber: {
        id: 'portal.views.claim-summary-box.RentersClaimNumber',
        defaultMessage: 'Renters claim #{claimNumber}'
    },
    claimNumber: {
        id: 'portal.views.claim-summary-box.ClaimNumber',
        defaultMessage: 'Claim #'
    },
    claimNumberAria: {
        id: 'portal.views.claim-summary-box.ClaimNumberAria',
        defaultMessage: 'Claim #{claimNumber}'
    },
    policyNumber: {
        id: 'portal.views.claim-summary-box.PolicyNumber',
        defaultMessage: 'Policy #'
    },
    policyNumberAria: {
        id: 'portal.views.claim-summary-box.PolicyNumberAria',
        defaultMessage: 'Claim #{claimNumber} is on Policy #{policyNumber}'
    },
    claimStatus: {
        id: 'portal.views.claim-summary-box.ClaimStatus',
        defaultMessage: 'Claim Status'
    },
    claimStatusAria: {
        id: 'portal.views.claim-summary-box.ClaimStatusAria',
        defaultMessage: 'Claim #{claimNumber} has a status of {status}'
    },
    completedDate: {
        id: 'portal.views.claim-summary-box.CompletedDate',
        defaultMessage: 'Completed Date'
    },
    completedDateAria: {
        id: 'portal.views.claim-summary-box.CompletedDateAria',
        defaultMessage: 'Claim #{claimNumber} completed on {completedDate}'
    },
    dateOfIncident: {
        id: 'portal.views.claim-summary-box.DateOfIncident',
        defaultMessage: 'Date of Incident'
    },
    dateOfIncidentAria: {
        id: 'portal.views.claim-summary-box.DateOfIncidentAria',
        defaultMessage: 'Claim #{claimNumber} occurred on {incidentDate}'
    },
    vehicle: {
        id: 'portal.views.claim-summary-box.Vehicle',
        defaultMessage: 'Vehicle'
    },
    vehicleAria: {
        id: 'portal.views.claim-summary-box.VehicleAria',
        defaultMessage: 'Claim #{claimNumber} involves a {vehicleDescription}'
    },
    multipleVehicles: {
        id: 'portal.views.claim-summary-box.MultipleVehicles',
        defaultMessage: 'Multiple vehicles'
    },
    multipleVehiclesAria: {
        id: 'portal.views.claim-summary-box.MultipleVehiclesAria',
        defaultMessage: 'Claim #{claimNumber} involves multiple vehicles'
    },
    pending: {
        id: 'portal.views.claim-summary-box.Pending',
        defaultMessage: 'Pending'
    },
    pendingAria: {
        id: 'portal.views.claim-summary-box.PendingAria',
        defaultMessage: 'Claim #{claimNumber} involves pending vehicle'
    },
    incidentLocation: {
        id: 'portal.views.claim-summary-box.IncidentLocation',
        defaultMessage: 'Incident Location'
    },
    incidentLocationAria: {
        id: 'portal.views.claim-summary-box.IncidentLocationAria',
        defaultMessage: 'Claim #{claimNumber} occurred at {locationDescription}'
    },
    claimType: {
        id: 'portal.views.claim-summary-box.ClaimType',
        defaultMessage: 'Claim Type'
    },
    claimTypeAria: {
        id: 'portal.views.claim-summary-box.ClaimTypeAria',
        defaultMessage: 'Claim #{claimNumber} is of type {claimType}'
    },
    ClaimDetailsAutoTrackButtonIdentifier: {
        id: 'portal.views.claim-summary-box.tracking.ClaimDetailsAuto',
        defaultMessage: 'Home Page - Auto Claim Summaries - Claim Details'
    }
    ,
    ClaimDetailsTrackButtonIdentifier: {
        id: 'portal.views.claim-summary-box.tracking.ClaimDetails',
        defaultMessage: 'Home Page - Claim Summaries - Claim Details'
    }
});
