// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICLiablityCoverages__gwCoverageWrapper{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);margin-bottom:var(--GW-LAYOUT-4)}.app__WMICLiablityCoverages__coveragesDetailsContainer{margin-top:var(--GW-LAYOUT-2)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/components/GL/WMICLiabilityCoverages/WMICLiablityCoverages.module.scss"],"names":[],"mappings":"AAIA,+CAEE,oEAAA,CADA,gCACA,CAGF,uDACE,6BAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\n.gwCoverageWrapper {\n  margin-bottom: var(--GW-LAYOUT-4);\n  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);\n}\n\n.coveragesDetailsContainer {\n  margin-top: var(--GW-LAYOUT-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwCoverageWrapper": "app__WMICLiablityCoverages__gwCoverageWrapper",
	"coveragesDetailsContainer": "app__WMICLiablityCoverages__coveragesDetailsContainer"
};
export default ___CSS_LOADER_EXPORT___;
