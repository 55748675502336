import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICVehicleInfoBox } from 'gw-capability-policy-react';

import metadata from './WMICVehiclesInfoBox.metadata.json5';
import styles from './WMICVehiclesInfoBox.module.scss';

const WMICVehiclesInfoBox = (props) => {
    const {
        lobData,
        jurisdiction,
        policyData
    } = props;

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wmicVehicleInfoBox: WMICVehicleInfoBox
        }
    };

    // eslint-disable-next-line radix,max-len
    const iterableArray = _.map(_.orderBy(lobData.vehicleDTOs, (vehicleDTO) => parseInt(vehicleDTO.vehicleNumber)), (vehicle) => {
        return {
            vehicle: vehicle,
            jurisdiction: jurisdiction,
            policyData: policyData
        };
    });

    const overrides = {
        vehiclesIterable: {
            data: iterableArray,
            visible: iterableArray.length > 0
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={lobData}
        />
    );
};

WMICVehiclesInfoBox.propTypes = {
    lobData: PropTypes.shape({
        vehicleDTOs: PropTypes.array
    }).isRequired,
    jurisdiction: PropTypes.shape({}).isRequired,
    policyData: PropTypes.shape({}).isRequired
};

export default WMICVehiclesInfoBox;
