function isRecurringPaymentDetailsHidden (step, currentstep) {
    return step.id === 'WMICRecurringPaymentDetails'
        && !(currentstep.id === 'WMICRecurringPaymentAuthorization' || currentstep.id === 'WMICRecurringPaymentDetails');
};

function onPageJump(index, { wizardSnapshot, updateWizardData, jumpTo }, viewModelService) {
    updateWizardData(viewModelService.clone(wizardSnapshot));
    jumpTo(index);
};

export  {
    isRecurringPaymentDetailsHidden,
    onPageJump
}