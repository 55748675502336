import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import PropTypes from 'prop-types';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import WMICAccordionCardHeader from './WMICAccordionCardHeader'

import styles from './WMICAccordionCard.module.scss'
import { AccordionCard } from '@jutro/legacy/components';

const WMICAccordionCard = (props) => {
    const { children, showErrors, parentNode, isValid, headerVariant, headerSize } = props;
    const [ errorState, setErrorState ] = useState(false);
    const [ bmsiWarning, setBmsiWarning ] = useState(false);
    const { wizardData: jobVM } = useContext(WizardContext) || {};

    useEffect(() => {
        if (showErrors && isValid === false) {
            setErrorState(true)
        } else if(showErrors && parentNode && isValid === undefined) {
            const childrenAreValid = WMICValidationUtil.checkChildNodesValid(children, parentNode);
            setErrorState(!childrenAreValid);
        } else {
            setErrorState(false);
        }
    }, [showErrors, isValid]);

    // Deriving stringified value of defaultedProperties_WMIC as update to the array is not detected by useEffect
    const defaultedProps = JSON.stringify(_.get(jobVM, 'defaultedProperties_WMIC.value'));

    useEffect(() => {
        const accordionContent = document.getElementById(props.id);
        if (accordionContent) {
            const bmsiElements = accordionContent.querySelectorAll("[data-bmsi-confirmed='false']");
            setBmsiWarning(bmsiElements?.length > 0);
        }

    }, [props, defaultedProps]);

    return (
        <AccordionCard
            {...props}
            renderHeader={(isOpen) => (
                <WMICAccordionCardHeader
                    isOpen={isOpen}
                    chevron={props.chevron}
                    chevronAlignment={props.chevronAlignment}
                    errorState={(showErrors && errorState) || bmsiWarning}
                    title={props.title}
                    isCollapsible={props.isCollapsible}
                    headerVariant={headerVariant}
                    headerSize={headerSize}
                />
            )}
            errorState={showErrors && errorState}
            className={cx(props.className, styles.wmicAccordionCard)}
            cardHeadingClassName={cx(
                props.cardHeadingClassName,
                styles.wmicAccordionCardHeader,
                { [styles.wmicAccordionCardHeaderError]: (showErrors && errorState) || bmsiWarning }
            )}
        />
    );
}

WMICAccordionCard.propTypes = {
    showErrors: PropTypes.bool.isRequired,
    parentNode: PropTypes.object,
    headerVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4',]),
    headerSize: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4',])
};

WMICAccordionCard.defaultProps = {
    headerVariant: 'h2',
    isCollapsible: true,
    chevron : true,
    chevronAlignment : 'left',
    headerSize : 'h2'
}

export default WMICAccordionCard;
