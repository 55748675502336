import WMICQuestionSetComponent from './WMICQuestionSetComponent/WMICQuestionSetComponent';
import WMICCheckbox from './WMICCheckbox/WMICCheckbox';
import WMICButton from './WMICButton/WMICButton';
import WMICAnimatedLoaderButton from './WMICAnimatedLoaderButton/WMICAnimatedLoaderButton';
import WMICCustomCircularCheckbox from './WMICCustomCircularCheckbox/WMICCustomCircularCheckbox';
import WMICDelinquencyModal from './WMICDelinquencyModal/WMICDelinquencyModal';
import WMICCurrencyField from './WMICCurrencyField/WMICCurrencyField';
import WMICDropdownSelect from './WMICDropdownSelect/WMICDropdownSelect';
// WMIC Hooks
import useFaIcons from './Hooks/useFaIcons';

const platformComponentMap = {
    questionSet: { component: 'WMICQuestionSetComponent' },
    checkbox: { component: 'WMICChekcbox' },
    WMICDelinquencyModal: { component: 'WMICDelinquencyModal' },
    WMICCurrencyField: { component: 'WMICCurrencyField' }
};

const platformComponents = {
    WMICQuestionSetComponent,
    WMICCheckbox,
    WMICButton,
    WMICCustomCircularCheckbox,
    WMICDelinquencyModal,
    WMICCurrencyField,
    WMICDropdownSelect
};

export {
    WMICQuestionSetComponent,
    WMICCheckbox,
    WMICButton,
    WMICAnimatedLoaderButton,
    WMICCustomCircularCheckbox,
    platformComponentMap,
    platformComponents,
    WMICDelinquencyModal,
    WMICCurrencyField,
    WMICDropdownSelect,
    // hooks
    useFaIcons
};
