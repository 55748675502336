import { defineMessages } from 'react-intl';

export default defineMessages({
    yourPolicyChangeCannotBeAccessedAtThisTimePleaseTryAgain1: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.Your policy change cannot be accessed 1st half ',
        defaultMessage: 'Your policy change cannot be processed at this time and requires additional review by our team.  Please call us at'
    },
    yourPolicyChangeCannotBeAccessedAtThisTimePleasePhoneNumber: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.Your policy change cannot be accessed phone number 800-640-2920',
        defaultMessage: '800-640-2920'
    },
    yourPolicyChangeCannotBeAccessedAtThisTimePleaseTryAgain2: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.Your policy change cannot be accessed 2nd half',
        defaultMessage: 'within 30 days to finish processing this request.  Our customer service team is available Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30 pm PT.'
    },
    yourPolicyChangeCannotBeAccessedAtThisTimeAriaMessage: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.yourPolicyChangeCannotBeAccessedAtThisTimeAriaMessage',
        defaultMessage: 'Your policy change cannot be processed at this time and requires additional review by our team. Please call us at 800-640-2920 within 30 days to finish processing this request. Our customer service team is available Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30 pm PT.'
    },
    pleaseProvideTransactionNumber: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.Please provide the transaction number when speaking with one of our agents',
        defaultMessage: 'Please provide the transaction number when speaking with one of our agents:'
    },
    policyChangeTransactionNumber: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.Transaction Number',
        defaultMessage: 'Transaction Number: {transactionNumber}'
    },
    wereSorry: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.We\'re Sorry',
        defaultMessage: 'We\'re Sorry'
    },
    close: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal-error.Close',
        defaultMessage: 'Close'
    }
});