import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Accordion, AccordionCard, InputField, Button, CurrencyField } from '@jutro/legacy/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './QuoteClauseTable.metadata.json5';
import styles from './QuoteClauseTable.module.scss';

const PAYMENT_TYPES = {
    monthly: 'monthly',
    annually: 'annually'
};

function QuoteClauseMobileTableHeader({
    dataForComponent,
    index,
    isFormValid,
    isOpen,
    isQuoteStale,
    isResetBranch,
    onBuyNow,
    onRecalculate,
    onResetCoverages,
    paymentToggle,
    shouldShowPriceDifference,
}) {
    // Due to having a new render content, there may be duplicate ID's
    const overrides = {
        monthlyAmountText: {
            visible: paymentToggle === PAYMENT_TYPES.monthly
        },
        annuallyAmountText: {
            visible: paymentToggle === PAYMENT_TYPES.annually
        },
        buyNowButton: {
            path: `columnData[${index}]`,
            visible: !isQuoteStale,
            onClick: onBuyNow
        },
        recalculateButton: {
            path: `columnData[${index}]`,
            visible: isQuoteStale,
            disabled: !isFormValid,
            onClick: onRecalculate
        },
        resetCoverages: {
            path: `columnData[${index}]`,
            visible: !isResetBranch || isQuoteStale || shouldShowPriceDifference,
            onClick: onResetCoverages
        },
        selectedCoverages: {
            visible: !isOpen
        },
        moreInfoContainer: {
            visible: !isOpen
        },
        accordionChevron: {
            isOpen
        },
        accordionStickyHeader: {
            className: classNames(styles.accordionStickyHeader, {
                [styles.mobileStickyHeader]: isOpen
            })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            Accordion,
            AccordionCard,
            InputField,
            Button,
            CurrencyField
        }
    };

    const metadataToRender = metadata.contentForMobileView.content;
    return (
        <ViewModelForm
            uiProps={metadataToRender}
            model={dataForComponent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

QuoteClauseMobileTableHeader.propTypes = {
    dataForComponent: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isFormValid: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isQuoteStale: PropTypes.bool.isRequired,
    isResetBranch: PropTypes.bool.isRequired,
    onBuyNow: PropTypes.func.isRequired,
    onRecalculate: PropTypes.func.isRequired,
    onResetCoverages: PropTypes.func.isRequired,
    paymentToggle: PropTypes.string.isRequired,
    shouldShowPriceDifference: PropTypes.bool.isRequired,
};

export default QuoteClauseMobileTableHeader;
