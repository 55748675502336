const PAConstants = (() => {

    const PACoveragesPaths = {
        PATH_TO_MOCK_DATA: 'quote.pa',
        VEHICLES_PATH: 'lobData.personalAuto.coverables.vehicles',
        POLICY_VIEW_VEHICLES_PATH: 'lobs.personalAuto.vehicleDTOs',
        OFFERINGS_PATH: 'lobData.personalAuto.offerings.children[0]',
        OFFERINGS_VALUE: 'lobData.personalAuto.offerings.value',
        COV_ID: 'coverageUniqueID'
    }

    const PADriverEndorsementsObjectConstants = {
        reductionDriverEndorsementsWMIC: 'reductionDriverEndorsements_WMIC',
        minorConvictionWaiverEndorsementsWMIC: 'minorConvictionWaiverEndorsements_WMIC',
        accidentWaiverEndorsementsWMIC: 'accidentWaiverEndorsements_WMIC',
        excludedDriverEndorsementsWMIC: 'excludedDriverEndorsements_WMIC',
        accidentWaiverMtcEndorsementsWMIC: 'accidentWaiverMtcEndorsements_WMIC',
        articleDriverEndorsementsWMIC: 'articleDriverEndorsements_WMIC',
        propertyDamageReimbursementDriverEndorsementsWMIC: 'propertyDamageReimbursementDriverEndorsements_WMIC',
    };

    const PAPhysicalDamageCoverages = {
        allPerilsCoverage: 'PA_APCov',
        comprehensiveCoverage: 'PA_ComprehensiveCov',
        collisionCoverage: 'PA_CollisionCov',
        specifiedPerils: 'PA_SPCov',
        restrictingGlassCoverage: 'PA_13C_CompLimitedGlass_WMIC'
    };

    const PAPhysicalDamageCoveragesPublicIDs = [
        {publicID: PAPhysicalDamageCoverages.comprehensiveCoverage},
        {publicID: PAPhysicalDamageCoverages.collisionCoverage},
        {publicID: PAPhysicalDamageCoverages.allPerilsCoverage},
        {publicID: PAPhysicalDamageCoverages.specifiedPerils},
        {publicID: PAPhysicalDamageCoverages.restrictingGlassCoverage}
    ];

    const PACommercialVehicleWeight = {
        ExtraHeavy: 'ExtraHeavy',
        ExtraHeavy_WMIC: 'ExtraHeavy_WMIC'
    };

    return {
        BIPDCovPublicId : 'PA_BIPDPackageCov',
        BIPDCovLimit: 'PA_BIPDPackageCovLimit',
        healthServicesLevyCovPublicId: 'PA_HospitalServicesLevy',
        vehicleBusinessSegmentCommercial: 'Commercial',
        vehicleBusinessSegmentPersonal: 'Personal',
        MaxEligibleAgeForGoodStudent: '25',
        MinEligibleAutonomousEmergencyBrakingSys: '2010',
        accidentWaiverCovPublicId: 'PA_AWECov',
        accidentWaiverMtcCovPublicId: 'PA_AWECov_MTC',
        reducAmtForNamedCov_28A_PublicId: 'PA_28A_ReducAmtForNamedCov',
        minorConvictionWaiver39bPublicId: 'PA_SEF39b_MinorConvictionWaiver',
        reducAmtForNamedCov_28_PublicId: 'PA_28_ReducAmtForNamedCov',
        reducAmtForNamedCov_28_LimitPatternCode: 'PA_28_ReducAmtForNamedCovLmt',
        article6ExcludedDriver_WMIC_PublicId: 'PA_Article6ExcludedDriver_WMIC',
        defaultPolDocPrefLang: 'english',
        reductionDriverEndorsementsWMIC: PADriverEndorsementsObjectConstants.reductionDriverEndorsementsWMIC,
        minorConvictionWaiverEndorsementsWMIC: PADriverEndorsementsObjectConstants.minorConvictionWaiverEndorsementsWMIC,
        accidentWaiverEndorsementsWMIC: PADriverEndorsementsObjectConstants.accidentWaiverEndorsementsWMIC,
        excludedDriverEndorsementsWMIC: PADriverEndorsementsObjectConstants.excludedDriverEndorsementsWMIC,
        accidentWaiverMtcEndorsementsWMIC: PADriverEndorsementsObjectConstants.accidentWaiverMtcEndorsementsWMIC,
        articleDriverEndorsementsWMIC: PADriverEndorsementsObjectConstants.articleDriverEndorsementsWMIC,
        personalAutoVehicleType: 'auto',
        trailerVehicleType: 'trailer',
        canteenTypeOfUse: 'Canteen',
        foodTruckTypeOfUse: 'FoodTruck',
        towTruckTypeOfUse: 'TowTruck',
        snowVehicleType: 'snow',
        atvVehicleType: 'atv',
        motorcycleVehicleType: 'motorcycle',
        motorhomeVehicleType: 'motorhome',
        mopedVehicleType: 'moped',
        primaryUseCommuting: 'commuting',
        allPerilsCoverage: PAPhysicalDamageCoverages.allPerilsCoverage,
        comprehensiveCoverage: PAPhysicalDamageCoverages.comprehensiveCoverage,
        collisionCoverage: PAPhysicalDamageCoverages.collisionCoverage,
        specifiedPerils: PAPhysicalDamageCoverages.specifiedPerils,
        restrictingGlassCoverage: PAPhysicalDamageCoverages.restrictingGlassCoverage,
        physicalDamageCoveragesPublicIDs: PAPhysicalDamageCoveragesPublicIDs,
        physicalDamageCoveragesCategory: 'PAPolicyHolderProperty_wmic',
        liabilityProtectionCoveragesCategory: 'PALiabilityProtection_wmic',
        postalCodeRatingVehicleTypes: ['auto', 'motorhome'],
        paCoveragesVehicleTypes:  ['auto', 'lighttruck', 'truckvan'],
        postalCodeRatingJurisdiction: 'AB',
        additionalInterestAssignee: 'ASSIGNEE_WMIC',
        directCompensationManualLossTypeCode: 'direct_compensation',
        automobilePackageCoverage: 'PA_AutoPackageCov_WMIC',
        permissionToRentOrLeaseCoverage: 'PA_05_PRLCov',
        lienholderProtectionCoverage: 'PA_23A_LienholderProtectionCov',
        directCompensationPropertyDamageCoverage: 'PA_DCPDCov',
        uninsuredAutomobileCoverage: 'PA_UACov',
        restrictedPermissionToDriveForAnInsuredTransportationNetwork: 'PA_RestrictedPermissionRideShareCoverage_WMIC',
        limitationOfAmountCoverage: 'PA_19_LimitationOfAmountCov',
        civilLiabilityForPhysicalDamageToANonOwnedVehicle: 'PA_27A_CLPDNVehicleCov_WMIC',
        vehiclesOfWhichNamedInsuredIsNotOwnerCoverage: 'PA_2_VehWhichNamedInsuredNotOwned_WMIC',
        lossOfUseCoverage: 'PA_20_LOUCov',
        changeToDeductiblesCoverage: 'PA_41_ChangDedCov_WMIC',
        accidentBenefitsCoverage: 'PA_34_AccBenefitsCov_WMIC',
        heavyNumRentals : 'PA_27B_NOAHeavyNumRentals',
        principalDriverType: 'principal',
        commercialVehicleWeight: PACommercialVehicleWeight,
        carrierNameOtherCode: '1137',
        daysBackMVRDate: 59,
        ageAllowedToDrive: 14,
        minimumVehicleAgeForInspection: 12,
        maxVehicleYear: 1900,
        trailerSubTypeQC: 'QCTrailerSubType_WMIC',
        trailerSubTypeNotQC: 'TrailerSubTypeOtherThanQC_WMIC',
        motorcycleClasses: ['6', '6A', '6B', '6C', '6D', '6E', '6R', 'classm', 'classmwithlcondition', 'classmwithmcondition',
            'classm1', 'classm2', 'classm2withlcondition', 'classm2withmcondition'],
        MAX_VIN_LENGTH: 17,
        PACoveragesPaths,
        thirdPartyLiabililityLimitNames: ["PA_CivilLiabilityCov_WMIC", "PA_BIPDPackageCov"],
        customizationAttachedEquipment: 'AttachedEquipment',
        customizationAltered: 'CustomizedAltered',
        physicalDamageCoverages: 'physicalDamage',
        dataMigrationNull_WMIC: 'DataMigrationNull_WMIC',
        lineAdditionalCoveragesCategory: 'PALineAdditional_WMIC',
        lineCommercialCoveragesCategory: 'PALineCommercial_WMIC',
        lineMTCGrpWMICCoveragesCategory: 'PALineMTCGrp_WMIC',
        linePAOptionalAccBenefitsWMICCoveragesCategory: 'PAOptionalAccBenefits_WMIC'
    };
})();

export default PAConstants;
