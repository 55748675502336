import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WMICIcon } from "wmic-pe-components-platform-react";
import cx from 'classnames';
import styles from './WMICLink.module.scss';
import { Link } from '@jutro/router';

// The "displayOnly" boolean prop renders a span with the styling of a regular WMICLink
const WMICLink = ({
    className: passedClassName,
    children,
    displayOnly,
    iconProps,
    ...props
}) => {
    const classes = cx(
        displayOnly ? styles.wmicMimicLink : styles.wmicLink,
        displayOnly && props.disabled ? styles.wmicMimicLinkDisabled : null,
        passedClassName
    );
    const hasIcon = props.hasOwnProperty('icon');

    return (
        /*
         * overflow: 'visible' is set here as inline style because
         * data table cells set visibility to hidden with
         * much higher specifity than can be easily achieved here without using !important.
         * this results in links focus style correctly rendering in table cells
         */
        displayOnly ? (
            <span className={classes}>
                {hasIcon && <WMICIcon icon={props.icon} {...iconProps}/>}
                <span>{children}</span>
            </span>
        ) : (
            <span style={{ overflow: 'visible' }}>
                <Link
                    {...props}
                    className={classes}
                    textClassName={styles.wmicLinkText}
                    aria-label={_.get(iconProps, 'aria-label', "")}
                >
                    {children}
                </Link>
            </span>
        )
    );
};


WMICLink.propTypes = {
    displayOnly: PropTypes.bool
};

WMICLink.defaultProps = {
    displayOnly: false,
}

export default WMICLink;
