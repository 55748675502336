/* eslint-disable no-unused-vars */
import React, {
    useEffect,
    useCallback,
    useContext,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import WMICHOBoatDetails from '../WMICHOBoatDetails/WMICHOBoatDetails';
import metadata from './WMICHOWatercraftPersonalDetails.metadata.json5';
import { useValidation } from '@xengage/gw-portals-validation-react'

function WMICHOWatercraftPersonalDetails({
    id,
    onValidate,
    showErrors,
    isEditMode,
}) {
    const {
        onValidate: setComponentValidation,
        isComponentValid,
    } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const [currentBoat, setCurrentBoat] = useState({});
    const [loading, setLoading] = useState(true);
    const { updateWatercraftRiskView, watercraftRiskView, jobVM } = useContext(WatercraftContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid]);

    const createBoatComponent = useCallback(() => {
        const newBoat = {
            subtype: 'HOWatercraftBoat_WMIC',
            equipmentInfo: {
                boatInfo: {
                    boatType: 'PersonalWatercraft',
                    use: 'Pleasure',
                },
            },
        };
        const newComponent = viewModelService.create(
            newBoat,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.HOWatercraftComponentDTO_WMIC',
            {...jobVM.aspects.context(), ...watercraftRiskView.aspects.context()}
        );
        _.set(watercraftRiskView, 'watercraftComponents', [])
        return newComponent;
    }, [viewModelService]);

    useEffect(() => {
        if (watercraftRiskView.watercraftComponents.length === 0) {
            const newBoat = createBoatComponent();
            setCurrentBoat(newBoat);
            watercraftRiskView.watercraftComponents.pushElement(newBoat);
        } else {
            setCurrentBoat(watercraftRiskView.watercraftComponents.getElement(0));
        }
        setLoading(false);
    }, [watercraftRiskView])

    const overrideProps = {
        '@field': {
            readOnly: !isEditMode,
            parentNode: watercraftRiskView,
        },
        boatInfoBoatTypeSelect: {
            readOnly: true,
        },
        wmichoBoatDetails: {
            readOnly: !isEditMode,
            boatData: currentBoat,
            boatTypeReadOnly: true,
            onValidate: setComponentValidation,
            showErrors,
            baseData: _.get(jobVM, 'baseData')
        },
    };

    const componentMap = {
        WMICHOBoatDetails
    };

    if (loading) {
        return null;
    }

    return (
        <ViewModelForm
            model={currentBoat}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={updateWatercraftRiskView}
            onValidationChange={setComponentValidation}
            componentMap={componentMap}
            showErrors={showErrors}
        />
    );
}

WMICHOWatercraftPersonalDetails.propTypes = {
    readOnly: PropTypes.bool,
};

WMICHOWatercraftPersonalDetails.defaultProps = {
    readOnly: false,
};

export default WMICHOWatercraftPersonalDetails;
