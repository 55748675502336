import { useCallback, useState } from 'react';

export function useTokenRefresher() {
    // this is used to track token updates
    const [tokenLastUpdated, setTokenLastUpdated] = useState(Date.now());

    const forceTokenUpdate = useCallback(() => {
        setTokenLastUpdated(Date.now());
    }, []);

    return {
        forceTokenUpdate,
        tokenLastUpdated
    };
}
