// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICVinSearchComponent__vinSearchVin input{text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-pa-react/components/WMICVehicleInfo/WMICVinSearch/WMICVinSearchComponent.module.scss"],"names":[],"mappings":"AACI,iDACI,wBAAA","sourcesContent":[".vinSearchVin {\n    input{\n        text-transform: uppercase;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vinSearchVin": "app__WMICVinSearchComponent__vinSearchVin"
};
export default ___CSS_LOADER_EXPORT___;
