import { defineMessages } from 'react-intl';

export default defineMessages({
    removeDocument: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmRemoveDocument: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove {documentName}?'
    },
    documentRemoveConfirmYes: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Yes',
        defaultMessage: 'Yes'
    },
    documentRemoveConfirmNo: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.No',
        defaultMessage: 'No'
    },
    documentDeletionFailed: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Document deletion failed',
        defaultMessage: 'Document deletion failed'
    },
    documentCannotDeleted: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Sorry your document could not be deleted at this time',
        defaultMessage: 'Sorry your document could not be deleted at this time'
    },
    documentDateUploaded: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Date Uploaded',
        defaultMessage: 'Date Uploaded'
    },
    documentUploadFailed: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Document upload failed',
        defaultMessage: 'Document upload failed'
    },
    documentCannotUploaded: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Failed to upload file, either you do not have permission or a file exists with the same name.',
        defaultMessage: 'Failed to upload file, either you do not have permission or a file exists with the same name.'
    },
    noDocumentFound: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.No Documents Found.',
        defaultMessage: 'No Documents Found.'
    },
    maxFileSizeLabel: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Maximum file size",
        defaultMessage: "Maximum file size"
    },
    maxFileSizeValue: {
        id: "wmic-pe-capability-gateway-common-react.Documents.File size limit",
        defaultMessage: "{maxFileSizeMB} MB"
    },
    maxFileSizeValueExceeded: {
        id: "wmic-pe-capability-gateway-common-react.Documents.File size limit exceeded",
        defaultMessage: "File cannot exceed {maxFileSizeMB} MB in size"
    },
    filesPerUpload: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Files per upload",
        defaultMessage: "Files per upload:"
    },
    filesPerUploadValue: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Files per upload amount",
        defaultMessage: "{maxNumberOfFiles}"
    },
    fileTypesLabel: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Document file types",
        defaultMessage: "Document file types:"
    },
    unsupportedFileType: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Unsupported file type",
        defaultMessage: "File type not supported"
    },
    fileTypesValue: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Document file types value",
        defaultMessage: "Word, Excel, PDF"
    },
    imageFileTypesLabel: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Image file types:",
        defaultMessage: "Image file types:"
    },
    imageFileTypesValue: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Image file types value",
        defaultMessage: ".jpg, .gif, .png"
    },
    filesUploadInProgress: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Please wait while your documents are uploaded",
        defaultMessage: "Please wait while your documents are uploaded"
    },
    filesUploadComplete: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Complete",
        defaultMessage: "Complete"
    },
    filesUploadCancel: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Cancel",
        defaultMessage: "Cancel"
    },
    uploadingDocuments: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Uploading Documents",
        defaultMessage: "Uploading Documents"
    },
    cancelAllUploads: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Cancel All Uploads",
        defaultMessage: "Cancel All Uploads"
    },
    closeModal: {
        id: "wmic-pe-capability-gateway-common-react.Documents.Close",
        defaultMessage: "Close"
    },
    newDocument: {
        id: "wmic-pe-capability-gateway-common-react.Documents.New",
        defaultMessage: "New"
    },
    emptyDocumentTable: {
        id: "wmic-pe-capability-gateway-common-react.Documents.There are no documents associated with this Submission",
        defaultMessage: "There are no documents associated with this Submission"
    },
    documentDeleted: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.FileUploadViewerComponent.This file has been deleted. Click X to clear from this section.',
        defaultMessage: 'This file has been deleted. Click X to clear from this section.'
    },
    documentUpload:{
        id:'wmic-pe-capability-gateway-common-react.Documents.Document Upload',
        defaultMessage:'Drag and drop files'
    },
    emptyFile: {
        id: 'wmic-pe-capability-gateway-common-react.Documents.Empty File',
        defaultMessage: 'Unable to upload empty file'
    }

});
