import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { Flex, FlexItem } from '@jutro/layout';
import { LobIconUtil } from 'wmic-portals-utils-js';
import styles from './WMICOverduePolicy.module.scss';
import messages from './WMICOverduePolicies.messages';


const WMICOverduePolicy = ({ id, type, line, onClick }) => {
    const translator = useTranslator();

    const getPolicyTypeTitle = (pType) => {
        switch (pType) {
            case 'Personal Auto':
                return translator(messages.automobilePolicyShort).toLowerCase();
            case 'Renters':
                return translator(messages.rentersPolicy).toLowerCase();
            case 'Homeowners':
                return translator(messages.homeownersPolicy).toLowerCase();
            case 'Condominium':
                return translator(messages.condominiumPolicy).toLowerCase();
            default:
                return translator(messages.genericPolicy).toLowerCase();
        }
    }

    const getIconClass = () => {
        const icon = LobIconUtil.getWmicIcon(line);
        return `fa ${icon} titleIconRed`;
    }

    return (
        <section role='alert'>
            <button
                role='link'
                className={cx('ww-link-button', styles.card)}
                onClick={() => onClick()}
            >
                <Flex alignItems="center">
                    <FlexItem>
                        <i className={`${styles.icon} ${getIconClass()}`} />
                    </FlexItem>
                    <FlexItem textAlign="left">
                        <h2 className={styles.policyNotice}>
                            {translator(messages.policyHasOverdueAmount, {
                                policyType: getPolicyTypeTitle(type),
                            })}
                        </h2>
                        <div>
                            {translator(messages.policy, { policyNumber: id })}
                        </div>
                    </FlexItem>
                </Flex>
            </button>
        </section>

    );
};

WMICOverduePolicy.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default WMICOverduePolicy;