import React from 'react';
import cx from 'classnames';
import styles from './WMICAccordionCard.module.scss';
import { AccordionCard } from '@jutro/legacy/components';

export default function WMICAccordionCard({
    className,
    cardHeadingClassName,
    cardTitleClassName,
    collapseClassName,
    cardBodyClassName,
    ...props
}) {
    const newClass = cx(
        styles['wmic-fixed-heigh'],
        styles['wmic-accordion-card'],
        className
    );
    const cardHeadingClass = cx(
        styles['wmic-accordion-card-heading'],
        cardHeadingClassName
    );
    const cardTitleClass = cx(
        styles['wmic-accordion-title'],
        cardTitleClassName
    );
    const collapseClass = cx(
        styles['wmic-accordion-collapse'],
        collapseClassName
    );
    const cardBodyClass = cx(
        styles['wmic-accordion-card-body'],
        cardBodyClassName
    );

    return (
        <AccordionCard
            className={newClass}
            cardHeadingClassName={cardHeadingClass}
            cardTitleClassName={cardTitleClass}
            collapseClassName={collapseClass}
            cardBodyClassName={cardBodyClass}
            {...props}
        />
    );
}
