import { defineMessages } from 'react-intl';

export default defineMessages({
    yourProfile: {
        id: 'usersprofile.views.amp-users-profile.Your Profile',
        defaultMessage: 'Your Profile'
    },
    changeYourPassword: {
        id: 'usersprofile.views.amp-users-profile.Change Your Password',
        defaultMessage: 'Change Your Password'
    },
    preferredContact: {
        id: 'usersprofile.views.amp-users-profile.Preferred Method of Contact',
        defaultMessage: 'Preferred Method of Contact'
    },
    phoneInformation: {
        id: 'usersprofile.views.amp-users-profile.Phone Information',
        defaultMessage: 'Phone Information'
    },
    phone: {
        id: 'usersprofile.views.amp-users-profile.Phone',
        defaultMessage: 'Phone'
    },
    phoneValidationError: {
        id: 'usersprofile.views.amp-users-profile.Value entered must be a valid phone number',
        defaultMessage: 'Value entered must be a valid phone number'
    },
    savedBankAccounts: {
        id: 'usersprofile.views.amp-users-profile.Saved bank accounts',
        defaultMessage: 'Saved bank accounts'
    },
    savedPaymentMethods: {
        id: 'usersprofile.views.amp-users-profile.Saved payment methods',
        defaultMessage: 'Saved payment methods'
    },
    saveChanges: {
        id: 'usersprofile.views.amp-users-profile.Save Changes',
        defaultMessage: 'Save Changes'
    },
    preferredMethod: {
        id: 'usersprofile.views.amp-users-profile.Your preferred method of contact is currently set to',
        defaultMessage: 'Your preferred method of contact is currently set to'
    },
    preferredMethodAria: {
        id: 'usersprofile.views.amp-users-profile.Your preferred method of contact is currently set to aria',
        defaultMessage: 'Your preferred method of contact is currently set to {pmocType}'
    },
    firstName: {
        id: 'usersprofile.views.amp-users-profile.first name',
        defaultMessage: 'First Name'
    },
    lastName: {
        id: 'usersprofile.views.amp-users-profile.last name',
        defaultMessage: 'Last Name'
    },
    email: {
        id: 'usersprofile.views.amp-users-profile.Email',
        defaultMessage: 'Email'
    },
    prefix: {
        id: 'usersprofile.views.amp-users-profile.Prefix',
        defaultMessage: 'Prefix'
    },
    changeEmail: {
        id: 'usersprofile.views.amp-users-profile.To change your email, please call us at',
        defaultMessage: 'To change your email, please call us at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt;'
    },
    denotes: {
        id: 'usersprofile.directives.templates.pmd-consent-modal.inputs.Denotes your primary phone method below.',
        defaultMessage: '* Denotes your primary phone method below.'
    },
    close: {
        id: 'usersprofile.views.manage-bank-account.Close',
        defaultMessage: 'Close'
    },
    savedSuccessfully: {
        id: 'userprofile.controllers.usersprofilectrl.Your changes were saved successfully',
        defaultMessage: 'Your changes were saved successfully'
    },
    somethingWentWrong: {
        id: 'userprofile.controllers.usersprofilectrl.Something went wrong and we could not save your changes. Please Contact Us for help updating your profile.',
        defaultMessage: 'Something went wrong and we could not save your changes. Please contact our Online Account Services toll free at: 1-888-363-7984 Monday to Friday 7:30 AM to 7:30 PM or Saturdays 8:00 AM to 4:30 PM'
    },
    bankAccountsCanBeSaved: {
        id: 'usersprofile.views.amp-users-profile.Bank accounts can be saved and used for future',
        defaultMessage: 'Bank accounts can be saved and used for future online payments. Below are the current bank accounts on record.'
    },
    paymentMethodsCanBeSaved: {
        id: 'usersprofile.views.amp-users-profile.Payment methods can be saved and used for future online payments.',
        defaultMessage: 'Payment methods can be saved and used for future online payments.'
    }
});
