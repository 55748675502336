import { defineMessages } from 'react-intl';

export default defineMessages({
    emailQuote: {
        id: 'wmic.quoteandbind.directives.templates.QnbWizardButtons.Email Quote',
        defaultMessage: 'Email Quote',
    },
    emailQuoteDescription: {
        id: 'wmic.quoteandbind.directives.QnbWizardButtons.We will save your quote and send its number',
        defaultMessage: 'Your quote details have been sent to {emailAddress}.',
    },
    error: {
        id: 'wmic.quoteandbind.directives.QnbWizardButtons.Error',
        defaultMessage: 'Error',
    },
    cantSendQuoteDetailsAtThisTime: {
        id: 'wmic.quoteandbind.directives.QnbWizardButtons.Sorry, we were not able to email the quote details at this time.',
        defaultMessage: 'Sorry, we were not able to email the quote details at this time.',
    },
    quoteMailed: {
        id: 'wmic.quoteandbind.common.Your quote has been emailed',
        defaultMessage: 'Your quote has been emailed'
    }
});
