import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { SUBMISSION_PATHS, JURISDICTIONS, PAConstants, WMICRPCUtil, WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import { WMICHeading } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import WMICDriverEndorsementsCoverageComponent from './WMICDriverEndorsementsCoverageComponent/WMICDriverEndorsementsCoverageComponent';
import metadata from './WMICDriverEndorsementsComponent.metadata.json5';
import messages from './WMICDriverEndorsementsComponent.messages';

function WMICDriverEndorsementsComponent(props) {
    const {
        id,
        jobVM,
        onClauseChange,
        isVehicle,
        selectedVehicleId,
        readOnly,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const coverages = _.get(jobVM, `${PAConstants.PACoveragesPaths.OFFERINGS_PATH}.coverages.driverEndorsements_WMIC`);
    const vehicleCoverages = _.get(jobVM, `${PAConstants.PACoveragesPaths.OFFERINGS_PATH}.coverages.vehicleCoverages.children[0].coverages.value`);
    const bipdCov = _.find(vehicleCoverages, {publicID: PAConstants.BIPDCovPublicId});
    const bipdCovLimit = _.find(bipdCov?.terms, {patternCode: PAConstants.BIPDCovLimit});
    const reducAmtForNamedCov_28 = _.find(coverages.value, {publicID: PAConstants.reducAmtForNamedCov_28_PublicId});
    const baseState = _.get(jobVM, SUBMISSION_PATHS.BASE_STATE_VALUE);
    const rateAsOfDate = _.get(jobVM, SUBMISSION_PATHS.RATE_AS_OF_DATE_VALUE);
    const reductionDriverEndorsementsForSelectedVehicle = _.find(_.get(jobVM, 'lobData.personalAuto.endorsements_Ext.reductionDriverEndorsements_WMIC.value'), {vehiclePublicID: selectedVehicleId})

    const getConvictionHeading = useCallback(() => {
        if (baseState === JURISDICTIONS.ONTARIO) {
            return messages.minorConvictionWaiverON;
        }
        if (WMICRPCUtil.getIsRPCEffective(baseState, rateAsOfDate, '1582')) {
            return messages.minorConvictionWaiverMaritimes;
        }
        return messages.trafficSafetyConvictionWaiver;
    }, [baseState, rateAsOfDate]);

    const getCov28Heading = useCallback(() => {
        let limit;

        if (baseState === JURISDICTIONS.NOVA_SCOTIA && bipdCovLimit) {
            limit = _.find(bipdCovLimit.options, {code: 'zqdhgmt4724ercknce4ksonb7d9'}).name;
        } else if ((baseState === JURISDICTIONS.NEW_BRUNSWICK || JURISDICTIONS.PRINCE_EDWARD_ISLAND) && bipdCovLimit) {
            limit = bipdCovLimit.options[0].name;
        } else if ((baseState === JURISDICTIONS.QUEBEC && reducAmtForNamedCov_28)) {
            const termLimit = WMICPACoverageUtil.getCoverageTermLimit(reducAmtForNamedCov_28.terms, PAConstants.reducAmtForNamedCov_28_LimitPatternCode)
            limit = termLimit && termLimit.options ? termLimit.options[0].name : undefined;
        }

        return limit ? WMICRichTextUtil.translateRichText(translator(messages.reductionOfCoverageWithLimit, {driverLimit: limit})) : undefined;
    }, [baseState, bipdCovLimit, reducAmtForNamedCov_28, translator]);

    const getDriverEndorsementHeading = useCallback((covPublicId) => {
        let heading = '';

        switch (covPublicId) {
            case PAConstants.accidentWaiverCovPublicId:
                heading = messages.accidentWaiverEndorsement;
                break;
            case PAConstants.accidentWaiverMtcCovPublicId:
                heading = messages.accidentWaiverEndorsementMotorcycle;
                break;
            case PAConstants.minorConvictionWaiver39bPublicId:
                heading = getConvictionHeading();
                break;
            case PAConstants.reducAmtForNamedCov_28_PublicId:
                heading = getCov28Heading();
                break;
            case PAConstants.article6ExcludedDriver_WMIC_PublicId:
                heading = messages.article6;
                break;
            case PAConstants.reducAmtForNamedCov_28A_PublicId:
                heading = messages.exludedDriver;
                break;
            default:
                return null;
        }

        return translator(heading);
    }, [translator, getConvictionHeading, getCov28Heading]);

    const isAccidentWaiverEndorsementVisible = useCallback(() => {
        return baseState !== JURISDICTIONS.QUEBEC && 
            _.find(coverages.value, {publicID: PAConstants.accidentWaiverCovPublicId}) === undefined;
    }, [baseState, coverages.value]);    

    const getDriverEndorsements = useCallback(() => {
        const endorsements = viewModelService.clone(coverages);
        
        if (isAccidentWaiverEndorsementVisible()) {
            endorsements.value.push({
                publicID: PAConstants.accidentWaiverCovPublicId,
            });
        }
        
        return endorsements.value;
    }, [coverages, isAccidentWaiverEndorsementVisible, viewModelService]);

    const renderDriverEndorsements = () => {
        const endorsements = getDriverEndorsements();

        return endorsements.filter((endorsement) => {
            return !WMICPACoverageUtil.getIsVehicleDriverEndorsement(endorsement.coverageUniqueID);
        }).map((endorsement) => {
            return (
                <>
                    <WMICHeading
                        id={`${id}_heading`}
                        underlined
                        title={getDriverEndorsementHeading(endorsement.publicID)}
                        className="gw-mb-3"
                    />
                    <WMICDriverEndorsementsCoverageComponent
                        id={endorsement.coverageUniqueID} 
                        driverEndorsement={endorsement}
                        offeredCoverages={coverages}
                        jobVM={jobVM}
                        baseState={baseState}
                        rateAsOfDate={rateAsOfDate}
                        onClauseChange={onClauseChange}
                        hideUnchecked={false}
                        readOnly={readOnly}
                    />
                </>
            )
        })
    }

    const renderDriverEndorsementsForVehicle = () => {
        if (!_.isUndefined(reductionDriverEndorsementsForSelectedVehicle)) {
            return coverages.children
                .filter(endorsement =>
                    WMICPACoverageUtil.getIsVehicleDriverEndorsement(
                        endorsement.publicID.value
                    )
                )
                .map(endorsement => {
                    const headingLabel = getDriverEndorsementHeading(endorsement.coverageUniqueID.value);
                    return (
                        <React.Fragment>
                            {headingLabel && (
                                <WMICHeading
                                    id={`${id}_heading`}
                                    underlined
                                    title={headingLabel}
                                    className="gw-mb-3"
                                />
                            )}
                            <WMICDriverEndorsementsCoverageComponent
                                id={endorsement.coverageUniqueID.value}
                                driverEndorsement={endorsement.value}
                                offeredCoverages={coverages}
                                jobVM={jobVM}
                                baseState={baseState}
                                onClauseChange={onClauseChange}
                                selectedVehicleId={selectedVehicleId}
                                readOnly={readOnly}
                                hideUnchecked
                            />
                        </React.Fragment>
                    );});
        }
        return null;
    };


    const overrideProps = {
        driverEndorsementsCoveragesContainer: {
            content: isVehicle ? renderDriverEndorsementsForVehicle() : renderDriverEndorsements()
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICDriverEndorsementsCoverageComponent
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICDriverEndorsementsComponent.propTypes = {
    id: PropTypes.string.isRequired,
    jobVM: PropTypes.shape({}).isRequired,
    onClauseChange: PropTypes.func.isRequired,
    isVehicle: PropTypes.bool,
    selectedVehicleId: PropTypes.string
};

export default WMICDriverEndorsementsComponent;
