/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { ModalNext, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { WMICManageBankingInformation, WMICBankAccount } from 'gw-capability-policy-react';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICPaymentService } from 'wmic-portals-utils-js';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import { WMICCheckbox, WMICButton } from 'wmic-components-platform-react';
import messages from './WMICManageBankAccountModal.messages';

const WMICManageBankAccountModal = (props) => {
    const {
        accountSummary, bankAccountInfo, mode, refreshAfterUpdate, isOpen, onReject, ldFlags
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const DOM_ELEMENT_IDS = {
        AGREE_BANKING_INFO_STORAGE_CHECKBOX: 'agreeBankingInfoStorage',
    };
    const DISPLAY_MODES = {
        ADD_BANK_ACCOUNT: 'addBankAccount',
        ADD_BANK_ACCOUNT_SUCCESS: 'addBankAccountSuccess',
        ADD_BANK_ACCOUNT_FAILURE: 'addBankAccountFailure',
        BANK_ACCOUNT_INVALID_ROUTING: 'bankAccountInvalidRouting',
        MANAGE_BANKING_INFORMATION: 'manageBankingInformation'
    };

    const [bankAccountData, updateBankAccountData] = useState(bankAccountInfo);
    const [bankAccountsLoading, updateBankAccountsLoading] = useState(false);
    const [isBankAccountSaved, updateIsBankAccountSaved] = useState(undefined);
    const [agreeBankingInfoStorage, updateAgreeBankingInfoStorage] = useState(false);
    const [isFormValid, updateIsFormValid] = useState(false);
    const [displayMode, updateDisplayMode] = useState(undefined);

    useEffect(() => {
        updateDisplayMode(mode === 'newBankAccount'
            ? DISPLAY_MODES.ADD_BANK_ACCOUNT : DISPLAY_MODES.MANAGE_BANKING_INFORMATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getModalHeader = () => {
        switch (displayMode) {
            case DISPLAY_MODES.ADD_BANK_ACCOUNT_SUCCESS:
                return translator(messages.bankAccountSaved);
            case DISPLAY_MODES.ADD_BANK_ACCOUNT_FAILURE:
                if (!isBankAccountSaved?.isDuplicate) {
                    return translator(messages.thereWasAnError);
                }
                return translator(messages.bankAccountAlreadyExists);
            case DISPLAY_MODES.BANK_ACCOUNT_INVALID_ROUTING:
                return translator(messages.invalidRoutingTransitNumber);
            default:
                return translator(messages.addBankAccount);
        }
    };

    const onClose = () => {
        onReject();
    };

    const onSaveBankAccount = () => {
        updateBankAccountsLoading(true);
        updateIsFormValid(false);
        WMICPaymentService.validateABA(bankAccountData.bankABANumber)
            .then((response) => response.json())
            .then((response) => {
                if (response?.ABAResult === 'true') { // don't save the bank account unless valid
                    bankAccountData.bankName = response?.ABABankName;
                    const policyNumber = null;
                    const saveConsent = true;
                    AccountBillingDetailsService.addNewBankingInfoWithConsentType(
                        accountSummary?.accountNumber, bankAccountData,
                        accountSummary?.publicID, 'YourProfile', policyNumber, saveConsent, authHeader
                    ).then((bankAccountAddedResult) => {
                        updateIsBankAccountSaved(bankAccountAddedResult);
                        updateBankAccountsLoading(false);
                        if (bankAccountAddedResult?.result && !bankAccountAddedResult?.isDuplicate) {
                            updateDisplayMode(DISPLAY_MODES.ADD_BANK_ACCOUNT_SUCCESS);
                        } else {
                            updateDisplayMode(DISPLAY_MODES.ADD_BANK_ACCOUNT_FAILURE);
                        }
                    }).finally(() => {
                        updateBankAccountsLoading(false);
                    });
                } else {
                    updateBankAccountsLoading(false);
                    updateDisplayMode(DISPLAY_MODES.BANK_ACCOUNT_INVALID_ROUTING);
                }
            }, () => {
                updateBankAccountsLoading(false);
                updateDisplayMode(DISPLAY_MODES.ADD_BANK_ACCOUNT_FAILURE);
            });
    };

    const canProceed = () => {
        return agreeBankingInfoStorage && isFormValid;
    };

    const onBankAccountChange = (changeData, isValid) => {
        updateIsFormValid(isValid);
        updateBankAccountData(changeData);
    };

    const getButtonContent = (message) => {
        return bankAccountsLoading ? <WMICLoader isInline /> : translator(message);
    };

    const onCloseAfterAction = () => {
        refreshAfterUpdate();
        onReject();
    };

    const onBackToAddBankAccount = () => {
        updateDisplayMode(DISPLAY_MODES.ADD_BANK_ACCOUNT);
    };

    const handleClose = () => {
        onReject();
    };
    
    return (
        <ModalNext 
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc>
            {displayMode === DISPLAY_MODES.MANAGE_BANKING_INFORMATION && (
                <WMICManageBankingInformation
                    accountInfo={accountSummary}
                    bankAccountInfo={bankAccountData}
                    mode={mode}
                    onCloseAfterAction={onCloseAfterAction}
                    applicationFlow="YourProfile"
                    ldFlags={ldFlags}
                />
            )}
            {displayMode !== DISPLAY_MODES.MANAGE_BANKING_INFORMATION && (
                <div>
                    <h3 className='wmic-modal-header-title'>{getModalHeader()}</h3>
                    <ModalBody>
                        {displayMode === DISPLAY_MODES.ADD_BANK_ACCOUNT && (
                            <div>
                                <WMICBankAccount
                                    bankInfo={bankAccountData}
                                    onChange={onBankAccountChange}
                                    newData
                                />
                                {!!bankAccountData?.bankAccountType && (
                                    <div className="wmic-authorization-disclaimer-container">
                                        <h4 className="wmic-authorization-disclaimer-title">{translator(messages.authorization)}</h4>
                                        <p className="wmic-authorization-disclaimer-legal-text">{translator(messages.iAuthorize)}</p>
                                        <WMICCheckbox
                                            id={DOM_ELEMENT_IDS.AGREE_BANKING_INFO_STORAGE_CHECKBOX}
                                            value={agreeBankingInfoStorage}
                                            label={translator(messages.agreeSaveBankAccount)}
                                            labelClassName="wmic-authorization-disclaimer-agree-text"
                                            onValueChange={
                                                (newValue) => updateAgreeBankingInfoStorage(newValue)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {displayMode === DISPLAY_MODES.ADD_BANK_ACCOUNT_SUCCESS && (
                            <div className="ww-content-limit ww-payment">
                                <p>{translator(messages.successSavingBankingInfo)}</p>
                            </div>
                        )}
                        {(displayMode === DISPLAY_MODES.ADD_BANK_ACCOUNT_FAILURE) && (
                            <div className="ww-content-limit ww-payment">
                                {(!isBankAccountSaved?.isDuplicate) && (
                                    <p>{translator(messages.somethingWentWrong)}</p>
                                )}
                                {(isBankAccountSaved?.isDuplicate) && (
                                    <p>{translator(messages.duplicateAccountNumber)}</p>
                                )}
                            </div>
                        )}
                        {displayMode === DISPLAY_MODES.BANK_ACCOUNT_INVALID_ROUTING && (
                            <div className="ww-content-limit ww-payment">
                                <p>{translator(messages.routingTransitNumberInvalid)}</p>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {displayMode === DISPLAY_MODES.ADD_BANK_ACCOUNT && (
                            <div className="ww-btn-group-gap-2">
                                <WMICButton
                                    onClick={onClose}
                                    type="outlined"
                                >
                                    {translator(messages.close)}
                                </WMICButton>
                                <WMICButton
                                    onClick={onSaveBankAccount}
                                    disabled={!canProceed() || bankAccountsLoading}
                                    type="primary"
                                >
                                    {getButtonContent(messages.save)}
                                </WMICButton>
                            </div>
                        )}
                        {displayMode === DISPLAY_MODES.BANK_ACCOUNT_INVALID_ROUTING && (
                            <div className="ww-btn-group-gap-2">
                                <WMICButton
                                    onClick={onClose}
                                    type="outlined"
                                >
                                    {translator(messages.close)}
                                </WMICButton>
                                <WMICButton
                                    onClick={onBackToAddBankAccount}
                                    type="primary"
                                >
                                    {translator(messages.back)}
                                </WMICButton>
                            </div>
                        )}
                        {displayMode === DISPLAY_MODES.ADD_BANK_ACCOUNT_SUCCESS && (
                            <div className="ww-btn-group-gap-2">
                                <WMICButton
                                    onClick={onCloseAfterAction}
                                    type="outlined"
                                >
                                    {translator(messages.close)}
                                </WMICButton>
                            </div>
                        )}
                        {(displayMode === DISPLAY_MODES.ADD_BANK_ACCOUNT_FAILURE) && (
                            <div className="ww-btn-group-gap-2">
                                {(!isBankAccountSaved?.isDuplicate) && (
                                    <WMICButton
                                        onClick={onCloseAfterAction}
                                        type="outlined"
                                    >
                                        {translator(messages.close)}
                                    </WMICButton>
                                )}
                                {(isBankAccountSaved?.isDuplicate) && (
                                    <WMICButton
                                        onClick={onBackToAddBankAccount}
                                        type="primary"
                                    >
                                        {translator(messages.back)}
                                    </WMICButton>
                                )}
                            </div>
                        )}
                    </ModalFooter>
                </div>
            )}
        </ModalNext>
    );
};

WMICManageBankAccountModal.propTypes = {
    accountSummary: PropTypes.shape({
        publicID: PropTypes.string,
        accountNumber: PropTypes.string
    }).isRequired,
    bankAccountInfo: PropTypes.shape({}),
    mode: PropTypes.string.isRequired,
    refreshAfterUpdate: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    ldFlags: PropTypes.shape({}).isRequired
};

WMICManageBankAccountModal.defaultProps = {
    bankAccountInfo: undefined
};

export default WMICManageBankAccountModal;