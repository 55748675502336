// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ClaimLobOptionsMenu__lobValuesClass{padding-left:var(--GW-SPACING-4)}.app__ClaimLobOptionsMenu__iconCheckedClass{display:inline-block}.app__ClaimLobOptionsMenu__iconCheckedClass-selected{margin-right:var(--GW-SPACING-2)}.app__ClaimLobOptionsMenu__itemNameClass{display:inline-block;padding-left:var(--GW-SPACING-6)}.app__ClaimLobOptionsMenu__itemNameSelected{display:inline-block;margin-left:var(--GW-SPACING-2)}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-claim-react/components/ClaimLobOptionsMenu/ClaimLobOptionsMenu.module.scss"],"names":[],"mappings":"AAAA,0CACI,gCAAA,CAGJ,4CACI,oBAAA,CAGJ,qDACI,gCAAA,CAGJ,yCACI,oBAAA,CACA,gCAAA,CAGJ,4CACI,oBAAA,CACA,+BAAA","sourcesContent":[".lobValuesClass {\n    padding-left: var(--GW-SPACING-4);\n}\n\n.iconCheckedClass {\n    display: inline-block;\n}\n\n.iconCheckedClass-selected {\n    margin-right: var(--GW-SPACING-2);\n}\n\n.itemNameClass {\n    display: inline-block;\n    padding-left: var(--GW-SPACING-6);\n}\n\n.itemNameSelected {\n    display: inline-block;\n    margin-left: var(--GW-SPACING-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobValuesClass": "app__ClaimLobOptionsMenu__lobValuesClass",
	"iconCheckedClass": "app__ClaimLobOptionsMenu__iconCheckedClass",
	"iconCheckedClass-selected": "app__ClaimLobOptionsMenu__iconCheckedClass-selected",
	"itemNameClass": "app__ClaimLobOptionsMenu__itemNameClass",
	"itemNameSelected": "app__ClaimLobOptionsMenu__itemNameSelected"
};
export default ___CSS_LOADER_EXPORT___;
