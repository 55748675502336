// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__trainingMaterials__trainingMaterial{padding:1.25rem}.app__trainingMaterials__trainingMaterial .app__trainingMaterials__listHeader{color:var(--GW-TEXT-COLOR-1);font-size:var(--GW-ICON-FONT-SIZE-2);font-weight:bolder;margin-bottom:5px;text-decoration:none}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-react/Components/HelpPage/TrainingMaterials/trainingMaterials.module.scss"],"names":[],"mappings":"AAGA,0CACI,eAAA,CAEA,8EAEI,4BAAA,CADA,oCAAA,CAGA,kBAAA,CACA,iBAAA,CAFA,oBAEA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n\n.trainingMaterial {\n    padding: 1.25rem;\n\n    .listHeader {\n        font-size: var(--GW-ICON-FONT-SIZE-2);\n        color: var(--GW-TEXT-COLOR-1);\n        text-decoration: none;\n        font-weight: bolder;\n        margin-bottom: 5px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trainingMaterial": "app__trainingMaterials__trainingMaterial",
	"listHeader": "app__trainingMaterials__listHeader"
};
export default ___CSS_LOADER_EXPORT___;
