import React, { Fragment } from 'react';
import { WMICLoader } from 'gw-components-platform-react';
import { useTranslator } from '@jutro/locale';
import styles from './WMICPendingPaymentCancellation.module.scss';
import messages from './WMICPendingPaymentCancellation.messages';

const WMICPendingPaymentCancellationProgress = () => {
    const translator = useTranslator();

    return (
        <div aria-hidden className="ww-content-limit ww-payment-modal">
            <div className="ww-center">
                <h3>
                    {translator(messages.PleaseWait)}
                </h3>
                <div className={styles.loaderDiv}>
                    <WMICLoader />
                </div>
                <br />
                <p>
                    {translator(messages.WeAreProcessingYourRequest)}
                    <br />
                    {translator(messages.DoNotNavigateAwayFromThisPageOrCloseYourBrowser)}
                </p>
            </div>
        </div>
    );
};

export default WMICPendingPaymentCancellationProgress;
