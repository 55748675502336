// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICDataBlocks__flexBlockContainer{display:flex;flex-wrap:wrap}.app__WMICDataBlocks__flexBlockContainer label{color:var(--GW-FIELD-COMPONENT-COLOR);font-weight:var(--GW-FONT-WEIGHT-BOLD)}.app__WMICDataBlocks__flexBlockContainer div[data-read-only]{color:var(--GW-FIELD-COMPONENT-COLOR);font-weight:var(--GW-FONT-WEIGHT-REGULAR)}.app__WMICDataBlocks__gutterContainer{align-self:center}.app__WMICDataBlocks__flexBlockItem{padding:.5rem}.app__WMICDataBlocks__flexBlockCard{padding:.5rem 1rem}.app__WMICDataBlocks__flexBox{flex-wrap:nowrap;justify-content:flex-start;margin-right:.5rem}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICDataBlocks/WMICDataBlocks.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,cAAA,CAEA,+CACE,qCAAA,CACA,sCAAA,CAGF,6DACE,qCAAA,CACA,yCAAA,CAIJ,sCACE,iBAAA,CAGF,oCACE,aAAA,CAGF,oCACE,kBAAA,CAGF,8BACE,gBAAA,CACA,0BAAA,CACA,kBAAA","sourcesContent":[".flexBlockContainer {\n  display: flex;\n  flex-wrap: wrap;\n\n  label {\n    color: var(--GW-FIELD-COMPONENT-COLOR);\n    font-weight: var(--GW-FONT-WEIGHT-BOLD);\n  }\n\n  div[data-read-only] {\n    color: var(--GW-FIELD-COMPONENT-COLOR);\n    font-weight: var(--GW-FONT-WEIGHT-REGULAR);\n  }\n}\n\n.gutterContainer {\n  align-self: center;\n}\n\n.flexBlockItem {\n  padding: 0.5rem;\n}\n\n.flexBlockCard {\n  padding: 0.5rem 1rem;\n}\n\n.flexBox {\n  flex-wrap: nowrap;\n  justify-content: flex-start;\n  margin-right: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexBlockContainer": "app__WMICDataBlocks__flexBlockContainer",
	"gutterContainer": "app__WMICDataBlocks__gutterContainer",
	"flexBlockItem": "app__WMICDataBlocks__flexBlockItem",
	"flexBlockCard": "app__WMICDataBlocks__flexBlockCard",
	"flexBox": "app__WMICDataBlocks__flexBox"
};
export default ___CSS_LOADER_EXPORT___;
