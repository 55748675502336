import { defineMessages } from 'react-intl';

export default defineMessages({
    LocationAndIncidentInformation: {
        id: 'wmic.component.location-and-incident-info-box-component.Location & Incident Information',
        defaultMessage: 'Location & Incident Information'
    },
    ReportedBy: {
        id: 'wmic.component.location-and-incident-info-box-component.Reported By',
        defaultMessage: 'Reported By'
    },
    ReportedDate: {
        id: 'wmic.component.location-and-incident-info-box-component.Reported Date',
        defaultMessage: 'Reported Date'
    },
    DateOfIncident: {
        id: 'wmic.component.location-and-incident-info-box-component.Date of Incident',
        defaultMessage: 'Date of Incident'
    },
    LocationAndIncidentInformationAria: {
        id: 'wmic.component.location-and-incident-info-box-component.Location & Incident Information Aria',
        defaultMessage: 'Reported By {name} Reported Date {reportedDate} Date of Incident {incidentDate} Location {location}'
    },
});
