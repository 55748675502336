import DesignatePersonModel from './DesignatePersonModel';
import messages from './Models.messages';

export default class DesignateAdditionalPersonModel {
    constructor({ changeType, designatePerson }) {
        this.changeType = changeType;
        this.designatePerson = designatePerson || null;
        this.CHANGE_TYPE = {
            ADD: 'Add',
            CHANGE: 'Change',
            DELETE: 'Remove'
        };
    }

    getRequestType() {
        return `I would like to ${this.CHANGE_TYPE[this.changeType]} the Designated Additional Person currently on my policy.`;
    }

    getDesignatePerson() {
        return !this.designatePerson ? '' : this.designatePerson.toString();
    }

    toHTML() {
        if (this.changeType === 'DELETE') {
            return `<strong class="gw-bold">
            ${this.designatePerson.name}
            ${this.designatePerson.street}
            ${this.designatePerson.city}, ${this.designatePerson.state} ${this.designatePerson.zip}
            ${this.designatePerson.country}
            </strong><br/>`;
        }

        return `<span>
${this.designatePerson.toHTML()}<br/>
</span>`;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.changeTypeRequired);
        }

        const text = `
${this.getRequestType()}
${this.getDesignatePerson()}
`;

        return text;
    }

    valid() {
        if (!this.changeType) return false;

        // For Add and Change, the designatePerson should be included
        if ([this.CHANGE_TYPE.ADD.toLowerCase(), this.CHANGE_TYPE.CHANGE.toLowerCase()]
            .includes(this.changeType.toLowerCase())) {
            return this.designatePerson.valid();
        }

        return true;
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangeDesignatedAdditionalPersonRequest);
    }

    static toObjectModel(obj) {
        const model = Object.setPrototypeOf(obj, DesignateAdditionalPersonModel.prototype);
        model.designatePerson = Object.setPrototypeOf(
            obj.designatePerson,
            DesignatePersonModel.prototype
        );
        return model;
    }
}