import { defineMessages } from "react-intl";

export default defineMessages({
    yourPolicyHasBeenUpdated: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Your policy has been successfully updated.",
        defaultMessage: "Your policy has been successfully updated.",
    },
    yourPolicyChangeHasBeenBound: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Your policy change has been bound",
        defaultMessage: "Your policy change has been bound.",
    },
    creditOwedAndRemainingPayments: {
        // hasAmountCredit && hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.creditOwedAndRemainingPayments",
        defaultMessage:
            "The changes you’ve selected result in a {amount} credit to your premium. The credit has been applied to your remaining payment(s).",
    },
    creditOwedAndCheckOutIn14Days: {
        // hasAmountCredit && !hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.creditOwedAndCheckOutIn14Days",
        defaultMessage:
            "The changes you’ve selected result in a {amount} credit to your premium. This credit will be processed and a check will be automatically mailed to you within 14 business days.",
    },
    amountOwedWithNoImmediateDueInvoices: {
        // hasAmountOwing && hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.amountOwedWithNoImmediateDueInvoices",
        defaultMessage:
            "The changes you’ve selected result in a {amount} increase to your premium. This increase will be applied to your remaining payment(s).",
    },
    amountOwedWithImmediateDueInvoices: {
        // hasAmountOwing && !hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.amountOwedWithImmediateDueInvoices",
        defaultMessage:
            "The changes you’ve selected result in a {amount} increase to your premium. You can pay this amount now or wait to receive your invoice.",
    },
    amountNoChange: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.amountNoChange",
        defaultMessage:
            "The changes you’ve selected result to no change to your premium.",
    },
    contactUs: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.If you have any questions, please contact us at 1-800-640-2920",
        defaultMessage:
            "If you have any questions, please contact us at 1-800-640-2920.",
    },
    yourPolicyWillReflect: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Your policy will reflect these changes at (date).",
        defaultMessage:
            "Your policy will reflect these changes at {date}.",
    },
    pleaseNoteItMayTakeAnAdditional: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Please Note it may take an additional two business days for the changes to be reflected on your upcoming renewal.",
        defaultMessage:
            "&lt;strong&gt;Please Note:&lt;/strong&gt; It may take an additional two business days for the changes to be reflected on your upcoming renewal.",
    },
    pleaseWait: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Please wait...",
        defaultMessage: "Please wait...",
    },
    wellProvideYourNewPayment: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.We'll provide your new payment schedule shortly",
        defaultMessage: "We'll provide your new payment schedule shortly",
    },
    pleaseWaitAndDontCloseYourBrowserOrLeaveThisPage: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Please wait and don't close your browser or leave this page",
        defaultMessage:
            "Please wait and don't close your browser or leave this page.",
    },
    payNow: {
        id: "wmic.policy-change.component.bound-policy-change-pa.Pay now",
        defaultMessage: "Pay now",
    },
    viewPolicyDetails: {
        id: "wmic.policy-change.component.bound-policy-change-pa.View Policy Details",
        defaultMessage: "View Policy Details",
    },
});
