import React, { useEffect, useContext, useState, useCallback } from 'react';
import _ from 'lodash'
import PropTypes from 'prop-types';

import {
    WMICVinLookupService,
    WMICLogger,
    WMICRichTextUtil,
    PAConstants,
    JURISDICTIONS
} from 'wmic-pe-portals-utils-js';
import { JobType } from 'wmic-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICButton } from 'wmic-pe-components-platform-react';

import { useValidation } from '@xengage/gw-portals-validation-react'
import { useDataRefresh, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { UserService } from 'gw-capability-gateway';

import { TranslatorContext } from '@jutro/locale';
import { InlineLoader, Icon } from '@jutro/components';

import metadata from './WMICVinSearchComponent.metadata.json5';
import messages from '../WMICVehicleInfoComponent.messages.js';
import styles from './WMICVinSearchComponent.module.scss'

function WMICVinSearchComponent(props){
    const {
        id,
        initialVinSearch,
        onValidate,
        showErrors,
        isEditMode,
        isReadOnlyUser,
        jobVM,
        authHeader,
        vehicleVM,
        updateShowVehicleSearch,
        updateVehicle,
        updateInitialVinSearch,
        cannotChangeVIN,
        reselectVehicle,
        updateReselectVehicle,
        getBusinessSegmentForVINSearch,
        repopulateVinSearch,
        vehicleSearchRef
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { setWizardLoading } = useWizardModals();
    const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { refreshData } = useDataRefresh();

    const [vinSearchVM, updateVinSearchVM] = useState(initialVinSearch);

    const [vehicleSearchResults, updateVehicleSearchResults] = useState([]);
    const [vehicleSearchErrors, updateVehicleSearchErrors] = useState([]);
    const [vehicleSearchInfoErrors, updateVehicleSearchInfoErrors] = useState([]);
    const [vehicleSearched, updateVehicleSearched] = useState(false);
    const [retrievingModel, updateRetrievingModel] = useState(false);
    const [retrievingMake, updateRetrievingMake] = useState(false);
    const [availableMakes, updateAvailableMakes] = useState([]);
    const [availableModels, updateAvailableModels] = useState([]);
    const [searchRequiredFields, updateSearchRequiredFields] = useState(false)
    const [currentLanguage, updateCurrentLanguage] = useState();

    const VICC_BODY_TYPE_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('VICCBodyType_WMIC');

    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { EndorsementService } = useDependencies('EndorsementService');
    const { RenewalService } = useDependencies('RenewalService');       

    const updateVinSearchData = (data) => {
        refreshData();
        updateVinSearchVM(data);
    };

    const getAvailableModels = (language) => {
        if(vinSearchVM.year.value
            && vinSearchVM.year.aspects.valid
            && vinSearchVM.make.value) {

            updateRetrievingModel(true);
            WMICVinLookupService.retrieveModelList(
                vehicleVM.vehicleType.value.code,
                vinSearchVM.make.value,
                vinSearchVM.year.value,
                language,
                authHeader
            )
                .then((result) => {
                    updateAvailableModels(result);

                    if(result.length === 1) {
                        _.set(vinSearchVM, 'model', result[0]);
                        updateVinSearchData(vinSearchVM);
                    }
                    updateRetrievingModel(false);
                });
        }
    };

    const loadModelsOnBackground = (language) => {
        WMICVinLookupService.retrieveMakeList(
            vehicleVM.vehicleType.value.code,
            language,
            authHeader
        ).then((makes) => {
            updateAvailableMakes(makes);
            getAvailableModels(language);
            updateRetrievingMake(false);
        })
    }

    const resetVinSearch = () => {
        updateAvailableMakes([]);
        updateAvailableModels([]);
        updateVehicleSearchResults([]);
        updateVehicleSearched(false);
        updateSearchRequiredFields(false);
        updateRetrievingMake(true);

        if(vinSearchVM.year.value
            && vinSearchVM.year.aspects.valid
            && vinSearchVM.make.value) {
            updateRetrievingModel(true);
        } else {
            updateRetrievingModel(false);
        }


        UserService.getGatewayCurrentUser(authHeader).then((aUser) => {
            updateCurrentLanguage(aUser.userPreferredLanguageCode_WMIC);


            if(!vehicleVM.vin.value) {
                const newVinSearch = ViewModelService.create(
                    {
                        vehicleType: vehicleVM.vehicleType.value.code,
                        ratingJurisdiction: jobVM.baseData.baseState.value.code,
                        baseState: jobVM.baseData.baseState.value.code,
                        effectiveDate: jobVM.baseData.periodStartDate.value,
                        rateAsOfDate: jobVM.baseData.rateAsOfDate.value,
                        transactionEffectiveDate: jobVM.baseData.transactionEffectiveDate.value,
                        businessSegment: getBusinessSegmentForVINSearch()
                    },
                    'pc',
                    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VinSearchDTO'
                );


                WMICVinLookupService.retrieveMakeList(
                    vehicleVM.vehicleType.value.code,
                    aUser.userPreferredLanguageCode_WMIC,
                    authHeader
                ).then((result) => {
                    updateAvailableMakes(Array.isArray(result) ? result : []);
                    if(result.length === 1) {
                        _.set(newVinSearch, 'make', result[0]);
                        getAvailableModels(aUser.userPreferredLanguageCode_WMIC);
                    }
                    if (vinSearchVM.year.value) {
                        _.set(newVinSearch, 'year', vinSearchVM.year.value);
                    }
                    updateVinSearchData(newVinSearch);
                    updateRetrievingMake(false);
                });
            } else {
                loadModelsOnBackground(aUser.userPreferredLanguageCode_WMIC)
            }
        })
    }

    const searchForVehicle = () => {
        if(!isComponentValid || (!vinSearchVM.make.value && !vinSearchVM.vin.value)) {
            updateSearchRequiredFields(true);
            updateVehicleSearched(false);
        } else {
            if (vinSearchVM.vin && vinSearchVM.vin.value) {
                _.set(vinSearchVM, "vin.value", vinSearchVM.vin.value.toUpperCase());
            }
            updateSearchRequiredFields(false);
            const vehicleSearchPromise = WMICVinLookupService.vehicleSearch(vinSearchVM.value, authHeader);
            setWizardLoading(true, translator(messages.SearchingForVehicleInformation));

            return vehicleSearchPromise.then((result) => {
                updateVehicleSearchErrors(result.errorMessages ?? []);
                updateVehicleSearchInfoErrors(result.infoMessages ?? []);
                updateVehicleSearchResults(result.results ?? []);
                updateVehicleSearched(true);

                let data;
                if(!result.errorMessages) {
                    data = `jobId=${jobVM.quoteID}, vin=${vinSearchVM.vin.value}, transactionType=${_.get(jobVM, 'baseData.jobType.code')}, numberOfResults=${result.results.length}`;
                } else {
                    data = `jobId=${jobVM.quoteID}, vin=${vinSearchVM.vin.value}, transactionType=${_.get(jobVM, 'baseData.jobType.code')}, numberOfResults=0`;
                }

                WMICLogger.info(data);

            }).catch(() => {
                updateVehicleSearchResults([]);
                updateVehicleSearchErrors([translator(messages.PleaseTryAgain)]);
                updateVehicleSearched(true);

                const data = `jobId=${jobVM.quoteID}, vin=${vinSearchVM.vin.value}, transactionType=${_.get(jobVM, 'baseData.jobType.code')}, numberOfResults=0`;
                WMICLogger.info(data);
            }).finally(() => {
                setWizardLoading(false)
            })
        };
        return Promise.reject();
    }

    const isNullOrWhiteSpace = (str) => {
        return (!str || str.length === 0 || /^\s*$/.test(str));
    }

    const populateVehicleFromSearchResult = (result) => {
        _.set(vehicleVM, 'year.value', !vinSearchVM.vin.year ? result.year : vinSearchVM.vin.year)
        _.set(vehicleVM, 'make.value', result.make);
        _.set(vehicleVM, 'model.value', result.englishModel);
        _.set(vehicleVM, 'vin.value', !vinSearchVM.vin.value ? result.vin : vinSearchVM.vin.value);
        _.set(vehicleVM, 'accidentBenefitRateGroup_WMIC.value', result.accidentBenefitsRateGroup);
        _.set(vehicleVM, 'collisionRateGroup_WMIC.value', result.collisionRateGroup);
        _.set(vehicleVM, 'comprehensiveRateGroup_WMIC.value', result.comprehensiveRateGroup);
        _.set(vehicleVM, 'dcpdrateGroup_WMIC', result.dcpdRateGroup);
        _.set(vehicleVM, 'carCode_WMIC.value', result.extendedVehicleCode);
        _.set(vehicleVM, 'engineFuel_WMIC.value', result.engineFuel);
        _.set(vehicleVM, 'vicccylinders_WMIC.value', result.viccCylinders);
        _.set(vehicleVM, 'msrp_WMIC.value', result.msrp_WMIC?.amount);
        _.set(vehicleVM, 'vehicleWeight.value', result.vehicleWeight);
        _.set(vehicleVM, 'wheelbase_WMIC.value', result.wheelbase_WMIC);
        _.set(vehicleVM, 'engineDisplacement_WMIC.value', result.engineDisplacement_WMIC);
        _.set(vehicleVM, 'horsepower_WMIC.value', result.horsepower_WMIC);
        _.set(vehicleVM, 'engineSupplemental_WMIC.value', result.engineSupplemental_WMIC);
        _.set(vehicleVM, 'engineForcedInduct_WMIC.value', result.engineForcedInduct_WMIC);
        _.set(vehicleVM, 'theftDeterrent_WMIC.value', result.theftDeterrent_WMIC);
        _.set(vehicleVM, 'engineStrokeType_WMIC.value', result.engineStrokeType_WMIC);

        let bodyStyleCode = `${result.vehicleTypeIndicator}_${result.bodyStyleClassIndicator}`;

        if (!isNullOrWhiteSpace(result.csioBodyTypeIndicator)) {
            bodyStyleCode += `_${result.csioBodyTypeIndicator}`;
        }

        if (result.enginesCCs) {
            _.set(vehicleVM, 'cubicCapacity_WMIC', result.enginesCCs);
        }

        const bodyType = VICC_BODY_TYPE_TYPELIST.getCode(bodyStyleCode.toLowerCase());

        _.set(vehicleVM, 'viccMotorcycleCBodyType_WMIC.value',  bodyType.code);
        _.set(vehicleVM, 'bodyStyle_WMIC.value', translator({id: bodyType.name}));
        _.set(vehicleVM, 'searchCompleted.value', true);
        _.set(vehicleVM, 'vinSearchAttempted_WMIC.value', true);

        updateInitialVinSearch(vinSearchVM);
        updateVehicle(vehicleVM);
    };

    const isInspectionRequired = () => {
        if (_.get(vehicleVM, 'vehicleType.value.code') === PAConstants.personalAutoVehicleType) {
            const today = new Date();
            const vehicleAge = today.getFullYear() - vehicleVM.year.value;
            if (vehicleAge >= PAConstants.minimumVehicleAgeForInspection && vehicleVM.year.value > PAConstants.maxVehicleYear
                && _.get(jobVM, 'baseData.baseState.value.code') === JURISDICTIONS.ALBERTA) {
                _.set(vehicleVM, 'vehicleInspectionRequired_WMIC', true);
            } else {
                _.set(vehicleVM, 'vehicleInspectionRequired_WMIC', false);
            }
        }
    };

    const updateJobVM = async () => {

      const jobType = _.get(jobVM, 'baseData.jobType.value.code');
      let service;

      switch (jobType) {
          case JobType.SUBMISSION:
              service = LoadSaveService;
              break;
          case JobType.POLICY_CHANGE:
              service = EndorsementService;
              break;
          case JobType.RENEWAL:
              service = RenewalService;
              break;
          default:
              break;
      }

      const updatedVM = await service.postOnChange(
          jobVM.value,
          authHeader
      );

      jobVM.value = updatedVM;
  };

  const selectVehicle = async (result) => {
      setWizardLoading(true, "");
      populateVehicleFromSearchResult(result);
      isInspectionRequired();
      updateVehicleSearchResults([]);
      updateReselectVehicle(false);
      updateVehicleSearched(false);
      updateShowVehicleSearch(false);
      await updateJobVM();
      setWizardLoading(false);
  };

    const isResultMatchingCurrentVehicle = (result) => {
        return result.extendedVehicleCode === vehicleVM.carCode_WMIC.value
            && result.make === vehicleVM.make.value
            && result.msrp_WMIC === vehicleVM.msrp_WMIC.value
            && result.theftDeterrent_WMIC === vehicleVM.theftDeterrent_WMIC.value.code
            && result.engineForcedInduct_WMIC === vehicleVM.engineForcedInduct_WMIC.value.code;
    }

    const handleRevalidateVinResults = () => {
        let updateSearchCompleted = false;
        if (vehicleSearchResults.length === 1) {
            selectVehicle(vehicleSearchResults[0]);
        } else if (vehicleSearchResults.length > 1) {
            const matchedResults = vehicleSearchResults.filter(isResultMatchingCurrentVehicle);

            if (matchedResults.length === 1) {
                selectVehicle(matchedResults[0]);
            } else {
                updateReselectVehicle(true);
                updateSearchCompleted = true;
            }
        } else {
            updateReselectVehicle(true);
            updateSearchCompleted = true;
        }
        if(reselectVehicle || updateSearchCompleted){
            _.set(vehicleVM, 'searchCompleted.value', true);
            updateVehicle(vehicleVM);
        }

        return vehicleSearchErrors
    };

    const revalidateVin = () => {
        repopulateVinSearch();
        return searchForVehicle().then(handleRevalidateVinResults);
    }

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate, vinSearchVM]);

    useEffect(() => {
        resetVinSearch();
    }, [])

    useEffect(() => {
        vehicleSearchRef.current = revalidateVin
    }, [vinSearchVM, isComponentValid, vehicleSearchResults, vehicleSearchErrors]);

    const renderLoader = () => {
        return <InlineLoader loading className="gw-inline-loader" />;
    };

    const onMakeYearChange = (value, path) => {
        _.set(vinSearchVM, path, value);
        updateVinSearchData(vinSearchVM);
        getAvailableModels(currentLanguage);
    }

    const getErrorMsg = () => {
        if (!vehicleSearchResults || vehicleSearchResults.length === 0) {
            if (vehicleSearchErrors && vehicleSearchErrors.length === 0) {
                return (<span>{translator(messages.NoSearchResults)}</span>);
            }
        }
        if (vehicleSearchErrors && vehicleSearchErrors.length > 0) {
            return (
                <React.Fragment>
                    {vehicleSearchErrors.map(msg => (
                        <p>
                        <Icon icon='mi-error' className='gw-mr-1 iconAlignment errorIconColor' />
                        <span>{msg}</span>
                        </p>
                    ))}
                </React.Fragment>
            );
        }
    };
    const getInfoMsg = () => {
        if (vehicleSearchInfoErrors && vehicleSearchInfoErrors.length > 0) {
            return (
                <React.Fragment>
                    {vehicleSearchInfoErrors.map(msg => (
                        <p>
                        <Icon icon='mi-info' className='gw-mr-1 iconAlignment infoBoxIcon' />
                        <span>{msg}</span>
                        </p>
                    ))}
                </React.Fragment>
            );
        }
    };
    const getAvailableMakesDropdownValues = useCallback(() => {
        if (availableMakes) {
            return availableMakes.map((make) => {
                const availableMakesJoinKey = {
                    code: make,
                    name: make
                };
                return availableMakesJoinKey;
            });
        }
        return [];
    },[availableMakes]);

    const getAvailableModelsDropdownValues = useCallback(() => {
        if (availableModels) {
            return availableModels.map((model) => {
                const availableModelsJoinKey = {
                    code: model,
                    name: model
                };
                return availableModelsJoinKey;
            });
        }
        return [];
    },[availableModels])

    const hideSearchIfRevalidateVIN = () => {
        return reselectVehicle && cannotChangeVIN();
    };

    const getSelectDisplaykey = () => {
        return hideSearchIfRevalidateVIN()
            ? translator(messages.selectVehicle)
            : translator(messages.SearchAndSelect)
    };

    const renderSelectButton = (rowData) => {
        return (
            <WMICButton onClick={() => selectVehicle(rowData)}>
                {translator(messages.Select)}
            </WMICButton>
        );
    };

    const getMSRPValue = (item) => {
        return item?.msrp_WMIC > 0
            ? `$${item.msrp_WMIC.toFixed(2)}`
            : translator(messages.MSRPUnavailable);
    };

    const getVehicleTitle = (item) => {
        return `${item.year} ${item.make} ${item.englishModel}`
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderSelectButton
        }
    }

    const overrideProps = {
        '@field': {
            parentNode: vinSearchVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        searchForVehicleButton: {
            onClick: searchForVehicle,
            disabled: retrievingMake || retrievingModel,
            visible: !isReadOnlyUser && isEditMode && !hideSearchIfRevalidateVIN()
        },
        vehicleTypeSearchTitle: {
            title: translator(messages.notTrailerTitle, {type: translator({id: vehicleVM.vehicleType.value.name})})
        },
        vinSearchYear: {
            onValueChange: onMakeYearChange
        },
        vinSearchMake: {
            onValueChange: onMakeYearChange,
            visible: !retrievingMake,
            readOnly: availableMakes.length <= 1,
            availableValues: getAvailableMakesDropdownValues(),
        },
        vinSearchModel: {
            readOnly: availableModels.length <= 1,
            visible: !retrievingModel,
            availableValues: getAvailableModelsDropdownValues(),
        },
        vinSearchMakeLoading: {
            visible: retrievingMake,
            value: renderLoader(),
            readOnly: true
        },
        vinSearchModelLoading: {
            visible: retrievingModel,
            value: renderLoader(),
            readOnly: true
        },
        resultDataListContainer: {
            visible: vehicleSearched && vehicleSearchResults && vehicleSearchResults.length > 0,
            VMList: vehicleSearchResults,
            VMData: [
                {
                    headerText: translator(messages.bodyStyle),
                    path: 'bodyTypeDescription'
                },
                {
                    headerText: translator(messages.engineForcedInduction),
                    path: 'engineForcedInductDescription_Ext'
                },
                {
                    headerText: translator(messages.theftDeterrentSystem),
                    path: 'hasTheftDeterrent_Ext'
                },
                {
                    headerText: translator(messages.MSRP),
                    getData: getMSRPValue
                }
            ],
            clickable: false,
            dataListTitle: {getData: getVehicleTitle},
            dataListSubtitle: {path: 'vin'},
            selectAction: selectVehicle,
            selectButtonMessage: translator(messages.Select),
            canDelete: () => false
        },
        searchRequiredFieldsErrorContainer: {
            visible: searchRequiredFields
        },
        searchRequiredMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.VINRequiredSearchFields))
        },
        resultErrorMessageContainer: {
            visible: vehicleSearched && vehicleSearchErrors && vehicleSearchErrors.length > 0,
            content: getErrorMsg(),
        },
        resultInfoMessageContainer: {
            visible: vehicleSearched && vehicleSearchInfoErrors && vehicleSearchInfoErrors.length > 0,
            content: getInfoMsg(),
        },
        searchAndSelectMsg: {
            content: getSelectDisplaykey(),
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vinSearchVM}
            overrideProps={overrideProps}
            onModelChange={updateVinSearchData}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );
};

WMICVinSearchComponent.propTypes = {
    id: PropTypes.string.isRequired,
    initialVinSearch: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    jobVM: PropTypes.shape({}).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    vehicleVM: PropTypes.shape({}).isRequired,
    updateVehicle: PropTypes.func.isRequired,
    updateShowVehicleSearch: PropTypes.func.isRequired,
    updateInitialVinSearch: PropTypes.func.isRequired,
};

export default WMICVinSearchComponent;
