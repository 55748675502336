/* eslint-disable max-len */
import _ from 'lodash';
import {
    CONSTANTS
} from 'wmic-portals-utils-js';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';

const LOB_PATH = {
    PersonalAuto: 'lobData.personalAuto.preQualQuestionSets.children[0].value',
    Homeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    HOPHomeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    BusinessOwners: 'lobData.businessOwners.preQualQuestionSets.children[0].value',
    BusinessAuto: 'lobData.businessOwners.preQualQuestionSets.children[0].value',
    PersonalUmbrella: 'lobData.personalUmbrella.preQualQuestionSets.children[0].value'
};

function shouldSkipBopPage(submissionVM, lobCode) {
    const preQualQuestionSets = _.get(
        submissionVM,
        LOB_PATH[lobCode].replace(/\.children\[\d\]/, '')
    );
    if (!_.isEmpty(preQualQuestionSets)) {
        const answerValues = _.values(preQualQuestionSets[0].answers);
        return answerValues.some((value) => value !== null);
    }
    return false;
}

function hasLicenseNumberForPADriver(submissionVM) {
    const licenseNumber = _.get(
        submissionVM,
        'lobData.personalAuto.coverables.drivers.value[0].licenseNumber'
    );

    if (config.persona === CONSTANTS.CONFIG_PERSONA.PRODUCER || config.persona === CONSTANTS.CONFIG_PERSONA.CSR) {
        return !_.isEmpty(licenseNumber);
    }
    return true;
}

export default {
    shouldSkipBopPage,
    hasLicenseNumberForPADriver
};