import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PolicyVehiclesDriversInfo from '../PA/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import PolicyDwellingInfo from '../HO/PolicyDwellingInfo/PolicyDwellingInfo';
import PolicyCommonCoverables from '../PolicyCommonCoverables/PolicyCommonCoverables';
import metadata from './PolicyCoverages.metadata.json5';

function PolicyCoverages(props) {
    const { value: policy } = props;

    if (_.isEmpty(policy)) {
        return null;
    }

    const overrides = {
        policyVehiclesDriversInfoComponent: {
            visible: !_.isEmpty(policy.lobs.personalAuto)
        },
        policyHomeOwnersInfoComponent: {
            visible: !_.isEmpty(policy.lobs.homeOwners)
        },
        genericCoveragesComponent: {
            visible:
                _.isEmpty(policy.lobs.homeOwners)
                && _.isEmpty(policy.lobs.personalAuto)
                && !_.isEmpty(policy.coverables)
        }
    };

    const resolvers = {
        resolveComponentMap: {
            policyvehiclesdriversinfocomponent: PolicyVehiclesDriversInfo,
            policydwellingcomponent: PolicyDwellingInfo,
            genericcoveragecomponent: PolicyCommonCoverables
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policy}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyCoverages.propTypes = {
    value: PropTypes.shape({})
};

PolicyCoverages.defaultProps = {
    value: {
        lobs: {
            homeOwners: {},
            personalAuto: {},
            coverables: []
        }
    }
};

export default PolicyCoverages;
