import { defineMessages } from 'react-intl';

export default defineMessages({
    ifYouLeaveNowAnyChangesYouveSelectedWillNotBeSaved: {
        id: 'wmic.policy-change.component.review-policy-change-pa-cancel-edit-modal.modal.If you leave now, any changes you’ve selected will not be saved.',
        defaultMessage: 'If you leave now, any changes you’ve selected will not be saved.'
    },
    areYouSureYouWishToCancel: {
        id: 'wmic.policy-change.component.review-policy-change-pa-cancel-edit-modal.modal.modal.Are you sure you wish to cancel?',
        defaultMessage: 'Are you sure you wish to cancel?'
    },
    areYouSure: {
        id: 'wmic.policy-change.component.review-policy-change-pa-cancel-edit-modal.modal.modal.Are you sure?',
        defaultMessage: 'Are you sure?'
    },
    noKeepEditing: {
        id: 'wmic.policy-change.component.review-policy-change-pa-cancel-edit-modal.modal.modal.No, Keep Editing',
        defaultMessage: 'No, Keep Editing'
    },
    yesCancelChanges: {
        id: 'wmic.policy-change.component.review-policy-change-pa-cancel-edit-modal.modal.modal.Yes, Cancel Changes',
        defaultMessage: 'Yes, Cancel Changes'
    }
});