// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Summary__cancellationRefundAmount .jut__FieldComponent__controlReadOnly{font-size:var(--GW-FONT-SIZE-H2);font-weight:var(--GW-FONT-WEIGHT-REGULAR)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/Cancellation/Summary/Summary.module.scss"],"names":[],"mappings":"AACC,8EACC,gCAAA,CACA,yCAAA","sourcesContent":[".cancellationRefundAmount {\n\t:global(.jut__FieldComponent__controlReadOnly) {\n\t\tfont-size: var(--GW-FONT-SIZE-H2);\n\t\tfont-weight: var(--GW-FONT-WEIGHT-REGULAR);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancellationRefundAmount": "app__Summary__cancellationRefundAmount"
};
export default ___CSS_LOADER_EXPORT___;
