import moment from 'moment';
import messages from './Models.messages';

export default class PolicyDetailsModel {
    constructor({
        policyHolder,
        policyNumber,
        policyHolderEmail,
        homePhone,
        workPhone,
        effectiveDate,
        requester
    }) {
        this.policyHolder = policyHolder;
        this.policyNumber = policyNumber;
        this.policyHolderEmail = policyHolderEmail || '';
        this.homePhone = homePhone || '';
        this.workPhone = workPhone || '';
        this.effectiveDate = effectiveDate;
        this.requester = requester;
    }

    toString() {
        if (!this.valid()) {
            // eslint-disable-next-line max-len
            throw new Error(messages.policyHolderPolicyNumberEffectiveDateRequesterRequired);
        }

        return `Policy Holder: ${this.policyHolder}
Policy Number: ${this.policyNumber}
E-mail: ${this.policyHolderEmail}
Home Phone: ${this.homePhone}
Work Phone: ${this.workPhone}
Policy Effective Date: ${moment(this.effectiveDate).format('MM/DD/YYYY')}
Requester: ${this.requester}`;
    }

    toXML() {
        if (!this.valid()) {
            throw new Error(messages.policyHolderNumberEffectiveDateRequired);
        }

        return `
        <web:CustomerInformation>
            <web:PolicyName>${this.policyHolder}</web:PolicyName>
            <web:PolicyNumber>${this.policyNumber}</web:PolicyNumber>
            <web:EmailAddress>${this.policyHolderEmail}</web:EmailAddress>
            <web:PhoneNumber type="home">${this.homePhone}</web:PhoneNumber>
            <web:PhoneNumber type="work">${this.workPhone}</web:PhoneNumber>
        </web:CustomerInformation>        
        `;
    }

    valid() {
        const required = [this.policyHolder, this.policyNumber, this.effectiveDate, this.requester];
        return required.every((v) => v);
    }
}
