import { defineMessages } from 'react-intl';

export default defineMessages({
    saveTimeAndPaper: {
        id: 'wmic.components.EmailConsentPage.Save time and paper',
        defaultMessage: 'Save Time and Paper'
    },
    submit: {
        id: 'wmic.components.EmailConsentPage.submit',
        defaultMessage: 'Submit'
    },
    setContactPreference: {
        id: 'wmic.components.EmailConsentPage.Set Your Contact Preference',
        defaultMessage: 'Set Your Contact Preference'
    },
    consentParagraphOne: {
        id: 'wmic.components.EmailConsentPage.consentParagraphOne',
        defaultMessage: 'With your consent Wawanesa Insurance will electronically deliver your policy documents, forms, billing statements, notices, claim-related documents and any other documents and communications permitted or required by law. Communications may contain documents or may provide access to an online account where a document or communication is available. Your consent is voluntary. Oregon policyholders receiving a discount related to electronic delivery of documents, will no longer receive the discount if authorization is cancelled.'
    },
    consentParagraphTwo: {
        id: 'wmic.components.EmailConsentPage.consentParagraphTwo',
        defaultMessage: 'To receive electronically delivered documents you must have a computer or other access device with an internet connection, an active email account, and software which permits you to receive, access and retain Portable Document Format or ("PDF") Files.'
    },
    consentParagraphThree: {
        id: 'wmic.components.EmailConsentPage.consentParagraphThree',
        defaultMessage: 'You may email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt; or call us at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; to do any of the following:'
    },
    consentListItemOne: {
        id: 'wmic.components.EmailConsentPage.consentListItemOne',
        defaultMessage: 'Opt-out of receiving electronic documents at any time'
    },
    consentListItemTwo: {
        id: 'wmic.components.EmailConsentPage.consentListItemTwo',
        defaultMessage: 'Update your email address to ensure you receive all communications'
    },
    consentListItemThree: {
        id: 'wmic.components.EmailConsentPage.consentListItemThree',
        defaultMessage: 'Request free paper copies of your policy or claim-related documents and communications'
    },
    consentQuestion: {
        id: 'wmic.components.EmailConsentPage.consentQuestion',
        defaultMessage: 'Would you like to receive your documents and communication by email?'
    },
    consentYes: {
        id: 'wmic.components.EmailConsentPage.consentYes',
        defaultMessage: '&lt;pre&gt;YES&lt;/pre&gt;&lt;strong&gt; - I want to receive my documents electronically. By clicking here I confirm that I have read the information above and consent to the electronic delivery of documents and communications from Wawanesa Insurance which will be sent to {email}.&lt;/strong&gt;'
    },
    consentNo: {
        id: 'wmic.components.EmailConsentPage.consentNo',
        defaultMessage: '&lt;pre&gt;NO&lt;/pre&gt;&lt;strong&gt; - &lt;/strong&gt;I do not want to receive my documents electronically and want to receive communications via US mail.'
    },
    privacyPolicy: {
        id: 'wmic.components.EmailConsentPage.Privacy Policy',
        defaultMessage: 'Privacy Policy '
    },
    termsofUse: {
        id: 'wmic.components.EmailConsentPage.Terms of Use',
        defaultMessage: 'Terms of Use '
    },
    onlinePaymentTerms: {
        id: 'wmic.components.EmailConsentPage.Online Payment Terms',
        defaultMessage: 'Online Payment Terms'
    },
    easyAccessHeading: {
        id: 'wmic.components.EmailConsentPage.Easy access',
        defaultMessage: 'Easy access to what &lt;span&gt;you&lt;/span&gt; need when &lt;span>you&lt;/span&gt; need it.'
    },
    digitalIDCards: {
        id: 'wmic.components.EmailConsentPage.Digital ID Cards',
        defaultMessage: 'Digital ID Cards'
    },
    roadsideAssistanceCards: {
        id: 'wmic.components.EmailConsentPage.Roadside Assistance Cards',
        defaultMessage: 'Roadside Assistance Cards'
    },
    makePaymentsQuicklyAndEasily: {
        id: 'wmic.components.EmailConsentPage.Make payments quickly and easily',
        defaultMessage: 'Make payments quickly and easily'
    },
    viewPolicyDetails: {
        id: 'wmic.components.EmailConsentPage.View policy details',
        defaultMessage: 'View policy details'
    },
    reviewPolicyRenewals: {
        id: 'wmic.components.EmailConsentPage.Review policy renewals',
        defaultMessage: 'Review policy renewals'
    },
    viewClaimsInformation: {
        id: 'wmic.components.EmailConsentPage.View claims information',
        defaultMessage: 'View claims information'
    },
    paymentPlanChanges: {
        id: 'wmic.components.EmailConsentPage.Payment plan changes',
        defaultMessage: 'Payment plan changes'
    },
    claimsPaymentHistory: {
        id: 'wmic.components.EmailConsentPage.Claims payment history',
        defaultMessage: 'Claims payment history'
    },
    makePolicyChanges: {
        id: 'wmic.components.EmailConsentPage.Make policy changes',
        defaultMessage: 'Make policy changes'
    }
});