// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICHOWaterUpdates__ww-margin-bottom{margin-bottom:var(--GW-LAYOUT-3)}.app__WMICHOWaterUpdates__waterUpdateContainer{border:1px solid var(--GW-BORDER-COLOR);border-radius:var(--GW-BORDER-RADIUS);display:flex;flex-direction:column;padding:var(--GW-SPACING-6)}.app__WMICHOWaterUpdates__WaterUpdateActions{align-self:flex-end;display:flex}.app__WMICHOWaterUpdates__mitigationTypeButton{margin-bottom:var(--GW-SPACING-6)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-ho-react/components/WMICHOWaterUpdates/WMICHOWaterUpdates.module.scss"],"names":[],"mappings":"AAAA,2CACI,gCAAA,CAGJ,+CAGI,uCAAA,CACA,qCAAA,CAHA,YAAA,CACA,qBAAA,CAGA,2BAAA,CAGJ,6CAEI,mBAAA,CADA,YACA,CAGJ,+CACI,iCAAA","sourcesContent":[".ww-margin-bottom {\n    margin-bottom: var(--GW-LAYOUT-3);\n}\n\n.waterUpdateContainer {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--GW-BORDER-COLOR);\n    border-radius: var(--GW-BORDER-RADIUS);\n    padding: var(--GW-SPACING-6);\n}\n\n.WaterUpdateActions {\n    display: flex;\n    align-self: flex-end;\n}\n\n.mitigationTypeButton {\n    margin-bottom: var(--GW-SPACING-6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ww-margin-bottom": "app__WMICHOWaterUpdates__ww-margin-bottom",
	"waterUpdateContainer": "app__WMICHOWaterUpdates__waterUpdateContainer",
	"WaterUpdateActions": "app__WMICHOWaterUpdates__WaterUpdateActions",
	"mitigationTypeButton": "app__WMICHOWaterUpdates__mitigationTypeButton"
};
export default ___CSS_LOADER_EXPORT___;
