// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICTransactionDetailsHeader__lobIcon{color:var(--wmic-wizard-header-icon-color)}.app__WMICTransactionDetailsHeader__accountHolderName{font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}.app__WMICTransactionDetailsHeader__editAccount{font-size:var(--GW-ICON-FONT-SIZE-1)}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICTransactionDetailsHeader/WMICTransactionDetailsHeader.module.scss"],"names":[],"mappings":"AAIA,4CACE,0CAAA,CAGF,sDACE,2CAAA,CAGF,gDACE,oCAAA","sourcesContent":["@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers';\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set';\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\n.lobIcon {\n  color: var(--wmic-wizard-header-icon-color);\n}\n\n.accountHolderName {\n  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n}\n\n.editAccount {\n  font-size: var(--GW-ICON-FONT-SIZE-1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobIcon": "app__WMICTransactionDetailsHeader__lobIcon",
	"accountHolderName": "app__WMICTransactionDetailsHeader__accountHolderName",
	"editAccount": "app__WMICTransactionDetailsHeader__editAccount"
};
export default ___CSS_LOADER_EXPORT___;
