import { defineMessages } from 'react-intl';

export default defineMessages({
    updateFinanceTitle: {
        id: 'wmic.policy-change.component.update-finance-pa.finance-or-leasing',
        defaultMessage: 'Finance or leasing company changes'
    },
    updateFinanceSubtitle: {
        id: 'wmic.policy-change.component.update-finance-pa.current-finance-or-leasing-companies',
        defaultMessage: 'Your current Finance or leasing companies'
    },
    updateFinanceSubtitle2: {
        id: 'wmic.policy-change.component.update-finance-pa.only-vehicles-currently',
        defaultMessage: 'Only vehicles currently assigned to finance or leasing companies are listed'
    },
    updateFinanceCompany: {
        id: 'wmic.policy-change.component.update-finance-pa.finance-company',
        defaultMessage: 'Finance Company'
    },
    updateFinanceVehicle: {
        id: 'wmic.policy-change.component.update-finance-pa.vehicle',
        defaultMessage: 'Vehicle'
    },
    updateFinanceVIN: {
        id: 'wmic.policy-change.component.update-finance-pa.vin',
        defaultMessage: 'VIN Number'
    },
    updateFinanceCompanyName: {
        id: 'wmic.policy-change.component.update-finance-pa.company-name',
        defaultMessage: 'Company Name'
    },
    updateFinanceLoanAccount: {
        id: 'wmic.policy-change.component.update-finance-pa.loan-account',
        defaultMessage: 'Loan or Account#'
    },
    updateFinanceStreet: {
        id: 'wmic.policy-change.component.update-finance-pa.street',
        defaultMessage: 'Street'
    },
    updateFinanceCity: {
        id: 'wmic.policy-change.component.update-finance-pa.city',
        defaultMessage: 'City'
    },
    updateFinanceState: {
        id: 'wmic.policy-change.component.update-finance-pa.state',
        defaultMessage: 'State'
    },
    updateFinancePostalCode: {
        id: 'wmic.policy-change.component.update-finance-pa.postal-code',
        defaultMessage: 'Postal Code'
    },
    updateFinanceEmptyTitle: {
        id: 'wmic.policy-change.component.update-finance-pa.empty-title',
        defaultMessage: 'You do not currently have a finance or leasing company on file.'
    },
    updateFinanceAddNewCompany: {
        id: 'wmic.policy-change.component.update-finance-pa.add-new-company',
        defaultMessage: 'Add New Company'
    },
    edit: {
        id: 'wmic.policy-change.component.update-finance-pa.edit',
        defaultMessage: 'Edit'
    },
    remove: {
        id: 'wmic.policy-change.component.update-finance-pa.remove',
        defaultMessage: 'Remove'
    },
    sendVerification: {
        id: 'wmic.policy-change.component.update-finance-pa.send-insurance-verification',
        defaultMessage: 'Send insurance verification'
    },
    cancel: {
        id: 'wmic.policy-change.component.update-finance-pa.cancel',
        defaultMessage: 'Cancel'
    },
    sendVerificationModalTitle: {
        id: 'wmic.policy-change.component.update-finance-pa.send-verification-modal.title',
        defaultMessage: 'Send Insurance Verification'
    },
    sendVerificationModalSubtitle: {
        id: 'wmic.policy-change.component.update-finance-pa.send-verification-modal.subtitle',
        defaultMessage: 'Please confirm that you want to send the insurance verification:'
    },
    sendVerificationModalFooter: {
        id: 'wmic.policy-change.component.update-finance-pa.send-verification-modal.footer',
        defaultMessage: 'Once submitted, an agent will review and finish processing this change. You will receive updated documents confirming when the change has taken effect'
    },
    sendVerificationModalSend: {
        id: 'wmic.policy-change.component.update-finance-pa.send-verification-modal.send',
        defaultMessage: 'Yes, Send'
    },
    removeModalTitle: {
        id: 'wmic.policy-change.component.update-finance-pa.remove-modal.title',
        defaultMessage: 'Remove Finance or Leasing Company'
    },
    removeModalSubtitle: {
        id: 'wmic.policy-change.component.update-finance-pa.remove-modal.subtitle',
        defaultMessage: 'Please confirm the deletion of the following finance or leasing company from your policy:'
    },
    removeModalRemove: {
        id: 'wmic.policy-change.component.update-finance-pa.remove-modal.remove',
        defaultMessage: 'Yes, Remove'
    },
    submitButtonTracking: {
        id: 'wmic.policy-change.component.update-finance-pa.tracking.submit',
        defaultMessage: 'Policy Change - Submit - Update Finance or Leasing Company'
    },
});
