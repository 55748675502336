import React, { useCallback, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import {TranslatorContext} from '@jutro/locale'
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { WMICUserAccessUtil, WMICVariousUtil, FlowStepId, WMICLogger, WMICValidationUtil, parseErrorMessage } from 'wmic-pe-portals-utils-js';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import messages from './WMICCPPolicyDetailsPage.messages';
import { WMICErrorHandler } from "wmic-pe-capability-quoteandbind-common-react";

function WMICCPPolicyDetailsPage(props) {
    const { isComponentValid, onValidate: setComponentValidation, initialValidation, registerInitialComponentValidation } = useValidation('CPPolicyDetailsPage');
    const { wizardData: submissionVM, updateWizardData } = props;
    const { setWizardLoading } = useWizardModals();
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const { authHeader, authUserData } = useAuthentication();
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState();
    const [disableNext, setDisableNext] = useState(false);
    const translator = useContext(TranslatorContext);

    useDocumentTitle(translator(messages.title), submissionVM);

    const validateInitialTableForm = useCallback(() => {
        const answers = _.get(submissionVM, 'lobData.commercialProperty.preQualQuestionSets.children[0].value.answers');
        // If the qualification questions were not answered, start wizard from page 1 (Policy Details page)
        return answers && Object.values(answers).find((value) => !_.isNil(value))
            && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.POLICY_DETAILS);
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialTableForm);
    }, [registerInitialComponentValidation, validateInitialTableForm]);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUserData]);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.POLICY_DETAILS;
    }, [submissionVM.value]);

    const launchDefaultAnswers = useCallback(async () => {
        try {
            setWizardLoading(true, translator(messages.defaultAnswers))
            const newVM = _.clone(submissionVM);
            newVM.value = await LoadSaveService
                .createDefaultAnswers_WMIC(submissionVM.value, authHeader);
            updateWizardData(newVM);
        } catch (e) {
            WMICLogger.error('Error launching default answers', e)
        } finally {
            setWizardLoading(false)
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setWizardLoading, translator, submissionVM, authHeader]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            setScrollToError(Date.now());
            return false;
        }

        try {
            setWizardLoading(true);
            _.unset(submissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmission(
                submissionVM.value,
                authHeader
            );

            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.POLICY_DETAILS)) {
                WMICVariousUtil.scrollToTop();
                return false
            }

            return submissionVM;
            } catch(error) {
                WMICLogger.error(error);
                WMICErrorHandler.processAsModal(parseErrorMessage(error));
                return false;
        } finally {
            setWizardLoading(false);
        }

        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, submissionVM, authHeader]);

    return (
        <WMICWizardSubmissionPage
            showPrevious={false}
            disableNext={disableNext}
            onNext={onNext}
            showRequired
            cancelLabel={translator(commonMessages.saveAndExit)}
            skipWhen={initialValidation}
        >
            <WMICScrollToError counter={scrollToError}/>
            <WMICPolicyDetailsComponent
                jobVM={submissionVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={isReadOnlyUser}
                authHeader={authHeader}
                launchDefaultAnswers={launchDefaultAnswers}
                showErrors={showErrors}
                setDisableNext={setDisableNext}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICCPPolicyDetailsPage.propTypes = wizardProps;
export default WMICCPPolicyDetailsPage;
