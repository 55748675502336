import { defineMessages } from 'react-intl';

export default defineMessages({
    EnterValidInformation: {
        id: 'portal.views.edit-designated-person.EnterValidInformation',
        defaultMessage: 'Enter a valid information.'
    },
    zipCodeIncorrect: {
        id: 'portal.views.edit-designated-person.zipCodeIncorrect',
        defaultMessage: 'Zip code must be 5 or 9 digits.'
    },
    name: {
        id: 'portal.views.edit-designated-person.fullname',
        defaultMessage: 'Full Name'
    },
    street: {
        id: 'portal.views.edit-designated-person.street',
        defaultMessage: 'Street'
    },
    unitNumber: {
        id: 'portal.views.edit-designated-person.unitNumber',
        defaultMessage: 'Unit Number'
    },
    city: {
        id: 'portal.views.edit-designated-person.city',
        defaultMessage: 'City'
    },
    state: {
        id: 'portal.views.edit-designated-person.state',
        defaultMessage: 'State'
    },
    zip: {
        id: 'portal.views.edit-designated-person.zip',
        defaultMessage: 'Zip'
    },
    permission: {
        id: 'portal.views.edit-designated-person.permission',
        defaultMessage: 'I give permission to discuss billing information with this Designee'
    }
});