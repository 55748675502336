/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components/widgets/modal-next/ModalNextProvider';
import {
    WMICChangeTypeSelectorHOComponent,
    WMICChangeTypeSelectorPAComponent,
    WMICCoverageTypeSelectorPAComponent,
    WMICChangeDeductiblesOrCoveragesPAComponent,
    WMICRemoveVehiclePAComponent,
    WMICRemoveVehiclePALegacyComponent,
    WMICChangeRoadsideAssistanceComponent,
    WMICUpdateFinancePAComponent,
    WMICBodilyInjuryComponent,
    WMICEditUninsuredComponent,
    WMICChangeAddressComponent,
    WMICChangeDesignatedPersonComponent,
    WMICReviewPolicyChangePAComponent,
    WMICBoundPolicyChangePA,
    WMICSuccessPolicyChangeComponent
} from 'gw-capability-policychange-common-react';
import WMICLink from 'wmic-components-amp-common-react/WMICLink/WMICLink';
import { useAuthentication } from 'wmic-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { BillingService } from 'gw-capability-billing';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICPolicyChangePaymentPlanBox } from 'gw-capability-policy-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { CONSTANTS, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import metadata from './WMICPolicyChange.metadata.json5';
import messages from './WMICPolicyChange.messages';
import styles from './WMICPolicyChange.module.scss';

function WMICPolicyChange(props) {
    const modalApi = useModal();
    const { match, history } = props;
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const [policyDetails, setPolicyDetails] = useState([]);
    const [billingDetails, setBillingDetails] = useState([]);
    const [loadingState, setLoadingState] = useState(true);
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [ldFlags, setLdFlags] = useState([]);
    const rFlagsPromise = WMICFeatureFlagUtil.useFlags(authUserData);
    const translator = useTranslator();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const stepSelected = _.get(match, 'params.selectedValue');
    const { WMICPaymentModal } = useDependencies('WMICPaymentModal');

    const initFeatureFlags = async () => {
        const rFlags = await rFlagsPromise;
        setLdFlags(rFlags);
    }

    const getFeatureAvailability = useCallback((featureName) => {
        const response = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureName);
        return response;
    }, [ldFlags]);

    useEffect(() => {
        initFeatureFlags();
        setLoadingState(true);
        Promise.all([
            BillingService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader),
            PolicyService.getAccountPolicyDetails_WMIC(policyNumber, termNumber, authHeader, authUserData.firstName, authUserData.lastName),
        ]).then(([billDetails, accPolDetails]) => {
            setBillingDetails(billDetails);
            setPolicyDetails(accPolDetails);
        }).finally(() => {
            setLoadingState(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const getPeriod = (policy) => {
        if (policy.renewedPeriod || policy.currentPeriod) {
            if (policy.renewedPeriod && policy.renewedPeriod.termNumber_WMIC) {
                return policy.renewedPeriod;
            }
            return policy.currentPeriod;
        }
        return undefined;
    };

    const isHO = (policy) => getPeriod(policy)?.lobs?.homeOwners?.coverageDTOs !== undefined;

    const isPA = (policy) => getPeriod(policy)?.lobs?.personalAuto?.coverageDTOs !== undefined;

    const paymentModal = (policyModalData, isOpen) => {
        modalApi.showModal(<WMICPaymentModal data={policyModalData} isOpen={isOpen} ldFlags={ldFlags} />).catch(_.noop());
    };

    const getSidePolicyMenuContent = () => {
        return (
            <Fragment>
                <h3 className={styles['wmic-h3-question']}>
                    <i className="fa fa-phone" />
                    {translator(messages.detailsMenuQuestions)}
                </h3>
                <p className={styles['wmic-service-call-paragraph']}>
                    <span>{translator(messages.contact)}</span>
                    <WMICLink href={`tel:${translator(messages.phone)}`} aria-label={translator(messages.callUs)}>{translator(messages.phone)}</WMICLink>
                    {translator(messages.mondayFriday)}
                    <br aria-hidden/>
                    {translator(messages.h730AM730PMand)}
                    <br aria-hidden/>
                    {translator(messages.saturday800AM430PM)}
                </p>
            </Fragment>
        );
    };

    const getPrimaryLOB = (policy) => {
        const periodData = getPeriod(policy);
        if (periodData) {
            return isHO(policy) ? periodData.lobs.homeOwners : periodData.lobs.personalAuto;
        }
        return undefined;
    };

    const getPageTitle = (policy) => {
        const getLineTitle = (t) => {
            let lineTitle = '';
            const title = t?.toLowerCase();
            if ([CONSTANTS.POLICY_TYPES.HO.HOMEOWNERS, CONSTANTS.POLICY_TYPES.HO.HOMEOWNER].includes(title)) {
                lineTitle = translator(messages.homeowners);
            } else if ([CONSTANTS.POLICY_TYPES.HO.TENANT, CONSTANTS.POLICY_TYPES.HO.RENTERS].includes(title)) {
                lineTitle = translator(messages.renters);
            } else if (title === CONSTANTS.POLICY_TYPES.HO.CONDOMINIUM) {
                lineTitle = translator(messages.condominium);
            } else if (isPA(policy)) {
                lineTitle = translator(messages.automobile);
            } else {
                lineTitle = t;
            }
            return lineTitle;
        };

        const getTitleIcon = () => {
            if (isPA(policy)) {
                return 'fa fa-car';
            }
            if (isHO(policy)) {
                return 'fa fa-home';
            }
            return '';
        };

        return (
            <>
                <i className={getTitleIcon()} />
                {` ${translator(messages.editPolicyNumber, {
                    policyType: getLineTitle(
                        getPrimaryLOB(policy)?.dwellingDTO?.policyType
                    ),
                    policyNumber: policyNumber,
                })}`}
            </>
        );
    };

    const commonProps = {
        policyVM: policyDetails,
        match: match,
        loadingState,
        setLoadingState,
        billingData: billingDetails
    }

    const overrideProps = {
        pageTitle: {
            content: getPageTitle(policyDetails)
        },
        policyMenuContent: {
            content: getSidePolicyMenuContent()
        },
        policyChangeLoader: {
            visible: loadingState
        },
        changeTypeSelectorComponentHO: {
            visible: isHO(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE,
            policyNumber: policyNumber,
            billingData: billingDetails,
            termNumber: termNumber,
            ...commonProps
        },
        changeTypeSelectorComponentPA: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE,
            billingData: billingDetails,
            ...commonProps
        },
        coverageTypeSelectorComponentPA: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.ADJUST_COVERAGES,
            paymentModal,
            history: history,
            ...commonProps
        },
        changeDeductiblesOrCoveragesPA: {
            visible: isPA(policyDetails) && match.url.startsWith(`/${CONSTANTS.POLICY_CHANGE_TYPE.EDIT_POLICY_SELF_SERVICE}/`),
            ...commonProps
        },
        reviewPolicyChangePA: {
            visible: isPA(policyDetails) && match.url.startsWith(`/${CONSTANTS.POLICY_CHANGE_TYPE.REVIEW_POLICY_CHANGE}/`),
            ...commonProps
        },
        boundPolicyChangePA: {
            visible: isPA(policyDetails) && match.url.startsWith(`/${CONSTANTS.POLICY_CHANGE_TYPE.BOUND_POLICY_CHANGE}/`),
            policyNumber: policyNumber,
            termNumber: termNumber,
            ...commonProps
        },
        removeVehiclePA: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.DELETE_VEHICLE && getFeatureAvailability(featureFlags.DELETEVEHICLE).isAvailable,
            ...commonProps
        },
        removeVehiclePALegacy: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.DELETE_VEHICLE && !getFeatureAvailability(featureFlags.DELETEVEHICLE).isAvailable,
            ...commonProps
        },
        changeRoadsideAssistance: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.ROADSIDE_ASSISTANCE,
            ...commonProps
        },
        bodilyInjuryComponent: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.BODILY_INJURY,
            history: history,
            ...commonProps
        },
        wmicPolicyChangePaymentPlanBox: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.PAYMENT_PLAN,
            billingVM: billingDetails,
            ...commonProps
        },
        updateFinancePA: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.FINANCE_LEASING,
            ...commonProps
        },
        editUninsuredComponent: {
            visible: isPA(policyDetails) && stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.EDIT_UNINSURED,
            history: history,
            ...commonProps
        },
        changeAddressPA: {
            visible: stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.CHANGE_ADDRESS,
            ...commonProps
        },
        changeDesignatedPerson: {
            visible: stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON,
            ...commonProps
        },
        successPolicyChange: {
            visible: stepSelected === CONSTANTS.POLICY_CHANGE_TYPE.SUCCESS_PAGE,
            ...commonProps
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICChangeTypeSelectorHOComponent: WMICChangeTypeSelectorHOComponent,
            WMICChangeTypeSelectorPAComponent: WMICChangeTypeSelectorPAComponent,
            WMICCoverageTypeSelectorPAComponent: WMICCoverageTypeSelectorPAComponent,
            WMICChangeDeductiblesOrCoveragesPAComponent: WMICChangeDeductiblesOrCoveragesPAComponent,
            WMICRemoveVehiclePAComponent: WMICRemoveVehiclePAComponent,
            WMICRemoveVehiclePALegacyComponent: WMICRemoveVehiclePALegacyComponent,
            WMICChangeRoadsideAssistanceComponent: WMICChangeRoadsideAssistanceComponent,
            WMICUpdateFinancePAComponent: WMICUpdateFinancePAComponent,
            WMICBodilyInjuryComponent: WMICBodilyInjuryComponent,
            WMICEditUninsuredComponent: WMICEditUninsuredComponent,
            wmicpolicychangepaymentPlanBox: WMICPolicyChangePaymentPlanBox,
            WMICChangeAddressComponent: WMICChangeAddressComponent,
            WMICChangeDesignatedPersonComponent: WMICChangeDesignatedPersonComponent,
            WMICReviewPolicyChangePAComponent: WMICReviewPolicyChangePAComponent,
            WMICBoundPolicyChangePA: WMICBoundPolicyChangePA,
            WMICSuccessPolicyChangeComponent: WMICSuccessPolicyChangeComponent,
            WMICLoaderComponent: WMICLoader,

        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyDetails}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
WMICPolicyChange.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired
};

export default withRouter(WMICPolicyChange);