import React, {
    useCallback,
    useContext,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICUserAccessUtil, WMICCreditConsentUtil, WMICValidationUtil, WMICJurisdictionUtil, JURISDICTIONS, WMICConstantsUtil, WMICLogger } from 'wmic-pe-portals-utils-js';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';

function WMICPAPolicyRenewalPolicyDetailsPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData
    } = props;

    const { RenewalService } = useDependencies('RenewalService');
    const { setWizardLoading } = useWizardModals();
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const { authHeader, authUserData } = useAuthentication();
    const [showErrors, setShowErrors] = useState(false);
    const rateAsOfDate = _.get(renewalVM, 'baseData.rateAsOfDate.value');
    const [isInitialized, setInitialized] = useState(WMICConstantsUtil.alreadyInitialized(rateAsOfDate));
    const translator = useContext(TranslatorContext);

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        initialValidation
    } = useValidation(
        'WMICPCPAPolicyDetailsPage'
    );

    useDocumentTitle(translator(commonMessages.policyDetailsTitle), renewalVM);

    useEffect(() => {
        const initializeConstants = async () => {
            try {
                const constantsInitialized = await WMICConstantsUtil.initialize(rateAsOfDate, authHeader);

                setInitialized(constantsInitialized);
            } catch(error) {
                WMICLogger.error('Constants util initialization failed', error);
            }
        }

        !isInitialized && initializeConstants();
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateDriversForPNI = useCallback((namedInsured) => {
        const allDrivers = _.get(renewalVM, 'draftData.lobs.personalAuto.coverables.drivers.value')
        if (WMICCreditConsentUtil.isPniCreditConsentable(_.get(renewalVM, 'baseData.primaryNamedInsured_WMIC'))
            && allDrivers
            && allDrivers.length > 0) {
            let found = false;
            for (let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
                const driver = allDrivers[driverCounter];
                if (driver.person.publicID === namedInsured.contactPublicID || driver.person.publicID === namedInsured.publicID) {
                    driver.creditConsentReceived = _.get(renewalVM, 'baseData.creditConsentReceived.value.code');
                    driver.creditConsentDate = _.get(renewalVM, 'baseData.creditConsentDate.value');
                    driver.creditInfoWithdrawalConsent = _.get(renewalVM, 'baseData.creditInfoWithdrawalConsent.value');
                    driver.personalInfoConsentForm = _.get(renewalVM, 'baseData.personalInfoConsentForm.value');
                    found = true;
                }
            }
            updateWizardData(renewalVM)
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }
        try {
            setWizardLoading(true);

            if (WMICJurisdictionUtil.isBaseState(_.get(renewalVM, 'baseData'), JURISDICTIONS.QUEBEC)) {
                updateDriversForPNI(_.get(renewalVM, 'draftData.primaryNamedInsured_WMIC.value'));
            }

            const newRenewalVM = _.cloneDeep(renewalVM.value);

            renewalVM.value = await RenewalService.saveRenewal(
                [newRenewalVM],
                authHeader
            );
            updateWizardData(renewalVM);

            return renewalVM;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, renewalVM, RenewalService, authHeader, updateDriversForPNI]);

    if (!isInitialized) {
        return null;
    };

    return (
        <WMICWizardChangeOrRenewalPage
            showPrevious={false}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={renewalVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={isReadOnlyUser}
                authHeader={authHeader}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPAPolicyRenewalPolicyDetailsPage.propTypes = wizardProps;

export default withViewModelService(WMICPAPolicyRenewalPolicyDetailsPage);
