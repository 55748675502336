import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import WMICOverduePolicyModal from './WMICOverduePolicyModal';
import WMICOverduePolicy from './WMICOverduePolicy';
import styles from './WMICOverduePolicies.module.scss';

const WMICOverduePolicies = ({ policies, onPayPolicyClick, openModalOnRender, openModal, onClose }) => {
    const modalApi = useModal();
    const triggerModal = useCallback(async () => {
        await modalApi.showModal(
            <WMICOverduePolicyModal
                policies={policies}
                onPayPolicyClick={onPayPolicyClick}
                onClose={onClose}
            />
        );
    }, [policies, onPayPolicyClick, onClose]);

    useEffect(() => {
        if (openModalOnRender && policies.length) {
            triggerModal();
        }
    }, [openModalOnRender, triggerModal, policies]);

    useEffect(() => {
        if(policies.length) {
            openModal && triggerModal()
        }
    }, [policies, openModal, triggerModal])

    return (
        <div className={styles.list}>
            {
                policies.map(({ id, details }) => (
                    <WMICOverduePolicy
                        key={id}
                        id={id}
                        type={details.policyType}
                        line={details.policyData.lines[0]}
                        onClick={triggerModal}
                    />
                ))
            }
        </div>
    );
};

WMICOverduePolicies.propTypes = {
    policies: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            details: PropTypes.object
        })
    ).isRequired,
    onPayPolicyClick: PropTypes.func.isRequired,
    openModalOnRender: PropTypes.bool,
    onResolve: PropTypes.func.isRequired,
    openModal: PropTypes.bool
};

WMICOverduePolicies.defaultProps = {
    openModalOnRender: true
};

export default WMICOverduePolicies;