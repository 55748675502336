/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import styles from './WMICDropdownSelect.module.scss';
import messages from './WMICDropdownSelect.messages';

const WMICDropdownSelect = (props) => {
    const {
        id,
        availableValues,
        label,
        labelClassName,
        labelPosition,
        onValueChange,
        value,
        visible,
        className,
        placeholder,
        onValidationChange,
        required,
        disabled,
        onFocus,
        onBlur,
        hideLabel,
        readOnly,
        alwaysShowPlaceholder,
        showErrors,
        validationMessages
    } = props;
    const translator = useTranslator();
    const [valueSelected, setValueSelected] = useState(false);

    const getStringParam = (param) => {
        switch (typeof(param)) {
            case 'string':
                return param;
            case 'object':
                return WMICRichTextUtil.translateRichText(translator(param));
            default:
                return '';
        }
    };

    const setRequired = (val) => {
        if (required && onValidationChange) {
            onValidationChange(val && val !== 'undefined' && val !== '');
        }
    };

    const onChange = (newVal) => {
        onValueChange(newVal);
        setRequired(newVal);
        if (newVal !== '') {
            setValueSelected(true);
        }
    };

    useEffect(() => {
        setRequired(value);
    }, []);

    const getMobileDropdown = () => {
        const getDisplayName = (val) => val.typelist ? translator({"id": val.name, "defaultMessage": val.description}) : val.displayName || val.name;
        const getValue = (val) => val.typelist ? val.code : val.id || val.code;
        const hasErrors = showErrors && validationMessages.length > 0;

        return visible ? (
            <div className={cx(className, labelPosition === 'left' ? styles.wmicLabelLeft : styles.wmicLabelTop)}>
                { !hideLabel && (
                    <div>
                        <label id={`${id}_label`} htmlFor={`${id}_select`} className={labelClassName}>
                            <span>{getStringParam(label)}</span>
                        </label>
                    </div>
                )}
                <div>
                    <select 
                        id={`${id}_select`}
                        disabled={disabled || readOnly ? 'disabled' : ''} 
                        className={cx(styles.wmicSelect, hasErrors ? styles.invalid : '' )}
                        onChange={(event) => {onChange(event.target.value);}} 
                        value={value} 
                        onFocus={onFocus} 
                        onBlur={onBlur}>
                        { placeholder && (
                            <option className={cx(!alwaysShowPlaceholder ? styles.wmicPlaceholderHide : '')} value='' disabled={placeholder.isDisabled || false} selected='selected'>{getStringParam(placeholder)}</option>
                        )}
                        { !placeholder && value === '' && (
                            <option className={cx(!alwaysShowPlaceholder ? styles.wmicPlaceholderHide : '')} value='' disabled='disabled' selected='selected'>{translator(messages.pleaseSelect)}</option>
                        )}
                        { availableValues?.map((v) => v.visible !== false && v.retired !== true && (<option value={getValue(v)} disabled={v.isDisabled}>{getDisplayName(v)}</option>))}
                    </select>
                </div>
                {hasErrors && (
                    validationMessages.map((valMsg, i) => {
                        return <div className='jut__OldFieldMessage__fieldMessage jut__OldFieldMessage__error' key={i}>{valMsg}</div>;
                    })
                )}
            </div>
        ) : null;
    };

    return getMobileDropdown();
};

WMICDropdownSelect.propTypes = {
    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    availableValues: PropTypes.shape({}),
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        })
    ]),
    labelClassName: PropTypes.string,
    onValueChange: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        })
    ]),
    onValidationChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    hideLabel: PropTypes.bool,
    readOnly: PropTypes.bool,
    alwaysShowPlaceholder: PropTypes.bool
};

WMICDropdownSelect.defaultProps = {
    labelPosition: 'top',
    visible: true,
    required: false,
    disabled: false,
    onFocus: () => {},
    onBlur: () => {},
    hideLabel: false,
    readOnly: false,
    alwaysShowPlaceholder: true,
    showErrors: false,
    validationMessages: []
};

export default WMICDropdownSelect;
