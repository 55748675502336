import { defineMessages } from 'react-intl';

export default defineMessages({
    unexpectedStatus: {
        id: 'wmic.capabilities.policy-change.services.Unexpected status',
        defaultMessage: 'Unexpected status: {status}'
    },
    modelShouldImplementObjectType: {
        id: 'wmic.capabilities.policy-change.services.Model should implement objectType',
        defaultMessage: 'Model should implement objectType: \'{value}\''
    },
    noModelFound: {
        id: 'wmic.capabilities.policy-change.services.No model found.',
        defaultMessage: 'No model \'{value}\' found.'
    },
    shouldDefineGetMessageTitle: {
        id: 'wmic.capabilities.policy-change.services.The model {key} should define a static method getMessageTitle().',
        defaultMessage: 'The model {key} should define a static method getMessageTitle().'
    },
    deleteVehicle: {
        id: 'wmic.capabilities.policy-change.services.Delete Vehicle',
        defaultMessage: 'Delete Vehicle'
    },
    yes: {
        id: 'wmic.capabilities.policy-change.services.Yes, Delete',
        defaultMessage: 'Yes, Delete'
    },
    cancel: {
        id: 'wmic.capabilities.policy-change.services.Cancel Deletion',
        defaultMessage: 'Cancel Deletion'
    },
    pleaseCallUs: {
        id: 'wmic.capabilities.policy-change.services.Please call us',
        defaultMessage: 'Please call us'
    },
    callUs: {
        id: 'wmic.capabilities.policy-change.services.We\'re sorry the changes you need aren\'t available online. Our agents are ready to help, call us at &lt;a&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.',
        defaultMessage: 'We\'re sorry the changes you need aren\'t available online. Our agents are ready to help, call us at &lt;a&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    close: {
        id: 'wmic.capabilities.policy-change.services.Close',
        defaultMessage: 'Close'
    },
    agentWillContact: {
        id: 'wmic.capabilities.policy-change.services.Agent will contact',
        defaultMessage: 'Once submitted, an agent will contact you shortly to finish processing this change. After it is fully processed, the agent will confirm with you when the change will take effect.'
    },
    agentWillReview: {
        id: 'wmic.capabilities.policy-change.services.Agent will review',
        defaultMessage: 'Once submitted, an agent will review and finish processing this change. You will receive updated documents confirming when the change has taken effect.'
    }
});
