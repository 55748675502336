import React, { useEffect, useState } from 'react';
import { FormattedCurrency } from '@jutro/components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import {CONSTANTS, WMICPaymentUtil} from 'wmic-portals-utils-js';
import { useMediaQuery } from 'react-responsive';
import { WMICAccordionCard } from 'wmic-components-amp-common-react';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';

import metadata from './WMICLocationInfoBox.metadata.json5';
import messages from './WMICLocationInfoBox.messages';
import styles from './WMICLocationInfoBox.module.scss';

const WMICLocationInfoBox = (props) => {
    const { lobData, jurisdiction } = props;
    let overrides = {};
    const translator = useTranslator();
    const [hoDeductibleCov, setHoDeductibleCov] = useState(undefined);
    const [hoCoverageType, setHoCoverageType] = useState(undefined);
    const [hoPersonalPropertyReplacementCostSettlementCov, setHoPersonalPropertyReplacementCostSettlementCov] = useState(undefined);
    const [hoDwellingCov, setHoDwellingCov] = useState(undefined);
    const [hoOtherStructuresCov, setHoOtherStructuresCov] = useState(undefined);
    const [hoPersonalPropertyCov, setHoPersonalPropertyCov] = useState(undefined);
    const [hoAddlLivingExpCov, setHoAddlLivingExpCov] = useState(undefined);
    const [hoPersonalLiabilityCov, setHoPersonalLiabilityCov] = useState(undefined);
    const [hoMedicalPaymentsCov, setHoMedicalPaymentsCov] = useState(undefined);
    const [hoScheduledPropertyCov, setHoScheduledPropertyCov] = useState([]);
    const [hoBusinessPursuitsClericalCov, setHoBusinessPursuitsClericalCov] = useState(undefined);
    const [hoBusinessPursuitsSalespersonCov, setHoBusinessPursuitsSalespersonCov] = useState(undefined);
    const [hoBusinessPursuitsTeacherCov, setHoBusinessPursuitsTeacherCov] = useState(undefined);
    const [hoBusinessPursuitsOtherCov, setHoBusinessPursuitsOtherCov] = useState(undefined);
    const [hoCreditCardCov, setHoCreditCardCov] = useState(undefined);
    const [hoEarthquakeCov, setHoEarthquakeCov] = useState(undefined);
    const [hoHomeCyberProtectionCov, setHoHomeCyberProtectionCov] = useState(undefined);
    const [hoSpecialLimitsEnhancementCov, setHoSpecialLimitsEnhancementCov] = useState(undefined);
    const [hoHomeSystemsProtectionCov, setHoHomeSystemsProtectionCov] = useState(undefined);
    const [hoIdentityFraudExpenseCov, setHoIdentityFraudExpenseCov] = useState(undefined);
    const [hoIncreasedLimitsOnBusinessPropertyCov, setHoIncreasedLimitsOnBusinessPropertyCov] = useState(undefined);
    const [hoLossAssessmentCov, setHoLossAssessmentCov] = useState(undefined);
    const [hoServiceLineCov, setHoServiceLineCov] = useState(undefined);
    const [hoWildfireResponseCov, setHoWildfireResponseCov] = useState(undefined);
    const [hoIncrSpecOfLiabScheduledProperty, setHoIncrSpecOfLiabScheduledProperty] = useState([]);
    const [hoPermIncOccResPermCov, setHoPermIncOccResPermCov] = useState(undefined);
    const [hoOtherStructuresEndorsementCov, setHoOtherStructuresEndorsementCov] = useState([]);
    const isCondo = lobData?.dwellingDTO.policyType.toLowerCase() === CONSTANTS.POLICY_TYPES.HO.CONDOMINIUM.toLowerCase();
    const isHomeowners = lobData?.dwellingDTO.policyType.toLowerCase() === CONSTANTS.POLICY_TYPES.HO.HOMEOWNERS.toLowerCase();
    const isRenters = lobData?.dwellingDTO.policyType.toLowerCase() === CONSTANTS.POLICY_TYPES.HO.RENTERS.toLowerCase();
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const [propertyCoverageAccordionOpenIDs, setpropertyCoverageAccordionOpenIDs] = useState(undefined);
    const [scheduledPropertyItemsCoverageAccordionOpenIDs, setscheduledPropertyItemsCoverageAccordionOpenIDs] = useState(undefined);
    const [additionalCoverageAccordionOpenIDs, setadditionalCoverageAccordionOpenIDs] = useState(undefined);
    const [increasedSpecialLimitsAccordionOpenIDs, setincreasedSpecialLimitsAccordionOpenIDs] = useState(undefined);
    const [otherStructuresEndorsementAccordionOpenIDs, setotherStructuresEndorsementAccordionOpenIDs] = useState(undefined);
    const [liabilityCoverageAccordionOpenIDs, setliabilityCoverageAccordionOpenIDs] = useState(undefined);

    const getCoverageByCoverageCode = (item, code) => {
        return _.find(item?.coverageDTOs, {
            patternCode_WMIC: code
        });
    };

    const hasAdditionalCoverageLimits = () => {
        return overrides.hoBusinessPursuitsClericalCovGrid?.visible || overrides.hoBusinessPursuitsSalespersonCovGrid?.visible
            || overrides.hoBusinessPursuitsTeacherCovGrid?.visible || overrides.hoBusinessPursuitsOtherCovGrid?.visible
            || overrides.hoCreditCardCovGrid?.visible || overrides.hoEarthquakeCovGrid?.visible
            || overrides.hoHomeCyberProtectionCovGrid?.visible || overrides.hoSpecialLimitsEnhancementCovGrid?.visible
            || overrides.hoHomeSystemsProtectionCovGrid?.visible || overrides.hoIdentityFraudExpenseCovGrid?.visible
            || overrides.hoIncreasedLimitsOnBusinessPropertyCovGrid?.visible || overrides.hoLossAssessmentCovGrid?.visible
            || overrides.hoServiceLineCovGrid?.visible || overrides.hoWildfireResponseCovGrid?.visible;
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent,
            WMICAccordionCard,
        }
    };

    const renderLocationTitle = (data) => {
        const address = data?.dwellingDTO.address;
        let addressInfo = '';
        if (address.addressLine1) {
            addressInfo = addressInfo.concat(address.addressLine1);
        }
        if (address.addressLine2) {
            addressInfo = addressInfo.concat(', ').concat(address.addressLine2);
        }
        if (address.city) {
            addressInfo = addressInfo.concat(', ').concat(address.city);
        }
        if (address.state && address.postalCode) {
            addressInfo = addressInfo.concat(', ').concat(address.state).concat(' ').concat(address.postalCode);
        }

        return (
            <h2 className={styles.locationTitle}><i className="fas fa-home titleIcon" />{addressInfo}</h2>
        );
    };

    const getInsuredLimit = (cov) => {
        const roundUpToNearest = (toRound, interval) => {
            if (interval === 0 || toRound === null) {
                return 0;
            }
            return (parseFloat(Math.ceil(toRound / interval)) * interval);
        };
        const calculatedLimit = cov?.terms[0]?.amount || '0';
        const adjustmentLimit = cov?.terms[1]?.amount || '0';
        const calcLimit = parseFloat(calculatedLimit.replace(/\s/g, '').replace(/,/g, ''));
        const adjLimit = (adjustmentLimit !== '') ? parseFloat((adjustmentLimit).replace(/\s/g, '').replace(/,/g, '')) : 0;
        if (cov?.patternCode_WMIC === CONSTANTS.COVERAGE_NAMES.HO_OTHERSTRUCTURES) {
            return roundUpToNearest(calcLimit + adjLimit, 50);
        }
        return calcLimit + adjLimit;
    };

    const getMessageWithHeader = (msg) => {
        return(
            <h3 className={styles.locationSubheader}>{msg}</h3>
        );
    }
 
    useEffect(() => {
        const deductible = getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_PACKAGE);
        if (deductible?.terms.length > 0 && deductible.terms[0].amount.toString().toUpperCase() === CONSTANTS.NO_DEDUCTIBLE.toUpperCase()) {
            deductible.terms[0].amount = 0;
        }
        setHoDeductibleCov(deductible);
        setHoCoverageType(
            {
                terms: [
                    {
                        name: jurisdiction === CONSTANTS.JURISDICTIONS.CA ? translator(messages.special) : _.capitalize(lobData?.dwellingDTO?.homeDetails.formType_WMIC)
                    }
                ]
            }
        );
        const ppReplacementCost = getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTYREPLACEMENTCOSTSETTLEMENT);
        setHoPersonalPropertyReplacementCostSettlementCov(
            {
                terms: [
                    {
                        name: jurisdiction === CONSTANTS.JURISDICTIONS.CA || (jurisdiction === CONSTANTS.JURISDICTIONS.CA && !!ppReplacementCost) ? translator(messages.replacementCost) : translator(messages.actualCashValue)
                    }
                ]
            }
        );
        setHoDwellingCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_DWELLING));
        setHoOtherStructuresCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_OTHERSTRUCTURES));
        setHoPersonalPropertyCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTY));
        setHoAddlLivingExpCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_ADDLLIVINGEXP));
        setHoPersonalLiabilityCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_PERSONALLIABILITY));
        setHoMedicalPaymentsCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_MEDICALPAYMENTS));
        setHoScheduledPropertyCov(lobData?.coverageDTOs[0]?.scheduledProperty_WMIC || []);
        setHoBusinessPursuitsClericalCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_BUSINESSPURSUITSCLERICAL));
        setHoBusinessPursuitsSalespersonCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_BUSINESSPURSUITSSALESPERSON));
        setHoBusinessPursuitsTeacherCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_BUSINESSPURSUITSTEACHER));
        setHoBusinessPursuitsOtherCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_BUSINESSPURSUITSOTHER));
        setHoCreditCardCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_CREDITCARD));
        setHoEarthquakeCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_EARTHQUAKE));
        setHoHomeCyberProtectionCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_HOMECYBERPROTECTION));
        setHoSpecialLimitsEnhancementCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_SPECIALLIMITSENHANCEMENT));
        setHoHomeSystemsProtectionCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_HOMESYSTEMSPROTECTION));
        setHoIdentityFraudExpenseCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_IDENTITYFRAUDEXPENSE));
        setHoIncreasedLimitsOnBusinessPropertyCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_INCRLMTBUSPROPERTY));
        setHoLossAssessmentCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_LOSSASSESSMENT));
        setHoServiceLineCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_SERVICELINE));
        setHoWildfireResponseCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_WILDFIRERESPONSE));
        setHoPermIncOccResPermCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_PERMINCOCCRES));
        setHoIncrSpecOfLiabScheduledProperty(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_INCRSPECLMTOFLIAB));
        setHoOtherStructuresEndorsementCov(getCoverageByCoverageCode(lobData?.dwellingDTO, CONSTANTS.COVERAGE_NAMES.HO_OTHERSTRUCTURESENDORSEMENT));
    }, []);

    overrides = {
        locationInfoBoxTitle: {
            content: renderLocationTitle(lobData)
        },
        propertyCoverageAccordionCard: {
            title: getMessageWithHeader(translator(messages.propertyCoverageAndLimits))
        },
        liabilityCoverageContentContainer: {
            visible: !!hoPersonalLiabilityCov || !!hoMedicalPaymentsCov
        },
        liabilityCoverageAccordionCard: {
            title: getMessageWithHeader(translator(messages.liabilityCoverageAndLimits))
        },
        scheduledPropertyItemsCoverageContentContainer: {
            visible: hoScheduledPropertyCov.length > 0
        },
        scheduledPropertyItemsCoverageAccordionCard: {
            title: getMessageWithHeader(translator(messages.scheduledPropertyItemsAndLimits))
        },
        additionalCoverageAccordionCard: {
            title: getMessageWithHeader(translator(messages.additionalCoverageAndLimits))
        },
        increasedSpecialLimitsContentContainer: {
            visible: hoIncrSpecOfLiabScheduledProperty?.scheduledProperty_WMIC?.length > 0 || !!hoPermIncOccResPermCov
        },
        increasedSpecialLimitsAccordionCard: {
            title: getMessageWithHeader(translator(messages.increasedSpecialLimitsOfLiability))
        },
        otherStructuresEndorsementContentContainer: {
            visible: hoOtherStructuresEndorsementCov?.scheduledProperty_WMIC?.length > 0 && (isCondo || isHomeowners)
        },
        otherStructuresEndorsementAccordionCard: {
            title: getMessageWithHeader(translator(messages.otherStructuresEndorsement))
        },
        hoDeductibleCovGrid: {
            visible: !!hoDeductibleCov
        },
        hoDeductibleCovValue: {
            content: `$${hoDeductibleCov?.terms[0].amount}`
        },
        hoCoverageTypeCovGrid: {
            visible: !!hoCoverageType
        },
        hoCoverageTypeCovValue: {
            content: hoCoverageType?.terms[0].name
        },
        hoPersonalPropertyReplacementCostSettlementCovGrid: {
            visible: !!hoPersonalPropertyReplacementCostSettlementCov
        },
        hoPersonalPropertyReplacementCostSettlementCovValue: {
            content: hoPersonalPropertyReplacementCostSettlementCov?.terms[0].name
        },
        hoDwellingCovGrid: {
            visible: !!hoDwellingCov && (isCondo || isHomeowners)
        },
        hoDwellingCovGridTooltip: {
            tooltipMessage: translator(messages.dwellingTooltip)
        },
        hoDwellingCovValue: {
            content: <FormattedCurrency value={WMICPaymentUtil.getShapedCurrencyAmount(getInsuredLimit(hoDwellingCov))} />
        },
        hoOtherStructuresCovGrid: {
            visible: !!hoOtherStructuresCov && isHomeowners
        },
        hoOtherStructuresCovGridTooltip: {
            tooltipMessage: translator(messages.otherStructuresTooltip)
        },
        hoOtherStructuresCovValue: {
            content: <FormattedCurrency value={WMICPaymentUtil.getShapedCurrencyAmount(getInsuredLimit(hoOtherStructuresCov))} />
        },
        hoPersonalPropertyCovGrid: {
            visible: !!hoPersonalPropertyCov
        },
        hoPersonalPropertyCovGridTooltip: {
            tooltipMessage: translator(messages.personalPropertyTooltip)
        },
        hoPersonalPropertyCovValue: {
            content: <FormattedCurrency value={WMICPaymentUtil.getShapedCurrencyAmount(getInsuredLimit(hoPersonalPropertyCov))} />
        },
        hoAddlLivingExpCovGrid: {
            visible: (isHomeowners && !!hoAddlLivingExpCov?.terms[0]) || isRenters || isCondo
        },
        hoAddlLivingExpCovGridTooltip: {
            tooltipMessage: translator(messages.lossOfUseTooltip)
        },
        hoAddlLivingExpCovValue: {
            content: isHomeowners && !!hoAddlLivingExpCov?.terms[0] ? <FormattedCurrency value={WMICPaymentUtil.getShapedCurrencyAmount(getInsuredLimit(hoAddlLivingExpCov))} /> : translator(messages.included)
        },
        hoPersonalLiabilityCovGrid: {
            visible: !!hoPersonalLiabilityCov
        },
        hoPersonalLiabilityCovGridTooltip: {
            tooltipMessage: translator(messages.personalLiabilityTooltip)
        },
        hoPersonalLiabilityCovValue: {
            content: `$${hoPersonalLiabilityCov?.terms[0].amount}`
        },
        hoMedicalPaymentsCovGrid: {
            visible: !!hoMedicalPaymentsCov
        },
        hoMedicalPaymentsCovGridTooltip: {
            tooltipMessage: translator(messages.medicalPaymentsToOthersTooltip)
        },
        hoMedicalPaymentsCovValue: {
            content: `$${hoMedicalPaymentsCov?.terms[0].amount}`
        },
        hoBusinessPursuitsClericalCovGrid: {
            visible: !!hoBusinessPursuitsClericalCov && jurisdiction === CONSTANTS.JURISDICTIONS.CA
        },
        hoBusinessPursuitsClericalCovGridTooltip: {
            tooltipMessage: translator(messages.businessPursuitsTooltip)
        },
        hoBusinessPursuitsClericalCovGridLabelContacts: {
            data: hoBusinessPursuitsClericalCov?.scheduledProperty
        },
        hoBusinessPursuitsSalespersonCovGrid: {
            visible: !!hoBusinessPursuitsSalespersonCov && jurisdiction === CONSTANTS.JURISDICTIONS.CA
        },
        hoBusinessPursuitsSalespersonCovGridTooltip: {
            tooltipMessage: translator(messages.businessPursuitsTooltip)
        },
        hoBusinessPursuitsSalespersonCovGridLabelContacts: {
            data: hoBusinessPursuitsSalespersonCov?.scheduledProperty
        },
        hoBusinessPursuitsTeacherCovGrid: {
            visible: !!hoBusinessPursuitsTeacherCov
        },
        hoBusinessPursuitsTeacherCovGridTooltip: {
            tooltipMessage: translator(messages.businessPursuitsTooltip)
        },
        hoBusinessPursuitsTeacherCovGridLabelContacts: {
            data: hoBusinessPursuitsTeacherCov?.scheduledProperty
        },
        hoBusinessPursuitsOtherCovGrid: {
            visible: !!hoBusinessPursuitsOtherCov && jurisdiction === CONSTANTS.JURISDICTIONS.OR
        },
        hoBusinessPursuitsOtherCovGridTooltip: {
            tooltipMessage: translator(messages.businessPursuitsTooltip)
        },
        hoBusinessPursuitsOtherCovGridLabelContacts: {
            data: hoBusinessPursuitsOtherCov?.scheduledProperty
        },
        hoCreditCardCovGrid: {
            visible: !!hoCreditCardCov
        },
        hoCreditCardCovGridTooltip: {
            tooltipMessage: translator(messages.increasedCreditCardLimitTooltip)
        },
        hoCreditCardCovValue: {
            content: `$${hoCreditCardCov?.terms[0].amount}`
        },
        hoEarthquakeCovGrid: {
            visible: !!hoEarthquakeCov
        },
        hoEarthquakeCovGridTooltip: {
            tooltipMessage: translator(messages.earthquakeTooltip)
        },
        hoEarthquakeCovGridAdditionalRow: {
            content: !isRenters || jurisdiction === CONSTANTS.JURISDICTIONS.OR ? translator(messages.deductibleOfDwelling) : translator(messages.deductible)
        },
        hoEarthquakeCovValue: {
            content: hoEarthquakeCov?.terms[0].amount
        },
        hoHomeCyberProtectionCovGrid: {
            visible: !!hoHomeCyberProtectionCov && (isCondo || isHomeowners)
        },
        hoHomeCyberProtectionCovGridTooltip: {
            tooltipMessage: translator(messages.homeCyberProtectionTooltip)
        },
        hoHomeCyberProtectionCovGridAdditionalRow: {
            content: `${translator(messages.deductible)} $${hoHomeCyberProtectionCov?.terms[1].amount}`
        },
        hoHomeCyberProtectionCovValue: {
            content: `$${hoHomeCyberProtectionCov?.terms[0].amount}`
        },
        hoSpecialLimitsEnhancementCovGrid: {
            visible: !!hoSpecialLimitsEnhancementCov && (isCondo || isHomeowners)
        },
        hoSpecialLimitsEnhancementCovGridTooltip: {
            tooltipMessage: translator(messages.specialLimitsEnhancementEndorsementTooltip)
        },
        hoHomeSystemsProtectionCovGrid: {
            visible: !!hoHomeSystemsProtectionCov && (isCondo || isHomeowners || jurisdiction === CONSTANTS.JURISDICTIONS.OR)
        },
        hoHomeSystemsProtectionCovGridTooltip: {
            tooltipMessage: translator(messages.homeSystemsProtectionTooltip)
        },
        hoHomeSystemsProtectionCovGridAdditionalRow: {
            content: `${translator(messages.deductible)} $${hoHomeSystemsProtectionCov?.terms[1].amount}`
        },
        hoHomeSystemsProtectionCovValue: {
            content: `$${hoHomeSystemsProtectionCov?.terms[0].amount}`
        },
        hoIdentityFraudExpenseCovGrid: {
            visible: !!hoIdentityFraudExpenseCov && (isCondo || isHomeowners || jurisdiction === CONSTANTS.JURISDICTIONS.OR)
        },
        hoIdentityFraudExpenseCovGridTooltip: {
            tooltipMessage: translator(messages.identityFraudExpenseTooltip)
        },
        hoIdentityFraudExpenseCovGridAdditionalRow: {
            content: `${translator(messages.deductible)} $${hoIdentityFraudExpenseCov?.terms[1].amount}`
        },
        hoIdentityFraudExpenseCovValue: {
            content: `$${hoIdentityFraudExpenseCov?.terms[0].amount}`
        },
        hoIncreasedLimitsOnBusinessPropertyCovGrid: {
            visible: !!hoIncreasedLimitsOnBusinessPropertyCov
        },
        hoIncreasedLimitsOnBusinessPropertyCovGridTooltip: {
            tooltipMessage: translator(messages.increasedLimitsOnBusinessPropertyTooltip)
        },
        hoIncreasedLimitsOnBusinessPropertyCovValue: {
            content: `$${hoIncreasedLimitsOnBusinessPropertyCov?.terms[0].amount}`
        },
        hoLossAssessmentCovGrid: {
            visible: !!hoLossAssessmentCov && (isCondo || isHomeowners)
        },
        hoLossAssessmentCovGridTooltip: {
            tooltipMessage: translator(messages.lossAssessmentTooltip)
        },
        hoLossAssessmentCovValue: {
            content: `$${hoLossAssessmentCov?.terms[0].amount}`
        },
        hoServiceLineCovGrid: {
            visible: !!hoServiceLineCov?.terms[0] && jurisdiction === CONSTANTS.JURISDICTIONS.CA && isHomeowners
        },
        hoServiceLineCovGridTooltip: {
            tooltipMessage: translator(messages.serviceLineTooltip)
        },
        hoServiceLineCovGridAdditionalRow: {
            content: `${translator(messages.deductible)} $${hoServiceLineCov?.terms[1].amount}`
        },
        hoServiceLineCovValue: {
            content: `$${hoServiceLineCov?.terms[0].amount}`
        },
        hoWildfireResponseCovGrid: {
            visible: !!hoWildfireResponseCov && jurisdiction === CONSTANTS.JURISDICTIONS.CA && isHomeowners
        },
        hoWildfireResponseCovGridTooltip: {
            tooltipMessage: translator(messages.wildfireResponseProgramTooltip)
        },
        increasedSpecialLimitsIterable: {
            data: hoIncrSpecOfLiabScheduledProperty?.scheduledProperty_WMIC
        },
        hoPermIncOccResPermCovGrid: {
            visible: !!hoPermIncOccResPermCov
        },
        hoPermIncOccResPermCovGridTooltip: {
            tooltipMessage: translator(messages.permittedIncidentalOccupancesOnTheResudencePremisesTooltip)
        },
        otherStructuresEndorsementIterable: {
            data: hoOtherStructuresEndorsementCov?.scheduledProperty_WMIC
        },
        locationInfoBoxContainer: {
            visible: !isMobile
        },
        locationInfoBoxContainerMobile: {
            visible: isMobile
        },

        propertyCoverageAccordion: {
            accordionStates: propertyCoverageAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setpropertyCoverageAccordionOpenIDs(openCardID)} 
        },
        liabilityCoverageAccordion: {
            accordionStates: liabilityCoverageAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setliabilityCoverageAccordionOpenIDs(openCardID)} 
        },
        scheduledPropertyItemsCoverageAccordion: {
            accordionStates: scheduledPropertyItemsCoverageAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setscheduledPropertyItemsCoverageAccordionOpenIDs(openCardID)}
        },
        additionalCoverageAccordion: {
            accordionStates: additionalCoverageAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setadditionalCoverageAccordionOpenIDs(openCardID)}
        },
        increasedSpecialLimitsAccordion: {
            accordionStates: increasedSpecialLimitsAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setincreasedSpecialLimitsAccordionOpenIDs(openCardID)}
        },
        otherStructuresEndorsementAccordion: {
            accordionStates: otherStructuresEndorsementAccordionOpenIDs,
            onUpdateAccordionStates: (openCardID) => { setotherStructuresEndorsementAccordionOpenIDs(openCardID)}
        }
    };

    overrides.additionalCoverageContentContainer = {
        visible: hasAdditionalCoverageLimits()
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={lobData}
        />
    );
};

WMICLocationInfoBox.propTypes = {
    lobData: PropTypes.shape({}).isRequired,
    jurisdiction: PropTypes.string.isRequired
};

export default WMICLocationInfoBox;