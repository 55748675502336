// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPolicyTransactions__link>span{font-weight:var(--GW-FONT-WEIGHT-REGULAR)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-policy-common-react/WMICPolicyWizard/pages/WMICPolicyTransactions/WMICPolicyTransactions.module.scss"],"names":[],"mappings":"AAAA,wCACI,yCAAA","sourcesContent":[".link > span {\n    font-weight: var(--GW-FONT-WEIGHT-REGULAR);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "app__WMICPolicyTransactions__link"
};
export default ___CSS_LOADER_EXPORT___;
