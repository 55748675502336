// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICLocationAndIncidentInfoBox__capitalizeText{text-transform:capitalize}.app__WMICLocationAndIncidentInfoBox__locationColumn{flex-grow:0}.app__WMICLocationAndIncidentInfoBox__wmic-location-map{padding:0}.app__WMICLocationAndIncidentInfoBox__wmic-location-map .app__WMICLocationAndIncidentInfoBox__wmic-map-responsive{position:relative}.app__WMICLocationAndIncidentInfoBox__wmic-location-map .app__WMICLocationAndIncidentInfoBox__wmic-map-responsive .app__WMICLocationAndIncidentInfoBox__wmic-google-map{border:none;height:450px}.app__WMICLocationAndIncidentInfoBox__wmic-location-map .app__WMICLocationAndIncidentInfoBox__wmic-map-responsive .app__WMICLocationAndIncidentInfoBox__wmic-google-map>div>div{border-radius:0!important}h2[id=locationAndIncidentInfoBoxTitle]{font-weight:600}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-claim-react/components/WMICLocationAndIncidentInfoBox/WMICLocationAndIncidentInfoBox.module.scss"],"names":[],"mappings":"AAIA,qDACE,yBAAA,CAGF,qDACE,WAAA,CAGF,wDACE,SAAA,CACA,kHACE,iBAAA,CACA,wKACE,WAAA,CACA,YAAA,CACA,gLACE,yBAAA,CAMR,uCACE,eAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n\n.capitalizeText {\n  text-transform: capitalize;\n}\n\n.locationColumn {\n  flex-grow: unset;\n}\n\n.wmic-location-map {\n  padding: 0;\n  .wmic-map-responsive {\n    position: relative;\n    .wmic-google-map {\n      border: none;\n      height: 450px;\n      & > div > div {\n        border-radius: 0px !important;\n      }\n    }\n  }\n}\n\nh2[id=locationAndIncidentInfoBoxTitle] {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"capitalizeText": "app__WMICLocationAndIncidentInfoBox__capitalizeText",
	"locationColumn": "app__WMICLocationAndIncidentInfoBox__locationColumn",
	"wmic-location-map": "app__WMICLocationAndIncidentInfoBox__wmic-location-map",
	"wmic-map-responsive": "app__WMICLocationAndIncidentInfoBox__wmic-map-responsive",
	"wmic-google-map": "app__WMICLocationAndIncidentInfoBox__wmic-google-map"
};
export default ___CSS_LOADER_EXPORT___;
