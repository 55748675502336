const constants = {
    JOBS_CREATED_IN_LAST_DAYS: 45,
    LIMIT_NUMBER_OF_POLICIES: 45,
    POLICIES_CREATED_IN_LAST_DAYS: 90,
    MAX_NUBER_RECENTLY_ISSUED_POLICIES: 5,
    MAX_NUBER_RECENTLY_VIEWED_POLICIES: 5,
    PAGE_SIZE: 20,
    AGE_ALLOWED_TO_DRIVER: 14,
    BACK_TO_TOP_BUTTON_VISIBLE_PIXELS: 200,
    DOT_CMS_LANG_ID_EN: '_en',
    DOT_CMS_LANG_ID_FR: '_fr',
    DOT_CMS_SECTION_CONTACTUS: 'dotcmscontactus',
    DOT_CMS_SECTION_RECENTUPDATE: 'dotcmsgetmostrecentupdate',
    DISPLAY_NAME_FRENCH: 'Français',
    DISPLAY_NAME_ENGLISH: 'English',
    LOCALE_KEY_FRENCH: 'fr-CA',
    LOCALE_KEY_ENGLISH: 'en-CA',
    DESTINATION_POLICIES: 'policies',
    DESTINATION_QUOTES: 'quotes',
    DESTINATION_RENEWAL: 'renewal',
    DESTINATION_CANCELLATION: 'cancellation',
    DESTINATION_CHANGE: 'change',
    DEFAULT_PRODUCER_CODE: 'all',
    LOGO_PATH: "/branding/BluePass.svg",
    LOGO_WHITE_PATH: "/branding/BluePass_white.svg"
};

export default constants;
