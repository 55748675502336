/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { CONSTANTS } from 'wmic-portals-utils-js';
import _ from 'lodash';
import WMICCoverageTerm from '../WMICCoverageTerm/WMICCoverageTerm.jsx';
import messages from './WMICCoverageDetail.messages';
import styles from './WMICCoverageDetail.module.scss';

const WMICCoverageDetail = (props) => {
    const { coverage, model, getOriginalCoverage, coverageTermChanges, coverageTermUpdated, coverageTermErrorMessages } = props;
    const translator = useTranslator();
    const RCF_DATES = {
        RCF_CA: '2021-10-01',
        RCF_DEFAULT: '2021-08-01'
    };

    const getCoverageDisplayName = (cov) => {
        switch (cov?.publicID?.value.toLowerCase()) {
            case CONSTANTS.COVERAGE_NAMES.PA_COLLISION.toLowerCase(): {
                return translator(messages.collisionCoverage);
            }
            case CONSTANTS.COVERAGE_NAMES.PA_COMPREHENSIVE.toLowerCase(): {
                return translator(messages.comprehensiveCoverage);
            }
            default: {
                return cov?.name?.value;
            }
        }
    };

    const setTipTextByEffectiveDate = () => {
        const rfcDate = model.baseData.policyAddress.value.state === CONSTANTS.JURISDICTIONS.CA ? new Date(RCF_DATES.RCF_CA) : new Date(RCF_DATES.RCF_DEFAULT);
        const convertedJson = JSON.parse(JSON.stringify(model.baseData.periodStartDate.value));
        const formatEffDate = new Date(`${convertedJson.year}-${convertedJson.month + 1}-${convertedJson.day} `);
        return formatEffDate.getTime() >= rfcDate.getTime() ? translator(messages.rentalExpenseCoverageHelpsPayFor2) : translator(messages.rentalExpenseCoverageHelpsPayFor);
    };

    const getCoverageDefinition = (cov) => {
        switch (cov?.publicID?.value.toLowerCase()) {
            case CONSTANTS.COVERAGE_NAMES.PA_COLLISION.toLowerCase(): {
                return translator(messages.collisionCoverageDesc);
            }
            case CONSTANTS.COVERAGE_NAMES.PA_COMPREHENSIVE.toLowerCase(): {
                return translator(messages.comprehensiveCoverageDesc);
            }
            case CONSTANTS.COVERAGE_NAMES.PA_RENTAL.toLowerCase(): {
                return setTipTextByEffectiveDate();
            }
            default: {
                return '';
            }
        }
    };

    const getOriginalTerm = (cov, term) => {
        const originalCoverage = getOriginalCoverage(cov);
        if (originalCoverage) {
            return _.find(originalCoverage.terms.value, (originalTerm) => {
                return originalTerm.publicID.value === term.publicID.value;
            });
        }
        return undefined;
    };

    const getCoverageTerm = (cov) => {
        const terms = cov?.terms.value;
        const content = terms?.map((term) => {
            return (
                <div>
                    <WMICCoverageTerm
                        coverage={cov}
                        term={term}
                        originalTerm={getOriginalTerm(cov, term)}
                        getOriginalCoverage={getOriginalCoverage}
                        coverageTermChanges={coverageTermChanges}
                        coverageTermUpdated={coverageTermUpdated}
                        coverageTermErrorMessages={coverageTermErrorMessages}
                    />
                </div>
            );
        });

        return (
            <Fragment>
                { content }
            </Fragment>
        );
    };

    return (
        <div className="ww-detail-row ww-detailed-row-boxed ww-row-flow ww-edit-coverage-select">
            <div className="ww-coverage-select">
                <p>
                    <strong>{getCoverageDisplayName(coverage)}</strong>
                </p>
                {getCoverageTerm(coverage)}
            </div>
            <div className="ww-coverage-definition">
                <p className={styles['wmic-cov-detail']}>{getCoverageDefinition(coverage)}</p>
            </div>
        </div>
    );
};

WMICCoverageDetail.propTypes = {
    coverage: PropTypes.shape({}).isRequired,
    model: PropTypes.shape({}).isRequired,
    getOriginalCoverage: PropTypes.func.isRequired,
    coverageTermChanges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    coverageTermUpdated: PropTypes.func.isRequired,
    coverageTermErrorMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default WMICCoverageDetail;