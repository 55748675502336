import { defineMessages } from 'react-intl';

export default defineMessages({
    trademarkInfo: {
        id: 'portal.views.enrollment-footer.enrollmentTrademarkInfo',
        defaultMessage: '"Wawanesa Insurance" is a trademark of Wawanesa General Insurance Company'
    },
    enrollmentCopyright: {
        id: 'portal.views.enrollment-footer.enrollmentCopyright',
        defaultMessage: 'Copyright 1996 - {currentYear}'
    },
    termsOfUse: {
        id: 'portal.views.enrollment-footer.termsOfUse',
        defaultMessage: 'Terms of Use'
    },
    termsOfUseTrackButtonIdentifier: {
        id: 'portal.views.enrollment-footer.tracking.termsOfUse',
        defaultMessage: 'Footer - Terms of Use'
    },
    privacyPolicy: {
        id: 'portal.views.enrollment-footer.privacyPolicy',
        defaultMessage: 'Privacy Policy'
    },
    privacyPolicyTrackButtonIdentifier: {
        id: 'portal.views.enrollment-footer.tracking.privacyPolicy',
        defaultMessage: 'Footer - Privacy Policy'
    }
})