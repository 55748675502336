
import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import PropTypes from 'prop-types';
import { CONSTANTS, Position } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import { WMICAdlIntGoldSearch } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import WMICAdditionalInterestUtil from "wmic-pe-portals-utils-js/WMICAdditionalInterestUtil";
import { CheckboxField } from '@jutro/legacy/components';
import messages from './WMICHOAdditionalInterests.messages';
import metadata from './WMICHOAdditionalInterestDetailComponent.metadata.json5';
import styles from './WMICHOAdditionalInterests.module.scss';


function WMICHOAdditionalInterestDetailComponent(props) {
    const {
        id,
        value: additionalInterestVM,
        updateModel,
        showErrors,
        onValidate: parentOnValidate,
        isEditing: isEditMode,
        category,
        jobVM,
        riskView
    } = props;

    const [addressEqualsPNI, setAddressEqualsPNI] = useState(false);
    const [isAddNewCompanyOpen, setAddNewCompanyOpen] = useState(false);
    const [isAICompanyVisible, setAICompanyVisible] = useState(false);
    const [isGoldSearchVisible, setGoldSearchVisible] = useState(false);
    const [isAddNewCompanyButtonVisible, setAddNewCompanyButtonVisible] = useState(false);
    const [isEditable, setIsEditable] = useState(isEditMode);
    const viewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, onValidate } = useValidation(id);

    useEffect(() => {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid && !isGoldSearchVisible, id);
        }
    }, [id, isComponentValid, isGoldSearchVisible, parentOnValidate]);

    const aiContactSubtype = _.get(additionalInterestVM, 'policyAdditionalInterest.subtype.value');

    const filteredAdditionalInterestTypes = useMemo(() => {
        const availableAddInterestTypes = _.get(additionalInterestVM, 'additionalInterestType.aspects.availableValues');
        return WMICAdditionalInterestUtil.getFilteredAdditionalInterestTypes(availableAddInterestTypes, riskView);
    }, [additionalInterestVM, riskView]);

    const descriptionOfOptionVisible = _.get(additionalInterestVM, 'descriptionOfInterestOption.aspects.ocular');
    const descriptionOfInterestLabel = useMemo(() => descriptionOfOptionVisible ?
        messages.descriptionOfInterestOther
        : messages.descriptionOfInterestOtherType,
    [descriptionOfOptionVisible]);

    const additionalInterestType = _.get(additionalInterestVM, 'additionalInterestType.value.code');

    useEffect(() => {
        if (aiContactSubtype === CONSTANTS.Company && isEditMode &&
            _.includes(
                [CONSTANTS.ADDITIONAL_INTEREST_TYPES.MORTGAGEE, CONSTANTS.ADDITIONAL_INTEREST_TYPES.LOSS_PAYEE],
                additionalInterestType
            )) {
            setGoldSearchVisible(true);
            setAICompanyVisible(false);
        }
        else {
            if (!isEditable && isEditMode) {
                // If we switched from read-only (selected a gold contact) to editable (enter new contact), reset contact details
                _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress', {});
                _.set(additionalInterestVM, 'policyAdditionalInterest.contactName', undefined);
                _.set(additionalInterestVM, 'policyAdditionalInterest.displayName', undefined);
                updateModel(additionalInterestVM);
            }

            setGoldSearchVisible(false);
            setIsEditable(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalInterestType, aiContactSubtype, isEditMode])

    const handleAddNewCompanyClick = useCallback((value) => {
        setAICompanyVisible(true);
        setAddNewCompanyOpen(true);
        setGoldSearchVisible(false);
        setIsEditable(true);
    }, []);

    const clearContactInfo = () => {
        WMICAdditionalInterestUtil.clearAdditionalInterestContactInfo(additionalInterestVM);
        updateModel(additionalInterestVM);
    }

    const handleCancelAddCompanyClick = useCallback(() => {
        setAICompanyVisible(false);
        setAddNewCompanyOpen(false);
        setGoldSearchVisible(true);
        setIsEditable(true);
        clearContactInfo();
    }, []);

    const handleGoldContactSelected = (goldContactInfo) => {
        goldContactInfo.address.displayName = goldContactInfo.displayAddress;

        const newAdditionalInterest = _.clone(additionalInterestVM.value);

        _.set(newAdditionalInterest, 'policyAdditionalInterest.primaryAddress', goldContactInfo.address);
        _.set(newAdditionalInterest, 'policyAdditionalInterest.contactName', goldContactInfo.companyName);
        _.set(newAdditionalInterest, 'policyAdditionalInterest.displayName', goldContactInfo.companyName);
        _.set(newAdditionalInterest, 'policyAdditionalInterest.addressBookUID_WMIC', goldContactInfo.contactAddressBookUID)
        additionalInterestVM.value = newAdditionalInterest;
        updateModel(additionalInterestType);

        setAICompanyVisible(true);
        setAddNewCompanyOpen(true);
        setGoldSearchVisible(false);
        setIsEditable(false);
    }

    const handleGoldListSearched = () => {
        setAddNewCompanyButtonVisible(true);
    }

    const setAIPrimaryAddress = (addressVM) => {
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress', addressVM.value);
    };

    const primaryAddress = _.get(jobVM, 'baseData.policyAddress.value');
    const handleAddressEqualsChange = useCallback((value) => {
        const newAdditionalInterestVM = viewModelService.clone(additionalInterestVM);

        _.set(newAdditionalInterestVM, 'policyAdditionalInterest.primaryAddress', value
            ? primaryAddress
            : {
                country: CONSTANTS.COUNTRY.CA,
                addressType: CONSTANTS.MAILING_ADDRESS
            });
        setAddressEqualsPNI(value);
        setAIPrimaryAddress(_.get(newAdditionalInterestVM, 'policyAdditionalInterest.primaryAddress'));
        updateModel(newAdditionalInterestVM);
    }, [additionalInterestVM, primaryAddress, setAIPrimaryAddress, updateModel, viewModelService]);

    const displayReadOnlyAI = !isEditMode || !isEditable;

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: additionalInterestVM
        },
        additionalInterestType: {
            availableValues: filteredAdditionalInterestTypes,
            readOnly: !isEditMode
        },
        descriptionOfInterest: {
            readOnly: !isEditMode
        },
        descriptionOfInterestOption: {
            readOnly: !isEditMode
        },
        descriptionOfInterestOther: {
            label: descriptionOfInterestLabel,
            readOnly: !isEditMode
        },
        descriptionOfInterestMajestyOption: {
            readOnly: !isEditMode
        },
        descriptionOfInterestMajestyOther: {
            readOnly: !isEditMode
        },
        firstName: {
            readOnly: !isEditMode
        },
        lastName: {
            readOnly: !isEditMode
        },
        effectiveDate: {
            readOnly: !isEditMode
        },
        expiryDate: {
            readOnly: !isEditMode
        },
        goldSearchContainer: {
            visible: isGoldSearchVisible
        },
        goldSearch: {
            additionalInterestVM,
            onGoldContactSelected: handleGoldContactSelected,
            onGoldListSearched: handleGoldListSearched
        },
        newAIPersonContainer: {
            visible: (aiContactSubtype === CONSTANTS.Person && !!additionalInterestVM.additionalInterestType.value)
        },
        newAICompanyContainer: {
            visible: ((aiContactSubtype === CONSTANTS.Company && !isGoldSearchVisible) || (displayReadOnlyAI && isAICompanyVisible)) && !!additionalInterestVM.additionalInterestType.value
        },
        addNewCompanyButton: {
            visible: isGoldSearchVisible && isAddNewCompanyButtonVisible
        },
        cancelAddCompanyButton: {
            visible: !isGoldSearchVisible && isAddNewCompanyOpen && isEditMode
        },
        personANIAddressEqualsPNIAddress: {
            value: addressEqualsPNI,
            readOnly: !isEditMode
        },
        personAddressLookup: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            onValidate : parentOnValidate,
            hideButtons : true,
            hideBorder : true,
            readOnly: addressEqualsPNI || displayReadOnlyAI,
            isEditing: !(addressEqualsPNI || displayReadOnlyAI),
            showErrors
        },
        companyAddressLookup: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            onValidate : parentOnValidate,
            hideButtons : true,
            hideBorder : true,
            readOnly: addressEqualsPNI || displayReadOnlyAI,
            isEditing: !(addressEqualsPNI || displayReadOnlyAI),
            showErrors
        },
        companyName: {
            readOnly: displayReadOnlyAI
        },

    };

    const resolvers = {
        resolveComponentMap: {
            WMICAdlIntGoldSearch,
            WMICAddressDetails,
            CheckboxField
        },
        resolveCallbackMap: {
            onAddressEqualsChange: handleAddressEqualsChange,
            onAddNewCompanyClick: handleAddNewCompanyClick,
            onCancelAddCompanyClick: handleCancelAddCompanyClick,
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={additionalInterestVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            onModelChange={updateModel}
            showErrors={showErrors}
            onValidationChange={onValidate}
        />
    );
}

WMICHOAdditionalInterestDetailComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    updateModel: PropTypes.func,
    showErrors: PropTypes.bool,
    onValidate: PropTypes.func,
    isEditing: PropTypes.bool,
    category: PropTypes.string,
    jobVM: PropTypes.shape({}).isRequired
};

WMICHOAdditionalInterestDetailComponent.defaultProps = {
    updateModel: undefined,
    showErrors: false,
    onValidate: undefined,
    isEditing: false,
    category: undefined
};

export default WMICHOAdditionalInterestDetailComponent;
