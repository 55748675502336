/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import { ModalFooter } from '@jutro/components';
import { publish } from "@jutro/events";
import { WMICButton } from 'wmic-components-platform-react';
import { useTranslator } from '@jutro/locale';
import messages from './WMICAddPolicyModal.messages';
import styles from './WMICAddPolicyModal.module.scss';

const WMICAddPolicyGenericFailureModal = (props) => {
    const translator = useTranslator();

    const {
        onReject,
        message,
        onAddMorePolicies
    } = props;

    publish('ENROLLMENT_TRACKING', {name: 'EnrollmentEvent', action: 'linkAPolicy', label: 'fail'});

    return (
        <div className={styles.modal}>
            <h1 className='wmic-modal-header-title'>{translator(messages.addPoliciesHeader)}</h1>
            <div className={styles.wmicAddPolicyModal}>
                <p className="ww-error">{message}</p>
            </div>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject(); }}
                    type="outlined"
                >
                    {translator(messages.cancel)}
                </WMICButton>
                <WMICButton
                    onClick={() => { onAddMorePolicies(); }}
                    type="primary"
                >
                    {translator(messages.addMorePolicies)}
                </WMICButton>
            </ModalFooter>
        </div>
    );
};

WMICAddPolicyGenericFailureModal.propTypes = {
    onReject: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    onAddMorePolicies: PropTypes.func.isRequired
};


export default WMICAddPolicyGenericFailureModal;