/* eslint-disable max-len */
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { CONSTANTS, LobIconUtil, LOBConstants } from 'wmic-portals-utils-js';
import { WMICChangeDesignatedPersonAddContactComponent, WMICChangeDesignatedPersonActionComponent } from 'gw-capability-policychange-common-react';

import metadata from './WMICChangeDesignatedPersonComponent.metadata.json5';
import messages from './WMICChangeDesignatedPersonComponent.messages';
import styles from './WMICChangeDesignatedPersonComponent.module.scss';

function ChangeDesignatedPersonComponent(props) {
    const { policyVM, match, loadingState, setLoadingState } = props;
    const translator = useTranslator();
    const [activeStep, setActiveStep] = useState(CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('addContactHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const getTitleStyle = useCallback(() => {
        return isMobile ? 'wmic-title-mobile' : 'wmic-title';
    },[isMobile]);

    const getChangeContactTitle = () => {
        const title = translator(messages.changeDesignatedPersonTitle);
        const policyType = _.get(match, 'params.policyType');
        const icon = policyType === 'home' ? LobIconUtil.getWmicIcon('Homeowners') : LobIconUtil.getWmicIcon(LOBConstants.PA);

        return (
            <Fragment>
                <i className={`fa ${icon} ${styles.titleIconBlue}`} />
                <h2 className={styles[getTitleStyle()]}>{title}</h2>
            </Fragment>
        );
    };

    const getDesignatedPerson = () => {
        return policyVM.currentPeriod.contacts.find((contact) => contact.contactRole === 'Designated Additional Person');
    };

    const overrideProps = {
        changeDesignatedPersonTitle: {
            content: getChangeContactTitle()
        },
        addContactSection: {
            visible: !loadingState
        },
        WMICChangeDesignatedPersonAddContactComponent: {
            visible: activeStep === CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON_ADD,
            policyVM: policyVM,
            match: match,
            setActiveStep: setActiveStep,
            designatedPersonData: getDesignatedPerson(),
            handleChangeLoading: setLoadingState,
            isLoading: loadingState
        },

        WMICChangeDesignatedPersonActionComponent: {
            visible: activeStep === CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON,
            policyVM: policyVM,
            match: match,
            setActiveStep: setActiveStep,
            designatedPersonData: getDesignatedPerson(),
            handleChangeLoading: setLoadingState,
            isLoading: loadingState
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICChangeDesignatedPersonAddContactComponent: WMICChangeDesignatedPersonAddContactComponent,
            WMICChangeDesignatedPersonActionComponent: WMICChangeDesignatedPersonActionComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ChangeDesignatedPersonComponent.propTypes = {
    policyVM: PropTypes.shape({
        currentPeriod: PropTypes.shape({
            contacts: PropTypes.array
        })
    }).isRequired,
    match: PropTypes.shape({}).isRequired
};

export default ChangeDesignatedPersonComponent;