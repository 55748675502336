import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';

export default class DocumentService {
    /**
     * Retrieves a document link.
     *
     * @param {String} documentID the documents id to retrieve
     * @param {String} token the security token
     */

    static downloadPolicyDocument(documentID, token) {
        return DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('policyDocument'), documentID, token);
    }
}
