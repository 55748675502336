import { defineMessages } from 'react-intl';

export default defineMessages({
    insuranceIDCard: {
        id: 'portal.views.ios-steps-modal.Insurance ID Card',
        defaultMessage: 'Insurance ID Card'
    },
    followTheseSteps: {
        id: 'portal.views.ios-steps-modal.Follow these steps to download the ID card to your wallet.',
        defaultMessage: 'Follow these steps to download the ID card to your wallet.'
    },
    onYourDevice: {
        id: 'portal.views.ios-steps-modal.On your iOS device use the button below to download your digital ID card.',
        defaultMessage: 'On your iOS device use the button below to download your digital ID Card.'
    },
    idCardShouldAutomatically: {
        id: 'portal.views.ios-steps-modal.The ID card should automatically download',
        defaultMessage: 'The ID card should automatically download and open in your Apple Pay wallet. If the ID card isn\'t automatically added to your wallet, locate the downloaded file in your notification center.'
    },
    youShouldSeeAPreview: {
        id: 'portal.views.ios-steps-modal.You should see a preview of the card in your wallet.',
        defaultMessage: 'You should see a preview of the card in your wallet. Next press "Add" in the top right of your device.'
    },
    theCardIsNowAdded: {
        id: 'portal.views.ios-steps-modal.The card is now added to your Apple Pay wallet.',
        defaultMessage: 'The card is now added to your Apple Pay wallet. It will be available in your wallet with or without internet access. If you make changes to your policy or your policy renews you will need to re-download the ID Card file to your wallet.'
    },
    selectTheVehicle: {
        id: 'portal.views.ios-steps-modal.Select the vehicle you want an ID card for.',
        defaultMessage: 'Select the vehicle you want an ID card for.'
    },
    close: {
        id: 'portal.views.ios-steps-modal.Close',
        defaultMessage: 'Close'
    },
    downloadIOSIdCardTrackButtonIdentifier: {
        id: 'portal.views.ios-steps-modal.tracking.Download iOS ID Card',
        defaultMessage: 'Download iOS ID Card'
    },
});