import React, {
    useContext, Fragment, useState, useEffect, useCallback
} from 'react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICButton } from 'wmic-components-platform-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import { WMICManageBankAccountModal } from 'wmic-components-amp-common-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';

import WMICOneIncPaymentModal from '../../WMICOneIncPaymentModal/WMICOneIncPaymentModal';
import messages from './WMICPaymentInstrumentsList.messages';
import styles from '../WMICBankAccountsList/WMICBankAccountsList.module.scss';

const WMICPaymentInstrumentsList = (props) => {
    const modalApi = useModal();
    const { 
        onReject,
        onResolve,
        accountInfo,
        ldFlags
    } = props;
    const [makingOneIncPayment, updateMakingOneIncPayment] = useState(false);
    const [oneIncPaymentDetails, updateOneIncPaymentDetails] = useState({});
    const [managePaymentMethodsEnabled, updateManagePaymentMethodsEnabled] = useState(false);
    const [paymentMethodsLoading, updatePaymentMethodsLoading] = useState(false);
    const [modalLoaded, updateModalLoaded] = useState(false);
    const [paymentMethods, updatePaymentMethods] = useState([]);
    const [isLoadingOneIncPayModal, updateIsLoadingOneIncPayModal] = useState(false);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const getPaymentInstrumentDisplay = (paymentInstrument, bankAccountTypelist, creditCardTypelist) => {
        let bankAccountType;
        let creditCardType;

        switch (paymentInstrument.paymentMethodType.toLowerCase()) {
            case 'eft':
                bankAccountType = bankAccountTypelist.getCode(paymentInstrument.bankAccountType);

                return `${bankAccountType.code}  ****${paymentInstrument.lastFour}`;
            case 'creditcard':
                creditCardType = creditCardTypelist.getCode(paymentInstrument.creditCardType);

                return `${creditCardType.code}  ****${paymentInstrument.lastFour}`;
            default:
                return paymentInstrument.description;
        }
    }

    const getPaymentMethods = useCallback((accountNumber, accountPublicID) => {
        updatePaymentMethodsLoading(true);
        AccountBillingDetailsService.getPaymentInstrumentTokenSummary(
            accountNumber,
            accountPublicID,
            null,
            authHeader
        ).then((oneIncPaymentInstruments) => {
            if(oneIncPaymentInstruments && oneIncPaymentInstruments.paymentInstrumentTokens) {
                const bankAccountTypelist = viewModelService.productMetadata.get('bc').types.getTypelist('BankAccountType');
                const creditCardTypelist =  viewModelService.productMetadata.get('bc').types.getTypelist('CreditCardType');
                const records = [];

                _.each(oneIncPaymentInstruments.paymentInstrumentTokens, (paymentInstrumentToken) => {
                    if (paymentInstrumentToken.token !== undefined && paymentInstrumentToken.token !== '') {
                        const description = getPaymentInstrumentDisplay(paymentInstrumentToken, bankAccountTypelist, creditCardTypelist);
                        const bankAccountTypeName = paymentInstrumentToken.bankAccountType ? bankAccountTypelist.getCode(paymentInstrumentToken.bankAccountType) : undefined;
                        const creditCardTypeName = paymentInstrumentToken.creditCardType ? creditCardTypelist.getCode(paymentInstrumentToken.creditCardType) : undefined;
                        const bankRecord = {
                            publicId: paymentInstrumentToken.publicID,
                            token: paymentInstrumentToken.token,
                            description: description,
                            forMarPolicy: paymentInstrumentToken.forMarPolicy,
                            paymentMethodTypeName: undefined,
                            lastFour: paymentInstrumentToken.lastFour,
                            bankName: paymentInstrumentToken.bankName,
                            // isPrimary: paymentInstrumentToken.isPrimary
                        };

                        if (bankAccountTypeName) {
                            bankRecord.paymentMethodTypeName = bankAccountTypeName.code;
                        } else if (creditCardTypeName) {
                            bankRecord.paymentMethodTypeName = creditCardTypeName.code;
                        }

                        records.push(bankRecord);
                    }
                });
                updatePaymentMethods(records);
            }
        }).finally(() => {
            updateModalLoaded(true);
            updatePaymentMethodsLoading(false)
        });
        
    }, [authHeader, viewModelService, updatePaymentMethods]);

    useEffect(() => {
        updateOneIncPaymentDetails({
            accountNumber: accountInfo.accountNumber,
            contactPublicId: accountInfo.accountContact.publicID
        });

        getPaymentMethods(accountInfo.accountNumber, accountInfo.accountContact.publicID);

    }, [accountInfo, getPaymentMethods, modalLoaded]);

    const paymentModalClosed = () => {
        updateMakingOneIncPayment(false);
        updateManagePaymentMethodsEnabled(false);
        // window.location.reload(); // Check addPolicies
        onReject();
    };

    const oneIncSaveComplete = useCallback((details) => {
        const getPaymentMethodType = () => {
            if(details.cardType)
                return details.cardType;

            if(details.accountType)
                return details.accountType;

            return null;
        };

        const paymentInfo = {
            publicId: null,
            token: details.tokenId,
            paymentMethodTypeName: getPaymentMethodType(),
            lastFour: details.lastFourDigits,
            forMarPolicy: false
        };
        
        updatePaymentMethods((current) => [...current, paymentInfo]);
    }, [paymentMethods, updatePaymentMethods]);

    const paymentModalLoaded = useCallback(() => {
        updateIsLoadingOneIncPayModal(false);
    }, [updateIsLoadingOneIncPayModal]);

    const managePaymentMethods = useCallback(() => {
        updateIsLoadingOneIncPayModal(true);
        updateManagePaymentMethodsEnabled(true);
    }, []);

    const deletePaymentInstrument = useCallback((bankInfo) => {
        modalApi.showModal(
            <WMICManageBankAccountModal
                accountSummary={{
                    accountNumber: accountInfo.accountNumber,
                    ...accountInfo.accountContact
                }}
                bankAccountInfo={bankInfo}
                mode='deleteBankAccount'
                refreshAfterUpdate={() => {getPaymentMethods(accountInfo.accountNumber, accountInfo.accountContact.publicID)}}
                ldFlags={ldFlags}
            />
        );
    }, [accountInfo.accountContact, accountInfo.accountNumber, getPaymentMethods]);

    return (
        <Fragment>
            <WMICOneIncPaymentModal
                paymentModalClosed={paymentModalClosed}
                oneIncPaymentDetails={oneIncPaymentDetails}
                payNowEnabled={false}
                savePaymentEnabled={false}
                saveComplete={oneIncSaveComplete}
                paymentModalLoaded={paymentModalLoaded}
                managePaymentMethodsEnabled={managePaymentMethodsEnabled}
                updateModalLoaded={updateModalLoaded}
            />

            <div>
                {paymentMethodsLoading && (
                    <WMICLoader />
                )}
                {!paymentMethodsLoading && paymentMethods.length < 1 && (
                    <p>
                        <strong>{translator(messages.noPaymentMethods)}</strong>
                    </p>
                )}
                {!paymentMethodsLoading && paymentMethods.length > 0 && (
                    <div>
                        {
                            paymentMethods.map((paymentInfo) => {
                                return (
                                    <div className={styles['ww-detail-payment-row']}>
                                        {paymentInfo.paymentMethodTypeName && paymentInfo.paymentMethodTypeName !== '' && (
                                            <div>
                                                <span>{paymentInfo.bankName} {paymentInfo.paymentMethodTypeName}&nbsp;&nbsp; ****{paymentInfo.lastFour}</span>
                                            </div>)}
                                        {paymentInfo.isPrimary && (
                                            <div>
                                                <span>{translator(messages.yourPrimaryAcc)}</span>
                                                <WMICClickableIconTooltipComponent
                                                    className={styles['modal-tooltip']}
                                                    id='primary-account-tooltip'
                                                    tooltipId='primary-account-tooltip-modal'
                                                    tooltipMessage={translator(messages.primarAccWillBeUsed)}
                                                />
                                            </div>)}
                                        {paymentInfo.forMarPolicy && (
                                            <div>
                                                <p className="ww-subscript ww-no-footer">
                                                    <span  className={styles['used-for-mar']}>
                                                        {translator(messages.usedForMonthlyAutomatedRecurringPayments)}
                                                    </span>
                                                    <br />
                                                    <span  className={styles['used-for-mar']}>
                                                        {translator(messages.pleaseNote)}
                                                    </span>
                                                    <span  className={styles['used-for-mar-increase-font-size']}>
                                                        {WMICRichTextUtil.translateRichText(translator(messages.ifYouNeedToEditOrDeleteIt))}
                                                    </span>
                                                </p>
                                            </div>)}
                                        {!paymentInfo.forMarPolicy && (
                                            <div>
                                                <WMICButton
                                                    type="secondary"
                                                    size="small"
                                                    className={styles['edit-payment-instrument-button']}
                                                    onClick={managePaymentMethods}
                                                >
                                                    {translator(messages.edit)}
                                                </WMICButton>
                                                <WMICButton
                                                    type="secondary"
                                                    size="small"
                                                    className={styles['edit-payment-instrument-button']}
                                                    onClick={() => deletePaymentInstrument(paymentInfo)}
                                                >
                                                    {translator(messages.delete)}
                                                </WMICButton>
                                            </div>)
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
            </div>

            <div className={styles['ww-detail-payment-row']}>
                <WMICButton
                    onClick={managePaymentMethods}
                    type="secondary"
                    size="small"
                >
                    {translator(messages.addNewPaymentMethods)}
                </WMICButton>
            </div>
        </Fragment>
    );
};

WMICPaymentInstrumentsList.propTypes = {
    accountInfo: PropTypes.shape({
        accountNumber: PropTypes.string,
        accountContact: PropTypes.shape({
            publicID: PropTypes.string
        })
    }).isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    ldFlags: PropTypes.shape({}).isRequired
};
    
export default WMICPaymentInstrumentsList;