import React, { useCallback, useEffect, useState, useContext } from 'react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import {FlowStepId, PAConstants} from 'wmic-pe-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    WMICDriverEndorsementsComponent,
    WMICPAVehicleCoverages,
    WMICPAPolicyCoverages
} from 'wmic-pe-capability-gateway-common-pa-react';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";

import PropTypes from 'prop-types';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js'
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import _ from 'lodash';
import appConfig from 'app-config';
import { useAccordionValidation, useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';

import styles from './WMICPACoveragesPage.module.scss';
import messages from './WMICPACoveragesPage.messages';
import metadata from './WMICPACoveragesPage.metadata.json5';

const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];


const structureCustomQuote = (submissionVM, affectedQuote, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
};


const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.personalAuto.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];
            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ quote }) => !_.isUndefined(quote.data));
    return _.sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

function WMICPACoveragesPage(props) {

    const { wizardSnapshot, wizardData: submissionVM, updateWizardData, underwritingIssues } = props;
    const translator = useContext(TranslatorContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [availableCoverages, setAvailableCoverages] = useState();
    const [showErrors, setShowErrors] = useState(false);

    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { setWizardLoading } = useWizardModals();
    const { authHeader } = useAuthentication();
    const { onValidate, disregardFieldValidation, initialValidation, isComponentValid } = useValidation("paCoveragesPage");
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);
    const [accordionColumnData, setAccordionColumnData] = useState({});

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.PA_COVERAGES;
    }, [submissionVM.value]);

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);
            return CustomQuoteService.updateCustomQuoteCoveragesAndQuoteData(customQuote, submissionVM.value, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;

                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.lineCoverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.vehicleCoverages`
                    );
                    const removedFieldsFromDriverEndorsementCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.driverEndorsements_WMIC`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages,
                        ...removedFieldsFromDriverEndorsementCoverages
                    ];
                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        [
            submissionVM,
            CustomQuoteService,
            authHeader,
            viewModelService,
            disregardFieldValidation,
            updateWizardData
        ]
    );

    const getCoverages = (submission) => {
        // putting ID into an object as the Jutro table component expects an object
        const structureClauseTData = (coverage) => ({
            coverageUniqueID: coverage.publicID,
            coverageCategoryCode: coverage.category_WMIC,
            coverageCategoryDisplayName: coverage.category_DisplayName_WMIC
        });

        const offerings = _.get(submission, PAConstants.PACoveragesPaths.OFFERINGS_VALUE);
        const lineCoverages = _.uniqBy(
            offerings.flatMap((offering) => offering.coverages
                .lineCoverages.map(structureClauseTData)),
            PAConstants.PACoveragesPaths.COV_ID
        );

        const allVehicleCoverages = offerings.flatMap((offering) => offering.coverages.vehicleCoverages);

        const vehicleCoverages = allVehicleCoverages.map((vehicleCoverageDTO) => {
            return {
                ...vehicleCoverageDTO,
                coverages: _.uniqBy(vehicleCoverageDTO.coverages.map(structureClauseTData),PAConstants.PACoveragesPaths.COV_ID)
            }
        })
        return {
            lineCoverages,
            vehicleCoverages
        };
    };

    const columnData = generateColumnData(submissionVM);

    useEffect(() => {
        if(columnData.length === 0){
            const updatedColumnData = generateColumnData(submissionVM);
            setAccordionColumnData(updatedColumnData);
        }
        if (submissionVM) {
            setAvailableCoverages(getCoverages(submissionVM));
        }
    }, []);

    const syncCoverages = useCallback(
        (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, PAConstants.PACoveragesPaths.OFFERINGS_PATH, PAConstants.PACoveragesPaths.OFFERINGS_PATH);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);
            return syncCoverages(value, changedPath, PAConstants.PACoveragesPaths.OFFERINGS_PATH, PAConstants.PACoveragesPaths.OFFERINGS_PATH);
        },
        [changeSubmission, syncCoverages]
    );

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.forceUpdateCustomQuoteCoveragesAndQuoteData(customQuote, initialSubmissionVM.value, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initialSubmissionVM, submissionVM, CustomQuoteService, authHeader, viewModelService]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.updateCustomQuoteAndQuoteData(customQuote, authHeader).then(
                (response) => {
                    setStaleQuoteBranchCode(undefined);
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;
                    updateWizardData(newSubmissionVM);
                    return response;
                }
            );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM, CustomQuoteService, authHeader]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const buyNow = useCallback(
        async (lobPath, quotePath) => {
            const lobOffering = _.get(submissionVM, `${lobPath}.value`);
            const quoteOffering = _.get(submissionVM, `${quotePath}.value`);
            const quoteID = _.get(submissionVM, 'quoteID.value');
            const sessionUUID = _.get(submissionVM, 'sessionUUID.value');

            await CustomQuoteService.setSelectedVersionOnSubmission(
                quoteID,
                lobOffering.branchName,
                sessionUUID,
                authHeader
            );

            // set chosen quote
            _.set(submissionVM, 'bindData.chosenQuote.value', quoteOffering.publicID);
            submissionVM.value = MockUpUtil.cleanUpMockedProperties(
                submissionVM.value,
                PAConstants.PACoveragesPaths.PATH_TO_MOCK_DATA,
                ...MOCK_DATA_TO_REMOVE
            );
            updateWizardData(submissionVM);
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM, CustomQuoteService, authHeader]
    );

    // props for the accordions themselves
    const commonAccordionProps = {
    }

    // props for the accordion content components
    const commonAccordionContentProps = {
        baseData: _.get(submissionVM, 'baseData'),
        authHeader,
        submissionVM,
        updateWizardData,
        onClauseChange,
        syncCoverages,
        changeSubmissionAndSync,
        onValidate: onValidateAccordion,
    };

    const getThirdPartyLiabilityWarning = () => {
        return [{
            id: "warningThirdPartyLiabilityContainer",
            type: "element",
            component: "div",
            content: [
                {
                    id: "exclamationcircleIcon",
                    type: "element",
                    component: "Icon",
                    componentProps: {
                        icon: "mi-error",
                        className: "exclamation-icon gw-mr-1 iconAlignment"
                    },
                },
                {
                    id: "warningThirdPartyLiabilityMessage",
                    type: "element",
                    component: "span",
                    content: {
                        id: "quoteandbind.pa.views.pa-coverage-term.Third Party Liability Applies to All vehicles",
                        defaultMessage: translator(messages.thirdPartyLiabilityWarning)
                    }
                }
            ]
        }];
    }

    const getCustomTerms = (clause) => {
        if (PAConstants.thirdPartyLiabililityLimitNames.includes(clause.publicID)) {
            return getThirdPartyLiabilityWarning();
        }
        return null;
    }

    const onStaleQuoteBranchCode = useCallback(() => {
        return staleQuoteBranchCode;
    }, [staleQuoteBranchCode]);

    const commonOverrides = {
        'coverageColumnData': columnData,
        'quoteID': submissionVM.quoteID.value,
        'changeSubmissionAndSync': changeSubmissionAndSync,
        'changeSubmission': changeSubmission,
        'onClauseChange': onClauseChange,
        'syncCoverages': syncCoverages,
        'recalculate': recalculate,
        'resetQuote': resetQuote,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'onValidate': onValidate,
        'getCustomTerms': getCustomTerms,
        'showErrors': showErrors
    }

    const getOrderedVehiclesList = () => {
        const vehicles = _.get(submissionVM, `${PAConstants.PACoveragesPaths.VEHICLES_PATH}.children`, [])
        return _.sortBy(vehicles, ['vehicleNumber_WMIC.value'])
    }

    const _iterableProps = () => {
        const driverEndorsements = _.get(submissionVM, `${PAConstants.PACoveragesPaths.OFFERINGS_PATH}.coverages.driverEndorsements_WMIC.value`);
        const driverEndorsementsForVehicle = driverEndorsements.filter((endorsement) => WMICPACoverageUtil.getIsVehicleDriverEndorsement(endorsement.publicID));
        const vehicleOverrides = getOrderedVehiclesList().map((vehicle, index) => {
            const vehicleCoverages = WMICPACoverageUtil.getVehicleCoveragesForRisk(_.get(submissionVM, 'lobData.personalAuto'), vehicle.publicID.value);
            const vehicleOverride = {
                [`vehicleCoveragesIterableComponent${index}`]: {
                    'vehicleVM': vehicle,
                    'vehicleIndex': index,
                    'baseData': submissionVM.baseData,
                    vehicleCoverages,
                    driverEndorsementsForVehicle,
                    jobVM: submissionVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, vehicleOverride);
        })

        return Object.assign({}, ...vehicleOverrides);
    }

    const overrideProps = {
        startDate: {
            visible: showQuoteStartDate
        },
        print: {
            visible: !showQuoteStartDate
        },
        vehicleCoveragesIterableAccordionContainer: {
            data: getOrderedVehiclesList()
        },
        policyCoveragesContainer: {
            jobVM: submissionVM,
            lineCoverages: _.get(submissionVM, 'lobData.personalAuto.offerings.children[0].coverages.lineCoverages'),
            ...commonOverrides
        },
        driverEndorsementsAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('driverEndorsementsComponent')
        },
        driverEndorsementsComponent: {
            accountHolder: _.get(submissionVM, 'baseData.accountHolder.value'),
            jobVM: submissionVM,
            ...commonAccordionContentProps,
        },
        ..._iterableProps()
    };

    useEffect(() => {
        const offerings = _.get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = _.filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = _.map(draftOffers, 'branchCode');
        setStaleQuoteBranchCode(draftBranchCodes);
        // Store the initial SubmissionVM when component is mounted
        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                return false;
            }
            setWizardLoading(true);
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader)
            updateWizardData(submissionVM);
            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
            onStaleQuoteBranchCode: onStaleQuoteBranchCode,
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onChangeSubmission: changeSubmission,
            onSyncCoverages: syncCoverages,
            onPrint: handlePrint
        },
        resolveComponentMap: {
            WMICDriverEndorsementsComponent,
            WMICPAVehicleCoverages,
            WMICPAPolicyCoverages,
            Accordion
        }
    };

    return (
        <WMICWizardSubmissionPage
            skipWhen={initialValidation}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            flowStepId={FlowStepId.PA_COVERAGES}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPACoveragesPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICPACoveragesPage;
