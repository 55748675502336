import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import _ from "lodash";
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import {WMICLogger, parseErrorMessage, WMICValidationUtil, WMICVariousUtil} from 'wmic-pe-portals-utils-js';
import { Loader } from '@jutro/components';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';

import metadata from './WMICRenewalChangeSummaryPage.metadata.json5';
import './WMICRenewalChangeSummaryPage.messages';

function WMICRenewalChangeSummaryPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData
    } = props;

    const { authHeader } = useAuthentication();
    const { RenewalService } = useDependencies('RenewalService');
    const translator = useContext(TranslatorContext);
    const { setWizardLoading } = useWizardModals();
    const [renewalDiffData, updateRenewalDiffData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const jobID = _.get(renewalVM, 'jobID.value');
    const jobType = _.get(renewalVM, 'baseData.jobType.value.code');
    const { isComponentValid, registerComponentValidation } = useValidation('WMICRenewalChangeSummaryPage');
    const hasNoDtoValidationErrors = useCallback(() => WMICValidationUtil.hasNoDtoValidationErrors(renewalVM) , [renewalVM]);

    useEffect(() => {
        registerComponentValidation(hasNoDtoValidationErrors);
    }, [registerComponentValidation, hasNoDtoValidationErrors]);

    useEffect(() => {
        setIsLoading(true);
        RenewalService.getRenewalDiff_WMIC(
            jobID,
            authHeader
        ).then((response) => {
            updateRenewalDiffData(response);
        }).catch((error) => {
            WMICLogger.error('WMICRenewalChangeSummaryPage getRenewalDiff_WMIC', error);
        }).finally(() => setIsLoading(false));
    }, [RenewalService, authHeader, jobID, renewalVM]);

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                return false;
            }

            const payload = renewalVM.value;
            setWizardLoading(true, translator(commonMessages.quoting));
            renewalVM.value = await RenewalService.generateQuote(
                [payload],
                authHeader
            );

            updateWizardData(renewalVM);

            if (WMICValidationUtil.hasDtoValidationErrors(renewalVM)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return renewalVM;
        } catch(error) {
            WMICLogger.error('WMICRenewalChangeSummaryPage generateQuote', error);
            WMICErrorHandler.processAsModal(parseErrorMessage(error));
            return false;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [RenewalService, authHeader, jobID, renewalVM, setWizardLoading, translator, isComponentValid]);

    const overrideProps = {
        noChangeMsg: {
            visible: _.get(renewalDiffData, "renewalDiffItems", []).length === 0
        },
        policyChangeDiffComponent: {
            visible: _.get(renewalDiffData, "renewalDiffItems", []).length > 0,
            changeSummaryDiffItems: _.get(renewalDiffData, "renewalDiffItems", []),
            jobType,
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            nextLabel={translator(commonMessages.quote)}
            disableNext={!isComponentValid}
        >
            {isLoading ? (
                <Loader loaded={!isLoading} />
            ) : (
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={renewalVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                />
            )}
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICRenewalChangeSummaryPage.propTypes = wizardProps;

export default withViewModelService(WMICRenewalChangeSummaryPage);
