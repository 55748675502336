/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react'
import {HOConstants, CONSTANTS} from "wmic-pe-portals-utils-js";
import {useWizardModals} from "wmic-pe-portals-wizard-components-ui";
import { WMICButton } from 'wmic-pe-components-platform-react';
import WMICAddKitchenBathComponent from './WMICAddKitchenBath/WMICAddKitchenBathComponent';
import WMICAddExteriorWallComponent from './WMICAddExteriorWall/WMICAddExteriorWallComponent';
import kitchenBathMessages from './WMICAddKitchenBath/WMICAddKitchenBathComponent.messages';
import exteriorWallMessages from './WMICAddExteriorWall/WMICAddExteriorWallComponent.messages';
import messages from './WMICHOConstructionDetails.messages';
import metadata from './WMICHOConstructionDetails.metadata.json5';
import styles from './WMICHOConstructionDetails.module.scss';
import * as WMICHOConstructionDetailsUtil from './WMICHOConstructionDetailsUtil';

function WMICHOConstructionDetails(props) {
    const {
        id,
        riskView,
        updateDetails,
        showErrors,
        isReadOnlyUser,
        isEditMode,
        onValidate,
        detailsValidity,
        updateDetailsValidity
    } = props;

    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();
    const { onValidate: setComponentValidation, isComponentValid, registerComponentValidation } = useValidation(id);

    const [minimumKitchensValid, setMinimumKitchensValid] = useState(false);
    const [minimumBathsValid, setMinimumBathsValid] = useState(false);
    const [showFinishPercentError, setShowFinishPercentError] = useState(false);
    const [showFramePercentError, setShowFramePercentError] = useState(false);
    const { showConfirm } = useWizardModals();

    const checkComponentValidation = useCallback(() => {
        const collectedExteriorWalls = _.get(riskView, 'constructionDetail.exteriorWallInfo.value');
        const isWallsPercentageEffective = _.get(riskView, 'constructionDetail.wallsPercentageEffectiveFlag.aspects.required');
        return (((minimumKitchensValid && minimumBathsValid) && _.some( collectedExteriorWalls, (exteriorWall) => { return  exteriorWall.materialOrFeature?.toLowerCase() === HOConstants.exteriorWallFramingType; } ) && (isWallsPercentageEffective || !(showFinishPercentError || showFramePercentError))))
    }, [minimumBathsValid, minimumKitchensValid, riskView, showFramePercentError, showFinishPercentError]);

    useEffect(() => {
        registerComponentValidation(checkComponentValidation);
    }, [checkComponentValidation, registerComponentValidation]);

    const updateDetailsData = useCallback(
        (data) => {
            refreshData();
            updateDetails(data);
        },
        [refreshData, updateDetails]
    );

    // KITCHEN & BATH
    const minimumKitchensAndBathsValidCheck = () => {
        const isKitchenBathsRequired = _.get(riskView, 'constructionDetail.kitchenBathInfo.aspects.required');
        const kitchenBaths = _.get(riskView, 'constructionDetail.kitchenBathInfo.value');

        const isMinimumKitchensValid = (_.some( kitchenBaths,(kitchenBath) => WMICHOConstructionDetailsUtil.isKitchen(kitchenBath)) || !isKitchenBathsRequired);
        const isMinimumBathsValid = (_.some( kitchenBaths,(kitchenBath) => WMICHOConstructionDetailsUtil.isBath(kitchenBath)) || !isKitchenBathsRequired);

        setMinimumKitchensValid(isMinimumKitchensValid);
        setMinimumBathsValid(isMinimumBathsValid);
        updateDetailsValidity(prevState => ({...prevState, minimumKitchensAndBathsValid: minimumKitchensValid && minimumBathsValid}));
    };

    const removeKitchenBath = async (kitchenBath, index) => {
        const kitchenBathDisplayName = WMICHOConstructionDetailsUtil.getKitchenBathDisplayName(kitchenBath.value, messages, translator)

        const response = await showConfirm({
            title: translator(messages.removeKitchenBathConfirmationTitle, {kitchenBath: kitchenBathDisplayName}),
            message: translator(messages.removeKitchenBathConfirmationMessage, { kitchenBath: kitchenBathDisplayName }),
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            riskView.constructionDetail.kitchenBathInfo.value.splice(index, 1);
            updateDetailsData(riskView)
            minimumKitchensAndBathsValidCheck();
        }
    };

    const minimumWallsValidCheck = () => {
        const collectedExteriorWalls = _.get(riskView, 'constructionDetail.exteriorWallInfo.value');
        const minimumWallsValid = _.some(
            collectedExteriorWalls,
            (exteriorWall) => {
                return  exteriorWall.materialOrFeature.toLowerCase() === HOConstants.exteriorWallFramingType;
            }
        );
        updateDetailsValidity(prevState => ({...prevState, minimumWallsValid: minimumWallsValid}));
    }

    const wallsPercentageValidCheck = () => {
        let totalFinish = 0;
        let totalFrame = 0;
        let finishCount = 0;
        let frameCount = 0;
        const collectedExteriorWalls = _.get(riskView, 'constructionDetail.exteriorWallInfo.value');

        _.each(collectedExteriorWalls, (ew) => {
            if (ew.materialOrFeature.toLowerCase() === HOConstants.exteriorWallFinishType) {
                totalFinish += parseInt(ew.percentage);
                finishCount++;
            } else if (ew.materialOrFeature.toLowerCase() === HOConstants.exteriorWallFramingType) {
                totalFrame += parseInt(ew.percentage);
                frameCount++;
            }
        })

        const isFinishPercentageEffective = _.get(riskView, 'constructionDetail.finishPercentageEffectiveFlag.apects.required');
        const checkIfShowFinishPercentError = (totalFinish !== 100 && finishCount > 0) && isFinishPercentageEffective;
        setShowFinishPercentError(checkIfShowFinishPercentError);

        const isFramePercentageEffective = _.get(riskView, 'constructionDetail.framePercentageEffectiveFlag.aspects.required');
        const checkIfShowFramePercentError = (totalFrame !== 100 && frameCount > 0) && isFramePercentageEffective;
        setShowFramePercentError(checkIfShowFramePercentError);

        const isWallsPercentageEffective = _.get(riskView, 'constructionDetail.wallsPercentageEffectiveFlag.aspects.required');
        updateDetailsValidity(prevState => ({...prevState, wallsPercentageValid: isWallsPercentageEffective || !(showFinishPercentError || showFramePercentError)}));

    }

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid && checkComponentValidation(), id);
        }
    }, [id, onValidate, isComponentValid, checkComponentValidation, minimumBathsValid, minimumKitchensValid, riskView, showFramePercentError, showFinishPercentError])

    useEffect(() => {
        minimumKitchensAndBathsValidCheck();
        minimumWallsValidCheck();
        wallsPercentageValidCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeExteriorWall = async (exteriorWall, index) => {
        const response = await showConfirm({
            title: messages.removeExteriorWall,
            message: messages.removeExteriorWall
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            riskView.constructionDetail.exteriorWallInfo.value.splice(index, 1);
            updateDetailsData(riskView)
            minimumWallsValidCheck();
            wallsPercentageValidCheck();
        }
    };

    const handleClickAddKitchenBath = () => {
        const kitchenBathVM = ViewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGKitchenBathDTO_WMIC'
        );

        riskView.constructionDetail.kitchenBathInfo.value.push(kitchenBathVM.value);

        return kitchenBathVM;
    }

    const saveNewKitchenBath = (kitchenBathVM, index) => {
        if (index >= 0) {
            riskView.constructionDetail.kitchenBathInfo.value[index] = kitchenBathVM.value;
        } else {
            riskView.constructionDetail.kitchenBathInfo.value.push(kitchenBathVM.value);
        }
        updateDetails(riskView);
        minimumKitchensAndBathsValidCheck();

        return true;
    }

    const handleAddExteriorWall = () => {
        const exteriorWallVM = ViewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGExtWallConstructDTO_WMIC'
        );
        riskView.constructionDetail.exteriorWallInfo.value.push(exteriorWallVM.value);

        return exteriorWallVM;
    };

    const saveNewExteriorWall = (exteriorWallVM, index) => {
        if (index >= 0) {
            riskView.constructionDetail.exteriorWallInfo.value[index] = exteriorWallVM.value;
        } else {
            riskView.constructionDetail.exteriorWallInfo.value.push(exteriorWallVM.value);
        }
        updateDetails(riskView);

        minimumWallsValidCheck();
        wallsPercentageValidCheck()

        return true;
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            parentNode: riskView,
            readOnly: !isEditMode || isReadOnlyUser
        },
        tlaContainer : {
            visible: _.get(riskView, 'constructionDetail.isTLAContainerVisibleFlag.aspects.ocular')
        },
        livingArea: {
            secondaryLabel: _.get(riskView, 'constructionDetail.livingArea.livingAreaVal.value') > 0 ? '' : translator(messages.wmicTLAMandatoryMsg),
        },
        mobileHomeInfoHomeTiedDown: {
            secondaryLabel: _.get(riskView, 'constructionDetail.mobileHomeInfo.homeTiedDown.value') ? '' : translator(messages.wmicHomeTiedDownMandatoryMsg),
        },
        kitchenBathListView: {
            VMData: [
                {
                    headerText: translator(kitchenBathMessages.featureType),
                    path: 'materialOrFeature'
                },
                {
                    headerText: translator(kitchenBathMessages.kitchenBathDescription),
                    path: 'description',
                    getData: (item, path) => WMICHOConstructionDetailsUtil.renderDescriptionWithOther(item, path, translator)
                },
                {
                    headerText: translator(kitchenBathMessages.kitchenBathNumber),
                    path: 'count'
                }
            ],
            startOpen: false,
            toCreate: handleClickAddKitchenBath,
            toUndoCreate: () => {
                const kitchenBathInfo = _.get(riskView.value, 'constructionDetail.kitchenBathInfo');
                kitchenBathInfo.splice(kitchenBathInfo.length - 1, 1);
                _.set(riskView.value, 'constructionDetail.kitchenBathInfo', kitchenBathInfo);
                updateDetailsData(riskView);
            },
            onSave: saveNewKitchenBath,
            onDelete: removeKitchenBath,
            onValidate,
            readOnly: !isEditMode,
            clickable: true,
            flatCards: true,
            detailViewComponent: WMICAddKitchenBathComponent,
            renderAddButton: ({isEditing, onClick}) => (
                <div className="gw-mb-4">
                    <WMICButton
                        id={`${id}_KitchenBath_CreateButton`} 
                        type="secondary"
                        onClick={onClick}
                        disabled={isEditing}
                        icon="mi-add"
                    >
                        {translator(messages.addKitchenBathButton)}
                    </WMICButton>
                </div>
            ),
        },
        kitchensBathsContainer: {
            visible:  _.get(riskView, 'constructionDetail.displayKitchenBathsContainer.aspects.ocular')
        },
        showKitchenBathMsg: {
            visible: !(detailsValidity.minimumKitchensAndBathsValid || (minimumKitchensValid || minimumBathsValid))
        },
        showKitchenMsg: {
            visible: (!minimumKitchensValid && minimumBathsValid)
        },
        showBathMsg: {
            visible: (minimumKitchensValid && !minimumBathsValid)
        },
        showExtWallMsg: {
            visible: !detailsValidity.minimumWallsValid
        },
        wmicFinishPercentMsg: {
            visible: showFinishPercentError
        },
        wmicFramePercentMsg: {
            visible: showFramePercentError
        },
        exteriorWallsListView: {
            VMData: [
                {
                    headerText: translator(exteriorWallMessages.materialType),
                    path: 'materialOrFeature'
                },
                {
                    headerText: translator(exteriorWallMessages.exteriorWallDescription),
                    path: 'description',
                    getData: (item, path) => WMICHOConstructionDetailsUtil.renderDescriptionWithOther(item, path, translator)
                },
                {
                    headerText: translator(exteriorWallMessages.exteriorWallPercentage),
                    path: 'percentage'
                },
                {
                    headerText: translator(exteriorWallMessages.exteriorWallNumber),
                    getData: (item, path, index) => index+1
                }
            ],
            startOpen: false,
            toCreate: handleAddExteriorWall,
            toUndoCreate: () => {
                const exteriorWallInfo = _.get(riskView.value, 'constructionDetail.exteriorWallInfo');
                exteriorWallInfo.splice(exteriorWallInfo.length - 1, 1);
                _.set(riskView.value, 'constructionDetail.exteriorWallInfo', exteriorWallInfo);
                updateDetailsData(riskView);
            },
            onSave: saveNewExteriorWall,
            onDelete: removeExteriorWall,
            onValidate,
            readOnly: !isEditMode,
            clickable: true,
            flatCards: true,
            detailViewComponent: WMICAddExteriorWallComponent,
            renderAddButton: ({isEditing, onClick}) => (
                <div className="gw-mb-4">
                    <WMICButton
                        id={`${id}_ExteriorWall_CreateButton`} 
                        type="secondary"
                        onClick={onClick}
                        disabled={isEditing}
                        icon="mi-add"
                    >
                        {translator(messages.addExteriorWallButton)}
                    </WMICButton>
                </div>
            ),
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            WMICAddKitchenBathComponent,
            WMICAddExteriorWallComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            onModelChange={updateDetailsData}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOConstructionDetails.propTypes = {
    riskView: PropTypes.shape({}),
    isEditMode: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired
};

WMICHOConstructionDetails.defaultProps = {
    riskView: {},
};

export default WMICHOConstructionDetails;
