import React, {
    useCallback,
    useContext,
    useState,
    useEffect,
} from 'react';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { CONSTANTS, WMICValidationUtil, WMICVariousUtil, FlowStepId, WMICLogger } from 'wmic-pe-portals-utils-js';
import { WMICScrollToError, WMICListView } from 'wmic-pe-components-platform-react';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICGLBusinessOperationsPage.metadata.json5';
import messages from './WMICGLBusinessOperationsPage.messages';
import BusinessOperationDetailView from './BusinessOperationDetailView/WMICGLBusinessOperationDetailView';

const EXPOSURE_PATH = 'lobData.generalLiability.exposures';

function WMICGLBusinessOperationsPage(props) {
    const translator = useContext(TranslatorContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { isComponentValid, onValidate, initialValidation, registerInitialComponentValidation } = useValidation('WMICGLBusinessOperationsPage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { setWizardLoading } = useWizardModals();
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState(false); // we toggle this when we want to scroll to the first error on the page
    const exposureListVM = _.get(submissionVM, EXPOSURE_PATH);
    const [loading, setLoading] = useState(true);
    const { showConfirm } = useWizardModals();

    useDocumentTitle(translator(messages.title), submissionVM);

    const validateInitialComponent = useCallback(() => {
        const exposures = _.get(submissionVM, `${EXPOSURE_PATH}.children`);

        return WMICValidationUtil.validatePageEntities(submissionVM, [`${EXPOSURE_PATH}`])
            && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_OPERATIONS)
            && _.some(exposures, (exposure) => exposure.industryCode.code.value === submissionVM.lobData.commercialProperty.industryCode.code.value);
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialComponent);
    }, [registerInitialComponentValidation, validateInitialComponent]);


    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    useEffect(() => {
        setLoading(false);
    }, []);


    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.CP_OPERATIONS;
    }, [submissionVM.value]);

    const onNext = useCallback(async () => {

        try {
            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true);
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');
            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader)

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_OPERATIONS)) {
                WMICVariousUtil.scrollToTop();
                return false
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }

    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);

    const updateExposureListVM = () => {
        updateWizardData(submissionVM);
    }

    const deleteExposure = useCallback(async (exposureListVM, index) => {
        const response = await showConfirm({
            title: messages.removeExposureShort,
            message: messages.removeExposureLong,
        });

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            try {
                setWizardLoading(true);

                const allExposures = _.get(submissionVM, `${EXPOSURE_PATH}.value`, []);
                allExposures.splice(index, 1);
                _.set(submissionVM, `${EXPOSURE_PATH}.value`, allExposures);

                _.unset(submissionVM.value, 'bindData');
                submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(submissionVM.value, authHeader)
                updateWizardData(submissionVM);

                return true;
            } finally {
                setWizardLoading(false);
            }
        }

        return false;
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [submissionVM, authHeader, setWizardLoading, showConfirm, LoadSaveService]);

    const createExposure = useCallback(
        () => {
            const newExposureVM = viewModelService.create(
                {
                    industryCode: submissionVM.lobData.commercialProperty.industryCode.value
                },
                'pc', 'wmic.edge.ca.capabilities.policyjob.lob.generalliability.exposures.dto.GlExposureDTO_WMIC',
                submissionVM.aspects.context()
            );
            submissionVM.lobData.generalLiability.exposures.value.push(newExposureVM.value);
            setShowErrors(false);
            return newExposureVM;
        }, []);

    const saveSubmission = useCallback((newSubmission, onError = _.noop) => {

        setWizardLoading(true, translator(messages.savingExposure));
        _.unset(newSubmission, 'errorsAndWarnings');

        return LoadSaveService.saveWithNoValidationRuleCheck(
            newSubmission,
            authHeader
        ).then((result) => {

            submissionVM.value = result;
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_OPERATIONS)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        }).catch((error) => {
            WMICLogger.error('Save Exposure failed', error);
            onError();
            return false;
        }).finally(() => {
            setWizardLoading(false);
        });

        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [LoadSaveService, authHeader, setWizardLoading, submissionVM, translator]);

    const saveExposure = useCallback((exposureVM, index) => {
        if (exposureVM.aspects.valid && exposureVM.aspects.subtreeValid) {
            _.set(submissionVM, `${EXPOSURE_PATH}[${index}].businessOperation`, exposureVM.businessOperation.displayName);
            return saveSubmission(submissionVM.value);
        }

        setShowErrors(true);
    }, [saveSubmission, submissionVM]);

    const businessOperationsListVMData = [
        {
            headerText: translator(messages.industryCode),
            path: 'industryCode.code',
        },
        {
            headerText: translator(messages.classification),
            path: 'industryCode.classification',
        },
        {
            headerText: translator(messages.basisType),
            path: 'industryCode.basisType',
        },
        {
            headerText: translator(messages.basis),
            path: 'basisAmount',
        },
    ];

    const overrideProps = {
        '@field': {
            parentNode: exposureListVM
        },
        additionalBusinessOperationList: {
            startOpen: false,
            readOnly: false,
            value: _.get(submissionVM, 'lobData.generalLiability.exposures.children', []),
            VMData: businessOperationsListVMData,
            detailViewComponentProps: { authHeader, updateWizardData, submissionVM },
            detailViewComponent: BusinessOperationDetailView,
            onValidate,
            toCreate: createExposure,
            onDelete: deleteExposure,
            onSave: saveExposure,
            toUndoCreate: () => {
                const exposures = _.get(submissionVM.value, EXPOSURE_PATH);
                exposures.splice(exposures.length - 1, 1);
                _.set(submissionVM.value, EXPOSURE_PATH, exposures);
                updateWizardData(submissionVM);
            },
            showErrors
        },
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICListView
        }
    };

    if (loading) {
        return null;
    }

    return (
        <WMICWizardSubmissionPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={commonMessages.saveAndExit}
            onNext={onNext}
            flowStepId={FlowStepId.CP_OPERATIONS}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={exposureListVM}
                overrideProps={overrideProps}
                onModelChange={updateExposureListVM}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );

}

export default WMICGLBusinessOperationsPage;
