import { defineMessages } from 'react-intl';

export default defineMessages({
    policyHasOverdueAmount: {
        id: 'portal.views.overdue-policies.policyHasOverdueAmount',
        defaultMessage: 'Your {policyType} payment is overdue.'
    },
    policy: {
        id: 'portal.views.overdue-policies.policy',
        defaultMessage: 'Policy #{policyNumber}'
    },
    policyIsOverdue: {
        id: 'portal.views.overdue-policies.policyIsOverdue',
        defaultMessage: 'Your policy is overdue'
    },
    policiesAreOverdue: {
        id: 'portal.views.overdue-policies.policiesAreOverdue',
        defaultMessage: 'Your policies are overdue'
    },
    payNow: {
        id: 'portal.views.overdue-policies.payNow',
        defaultMessage: 'Pay Now'
    },
    payNowAria: {
        id: 'portal.views.overdue-policies.payNowAria',
        defaultMessage: 'Pay the overdue balance of {overdueAmount} on policy #{policyNumber} now'
    },
    payNowTrackButtonIdentifier: {
        id: 'portal.views.overdue-policies.tracking.payNow',
        defaultMessage: 'Home Page - Calls to action - {policyActiveStatus} Policies - Pay Now'
    },
    active: {
        id: 'portal.views.overdue-policies.active',
        defaultMessage: 'Active'
    },
    inactive: {
        id: 'portal.views.overdue-policies.inactive',
        defaultMessage: 'Inactive'
    },
    makeAPaymentContact: {
        id: 'portal.views.overdue-policies.makeAPaymentContact',
        defaultMessage: 'Please make a payment below or contact us with any questions at &lt;a href="tel:1-800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    makeAPaymentContactAria: {
        id: 'portal.views.overdue-policies.makeAPaymentContactAria',
        defaultMessage: 'Please make a payment below or contact us with any questions at &lt;a href="tel:1-800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday to Friday 7:30 AM to 7:30 PM and Saturday 8:00 AM to 4:30 PM.'
    },
    close: {
        id: 'portal.views.overdue-policies.close',
        defaultMessage: 'Close'
    },
    yourPaymentsAreOverdue: {
        id: 'portal.views.overdue-policies.yourPaymentsAreOverdue',
        defaultMessage: 'Your payments are overdue'
    },
    yourPolicyHasBeenCancelled: {
        id: 'portal.views.overdue-policies.yourPolicyHasBeenCancelled',
        defaultMessage: 'Your policy has been cancelled'
    },
    yourPaymentIsOverdue: {
        id: 'portal.views.overdue-policies.yourPaymentIsOverdue',
        defaultMessage: 'Your payment is overdue'
    },
    lastPaymentReturned: {
        id: 'portal.views.overdue-policies.lastPaymentReturned',
        defaultMessage: 'The last payment was returned due to a lack of funds. The policy has an overdue amount of '
    },
    mustBePaidBefore: {
        id: 'portal.views.overdue-policies.mastBePaidBefore',
        defaultMessage: 'which &lt;strong&gt;must be paid before {payBeforeDate}&lt;/strong&gt; to avoid cancellation.'
    },
    automobilePolicy: {
        id: 'portal.views.overdue-policies.automobilePolicy',
        defaultMessage: 'Automobile policy'
    },
    automobilePolicyShort: {
        id: 'portal.views.overdue-policies.automobilePolicyShort',
        defaultMessage: 'Auto policy'
    },
    rentersPolicy: {
        id: 'portal.views.overdue-policies.rentersPolicy',
        defaultMessage: 'Renters policy'
    },
    homeownersPolicy: {
        id: 'portal.views.overdue-policies.homeownersPolicy',
        defaultMessage: 'Homeowners policy'
    },
    condominiumPolicy: {
        id: 'portal.views.overdue-policies.condominiumPolicy',
        defaultMessage: 'Condominium policy'
    },
    genericPolicy: {
        id: 'portal.views.overdue-policies.genericPolicy',
        defaultMessage: 'Policy'
    },
    subHeaderNotTakenCancelled: {
        id: 'portal.views.overdue-policies.subHeaderNotTakenCancelled',
        defaultMessage: '{policyType} #{policyNumber} has expired due to non-payment.'
    },
    subHeaderNotTakenInForce: {
        id: 'portal.views.overdue-policies.subHeaderNotTakenInForce',
        defaultMessage: '{policyType} #{policyNumber} requires a payment to renew insurance coverage.'
    },
    messageNotTakenCancelled: {
        id: 'portal.views.overdue-policies.messageNotTakenCancelled',
        defaultMessage: 'Your payment has not been received or was returned by your financial institution.&lt;br/&gt;This policy still has an overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt;.'
    },
    messageNotTakenInForceNoDate: {
        id: 'portal.views.overdue-policies.messageNotTakenInForceNoDate',
        defaultMessage: 'Your policy has expired as of 12:01 AM on &lt;strong&gt;{effDate}&lt;/strong&gt;. We would be pleased to reinstate policy coverage without interruption if payment of &lt;strong&gt;{overdueAmount}&lt;/strong&gt;.'
    },
    messageNotTakenInForce: {
        id: 'portal.views.overdue-policies.messageNotTakenInForce',
        defaultMessage: 'Your policy has expired as of 12:01 AM on &lt;strong&gt;{effDate}&lt;/strong&gt;. We would be pleased to reinstate policy coverage without interruption if payment of &lt;strong&gt;{overdueAmount}&lt;/strong&gt; is received before &lt;strong&gt;{cancellationDate}&lt;/strong&gt;.'
    },
    overdueAmountCallToReinstate: {
        id: 'portal.views.overdue-policies.overdueAmountCallToReinstate',
        defaultMessage: 'Your payment has not been received or was returned by your financial institution.&lt;br/&gt;This policy still has an overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt;.'
    },
    cancelledDueToAnOutstandingBalance: {
        id: 'portal.views.overdue-policies.cancelledDueToAnOutstandingBalance',
        defaultMessage: '{policyType} #{policyNumber} was cancelled due to an outstanding balance.'
    },
    isAtRiskOfBeingCancelled: {
        id: 'portal.views.overdue-policies.isAtRiskOfBeingCancelled',
        defaultMessage: '{policyType} #{policyNumber} is at risk of being cancelled.'
    },
    hasAnOverdueAmountWhichIsInactive: {
        id: 'portal.views.overdue-policies.hasAnOverdueAmountWhichIsInactive',
        defaultMessage: 'Your payment has not been received or was returned by your financial institution.&lt;br/&gt;This policy still has an overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt;'
    },
    hasAnOverdueAmountWhichMustBePaidBefore: {
        id: 'portal.views.overdue-policies.hasAnOverdueAmountWhichMustBePaidBefore',
        defaultMessage: 'Your payment has not been received or was returned by your financial institution. The overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt; must be paid by &lt;strong&gt;{cancellationDate}&lt;/strong&gt; to avoid cancellation.'
    },
    policyHasAnOverdueAmount: {
        id: 'portal.views.overdue-policies.policyHasAnOverdueAmount',
        defaultMessage: 'Your payment has not been received or was returned by your financial institution.&lt;br/&gt;The overdue balance is &lt;strong&gt;{overdueAmount}&lt;/strong&gt;.'
    },
    contact: {
        id: 'portal.views.overdue-policies.contact',
        defaultMessage: 'Contact us with any questions at &lt;a href="tel:1-800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    contactAria: {
        id: 'portal.views.overdue-policies.contactAria',
        defaultMessage: 'Contact us with any questions at &lt;a href="tel:1-800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday to Friday 7:30 AM to 7:30 PM and Saturday 8:00 AM to 4:30 PM.'
    },
    yourPolicyHasExpiredAsOf: {
        id: 'portal.views.overdue-policies.yourPolicyHasExpiredAsOf',
        defaultMessage: 'Your policy has expired as of 12:01 AM on &lt;strong&gt;{effDate}&lt;/strong&gt;. We would be pleased to reinstate policy coverage without interruption if payment of &lt;strong&gt;{overdueAmount}&lt;/strong&gt; is received before &lt;strong&gt;{cancellationDate}&lt;/strong&gt;.&lt;br/&gt;&lt;br/&gt;You may still make a payment, but we recommend contacting your mortgage company as they have already been billed. If you make a payment, it may take up to 5 business days for your account to be updated.'
    },
    aPaymentWasNotReceivedFromYourMortgageCompany: {
        id: 'portal.views.overdue-policies.aPaymentWasNotReceivedFromYourMortgageCompany',
        defaultMessage: 'A payment was not received from your mortgage company. This policy still has an overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt;.'
    },
    aPaymentWasNotReceivedFromYourMortgageCompany2: {
        id: 'portal.views.overdue-policies.aPaymentWasNotReceivedFromYourMortgageCompany2',
        defaultMessage: 'A payment has not been received from your mortgage company. The overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt; must be paid by &lt;strong&gt;{cancellationDate}&lt;/strong&gt; to avoid cancellation.&lt;br/&gt;&lt;br/&gt;You may still make a payment, but we recommend contacting your mortgage company as they have already been billed. If you make a payment, it may take up to 5 business days for your account to be updated.'
    },
    aPaymentHasBeenReceivedButWasReturned: {
        id: 'portal.views.overdue-policies.aPaymentHasBeenReceivedButWasReturned',
        defaultMessage: 'A payment has been received, but was returned by the financial institution. The overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt; must be paid by &lt;strong&gt;{cancellationDate}&lt;/strong&gt; to avoid cancellation.&lt;br/&gt;&lt;br/&gt;You can make a payment, but we recommend contacting your mortgage company as they have already been billed. If you make another payment, it may take up to 5 business days for your account to be updated.'
    },
    aPaymentWasReceivedButWasReturned: {
        id: 'portal.views.overdue-policies.aPaymentWasReceivedButWasReturned',
        defaultMessage: 'A payment was received, but was returned by the financial institution.  No additional payment was made. This policy still has an overdue balance of &lt;strong&gt;{overdueAmount}&lt;/strong&gt;.'
    }
});