import moment from 'moment';
import VehicleModel from './VehicleModel';
import messages from './Models.messages';

export default class DeleteVehicleModel {
    constructor({ vehicle, reason, date, remainingVehicles, originalVehicles, occasionalDrivers, jobId, status }) {
        this.vehicle = vehicle;
        this.reason = reason;
        this.date = date;
        this.remainingVehicles = remainingVehicles;
        this.originalVehicles = originalVehicles;
        this.occasionalDrivers = occasionalDrivers;
        this.jobId = jobId;
        this.REASONS = {
            SOLD: 'Sold' ,
            GIFTED: 'Gifted',
            TOTAL_LOSS: 'Total Loss',
            TRADE_IN: 'Trade-In',
            INSURED: 'Insured elsewhere',
            NOT_WORKING: 'Non-operational / Not working',
            REPOSSESSED: 'Repossessed'
        }
        this.status = status;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.vehicleReasonDateRequired);
        }

        return `Vehicle to be deleted:
${this.vehicle.toString()}

Reason for deleting vehicle: ${this.REASONS[this.reason]}

Date: ${moment(this.date).format('MM-DD-YYYY')}

----------------------------------------------------------------------------
${this.originalVehicles ? 'ORIGINAL VEHICLES:' : ''}
${this.originalVehicles ? this.originalVehicles.map((v) => v.toStringWithAddParams()).join('') : ''}
----------------------------------------------------------------------------

${this.remainingVehicles ? 'REMAINING VEHICLES:' : ''}
${this.remainingVehicles ? this.remainingVehicles.map((v) => v.toStringWithAddParams()).join('') : ''}
`;
    }

    toHTML() {
        return `<span>
<b>${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}</b><br/>
<b>VIN:</b> ${this.vehicle.vin}<br/>
<b>Reason:</b> ${this.REASONS[this.reason]}<br/>
<b>Date:</b> ${moment(this.date).format('MM-DD-YYYY')}<br/>
</span>`;
    }

    static getMessageTitle(translator) {
        return translator(messages.ampDeleteVehicleRequest);
    }

    valid() {
        const required = [this.vehicle, this.reason, this.date];
        return required.every((v) => v)
            && this.vehicle.valid()
            // eslint-disable-next-line no-prototype-builtins
            && this.REASONS.hasOwnProperty(this.reason);
    }

    static toObjectModel(obj) {
        const model = Object.setPrototypeOf(obj, DeleteVehicleModel.prototype);
        model.vehicle = Object.setPrototypeOf(obj.vehicle, VehicleModel.prototype);
        return model;
    }
}
