// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICProgressModal__wmicPeProgressModal{padding:var(--GW-SPACING-8)}.app__WMICProgressModal__wmicPeProgressModal .app__WMICProgressModal__wmicPeProgressModalTitle{color:var(--GW-TEXT-COLOR-1);font-family:var(--GW-FONT-FAMILY);font-size:var(--GW-FONT-SIZE-H3);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}.app__WMICProgressModal__wmicPeProgressModalSmall{padding:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/components/WMICProgressModal/WMICProgressModal.module.scss"],"names":[],"mappings":"AACA,6CACI,2BAAA,CAEA,+FAKI,4BAAA,CAFA,iCAAA,CADA,gCAAA,CAEA,2CACA,CAIR,kDACI,2BAAA","sourcesContent":["\n.wmicPeProgressModal {\n    padding: var(--GW-SPACING-8);\n\n    .wmicPeProgressModalTitle {\n        /* Title span is replacing old h3 tag. Styling of h3 tag placed here*/\n        font-size: var(--GW-FONT-SIZE-H3);\n        font-family: var(--GW-FONT-FAMILY);\n        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n        color: var(--GW-TEXT-COLOR-1);\n    }\n}\n\n.wmicPeProgressModalSmall {\n    padding: var(--GW-SPACING-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmicPeProgressModal": "app__WMICProgressModal__wmicPeProgressModal",
	"wmicPeProgressModalTitle": "app__WMICProgressModal__wmicPeProgressModalTitle",
	"wmicPeProgressModalSmall": "app__WMICProgressModal__wmicPeProgressModalSmall"
};
export default ___CSS_LOADER_EXPORT___;
