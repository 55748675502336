import { defineMessages } from 'react-intl';

export default defineMessages({
    ClaimVehicleInformation: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Claim & Vehicle Information',
        defaultMessage: 'Claim & Vehicle Information'
    },
    ClaimStatus: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Claim Status',
        defaultMessage: 'Claim Status'
    },
    ClaimStatusAria: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Claim Status Aria',
        defaultMessage: 'Claim Status {claimState}'
    },
    CompletedDate: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Completed Date',
        defaultMessage: 'Completed Date'
    },
    CompletedDateAria: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Completed Date Aria',
        defaultMessage: 'Completed Date {date}'
    },
    PolicyNumber: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Policy #',
        defaultMessage: 'Policy #'
    },
    PolicyNumberAria: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Policy # Aria',
        defaultMessage: 'Policy # {policyNumber}'
    },
    ClaimType: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Claim Type',
        defaultMessage: 'Claim Type'
    },
    ClaimTypeAria: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Claim Type Aria',
        defaultMessage: 'Claim Type {claimType}'
    },
    InsuredVehicle: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Insured Vehicle',
        defaultMessage: 'Insured Vehicle'
    },
    InsuredVehicleAria: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Insured Vehicle Aria',
        defaultMessage: 'Insured Vehicle {vehicle}'
    },
    InsuredDriverAria: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Insured Driver Aria',
        defaultMessage: 'Insured Driver {driver}'
    },
    Pending: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Pending',
        defaultMessage: 'Pending'
    },
    VehicleWasParked: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Vehicle was parked',
        defaultMessage: 'Vehicle was parked'
    },
    InformationUnavailable: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Information unavailable',
        defaultMessage: 'Information unavailable'
    },
    Deductible: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Deductible',
        defaultMessage: 'Deductible'
    },
    DeductibleAria: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Deductible Aria',
        defaultMessage: 'Deductible {amount}'
    },
    DeductibleTooltip: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.DeductibleTooltip',
        defaultMessage: 'The deductible shown is the amount listed in your policy. For more information, please contact your adjuster.'
    },
    NotApplicable: {
        id: 'wmic.component.claim-and-vehicle-info-box-component.Not Applicable',
        defaultMessage: 'Not Applicable'
    },
    earthquake: {
        id: 'claim.common.directives.lookups.detail-claim-type.Earthquake',
        defaultMessage: 'Earthquake'
    },
    fireFollowingEarthquake: {
        id: 'claim.common.directives.lookups.detail-claim-type.Fire Following Earthquake',
        defaultMessage: 'Fire Following Earthquake'
    },
    landslideSnowslideFollowingEarthquake: {
        id: 'claim.common.directives.lookups.detail-claim-type.Landslide/Snowslide following Earthquake',
        defaultMessage: 'Landslide/Snowslide following Earthquake'
    },
    explosion: {
        id: 'claim.common.directives.lookups.detail-claim-type.Explosion',
        defaultMessage: 'Explosion'
    },
    explosionFromTerrorismOrActOfWar: {
        id: 'claim.common.directives.lookups.detail-claim-type.Explosion from Terrorism or Act of War',
        defaultMessage: 'Explosion from Terrorism or Act of War'
    },
    applianceFire: {
        id: 'claim.common.directives.lookups.detail-claim-type.Appliance Fire',
        defaultMessage: 'Appliance Fire'
    },
    arsonOrSuspectedArson: {
        id: 'claim.common.directives.lookups.detail-claim-type.Arson or Suspected Arson',
        defaultMessage: 'Arson or Suspected Arson'
    },
    bonfireFirePitEmberAsh: {
        id: 'claim.common.directives.lookups.detail-claim-type.Bonfire/Fire Pit (Ember/Ash)',
        defaultMessage: 'Bonfire/Fire Pit (Ember/Ash)'
    },
    cookingIncident: {
        id: 'claim.common.directives.lookups.detail-claim-type.Cooking incident',
        defaultMessage: 'Cooking incident'
    },
    electricalFire: {
        id: 'claim.common.directives.lookups.detail-claim-type.Electrical Fire',
        defaultMessage: 'Electrical Fire'
    },
    fire: {
        id: 'claim.common.directives.lookups.detail-claim-type.Fire',
        defaultMessage: 'Fire'
    },
    storageOrHandlingOfChemicalsFuels: {
        id: 'claim.common.directives.lookups.detail-claim-type.Storage or Handling of Chemicals/Fuels',
        defaultMessage: 'Storage or Handling of Chemicals/Fuels'
    },
    fireFromNeighboringPremises: {
        id: 'claim.common.directives.lookups.detail-claim-type.Fire From Neighboring Premises',
        defaultMessage: 'Fire From Neighboring Premises'
    },
    fireFromHeatingDevice: {
        id: 'claim.common.directives.lookups.detail-claim-type.Fire From Heating Device',
        defaultMessage: 'Fire From Heating Device'
    },
    smokingCigaretteOrOther: {
        id: 'claim.common.directives.lookups.detail-claim-type.Smoking (cigarette or other)',
        defaultMessage: 'Smoking (cigarette or other)'
    },
    wildFire: {
        id: 'claim.common.directives.lookups.detail-claim-type.Wild Fire',
        defaultMessage: 'Wild Fire'
    },
    fallenTreeBranch: {
        id: 'claim.common.directives.lookups.detail-claim-type.Fallen Tree/Branch',
        defaultMessage: 'Fallen Tree/Branch'
    },
    fireFromLightning: {
        id: 'claim.common.directives.lookups.detail-claim-type.Fire From Lightning',
        defaultMessage: 'Fire From Lightning'
    },
    lightning: {
        id: 'claim.common.directives.lookups.detail-claim-type.Lightning',
        defaultMessage: 'Lightning'
    },
    powerSurge: {
        id: 'claim.common.directives.lookups.detail-claim-type.Power Surge',
        defaultMessage: 'Power Surge'
    },
    smokeFromAppliance: {
        id: 'claim.common.directives.lookups.detail-claim-type.Smoke from Appliance',
        defaultMessage: 'Smoke from Appliance'
    },
    controlledBurnOrSmudging: {
        id: 'claim.common.directives.lookups.detail-claim-type.Controlled Burn or Smudging',
        defaultMessage: 'Controlled Burn or Smudging'
    },
    smokeFromNeighboringPremises: {
        id: 'claim.common.directives.lookups.detail-claim-type.Smoke From Neighboring Premises',
        defaultMessage: 'Smoke From Neighboring Premises'
    },
    smokeFromHeatingDevice: {
        id: 'claim.common.directives.lookups.detail-claim-type.Smoke From Heating Device',
        defaultMessage: 'Smoke From Heating Device'
    },
    smoke: {
        id: 'claim.common.directives.lookups.detail-claim-type.Smoke',
        defaultMessage: 'Smoke'
    },
    glassBreakage: {
        id: 'claim.common.directives.lookups.detail-claim-type.Glass Breakage',
        defaultMessage: 'Glass Breakage'
    },
    stoneChip: {
        id: 'claim.common.directives.lookups.detail-claim-type.Stone Chip',
        defaultMessage: 'Stone Chip'
    },
    hail: {
        id: 'claim.common.directives.lookups.detail-claim-type.Hail',
        defaultMessage: 'Hail'
    },
    hailWind: {
        id: 'claim.common.directives.lookups.detail-claim-type.Hail & Wind',
        defaultMessage: 'Hail & Wind'
    },
    iceStorm: {
        id: 'claim.common.directives.lookups.detail-claim-type.Ice Storm',
        defaultMessage: 'Ice Storm'
    },
    snowStormBlizzard: {
        id: 'claim.common.directives.lookups.detail-claim-type.Snow Storm/Blizzard',
        defaultMessage: 'Snow Storm/Blizzard'
    },
    hurricaneTropicalStorm: {
        id: 'claim.common.directives.lookups.detail-claim-type.Hurricane/Tropical Storm',
        defaultMessage: 'Hurricane/Tropical Storm'
    },
    tornado: {
        id: 'claim.common.directives.lookups.detail-claim-type.Tornado',
        defaultMessage: 'Tornado'
    },
    wind: {
        id: 'claim.common.directives.lookups.detail-claim-type.Wind',
        defaultMessage: 'Wind'
    },
    boost: {
        id: 'claim.common.directives.lookups.detail-claim-type.Boost',
        defaultMessage: 'Boost'
    },
    flatTire: {
        id: 'claim.common.directives.lookups.detail-claim-type.Flat Tire',
        defaultMessage: 'Flat Tire'
    },
    lockOutServices: {
        id: 'claim.common.directives.lookups.detail-claim-type.Lock Out Services',
        defaultMessage: 'Lock Out Services'
    },
    roadsideAssistance: {
        id: 'claim.common.directives.lookups.detail-claim-type.Roadside Assistance',
        defaultMessage: 'Roadside Assistance'
    },
    tow: {
        id: 'claim.common.directives.lookups.detail-claim-type.Tow',
        defaultMessage: 'Tow'
    },
    winch: {
        id: 'claim.common.directives.lookups.detail-claim-type.Winch',
        defaultMessage: 'Winch'
    },
    civilDisturbanceRiot: {
        id: 'claim.common.directives.lookups.detail-claim-type.Civil Disturbance/Riot',
        defaultMessage: 'Civil Disturbance/Riot'
    },
    vandalismOrMaliciousActs: {
        id: 'claim.common.directives.lookups.detail-claim-type.Vandalism or Malicious Acts',
        defaultMessage: 'Vandalism or Malicious Acts'
    },
    theft: {
        id: 'claim.common.directives.lookups.detail-claim-type.Theft',
        defaultMessage: 'Theft'
    },
    collisionWithBuildingOrStructure: {
        id: 'claim.common.directives.lookups.detail-claim-type.Collision with Building or Structure',
        defaultMessage: 'Collision with Building or Structure'
    },
    collisionWithCyclist: {
        id: 'claim.common.directives.lookups.detail-claim-type.Collision with Cyclist',
        defaultMessage: 'Collision with Cyclist'
    },
    collisionWithPedestrian: {
        id: 'claim.common.directives.lookups.detail-claim-type.Collision with Pedestrian',
        defaultMessage: 'Collision with Pedestrian'
    },
    collisionWithWildlifeOrDomesticAnimal: {
        id: 'claim.common.directives.lookups.detail-claim-type.Collision with Wildlife or Domestic Animal',
        defaultMessage: 'Collision with Wildlife or Domestic Animal'
    },
    hitAndRun: {
        id: 'claim.common.directives.lookups.detail-claim-type.Hit and Run',
        defaultMessage: 'Hit and Run'
    },
    multiVehicleCollision: {
        id: 'claim.common.directives.lookups.detail-claim-type.Multi-Vehicle Collision',
        defaultMessage: 'Multi-Vehicle Collision'
    },
    singleVehicleCollision: {
        id: 'claim.common.directives.lookups.detail-claim-type.Single Vehicle Collision',
        defaultMessage: 'Single Vehicle Collision'
    },
    nonCollision: {
        id: 'claim.common.directives.lookups.detail-claim-type.Non-Collision',
        defaultMessage: 'Non-Collision'
    },
    onCollision: {
        id: 'claim.common.directives.lookups.detail-claim-type.Non-Collision Damage',
        defaultMessage: 'on-Collision'
    },
    overflowFromBodyOfWater: {
        id: 'claim.common.directives.lookups.detail-claim-type.Overflow from Body of Water',
        defaultMessage: 'Overflow from Body of Water'
    },
    surfaceWater: {
        id: 'claim.common.directives.lookups.detail-claim-type.Surface Water',
        defaultMessage: 'Surface Water'
    },
    water: {
        id: 'claim.common.directives.lookups.detail-claim-type.Water',
        defaultMessage: 'Water'
    },
    aboveGroundWaterNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Above Ground Water - not otherwise classified",
        defaultMessage: "Above Ground Water - not otherwise classified"
    },
    applianceGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Appliance",
        defaultMessage: "Appliance"
    },
    arsonOrSuspectedArsonGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Arson or Suspected Arson",
        defaultMessage: "Arson or Suspected Arson"
    },
    bonfireFirePitEmberAshGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Bonfire/Fire Pit (Ember/Ash)",
        defaultMessage: "Bonfire/Fire Pit (Ember/Ash)"
    },
    boostGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Boost",
        defaultMessage: "Boost"
    },
    breakEnterBurglaryGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Break & Enter (Burglary)",
        defaultMessage: "Break & Enter (Burglary)"
    },
    civilDisturbanceRiotGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Civil Disturbance/Riot",
        defaultMessage: "Civil Disturbance/Riot"
    },
    collapseNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Collapse - not otherwise classified",
        defaultMessage: "Collapse - not otherwise classified"
    },
    collisionWithBuildingOrStructureGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Collision with Building or Structure",
        defaultMessage: "Collision with Building or Structure"
    },
    collisionWithCyclistGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Collision with Cyclist",
        defaultMessage: "Collision with Cyclist"
    },
    collisionWithPedestrianGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Collision with Pedestrian",
        defaultMessage: "Collision with Pedestrian"
    },
    collisionWithWildlifeOrDomesticAnimalGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Collision with Wildlife or Domestic Animal",
        defaultMessage: "Collision with Wildlife or Domestic Animal"
    },
    collisionGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Collision",
        defaultMessage: "Collision"
    },
    comprehensiveNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Comprehensive - not otherwise classified",
        defaultMessage: "Comprehensive - not otherwise classified"
    },
    controlledBurnOrSmudgingGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Controlled Burn or Smudging",
        defaultMessage: "Controlled Burn or Smudging"
    },
    cookingIncidentGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Cooking Incident",
        defaultMessage: "Cooking Incident"
    },
    earthMovementOtherThanEarthquakeGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Earth Movement (other than earthquake)",
        defaultMessage: "Earth Movement (other than earthquake)"
    },
    earthquakeGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Earthquake",
        defaultMessage: "Earthquake"
    },
    electricalFireGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Electrical Fire",
        defaultMessage: "Electrical Fire"
    },
    electronicCircuitryImpairmentGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Electronic Circuitry Impairment",
        defaultMessage: "Electronic Circuitry Impairment"
    },
    escapeCausedByFreezingOfPlumbingSystemHeatingAirConditioningOrFireSuppressionSystemGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Escape caused by Freezing of Plumbing System, Heating, Air Conditioning or Fire Suppression System",
        defaultMessage: "Escape caused by Freezing of Plumbing System, Heating, Air Conditioning or Fire Suppression System"
    },
    escapeFromDomesticWaterContainerGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Escape from Domestic Water Container",
        defaultMessage: "Escape from Domestic Water Container"
    },
    escapeFromNeighboringPremisesGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Escape from Neighboring Premises",
        defaultMessage: "Escape from Neighboring Premises"
    },
    escapeFromPlumbingSystemHeatingAirConditioningOrFireSuppressionSystemGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Escape from Plumbing System, Heating, Air Conditioning or Fire Suppression System",
        defaultMessage: "Escape from Plumbing System, Heating, Air Conditioning or Fire Suppression System"
    },
    escapeFromWatermainGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Escape from Watermain",
        defaultMessage: "Escape from Watermain"
    },
    escapeOverflowFromApplianceOrFixtureGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Escape/Overflow from Appliance or Fixture",
        defaultMessage: "Escape/Overflow from Appliance or Fixture"
    },
    escapeOverflowFromSwimmingPoolGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Escape/Overflow from Swimming Pool",
        defaultMessage: "Escape/Overflow from Swimming Pool"
    },
    explosionGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Explosion",
        defaultMessage: "Explosion"
    },
    fallenTreeBranchGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Fallen Tree/Branch",
        defaultMessage: "Fallen Tree/Branch"
    },
    fireNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Fire - not otherwise classified",
        defaultMessage: "Fire - not otherwise classified"
    },
    fireFollowingEarthquakeGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Fire Following Earthquake",
        defaultMessage: "Fire Following Earthquake"
    },
    fireFromLightningGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Fire From Lightning",
        defaultMessage: "Fire From Lightning"
    },
    fireFromStorageHandlingOfChemicalsFuelsGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Fire from Storage & Handling of Chemicals/Fuels",
        defaultMessage: "Fire from Storage & Handling of Chemicals/Fuels"
    },
    flatTireGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Flat Tire",
        defaultMessage: "Flat Tire"
    },
    fromNeighboringPremisesGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.From Neighboring Premises",
        defaultMessage: "From Neighboring Premises"
    },
    glassBreakageGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Glass Breakage",
        defaultMessage: "Glass Breakage"
    },
    hailWindGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Hail & Wind",
        defaultMessage: "Hail & Wind"
    },
    hailGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Hail",
        defaultMessage: "Hail"
    },
    heatingDeviceGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Heating Device",
        defaultMessage: "Heating Device"
    },
    hitAndRunGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Hit and Run",
        defaultMessage: "Hit and Run"
    },
    humanErrorGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Human Error",
        defaultMessage: "Human Error"
    },
    hurricaneTropicalStormGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Hurricane/Tropical Storm",
        defaultMessage: "Hurricane/Tropical Storm"
    },
    iceStormGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Ice Storm",
        defaultMessage: "Ice Storm"
    },
    landslideSnowslideFollowingEarthquakeGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Landslide/Snowslide following Earthquake",
        defaultMessage: "Landslide/Snowslide following Earthquake"
    },
    lightningNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Lightning - not otherwise classified",
        defaultMessage: "Lightning - not otherwise classified"
    },
    lockOutServicesGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Lock Out Services",
        defaultMessage: "Lock Out Services"
    },
    lostOrMisplacedArticlesGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Lost or Misplaced Articles",
        defaultMessage: "Lost or Misplaced Articles"
    },
    multiVehicleCollisionUpset2OrMoreGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Multi-Vehicle Collision/Upset (2 or more)",
        defaultMessage: "Multi-Vehicle Collision/Upset (2 or more)"
    },
    overflowNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Overflow - not otherwise classified",
        defaultMessage: "Overflow - not otherwise classified"
    },
    overflowFromFreshBodyOfWaterFluvialGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Overflow from Fresh Body of Water (Fluvial)",
        defaultMessage: "Overflow from Fresh Body of Water (Fluvial)"
    },
    overflowFromSaltBodyOfWaterFluvialGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Overflow from Salt Body of Water (Fluvial)",
        defaultMessage: "Overflow from Salt Body of Water (Fluvial)"
    },
    powerSurgeGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Power Surge",
        defaultMessage: "Power Surge"
    },
    roadsideAssistanceNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Roadside Assistance - not otherwise classified",
        defaultMessage: "Roadside Assistance - not otherwise classified"
    },
    robberyBodilyHarmThreatenedBodilyHarmGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Robbery - Bodily Harm/Threatened Bodily Harm",
        defaultMessage: "Robbery - Bodily Harm/Threatened Bodily Harm"
    },
    sandGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Sand",
        defaultMessage: "Sand"
    },
    singleVehicleCollisionUpsetNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Single Vehicle Collision/Upset - not otherwise classified",
        defaultMessage: "Single Vehicle Collision/Upset - not otherwise classified"
    },
    smokeNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Smoke - not otherwise classified",
        defaultMessage: "Smoke - not otherwise classified"
    },
    smokingCigaretteOrOtherGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Smoking (cigarette or other)",
        defaultMessage: "Smoking (cigarette or other)"
    },
    snowStormBlizzardGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Snow Storm/Blizzard",
        defaultMessage: "Snow Storm/Blizzard"
    },
    stoneChipGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Stone Chip",
        defaultMessage: "Stone Chip"
    },
    structuralFailureGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Structural Failure",
        defaultMessage: "Structural Failure"
    },
    surfaceWaterPluvialGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Surface Water (Pluvial)",
        defaultMessage: "Surface Water (Pluvial)"
    },
    terrorismOrActOfWarGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Terrorism or Act of War",
        defaultMessage: "Terrorism or Act of War"
    },
    theftOfEntireVehicleGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Theft of Entire Vehicle",
        defaultMessage: "Theft of Entire Vehicle"
    },
    theftOrAttemptedTheftOfVehicleGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Theft or Attempted Theft of Vehicle",
        defaultMessage: "Theft or Attempted Theft of Vehicle"
    },
    tornadoGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Tornado",
        defaultMessage: "Tornado"
    },
    towGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Tow",
        defaultMessage: "Tow"
    },
    undeterminedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Undetermined",
        defaultMessage: "Undetermined"
    },
    vandalismOrMaliciousActsGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Vandalism or Malicious Acts",
        defaultMessage: "Vandalism or Malicious Acts"
    },
    waterAllOtherGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Water All Other",
        defaultMessage: "Water All Other"
    },
    waterEscapeNotOtherwiseClassifiedGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Water Escape - not otherwise classified",
        defaultMessage: "Water Escape - not otherwise classified"
    },
    waterEnteringThroughRoofRoofOpeningEavestroughDownspoutOrRainwaterLeaderGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Water entering through Roof, Roof Opening, Eavestrough, Downspout or Rainwater Leader",
        defaultMessage: "Water entering through Roof, Roof Opening, Eavestrough, Downspout or Rainwater Leader"
    },
    waterEnteringThroughWallsDoorsWindowsOrWindowWellsGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Water entering through Walls, Doors, Windows or Window Wells",
        defaultMessage: "Water entering through Walls, Doors, Windows or Window Wells"
    },
    weightOfSnowIceGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Weight of Snow/Ice",
        defaultMessage: "Weight of Snow/Ice"
    },
    wildFireGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Wild Fire",
        defaultMessage: "Wild Fire"
    },
    winchGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Winch",
        defaultMessage: "Winch"
    },
    windGroupEvent: {
        id: "claim.common.directives.lookups.peril-group-event.Wind",
        defaultMessage: "Wind"
    },
    pending: {
        id: "claim.common.directives.lookups.claim-type.Pending",
        defaultMessage: "Pending"
    }
});
