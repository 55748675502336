import { defineMessages } from 'react-intl';

export default defineMessages({
    policyChangesTempUnavailable: {
        id: 'common.components.delinquency-modal.Policy Changes Are Temporarily Unavailable',
        defaultMessage: 'Policy Changes Are Temporarily Unavailable'
    },
    paymentIsDue: {
        id: 'common.components.delinquency-modal.Payment is due on your policy in the amount of (amount)',
        defaultMessage: 'Payment is due on your policy in the amount of {amount}. Please make your payment below or give us a call. Online policy changes are temporarily unavailable until payment is made.'
    },
    reachUs: {
        id: 'common.components.delinquency-modal.You can reach us at',
        defaultMessage: 'You can reach us by phone &lt;a href="tel:1-800-640-2920"&gt;1-800-640-2920&lt;/a&gt; Monday to Friday 7:30 am to 7:30 pm PT and Saturday 8:00 am to 4:30 pm PT.'
    },
    payNow: {
        id: 'common.components.delinquency-modal.Pay Now',
        defaultMessage: 'Pay Now'
    },
    close: {
        id: 'common.components.delinquency-modal.Close',
        defaultMessage: 'Close'
    }
});