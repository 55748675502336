import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'jutro-app.components.WawaModal.cancelButtonText',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'jutro-app.components.WawaModal.confirmButtonText',
        defaultMessage: 'Submit',
    },
});
