import _ from 'lodash';
import CONSTANTS from './StringConstants';

export default {
    populateDelinquencyInfo: (billingData) => {
        if (billingData.delinquencyHistory) {
            const delinquencies = _.sortBy(billingData.delinquencyHistory, (delinquency) => {
                return new Date(delinquency.startDate);
            }).reverse();
            // we only care about the most recent delinquency, if one exists
            const hasOpenDelinquency = delinquencies.length > 0 ? delinquencies[0].status === CONSTANTS.DELINQUENCY_STATUS.OPEN : false;
            const type = hasOpenDelinquency ? delinquencies[0].reason : null;
            const startDate = hasOpenDelinquency ? delinquencies[0].startDate : null;
            const targetCancellationDate = hasOpenDelinquency ? delinquencies[0].targetCancellationDate : null;
            billingData.delinquencyInfo = { hasOpenDelinquency: hasOpenDelinquency, type: type, startDate: startDate, targetCancellationDate: targetCancellationDate };
        }
        return billingData;
    },
    filterPaymentInstruments: (paymentInstrumentsData, type) => {
        paymentInstrumentsData = _.filter(paymentInstrumentsData, (item) => {
            if (type) {
                return (item.paymentMethodType && item.paymentMethodType.toLowerCase() === type) && item.description;
            }
            return item.description;
        });
        return paymentInstrumentsData;
    },
    getPaymentInstrumentDisplay: (paymentInstrument, bankAccountTypelist, creditCardTypelist) => {
        let bankAccountType;
        let creditCardType;
        switch (paymentInstrument.paymentMethodType.toLowerCase()) {
            case 'eft':
                bankAccountType = bankAccountTypelist.getCode(paymentInstrument.bankAccountType);
                return `${bankAccountType.name}  ****${paymentInstrument.lastFour}`;
            case 'creditcard':
                creditCardType = creditCardTypelist.getCode(paymentInstrument.creditCardType);
                return `${creditCardType.name}  ****${paymentInstrument.lastFour}`;
            default:
                return paymentInstrument.description;
        }
    },
    populateBillingData: (rawBillingData, termNumber) => {
        let billingData = rawBillingData;
        const allBillingData = rawBillingData;
        if (rawBillingData.renewalPaymentDetails && allBillingData.renewalPaymentDetails.termNumber && allBillingData.renewalPaymentDetails.termNumber.toString() === termNumber.toString()) {
            billingData = allBillingData.renewalPaymentDetails;
            billingData.paymentHistory = allBillingData.paymentHistory;
            billingData.policyId = allBillingData.policyId;
            billingData.accountSummary = allBillingData.accountSummary;
        }

        return billingData;
    }
};