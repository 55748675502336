import queryString from 'query-string';

const CONSTANTS = {
    REGION: 'region',
    CA_SHORT: 'CA',
    OR_SHORT: 'OR',
    CA_LONG: 'California',
    OR_LONG: 'Oregon'
};

/**
 * Function that parses and sets the query param with key "region"
 * @param {*} queryParam '?region=CA'
 */
const setRegion = (queryParam) => {
    const region = queryString.parse(queryParam)?.region;
    if (!region) {
        // default to California
        localStorage.setItem(CONSTANTS.REGION, CONSTANTS.CA_SHORT);
    } else {
        localStorage.setItem(CONSTANTS.REGION, region);
    }
};


/**
 * Function that updates stored region with key "region"
 * @param {*} region 'CA'
 */
const setRegionFromValue = (region) => {
    if (!region) {
        // default to California
        localStorage.setItem(CONSTANTS.REGION, CONSTANTS.CA_SHORT);
    } else {
        localStorage.setItem(CONSTANTS.REGION, region);
    }
};

/**
 * Function to retrieve region from local storage
 * @returns {string} region value
 */
const getRegion = () => {
    const regionValue = localStorage.getItem(CONSTANTS.REGION);

    return regionValue;
};

/**
 * Function to retrieve region from query param
 * @param {*} queryParam '?region=CA'
 * @returns {string} region value
 */
const getRegionByParam = (queryParam) => {
    if(queryParam) {
        const region = queryString.parse(queryParam)?.region;
        return region;
    }
};

/**
 * Function to retrieve region long name from region short name
 * @param {*} region 'CA'
 * @returns {string} long region value name
 */
const getLongNameFromRegion = (region) => {
    return region === CONSTANTS.CA_SHORT ? CONSTANTS.CA_LONG : CONSTANTS.OR_LONG;
};


/**
 * Function to retrieve region long name from local storage
 * @returns {string} long region value name
 */
const getRegionLongName = () => {
    const regionValue = getRegion();
    // eslint-disable-next-line max-len
    const regionLongName = regionValue === CONSTANTS.CA_SHORT ? CONSTANTS.CA_LONG : CONSTANTS.OR_LONG;

    return regionLongName;
};

export default {
    setRegion,
    setRegionFromValue,
    getRegion,
    getRegionByParam,
    getLongNameFromRegion,
    getRegionLongName,
    CONSTANTS
};
