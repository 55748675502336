/* eslint-disable no-secrets/no-secrets */
import React, { useMemo, useContext, useCallback, Fragment } from 'react';
import _ from 'lodash';
import { QuoteClauseTable, WMICHeading } from 'wmic-pe-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Position, PAConstants } from "wmic-pe-portals-utils-js";
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import metadata from './WMICPAPolicyCoverages.metadata.json5';
import messages from './WMICPAPolicyCoverages.messages';

function WMICPAPolicyCoverages(props) {
    const {
        jobVM,
        lineCoverages,
        coverageColumnData,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        readOnly,
    } = props;

    const translator = useContext(TranslatorContext);

    const vehicleAvailableValues = useMemo(() => {
        const vehiclesList = _.get(jobVM, 'lobData.personalAuto.coverables.vehicles.value', []);
        const vehicleTypes = PAConstants.paCoveragesVehicleTypes;
        const filteredVehicles = _.filter(vehiclesList, (vehicle) =>
            vehicleTypes.includes(vehicle.vehicleType.toLowerCase()));

        const vehicleIdsList = [];
        _.each(filteredVehicles, (vehicle) => {
            vehicleIdsList.push({
                code: vehicle.publicID,
                name: `${vehicle.year} ${vehicle.make} ${vehicle.model}`
            });
        });
        return vehicleIdsList;
    }, [jobVM]);

    const getPADriveGovernmentAutomobilesSubElement = useCallback((clause, path) =>
        [{
            component: 'DropdownSelect',
            type: 'field',
            componentProps: {
                label: translator(messages.liabilityProtectionPhysicalDamage),
                availableValues: vehicleAvailableValues,
                path: `${path}.relateToVehiclePublicID_WMIC`,
                value: clause.relateToVehiclePublicID_WMIC,
                onValueChange: changeSubmissionAndSync,
                className: 'wmicMasterInputInputField',
                contentContainerClassName: "wmicMasterInputDropdown",
                labelClassName: 'wmicMasterInputFieldLabel',
                required: true,
            }
        }]
    , [translator, vehicleAvailableValues, changeSubmissionAndSync])

    const getCustomTerms = useCallback((clause, path) => {
        if (clause.publicID === 'PA_03_DGACov' && clause.selected === true) {
            return getPADriveGovernmentAutomobilesSubElement(clause, path);
        }
        return null;
    }, [getPADriveGovernmentAutomobilesSubElement])

    const generateClauseData = () =>
        coverageColumnData.map(({ lob, code }) =>
            ({
                code,
                path: `${lob.path}.coverages.lineCoverages`,
                clauses: lineCoverages.value
            })
        );

    const getPolicyCoverageSections = () => {
        const categoryMap = ['policyLevelCoverages', 'motorcycleCoverages', 'commercialCoverages', 'optionalAccidentBenefitsCoverages'];
        const categorizedCoverages = WMICPACoverageUtil.categorizeLineCoverages(lineCoverages.value, translator);

        return categoryMap.map((categoryKey) => {
            const lineCoverage = _.get(categorizedCoverages, categoryKey);
            const coverageTableData = [{
                data: lineCoverage.coverages,
                tableContent: generateClauseData()
            }]

            return (
                <Fragment>
                    {lineCoverage.coverages.length > 0
                    && lineCoverage.categoryID !== "policyLevelCoverages" &&
                        <WMICHeading
                            variant="h2"
                            underlined
                            bold
                            size="h2"
                            title={lineCoverage.categoryName} />}
                    <QuoteClauseTable
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={readOnly}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        showTooltip={false}
                        getCustomTerms={getCustomTerms}
                    />
                </Fragment>
            )
        });
    }


    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: jobVM
        },
        policyLevelCoveragesAccordionCard: {
            content: getPolicyCoverageSections()
        }
    }

    const resolvers = {
        resolveComponentMap: {
            Accordion
        }
    };

    return (
        <ViewModelForm
            model={jobVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPAPolicyCoverages;
