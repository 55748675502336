import { React } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICAccordionCard, WMICAddressDetails } from 'wmic-pe-components-platform-react';
import { Accordion, InputField } from '@jutro/legacy/components';
import { CONSTANTS, POLICY_DETAILS } from 'wmic-pe-portals-utils-js';
import PropTypes from 'prop-types';
import _ from 'lodash';
import metadata from './WMICAccountDetails.metadata.json5';
import contactTabMetadata from './WMICAccountDetailTabs/WMICContactTab.metadata.json5'
import accountHolderTabMetadata from './WMICAccountDetailTabs/WMICAccountHolderTab.metadata.json5'
import brokerTabMetadata from './WMICAccountDetailTabs/WMICBrokerTab.metadata.json5'
import styles from './WMICAccountDetails.module.scss';
import './WMICAccountDetails.messages';

const WMICAccountDetails = ({ parentNode, pOnValueChange, pOverrides, pResolvers, setComponentValidation, showErrors, contactTypeValue, isSummary, isNewQuote, readOnly }) => {

    const today = new Date();

    const minDOB = {
        year: today.getFullYear() - POLICY_DETAILS.MAXIMUM_LEGAL_AGE,
        month: today.getMonth(),
        day: today.getDate()
    };

    const maxDOB = {
        year: today.getFullYear() - POLICY_DETAILS.MINIMUM_LEGAL_AGE,
        month: today.getMonth(),
        day: today.getDate()
    };

    const accountDetailsOverrides = {
        accountDetailsAccountHolderAccordionCard: {
            content: accountHolderTabMetadata.componentContent.content,
            parentNode,
            showErrors
        },
        accountDetailsContactAccordionCard: {
            content: contactTabMetadata.componentContent.content,
            visible: contactTypeValue === CONSTANTS.Person,
            parentNode,
            showErrors,
        },
        accountDetailsBrokerAccordionCard: {
            content: brokerTabMetadata.componentContent.content,
            parentNode,
            showErrors
        },
        brokerCode: {
            readOnly: isSummary
        },
        personAddressLookup: {
            addressVM : _.get(parentNode, 'primaryAddress'),
            hideButtons : true,
            hideBorder : true,
            readOnly,
            onValidate: setComponentValidation,
            showErrors,
            readOnlyCountry: true
        },
        companyAddressLookup: {
            addressVM : _.get(parentNode, 'primaryAddress'),
            hideButtons : true,
            hideBorder : true,
            readOnly: false,
            onValidate: setComponentValidation,
            showErrors,
            readOnlyCountry: true
        },
        birthDate: {
            maxDate: maxDOB,
            minDate: minDOB
        },
    }

    const overrides = {...accountDetailsOverrides, ...pOverrides};

    const accountDetailsResolvers = {
        resolveCallbackMap : {
        },
        resolveComponentMap: {
            WMICAccordionCard,
            WMICAddressDetails,
            Accordion,
            InputField
        },
        resolveClassNameMap: styles

    }

    const resolvers = {
        resolveCallbackMap : { ...accountDetailsResolvers.resolveCallbackMap, ...pResolvers.resolveCallbackMap },
        resolveComponentMap: { ...accountDetailsResolvers.resolveComponentMap, ...pResolvers.resolveComponentMap },
        resolveClassNameMap: { ...accountDetailsResolvers.resolveClassNameMap, ...pResolvers.resolveClassNameMap }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={parentNode}
                onValueChange={pOnValueChange}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={setComponentValidation}
                showErrors={showErrors}
            />
        </div>
    );
}

WMICAccountDetails.propTypes = {
    parentNode: PropTypes.shape({}).isRequired,
    pOverrides: PropTypes.shape({}).isRequired,
    pResolvers: PropTypes.shape({}).isRequired,
    setComponentValidation: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    contactTypeValue: PropTypes.oneOf([CONSTANTS.Person, CONSTANTS.Company, undefined])
};

WMICAccountDetails.defaultProps = {
    isSummary: false,
    isNewQuote: false
};

export default WMICAccountDetails;
