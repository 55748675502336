/* eslint-disable react/prop-types */
import React, {
    useContext
} from 'react';
import PropTypes from 'prop-types';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNext
} from '@jutro/components';
import { Table, TableColumn } from '@jutro/legacy/datatable';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import messages from './WMICPreviewPaymentsModal.messages';

const WMICPreviewPaymentsModal = ({
    isOpen,
    onResolve,
    payments
}) => {
    const translator = useContext(TranslatorContext);
    const intl = useContext(IntlContext);

    const handleClose = () => {
        onResolve();
    };

    const getDueDateCell = (row, rowIndex) => {
        const dueDate = row.dueDate;
        const dueDateObj = new Date(dueDate.year, dueDate.month, dueDate.day);
        return intl.formatDate(dueDateObj, { year: 'numeric', month: 'numeric', day: 'numeric' })
    };

    const getTypeCell = (row, rowIndex) => {
        return `${row.typeDisplay} - ${row.chargeCategoryDisplay}` ;
    };

    const getAmountCell = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`totalpayment_${rowIndex}`}
                value={row.amount}
                dataType="object"
                showFractions
                readOnly
                hideLabel
            />
        );
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <ModalHeader
                title={translator(messages.previewPayments)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true
                    }
                }}
            />
            <ModalBody>
                <Table
                    data={payments}
                    columnsProportion={[2, 3, 1]}
                    placeholder={messages.emptyPaymentPlans}
                >
                    <TableColumn
                        id="sortDate"
                        textAlign="left"
                        header={messages.sortDate}
                        cell={getDueDateCell}
                    />
                    <TableColumn
                        id="type"
                        textAlign="left"
                        header={messages.type}
                        cell={getTypeCell}
                    />
                    <TableColumn
                        id="amount"
                        textAlign="left"
                        header={messages.amount}
                        cell={getAmountCell}
                    />                
                </Table>
            </ModalBody>
            <ModalFooter>
                <div>
                    <WMICButton
                        onClick={handleClose}
                        type="primary"
                    >
                        {translator(commonMessages.close)}
                    </WMICButton>
                </div>
            </ModalFooter>
        </ModalNext>
    );
};

WMICPreviewPaymentsModal.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default WMICPreviewPaymentsModal;