import { defineMessages } from 'react-intl';

export default defineMessages({
    collisionCoverage: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-detail.Collision Coverage',
        defaultMessage: 'Collision Coverage'
    },
    comprehensiveCoverage: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-detail.Comprehensive Coverage',
        defaultMessage: 'Comprehensive Coverage'
    },
    collisionCoverageDesc: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-detail.Collision coverage helps pay for loss or damage to your vehicle after an accident with another vehicle or object. It pays the lesser amount of either the actual value of your vehicle or the amount of each loss, after applying your deductible.',
        defaultMessage: 'Collision coverage helps pay for loss or damage to your vehicle after an accident with another vehicle or object. It pays the lesser amount of either the actual value of your vehicle or the amount of each loss, after applying your deductible.'
    },
    comprehensiveCoverageDesc: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-detail.Comprehensive coverage helps pay for loss or damage to your vehicle due to non-collision causes such as: fire, theft, earthquake, explosion, glass breakage, riot, vandalism, and wind storm.  It pays the lesser amount of either the actual value of your vehicle or the amount of each loss, after applying your deductible.',
        defaultMessage: 'Comprehensive coverage helps pay for loss or damage to your vehicle due to non-collision causes such as: fire, theft, earthquake, explosion, glass breakage, riot, vandalism, and wind storm.  It pays the lesser amount of either the actual value of your vehicle or the amount of each loss, after applying your deductible.'
    },
    rentalExpenseCoverageHelpsPayFor: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-detail.Rental expense coverage helps pay for a rental vehicle while your vehicle is being repaired due to an incident covered under Collision or Comprehensive coverage.',
        defaultMessage: 'Rental expense coverage helps pay for a rental vehicle while your vehicle is being repaired due to an incident covered under Collision or Comprehensive coverage.'
    },
    rentalExpenseCoverageHelpsPayFor2: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.coverage-detail.Rental expense coverage helps pay for a rental vehicle, public transit, or even rideshare services while your vehicle is being repaired due to an incident covered under Collision or Comprehensive coverage.',
        defaultMessage: 'Rental expense coverage helps pay for a rental vehicle, public transit, or even rideshare services while your vehicle is being repaired due to an incident covered under Collision or Comprehensive coverage.'
    }
});