// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICModalHeader__modalHeader{padding:var(--GW-MODAL-HEADER-PADDING-TOP) var(--GW-MODAL-HEADER-PADDING-HORIZONTAL) var(--GW-MODAL-HEADER-PADDING-BOTTOM)}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICModalHeader/WMICModalHeader.module.scss"],"names":[],"mappings":"AAAA,mCACI,0HAAA","sourcesContent":[".modalHeader {\n    padding: var(--GW-MODAL-HEADER-PADDING-TOP) var(--GW-MODAL-HEADER-PADDING-HORIZONTAL) var(--GW-MODAL-HEADER-PADDING-BOTTOM);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "app__WMICModalHeader__modalHeader"
};
export default ___CSS_LOADER_EXPORT___;
