import appConfig from 'app-config';
const { env } = appConfig;

const { serviceUrls } = appConfig;

function sanitizeHostName(url) {
    // remove the protocol if it exists
    let tempUrl = `${url}`.replace(/(^\w+:|^)\/\//, '');
    // remove trailing slash
    tempUrl = `${tempUrl}`.replace(/(\/){1,}$/, '');

    return tempUrl;
}

export default class PolicyService {
    
    static getIdCard(policyNumber, termNumber, vehicleNumber, authHeader = {}) {
        const params = `jsonrpc=2.0&method=getVehicleDriverDetails&params[]=${policyNumber}&params[]=${termNumber}&params[]=${vehicleNumber}`;

        const sanitizedApiGateway = sanitizeHostName(env.API_GATEWAY_URL);

        return fetch(`${serviceUrls.wallet}createPass`, {
            method: 'POST',
            headers: {
                Authorization: authHeader.Authorization,
                'x-forwarded-host': sanitizedApiGateway,
                'Content-Type': 'application/x-www-form-urlencoded',
                responseType: 'blob'
            },
            body: params
        });
    }

    static getJwtToken(policyNumber, termNumber, vehicleNumber, authHeader = {}) {
        const params = `jsonrpc=2.0&method=getVehicleDriverDetails&params[]=${policyNumber}&params[]=${termNumber}&params[]=${vehicleNumber}`;

        const sanitizedApiGateway = sanitizeHostName(env.API_GATEWAY_URL);

        return fetch(`${serviceUrls.wallet}createPassToken`, {
            method: 'POST',
            headers: {
                Authorization: authHeader.Authorization,
                'x-forwarded-host': sanitizedApiGateway,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params
        });
    }
}