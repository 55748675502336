import { defineMessages } from 'react-intl';

export default defineMessages({
    claimDetailNumber: {
        id: 'claim.views.claim-detail.Claim Details',
        defaultMessage: 'Claim Details ({ num })'
    },
    summaryTab: {
        id: 'claim.views.claim-detail.Summary',
        defaultMessage: 'Summary'
    },
    notesTab: {
        id: 'claim.views.claim-detail.Notes',
        defaultMessage: 'Notes'
    },
    documentsTab: {
        id: 'claim.views.claim-detail.Documents',
        defaultMessage: 'Documents'
    },
    paymentsTab: {
        id: 'claim.views.claim-detail.Payments',
        defaultMessage: 'Payments'
    },
    servicesTab: {
        id: 'claim.views.claim-detail.Services',
        defaultMessage: 'Services'
    },
    policyNumberClaimDetail: {
        id: 'claim.views.claim-detail.Policy',
        defaultMessage: 'Policy'
    },
    policyStatus: {
        id: 'claim.views.claim-detail.Status',
        defaultMessage: 'Status'
    },
    reportedBy: {
        id: 'claim.views.claim-detail.Reported By',
        defaultMessage: 'Reported By'
    },
    accountNumber: {
        id: 'claim.views.claim-detail.Account',
        defaultMessage: 'Account'
    },
    dateOfLossClaimDetail: {
        id: 'claim.views.claim-detail.Date of Loss',
        defaultMessage: 'Date of Loss'
    },
    totalPayments: {
        id: 'claim.views.claim-detail.Paid',
        defaultMessage: 'Paid'
    },
    totalIncurrednet: {
        id: 'claim.views.claim-detail.Net Incurred',
        defaultMessage: 'Net Incurred'
    },
    adjusterTitle: {
        id: 'claim.views.claim-detail.Adjuster',
        defaultMessage: 'Adjuster'
    },
    adjusterName: {
        id: 'claim.views.claim-detail.Name',
        defaultMessage: 'Name'
    },
    adjusterPhone: {
        id: 'claim.views.claim-detail.Phone',
        defaultMessage: 'Phone'
    },
    adjusterAddnoteButton: {
        id: 'claim.views.claim-detail.Add Note',
        defaultMessage: 'Add Note'
    },
    addnoteButton: {
        id: 'claim.views.claim-detail.Note',
        defaultMessage: 'Note'
    },
    partiesInvolved: {
        id: 'claim.views.claim-detail.Parties Involved',
        defaultMessage: 'Parties Involved'
    },
    vendorsInvolved: {
        id: 'claim.views.claim-detail.Vendors involved',
        defaultMessage: 'Vendors Involved'
    },
    involvement: {
        id: 'claim.views.claim-detail.Involvement',
        defaultMessage: 'Involvement'
    },
    vehicle: {
        id: 'claim.views.claim-detail.Vehicle(s)',
        defaultMessage: 'Vehicle(s)'
    },
    make: {
        id: 'claim.directives.pa-vehicle-coverages.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'claim.directives.pa-vehicle-coverages.Model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'claim.directives.pa-vehicle-coverages.Year',
        defaultMessage: 'Year'
    },
    license: {
        id: 'claim.directives.pa-vehicle-coverages.License #',
        defaultMessage: 'License #'
    },
    coveragesFor: {
        id: 'claim.directives.pa-vehicle-coverages.Coverages for',
        defaultMessage: 'Coverages for {vehicleMake} {vehicleModel}'
    },
    type: {
        id: 'claim.directives.pa-vehicle-coverages.Type',
        defaultMessage: 'Type'
    },
    deductible: {
        id: 'claim.directives.pa-vehicle-coverages.Deductible',
        defaultMessage: 'Deductible'
    },
    limittype: {
        id: 'claim.directives.pa-vehicle-coverages.Limit Type',
        defaultMessage: 'Limit Type'
    },
    limitvalue: {
        id: 'claim.directives.pa-vehicle-coverages.Limit Value',
        defaultMessage: 'Limit Value'
    },
    perAccident: {
        id: 'claim.directives.pa-vehicle-coverages.Per Accident',
        defaultMessage: 'Per Accident'
    },
    perPerson: {
        id: 'claim.directives.pa-vehicle-coverages.Per Person',
        defaultMessage: 'Per Person'
    },
    lossLocation: {
        id: 'claim.views.claim-detail.Loss Location',
        defaultMessage: 'Loss Location'
    },
    employerNotified: {
        id: 'claim.views.claim-detail.Date Employer Notified',
        defaultMessage: 'Date Employer Notified'
    },
    daysInHospital: {
        id: 'claim.views.claim-detail.Hospital',
        defaultMessage: 'Days spent in the hospital'
    },
    noNotes: {
        id: 'claim.views.claim-detail.No notes associated with this claim',
        defaultMessage: 'There are no notes associated with this claim.'
    },
    notesAuthor: {
        id: 'claim.directives.claim-notes.Author',
        defaultMessage: 'Author'
    },
    notesDate: {
        id: 'claim.directives.claim-notes.Date / Time',
        defaultMessage: 'Date / Time'
    },
    notesSubject: {
        id: 'claim.directives.claim-notes.Subject',
        defaultMessage: 'Subject'
    },
    notesContent: {
        id: 'claim.directives.claim-notes.Note',
        defaultMessage: 'Note'
    },
    searchPlaceholder: {
        id: 'claim.directives.claim-notes.Search',
        defaultMessage: 'Search'
    },
    createNoteSubject: {
        id: 'claim.views.create-note.Subject',
        defaultMessage: 'Subject'
    },
    createNoteCancel: {
        id: 'claim.views.create-note.Cancel',
        defaultMessage: 'Cancel'
    },
    createNoteSave: {
        id: 'claim.views.create-note.Save',
        defaultMessage: 'Save'
    },
    documentsName: {
        id: 'claim.directives.claim-documents.Name',
        defaultMessage: 'Name'
    },
    documentsDateModified: {
        id: 'claim.directives.claim-documents.Date Modified',
        defaultMessage: 'Date Modified'
    },
    documentsViewandDownload: {
        id: 'claim.directives.claim-documents.View/Download',
        defaultMessage: 'View/Download'
    },
    documentsRemove: {
        id: 'claim.directives.claim-documents.Remove',
        defaultMessage: 'Remove'
    },
    uploadDocuments: {
        id: 'platform.documentupload.templates.document-upload.Upload Documents',
        defaultMessage: 'Upload Documents'
    },
    modalAddress: {
        id: 'platform.contacts.templates.contact-template.Address',
        defaultMessage: 'Address'
    },
    modalPrimary: {
        id: 'platform.contacts.templates.contact-template.primary',
        defaultMessage: 'primary'
    },
    modalPrimaryContact: {
        id: 'platform.contacts.templates.contact-template.Primary Contact',
        defaultMessage: 'Primary Contact'
    },
    modalPhoneNumber: {
        id: 'platform.contacts.templates.contact-template.Phone Number',
        defaultMessage: 'Phone Number'
    },
    modalFaxNumber: {
        id: 'platform.contacts.templates.contact-template.Fax Number',
        defaultMessage: 'Fax Number'
    },
    modalClose: {
        id: 'platform.contacts.templates.contact-template.Close',
        defaultMessage: 'Close'
    },
    modalUnknown: {
        id: 'platform.contacts.Unknown',
        defaultMessage: 'Unknown'
    },
    claimServicesMessage: {
        id: 'claim.views.claim-detail.This tab contains claim-related services such as repairs, replacements or medical treatments.',
        defaultMessage: 'This tab contains claim-related services such as repairs, replacements or medical treatments.',
    },
    statusInProgress: {
        id: 'claim.views.claim-detail.In Progress',
        defaultMessage: 'In Progress',
    },
    statusCompleted: {
        id: 'claim.views.claim-detail.Completed',
        defaultMessage: 'Completed',
    },
    statusOffTrack: {
        id: 'claim.views.claim-detail.Off Track',
        defaultMessage: 'Off Track',
    },
    statusUnknown: {
        id: 'claim.controllers.ClaimDetailsCtrl.Unknown',
        defaultMessage: 'Unknown',
    },
    serviceNumber: {
        id: 'claim.views.claim-detail.Service Number',
        defaultMessage: 'Service Number',
    },
    nextAction: {
        id: 'claim.views.claim-detail.Next Action',
        defaultMessage: 'Next Action',
    },
    service: {
        id: 'claim.views.claim-detail.Service',
        defaultMessage: 'Service',
    },
    vendor: {
        id: 'claim.views.claim-detail.Vendor',
        defaultMessage: 'Vendor',
    },
    expectedCompletionDate: {
        id: 'claim.views.claim-detail.Expected Completion Date',
        defaultMessage: 'Expected Completion Date',
    },
    primaryContact: {
        id: 'claim.views.claim-detail.Primary Contact',
        defaultMessage: 'Primary Contact',
    },
    noClaimServices: {
        id: 'claim.views.claim-detail.There are no service requests associated with this claim.',
        defaultMessage: 'There are no service requests associated with this claim.',
    },
    paymentServicesMessage: {
        id: 'claim.directives.claim-payment-details.This tab lists payment transactions made based on the claim.',
        defaultMessage: 'This tab lists payment transactions made based on the claim.',
    },
    paymentStatus: {
        id: 'claim.directives.claim-payment-details.Status',
        defaultMessage: 'Status',
    },
    scheduledDate: {
        id: 'claim.directives.claim-payment-details.Scheduled Date',
        defaultMessage: 'Scheduled Date',
    },
    issueDate: {
        id: 'claim.directives.claim-payment-details.Issue Date',
        defaultMessage: 'Issue Date',
    },
    grossAmount: {
        id: 'claim.directives.claim-payment-details.Gross Amount',
        defaultMessage: 'Gross Amount',
    },
    checkNumber: {
        id: 'claim.directives.claim-payment-details.Check Number',
        defaultMessage: 'Check Number',
    },
    payee: {
        id: 'claim.directives.claim-payment-details.Payee',
        defaultMessage: 'Payee',
    },
    noClaimPayments: {
        id: 'claim.directives.claim-payment-details.There are no payments associated with this claim.',
        defaultMessage: 'There are no payments associated with this claim.',
    },
    confirmationToRemoveTitle: {
        id: 'claim.directives.ClaimDocuments.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmationToRemoveMessage: {
        id: 'claim.directives.ClaimDocuments.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove {documentname}?'
    },
    removeConfirmationYes: {
        id: 'claim.directives.ClaimDocuments.Yes',
        defaultMessage: 'Yes'
    },
    removeConfirmationNo: {
        id: 'claim.directives.ClaimDocuments.No',
        defaultMessage: 'No'
    },
    deletionFailedTitle: {
        id: 'claim.directives.ClaimDocuments.Document deletion may have failed',
        defaultMessage: 'Document deletion may have failed'
    },
    deletionFailedMessage: {
        id: 'claim.directives.ClaimDocuments.Something may have gone wrong trying to delete your document.',
        defaultMessage: 'Something may have gone wrong trying to delete your document.'
    },
    removeServiceFailedTitle: {
        id: 'claim.directives.ClaimDocuments.Document deletion failed',
        defaultMessage: 'Document deletion failed'
    },
    removeServiceFailedMessage: {
        id: 'claim.directives.ClaimDocuments.Sorry your document could not be deleted at this time',
        defaultMessage: 'Sorry your document could not be deleted at this time'
    },
    noDocumentsMessage: {
        id: 'claim.directives.claim-documents.There are no documents associated with this claim.',
        defaultMessage: 'There are no documents associated with this claim.'
    },
    claimsTitle: {
        id: 'claim.views.claims.Claims',
        defaultMessage: 'Claims'
    },
    line: {
        id: 'claim.views.claims-list.Line',
        defaultMessage: 'Line'
    },
    claimNumber: {
        id: 'claim.views.claims-list.Claim Number',
        defaultMessage: 'Claim Number'
    },
    claimVendors: {
        id: 'claim.views.claims-list.Vendors',
        defaultMessage: 'Vendor(s)'
    },
    claimStatus: {
        id: 'claim.views.claims-list.Status',
        defaultMessage: 'Status'
    },
    closedClaims: {
        id: 'claim.views.claims-list.Closed Claims',
        defaultMessage: 'Closed Claims'
    },
    includeClosedClaims: {
        id: 'claim.views.claims-list.Include Closed Claims',
        defaultMessage: 'Include Closed Claims'
    },
    allClaims: {
        id: 'claim.views.claims-list.All Claims',
        defaultMessage: 'All Claims'
    },
    filters: {
        id: 'claim.views.claims-list.Filters',
        defaultMessage: 'Filters'
    },
    filterBy: {
        id: 'claim.views.claims-list.Filter By',
        defaultMessage: 'Filter By'
    },
    noClaimsResult: {
        id: 'claim.views.claims-list.No Claims',
        defaultMessage: 'There are no claims currently active'
    },
    fileclaim: {
        id: 'claim.views.claims-list.File a Claim',
        defaultMessage: 'File a Claim'
    },
    unableToDeleteClaims: {
        id: 'claim.directives.ClaimDocuments.Unable to delete that document',
        defaultMessage: 'Unable to delete {documentName}'
    },
    loadingPolicyDetails: {
        id: 'claim.views.claim-policy-details.Loading policy details..',
        defaultMessage: 'Loading policy details..'
    },
    errorLoadingPolicyDetails: {
        id: 'claim.views.claim-policy-details.Error loading policy details...',
        defaultMessage: 'Error loading policy details...'
    },
    claimPolicyDetails: {
        id: 'claim.views.claim-policy-details.Policy Details',
        defaultMessage: 'Policy Details : {policyNumber}'
    },
    claimVehicleCoverage: {
        id: 'claim.views.claim-policy-details.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages'
    },

    policyLevelCoverages: {
        id: 'claim.directives.policy-level-coverages.Policy-Level Coverages',
        defaultMessage: 'Policy-Level Coverages'
    },
    limitType: {
        id: 'claim.directives.policy-level-coverages.Limit Type',
        defaultMessage: 'Limit Type'
    },
    limitValue: {
        id: 'claim.directives.policy-level-coverages.Limit Value',
        defaultMessage: 'Limit Value'
    },
    coverage: {
        id: 'claim.directives.policy-level-coverages.Coverage',
        defaultMessage: 'Coverage'
    },
    coveragePerAccident: {
        id: 'claim.directives.policy-level-coverages.Per Accident',
        defaultMessage: 'Per Accident'
    },
    coveragePerPerson: {
        id: 'claim.directives.policy-level-coverages.Per Person',
        defaultMessage: 'Per Person'
    },
    limit: {
        id: 'claim.directives.policy-level-coverages.Limit',
        defaultMessage: 'Limit'
    },
    insured: {
        id: 'claim.views.claim-detail.Insured',
        defaultMessage: 'Insured'
    },
    wmicEarthquake: {
        id: 'wmic.claim.common.lookups.claim-type.Earthquake',
        defaultMessage: 'Earthquake'
    },
    wmicExplosion: {
        id: 'wmic.claim.common.lookups.claim-type.Explosion',
        defaultMessage: 'Explosion'
    },
    wmicFire: {
        id: 'wmic.claim.common.lookups.claim-type.Fire',
        defaultMessage: 'Fire'
    },
    wmicLightning: {
        id: 'wmic.claim.common.lookups.claim-type.Lightning',
        defaultMessage: 'Lightning'
    },
    wmicSmoke: {
        id: 'wmic.claim.common.lookups.claim-type.Smoke',
        defaultMessage: 'Smoke'
    },
    wmicGlass: {
        id: 'wmic.claim.common.lookups.claim-type.Glass',
        defaultMessage: 'Glass'
    },
    wmicHail: {
        id: 'wmic.claim.common.lookups.claim-type.Hail',
        defaultMessage: 'Hail'
    },
    wmicIceSnow: {
        id: 'wmic.claim.common.lookups.claim-type.Ice/Snow',
        defaultMessage: 'Ice/Snow'
    },
    wmicWind: {
        id: 'wmic.claim.common.lookups.claim-type.Wind',
        defaultMessage: 'Wind'
    },
    wmicRoadsideAssistance: {
        id: 'wmic.claim.common.lookups.claim-type.Roadside Assistance',
        defaultMessage: 'Roadside Assistance'
    },
    wmicRiotVandalismMaliciousActs: {
        id: 'wmic.claim.common.lookups.claim-type.Riot, Vandalism or Malicious Acts',
        defaultMessage: 'Riot, Vandalism or Malicious Acts'
    },
    wmicTheft: {
        id: 'wmic.claim.common.lookups.claim-type.Theft',
        defaultMessage: 'Theft'
    },
    wmicCollision: {
        id: 'wmic.claim.common.lookups.claim-type.Collision',
        defaultMessage: 'Collision'
    },
    wmicNonCollision: {
        id: 'wmic.claim.common.lookups.claim-type.Non-Collision Damage',
        defaultMessage: 'Non-Collision'
    },
    wmicWater: {
        id: 'wmic.claim.common.lookups.claim-type.Water',
        defaultMessage: 'Water'
    },
    wmicEquipmentBreakdown: {
        id: 'wmic.claim.common.lookups.claim-type.Equipment Breakdown',
        defaultMessage: 'Equipment Breakdown'
    },
    wmicAircraftVehicleImpact: {
        id: 'wmic.claim.common.lookups.claim-type.Aircraft / Vehicle Impact',
        defaultMessage: 'Aircraft / Vehicle Impact'
    },
    wmicPropertyLossDamage: {
        id: 'wmic.claim.common.lookups.claim-type.Property Loss or Damage',
        defaultMessage: 'Property Loss or Damage'
    },
    wmicOilTank: {
        id: 'wmic.claim.common.lookups.claim-type.Oil Tank',
        defaultMessage: 'Oil Tank'
    },
    wmicDamageCausedBears: {
        id: 'wmic.claim.common.lookups.claim-type.Damage Caused by Bears',
        defaultMessage: 'Damage Caused by Bears'
    },
    wmicDamageCausedPets: {
        id: 'wmic.claim.common.lookups.claim-type.Damage Caused by Pets',
        defaultMessage: 'Damage Caused by Pets'
    },
    wmicFoodFreezerSpoilage: {
        id: 'wmic.claim.common.lookups.claim-type.Food Freezer Spoilage',
        defaultMessage: 'Food Freezer Spoilage'
    },
    wmicServiceLineFailure: {
        id: 'wmic.claim.common.lookups.claim-type.Service Line Failure',
        defaultMessage: 'Service Line Failure'
    },
    wmicInjury: {
        id: 'wmic.claim.common.lookups.claim-type.Injury',
        defaultMessage: 'Injury'
    },
    wmicPropertyDamage: {
        id: 'wmic.claim.common.lookups.claim-type.Property Damage',
        defaultMessage: 'Property Damage'
    },
    wmicBreakEnter: {
        id: 'wmic.claim.common.lookups.claim-type.Break & Enter',
        defaultMessage: 'Break & Enter'
    },
    wmicRobbery: {
        id: 'wmic.claim.common.lookups.claim-type.Robbery',
        defaultMessage: 'Robbery'
    },
    wmicLostMisplacedItems: {
        id: 'wmic.claim.common.lookups.claim-type.Lost or Misplaced Items',
        defaultMessage: 'Lost or Misplaced Items'
    },
    wmicTransportation: {
        id: 'wmic.claim.common.lookups.claim-type.Transportation',
        defaultMessage: 'Transportation'
    },
    wmicIceDam: {
        id: 'wmic.claim.common.lookups.claim-type.Ice Dam',
        defaultMessage: 'Ice Dam'
    },
    wmicSewer: {
        id: 'wmic.claim.common.lookups.claim-type.Sewer',
        defaultMessage: 'Sewer'
    },
    wmicCollapse: {
        id: 'wmic.claim.common.lookups.claim-type.Collapse',
        defaultMessage: 'Collapse'
    },
    wmicCyberAttack: {
        id: 'wmic.claim.common.lookups.claim-type.Cyber Attack',
        defaultMessage: 'Cyber Attack'
    },
    wmicAllOther: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.All Other',
        defaultMessage: 'All Other'
    },
    wmicAnimalCollision: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Animal Collision',
        defaultMessage: 'Animal Collision'
    },
    wmicBurglary: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Burglary',
        defaultMessage: 'Burglary'
    },
    wmicBurglaryTheftPremises: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Burglary/Theft on Premises',
        defaultMessage: 'Burglary/Theft on Premises'
    },
    wmicCollisionLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Collision',
        defaultMessage: 'Collision'
    },
    wmicComprehensiveStruckPedestrian: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Comprehensive - Struck Pedestrian',
        defaultMessage: 'Comprehensive - Struck Pedestrian'
    },
    wmicConflictInterest: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Conflict of Interest',
        defaultMessage: 'Conflict of Interest'
    },
    wmicEarthquakeLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Earthquake',
        defaultMessage: 'Earthquake'
    },
    wmicExplosionLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Explosion',
        defaultMessage: 'Explosion'
    },
    wmicFireLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Fire damage',
        defaultMessage: 'Fire'
    },
    wmicFireLightning: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Fire or Lightning',
        defaultMessage: 'Fire or Lightning'
    },
    wmicFoodFreezerSpoilageLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Food Freezer Spoilage',
        defaultMessage: 'Food Freezer Spoilage'
    },
    wmicGlassLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Glass',
        defaultMessage: 'Glass'
    },
    wmicGlassBreakagePolicyGlassDeductible: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Glass Breakage',
        defaultMessage: 'Glass Breakage (Policy or Glass Deductible)'
    },
    wmicHailDamageLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Hail damage',
        defaultMessage: 'Hail'
    },
    wmicHailLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Hail',
        defaultMessage: 'Hail'
    },
    wmicIceDamLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Ice Dam',
        defaultMessage: 'Ice Dam'
    },
    wmicLiability: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Liability',
        defaultMessage: 'Liability'
    },
    wmicMold: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Mold',
        defaultMessage: 'Mold'
    },
    wmicOther: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Other',
        defaultMessage: 'Other'
    },
    wmicOtherComprehensive: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Other Comprehensive',
        defaultMessage: 'Other Comprehensive'
    },
    wmicOtherSpecifiedPeril: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Other Specified Peril',
        defaultMessage: 'Other Specified Peril'
    },
    wmicRoadsideAssistanceLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Roadside Assistance',
        defaultMessage: 'Roadside Assistance'
    },
    wmicSewerBackup: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Sewer Backup',
        defaultMessage: 'Sewer Backup'
    },
    wmicSmokeLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Smoke',
        defaultMessage: 'Smoke'
    },
    wmicSmokeAsh: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Smoke/Ash',
        defaultMessage: 'Smoke'
    },
    wmicStructuralfailure: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Structural failure',
        defaultMessage: 'Structural failure'
    },
    wmicTheftLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Theft',
        defaultMessage: 'Theft'
    },
    wmicTheftEntireVehicle: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Stolen Vehicle',
        defaultMessage: 'Theft of Entire Vehicle'
    },
    wmicTheftPremises: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Theft off Premises',
        defaultMessage: 'Theft off Premises'
    },
    wmicTheftAttemptedTheft: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Items or parts stolen',
        defaultMessage: 'Theft or Attempted Theft'
    },
    wmicTowing: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Vehicle towed',
        defaultMessage: 'Towing'
    },
    wmicVandalismMaliciousDamage: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Vehicle vandalized',
        defaultMessage: 'Vandalism/Malicious Damage'
    },
    wmicVandalismMaliciousMischief: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Vandalism/Malicious Mischief',
        defaultMessage: 'Vandalism/Malicious Mischief'
    },
    wmicVehicleImpact: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Vehicle Impact',
        defaultMessage: 'Vehicle Impact'
    },
    wmicVoluntaryMedicalPayments: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Medical Payments to Others',
        defaultMessage: 'Voluntary Medical Payments'
    },
    wmicVoluntaryPropertyDamage: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Property Damage to Others',
        defaultMessage: 'Voluntary Property Damage'
    },
    wmicWaterLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Water',
        defaultMessage: 'Water'
    },
    wmicWaterDamage: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Water damage',
        defaultMessage: 'Water'
    },
    wmicWaterEscapeDamage: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Water Damage',
        defaultMessage: 'Water Escape/Damage'
    },
    wmicWeatherRelated: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Weather Related',
        defaultMessage: 'Weather Related'
    },
    wmicWeightIceSnow: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Weight of Ice/Snow',
        defaultMessage: 'Weight of Ice/Snow'
    },
    wmicWindDamage: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Wind damage',
        defaultMessage: 'Wind'
    },
    wmicWindLoss: {
        id: 'wmic.claim.common.lookups.claim-type-of-loss.Wind',
        defaultMessage: 'Wind'
    },
    wmicEarthquakeDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Earthquake',
        defaultMessage: 'Earthquake'
    },
    wmicFireFollowingEarthquake: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Fire Following Earthquake',
        defaultMessage: 'Fire Following Earthquake'
    },
    wmicLandslideSnowslidefollowingEarthquake: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Landslide/Snowslide following Earthquake',
        defaultMessage: 'Landslide/Snowslide following Earthquake'
    },
    wmicExplosionDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Explosion',
        defaultMessage: 'Explosion'
    },
    wmicExplosionTerrorismActWar: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Explosion from Terrorism or Act of War',
        defaultMessage: 'Explosion from Terrorism or Act of War'
    },
    wmicApplianceFire: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Appliance Fire',
        defaultMessage: 'Appliance Fire'
    },
    wmicArsonSuspectedArson: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Arson or Suspected Arson',
        defaultMessage: 'Arson or Suspected Arson'
    },
    wmicBonfireFirePitEmberAsh: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Bonfire/Fire Pit (Ember/Ash)',
        defaultMessage: 'Bonfire/Fire Pit (Ember/Ash)'
    },
    wmicCookingincident: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Cooking incident',
        defaultMessage: 'Cooking incident'
    },
    wmicElectricalFire: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Electrical Fire',
        defaultMessage: 'Electrical Fire'
    },
    wmicFireDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Fire',
        defaultMessage: 'Fire'
    },
    wmicStorageHandlingChemicalsFuels: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Storage or Handling of Chemicals/Fuels',
        defaultMessage: 'Storage or Handling of Chemicals/Fuels'
    },
    wmicFireNeighboringPremises: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Fire From Neighboring Premises',
        defaultMessage: 'Fire From Neighboring Premises'
    },
    wmicFireHeatingDevice: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Fire From Heating Device',
        defaultMessage: 'Fire From Heating Device'
    },
    wmicSmokingCigaretteOther: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Smoking (cigarette or other)',
        defaultMessage: 'Smoking (cigarette or other)'
    },
    wmicWildFire: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Wild Fire',
        defaultMessage: 'Wild Fire'
    },
    wmicFallenTreeBranch: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Fallen Tree/Branch',
        defaultMessage: 'Fallen Tree/Branch'
    },
    wmicFireFromLightning: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Fire From Lightning',
        defaultMessage: 'Fire From Lightning'
    },
    wmicLightningDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Lightning',
        defaultMessage: 'Lightning'
    },
    wmicPowerSurge: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Power Surge',
        defaultMessage: 'Power Surge'
    },
    wmicSmokeAppliance: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Smoke from Appliance',
        defaultMessage: 'Smoke from Appliance'
    },
    wmicControlledBurnSmudging: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Controlled Burn or Smudging',
        defaultMessage: 'Controlled Burn or Smudging'
    },
    wmicSmokeNeighboringPremises: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Smoke From Neighboring Premises',
        defaultMessage: 'Smoke From Neighboring Premises'
    },
    wmicSmokeHeatingDevice: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Smoke From Heating Device',
        defaultMessage: 'Smoke From Heating Device'
    },
    wmicSmokeDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Smoke',
        defaultMessage: 'Smoke'
    },
    wmicGlassBreakage: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Glass Breakage',
        defaultMessage: 'Glass Breakage'
    },
    wmicStoneChip: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Stone Chip',
        defaultMessage: 'Stone Chip'
    },
    wmicHailDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Hail',
        defaultMessage: 'Hail'
    },
    wmicHailWind: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Hail & Wind',
        defaultMessage: 'Hail & Wind'
    },
    wmicIceStorm: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Ice Storm',
        defaultMessage: 'Ice Storm'
    },
    wmicSnowStormBlizzard: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Snow Storm/Blizzard',
        defaultMessage: 'Snow Storm/Blizzard'
    },
    wmicHurricaneTropicalStorm: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Hurricane/Tropical Storm',
        defaultMessage: 'Hurricane/Tropical Storm'
    },
    wmicTornado: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Tornado',
        defaultMessage: 'Tornado'
    },
    wmicWindDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Wind',
        defaultMessage: 'Wind'
    },
    wmicBoost: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Boost',
        defaultMessage: 'Boost'
    },
    wmicFlatTire: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Flat Tire',
        defaultMessage: 'Flat Tire'
    },
    wmicLockOutServices: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Lock Out Services',
        defaultMessage: 'Lock Out Services'
    },
    wmicRoadsideAssistanceDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Roadside Assistance',
        defaultMessage: 'Roadside Assistance'
    },
    wmicTow: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Tow',
        defaultMessage: 'Tow'
    },
    wmicWinch: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Winch',
        defaultMessage: 'Winch'
    },
    wmicCivilDisturbanceRiot: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Civil Disturbance/Riot',
        defaultMessage: 'Civil Disturbance/Riot'
    },
    wmicVandalismMaliciousActs: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Vandalism or Malicious Acts',
        defaultMessage: 'Vandalism or Malicious Acts'
    },
    wmicTheftDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Theft',
        defaultMessage: 'Theft'
    },
    wmicCollisionBuildingStructure: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Collision with Building or Structure',
        defaultMessage: 'Collision with Building or Structure'
    },
    wmicCollisionCyclist: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Collision with Cyclist',
        defaultMessage: 'Collision with Cyclist'
    },
    wmicCollisionPedestrian: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Collision with Pedestrian',
        defaultMessage: 'Collision with Pedestrian'
    },
    wmicCollisionWildlifeDomesticAnimal: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Collision with Wildlife or Domestic Animal',
        defaultMessage: 'Collision with Wildlife or Domestic Animal'
    },
    wmicHitRun: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Hit and Run',
        defaultMessage: 'Hit and Run'
    },
    wmicMultiVehicleCollision: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Multi-Vehicle Collision',
        defaultMessage: 'Multi-Vehicle Collision'
    },
    wmicSingleVehicleCollision: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Single Vehicle Collision',
        defaultMessage: 'Single Vehicle Collision'
    },
    wmicNonCollisionDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Non-Collision',
        defaultMessage: 'Non-Collision'
    },
    wmicNonCollisionDamage: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Non-Collision Damage',
        defaultMessage: 'Non-Collision'
    },
    wmicOverflowBodyWater: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Overflow from Body of Water',
        defaultMessage: 'Overflow from Body of Water'
    },
    wmicSurfaceWater: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Surface Water',
        defaultMessage: 'Surface Water'
    },
    wmicWaterDetailClaim: {
        id: 'wmic.claim.common.lookups.detail-claim-type.Water',
        defaultMessage: 'Water'
    },
    wmicAboveGroundWater: {
        id: 'wmic.claim.common.lookups.peril-group-category.Above Ground Water',
        defaultMessage: 'Above Ground Water'
    },
    wmicAllOtherPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.All Other',
        defaultMessage: 'All Other'
    },
    wmicBelowGroundWater: {
        id: 'wmic.claim.common.lookups.peril-group-category.Below Ground Water',
        defaultMessage: 'Below Ground Water'
    },
    wmicBreakEnterRobbery: {
        id: 'wmic.claim.common.lookups.peril-group-category.Break & Enter or Robbery',
        defaultMessage: 'Break & Enter or Robbery'
    },
    wmicBodilyInjury: {
        id: 'wmic.claim.common.lookups.peril-group-category.Bodily Injury',
        defaultMessage: 'Bodily Injury'
    },
    wmicCollapsePeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Collapse',
        defaultMessage: 'Collapse'
    },
    wmicEarthquakePeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Earthquake',
        defaultMessage: 'Earthquake'
    },
    wmicEquipmentBreakdownPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Equipment Breakdown',
        defaultMessage: 'Equipment Breakdown'
    },
    wmicExplosionPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Explosion',
        defaultMessage: 'Explosion'
    },
    wmicFirePeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Fire',
        defaultMessage: 'Fire'
    },
    wmicGlassPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Glass',
        defaultMessage: 'Glass'
    },
    wmicHailPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Hail',
        defaultMessage: 'Hail'
    },
    wmicIceDamPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Ice Dam',
        defaultMessage: 'Ice Dam'
    },
    wmicIceSnowPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Ice/Snow',
        defaultMessage: 'Ice/Snow'
    },
    wmicImpact: {
        id: 'wmic.claim.common.lookups.peril-group-category.Impact',
        defaultMessage: 'Impact'
    },
    wmicLightningPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Lightning',
        defaultMessage: 'Lightning'
    },
    wmicMysteriousDisappearance: {
        id: 'wmic.claim.common.lookups.peril-group-category.Mysterious Disappearance',
        defaultMessage: 'Mysterious Disappearance'
    },
    wmicRiotVandalismMaliciousActsPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Riot, Vandalism or Malicious Acts',
        defaultMessage: 'Riot, Vandalism or Malicious Acts'
    },
    wmicSewerPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Sewer',
        defaultMessage: 'Sewer'
    },
    wmicSmokePeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Smoke',
        defaultMessage: 'Smoke'
    },
    wmicTheftPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Theft',
        defaultMessage: 'Theft'
    },
    wmicTransportationPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Transportation',
        defaultMessage: 'Transportation'
    },
    wmicWaterAllOther: {
        id: 'wmic.claim.common.lookups.peril-group-category.Water All Other',
        defaultMessage: 'Water All Other'
    },
    wmicWaterEscape: {
        id: 'wmic.claim.common.lookups.peril-group-category.Water Escape',
        defaultMessage: 'Water Escape'
    },
    wmicWindPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Wind',
        defaultMessage: 'Wind'
    },
    wmicRoadsideAssistancePeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Roadside Assistance',
        defaultMessage: 'Roadside Assistance'
    },
    wmicVehicleCollisionDamage: {
        id: 'wmic.claim.common.lookups.peril-group-category.Vehicle Collision Damage',
        defaultMessage: 'Vehicle Collision Damage'
    },
    wmicOverflowBodyWaterFluvial: {
        id: 'wmic.claim.common.lookups.peril-group-category.Overflow from Body of Water (Fluvial)',
        defaultMessage: 'Overflow from Body of Water (Fluvial)'
    },
    wmicPropertyDamagePeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Property Damage',
        defaultMessage: 'Property Damage'
    },
    wmicSurfaceWaterPluvial: {
        id: 'wmic.claim.common.lookups.peril-group-category.Surface Water (Pluvial)',
        defaultMessage: 'Surface Water (Pluvial)'
    },
    wmicVehicleNonCollisionDamage: {
        id: 'wmic.claim.common.lookups.peril-group-category.Vehicle Non-Collision Damage',
        defaultMessage: 'Vehicle Non-Collision Damage'
    },
    wmicCyberAttackPeril: {
        id: 'wmic.claim.common.lookups.peril-group-category.Cyber Attack',
        defaultMessage: 'Cyber Attack'
    },
    wmicAboveGroundWaterNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Above Ground Water - not otherwise classified',
        defaultMessage: 'Above Ground Water - not otherwise classified'
    },
    wmicAppliance: {
        id: 'wmic.claim.common.lookups.peril-group-event.Appliance',
        defaultMessage: 'Appliance'
    },
    wmicArsonSuspectedArsonPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Arson or Suspected Arson',
        defaultMessage: 'Arson or Suspected Arson'
    },
    wmicBonfireFirePitEmberAshPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Bonfire/Fire Pit (Ember/Ash) ',
        defaultMessage: 'Bonfire/Fire Pit (Ember/Ash) '
    },
    wmicBoostPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Boost',
        defaultMessage: 'Boost'
    },
    wmicBreakEnterBurglary: {
        id: 'wmic.claim.common.lookups.peril-group-event.Break & Enter (Burglary)',
        defaultMessage: 'Break & Enter (Burglary)'
    },
    wmicCivilDisturbanceRiotPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Civil Disturbance/Riot',
        defaultMessage: 'Civil Disturbance/Riot'
    },
    wmicCollapseNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Collapse - not otherwise classified',
        defaultMessage: 'Collapse - not otherwise classified'
    },
    wmicCollisionBuildingStructurePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Collision with Building or Structure',
        defaultMessage: 'Collision with Building or Structure'
    },
    wmicCollisionCyclistPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Collision with Cyclist',
        defaultMessage: 'Collision with Cyclist'
    },
    wmicCollisionPedestrianPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Collision with Pedestrian',
        defaultMessage: 'Collision with Pedestrian'
    },
    wmicCollisionWildlifeDomesticAnimalPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Collision with Wildlife or Domestic Animal',
        defaultMessage: 'Collision with Wildlife or Domestic Animal'
    },
    wmicCollisionPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Collision',
        defaultMessage: 'Collision'
    },
    wmicComprehensiveNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Comprehensive - not otherwise classified',
        defaultMessage: 'Comprehensive - not otherwise classified'
    },
    wmicControlledBurnSmudgingPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Controlled Burn or Smudging',
        defaultMessage: 'Controlled Burn or Smudging'
    },
    wmicCookingIncident: {
        id: 'wmic.claim.common.lookups.peril-group-event.Cooking Incident',
        defaultMessage: 'Cooking Incident'
    },
    wmicEarthMovementOtherThanWarthquake: {
        id: 'wmic.claim.common.lookups.peril-group-event.Earth Movement (other than earthquake)',
        defaultMessage: 'Earth Movement (other than earthquake)'
    },
    wmicEarthquakePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Earthquake',
        defaultMessage: 'Earthquake'
    },
    wmicElectricalFirePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Electrical Fire',
        defaultMessage: 'Electrical Fire'
    },
    wmicElectronicCircuitryImpairment: {
        id: 'wmic.claim.common.lookups.peril-group-event.Electronic Circuitry Impairment',
        defaultMessage: 'Electronic Circuitry Impairment'
    },
    wmicEscapeCausedFreezingSystem: {
        id: 'wmic.claim.common.lookups.peril-group-event.Escape caused by Freezing of Plumbing System, Heating, Air Conditioning or Fire Suppression System',
        defaultMessage: 'Escape caused by Freezing of Plumbing System, Heating, Air Conditioning or Fire Suppression System'
    },
    wmicEscapeDomesticWaterContainer: {
        id: 'wmic.claim.common.lookups.peril-group-event.Escape from Domestic Water Container',
        defaultMessage: 'Escape from Domestic Water Container'
    },
    wmicEscapeNeighboringPremises: {
        id: 'wmic.claim.common.lookups.peril-group-event.Escape from Neighboring Premises',
        defaultMessage: 'Escape from Neighboring Premises'
    },
    wmicEscapeSystems: {
        id: 'wmic.claim.common.lookups.peril-group-event.Escape from Plumbing System, Heating, Air Conditioning or Fire Suppression System',
        defaultMessage: 'Escape from Plumbing System, Heating, Air Conditioning or Fire Suppression System'
    },
    wmicEscapeWatermain: {
        id: 'wmic.claim.common.lookups.peril-group-event.Escape from Watermain',
        defaultMessage: 'Escape from Watermain'
    },
    wmicEscapeOverflowApplianceFixture: {
        id: 'wmic.claim.common.lookups.peril-group-event.Escape/Overflow from Appliance or Fixture',
        defaultMessage: 'Escape/Overflow from Appliance or Fixture'
    },
    wmicEscapeOverflowSwimmingPool: {
        id: 'wmic.claim.common.lookups.peril-group-event.Escape/Overflow from Swimming Pool',
        defaultMessage: 'Escape/Overflow from Swimming Pool'
    },
    wmicExplosionPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Explosion',
        defaultMessage: 'Explosion'
    },
    wmicFallenTreeBranchPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Fallen Tree/Branch',
        defaultMessage: 'Fallen Tree/Branch'
    },
    wmicFireNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Fire - not otherwise classified',
        defaultMessage: 'Fire - not otherwise classified'
    },
    wmicFireFollowingEarthquakePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Fire Following Earthquake',
        defaultMessage: 'Fire Following Earthquake'
    },
    wmicFireFromLightningPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Fire From Lightning',
        defaultMessage: 'Fire From Lightning'
    },
    wmicFireStorageHandlingChemicalsFuels: {
        id: 'wmic.claim.common.lookups.peril-group-event.Fire from Storage & Handling of Chemicals/Fuels',
        defaultMessage: 'Fire from Storage & Handling of Chemicals/Fuels'
    },
    wmicFlatTirePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Flat Tire',
        defaultMessage: 'Flat Tire'
    },
    wmicFromNeighboringPremises: {
        id: 'wmic.claim.common.lookups.peril-group-event.From Neighboring Premises',
        defaultMessage: 'From Neighboring Premises'
    },
    wmicGlassBreakagePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Glass Breakage',
        defaultMessage: 'Glass Breakage'
    },
    wmicHailWindPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Hail & Wind',
        defaultMessage: 'Hail & Wind'
    },
    wmicHailPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Hail',
        defaultMessage: 'Hail'
    },
    wmicHeatingDevice: {
        id: 'wmic.claim.common.lookups.peril-group-event.Heating Device',
        defaultMessage: 'Heating Device'
    },
    wmicHitRunPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Hit and Run',
        defaultMessage: 'Hit and Run'
    },
    wmicHumanError: {
        id: 'wmic.claim.common.lookups.peril-group-event.Human Error',
        defaultMessage: 'Human Error'
    },
    wmicHurricaneTropicalStormPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Hurricane/Tropical Storm',
        defaultMessage: 'Hurricane/Tropical Storm'
    },
    wmicIceStormPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Ice Storm',
        defaultMessage: 'Ice Storm'
    },
    wmicLandslideSnowslideEarthquake: {
        id: 'wmic.claim.common.lookups.peril-group-event.Landslide/Snowslide following Earthquake',
        defaultMessage: 'Landslide/Snowslide following Earthquake'
    },
    wmicLightningNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Lightning - not otherwise classified',
        defaultMessage: 'Lightning - not otherwise classified'
    },
    wmicLockOutServicesPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Lock Out Services',
        defaultMessage: 'Lock Out Services'
    },
    wmicLostMisplacedArticles: {
        id: 'wmic.claim.common.lookups.peril-group-event.Lost or Misplaced Articles',
        defaultMessage: 'Lost or Misplaced Articles'
    },
    wmicMultiVehicleCollisionUpset: {
        id: 'wmic.claim.common.lookups.peril-group-event.Multi-Vehicle Collision/Upset (2 or more)',
        defaultMessage: 'Multi-Vehicle Collision/Upset (2 or more)'
    },
    wmicOverflowNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Overflow - not otherwise classified',
        defaultMessage: 'Overflow - not otherwise classified'
    },
    wmicOverflowFreshBodyWaterFluvial: {
        id: 'wmic.claim.common.lookups.peril-group-event.Overflow from Fresh Body of Water (Fluvial)',
        defaultMessage: 'Overflow from Fresh Body of Water (Fluvial)'
    },
    wmicOverflowfromSaltBodyWaterFluvial: {
        id: 'wmic.claim.common.lookups.peril-group-event.Overflow from Salt Body of Water (Fluvial)',
        defaultMessage: 'Overflow from Salt Body of Water (Fluvial)'
    },
    wmicPowerSurgePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Power Surge',
        defaultMessage: 'Power Surge'
    },
    wmicRoadsideAssistanceNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Roadside Assistance - not otherwise classified',
        defaultMessage: 'Roadside Assistance - not otherwise classified'
    },
    wmicRobberyBodilyHarmThreatenedBodilyHarm: {
        id: 'wmic.claim.common.lookups.peril-group-event.Robbery - Bodily Harm/Threatened Bodily Harm',
        defaultMessage: 'Robbery - Bodily Harm/Threatened Bodily Harm'
    },
    wmicSand: {
        id: 'wmic.claim.common.lookups.peril-group-event.Sand',
        defaultMessage: 'Sand'
    },
    wmicSingleVehicleCollisionUpsetNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Single Vehicle Collision/Upset - not otherwise classified',
        defaultMessage: 'Single Vehicle Collision/Upset - not otherwise classified'
    },
    wmicSmokeNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Smoke - not otherwise classified',
        defaultMessage: 'Smoke - not otherwise classified'
    },
    wmicSmokingCigaretteOtherPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Smoking (cigarette or other)',
        defaultMessage: 'Smoking (cigarette or other)'
    },
    wmicSnowStormBlizzardPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Snow Storm/Blizzard',
        defaultMessage: 'Snow Storm/Blizzard'
    },
    wmicStoneChipPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Stone Chip',
        defaultMessage: 'Stone Chip'
    },
    wmicStructuralFailure: {
        id: 'wmic.claim.common.lookups.peril-group-event.Structural Failure',
        defaultMessage: 'Structural Failure'
    },
    wmicSurfaceWaterPluvialPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Surface Water (Pluvial)',
        defaultMessage: 'Surface Water (Pluvial)'
    },
    wmicTerrorismActWar: {
        id: 'wmic.claim.common.lookups.peril-group-event.Terrorism or Act of War',
        defaultMessage: 'Terrorism or Act of War'
    },
    wmicTheftEntireVehiclePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Theft of Entire Vehicle',
        defaultMessage: 'Theft of Entire Vehicle'
    },
    wmicTheftAttemptedTheftVehicle: {
        id: 'wmic.claim.common.lookups.peril-group-event.Theft or Attempted Theft of Vehicle',
        defaultMessage: 'Theft or Attempted Theft of Vehicle'
    },
    wmicTornadoPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Tornado',
        defaultMessage: 'Tornado'
    },
    wmicTowPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Tow',
        defaultMessage: 'Tow'
    },
    wmicUndetermined: {
        id: 'wmic.claim.common.lookups.peril-group-event.Undetermined',
        defaultMessage: 'Undetermined'
    },
    wmicVandalismMaliciousActsPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Vandalism or Malicious Acts',
        defaultMessage: 'Vandalism or Malicious Acts'
    },
    wmicWaterAllOtherPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Water All Other',
        defaultMessage: 'Water All Other'
    },
    wmicWaterEscapeNotOtherwiseClassified: {
        id: 'wmic.claim.common.lookups.peril-group-event.Water Escape - not otherwise classified',
        defaultMessage: 'Water Escape - not otherwise classified'
    },
    wmicWaterEnteringThroughRoof: {
        id: 'wmic.claim.common.lookups.peril-group-event.Water entering through Roof, Roof Opening, Eavestrough, Downspout or Rainwater Leader',
        defaultMessage: 'Water entering through Roof, Roof Opening, Eavestrough, Downspout or Rainwater Leader'
    },
    wmicWaterEnteringThroughWalls: {
        id: 'wmic.claim.common.lookups.peril-group-event.Water entering through Walls, Doors, Windows or Window Wells',
        defaultMessage: 'Water entering through Walls, Doors, Windows or Window Wells'
    },
    wmicWeightSnowIce: {
        id: 'wmic.claim.common.lookups.peril-group-event.Weight of Snow/Ice',
        defaultMessage: 'Weight of Snow/Ice'
    },
    wmicWildFirePerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Wild Fire',
        defaultMessage: 'Wild Fire'
    },
    wmicWinchPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Winch',
        defaultMessage: 'Winch'
    },
    wmicWindPerilGroupEvent: {
        id: 'wmic.claim.common.lookups.peril-group-event.Wind',
        defaultMessage: 'Wind'
    },
    createNoteErrorTitle: {
        id: 'claim.controllers.CreateNoteCtrl.Claim update failed',
        defaultMessage: 'Claim update failed'
    },
    createNoteErrorMessage: {
        id: 'claim.controllers.CreateNoteCtrl.Sorry, we were not able to add a new note to your claim.',
        defaultMessage: 'Sorry, we were not able to add a new note to your claim.'
    }
});
