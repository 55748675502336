import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WMICTransactionDetailsPage from './pages/TransactionDetails/WMICTransactionDetailsPage';
import WMICChangeSummaryPage from './pages/ChangeSummary/WMICChangeSummaryPage';
import WMICPCQuotePage from './pages/Quote/WMICPCQuotePage';
import WMICFutureRenewalModal from './pages/WMICFutureRenewalModal/WMICFutureRenewalModal'
import WMICPolicyChangeContactDetailsPage from './pages/ContactDetails/WMICPolicyChangeContactDetailsPage'

setComponentMapOverrides(
    {
        WMICTransactionDetailsPage: { component: 'WMICTransactionDetailsPage' },
        WMICPolicyChangeContactDetailsPage: { component: 'WMICPolicyChangeContactDetailsPage' },
        WMICChangeSummaryPage: { component: 'WMICChangeSummaryPage' },
        WMICPCQuotePage: { component: 'WMICPCQuotePage' },
        WMICFutureRenewalModal: {component: 'WMICFutureRenewalModal'}
    },
    {
        WMICTransactionDetailsPage,
        WMICPolicyChangeContactDetailsPage,
        WMICChangeSummaryPage,
        WMICPCQuotePage,
        WMICFutureRenewalModal
    }
);

export { default as WMICTransactionDetailsPage } from './pages/TransactionDetails/WMICTransactionDetailsPage';
export { default as WMICPolicyChangeContactDetailsPage } from './pages/ContactDetails/WMICPolicyChangeContactDetailsPage'
export { default as RenewalWarning } from './components/RenewalWarning/RenewalWarning';
export { default as WMICChangeSummaryPage } from './pages/ChangeSummary/WMICChangeSummaryPage';
export { default as WMICPCQuotePage } from './pages/Quote/WMICPCQuotePage';
export { default as WMICFutureRenewalModal } from './pages/WMICFutureRenewalModal/WMICFutureRenewalModal';
export { default as messages } from './PolicyChangeCommon.messages';
