// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICDataView__simpleTableWMIC{--GW-DATA-TABLE-ROW-EVEN-BACKGROUND-COLOR:var(--GW-DATA-TABLE-ROW-ODD-BACKGROUND-COLOR)}.app__WMICDataView__simpleTableWMIC [class*=-even],.app__WMICDataView__simpleTableWMIC div[class*=-odd]{border-bottom:1px solid #ddd}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICDataView/WMICDataView.module.scss"],"names":[],"mappings":"AAIA,oCACE,uFAAA,CAEA,wGACE,4BAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\n.simpleTableWMIC {\n  --GW-DATA-TABLE-ROW-EVEN-BACKGROUND-COLOR: var(--GW-DATA-TABLE-ROW-ODD-BACKGROUND-COLOR);\n\n  div[class*=\"-odd\"],[class*=\"-even\"] {\n    border-bottom: 1px solid $grey-light-color;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simpleTableWMIC": "app__WMICDataView__simpleTableWMIC"
};
export default ___CSS_LOADER_EXPORT___;
