// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICBrokerFeesModal__brokerFeesModal{height:211px;width:537px}.app__WMICBrokerFeesModal__brokerFeesModal svg[class*=jut__ModalHeader__iconInfo]{color:var(--GW-BRAND-COLOR-1)}.app__WMICBrokerFeesModal__brokerFeesModal div[class*=jut__ModalHeader__modalInfo]{background-color:var(--GW-BRAND-COLOR-1)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICBrokerFeesComponent/WMICBrokerFeesModal/WMICBrokerFeesModal.module.scss"],"names":[],"mappings":"AAAA,2CAEE,YAAA,CADA,WACA,CAEA,kFACE,6BAAA,CAGF,mFACE,wCAAA","sourcesContent":[".brokerFeesModal {\n  width: 537px;\n  height: 211px;\n\n  svg[class*='jut__ModalHeader__iconInfo'] {\n    color: var(--GW-BRAND-COLOR-1);\n  }\n\n  div[class*='jut__ModalHeader__modalInfo'] {\n    background-color: var(--GW-BRAND-COLOR-1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brokerFeesModal": "app__WMICBrokerFeesModal__brokerFeesModal"
};
export default ___CSS_LOADER_EXPORT___;
