/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types'; 
import { ModalFooter } from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { useTranslator } from '@jutro/locale';
import { publish } from "@jutro/events";
import messages from './WMICAddPolicyModal.messages';
import styles from './WMICAddPolicyModal.module.scss';

const WMICAddPolicySuccessModal = (props) => {
    const translator = useTranslator();

    const {
        onReject,
        enteredPolicyNumbers,
        foundPolicies,
        onAddMorePolicies
    } = props;

    publish('ENROLLMENT_TRACKING', {name: 'EnrollmentEvent', action: 'linkAPolicy', label: 'pass'});

    return (
        <div className={styles.modal}>
            <h1 className='wmic-modal-header-title'>{translator(messages.addPoliciesHeader)}</h1>
            <div className={styles.wmicAddPolicyModal}>
                <p>{translator(messages.youEnteredTheFollowing, { info: enteredPolicyNumbers.join(', ') })}</p>
                <p>{translator(messages.foundPolicies)}</p>
                <ul>
                    {foundPolicies.map((foundPolicy) => (<li key={foundPolicy.policyNumber}>{foundPolicy.policyNumber}</li>))}
                </ul>
            </div>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject(foundPolicies); }}
                    type="outlined"
                >
                    {translator(messages.close)}
                </WMICButton>
                <WMICButton
                    onClick={() => { onAddMorePolicies(); }}
                    type="primary"
                >
                    {translator(messages.addMorePolicies)}
                </WMICButton>
            </ModalFooter>
        </div>
    );
};

WMICAddPolicySuccessModal.propTypes = {
    onReject: PropTypes.func.isRequired,
    enteredPolicyNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    foundPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAddMorePolicies: PropTypes.func.isRequired
};


export default WMICAddPolicySuccessModal;