/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import messages from './WMICQuoteNumberComponent.messages';

function WMICQuoteNumberComponent() {
    const wizardContext = useContext(WizardContext);
    const translator = useTranslator();
    const quoteNumber = _.get(wizardContext.wizardData.value, 'quoteID', false);
    const quoteNumberDivClass = quoteNumber ? 'quote-number' : 'wmicDisplayNone';

    return (
        <p className={quoteNumberDivClass}>
            {translator(messages.quoteNumber)}
            <span className="highlight"> {quoteNumber}</span>
        </p>
    );
}

export default WMICQuoteNumberComponent;
