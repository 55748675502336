/* eslint-disable no-secrets/no-secrets */
/* eslint-disable no-plusplus */
import React, {
    useState, useEffect, useContext, useCallback, useMemo
} from 'react';
import { Icon } from '@jutro/components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import appConfig from 'app-config';
import { LocalDateUtil } from '@xengage/gw-portals-util-js'
import { PolicyService } from 'gw-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICLink, WMICHeading, WMICIcon, WMICDropdownMenuButton } from 'wmic-pe-components-platform-react';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { ICON_NAMES, TRANSACTION_STATUS, JobType, WMICVariousUtil, DateUtil, WMICLogger, WMICLapseUtil, MODAL_CONSTANTS, CONSTANTS, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { Flex } from '@jutro/layout';
import { DropdownSelectField } from '@jutro/legacy/components';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { isCapabilityEnabled } from 'wmic-pe-portals-config-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICPolicyCancelModal, WMICPolicyChangeUtil } from 'wmic-pe-capability-gateway-common-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICLapseRenewalModal, WMICRevertLapseModal } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import PolicyChangeStartModal from './PolicyChangeStartModal/PolicyChangeStartModal';
import styles from './PolicyWizardPageHeader.module.scss';
import messages from './PolicyWizardPageHeader.messages';

const EDIT_POLICY_OPTIONS = {
    CHANGE: 'change',
    CANCEL: 'cancel',
    LAPSE: 'lapse',
    REVERT: 'revert'
};

const BROKER_MARKET_UNDERWRITER_OPTIONS = {
    UNERDERWRITER_BROKER_MARKET: 'Underwriter (Broker Market)',
    UNERDERWRITER_ASSISTANT_BROKER_MARKET: 'Underwriting Assistant (Broker Market)'
}

const isUnderwriter = (name) => name === BROKER_MARKET_UNDERWRITER_OPTIONS.UNERDERWRITER_BROKER_MARKET;

const isUnderwriterAssistant = (name) => name === BROKER_MARKET_UNDERWRITER_OPTIONS.UNERDERWRITER_ASSISTANT_BROKER_MARKET;

const searchRoles = (roles, ...hasRole) => {
    let found = false;

    const checkRole = (nameOfRole) => {
        let passed = false;

        for (let i = 0, j = hasRole.length - 1; !passed && i <= (j - i); i++) {
            passed = hasRole[i](nameOfRole) || hasRole[j - i](nameOfRole);
        }

        return passed;
    };

    if (roles) {
        for (let i = 0, j = roles.length - 1; !found && i <= (j - i); i++) {
            found = checkRole(roles[i].name) || checkRole(roles[j - i].name);
        }
    }

    return found;
};



const PolicyWizardPageHeader = (props) => {
    const {
        wizardSubmission: { policyVM, selectedPolicyTermNumber },
        isSideHeader,
        displayWizardHeader,
        history,
        location,
        isEditButtonEnabled,
        doesScheduledRenewalExist,
        currentUser,
        setHasLapseStateChangedFlag,
        policyPeriods
    } = props;

    const { showCustom } = useWizardModals();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { RenewalService } = useDependencies('RenewalService');
    const { CancellationService } = useDependencies('CancellationService');
    const { showError, showConfirm } = useWizardModals();
    const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const { currentStep, isSkipping, jumpTo, steps } = useContext(WizardContext);
    const [showRenewalWarning, setShowRenewalWarning] = useState(false);

    const canChangePolicyPeriod = _.get(policyVM, 'latestPeriod.canChange.value');
    const isPolicyPeriodCanceled = _.get(policyVM, 'latestPeriod.canceled.value');

    const selectedTerm = _.get(location, "state.termNumber", selectedPolicyTermNumber ?? 1);
    const policyNumber = _.get(policyVM, 'policyNumber.value')
    const productCode = _.get(policyVM, 'product.productCode.value');
    const transactions = _.get(policyVM, 'jobs.value', []);
    const canEditAccount = WMICUserAccessUtil.canEditAccount(authUserData.roles);

    const {
        lobEndorsementURL,
        endorsementProducts,
        capabilitiesConfig,
    } = appConfig;

    const hasPendingTransactions = useMemo(() => {
        const hasPending = transactions.some((transaction) => (
            [
                TRANSACTION_STATUS.DRAFT,
                TRANSACTION_STATUS.QUOTED,
                TRANSACTION_STATUS.DRAFT_FR,
                TRANSACTION_STATUS.QUOTED_FR_1,
                TRANSACTION_STATUS.QUOTED_FR_2,
            ].includes(transaction.status) &&
            transaction.isBluePass &&
            transaction.termNumber === selectedTerm
        ));

        return hasPending;
    }, [selectedTerm, transactions]);

    const getMaxDate = (txns) => Math.max.apply(null, txns.map((txn) => new Date(txn.closeDate)));

    const isCancellable = useMemo(() => {
        let cancellable = false;

        const cancellations = transactions.filter((transaction) => (transaction.status === TRANSACTION_STATUS.BOUND || transaction.status === TRANSACTION_STATUS.CANCELING)
            && transaction.type === JobType.CANCELLATION
            && transaction.termNumber === selectedTerm);

        if (cancellations.length !== 0) {
            const reinstateOrRewrites = transactions.filter((transaction) => transaction.status === TRANSACTION_STATUS.BOUND && ['Reinstatement', 'Rewrite'].includes(transaction.type));

            if (reinstateOrRewrites.length !== 0) {
                const maxCancelDate = getMaxDate(cancellations);
                const maxReinstateOrRewriteDate = getMaxDate(reinstateOrRewrites);

                cancellable = maxReinstateOrRewriteDate > maxCancelDate;
            } else {
                cancellable = false;
            }
        } else {
            cancellable = true;
        }

        return cancellable;
    }, [selectedTerm, transactions]);

    const hasCanceling = useMemo(() => transactions.some((transaction) => (
        [
            TRANSACTION_STATUS.CANCELING,
            TRANSACTION_STATUS.CANCELING_FR,
        ].includes(transaction.status) &&
        transaction.isBluePass &&
        transaction.termNumber === selectedTerm
    )), [selectedTerm, transactions]);

    const hasUnderwriterBrokerMarketRole = useCallback(() => {
        const currentUserRoles = currentUser.roles;

        return searchRoles(currentUserRoles, isUnderwriter, isUnderwriterAssistant);
    }, [currentUser.roles]);

    const changeable = !hasPendingTransactions || doesScheduledRenewalExist;
    const cancellable = isCancellable && !hasUnderwriterBrokerMarketRole();

    const accountNumber = _.get(policyVM, 'account.accountNumber.value');
    const routePath = `/accounts/${accountNumber}/summary`;

    const checkUpcomingRenewal = useCallback(async () => {
        const hasUpcomingRenewal = await WMICPolicyChangeUtil.hasUpcomingRenewal(
            policyNumber,
            Number(selectedTerm),
            authHeader
        ).catch((error) => {
            WMICLogger.error('CheckUpcomingRenewal failed', error);
        });

        setShowRenewalWarning(hasUpcomingRenewal);
    }, [authHeader, policyNumber, selectedTerm]);

    useEffect(() => {
        if (isSkipping) {
            return;
        }

        checkUpcomingRenewal();
    }, []);

    const accountAddress = _.get(policyVM, 'account.accountHolder.primaryAddress.displayName.value', '');

    const firstName = _.get(policyVM, 'account.accountHolder.firstName.value', '');
    const lastName = _.get(policyVM, 'account.accountHolder.lastName.value', '');
    const displayName = _.get(policyVM, 'account.accountHolder.displayName.value');
    const accountDisplayName = !displayName ? `${firstName} ${lastName}` : displayName;
    const accountName = _.trim(accountDisplayName) || _.get(policyVM, 'account.accountHolder.contactName.value');

    const lobWithPolicyNumber = translator(messages[_.camelCase(productCode)], { policyNumber });

    const getWizardTitleIcon = () => {
        const icon = WMICVariousUtil.getLobIcon(productCode)

        return <WMICIcon
            icon={icon}
            id="lobIcon"
            size="large"
            className={cx("lobIcon", "gw-mr-2", "gw-mt-1")}
        />
    }

    const checkPolicyChangeCanBeStarted = () => EndorsementService.checkPolicyChangeCanBeStarted_WMIC(
        policyNumber,
        selectedTerm,
        authHeader
    );

    const titleContainerClass = cx({
        [styles.gwWizardPageTitle]: !isSideHeader,
        [styles.gwWizardSidePageTitle]: isSideHeader
    });

    const categoryContainerClass = cx({
        [styles.gwWizardPageCategory]: !isSideHeader,
        [styles.gwWizardSidePageCategory]: isSideHeader
    });

    const associatedPageClass = cx({
        [styles.gwWizardPageAssociated]: !isSideHeader,
        [styles.gwWizardSidePageAssociated]: isSideHeader
    });

    const titleSeparator = isSideHeader && (
        <div className={styles.gwWizardSidePageTitleSeparator} />
    );

    const onChangePolicy = async (effectiveDate) => {
        const date = new Date(effectiveDate);
        const year = DateUtil.getYear(date);
        const month = DateUtil.getMonth(date);
        const day = DateUtil.getDate(date);

        const policyChangeInfo = {
            description: '',
            effectiveDate: {year, month, day},
            policyNumber
        };

        const response = await PolicyService.createNewPolicyChangeTransaction(policyChangeInfo, authHeader);

        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode], {
                policyNumber: response.policy.policyNumber,
                selectedTerm,
                policyChangeEntry: {
                    effectiveDate: policyChangeInfo.effectiveDate
                },
            });
        }
    };

    // Initializes the CancellationDTO and opens cancellation modal
    const initCancellationDTO = () => {
        CancellationService.initCancellationDTO(policyNumber, selectedTerm, authHeader).then((initCancellation) => {
            showCustom(
                <WMICPolicyCancelModal policyVM={policyVM} selectedTerm={selectedTerm} authHeader={authHeader} initCancellation={initCancellation} viewModelService={ViewModelService} history={history}
                />
            ).catch(() => {
                _.noop();
            });
        }).catch((error) => {
            WMICErrorHandler.processAsModal(error);
        });
    }

    const lapseReason = translator(messages.lapseReason);
    const lapseTermNumber = WMICLapseUtil.getLapseTermNumber(policyPeriods);

    const lapseData = {
        policyNumber: policyNumber,
        termNumber: lapseTermNumber,
        reason: lapseReason
    };

    const getPolicyDisplayName = () => {
        const productNameDisplay = _.get(policyVM, 'product.value.productName');

        return `${productNameDisplay} ${policyNumber}`;
    };

    const getMailingAddress = () => _.get(policyVM, 'account.accountHolder.primaryAddress.value.displayName');

    const onEditPolicyClick = (editCode) => {
        try {
            switch (editCode) {
                case EDIT_POLICY_OPTIONS.CHANGE:
                    checkPolicyChangeCanBeStarted()
                        .then((result) => {
                            if (!_.isEmpty(result)) {
                                showConfirm({
                                    title: translator({ id: "agent.views.policy-detail.Pending Policy Transaction", defaultMessage: "Pending Policy Transaction" }),
                                    message: result,
                                    icon: MODAL_CONSTANTS.ICON.WARNING,
                                    confirmButtonText: messages.goToTransaction,
                                    cancelButtonText: messages.cancel
                                }).then((goToTransactionResult) => {
                                    if (goToTransactionResult === CONSTANTS.MODAL_RESULT.CONFIRM) {
                                        const indexOfDestinationPage = _.findIndex(
                                            steps,
                                            ({ path }) => path === '/transactions'
                                        );
                                        jumpTo(indexOfDestinationPage);
                                    }
                                });
                            } else {
                                showCustom(<PolicyChangeStartModal minDate={policyVM.value.latestPeriod.policyChangeMinimumEffectiveDate} maxDate={policyVM.value.latestPeriod.policyChangeMaximumEffectiveDate} showRenewalWarning={showRenewalWarning} />).then((modalResponse) => {
                                    if (modalResponse) {
                                        onChangePolicy(modalResponse);
                                    }
                                })
                            }
                        }).catch((err) => {
                            WMICLogger.error('Error checking policy change can be started', err);
                            showError({
                                title: translator({ id: "endorsement.common.views.history.Policy Change Effective Date", defaultMessage: "Policy Change Effective Date" }),
                                message: err?.baseError,
                                icon: MODAL_CONSTANTS.ICON.ERROR
                            });
                        });
                    break;
                case EDIT_POLICY_OPTIONS.CANCEL:
                    initCancellationDTO();
                    break;
                case EDIT_POLICY_OPTIONS.LAPSE:
                    showCustom(<WMICLapseRenewalModal
                        setHasLapseStateChangedFlag={setHasLapseStateChangedFlag}
                        RenewalService={RenewalService}
                        lapseData={lapseData}
                        insured={_.get(policyVM, 'account.accountHolder.value.displayName')}
                        policy={getPolicyDisplayName()}
                        mailingAddress={getMailingAddress()}
                        reason={lapseReason}
                        authHeader={authHeader}
                    />).then(() => {
                        _.noop();
                    });
                    break;
                case EDIT_POLICY_OPTIONS.REVERT:
                    showCustom(<WMICRevertLapseModal
                        setHasLapseStateChangedFlag={setHasLapseStateChangedFlag}
                        RenewalService={RenewalService}
                        lapseData={lapseData}
                        reason={translator(messages.revertLapseReason)}
                        authHeader={authHeader}
                    />).then(() => {
                        _.noop();
                    });
                    break;
                default:
                    break;
            }
        } catch (e) {
            WMICLogger.error('Error checking policy change can be started', e);
            throw new Error(e);
        }
    };

    const editPolicyLinkItems = useMemo(() => {
        const changeOption = { id: EDIT_POLICY_OPTIONS.CHANGE, text: translator(messages.changePolicy), code: EDIT_POLICY_OPTIONS.CHANGE, icon: "fa fa-fw fa-pencil-alt" };
        const cancelOption = { id: EDIT_POLICY_OPTIONS.CANCEL, text: translator(messages.cancelPolicy), code: EDIT_POLICY_OPTIONS.CANCEL, icon: "fa fa-fw fa-times" };
        const lapseOption = { id: EDIT_POLICY_OPTIONS.LAPSE, text: translator(messages.lapsePolicy), code: EDIT_POLICY_OPTIONS.LAPSE, icon: "fa fa-fw fa-calendar-times" };
        const revertOption = { id: EDIT_POLICY_OPTIONS.REVERT, text: translator(messages.revertLapse), code: EDIT_POLICY_OPTIONS.REVERT, icon: "fa fa-fw fa-calendar-times" };
        const editPolicyOptionsArr = [];

        const isPolicyChangeCapabilityEnabled = isCapabilityEnabled({
            capabilitiesConfig,
            capabilityName: 'policychange',
            lob: productCode
        })

        if (
            !isPolicyPeriodCanceled
            && canChangePolicyPeriod
            && changeable
            && isPolicyChangeCapabilityEnabled
        ) {
            editPolicyOptionsArr.push(changeOption);
        }

        if (cancellable) {
            editPolicyOptionsArr.push(cancelOption);
        }

        const lapsePeriod = WMICLapseUtil.getLapsePeriod(policyPeriods, lapseTermNumber);
        if (_.get(lapsePeriod, 'canAddPreRenewalDirection') && !doesScheduledRenewalExist) {
            editPolicyOptionsArr.push(lapseOption);
        }
        if (_.get(lapsePeriod, 'canRemovePreRenewalDirection')) {
            editPolicyOptionsArr.push(revertOption);
        }

        return editPolicyOptionsArr;
    }, [canChangePolicyPeriod, cancellable, capabilitiesConfig, changeable, doesScheduledRenewalExist, isPolicyPeriodCanceled, lapseTermNumber, policyPeriods, productCode, translator]);

    const onTermValueChange = (data) => {
        history.push({
            pathname: `/policies/${policyNumber}/${data}`,
            state: {
                policyNumber,
                termNumber: data
            }
        });
    }

    const getTermAvailableValues = useCallback(() => {
        const policyTerms = _.get(policyVM, 'periods.value', []);
        const validPolicyTerms = policyTerms.filter((term) => !_.isNil(term.termNumber_WMIC));
        const uniqueTerms = [...new Map(validPolicyTerms.map((term) => [term.termNumber_WMIC, term])).values()];

        const displayTerms = uniqueTerms.map((term) => {
            const effectiveDate = DateUtil.getDateFormatMMDDYYYY(LocalDateUtil.toMidnightDate(term.effectiveDate));
            const expirationDate = DateUtil.getDateFormatMMDDYYYY(LocalDateUtil.toMidnightDate(term.expirationDate));

            return {
                code: term.termNumber_WMIC,
                name: `#${term.termNumber_WMIC}: ${effectiveDate} - ${expirationDate}`
            };
        });

        return _.sortBy(displayTerms, ['code']);
    }, [policyVM]);

    const policyHeader = (
        <div className={titleContainerClass}>
            {titleSeparator}
            {displayWizardHeader ? (
                <div>
                    <Flex justifyContent="between">
                        <div className={cx("wizardHeaderJobDetails", { [styles.gwWizardSidepageTitle]: isSideHeader }, 'gw-mb-5')}>
                            {getWizardTitleIcon()}
                            <span>{lobWithPolicyNumber}</span>
                        </div>
                        {isEditButtonEnabled &&
                            <WMICDropdownMenuButton
                                id="editPolicyButton"
                                onItemClick={onEditPolicyClick}
                                items={editPolicyLinkItems}
                                buttonText={translator(messages.editPolicy)}
                                type="primary"
                                disabled={
                                    hasCanceling
                                    // eslint-disable-next-line no-warning-comments
                                    // TODO - the commented conditions below needs to be enabled after implementing lapse renewal
                                    // eslint-disable-next-line spaced-comment
                                    || (!changeable && !cancellable/* && !lapsable && !unlapsable*/)
                                }
                            />
                        }
                    </Flex>

                    <div className={categoryContainerClass}>
                        <div className={cx(styles.accountHolderName)}>{accountName}</div>
                        <p className={styles.accountAddress}>
                            {accountAddress}
                            {canEditAccount && <WMICLink className={associatedPageClass} to={routePath}>
                                <Icon
                                    icon={ICON_NAMES.ICON_EDIT}
                                    id="iconEdit"
                                    size="small"
                                    className="gw-ml-1"
                                />
                                <span className={styles.editAccount}>{translator(messages.editAccount)}</span>
                            </WMICLink>}
                        </p>
                    </div>
                    <Flex justifyContent="between" className={styles.headingUnderline}>
                        <WMICHeading
                            title={translator(currentStep.title)}
                            variant='h1'
                            isPrintablePage={false}
                            underlined={false}
                            className="gw-mb-0"
                        />
                        <div className={styles.termFieldContainer}>
                            <DropdownSelectField
                                id="termDropdownSelectField"
                                labelPosition='left'
                                availableValues={getTermAvailableValues()}
                                disabled={getTermAvailableValues().length === 1}
                                onValueChange={onTermValueChange}
                                value={selectedTerm}
                                label={translator(messages.termTitle)}
                                alwaysShowPlaceholder={false}
                                className={cx('wmicMasterInputInputField')}
                                contentContainerClassName="wmicMasterInputDropdown"
                                labelClassName={cx('wmicMasterInputFieldLabel', styles.termFieldLabel)}
                            />
                        </div>
                    </Flex>
                </div>
            ) : (
                ''
            )}
        </div>
    );

    return policyHeader;
};

PolicyWizardPageHeader.propTypes = {
    wizardSubmission: PropTypes.shape({
        value: PropTypes.shape({}),
        _dtoName: PropTypes.string
    }).isRequired,
    isSideHeader: PropTypes.bool,
    lobFlow: PropTypes.string,
    displayWizardHeader: PropTypes.bool
};

PolicyWizardPageHeader.defaultProps = {
    isSideHeader: false,
    lobFlow: '',
    displayWizardHeader: true
};

export default withRouter(PolicyWizardPageHeader);
