// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__FileUploadViewerComponent__fileUpload{margin-bottom:var(--GW-LAYOUT-6)}.app__FileUploadViewerComponent__policyDocumentsTableNoPadding{padding:0}.app__FileUploadViewerComponent__fileTypeIcon{position:relative;top:var(--GW-SPACING-2)}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policy-common-react/components/PolicyCommonDocuments/FileUploadViewerComponent/FileUploadViewerComponent.module.scss"],"names":[],"mappings":"AACA,4CACI,gCAAA,CAGJ,+DACI,SAAA,CAGJ,8CACI,iBAAA,CACA,uBAAA","sourcesContent":["\n.fileUpload {\n    margin-bottom: var(--GW-LAYOUT-6);\n}\n\n.policyDocumentsTableNoPadding {\n    padding: 0;\n}\n\n.fileTypeIcon {\n    position: relative;\n    top: var(--GW-SPACING-2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUpload": "app__FileUploadViewerComponent__fileUpload",
	"policyDocumentsTableNoPadding": "app__FileUploadViewerComponent__policyDocumentsTableNoPadding",
	"fileTypeIcon": "app__FileUploadViewerComponent__fileTypeIcon"
};
export default ___CSS_LOADER_EXPORT___;
