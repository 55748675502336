import React, { useState, useEffect } from 'react';
import cx from 'classnames'
import _ from 'lodash';

import messages from '../WMICPAVehicleCoverages.messages.js'

import { CheckboxField } from '@jutro/legacy/components';

function WMICPAPFRComponent({ VM, path, readOnly = false }) {
    const [checked, setChecked] = useState();

    useEffect(() => {
        setChecked(_.get(VM, `${path}.value`))
    }, [])

    const handleChange = (value, aPath) => {
        _.set(VM, aPath, value)
        setChecked(value)
    }

    return (
        <div className="gw-pb-4">
            <CheckboxField
                id="PFR_checkbox"
                className={cx(
                    'wmicMasterInputCheckbox',
                    {
                        'checkBoxReadOnly': readOnly,
                        'gw-pl-5': !readOnly
                    }
                )}
                readOnly={readOnly}
                showInlineLabel
                labelClassName={cx('wmicMasterInputFieldLabel')}
                label={messages.proofOfFinancialResponibility}
                value={checked}
                onValueChange={(value, aPath) => handleChange(value, aPath, setChecked)}
                path={path}
            />
        </div>
    );
}

export default WMICPAPFRComponent;
