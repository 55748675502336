/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';

const WMICFailedPaymentModal = (props) => {
    const { onClose } = props;
    const translator = useTranslator();

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(messages.paymentUnsuccessful)}</h3>
            <ModalBody>
                <div>
                    <p className={styles['wmic-payment-text']}>{translator(messages.thePaymentSystemIsCurrentlyUnavailablePleaseChooseAnotherPaymentMethodOrTryAgainLater)}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onClose(); }}
                    type="primary"
                >
                    {translator(messages.close)}
                </WMICButton>
            </ModalFooter>
        </Fragment>
    );
};

WMICFailedPaymentModal.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default WMICFailedPaymentModal;
