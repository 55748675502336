import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-vehicleinfo';
import ConfirmationPage from './pages/Confirmation/ConfirmationPage';
import HOPAPaymentPage from './pages/Payments/HOPAPaymentPage/PaymentPage';
import CPBOPPaymentPage from './pages/Payments/CPBOPPaymentPage/PaymentPage';
import PolicyChangeUtil from './utils/PolicyChangeUtil';
import WMICChangeTypeSelectorPAComponent from './components/WMICChangeTypeSelectorPA/WMICChangeTypeSelectorPAComponent';
import WMICChangeTypeSelectorHOComponent from './components/WMICChangeTypeSelectorHO/WMICChangeTypeSelectorHOComponent';
import WMICCoverageTypeSelectorPAComponent from './components/WMICCoverageTypeSelectorPA/WMICCoverageTypeSelectorPAComponent';
import WMICChangeDeductiblesOrCoveragesPAComponent from './components/WMICChangeDeductiblesOrCoveragesPA/WMICChangeDeductiblesOrCoveragesPAComponent';
import WMICRemoveVehiclePAComponent from './components/WMICRemoveVehiclePA/WMICRemoveVehiclePAComponent';
import WMICChangeDesignatedPersonComponent from './components/WMICChangeDesignatedPerson/WMICChangeDesignatedPersonComponent';
import WMICUpdateFinancePAComponent from './components/WMICUpdateFinancePA/WMICUpdateFinancePAComponent';
import WMICBodilyInjuryComponent from './components/WMICBodilyInjury/WMICBodilyInjuryComponent';
import WMICEditUninsuredComponent from './components/WMICEditUninsured/WMICEditUninsuredComponent';
import WMICChangeAddressViewComponent from './components/address/WMICChangeAddressViewComponent/WMICChangeAddressViewComponent';
import WMICChangeAddressComponent from './components/address/WMICChangeAddressComponent';
import WMICChangeAddressEditComponent from './components/address/WMICChangeAddressEditComponent/WMICChangeAddressEditComponent';
import AddressForm from './components/address/EditAddress/AddressForm/AddressForm';
import WMICChangeDesignatedPersonAddContactComponent from './components/WMICChangeDesignatedPerson/WMICChangeDesignatedPersonAddContactComponent/WMICChangeDesignatedPersonAddContactComponent';
import WMICChangeDesignatedPersonActionComponent from './components/WMICChangeDesignatedPerson/WMICChangeDesignatedPersonActionComponent/WMICChangeDesignatedPersonActionComponent';
import WMICDesignatedPersonDetailsForm from './components/WMICChangeDesignatedPerson/WMICDesignatedPersonDetailsForm/WMICDesignatedPersonDetailsForm';
import WMICReviewPolicyChangePAComponent from './components/WMICReviewPolicyChangePA/WMICReviewPolicyChangePA';
import WMICBoundPolicyChangePA from './components/WMICBoundPolicyChangePA/WMICBoundPolicyChangePA';
import WMICSuccessPolicyChangeComponent from './components/WMICSuccessPolicyChange/WMICSuccessPolicyChangeComponent';
import WMICChangeRoadsideAssistanceComponent from './components/WMICChangeRoadsideAssistance/WMICChangeRoadsideAssistanceComponent';
import WMICChangeRoadsideAssistanceToggle from './components/WMICChangeRoadsideAssistance/WMICChangeRoadsideAssistanceToggle/WMICChangeRoadsideAssistanceToggle';
import WMICRemoveVehicleWizardNavigation  from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardNavigation';
import WMICRemoveVehicleWizardInitialStep  from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleInitialStep';
import WMICRemoveVehicleEffectiveDateStep  from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleEffectiveDateStep';
import WMICRemoveVehicleUsingStep  from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleUsingStep';
import WMICRemoveVehicleAssignPrimaryDriversStep  from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleAssignPrimaryDriversStep';
import WMICRemoveVehicleAssignOccasionalDriversStep  from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleAssignOccasionalDriversStep';
import WMICRemoveVehicleErrorStep  from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleErrorStep';
import WMICRemoveVehiclePALegacyComponent from './components/WMICRemoveVehiclePA/WMICRemoveVehiclePALegacyComponent/WMICRemoveVehiclePAComponent';

setComponentMapOverrides(
    {
        HOPAPaymentPage: { component: 'HOPAPaymentPage' },
        CPBOPPaymentPage: { component: 'CPBOPPaymentPage' },
        ConfirmationPage: { component: 'ConfirmationPage' },
        WMICChangeTypeSelectorPAComponent: { component: 'WMICChangeTypeSelectorPAComponent' },
        WMICChangeTypeSelectorHOComponent: { component: 'WMICChangeTypeSelectorHOComponent' },
        WMICCoverageTypeSelectorPAComponent: { component: 'WMICCoverageTypeSelectorPAComponent' },
        WMICChangeDeductiblesOrCoveragesPAComponent: { component: 'WMICChangeDeductiblesOrCoveragesPAComponent' },
        WMICRemoveVehiclePAComponent: { component: 'WMICRemoveVehiclePAComponent' },
        WMICRemoveVehiclePALegacyComponent: { component: 'WMICRemoveVehiclePALegacyComponent' },
        WMICUpdateFinancePAComponent: { component: 'WMICUpdateFinancePAComponent' },
        WMICBodilyInjuryComponent: { component: 'WMICBodilyInjuryComponent' },
        WMICEditUninsuredComponent: { component: 'WMICEditUninsuredComponent' },
        WMICChangeAddressViewComponent: { component: 'WMICChangeAddressViewComponent' },
        WMICChangeAddressComponent: { component: 'WMICChangeAddressComponent' },
        WMICChangeAddressEditComponent: { component: 'WMICChangeAddressEditComponent' },
        AddressForm: { component: 'AddressForm' },
        WMICChangeDesignatedPersonComponent: { component: 'WMICChangeDesignatedPersonComponent' },
        WMICChangeDesignatedPersonActionComponent: { component: 'WMICChangeDesignatedPersonActionComponent' },
        WMICChangeDesignatedPersonAddContactComponent: { component: 'WMICChangeDesignatedPersonAddContactComponent' },
        WMICDesignatedPersonDetailsForm: { component: 'WMICDesignatedPersonDetailsForm' },
        WMICReviewPolicyChangePAComponent: { component: 'WMICReviewPolicyChangePAComponent' },
        WMICBoundPolicyChangePA: { component: 'WMICBoundPolicyChangePA' },
        WMICSuccessPolicyChangeComponent: { component: 'WMICSuccessPolicyChangeComponent' },
        WMICChangeRoadsideAssistanceComponent: { component: 'WMICChangeRoadsideAssistanceComponent' },
        WMICChangeRoadsideAssistanceToggle: {component: 'WMICChangeRoadsideAssistanceToggle'},
        WMICRemoveVehicleWizardNavigation: {component: 'WMICRemoveVehicleWizardNavigation'},
        WMICRemoveVehicleWizardInitialStep: {component: 'WMICRemoveVehicleWizardInitialStep'},
        WMICRemoveVehicleEffectiveDateStep: {component: 'WMICRemoveVehicleEffectiveDateStep'},
        WMICRemoveVehicleUsingStep: {component: 'WMICRemoveVehicleUsingStep'},
        WMICRemoveVehicleAssignPrimaryDriversStep: {component: 'WMICRemoveVehicleAssignPrimaryDriversStep'},
        WMICRemoveVehicleAssignOccasionalDriversStep: {component: 'WMICRemoveVehicleAssignOccasionalDriversStep'},
        WMICRemoveVehicleErrorStep: {component: 'WMICRemoveVehicleErrorStep'},
    },
    {
        HOPAPaymentPage,
        CPBOPPaymentPage,
        ConfirmationPage,
        WMICChangeTypeSelectorPAComponent,
        WMICChangeTypeSelectorHOComponent,
        WMICCoverageTypeSelectorPAComponent,
        WMICChangeDeductiblesOrCoveragesPAComponent,
        WMICRemoveVehiclePAComponent,
        WMICRemoveVehiclePALegacyComponent,
        WMICUpdateFinancePAComponent,
        WMICBodilyInjuryComponent,
        WMICEditUninsuredComponent,
        WMICChangeAddressViewComponent,
        WMICChangeAddressComponent,
        WMICChangeAddressEditComponent,
        AddressForm,
        WMICChangeDesignatedPersonComponent,
        WMICChangeDesignatedPersonAddContactComponent,
        WMICChangeDesignatedPersonActionComponent,
        WMICDesignatedPersonDetailsForm,
        WMICReviewPolicyChangePAComponent,
        WMICBoundPolicyChangePA,
        WMICSuccessPolicyChangeComponent,
        WMICChangeRoadsideAssistanceComponent,
        WMICChangeRoadsideAssistanceToggle,
        WMICRemoveVehicleWizardNavigation,
        WMICRemoveVehicleWizardInitialStep,
        WMICRemoveVehicleEffectiveDateStep,
        WMICRemoveVehicleUsingStep,
        WMICRemoveVehicleAssignPrimaryDriversStep,
        WMICRemoveVehicleAssignOccasionalDriversStep,
        WMICRemoveVehicleErrorStep,
    }
);
export { PolicyChangeUtil };
export { default as messages } from './PolicyChangeCommon.messages';
export { default as PolicyChange } from './models/PolicyChange';
export { default as ConfirmationQuoteDetailsPage } from './pages/ConfirmationQuoteDetails/ConfirmationQuoteDetailsPage';
export { default as CPBOPPaymentPage } from './pages/Payments/CPBOPPaymentPage/PaymentPage';
export { default as WMICChangeTypeSelectorPAComponent } from './components/WMICChangeTypeSelectorPA/WMICChangeTypeSelectorPAComponent';
export { default as WMICChangeTypeSelectorHOComponent } from './components/WMICChangeTypeSelectorHO/WMICChangeTypeSelectorHOComponent';
export { default as WMICCoverageTypeSelectorPAComponent } from './components/WMICCoverageTypeSelectorPA/WMICCoverageTypeSelectorPAComponent';
export { default as WMICChangeDeductiblesOrCoveragesPAComponent } from './components/WMICChangeDeductiblesOrCoveragesPA/WMICChangeDeductiblesOrCoveragesPAComponent';
export { default as WMICRemoveVehiclePAComponent } from './components/WMICRemoveVehiclePA/WMICRemoveVehiclePAComponent';
export { default as WMICRemoveVehiclePALegacyComponent } from './components/WMICRemoveVehiclePA/WMICRemoveVehiclePALegacyComponent/WMICRemoveVehiclePAComponent';
export { default as WMICUpdateFinancePAComponent } from './components/WMICUpdateFinancePA/WMICUpdateFinancePAComponent';
export { default as WMICBodilyInjuryComponent } from './components/WMICBodilyInjury/WMICBodilyInjuryComponent';
export { default as WMICEditUninsuredComponent } from './components/WMICEditUninsured/WMICEditUninsuredComponent';
export { default as WMICChangeAddressViewComponent } from './components/address/WMICChangeAddressViewComponent/WMICChangeAddressViewComponent';
export { default as WMICChangeAddressEditComponent } from './components/address/WMICChangeAddressEditComponent/WMICChangeAddressEditComponent';
export { default as WMICChangeAddressComponent } from './components/address/WMICChangeAddressComponent';
export { default as AddressForm } from './components/address/EditAddress/AddressForm/AddressForm';
export { default as WMICCoverageComparisonVM } from './components/WMICChangeDeductiblesOrCoveragesPA/WMICViewModels/WMICCoverageComparisonVM';
export { default as WMICPolicyChangesVehicleVM } from './components/WMICChangeDeductiblesOrCoveragesPA/WMICViewModels/WMICPolicyChangesVehicleVM';
export { default as WMICChangeDesignatedPersonComponent } from './components/WMICChangeDesignatedPerson/WMICChangeDesignatedPersonComponent';
export { default as WMICChangeDesignatedPersonAddContactComponent } from './components/WMICChangeDesignatedPerson/WMICChangeDesignatedPersonAddContactComponent/WMICChangeDesignatedPersonAddContactComponent';
export { default as WMICChangeDesignatedPersonActionComponent } from './components/WMICChangeDesignatedPerson/WMICChangeDesignatedPersonActionComponent/WMICChangeDesignatedPersonActionComponent';
export { default as WMICDesignatedPersonDetailsForm } from './components/WMICChangeDesignatedPerson/WMICDesignatedPersonDetailsForm/WMICDesignatedPersonDetailsForm';
export { default as WMICReviewPolicyChangePAComponent } from './components/WMICReviewPolicyChangePA/WMICReviewPolicyChangePA';
export { default as WMICBoundPolicyChangePA } from './components/WMICBoundPolicyChangePA/WMICBoundPolicyChangePA';
export { default as WMICSuccessPolicyChangeComponent } from './components/WMICSuccessPolicyChange/WMICSuccessPolicyChangeComponent';
export { default as WMICChangeRoadsideAssistanceComponent } from './components/WMICChangeRoadsideAssistance/WMICChangeRoadsideAssistanceComponent';
export { default as WMICChangeRoadsideAssistanceToggle } from './components/WMICChangeRoadsideAssistance/WMICChangeRoadsideAssistanceToggle/WMICChangeRoadsideAssistanceToggle';
export { default as WMICRemoveVehicleWizardNavigation } from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardNavigation';
export { default as WMICRemoveVehicleWizardInitialStep } from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleInitialStep';
export { default as WMICRemoveVehicleEffectiveDateStep } from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleEffectiveDateStep';
export { default as WMICRemoveVehicleUsingStep } from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleUsingStep';
export { default as WMICRemoveVehicleAssignPrimaryDriversStep } from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleAssignPrimaryDriversStep';
export { default as WMICRemoveVehicleAssignOccasionalDriversStep } from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleAssignOccasionalDriversStep';
export { default as WMICRemoveVehicleErrorStep } from './components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardSteps/WMICRemoveVehicleErrorStep';
