import React from 'react';

export default function WMICSection({
    ...props
}) {

    return (
        <section
            {...props}
        />
    );
}
