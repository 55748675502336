// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICHODwellingCoverages__dwellingInfoFields{margin:var(--GW-SPACING-6) var(--GW-SPACING-10);padding:var(--GW-LAYOUT-5)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-ho-react/components/WMICHOCoverages/WMICHODwellingCoverages/WMICHODwellingCoverages.module.scss"],"names":[],"mappings":"AAAA,kDACE,+CAAA,CACA,0BAAA","sourcesContent":[".dwellingInfoFields {\n  margin: var(--GW-SPACING-6) var(--GW-SPACING-10);\n  padding: var(--GW-LAYOUT-5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dwellingInfoFields": "app__WMICHODwellingCoverages__dwellingInfoFields"
};
export default ___CSS_LOADER_EXPORT___;
