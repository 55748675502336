// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PaymentComponent__paymentButtonFooter{--gw-payment-button-footer-width:100%;margin-top:var(--GW-LAYOUT-4);text-align:right;width:var(--gw-payment-button-footer-width)}.app__PaymentComponent__paymentButtonStyle{margin-left:var(--GW-LAYOUT-4)}.app__PaymentComponent__paymentCustomFooter{display:flex;justify-content:space-between}h3[class=paymentTitle]{margin-bottom:var(--GW-SPACING-5)}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/PaymentComponent/PaymentComponent.module.scss"],"names":[],"mappings":"AAEA,4CACE,qCAAA,CAIA,6BAAA,CADA,gBAAA,CADA,2CAEA,CAEF,2CACE,8BAAA,CAEF,4CACE,YAAA,CACA,6BAAA,CAGF,uBACE,iCAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n\n.paymentButtonFooter {\n  --gw-payment-button-footer-width: 100%;\n\n  width: var(--gw-payment-button-footer-width);\n  text-align: right;\n  margin-top: var(--GW-LAYOUT-4);\n}\n.paymentButtonStyle {\n  margin-left: var(--GW-LAYOUT-4);\n}\n.paymentCustomFooter {\n  display: flex;\n  justify-content: space-between;\n}\n\nh3[class='paymentTitle'] {\n  margin-bottom: var(--GW-SPACING-5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentButtonFooter": "app__PaymentComponent__paymentButtonFooter",
	"paymentButtonStyle": "app__PaymentComponent__paymentButtonStyle",
	"paymentCustomFooter": "app__PaymentComponent__paymentCustomFooter"
};
export default ___CSS_LOADER_EXPORT___;
