// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICRequiredText__requiredField{font-size:var(--wmic-font-size-subtext)}.app__WMICRequiredText__requiredField:before{color:var(--GW-FIELD-COMPONENT-REQUIRED-SYMBOL-COLOR);content:\"* \";font-weight:var(--GW-FONT-WEIGHT-BOLD)}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICRequiredText/WMICRequiredText.module.scss"],"names":[],"mappings":"AACA,sCACI,uCAAA,CACA,6CAEI,qDAAA,CADA,YAAA,CAEA,sCAAA","sourcesContent":["\n.requiredField {\n    font-size: var(--wmic-font-size-subtext);\n    &:before {\n        content: '* ';\n        color: var(--GW-FIELD-COMPONENT-REQUIRED-SYMBOL-COLOR);\n        font-weight: var(--GW-FONT-WEIGHT-BOLD);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requiredField": "app__WMICRequiredText__requiredField"
};
export default ___CSS_LOADER_EXPORT___;
