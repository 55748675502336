import { defineMessages } from 'react-intl';

export default defineMessages({
    schedOutageOnlineAccountAccess: {
        id: 'portal.views.home-page-layout.Scheduled Outage for Online Account Access',
        defaultMessage: 'Scheduled Outage for Online Account Access'
    },
    accountAccessUpcomingOutage: {
        id: 'portal.views.home-page-layout.Online Account Access Upcoming Outage',
        defaultMessage: 'Our online account access will be unavailable from {outageStart} to {outageEnd}. We apologize for any inconvenience.',
    },
    outagePaymentOptions: {
        id: 'portal.views.home-page-layout.Scheduled Outage for Payment Options',
        defaultMessage: 'Scheduled Outage for Payment Options'
    },
    paymentOptionsUpcomingOutage: {
        id: 'portal.views.home-page-layout.Payment Options Upcoming Outage',
        defaultMessage: 'Our payment options will be unavailable from {outageStart} to {outageEnd}. We apologize for any inconvenience.'
    },
    outageClaim: {
        id: 'portal.views.home-page-layout.Scheduled Outage for Claims',
        defaultMessage: 'Scheduled Outage for Claims'
    },
    claimUpcomingOutage: {
        id: 'portal.views.home-page-layout.Claim Center Upcoming Outage',
        defaultMessage: 'Claim related information will be unavailable from {outageStart} to {outageEnd}. We apologize for any inconvenience.'
    },
    scheduleOutageCreditCardPayment: {
        id: 'portal.views.home-page-layout.Scheduled Outage for Credit Card Payments',
        defaultMessage: 'Scheduled Outage for Credit Card Payments'
    },
    creditCardUpcomingOutage: {
        id: 'portal.views.home-page-layout.Credit Card Payments Upcoming Outage',
        defaultMessage: 'Credit card payments will be unavailable from {outageStart} to {outageEnd}. Bank account payments will be available as an alternative option. We apologize for any inconvenience.'
    },
    scheduleOutageBankAccountPayment: {
        id: 'portal.views.home-page-layout.Scheduled Outage for Bank Account Payments',
        defaultMessage: 'Scheduled Outage for Bank Account Payments'
    },
    bankAccountUpcomingOutage: {
        id: 'portal.views.home-page-layout.Bank Account Payments Upcoming Outage',
        defaultMessage: 'Bank account payments will be unavailable from {outageStart} to {outageEnd}. Credit card payments will be available as an alternative option. We apologize for any inconvenience.'
    },
    paymentOptionsOutage: {
        id: 'portal.views.home-page-layout.Payment Options are Currently Unavailable',
        defaultMessage: 'Payment Options are Currently Unavailable'
    },
    paymentOptionsUnplannedOutage: {
        id: 'portal.views.home-page-layout.Payment Options Unplanned Outage',
        defaultMessage: 'Our payment options are currently unavailable. Please check back at a later time. We apologize for any inconvenience.'
    },
    claimsUnavailable: {
        id: 'portal.views.home-page-layout.Claims are Currently Unavailable',
        defaultMessage: 'Claims are Currently Unavailable'
    },
    claimsUnplannedOutage: {
        id: 'portal.views.home-page-layout.Claim Center Unplanned Outage',
        defaultMessage: 'Claim related information cannot be displayed at this time. We apologize for any inconvenience.'
    },
    creditCardPaymentsUnavailable: {
        id: 'portal.views.home-page-layout.Credit Card Payments are Currently Unavailable',
        defaultMessage: 'Credit Card Payments are Currently Unavailable'
    },
    creditCardPaymentsUnplannedOutage: {
        id: 'portal.views.home-page-layout.Credit Card Payments Unplanned Outage',
        defaultMessage: 'Credit card payments are currently unavailable. Bank account payments are available as an alternative option. We apologize for any inconvenience.'
    },
    bankAccountPaymentsUnavailable: {
        id: 'portal.views.home-page-layout.Bank Account Payments are Currently Unavailable',
        defaultMessage: 'Bank Account Payments are Currently Unavailable'
    },
    bankAccountPaymentUnplannedOutage: {
        id: 'portal.views.home-page-layout.Bank Account Payments Unplanned Outage',
        defaultMessage: 'Bank account payments are currently unavailable. Credit Card payments are available as an alternative payment option. We apologize for any inconvenience.'
    },
    paymentOptionsPlannedOutage: {
        id: 'portal.views.home-page-layout.Payment Options Planned Outage',
        defaultMessage: 'Our payment options will be unavailable from {outageStart} to {outageEnd}. Please check back at a later time. We apologize for any inconvenience.'
    },
    claimPlannedOutage: {
        id: 'portal.views.home-page-layout.Claim Center Planned Outage',
        defaultMessage: 'Claim related information will be unavailable from {outageStart} to {outageEnd}. We apologize for any inconvenience.'
    },
    creditCardPlannedOutage: {
        id: 'portal.views.home-page-layout.Credit Card Payments Planned Outage',
        defaultMessage: 'Credit card payments will be unavailable from {outageStart} to {outageEnd}. Bank account payments are available as an alternative option. We apologize for any inconvenience.'
    },
    bankAccountPaymantsPlannedOutage: {
        id: 'portal.views.home-page-layout.Bank Account Payments Planned Outage',
        defaultMessage: 'Bank account payments will be unavailable from {outageStart} to {outageEnd}. Credit Card payments are available as an alternative payment option. We apologize for any inconvenience.'
    },
    paymentSystemIsCurrentlyUnavailable: {
        id: 'portal.views.home-page-layout.Payment System is Currently Unavailable',
        defaultMessage: 'Payment System is Currently Unavailable'
    },
    paymentSystemUnplannedOutage: {
        id: 'portal.views.home-page-layout.Payment System Unplanned Outage',
        defaultMessage: 'Payment services are unavailable at this time.  Please check back later. We apologize for any inconvenience.'
    },
    scheduledOutageForPaymentSystem: {
        id: 'portal.views.home-page-layout.Scheduled Outage for Payment System',
        defaultMessage: 'Scheduled Outage for Payment System'
    },
    paymentSystemUpcomingOutage: {
        id: 'portal.views.home-page-layout.Payment System Upcoming Outage',
        defaultMessage: 'Payment services will be unavailable from {outageStart} to {outageEnd}. We apologize for any inconvenience.'
    }
});