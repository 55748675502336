export default class AssistedPolicyChangeModel {
    constructor({
        policyNumber, changes, requesterName, requesterEmail
    }) {
        this.policyNumber = policyNumber;
        this.changes = changes;
        this.requesterName = requesterName;
        this.requesterEmail = requesterEmail;
    }

    static getChangeType(changeType) {
        return AssistedPolicyChangeModel.changeTypes[changeType];
    }
}

AssistedPolicyChangeModel.changeTypes = {
    'change-address': 'AddressChange',
    'payment-plan': 'ChangePaymentPlan',
    'change-coverages': 'ChangeCoverages',
    'finance-leasing-add': 'FinanceInstitutionAdd',
    'finance-leasing-change': 'FinanceInstitutionChange',
    'finance-leasing-delete': 'FinanceInstitutionDelete',
    'finance-leasing-send': 'FinanceInstitutionSend',
    'condo-questionnaire-review': 'CondRenewalQuestionnaire',
    'delete-vehicle': 'DeleteVehicle',
    DesignateAdditionalPersonModel: 'DesignatedAddPerson',
    'edit-uninsured': 'EditUninsuredCoverages',
    'bodily-injury': 'PolicyLevelCoverages',
    'designated-person-add': 'DesignatedAddPersonAdd',
    'designated-person-change': 'DesignatedAddPersonChange',
    'designated-person-delete': 'DesignatedAddPersonDelete',
    'roadside-assistance-add': 'ChangeRoadsideAssistance',
    'roadside-assistance-delete': 'ChangeRoadsideAssistance'
};
