/* eslint-disable max-len */
import _ from 'lodash';
import { EndorsementService } from 'gw-capability-policychange';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { PolicyChange, WMICCoverageComparisonVM } from 'gw-capability-policychange-common-react';
import { MESSAGE_TYPES } from "gw-capability-policychange-common-react/components/WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardConsts.js";
import { publish } from "@jutro/events";

const EDITABLE_VEHICLE_COVERAGES = [
    CONSTANTS.COVERAGE_NAMES.PA_COMPREHENSIVE.toLowerCase(),
    CONSTANTS.COVERAGE_NAMES.PA_COLLISION.toLowerCase(),
    CONSTANTS.COVERAGE_NAMES.PA_RENTAL.toLowerCase()
];

const getEditableVehicleCoveragesArray = () => EDITABLE_VEHICLE_COVERAGES;

const getEditableVehicleCoverages = (coverages, originalCoverages) => {
    const returnCoverages = { coverages: [], originalCoverages: [] };

    if (coverages) {
        _.each(coverages.value, (coverage, index) => {
            const coverageID = coverage.publicID.toLowerCase();
            if (getEditableVehicleCoveragesArray().includes(coverageID)) {
                returnCoverages.coverages.push(coverages.findFirstElement(coverages.value[index]));
            }
        });

        returnCoverages.coverages = _.sortBy(returnCoverages.coverages, (x) => {
            return EDITABLE_VEHICLE_COVERAGES.indexOf(x.publicID.value.toLowerCase());
        });

        if (originalCoverages) {
            _.each(originalCoverages.value, (coverage, index) => {
                const coverageID = coverage.publicID.toLowerCase();
                if (getEditableVehicleCoveragesArray().includes(coverageID)) {
                    returnCoverages.originalCoverages.push(originalCoverages.findFirstElement(originalCoverages.value[index]));
                }
            });

            returnCoverages.originalCoverages = _.sortBy(returnCoverages.originalCoverages, (x) => {
                EDITABLE_VEHICLE_COVERAGES.indexOf(x.publicID.value.toLowerCase());
            });
        }
    }

    return returnCoverages;
};

const startPolicyChange = (policyNumber, termNumber, viewModelService, policyChangeType, authHeader) => {
    const promise = new Promise((resolve, reject) => {
        // Need to create the policyChangeDTO from the backend as it will contain
        // all the base data (the current in force policy) which is required
        // for the policy change.

        EndorsementService.loadPolicyChange_WMIC(policyNumber, policyChangeType, authHeader).then(
            (model) => {
                let newPolicyChange = new PolicyChange(model);
                if (newPolicyChange.exceptions) {
                    // Need to handle this
                    reject(newPolicyChange.exceptions);
                }

                if (model.termNumber > termNumber) {
                    reject(new Error(MESSAGE_TYPES.LAST_EFFECTIVE_DAY_OF_TERM_BEFORE_RENEWAL));
                    publish('POLICY_CHANGE_TRACKING', {name: 'PolicyChangeEvent', action: 'Remove Vehicle - Renewal Error', label: 'fail'});
                }

                const view = viewModelService.create(newPolicyChange, 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                
                // do not allow the user to continue as there are DTO issues
                if (view.aspects.subtreeValid && view.aspects.valid) {
                    EndorsementService.saveEndorsement_WMIC(newPolicyChange, authHeader).then((result) => {
                        newPolicyChange = new PolicyChange(result);
                        resolve({ policyChange: newPolicyChange });
                    }).catch((err) => {
                        reject(err);
                    });
                } else {
                    reject(new Error('Cannot start policy change'));
                }
            }
        ).catch((err) => {
            reject(err);
        });
    });
    return promise;
};

const notTaken = (jobNumber, authHeader) => {
    const promise = new Promise((resolve, reject) => {
        const notTakenPromise = EndorsementService.notTaken_WMIC(jobNumber, authHeader);
        notTakenPromise.then((policyChange) => {
            const newPolicyChange = new PolicyChange(policyChange);
            resolve({ policyChange: newPolicyChange });
        }).catch((err) => {
            reject(err);
        });
    });

    return promise;
};

const updateCoverageTermChanges = (originalCoverageTerm, newCoverageTerm, coverageTermChanges) => {
    if (originalCoverageTerm) {
        const coverageTermChange = _.find(coverageTermChanges, {
            coverageTermPatternCode: newCoverageTerm.patternCode
        });
        if (coverageTermChange) {
            coverageTermChange.originalCoverageTermValue = originalCoverageTerm.chosenTermValue;
            coverageTermChange.newCoverageTermValue = newCoverageTerm.chosenTermValue;
        } else {
            if (!coverageTermChanges) {
                // eslint-disable-next-line no-param-reassign
                coverageTermChanges = [];
            }
            const coverageComparisonVM = new WMICCoverageComparisonVM();
            coverageComparisonVM.coveragePublicId = newCoverageTerm.coveragePublicID;
            coverageComparisonVM.coverageTermPatternCode = newCoverageTerm.patternCode;
            coverageComparisonVM.originalCoverageTermValue = originalCoverageTerm.chosenTermValue;
            coverageComparisonVM.newCoverageTermValue = newCoverageTerm.chosenTermValue;
            coverageComparisonVM.newlyUpdated = true;
            coverageTermChanges.push(coverageComparisonVM);
        }
    }
    return coverageTermChanges;
};

const getPolicyChangeModelByJobNumber = (jobNumber, viewModelService, authHeader) => {
    const promise = new Promise((resolve, reject) => {
        EndorsementService.getPolicyChangeByJobNumber_WMIC(jobNumber, authHeader).then(
            (model) => {
                const newPolicyChange = new PolicyChange(model);
                const view = viewModelService.create(newPolicyChange, 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                resolve(view);
            }
        ).catch((err) => {
            reject(err);
        });
    });
    return promise;
};

const bindPolicyChange = (jobNumber, paymentDetails, policyChangeType, authHeader) => {
    const promise = new Promise((resolve, reject) => {
        const bindPromise = EndorsementService.bind_WMIC(jobNumber, paymentDetails, policyChangeType, authHeader);
        bindPromise.then((model) => {
            resolve(model);
        }).catch((err) => {
            reject(err);
        });
    });

    return promise;
};

const bindWithRenewal = (jobNumber, paymentDetails, notes, policyChangeType, authHeader) => {
    const promise = new Promise((resolve, reject) => {
        const bindPromise = EndorsementService.bindWithRenewal_WMIC(jobNumber, paymentDetails, true, notes, policyChangeType, authHeader);
        bindPromise.then((model) => {
            resolve(model);
        }).catch((err) => {
            reject(err);
        });
    });

    return promise;
};

export default {
    getEditableVehicleCoverages,
    startPolicyChange,
    updateCoverageTermChanges,
    notTaken,
    getPolicyChangeModelByJobNumber,
    bindPolicyChange,
    bindWithRenewal
};