import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalNext } from '@jutro/components/widgets/modal-next/ModalNext';
import { ModalFooter } from '@jutro/components/widgets/modal-next/ModalFooter/ModalFooter';
import cx from 'classnames';
import { Flex, FlexItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { WMICDocumentDownloadButton } from 'gw-capability-document-react';
import { WMICButton } from 'wmic-components-platform-react';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { PolicyService } from 'gw-capability-policy';
import pdfImg from 'wmic-amp-digital-theme-styles/images/policy/poi-pdf.gif';
import walletImg from 'wmic-amp-digital-theme-styles/images/policy/poi-wallet.gif';
import messages from './WMICInsuranceCardModal.messages';
import styles from './WMICInsuranceCardModal.module.scss';
import WMICIDCardOption from './WMICIDCardOption';

const WMICInsuranceCardModal = ({
    isOpen,
    onResolve,
    onReject,
    documents,
    walletPassAvailable,
    authHeader
}) => {
    const translator = useTranslator();
    const [emailSent, setEmailSent] = useState(false);

    const onSendViaEmail = () => {
        const request = {
            docId: documents.proofOfInsuranceDocument ? documents.proofOfInsuranceDocument.id : undefined,
            token: documents.proofOfInsuranceDocument ? documents.proofOfInsuranceDocument.sessionID : undefined
        };
        
        PolicyService.sendDocumentViaEmail_WMIC(request, authHeader)
            .then((response = { failReasons: [] }) => {
                if (response.failReasons && response.failReasons.length > 0) {
                    onResolve(CONSTANTS.ERROR);
                } else {
                    setEmailSent(true);
                }
            }).catch(() => {
                onResolve(CONSTANTS.ERROR);
            });
    };

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext
            isOpen={isOpen}
            className={styles.appropriateDesktopWidth}
            onRequestClose={handleClose} 
            shouldCloseOnEsc
            overlayClassName={styles['ww-payment-modal-overlay']}
            contentLayout={{
                component: 'Grid',
                componentProps: {
                    rows: ['auto'],
                },
            }}
        >
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <h3>{translator(messages.insuranceIDCard)}</h3>
                    <p>{translator(messages.chooseHowToReceiveID)}</p>
                </div>
                <Flex
                    wrap={false}
                    className={styles.flexContent}
                >
                    {walletPassAvailable &&
                    <>
                        <FlexItem className={styles.optionSection} shrink={1}>
                            <WMICIDCardOption
                                icon={<img src={walletImg} alt="wallet" />}
                                title={messages.digitalWallet}
                                subtitle={messages.digitalWalletDescription}
                                buttons={[
                                    {
                                        onClick: () => {
                                            onResolve(CONSTANTS.DEVICE_TYPES.IOS);
                                        },
                                        children: messages.iosCustomers,
                                    },
                                    {
                                        onClick: () => {
                                            onResolve(
                                                CONSTANTS.DEVICE_TYPES.ANDROID
                                            );
                                        },
                                        children: messages.androidCustomers,
                                    },
                                ]}
                            />
                        </FlexItem>
                        <FlexItem className={styles.wwPoiDivider}>
                            <div>{translator(messages.or)}</div>
                        </FlexItem>
                    </>
                    }
                    <FlexItem className={cx(styles.optionSection, !walletPassAvailable && styles.onlyPdfToDisplay)} shrink={1}>
                        <WMICIDCardOption
                            icon={<img src={pdfImg} alt="pdf" />}
                            title={messages.pdfFileFormat}
                            subtitle={messages.pdfDescription}
                            emailSent={emailSent}
                            buttons={[
                                {
                                    children: (
                                        <WMICDocumentDownloadButton
                                            size="medium"
                                            targetDocument={
                                                documents.proofOfInsuranceDocument
                                            }
                                        />
                                    ),
                                },
                                {
                                    onClick: onSendViaEmail,
                                    children: messages.sendCopyViaEmail,
                                },
                            ]}
                        />
                    </FlexItem>
                </Flex>
                <ModalFooter>
                    <WMICButton
                        type="primary"
                        className="ww-btn-primary"
                        onClick={() => onResolve()}
                    >
                        {translator(messages.close)}
                    </WMICButton>
                </ModalFooter>
            </div>
        </ModalNext>
    );
};

WMICInsuranceCardModal.propTypes = {
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    documents: PropTypes.arrayOf(PropTypes.shape({}))
};

WMICInsuranceCardModal.defaultProps = {
    isOpen: true,
    onResolve: () => {},
    documents: []
};

export default WMICInsuranceCardModal;
