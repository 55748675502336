// import styles from 'customerEdge/policychange/common/templates/ComfirmationModal/style.scss';
// import cssUtil from 'wmic-portals-utils-js/CssUtil';
import { ModalNextProvider } from '@jutro/components';
import _ from 'lodash';
import htmlParser from 'html-react-parser';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import moment from 'moment';
import messages from './Services.messages';

export default class WMICPolicyChangeUtilsService {
    static getSubmittedChange = (policyNumber) => {
        const key = `apc-${policyNumber}`;
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : {};
    };

    static updateSubmittedChange = (policyNumber, value) => {
        const key = `apc-${policyNumber}`;
        localStorage.setItem(key, JSON.stringify(value));
    };

    static setSubmittedChange = (model) => {
        const key = `apc-${model.policyNumber}`;
        const expiryDate = moment().add(2, 'days').toDate();

        const oldValue = this.getSubmittedChange(model.policyNumber);
        const value = {};
        model.changes.forEach((change) => {
            value[change] = {
                expiry: expiryDate
            };
        });
        const currentValue = Object.assign(oldValue, value);

        localStorage.setItem(key, JSON.stringify(currentValue));
    };

    static hasSubmittedChange = (policyNumber, change) => {
        const time = new Date();
        const value = this.getSubmittedChange(policyNumber);
        let update = false;
        Object.keys(value).forEach((k) => {
            if (new Date(value[k].expiry) < time) {
                update = true;
                delete value[k];
            }
        });
        if (update) {
            this.updateSubmittedChange(policyNumber, value);
        }

        const item = value[change];
        return !!item;
    };

    static splitStreetUnit(addressLine1) {
        if (addressLine1) {
            const addressAux = addressLine1.split(new RegExp(/(unit|Unit|UNIT)/, 'i'));
            const street = addressAux[0] ? addressAux[0].trim() : '';
            const unit = addressAux[2] ? addressAux[2].trim() : '';

            return { street, unit };
        }
        return { street: addressLine1, unit: '' };
    }

    static getModifiedBody(changeModel, bodyTitle, translator) {
        let toHTML = '';
        if (changeModel) {
            toHTML = '<div class="gw-mb10">';
            toHTML += Array.isArray(changeModel) ? changeModel.map((r) => r.toHTML(translator)).join('<br/>') : changeModel.toHTML(translator);
            toHTML += changeModel.constructor.name !== 'FinanceLeasingModel'
                // Agent will contact
                ? `</div><p>${translator(messages.agentWillContact)}</p>`
                // Change to no agent will contact text
                : `</div><p>${translator(messages.agentWillReview)}</p>`;
        }
        const body = translator(bodyTitle);
        return body.concat(toHTML);
    }

    static openModal({
        modalTitle,
        bodyTitle,
        confirmationLabel = 'Yes',
        handleConfirm,
        cancelLabel = 'Cancel',
        translator,
        handleCancel = null,
        changeModel = null,
        submitButtonTrackingText = null
    }) {
        // TODO - AMPDM-899 - review ConfirmationModal
        // const hashedTemplate = cssUtil.hashTemplate(template, styles);

        const cancelFunction = () => {
            if (handleCancel) handleCancel();
        };

        const confirmationFunction = () => {
            if (handleConfirm) handleConfirm();
        };

        const bodyMessage = WMICPolicyChangeUtilsService.getModifiedBody(changeModel, bodyTitle, translator);

        // return ModalService.showSimpleDialog(hashedTemplate, {
        return ModalNextProvider.showConfirm({
            title: modalTitle,
            message: bodyMessage,
            showCancelBtn: true,
            cancelButtonText: cancelLabel,
            confirmButtonText: confirmationLabel,
            submitButtonTrackingText: submitButtonTrackingText
        }).then((res) => {
            if (res === 'confirm') {
                confirmationFunction();
            }
        }).catch(() => {
            cancelFunction();
        });
    }

    static openOtherChangesModal({
        modalTitle = messages.pleaseCallUs,
        bodyModal = htmlParser(messages.callUs),
        confirmationLabel = messages.close
    }) {
        // TODO - AMPDM-899 - Review OtherChangesModal
        // const hashedTemplate = cssUtil.hashTemplate(otherChangesTemplate, styles);
        const confirmationFunction = () => { return _.noop(); };

        return ModalService.showConfirm({
            title: modalTitle,
            message: bodyModal,
            actionLabel: confirmationLabel,
        }, {
            confirmationFunction: confirmationFunction,
        });
    }
}