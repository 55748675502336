import { defineMessages } from 'react-intl';

export default defineMessages({
    RentalVehicleInformation: {
        id: 'wmic.component.rental-vehicle-info-box-component.Rental Vehicle Information',
        defaultMessage: 'Rental Vehicle Information'
    },
    AvailabilityNotice: {
        id: 'wmic.component.rental-vehicle-info-box-component.AvailabilityNotice',
        defaultMessage: 'Please contact your rental location to confirm vehicle availability.'
    },
    PolicyLimitNotice: {
        id: 'wmic.component.rental-vehicle-info-box-component.PolicyLimitNotice',
        defaultMessage: 'Notice: you risk going over your Policy Limit at your current Vehicle Daily Rate.'
    },
    OverdueNotice: {
        id: 'wmic.component.rental-vehicle-info-box-component.OverdueNotice',
        defaultMessage: 'Your rental vehicle is overdue. Please return the vehicle or contact your adjuster to request an extension.'
    },
    DailyLimitNotice: {
        id: 'wmic.component.rental-vehicle-info-box-component.DailyLimitNotice',
        defaultMessage: 'Notice: your current Vehicle Daily Rate exceeds your approved Daily Limit. You will be required to pay any additional costs that exceed your approved limits.'
    },
    RentalLocationAndContact: {
        id: 'wmic.component.rental-vehicle-info-box-component.Rental Location & Contact',
        defaultMessage: 'Rental Location & Contact'
    },
    RentalLocationAndContactAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Rental Location & Contact Aria',
        defaultMessage: 'Rental Location & Contact Enterprise Rent-A-Car {phoneNumber} {vendorAddress}'
    },
    EnterpriseRentACar: {
        id: 'wmic.component.rental-vehicle-info-box-component.Enterprise Rent-A-Car',
        defaultMessage: 'Enterprise Rent-A-Car'
    },
    PhoneNumber18552669289: {
        id: 'wmic.component.rental-vehicle-info-box-component.PhoneNumber18552669289',
        defaultMessage: '1-855-266-9289'
    },
    PhoneNumber18552669565: {
        id: 'wmic.component.rental-vehicle-info-box-component.PhoneNumber18552669565',
        defaultMessage: '1-855-266-9565'
    },
    ReservationNumber: {
        id: 'wmic.component.rental-vehicle-info-box-component.Reservation Number',
        defaultMessage: 'Reservation Number'
    },
    ReservationNumberAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Reservation Number Aria',
        defaultMessage: 'Reservation Number {reservationNumber}'
    },
    PolicyLimit: {
        id: 'wmic.component.rental-vehicle-info-box-component.Policy Limit',
        defaultMessage: 'Policy Limit'
    },
    PolicyLimitAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Policy Limit Aria',
        defaultMessage: 'Policy Limit ${policyLimit}'
    },
    PolicyLimitTooltip: {
        id: 'wmic.component.rental-vehicle-info-box-component.PolicyLimitTooltip',
        defaultMessage: 'The maximum dollar limit amount allowed per claim.'
    },
    DailyLimit: {
        id: 'wmic.component.rental-vehicle-info-box-component.Daily Limit',
        defaultMessage: 'Daily Limit'
    },
    DailyLimitAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Daily Limit Aria',
        defaultMessage: 'Daily Limit ${dailyLimit}'
    },
    DailyLimitTooltip: {
        id: 'wmic.component.rental-vehicle-info-box-component.DailyLimitTooltip',
        defaultMessage: 'The maximum daily dollar limit allowed.'
    },
    ApprovedFor: {
        id: 'wmic.component.rental-vehicle-info-box-component.Approved for',
        defaultMessage: 'Approved for'
    },
    ApprovedForAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Approved for Aria',
        defaultMessage: 'Approved for {days}'
    },
    ApprovedForTooltip: {
        id: 'wmic.component.rental-vehicle-info-box-component.ApprovedForTooltip',
        defaultMessage: 'This number is subject to change and will be modified by your adjuster accordingly.'
    },
    Day: {
        id: 'wmic.component.rental-vehicle-info-box-component.day',
        defaultMessage: 'day'
    },
    Days: {
        id: 'wmic.component.rental-vehicle-info-box-component.days',
        defaultMessage: 'days'
    },
    RentalStartDate: {
        id: 'wmic.component.rental-vehicle-info-box-component.Rental Start Date',
        defaultMessage: 'Rental Start Date'
    },
    RentalStartDateAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Rental Start Date Aria',
        defaultMessage: 'Rental Start Date {startDate}'
    },
    ExpectedReturnDate: {
        id: 'wmic.component.rental-vehicle-info-box-component.Expected Return Date',
        defaultMessage: 'Expected Return Date'
    },
    ExpectedReturnDateAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Expected Return Date Aria',
        defaultMessage: 'Expected Return Date {date}'
    },
    RentalEndDate: {
        id: 'wmic.component.rental-vehicle-info-box-component.Rental End Date',
        defaultMessage: 'Rental End Date'
    },
    RentalEndDateAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Rental End Date Aria',
        defaultMessage: 'Rental End Date {date}'
    },
    VehicleMakeAndModel: {
        id: 'wmic.component.rental-vehicle-info-box-component.Vehicle Make and Model',
        defaultMessage: 'Vehicle Make and Model'
    },
    VehicleMakeAndModelAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Vehicle Make and Model Aria',
        defaultMessage: 'Vehicle Make and Model {vehicle}'
    },
    VehicleDailyRate: {
        id: 'wmic.component.rental-vehicle-info-box-component.Vehicle Daily Rate',
        defaultMessage: 'Vehicle Daily Rate'
    },
    VehicleDailyRateAria: {
        id: 'wmic.component.rental-vehicle-info-box-component.Vehicle Daily Rate Aria',
        defaultMessage: 'Vehicle Daily Rate ${dailyRate}'
    }
});
