import { defineMessages } from 'react-intl';

export default defineMessages({
    example: {
        id: 'portal.views.billing-info-box.An Example',
        defaultMessage: 'An Example'
    },
    billing: {
        id: 'portal.views.billing-info-box.Billing',
        defaultMessage: 'Billing'
    },
    pendingCancellation: {
        id: 'portal.views.billing-info-box.policy.common.views.account-policy-details.This policy is pending cancellation if payment...',
        defaultMessage: 'This policy is pending cancellation if payment is not received by {paymentDate}'
    },
    policyCancelled: {
        id: 'portal.views.billing-info-box.policy.common.views.account-policy-details.Please note that making a payment will not automatically reinstate your cancelled policy.',
        defaultMessage: 'Please note that making a payment will not automatically reinstate your cancelled policy. If you have any questions about reinstating a cancelled policy, please call us at 888-363-7984 Monday to Friday 7:30 am to 7:30 pm or Saturdays 8:00 am to 4:30 pm.'
    },
    listBillWarning: {
        id: 'portal.views.billing-info-box.policy.common.views.account-policy-details.List Bill Warning',
        defaultMessage: 'Your property policy payments will be made by your designated third party.'
    },    
    paymentPlan: {
        id: 'portal.views.billing-info-box.Payment Plan',
        defaultMessage: '&lt;strong&gt;Payment Plan: &lt;/strong&gt;'
    },
    lastPaymentDate: {
        id: 'portal.views.billing-info-box.Last Payment Date',
        defaultMessage: '&lt;strong&gt;Last Payment Date: &lt;/strong&gt;'
    },
    lastPaymentAmount: {
        id: 'portal.views.billing-info-box.Last Payment Amount',
        defaultMessage: '&lt;strong&gt;Last Payment Amount: &lt;/strong&gt;'
    },
    managePaymentPlan: {
        id: 'policy.common.views.account-policy-details.billing-details.Manage Payment Plan',
        defaultMessage: 'Manage Payment Plan'
    },
    viewPaymentPlan: {
        id: 'policy.common.views.account-policy-details.billing-details.View Payment Plan',
        defaultMessage: 'View Payment Plan'
    },
    policyTerm: {
        id: 'portal.views.billing-info-box.POLICY TERM',
        defaultMessage: 'POLICY TERM'
    },
    policyTermAria: {
        id: 'portal.views.billing-info-box.POLICY TERM Aria',
        defaultMessage: 'Policy Term Total ${amount} for Policy #{policyNumber} '
    },
    premium: {
        id: 'portal.views.billing-info-box.Premium',
        defaultMessage: 'Premium'
    },
    policy: {
        id: 'portal.views.billing-info-box.Policy',
        defaultMessage: 'Policy #'
    },
    premiumAmountAria: {
        id: 'portal.views.billing-info-box.Policy Premium Amount Aria',
        defaultMessage: 'Premium ${amount} for Policy #{policyNumber}'
    },
    installmentFees: {
        id: 'portal.views.billing-info-box.Installment Fees',
        defaultMessage: 'Installment Fees'
    },
    installmentFeesAria: {
        id: 'portal.views.billing-info-box.Installment Fees Aria',
        defaultMessage: 'Installment Fees ${amount} for Policy #{policyNumber}'
    },
    otherCharges: {
        id: 'portal.views.billing-info-box.Other Charges',
        defaultMessage: 'Other Charges'
    },
    otherChargesAria: {
        id: 'portal.views.billing-info-box.Other Charges Aria',
        defaultMessage: 'Other Charges ${amount} for Policy #{policyNumber}'
    },
    total: {
        id: 'portal.views.billing-info-box.TOTAL',
        defaultMessage: 'TOTAL'
    },
    remainingBalancePaymentDueAria: {
        id: 'portal.views.billing-info-box.Remaining Balance Payment Due',
        defaultMessage: 'Payment of ${amount} due on {date} for Policy #{policyNumber}'
    },
    remainingBalance: {
        id: 'portal.views.billing-info-box.Remaining Balance',
        defaultMessage: 'Remaining Balance'
    },
    remainingBalanceTotalAria: {
        id: 'portal.views.billing-info-box.Remaining Balance Aria',
        defaultMessage: 'Remaining Balance Total ${amount} for Policy #{policyNumber}'
    },
    nextPayment: {
        id: 'portal.views.billing-info-box.Next Payment',
        defaultMessage: 'Next Payment'
    },
    nextPaymentAria: {
        id: 'portal.views.billing-info-box.Next Payment Aria',
        defaultMessage: 'Next Payment to be paid ${amount} on {date} for Policy #{policyNumber}'
    },
    overdueAmount: {
        id: 'portal.views.billing-info-box.Overdue Amount',
        defaultMessage: 'Overdue Amount'
    },
    overdueAmountAria: {
        id: 'portal.views.billing-info-box.Overdue Amount Aria',
        defaultMessage: 'Overdue Amount ${amount} for Policy #{policyNumber}'
    },
    paymentHistoryMessage: {
        id: 'portal.views.billing-info-box.paymentHistoryMessage',
        defaultMessage: '&lt;span&gt;Payments that were refunded in full are not currently included in your payment history. If you have any questions please contact us by phone at &lt;a href="tel:888-363-7984"&gt;888-363-7984&lt;/a&gt; Monday to Friday 7:30 am to 7:30 pm and Saturday 8:00 am to 4:30 pm.&lt;/span&gt;'
    },
    noPaymentHistoryMessage: {
        id: 'policy.common.views.account-policy-details.billing-details.There are no payments in the last two years',
        defaultMessage: 'There are no payments in the last two years. Once a payment is made it will appear here.'
    },
    paymentHistory: {
        id: 'portal.views.billing-info-box.PAYMENT HISTORY',
        defaultMessage: 'PAYMENT HISTORY'
    },
    futurePayments: {
        id: 'portal.views.billing-info-box.Future Payments',
        defaultMessage: 'Future Payments'
    },
    paymentDue: {
        id: 'portal.views.billing-info-box.Payment Due',
        defaultMessage: 'Payment Due'
    },
    amountCarriedToNextTerm: {
        id: 'portal.views.billing-info-box.Amount carried to Next Term',
        defaultMessage: 'Amount carried to Next Term'
    },
    iconButtonPremiumTooltip: {
        id: 'portal.views.billing-info-box.Button Premium tooltip message',
        defaultMessage: 'A change was made to the policy mid-term. The total Premium amount listed is prorated from the date of the policy change. This amount may be different than the sum of all Vehicle Premiums combined as Vehicle Premium amounts are not prorated.'
    },
    detailedExplanationFirst: {
        id: 'portal.views.billing-info-box.Detailed Explanation First',
        defaultMessage: 'For a detailed explanation of the fees that may apply to your policy, please see the '
    },
    detailedExplanationSecond: {
        id: 'portal.views.billing-info-box.Detailed Explanation Second',
        defaultMessage: ' notice included in your policy documents package.'
    },
    disclosureOfFees: {
        id: 'policy.common.views.account-policy-details.Disclosure of Fees',
        defaultMessage: 'Disclosure of Fees'
    },
    payNow: {
        id: 'portal.views.billing-info-box.Pay Now',
        defaultMessage: 'Pay Now'
    },
    payNowTrackButtonIdentifier: {
        id: 'portal.views.billing-info-box.tracking.Pay Now',
        defaultMessage: 'Policy Details - Pay Now'
    },
    recentBillingDocTrackButtonIdentifier: {
        id: 'portal.views.billing-info-box.tracking.Recent Billing Document',
        defaultMessage: 'Policy Details - Most Recent Billing Document - {documentName}'
    },
    pendingPayments: {
        id: 'portal.views.billing-info-box.Pending Payments',
        defaultMessage: 'Pending Payments'
    },
    oneTimeBankWithdrawal: {
        id: 'portal.views.billing-info-box.One Time Bank Withdrawal',
        defaultMessage: 'One Time Bank Withdrawal'
    },
    cancel: {
        id: 'portal.views.billing-info-box.Cancel',
        defaultMessage: 'Cancel'
    },
    futurePaymentsTooltip: {
        id: 'policy.common.views.billing-details.tooltips.Future Payments',
        defaultMessage: 'The Future Payments amount is the sum of the remaining payments for your policy, which will be billed after any currently due payments (Overdue Amounts or Payments Due) and your next payment.'
    },
    automaticallyWithdrawn: {
        id: 'payment.views.make-a-payment-modal.Payments for this plan are automatically withdrawn from your bank',
        defaultMessage: '&lt;span&gt;Payments for this plan are automatically withdrawn from your bank account. Only overdue payments can be made online. If you need to change the bank account used, please call us at &lt;a href="tel:888-363-7984"&gt;888-363-7984&lt;/a&gt; Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30 pm PT.&lt;/span&gt;'
    },
    pendingBankPaymentNameLastFour: {
        id: 'payment.views.make-a-payment-modal.PendingBankPaymentNameLastFour',
        defaultMessage: '{name} *** {lastFour}'
    }
});