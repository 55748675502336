import React, { useContext} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { WMICStepProgressBar, WMICCard } from 'wmic-pe-components-platform-react'
import { BreakpointTrackerContext } from '@jutro/layout';
import cx from 'classnames'
import styles from './WMICWizardSidebar.module.scss';

import messages from './WMICWizardSidebar.messages'

import { Accordion, AccordionCard } from '@jutro/legacy/components';
/**
 * @typedef {import('wmic-pe-portals-edge-validation-js/errors').GenericUIIssue} GenericUIIssue
 */

/**
 * Renders an icon for steps with errors
 * @param {Array<GenericUIIssue>} errorsAndWarnings array
 * @returns {Object}
 */

function jumpRenderModal(index, jumpTo, steps, step, wizardContext, customMessageModal) {
    const modalMessages = {
        title: !_.isEmpty(customMessageModal)
            ? customMessageModal.title
            : commonMessages.wantToJump,
        message: !_.isEmpty(customMessageModal)
            ? customMessageModal.message
            : commonMessages.wantToJumpMessage,
        messageProps: {
            ok: commonMessages.yes,
            close: commonMessages.close
        }
    };
    const { wizardData, wizardSnapshot, onPageJump } = wizardContext;

    onPageJump({
        wizardData,
        wizardSnapshot,
        modalMessages,
        index
    });
}

const modifySteps = (steps, currentStepIndex, stepsWithErrors, jumpTo, wizardContext, customMessageModal, translator) => {
  steps.forEach((step, index) => {
      step.disabled = !step.visited;
      step.active = index === currentStepIndex;
      step.error = stepsWithErrors.includes(steps[index].id)
      step.onClick = () => {
          !step.active &&
              jumpRenderModal(
                  index,
                  jumpTo,
                  steps,
                  step,
                  wizardContext,
                  customMessageModal
              )
      };
  })
  return steps
}

function renderSteps(
    translator,
    steps,
    currentStepIndex,
    stepsWithErrors,
    jumpTo,
    wizardContext,
    customMessageModal,
    isBMSI
) {
    return (
        <WMICStepProgressBar
            steps={modifySteps(steps, currentStepIndex, stepsWithErrors, jumpTo, wizardContext, customMessageModal, translator)}
            isBMSI={isBMSI}
        />
    );
}

function WMICWizardSidebar(props) {
    const wizardContext = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isTablet = ['phone', 'phoneWide', 'tablet'].includes(breakpoint)


    const {
        steps, currentStepIndex, jumpTo, wizardTitle, currentStep
    } = wizardContext;

    const getTitle = () =>{
        return translator(messages.tabletAccordionTitle, {
            currentStep :  currentStepIndex + 1,
            totalSteps : steps.length,
            pageTitle : translator(currentStep.title)
        })
    }


    const { customMessageModal, isBMSI, stepsWithErrors } = props;
    return isTablet ? (
        <WMICCard className={cx("gw-mb-5", "gw-p-0", "noPrint")}>
           <Accordion
               id="accordion"
               showFrame={false}>
                    <AccordionCard
                    cardBodyClassName={styles.accordionCardTablet}
                    id='wizardTabletAccordionCard'
                    title={getTitle()}
                    chevron
                >
                    <div role="navigation" aria-label={translator(wizardTitle)}>
                        <ul className={styles.stepsNav}>
                            {renderSteps(
                                translator,
                                steps,
                                currentStepIndex,
                                stepsWithErrors,
                                jumpTo,
                                wizardContext,
                                customMessageModal
                            )}
                        </ul>
                    </div>
                </AccordionCard>
            </Accordion>
        </WMICCard>
    ) : (
        <div className={styles.wizardSidebarWrapper}>
            <h3 className={styles.sideBarTitle}>{translator(wizardTitle)}</h3>
            <div role="navigation" aria-label={translator(wizardTitle)}>
                <ul className={styles.stepsNav}>

                    {renderSteps(
                        translator,
                        steps,
                        currentStepIndex,
                        stepsWithErrors,
                        jumpTo,
                        wizardContext,
                        customMessageModal,
                        isBMSI
                    )}
                </ul>
            </div>
        </div>
    );
}

WMICWizardSidebar.propTypes = {
    customMessageModal: PropTypes.shape({}),
    isBMSI: PropTypes.bool.isRequired,
    stepsWithErrors: PropTypes.array
};
WMICWizardSidebar.defaultProps = {
    customMessageModal: {},
    isBMSI: false,
    stepsWithErrors: []
};
export default WMICWizardSidebar;
