import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from "lodash";
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { InputField, CurrencyField } from '@jutro/legacy/components';
import { CONSTANTS, PAConstants, Position } from 'wmic-pe-portals-utils-js';
import WMICVehicleCoveragesTable from "../WMICVehicleCoveragesTable/WMICVehicleCoveragesTable"
import metadata from './WMICIterableVehicleComponent.metadata.json5';
import messages from './WMICIterableVehicleComponent.messages';
import styles from './WMICIterableVehicleComponent.module.scss';

function WMICIterableVehicleComponent(props) {
    const { vehicle, vehicleCoverages, vehicleNonCoverageCosts, rateAsOfDate, lineCoverages, isPolicyChangeOrRenewal, jobVM, isVehicleDeleted } = props;
    const [showCoveragesContainer, setShowCoveragesContainer] = useState( false);
    const translator = useContext(TranslatorContext);

    if (isVehicleDeleted) {
        vehicle.displayMake = `${translator(messages.removed)} ${vehicle.make.value} ${vehicle.model.value}`;
    } else {
        vehicle.displayMake = `${vehicle.make.value} ${vehicle.model.value}`;
    }

    const categorizeCoverages = () => {
        const allCoverages = {
            liabilityProtectionCoverages: [],
            additionalCoverages: [],
            physicalDamageCoverages: [],
            policyLevelCoverages: [],
            underageMaleDriverCoverages: [],
            underageGenderXOrFemaleDriverCoverages:[],
            hiddenCoverages: [],
            ratingInfo: [],
            ...vehicle.value
        };

        _.filter(vehicleCoverages.value, {'selected': true}).forEach((coverage) => {
            switch (coverage.category_WMIC) {
                case 'PALiabilityProtection_wmic':
                    allCoverages.liabilityProtectionCoverages.push(coverage);
                    break;
                case 'PAAdditional_wmic':
                    allCoverages.additionalCoverages.push(coverage);
                    break;
                case 'PACanadian06Coverages':
                    allCoverages.underageMaleDriverCoverages.push(coverage);
                    break;
                case 'PACanadian05Coverages':
                    allCoverages.underageGenderXOrFemaleDriverCoverages.push(coverage);
                    break;
                case 'PAPolicyHolderProperty_wmic':
                    allCoverages.physicalDamageCoverages.push(coverage);
                    break;
                case 'PA_HiddenCov':
                    allCoverages.hiddenCoverages.push(coverage);
                    break;
                default:
                    break;
            }
        });

        lineCoverages.forEach((coverage) => {
            if ((coverage.category_WMIC.value === 'PALineAdditional_WMIC'
                    || coverage.category_WMIC.value === 'PAOptionalAccBenefits_WMIC') && coverage.selected.value === true) {
                allCoverages.policyLevelCoverages.push(coverage.value);
            }
        });

        const bipdPackageCovIndex = _.findIndex(allCoverages.liabilityProtectionCoverages, (coverage) => coverage.coverageUniqueID === PAConstants.BIPDCovPublicId)

        // setting the BIPD coverage package amount
        if (bipdPackageCovIndex >= 0) {
            const biCov = _.find(allCoverages.hiddenCoverages, (coverage) => coverage.coverageUniqueID === `${CONSTANTS.COVERAGE_NAMES.PA_BI}_CA`)
            const pdCov = _.find(allCoverages.hiddenCoverages, (coverage) => coverage.coverageUniqueID === `${CONSTANTS.COVERAGE_NAMES.PA_PD}_CA`)

            if (
                biCov &&
                pdCov &&
                _.has(biCov, 'amount') &&
                !!_.get(biCov, 'amount.currency')
            ) {
                const bipdAmount = {
                    currency: biCov.amount.currency,
                    amount: biCov.amount.amount + pdCov.amount.amount
                }

                allCoverages.liabilityProtectionCoverages[bipdPackageCovIndex].amount = bipdAmount
            }
        }

        return allCoverages;
    };


    const toggleShowCoverages = () => {
        if (!isVehicleDeleted) {
            setShowCoveragesContainer(!showCoveragesContainer);
        }
    }

    const getClassName = () => {
        if (!isVehicleDeleted) {
            return 'iterableVehicleContainer';
        }

        return cx('removed', 'iterableVehicleContainer');
    }

    const overrides = {
        '@field': {
            parentNode: vehicle,
            readOnly: true,
            labelPosition: Position.TOP,
        },
        iterableVehicleContainer: {
            className: getClassName()
        },
        expandIcon: {
            disabled: isVehicleDeleted,
            icon: showCoveragesContainer ? 'mi-expand-less' : 'mi-expand-more'
        },
        vehicleCoveragesTable: {
            visible: !isVehicleDeleted && showCoveragesContainer,
            vehiclesCoverages: !isVehicleDeleted && categorizeCoverages(),
            vehicleNonCoverageCosts: vehicleNonCoverageCosts,
            vehicle,
            rateAsOfDate,
            isPolicyChangeOrRenewal,
            jobVM
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            toggleShowCoverages
        },
        resolveComponentMap: {
            WMICVehicleCoveragesTable,
            InputField,
            CurrencyField
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={vehicle}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICIterableVehicleComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICIterableVehicleComponent;
