// A wrapper for Jutro Link component with custom focus style
import React from 'react';
import cx from 'classnames';
import styles from './WMICLink.module.scss';
import { Link } from '@jutro/router';

const WMICLink = ({ children, className, textClassName, ...props }) => {
    const linkStyle = className
        ? cx(styles['wmic-link'], className)
        : styles['wmic-link'];

    const styleForLinkText = textClassName
        ? cx(styles['wmic-link-text'], textClassName)
        : styles['wmic-link-text'];

    return (
        <Link className={linkStyle} textClassName={styleForLinkText} {...props}>
            {children}
        </Link>
    );
};

export default WMICLink;
