// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WizardSidebarButtons__buttonContainer{--gw-wizard-sidebar-button-container-width:60%;display:flex;flex-direction:column;margin:auto;width:var(--gw-wizard-sidebar-button-container-width)}.app__WizardSidebarButtons__actionButtons{margin-bottom:var(--GW-SPACING-3)}.app__WizardSidebarButtons__actionButtons span{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-common-react/components/WizardSidebarButtons/WizardSidebarButtons.module.scss"],"names":[],"mappings":"AAAA,4CACI,8CAAA,CAEA,YAAA,CACA,qBAAA,CAEA,WAAA,CADA,qDACA,CAGJ,0CACI,iCAAA,CAGJ,+CACI,eAAA,CAEA,sBAAA,CADA,kBACA","sourcesContent":[".buttonContainer {\n    --gw-wizard-sidebar-button-container-width: 60%;\n    margin: var(--GW-SPACING-3) 0;\n    display: flex;\n    flex-direction: column;\n    width: var(--gw-wizard-sidebar-button-container-width);\n    margin: auto;\n}\n\n.actionButtons {\n    margin-bottom: var(--GW-SPACING-3);\n}\n\n.actionButtons span {\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "app__WizardSidebarButtons__buttonContainer",
	"actionButtons": "app__WizardSidebarButtons__actionButtons"
};
export default ___CSS_LOADER_EXPORT___;
