// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICCoBrowseButton__cobrowseSubHeaderBtn{align-items:center;background:none;background-color:var(--GW-TOP-NAV-ITEM-BACKGROUND-COLOR);border:none;border-left:1px solid #fff;color:var(--GW-TOP-NAV-ITEM-COLOR);cursor:pointer;display:flex;font-size:var(--GW-FONT-SIZE-BODY);font-weight:var(--GW-FONT-WEIGHT-REGULAR);line-height:var(--GW-LINE-HEIGHT-BODY);padding-left:1rem;position:relative;text-decoration:none}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-react/Components/WMICCoBrowseButton/WMICCoBrowseButton.module.scss"],"names":[],"mappings":"AAEA,+CAKI,kBAAA,CAHA,eAAA,CAWA,wDAAA,CAVA,WAAA,CAAA,0BAAA,CASA,kCAAA,CAJA,cAAA,CAFA,YAAA,CAIA,kCAAA,CADA,yCAAA,CAEA,sCAAA,CAGA,iBAAA,CAVA,iBAAA,CAGA,oBAOA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n\n.cobrowseSubHeaderBtn {\n    border: none;\n    background: none;\n    border-left: 1px solid #FFFFFF;\n    position: relative;\n    align-items: center;\n    display: flex;\n    text-decoration: none;\n    cursor: pointer;\n    font-weight: var(--GW-FONT-WEIGHT-REGULAR);\n    font-size: var(--GW-FONT-SIZE-BODY);\n    line-height: var(--GW-LINE-HEIGHT-BODY);\n    color: var(--GW-TOP-NAV-ITEM-COLOR);\n    background-color: var(--GW-TOP-NAV-ITEM-BACKGROUND-COLOR);\n    padding-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cobrowseSubHeaderBtn": "app__WMICCoBrowseButton__cobrowseSubHeaderBtn"
};
export default ___CSS_LOADER_EXPORT___;
