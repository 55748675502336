import { defineMessages } from 'react-intl';

export default defineMessages({
    pleaseEnterAddress: {
        id: 'portal.views.edit-address.pleaseEnterAddress',
        defaultMessage: 'Please enter your new address'
    },
    zipCodeIncorrect: {
        id: 'portal.views.edit-address.zipCodeIncorrect',
        defaultMessage: 'Zip code must be 5 or 9 digits'
    },
    street: {
        id: 'portal.views.edit-address.street',
        defaultMessage: 'Street'
    },
    unitNumber: {
        id: 'portal.views.edit-address.unitNumber',
        defaultMessage: 'Unit Number'
    },
    city: {
        id: 'portal.views.edit-address.city',
        defaultMessage: 'City'
    },
    state: {
        id: 'portal.views.edit-address.state',
        defaultMessage: 'State'
    },
    zip: {
        id: 'portal.views.edit-address.zip',
        defaultMessage: 'Zip'
    },
});
