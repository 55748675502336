// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WizardBMSIModal__headerContainer{font-size:36px;padding:var(--GW-MODAL-HEADER-PADDING-TOP) var(--GW-MODAL-HEADER-PADDING-HORIZONTAL) var(--GW-MODAL-HEADER-PADDING-BOTTOM)}.app__WizardBMSIModal__sectionHeader{font-size:1.25em}.app__WizardBMSIModal__sectionBody{margin-left:36px}.app__WizardBMSIModal__error{color:var(--GW-COLOR-ERROR)}.app__WizardBMSIModal__warning{color:var(--GW-COLOR-WARNING)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-quoteandbind-common-react/components/WizardBMSIModal/WizardBMSIModal.module.scss"],"names":[],"mappings":"AAAA,uCACE,cAAA,CACA,0HAAA,CAGF,qCACE,gBAAA,CAGF,mCACE,gBAAA,CAGF,6BACE,2BAAA,CAGF,+BACE,6BAAA","sourcesContent":[".headerContainer {\n  font-size: 36px;\n  padding: var(--GW-MODAL-HEADER-PADDING-TOP) var(--GW-MODAL-HEADER-PADDING-HORIZONTAL) var(--GW-MODAL-HEADER-PADDING-BOTTOM);\n}\n\n.sectionHeader{\n  font-size: 1.25em;\n}\n\n.sectionBody{\n  margin-left: 36px;\n}\n\n.error{\n  color: var(--GW-COLOR-ERROR)\n}\n\n.warning{\n  color: var(--GW-COLOR-WARNING)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "app__WizardBMSIModal__headerContainer",
	"sectionHeader": "app__WizardBMSIModal__sectionHeader",
	"sectionBody": "app__WizardBMSIModal__sectionBody",
	"error": "app__WizardBMSIModal__error",
	"warning": "app__WizardBMSIModal__warning"
};
export default ___CSS_LOADER_EXPORT___;
