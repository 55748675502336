/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { useTranslator } from '@jutro/locale';
import messages from './WMICConfirmRemoveVehicleModal.messages';
import styles from './WMICConfirmRemoveVehicleModal.module.scss';

const WMICConfirmRemoveVehicleModal = (props) => {
    const {
        onConfirm,
        vehicle,
        reason,
        date,
        onResolve,
        onReject,
        isOpen
    } = props;
    const translator = useTranslator();

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext 
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc>
            <h1 className={styles.modalHeader}>{translator(messages.removeVehicle)}</h1>
            <ModalBody>
                <div className={styles['wmic-content-wrapper']}>
                    <p className={styles['wmic-paragraph-text']}>
                        {translator(messages.pleaseConfirmTheDeletionOfTheFollowingVehicle)}
                    </p>
                    <p className={styles['wmic-paragraph-summary-data']}>
                        <strong>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</strong>
                        <br />
                        <strong>{translator(messages.vin)}</strong>
                        { vehicle.vin }
                        <br />
                        <strong>{translator(messages.reason)}</strong>
                        { reason }
                        <br />
                        <strong>{translator(messages.date)}</strong>
                        { date }
                    </p>
                    <p className={styles['wmic-paragraph-last']}>
                        {translator(messages.onceSubmittedAnAgentWillContactYouShortly)}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={onReject}
                    type="outlined"
                >
                    {translator(messages.cancel)}
                </WMICButton>
                <WMICButton
                    onClick={() => {
                        onConfirm();
                        onResolve();
                    }}
                    type="primary"
                >
                    {translator(messages.yesRemove)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICConfirmRemoveVehicleModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    vehicle: PropTypes.shape({
        year: PropTypes.number,
        make: PropTypes.string,
        model: PropTypes.string,
        vin: PropTypes.string
    }).isRequired,
    reason: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
};

export default WMICConfirmRemoveVehicleModal;