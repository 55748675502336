/* eslint-disable max-len */
import React, {
    useEffect, useState, useMemo, useContext, useCallback, Fragment
} from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    WMICPaymentUtil,
    CONSTANTS,
    SUITES,
    WMICRichTextUtil
} from 'wmic-portals-utils-js';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import {
    CurrencyValue,
    useModal
} from '@jutro/components';
import {useHistory, useParams} from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';
import { WMICDocumentDownloadButton } from 'gw-capability-document-react';
import { WMICAccordionCard } from 'wmic-components-amp-common-react';
import { WMICButton } from 'wmic-components-platform-react';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import cx from 'classnames';
import dayjs from 'dayjs';
import WMICPolicyDetailsUtil from '../WMICPolicyDetailsUtil';
import metadata from './WMICBillingInfoBox.metadata.json5';
import messages from './WMICBillingInfoBox.messages';
import styles from './WMICBillingInfoBox.module.scss';
import WMICPaymentHistoryElement from './WMICPaymentHistoryElement/WMICPaymentHistoryElement';
import WMICManagePaymentPlan from './WMICManagePaymentPlan/WMICManagePaymentPlan';
import WMICPendingPaymentCancellation from './WMICPendingPaymentCancellation/WMICPendingPaymentCancellation';

const WMICBillingInfoBox = (props) => {
    const modalApi = useModal();
    const { termNumber } = useParams();
    const {
        billingData: billingInfo,
        policyData: policyInfo,
        policyTransactions: transactionsInfo,
        mostRecentBillingDocument,
        updateBillingDetails,
        fetchPolicyData,
        ldFlags,
        isRenewal,
    } = props;

    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const { authHeader,  } = useAuthentication();
    const [availablePaymentPlans, setAvailablePaymentPlans] = useState(undefined);
    const [pendingPayments, setPendingPayments] = useState([]);
    const { WMICPaymentModal } = useDependencies('WMICPaymentModal');
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const history = useHistory();
    const policyNumber = policyInfo?.currentPeriod?.policyNumber;

    const refreshPendingPayments = useCallback(() => {
        AccountBillingDetailsService.getPendingPaymentRequests_WMIC(billingInfo.invoiceStreamId, authHeader).then(setPendingPayments);
    }, [billingInfo, authHeader]);

    useEffect(() => {
        refreshPendingPayments();
    }, [refreshPendingPayments]);

    const isPersonalAutoLob = () => {
        return Array.isArray(_.get(policyInfo, 'currentPeriod.lobs.personalAuto.vehicleDTOs'));
    }

    useEffect(() => {
        if (!_.isEmpty(policyInfo) && !_.isEmpty(billingInfo)) {        
            billingInfo.policyStatus = WMICPolicyDetailsUtil.getBillingInfoPolicyStatus(policyInfo);
            billingInfo.renewalPolicyStatus = WMICPolicyDetailsUtil.getBillingInfoRenewalPolicyStatus(policyInfo);

            if (isPersonalAutoLob() && policyInfo.currentPeriod.jurisdiction_WMIC === CONSTANTS.JURISDICTIONS.CA &&
                (billingInfo.policyStatus.toLowerCase() === CONSTANTS.INFORCE.toLowerCase() || billingInfo.policyStatus.toLowerCase() === CONSTANTS.IN_FORCE.toLowerCase() || billingInfo.policyStatus.toLowerCase() === CONSTANTS.SCHEDULED.toLowerCase())) {
                AccountBillingDetailsService.getPaymentPlanChangesWithInvoices_WMIC(policyInfo.currentPeriod.policyNumber, termNumber, authHeader).then((result) => {
                    const paymentPlans = _.filter(result.paymentPlanChanges, (pp) => pp.paymentPlanDisplayName !== billingInfo.paymentPlan && pp.invoices.length > 0);
                    setAvailablePaymentPlans(paymentPlans);
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingInfo, policyInfo]);

    const status =  billingInfo.policyStatus ||
            policyInfo.currentPeriod.lobs.personalAuto.status ||
            policyInfo.currentPeriod.lobs.homeOwners.status;

    const isPayNowButtonVisible = useCallback(() => {
        if (!_.isEmpty(policyInfo)) {
            const policyStatus = WMICPaymentUtil.getPolicyStatusName(status, billingInfo.renewalPolicyStatus);
            const isViewingCurrentTerm = billingInfo.termNumber === parseInt(termNumber, 10);
            return WMICPaymentUtil.canShowPayNowButton(billingInfo, policyStatus, isViewingCurrentTerm);
        }
        return false;
    }, [billingInfo, termNumber]);

    const getActualCurrency = (aBillingData) => {
        return _.get(aBillingData, 'premium.currency');
    };

    const isIconButtonPremiumVisible = (aTransactionsInfo) => {
        return WMICPolicyDetailsUtil.isIconButtonPremiumVisible(aTransactionsInfo);
    };

    const showPendingPaymentCancellationModal = useCallback((event, payment) => {
        event.preventDefault();
        return modalApi.showModal(<WMICPendingPaymentCancellation payment={payment} callBack={refreshPendingPayments} />);
    }, [refreshPendingPayments]);

    const getFormattedDate = useCallback((date) => {
        if (date !== undefined) {
            // Convert Date string to date format
            let formattedDate = (typeof date === 'string' && date[date.length - 1] === 'Z') ? date : dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
            // Take the date only
            formattedDate = formattedDate.substring(0, formattedDate.indexOf('T'));
            // Set date's time to midnight, so MM/DD/YYYYT00:00:00
            formattedDate = dayjs(`${formattedDate}T00:00:00`);
            // Format to display
            formattedDate = intl.formatDate(new Date(formattedDate), { year: 'numeric', month: '2-digit', day: '2-digit' })
            return formattedDate;
        }
    }, [intl]);

    const getMonthDateYearFormat = useCallback((date) => {
        if (date !== undefined) {
            // Convert Date string to date format
            let formattedDate = (typeof date === 'string' && date[date.length - 1] === 'Z') ? date : dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
            // Take the date only
            formattedDate = formattedDate.substring(0, formattedDate.indexOf('T'));
            // Set date's time to midngiht, so MM/DD/YYYYT00:00:00
            formattedDate = dayjs(`${formattedDate}T00:00:00`);
            // Format to display
            formattedDate = intl.formatDate(new Date(formattedDate), { month: 'long', day: 'numeric', year: 'numeric' })
            return formattedDate;
        }
    }, [intl]);

    const getPendingBankPaymentLabel = useCallback((payment) => {
        let paymentName;
        if (payment.bankAccountTypeName) {
            paymentName = payment.bankAccountTypeName;
        } else if (payment.creditCardTypeName) {
            paymentName = payment.creditCardTypeName
        } else {
            paymentName = payment.displayName
        }
        return translator(messages.pendingBankPaymentNameLastFour, { name: paymentName, lastFour: payment.lastFourDigits })
    });

    const getPendingPaymentContent = useCallback((payment) => {
        return (
            <Fragment>
                <div className="ww-label">
                    <strong>
                        { getFormattedDate(payment.draftDate) }
                    </strong>
                    <br />
                        { getPendingBankPaymentLabel(payment) }
                    <br />
                    <a href="/" onClick={(e) => showPendingPaymentCancellationModal(e, payment)}>
                        { translator(messages.cancel) }
                    </a>
                </div>
            </Fragment>
        );
    }, [getFormattedDate, showPendingPaymentCancellationModal, translator]);

    const paymentAmountsViewData = useMemo(() => {

        const policyStatus = WMICPaymentUtil.getPolicyStatusName(status, billingInfo.renewalPolicyStatus);

        const paymentHistory = [];
        const remainingBalanceDisplayData = WMICPaymentUtil.getRemainingBalanceDisplayData(billingInfo, policyStatus);

        const viewData = {
            paymentPlan: billingInfo.paymentPlan,
            policyTermTotal: WMICPaymentUtil.getAmount(billingInfo.totalPremiumAmount).toString(),
            policyTermTotalCurrencyAmount: billingInfo.totalPremiumAmount,
            premium: WMICPaymentUtil.getAmount(billingInfo.premium).toString(),
            premiumCurrencyAmount: billingInfo.premium,
            installmentFees: WMICPaymentUtil.getAmount(billingInfo.installmentFees).plus(WMICPaymentUtil.getAmount(billingInfo.unbilledFeesAmount)).toString(),
            otherCharges: WMICPaymentUtil.getAmount(billingInfo.otherCharges).toString(),
            otherChargesCurrencyAmount: billingInfo.otherCharges,
            totalRemainingBalance: WMICPaymentUtil.getTotalAmount(remainingBalanceDisplayData, billingInfo.outstandingAmount).toString(),
            overdueAmount: WMICPaymentUtil.getOverdueAmount(billingInfo).toString(),
            paymentDue: WMICPaymentUtil.getAmount(billingInfo.amountDue).minus(WMICPaymentUtil.getAmount(billingInfo.amountOverDue)).toString(),
            paymentDueLabel: `${translator(messages.paymentDue)} (${getFormattedDate(billingInfo.nextDuePaymentDate)})`,
            amountCarriedToNextTerm: remainingBalanceDisplayData.totalUnbilled.toString(),
            amountCarriedToNextTermLabel: translator(messages.amountCarriedToNextTerm),
            nextPayment: remainingBalanceDisplayData.nextPayment.toString(),
            nextPaymentLabel: `${translator(messages.nextPayment)} (${getFormattedDate(billingInfo.nextPlannedAmountPaymentDate)})`,
            futurePayments: WMICPaymentUtil.getTotalRemainingBalance(billingInfo).toString(),
            lastPaymentDate: billingInfo.lastPaymentDate ? getFormattedDate(billingInfo.lastPaymentDate) : null,
            lastPaymentAmount: billingInfo.lastPaymentAmount ? billingInfo.lastPaymentAmount : null
        };

        // include these properties based on the values already on the viewData object
        viewData.installmentFeesCurrencyAmount = WMICPaymentUtil.getShapedCurrencyAmount(viewData.installmentFees);
        viewData.overdueCurrencyAmount = WMICPaymentUtil.getShapedCurrencyAmount(viewData.overdueAmount);
        viewData.nextPaymentCurrencyAmount = WMICPaymentUtil.getShapedCurrencyAmount(viewData.nextPayment);
        viewData.paymentDueCurrencyAmount = WMICPaymentUtil.getShapedCurrencyAmount(viewData.paymentDue);
        viewData.totalRemainingBalanceCurrencyAmount = WMICPaymentUtil.getShapedCurrencyAmount(viewData.totalRemainingBalance);

        _.each(billingInfo.paymentHistory, (paymentHistoryItem) => {
            const paymentHistoryRecord = {
                receivedDate: paymentHistoryItem.receivedDate,
                displayReceivedDate: getFormattedDate(paymentHistoryItem.receivedDate),
                amount: WMICPaymentUtil.getAmount(paymentHistoryItem.amount).toString(),
                paymentMethod: paymentHistoryItem.paymentMethod,
                reversed: paymentHistoryItem.reversed,
                reversalDate: paymentHistoryItem.reversalDate,
                displayReversalDate: getFormattedDate(paymentHistoryItem.reversalDate),
                reversalReason: paymentHistoryItem.reversalReason,
                policyNumber
            };

            paymentHistoryRecord.currencyAmount = WMICPaymentUtil.getShapedCurrencyAmount(paymentHistoryRecord.amount);

            paymentHistory.push(paymentHistoryRecord);
        });

        viewData.paymentHistory = paymentHistory;

        return viewData;

    }, [billingInfo, getFormattedDate, policyNumber, status, translator]); 

    const resolvers = {
        resolveComponentMap: {
            wmicpaymenthistoryelement: WMICPaymentHistoryElement,
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent,
            WMICDocumentDownloadButton,
            WMICButton,
            WMICAccordionCard,
        },
        resolveClassNameMap: styles,
    };

    const pendingPaymentsOverrides = useMemo(() => {
        const iterableOverrides = pendingPayments?.map((payment, index) => {
            return {
                [`pendingPaymentLabel${index}`]: {
                    content: getPendingPaymentContent(payment)
                },
                [`pendingPaymentValue${index}`]: {
                    value: payment?.transactionAmount,
                    showFractions: true
                }
            };
        });

        return {
            // isListBill condition check is a temporary fix for AMPDM-3257
            pendingPaymentsSectionContainer: {
                visible: pendingPayments?.length > 0 && !WMICPaymentUtil.isListBill(billingInfo)
            },
            pendingPaymentsIterable: {
                data: pendingPayments
            },
            pendingPaymentsTotalValue: {
                value: WMICPaymentUtil.getShapedCurrencyAmount(pendingPayments?.reduce((total, curr) => total + curr.transactionAmount.amount, 0)),
                showFractions: true,
            },
            ...Object.assign({}, ...iterableOverrides)
        };
    }, [getPendingPaymentContent, pendingPayments, WMICPaymentUtil]);

    const isReadOnlyPaymentPlan = useMemo(() => {
        if (!_.isEmpty(billingInfo)) {
            const data = isRenewal ? billingInfo.renewalPaymentDetails : billingInfo;
            return _.get(data, 'amountOverDue.amount') > 0 || _.get(data, 'outstandingAmount.amount') === 0;
        }
        return true;
    }, [billingInfo, isRenewal]);

    const getPaymentPlanButtonText = useMemo(() => {
        const messageKey = isReadOnlyPaymentPlan ? 'viewPaymentPlan' : 'managePaymentPlan';
        return translator(messages[messageKey]);
    }, [isReadOnlyPaymentPlan, translator]);

    const paymentStatus = useMemo(() => {
        if (!_.isEmpty(billingInfo)) {
            const policyStatus = WMICPaymentUtil.getPolicyStatusName(status, billingInfo.renewalPolicyStatus);
            return WMICPaymentUtil.getPaymentStatus(billingInfo, policyStatus === CONSTANTS.INACTIVE)
        }        
        return '';
    }, [billingInfo]);

    const isPolicyCancelled = useMemo(() => {
        return paymentStatus === CONSTANTS.OVERDUE
            && (billingInfo.policyStatus.toLowerCase() === CONSTANTS.EXPIRED || billingInfo.policyStatus.toLowerCase() === CONSTANTS.CANCELED)
            && isPayNowButtonVisible();
    }, [paymentStatus, billingInfo.policyStatus, isPayNowButtonVisible]);

    const showListBillWarning = useMemo(() => {
        return !isPayNowButtonVisible() && WMICPaymentUtil.isListBill(billingInfo);
    }, [billingInfo, isPayNowButtonVisible]);

    const disclosureOfFees = useMemo(() => {
        const periodData = _.get(policyInfo, 'renewedPeriod.documentDTOs') || _.get(policyInfo, 'currentPeriod.documentDTOs') || [];
        return WMICPolicyDetailsUtil.getDisclosureOfFees(WMICPolicyDetailsUtil.reverseChronologicalDocuments(periodData));
    }, [policyInfo]);

    const disclosureOfFeesOnClick = useCallback((evt) => {
        evt.preventDefault();
        const targetDocument = {
            id: disclosureOfFees ? disclosureOfFees.workingPublicID : undefined,
            sessionID: disclosureOfFees ? disclosureOfFees.sessionID : undefined,
            fileName: disclosureOfFees ? disclosureOfFees.name : '',
            type: SUITES.PC
        };
        const dcf = 'policyDocument';

        const docUrl = DocumentDownloadService.getDocumentLink(
            getProxiedServiceUrl(dcf), targetDocument.id, targetDocument.sessionID
        );
        DocumentDownloadService.getDocument(docUrl, authHeader, () =>
            history.push('/technicalErrorPage/documentDownload')
        );
    }, [disclosureOfFees]);

    const getDisclosureOfFeesTooltipMessage = () => {
        return (
            <Fragment>
                { translator(messages.detailedExplanationFirst) }
                <a onClick={(event) => disclosureOfFeesOnClick(event)} href="/">{translator(messages.disclosureOfFees)}</a>
                { translator(messages.detailedExplanationSecond)}
            </Fragment>
        );
    };

    const getHeadPaymentHistory = () => {
        return (
            <h3 className={styles.paymentHistoryTitle}>{translator(messages.paymentHistory)}</h3>
        );
    }

    const passesCAAutoFullPayCheck = () => {
        let result = true;
        if (isPersonalAutoLob() && policyInfo.currentPeriod.jurisdiction_WMIC === CONSTANTS.JURISDICTIONS.CA) {
            result = Array.isArray(availablePaymentPlans) && availablePaymentPlans.length > 0;
        }
        return result;
    }

    const canShowPaymentPlanButton = useCallback(() => {
        const policyStatus = status.toLowerCase();

        return (policyStatus === CONSTANTS.INFORCE || policyStatus === CONSTANTS.IN_FORCE || policyStatus === CONSTANTS.SCHEDULED)
            && billingInfo.billingMethod !== 'ListBill'
            && billingInfo.outstandingAmount.amount > 0
            && billingInfo.outstandingAmount.amount !== billingInfo.amountOverDue.amount
            && (paymentAmountsViewData.amountCarriedToNextTerm === '' || paymentAmountsViewData.amountCarriedToNextTerm === 0)
            && passesCAAutoFullPayCheck();
    }, [billingInfo, policyInfo, availablePaymentPlans, status]);

    const getBillWarningMessage = () => 
        <>
            <i className={cx(styles.iconFontSize, 'fa fa-exclamation-circle wmic-alert-color')} />
            {WMICRichTextUtil.translateRichText(translator(messages.listBillWarning))}
        </>
    
    const renderSrContent = useCallback((message, amount, policyNumber, date) => {
        if (date) {
            return (
                <Fragment>
                    {translator(message, {amount, policyNumber, date: getMonthDateYearFormat(date)})}
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    {translator(message, {amount, policyNumber})}
                </Fragment>
            );
        }
    }, [translator]);

    const renderPolicyTermPremiumValue = useCallback((premiumValue) => {
        return (
            <CurrencyValue 
                id="policyTermPremiumValue"
                className="ww-billing-value"
                value= {premiumValue}
                showFractions="true"
            />
        );
    }, [translator]);

    const renderPolicyTermInstallmentFeesValue = useCallback((installmentFeesValue) => {
        return (
            <CurrencyValue 
                id="policyTerminstallmentFeesValue"
                className="ww-billing-value"
                value= {installmentFeesValue}
                showFractions="true"
            />
        );
    }, [translator]);

    const renderPolicyTermOtherChargesValue = useCallback((otherChargesValue) => {
        return (
            <CurrencyValue 
                id="policyTermOtherChargesValue"
                className="ww-billing-value"
                value= {otherChargesValue}
                showFractions="true"
            />
        );
    }, [translator]);

    const renderPolicyTermTotalValue = useCallback((policyTermTotal) => {
        return (
            <CurrencyValue 
                id="policyTermTotalValue"
                className="ww-billing-value"
                value= {policyTermTotal}
                showFractions="true"
            />
        );
    }, [translator]);

    const renderOverdueAmount = useCallback((overDueAmountValue) => {
        return (
            <CurrencyValue 
                id="remainingBalanceOverDueAmountValue"
                className="ww-billing-value"
                value= {overDueAmountValue}
                showFractions="true"
            />
        );
    }, [translator]);

    const renderNextPaymentValue = useCallback((nextPaymentValue) => {
        return (
            <CurrencyValue 
                id="remainingBalanceNextPaymentValue"
                className="ww-billing-value"
                value= {nextPaymentValue}
                showFractions="true"
            />
        );
    }, [translator]);

    const renderRemainingBalanceTotal = useCallback((totalRemainingBalance) => {
        return (
            <CurrencyValue 
                id="remainingBalanceTotalValue"
                className="ww-billing-value"
                value= {totalRemainingBalance}
                showFractions="true"
            />
        );
    }, [translator]);

    const overrides = {
        billingInfoBoxyTitle: {
            content: translator(messages.billing),
            className: billingInfo?.amountOverDue?.amount > 0 ? 'wmic-card-header-title wmic-alert-color' : 'wmic-card-header-title'
        },
        buttonPayNow: {
            visible: isPayNowButtonVisible(),
            type: billingInfo?.amountOverDue?.amount > 0 ? 'danger' : 'secondary',
            size: "small",
            icon: 'cust-dollar-sign',
            iconClassName: 'payNowIcon',
            content: translator(messages.payNow),
            trackButtonIdentifier: translator(messages.payNowTrackButtonIdentifier),
            'aria-label': "Pay the overdue amount of $" + paymentAmountsViewData.overdueAmount +" now, on policy #" + policyInfo?.currentPeriod.policyNumber,
            onClick: (evt) => {
                evt.preventDefault();
                const policyModalData = {
                    billingData: billingInfo,
                    policyData: policyInfo
                };

                return modalApi.showModal(<WMICPaymentModal data={policyModalData} fetchBillingData={fetchPolicyData} ldFlags={ldFlags} />).then(() => {
                    history.push('/technicalErrorPage/issueWithAccount');
                }).catch((e) => {
                    _.noop;
                });
            }
        },
        pendingCancellationMessageContainer: {
            visible: !!(billingInfo.isNOITCCompleted && billingInfo.cancellationDate)
        },
        pendingCancellationMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.pendingCancellation, {paymentDate: getFormattedDate(billingInfo.cancellationDate)})),
        },
        policyCancelledContainer: {
            visible: isPolicyCancelled
        },
        policyCancelledMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.policyCancelled)),
        },
        listBillWarningContainer: {
            visible: showListBillWarning
        },
        listBillWarning: {
            content: getBillWarningMessage(),
        },
        paymentPlanLabel: {
            content: WMICRichTextUtil.translateRichText(translator(messages.paymentPlan))
        },
        paymentPlanValue: {
            content: paymentAmountsViewData.paymentPlan
        },
        lastPaymentAmountWrapper: {
            visible: !!billingInfo.lastPaymentAmount
        },
        lastPaymentDateLabel: {
            content: WMICRichTextUtil.translateRichText(translator(messages.lastPaymentDate))
        },
        lastPaymentDateValue: {
            content: getFormattedDate(billingInfo.lastPaymentDate)
        },
        lastPaymentAmountLabel: {
            content: WMICRichTextUtil.translateRichText(translator(messages.lastPaymentAmount))
        },
        lastPaymentAmountValue: {
            value: billingInfo.lastPaymentAmount,
            showFractions: true
        },
        buttonManagePaymentPlanDiv: {
            visible: canShowPaymentPlanButton()
        },
        paymentPlanButton: {
            content: getPaymentPlanButtonText,
            onClick: (evt) => {
                evt.preventDefault();
                return modalApi.showModal(
                    <WMICManagePaymentPlan
                        billingData={billingInfo}
                        updateBillingDetails={updateBillingDetails}
                        isRenewal={isRenewal}
                        fetchDataOnClose={fetchPolicyData}
                        ldFlags={ldFlags}
                    />
                );
            }
        },
        recentBillingDocButtonDiv: {
            visible: !_.isEmpty(mostRecentBillingDocument)
        },
        recentBillingDocButton: {
            visible: !_.isEmpty(mostRecentBillingDocument),
            targetDocument: {
                id: _.get(mostRecentBillingDocument, 'id'),
                friendlyName: translator(mostRecentBillingDocument.friendlyName),
                fileName: _.get(mostRecentBillingDocument, 'fileName'),
                sessionID: _.get(mostRecentBillingDocument, 'sessionID'),
                type: SUITES.BC
            },
            trackButtonIdentifier: translator(messages.recentBillingDocTrackButtonIdentifier, {documentName: translator(mostRecentBillingDocument.friendlyName)}),
            policyNumber: policyInfo?.currentPeriod.policyNumber
        },
        // POLICY TERM SECTION
        policyTermTitle: {
            content: translator(messages.policyTerm)
        },
        // Premium
        policyTermPremiumLabel: {
            content: translator(messages.premium)
        },
        policyTermPremiumValueDiv: {
            content: renderPolicyTermPremiumValue(
                paymentAmountsViewData.premiumCurrencyAmount
            )
        },
        policyTermPremiumSrContent: {
            content: renderSrContent(
                messages.premiumAmountAria, paymentAmountsViewData.premium, policyInfo?.currentPeriod.policyNumber
            )
        },
        premiumIconTooltip: {
            visible: isIconButtonPremiumVisible(transactionsInfo),
            tooltipMessage: translator(messages.iconButtonPremiumTooltip),
            tooltipId: 'tooltipPremium'
        },
        // Installment Fees
        policyTerminstallmentFeesGrid: {
            visible: paymentAmountsViewData.installmentFees > 0
        },
        policyTermInstallmentFeesSrContent: {
            content: renderSrContent(
                messages.installmentFeesAria, paymentAmountsViewData.installmentFees, policyInfo?.currentPeriod.policyNumber
            )        
        },
        policyTerminstallmentFeesLabel: {
            content: translator(messages.installmentFees)
        },
        policyTerminstallmentFeesValueDiv: {
            content: renderPolicyTermInstallmentFeesValue(
                paymentAmountsViewData.installmentFeesCurrencyAmount
            )
        },
        // Other Charges
        policyTermOtherChargesGrid: {
            visible: paymentAmountsViewData.otherCharges > 0
        },
        policyTermOtherChargesSrContent: {
            content: renderSrContent(
                messages.otherChargesAria, paymentAmountsViewData.otherCharges, policyInfo?.currentPeriod.policyNumber
            )           
        },
        policyTermOtherChargesLabel: {
            content: translator(messages.otherCharges)
        },
        policyTermOtherChargesValueDiv: {
            content: renderPolicyTermOtherChargesValue(
                paymentAmountsViewData.otherChargesCurrencyAmount
            )
        },
        otherChargesIconTooltip: {
            tooltipMessage: getDisclosureOfFeesTooltipMessage(),
            tooltipId: 'tooltipOtherCharges',
            placement: 'right',
            flipBehavior: ['right']
        },
        // Total
        policyTermTotalSrContent: {
            content: renderSrContent(
                messages.policyTermAria, paymentAmountsViewData.policyTermTotal, policyInfo?.currentPeriod.policyNumber
            )           
        },
        policyTermTotalLabel: {
            content: translator(messages.total)
        },
        policyTermTotalValueDiv: {
            content: renderPolicyTermTotalValue(
                paymentAmountsViewData.policyTermTotalCurrencyAmount
            )
        },
        // REMAINING BALANCE SECTION
        remainingBalanceSectionContainer: {
            // Check if either or both of 'Amount carried to next Term' 
            // and 'Total Remaining Amount' are greater than 0.
            visible: 
                paymentAmountsViewData.totalRemainingBalance > 0
                || paymentAmountsViewData.amountCarriedToNextTerm > 0
        },
        remainingBalanceTitle: {
            content: translator(messages.remainingBalance)
        },
        // PENDING PAYMENTS SECTION
        ...pendingPaymentsOverrides,
        // Over Due Amount
        remainingBalanceOverDueAmountGrid: {
            visible: paymentAmountsViewData.overdueAmount > 0
        },
        remainingBalanceOverDueAmountTooltip: {
            tooltipMessage: translator(messages.futurePaymentsTooltip),
            tooltipId: 'futurePaymentsTooltip'
        },
        remainingBalanceOverDueAmountLabel: {
            content: translator(messages.overdueAmount)
        },
        remainingBalanceOverDueAmountValueDiv: {
            content: renderOverdueAmount(
                paymentAmountsViewData.overdueCurrencyAmount
            )
        },
        remainingBalanceOverDueAmountSrContent: {
            content: renderSrContent(
                messages.overdueAmountAria, paymentAmountsViewData.overdueAmount, policyInfo?.currentPeriod.policyNumber
            )    
        },
        // Payment Due
        remainingBalancePaymentDueGrid: {
            visible: paymentAmountsViewData.paymentDue > 0
        },
        remainingBalancePaymentDueSrContent: {
            content: renderSrContent(
                messages.remainingBalancePaymentDueAria, paymentAmountsViewData.paymentDue, policyInfo?.currentPeriod.policyNumber, getFormattedDate(billingInfo.nextDuePaymentDate),
            )
        },
        remainingBalancePaymentDueLabel: {
            content: paymentAmountsViewData.paymentDueLabel
        },
        remainingBalancePaymentDueValueDiv: {
            content: renderRemainingBalanceTotal(
                paymentAmountsViewData.paymentDueCurrencyAmount
            )
        },
        // Next Payment
        remainingBalanceNextPaymentGrid: {
            visible: paymentAmountsViewData.nextPayment > 0
        },
        remainingBalanceNextPaymentLabel: {
            content: paymentAmountsViewData.nextPaymentLabel
        },
        remainingBalanceNextPaymentSrContent: {
            content: renderSrContent(
                messages.nextPaymentAria, paymentAmountsViewData.nextPayment,  policyInfo?.currentPeriod.policyNumber, getFormattedDate(billingInfo?.nextPlannedAmountPaymentDate)
            )    
        },
        remainingBalanceNextPaymentValueDiv: {
            content: renderNextPaymentValue(
                paymentAmountsViewData.nextPaymentCurrencyAmount
            )
        },
        // Future Payments
        remainingBalanceFuturePaymentsGrid: {
            visible: (paymentAmountsViewData.futurePayments > 0) && !isMobile
        },
        remainingBalanceFuturePaymentsGridMobile: {
            visible: (paymentAmountsViewData.futurePayments > 0) && isMobile
        },
        remainingBalanceFuturePaymentsLabel: {
            content: translator(messages.futurePayments)
        },
        remainingBalanceFuturePaymentsValue: {
            value: {
                amount: paymentAmountsViewData.futurePayments,
                currency: getActualCurrency(billingInfo)
            },
            showFractions: true
        },
        // Amount carried to next Term
        remainingBalanceAmountCarriedToNextTermGrid: {
            visible: paymentAmountsViewData.amountCarriedToNextTerm > 0,
        },
        remainingBalanceAmountCarriedToNextTermLabel: {
            content: paymentAmountsViewData.amountCarriedToNextTermLabel
        },
        remainingBalanceAmountCarriedToNextTermValue: {
            value: {
                amount: paymentAmountsViewData.amountCarriedToNextTerm,
                currency: getActualCurrency(billingInfo)
            },
            showFractions: true
        },
        // Total
        remainingBalanceTotalLabel: {
            content: translator(messages.total)
        },
        remainingBalanceTotalValueDiv: {
            content: renderRemainingBalanceTotal(
                paymentAmountsViewData.totalRemainingBalanceCurrencyAmount
            )
        },
        remainingBalanceTotalSrContent: {
            content: renderSrContent(
                messages.remainingBalanceTotalAria, paymentAmountsViewData.totalRemainingBalance, policyInfo?.currentPeriod.policyNumber
            )
        },
        // PAYMENT HISTORY SECTION
        noPaymentHistoryMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.noPaymentHistoryMessage)),
            visible: !paymentAmountsViewData.paymentHistory?.length
        },
        paymentHistoryMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.paymentHistoryMessage)),
            visible: paymentAmountsViewData.paymentHistory?.length > 0
        },
        paymentHistoryIterable: {
            data: paymentAmountsViewData.paymentHistory
        },
        paymentHistoryAccordionCard: {
            title: getHeadPaymentHistory()
        },
        isMarPaymentPlan: {
            content: WMICRichTextUtil.translateRichText(translator(messages.automaticallyWithdrawn)),
            visible: WMICPaymentUtil.isMARPayment(billingInfo)
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={billingInfo}
        />
    );
};

WMICBillingInfoBox.propTypes = {
    billingData: PropTypes.shape({}),
    policyData: PropTypes.shape({}),
    policyTransactions: PropTypes.shape({}),
    mostRecentBillingDocument: PropTypes.shape({}),
    updateBillingDetails: PropTypes.func.isRequired,
    fetchPolicyData: PropTypes.func,
    ldFlags: PropTypes.shape({}).isRequired,
    isRenewal: PropTypes.bool.isRequired,
};

WMICBillingInfoBox.defaultProps = {
    billingData: {},
    policyData: {},
    policyTransactions: {},
    mostRecentBillingDocument: {},
    isRenewal: false,
};

export default WMICBillingInfoBox;