// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ProductZipCode__productInputContainer{display:grid;grid-template-columns:100%;grid-template-rows:auto auto auto;justify-content:center;grid-row-gap:var(--line-of-business-space);grid-template-areas:\"postalCode\" \"startQuote\" \"retrieveQuote\"}.app__ProductZipCode__postalCode{grid-area:postalCode;margin:auto;width:var(--line-of-business-item-width)}.app__ProductZipCode__postalCode,.app__ProductZipCode__postalCodeContainer{text-align:center}.app__ProductZipCode__startQuote{grid-area:startQuote;margin:auto}.app__ProductZipCode__retrieveQuote{grid-area:retrieveQuote;text-align:center}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-common-react/components/LineOfBusiness/ProductZipCode/ProductZipCode.module.scss"],"names":[],"mappings":"AAAA,4CACI,YAAA,CAEA,0BAAA,CADA,iCAAA,CAEA,sBAAA,CACA,0CAAA,CACA,6DACI,CAKR,iCAII,oBAAA,CAFA,WAAA,CACA,wCACA,CAGJ,2EANI,iBAOA,CAGJ,iCACI,oBAAA,CACA,WAAA,CAGJ,oCACI,uBAAA,CACA,iBAAA","sourcesContent":[".productInputContainer {\n    display: grid;\n    grid-template-rows: auto auto auto;\n    grid-template-columns: 100%;\n    justify-content: center;\n    grid-row-gap: var(--line-of-business-space);\n    grid-template-areas:\n        \"postalCode\"\n        \"startQuote\"\n        \"retrieveQuote\";\n}\n\n.postalCode {\n    text-align: center;\n    margin: auto;\n    width: var(--line-of-business-item-width);\n    grid-area: postalCode;\n}\n\n.postalCodeContainer {\n    text-align: center;\n}\n\n.startQuote {\n    grid-area: startQuote;\n    margin: auto;\n}\n\n.retrieveQuote {\n    grid-area: retrieveQuote;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productInputContainer": "app__ProductZipCode__productInputContainer",
	"postalCode": "app__ProductZipCode__postalCode",
	"postalCodeContainer": "app__ProductZipCode__postalCodeContainer",
	"startQuote": "app__ProductZipCode__startQuote",
	"retrieveQuote": "app__ProductZipCode__retrieveQuote"
};
export default ___CSS_LOADER_EXPORT___;
