import { defineMessages } from 'react-intl';

export default defineMessages({

    changeAddress: {
        id: 'wmic.policy-change.component.address-pa.Change of Address',
        defaultMessage: 'Change of Address'
    },

    cancelEdit: {
        id: 'wmic.policy-change.component.address-pa.Cancel Edit',
        defaultMessage: 'Cancel'
    },

    continue: {
        id: 'wmic.policy-change.component.address-pa.Continue',
        defaultMessage: 'Continue'
    },

    submit: {
        id: 'wmic.policy-change.component.address-pa.submit',
        defaultMessage: 'Submit'
    },

    policyChangeReady: {
        id: 'wmic.policy-change.component.address-pa.policyChangeReady',
        defaultMessage: 'Your policy change is ready to submit'
    },

    submitInfo: {
        id: 'wmic.policy-change.component.address-pa.submitInfo',
        defaultMessage: 'Once submitted, the agent will contact you shortly to finish processing this change. After it is fully processed, the agent will confirm with you when the change will take effect.'
    },

});
