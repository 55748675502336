
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import { WMICGLLiabilityPage, WMICGLAdditionalCoveragesPage, WMICGLBusinessOperationsPage } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';
import WMICCPQualificationPage from './pages/WMICCPQualificationPage/WMICCPQualificationPage';
import WMICPropertyPage from './pages/WMICPropertyPage/WMICPropertyPage';
import WMICCPInsuranceHistoryPage from './pages/WMICCPInsuranceHistory/WMICCPInsuranceHistoryPage';
import WMICCPLocationsBuildingsPage from './pages/WMICCPLocationsBuildingsPage/WMICCPLocationsBuildingsPage';
import WMICCPQuotePage from './pages/WMICCPQuotePage/WMICCPQuote';
import WMICCPPolicyDetailsPage from './pages/WMICCPPolicyDetails/WMICCPPolicyDetailsPage';
import WMICCPAdditionalInterestDetailView from './pages/WMICCPLocationsBuildingsPage/WMICCPAdditionalInterestDetailView/WMICCPAdditionalInterestDetailView';
import WMICLocationDetailView from './pages/WMICCPLocationsBuildingsPage/WMICLocationDetailView/WMICLocationDetailView';

setComponentMapOverrides(
    {
        WMICCPQualificationPage: { component: 'WMICCPQualificationPage' },
        WMICGLBusinessOperationsPage: { component: 'WMICGLBusinessOperationsPage' },
        WMICPropertyPage: { component: 'WMICPropertyPage' },
        WMICGLLiabilityPage: { component: 'WMICGLLiabilityPage' },
        WMICCPInsuranceHistoryPage: { component: 'WMICCPInsuranceHistoryPage' },
        WMICCPQuotePage: { component: 'WMICCPQuotePage' },
        WMICCPPolicyDetailsPage: { component: 'WMICCPPolicyDetailsPage'},
        WMICCPLocationsBuildingsPage: { component: 'WMICCPLocationsBuildingsPage' },
        WMICGLAdditionalCoveragesPage: { component: 'WMICGLAdditionalCoveragesPage' },
        WMICCPAdditionalInterestDetailView: { component: 'WMICCPAdditionalInterestDetailView' },
        WMICLocationDetailView: { component: 'WMICLocationDetailView'},
    },
    {
        WMICCPQualificationPage,
        WMICGLBusinessOperationsPage,
        WMICPropertyPage,
        WMICCPInsuranceHistoryPage,
        WMICCPQuotePage: WMICCPQuotePage,
        WMICCPPolicyDetailsPage: WMICCPPolicyDetailsPage,
        WMICCPLocationsBuildingsPage,
        WMICGLLiabilityPage,
        WMICGLAdditionalCoveragesPage,
        WMICCPAdditionalInterestDetailView,
        WMICLocationDetailView,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PECPWizard } from './PECPWizard';
export { default as CPSubmissionLobDataExtension } from './extension/CPSubmissionLobDataExtension';

export { default as WMICTradeNameComponent } from './components/WMICTradeNameComponent/WMICTradeNameComponent';
export { default as WMICCPLocationCoverages } from './components/WMICCPLocationCoverages/WMICCPLocationCoverages';
export { default as WMICCPBuildingCoverages } from './components/WMICCPBuildingCoverages/WMICCPBuildingCoverages';
export { default as WMICCPCoverageBuildings } from './components/WMICCPCoverageBuildings/WMICCPCoverageBuildings';
export { default as WMICCPAdditionalInterestDetailView } from './pages/WMICCPLocationsBuildingsPage/WMICCPAdditionalInterestDetailView/WMICCPAdditionalInterestDetailView';
export { default as WMICLocationDetailView } from './pages/WMICCPLocationsBuildingsPage/WMICLocationDetailView/WMICLocationDetailView';
