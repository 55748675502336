/* eslint-disable max-len */
import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { WMICQuestionSetComponent } from 'wmic-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    DeviceBreakpoint, FlowStepId, Position, LOBConstants
} from 'wmic-portals-utils-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { WMICCustomRadioButton, WMICProgressModal } from 'gw-capability-quoteandbind-common-react';
import util from './WMICQualificationPageQnBUtil';
import metadata from './WMICQualificationPageQnB.metadata.json5';
import messages from './WMICQualificationPageQnB.messages'

const LOB_PATH = {
    PersonalAuto: 'lobData.personalAuto.preQualQuestionSets.children[0].value',
    Homeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    HOPHomeowners: 'lobData.homeowners.preQualQuestionSets.children[0].value',
    BusinessOwners: 'lobData.businessOwners.preQualQuestionSets.children[0].value',
    BusinessAuto: 'lobData.businessOwners.preQualQuestionSets.children[0].value',
    PersonalUmbrella: 'lobData.personalUmbrella.preQualQuestionSets.children[0].value'
};

function WMICQualificationPageQnB(props) {
    const modalApi = useModal();
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { wizardData: submissionVM, updateWizardData } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const {
        registerInitialComponentValidation,
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('QualificationPage');
    const [showErrors, setShowErrors] = useState(false);
    const lobCode = _.get(submissionVM.value, 'baseData.productCode');

    const onNext = useCallback(async () => {
        if (isComponentValid) {
            _.unset(submissionVM.value, 'bindData');
            submissionVM.value.flowStepId_WMIC = FlowStepId.PRE_QUALIFICATION;


            const updatePromise = LoadSaveService.updateDraftSubmission(submissionVM.value, authHeader);
            submissionVM.value = await modalApi.showModal(
                <WMICProgressModal
                    modalTitle={translator(messages.savingSubmission)}
                    isOpen
                    promise={updatePromise}
                />
            ).then((response) => {
                return response;
            })
            return submissionVM;
        }
        setShowErrors(true);
        return false;
    }, [isComponentValid, submissionVM, LoadSaveService, authHeader, translator]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP
        },
        questionSets: {
            path: LOB_PATH[lobCode],
            showErrors: showErrors,
            usesRadioButtons: true,
            questionsToOmit: ['PrimaryResidence_wmic'],
            labelPosition: 'left'
        }
    };

    const resolvers = {
        resolveComponentMap: {
            questionset: WMICQuestionSetComponent,
            wmicCustomRadioButton: WMICCustomRadioButton
        },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const shouldSkipBopPage = useCallback(() => {
        return util.shouldSkipBopPage(submissionVM, lobCode);
    }, [lobCode, submissionVM]);

    const hasLicenseNumberForPADriver = useCallback(() => {
        return util.hasLicenseNumberForPADriver(submissionVM);
    }, [submissionVM]);

    useEffect(() => {
        if (lobCode === LOBConstants.PA) {
            registerInitialComponentValidation(hasLicenseNumberForPADriver);
        } else if (lobCode === 'BusinessOwners') {
            registerInitialComponentValidation(shouldSkipBopPage);
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showEmailQuoteButton={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

WMICQualificationPageQnB.propTypes = wizardProps;
export default WMICQualificationPageQnB;