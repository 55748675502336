let mobileViewLinks;

const setMobileViewLinks = (linksFragment) => {
    mobileViewLinks = linksFragment;
}

const getMobileViewLinks = () => {
    return mobileViewLinks;
};

export default {
    setMobileViewLinks,
    getMobileViewLinks
};