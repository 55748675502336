/* eslint-disable no-secrets/no-secrets */

import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WMICTradeNameComponent } from 'wmic-pe-capability-gateway-quoteandbind-cp-react';
import { WMICRequiredText } from 'wmic-pe-components-platform-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';

import {
    WMICUserAccessUtil,
    WMICEDocsService,
    WMICJurisdictionUtil,
    WMICCreditConsentUtil,
    WMICDateTimeService,
    WMICRPCUtil,
    LINE_OF_BUSINESS,
    POLICY_DETAILS,
    JURISDICTIONS,
    CONSTANTS,
    WMICRichTextUtil,
    JobType,
    MODAL_CONSTANTS,
} from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';

import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';

import WMICNamedInsuredComponent from '../WMICNamedInsuredComponent/WMICNamedInsuredComponent';

import metadata from './WMICPolicyDetailsComponent.metadata.json5';
import styles from './WMICPolicyDetailsComponent.module.scss';
import messages from './WMICPolicyDetailsComponent.messages.js'

let initialPNIDateOfBirth;

const WMICPolicyDetailsComponent = (props) => {
    const {
        jobVM,
        updateWizardData,
        onValidate,
        authHeader,
        launchDefaultAnswers,
        showErrors,
        isReadOnly
    } = props;


    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, onValidate: setComponentValidation, registerComponentValidation } = useValidation('policyDetailsComponent');
    const { setWizardLoading, showWarning } = useWizardModals();

    const [isInitialized, setInitialized] = useState(false);

    const [isEditFormOpen, setEditFormOpen] = useState(false);
    const [showAdditionalInsured, updateShowAdditionalInsured] = useState(false);
    const [isANIDOBsValid, updateIsANIDOBsValid] = useState(false);
    const [effectiveDate, updateEffectiveDate] = useState(WMICDateTimeService.localDate2Date(_.get(jobVM, 'baseData.periodStartDate.value')));
    const [creditConsentFieldsVisible, updateCreditConsentFieldsVisible] = useState(false);
    const [linkedContact, setLinkedContact] = useState(false);
    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);
    const { authUserData: currentUser } = useAuthentication();

    const canEditPolicyChange = useMemo(
        () => WMICUserAccessUtil.canEditPolicyChange(currentUser.roles),
        [currentUser]
    );

    const isBMSi = _.get(jobVM, "isFromBMS_WMIC.value");
    const initVehicles = _.get(jobVM, 'lobData.personalAuto.coverables.vehicles.value');
    const lob = _.get(jobVM, 'lob.value.code');
    const TERM_TYPE_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('TermType');
    const baseData = _.get(jobVM, 'baseData');
    const isSubmission = _.get(jobVM, 'baseData.jobType.value.code') === JobType.SUBMISSION;
    const isPolicyChange = _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE;
    const isRenewal = _.get(jobVM, 'baseData.jobType.value.code') === JobType.RENEWAL;
    const industryCodeValue = _.get(jobVM, 'lobData.commercialProperty.industryCode.value');
    useEffect(() => {
        if (isInitialized && onValidate) {
            onValidate(isComponentValid, 'policyDetailsComponent');
        }
    }, [isComponentValid, onValidate, isInitialized])

    const isEditFormOpenFunc = useCallback(() => !isEditFormOpen && !showAdditionalInsured, [isEditFormOpen, showAdditionalInsured])

    const areFieldsValid = useCallback(() => {
        const addressVM = _.get(jobVM, 'baseData.policyAddress')

        if (!addressVM) {
            return true;
        }

        return addressVM.aspects && addressVM.aspects.valid && addressVM.aspects.subtreeValid;
    }, [jobVM]);

    useEffect(() => {
        registerComponentValidation(isEditFormOpenFunc, areFieldsValid)
    }, [registerComponentValidation, isEditFormOpenFunc, areFieldsValid])

    const showDefaultAnswersButton = () => !!_.get(jobVM, 'baseData.value.enableDefaultButton_WMIC')

    const isPA = useCallback(() => lob === LINE_OF_BUSINESS.PERSONAL_AUTO, [lob]);

    const isHO = useCallback(() => lob === LINE_OF_BUSINESS.HOME_OWNER, [lob]);

    const isPUP = useCallback(() => lob === LINE_OF_BUSINESS.PERSONAL_UMBRELLA, [lob]);

    const isCP = useCallback(() => lob === LINE_OF_BUSINESS.GENERAL_LIABILITY, [lob]);

    const isRPCEffective = useCallback((...rpcNumbers) => {
        const effective = WMICRPCUtil.getIsRPCEffective(_.get(jobVM, 'baseData.baseState.value.code'), jobVM.baseData.rateAsOfDate.value, ...rpcNumbers);
        return effective;
    }, [jobVM]);

    const checkANIDOBsValid = useCallback(() => {
        let valid = true;
        if (isHO() && effectiveDate && isRPCEffective('1222')) {
            const anis = _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.value');
            for (let i = 0; valid && i < anis.length; i++) {
                if (anis[i].subtype === CONSTANTS.Person) {
                    valid = anis[i].dateOfBirth !== undefined;
                }
            }
        }
        return valid;
    }, [jobVM, effectiveDate, isRPCEffective, isHO]);

    const isPersonPNI = useCallback(() => _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.subtype.value') === CONSTANTS.Person, [jobVM]);

    const showCreditConsentInfoPNI = () => {
        if (!WMICCreditConsentUtil.isPniCreditConsentable(_.get(jobVM, 'baseData.primaryNamedInsured_WMIC'))) {
            return false;
        }

        if (isPA()
            && (WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)
                || (isRPCEffective('1296') && !isRPCEffective('1576')))) {
            return isPersonPNI();
        }

        return isHO() && isPersonPNI() && creditConsentFieldsVisible;
    };

    const getNewExpiryDate = (monthsToExpiry, effectiveDateParam) => {
        const newExpiryDate = moment(effectiveDateParam).add(monthsToExpiry, 'M').toDate();
        return newExpiryDate ? {
            year: newExpiryDate.getFullYear(),
            month: newExpiryDate.getMonth(),
            day: newExpiryDate.getDate()
        } : undefined;
    };

    const showWarningModal = () => {
        showWarning({
            title: messages.alerts,
            message: translator(messages.changeEffectiveDateWarning),
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.ERROR,
            confirmButtonText: commonMessages.ok,
        });
    };

    const updateExpiryDate = (value, path) => {

        setWizardLoading(true, translator(messages.loading));

        if (isBMSi && maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true)
        }

        _.set(jobVM, path, value);
        updateWizardData(jobVM)

        const termType = _.get(jobVM, 'baseData.termType.value');
        const startDate = _.get(jobVM, 'baseData.periodStartDate.value');

        if (!WMICDateTimeService.isDateDTOValid(startDate)) {
            setWizardLoading(false);
            return;
        }

        if (termType === TERM_TYPE_TYPELIST.getCode(CONSTANTS.TERM_TYPES.ANNUAL)) {
            _.set(jobVM, 'baseData.periodEndDate.value', getNewExpiryDate(12, startDate))
        } else if (termType === TERM_TYPE_TYPELIST.getCode(CONSTANTS.TERM_TYPES.HALF_YEAR)) {
            _.set(jobVM, 'baseData.periodEndDate.value', getNewExpiryDate(6, startDate))
        }

        _.set(jobVM, 'baseData.rateAsOfDate.value', startDate)
        _.set(jobVM, 'baseData.inceptionDate_WMIC.value', startDate)

        updateEffectiveDate(WMICDateTimeService.localDate2Date(startDate));
        updateIsANIDOBsValid(checkANIDOBsValid());

        updateCreditConsentFieldsVisible(!WMICCreditConsentUtil.isHOCreditConsentTabVisibleOnInsuranceHistory(effectiveDate));

        setWizardLoading(false);

        if (isBMSi) {
            showWarningModal();
        }
    };

    const setCreditConsentInfoPNI = (data) => {
        const today = new Date();
        if (!_.isUndefined(_.get(jobVM, 'baseData.creditConsentReceived.value'))) {
            if (_.isUndefined(_.get(jobVM, 'baseData.creditConsentDate.value'))) {
                _.set(jobVM, 'baseData.creditConsentDate.value', {
                    year: today.getFullYear(),
                    month: today.getMonth(),
                    day: today.getDate()
                }
                );
            }
            return _.get(jobVM, 'baseData.creditConsentReceived.value.code') === data;
        }
        return false;
    };

    const isConsentNotReceivedPrimaryNamedInsured = () => isPersonPNI() && setCreditConsentInfoPNI(POLICY_DETAILS.NO);

    const isCreditInfoWithdrawalConsentApplicable = (creditConsentReceived) => {
        const consentOption = (creditConsentReceived && creditConsentReceived.code) || creditConsentReceived;
        const rateAsOfDate = _.get(jobVM, 'baseData.rateAsOfDate.value');
        const baseState = _.get(jobVM, 'baseData.baseState.value.code');
        return WMICCreditConsentUtil.isCreditInfoWithdrawalConsentApplicable(consentOption, rateAsOfDate, baseState, lob, initVehicles);
    };

    const isConsentReceivedPrimaryNamedInsured = () => isPersonPNI() && setCreditConsentInfoPNI(POLICY_DETAILS.YES);

    const mapIndustryCode = (industryCodeDTO) => {
        const autoDeclined = industryCodeDTO.smallBusinessAutoDecline_WMIC;

        return {
            displayName: `${industryCodeDTO.code} - ${industryCodeDTO.classification}`,
            id: industryCodeDTO.publicId,
            code: industryCodeDTO.code,
            type: autoDeclined === true ? 'unsupported' : 'supported',
            industryCode: industryCodeDTO
        };
    };

    const setIndustryCode = useCallback((selectedIndustryCode) => {
        if (selectedIndustryCode) {
            _.set(jobVM, 'lobData.commercialProperty.industryCode.value', selectedIndustryCode.industryCode);
            _.set(jobVM, 'lobData.commercialProperty.smallBusinessAutoDecline_WMIC.value', selectedIndustryCode.industryCode.smallBusinessAutoDecline_WMIC);
        } else {
            const emptyIndustryCode = {
                classification: undefined,
                code: undefined,
                domain: undefined,
                isSmallBusiness_WMIC: undefined,
                smallBusinessAutoDecline_WMIC: undefined,
                publicId: undefined
            };

            _.set(jobVM, 'lobData.commercialProperty.industryCode.value', emptyIndustryCode);
            _.set(jobVM, 'lobData.commercialProperty.smallBusinessAutoDecline_WMIC.value', emptyIndustryCode.smallBusinessAutoDecline_WMIC);
        }

        _.set(jobVM, 'lobData.commercialProperty.businessOperation.value', selectedIndustryCode);
    }, [jobVM]);

    const updateIndustryCode = useCallback((selectedIndustryCode) => {
        setIndustryCode(selectedIndustryCode);

        if (selectedIndustryCode != null) {
            _.set(jobVM, "lobData.commercialProperty.busOpsDesc_Ext.value", selectedIndustryCode.industryCode.classification);
        }
        updateWizardData(jobVM);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobVM, setIndustryCode]);

    if (!isInitialized) {
        if (_.isEmpty(jobVM.baseData)) {
            jobVM.baseData = ViewModelService.create({}, 'pc', 'wmic.edge.ca.capabilities.quote.submission.base.dto.QuoteBaseDataDTO');
            updateWizardData(jobVM);
        }

        const businessOperation = _.get(jobVM, 'lobData.commercialProperty.businessOperation.value');
        if (_.isNil(businessOperation)) {
            const selectedIndustryCode = _.get(jobVM, 'lobData.commercialProperty.industryCode.value');
            if (!_.isEmpty(selectedIndustryCode)) {
                const mappedIndustryCode = mapIndustryCode(selectedIndustryCode);
                _.set(jobVM, 'lobData.commercialProperty.businessOperation.value', mappedIndustryCode)
            }
        }

        let rateAsOfDate = _.get(jobVM, 'baseData.rateAsOfDate.value');
        if (_.isNil(rateAsOfDate)) {
            rateAsOfDate = WMICDateTimeService.today();
            _.set(jobVM, 'baseData.rateAsOfDate.value', rateAsOfDate);
        }

        WMICEDocsService.init(lob);
        updateIsANIDOBsValid(checkANIDOBsValid());
        initialPNIDateOfBirth = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.dateOfBirth.value')

        setInitialized(true);
    } else if (isCP() && !jobVM.aspects.subtreeValid) {

        const businesOperation = _.get(jobVM, 'lobData.commercialProperty.businessOperation.value');
        const industryCode = _.get(jobVM, 'lobData.commercialProperty.industryCode.code.value');

        if (businesOperation === undefined && industryCode !== undefined) {

            const selectedIndustryCode = _.get(jobVM, 'lobData.commercialProperty.industryCode.value');
            if (!_.isEmpty(selectedIndustryCode)) {
                setIndustryCode(mapIndustryCode(selectedIndustryCode));
                updateWizardData(jobVM);
            }
        }
    }

    const searchIndustryCodes = useCallback(
        async (filter) => {
            let results = [];

            if (filter.length >= 3) {
                const jobNumber = _.get(jobVM, 'quoteID.value');
                const industryCodes = await UISupportingInfoLookupService.retrieveIndustryCodes(jobNumber, filter, filter, true, authHeader);
                results = industryCodes.map(mapIndustryCode);
            }

            return results;
        },
        [authHeader, jobVM]
    );

    const changedCreditConsent = (value, path) => {
        _.set(jobVM, path, value)
        if (_.get(jobVM, 'baseData.creditConsentReceived.value')) {
            const required = _.get(jobVM, 'baseData.creditConsentReceived.value.code') === POLICY_DETAILS.YES;
            _.set(jobVM, 'baseData.primaryNamedInsured_WMIC.dateOfBirthIsRequired_WMIC.value', required);

            if (!required) {
                _.set(jobVM, 'baseData.primaryNamedInsured_WMIC.dateOfBirth.value', initialPNIDateOfBirth);
            }
        }
        updateWizardData(jobVM)
    };

    const showPNIDateOfBirth = () => isPersonPNI()
        && (isHO()
            || (isPA()
                && (WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)
                    || isRPCEffective('1296') || isRPCEffective('1576')
                )));

    const handleSetEditFormOpen = (isEditingANI) => {
        _.set(jobVM, "isEditingANI.value", isEditingANI)
        updateWizardData(jobVM)
        updateShowAdditionalInsured(isEditingANI)
    }

    const overrideProps = {
        '@field': {
            parentNode: jobVM
        },
        policyDetailsSubmissionContainer: {
            visible: isSubmission
        },
        defaultAnswers: {
            visible: showDefaultAnswersButton() && !isCP()
        },
        aniTitle: {
            title: !isSubmission ? translator(messages.aniTitlePolicyChange) : translator(messages.aniTitle)
        },
        nameInsuredComponentId: {
            updateWizardData,
            jobVM,
            onValidate: setComponentValidation,
            setEditFormOpen,
            isCreditInfoWithdrawalConsentApplicable,
            authHeader,
            lob,
            showAdditionalInsured,
            updateShowAdditionalInsured: handleSetEditFormOpen,
            showCreditConsentInfoPNI,
            isRPCEffective,
            showErrors,
            isReadOnly,
            setLinkedContact
        },
        tradeNameComponentId: {
            visible: isCP(),
            updateWizardData,
            submissionVM: jobVM,
            onValidate: setComponentValidation,
            setEditFormOpen,
            authHeader,
            lob
        },
        effDate: {
            readOnly: showAdditionalInsured,
            maxDate: _.get(jobVM, 'baseData.maxPeriodStartDate_WMIC.value'),
            minDate: _.get(jobVM, 'baseData.minPeriodStartDate_WMIC.value'),
        },
        inceptionDate: {
            visible: !isPUP(),
            readOnly: showAdditionalInsured,
        },
        PUPContainer: {
            visible: isPUP()
        },
        notPUPContainer: {
            visible: !isPUP()
        },
        CPContainer: {
            visible: isCP()
        },
        showCreditConsentContainer: {
            visible: showCreditConsentInfoPNI()
        },
        declineDate: {
            visible: isConsentNotReceivedPrimaryNamedInsured()
        },
        creditInfoWithdrawalConsent: {
            visible: isCreditInfoWithdrawalConsentApplicable(_.get(jobVM, 'baseData.creditConsentReceived.value'))
        },
        isConsentReceivedPrimaryContainer: {
            visible: isConsentReceivedPrimaryNamedInsured()
        },
        pniDOB: {
            visible: showPNIDateOfBirth() && isSubmission,
            maxDate: new Date()
        },
        regOwner: {
            visible: isPA() && isRPCEffective('1599', '1661')
        },
        checkDrivers: {
            visible: linkedContact
        },
        isANIDOBsValid: {
            visible: !isANIDOBsValid,
            content: WMICRichTextUtil.translateRichText(translator(messages.aniDOB))
        },
        expDate: {
            defaultValue: WMICDateTimeService.dateYearsAgo(WMICDateTimeService.today(), -1)
        },
        writeDate: {
            defaultValue: WMICDateTimeService.today()
        },
        rateDate: {
            defaultValue: WMICDateTimeService.today()
        },
        pupCoverages: {
            coverageVM: jobVM,
            updateCoverageData: updateWizardData,
            onValidate,
            coveragePath: 'lobData.personalUmbrella.coverables.liabilityCoverages',
            readOnly: !canEditPolicyChange,
        },
        policyInformationCP: {
            visible: isCP()
        },
        polDocPrefLang_WMIC: {
            visible: isCP()
        },
        tradeNameTitle: {
            visible: isCP()
        },
        primaryBusinessOperation: {
            required: _.get(jobVM, 'lobData.commercialProperty.industryCode.aspects.required'),
            availableValues: [
                industryCodeValue !== undefined ? mapIndustryCode(industryCodeValue) : null
            ],
            optionTypes: [
                {
                    className: "ww_industrycode_supported",
                    displayName: translator(messages.industryCodeSupported),
                    type: "supported",
                    icon: "gw-check"
                },
                {
                    className: "ww_industrycode_unsupported",
                    displayName: translator(messages.industryCodeUnsupported),
                    type: "unsupported",
                    icon: "gw-clear"
                },
            ]
        },
        portfolioTransfer: {
            disabled: isPolicyChange || isRenewal,
            readOnly: !canEditPolicyChange,
        },
        brokerID: {
            readOnly: !canEditPolicyChange
        }
    };


    const resolvers = {
        resolveComponentMap: {
            WMICRequiredText,
            WMICNamedInsuredComponent,
            WMICTradeNameComponent,
        },
        resolveCallbackMap: {
            updateExpiryDate,
            changedCreditConsent,
            launchDefaultAnswers,
            searchIndustryCodes,
            updateIndustryCode
        },
        resolveClassNameMap: styles
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onModelChange={updateWizardData}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

export default WMICPolicyDetailsComponent;





