import { defineMessages } from 'react-intl';

export default defineMessages({
    invalidRoutingTransitNumber: {
        id: 'payment.views.invalid-transit-number.Invalid routing transit number',
        defaultMessage: 'Invalid routing transit number'
    },
    planChangeUnsuccessful: {
        id: 'payment.views.invalid-transit-number.Payment plan change unsuccessful',
        defaultMessage: 'Payment plan change unsuccessful'
    },
    theRoutingTransitNumberEnteredIsNotValid: {
        id: 'payment.views.invalid-transit-number.The Routing Transit Number entered is not valid. Please re-enter the transit number to try your payment again or select another payment method.',
        defaultMessage: 'The Routing Transit Number entered is not valid. Please re-enter the transit number to try your payment again or select another payment method.'
    },
    somethingWentWrong: {
        id: 'payment.views.invalid-transit-number.Something went wrong. Please try again later.',
        defaultMessage: 'Something went wrong. Please try again later.'
    },
    close: {
        id: 'payment.views.invalid-transit-number.Close',
        defaultMessage: 'Close'
    }
});