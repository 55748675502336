import _ from 'lodash';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';

import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { MockUpUtil } from '@xengage/gw-portals-util-js'
import { WizardModalsProvider, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { ClauseService } from 'wmic-pe-capability-policycommon';
import { CONSTANTS, WMICValidationUtil, MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';
import { WMICWizardHandler } from 'wmic-pe-capability-gateway-common-react';
import { messages as commonSubmissionMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react'

import wizardStepToFieldMapping from './config/cp-wizard-step-to-field-mapping.json5';
import wizardConfig from './config/cp-wizard-config.json5';
import messages from './PECPWizard.messages';


const PATH_TO_MOCK_DATA = 'quote.cp';
const MOCK_DATA_TO_SET = ['bindData.contactPhone'];
const MOCK_DATA_TO_REMOVE = ['bindData.contactPhone'];

function checkValidity(submissionVM, extractVMObject, currentStep) {
    const checkValidityPathsForCurrentStep = _.get(currentStep, 'checkValidityPaths', []);

    // Cloning checkValidityPaths to avoid adding duplicates to array
    const checkValidityPaths = _.clone(checkValidityPathsForCurrentStep);

    return WMICValidationUtil.checkValidity(submissionVM, checkValidityPaths);
}

function PECPWizard(props) {
    const { steps, title } = wizardConfig;
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { viewModelService } = props;
    const history  = useHistory();
    const location = useLocation();
    const translator = useContext(TranslatorContext);
    const { showConfirm, showError } = useWizardModals()

    useEffect(
        () => {
            const viewModelContext = {
                AccountEmailRequired: false,
                DriverEmailRequired: true,
                AccountDOBRequired: false
            };
            if (!location.state) {
                history.push('/');
                return;
            }

            const { quoteentry } = location.state;
            const { postalCode, quoteID } = quoteentry;
            const requestData = {
                quoteID: quoteID,
                postalCode: postalCode
            };
            LoadSaveService.retrieveSubmission(requestData, authHeader)
                .then((response) => {
                    return MockUpUtil.cleanUpMockedProperties(
                        response,
                        PATH_TO_MOCK_DATA,
                        ...MOCK_DATA_TO_REMOVE
                    );
                })
                .then((response) => {
                    response.persons = [response.baseData.accountHolder];
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(response, 'quoteID'),
                        authHeader
                    );
                    const submission = viewModelService.create(
                        response,
                        'pc',
                        'wmic.edge.ca.capabilities.quote.submission.dto.QuoteDataDTO',
                        viewModelContext
                    );
                    setInitialSubmission(submission);
                    setIsLoading(false);
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleCancel = useCallback(
        ({ wizardSnapshot, param: nextPath = '', wizardData }) => {
            const skipModal = CONSTANTS.SKIP_CANCEL_MODAL;
            const lastPathName = nextPath.replace(/\/?.*\//g, '');
            const isStatusBound = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === CONSTANTS.BOUND;
            if (isStatusBound || skipModal.includes(lastPathName) || hasErrorOccurred) {
                return true;
            }
            return showConfirm({
                title: commonSubmissionMessages.leaveWithoutSaving,
                message: commonSubmissionMessages.infoWillBeSavedAsDraft,
                status: MODAL_CONSTANTS.STATUS.WARNING,
                icon: MODAL_CONSTANTS.ICON.WARNING,
                confirmButtonText: commonMessages.yes,
                cancelButtonText: commonMessages.no
            }).then((results) => {
                if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                    return _.noop();
                }
                const isStatusQuoted = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === CONSTANTS.QUOTED;

                let serverCall = LoadSaveService.updateDraftSubmission;

                if (isStatusQuoted) {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const isValid = wizardData.lobData.aspects.valid
                    && wizardData.lobData.aspects.subtreeValid;

                const payload = isValid
                    ? _.get(wizardData, 'value')
                    : _.get(wizardSnapshot, 'value');
                const payloadBeforeSave = _.omit(payload, 'bindData.paymentDetails');
                const dataToSave = MockUpUtil.setMockData(
                    payloadBeforeSave,
                    PATH_TO_MOCK_DATA,
                    ...MOCK_DATA_TO_SET
                );

                setIsLoading(true);

                const redirectPath = `/quotes/${dataToSave.quoteID}/summary`;
                serverCall(dataToSave, authHeader)
                    .then(() => {
                        history.push(nextPath || redirectPath);
                    })
                    .catch(() => {
                        showError({
                            title: messages.anErrorOccurredTitle,
                            message: messages.anErrorOccurred,
                            status: MODAL_CONSTANTS.STATUS.ERROR,
                            icon: MODAL_CONSTANTS.ICON.ERROR,
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                        history.push(nextPath || redirectPath);
                    });
                return true;
            }, _.noop);
        },
        [authHeader, hasErrorOccurred, history, showError]
    );

    const handleError = useCallback((error) => {
        WMICWizardHandler.handleSubmissionError(error, location, history, setHasErrorOccurred);
    }, [history, location]);

    const handleKnockout = useCallback(({ knockOutErrors, wizardData }) => {
        const quoteID = _.get(wizardData, 'quoteID.value');
        history.push('/knockoutpage', {
            underwritingIssues: knockOutErrors,
            quoteID,
            lob: 'cp',
            account: {
                displayName: _.get(wizardData.value, 'baseData.accountHolder.displayName'),
                displayAddress: _.get(wizardData.value, 'baseData.accountHolder.primaryAddress.displayName') || '',
                accountNumber: _.get(wizardData.value, 'baseData.accountNumber')
            }
        });
    }, [history]);

    const handleFinish = useCallback((wizardProps) => {
        const {
            wizardData
        } = wizardProps;
        history.push({
            pathname: `/quotes/${wizardData.value.quoteID}/summary`,
            props: wizardData.value
        });
    }, [history]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <WizardModalsProvider defaultMessage={translator({
                id: 'quoteandbind.views.quote-effective-date.Saving Quote Submission Details',
                defaultMessage: 'Saving Quote Submission Details'
            })}>
                <Wizard
                    wizardTitle={title}
                    initialSteps={steps}
                    initialData={initialSubmission}
                    onKnockOut={handleKnockout}
                    onFinish={handleFinish}
                    onCancel={handleCancel}
                    checkValidity={checkValidity}
                    skipCompletedSteps
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    onPreviousModalProps={{
                        title: commonMessages.wantToJump,
                        message: commonMessages.wantToJumpMessage,
                        status: MODAL_CONSTANTS.STATUS.WARNING,
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.yesModel,
                        cancelButtonText: commonMessages.cancelModel
                    }}
                />
            </WizardModalsProvider>
        </ErrorBoundary>
    );
}

PECPWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        })
    }).isRequired
};

export default withViewModelService(PECPWizard);
