import {
    BrowserRouter
} from 'react-router-dom';
import { start } from '@jutro/app';
import { configureLegacyComponentMap } from '@jutro/legacy/uiconfig';
import { initDefaultGA } from '@jutro/events';
import  { BrandingService } from '@xengage/gw-portals-branding-js'
import { LocaleService, G11nstore } from 'gw-portals-i18n-react';
import { loadGoogleApiScript } from 'wmic-pe-ext-google-tools';

import 'regenerator-runtime';

import appConfig from 'app-config';

import App from './app/App';

configureLegacyComponentMap();

const AUTH_TYPE_INTERNAL = 'internal';
const QUERY_PARAMETER_AUD = 'aud';
const AUTH_STORAGE_KEY = 'okta-auth-type';


const silentRedirectPath = appConfig?.authentication?.servers?.okta?.JUTRO_AUTH_SILENT_REDIRECT_PATH;
const silentLoginPath = appConfig?.authentication?.servers?.okta?.JUTRO_AUTH_SILENT_LOGIN_PATH;

if (window.self === window.top) { 
    const theBody = document.getElementsByTagName('body')[0];

    theBody.style.display = 'block';
} else if ((!silentLoginPath || !window.self.location.pathname.endsWith(silentLoginPath)) && 
    (!silentRedirectPath || !window.self.location.pathname.endsWith(silentRedirectPath))) { 
    const {host, protocol} = new URL(window.top.location)
    const root = `${protocol}//${host}`
    const rootPath = process.env.PUBLIC_PATH

    window.top.location = new URL(rootPath, root);
}

const { trackingConfig = {}, authentication = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}

const authTypeLocal = localStorage.getItem(AUTH_STORAGE_KEY);
const authTypeParam = new URL(window.location).searchParams.get(QUERY_PARAMETER_AUD);
const isInternalUser = authTypeParam === AUTH_TYPE_INTERNAL || authTypeLocal === AUTH_TYPE_INTERNAL;
if (isInternalUser) {
    localStorage.setItem(AUTH_STORAGE_KEY, AUTH_TYPE_INTERNAL);

    const configLoginQueryExtra = authentication?.servers?.okta?.JUTRO_AUTH_LOGIN_QUERY_EXTRAS || null;
    const modifiedConfigLoginQueryExta = {...JSON.parse(configLoginQueryExtra), idp: appConfig.wmicAuthenticationConfig.authIdpInternal};
    authentication.servers.okta.JUTRO_AUTH_LOGIN_QUERY_EXTRAS = JSON.stringify(modifiedConfigLoginQueryExta);
}
const config = {
    ...authentication.servers.okta,
    ...appConfig.credentials,
    localeSettings: { ...appConfig.localeSettings },
    ...appConfig.wmicJutroEnvVars
};

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLanguageOrLocaleChange: true,
    routerBasename: '/producer-engage',
    config: [config],
    themeConfig: BrandingService.getBrandingTheme(),
    g11nStore: G11nstore(),

    onInit: () => {
        LocaleService.register();
        loadGoogleApiScript()
    },

    Router: BrowserRouter,

    appName: {
        id: 'digital.appName',
        defaultMessage: 'BluePass'
    },

    appDescription: 'ProducerEngage',
});
