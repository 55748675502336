// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Contacts__gwContactsTableContainer{padding:var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6)}.app__Contacts__gwContactsTableContainer .app__Contacts__gwTitleStyle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:var(--GW-LAYOUT-2);padding-bottom:var(--GW-LAYOUT-2)}.app__Contacts__contactsContainer{margin-bottom:var(--GW-LAYOUT-10)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/Accounts/Contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA,yCACE,mFAAA,CAEA,sEAEE,iEAAA,CACA,gCAAA,CAFA,iCAEA,CAIJ,kCACE,iCAAA","sourcesContent":[".gwContactsTableContainer {\n  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);\n\n  .gwTitleStyle {\n    padding-bottom: var(--GW-LAYOUT-2);\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n    margin-bottom: var(--GW-LAYOUT-2);\n  }\n}\n\n.contactsContainer {\n  margin-bottom: var(--GW-LAYOUT-10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwContactsTableContainer": "app__Contacts__gwContactsTableContainer",
	"gwTitleStyle": "app__Contacts__gwTitleStyle",
	"contactsContainer": "app__Contacts__contactsContainer"
};
export default ___CSS_LOADER_EXPORT___;
