import AdditionalInterestInfoModel from './AdditionalInterestInfoModel';
import AddressInfoModel from './AddressInfoModel';
import ChangeAddressModel from './ChangeAddressModel';
import ChangePaymentPlanModel from './ChangePaymentPlanModel';
import DesignateAdditionalPersonModel from './DesignateAdditionalPersonModel';
import DesignatePersonModel from './DesignatePersonModel';
import FinanceLeasingModel from './FinanceLeasingModel';
import PolicyChangeRequestModel from './PolicyChangeRequestModel';
import PolicyDetailsModel from './PolicyDetailsModel';
import RoadsideAssistanceModel from './RoadsideAssistanceModel';
import VehicleModel from './VehicleModel';
import DeleteVehicleModel from './DeleteVehicleModel';
import BodilyInjuryModel from './BodilyInjuryModel';
import EditUninsuredModel from './EditUninsuredModel';

export default {
    AdditionalInterestInfoModel,
    AddressInfoModel,
    ChangeAddressModel,
    ChangePaymentPlanModel,
    DesignateAdditionalPersonModel,
    DesignatePersonModel,
    FinanceLeasingModel,
    PolicyChangeRequestModel,
    PolicyDetailsModel,
    RoadsideAssistanceModel,
    VehicleModel,
    DeleteVehicleModel,
    BodilyInjuryModel,
    EditUninsuredModel
};
