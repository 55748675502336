// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICIterableLocationComponent__iterableLocationContainer{border:1px solid #ccc;border-radius:var(--wmic-border-radius-card);margin-block-end:10px}.app__WMICIterableLocationComponent__wmicMasterInputFieldLabelLocation{color:var(--GW-TEXT-COLOR-1)}.app__WMICIterableLocationComponent__wmicMasterInputFieldLabelLocation,.app__WMICIterableLocationComponent__wmicMasterInputFieldLabelLocation b,.app__WMICIterableLocationComponent__wmicMasterInputFieldLabelLocation em,.app__WMICIterableLocationComponent__wmicMasterInputFieldLabelLocation strong{font-weight:var(--GW-FONT-WEIGHT-BOLD)}.app__WMICIterableLocationComponent__centeredIcon{margin-bottom:auto;margin-top:auto;text-align:center;width:2em}.app__WMICIterableLocationComponent__locationDetailsContainer{padding:.5em}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/components/CP/WMICIterableLocationComponent/WMICIterableLocationComponent.module.scss"],"names":[],"mappings":"AAEA,+DACI,qBAAA,CACA,4CAAA,CACA,qBAAA,CAGJ,uEACI,4BACA,CACA,wSADA,sCAEE,CAIN,kDAEI,kBAAA,CADA,eAAA,CAGA,iBAAA,CADA,SACA,CAGJ,8DACI,YAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n\n.iterableLocationContainer{\n    border: 1px solid $filter-type-section-color;\n    border-radius: var(--wmic-border-radius-card);\n    margin-block-end: 10px;\n}\n\n.wmicMasterInputFieldLabelLocation{\n    color: var(--GW-TEXT-COLOR-1);\n    font-weight: var(--GW-FONT-WEIGHT-BOLD);\n    b, strong, em {\n      font-weight: var(--GW-FONT-WEIGHT-BOLD);\n    }\n}\n\n.centeredIcon{\n    margin-top: auto;\n    margin-bottom: auto;\n    width: 2em;\n    text-align: center;\n}\n\n.locationDetailsContainer{\n    padding: .5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iterableLocationContainer": "app__WMICIterableLocationComponent__iterableLocationContainer",
	"wmicMasterInputFieldLabelLocation": "app__WMICIterableLocationComponent__wmicMasterInputFieldLabelLocation",
	"centeredIcon": "app__WMICIterableLocationComponent__centeredIcon",
	"locationDetailsContainer": "app__WMICIterableLocationComponent__locationDetailsContainer"
};
export default ___CSS_LOADER_EXPORT___;
