// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICFieldAlert__fieldAlert{color:var(--GW-FIELD-COMPONENT-REQUIRED-SYMBOL-COLOR);display:flex;flex-direction:row;font-size:var(--wmic-font-size-subtext);margin-top:-.75rem;position:relative}.app__WMICFieldAlert__fieldLabel_left{display:flex;flex-direction:column;flex-grow:1}.app__WMICFieldAlert__fieldControls_left{flex:2 2;overflow-wrap:break-word;position:relative;width:100%}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICFieldAlert/WMICFieldAlert.module.scss"],"names":[],"mappings":"AAAA,iCAEE,qDAAA,CAGA,YAAA,CACA,kBAAA,CALA,uCAAA,CAEA,kBAAA,CAIA,iBAAA,CAGF,sCACE,YAAA,CACA,qBAAA,CACA,WAAA,CAGF,yCAEE,QAAA,CACA,wBAAA,CACA,iBAAA,CAHA,UAGA","sourcesContent":[".fieldAlert {\n  font-size: var(--wmic-font-size-subtext);\n  color: var(--GW-FIELD-COMPONENT-REQUIRED-SYMBOL-COLOR);\n  margin-top: -0.75rem;\n\n  display: flex;\n  flex-direction: row;\n  position: relative;\n}\n\n.fieldLabel_left {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n\n.fieldControls_left {\n  width: 100%;\n  flex: 2 2;\n  overflow-wrap: break-word;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldAlert": "app__WMICFieldAlert__fieldAlert",
	"fieldLabel_left": "app__WMICFieldAlert__fieldLabel_left",
	"fieldControls_left": "app__WMICFieldAlert__fieldControls_left"
};
export default ___CSS_LOADER_EXPORT___;
