import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';

export default {
    formatShortDate: (date) => {
        const localeService = ServiceManager.getService('locale-service');
        return date?.toLocaleDateString(localeService.getPreferredLocale(), { dateStyle: 'short'});
    },
    formatFullDate: (date) => {
        const localeService = ServiceManager.getService('locale-service');
        return date?.toLocaleDateString(localeService.getPreferredLocale(), { weekday:"long", year:"numeric", month:"long", day:"numeric"})
    },
    formatMediumDate_WMIC: (date) => {
        const localeService = ServiceManager.getService('locale-service');
        return date?.toLocaleDateString(localeService.getPreferredLocale(), { dateStyle: 'medium'});
    }
};
