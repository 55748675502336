import { defineMessages } from 'react-intl';

export default defineMessages({
    ifYouLeaveNowAnyChangesYouveSelectedWillNotBeSaved: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal.If you leave now, any changes you’ve selected will not be saved.',
        defaultMessage: 'If you leave now, any changes you’ve selected will not be saved.'
    },
    areYouSureYouWishToCancel: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal.Are you sure you wish to cancel?',
        defaultMessage: 'Are you sure you wish to cancel?'
    },
    areYouSure: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal.Are you sure?',
        defaultMessage: 'Are you sure?'
    },
    noKeepEditing: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal.No, Keep Editing',
        defaultMessage: 'No, Keep Editing'
    },
    yesCancelChanges: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.modal.Yes, Cancel Changes',
        defaultMessage: 'Yes, Cancel Changes'
    }
});
