// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICHOWatercraftBoatEquipmentDetails__indentedContent{display:flex}.app__WMICHOWatercraftBoatEquipmentDetails__indentedContent:before{content:\"\";flex:0 0 min(23vw,298px)}.app__WMICHOWatercraftBoatEquipmentDetails__indentedContent>div{flex-grow:1}.app__WMICHOWatercraftBoatEquipmentDetails__indentedContent [class*=fieldComponent] div[class*=fieldLabelContainer]:first-child{flex:initial;flex-basis:34%}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-ho-react/components/WMICHOWatercraftDetails/Components/WMICHOWatercraftBoatEquipmentTrailerDetails/WMICHOWatercraftBoatEquipmentDetails.module.scss"],"names":[],"mappings":"AAAA,4DAEI,YAAA,CAEA,mEACI,UAAA,CACA,wBAAA,CAGJ,gEACI,WAAA,CAIJ,gIACI,YAAA,CACA,cAAA","sourcesContent":[".indentedContent {\n    /* Indenting content in a way that matches the wmicMasterInputControlElement*/\n    display: flex;\n    \n    &::before {\n        content: \"\";\n        flex: 0 0 min(23vw, 298px)\n    }\n\n    > div {\n        flex-grow: 1;\n    }\n    \n    /* Minor changes required to appropriately fit fields inside the indentedContent*/\n    [class*=\"fieldComponent\"] div[class*=\"fieldLabelContainer\"]:first-child {\n        flex: unset;\n        flex-basis: 34%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indentedContent": "app__WMICHOWatercraftBoatEquipmentDetails__indentedContent"
};
export default ___CSS_LOADER_EXPORT___;
