import React, { useEffect, useState, useCallback, Fragment } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS } from 'wmic-portals-utils-js';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';
import metadata from './WMICPolicyCoverageInfoBox.metadata.json5';
import messages from './WMICPolicyCoverageInfoBox.messages';
import styles from './WMICPolicyCoverageInfoBox.module.scss';

const WMICPolicyCoverageInfoBox = (props) => {
    const { policyCoverageData, jurisdiction, policyNumber } = props;
    const translator = useTranslator();
    const [paBiCov, setPaBiCov] = useState(undefined);
    const [paPdCov, setPaPdCov] = useState(undefined);
    const [paPipCov, setPaPipCov] = useState(undefined);
    const [paMedPayCov, setPaMedPayCov] = useState(undefined);
    const [paUnimsbiCov, setPaUnimsbiCov] = useState(undefined);
    const [paUmCovWaiver, setPaUmCovWaiver] = useState(undefined);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [accordionOpenID, setAccordionOpenID] = useState(undefined);

    const getFirstVehicle = (vehicles) => {
        return _.find(vehicles, (vehicle) => {
            return (vehicle.vehicleType_WMIC !== CONSTANTS.VEHICLE_TYPES.TRAILER
                && vehicle.vehicleType_WMIC !== CONSTANTS.VEHICLE_TYPES.CAMPER);
        });
    };

    const getCoverageByCoverageCode = (vehicle, code) => {
        return _.find(vehicle.coverages, {
            patternCode_WMIC: code
        });
    };

    const getStyleForCovGridCovGridTooltip = () => {
        if(isMobile) {
            return 'tooltip'
        }
        return ''
    }

    useEffect(() => {
        const firstVehicle = getFirstVehicle(policyCoverageData);

        const biCov = getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_BI);
        if (biCov && biCov.limit.split(' / ').length === 2) {
            setPaBiCov(
                <div className={styles['wmic-cov-value-multiline']}>
                    ${biCov.limit.split(' / ')[0]}
                    {translator(messages.perPerson)}
                    <br />
                    ${biCov.limit.split(' / ')[1]}
                    {translator(messages.perOccurrence)}
                </div>
            );
        }
        setPaPdCov(getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_PD));
        setPaPipCov(getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_PIP));
        setPaMedPayCov(getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_MEDPAY));

        const unimsbiCov = getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_UNIMSBI);
        if (unimsbiCov && unimsbiCov.limit.split(' / ').length === 2) {
            setPaUnimsbiCov(
                <div className={styles['wmic-cov-value-multiline']}>
                    ${unimsbiCov.limit.split(' / ')[0]}
                    {translator(messages.perPerson)}
                    <br />
                    ${unimsbiCov.limit.split(' / ')[1]}
                    {translator(messages.perOccurrence)}
                </div>
            );
        }
        setPaUmCovWaiver(getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_UMWAIVER));
    }, []);

    const getMessageWithHeader = (msg) => <h2 className={styles.subTitle}>{msg}</h2>;

        
    const renderBiCovSrContent = useCallback((bodilyInjuryLiability, policy, policyNumber) => {
        const firstVehicle = getFirstVehicle(policyCoverageData);
        const biCov = getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_BI);
        return (
            <Fragment>
                { bodilyInjuryLiability + " coverage of $" + biCov?.limit.split(' / ')[0] + translator(messages.perPerson) +  
                " $" + biCov?.limit.split(' / ')[1] + translator(messages.perOccurrence) + " for " + policy + policyNumber}
            </Fragment>
        );
    }, [translator]);

    const renderPaPdCovSrContent = useCallback((propertyDamageLiability, policy, policyNumber) => {
        const firstVehicle = getFirstVehicle(policyCoverageData);
        const paPdCov = getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_PD);
        return (
            <Fragment>
                { propertyDamageLiability + " coverage of $" + paPdCov?.limit + " for " + policy + policyNumber}
            </Fragment>
        );
    }, [translator]);

    const renderPaMedPayCovSrContent = useCallback((medicalPayments, policy, policyNumber) => {
        const firstVehicle = getFirstVehicle(policyCoverageData);
        const paMedPayCov = getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_MEDPAY);
        return (
            <Fragment>
                { medicalPayments + " coverage of $" + paMedPayCov?.limit + " for " + policy + policyNumber}
            </Fragment>
        );
    }, [translator]);

    const renderPaUnimsbiCovSrContent = useCallback((uninsuredUnderinsuredMotoristBodilyInjury, policy, policyNumber) => {
        const firstVehicle = getFirstVehicle(policyCoverageData);
        const paUnimsbiCov = getCoverageByCoverageCode(firstVehicle, CONSTANTS.COVERAGE_NAMES.PA_UNIMSBI);
        return (
            <Fragment>
                { uninsuredUnderinsuredMotoristBodilyInjury + " coverage of $" + paUnimsbiCov?.limit.split(' / ')[0] + translator(messages.perPerson) +  
                " $" + paUnimsbiCov?.limit.split(' / ')[1] + translator(messages.perOccurrence) + " for " + policy + policyNumber}
            </Fragment>
        );
    }, [translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent
        }
    };

    const overrides = {
        driversInfoBoxContainer: {
            visible: !isMobile
        },
        driversInfoBoxContainerMobile: {
            visible: isMobile
        },
        cardPolicyCoverage: {
            title: getMessageWithHeader(translator(messages.policyCoverageLimits))
        },
        paBiCovContainer: {
            visible: !!paBiCov
        },
        paBiCovGridCovGridTooltip: {
            tooltipMessage: translator(messages.bodilyInjuryLiabilityTooltip)
        },
        paBiCovGridCovValue: {
            content: paBiCov
        },
        paBiCovSrContent: {
            content: renderBiCovSrContent(
                translator(messages.bodilyInjuryLiability), translator(messages.policy), policyNumber 
            )
        },
        paPdCovContainer: {
            visible: !!paPdCov
        },
        paPdCovGridCovGridTooltip: {
            tooltipMessage: translator(messages.propertyDamageLiabilityTooltip)
        },
        paPdCovGridCovValue: {
            content: `$${paPdCov?.limit}`
        },
        paPdCovSrContent: {
            content: renderPaPdCovSrContent(
                translator(messages.propertyDamageLiability), translator(messages.policy), policyNumber 
            )
        },
        paPipCovContainer: {
            visible: !!paPipCov && jurisdiction === CONSTANTS.JURISDICTIONS.OR
        },
        paPipCovGridCovGridTooltip: {
            tooltipMessage: translator(messages.personalInjuryProtectionTooltip)
        },
        paPipCovGridCovValue: {
            content: `$${paPipCov?.limit}`
        },
        paMedPayCovContainer: {
            visible: !!paMedPayCov
        },
        paMedPayCovGridCovGridTooltip: {
            tooltipMessage: translator(messages.medicalPaymentsTooltip)
        },
        paMedPayCovGridCovValue: {
            content: `$${paMedPayCov?.limit}`
        },
        paMedPayCovSrContent: {
            content: renderPaMedPayCovSrContent(
                translator(messages.medicalPayments), translator(messages.policy), policyNumber 
            )
        },
        paUnimsbiCovContainer: {
            visible: !!paUnimsbiCov
        },
        paUnimsbiCovGridCovGridTooltip: {
            tooltipMessage: translator(messages.uninsuredUnderinsuredMotoristBodilyInjuryTooltip)
        },
        paUnimsbiCovGridCovValue: {
            content: paUnimsbiCov
        },
        paUnimsbiCovSrContent: {
            content: renderPaUnimsbiCovSrContent(
                translator(messages.uninsuredUnderinsuredMotoristBodilyInjury), translator(messages.policy), policyNumber 
            )
        },
        paUmCovWaiverGrid: {
            visible: !!paUmCovWaiver
        },
        paUnimsbiCovGridCovGridLabel: {
        },
        paUnimsbiCovGridCovGridTooltipContainer: {
            className: styles[getStyleForCovGridCovGridTooltip()]
        },
        Accordion: {
            accordionStates: accordionOpenID,
            onUpdateAccordionStates: (openCardID) => {setAccordionOpenID(openCardID)} 
        },
        
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={policyCoverageData}
        />
    );
};

WMICPolicyCoverageInfoBox.propTypes = {
    policyCoverageData: PropTypes.shape({}).isRequired,
    jurisdiction: PropTypes.string.isRequired
};

export default WMICPolicyCoverageInfoBox;
