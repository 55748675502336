/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Flex, FlexItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import { PolicyService } from 'gw-capability-policy';
import { WMICLoader } from 'gw-components-platform-react';
import messages from './WMICEmailConsentPage.messages';

import styles from './WMICEmailConsentPage.module.scss';
import { RadioButtonField } from '@jutro/legacy/components';

const WMICEmailConsentPage = () => {
    const METHOD_OF_DISTRIBUTION = { EMAIL: 'email', MAIL: 'mail'}
    const translator = useTranslator();
    const history = useHistory();

    const { userInfo: authUserData, userInfo: {
        email
    }, authHeader } = useAuthentication();
    const { EnrollmentService } = useDependencies('EnrollmentService');
    const [consent, updateConsent] = useState(null);
    const [loading, updateLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);

    const displayConsentPage = useCallback((contactSummary) => {
        return contactSummary?.accountContact?.prefDistributionMethod_WMIC !== METHOD_OF_DISTRIBUTION.EMAIL ||
                contactSummary?.accountContact?.contactMainEmailAddress !== email;
    }, [authHeader]);

    useEffect(() => {
        (async () => {
            setIsPageLoading(true);
            const contactSummary = await PolicyService.getContactSummary_WMIC(authHeader);
            setIsPageLoading(false);

            contactSummary && !displayConsentPage(contactSummary) && history.push('/');
        })()
    }, [authHeader])

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const tags = document.getElementsByTagName('main');
        if (tags.length === 1 && !isPageLoading) {
            tags[0].focus({preventScroll: true});
        }
    }, [isPageLoading])

    const submitConsentHandler = useCallback(() => {
        updateLoading(true);

        const request = {
            distributionType: consent,
            systemOrigin: 'amp',
            applicationFlow: 'registration',
            consentType: 'distributionMethod'
        };

        EnrollmentService
            .updateDistributionTypeWithConsent_WMIC(request, authHeader, authUserData.firstName, authUserData.lastName)
            .finally(() => updateLoading(false));

        return history.push('/');
    }, [
        EnrollmentService,
        authHeader,
        consent,
        history
    ]);

    return isPageLoading ? <WMICLoader /> : (
        <>
            <div className="ww-content-limit ww-page-header">
                <div className="ww-card--100w">
                    <h1>{translator(messages.saveTimeAndPaper)}</h1>
                </div>
            </div>
            <div className='ww-content-limit ww-main-content'>
                <Flex wrap={false} gap='none' className={styles.flexContent}>
                    <FlexItem className={`${styles.flexItem} ww-card`}>
                        <div className={`${styles.consentModalBody} ww-card__content`}>
                            <div className="ww-email-consent large-footer">
                                <div>
                                    <p>{translator(messages.consentParagraphOne)}</p>
                                    <p>{translator(messages.consentParagraphTwo)}</p>
                                    <p>{WMICRichTextUtil.translateRichText(translator(messages.consentParagraphThree))}</p>
                                    <div>
                                        <ul>
                                            <li>{translator(messages.consentListItemOne)}</li>
                                            <li>{translator(messages.consentListItemTwo)}</li>
                                            <li>{translator(messages.consentListItemThree)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <hr aria-hidden />
                                <div className={styles.consentAction}>
                                    <h2 className={styles.consentActionHeader}>
                                        {translator(messages.consentQuestion)}
                                    </h2>
                                    <RadioButtonField
                                        availableValues={[
                                            {
                                                code: 'EMAIL',
                                                name: WMICRichTextUtil.translateRichText(translator(messages.consentYes, { email }))
                                            },
                                            {
                                                code: 'MAIL',
                                                name: WMICRichTextUtil.translateRichText(translator(messages.consentNo))
                                            }
                                        ]}
                                        id="consentSelection"
                                        value={consent}
                                        onValueChange={(newValue) => updateConsent(newValue)}
                                    />
                                </div>
                                <WMICButton
                                    type="primary"
                                    onClick={submitConsentHandler}
                                    disabled={_.isNull(consent) || loading}
                                >
                                    {translator(messages.submit)}
                                </WMICButton>
                            </div>
                            <p className="ww-subscript">
                                <a href="https://www.wawanesa.com/home/amp/privacy-policy.html" target="_blank">{translator(messages.privacyPolicy)}</a>
                                | <a href="https://www.wawanesa.com/home/amp/terms-of-use.html" target="_blank">{translator(messages.termsofUse)}</a>
                                | <a href="https://www.wawanesa.com/home/amp/online-payment-terms.html" target="_blank">{translator(messages.onlinePaymentTerms)}</a>
                                <br />
                            </p>
                        </div>
                    </FlexItem>
                    <FlexItem className={`${styles.flexItem} ${styles.wwLoginFeatures} ww-card`}>
                        <div className="ww-card__content">
                            <h2>{WMICRichTextUtil.translateRichText(translator(messages.easyAccessHeading))}</h2>
                            <ul>
                                <li>{translator(messages.digitalIDCards)}</li>
                                <li>{translator(messages.roadsideAssistanceCards)}</li>
                                <li>{translator(messages.makePaymentsQuicklyAndEasily)}</li>
                                <li>{translator(messages.viewPolicyDetails)}</li>
                                <li>{translator(messages.reviewPolicyRenewals)}</li>
                                <li>{translator(messages.viewClaimsInformation)}</li>
                                <li>{translator(messages.paymentPlanChanges)}</li>
                                <li>{translator(messages.claimsPaymentHistory)}</li>
                                <li>{translator(messages.makePolicyChanges)}</li>
                            </ul>
                        </div>
                    </FlexItem>
                </Flex>
            </div>
        </>
    );
};

WMICEmailConsentPage.propTypes = {};

export default WMICEmailConsentPage;