import React, { useCallback } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { WMICBulletPointComponent } from 'gw-capability-policy-common-react';

import { Button } from '@jutro/legacy/components';

const emptyTranslation = {
    id: 'empty',
    defaultMessage: ''
};

function WMICMessageModal(props) {
    const {
        title,
        message,
        description,
        messages,
        iconClass,
        showCancelBtn,
        cancelBtnLabel,
        actionBtnLabel,
        actionBtnClass,
        onAfterClose,
        isOpen,
        onReject
    } = props;
    const translator = useTranslator();

    const handleClose = useCallback(() => {
        onReject();
    }, [onReject]);

    const icons = {
        'gw-messageSuccessIcon': 'gw-iconSpan gw-messageSuccessIcon',
        'gw-messageErrorIcon': 'gw-iconSpan gw-messageErrorIcon',
        'gw-messageQuestionIcon': 'gw-iconSpan gw-messageQuestionIcon',
        'gw-messageInformationIcon': 'gw-iconSpan gw-messageInformationIcon',
        'fa fa-fw fa-exclamation-circle': 'fa fa-fw fa-exclamation-circle gw-iconCircle',
        'fa fa-fw fa-exclamation-triangle': 'fa fa-fw fa-exclamation-triangle gw-iconTriangle'
    };

    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose} onAfterClose={onAfterClose} className="gw-modal gw-fade">
            <ModalHeader title={translator(title)} />
            <ModalBody>
                <div className="gw-modal-body">
                    <span className={icons[iconClass]} />
                    <div className="gw-message">
                        <p>{translator(message)}</p>
                        {(_.isEmpty(messages) && description) && (
                            <p className="gw-description">{translator(description)}</p>
                        )}
                        <WMICBulletPointComponent bulletPoints={messages} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="gw-modal-footer">
                    {showCancelBtn && (
                        <Button
                            className="ww-btn ww-btn-cancel"
                            type="tertiary"
                            onClick={handleClose}
                        >
                            {translator(cancelBtnLabel)}
                        </Button>
                    )}

                    <Button
                        className={`ww-btn ww-btn-primary ${actionBtnClass}`}
                        onClick={handleClose}
                    >
                        {translator(actionBtnLabel)}
                    </Button>
                </div>
            </ModalFooter>
        </ModalNext>
    );
}

WMICMessageModal.propTypes = {
    title: PropTypes.shape({}),
    message: PropTypes.shape({}),
    description: PropTypes.shape({}),
    messages: PropTypes.arrayOf(PropTypes.shape({})),
    iconClass: PropTypes.string,
    showCancelBtn: PropTypes.bool,
    cancelBtnLabel: PropTypes.shape({}),
    actionBtnLabel: PropTypes.shape({}),
    actionBtnClass: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onAfterClose: PropTypes.func,
};

WMICMessageModal.defaultProps = {
    title: emptyTranslation,
    message: emptyTranslation,
    messages: [],
    iconClass: 'gw-messageErrorIcon',
    showCancelBtn: false,
    cancelBtnLabel: platformMessages.close,
    actionBtnLabel: platformMessages.ok,
    actionBtnClass: '',
    description: emptyTranslation,
    onAfterClose: _.noop,
    isOpen: false,
    onReject: _.noop
};

export default WMICMessageModal;
