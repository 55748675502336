export default {
    isValidBigDecimal: (obj, precision, scale) => {
        if (obj === undefined || obj === null || obj === '') {
            return false;
        }

        let numberAsString;
        if (typeof obj === 'number') {
            numberAsString = obj.toString();
        } else {
            return false;
        }

        if (typeof numberAsString !== 'string') {
            return false;
        }

        if (!numberAsString.match(/^-?\d*(.\d*)?$/)) {
            return false;
        }

        numberAsString = numberAsString.replace('-', '');

        if (numberAsString.indexOf('.') === -1) {
            return numberAsString.length <= precision - scale;
        }

        const numberAsArray = numberAsString.split('.');

        if (numberAsArray.length !== 2) {
            return false;
        }

        return numberAsArray[0].length <= (precision - scale)
            && numberAsArray[1].length <= scale;
    }
};
