/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    ModalBody,
    ModalFooter,
    CurrencyValue
} from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICPaymentUtil, WMICRichTextUtil } from 'wmic-portals-utils-js';
import classNames from 'classnames';
import messages from './WMICPaymentModal.messages';
import styles from './WMICPaymentModal.module.scss';

const WMICPaymentWarningModal = (props) => {
    const {
        billingData,
        policyStatus,
        policyNumber,
        policyType,
        onReject,
        onResolve
    } = props;
    const translator = useTranslator();

    const isListBill = () => {
        return WMICPaymentUtil.isListBill(billingData);
    }

    const isPolicyCanceled = () => {
        return WMICPaymentUtil.shouldDisplayInactivePolicyMessage(billingData, policyStatus);
    }

    const isDelinquent = () => {
        return WMICPaymentUtil.isDelinquent(billingData);
    }

    const getOverdueAmount = () => {
        let returnAmount = null;
        let overdueAmount;

        if (billingData) {
            const { currency } = billingData.outstandingAmount;
            overdueAmount = WMICPaymentUtil.getOverdueAmount(billingData).toString();
            returnAmount = Number.isNaN(overdueAmount) ? null : <strong><CurrencyValue className={styles.overdueAmount} amount={overdueAmount} currency={currency} showFractions/></strong>;
        }

        return returnAmount;
    }

    return (
        <Fragment>
            <h1 className='wmic-modal-header-title'>{translator(messages.makeAPayment)}</h1>
            <ModalBody>
                <Flex direction="column" justify-content="around">
                    {isListBill() && (
                        <Fragment>
                            <p aria-hidden>
                                <i aria-label={translator(messages.alertIconAria)} className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                {translator(messages.contactYourMortgageCompany)}
                            </p>
                            <p className="sr-only">
                                <i aria-label={translator(messages.alertIconAria)} className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                {translator(messages.contactYourMortgageCompanyAria, {policyNumber, policyType})}
                            </p>
                        </Fragment>
                    )}
                    {isPolicyCanceled() && (
                        <Fragment>
                            <p aria-hidden>
                                <i aria-label={translator(messages.alertIconAria)} className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                {WMICRichTextUtil.translateRichText(translator(messages.noAutomaticReinstate))}
                            </p>
                            <p className="sr-only">
                                <i aria-label={translator(messages.alertIconAria)} className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                {WMICRichTextUtil.translateRichText(translator(messages.noAutomaticReinstateAria, {policyNumber, policyType}))}
                            </p>
                        </Fragment>
                    )}
                    {!isPolicyCanceled() && isDelinquent() && (
                        <Fragment>
                            <p aria-hidden>
                                <i className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                {translator(messages.remainInDelinquentStatus, {overdueAmount: getOverdueAmount()})}
                            </p>
                            <p className="sr-only">
                                <i aria-label={translator(messages.alertIconAria)} className={classNames('fa fa-exclamation-circle', styles['wmic-icon-warning'])} />
                                {translator(messages.remainInDelinquentStatusAria, {overdueAmount: getOverdueAmount(), policyNumber, policyType})}
                            </p>
                        </Fragment>
                    )}
                </Flex>
            </ModalBody>
            <ModalFooter>
                <WMICButton
                    onClick={() => { onReject(); }}
                    type="outlined"
                >
                    {translator(messages.cancel)}
                </WMICButton>
                <WMICButton
                    onClick={() => { onResolve(); }}
                    type="primary"
                >
                    {translator(messages.continue)}
                </WMICButton>
            </ModalFooter>
        </Fragment>
    );
};

WMICPaymentWarningModal.propTypes = {
    billingData: PropTypes.shape({}).isRequired,
    policyStatus: PropTypes.string.isRequired,
    policyNumber: PropTypes.string.isRequired,
    policyType: PropTypes.string.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default WMICPaymentWarningModal;