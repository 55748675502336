import { defineMessages } from 'react-intl';

export default defineMessages({
    qualification: {
        id: 'quoteandbind.views.contact-us.Qualification',
        defaultMessage: 'Qualification'
    },
    error: {
        id: 'quoteandbind.views.contact-us.Error',
        defaultMessage: 'Error'
    },
    notMeetCriteria: {
        id: 'quoteandbind.views.contact-us.Not Meet Underwriting Criteria',
        defaultMessage: 'The submission does not meet underwriting criteria'
    },
    notEligibleNeedUWAssistance: {
        id: 'quoteandbind.views.contact-us.Not Eligible Underwriting Criteria',
        defaultMessage: 'One or more of the risks are not eligible for some or all of the coverages requested. Please refer to our Underwriting manual or contact an Underwriter for assistance.'
    },
    underwriterProceed1: {
        id: 'quoteandbind.views.contact-us.Underwriter proceed with application 1',
        defaultMessage: 'You cannot proceed with the transaction on BluePass.'
    },
    underwriterProceed2: {
        id: 'quoteandbind.views.contact-us.Underwriter proceed with application 2',
        defaultMessage: 'Please submit directly to your underwriter.'
    },
    referenceNumber: {
        id: 'quoteandbind.views.contact-us.Reference Number',
        defaultMessage: 'Submission Number'
    },
    cpTitle: {
        id: 'quoteandbind.views.contact-us.cp.Title',
        defaultMessage: 'Commercial Lines Small Business Submission ({submission})'
    },
    cpHeader: {
        id: 'quoteandbind.views.contact-us.cp.Header',
        defaultMessage: 'Qualifications'
    },
    cpAlertTitle: {
        id: 'quoteandbind.views.contact-us.cp.AlertTitle',
        defaultMessage: 'Unable to quote through our digital platform'
    },
    cpAlertBody: {
        id: 'quoteandbind.views.contact-us.cp.AlertBody',
        defaultMessage: 'Please submit the quote request to our commercial team at &lt;a href="mailto:sbclunderwriting@wawanesa.com?subject=Quote Request for Submission {submission}"&gt;sbclunderwriting@wawanesa.com&lt;/a&gt; and reference submission number {submission}.'
    },
    cpBackToPolicyButton: {
        id: 'quoteandbind.views.contact-us.cp.BackToPolicies',
        defaultMessage: 'Back to Policies'
    },
    cpEditAccount: {
        id: 'quoteandbind.views.contact-us.cp.EditAccount',
        defaultMessage: 'Edit Account'
    }
});
