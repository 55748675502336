import React, {
    Fragment,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import htmlParser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { IntlContext, useTranslator } from '@jutro/locale';
import { CurrencyValue } from '@jutro/components';
import { formatCurrency} from '@jutro/components/widgets/values/FormattedCurrency';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICPaymentUtil, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import style from './WMICManagePaymentPlan.module.scss';
import messages from './WMICManagePaymentPlan.messages';


const PaymentPlanModal = (props) => {
    const [result, updateResult] = useState(undefined);
    const [isLoading, updateLoading] = useState(true);
    const [bankData, updateBankData] = useState([]);
    const { onEdit, billingData, isEditMode, showContactUs, showRemainingPaymentsHeader } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const { authHeader } = useAuthentication();
    const { policyId, termNumber } = _.get(props, 'billingData', {});
    const history = useHistory();

    useEffect(() => {
        AccountBillingDetailsService
            .getPolicyBillingSummary_WMIC(policyId, termNumber, authHeader)
            .then((response) => {
                const orderByData =_.sortBy(response.unpaidInvoices,"dueDate")

                response.unpaidInvoices = orderByData;
                updateResult(response);
            });
            AccountBillingDetailsService.getPaymentInstrumentTokenSummaryForMarPolicy(policyId, termNumber, authHeader)
            .then((response) => {
                _.each(response.paymentInstrumentTokens, (paymentInstrumentToken) => {
                    const accountRecord = {
                        publicId: paymentInstrumentToken.publicId,
                        bankName: paymentInstrumentToken.bankName,
                        token: paymentInstrumentToken.token,
                        bankAccountTypeName:  paymentInstrumentToken.bankAccountType,
                        lastFour: paymentInstrumentToken.lastFour
                    }
                    
                    updateBankData([...bankData, accountRecord]);
                });
            }).finally(() => updateLoading(false));
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const showContactUsMsgForMARBankDetailsInBillingSection = useCallback(() => showContactUs
            && WMICPaymentUtil.isMARPayment(billingData)
            && bankData.length === 0
            && !WMICPaymentUtil.isPolicyInactive(billingData), [bankData.length, billingData, showContactUs]);

    const onClickContactUs = useCallback((e) => {
        e.preventDefault();
        history.push('/contactUs');
    }, [history]);

    const modalBody = useMemo(() => {
        const today = new Date();

        if (isLoading || !result) {
            return isLoading ? <WMICLoader isInline /> : null;
        }

        return <Fragment>
            <div className="ww-card-row ww-payment-plan-details mb-4">
                <div>
                    <strong>
                        {`${translator(messages.paymentPlan)}: `}
                    </strong>
                    {_.get(billingData, 'paymentPlan')}
                    { showContactUsMsgForMARBankDetailsInBillingSection()
                        && (
                            <div className={style.wmicContactUsMar}>
                                <p>
                                    {`${translator(messages.doNotSeeYourBankAccDetails)} `}
                                    <a onClick={onClickContactUs}>{translator(messages.contactUs)}</a>
                                </p>
                            </div>
                        )
                    }
                    { showRemainingPaymentsHeader && (
                        <div className="ww-card-row mt-4">
                            <h3 className={style.wmicRemainingPaymentsHeading}>{`${translator(messages.yourRemainingPayments)}`}</h3>
                        </div>
                    ) }
                    {
                        bankData.map((item, idx) => (
                            <Fragment key={`$bank-name-${idx}`}>
                                <div><strong>{translator(messages.bankDetails)}</strong></div>
                                <span>
                                    <span>
                                        {translator(messages.financialInstitutionName)}
                                        <br />
                                        {item.bankName}
                                        <br />
                                    </span>
                                    {translator(messages.bankAccountNumber)}
                                    <span>{htmlParser(translator(messages.bankAccountNumberDisplay, {bankAccountType: item.bankAccountTypeName, lastFour: item.lastFour}))}</span>
                                    <br />
                                </span>
                            </Fragment>
                        ))
                    }
                </div>
                <div className={style.editButton}>
                    {
                        isEditMode
                        && (
                            <WMICButton
                                type="secondary"
                                size="small"
                                onClick={() => onEdit()}
                            >
                                {translator(messages.edit)}
                            </WMICButton>
                        )}
                </div>
            </div>
            <div className="ww-payment-subsection-box">
                <div className="ww-payment-plan-header">
                    <div aria-hidden>{translator(messages.dueDate)}</div>
                    <div aria-hidden>{translator(messages.amount)}</div>
                </div>
                {
                    result.unpaidInvoices && result.unpaidInvoices.map((unpaid, idx) => {
                        const dueDate = new Date(unpaid.dueDate);
                        
                        return (
                            <div key={`$b-unpaid-${idx}`} className="ww-payment-plan-row">
                                <div aria-hidden>
                                    {intl.formatDate(dueDate, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                </div>
                                <div role="listitem" className="sr-only">
                                    {
                                        translator(messages.currentScheduleAria, {
                                            eventDate: intl.formatDate(dueDate, { year: 'numeric', month: '2-digit', day: '2-digit' }),
                                            amount: formatCurrency(intl, { value: unpaid.amountDue.amount, showFractions: true })
                                        })
                                    }
                                </div>
                                <div aria-hidden className={style.amountDue}>
                                    <CurrencyValue
                                        id={`cv-${idx}`}
                                        value={unpaid.amountDue}
                                        showFractions
                                        tag="span"
                                    />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </Fragment>
    }, [isLoading, result, translator, billingData, showContactUsMsgForMARBankDetailsInBillingSection, onClickContactUs, showRemainingPaymentsHeader, bankData, isEditMode, onEdit, intl]);

    return modalBody;
};

PaymentPlanModal.propTypes = {
    onEdit: PropTypes.func.isRequired,
    billingData: PropTypes.shape({}),
    isEditMode: PropTypes.bool.isRequired,
    showContactUs: PropTypes.bool,
    ldFlags: PropTypes.shape({}),
    showRemainingPaymentsHeader: PropTypes.bool
};
PaymentPlanModal.defaultProps = {
    billingData: {},
    showContactUs: false,
    ldFlags: {},
    showRemainingPaymentsHeader: false
};

export default PaymentPlanModal;