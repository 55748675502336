// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__RssDirective__rssStyle{background:var(--GW-COLOR-SUCCESS-CONTRAST);border-bottom:1px solid var(--GW-COLOR-SUCCESS-CONTRAST);padding:var(--GW-LAYOUT-2)}.app__RssDirective__rssStyle a{text-decoration:none}.app__RssDirective__rssStyle .app__RssDirective__muted{color:var(--GW-TEXT-COLOR-1);font-size:var(--GW-FONT-SIZE-BODY)}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/Directives/RssDirective/RssDirective.module.scss"],"names":[],"mappings":"AAAA,6BAEI,2CAAA,CACA,wDAAA,CAFA,0BAEA,CAEA,+BACI,oBAAA,CAGJ,uDACI,4BAAA,CACA,kCAAA","sourcesContent":[".rssStyle {\n    padding: var(--GW-LAYOUT-2);\n    background: var(--GW-COLOR-SUCCESS-CONTRAST);\n    border-bottom: 1px solid var(--GW-COLOR-SUCCESS-CONTRAST);\n\n    a {\n        text-decoration: none;\n    }\n\n    .muted {\n        color: var(--GW-TEXT-COLOR-1);\n        font-size: var(--GW-FONT-SIZE-BODY);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rssStyle": "app__RssDirective__rssStyle",
	"muted": "app__RssDirective__muted"
};
export default ___CSS_LOADER_EXPORT___;
