import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SearchService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { JobType } from 'wmic-portals-utils-js/StringConstants';
import { ICON_NAMES, CONSTANTS, WMICVariousUtil, WMICRichTextUtil, LOBConstants } from 'wmic-pe-portals-utils-js'
import { useTranslator } from '@jutro/locale';
import { withRouter, useHistory } from 'react-router-dom';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './SearchResults.metadata.json5';

import styles from './SearchResults.module.scss';
import messages from './SearchResults.messages';

function SearchResults(props) {
    const {
        match: {
            params: { searchText }
        },
        param,
        title,
        noResultsMessage
    } = props;
    const translator = useTranslator();
    const [searchResult, setSearchResult] = useState({
        accounts: [],
        policies: [],
        submissions: []
    });
    const [prevParam, setPrevParam] = useState('');
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const { authHeader } = useAuthentication();

    const getPhoneNumber = useCallback((accountHolder) => {
        switch (accountHolder.primaryPhoneType) {
        case CONSTANTS.PHONE_TYPES.HOME:
            return accountHolder.homeNumber;
        case CONSTANTS.PHONE_TYPES.MOBILE:
            return accountHolder.cellNumber;
        case CONSTANTS.PHONE_TYPES.WORK:
            return accountHolder.workNumber;
        default:
            return undefined;
    }}, [])

    const getAccountIcon = useCallback((account) => {
        if(account.isPerson){
            return "mi-person"
        } 
        
        if (account.isCommercial) {
            return ICON_NAMES.ICON_BUILDING;
        }

        return "";
    }, [])

    const setAddressData = useCallback((policyInfo, location) => {
        const {
            addressLine1,
            addressLine2,
            city,
            state,
            postalCode
        } = location;

        const addressLine2Data = addressLine2 ? addressLine2 : '';

        policyInfo.addressLine = `${addressLine1}, ${addressLine2Data}`;
        policyInfo.addressOthers = `${city}, ${state} ${postalCode}`;
    }, [])

    const populateAccountResults = useCallback((data) => {
        const accounts = [];

        if (data.submissions.length === 0 && data.policies.length === 0 && data.accounts.length === 1) {
            history.push(`/accounts/${data.accounts[0].accountNumber}/summary`)
        }

        if (data.accounts.length) {
            data.accounts.forEach((account) => {
                if (account !== null) {
                    const accountInfo = {};

                    accountInfo.accountNumber = account.accountNumber;
                    accountInfo.accountName = account.accountHolder;

                    const {
                        addressLine1, city, state, postalCode
                    } = account.accountHolderAddress;
                    const addressLine2Data = account.accountHolderAddress.addressLine2
                        ? account.accountHolderAddress.addressLine2
                        : '';

                    accountInfo.addressLine = `${addressLine1}, ${addressLine2Data}`;
                    accountInfo.addressOthers = `${city}, ${state} ${postalCode}`;
                    accountInfo.isPerson = account.isPersonalAccount;
                    accountInfo.isCommercial = account.isCommercialAccount;
                    accountInfo.phoneNumber = getPhoneNumber(account);
                    accountInfo.policyCount = account.policySummaries.length;
                    accounts.push(accountInfo);
                }
            });
        }

        return accounts;
    }, [history, getPhoneNumber]);
    
    const toBasePolicy = useCallback((policy) => {
        const policyInfo = {};

        policyInfo.policyNumber = policy.policyNumber;
        policyInfo.accountNumber = policy.accountNumber;

        if (policy.accountHolder.subtype === 'Company') {
            policyInfo.accountName = policy.accountHolder.contactName;
        } else if (policy.accountHolder.subtype === 'Person') {
            policyInfo.accountName = `${policy.accountHolder.firstName} ${policy.accountHolder.lastName}`;
        }

        policyInfo.policyType = policy.product.productCode;
        policyInfo.policyStatus = policy.jobStatus;
        policyInfo.phoneNumber = getPhoneNumber(policy.accountHolder)
        policyInfo.displayStatus = policy.displayStatus;
        policyInfo.effectiveTermNumber = policy.effectiveTermNumber;
        return policyInfo;
    }, [getPhoneNumber])

    const populatePolicyResults = useCallback((data) => {
        const policies = [];

        if (data.submissions.length === 0 && data.policies.length === 1 && data.accounts.length === 0) {
            history.push(
                `/policies/${data.policies[0].policyNumber}/${data.policies[0].effectiveTermNumber}/summary`
            );
        }

        if (data.policies.length) {
            data.policies.forEach((policy) => {
                if (policy.policyNumber) {
                    let policyInfo = toBasePolicy(policy);

                    setAddressData(policyInfo, policy.accountHolder.primaryAddress)
                    policies.push(policyInfo);

                    policy.policyLocations.forEach((location) => {
                        policyInfo = toBasePolicy(policy);

                        setAddressData(policyInfo, location)
                        policies.push(policyInfo);
                    })
                }
            });
        }
        
        return policies;
    }, [history, toBasePolicy, setAddressData]);

    const toBaseSubmission = useCallback((submission) => {
        const policyInfo = {};

        policyInfo.rank = submission.rank_WMIC;
        policyInfo.productCode = submission.product.productCode;
        policyInfo.jobNumber = submission.jobNumber;
        policyInfo.jobStatus = submission.jobStatus;
        policyInfo.accountNumber = submission.accountNumber;
        policyInfo.jobType = submission.jobType;
        policyInfo.displayStatus = submission.displayStatus;

        if (submission.accountHolder.subtype === 'Company') {
            policyInfo.accountName = submission.accountHolder.contactName;
        } else if (submission.accountHolder.subtype === 'Person') {
            policyInfo.accountName = `${submission.accountHolder.firstName} ${submission.accountHolder.lastName}`;
        }
        
        policyInfo.phoneNumber = getPhoneNumber(submission.accountHolder)
        
        return policyInfo;
    }, [getPhoneNumber])

    const getSubmissionRedirectPath = (submission) => {
        switch (submission.jobType) {
            case JobType.POLICY_CHANGE: 
                return `/change/${submission.jobNumber}/summary`
            case JobType.RENEWAL:
                return `/renewal/${submission.jobNumber}/summary`
            default:
                return `/quotes/${submission.jobNumber}/summary`
        }
    }

    const populateSubmissionResults = useCallback((data) => {
        const submissions = [];

        if (data.submissions.length === 1 && data.policies.length === 0 && data.accounts.length === 0) {
            history.push(getSubmissionRedirectPath(data.submissions[0]))
        }

        if (data.submissions.length > 0) {
            data.submissions.forEach((submission) => {
                if (submission.jobNumber) {
                    let policyInfo = toBaseSubmission(submission);

                    setAddressData(policyInfo, submission.accountHolder.primaryAddress)
                    submissions.push(policyInfo);

                    submission.policyLocations.forEach((location) => {
                        policyInfo = toBaseSubmission(submission);

                        setAddressData(policyInfo, location)
                        submissions.push(policyInfo);
                    })
                }
            });
        }

        return submissions;
    }, [history, setAddressData, toBaseSubmission]);

    useEffect(() => {
        const searchParam = param || searchText;
        const fetchData = async () => {
            setLoading(true);
            setPrevParam(searchParam);

            const searchObj = {
                searchParam
            };
            const data = await SearchService.search(searchObj, authHeader);

            setLoading(false);
            setSearchResult(() => {
                return {
                    accounts: populateAccountResults(data),
                    policies: populatePolicyResults(data),
                    submissions: populateSubmissionResults(data),
                    isSOLRDisabled: data.isSOLRDisabled
                };
            });
        };

        if (prevParam !== searchParam) {
            fetchData();
        }
    }, [searchText, populateAccountResults, populatePolicyResults, populateSubmissionResults, authHeader, param, prevParam]);

    const accountDetailsOverrides = useMemo(() => {
        const overrides = searchResult.accounts.map((account, index) => {
            const iconType = getAccountIcon(account)

            return {
                [`accountAccountNameLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`,
                    content: `${account.accountName}`
                },
                [`accountNumberLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`,
                    content: ` ${account.accountNumber}`
                },
                [`accountLinkIcon${index}`]: {
                    icon: iconType
                },
                [`accountPhone${index}`]: {
                    value: `${account.phoneNumber}`,
                    visible: account.phoneNumber !== undefined
                },
                [`accountAddressLine${index}`]: {
                    content: `${account.addressLine}`
                },
                [`accountAddressOthers${index}`]: {
                    content: `${account.addressOthers}`
                },
                [`accountPolicyCount${index}`]: {
                    content: translator(messages.accountPolicyCount, {policyResultsNumber: account.policyCount})
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [searchResult.accounts, getAccountIcon]);

    const isPUP = (value) => value === LOBConstants.PUP

    const policyDetailsOverrides = useMemo(() => {
        const overrides = searchResult.policies.map((policy, index) => {
            const iconType = WMICVariousUtil.getLobIcon(policy.policyType);

            return {
                [`policyNumberLink${index}`]: {
                    to: `/policies/${policy.policyNumber}/${policy.effectiveTermNumber}/summary`,
                    content: ` ${policy.policyNumber}`
                },
                [`policyLinkIcon${index}`]: {
                    icon: iconType,
                    className: `goto accountName ${(isPUP(policy.policyType)) ? ICON_NAMES.ICON_UMBRELLA : ""}`
                },
                [`policyAccountName${index}`]: {
                    content: `${policy.accountName}`
                },
                [`policyAccountNameLink${index}`]: {
                    to: `/accounts/${policy.accountNumber}/summary`
                },
                [`policyAddressLine${index}`]: {
                    content: `${policy.addressLine}`
                },
                [`policyAddressOthers${index}`]: {
                    content: `${policy.addressOthers}`
                },
                [`policyPhone${index}`]: {
                    value: `${policy.phoneNumber}`,
                    visible: policy.phoneNumber !== undefined
                },
                [`policyStatusLine${index}`]: {
                    content: translator(messages.resultStatus, {resultStatus: policy.displayStatus})
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [searchResult.policies]);

    const submissionDetailsOverrides = useMemo(() => {
        const overrides = searchResult.submissions.map((submission, index) => {
            const { productCode } = submission;
            const icon = WMICVariousUtil.getLobIcon(productCode);

            return {
                [`submissionNumberLink${index}`]: {
                    to: getSubmissionRedirectPath(submission),
                    content: ` ${submission.jobNumber}`
                },
                [`submissionLinkIcon${index}`]: {
                    icon,
                    className: `goto accountName ${(isPUP(productCode)) ? ICON_NAMES.ICON_UMBRELLA : ""}`
                },
                [`submissionAccountName${index}`]: {
                    content: `${submission.accountName}`
                },
                [`submissionAccountNameLink${index}`]: {
                    to: `/accounts/${submission.accountNumber}/summary`
                },
                [`submissionAddressLine${index}`]: {
                    content: `${submission.addressLine}`
                },
                [`submissionAddressOthers${index}`]: {
                    content: `${submission.addressOthers}`
                },
                [`submissionPhone${index}`]: {
                    value: `${submission.phoneNumber}`,
                    visible: submission.phoneNumber !== undefined
                },
                [`submissionStatus${index}`]: {
                    content: translator(messages.resultStatus, {resultStatus: submission.displayStatus})
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [searchResult.submissions]);

    const overrideProps = {
        accountResults: {
            visible: searchResult.accounts.length > 0 && !loading
        },
        searchResultsTitle: {
            content: title || translator(messages.searchResults)
        },
        policyResults: {
            visible: searchResult.policies.length > 0 && !loading
        },
        submissionResults: {
            visible: searchResult.submissions.length > 0 && !loading
        },
        searchingText: {
            visible: loading
        },
        loadingIndicator: {
            loaded: !loading
        },
        noResultsMessage: {
            visible:
                (!!searchText || param)
                && !loading
                && !searchResult.accounts.length
                && !searchResult.policies.length
                && !searchResult.submissions.length,
            content:
                noResultsMessage
                || translator(messages.noResultsFoundFor, {
                    query: searchText || param
                })
        },
        accountHeader: {
            content: translator(
                messages.accountResults,
                { accountResultsNumber: searchResult.accounts.length }
            )
        },
        statusComponentContainer: {
            visible: !loading && searchResult.isSOLRDisabled
        },
        statusMessageBody: {
            content: WMICRichTextUtil.translateRichText(translator(messages.solrDisabledMessage))
        },
        tooManyResultsText: {
            visible: !searchResult.isSOLRDisabled,
            content: WMICRichTextUtil.translateRichText(translator(messages.tooManyResults))
        },
        policyHeader: {
            content: translator(
                messages.policyResults,
                { policyResultsNumber: searchResult.policies.length }
            )
        },
        submissionHeader: {
            content: translator(
                messages.submissionResults,
                { submissionResultsNumber: searchResult.submissions.length }
            )
        },
        ...accountDetailsOverrides,
        ...policyDetailsOverrides,
        ...submissionDetailsOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const readValue = useCallback(
        (id, path) =>  readViewModelValue(metadata.pageContent, searchResult, id, path, overrideProps),
        [overrideProps, searchResult]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={searchResult}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

SearchResults.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            searchText: PropTypes.string
        })
    }).isRequired,
    param: PropTypes.string,
    title: PropTypes.string,
    noResultsMessage: PropTypes.string
};

SearchResults.defaultProps = {
    param: '',
    title: '',
    noResultsMessage: ''
};

export default withRouter(SearchResults);
