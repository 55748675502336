/* eslint-disable max-len */

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const { serviceUrls, settings } = appConfig;

export default {
    /*
        It returns a self submitting payment form component.
        It accepts a json object containing the data to be sent via POST.
    */
    createPaymentForm: (jsonData) => {
        const defaultPaymentParams = {
            urlComplete: serviceUrls.paymentComplete,
            urlCancel: serviceUrls.paymentCancel,
            urlError: serviceUrls.paymentError,
            SourceId: settings.paymentServiceSourceId,
            phoneNumber: settings.paymentPhoneNumber,
            sourceSystem: settings.paymentSourceSystem,
            agentName: settings.paymentAgentName,
            applicationURL: serviceUrls.paymentApplicationUrl
        };

        const url = `${serviceUrls.esbEndpoint}create/Payment`;
        const params = Object.assign(defaultPaymentParams, jsonData);

        const PaymentForm = ({
            formUrl,
            formParams
        }) => {
            const formRef = useRef(null);
            useEffect(() => {
                formRef.current.submit();
            }, []);
            return (
                <form ref={formRef} method="POST" action={formUrl}>
                    {Object.keys(formParams).map((key) => (
                        <input
                            type="hidden"
                            name={key}
                            value={formParams[key]}
                        />
                    ))}
                </form>
            );
        };

        PaymentForm.propTypes = {
            formUrl: PropTypes.string.isRequired,
            formParams: PropTypes.string.isRequired
        };

        return <PaymentForm formUrl={url} formParams={params} />;
    },
    getPayment: (entityId) => {
        const url = `${serviceUrls.esbEndpoint}read/Payment`;
        return fetch(`${url}?entityid=${entityId}`);
    },
    validateABA: (routingNumber) => {
        const url = serviceUrls.bankValidation;
        const policyChangeSubmitConfig = appConfig.policyChangeSubmitConfig;
        const basicAuthHeader = window.btoa(`${policyChangeSubmitConfig.username}:${policyChangeSubmitConfig.password}`);

        return fetch(`${url}?routingNumber=${routingNumber}&bankName=true`, {
            headers: {
                'Content-Type': 'application/xml; charset=utf-8',
                'Authorization': `Basic ${basicAuthHeader}`,
            }
        });
    }
};