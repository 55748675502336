import { HighlightPhraseFilter } from '@xengage/gw-portals-util-js';
/**
 * @name HighlightPhraseFilter
 * @description Filter useful in two cases: highlighting queried phrase
 * and providing safe html content.
 *
 * Main responsibility is to look for the string literal given as an
 * argument and provide the 'highlighted' styling to it.
 * E.g. Let's take the phrase: 'Some super loooong phrase'
 * (defined by the 'text' argument) and assume that the user
 * is searching for the 'super' query (given by the 'phrase' argument).
 * By applying this filter with those arguments,
 * you will get the result: 'Some <span class="gw-highlight-phrase-filter_matched">super</span>
 * loooong phrase' which eventually may be injected to the view with the usage of ng-bind-html.
 *
 * On the other hand, it may also just get some text with html tags included
 * and produce sanitized result from it.
 */

export default {
    // Platform HighlightPhraseFilter functions
    highlightText: HighlightPhraseFilter.highlightText
};
