// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ContactComponent__gwContactModule{background-color:var(--GW-BACKGROUND-COLOR);display:flex;flex-direction:row;padding:var(--GW-SPACING-3) 0 var(--GW-SPACING-2) var(--GW-SPACING-3)}.app__ContactComponent__gwContactModule .app__ContactComponent__gwContactInformationContainer{color:var(--GW-TEXT-COLOR-2);width:100%;word-break:break-word}.app__ContactComponent__gwContactModule .app__ContactComponent__gwImageContainer{--gw-image-container-width:64px;--gw-image-container-height:100%;height:var(--gw-image-container-height);width:var(--gw-image-container-width)}.app__ContactComponent__gwContactModule .app__ContactComponent__gwImageContainer img{max-width:-moz-fit-content;max-width:fit-content}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/ContactComponent/ContactComponent.module.scss"],"names":[],"mappings":"AAAA,wCAGE,2CAAA,CAFA,YAAA,CACA,kBAAA,CAEA,qEAAA,CAEA,8FAEE,4BAAA,CADA,UAAA,CAEA,qBAAA,CAGF,iFACE,+BAAA,CACA,gCAAA,CAGA,uCAAA,CADA,qCACA,CAEA,qFACE,0BAAA,CAAA,qBAAA","sourcesContent":[".gwContactModule {\n  display: flex;\n  flex-direction: row;\n  background-color: var(--GW-BACKGROUND-COLOR);\n  padding: var(--GW-SPACING-3) 0 var(--GW-SPACING-2) var(--GW-SPACING-3);\n\n  .gwContactInformationContainer {\n    width: 100%;\n    color: var(--GW-TEXT-COLOR-2);\n    word-break: break-word;\n  }\n\n  .gwImageContainer {\n    --gw-image-container-width: 64px;\n    --gw-image-container-height: 100%;\n\n    width: var(--gw-image-container-width);\n    height: var(--gw-image-container-height);\n\n    img {\n      max-width: fit-content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwContactModule": "app__ContactComponent__gwContactModule",
	"gwContactInformationContainer": "app__ContactComponent__gwContactInformationContainer",
	"gwImageContainer": "app__ContactComponent__gwImageContainer"
};
export default ___CSS_LOADER_EXPORT___;
