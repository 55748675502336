import React, { useEffect } from 'react';
import _ from 'lodash';
import { ModalNext } from '@jutro/components/widgets/modal-next/ModalNext';
import { ModalBody } from '@jutro/components/widgets/modal-next/ModalBody/ModalBody';
import { ModalFooter } from '@jutro/components/widgets/modal-next/ModalFooter/ModalFooter';
import { useModal } from '@jutro/components/widgets/modal-next/ModalNextProvider';
import WMICButton from 'wmic-components-platform-react/WMICButton/WMICButton';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import messages from './LogoutWarning.messages';

export function InactiveConfirmModal(props) {
    const {
        isOpen,
        onResolve,
        onReject,
        logoutConfirmationInterval
    } = props;

    const translator = useTranslator();

    useEffect(() => {
        const closeModal = setTimeout(onReject, logoutConfirmationInterval);

        return () => window.clearTimeout(closeModal);
    }, [logoutConfirmationInterval, onReject]);

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc
            className="wmic-session-inactivity-timeout"
        >
            <h2 className='wmic-modal-header-title'>{translator(messages.sessionInactivityTimeout)}</h2>
            <ModalBody>
                <p>
                    {translator(messages.sessionAboutToExpire)}
                </p>
            </ModalBody>
            <ModalFooter>
                <WMICButton onClick={onResolve} type="primary">
                    {translator(messages.continueSession)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
}

InactiveConfirmModal.propTypes = {
    isOpen: PropTypes.bool,
    logoutConfirmationInterval: PropTypes.number.isRequired,
    onResolve: PropTypes.func,
    onReject: PropTypes.func
};

InactiveConfirmModal.defaultProps = {
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop
}


function LogoutWarningModal(resetInactivityTimer, logoutConfirmationInterval) {
    const modalApi = useModal();
    
    modalApi.showModal(
        <InactiveConfirmModal
            logoutConfirmationInterval={logoutConfirmationInterval}
        />
    ).then(() => {
        resetInactivityTimer();
    }, _.noop);
}

export default LogoutWarningModal;
