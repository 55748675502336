import React, { } from 'react';
import PropTypes from 'prop-types';

function WMICTabSetBadgeHeadingComponent(props) {
    const {
        errorCount,
        headingText
    } = props;

    return (
        <div>
            <span>{headingText}</span>
            <i className="ww-tab-chevron fa fa-fw fa-angle-down" />
            {errorCount > 0 && (
                <span className="badge">
                    <span className="fa-stack ww-indicator ww-error-indicator">
                        <i className="fa fa-fw fa-circle fa-stack-1x" />
                        <i className="fa fa-fw fa-circle-thin fa-stack-1x" />
                        <span className="fa-fw fa-stack-1x ww-indicator-text">{errorCount}</span>
                    </span>
                </span>
            )}
        </div>
    );
}

WMICTabSetBadgeHeadingComponent.propTypes = {
    errorCount: PropTypes.string,
    headingText: PropTypes.string.isRequired
};

WMICTabSetBadgeHeadingComponent.defaultProps = {
    errorCount: ''
};

export default WMICTabSetBadgeHeadingComponent;
