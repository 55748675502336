import React from 'react';
import _ from 'lodash';
import { CONSTANTS, WMICRichTextUtil } from 'wmic-portals-utils-js';
import InsuranceCardModal from './WMICInsuranceCardModal';
import AndroidStepsModal from './WMICAndroidStepsModal/WMICAndroidStepsModal';
import IOSStepsModal from './WMICIOSStepsModal/WMICIOSStepsModal';
import WMICModal from '../WMICModal/WMICModal';
import insuranceCardMessages from './WMICInsuranceCardModal.messages';

const WMICTriggerInsuranceCardModal = async (props) => {
    const { modalApi } = _.get(window, '__giwan.modalApi');

    let result = await modalApi.showModal(
        <InsuranceCardModal
            {...props}
        />
    );

    if (result === CONSTANTS.DEVICE_TYPES.ANDROID) {
        result = await modalApi.showModal(
            <AndroidStepsModal isOpen onReject={() => _.noop()} {...props} />
        );
    } else if (result === CONSTANTS.DEVICE_TYPES.IOS) {
        result = await modalApi.showModal(
            <IOSStepsModal isOpen onReject={() => _.noop()} {...props} />
        );
    }

    if (result === CONSTANTS.ERROR) {
        const body = WMICRichTextUtil.safeUnescape(insuranceCardMessages.sorryMessage.defaultMessage);
        const title = insuranceCardMessages.sorry.defaultMessage;
        await modalApi.showModal(
            <WMICModal
                isOpen
                id="insuranceIDCardErrorModal"
                modalHeader={title}
                modalBody={body}
                confirmButtonText={insuranceCardMessages.close.defaultMessage}
                onConfirmCallback={() => _.noop()}
            />
        );
    }
};

export default WMICTriggerInsuranceCardModal;