import { defineMessages } from 'react-intl';

export default defineMessages({
    changesWillBeLost: {
        id: 'wmic.policy-change.component.cancel-remove-vehicle-pa.modal.Changes will be lost',
        defaultMessage: 'Changes will be lost'
    },
    ifYouCancelNowYoullLoseAnyChangesYouveMade: {
        id: 'wmic.policy-change.component.cancel-remove-vehicle-pa.modal.If you cancel now youll lose any changes youve made.',
        defaultMessage: 'If you cancel now you\'ll lose any changes you\'ve made.'
    },
    keepEditing: {
        id: 'wmic.policy-change.component.cancel-remove-vehicle-pa.modal.Keep Editing',
        defaultMessage: 'Keep Editing'
    },
    cancelChanges: {
        id: 'wmic.policy-change.component.cancel-remove-vehicle-pa.modal.Cancel Changes',
        defaultMessage: 'Cancel Changes'
    }
});