import React from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import metadata from './WMICBulletPointComponent.metadata.json5';
import styles from './WMICBulletPointComponent.module.scss';

export default function WMICBulletPointComponent({ bulletPoints }) {

    const overrideProps = {
        bulletpoints: {
            data: bulletPoints
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataContent 
            uiProps={metadata.pageContent} 
            overrideProps={overrideProps} 
            {...resolvers} 
        />
    )
}
