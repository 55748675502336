const storage = {};

export default {
    push: (key, value) => {
        storage[key] = value;
    },
    pop: (key) => {
        const val = storage[key];
        storage[key] = undefined;
        return val;
    },
    read: (key) => {
        const val = storage[key];
        return val;
    }
};
