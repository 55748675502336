import { defineMessages } from 'react-intl';

export default defineMessages({
    bodiliyInjuryTitle: {
        id: 'wmic.policy-change.component.bodily-injury.Adjust coverages or deductibles',
        defaultMessage: 'Adjust Coverages or Deductibles'
    },
    allVehicleCoverages: {
        id: 'wmic.policy-change.component.bodily-injury.The following coverages are applied to all vehicles under your policy',
        defaultMessage: 'The following coverages are applied to all vehicles under your policy'
    },
    bodilyInjuryLiability: {
        id: 'wmic.policy-change.component.bodily-injury.Bodily Injury Liability',
        defaultMessage: 'Bodily Injury Liability'
    },
    currentCoverage: {
        id: 'wmic.policy-change.component.bodily-injury.Your current coverage is...',
        defaultMessage: 'Your current coverage is {currentCoverage}'
    },
    propertyDamageLiability: {
        id: 'wmic.policy-change.component.bodily-injury.Property Damage Liability',
        defaultMessage: 'Property Damage Liability'
    },
    uninsuredUnderInsuredMotoristLiability: {
        id: 'wmic.policy-change.component.bodily-injury.Uninsured/Underinsured Motorist Bodily Injury',
        defaultMessage: 'Uninsured/Underinsured Motorist Bodily Injury'
    },
    uninsuredMotoristLiability: {
        id: 'wmic.policy-change.component.bodily-injury.Uninsured Motorist Bodily Injury',
        defaultMessage: 'Uninsured Motorist Bodily Injury'
    },
    uninsuredMotoristErrorMessage: {
        id: 'wmic.policy-change.component.bodily-injury.Protection limits must be equal to or less',
        defaultMessage: 'Uninsured/Underinsured Motorist Bodily Injury Protection limits must be equal to or less than your Bodily Injury Liability coverage'
    },
    personalInjuryProtection: {
        id: 'wmic.policy-change.component.bodily-injury.Personal Injury Protection Coverage',
        defaultMessage: 'Personal Injury Protection Coverage'
    },
    medicalPaymentsCoverage: {
        id: 'wmic.policy-change.component.bodily-injury.Medical Payments Coverage',
        defaultMessage: 'Medical Payments Coverage'
    },
    noCoverageSelected: {
        id: 'wmic.policy-change.component.bodily-injury.You do not currently have this coverage.',
        defaultMessage: 'You do not currently have this coverage.'
    },
    cancel: {
        id: 'wmic.policy-change.component.bodily-injury.Cancel',
        defaultMessage: 'Cancel'
    },
    continue: {
        id: 'wmic.policy-change.component.bodily-injury.Continue',
        defaultMessage: 'Continue'
    },
    biTooltip: {
        id: 'wmic.policy-change.component.bodily-injury.Bodily Injury tooltip',
        defaultMessage: 'This coverage protects you against possible loss of your home, income and life savings (financial loss) should your vehicle injure or kill anyone and you are legally liable. It also pays for related legal defense, court and investigative costs. This coverage pays up to the first limit for any one person and up to the second limit for two or more persons injured or killed.'
    },
    pdTooltip: {
        id: 'wmic.policy-change.component.bodily-injury.Property Damage tooltip',
        defaultMessage: 'This coverage protects you against financial loss when your vehicle damages another person\'s property, such as their vehicle, if you are legally liable.'
    },
    umTooltip: {
        id: 'wmic.policy-change.component.bodily-injury.Uninsured Motorist tooltip',
        defaultMessage: '(Coverage limits will match liability coverage unless a waiver is signed) This coverage provides protection against the hazard of bodily injury caused by someone who has no auto liability insurance or whose Bodily Injury Liability limits are not enough to pay the full amount you are legally entitled to recover as damages up to your chosen limit for this coverage.'
    },
    piTooltip: {
        id: 'wmic.policy-change.component.bodily-injury.Personal Injury tooltip',
        defaultMessage: 'Regardless of who is at fault for an auto accident, your medical payments coverage will pay, up to your policy limit, for injuries and related costs to you, resident family members and your vehicle’s passengers, such as medical bills, hospitalization bills, and funeral expenses. It also applies if you or resident family members are passengers in other vehicles or hit by a car while walking or riding a bicycle.'
    },
    confirmModalTitle: {
        id: 'wmic.policy-change.component.bodily-injury.Confirm policy changes',
        defaultMessage: 'Confirm policy changes'
    },
    confirmModalBody: {
        id: 'wmic.policy-change.component.bodily-injury.Please confirm',
        defaultMessage: 'Please confirm the following changes to your policy:'
    },
    confirmationLabel: {
        id: 'wmic.policy-change.component.bodily-injury.Submit',
        defaultMessage: 'Submit'
    },
    submitButtonTracking: {
        id: 'wmic.policy-change.component.bodily-injury.tracking.Policy Change - Submit - Adjust Coverages or Deductibles',
        defaultMessage: 'Policy Change - Submit - Adjust Coverages or Deductibles'
    }
});
