import React, { useContext } from 'react';
import createHOC from 'react-create-hoc';

/**
 * Authentication context.
 * When React renders a component that subscribes to this Context object it will read the
 * current context value from the closest matching Provider above it in the tree.
 *
 * @alias AuthContext
 * @memberof module:gw-digital-auth-react
 *
 * @type {React.Context}
 */
export const AuthContext = React.createContext({});

/**
 * Returns the current context value, as given by the nearest context provider for the AuthContext
 *
 * @function
 * @alias useAuthentication
 * @memberof module:gw-digital-auth-react
 *
 * @returns {*} current context value for AuthContext
 */
export const useAuthentication = () => useContext(AuthContext);

/**
 * Wraps a component in an authentication context
 *
 * @alias withAuthenticationContext
 * @memberof module:gw-digital-auth-react
 *
 * @param {ReactElement} WrappedComponent a component to be wrapped
 *
 * @returns {ReactElement} the element wrapped in authentication context
 */
export const withAuthenticationContext = createHOC(
    'withAuthenticationContext',
    (WrappedComponent) => {
        return function WithAuthenticationContext(props) {
            const authProps = useAuthentication();
            return <WrappedComponent {...authProps} {...props} />;
        };
    },
    { factory: false, allowExtraProps: true }
);

/**
 * The authentication context consumer. `AuthContext.Consumer`
 *
 * @alias AuthenticationContextConsumer
 * @memberof module:gw-digital-auth-react
 *
 * @type {Context.Consumer}
 *
 * @example
 *  <AuthenticationContextConsumer>
 *      ...
 *  </AuthenticationContextConsumer>
 */
export default AuthContext.Consumer;
