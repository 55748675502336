import { MockUpUtil } from '@xengage/gw-portals-util-js';

/**
 * MockupUtil was designed to control all mock-up information used through Digital applications
 */
export default {
    // Platform MockUpUtil functions
    getMockData: MockUpUtil.getMockData,
    isDisplayNameMocked: MockUpUtil.isDisplayNameMocked,
    isValueMocked: MockUpUtil.isValueMocked,
    cleanUpMockedProperties: MockUpUtil.cleanUpMockedProperties,
    setMockData: MockUpUtil.setMockData
};
