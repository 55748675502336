// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICDataTable__searchFieldContainer{max-width:100%;width:24rem}", "",{"version":3,"sources":["webpack://./src/customer/modules-react/wmic-pe-components-platform-react/WMICDataTable/WMICDataTable.module.scss"],"names":[],"mappings":"AAAA,0CAEI,cAAA,CADA,WACA","sourcesContent":[".searchFieldContainer {\n    width: 24rem;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFieldContainer": "app__WMICDataTable__searchFieldContainer"
};
export default ___CSS_LOADER_EXPORT___;
