import _ from 'lodash';
import jwtHelper from './JwtHelper';

/**
 * Normalizes the authorities to an array of Strings
 * some auth servers (e.g. Auth0) pass authorities as an array
 * rather than as a string (e.g. Okta)
 * @param {String|Array<String>} authorities
 * @returns {Array<String>}
 */
function getAuthoritiesArray(authorities) {
    if (_.isString(authorities)) {
        return authorities.split(' ');
    }
    return authorities;
}

/**
 * Returns whether the given `authority` is an edge authority
 * @param {String} authority the string representing the authority
 * @returns {Boolean} `true` if the authority is an Edge\ authority
 */
function isEdgeAuthority(authority) {
    return _.includes(authority, 'guidewire.edge');
}

/**
 * Returns an array of edge authorities
 * @param {String[]} authorities a list of string authorities
 * @returns {String[]} Filtered list of all edge authorities
 */
function filterAuthorities(authorities) {
    return getAuthoritiesArray(authorities).filter(isEdgeAuthority);
}

function getGrantedAuthorities(tokens) {
    const isAuthenticated = tokens.accessToken
        && !jwtHelper.isTokenExpired(tokens.accessToken);

    if (!isAuthenticated) {
        return [];
    }
    const decodedToken = jwtHelper.decodeToken(tokens.accessToken);
    const authorities = decodedToken.scope || decodedToken.grantedAuthorities;
    const filteredAuthorities = filterAuthorities(authorities);

    return filteredAuthorities.map((scope) => {
        const authorityRegex = /^guidewire\.edge\.(.*)\.(.*)\.(.*)$/g;
        const match = authorityRegex.exec(scope);
        return {
            authorityType: match[1].toUpperCase(),
            value: match[2],
            authorityLevel: match[3]
        };
    });
}

export {
    getAuthoritiesArray,
    filterAuthorities,
    getGrantedAuthorities
};
