import React, { useState, useEffect, useContext, useCallback, Fragment } from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { publish, JUTRO_TOPICS } from '@jutro/events';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICAccordionCard } from 'wmic-components-amp-common-react';
import { SUITES, DateUtil } from 'wmic-portals-utils-js';
import { WMICDocumentDownloadButton } from 'gw-capability-document-react';
import metadata from './WMICPolicyDocuments.metadata.json5';
import messages from './WMICPolicyDocuments.messages';
import styles from './WMICPolicyDocuments.module.scss';

const DOC_LIMIT = 5;
const ADDITIONAL_DOC_LIMIT = 5;

const WMICPolicyDocuments = (props) => {
    const {
        policyDocs,
        additionalPolicyDocs,
        isAdditionalDocumentsAvailable,
        cardHeaderMessage
    } = props;
    const [
        canShowAllAdditionalDocsBtn,
        setCanShowAllAdditionalDocsBtn,
    ] = useState(false);
    const [canShowDocsBtn, setCanShowDocsBtn] = useState((policyDocs.length > DOC_LIMIT));
    const [additionalDocsToShow, setAdditionalDocsToShow] = useState([]);
    const [policyDocsToShow, setPolicyDocsToShow] = useState([]);
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const formattedDate = (date, isForScreenReader = false) => {
        if (isForScreenReader) {
            return intl.formatDate(
                DateUtil.localDateDTOToDate(date),
                { year: 'numeric', month: 'short', day: 'numeric' }
            )
        }
        return intl.formatDate(
            DateUtil.localDateDTOToDate(date),
            { year: 'numeric', month: '2-digit', day: '2-digit' }
        )
    }

    const getDocumentContent = useCallback((data) => {
        const targetDocument = {
            id: data.id,
            friendlyName: translator(messages.viewDocument),
            fileName: data.name,
            sessionID: data.sessionID,
            type: SUITES[`${data.type.toUpperCase()}`]
        };

        return (
            <Fragment>
                <div className={`${styles['document-lable']}`}>
                    <span class="sr-only">
                        <a href='#linkToDocument' class="sr-only">
                            { translator(messages.policyDocumentsAria, { documentTitle: data.name, date: formattedDate(data.localDateCompleted, true) }) }
                        </a>
                    </span>
                    <div className={`${styles.documentLabelContent}`} aria-hidden>
                        <strong>
                            { data.name }
                        </strong>
                        <div>
                            { formattedDate(data.localDateCompleted) }
                        </div>
                    </div>
                </div>
                <div className={`${styles['document-button']}`} aria-hidden>
                    <WMICDocumentDownloadButton
                        targetDocument={targetDocument}
                        trackButtonIdentifier={translator(messages.policyDocumentsTrackButtonIdentifier, {documentName: data.fileName || data.name})}
                    />
                </div>
            </Fragment>
        );
    }, [translator, intl]);

    const policyDocsOverrides = useCallback((docs, docType) => {
        const overrides = docs.map((data, index) => {
            return {
                [`${docType}${index}`]: {
                    content: getDocumentContent(data)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [getDocumentContent]);

    const getMessageWithHeader = (msg) => <h2 className={styles.policyDocsTitle}>{msg}</h2>;
    
    const getAdditionalDocsHeader = (msg) => <h3 className={styles.additionalDocsHeader}>{msg}</h3>;

    const handleShowAllAdditionalDocs = () => {
        setCanShowAllAdditionalDocsBtn(false);
        setAdditionalDocsToShow(additionalPolicyDocs);
    };

    useEffect(() => {
        const isAdditionalDocsLong =
            additionalPolicyDocs.length > ADDITIONAL_DOC_LIMIT;
        if (isAdditionalDocsLong) {
            setAdditionalDocsToShow(
                additionalPolicyDocs.slice(0, ADDITIONAL_DOC_LIMIT)
            );
        } else {
            setAdditionalDocsToShow(additionalPolicyDocs);
        }
        setCanShowAllAdditionalDocsBtn(isAdditionalDocsLong);
    }, [additionalPolicyDocs]);

    const handleShowAllDocs = () => {
        setCanShowDocsBtn(false);
        setPolicyDocsToShow(policyDocs);
    };

    useEffect(() => {
        const isPolicyDocsLong =
            policyDocs.length > DOC_LIMIT;
        if (isPolicyDocsLong) {
            setPolicyDocsToShow(
                policyDocs.slice(0, DOC_LIMIT)
            );
        } else {
            setPolicyDocsToShow(policyDocs);
        }
        setCanShowDocsBtn(isPolicyDocsLong);
    }, [policyDocs]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICAccordionCard,
        },
    };

    const overrides = {
        cardTitle: {
            content: getMessageWithHeader(translator(cardHeaderMessage)),
        },
        documentContainer: {
            data: policyDocsToShow,
        },
        additionalDocumentsSectionContainer: {
            visible:
                additionalDocsToShow.length > 0 &&
                isAdditionalDocumentsAvailable,
        },
        additionalDocumentContainer: {
            data: additionalDocsToShow,
        },
        showAllDocuments: {
            visible: canShowDocsBtn,
            onClick: handleShowAllDocs,
            trackButtonIdentifier: translator(messages.showAllDocumentsTrackButtonIdentifier)
        },
        showAllAdditionalDocuments: {
            visible: canShowAllAdditionalDocsBtn,
            onClick: handleShowAllAdditionalDocs,
            trackButtonIdentifier: translator(messages.showAllAdditionalDocumentsTrackButtonIdentifier)
        },
        additionalDocumentsAccordionCard: {
            title: getAdditionalDocsHeader(translator(messages.additionalDocuments)),
        },
        additionalDocumentsAccordion: {
            onUpdateAccordionStates: (openedAccordionCards) => {
                if (Array.isArray(openedAccordionCards) && openedAccordionCards.length > 0) {
                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.additionalDocumentsExpanded)});
                } else {
                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.additionalDocumentsCollapsed)});
                }
            }
        },
        ...policyDocsOverrides(policyDocs, 'document'),
        ...policyDocsOverrides(additionalDocsToShow, 'additionalDocument'),
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={policyDocs}
        />
    );
};

WMICPolicyDocuments.propTypes = {
    policyDocs: PropTypes.arrayOf(
        PropTypes.shape({})
    ),
    additionalPolicyDocs: PropTypes.arrayOf(
        PropTypes.shape({})
    ),
    isAdditionalDocumentsAvailable: PropTypes.bool.isRequired,
    cardHeaderMessage: PropTypes.shape({})
};

WMICPolicyDocuments.defaultProps = {
    policyDocs: [],
    additionalPolicyDocs: [],
    isAdditionalDocumentsAvailable: true,
    cardHeaderMessage: messages.policyDocuments
};

export default WMICPolicyDocuments;