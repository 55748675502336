import { defineMessages } from 'react-intl';

export default defineMessages({
    close: {
        id: 'payment.views.one-inc-payment-modal.Close',
        defaultMessage: 'Close'
    },
    paymentSystemUnavailable: {
        id: 'payment.views.one-inc-payment-modal.Payment system unavailable',
        defaultMessage: 'Payment System Unavailable'
    },
    paymentSystemCurrentlyUnavailable: {
        id: 'payment.views.one-inc-payment-modal.The payment system is currently unavailable.  Please try again...',
        defaultMessage: 'The payment system is currently unavailable.  Please try again later or call us for assistance at &lt;a href=\"tel:1-800-640-2920\"&gt;1-800-640-2920&lt;/a&gt; Monday to Friday 7:30 AM to 7:30 PM PT or Saturday 8:00 AM to 4:30 PM PT.'
    }
});
