import React, { useCallback, useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import { withRouter, useHistory } from 'react-router-dom';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { InputField } from '@jutro/legacy/components';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { Loader } from '@jutro/components';
import { BillingService } from 'wmic-pe-capability-gateway-billing';
import { WMICLogger, WMICRichTextUtil } from 'wmic-pe-portals-utils-js';

import cx from 'classnames';
import PropTypes from 'prop-types';
import metadata from './WMICReceiptPage.metadata.json5';
import messages from './WMICReceiptPage.messages';
import styles from './WMICReceiptPage.module.scss';

const MAX_FETCH_POLICY_NUMBER_ATTEMPTS = 6;
const REFRESH_RATE_IN_MILLISECONDS = 5000;

function WMICReceiptPage(props) {
    const { match: { params }, isPolicyReceiptPage } = props;
    const { setWizardLoading } = useWizardModals();
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const history = useHistory();
    const [formData, setFormData] = useState(params);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [bindError, updateBindError] = useState(false);

    let attempts = 0;
    let fetchPolicyTimeout;

    const getAmountPaid = async (tmpFormData) => {
        try {
            const policyNumber = _.get(formData, 'policyNumber')
            const termNumber = _.get(formData, 'termNumber')
            const refNumber = _.get(formData, 'refNumber')
            const amountPaidResponse = await BillingService.getAmountPaid(policyNumber, termNumber, refNumber, authHeader);
            if (!_.isEmpty(amountPaidResponse)) {
                _.set(tmpFormData, 'amount', `$ ${amountPaidResponse.amount}`);
            }
            setFormData(tmpFormData);
            setIsLoadingData(false);
        } catch (e) {
            WMICLogger.error('WMICReceiptPage getAmountPaid', e);
        };
    }

    const fetchPolicyNumber = useCallback(() => {
        if (attempts++ < MAX_FETCH_POLICY_NUMBER_ATTEMPTS) {
            LoadSaveService.getPolicyNumberForSubmission(params.submissionNumber, authHeader).then((policyNumber) => {
                if (!policyNumber || _.isEmpty(policyNumber)) {
                    fetchPolicyTimeout = setTimeout(fetchPolicyNumber, REFRESH_RATE_IN_MILLISECONDS);
                } else {
                    _.set(formData, 'policyNumber', policyNumber);
                    setFormData({ ...formData, policyNumber });
                }
            }).catch(err => {
                WMICLogger.error('Cannot find the policy', err);
                updateBindError(true);
            });
        } else {
            updateBindError(true);
        }
    }, [attempts, setFormData]);

    const retrieveSubmissionData = useCallback(async () => {
        const tmpFormData = formData;
        await LoadSaveService.getBindDataForSubmission(params.submissionNumber, authHeader).then((bindData) => {
            const amount = _.find(_.get(bindData, 'paymentPlans'), { billingId: _.get(bindData, 'selectedPaymentPlan') }).downPayment?.amount;
            if (amount) {
                _.set(tmpFormData, 'amount', `$ ${amount}`);
            }
            const policyNumber = _.get(bindData, 'policyNumber');
            if (policyNumber) {
                _.set(tmpFormData, 'policyNumber', policyNumber);
            }
            else {
                fetchPolicyTimeout = setTimeout(fetchPolicyNumber, REFRESH_RATE_IN_MILLISECONDS);
            }
            setFormData(tmpFormData);
        }).catch(err => {
            WMICLogger.error('Cannot find the policy', err);
            updateBindError(true);
        });
        setIsLoadingData(false);
    }, [setWizardLoading, LoadSaveService, setFormData, params]);

    useEffect(() => {
        const tmpFormData = formData;

        tmpFormData.paymentMethod = `${tmpFormData.card} - ${tmpFormData.mPan}`
        tmpFormData.strDate = new Date(tmpFormData.date).toLocaleDateString(localStorage.getItem('selectedLocale'),
            { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', hour12: false, minute: 'numeric', second: 'numeric' });
        if (!isPolicyReceiptPage) {
            retrieveSubmissionData(params);
        } else {
            getAmountPaid(tmpFormData);
        }

        return () => {
            if (fetchPolicyTimeout) {
                clearTimeout(fetchPolicyTimeout);
            }
        };
    }, []);

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const onViewPolicy = () => {
        history.push(`/policies/${_.get(formData, 'policyNumber')}/${_.get(formData, 'termNumber', '1')}/summary`);
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true
        },
        successContainerId: {
            className: cx(styles.gwAlert, {
                [styles.gwAlertInfo]: true
            }),
            columns: ['3fr', '2fr']
        },
        loadingContainerId: {
            visible: _.isNil(_.get(formData, 'policyNumber')),
            className: cx(styles.gwAlert, {
                [styles.loadingContainer]: !bindError,
                [styles.loadedBindErrorContainer]: bindError,
            })
        },
        loadedContainer: {
            visible: !_.isNil(_.get(formData, 'policyNumber')),
            className: cx(styles.gwAlert, {
                [styles.loadedContainer]: true
            })
        },
        inProgressTitleSubHeading: {
            content: !bindError ? translator(messages.inProgressTitleSubHeading) : WMICRichTextUtil.translateRichText(translator(messages.bindErrorTitleSubHeading))
        },
        doneIcon: {
            visible: !isPolicyReceiptPage
        },
        policyCreatedTitle: {
            visible: !isPolicyReceiptPage,
            title: translator(messages.policyCreatedTitle, { policyNumber: _.get(formData, 'policyNumber') })
        },
        wmicLoader: {
            visible: true
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onPrint: handlePrint,
            onViewPolicy
        },
        resolveComponentMap: {
            WMICLoaderComponent: Loader,
            InputField
        },
        resolveClassNameMap: styles
    };
    if (isLoadingData) {
        return <Loader loaded={!isLoadingData} />;
    }

    return (
        <ViewModelForm
            model={formData}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICReceiptPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default withRouter(WMICReceiptPage);
