/* eslint-disable camelcase */
import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { DropdownMenuButton } from '@jutro/components';
import { WMICRequiredText } from 'wmic-pe-components-platform-react';
import { WMICVehiclesDetailView } from 'wmic-pe-capability-gateway-common-pa-react'
import { useWizardModals, WMICWizardChangeOrRenewalPage } from 'wmic-pe-portals-wizard-components-ui';
import {
    CONSTANTS,
    JURISDICTIONS,
    PAConstants,
    WMICLogger,
    ACTION_TYPES,
    WMICUserAccessUtil,
    MODAL_CONSTANTS,
    WMICVariousUtil,
    WMICVehicleUtil
} from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { EntityUtil } from '@xengage/gw-portals-util-js';

import { messages as policyRenewalCommonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import metadata from './WMICPRPAVehiclesPage.metadata.json5';
import messages from './WMICPRPAVehiclesPage.messages';

import { DropdownMenuLink } from '@jutro/router';

function WMICPRPAVehiclesPage(props) {
    const {
        wizardData: policyRenewalVM,
        updateWizardData
    } = props;

    const translator = useContext(TranslatorContext);
    const { authHeader, authUserData } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('WMICPRPAVehiclesPage');
    const viewModelService = useContext(ViewModelServiceContext);
    const { RenewalService } = useDependencies('RenewalService');
    const { setWizardLoading } = useWizardModals();
    const { showInfo, showWarning, showError, showConfirm } = useWizardModals();
    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);

    const [showErrors, setShowErrors] = useState(false);
    const [vehicleAction, setVehicleAction] = useState(ACTION_TYPES.NONE);
    const [additionalNamedInsureds, updateAdditionalNamedInsureds] = useState([]);
    const [previousAdditionalNamedInsureds, updatePreviousAdditionalNamedInsureds] = useState([]);
    const [primaryNamedInsured, updatePrimaryNamedInsured] = useState({});
    const [drivers, updateDrivers] = useState([]);
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [skeletonStruct, setSkeletonStruct] = useState({});
    const [vehicleTypes, updateVehicleTypes] = useState([]);

    const VEHICLES_PATH = 'lobData.personalAuto.coverables.vehicles';
    const DRIVERS_PATH = 'lobData.personalAuto.coverables.drivers';
    const LOB_PATH = 'lobData.personalAuto';
    const BASEDATA = 'baseData';

    const vehicleVMList = _.get(policyRenewalVM, `${VEHICLES_PATH}.children`, []);

    const initVehicleData = useCallback(async () => {
        const lobData = _.get(policyRenewalVM, LOB_PATH);
        const driversTmp = _.get(policyRenewalVM, DRIVERS_PATH);
        const baseData = _.get(policyRenewalVM, BASEDATA);

        const additionalNameInsuredsSub = _.get(baseData, `additionalNamedInsureds_WMIC.value`);
        const additionalNameInsuredsTmp = [];

        const skeletonStructure = await RenewalService.getSkeletonStructure(
            policyRenewalVM.value,
            authHeader
        );

        const vehicleStruct = _.get(skeletonStructure, VEHICLES_PATH)
        setSkeletonStruct(vehicleStruct);

        if (additionalNameInsuredsSub && !_.isEmpty(additionalNameInsuredsSub)) {
            additionalNameInsuredsSub.forEach((nameInsured) => {
                const additionalNameInsured = viewModelService.create(
                    nameInsured,
                    'pc',
                    'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC'
                );
                _.set(additionalNameInsured, 'isDriver', false);
                if (_.get(additionalNameInsured, 'subtype.value')
                    && _.isEqual(_.get(additionalNameInsured, 'subtype.value.code'), CONSTANTS.Person)) {
                    const isDriver = driversTmp.value.some((driver) => _.isEqual(
                            _.get(additionalNameInsured, 'contactPublicID.value'),
                            _.get(driver, 'person.publicID.value')
                        ));
                    _.set(additionalNameInsured, 'isDriver', isDriver);
                }
                additionalNameInsuredsTmp.push(additionalNameInsured);
                updateAdditionalNamedInsureds(additionalNameInsuredsTmp);
            });
        }

        const primaryNamedInsuredTmp = _.get(baseData, 'primaryNamedInsured_WMIC');
        _.set(primaryNamedInsuredTmp, 'relationshipToPrimaryInsured_WMIC', 'insured');
        _.set(primaryNamedInsuredTmp, 'isDriver', false);
        const isDriver = driversTmp.value.some((driver) => _.isEqual(
                _.get(primaryNamedInsuredTmp, 'contactPublicID'),
                _.get(driver, 'person.publicID')
            ));
        _.set(primaryNamedInsuredTmp, 'isDriver', isDriver);
        updatePrimaryNamedInsured(primaryNamedInsuredTmp);
        updatePreviousAdditionalNamedInsureds(_.get(lobData, 'updatePreviousAdditionalNamedInsureds.value'));
        const driversFiltered = _.filter(driversTmp.value, (driver) => driver.assignment_WMIC === CONSTANTS.ASSIGNMENT.ASSIGNED);
        updateDrivers(driversFiltered);
    }, [RenewalService, authHeader, policyRenewalVM, viewModelService]);

    const noVehicles = () => _.isEmpty(_.get(policyRenewalVM, `${VEHICLES_PATH}.value`, []))

    useEffect(() => {
        setWizardLoading(true);
        setReadOnlyUser(!WMICUserAccessUtil.canEditPolicyRenewal(authUserData.roles));
        const typelist = viewModelService.productMetadata.get('pc').types
            .getTypelist('VehicleType')
            .getCodesForCategory(
                {
                    code: CONSTANTS.COUNTRY.CA, typelist: { name: 'Country' }
                }
            );
        updateVehicleTypes(typelist);
        initVehicleData();

        const vehicles = _.get(policyRenewalVM, VEHICLES_PATH)

        vehicles.forEach((vehicle, index) => {
            _.unset(policyRenewalVM, `lobData.personalAuto.coverables.vehicles.value[${index}].flexDTO`);
        })

        setWizardLoading(false);

        registerInitialComponentValidation(() => !noVehicles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ensureUniqueVin = useCallback((vehicle) => {
        const vehicleMatch = _.filter(vehicleVMList, { vin: { value: vehicle.vin } });
        if (!vehicleMatch) {
            return undefined;
        }
        if (vehicleMatch.length === 1) {
            return !_.isEqual(vehicleMatch[0].value, vehicle) ? vehicleMatch[0] : undefined;
        }
        return _.find(vehicleMatch, (v) => v !== vehicle);
    }, [vehicleVMList]);

    const getVehicleDescription = (vehicle) => (`${vehicle.year ? `${vehicle.year} ` : ''}
            ${vehicle.make ? `${vehicle.make} ` : ''}
            ${vehicle.model ? `${vehicle.model} ` : ''}`)

    const isValid = useCallback((selectedVehicleVM) => {
        const vinMatchedVehicle = ensureUniqueVin(selectedVehicleVM.value);
        if (vinMatchedVehicle) {
            showInfo({
                title: messages.vinIsNotUnique,
                message: translator(messages.vinMatchMsg,
                    { vehicle: getVehicleDescription(vinMatchedVehicle.value) })
            })
            return false;
        }

        const isVinSearchRequired = WMICVehicleUtil.vinSearchRequired(selectedVehicleVM.value);
        if (isVinSearchRequired) {
            showWarning({
                title: messages.actionRequired,
                message: messages.vinSearchRequired,
                icon: MODAL_CONSTANTS.ICON.WARNING,
                confirmButtonText: messages.close,
            })
            .then(() => WMICVariousUtil.scrollToId('vehicleInfoAccordion'));
            return false;
        }

        return true;
    }, [ensureUniqueVin, translator, showInfo, showWarning]);

    const checkAndReportVehicleTypesCombinationValidity = useCallback(() => {
        const ontarioVehicles = policyRenewalVM.baseData
            && policyRenewalVM.baseData.lobData
            && policyRenewalVM.baseData.lobData.value
            && policyRenewalVM.baseData.lobData.value.personalAuto
            && policyRenewalVM.baseData.lobData.value.personalAuto.coverables.vehicles
            && policyRenewalVM.baseData.lobData.value.personalAuto.coverables.vehicles.filter(
                (vehicle) => vehicle.garageLocation && vehicle.garageLocation.state === JURISDICTIONS.ONTARIO);
        if (ontarioVehicles && ontarioVehicles.length > 1) {
            const hasAuto = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.personalAutoVehicleType);
            const hasMotorcycle = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.motorcycleVehicleType);
            const hasSnowVehicle = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.snowVehicleType);
            let errorMessage = '';
            if (hasAuto) {
                if (hasSnowVehicle) {
                    errorMessage = translator(messages.snowVehicleCombinationError);
                }
                if (hasMotorcycle) {
                    errorMessage = hasSnowVehicle
                        ? translator(messages.snowMotorcycleVehicleCombinationError)
                        : translator(messages.motorcycleVehicleCombinationError);
                }
            } else if (hasMotorcycle && hasSnowVehicle) {
                errorMessage = translator(messages.snowMotorcycleVehicleCombination);
            }
            if (errorMessage !== '') {
                showError({
                    title: translator(messages.errorVehicleCombinationError),
                    message: errorMessage,
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                });
                return false;
            }
        }
        return true;
    }, [policyRenewalVM.baseData, translator, showError]);

    const createVehicle = (person, skeletonStructureCreateVehicle) => {
        const vehicle = {
            tempID: EntityUtil.nextId(),
            vinOverride_WMIC: false,
            leaseOrRent: false,
            rateAtPrimaryLocation_WMIC: true,
            addAdditionalInterests_WMIC: false,
            mileageUnit_WMIC: 'KM',
            vehicleInspectionRequired_WMIC: false,
            drivers: [],
            registeredOwners_WMIC: [],
            commercialVehicle_WMIC: {},
            msrp_WMIC: null,
            costNew: null
        };

        if (skeletonStructureCreateVehicle.length && skeletonStructureCreateVehicle[0].metaDataMap) {
            vehicle.metaDataMap = skeletonStructureCreateVehicle[0].metaDataMap;
        }

        if (person && person.primaryAddress) {
            vehicle.licenseState = person.primaryAddress.state;
        }
        return vehicle;
    };

    const initializeVehicleValues = (vehicle, isUnderEditing, existingVehicle) => {
        _.set(vehicle, 'isUnderEditing', { value: isUnderEditing });
        _.set(vehicle, 'isAddingAdditionalInterest', { value: false });
        _.set(vehicle, 'isAddingCustomization', { value: false });
        _.set(vehicle, 'isAddingGarageLocation', { value: false });
        _.set(vehicle, 'isAddingPrimaryLocation', { value: false });
        _.set(vehicle, 'isAddingDriver', { value: false });
        _.set(vehicle, 'searchCompleted', { value: existingVehicle });
    };

    const createVehicleAddress = () => ({
            country: CONSTANTS.COUNTRY.CA,
            addressType: CONSTANTS.MAILING_ADDRESS
        })

    const onCreateVehicle = useCallback(async(vehicleType) => {
        const accountHolder = _.get(policyRenewalVM, 'baseData.accountHolder.value');
        const vehicle = createVehicle(accountHolder, skeletonStruct);
        _.set(vehicle, 'vehicleType', vehicleType);
        let vehicleAddress = createVehicleAddress();
        const accountAddress = _.get(policyRenewalVM, "baseData.accountAddresses_WMIC.value[0]"); // vehicle usage component does not set this address properly
        if (accountAddress) {
            vehicleAddress = accountAddress;
        }

        _.set(vehicle, 'primaryLocation_WMIC', vehicleAddress);
        _.set(vehicle, 'garageLocation', vehicleAddress);
        _.set(vehicle, 'ratingJurisdiction', _.get(policyRenewalVM, 'baseData.baseState.value.code'));
        _.set(vehicle, 'businessSegment_WMIC', CONSTANTS.BUSINESS_SEGMENT_CODES.PERSONAL);

        const vehiclesVM = _.get(policyRenewalVM, VEHICLES_PATH);
        const { _xCenter, _dtoName } = vehiclesVM;
        const _vehicleVM = viewModelService.create(vehicle, _xCenter, _dtoName);


        initializeVehicleValues(_vehicleVM, true, false);
        policyRenewalVM.lobData.personalAuto.coverables.vehicles.value.push(_vehicleVM.value);

        await WMICVehicleUtil.updateVehicleList(policyRenewalVM, RenewalService, authHeader);

        setVehicleAction(ACTION_TYPES.ADD);
        return _vehicleVM;
    }, [policyRenewalVM, skeletonStruct, viewModelService]);

    const onCreateVehicleWithModal = useCallback((vehicleType) => showConfirm({
        title: policyRenewalCommonMessages.revalidationRequiredTitle,
        message: policyRenewalCommonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.cancelModel,
    }).then( (results) => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
            updateWizardData(policyRenewalVM);
        }
        return onCreateVehicle(vehicleType);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [showConfirm, maintainFurtherStepsVisitedSubmitted, onCreateVehicle, setIsCustomStepsInvalidated, policyRenewalVM]);

    const saveVehicle = useCallback(async (newpolicyRenewalVM) => {
        _.unset(newpolicyRenewalVM.value, 'bindData');

        try {
            setWizardLoading(true, translator(messages.savingVehicle));

            const result = await RenewalService.saveRenewal(
                [newpolicyRenewalVM],
                authHeader
            );
            _.extend(policyRenewalVM.value, result);
            updateWizardData(policyRenewalVM);
            setShowErrors(false);
            return true;
        } catch (error) {
            WMICLogger.error(translator(messages.saveVehicleFailed), error);
            return false;
        } finally {
            setWizardLoading(false);
        };

        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [RenewalService, setWizardLoading, authHeader, translator, policyRenewalVM])

    const onSaveVehicle = useCallback((vehicleVM) => {
        const valid = isValid(vehicleVM);
        if (!valid || !checkAndReportVehicleTypesCombinationValidity()) {
            setShowErrors(true);
            return false;
        }

        return saveVehicle(policyRenewalVM.value)
    }, [isValid, checkAndReportVehicleTypesCombinationValidity, policyRenewalVM, saveVehicle]);

    const onDeleteVehicle = useCallback((vehicle, index) => {
        showConfirm({
            title: translator(messages.removeVehicleTitle),
            message: translator(messages.removeVehicle, { vehicle: getVehicleDescription(vehicle.value) })
        })
            .then((response) => {
                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    policyRenewalVM.value.lobData.personalAuto.coverables.vehicles.splice(index, 1);
                    return saveVehicle(policyRenewalVM.value);
                }
                return false;
            });
    }, [saveVehicle, showConfirm, policyRenewalVM, translator]);

    const getVehicleDescriptionCell = (item) => {
        const vehicle = item;
        return (
            <span>
                {getVehicleDescription(vehicle)}
                <div>
                    {_.get(vehicle, 'vin')}
                </div>
            </span>
        );
    };

    const onCancelVehicle = () => {
        _.set(policyRenewalVM, 'isEditingPage.value', false);
        updateWizardData(policyRenewalVM);
    }

    const onEditVehicle = useCallback(() => {
        _.set(policyRenewalVM, 'isEditingPage.value', true);
        setVehicleAction(ACTION_TYPES.EDIT);
    }, [policyRenewalVM]);

    const renderAddVehicleButton = useCallback(({ isEditing, onClick }) => (
        <DropdownMenuButton
            id="addNewAdditionalInterestButton"
            buttonText={translator(messages.newVehicle)}
            icon="mi-add"
            disabled={isEditing} >
            {vehicleTypes.map((vehicleType, index) => (
                <DropdownMenuLink onClick={() => onClick(vehicleType.code, index)}>
                    {translator({
                        id: vehicleType.name,
                        defaultMessage: vehicleType.code
                    })}
                </DropdownMenuLink>
            ))}
        </DropdownMenuButton>
    ), [translator, vehicleTypes]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }

        if (!isReadOnlyUser) {
            if (!checkAndReportVehicleTypesCombinationValidity()) {
                return false;
            }
        } else {
            return false;
        }
        try {
            setWizardLoading(true, translator(policyRenewalCommonMessages.savingTransactionDetails));

            const result = await RenewalService.saveRenewal(
                [policyRenewalVM.value],
                authHeader
            );

            _.extend(policyRenewalVM.value, result);
            return policyRenewalVM;
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, isReadOnlyUser, checkAndReportVehicleTypesCombinationValidity, setWizardLoading, translator, RenewalService, policyRenewalVM, authHeader]);

    const overrideProps = {
        vehiclesListView: {
            value: vehicleVMList,
            clickable: true,
            onSave: onSaveVehicle,
            onDelete: onDeleteVehicle,
            readOnly: isReadOnlyUser,
            toCreate: onCreateVehicleWithModal,
            toUndoCreate: () => {
                // to undo new vehicle creation, simply remove the last vehicle in the array (it's always the one being "added")
                const vehicles = _.get(policyRenewalVM.value, VEHICLES_PATH);
                vehicles.splice(vehicles.length - 1, 1);
                _.set(policyRenewalVM.value, VEHICLES_PATH, vehicles);
                updateWizardData(policyRenewalVM);
            },
            toEdit: onEditVehicle,
            onCancel: onCancelVehicle,
            showCancelModal: true,
            detailViewComponent: WMICVehiclesDetailView,
            onValidate,
            showErrors,
            startOpen: true,
            detailViewComponentProps: {
                jobVM: policyRenewalVM,
                updateWizardData,
                vehicleAction,
                additionalNamedInsureds,
                previousAdditionalNamedInsureds,
                primaryNamedInsured,
                drivers,
                isReadOnlyUser,
                skeletonStruct
            },
            renderAddButton: renderAddVehicleButton,
            VMData: [
                {
                    headerText: translator(messages.vehicleNumber),
                    path: 'vehicleNumber_WMIC'
                },
                {
                    headerText: translator(messages.theftIdentifier),
                    path: 'theftIdentifier_Ext'
                },
                {
                    headerText: translator(messages.vehicleType),
                    path: 'vehicleType'
                },
                {
                    headerText: translator(messages.vehicleDescription),
                    getData: (item) => getVehicleDescriptionCell(item.value)
                },
                {
                    headerText: translator(messages.vehicleBusinessSegment),
                    path: 'businessSegment_WMIC'
                }
            ],
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: onCreateVehicle
        },
        resolveComponentMap: {
            WMICRequiredText,
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(policyRenewalCommonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyRenewalVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPRPAVehiclesPage.propTypes = wizardProps;

export default withViewModelService(WMICPRPAVehiclesPage);
