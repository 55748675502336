// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__TypeAheadComponent__icon{color:var(--GW-SEPARATOR-COLOR);position:absolute;right:var(--GW-SPACING-2);top:var(--GW-SPACING-2)}.app__TypeAheadComponent__clearIcon{right:0;top:0}.app__TypeAheadComponent__zeroPadding{padding:0}.app__TypeAheadComponent__gwTypeaheadResults{--gw-typeahead-border:1px;background-color:var(--GW-BACKGROUND-COLOR);border:var(--gw-typeahead-border) solid var(--GW-BORDER-COLOR);list-style:none;padding:0}.app__TypeAheadComponent__gwTypeaheadMatch{padding:var(--GW-SPACING-2)}.app__TypeAheadComponent__gwTypeaheadMatch:hover{background-color:var(--GW-DROPDOWN-OPTION-BACKGROUND-COLOR-HOVER)}.app__TypeAheadComponent__gwTypeaheadMatch:active{background-color:var(--GW-DROPDOWN-OPTION-BACKGROUND-COLOR-ACTIVE)}", "",{"version":3,"sources":["webpack://./node_modules/gw-components-platform-react/TypeAheadComponent/TypeAheadComponent.module.scss"],"names":[],"mappings":"AAAA,+BACE,+BAAA,CACA,iBAAA,CAEA,yBAAA,CADA,uBACA,CAGF,oCAEE,OAAA,CADA,KACA,CAGF,sCACE,SAAA,CAGF,6CACE,yBAAA,CAGA,2CAAA,CACA,8DAAA,CAHA,eAAA,CACA,SAEA,CAGF,2CACE,2BAAA,CAEA,iDACE,iEAAA,CAEF,kDACE,kEAAA","sourcesContent":[".icon {\n  color: var(--GW-SEPARATOR-COLOR);\n  position: absolute;\n  top: var(--GW-SPACING-2);\n  right: var(--GW-SPACING-2);\n}\n\n.clearIcon {\n  top: 0;\n  right: 0;\n}\n\n.zeroPadding {\n  padding: 0;\n}\n\n.gwTypeaheadResults{\n  --gw-typeahead-border: 1px;\n  list-style: none;\n  padding: 0;\n  background-color: var(--GW-BACKGROUND-COLOR);\n  border: var(--gw-typeahead-border) solid var(--GW-BORDER-COLOR);\n}\n\n.gwTypeaheadMatch {\n  padding: var(--GW-SPACING-2);\n\n  &:hover {\n    background-color: var(--GW-DROPDOWN-OPTION-BACKGROUND-COLOR-HOVER);\n  }\n  &:active {\n    background-color: var(--GW-DROPDOWN-OPTION-BACKGROUND-COLOR-ACTIVE);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "app__TypeAheadComponent__icon",
	"clearIcon": "app__TypeAheadComponent__clearIcon",
	"zeroPadding": "app__TypeAheadComponent__zeroPadding",
	"gwTypeaheadResults": "app__TypeAheadComponent__gwTypeaheadResults",
	"gwTypeaheadMatch": "app__TypeAheadComponent__gwTypeaheadMatch"
};
export default ___CSS_LOADER_EXPORT___;
