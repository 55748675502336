import { defineMessages } from 'react-intl';

export default defineMessages({
    belowAreTheChangesYouVeSubmitted: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.review-vehicle-coverages.Below are the changes you\'ve submitted',
        defaultMessage: 'Below are the changes you\'ve submitted'
    },
    previousAmount: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.review-vehicle-coverages.Previous Amount',
        defaultMessage: 'Previous Amount'
    },
    newAmount: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.review-vehicle-coverages.New Amount',
        defaultMessage: 'New Amount'
    },
    comprehensiveCovDedTooltip: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.review-vehicle-coverages.comprehensiveCovDedTooltip',
        defaultMessage: 'Comprehensive coverage deductibles cannot be lowered online. For assistance, please call &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    collisionCovDedTooltip: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.review-vehicle-coverages.collisionCovDedTooltip',
        defaultMessage: 'Collision coverage deductibles cannot be lowered online. For assistance, please call &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    perDay: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.review-vehicle-coverages.per day',
        defaultMessage: 'per day'
    },
    perOccurrence: {
        id: 'wmic.policy-change.component.change-deductibles-coverages-pa.review-vehicle-coverages.per occurrence',
        defaultMessage: 'per occurrence'
    }
});