import { defineMessages } from 'react-intl';

export default defineMessages({
    AdditionalInsuredVehiclesAndDrivers: {
        id: 'wmic.component.additional-vehicle-info-box-component.Additional Insured Vehicles and Drivers',
        defaultMessage: 'Additional Insured Vehicles and Drivers'
    },
    Pending: {
        id: 'wmic.component.additional-vehicle-info-box-component.Pending',
        defaultMessage: 'Pending'
    },
    InformationUnavailable: {
        id: 'wmic.component.additional-vehicle-info-box-component.Information Unavailable',
        defaultMessage: 'Information Unavailable'
    },
    Vehicle: {
        id: 'wmic.component.additional-vehicle-info-box-component.Vehicle',
        defaultMessage: 'Vehicle'
    },
    Driver: {
        id: 'wmic.component.additional-vehicle-info-box-component.Driver',
        defaultMessage: 'Driver'
    },
    VehicleWasParked: {
        id: 'wmic.component.additional-vehicle-info-box-component.Vehicle was parked',
        defaultMessage: 'Vehicle was parked'
    }
});
