/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IntlContext, useTranslator } from '@jutro/locale';
import _ from 'lodash';
import styles from './WMICPremiumBox.module.scss';

import messages from './WMICPremiumBox.messages';
import { Button } from '@jutro/legacy/components';

const WMICPremiumBox = ({
    isStale,
    showLoading,
    recalculate,
    displayed,
    monthlyAmount,
    fullAmount
}) => {
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const onRecalculate = () => {
        recalculate();
    }

    if (isStale) {
        return (
            <div className={styles.WMICPremiumBox}>
                <div className="wmicShowBiggerThanPhoneLandscape">
                    {translator(messages.quoteChanged)}
                </div>
                <div className={styles.wmicQuoteChangedText}>
                    {translator(messages.quoteChangedPart1)}
                </div>
                <div className={styles.wmicQuoteChangedText}>
                    {translator(messages.quoteChangedPart2)}
                </div>
                <Button
                    className={cx(styles.recalculateButton, 'ww-btn-secondary')}
                    type="secondary"
                    onClick={onRecalculate}
                    icon="cust-calculator-solid"
                    iconClassName={styles.buttonIcon}
                >
                    {translator(messages.recalculate)}
                </Button>
                <div className={styles.wmicScrollDown}>
                    {translator(messages.scrollDown)}
                </div>
            </div>
        );
    }

    if (showLoading) {
        return (
            <div className={styles.WMICPremiumBox}>
                {translator(messages.recalculating)}
            </div>
        );
    }

    const formattedAmount = (currAmount) => intl.formatNumber(
        currAmount.amount,
        {
            style: 'currency',
            currency: currAmount.currency,
            currencyDisplay: 'symbol'
        }
    );

    if (displayed) {
        return (
            <div className={cx(styles.WMICPremiumBox, styles.calculated)}>
                <div className={styles.priceAndBreakdown}>
                    <div className={styles.price}>
                        <h1>{formattedAmount(monthlyAmount)}</h1>
                    </div>
                    <div className={styles.priceBreakdown}>
                        <h3>{translator(messages.monthlyPayment)}</h3>
                        <h4>{translator(messages.fullPayment, { amount: formattedAmount(fullAmount) })}</h4>
                    </div>
                </div>
                <div className={styles.scrollDownPurchase}>
                    {translator(messages.scrollDown)}
                </div>
            </div>
        );
    }

    return (
        <span />
    );
};

WMICPremiumBox.propTypes = {
    isStale: PropTypes.bool,
    showLoading: PropTypes.bool,
    recalculate: PropTypes.func.isRequired,
    displayed: PropTypes.bool,
    monthlyAmount: PropTypes.string,
    fullAmount: PropTypes.string
};

WMICPremiumBox.defaultProps = {
    isStale: false,
    showLoading: false,
    displayed: false,
    monthlyAmount: undefined,
    fullAmount: undefined
};

export default WMICPremiumBox;