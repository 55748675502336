/* eslint-disable react/no-unknown-property */
 
import React from 'react';

const isElementVisible = (element) => {
    return !(element === null || element === '' || element === undefined);
};

const getTextWithIcon = (iconClass, aMessage, messageClass) => {
    const getMessageHTML = () => {
        if (messageClass === undefined) {
            return aMessage;
        }
        return (<span class={messageClass}>{aMessage}</span>);
    };

    return (
        <span>
            <icon class={iconClass} /> {getMessageHTML()}
        </span>
    );
};

export default {
    isElementVisible: (element) => {
        return isElementVisible(element);
    },
    isElementAccessible: (element) => {
        return isElementVisible(element);
    },
    getTextWithIcon
};