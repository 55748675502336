import React, { useContext } from 'react';
import _ from 'lodash';
import { Accordion } from '@jutro/legacy/components';
import { QuoteClauseTable, WMICAccordionCard, WMICHeading, ClausesComponentVM } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {Position} from "wmic-pe-portals-utils-js";
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';

import WMICCPCoverageUtil from "wmic-pe-capability-gateway-quoteandbind-cp-react/util/WMICCPCoverageUtil";
import messages from './WMICCPBuildingCoverages.messages';
import metadata from './WMICCPBuildingCoverages.metadata.json5';

function WMICCPBuildingCoverages(props) {
    const {
        buildingIndex,
        buildingVM,
        buildingCoverages,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        onValidate,
        jobVM,
        isBoundPolicy,
        readOnly
    } = props;

    const translator = useContext(TranslatorContext);

    const { isAccordionValid } = useAccordionValidation(onValidate);
    const generateClauseData = (coverageColumnData) => {
        return coverageColumnData.map(({ lob, code }) => {
            return {
                code: code,
                path: `${lob.path}.coverages.buildingCoverages.children[${buildingIndex}].coverages`,
                clauses: _.get(buildingCoverages, 'coverages.value')
            };
        });
    }

    const getBuildingCoverages = () => {
        // only show these categories
        const categoryMap = ['buildingCoverages', 'buildingContents', 'additionalBuildingCoverages'];

        const categorizedCoverages = WMICCPCoverageUtil.categorizeBuildingCoverages(buildingCoverages.value);
        const coverageAccordions = categoryMap.map((categoryKey) => {
            const buildingCoverage = _.get(categorizedCoverages, categoryKey);

            if (!isBoundPolicy) {
                const coverageColumnData = WMICCPCoverageUtil.generateColumnData(jobVM);
                const coverageTableData = [{
                    data: buildingCoverage.coverages,
                    tableContent: generateClauseData(coverageColumnData)
                }]

                return (<>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={buildingCoverage.categoryName}
                        isValid={isAccordionValid(`clauseTable-${buildingCoverage.categoryID}`)} />
                    <QuoteClauseTable
                        id={`clauseTable-${buildingCoverage.categoryID}`}
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={readOnly}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        showTooltip={false}
                        onValidate={onValidate}
                    />
                </>);
            }

            return (
                <>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={buildingCoverage.categoryName}
                    />
                    <ClausesComponentVM
                        showAmount={false}
                        isEditable
                        readOnly
                        path={`${categoryKey}.coverages`}
                        value={buildingCoverage.coverages}
                    />
                </>
            );
        });

        return coverageAccordions;
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: buildingVM
        },
        buildingCoveragesAccordions: {
            content: getBuildingCoverages()
        }
    }

    const resolvers = {
        resolveComponentMap: {
            Accordion
        }
    }

    return (
        <WMICAccordionCard
            id={`buildingAccordionCard${buildingIndex}`}
            title={`${translator(messages.buildingNumberHeader, { buildingNumber: buildingVM.buildingNumber.value })}`}
            chevron
            chevronAlignment='left'
            headerVariant='h2'
            headerSize='h2'
            className='gw-mb-0'
            cardBodyClassName='gw-mx-2'
        >
            <ViewModelForm
                model={buildingVM}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICAccordionCard>
    );
}

export default WMICCPBuildingCoverages;
