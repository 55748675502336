import _ from 'lodash';

const getModalType = (step, page, isReadOnlyPlan) => {
    const changePaymentMsgKey = 'changePaymentPlan';
    const managePaymentMsgKey = 'managePaymentPlan';
    const viewPaymentMsgKey = 'viewPaymentPlan';

    if (step > page) {
        return changePaymentMsgKey;
    }

    if (isReadOnlyPlan) {
        return viewPaymentMsgKey;
    }

    return managePaymentMsgKey;
};

const generatePaymentData = (intl, group, key) => {
    return {
        eventDate: intl.formatDate(
            new Date(key), { year: 'numeric', month: '2-digit', day: '2-digit' }
        ),
        amount: _.reduce(group, (memory, invoiceItem) => {
            const paidAmount = _.get(invoiceItem, 'paidAmount.amount', 0);
            return (memory + invoiceItem.amount.amount) - paidAmount;
        }, 0)
    };
};

export default {
    getModalType,
    generatePaymentData
};