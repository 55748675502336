import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table, TableColumn, RadioColumn } from '@jutro/legacy/datatable';
import { Currency as CurrencyField, FieldLinkComponent } from 'gw-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { WMICLogger } from "wmic-pe-portals-utils-js";
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import WMICPreviewPaymentsModal from './WMICPreviewPaymentsModal/WMICPreviewPaymentsModal';
import styles from './WMICPaymentPlansList.module.scss';
import messages from './WMICPaymentPlansList.messages';

function WMICPaymentPlansList(props) {
    const { value, quoteID, postalCode, authHeader } = props;
    const { paymentDetails, selectedPaymentPlan, paymentPlans } = value;
    const { showCustom, setWizardLoading } = useWizardModals();
    const translator = useContext(TranslatorContext);

    const generateDataForRadioButton = (row) => ({
            name: '',
            code: row.billingId
        });

    const generateDownPayment = (row, rowIndex) => {
        if (_.isNil(row.downPayment)) {
            row.downPayment = {currency: 'cad', amount: 0}
        }
        return (
            <CurrencyField
                id={`downpayment_${rowIndex}`}
                value={row.downPayment}
                dataType="object"
                showFractions
                readOnly
                hideLabel
            />
        );
    };

    const generateInstallmentPayment = (row, rowIndex) => {
        if (_.isNil(row.installment)) {
            row.installment = {currency: 'cad', amount: 0}
        }
        return (
            <CurrencyField
                id={`installment_${rowIndex}`}
                value={row.installment}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );
    };

    const generateTotalPayment = (row, rowIndex) => (
            <CurrencyField
                id={`totalpayment_${rowIndex}`}
                value={row.total}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );

    const previewPayments = (row) => {
        const obj = {
            postalCode,
            quoteID,
            dayOfMonth: paymentDetails.firstDateOfMonth,
            billingID: selectedPaymentPlan
        };
        const retrievePaymentPreviewPromise = LoadSaveService.retrievePaymentPreview(obj, authHeader)
        setWizardLoading(true, "");
        retrievePaymentPreviewPromise.then((response) => {
            showCustom(
                <WMICPreviewPaymentsModal
                    payments={response}
                />
            ).catch(() => {
                _.noop();
            });
        }).catch((error) => {
            WMICLogger.error(`request=retrievePaymentPreview, error=${error}`);
        }).finally(() => {
            setWizardLoading(false);
        });
    };
    
    const generateViewLink = (row, rowIndex) => {
        if (row.billingId === selectedPaymentPlan) {
            return (
                <FieldLinkComponent
                    id={`viewlink_${rowIndex}`}
                    onClick={() => previewPayments(row)}
                    value={translator(messages.view)}
                    icon='mi-open-in-browser'
                />
            );
        }
        return "-";
    };    

    const getTypeCell = (row, rowIndex) => row.name;

    const handleValueChange = (value) => {
        const { onValueChange, path, selectedPaymentPlanChanged } = props;
        if (onValueChange) {
            selectedPaymentPlanChanged(value);
            return onValueChange(value, `${path}.selectedPaymentPlan`);
        }
        return null;
    };

    return (
        <Table
            data={paymentPlans}
            className={styles.paymentListTable}
            columnsProportion={[0.5, 2, 1, 1, 1, 1]}
        >
            <RadioColumn
                id="plan"
                textAlign="center"
                header={messages.select}
                option={generateDataForRadioButton}
                cellClassName={styles.radioCell}
                headerClassName={styles.headerCell}
                value={selectedPaymentPlan}
                onValueChange={handleValueChange}
            />
            <TableColumn
                id="type"
                textAlign="left"
                header={messages.type}
                cellClassName={styles.paymentListTableCell}
                cell={getTypeCell}
            />
            <TableColumn
                id="downPayment"
                textAlign="left"
                header={messages.downPayment}
                cellClassName={styles.paymentListTableCell}
                cell={generateDownPayment}
            />
            <TableColumn
                id="installment"
                textAlign="left"
                header={messages.installment}
                cellClassName={styles.paymentListTableCell}
                cell={generateInstallmentPayment}
            />
            <TableColumn
                id="total"
                textAlign="left"
                header={messages.total}
                cellClassName={styles.paymentListTableCell}
                cell={generateTotalPayment}
            />                
            <TableColumn
                id="schedule"
                textAlign="left"
                header={messages.schedule}
                cellClassName={styles.paymentListTableCell}
                cell={generateViewLink}
            />
        </Table>
    );
};

export default WMICPaymentPlansList;
