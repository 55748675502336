// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICBulletPointComponent__bullet-list{display:block;list-style-type:disc;margin-bottom:1em;margin-top:1em;padding-left:40px;text-align:left!important}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policy-common-react/components/WMICBulletPointComponent/WMICBulletPointComponent.module.scss"],"names":[],"mappings":"AAEA,4CACE,aAAA,CACA,oBAAA,CAEA,iBAAA,CADA,cAAA,CAEA,iBAAA,CACA,yBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n\n.bullet-list {\n  display: block;\n  list-style-type: disc;\n  margin-top: 1em;\n  margin-bottom: 1em;\n  padding-left: 40px;\n  text-align: left !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bullet-list": "app__WMICBulletPointComponent__bullet-list"
};
export default ___CSS_LOADER_EXPORT___;
