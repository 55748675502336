/* eslint-disable no-secrets/no-secrets */
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { WMICLogger, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import {
    WMICHOConstructionDetailView,
    presentConstruction,
    WMICKitchenBathValidator
} from 'wmic-pe-capability-gateway-common-ho-react';
import { renderDwellingRiskNumberCell, renderPrimaryCell, renderDwellingAddressCell } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './WMICHOPolicyChangeConstructionPage.metadata.json5';
import messages from './WMICHOPolicyChangeConstructionPage.messages';

const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';

function WMICHOPolicyChangeConstructionPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const { EndorsementService } = useDependencies('EndorsementService');
    const { authHeader, authUserData: currentUser } = useAuthentication();
    const { setWizardLoading, showError } = useWizardModals();
    const { isComponentValid, initialValidation, onValidate, registerInitialComponentValidation } = useValidation(
        'WMICHOPolicyChangeConstructionPage'
    );
    useDocumentTitle("Construction", policyChangeVM);

    const canEditPolicyChange = useMemo(
        () => WMICUserAccessUtil.canEditPolicyChange(currentUser.roles),
        [currentUser]
    );

    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState(); // we toggle this when we want to scroll to the first error on the page
    const [hasDwellings, setHasDwellings] = useState(false);

    useEffect(() => {
        registerInitialComponentValidation(() => presentConstruction(policyChangeVM));
        setHasDwellings(_.get(policyChangeVM, `${DWELLINGS_PATH}.children`, []).length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {

        if (!isComponentValid) {
            setShowErrors(true);
            setScrollToError(Date.now())
            return false;
        }

        try {
            setWizardLoading(true);
            const newPolicyChangeVM = viewModelService.clone(policyChangeVM);
            _.unset(newPolicyChangeVM.value, 'bindData');

            policyChangeVM.value = await EndorsementService.saveEndorsement(
                [policyChangeVM.value],
                authHeader
            );

            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
    }, [viewModelService, policyChangeVM, EndorsementService, authHeader, setWizardLoading, isComponentValid]);

    const saveConstruction = useCallback(async (newPolicyChangeVM) => {
        _.unset(newPolicyChangeVM.value, 'bindData');
        try {
            setWizardLoading(true, translator(messages.savingConstruction));

            const result = await EndorsementService.saveEndorsement(
                [newPolicyChangeVM.value],
                authHeader
            );
            _.extend(policyChangeVM.value, result);
            updateWizardData(policyChangeVM);
            setShowErrors(false);
            return true;
        } catch (error) {
            WMICLogger.error('Save construction failed', error);
            return false;
        } finally {
            setWizardLoading(false);
        };
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setWizardLoading, translator, EndorsementService, authHeader, policyChangeVM]);

    const kitchenBathValidator = useMemo(() => new WMICKitchenBathValidator(viewModelService.productMetadata.get('pc').types.getTypelist('DWIGKitchenBathFeat_WMIC')), []);

    const isKitchenBathValidationValid = (dwellingVM) => {
        const kitchenBathsValidationErrors = kitchenBathValidator.getValidationMessagesForDwelling(dwellingVM?.value);
        if (Array.isArray(kitchenBathsValidationErrors) && kitchenBathsValidationErrors.length > 0) {
            showError({
                title: messages.validationKitchenError,
                message: kitchenBathsValidationErrors.map((validation) => <p>{translator({id: validation.message}, {min: validation.min, max: validation.max})}</p>)
            });
            return false;
        }
        return true;
    }

    const onSaveDwelling = useCallback((dwellingVM, index) => {
        if (!isKitchenBathValidationValid(dwellingVM)) {
            setShowErrors(true);
            return false;
        }

        _.set(dwellingVM, 'value.isUnderEditing', false);

        // unset heaters' secondarySurveyDetail if not used, to get rid of validation errors
        dwellingVM.construction.heaters.value.forEach((heater, heaterIndex) => {
            const isPrimaryFuelSourceSecondarySurveyDetailNeeded = _.get(heater, 'primaryFuelSource.fuelSurvey.secondarySurveyDetail.oilTankType', false);
            const isSecondaryFuelSourceSecondarySurveyDetailNeeded = _.get(heater, 'secondaryFuelSource.fuelSurvey.secondarySurveyDetail.oilTankType', false);

            if (!isPrimaryFuelSourceSecondarySurveyDetailNeeded) {
                _.unset(dwellingVM.value, `construction.heaters[${heaterIndex}].primaryFuelSource.fuelSurvey.secondarySurveyDetail`)
            }

            if (!isSecondaryFuelSourceSecondarySurveyDetailNeeded) {
                _.unset(dwellingVM.value, `construction.heaters[${heaterIndex}].secondaryFuelSource.fuelSurvey.secondarySurveyDetail`)
            }
        })

        policyChangeVM.lobData.homeowners.coverables.dwellings.setElement(index, dwellingVM.value);
        _.set(policyChangeVM, 'isEditingPage.value', false);
        // Third argument to saveSubmission is any actions to take in case of an error
        return saveConstruction(policyChangeVM);
    }, [saveConstruction, policyChangeVM]);

    const onEditDwelling = () => {
        _.set(policyChangeVM, 'isEditingPage.value', true);
        updateWizardData(policyChangeVM);
    }

    const overrideProps = {
        noRisksText: {
            visible: !hasDwellings
        },
        risksDataList: {
            clickable: true,
            startOpen: true,
            readOnly: !canEditPolicyChange,
            showErrors,
            editEnabled: true,
            value: _.get(policyChangeVM, `${DWELLINGS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.riskNumber),
                    path: 'yourHome.dwellingNumber_EXT',
                    getData: (item, path) => renderDwellingRiskNumberCell(item, path, policyChangeVM)
                },
                {
                    headerText: translator(messages.riskPrimary),
                    path: 'yourHome.primaryDwelling_EXT',
                    getData: renderPrimaryCell
                },
                {
                    headerText: translator(messages.riskDescription),
                    path: 'yourHome.locationAddress.displayName',
                    getData: (item) => renderDwellingAddressCell(item, translator)
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'rating.hoPolicyType'
                }
            ],
            detailViewComponent: WMICHOConstructionDetailView,
            detailViewComponentProps: {
                jobVM: policyChangeVM
            },
            onValidate,
            onSave: onSaveDwelling,
            toEdit: onEditDwelling,
            canDelete: () => false,
            onCancel: () => {
                _.set(policyChangeVM, 'isEditingPage.value', false);
            },
            showCancelModal: true
        }
    };

    return (
        <React.Fragment>
            <WMICScrollToError counter={scrollToError}/>
            <WMICWizardChangeOrRenewalPage
                onNext={onNext}
                cancelLabel={translator(commonMessages.saveAndExit)}
                isSkipping={initialValidation}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={policyChangeVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </WMICWizardChangeOrRenewalPage>
        </React.Fragment>
    );
}

WMICHOPolicyChangeConstructionPage.propTypes = wizardProps;

export default withViewModelService(WMICHOPolicyChangeConstructionPage);
