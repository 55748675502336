// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__HOLocationCoverages__pageContainer{margin-top:var(--GW-LAYOUT-8)}.app__HOLocationCoverages__policySectionStyle{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:var(--GW-SPACING-10);padding-bottom:var(--GW-SPACING-5)}.app__HOLocationCoverages__coverageTitle{padding:var(--GW-SPACING-5)}.app__HOLocationCoverages__coverageDetailsContainer{background-color:var(--GW-COLOR-SUCCESS-CONTRAST);margin-top:var(--GW-LAYOUT-4)}.app__HOLocationCoverages__toggleLocationInfo{color:var(--GW-TEXT-COLOR-1);justify-content:right;text-align:right;text-decoration:none}.app__HOLocationCoverages__toggleLocationInfo:hover{color:var(--GW-TEXT-COLOR-1-HOVER);text-decoration:none}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-claim-ho-react/pages/HOLocationCoverages/HOLocationCoverages.module.scss"],"names":[],"mappings":"AACA,yCACC,6BAAA,CAED,8CAEC,iEAAA,CACA,kCAAA,CAFA,kCAEA,CAED,yCACC,2BAAA,CAED,oDAEC,iDAAA,CADA,6BACA,CAID,8CAII,4BAAA,CADA,qBAAA,CADA,gBAAA,CADA,oBAGA,CAEA,oDACF,kCAAA,CACA,oBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n.pageContainer {\n\tmargin-top: var(--GW-LAYOUT-8);\n}\n.policySectionStyle {\n\tpadding-bottom: var(--GW-SPACING-5);\n\tborder-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n\tmargin-bottom: var(--GW-SPACING-10);\n}\n.coverageTitle {\n\tpadding: var(--GW-SPACING-5);\n}\n.coverageDetailsContainer {\n\tmargin-top: var(--GW-LAYOUT-4);\n\tbackground-color: var(--GW-COLOR-SUCCESS-CONTRAST);\n}\n\n\n.toggleLocationInfo {\n    text-decoration: none;\n    text-align: right;\n    justify-content: right;\n    color: var(--GW-TEXT-COLOR-1);\n\n    &:hover {\n\t\tcolor: var(--GW-TEXT-COLOR-1-HOVER);\n\t\ttext-decoration: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "app__HOLocationCoverages__pageContainer",
	"policySectionStyle": "app__HOLocationCoverages__policySectionStyle",
	"coverageTitle": "app__HOLocationCoverages__coverageTitle",
	"coverageDetailsContainer": "app__HOLocationCoverages__coverageDetailsContainer",
	"toggleLocationInfo": "app__HOLocationCoverages__toggleLocationInfo"
};
export default ___CSS_LOADER_EXPORT___;
