// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PolicyDwellingInfo__policyHomeOwnersSection:not(:last-child){margin-bottom:var(--GW-LAYOUT-10)}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policy-common-react/components/HO/PolicyDwellingInfo/PolicyDwellingInfo.module.scss"],"names":[],"mappings":"AAAA,mEACI,iCAAA","sourcesContent":[".policyHomeOwnersSection:not(:last-child) {\n    margin-bottom: var(--GW-LAYOUT-10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyHomeOwnersSection": "app__PolicyDwellingInfo__policyHomeOwnersSection"
};
export default ___CSS_LOADER_EXPORT___;
