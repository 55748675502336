import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDataRefresh, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import metadata from './WMICHOPetsTableEdit.metadata.json5';

const WMICHOPetsTableEdit = ({
    id,
    petVM,
    onDataSave,
    onDataCancel,
    showErrors,
    onValidate
}) => {
    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(id);
    const { refreshData } = useDataRefresh()

    const [currentPetVM, setCurrentPetVM] = useState(petVM);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const updateCurrentPet = (data) => {
        setCurrentPetVM(data);
        refreshData();
    }

    useEffect(() => {
        updateCurrentPet(petVM);
    }, [petVM])

    const overrideProps = {
        '@field': {
            parentNode: currentPetVM,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onSavePet: onDataSave,
            onCancelPet: onDataCancel
        }
    };

    return(
        <ViewModelForm
            model={currentPetVM}
            onModelChange={updateCurrentPet}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            showErrors={showErrors}
        />
    );
}


WMICHOPetsTableEdit.propTypes = {
    petVM: PropTypes.shape({}),
    onDataSave: PropTypes.func.isRequired,
    onDataCancel: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICHOPetsTableEdit;