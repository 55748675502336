/* eslint-disable max-len */
import _ from 'lodash';
import { CONSTANTS } from 'wmic-portals-utils-js';

function getIncidentVehicles(details) {
    const incidentVehicles = _.map(details.lobs.personalAuto.vehicleIncidents, (incident) => {
        const incidentVehicle = incident.vehicle;
        if (incidentVehicle) {
            incidentVehicle.wasParked = incident.vehicleParked;
            incidentVehicle.driver = incident.driver;
            return incidentVehicle;
        }
        return {};
    });

    return _.filter(incidentVehicles, (vehicle) => {
        return vehicle.policyVehicle;
    });
}

function resolveInsuredVehicleDriver(driver, vehicle, reportedDate, translator, messages) {
    if (vehicle.wasParked) {
        return translator(messages.VehicleWasParked);
    }

    if (driver === CONSTANTS.UNKNOWN || driver === CONSTANTS.UNK || driver === '') {
        const twoDays = 2 * 24 * 60 * 60 * 1000; // days * hours * minutes * seconds * milliseconds
        const nowTime = new Date().getTime();
        const reportedDateTime = new Date(reportedDate).getTime();

        if (nowTime - reportedDateTime <= twoDays) {
            return translator(messages.Pending);
        }
        return translator(messages.InformationUnavailable);
    }
    return driver;
}

function getOtherInsuredVehicles(info, translator, messages) {
    if (info) {
        const incidentVehicles = getIncidentVehicles(info);
        let insuredVehicles = [];

        if (incidentVehicles.length > 0) {
            insuredVehicles = _.map(incidentVehicles, (vehicle) => {
                const insuredVehicle = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
                let insuredVehicleDriver = vehicle.driver !== undefined ? vehicle.driver.displayName : CONSTANTS.UNKNOWN;
                insuredVehicleDriver = resolveInsuredVehicleDriver(insuredVehicleDriver, vehicle, info.claimReporter.reportedDate, translator, messages);
                return {
                    vehicle: insuredVehicle,
                    driver: insuredVehicleDriver
                };
            });

            insuredVehicles.shift();
            return insuredVehicles;
        }
    }

    return undefined;
}

export default {
    getIncidentVehicles,
    resolveInsuredVehicleDriver,
    getOtherInsuredVehicles
};