import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import classNames from 'classnames';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { 
    WMICFeatureFlagUtil,
    WMICTempStorageService,
    WMICRichTextUtil,
    WMICBillingUtil
} from 'wmic-portals-utils-js';
import { WMICCheckbox } from 'wmic-components-platform-react';
import { WMICLoader } from 'gw-components-platform-react';
import _ from 'lodash';
import { useAuthentication } from 'wmic-digital-auth-react';
import { WMICOneIncPaymentModal } from 'wmic-components-amp-common-react';
import styles from './WMICManagePaymentPlan.module.scss';
import messages from './WMICManagePaymentPlan.messages';


const WMICSelectBankAccountModal = (props) => {
    const {
        billingData,
        onManageBankingInformation,
        updateSaveDisabled,
        accountContact,
        updateBankAccount,
        updateModalLoaded
    } = props;
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const translator = useTranslator();
    const [bankAccounts, updateBankAccounts] = useState([]);
    const [bankAccountData, updateBankAccountData] = useState({});
    const [bankAccountOptions, updateBankAccountOptions] = useState([]);
    const [selectedBankAccountOption, updateSelectedBankAccountOption] = useState('');
    const [bankAccountsLoading, updateBankAccountsLoading] = useState(false);
    const [useNewBankAccount, updateUseNewBankAccount] = useState(false);
    const [bankAccountType, updateBankAccountType] = useState(undefined);
    const [routingTransitNumber, updateRoutingTransitNumber] = useState(undefined);
    const [bankAccountNumber, updateBankAccountNumber] = useState(undefined);
    const [hasAgreed, updateHasAgreed] = useState(false);
    const [canShowSelectBankAccountSection, updateCanShowSelectBankAccountSection] = useState(false);
    const [makingOneIncPayment, updateMakingOneIncPayment] = useState(false);
    const [oneIncPaymentDetails, updateOneIncPaymentDetails] = useState({});
    const [oneIncSavePaymentEnabled, updateOneIncSavePaymentEnabled] = useState(false);
    const [ldFlags, setLdFlags] = useState({});
    const NEW_BANK_ACCOUNT_OPTION = 'useNewBankAccount';
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const PREFILLED_STORAGE_KEY_SEED = 'WMICSelectBankAccountModal_prefilledStorageKey';
    const PREFILLED_STORAGE_KEYS = {
        PAYMENT_METHOD: `${PREFILLED_STORAGE_KEY_SEED}_PAYMENT_METHOD`,
        PAYMENT_DATE: `${PREFILLED_STORAGE_KEY_SEED}_PAYMENT_DATE`,
        BANK_ACCOUNT_TYPE: `${PREFILLED_STORAGE_KEY_SEED}_BANK_ACCOUNT_TYPE`,
        ROUTING_TRANSIT_NUMBER: `${PREFILLED_STORAGE_KEY_SEED}_ROUTING_TRANSIT_NUMBER`,
        BANK_ACCOUNT_NUMBER: `${PREFILLED_STORAGE_KEY_SEED}_BANK_ACCOUNT_NUMBER`
    };

    const initBankAccountData = () => ({
                paymentInstrumentToken: '',
                description: '',
                isOneIncPaymentEnabled: true
            });


    useMemo(() => {
        updateSaveDisabled(!hasAgreed);
    }, [hasAgreed, updateSaveDisabled]);

    const selectBankAccount = (selectedBankAccount) => {
        let selectedBankAccountId = null;

        updateSelectedBankAccountOption(selectedBankAccount);
        
        if (selectedBankAccount === NEW_BANK_ACCOUNT_OPTION) {
            updateMakingOneIncPayment(true);
            updateHasAgreed(false);
            updateOneIncPaymentDetails({
                accountNumber: _.get(billingData, 'accountSummary.accountNumber'),
                contactPublicId: _.get(accountContact, 'publicID')
            });
            updateOneIncSavePaymentEnabled(true);
        } else {
            if (selectedBankAccount) {
                selectedBankAccountId = selectedBankAccount;
            }

            const bankAccount = _.find(bankAccounts, { paymentInstrumentToken: selectedBankAccountId });

            if (bankAccount) {
                const bankAcctData = {
                    paymentInstrumentToken: bankAccount.paymentInstrumentToken,
                    description: bankAccount.description,
                    isOneIncPaymentEnabled: true
                };

                if (bankAccount.publicId) {
                    bankAcctData.publicId = bankAccount.publicId
                }

                updateBankAccountData(bankAcctData);
                updateUseNewBankAccount(true);
                updateSaveDisabled(true);
                updateBankAccount(bankAccount);
            }
        }
    };

    const addToBankAccountOptions = useCallback((bankAccount) => {
        let optionDisplayText = '';

        if (bankAccount === NEW_BANK_ACCOUNT_OPTION) {
            optionDisplayText = translator(messages.useAnotherBankAccount);
        } else {
            optionDisplayText = bankAccount.description;
        }

        return {
            // eslint-disable-next-line no-nested-ternary
            value: bankAccount === NEW_BANK_ACCOUNT_OPTION ? bankAccount : bankAccount.paymentInstrumentToken,
            display: optionDisplayText
        }
    }, [translator]);

    const reloadBankAccountOptions = () => {
        const bankAcctOpts = [];

        _.each(bankAccounts, (bankAccount) => {
            bankAcctOpts.push(addToBankAccountOptions(bankAccount));
        });
        bankAcctOpts.push(addToBankAccountOptions(NEW_BANK_ACCOUNT_OPTION));
        updateBankAccountOptions(bankAcctOpts);
    }

    const setCanShowSelectBankAccountSection = () => {
        updateCanShowSelectBankAccountSection(true);
    };

    const getAccountBillingDetailsForPolicy = useCallback(() => {
        if (!WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.MANAGEBANKACCOUNTS).isAvailable) {
            return;
        }

        updateBankAccountsLoading(true);
            AccountBillingDetailsService.getPaymentInstrumentTokenSummary(
                _.get(billingData, 'accountSummary.accountNumber'),
                _.get(accountContact, 'publicID'),
                null,
                authHeader)
                .then((oneIncPaymentInstruments) => {
                    const bankAcctOpt = [];
                    const bankAccts = bankAccounts;
                    const filteredPaymentInstruments = WMICBillingUtil.filterPaymentInstruments(oneIncPaymentInstruments.paymentInstrumentTokens, 'eft');

                    _.each(filteredPaymentInstruments, (data) => {
                        const bankRecord = {
                            publicId: data.publicID,
                            paymentInstrumentToken: data.token,
                            description: data.description,
                            forMarPolicy: data.forMarPolicy
                        };

                        bankAccts.push(bankRecord);
                        bankAcctOpt.push(addToBankAccountOptions(bankRecord));
                    });

                    updateBankAccounts(bankAccts);
                    updateUseNewBankAccount(bankAccts.length === 0);
                    bankAcctOpt.push(addToBankAccountOptions(NEW_BANK_ACCOUNT_OPTION));
                    updateBankAccountOptions(bankAcctOpt);
                    setCanShowSelectBankAccountSection();
                    updateBankAccountsLoading(false);
                });
    }, [accountContact, addToBankAccountOptions, authHeader, bankAccounts, billingData, featureFlags.MANAGEBANKACCOUNTS, ldFlags]);

    const saveFilledInfo = useCallback(() => {
        WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_TYPE, bankAccountType);

        if (useNewBankAccount || !WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.MANAGEBANKACCOUNTS)) {
            WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.ROUTING_TRANSIT_NUMBER, routingTransitNumber);
            WMICTempStorageService.push(PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_NUMBER, bankAccountNumber);
        }
    }, [PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_NUMBER, PREFILLED_STORAGE_KEYS.BANK_ACCOUNT_TYPE, PREFILLED_STORAGE_KEYS.ROUTING_TRANSIT_NUMBER, bankAccountNumber, bankAccountType, featureFlags.MANAGEBANKACCOUNTS, ldFlags, routingTransitNumber, useNewBankAccount]);

    const manageBankingInformation = useCallback(() => {
        const isAvailable = bankAccounts.length > 0 && WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.MANAGEBANKACCOUNTS);

        if (isAvailable) {
            const accountInfo = {
                accountNumber: billingData?.accountSummary?.accountNumber,
                firstName: accountContact?.firstName,
                lastName: accountContact?.lastName,
                policyId: billingData.policyId,
                termNumber: billingData.termNumber,
                publicId: accountContact?.publicID
            };

            saveFilledInfo();
            onManageBankingInformation(accountInfo);
        }
    }, [accountContact?.firstName, accountContact?.lastName, accountContact?.publicID, bankAccounts.length, billingData?.accountSummary?.accountNumber, billingData.policyId, billingData.termNumber, featureFlags.MANAGEBANKACCOUNTS, ldFlags, onManageBankingInformation, saveFilledInfo]);

    const initFeatureFlags = useCallback(async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);

        setLdFlags(rFlags);
    }, [authUserData]);

    useEffect(() => {
        updateSaveDisabled(true);
        initFeatureFlags();
        updateBankAccountData(initBankAccountData());
    }, [initFeatureFlags, updateSaveDisabled]);

    useEffect(() => {
        getAccountBillingDetailsForPolicy();
    }, [accountContact, getAccountBillingDetailsForPolicy, ldFlags]);

    const paymentModalClosed = (methodSaved) => {
        if (!methodSaved) {
            updateSelectedBankAccountOption('');
            updateHasAgreed(false);
        }

        updateMakingOneIncPayment(false);
    };

    const oneIncSaveComplete = (details) => {
        const bankAccts = bankAccounts;
        const bankRecord = {
            paymentInstrumentToken: details.tokenId,
            description: `${details.accountType} - ${details.lastFourDigits}`,
            forMarPolicy: false
        };

        bankAccts.push(bankRecord);
        updateBankAccounts(bankAccts);
        selectBankAccount(bankRecord.paymentInstrumentToken);
        reloadBankAccountOptions();
    };

    if (bankAccountsLoading) {
        return <WMICLoader isInline />
    }

    if (makingOneIncPayment) {
        return (
            <WMICOneIncPaymentModal
                paymentModalClosed={paymentModalClosed}
                oneIncPaymentDetails={oneIncPaymentDetails}
                saveComplete={oneIncSaveComplete}
                savePaymentEnabled={oneIncSavePaymentEnabled}
                updateModalLoaded={updateModalLoaded}
            />
        );
    }

    return (
        !bankAccountsLoading && <div>
            <div className={styles['ww-bank-account-select']}>          
                {translator(messages.selectABankAccount)}<br />
                <select id='bankAccountSelect' value={selectedBankAccountOption} onChange={(event) => { selectBankAccount(event.target.value); }} className={styles['wmic-dropdown-select']}>
                    <option value="" disabled="disabled" selected>{translator(messages.select)}</option>
                    {
                        bankAccountOptions.map((bao) => (
                                <option value={bao.value}>
                                    {bao.display}
                                </option>
                            ))
                    }
                </select>
                <br />
                <a onClick={() => manageBankingInformation()}>{translator(messages.managePaymentMethods)}</a>
            </div>
            {(((bankAccountData.paymentInstrumentToken
                && selectedBankAccountOption !== '')
                || bankAccountType !== undefined) && (
                <div className={styles['ww-large-footer-div']} >
                    <h4 className={styles['ww-payment-subhead']}>{translator(messages.marPlanAuthorization)}</h4>
                    <p className={classNames('ww-subscript ww-subscript-em ww-large-footer ww-med-large-footer', styles['ww-agreement-text'])}>
                        <span>
                            {WMICRichTextUtil.translateRichText(translator(messages.marTextPart1, {
                                policyId: billingData.policyId
                            }))}
                        </span>
                    </p>
                    <p className={classNames('ww-subscript ww-subscript-em ww-large-footer ww-med-large-footer', styles['ww-agreement-text'])}>
                        <span>{translator(messages.marTextPart2)}</span>
                    </p>
                    <p className={classNames('ww-subscript ww-subscript-em ww-large-footer', styles['ww-agreement-text'])}>
                        <span>{WMICRichTextUtil.translateRichText(translator(messages.marTextPart3))}</span>
                    </p>
                    <p className={classNames('ww-subscript ww-subscript-em ww-large-footer ww-med-large-footer', styles['ww-agreement-text'])}>
                        <span>{translator(messages.marTextPart4)}</span>
                    </p>
                    <p className={classNames('ww-subscript ww-subscript-em ww-large-footer ww-med-large-footer', styles['ww-agreement-text'])}>
                        <span>{WMICRichTextUtil.translateRichText(translator(messages.marTextPart5))}</span>
                    </p>
                    <div className={styles['wmic-agreement-div']}>
                        <WMICCheckbox
                            id="isAgreeToSwitchPaymentPlan-checkbox"
                            value={hasAgreed}
                            onValueChange={(newValue) => updateHasAgreed(newValue)}
                        />
                        <label htmlFor="isAgreeToSwitchPaymentPlan-checkbox">
                            <span aria-hidden className={classNames('ww-span-bold')}>
                                {WMICRichTextUtil.translateRichText(translator(messages.acknowledgementText, {
                                    fullName: accountContact?.displayName
                                }))}
                            </span>
                            <span className="sr-only">
                                {translator(messages.acknowledgementTextAria, {fullName: accountContact?.displayName})}
                            </span>
                        </label>
                    </div>
                </div>
            )
            )}
        </div>
    );
};

WMICSelectBankAccountModal.propTypes = {
    billingData: PropTypes.shape({
        policyId: PropTypes.string,
        termNumber: PropTypes.number,
        accountSummary: PropTypes.shape({
            accountNumber: PropTypes.string,
            fullName: PropTypes.string
        })
    }),
    onManageBankingInformation: PropTypes.func.isRequired,
    updateSaveDisabled: PropTypes.func.isRequired,
    accountContact: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        publicID: PropTypes.string
    }).isRequired,
    updateBankAccount: PropTypes.func.isRequired,
    updateModalLoaded: PropTypes.func.isRequired
};

WMICSelectBankAccountModal.defaultProps = {
    billingData: {}
};

export default WMICSelectBankAccountModal;