import { defineMessages } from 'react-intl';

export default defineMessages({
    removeVehicle: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Remove Vehicle',
        defaultMessage: 'Remove Vehicle'
    },
    selectTheVehicleToRemoveFromYourPolicy: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Select the vehicle to remove from your policy',
        defaultMessage: 'Select the vehicle to remove from your policy'
    },
    remove: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Remove',
        defaultMessage: 'Remove'
    },
    cancel: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Cancel',
        defaultMessage: 'Cancel'
    },
    pleaseProvideFurtherDetailsAboutThisChange: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Please provide further details about this change',
        defaultMessage: 'Please provide further details about this change'
    },
    removingFromYourPolicy: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Removing from your policy',
        defaultMessage: 'Removing {vehicle} from your policy'
    },
    continue: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Continue',
        defaultMessage: 'Continue'
    },
    whatDateDidThisOccur: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.What date did this occur? (MM-DD-YYYY)',
        defaultMessage: 'What date did this occur? (MM-DD-YYYY)'
    },
    enterAValidInformation: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-l.Enter a valid information.',
        defaultMessage: 'Enter a valid information.'
    }
});