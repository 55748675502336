import { CONSTANTS, LocalDateUtil } from 'wmic-portals-utils-js';
import _ from 'lodash';
import messages from './WMICPolicyDocuments/WMICPolicyDocuments.messages';


const getBillingInfoPolicyStatus = (policyInfo) => {
    const lobsCurrent = policyInfo.currentPeriod.lobs;
    return lobsCurrent.personalAuto.status || lobsCurrent.homeOwners.status;
};

const getBillingInfoRenewalPolicyStatus = (policyInfo) => {
    let renewalPolicyStatus;
    const lobsRenewal = policyInfo.renewedPeriod?.lobs;
    if (lobsRenewal) {
        renewalPolicyStatus = lobsRenewal.personalAuto.status || lobsRenewal.homeOwners.status;
    }
    return renewalPolicyStatus;
};

const isIconButtonPremiumVisible = (aTransactionsInfo) => {
    const sortedTransactions = _.chain(aTransactionsInfo.policyTransactions)
        .sortBy((transaction) => { return transaction.completionDate; })
        .reverse()
        .filter((transaction) => {
            return transaction.type.toLowerCase().trim() === CONSTANTS.POLICY_CHANGE
            && transaction.completionDate > transaction.termEffectiveDate;
        })
        .value();
    return sortedTransactions.length > 0;
};

const getRemainingAmount = (billingInfo) => {
    return billingInfo.installmentFees?.amount + billingInfo.unbilledFeesAmount?.amount;
};

const getProofOfInsurance = (docs) => {

    // return null if docs is null or undefined
    if (!docs) {return null}

    const responseDocuments = _.chain(docs)
        // filter documents that are not proof of insurance
        .filter((doc) => doc.formCode_WMIC === CONSTANTS.DOCUMENT_TYPE.VID)
        // sort by date created, then reverse to get most recent
        .sortBy(doc => doc.dateCreated_WMIC)
        .reverse()
        // end the lodash chain command and return the value or null
        .value()

    return responseDocuments[0] || null;
};

const getRcnDocument = (docs) => {
    return _.find(docs, (doc) => {
        return doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.RCN;
    });
};

const getPadecDocument = (docs) => {
    return _.find(docs, (doc) => {
        return doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.PADEC;
    });
};

const getMostRecentBillingDocumentInfo = (documents, renewedPeriod, termNumber) => {
    const renewalTermExists = renewedPeriod !== undefined;
    const responseDocuments = _.chain(documents)
        .sortBy((doc) => { return doc.dateCompleted; })
        .filter((doc) => {
            return doc.relatedPolicyPeriodTermNumber
                ? doc.relatedPolicyPeriodTermNumber === termNumber 
                : renewalTermExists;
        })
        .value();
    return responseDocuments[0];
};

const reverseChronologicalDocumentsByDateCompleted = (policyDocuments) => {
    return _.chain(policyDocuments)
        .sortBy((doc) => { return doc.dateCompleted; })
        .reverse()
        .value();
};

const getDocumentFriendlyName = (documentType) => {
    return messages[documentType.toLowerCase()] || '';
};

const getDisclosureOfFees = (policyDocuments) => {
    return _.find(policyDocuments, (doc) => {
        return doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.CADCF
            || doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.CADCFA
            || doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.ORDCF
            || doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.ORDCFA;
    });
};

const getPolicyDeclaration = (policyDocuments) => {
    return _.find(policyDocuments, (doc) => {
        return (
            doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.PADEC ||
            doc.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.HODEC
        );
    });
};

const getFutureDatedPolicyChangeDeclaration = (policyDocuments, currentPolicyPeriod, message) => {
    const dateToday = LocalDateUtil.toMidnightDate(LocalDateUtil.today());

    return _.find(policyDocuments, (doc) => {
        const dateDoc = new Date(doc.dateCompleted);
        return doc.fromJobType === 'PolicyChange'
            && doc.name === message
            && doc.dateCompleted > currentPolicyPeriod.effective
            && dateDoc > dateToday
            && doc.term === currentPolicyPeriod.termNumber_WMIC;
    });
};

const getRoadsideAssistanceCard = (policyDocuments) => {
    return _.find(policyDocuments, (doc) => {
        return doc.formCode_WMIC === CONSTANTS.DOCUMENT_TYPE.ORRA || doc.formCode_WMIC === CONSTANTS.DOCUMENT_TYPE.CARA;
    });
};

const reverseChronologicalDocuments = (policyDocuments) => {
    return _.chain(policyDocuments)
        .sortBy((doc) => { return doc.dateModified; })
        .reverse()
        .value();
};

const getOfferOfEarthquake = (policyDocuments) => {
    return _.find(
        policyDocuments,
        (doc) => {
            return (
                doc.documentPatternID
                && doc.documentPatternID.toLowerCase() === CONSTANTS.DOCUMENT_PATTERNS.OFFER_OF_EARTHQUAKE.toLowerCase()
            );
        }
    );
};

export default {
    getBillingInfoPolicyStatus,
    getBillingInfoRenewalPolicyStatus,
    isIconButtonPremiumVisible,
    getRemainingAmount,
    getProofOfInsurance,
    getRcnDocument,
    getPadecDocument,
    getMostRecentBillingDocumentInfo,
    getDocumentFriendlyName,
    getDisclosureOfFees,
    getPolicyDeclaration,
    getFutureDatedPolicyChangeDeclaration,
    getRoadsideAssistanceCard,
    getOfferOfEarthquake,
    reverseChronologicalDocuments,
    reverseChronologicalDocumentsByDateCompleted
};