// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__SelectProducerCode__gwSelectBoxStyle{grid-template-columns:0fr auto;max-width:100%;width:17rem}", "",{"version":3,"sources":["webpack://./node_modules/gw-gateway-common-react/components/SelectProducerCode/SelectProducerCode.module.scss"],"names":[],"mappings":"AAAA,2CACI,8BAAA,CAEA,cAAA,CADA,WACA","sourcesContent":[".gwSelectBoxStyle {\n    grid-template-columns: 0fr auto;\n    width: 17rem;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwSelectBoxStyle": "app__SelectProducerCode__gwSelectBoxStyle"
};
export default ___CSS_LOADER_EXPORT___;
