// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import React, { useCallback, useContext, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Icon, InfoLabel } from '@jutro/components';
import { Flex, FlexItem } from '@jutro/layout';
import { withIntl, TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDataFilter } from '@jutro/legacy/datatable';
import { WMICDataTable, WMICButton } from 'wmic-pe-components-platform-react';
import { BUTTON_CONSTANTS, FILE_UPLOAD } from 'wmic-pe-portals-utils-js';

import moment from 'moment';
import UploadAreaMessage from '../UploadAreaMessage/UploadAreaMessage';
import metadata from './FileUploadViewerComponent.metadata.json5';
import styles from './FileUploadViewerComponent.module.scss';
import messages from '../WMICDocuments.messages';
import uploadIcon from './file-upload-icon.svg';


import { Link } from '@jutro/router';


import { FileUploadField, useMultipleFileUpload } from '@jutro/legacy/components';


// BPN-9456 - provides same features as useMultipleFileUpload, but overrides it for files that got deleted elsewhere
function useMultipleFileUploadWithDeleteTracking({deletedFiles={}, ...props}) {
    const { props: fileProps, ...otherProps } = useMultipleFileUpload(props);
    const translator = useContext(TranslatorContext);
    
    const { uploadErrors={}, uploadState={} } = fileProps;

    const newUploadErrors = {...uploadErrors};
    const newUploadState = {...uploadState};
    const deletedNames = Object.keys(deletedFiles);
    
    for(let i = 0; i < deletedNames.length; i += 1) {
        const key = deletedNames[i];
        if (deletedFiles[key] && uploadState[key]) {
            newUploadState[key] = 'failed';
            newUploadErrors[key] = [translator(messages.documentDeleted)];
        }
    }

    const newFileProps = {
        ...fileProps,
        uploadErrors: newUploadErrors,
        uploadState: newUploadState
    };


    return {...otherProps, props: newFileProps};
}

function FileUploadViewerComponent(props) {
    const {
        data: policyDocuments,
        intl,
        getDownloadLink,
        onDocumentDelete,
        onDocumentUpload,
        maxNumberOfFiles,
        maxFileSizeKB,
        noDocumentMessage,
        deletedFiles,
        onFileClear: pOnFileClear
    } = props;
    const filesRef = useRef({});

    const { documents } = policyDocuments;
    const { maxFileUploadSize } = appConfig;
    const translator = useContext(TranslatorContext);
    const [searchValue, setSearchValue] = useState('');

    const maximumCalculatedFileSize = useMemo(() =>
        maxFileSizeKB ?? (maxFileUploadSize !== undefined
                    ? maxFileUploadSize : 50000)
    , [maxFileSizeKB, maxFileUploadSize]);

    const messageProps = useMemo(() => ({
            // For full list of available messageProps, see StoryBook:
            // https://jutro-6-5-1.int.ccs.guidewire.net/jutro-storybook/?path=/docs/file-uploaders
            incorrectFileTypeMessage: translator(messages.unsupportedFileType),
            emptyLimitedUploadAreaMessage:
                <UploadAreaMessage
                    maxNumberOfFiles={maxNumberOfFiles}
                    maxFileSizeKB={maximumCalculatedFileSize}
                />,
            maxFileSizeKBMessage: translator(messages.maxFileSizeValueExceeded, {maxFileSizeMB: maximumCalculatedFileSize/1000})
        }), [maxNumberOfFiles, maximumCalculatedFileSize, translator])

    const [filteredData] = useDataFilter(documents, {name: searchValue})

    const isNew = ({dateModified}) => {
        if (!dateModified) return false;

        return moment().diff(moment(dateModified), 'days') <= 1;
    }

    const handleDocumentDelete = useCallback(
        (event, item) => {
            event.preventDefault();
            onDocumentDelete(item);
        },
        [onDocumentDelete]
    );

    const cancelFileUploading = useCallback(
        async (fileName) => {
            if (filesRef.current[fileName] && filesRef.current[fileName].abortController) {
                filesRef.current[fileName].abortController.abort();
                delete filesRef.current[fileName];
            }
            else {
                const item = documents.find((d) => d.name === fileName)

                if (item) {
                    await onDocumentDelete(item);
                }
                
                if (pOnFileClear) {
                    pOnFileClear(fileName);
                }
            }
        },
        [pOnFileClear, documents, onDocumentDelete]
    );

    const { progress, props: fileProps, error } = useMultipleFileUploadWithDeleteTracking({deletedFiles, onFileClear: cancelFileUploading});
   
    const handleDocumentUpload = useCallback(
        (item) => {
            item.abortController = new AbortController();
            filesRef.current[item.name] = item;
            fileProps.onUpload(item);

            onDocumentUpload(item, progress, fileProps).then((result) => {
                if (result.error) {
                    error(item.name, [result.message])
                }

                delete filesRef.current[item.name];
            });

        },
        [fileProps, onDocumentUpload, progress, error]
    );

    const getFileName = useCallback(
        (document) => {
            const link = getDownloadLink(document);

            return (
                <div>
                    <Link href={link} target="_blank">
                        {
                            isNew(document)
                                ? <InfoLabel type="success" className="fontSizeSubtext gw-mr-1">{translator(messages.newDocument)}</InfoLabel>
                                : <Icon icon="mi-insert_drive_file" className={styles.fileTypeIcon} />
                        }
                        {document.name}
                    </Link>
                </div>
            );
        },
        [getDownloadLink, translator]
    );

    const getFormattedDate = useCallback(
        (document) => {
            return intl.formatDate(new Date(document.dateModified), { year: 'numeric', month: 'long', day: 'numeric' });
        },
        [intl]
    );

    const getFileExtension = useCallback(
        ({ name }) => {
            if (!name) {
                return ""
            }
            const extension = name.split(".").pop();
            return extension.toUpperCase();
        },
        []
    );

    const getDeleteIcon = useCallback(
        (document) => {
            if (document.canDelete) {
                return (
                    <Flex justifyContent="center" alignItems="center">
                        <FlexItem>
                            <WMICButton
                                id="deleteIconButton"
                                icon="gw-delete"
                                type="secondary"
                                onClick={(e) => handleDocumentDelete(e, document)}
                            />
                        </FlexItem>
                    </Flex>
                );
            }
            return null;
        },
        [handleDocumentDelete]
    );

    const overrides = useMemo(() => ({
            fileUploadComponent: {
                messageProps,
                visible: !policyDocuments.isReadOnly,
                maxFileSizeKB: maximumCalculatedFileSize,
                maxNumberOfFiles,
                buttonType: BUTTON_CONSTANTS.MODIFIERS.FILLED,
                accept: `${FILE_UPLOAD.ACCEPTED_IMAGE_TYPES},${FILE_UPLOAD.ACCEPTED_FILE_TYPES}`,
                imageSource: uploadIcon,
                ...fileProps
            },
            uploadedFileTableDetail: {
                placeholder: policyDocuments.isReadOnly && !documents.length ? translator(messages.noDocumentFound) : '',
                data: filteredData,
                defaultConfig: {
                    filterValue: '',
                },
                withSearch: true,
                searchValue,
                onSearchUpdate: setSearchValue,
                noDataText: noDocumentMessage || translator(messages.emptyDocumentTable)
            },
            fileDeleteBtnContainer: {
                id: 'deleteBtn',
                renderCell: getDeleteIcon,
                sortable: false
            },
            uploadedFileDataView: {
                data: policyDocuments
            }
        }), [documents.length, fileProps, filteredData, getDeleteIcon, maxNumberOfFiles, maximumCalculatedFileSize, messageProps, noDocumentMessage, policyDocuments, searchValue, translator])

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFileUpload: handleDocumentUpload,
            getFileName,
            getFormattedDate,
            getFileExtension
        },
        resolveComponentMap: {
            WMICDataTable,
            FileUploadField
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, documents, id, path, overrides);
        },
        [documents, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={documents}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

FileUploadViewerComponent.propTypes = {
    data: PropTypes.shape({
        canDelete: PropTypes.bool
    }),
    intl: PropTypes.func.isRequired,
    getDownloadLink: PropTypes.func.isRequired,
    onDocumentDelete: PropTypes.func.isRequired,
    onDocumentUpload: PropTypes.func.isRequired,
    deletedFiles: PropTypes.shape({}),
    onFileClear: PropTypes.func
};

FileUploadViewerComponent.defaultProps = {
    data: {
        canDelete: false
    },
    deletedFiles: {},
    onFileClear: noop
};

export default withIntl(FileUploadViewerComponent);
