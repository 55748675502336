import { defineMessages } from 'react-intl';

export default defineMessages({
    highPerformance: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.High PerformanceTurbo vehicle',
        defaultMessage: 'High PerformanceTurbo vehicle'
    },
    KITcars: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.KIT cars',
        defaultMessage: 'KIT cars'
    },
    foreignExoticOrSportsCars: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Foreign exotic or sports cars',
        defaultMessage: 'Foreign exotic or sports cars'
    },
    antiqueOrClassicVehicle: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Antique or classic vehicle',
        defaultMessage: 'Antique or classic vehicle'
    },
    rightHandDriveVehicle: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Right-hand drive vehicle',
        defaultMessage: 'Right-hand drive vehicle'
    },
    miniOrPocketBike: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Mini or Pocket Bike',
        defaultMessage: 'Mini or Pocket Bike'
    },
    motardSupermotard: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Motard/Supermotard',
        defaultMessage: 'Motard/Supermotard'
    },
    offRoadMotocross: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Off-road Motocross',
        defaultMessage: 'Off-road Motocross'
    },
    allTerrainVehicles: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Three wheel All-Terrain Vehicles',
        defaultMessage: 'Three wheel All-Terrain Vehicles'
    },
    amphibiousVehicle: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Amphibious vehicle',
        defaultMessage: 'Amphibious vehicle'
    },
    allTerrainBuggy: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.All-Terrain Buggy',
        defaultMessage: 'All-Terrain Buggy'
    },
    childSizeOrJuniorSnowVehicle: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISSpecialVehicle_wmic.Child size or Junior Snow Vehicle',
        defaultMessage: 'Child size or Junior Snow Vehicle'
    },
    actor: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Actor',
        defaultMessage: 'Actor'
    },
    author: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Author',
        defaultMessage: 'Author'
    },
    broadcaster: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Broadcaster',
        defaultMessage: 'Broadcaster'
    },
    journalist: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Journalist in any media form',
        defaultMessage: 'Journalist in any media form'
    },
    labourUnionLeaders: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Labour/Union Leaders',
        defaultMessage: 'Labour/Union Leaders'
    },
    newsmediaEditor: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Newsmedia Editor',
        defaultMessage: 'Newsmedia Editor'
    },
    politician: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Politician',
        defaultMessage: 'Politician'
    },
    professionalAthlete: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Professional Athlete',
        defaultMessage: 'Professional Athlete'
    },
    professionalEntertainer: {
        id: 'wmic.quoteandbind.views.prequal-questions.BPASSPUPPrequalProfession_WMIC.Professional Entertainer',
        defaultMessage: 'Professional Entertainer'
    },
    highHazard: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISForUndesirableCommercialPurposes_wmic.highHazard',
        defaultMessage: 'High hazard'
    },
    longHaul: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISForUndesirableCommercialPurposes_wmic.longHaul',
        defaultMessage: 'Long haul'
    },
    usedOutsideCanada: {
        id: 'wmic.quoteandbind.views.prequal-questions.ISForUndesirableCommercialPurposes_wmic.usedOutsideCanada',
        defaultMessage: 'Used outside of Canada'
    },
    singleWalledReconditioned: {
        id: 'wmic.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Single Walled Reconditioned',
        defaultMessage: 'Single-walled or reconditioned'
    },
    locatedUnderground: {
        id: 'wmic.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Located Underground',
        defaultMessage: 'Located underground'
    },
    uncertified: {
        id: 'wmic.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Uncertified',
        defaultMessage: 'Uncertified'
    },
    notProfessionallyInstalled: {
        id: 'wmic.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Not Professionally Installed',
        defaultMessage: 'Not professionally installed'
    },
    manufacturedByDTE: {
        id: 'wmic.quoteandbind.views.prequal-questions.Question_GPA_HO_PreQualification_WMIC.Manufactured By DTE',
        defaultMessage: 'Manufactured by DTE'
    }
});
