/* eslint-disable no-param-reassign */
import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';

import util from './WMICEstimateAndRepairFacilityInfoBoxUtil';
import metadata from './WMICEstimateAndRepairFacilityInfoBox.metadata.json5';
import styles from './WMICEstimateAndRepairFacilityInfoBox.module.scss';
import messages from './WMICEstimateAndRepairFacilityInfoBox.messages';

const WMICEstimateAndRepairFacilityInfoBox = (props) => {
    const { claimData: claimInfo } = props;
    const translator = useTranslator();

    const getRepairProgram = (info) => util.getRepairProgram(info);

    const isVendorTypeIndependent = (info) => util.isVendorTypeIndependent(info);

    const isEstimating = (info) => util.isEstimating(info);

    const srMessage = useMemo(() => {
        let message = '';

        if (isVendorTypeIndependent(claimInfo) && isEstimating(claimInfo)) {
            message += ` ${  translator(messages.AppraisalMessage)}`;
        }

        if (!!getRepairProgram(claimInfo) && !isVendorTypeIndependent(claimInfo)) {
            message +=` ${   translator(messages.EstimateOrRepairFacilityInformationAria, 
                { 
                    facility: getRepairProgram(claimInfo)?.name, 
                    phoneNumber: getRepairProgram(claimInfo)?.phoneNumber,
                    location: getRepairProgram(claimInfo)?.address?.displayName
                })}`;
        }
        
        return message;
    }, [claimInfo, translator]);

    const overrides = {
        estimateAndRepairFacilityInfoBoxContentMessage: {
            visible: isVendorTypeIndependent(claimInfo) && isEstimating(claimInfo)
        },
        estimateAndRepairFacilityInfoBoxContentGrid: {
            visible: !!getRepairProgram(claimInfo) && !isVendorTypeIndependent(claimInfo)
        },
        facilityValueDiv: {
            content: getRepairProgram(claimInfo)?.name
        },
        locationValueDiv: {
            content: getRepairProgram(claimInfo)?.address?.displayName
        },
        phoneNumberColumn: {
            onClick: () => { window.location.href = `tel:${getRepairProgram(claimInfo)?.phoneNumber}`; }
        },
        phoneNumberInput: {
            value: getRepairProgram(claimInfo)?.phoneNumber
        },
        estimateAndRepairFacilityInfoBoxContentAria: {
            content: srMessage
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrides}
            model={claimInfo}
        />
    );
};

WMICEstimateAndRepairFacilityInfoBox.propTypes = {
    claimData: PropTypes.shape({})
};

WMICEstimateAndRepairFacilityInfoBox.defaultProps = {
    claimData: {}
};

export default WMICEstimateAndRepairFacilityInfoBox;