import { AddressService_Ext } from "wmic-pe-capability-address";
import { CONSTANTS, WMICLogger } from 'wmic-pe-portals-utils-js';

const updateAddressIfNeeded = (addressVM, clonedAddressVM) => {
    if (!addressVM?.isValidationOverridden_Ext?.value) {
        clonedAddressVM.isValidationOverridden_Ext.value = false;
        clonedAddressVM.byPassAddressOverridde_Ext.value = true;
    }
};

const setAddressValidationFields = (addressVM, addressResponse) => {
    addressVM.addrValidateDate_Ext = addressResponse.addrValidateDate_Ext;
    addressVM.addrValidateStatus_Ext = addressResponse.addrValidateStatus_Ext;
};

const validateAddress = async (addressVM, clonedAddressVM, authHeader) => {
    try {
        updateAddressIfNeeded(addressVM, clonedAddressVM);

        const addressResponse = await AddressService_Ext.validateAddress(
            clonedAddressVM.value,
            authHeader
        );

        setAddressValidationFields(addressVM, addressResponse);
    } catch (error) {
        WMICLogger.error(`Address validation failed: ${JSON.stringify(error)}`);
    }
};

const isAddressApplicableToBeValidated = (addressVM) => {
    return !addressVM?.isValidationOverridden_Ext?.value
        && addressVM?.aspects.subtreeValid
};

export default {
    isAddressApplicableToBeValidated: isAddressApplicableToBeValidated,
    setAddressValidationFields: setAddressValidationFields,
    updateAddressIfNeeded: updateAddressIfNeeded,
    validateAddress: validateAddress
};
