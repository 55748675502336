/* eslint-disable no-secrets/no-secrets */

import React, {
    useContext, useCallback, useState, useEffect,
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import {
    CONSTANTS,
    ACTION_TYPES,
    WMICLogger,
    WMICUserAccessUtil,
    JURISDICTIONS,
    PAConstants,
    WMICVariousUtil,
    MODAL_CONSTANTS,
    FlowStepId,
    WMICValidationUtil,
    WMICVehicleUtil
} from 'wmic-pe-portals-utils-js';
import { WMICVehiclesUsageComponent, WMICVehicleOwnershipAndAssignmentComponent, WMICVehicleAdditionalInfoComponent, WMICVehicleInfoComponent, WMICVehiclesDetailView } from 'wmic-pe-capability-gateway-common-pa-react'
import { useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WMICDataList, WMICRequiredText } from 'wmic-pe-components-platform-react';
import { EntityUtil } from '@xengage/gw-portals-util-js'
import { DropdownMenuButton } from '@jutro/components';

import { messages as quoteAndBindCommonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICVehiclesPage.metadata.json5';
import messages from './WMICVehiclesPage.messages';

import { DropdownMenuLink } from '@jutro/router';

function getVehicleDescription(vehicle) {
    return (`${vehicle.year ? `${vehicle.year} ` : ''}
        ${vehicle.make ? `${vehicle.make} ` : ''}
        ${vehicle.model ? `${vehicle.model} ` : ''}`);
}

const getVehicleDescriptionCell = (item) => {
    const vehicle = item;

    return (
        <span>
            {getVehicleDescription(vehicle)}
            <div>
                {_.get(vehicle, 'vin')}
            </div>
        </span>
    );
};

const createVehicle = (person, skeletonStruct) => {
    const v = {
        tempID: EntityUtil.nextId(),
        vinOverride_WMIC : false,
        leaseOrRent : false,
        rateAtPrimaryLocation_WMIC : true,
        addAdditionalInterests_WMIC: false,
        mileageUnit_WMIC: 'KM',
        vehicleInspectionRequired_WMIC: false,
        commercialVehicle_WMIC: {},
        msrp_WMIC: null,
        costNew: null
    };

    // this is the hookup for server aspects
    if (skeletonStruct.length > 0 && skeletonStruct[0].metaDataMap) {
        v.metaDataMap = skeletonStruct[0].metaDataMap;
    }

    if (person && person.primaryAddress) {
        v.licenseState = person.primaryAddress.state;
    }

    return v;
};

const createVehicleAddress = () => {
    const address = {
        country : CONSTANTS.COUNTRY.CA,
        addressType : CONSTANTS.MAILING_ADDRESS
    };

    return address;
}

const initializeVehicleValues = (vehicle, isUnderEditing, existingVehicle) => {
    _.set(vehicle, 'isUnderEditing', { value: isUnderEditing });
    _.set(vehicle, 'isAddingAdditionalInterest', { value: false });
    _.set(vehicle, 'isAddingCustomization', { value: false });
    _.set(vehicle, 'isAddingGarageLocation', { value: false });
    _.set(vehicle, 'isAddingPrimaryLocation', { value: false });
    _.set(vehicle, 'isAddingDriver', { value: false });
    _.set(vehicle, 'searchCompleted', { value: existingVehicle });
    _.set(vehicle, 'drivers', []);
};

const VEHICLES_PATH = 'lobData.personalAuto.coverables.vehicles';
const DRIVERS_PATH = 'lobData.personalAuto.coverables.drivers';
const LOB_PATH = 'lobData.personalAuto';
const BASEDATA = 'baseData';

function WMICVehiclesPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');

    const [vehicleTypes, updateVehicleTypes] = useState([]);

    const [primaryNamedInsured, updatePrimaryNamedInsured] = useState({});
    const [additionalNamedInsureds, updateAdditionalNamedInsureds] = useState([]);
    const [previousAdditionalNamedInsureds, updatePreviousAdditionalNamedInsureds] = useState([]);
    const [drivers, updateDrivers] = useState([]);
    const [skeletonStruct, setSkeletonStruct] = useState({});
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const [vehicleAction, setVehicleAction] = useState(ACTION_TYPES.NONE);
    const { setWizardLoading } = useWizardModals();
    const { showInfo, showConfirm, showWarning, showError } = useWizardModals();

    const vehicleVMList =  _.get(submissionVM, `${VEHICLES_PATH}.children`, []);

    const { authHeader, authUserData } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('WMICVehiclesPage');

    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);
    const isBMSi = _.get(submissionVM, "isFromBMS_WMIC.value");

    const initVehicleData = useCallback(async() => {
        const lobData = _.get(submissionVM, LOB_PATH);
        const driversTmp = _.get(submissionVM, DRIVERS_PATH);
        const baseData = _.get(submissionVM, BASEDATA);

        const additionalNameInsuredsSub = _.get(baseData, `additionalNamedInsureds_WMIC.value`);
        const additionalNameInsuredsTmp = [];

        const skeletonStructure = await LoadSaveService.getSkeletonStructure(
            submissionVM.value,
            authHeader
        );

        // pull out vehicle specific structure
        const vehicleStruct = _.get(skeletonStructure, VEHICLES_PATH)

        setSkeletonStruct(vehicleStruct);

        if (additionalNameInsuredsSub && !_.isEmpty(additionalNameInsuredsSub)) {
            additionalNameInsuredsSub.forEach((nameInsured) => {
                const additionalNameInsured = viewModelService.create(
                    nameInsured,
                    'pc',
                    'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC'
                );

                _.set(additionalNameInsured, 'isDriver', false);

                if (_.get(additionalNameInsured, 'subtype.value')
                    && _.isEqual(_.get(additionalNameInsured, 'subtype.value.code'), CONSTANTS.Person)) {
                    const isDriver = driversTmp.value.some((driver) => _.isEqual(
                            _.get(additionalNameInsured, 'contactPublicID.value'),
                            _.get(driver, 'person.publicID.value')
                        ));

                    _.set(additionalNameInsured, 'isDriver', isDriver);
                }

                additionalNameInsuredsTmp.push(additionalNameInsured);
                updateAdditionalNamedInsureds(additionalNameInsuredsTmp);
            });
        }

        const primaryNamedInsuredTmp = _.get(baseData, 'primaryNamedInsured_WMIC');

        _.set(primaryNamedInsuredTmp, 'relationshipToPrimaryInsured_WMIC', 'insured');
        _.set(primaryNamedInsuredTmp, 'isDriver', false);

        const isDriver = driversTmp.value.some((driver) => _.isEqual(
                _.get(primaryNamedInsuredTmp, 'contactPublicID'),
                _.get(driver, 'person.publicID')
            ));

        _.set(primaryNamedInsuredTmp, 'isDriver', isDriver);
        updatePrimaryNamedInsured(primaryNamedInsuredTmp);
        updatePreviousAdditionalNamedInsureds(_.get(lobData, 'updatePreviousAdditionalNamedInsureds.value'));

        const driversFiltered = _.filter(driversTmp.value, (driver) => driver.assignment_WMIC === CONSTANTS.ASSIGNMENT.ASSIGNED);

        updateDrivers(driversFiltered);
    }, [LoadSaveService, authHeader, submissionVM, viewModelService]);

    const noVehicles = () => _.isEmpty(_.get(submissionVM, `${VEHICLES_PATH}.value`, []))

    useEffect(() => {
        setWizardLoading(true);
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));

        const typelist = viewModelService.productMetadata.get('pc').types
            .getTypelist('VehicleType')
            .getCodesForCategory(
                {
                    code: CONSTANTS.COUNTRY.CA, typelist: { name: 'Country' }
                }
            );

        updateVehicleTypes(typelist);
        initVehicleData();

        const vehicles = _.get(submissionVM, VEHICLES_PATH)

        vehicles.forEach((vehicle, index) => {
            _.unset(submissionVM, `lobData.personalAuto.coverables.vehicles.value[${index}].flexDTO`);
        })

        setWizardLoading(false);

        registerInitialComponentValidation(() => !noVehicles() && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PERSONAL_VEHICLES));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.PERSONAL_VEHICLES;
    }, [submissionVM.value]);

    const onCreateVehicle = useCallback(async(vehicleType) => {
        if (isBMSi && maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
            updateWizardData(submissionVM);
        }

        const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value');
        const vehicle = createVehicle(accountHolder, skeletonStruct);

        _.set(vehicle, 'vehicleType', vehicleType);

        let vehicleAddress = createVehicleAddress();
        const accountAddress = _.get(submissionVM, "baseData.accountAddresses_WMIC.value[0]"); // vehicle usage component does not set this address properly

        if (accountAddress) {
            vehicleAddress = accountAddress;
        }

        _.set(vehicle, 'primaryLocation_WMIC', vehicleAddress);
        _.set(vehicle, 'garageLocation', vehicleAddress);
        _.set(vehicle, 'ratingJurisdiction', _.get(submissionVM, 'baseData.baseState.value.code'));
        _.set(vehicle, 'businessSegment_WMIC', CONSTANTS.BUSINESS_SEGMENT_CODES.PERSONAL);

        const vehiclesVM = _.get(submissionVM, VEHICLES_PATH);
        const { _xCenter, _dtoName } = vehiclesVM;
        const _vehicleVM = viewModelService.create(vehicle, _xCenter, _dtoName, submissionVM.aspects.context());


        initializeVehicleValues(_vehicleVM, true, false);
        // Note: WMICListView's toCreate handler must also add the new value to the array
        submissionVM.lobData.personalAuto.coverables.vehicles.value.push(_vehicleVM.value);

        await WMICVehicleUtil.updateVehicleList(submissionVM, LoadSaveService, authHeader);

        setVehicleAction(ACTION_TYPES.ADD);

        return _vehicleVM;
    }, [submissionVM, skeletonStruct, viewModelService]);

    const onCreateVehicleWithModal = useCallback((vehicleType) => showConfirm({
        title: quoteAndBindCommonMessages.revalidationRequiredTitle,
        message: quoteAndBindCommonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.cancelModel,
    }).then( (results) => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }

        return onCreateVehicle(vehicleType);
    }), [showConfirm, onCreateVehicle]);

    const checkAndReportVehicleTypesCombinationValidity = useCallback(() => {
        const ontarioVehicles = submissionVM.baseData
            && submissionVM.baseData.lobData
            && submissionVM.baseData.lobData.value
            && submissionVM.baseData.lobData.value.personalAuto
            && submissionVM.baseData.lobData.value.personalAuto.coverables.vehicles
            && submissionVM.baseData.lobData.value.personalAuto.coverables.vehicles.filter(
                (vehicle) => vehicle.garageLocation && vehicle.garageLocation.state === JURISDICTIONS.ONTARIO);

        if (ontarioVehicles && ontarioVehicles.length > 1) {
            const hasAuto = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.personalAutoVehicleType);
            const hasMotorcycle = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.motorcycleVehicleType);
            const hasSnowVehicle = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.snowVehicleType);
            let errorMessage = '';

            if (hasAuto) {
                if (hasSnowVehicle) {
                    errorMessage = translator(messages.snowVehicleCombinationError);
                }

                if (hasMotorcycle) {
                    errorMessage = hasSnowVehicle
                        ? translator(messages.snowMotorcycleVehicleCombinationError)
                        : translator(messages.motorcycleVehicleCombinationError);
                }
            } else if (hasMotorcycle && hasSnowVehicle) {
                errorMessage = translator(messages.snowMotorcycleVehicleCombination);
            }

            if (errorMessage !== '') {
                showError({
                    title: translator(messages.errorVehicleCombinationError),
                    message: errorMessage,
                    status: CONSTANTS.ERROR,
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);

                return false;
            }
        }

        return true;
    }, [submissionVM.baseData, translator, showError]);

    const onNext = useCallback(async () => {
        if (!isBMSi && !isComponentValid) {
            setShowErrors(true);
            return false;
        }

        if (!isReadOnlyUser) {
            if (!checkAndReportVehicleTypesCombinationValidity()) {
                return false;
            }
        } else {
            return false;
        }

        try {
            setWizardLoading(true);

            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.unset(newSubmissionVM.value, 'bindData');

            newSubmissionVM.value = await LoadSaveService.saveAndSyncPACoverages_WMIC(newSubmissionVM.value, authHeader);

            newSubmissionVM.value.flowStepId_WMIC = FlowStepId.PERSONAL_VEHICLES;
            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(
                newSubmissionVM.value,
                authHeader
            );

            updateWizardData(submissionVM);
            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PERSONAL_VEHICLES)) {
                WMICVariousUtil.scrollToTop();
                return false
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, isReadOnlyUser, checkAndReportVehicleTypesCombinationValidity, setWizardLoading, viewModelService, submissionVM, LoadSaveService, authHeader, isBMSi]);

    const renderAddVehicleButton = useCallback(({isEditing, onClick}) =>  (
        <DropdownMenuButton
            id="addNewAdditionalInterestButton"
            buttonText={translator({
                "id": "quoteandbind.pa.views.pa-vehicle-details.newVehicle",
                "defaultMessage": "meta.New Vehicle"
            })}
            icon="mi-add"
            disabled={isEditing} >
            {vehicleTypes.map((vehicleType, index) => (
                <DropdownMenuLink onClick={() => onClick(vehicleType.code, index)}>
                    {translator({
                        id: vehicleType.name,
                        defaultMessage: vehicleType.code
                    })}
                </DropdownMenuLink>
            ))}
        </DropdownMenuButton>
    ), [translator, vehicleTypes]);

    const ensureUniqueVin = useCallback((vehicle) => {
        const vehicleMatch = _.filter(vehicleVMList, { vin: { value: vehicle.vin } });

        if (!vehicleMatch) {
            return undefined;
        }

        if (vehicleMatch.length === 1) {
            return !_.isEqual(vehicleMatch[0].value, vehicle) ? vehicleMatch[0] : undefined;
        }

        return _.find(vehicleMatch, (v) => v !== vehicle);
    }, [vehicleVMList]);

    const isValid = useCallback((selectedVehicleVM) => {
        const vinMatchedVehicle = ensureUniqueVin(selectedVehicleVM.value);
        if (vinMatchedVehicle) {
            showInfo({
                title: messages.vinIsNotUnique,
                message: translator(messages.vinMatchMsg,
                    { vehicle: getVehicleDescription(vinMatchedVehicle.value) })
            })
            return false;
        }

        const isVinSearchRequired = WMICVehicleUtil.vinSearchRequired(selectedVehicleVM.value);
        if (isVinSearchRequired) {
            showWarning({
                title: messages.actionRequired,
                message: messages.vinSearchRequired,
                icon: MODAL_CONSTANTS.ICON.WARNING,
                confirmButtonText: messages.close,
            })
            .then(() => WMICVariousUtil.scrollToId('vehicleInfoAccordion'));
            return false;
        }

        return true;
    }, [ensureUniqueVin, translator, showInfo, showWarning]);

    const saveSubmission = useCallback((newSubmission, onError=_.noop) => {
        setWizardLoading(true, translator(messages.savingRisk));

        return LoadSaveService.updateDraftSubmission(
            newSubmission,
            authHeader
        ).then((result) => {
            submissionVM.value = result;
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PERSONAL_VEHICLES)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        }).catch((error) => {
            WMICLogger.error('Save vehicle failed', error);
            onError();

            return false;
        }).finally(() => {
            setWizardLoading(false);
        });
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, setWizardLoading, authHeader, translator, submissionVM])

    const onSaveVehicle = useCallback((vehicleVM) => {
        const valid = isValid(vehicleVM);

        if (!valid || !checkAndReportVehicleTypesCombinationValidity()) {
            return false;
        }

        return saveSubmission(submissionVM.value)
    }, [checkAndReportVehicleTypesCombinationValidity, isValid, saveSubmission, submissionVM.value]);

    const onDeleteVehicle = useCallback((vehicle, index) => {
        showConfirm({
            title: translator(messages.removeVehicleTitle),
            message: translator(messages.removeVehicle, { vehicle: getVehicleDescription(vehicle.value) })
        })
            .then((response) => {
                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    const vehicles = _.get(submissionVM.value, VEHICLES_PATH);

                    vehicles.splice(index, 1);
                    _.set(submissionVM.value, VEHICLES_PATH, vehicles);

                    return saveSubmission(submissionVM.value);
                }

                return false;
            });
    }, [saveSubmission, showConfirm, submissionVM, translator]);

    const onEditVehicle = useCallback(() => {
        _.set(submissionVM, 'isEditingPage.value', true);
    }, [submissionVM]);

    const overrideProps = {
        vehiclesListView: {
            value: vehicleVMList,
            clickable: true,
            onSave: onSaveVehicle,
            onDelete: onDeleteVehicle,
            readOnly: isReadOnlyUser,
            toCreate: isBMSi ? onCreateVehicleWithModal : onCreateVehicle,
            toUndoCreate: () => {
                // to undo new vehicle creation, simply remove the last vehicle in the array (it's always the one being "added")
                const vehicles = _.get(submissionVM.value, VEHICLES_PATH);

                vehicles.splice(vehicles.length-1, 1);
                _.set(submissionVM.value, VEHICLES_PATH, vehicles);
                updateWizardData(submissionVM);
            },
            toEdit: onEditVehicle,
            detailViewComponent: WMICVehiclesDetailView,
            onValidate,
            startOpen: true,
            detailViewComponentProps: {
                jobVM: submissionVM,
                updateWizardData,
                vehicleAction,
                additionalNamedInsureds,
                previousAdditionalNamedInsureds,
                primaryNamedInsured,
                drivers,
                isReadOnlyUser,
                skeletonStruct
            },
            renderAddButton: renderAddVehicleButton,
            VMData: [
                {
                    headerText: translator(messages.vehicleNumber),
                    path: 'vehicleNumber_WMIC'
                },
                {
                    headerText: translator(messages.theftIdentifier),
                    path: 'theftIdentifier_Ext',
                    visibilityCondition: (item) => _.get(item, 'theftIdentifier_Ext.aspects.ocular')
                },
                {
                    headerText: translator(messages.vehicleType),
                    path: 'vehicleType'
                },
                {
                    headerText: translator(messages.vehicleDescription),
                    getData: (item) => getVehicleDescriptionCell(item.value)
                },
                {
                    headerText: translator(messages.vehicleBusinessSegment),
                    path: 'businessSegment_WMIC'
                }
            ],
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: onCreateVehicle
        },
        resolveComponentMap:{
            WMICDataList,
            WMICRequiredText,
            WMICVehicleAdditionalInfoComponent,
            WMICVehicleInfoComponent,
            WMICVehicleOwnershipAndAssignmentComponent,
            WMICVehiclesUsageComponent
        }
    };

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(quoteAndBindCommonMessages.saveAndExit)}
            flowStepId={FlowStepId.PERSONAL_VEHICLES}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICVehiclesPage.propTypes = wizardProps;

export default WMICVehiclesPage;
