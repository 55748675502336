import { defineMessages } from "react-intl";

export default defineMessages({
    pleaseWait: {
        id:
            "wmic.policy-change.component.change-deductibles-coverages-pa.Please wait...",
        defaultMessage: "Please wait...",
    },
    weRePreparingYourQuote: {
        id:
            "wmic.policy-change.component.change-deductibles-coverages-pa.We’re preparing your quote.",
        defaultMessage: "We’re preparing your quote.",
    },
    pleaseWaitAndDontCloseYourBrowserOrLeaveThisPage: {
        id:
            "wmic.policy-change.component.change-deductibles-coverages-pa.Please wait and don’t close your browser or leave this page.",
        defaultMessage:
            "Please wait and don’t close your browser or leave this page.",
    },
});
