// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h2[class=policyDocumentTitle]{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:0}.app__WMICDocuments__policyDocumentContainer:not(:last-child){margin-bottom:var(--GW-LAYOUT-10)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICDocuments/WMICDocuments.module.scss"],"names":[],"mappings":"AAAA,8BACI,iEAAA,CACA,eAAA,CAGJ,8DACI,iCAAA","sourcesContent":["h2[class='policyDocumentTitle'] {\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n    margin-bottom: 0;\n}\n\n.policyDocumentContainer:not(:last-child) {\n    margin-bottom: var(--GW-LAYOUT-10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyDocumentContainer": "app__WMICDocuments__policyDocumentContainer"
};
export default ___CSS_LOADER_EXPORT___;
