/* eslint-disable no-secrets/no-secrets */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { Accordion } from '@jutro/legacy/components';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { CONSTANTS, HOConstants, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';

import WMICHOConstructionDetails from '../WMICHOConstructionDetails/WMICHOConstructionDetails';
import WMICHOConstructionHeating from '../WMICHOConstructionHeating/WMICHOConstructionHeating';
import WMICHOConstructionRoofElectricalPlumbing from '../WMICHOConstructionRoofElectricalPlumbing/WMICHOConstructionRoofElectricalPlumbing';
import WMICHOWaterUpdates from '../WMICHOWaterUpdates/WMICHOWaterUpdates';
import metadata from './WMICHOConstructionDetailView.metadata.json5';
import messages from './WMICHOConstructionDetailView.messages';


function WMICHOConstructionDetailView(props) {
    const {
        value: dwellingView,
        updateModel,
        isEditing,
        showErrors,
        onValidate,
        jobVM,
        selectedIndex } = props;

    const { authUserData } = useAuthentication();
    const { registerComponentValidation } = useValidation('WMICHOConstructionPage');
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const isReadOnlyUser = useMemo(() => !WMICUserAccessUtil.canCreateSubmission(authUserData.roles), [authUserData.roles]);
    const isTenantDwelling = _.get(dwellingView, 'rating.hoPolicyType.value.code') === HOConstants.rentersPolicyType;

    const [detailsValidity, setDetailsValidity] = useState({
        roofValid: true,
        minimumWallsValid: false,
        minimumKitchensAndBathsValid: false,
        wallsPercentageValid: false,
        hasPrimaryHeating: false
    });

    const updateDetails = (dwellingWingViewVM) => {
        _.set(dwellingView, `construction.dwellingWings.children[0]`, dwellingWingViewVM);
    };

    const commonAccordionProps = {
        showErrors,
    };

    const commonAccordionContentProps = {
        riskView: _.get(dwellingView, `construction.dwellingWings.children[0]`),
        jobVM,
        updateDetails,
        isEditMode: isEditing,
        isReadOnlyUser,
        onValidate: onValidateAccordion,
        showErrors,
        selectedIndex
    };

    const constructionDetailsContentProps = {
        ...commonAccordionContentProps,
        detailsValidity,
        updateDetailsValidity: setDetailsValidity
    }

    const nonDwellingCoverage = useCallback(() => [HOConstants.revenuePropertyPolicyType, HOConstants.seasonalPolicyType].includes(_.get(dwellingView, 'rating.hoPolicyType.value.code'))
        && (!_.get(dwellingView, 'yourHome.coverageFor.value') || _.get(dwellingView, 'yourHome.coverageFor.value.code') !== HOConstants.riskSubTypeDwelling), [dwellingView]);

    const isSelectRiskValid = useCallback(() =>
         dwellingView
            ? detailsValidity.minimumKitchensAndBathsValid
            && detailsValidity.minimumWallsValid
            && detailsValidity.wallsPercentageValid
            && (nonDwellingCoverage()
                    || _.find(dwellingView.construction.heaters.value, (item) => item.heatingUse === CONSTANTS.HEATER_TYPES.PRIMARY) !== undefined)
            : true
    , [detailsValidity.minimumKitchensAndBathsValid, detailsValidity.minimumWallsValid, detailsValidity.wallsPercentageValid, dwellingView, nonDwellingCoverage]);

    const validateTableForm = useCallback(() => isSelectRiskValid(), [isSelectRiskValid]);

    useEffect(() => {
        registerComponentValidation(validateTableForm);
    }, [registerComponentValidation, validateTableForm]);

    const overrideProps = {
        detailsAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('detailsAccordionContent')
        },
        detailsAccordionContent: {
            ...constructionDetailsContentProps,
        },
        roofElectricalPlumbingUpdatesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('roofElectricalPlumbingAccordionContent'),
            visible: !isTenantDwelling
        },
        roofElectricalPlumbingAccordionContent: {
            ...commonAccordionContentProps
        },
        waterUpdatesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('waterUpdatesContent')
        },
        waterUpdatesContent: {
            ...commonAccordionContentProps,
        },
        heatingUpdatesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('heatingAccordionContent')
        },
        heatingAccordionContent: {
            ...commonAccordionContentProps,
            riskView: dwellingView,
            updateDetails: updateModel
        }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            WMICHOConstructionDetails,
            WMICHOConstructionHeating,
            WMICHOConstructionRoofElectricalPlumbing,
            WMICHOWaterUpdates,
            Accordion
        }
    };

    return (
        <ViewModelForm
            model={dwellingView}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            onModelChange={updateModel}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
            onValidationChange={onValidate}
        />
    )
}

export default WMICHOConstructionDetailView;