import { defineMessages } from 'react-intl';

export default defineMessages({
    insuranceIDCard: {
        id: 'portal.views.insurance-card-modal.insuranceIDCard',
        defaultMessage: 'Insurance ID Card'
    },
    chooseHowToReceiveID: {
        id: 'portal.views.insurance-card-modal.chooseHowToReceiveID',
        defaultMessage: 'Choose how you\'d like to receive your ID cards.'
    },
    pdfFileFormat: {
        id: 'portal.views.insurance-card-modal.pdfFileFormat',
        defaultMessage: 'PDF file format'
    },
    pdfDescription: {
        id: 'portal.views.insurance-card-modal.pdfDescription',
        defaultMessage: 'This PDF includes all vehicles listed on your policy in one file.'
    },
    downloadPdfVersion: {
        id: 'portal.views.insurance-card-modal.downloadPdfVersion',
        defaultMessage: 'Download a PDF version'
    },
    sendCopyViaEmail: {
        id: 'portal.views.insurance-card-modal.sendCopyViaEmail',
        defaultMessage: 'Send a copy via email'
    },
    digitalWallet: {
        id: 'portal.views.insurance-card-modal.digitalWallet',
        defaultMessage: 'iOS or Android digital wallet cards'
    },
    digitalWalletDescription: {
        id: 'portal.views.insurance-card-modal.digitalWalletDescription',
        defaultMessage: 'Download your ID cards directly to your device for individual vehicles.'
    },
    iosCustomers: {
        id: 'portal.views.insurance-card-modal.iosCustomers',
        defaultMessage: 'iOS customers'
    },
    androidCustomers: {
        id: 'portal.views.insurance-card-modal.androidCustomers',
        defaultMessage: 'Android customers'
    },
    close: {
        id: 'portal.views.insurance-card-modal.close',
        defaultMessage: 'Close'
    },
    or: {
        id: 'portal.views.insurance-card-modal.or',
        defaultMessage: 'OR'
    },
    followStepsIOS: {
        id: 'portal.views.insurance-card-modal.followStepsIOS',
        defaultMessage: 'Follow these steps to download the ID card to your wallet.'
    },
    iOSFirstStep: {
        id: 'portal.views.insurance-card-modal.iOSFirstStep',
        defaultMessage: 'On your iOS device use the button below to download your digital ID Card.'
    },
    iOSSecondStep: {
        id: 'portal.views.insurance-card-modal.iOSSecondStep',
        defaultMessage: 'The ID card should automatically download and open in your Apple Pay wallet. If the ID card isn\'t automatically added to your wallet, locate the downloaded file in your notification center.'
    },
    iOSThirdStep: {
        id: 'portal.views.insurance-card-modal.iOSThirdStep',
        defaultMessage: 'You should see a preview of the card in your wallet. Next press "Add" in the top right of your device.'
    },
    iOSFourthStep: {
        id: 'portal.views.insurance-card-modal.iOSFourthStep',
        defaultMessage: 'The card is now added to your Apple Pay wallet. It will be available in your wallet with or without internet access. If you make changes to your policy or your policy renews you will need to re-download the ID Card file to your wallet.'
    },
    followStepsAndroid: {
        id: 'portal.views.insurance-card-modal.followStepsAndroid',
        defaultMessage: 'Follow these steps to download the ID card to Google Pay.'
    },
    androidFirstStep: {
        id: 'portal.views.insurance-card-modal.androidFirstStep',
        defaultMessage: 'Using your Android device Download Google Pay for Android.'
    },
    androidSecondStep: {
        id: 'portal.views.insurance-card-modal.androidSecondStep',
        defaultMessage: 'Once Google Pay is installed on your Android device select your vehicle from the list below and use the button beneath the title to download your digital ID Card.'
    },
    androidThirdStep: {
        id: 'portal.views.insurance-card-modal.androidThirdStep',
        defaultMessage: 'The card is now added to Google Pay. It will be available in the Google Pay app with or without internet access. If you make changes to your policy or your policy renews you will need to re-download the ID Card.'
    },
    selectVehicle: {
        id: 'portal.views.insurance-card-modal.selectVehicle',
        defaultMessage: 'Select the vehicle you want an ID card for.'
    },
    sorry: {
        id: "policy.common.views.email-proof-of-insurance-modal.We're Sorry",
        defaultMessage: "We're Sorry"
    },
    sorryMessage: {
        id: 'policy.common.views.email-proof-of-insurance-modal.A technical error occurred completing your request. Please try again later or call us at 1-888-363-7984 or email us at service.us@wawanesa.com.  Our customer service team is available Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.',
        defaultMessage: 'A technical error occurred completing your request.&lt;br/&gt;&lt;br/&gt;Please try again later or call us at &lt;a wmic-track-button-click=\"Modal - Email Document Error - Support Phone Number\" href=\"tel:1-888-363-7984\"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href=\"mailto:service.us@wawanesa.com\"&gt;service.us@wawanesa.com&lt;/a&gt;.  Our customer service team is available Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.',
    }
});
