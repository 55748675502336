import messages from './WMICQuestionSetBulletPoint.messages';

const questionsWithBulletPoints = ['ISSpecialVehicle_wmic', 'BPASSPUPPrequalProfession_WMIC', 'ISForUndesirableCommercialPurposes_wmic', 'SingleWallTank'];

const bulletPoints = {
    ISSpecialVehicle_wmic: [
        messages.highPerformance,
        messages.KITcars,
        messages.foreignExoticOrSportsCars,
        messages.antiqueOrClassicVehicle,
        messages.rightHandDriveVehicle,
        messages.miniOrPocketBike,
        messages.motardSupermotard,
        messages.offRoadMotocross,
        messages.allTerrainVehicles,
        messages.amphibiousVehicle,
        messages.allTerrainBuggy,
        messages.childSizeOrJuniorSnowVehicle
    ],
    BPASSPUPPrequalProfession_WMIC: [
        messages.actor,
        messages.author,
        messages.broadcaster,
        messages.journalist,
        messages.labourUnionLeaders,
        messages.newsmediaEditor,
        messages.politician,
        messages.professionalAthlete,
        messages.professionalEntertainer
    ],
    ISForUndesirableCommercialPurposes_wmic: [
        messages.highHazard,
        messages.longHaul,
        messages.usedOutsideCanada

    ],
    SingleWallTank: [
        messages.singleWalledReconditioned,
        messages.locatedUnderground,
        messages.uncertified,
        messages.notProfessionallyInstalled,
        messages.manufacturedByDTE
    ]
};


function isQuestionWithBulletPoints(code) {
    return questionsWithBulletPoints.includes(code);
}

function getBulletPoints(code, translator) {
    return bulletPoints[code].map((element) => {
        return { message: translator(element) };
    });
}

export default {
    isQuestionWithBulletPoints: (code) => {
        return isQuestionWithBulletPoints(code);
    },
    getBulletPoints: (code, translator) => {
        return getBulletPoints(code, translator);
    }
};
