import { defineMessages } from 'react-intl';

export default defineMessages({
    editBankAccount: {
        id: 'userprofile.controllers.bankaccountmodal.Edit Bank Account',
        defaultMessage: 'Edit Bank Account'
    },
    bankAccountSaved: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Saved',
        defaultMessage: 'Bank Account Saved'
    },
    thereWasAnError: {
        id: 'userprofile.controllers.bankaccountmodal.There was an error',
        defaultMessage: 'Bank account is not saved'
    },
    bankAccountAlreadyExists: {
        id: 'userprofile.controllers.bankaccountmodal.Bank account already exists',
        defaultMessage: 'Bank account already exists'
    },
    invalidRoutingTransitNumber: {
        id: 'userprofile.controllers.bankaccountmodal.Invalid routing transit number',
        defaultMessage: 'Invalid routing transit number'
    },
    deleteBankAccount: {
        id: 'userprofile.controllers.bankaccountmodal.Delete Bank Account?',
        defaultMessage: 'Delete Bank Account?'
    },
    bankAccountDeleted: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Deleted',
        defaultMessage: 'Bank Account Deleted'
    },
    deletionUnsuccessful: {
        id: 'userprofile.controllers.bankaccountmodal.Deletion Unsuccessful',
        defaultMessage: 'Deletion Unsuccessful'
    },
    addBankAccount: {
        id: 'userprofile.controllers.bankaccountmodal.Add bank account',
        defaultMessage: 'Add bank account'
    },
    accountNickname: {
        id: 'userprofile.controllers.bankaccountmodal.Account Nickname',
        defaultMessage: 'Account Nickname:'
    },
    accountType: {
        id: 'userprofile.controllers.bankaccountmodal.Account Type',
        defaultMessage: 'Account Type:'
    },
    accountNumber: {
        id: 'userprofile.controllers.bankaccountmodal.Account Number',
        defaultMessage: 'Account Number:'
    },
    thisIsPrimary: {
        id: 'userprofile.controllers.bankaccountmodal.This is your primary account.',
        defaultMessage: 'This is your primary account.'
    },
    primaryAccountWillBeUsed: {
        id: 'userprofile.controllers.bankaccountmodal.Your primary account will be used',
        defaultMessage: 'Your primary account will be used when Wawanesa sends you an electronic payment.'
    },
    setAsPrimary: {
        id: 'userprofile.controllers.bankaccountmodal.Set as primary account.',
        defaultMessage: 'Set as primary account.'
    },
    savingPrimaryUnsuccessful: {
        id: 'userprofile.controllers.bankaccountmodal.Saving Primary Account Unsuccessful',
        defaultMessage: 'Saving Primary Account Unsuccessful'
    },
    forMARPolicy: {
        id: 'userprofile.controllers.bankaccountmodal.This account is used for MAR',
        defaultMessage: 'This account is used for Monthly Automated Recurring payments. If you need to edit or delete it, please contact us at 1-888-363-7984 Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    edit: {
        id: 'userprofile.controllers.bankaccountmodal.Edit',
        defaultMessage: 'Edit'
    },
    delete: {
        id: 'userprofile.controllers.bankaccountmodal.Delete',
        defaultMessage: 'Delete'
    },
    nameOnAccount: {
        id: 'userprofile.controllers.bankaccountmodal.Name on Account',
        defaultMessage: 'Name on Account'
    },
    authorization: {
        id: 'userprofile.controllers.bankaccountmodal.Authorization',
        defaultMessage: 'Authorization'
    },
    iAuthorize: {
        id: 'userprofile.controllers.bankaccountmodal.I authorize Wawanesa to store the bank account information',
        defaultMessage: 'I authorize Wawanesa to store the bank account information indicated on the Authorization Form for the purpose of making future one-time payments to Wawanesa. This Authorization will remain in effect until Wawanesa has received written notice from you of its termination.'
    },
    enterBankAccountInformation: {
        id: 'userprofile.controllers.bankaccountmodal.Enter Bank Account Information',
        defaultMessage: 'Enter Bank Account Information'
    },
    checking: {
        id: 'userprofile.controllers.bankaccountmodal.Checking',
        defaultMessage: 'Checking'
    },
    savings: {
        id: 'userprofile.controllers.bankaccountmodal.Savings',
        defaultMessage: 'Savings'
    },
    bankAccountType: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Type',
        defaultMessage: 'Bank Account Type'
    },
    pleaseChooseAnAccountTypeBeforeProceeding: {
        id: 'userprofile.controllers.bankaccountmodal.Please choose an account type before proceeding.',
        defaultMessage: 'Please choose an account type before proceeding.'
    },
    nonSufficientFundsFeeWillBeAssessed: {
        id: 'userprofile.controllers.bankaccountmodal. A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.',
        defaultMessage: ' A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.'
    },
    chequeSample: {
        id: 'userprofile.controllers.bankaccountmodal.Cheque sample',
        defaultMessage: 'Cheque sample'
    },
    checkNumberIsNotARequiredField: {
        id: 'userprofile.controllers.bankaccountmodal.* Check number is not a required field.',
        defaultMessage: '* Check number is not a required field.'
    },
    routingTransitNumber: {
        id: 'userprofile.controllers.bankaccountmodal.Routing Transit Number',
        defaultMessage: 'Routing Transit Number'
    },
    bankAccountNumber: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Number',
        defaultMessage: 'Bank Account Number'
    },
    routingTransitNumberMustBe9DigitsLong: {
        id: 'userprofile.controllers.bankaccountmodal.Routing transit number must be 9 digits long.',
        defaultMessage: 'Routing transit number must be 9 digits long.'
    },
    bankAccountNumberMustBe1To17Digits: {
        id: 'userprofile.controllers.bankaccountmodal.Bank account number must be 1 to 17 digits.',
        defaultMessage: 'Bank account number must be 1 to 17 digits.'
    },
    yourCurrentAccountNumber: {
        id: 'userprofile.controllers.bankaccountmodal.Your current account number is set to:',
        defaultMessage: 'Your current account number is set to:'
    },
    nickname: {
        id: 'userprofile.controllers.bankaccountmodal.Nickname',
        defaultMessage: 'Nickname'
    },
    makeThisPrimary: {
        id: 'userprofile.controllers.bankaccountmodal.Make this my primary Account',
        defaultMessage: 'Make this my primary Account'
    },
    yourPrimaryAccountWillBeUsed: {
        id: 'userprofile.controllers.bankaccountmodal.Your primary account will be used when Wawanesa sends you an electronic payment.',
        defaultMessage: 'Your primary account will be used when Wawanesa sends you an electronic payment.'
    },
    agreeSaveBankAccount: {
        id: 'userprofile.controllers.bankaccountmodal.I AGREE',
        defaultMessage: 'I AGREE'
    },
    bankAccountExists: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Exists',
        defaultMessage: 'Bank Account Exists'
    },
    bankAccountExistsText: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Exists text',
        defaultMessage: 'A bank account with the routing and bank account number submitted is already saved to your account. Please enter a different routing or account number to continue.'
    },
    bankAccountNicknameExists: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Nickname Exists',
        defaultMessage: 'Bank Account Nickname Exists'
    },
    bankAccountNicknameExistsText: {
        id: 'userprofile.controllers.bankaccountmodal.Bank Account Nickname Exists text',
        defaultMessage: 'A bank account with the nickname submitted is already saved to your account. Please enter a different nickname to continue.'
    },
    errorSavingBankingInfo: {
        id: 'userprofile.controllers.bankaccountmodal.There was an error saving your banking information',
        defaultMessage: 'There was an error saving your banking information. Contact us for assistance at 1-800-640-2920 Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    successSavingBankingInfo: {
        id: 'userprofile.controllers.bankaccountmodal.Thank you, your bank account has been successfully saved',
        defaultMessage: 'Thank you, your bank account has been successfully saved for future use.'
    },
    somethingWentWrong: {
        id: 'userprofile.controllers.bankaccountmodal.Something went wrong',
        defaultMessage: 'Something went wrong. Your bank account cannot be saved.'
    },
    duplicateAccountNumber: {
        id: 'userprofile.controllers.bankaccountmodal.Duplicate account number',
        defaultMessage: 'A bank account with the same bank account number is already saved to your account. Please enter a different bank account number to continue.'
    },
    routingTransitNumberInvalid: {
        id: 'userprofile.controllers.bankaccountmodal.The Routing Transit Number entered is not valid',
        defaultMessage: 'The Routing Transit Number entered is not valid. Please re-enter the transit number.'
    },
    pleaseConfirmDelete: {
        id: 'userprofile.controllers.bankaccountmodal.Please confirm you want to delete the following information',
        defaultMessage: 'Please confirm you want to delete the following information'
    },
    accountSuccessfullyDeleted: {
        id: 'userprofile.controllers.bankaccountmodal.Your bank account has been successfully deleted.',
        defaultMessage: 'Your bank account has been successfully deleted.'
    },
    errorDeletingBankAccount: {
        id: 'userprofile.controllers.bankaccountmodal.There was an error deleting your banking information.',
        defaultMessage: 'There was an error deleting your banking information.'
    },
    contactForAssistance: {
        id: 'userprofile.controllers.bankaccountmodal.Contact us for assistance',
        defaultMessage: 'Contact us for assistance at 1-888-3637984 Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM'
    },
    back: {
        id: 'userprofile.controllers.bankaccountmodal.Back',
        defaultMessage: 'Back'
    },
    cancel: {
        id: 'userprofile.controllers.bankaccountmodal.Cancel',
        defaultMessage: 'Cancel'
    },
    yesDelete: {
        id: 'userprofile.controllers.bankaccountmodal.Yes, Delete',
        defaultMessage: 'Yes, Delete'
    },
    ok: {
        id: 'userprofile.controllers.bankaccountmodal.OK',
        defaultMessage: 'OK'
    },
    submit: {
        id: 'userprofile.controllers.bankaccountmodal.Submit',
        defaultMessage: 'Submit'
    },
    save: {
        id: 'userprofile.controllers.bankaccountmodal.Save',
        defaultMessage: 'Save'
    },
    close: {
        id: 'userprofile.controllers.bankaccountmodal.Close',
        defaultMessage: 'Close'
    }
});