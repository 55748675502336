import React from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { CONSTANTS, WMICRichTextUtil } from 'wmic-portals-utils-js';
import appConfig from 'app-config';

import metadata from './WMICSidebarBoxes.metadata.json5';
import messages from './WMICSidebarBoxes.messages';

function WMICSidebarBoxes() {
    const currentRegion = localStorage.getItem(WMICRegionUrlUtil.CONSTANTS.REGION);
    const translator = useTranslator();
    const { phoneNumber, phoneNumberRaw } = appConfig;

    let quickLinksRegion;

    switch (currentRegion) {
        case WMICRegionUrlUtil.CONSTANTS.CA_SHORT:
            quickLinksRegion = WMICRegionUrlUtil.CONSTANTS.CA_SHORT;
            break;
        case WMICRegionUrlUtil.CONSTANTS.OR_SHORT:
        default:
            quickLinksRegion = WMICRegionUrlUtil.CONSTANTS.OR_SHORT;
            break;
    }

    const overrideProps = {
        renterCoverageLink: {
            href: CONSTANTS.QUICK_LINKS[quickLinksRegion].RENTERS_COVERAGE
        },
        renterDiscountLink: {
            href: CONSTANTS.QUICK_LINKS[quickLinksRegion].RENTERS_DISCOUNTS
        },
        renterInsuranceFaqLink: {
            href: CONSTANTS.QUICK_LINKS[quickLinksRegion].RENTERS_INSURANCE_FAQ
        },
        callForHelp: {
            content: WMICRichTextUtil.translateRichText(translator(messages.callForHelp, { phoneNumberRaw, phoneNumber }))
        }
    };

    return (
        <div>
            <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} />
        </div>
    );
}

export default WMICSidebarBoxes;
