/* eslint-disable max-len */
import _ from 'lodash';
import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
    Fragment,
} from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { Decimal } from 'decimal.js-light';
import {
    LobIconUtil,
    SUITES,
    WMICPaymentUtil,
    WMICFeatureFlagUtil,
    CONSTANTS,
    WMICRichTextUtil
} from 'wmic-portals-utils-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { WMICLink } from "wmic-components-amp-common-react";
import { PolicyService } from 'gw-capability-policy';
import {
    WMICModal,
    WMICTriggerInsuranceCardModal,
    WMICPolicyDetailsUtil
} from 'gw-capability-policy-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import {
    WMICLoader
} from 'gw-components-platform-react';
import { WMICButton } from 'wmic-components-platform-react';
import classNames from 'classnames';
import WMICSection from "../WMICSection/WMICSection";
import WMICPaymentModal from '../WMICPaymentModal/WMICPaymentModal';
import metadata from './WMICPolicySummaryBox.metadata.json5';
import messages from './WMICPolicySummaryBox.messages';
import styles from './WMICPolicySummaryBox.module.scss';

const PolicySummaryBox = (props) => {
    const modalApi = useModal();
    const { data: aPolicy, isInactive, fetchBillingData } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const [insuranceCardsLoading, setInsuranceCardsLoading] = useState([]);
    const [policyNumber, setPolicyNumber] = useState('');
    const [termNumber, setTermNumber] = useState('');
    const [walletPassAvailable, setWalletPassAvailable] = useState(false);
    const [ldFlags, setLdFlags] = useState({});
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();

    const POLICY_OVERVIEW = {
        PERSONAL_AUTO: 'Personal Auto',
        RENTERS: 'Renters',
        HOMEOWNERS: 'Homeowners',
        CONDOMINIUM: 'Condominium'
    };

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    useEffect(() => {
        initFeatureFlags();
    });

    useEffect(() => {
        setPolicyNumber(_.get(aPolicy, 'policyData.policyId'));
        setTermNumber(_.get(aPolicy, 'policyData.termNumber_WMIC'));
        const walletPassAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.WALLETPASS);
        setWalletPassAvailable(walletPassAvailability && walletPassAvailability.isAvailable);
    }, [aPolicy, ldFlags, featureFlags]);

    const overdueAmount = useMemo(() => {
        const overdue = new Decimal(aPolicy.billingData.amountOverDue ? aPolicy.billingData.amountOverDue.amount : 0);
        const priorTermBalance = new Decimal(aPolicy.billingData.priorTermBalance ? aPolicy.billingData.priorTermBalance.amount : 0);
        const totalOverdue = overdue.plus(priorTermBalance);
        return totalOverdue;
    }, [aPolicy]);

    const hasOverdueAmount = useMemo(() => {
        return overdueAmount && new Decimal(overdueAmount).isPositive();
    }, [aPolicy]);

    const getPolicyIdLink = (policy) => (
        <WMICLink
            to={{
                pathname: `/account-policy-details/${policy.policyData.policyId}/${policy.policyData.termNumber_WMIC}`,
            }}
            aria-label={translator(messages.policyNumberAriaLabel, {
                policyNumber: aPolicy.policyData.policyId
            })}
        >
            {policy.policyData.policyId}
        </WMICLink>
    );

    /*
    * DESCRIPTION: returns a formatted string with the term period if effective and expiration are not null, or an empty string otherwise.
    * PARAMS:
    *   effective: string // format: "2021-07-01T05:01:00Z"
    *   expiration: string // format: "2021-07-01T05:01:00Z"
    * RETURN: string // term period in this format: 'mm/dd/yyyy - mm/dd/yyyy'
    */
    const getTermValue = (effective, expiration) => {
        let termValue = '';
        if (effective && expiration) {
            const effDate = intl.formatDate(new Date(effective), { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'US/Central' });
            const expDate = intl.formatDate(new Date(expiration), { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'US/Central' });
            termValue = `${effDate} - ${expDate}`;
        }
        return termValue;
    };

    const getReadableDate = (theDate) => {
        let formattedDate = '';
        if (theDate) {
            formattedDate = intl.formatDate(new Date(theDate), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'US/Central' });
        }
        return formattedDate;
    };

    const getReadableMonetaryValue = (currencyObject) => {
        let formattedNumber = '';
        if (currencyObject) {
            formattedNumber = intl.formatNumber(
                currencyObject.amount,
                {
                    style: 'currency',
                    currency: currencyObject.currency,
                    currencyDisplay: 'symbol'
                }
            )
        }
        return formattedNumber;
    };

    const isPayNowButtonVisible = (policy) => {
        let renewalStatus;
        if (policy.renewal_Details_WMIC && policy.renewal_Details_WMIC.displayStatus_WMIC) {
            renewalStatus = policy.renewal_Details_WMIC.displayStatus_WMIC;
        }
        return WMICPaymentUtil.canShowPayNowButton(policy.billingData, WMICPaymentUtil.getPolicyStatusName(policy.policyData.displayStatus_WMIC, renewalStatus));
    };

    const isReinstateMessageVisible = (policy) => {
        const billingStatus = _.get(policy, 'policyData.displayStatus_WMIC');
        return !_.isUndefined(billingStatus) && billingStatus.toLowerCase() === CONSTANTS.CANCELED && isPayNowButtonVisible(policy) && isInactive;
    };

    const getPolicySummaryTitle = (policy) => {
        const getTitle = (p) => {
            switch (p.policyData.overview) {
                case POLICY_OVERVIEW.PERSONAL_AUTO:
                    return translator(messages.automobilePolicyNumber, { policyNumber: p.policyData.policyId });
                case POLICY_OVERVIEW.RENTERS:
                    return translator(messages.rentersPolicyNumber, { policyNumber: p.policyData.policyId });
                case POLICY_OVERVIEW.HOMEOWNERS:
                    return translator(messages.homeownersPolicyNumber, { policyNumber: p.policyData.policyId });
                case POLICY_OVERVIEW.CONDOMINIUM:
                    return translator(messages.condominiumPolicyNumber, { policyNumber: p.policyData.policyId });
                default:
                    return translator(messages.genericPolicyNumber, { policyNumber: p.policyData.policyId });
            }
        };
        const icon = LobIconUtil.getWmicIcon(_.get(policy, 'policyData.lines[0]'));
        const iconClassName = hasOverdueAmount ? 'titleIconRed' : 'titleIcon';
        const textClassName = hasOverdueAmount ? 'wmic-alert-color' : '';
        return (
            <Fragment>
                <i className={`fa ${icon} ${iconClassName}`} />
                <h3 className={classNames(textClassName, styles['wmic-policy-title'])}>
                    {getTitle(policy)}
                </h3>
            </Fragment>
        );
    };

    const getPolicyStatus = (policy) => {
        if (isInactive) {
            return translator(messages.statusInactive);
        }
        switch (policy.policyData.displayStatus_WMIC.toLowerCase()) {
            case CONSTANTS.IN_FORCE:
                return translator(messages.statusActive);
            case CONSTANTS.EXPIRED:
                return translator(messages.statusInactive);
            case CONSTANTS.SCHEDULED:
                return translator(messages.statusNew);
            default:
                return '-';
        }
    };

    const isListBill = useMemo(() => {
        let result = false;
        const billingData = _.get(aPolicy, 'billingData');
        if (billingData) {
            result = WMICPaymentUtil.isListBill(billingData);
        }
        return result;
    }, [aPolicy]);

    const payNowButtonTrackingText = useMemo(() => {
        return isListBill ?
            translator(messages.payNowListBillTrackButtonIdentifier, {policyActiveStatus: getPolicyStatus(aPolicy)}) :
            translator(messages.payNowTrackButtonIdentifier, {policyActiveStatus: getPolicyStatus(aPolicy)});
    }, [isListBill, aPolicy]);

    const isInsuranceCardLoading = useCallback((aPolicyNumber) => {
        return insuranceCardsLoading[aPolicyNumber];
    }, [insuranceCardsLoading]);

    const handleIDCardLoader = useCallback((aPolicyNumber, value) => {
        const loadingData = [];
        loadingData[aPolicyNumber] = value;
        setInsuranceCardsLoading(loadingData);
    }, [setInsuranceCardsLoading]);


    const onTriggerInsuranceCardModal = () => {
        let periodData = null;
        const promise = PolicyService.getAccountPolicyDetails_WMIC(policyNumber, termNumber, authHeader, authUserData.firstName, authUserData.lastName);
        handleIDCardLoader(policyNumber, true);
        promise.then((response) => {
            const policyData = response;

            if ((aPolicy.policyData.renewal_Details_WMIC != null && aPolicy.policyData.renewal_Details_WMIC.termNumber_WMIC === termNumber) || (policyData.renewedPeriod && policyData.renewedPeriod.termNumber_WMIC && policyData.renewedPeriod.termNumber_WMIC.toString() === termNumber)) {
                periodData = policyData.renewedPeriod;
            } else {
                periodData = policyData.currentPeriod;
            }

            const proofOfInsurance = WMICPolicyDetailsUtil.getProofOfInsurance(periodData.documentDTOs);
            const documents = [];
            documents.proofOfInsuranceDocument = {
                id: proofOfInsurance ? proofOfInsurance.workingPublicID : undefined,
                friendlyName: translator(messages.proofOfInsuranceFriendlyName),
                sessionID: proofOfInsurance ? proofOfInsurance.sessionID : undefined,
                fileName: proofOfInsurance ? proofOfInsurance.name : '',
                visible: !!proofOfInsurance,
                type: SUITES.PC
            };
            const personalAuto = _.get(periodData, 'lobs.personalAuto');
            if (proofOfInsurance && personalAuto && personalAuto.vehicleDTOs && personalAuto.vehicleDTOs.length > 0) {
                WMICTriggerInsuranceCardModal({
                    policyNumber,
                    termNumber,
                    documents,
                    vehicles: personalAuto.vehicleDTOs,
                    walletPassAvailable,
                    authHeader
                });
            } else {
                handleIDCardLoader(policyNumber, false);
                const body = WMICRichTextUtil.translateRichText(translator(messages.insuranceIDModalErrorMessageBody));
                const title = translator(messages.insuranceIDModalSorry);
                modalApi.showModal(
                    <WMICModal
                        id="insuranceIDCardModal"
                        modalHeader={title}
                        modalBody={body}
                        onConfirmCallback={() => handleIDCardLoader(policyNumber, false)}
                        confirmButtonText={translator(messages.close)}
                    />
                );
            }
        }).finally(() => {
            handleIDCardLoader(policyNumber, false);
        });
    };

    const getInsuranceCardContent = useCallback(() => {
        return isInsuranceCardLoading(policyNumber) !== null && isInsuranceCardLoading(policyNumber) ? <WMICLoader isInline title={translator(messages.insuranceIdCard)}/> : translator(messages.insuranceIdCard);
    }, [policyNumber, translator, isInsuranceCardLoading]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICButton,
            WMICSection
        }
    };

    const overrides = {
        policySummaryTitle: {
            content: getPolicySummaryTitle(aPolicy)
        },
        activePeriodTitleMobile: {
            visible: aPolicy.policyData.renewal_Details_WMIC != null
        },
        marPaymentPlanMessage: {
            visible: WMICPaymentUtil.isMARPayment(aPolicy.billingData),
            content: translator(messages.marPaymentsMessage)
        },
        policyNumberValueDiv: {
            content: getPolicyIdLink(aPolicy)
        },
        statusColValueDiv: {
            content: getPolicyStatus(aPolicy)
        },
        statusColValueDivAria: {
            content: translator(messages.policyStatusAriaLabel, {
                policyNumber: aPolicy.policyData.policyId,
                status: getPolicyStatus(aPolicy)
            })
        },
        termColValueDiv: {
            content: getTermValue(aPolicy.policyData.effective, aPolicy.policyData.expiration)
        },
        termColValueDivAria: {
            content: translator(messages.policyTermAriaLabel, {
                policyNumber: aPolicy.policyData.policyId,
                termStartDate: getReadableDate(aPolicy.policyData.effective),
                termEndDate: getReadableDate(aPolicy.policyData.expiration),
            })
        },
        paymentPlanColValueDiv: {
            content: aPolicy.billingData.paymentPlan
        },
        paymentPlanColValueDivAria: {
            content: translator(messages.paymentPlanAriaLabel, {
                policyNumber: aPolicy.policyData.policyId,
                paymentPlanName: aPolicy.billingData.paymentPlan
            })
        },
        policyTermTotalColValue: {
            value: aPolicy.billingData.totalPremiumAmount,
            showFractions: true
        },
        policyTermTotalColValueDivAria: {
            content: translator(messages.policyTermTotalAriaLabel, {
                policyNumber: aPolicy.policyData.policyId,
                policyTermTotalAmount: getReadableMonetaryValue(aPolicy.billingData.totalPremiumAmount)
            })
        },
        amountOutstandingColValue: {
            value: aPolicy.billingData.outstandingAmount,
            showFractions: true
        },
        amountOutstandingColValueDivAria: {
            content: translator(messages.amountOutstandingAriaLabel, {
                policyNumber: aPolicy.policyData.policyId,
                outstandingAmount: getReadableMonetaryValue(aPolicy.billingData.outstandingAmount)
            })
        },
        buttonPayNow: {
            visible: isPayNowButtonVisible(aPolicy),
            type: hasOverdueAmount ? 'danger' : 'secondary',
            size: 'small',
            icon: 'cust-dollar-sign',
            iconClassName: 'payNowIcon',
            content: translator(messages.payNow),
            trackButtonIdentifier: payNowButtonTrackingText,
            onClick: () => {
                modalApi.showModal(<WMICPaymentModal data={aPolicy} fetchBillingData={fetchBillingData} ldFlags={ldFlags} />).catch(_.noop);
            },
            'aria-label': translator(
                hasOverdueAmount ? messages.buttonPayNowOverdueAriaLabel : messages.buttonPayNowAriaLabel, {
                    policyNumber: aPolicy.policyData.policyId,
                    amount: overdueAmount.toString()
            })
        },
        buttonInsuranceIdCard: {
            type: isInsuranceCardLoading(policyNumber) ? 'tertiary' : 'secondary',
            size: 'small',
            visible:
                aPolicy.policyData.overview === POLICY_OVERVIEW.PERSONAL_AUTO &&
                (aPolicy.policyData.displayStatus_WMIC.toLowerCase() ===
                    CONSTANTS.IN_FORCE ||
                    aPolicy.policyData.displayStatus_WMIC.toLowerCase() ===
                        CONSTANTS.SCHEDULED),
            content: getInsuranceCardContent(),
            onClick: () => {
                return onTriggerInsuranceCardModal();
            },
            'aria-label': translator(messages.insuranceIDCardAriaLabel, {
                policyNumber: aPolicy.policyData.policyId
            })
        },
        // Renewal box container
        renewalBoxTitle: {
            content: translator(messages.policyUpForRenewal)
        },
        renewalBoxTitleMobile: {
            content: translator(messages.policyUpForRenewal)
        },
        renewalBoxContainer: {
            visible: aPolicy.policyData.renewal_Details_WMIC != null
        },
        renewalButtonValue: {
            to: `/account-policy-details/${aPolicy.policyData.policyId}/${_.get(aPolicy, 'policyData.renewal_Details_WMIC.termNumber_WMIC')}`
        },
        renewalStatusColValue: {
            content: translator(messages.statusRenewing)
        },
        renewalTermColValue: {
            content: getTermValue(_.get(aPolicy, 'policyData.renewal_Details_WMIC.effective'), _.get(aPolicy, 'policyData.renewal_Details_WMIC.expiration'))
        },
        renewalPaymentPlanColValue: {
            content: _.get(aPolicy, 'billingData.renewalPaymentDetails.paymentPlan')
        },
        renewalPolicyTermTotalColValue: {
            value: _.get(aPolicy, 'billingData.renewalPaymentDetails.totalPremiumAmount'),
            showFractions: true
        },
        renewalAmountOutstandingColValue: {
            value: _.get(aPolicy, 'billingData.renewalPaymentDetails.outstandingAmount'),
            showFractions: true
        },
        inactiveReinstateMessage: {
            visible: isReinstateMessageVisible(aPolicy)
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={aPolicy}
        />
    );
};

PolicySummaryBox.propTypes = {
    data: PropTypes.shape({}),
    isInactive: PropTypes.bool
};

PolicySummaryBox.defaultProps = {
    data: {},
    isInactive: false
};

export default PolicySummaryBox;