import React, {
    useContext, useEffect, useMemo, useState, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { IntlContext, useTranslator } from '@jutro/locale';
import {FormattedCurrency, formatCurrency} from '@jutro/components/widgets/values/FormattedCurrency';
import _ from 'lodash';
import classNames from 'classnames';
import { LocalDateUtil, WMICRichTextUtil } from 'wmic-portals-utils-js';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import WMICPaymentUtil from "wmic-portals-utils-js/WMICPaymentUtil";
import paymentUtility from './ManagePaymentPlanUtility';
import style from './WMICManagePaymentPlan.module.scss';
import messages from './WMICManagePaymentPlan.messages';

let hasPaymentDueByTomorrow;

const WMICComparePaymentPlanModal = (props) => {
    const { billingData, paymentOption, newPaymentPlan, updateSaveDisabled } = props;
    const [results, updateResults] = useState({});
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const { authHeader } = useAuthentication();

    const paymentPlan = _.get(billingData, 'paymentPlan');
    const { policyId, termNumber } = _.get(props, 'billingData', {});

    const items = useMemo(() => {
        let view = [];
        const invoices = _.get(results, 'unpaidInvoices', []);
        if (!invoices.length) {
            return view;
        }
        if (invoices && invoices.length > 0) {
            _.each(invoices, (invoice) => {
                const groups = _.groupBy(invoice.invoiceItems, 'dueDate');
                view = view.concat(
                    _.map(groups, paymentUtility.generatePaymentData.bind(null, intl))
                );
            });
        }

        return _.sortBy(view, (obj) => new Date(obj.eventDate));
    }, [results, intl]);

    const getMatchedPaymentPlan = useMemo(() => {
        let view = [];
        if (!newPaymentPlan) {
            return view;
        }
        const proposedScheduleItems = _.find(newPaymentPlan, (pp) => {
            return pp.paymentPlanDisplayName === paymentOption;
        });

        if (!proposedScheduleItems) {
            return view;
        }

        if (proposedScheduleItems.invoices && proposedScheduleItems.invoices.length > 0) {
            proposedScheduleItems.invoices.forEach((proposedScheduleItem) => {
                proposedScheduleItem.invoiceDueDate = intl.formatDate(LocalDateUtil.toMidnightDate(proposedScheduleItem.dueDate));
            });
            const groups = _.groupBy(proposedScheduleItems.invoices, 'invoiceDueDate');
            view = _.map(groups, paymentUtility.generatePaymentData.bind(null, intl));
        }
        return _.sortBy(view,"eventDate");
    }, [newPaymentPlan, paymentOption, intl]);

    const paymentDueByTomorrowCheck = useCallback((paymentDate) => {
        const maxDate = new Date();
        maxDate.setHours(0, 0, 0, 0); // reset time to compare only the date
        maxDate.setDate(maxDate.getDate() + 1);

        const comparePaymentDate = new Date(paymentDate); // date to compare todays date with
        comparePaymentDate.setHours(0, 0, 0, 0);

        const paymentDueByTomorrow = comparePaymentDate <= maxDate;

        if (paymentDueByTomorrow) {
            hasPaymentDueByTomorrow = true;
        }

        return paymentDueByTomorrow;
    }, []);

    useEffect(() => {
        AccountBillingDetailsService
            .getPolicyBillingSummary_WMIC(policyId, termNumber, authHeader)
            .then((data) => {
                updateResults({ ...data });
            });
        updateSaveDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatLongDate = (value) => {
      return intl.formatDate(new Date(value), { year: 'numeric', month: 'short', day: 'numeric' });
    };

    return (
        <div className={style.paymentPlanContent}>
            <p>
                <strong>
                    {`${translator(messages.currentPaymentPlan)}: `}
                </strong>
                {paymentPlan}
                <br />
                <strong>
                    {`${translator(messages.switchTo)}: `}
                </strong>
                { paymentOption }
            </p>
            <div className="ww-comparison-row">
                <div id="current-plan-col" className="ww-payment-comparison-col">
                    <div className="ww-payment-subsection-box" role="list">
                        <div aria-hidden className="ww-payment-plan-header">
                            <div>{translator(messages.current)}</div>
                            <div>&nbsp;</div>
                        </div>

                        {
                            items.map((item, idx) => (
                                <div key={`ppc-row-${idx}`} className="ww-payment-plan-row">
                                    <div aria-hidden>{item.eventDate}</div>
                                    <div role="listitem" className="sr-only">
                                        {
                                            translator(messages.currentScheduleAria, {
                                                eventDate: formatLongDate(item.eventDate),
                                                amount: formatCurrency(intl, { value:item.amount, showFractions:true })
                                            })
                                        }
                                    </div>
                                    <div aria-hidden className={style.amountDue}>
                                        <FormattedCurrency value={WMICPaymentUtil.getShapedCurrencyAmount(item.amount)} showFractions />
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                    <div className="ww-payment-comparison-details">
                        <p>{translator(messages[_.camelCase(paymentPlan)])}</p>
                    </div>
                </div>
                <div id="proposed-plan-col" className="ww-payment-comparison-col">
                    <div className="ww-payment-subsection-box" role="list">
                        <div aria-hidden className="ww-payment-plan-header">
                            <div>{translator(messages.proposed)}</div>
                            <div>&nbsp;</div>
                        </div>
                        {
                            getMatchedPaymentPlan.map((item, idx) => {
                                const setClassName =  classNames(
                                    'ww-payment-plan-row',
                                    {'ww-billing-alert': paymentDueByTomorrowCheck(item.eventDate)}
                                );
                                return (
                                    <div key={`pp-row-${idx}`} className={setClassName}>
                                        <div aria-hidden>{item.eventDate}</div>
                                        <div role="listitem" className="sr-only">
                                            {
                                                translator(messages.proposedScheduleAria, {
                                                    eventDate: formatLongDate(item.eventDate),
                                                    amount: formatCurrency(intl, { value:item.amount, showFractions:true })
                                                })
                                            }
                                        </div>
                                        <div aria-hidden className={style.amountDue}>
                                            <FormattedCurrency value={WMICPaymentUtil.getShapedCurrencyAmount(item.amount)} showFractions />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        hasPaymentDueByTomorrow && (
                            <div className="ww-payment-comparison-payment-due-alert">
                                <p className={style.alertText}>{translator(messages.noteCurrentPlantoProposed)}</p>
                            </div>
                        )
                    }
                    <div className="ww-payment-comparison-details">
                        <p>{translator(messages[_.camelCase(paymentOption)])}</p>
                    </div>
                </div>
            </div>
            <p className="ww-payment-comparison-contact-info">
                <span aria-hidden>{translator(messages.pleaseCallUs1)}</span>
                <span className="sr-only">{translator(messages.pleaseCallUs1Aria)}</span>
                {WMICRichTextUtil.translateRichText(translator(messages.pleaseCallUs2Phone))}
                <span aria-hidden>{translator(messages.pleaseCallUs3)}</span>
                <span className="sr-only">{translator(messages.pleaseCallUs3Aria)}</span>
                {WMICRichTextUtil.translateRichText(translator(messages.pleaseCallUs4Email))}
            </p>
        </div>
    );
};

WMICComparePaymentPlanModal.propTypes = {
    billingData: PropTypes.shape({}),
    newPaymentPlan: PropTypes.arrayOf(PropTypes.shape({})),
    paymentOption: PropTypes.string,
    updateSaveDisabled: PropTypes.func
};

WMICComparePaymentPlanModal.defaultProps = {
    billingData: {},
    newPaymentPlan: [],
    paymentOption: '',
    updateSaveDisabled: _.noop()
};

export default WMICComparePaymentPlanModal;