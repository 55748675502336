/* eslint-disable no-param-reassign */
import _ from 'lodash';

const PASubmissionDraftDataExtension = function(submission) {
    const lobData = _.get(submission, 'lobData.personalAuto');
    if (lobData && _.isObject(lobData)) {
        Object.assign(this, lobData);
    }

    // Default these collections to empty arrays if they are not defined
    this.drivers ||= [];
    this.vehicles ||= [];
    this.availableAdditionalInterests ||= [];
    this.offerings ||= [];
};

export default PASubmissionDraftDataExtension;