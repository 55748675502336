// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-contact-roles-margin:2px;--gw-contact-roles-padding:2rem;--gw-contact-role-header-padding-bottom:10px;--gw-contact-role-container-padding-left:10px}.app__ContactRoles__gwContactRoleHeader{padding-bottom:10px;padding-bottom:var(--gw-contact-role-header-padding-bottom)}.app__ContactRoles__gwContactRoleContainer{display:flex;margin:2px 0;margin:var(--gw-contact-roles-margin) 0;padding-bottom:var(--GW-LAYOUT-3)}.app__ContactRoles__gwContactRoleContainer .app__ContactRoles__gwContactRoleTitle{color:var(--GW-TEXT-COLOR-1);height:auto}.app__ContactRoles__gwContactRoleContainer .app__ContactRoles__gwContactRoleTitle:after{content:\":\";padding-right:var(--GW-LAYOUT-1)}.app__ContactRoles__gwContactRoleContainer .app__ContactRoles__gwContactRoleValue{font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-gateway-react/Components/ContactRoles/ContactRoles.module.scss"],"names":[],"mappings":"AAAA,MACI,6BAAA,CACA,+BAAA,CACA,4CAAA,CACA,6CAAA,CAGJ,wCACI,mBAAA,CAAA,2DAAA,CAGJ,2CACI,YAAA,CAEA,YAAA,CAAA,uCAAA,CADA,iCACA,CAEA,kFACI,4BAAA,CACA,WAAA,CACA,wFACL,WAAA,CACA,gCAAA,CAGC,kFACI,2CAAA","sourcesContent":[":root {\n    --gw-contact-roles-margin: 2px;\n    --gw-contact-roles-padding: 2rem;\n    --gw-contact-role-header-padding-bottom: 10px;\n    --gw-contact-role-container-padding-left: 10px;\n}\n\n.gwContactRoleHeader {\n    padding-bottom: var(--gw-contact-role-header-padding-bottom);\n}\n\n.gwContactRoleContainer {\n    display: flex;\n    padding-bottom: var(--GW-LAYOUT-3);\n    margin: var(--gw-contact-roles-margin) 0;\n\n    .gwContactRoleTitle {\n        color: var(--GW-TEXT-COLOR-1);\n        height: auto;\n        &:after {\n\t\t\tcontent: '\\003a';\n\t\t\tpadding-right: var(--GW-LAYOUT-1);\n\t\t}\n    }\n    .gwContactRoleValue {\n        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwContactRoleHeader": "app__ContactRoles__gwContactRoleHeader",
	"gwContactRoleContainer": "app__ContactRoles__gwContactRoleContainer",
	"gwContactRoleTitle": "app__ContactRoles__gwContactRoleTitle",
	"gwContactRoleValue": "app__ContactRoles__gwContactRoleValue"
};
export default ___CSS_LOADER_EXPORT___;
