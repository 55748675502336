import { defineMessages } from 'react-intl';

export default defineMessages({
    nameAndAddressRequired: {
        id: 'wmic.capabilities.policy-change.models.Name and Address are required',
        defaultMessage: 'Name and Address are required.'
    },
    streetStateZipRequired: {
        id: 'wmic.capabilities.policy-change.models.Street, city, state and zip are required',
        defaultMessage: 'Street, city, state and zip are required.'
    },
    countryStateRequired: {
        id: 'wmic.capabilities.policy-change.models.The fields country, state are required.',
        defaultMessage: 'The fields country, state are required.'
    },
    applyPolicyLevelCoverageChanges: {
        id: 'wmic.capabilities.policy-change.models.Please apply the following Policy level coverage changes',
        defaultMessage: 'Please apply the following Policy level coverage changes:\n'
    },
    ampChangeOfCoverageRequest: {
        id: 'wmic.capabilities.policy-change.models.Account Management Portal - Change of Coverage Request',
        defaultMessage: 'Account Management Portal - Change of Coverage Request'
    },
    oldAndNewAddressRequired: {
        id: 'wmic.capabilities.policy-change.models.Old Address and New Address are required.',
        defaultMessage: 'Old Address and New Address are required.'
    },
    ampChangeAddressRequest: {
        id: 'wmic.capabilities.policy-change.models.Account Management Portal - Change of Address Request',
        defaultMessage: 'Account Management Portal - Change of Address Request'
    },
    paymentPlanRequired: {
        id: 'wmic.capabilities.policy-change.models.Payment plan is required.',
        defaultMessage: 'Payment plan is required.'
    },
    ampChangePaymentPlanRequest: {
        id: 'wmic.capabilities.policy-change.models.Account Management Portal - Change Payment Plan Request',
        defaultMessage: 'Account Management Portal - Change Payment Plan Request'
    },
    nameRequired: {
        id: 'wmic.capabilities.policy-change.models.The field name is required.',
        defaultMessage: 'The field name is required.'
    },
    vehicleReasonDateRequired: {
        id: 'wmic.capabilities.policy-change.models.The fields vehicle, reason and date are required.',
        defaultMessage: 'The fields vehicle, reason and date are required.'
    },
    ampDeleteVehicleRequest: {
        id: 'wmic.capabilities.policy-change.models.Account Management Portal - Delete Vehicle Request',
        defaultMessage: 'Account Management Portal - Delete Vehicle Request'
    },
    changeTypeRequired: {
        id: 'wmic.capabilities.policy-change.models.Change type is required',
        defaultMessage: 'Change type is required'
    },
    ampChangeDesignatedAdditionalPersonRequest: {
        id: 'wmic.capabilities.policy-change.models.Account Management Portal - Change Designated Additional Person Request',
        defaultMessage: 'Account Management Portal - Change Designated Additional Person Request'
    },
    nameStreetCityStateZipPermissionRequired: {
        id: 'wmic.capabilities.policy-change.models.Name, Street, City, State, Zip and Permission are required',
        defaultMessage: 'Name, Street, City, State, Zip and Permission are required'
    },
    vehicleCollisionDeductibleWaiverPropertyDamageVehicleManufacturerReplacementPartsRequired: {
        id: 'wmic.capabilities.policy-change.models.The fields vehicle, collisionDeductibleWaiver, propertyDamage, vehicleManufacturerReplacementParts are required.',
        defaultMessage: 'The fields vehicle, collisionDeductibleWaiver, propertyDamage, vehicleManufacturerReplacementParts are required.'
    },
    vehiclePropertyDamageVehicleManufacturerReplacementPartsRequired: {
        id: 'wmic.capabilities.policy-change.models.The fields vehicle, propertyDamage, vehicleManufacturerReplacementParts are required.',
        defaultMessage: 'The fields vehicle, propertyDamage, vehicleManufacturerReplacementParts are required.'
    },
    changeTypeVehicleCompanyInfoRequired: {
        id: 'wmic.capabilities.policy-change.models.ChangeType, vehicle and companyInfo are required.',
        defaultMessage: 'ChangeType, vehicle and companyInfo are required.'
    },
    ampChangeFinanceLeasingCompanyRequest: {
        id: 'wmic.capabilities.policy-change.models.Account Management Portal - Change Finance or Leasing Company Request',
        defaultMessage: 'Account Management Portal - Change Finance or Leasing Company Request'
    },
    policyHolderPolicyNumberEffectiveDateRequesterRequired: {
        id: 'wmic.capabilities.policy-change.models.Policy holder, policy number, effective date and requester are required.',
        defaultMessage: 'Policy holder, policy number, effective date and requester are required.'
    },
    ampChangeRoadsideAssistanceRequest: {
        id: 'wmic.capabilities.policy-change.models.Account Management Portal - Change Roadside Assistance Request',
        defaultMessage: 'Account Management Portal - Change Roadside Assistance Request'
    },
    changeTypeVehicleRequired: {
        id: 'wmic.capabilities.policy-change.models.ChangeType and vehicle are required.',
        defaultMessage: 'ChangeType and vehicle are required.'
    },
    yearMakeModelVinRequired: {
        id: 'wmic.capabilities.policy-change.models.The fields year, make, model and vin are required.',
        defaultMessage: 'The fields year, make, model and vin are required.'
    },
    policyHolderNumberEffectiveDateRequired: {
        id: 'wmic.capabilities.policy-change.models.Policy holder, number and effective date are required.',
        defaultMessage: 'Policy holder, number and effective date are required.'
    }
});
