import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { WMICRPCUtil, CONSTANTS, DeviceBreakpoint, Position, HOConstants } from 'wmic-pe-portals-utils-js';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { TextAreaField } from '@jutro/legacy/components';
import { commonMessages } from 'gw-capability-quoteandbind-common-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import _ from 'lodash';

import { WMICAdditionalInterestComponent } from 'wmic-pe-capability-gateway-common-ho-react';

import styles from './WMICScheduledItemComponent.module.scss';
import messages from './WMICScheduledItemComponent.messages';
import metadata from './WMICScheduledItemComponent.metadata.json5';

function WMICScheduledItemComponent(props) {
    const {
        id,
        value: scheduledItemVM,
        updateModel: setScheduledItemVM,
        readOnly: pReadOnly,
        jobVM,
        onValidate,
        showErrors,
        setShowErrors,
        isPolicyView,
        isEditing
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { refreshData } = useDataRefresh();
    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals();

    const [isEditingAdditionalInterest, setIsEditingAdditionalInterest] = useState(false);
    const [scheduledItemType, setScheduledItemType] = useState('');
    const [scheduledItemSubTypeList, setScheduledItemSubTypeList] = useState([]);
    const [additionalInterestVM, _setAdditionalInterestVM] = useState(undefined);
    const [isNewAdditionalInterestVisible, setNewAdditionalInterestVisible] = useState(false);
    const [selectedIndex, _setSelectedIndex] = useState(-1);
    const [deductibleAvailableValues, setDeductibleAvailableValues] = useState([]);
    const [vacationTrailerDeductibleList, setVacationTrailerDeductibleList] = useState([]);
    const [jewelleryAndWatchesDeductibleList, setJewelleryAndWatchesDeductibleList] = useState([]);
    const [cellularPhoneDeductibleList, setCellularPhoneDeductibleList] = useState([]);

    const deductibleCompleteList = viewModelService.productMetadata.get('pc').types.getTypelist('ScheduledItemDedOpt_WMIC');
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        _setAdditionalInterestVM(undefined)
    }, [scheduledItemVM])

    const setAdditionalInterestVM = useCallback((data) => {
        _setAdditionalInterestVM(data);
        refreshData();
    }, [refreshData, _setAdditionalInterestVM]);

    const mapDeductibleList = (source) => {
        if (_.isNil(source)) return [];
        return source.map((item) => {
            return { code: parseInt(item.code), name: item.name };
        });
    }

    const fillDeductibleAvailableValues = () => {
        setVacationTrailerDeductibleList(mapDeductibleList(deductibleCompleteList.filters.find((item) => item.name === 'VacTrailer_CAOpts')?.codes));
        setJewelleryAndWatchesDeductibleList(mapDeductibleList(deductibleCompleteList.filters.find((item) => item.name === 'Jewellery_CAOpts')?.codes));
        setCellularPhoneDeductibleList(mapDeductibleList(deductibleCompleteList.filters.find((item) => item.name === 'Cellular_CAOPts')?.codes));
    }

    useEffect(() => {
        setScheduledItemType(_.get(scheduledItemVM, 'scheduledItemTypeCode.value.code', ''));
        fillDeductibleAvailableValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getScheduleTypeFilterNameForPersonalArticleCoverage = useCallback((jurisdiction) => {
        switch (jurisdiction) {
            case HOConstants.ho_AB:
            case HOConstants.ho_YT:
                return "ABYTPersonalArticleCovTypes_WMIC"
            case HOConstants.ho_BC:
                return "BCPersonalArticleCovTypes_WMIC"
            case HOConstants.ho_MB:
                return "MBPersonalArticleCovTypes_WMIC"
            case HOConstants.ho_ON:
            case HOConstants.ho_SEO:
                return "ONSEOPersonalArticleCovTypes_WMIC"
            case HOConstants.ho_NWO:
                return "NWOPersonalArticleCovTypes_WMIC"
            case HOConstants.ho_NB:
            case HOConstants.ho_NS:
            case HOConstants.ho_PE:
                return "NBNSPEPersonalArticleCovTypes_WMIC"
            case HOConstants.ho_QC:
                return "QCPersonalArticleCovTypes_WMIC"
            default:
                return "DefaultPersonalArticleCovTypes_WMIC"
        }
    }, []);

    const getScheduleTypeFilterNameForMotorVehicleCoverage = useCallback((jurisdiction) => {
        switch (jurisdiction) {
            case HOConstants.ho_MB:
                return 'MBMotorVehicleCovTypes_WMIC';
            case HOConstants.ho_SK:
                return 'SKMotorVehicleCovTypes_WMIC';
            case HOConstants.ho_BC:
                return 'BCMotorVehicleCovTypes_WMIC';
            case HOConstants.ho_ON:
            case HOConstants.ho_SEO:
            case HOConstants.ho_NWO:
                return 'NWOSEOMotorVehicleCovTypes_WMIC';
            case HOConstants.ho_NS:
            case HOConstants.ho_NB:
            case HOConstants.ho_PE:
                return 'NSNBPEMotorVehicleCovTypes_WMIC';
            case HOConstants.ho_QC:
                return 'QCMotorVehicleCovTypes_WMIC';
            default:
                return 'MotorVehicleCovTypes_WMIC';
        }
    }, []);

    const filterPersonalArticleSubtypes = useCallback((scheduledItemSubtypeCodes) => {
        const baseState = _.get(jobVM, 'baseData.baseState.value.code', '');
        const rateAsOfDate = _.get(jobVM, 'baseData.rateAsOfDate.value', '');
        if (WMICRPCUtil.getIsRPCEffectiveForRiskType(baseState, rateAsOfDate, null, '1378')) {
            scheduledItemSubtypeCodes.push('LuggageHandBagsFootwearCA');
        }
        return scheduledItemSubtypeCodes;
    }, [jobVM]);

    const filterMotorVehicleSubtypes = useCallback((scheduledItemSubtypeCodes) => {
        const baseState = _.get(jobVM, 'baseData.baseState.value.code', '');
        const rateAsOfDate = _.get(jobVM, 'baseData.rateAsOfDate.value', '');
        if (baseState === HOConstants.ho_MB && WMICRPCUtil.getIsRPCEffective(baseState, rateAsOfDate, '1671')) {
            scheduledItemSubtypeCodes = scheduledItemSubtypeCodes.filter((item) => {
                return item !== 'MV_VehiclesInStorage_CA_WMIC';
            });
        }
        return scheduledItemSubtypeCodes;
    }, [jobVM]);

    const getItemSubTypeCodeFilterName = useCallback((siType, baseState) => {
        switch (siType) {
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.COMPUTER:
                return baseState === HOConstants.ho_QC ? "QCComputerItems_wmic" : "CPUCovTypes_WMIC"
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.HORSE:
                return "HorseEquipmentTypes_WMIC"
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MOTORIZED_VEHICLE:
                return getScheduleTypeFilterNameForMotorVehicleCoverage(baseState);
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.PERSONAL_ARTICLES:
                return getScheduleTypeFilterNameForPersonalArticleCoverage(baseState);
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.SPORTS_EQUIPMENT:
                return "SportEquipCovTypes_WMIC"
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MISCELLANEOUS:
                return "MiscellaneousCovTypes_WMIC"
            default:
                return "";
        }
    }, [getScheduleTypeFilterNameForMotorVehicleCoverage, getScheduleTypeFilterNameForPersonalArticleCoverage]);

    const createItemSubTypeCodeList = useCallback((siType) => {
        const baseState = _.get(jobVM, 'baseData.baseState.value.code', '');
        const filterName = getItemSubTypeCodeFilterName(siType, baseState);
        const scheduledItemSubtypeFilter = viewModelService.productMetadata.get('pc').types.getTypelist('ScheduleType_HOE')
            .filters.find((filter) => filter.name === filterName);

        if (_.isUndefined(scheduledItemSubtypeFilter)) {
            switch (siType) {
                case 'HO_BicycleCov':
                    return ['Bicycle_CA_WMIC'];
                case 'HO_FineArtsCov':
                    return ['FineArts_CA_WMIC'];
                case 'HO_ToolsCov':
                    return ['Tools_CA_WMIC'];
                case 'HO_VacationTrailerCov':
                    return ['VacationTrailer_CA_WMIC'];
                default:
                    return [];
            }
        }
        let scheduledItemSubtypeCodes = scheduledItemSubtypeFilter.codes.map((code) => code.code);
        if (siType === CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MOTORIZED_VEHICLE) {
            scheduledItemSubtypeCodes = filterMotorVehicleSubtypes(scheduledItemSubtypeCodes);
        }
        if (siType === CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.PERSONAL_ARTICLES) {
            scheduledItemSubtypeCodes = filterPersonalArticleSubtypes(scheduledItemSubtypeCodes);
        }
        return scheduledItemSubtypeCodes;
    }, [filterMotorVehicleSubtypes, filterPersonalArticleSubtypes, getItemSubTypeCodeFilterName, jobVM, viewModelService.productMetadata]);


    const mapSubTypesAvailableValues = (subTypes) => {
        return subTypes.map((subType) => {
            return {
                ...subType,
                code: subType.code,
                displayName: {
                    id: subType.name,
                    defaultMessage: subType.name
                }
            }
        })
    }

    const getItemSubTypeList = useCallback((itemType) => {
        const typeList = createItemSubTypeCodeList(itemType);
        if (_.isEmpty(typeList)) {
            return;
        }
        const scheduledItemSubTypeValues = [];
        const subTypeAvailableValues = _.get(scheduledItemVM, 'scheduledItemSubType.aspects.availableValues', []);
        subTypeAvailableValues.forEach((subType) => {
            typeList.forEach((type) => {
                if (subType?.code === type) {
                    scheduledItemSubTypeValues.push(subType);
                }
            })
        });
        return mapSubTypesAvailableValues(scheduledItemSubTypeValues);
    }, [scheduledItemVM, createItemSubTypeCodeList]);

    const saveNewAdditionalInterest = useCallback((additionalInterest) => {
        const additionalInterests = _.get(scheduledItemVM, 'additionalInterests', {});
        additionalInterests.pushElement(additionalInterest);
        _.set(scheduledItemVM, 'additionalInterests.children', additionalInterests);
        setScheduledItemVM(scheduledItemVM);
        setNewAdditionalInterestVisible(false);
        setShowErrors(false);
    }, [scheduledItemVM, setScheduledItemVM, setShowErrors]);

    const removeAdditionalInterest = useCallback(async (additionalInterestIndex) => {
        const response = await showConfirm({
            title: messages.removeAIShort,
            message: messages.removeAILong,
        });
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const additionalInterests = _.get(scheduledItemVM, 'additionalInterests.value', []);
            additionalInterests.splice(additionalInterestIndex, 1);
            _.set(scheduledItemVM, 'additionalInterests.value', additionalInterests);
            setScheduledItemVM(scheduledItemVM);
            refreshData();
        }
    }, [refreshData, scheduledItemVM, setScheduledItemVM, showConfirm]);

    const createNewInsured = (subType) => {
        const currentDate = new Date();
        const minDate = {
            day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MAXIMUM_LEGAL_AGE,
            month: currentDate.getMonth(),
            year: currentDate.getFullYear()
        };
        const maxDate = {
            day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MINIMUM_LEGAL_AGE,
            month: currentDate.getMonth(),
            year: currentDate.getFullYear()
        };
        const contact = {
            subtype: subType,
            minDOB: minDate,
            maxDOB: maxDate,
            primaryAddress: { country: CONSTANTS.COUNTRY.CA },
            primaryPhoneType: CONSTANTS.PHONE_TYPES.NO_PHONE_WMIC
        };
        return contact;
    }

    const createAdditionalInterestVM = (subtype) => {
        const contact = createNewInsured(subtype);
        const additionalInterest = {
            additionalInterestCategory: HOConstants.ADDITIONAL_INTEREST_CATEGORY.LINE_ITEM,
            policyAdditionalInterest: viewModelService.create(
                contact,
                'pc',
                'wmic.edge.ca.capabilities.policycommon.accountcontact.dto.AccountContactDTO',
                jobVM.aspects.context()
            ).value,
            mailSeparateNotices: true,
            validateDescriptionOfInterest: true,
            validateAdditionalInterestType: true
        };
        const newVM = viewModelService.create(
            additionalInterest,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.AdditionalInterestDTO_WMIC',
            { ...scheduledItemVM.aspects.context(), ...jobVM.aspects.context() }
        );
        return newVM;
    }

    const onAddNewAdditionalInterest = (item) => {
        setNewAdditionalInterestVisible(true);
        const newAdditionalInterest = createAdditionalInterestVM(item);
        setAdditionalInterestVM(newAdditionalInterest);
        setShowErrors(false);
        setIsEditingAdditionalInterest(true)
    }

    const cancelAdditionalInterest = () => {
        setNewAdditionalInterestVisible(false);
        setShowErrors(false);
        setAdditionalInterestVM(undefined);
        setIsEditingAdditionalInterest(false)
    }

    const updateOptionalDeductibleValues = () => {
        return _.filter(deductibleCompleteList.codes, (d) =>  _.get(scheduledItemVM, 'optionalDeductibleValues.value', []).includes(d.code))
    }

    const getDeductibleAvailableValues = (itemType, itemSubtype) => {
        if (itemType === CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.VACATION_TRAILER) {
            return vacationTrailerDeductibleList;
        }
        if (itemType === CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.PERSONAL_ARTICLES
            && (itemSubtype === CONSTANTS.HO_SCHEDULED_ITEM_SUBTYPES.JEWELRY || itemSubtype?.code === CONSTANTS.HO_SCHEDULED_ITEM_SUBTYPES.JEWELRY)) {
            return jewelleryAndWatchesDeductibleList;
        }
        if (itemType === CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.PERSONAL_ARTICLES
            && (itemSubtype === CONSTANTS.HO_SCHEDULED_ITEM_SUBTYPES.CELL_PHONE || itemSubtype?.code === CONSTANTS.HO_SCHEDULED_ITEM_SUBTYPES.CELL_PHONE)) {
            return cellularPhoneDeductibleList;
        }
        if(itemType === CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MOTORIZED_VEHICLE && _.get(jobVM, 'baseData.baseState.value.code', '') === HOConstants.ho_SK) {
            return updateOptionalDeductibleValues()
        }
        return [];
    }

    const getDeductibleValue = (itemType) => {
        const baseState = _.get(jobVM, 'baseData.baseState.value.code', '');
        switch (itemType) {
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.BICYCLE:
                return '25';
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MOTORIZED_VEHICLE:
                return baseState === HOConstants.ho_SK ? '500' : '100';
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.HORSE:
                return '50';
            case CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.TOOLS:
                return '50';
            default:
                return '0';
        }
    }

    const mapDeductibleValues = (deductibleValues) => {
        return deductibleValues.map((deductible) => {
            return {
                code: deductible.code,
                displayName: {
                    id: deductible.name,
                    defaultMessage: deductible.code
                }
            }
        })
    }

    useEffect(() => {
        if (_.get(scheduledItemVM, 'optionalDeductibleValues.value.length') !== 0) {
            setDeductibleAvailableValues(mapDeductibleValues(updateOptionalDeductibleValues()))
        }
    }, [_.get(scheduledItemVM, 'optionalDeductibleValues.value')])

    const getDeductibleIfApplicable = (value, subTypeValue) => {
        const newDeductibleValue = getDeductibleValue(value);
        const newDeductibleAvailableValues = getDeductibleAvailableValues(value, subTypeValue);
        if (!_.isEmpty(newDeductibleValue) && _.isEmpty(newDeductibleAvailableValues)) {
            const newDeductibleDropdownValue = {
                code: newDeductibleValue,
                displayName: {
                    id: newDeductibleValue,
                    defaultMessage: newDeductibleValue
                }
            }
            setDeductibleAvailableValues([newDeductibleDropdownValue]);
        }
        else {
            setDeductibleAvailableValues(mapDeductibleValues(newDeductibleAvailableValues));
        }
        if (!_.isEmpty(newDeductibleValue)) {
            return parseInt(newDeductibleValue.replace(/\D/g, ''))
        }
        return null
    }

    const getSubTypeIfApplicable = (siType, newScheduledItemVM) => {
        let subTypeCode;
        if (siType === 'HO_BicycleCov') subTypeCode = 'Bicycle_CA_WMIC';
        else if (siType === 'HO_FineArtsCov') subTypeCode = 'FineArts_CA_WMIC';
        else if (siType === 'HO_ToolsCov') subTypeCode = 'Tools_CA_WMIC';
        else if (siType === 'HO_VacationTrailerCov') subTypeCode = 'VacationTrailer_CA_WMIC';
        else return {}

        const scheduledItemSubTypeValues = [];
        const subTypeAvailableValues = _.get(newScheduledItemVM, 'scheduledItemSubType.aspects.availableValues', []);
        subTypeAvailableValues.forEach((subType) => {
            if (subType?.code === subTypeCode) {
                scheduledItemSubTypeValues.push(subType);
            }
        })
        if (scheduledItemSubTypeValues.length === 1) {
            return scheduledItemSubTypeValues[0];
        }
        return {};
    }

    const onDeductibleChange = (value) => {
        _.set(scheduledItemVM, 'deductible', parseInt(value.replace(/\D/g, '')));
        setScheduledItemVM(scheduledItemVM);
    }

    const onScheduledItemSubtypeChange = (value) => {
        _.set(scheduledItemVM, 'scheduledItemSubType', value);
        const deductible = getDeductibleIfApplicable(_.get(scheduledItemVM, 'scheduledItemTypeCode.value.code', ''), value);
        if (!_.isNil(deductible)) {
            _.set(scheduledItemVM, 'deductible', deductible);
        }
        setScheduledItemVM(scheduledItemVM);
    }

    const onScheduledItemTypeChange = (value) => {
        setScheduledItemType(value);
        _.set(scheduledItemVM, 'scheduledItemTypeCode', value);

        const subType = getSubTypeIfApplicable(value, scheduledItemVM);
        if (!_.isEmpty(subType)) {
            _.set(scheduledItemVM, 'scheduledItemSubType', subType);
        }
        const deductible = getDeductibleIfApplicable(value, _.get(scheduledItemVM, 'scheduledItemSubType.value.code', ''));
        if (!_.isNil(deductible)) {
            _.set(scheduledItemVM, 'deductible', deductible);
        }

        const itemSubTypeList = getItemSubTypeList(value);
        setScheduledItemSubTypeList(itemSubTypeList);
        setScheduledItemVM(scheduledItemVM);
    }

    const getExistingAIDisplayName = (existingAI) => {
        const firstName = _.get(existingAI, 'policyAdditionalInterest.firstName.value', '');
        const lastName = _.get(existingAI, 'policyAdditionalInterest.lastName.value', '');
        if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Person
            && (!_.isEmpty(firstName) || !_.isEmpty(lastName))) {
            return `${firstName} ${lastName}`;
        }
        const contactName = _.get(existingAI, 'policyAdditionalInterest.contactName.value', '');
        if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Company
            && !_.isEmpty(contactName)) {
            return contactName;
        }
        return '';
    }

    const isMotorizedVehicleIncludeLiabilityVisible = () => {
        const rpc1348 = WMICRPCUtil.getIsRPCEffective(
            _.get(jobVM, 'baseData.baseState.value.code', ''),
            _.get(jobVM, 'baseData.rateAsOfDate.value', ''),
            '1348'
        );
        return rpc1348
            && _.get(scheduledItemVM, 'scheduledItemSubType.value.code', '') === CONSTANTS.HO_SCHEDULED_ITEM_SUBTYPES.RPC_1348_ATV;
    }

    const hasAnyPostalCodeStartingWithForGivenState = (startingWith, state) => {
        const found = (address) => address.postalCode.startsWith(startingWith) && address.state === state;
        return _.get(jobVM, 'baseData.accountAddresses_WMIC.value').some(found);
    };

    const isBikesScootsATVIncludeLiabilityVisible = () => {
        const type = _.get(scheduledItemVM, 'scheduledItemSubType.value.code');
        return type === HOConstants.bikesScootersATVsCode && hasAnyPostalCodeStartingWithForGivenState(HOConstants.saskLloydminsterFSA, HOConstants.ho_AB);
    };

    const isOptionalLiabilityCoveragesVisible = () => {
        return isMotorizedVehicleIncludeLiabilityVisible() || isBikesScootsATVIncludeLiabilityVisible();
    }

    const setSelectedIndex = useCallback((index) => {
        _setSelectedIndex(index);
        if (isPolicyView) {
            _setAdditionalInterestVM(_.get(scheduledItemVM, `additionalInterests.children[${index}]`, {}));
            setNewAdditionalInterestVisible(true);
        }
    });

    const isDeductibleReadOnly = () => {
        let isDeductibleReadOnly = deductibleAvailableValues.length === 1 || pReadOnly || !isEditing;

        if(_.get(scheduledItemVM, 'scheduledItemTypeCode.value.code', '') === CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MOTORIZED_VEHICLE) {
            const isReadOnlyForJurisdiction = _.get(jobVM, 'baseData.baseState.value.code', '') !== HOConstants.ho_SK;
            isDeductibleReadOnly = isDeductibleReadOnly || isReadOnlyForJurisdiction;
        }

        return isDeductibleReadOnly;
    };

    const overrideProps = {
        '@field': {
            parentNode: scheduledItemVM,
            readOnly: pReadOnly || !isEditing,
            showRequired: true
        },
        scheduledItemType: {
            onValueChange: onScheduledItemTypeChange
        },
        itemTypeDefinedContainer: {
            visible: !_.isEmpty(scheduledItemType)
        },
        scheduledItemSubType: {
            onValueChange: onScheduledItemSubtypeChange,
            availableValues: getItemSubTypeList(_.get(scheduledItemVM, 'scheduledItemTypeCode.value.code', ''))
        },
        scheduledItemDescription: {
            required: _.get(scheduledItemVM, 'description.aspects.required', false),
            maxLength: 2000,
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP
        },
        scheduledItemHorsepower: {
            secondaryLabel: _.get(scheduledItemVM, 'horsepower.value') ? '' : translator(messages.wmicHorsePowerMandatoryMsg)
        },
        scheduledItemDeductible: {
            onValueChange: onDeductibleChange,
            availableValues: deductibleAvailableValues,
            visible: _.get(scheduledItemVM, 'deductible.aspects.visible', false) && deductibleAvailableValues.length > 0,
            required: _.get(scheduledItemVM, 'deductible.aspects.required', false),
            readOnly: isDeductibleReadOnly(),
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP
        },
        optionalLiabilityCoveragesContainer: {
            visible: isOptionalLiabilityCoveragesVisible()
        },
        additionalInterestsContainer: {
            visible: !_.isEmpty(scheduledItemType)
        },
        additionalInterestsDataList: {
            VMList: _.get(scheduledItemVM, 'additionalInterests.children', []),
            VMData: [
                {
                    headerText: "Name",
                    getData: getExistingAIDisplayName
                },
                {
                    headerText: "Additional Interest Type",
                    path: 'additionalInterestType'
                }
            ],
            onRemoveAction: removeAdditionalInterest,
            selectedCardIndex: selectedIndex,
            updateSelectedCardIndex: setSelectedIndex,
            isEditing: isNewAdditionalInterestVisible,
            readOnly: pReadOnly || !isEditing,
            onClickAction: (data) => setAdditionalInterestVM(data)
        },
        addNewAdditionalInterestButton: {
            onItemClick: onAddNewAdditionalInterest,
            items: [
                { id: 'newPerson', text: translator(commonMessages.additionalInterestsSubtypePerson), icon: 'mi-person', code: CONSTANTS.Person },
                { id: 'newCompany', text: translator(commonMessages.additionalInterestsSubtypeCompany), icon: 'mi-home', code: CONSTANTS.Company },
            ],
            disabled: isNewAdditionalInterestVisible || !isComponentValid || pReadOnly,
            visible: !isPolicyView && !pReadOnly && isEditing,
        },
        newAdditionalInterestContainer: {
            visible: isNewAdditionalInterestVisible || !_.isUndefined(additionalInterestVM)
        },
        additionalInterestComponent: {
            additionalInterestVM,
            setAdditionalInterestVM,
            saveNewAdditionalInterest,
            cancelAdditionalInterest,
            showErrors,
            setShowErrors,
            onValidate: setComponentValidation,
            jobVM,
            readOnly: isPolicyView || !isNewAdditionalInterestVisible,
            isPolicyView
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICAdditionalInterestComponent,
            TextAreaField
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={scheduledItemVM}
            onModelChange={setScheduledItemVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );

}

WMICScheduledItemComponent.defaultProps = {
    isPolicyView: false
};

export default WMICScheduledItemComponent;
