import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Grid, GridItem } from '@jutro/layout';
import { useMediaQuery } from 'react-responsive';
import { CONSTANTS, DateUtil } from 'wmic-portals-utils-js';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';
import metadata from './WMICDriversInfoBox.metadata.json5';
import messages from './WMICDriversInfoBox.messages';
import styles from './WMICDriversInfoBox.module.scss';

const WMICDriversInfoBox = (props) => {
    const { policyDrivers } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [accordionOpenID, setAccordionOpenID] = useState(undefined);

    const getDriversByDriverType = (drivers, type) => _.filter(drivers, (d) => d.assignmentType_WMIC === type);

    const renderDriverList = (drivers) => {
        const gridItems = _.reduce(drivers, (result, driver) => {
            const fragment = (
                <GridItem tag="div" className={styles['driver-row-value']}>
                    { driver.displayName }
                    <br />
                    { translator(messages.dob) }
                    { driver.dateOfBirth === undefined ? 'N/A' : intl.formatDate(DateUtil.localDateDTOToDate(driver.dateOfBirth), { year: 'numeric', month: '2-digit', day: '2-digit' }) }
                </GridItem>
            );
            result.push(fragment);
            return result;
        }, []);
        return (
            <Grid
                columns={['1fr']}
                gap="medium"
                justifyItems="left"
            >
                {gridItems}
            </Grid>
        );
    };

    const getMessageWithHeader = (msg) => <h2 className={styles.subTitle}>{msg}</h2>;

    const drivers = {
        assigned: getDriversByDriverType(policyDrivers, CONSTANTS.DRIVER_TYPES.ASSIGNED),
        unassigned: getDriversByDriverType(policyDrivers, CONSTANTS.DRIVER_TYPES.UNASSIGNED),
        excluded: getDriversByDriverType(policyDrivers, CONSTANTS.DRIVER_TYPES.EXCLUDED)
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent
        }
    };

    const overrides = {
        cardDrivers: {
            title: getMessageWithHeader(translator(messages.drivers))
        },
        assignedDriversGrid: {
            visible: drivers.assigned.length > 0
        },
        assignedDriversTooltip: {
            tooltipMessage: translator(messages.assignedDriversTooltip)
        },
        assignedDriversValue: {
            content: renderDriverList(drivers.assigned)
        },
        unassignedDriversGrid: {
            visible: drivers.unassigned.length > 0
        },
        unassignedDriversTooltip: {
            tooltipMessage: translator(messages.unassignedDriversTooltip)
        },
        unassignedDriversValue: {
            content: renderDriverList(drivers.unassigned)
        },
        excludedDriversGrid: {
            visible: drivers.excluded.length > 0
        },
        excludedDriversTooltip: {
            tooltipMessage: translator(messages.excludedDriversTooltip)
        },
        excludedDriversValue: {
            content: renderDriverList(drivers.excluded)
        },
        driversInfoBoxContainerMobile: {
            visible: isMobile
        },
        driversInfoBoxContainer: {
            visible: !isMobile
        },
        Accordion: {
            accordionStates: accordionOpenID,
            onUpdateAccordionStates: (openCardID) => {setAccordionOpenID(openCardID)} 
        },

    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={policyDrivers}
        />
    );
};

WMICDriversInfoBox.propTypes = {
    policyDrivers: PropTypes.shape({})
};

WMICDriversInfoBox.defaultProps = {
    policyDrivers: []
};

export default WMICDriversInfoBox;
