import { defineMessages } from 'react-intl';

export default defineMessages({
    successHeader: {
        id: 'wmic.policy-change.component.success-policy-change.Thank you for submitting your policy change',
        defaultMessage: 'Thank you for submitting your policy change'
    },
    successSubtitle: {
        id: 'wmic.policy-change.component.success-policy-change.Your change is being processed',
        defaultMessage: 'Your change is being processed'
    },
    successBody: {
        id: 'wmic.policy-change.component.success-policy-change.Please note, changes can take...',
        defaultMessage: 'This policy change must be processed by an agent.  Please note, changes can take a few business days to complete.'
    },
    goToPolicyDetails: {
        id: 'wmic.policy-change.component.success-policy-change.Return to Policy Details',
        defaultMessage: 'Return to Policy Details'
    },
    ifYouNeedAssistanceWithText: {
        id: 'wmic.policy-change.component.success-policy-change.If you need assistance with making this policy change...',
        defaultMessage: 'If you need assistance with making this policy change, please call us at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;. Our customer service team is available Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30 pm PT.'
    },
});