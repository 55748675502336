import React, { useState, useContext, useCallback, useMemo } from 'react';
import { IntlContext } from '@jutro/locale';
import cx from 'classnames';
import styles from './WMICCurrencyField.module.scss';
import { InputField } from '@jutro/legacy/components';

function WMICCurrencyField(props) {
    const { value, int } = props;
    const intl = useContext(IntlContext);

    const nondigitRe = useMemo(() => /[^\d]/g, []);

    const format = useCallback((number) => {
        return intl.formatNumber(number, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }, [intl]);

    // Logic taken from old angular currency field implementation
    const parse = useCallback((str, isInt) => {
        if (!str) {
            return 0;
        }
        // Get example format and extract decimal separator
        const example = format(10000000000.11);
        const decimalSeparator = (example.match(nondigitRe) || []).pop();

        let val = str.trim();
        if (val.charAt(0) === decimalSeparator) {
            val = `0${val}`; // Starting with a fraction separator, prepend with zero
        }

        // Split into whole numbers and decimals
        const digitGroups = val.split(decimalSeparator);
        let decimals = digitGroups.pop();
        if (decimals && digitGroups.length === 0) {
            // If no numbers left then it's not a fraction we've got. Push it back
            digitGroups.push(decimals);
            decimals = null;
        }

        // Compose the float back
        let compiledFloat = digitGroups.join('').replace(nondigitRe, '');
        if (decimals) {
            compiledFloat = `${compiledFloat}.${decimals}`;
        }

        const parsedValue = isInt ? parseInt(compiledFloat, 10) : parseFloat(compiledFloat);

        return !Number.isNaN(parsedValue) ? parsedValue : 0;
    }, [format, nondigitRe]);

    const [displayValue, setDisplayValue] = useState(format(parse(String(value.amount), int)));

    const onValueChange = (newValue) => {
        let amount = "";
        if (newValue !== "") {
            newValue = newValue.replace(/^(0|\.)+/, ''); // remove 0 and . from the line start
            amount = newValue.match(/^-?\d{0,4}(?:\.\d{0,2})?/)[0]
        }
        props.onValueChange({ amount });
        setDisplayValue(amount);
    };

    const onFocus = ({ target }) => {
        const number = parse(target.value, int); // remove mask
        const clearValue = number > 0 ? `${number}` : '';
        setDisplayValue(clearValue);
    };

    const onBlur = ({ target }) => {
        const number = parse(target.value, int);
        setDisplayValue(format(number));
        if (props.onBlur) {
            props.onBlur();
        }
    };

    return (
        <div className={cx(styles.wmicCurrencyWrapper)}>
            <div className={styles.wmicCurrencySymbol}>
                <span>$</span>
            </div>
            <InputField
                {...props}
                onBlur={onBlur}
                onFocus={onFocus}
                value={displayValue}
                onValueChange={onValueChange}
            />
        </div>
    );
}

WMICCurrencyField.propTypes = InputField.propTypes;
WMICCurrencyField.defaultProps = InputField.defaultProps;

export default WMICCurrencyField;