import _ from 'lodash';

function WMICCoverageComparisonVM(data) {
    if (data) {
        _.extend(this, data);
    } else {
        this.coveragePublicId = '';
        this.coverageTermPatternCode = '';
        this.coverageName = '';
        this.originalCoverageTermValue = '';
        this.newCoverageTermValue = '';
        this.newlyUpdated = '';
    }
}

export default WMICCoverageComparisonVM;