import React, { useCallback } from 'react';
import createHOC from 'react-create-hoc';
import CloudAuthenticationContext from './CloudAuthenticationContext';
import { AuthProvider } from '@jutro/auth';
import { useHistory } from 'react-router-dom';

/**
 * Creates a content wrapped with CloudAuthenticationContext
 *
 * @function withAuthenticationProvider
 * @memberof module:gw-digital-auth-react
 *
 * @param {ReactElement} ComponentToWrap component to be wrapped with AuthContestProvider
 * @param {Object} authProps authorisation passed to the CloudAuthenticationContext
 * @see {@link module:gw-digital-auth-react.CloudAuthenticationContext} for more information
 *
 * @returns {ReactElement} the content wrapped with CloudAuthenticationContext
 */
const withAuthenticationProvider = createHOC('withAuthenticationProvider', (ComponentToWrap, authProps) => function WithAuthenticationProvider(props) {        
        const history = useHistory();

        const onLocationChange = useCallback(newPath => {
            history.replace(newPath)
        },[history]);

        return (
            <AuthProvider onLocationChange={onLocationChange}>
                <CloudAuthenticationContext {...authProps}>
                    <ComponentToWrap {...props} />
                </CloudAuthenticationContext>
            </AuthProvider>
        );
},
{ factory: true, allowExtraProps: true });

export default withAuthenticationProvider;
