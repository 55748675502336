// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ol.app__WMICIOSStepsModal__wmic-ol{margin-bottom:0}h3.app__WMICIOSStepsModal__ww-editorial-subhead{font-size:1.6rem;font-weight:500;line-height:1.1;margin-bottom:2rem}.app__WMICIOSStepsModal__digital-wallet li{margin-bottom:1.2rem}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policy-react/WMICInsuranceCardModal/WMICIOSStepsModal/WMICIOSStepsModal.module.scss"],"names":[],"mappings":"AAIA,mCACE,eAAA,CAGF,gDAEE,gBAAA,CADA,eAAA,CAGA,eAAA,CADA,kBACA,CAGF,2CACE,oBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n\nol.wmic-ol {\n  margin-bottom: 0rem;\n}\n\nh3.ww-editorial-subhead {\n  font-weight: 500;\n  font-size: 1.6rem;\n  margin-bottom: 2rem;\n  line-height: 1.1;\n}\n\n.digital-wallet li {\n  margin-bottom: 1.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-ol": "app__WMICIOSStepsModal__wmic-ol",
	"ww-editorial-subhead": "app__WMICIOSStepsModal__ww-editorial-subhead",
	"digital-wallet": "app__WMICIOSStepsModal__digital-wallet"
};
export default ___CSS_LOADER_EXPORT___;
