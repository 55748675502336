import { defineMessages } from 'react-intl';

export default defineMessages({
    riskNumber: {
        id: "quoteandbind.ho.views.construction.RiskTable.Risk Number",
        defaultMessage: "Risk #"
    },
    riskPrimary: {
        id: "quoteandbind.ho.views.construction.RiskTable.Primary",
        defaultMessage: "Primary"
    },
    riskDescription: {
        id: "quoteandbind.ho.views.construction.RiskTable.Description",
        defaultMessage: "Description"
    },
    riskType: {
        id: "quoteandbind.ho.views.construction.RiskTable.Risk Type",
        defaultMessage: "Risk Type"
    },
    saveConstruction: {
        id: 'quoteandbind.ho.views.construction.Save',
        defaultMessage: 'Save Construction'
    },
    cancelConstructionEdit: {
        id: 'quoteandbind.ho.views.construction.Cancel Edit',
        defaultMessage: 'Cancel Edit'
    },
    savingConstruction: {
        id: 'quoteandbind.ho.views.construction.Saving Construction',
        defaultMessage: 'Saving Construction'
    },
    validationKitchenError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Modal.Error.Header',
        defaultMessage: 'Please address the following errors for kitchens / bathrooms :',
    }

});
