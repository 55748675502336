// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPEHOWizard__PEHOWizardContainer [class*=clauseAndTermSpacing] label{color:var(--GW-COLOR-WARNING-CONTRAST);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-quoteandbind-ho-react/WMICPEHOWizard.module.scss"],"names":[],"mappings":"AACI,8EAEI,sCAAA,CADA,2CACA","sourcesContent":[".PEHOWizardContainer {\n    [class*='clauseAndTermSpacing'] label {\n        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);\n        color: var(--GW-COLOR-WARNING-CONTRAST);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PEHOWizardContainer": "app__WMICPEHOWizard__PEHOWizardContainer"
};
export default ___CSS_LOADER_EXPORT___;
