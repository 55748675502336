// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICOverduePolicies__list>:last-child{margin-bottom:2.5rem}", "",{"version":3,"sources":["webpack://./../common/modules-react/wmic-components-amp-common-react/WMICOverduePolicies/WMICOverduePolicies.module.scss"],"names":[],"mappings":"AAAA,4CACE,oBAAA","sourcesContent":[".list > :last-child {\n  margin-bottom: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "app__WMICOverduePolicies__list"
};
export default ___CSS_LOADER_EXPORT___;
