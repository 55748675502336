import {UsersProfileDetailsService} from "gw-capability-profileinfo";

const getAccountContact = async (authHeader, authUserData) => {
    let accountContactSummaries = await UsersProfileDetailsService.getAccountsContactSummaries(
        authHeader
    );
    let accountContactSummary;
    let accountNumber;
    for (let index = 0; index < accountContactSummaries.length; index++) {
        const emailMatches = accountContactSummaries[index].namedInsureds.filter(contact => contact?.emailAddress1?.toLowerCase() === authUserData?.email?.toLowerCase());
        if (emailMatches.length === 1) {
            accountContactSummary = emailMatches[0];
            accountNumber = accountContactSummaries[index].accountNumber;
            break;
        } else if (emailMatches.length > 1) {
            // more than one matching email found, narrow next search to email matches
            accountContactSummaries = accountContactSummaries.filter(
                summary => summary.namedInsureds.some(
                    insured => insured?.emailAddress1?.toLowerCase() === authUserData?.email?.toLowerCase()
                )
            )
        }
    }
    if (!accountContactSummary) {
        for (let index = 0; index < accountContactSummaries.length; index++) {
            // zero or more than one matching email found, try matching on name
            const nameMatches = accountContactSummaries[index].namedInsureds.filter(contact =>
                contact?.firstName?.toLowerCase() === authUserData?.firstName?.toLowerCase() && contact?.lastName?.toLowerCase() === authUserData?.lastName?.toLowerCase())
            if (nameMatches.length === 1) {
                accountContactSummary = nameMatches[0];
                accountNumber = accountContactSummaries[index].accountNumber
                break;

            } else if (nameMatches.length > 1) {
                // Multiple matches, pick the most recently updated contact as sorted by
                // UsersProfileDetailsService.getAccountsContactSummaries
                accountContactSummary = accountContactSummaries[index].accountContact;
                accountNumber = accountContactSummaries[index].accountNumber
                break;
            }
        }
    }
    return accountContactSummary === undefined ? undefined :  {
        'accountContact': accountContactSummary,
        'accountNumber': accountNumber
    }
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
}

export  default {
    getAccountContact,
    scrollToTop
}