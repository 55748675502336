import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import VehicleModel from './VehicleModel';
import AdditionalInterestInfoModel from './AdditionalInterestInfoModel';
import messages from './Models.messages';

export default class FinanceLeasingModel {
    constructor({ changeType, vehicle, companyInfo }) {
        this.changeType = changeType;
        this.vehicle = vehicle;
        this.companyInfo = companyInfo;
    }

    getRequestType() {
        return `I would like to ${this.changeType} the Finance or Leasing Company information for the following vehicle:`;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.changeTypeVehicleCompanyInfoRequired);
        }

        return `
${this.getRequestType()}
${this.vehicle.toFullString()}${this.getCompanyInfo()}
---

`;
    }

    toHTML() {
        if (CONSTANTS.CHANGE_TYPE_DELETE === this.changeType) {
            return `<span>
            <b>Company Name: </b>${this.companyInfo.name}<br/>
            ${this.vehicle.toHTML()}
            </span>`;
        }

        return `<span>
${this.vehicle.toHTML()}
${!this.companyInfo ? '' : this.companyInfo.toHTML()}
</span>`;
    }

    getCompanyInfo() {
        if (CONSTANTS.CHANGE_TYPE_DELETE === this.changeType) return '';
        return `

Finance / Leasing Company information:
${this.companyInfo}`;
    }

    valid() {
        if (!this.changeType || !this.vehicle || !this.vehicle.valid()) return false;

        // For Add and Change, the designatePerson should be included
        if ([
            CONSTANTS.CHANGE_TYPE_ADD,
            CONSTANTS.CHANGE_TYPE_CHANGE,
            CONSTANTS.CHANGE_TYPE_SEND
        ].includes(this.changeType)) {
            return this.companyInfo.valid();
        }

        return true;
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangeFinanceLeasingCompanyRequest);
    }

    static toObjectModel(obj) {
        const model = Object.setPrototypeOf(obj, FinanceLeasingModel.prototype);
        model.vehicle = Object.setPrototypeOf(obj.vehicle, VehicleModel.prototype);
        model.companyInfo = obj.companyInfo
            ? AdditionalInterestInfoModel.toObjectModel(obj.companyInfo) : undefined;

        return model;
    }
}
