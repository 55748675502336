import { defineMessages } from 'react-intl';

export default defineMessages({
    insuranceIDCard: {
        id: 'portal.views.android-steps-modal.Insurance ID Card',
        defaultMessage: 'Insurance ID Card'
    },
    followTheseSteps: {
        id: 'portal.views.android-steps-modal.Follow these steps to download the ID card to Google Pay.',
        defaultMessage: 'Follow these steps to download the ID card to Google Pay.'
    },
    usingYourAndroidDevice: {
        id: 'portal.views.android-steps-modal.Using your Android device Download Google Pay for Android.',
        defaultMessage: 'Using your Android device Download Google Pay for Android.'
    },
    onceGooglePayInstalled: {
        id: 'portal.views.android-steps-modal.Once Google Pay is installed on your Android device',
        defaultMessage: 'Once Google Pay is installed on your Android device select your vehicle from the list below and use the button beneath the title to download your digital ID Card.'
    },
    theCardIsNowAdded: {
        id: 'portal.views.android-steps-modal.The card is now added to Google Pay',
        defaultMessage: 'The card is now added to Google Pay. It will be available in the Google Pay app with or without internet access. If you make changes to your policy or your policy renews you will need to re-download the ID Card.'
    },
    selectTheVehicle: {
        id: 'portal.views.android-steps-modal.Select the vehicle you want an ID card for.',
        defaultMessage: 'Select the vehicle you want an ID card for.'
    },
    download: {
        id: 'portal.views.android-steps-modal.Download',
        defaultMessage: 'Download {year} {make} {model} ({vehicleNumber})'
    },
    downloadAndroidIdCardTrackButtonIdentifier: {
        id: 'portal.views.android-steps-modal.tracking.Download',
        defaultMessage: 'Download Android ID Card'
    },
    downloadGooglePayTrackButtonIdentifier: {
        id: 'portal.views.android-steps-modal.tracking.Download Google Pay',
        defaultMessage: 'Download Google Pay'
    },
    close: {
        id: 'portal.views.android-steps-modal.Close',
        defaultMessage: 'Close'
    }
});
