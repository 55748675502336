import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './VehicleCoverageTable.metadata.json5';
import './VehicleCoverageTable.messages';

const getCell = (items, index, property) => {
    return !_.isObject(items[property.id]) ? items[property.id] : null;
};

const getCurrencyCell = (items, index, property) => {
    return (
        <CurrencyField
            id={`currency_${index}`}
            value={items[property.id]}
            readOnly
            hideLabel
            showOptional={false}
        />
    );
};

function VehicleCoverageTable({ data }) {
    const overrides = {
        vehicleCoverageTable: {
            data: data
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getCellCoverage: getCell,
            getCellCurrencyCoverage: getCurrencyCell
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

VehicleCoverageTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}))
};

VehicleCoverageTable.defaultProps = {
    data: []
};
export default VehicleCoverageTable;
