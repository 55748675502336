/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { InputField } from '@jutro/legacy/components';

function WMICCustomInput(props) {
    const [nameOfClass, setNameOfClass] = useState('wmicWizardInputLabel wmicInactive');
    const [isOnFocus, setIsOnFocus] = useState(false);

    useEffect(() => {
        if (isOnFocus) {
            setNameOfClass('wmicWizardInputLabel wmicActive');
        } else {
            setNameOfClass(`wmicWizardInputLabel ${_.isUndefined(props.value) ? 'wmicInactive' : 'wmicActiveNotFocused'}`);
        }
    }, [props, isOnFocus]);

    return (
        <InputField
            {...props}
            hideLabel={false}
            onFocus={(value) => {
                setIsOnFocus(true);
                if (props.onFocus) {
                    props.onFocus(value);
                }
            }
            }
            onBlur={(value) => {
                setIsOnFocus(false);
                if (props.onBlur) {
                    props.onBlur(value);
                }
            }
            }
            labelClassName={`${props.labelClassName} ${nameOfClass}`}
            labelContainerClassName='wmicWizardLabelContainer customLabel'
            nullable
            label={props.label}
        />
    );
}

WMICCustomInput.propTypes = InputField.propTypes;
WMICCustomInput.defaultProps = InputField.defaultProps;

export default WMICCustomInput;