import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
import WMICHOPolicyDetailsPage from './pages/PolicyDetails/WMICHOPolicyDetailsPage';
import WMICPolicyChangeHoRiskPage from './pages/Risks/WMICPolicyChangeHoRiskPage';
import WMICHOPolicyChangeConstructionPage from './pages/Construction/WMICHOPolicyChangeConstructionPage';
import CoveragesPage from './pages/Coverages/CoveragesPage';
import WMICHOPolicyChangeScheduledItemsPage from './pages/ScheduledItems/WMICHOPolicyChangeScheduledItemsPage';
import WMICHOPolicyChangeInsuranceHistoryPage from './pages/InsuranceHistory/WMICHOPolicyChangeInsuranceHistoryPage';
import './WMICPEHOPolicyChangeWizard.messages';

setComponentMapOverrides(
    {
        WMICHOPolicyDetailsPage: { component: 'WMICHOPolicyDetailsPage' },
        WMICPolicyChangeHoRiskPage: { component: 'WMICPolicyChangeHoRiskPage' },
        WMICHOPolicyChangeConstructionPage: { component: 'WMICHOPolicyChangeConstructionPage' },
        CoveragesPage: { component: 'CoveragesPage' },
        WMICHOPolicyChangeScheduledItemsPage: { component: 'WMICHOPolicyChangeScheduledItemsPage' },
        WMICHOPolicyChangeInsuranceHistoryPage: { component: 'WMICHOPolicyChangeInsuranceHistoryPage' },
    },
    {
        WMICHOPolicyDetailsPage,
        WMICPolicyChangeHoRiskPage,
        WMICHOPolicyChangeConstructionPage,
        CoveragesPage,
        WMICHOPolicyChangeScheduledItemsPage,
        WMICHOPolicyChangeInsuranceHistoryPage,
    }
);
// eslint-disable-next-line import/prefer-default-export
export { default as WMICPEHOPolicyChangeWizard } from './WMICPEHOPolicyChangeWizard';
export { default as WMICHOPolicyDetailsPage } from './pages/PolicyDetails/WMICHOPolicyDetailsPage';
export { default as WMICPolicyChangeHoRiskPage } from './pages/Risks/WMICPolicyChangeHoRiskPage';
export { default as WMICHOPolicyChangeConstructionPage } from './pages/Construction/WMICHOPolicyChangeConstructionPage';
export { default as CoveragesPage } from './pages/Coverages/CoveragesPage';
export { default as WMICHOPolicyChangeScheduledItemsPage } from './pages/ScheduledItems/WMICHOPolicyChangeScheduledItemsPage';
export { default as WMICHOPolicyChangeInsuranceHistoryPage } from './pages/InsuranceHistory/WMICHOPolicyChangeInsuranceHistoryPage';
export { default as messages } from './WMICPEHOPolicyChangeWizard.messages';
