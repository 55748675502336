import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { InactivityTimerService } from '@xengage/gw-portals-auth-inactivity-timer-js';
import { getConfigValue, DEFAULT_HTTP_TIMEOUT } from '@jutro/config';


const { serviceUrls } = appConfig;
const serviceBaseURL = serviceUrls ? serviceUrls.webpolicychange : '';

const httpTimeout = getConfigValue('httpSettings.timeout', DEFAULT_HTTP_TIMEOUT);
const baseHeaders = {
    'Content-Type': 'application/xml; charset=utf-8',
    'x-forwarded-host': serviceUrls.xForwardedHost
};

export default class WMICPolicyChangeService {
    constructor(props) {
        this.authHeader = props.authHeader;
    }

    static submitDataChange(body, authHeader) {
        if (_.isUndefined(body)) {
            return undefined;
        }

        const headers = _.cloneDeep(baseHeaders);
        const callStart = new Date().getTime();
        const endpoint = serviceBaseURL;
        headers.Authorization = authHeader.Authorization;
        headers.AuthorizationEdge = authHeader.AuthorizationEdge;

        const inactivityTimer = new InactivityTimerService({});
        inactivityTimer.resetInactivityTimer();

        return fetch(endpoint, {
            method: 'POST',
            headers,
            body
        }).then((response) => {
            return response.text();
        }).then((text) => {
            const data = (new window.DOMParser()).parseFromString(text, 'text/xml');
            const status = data.getElementsByTagName('a:Status')[0].innerHTML;
            if (status !== 'OK') {
                throw Error(`Unexpected status: ${status}`);
            }
            return true;
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            const callTime = new Date().getTime() - callStart;
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
                baseError: error,
                isTimeout: (error.status === 0) && callTime < httpTimeout
            });
        });
    }
}