// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPreparingQuoteLoader__wmic-loading-quote-title{font-size:2.5rem!important;font-weight:600!important}.app__WMICPreparingQuoteLoader__wmic-loading-quote-container{text-align:center}.app__WMICPreparingQuoteLoader__wmic-loader-div{padding:25px 0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policychange-common-react/components/WMICPreparingQuoteLoader/WMICPreparingQuoteLoader.module.scss"],"names":[],"mappings":"AAAA,yDAEI,0BAAA,CADA,yBACA,CAGJ,6DACI,iBAAA,CAGJ,gDACI,cAAA","sourcesContent":[".wmic-loading-quote-title {\n    font-weight: 600 !important;\n    font-size: 2.5rem !important;\n}\n\n.wmic-loading-quote-container {\n    text-align: center;\n}\n\n.wmic-loader-div {\n    padding: 25px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-loading-quote-title": "app__WMICPreparingQuoteLoader__wmic-loading-quote-title",
	"wmic-loading-quote-container": "app__WMICPreparingQuoteLoader__wmic-loading-quote-container",
	"wmic-loader-div": "app__WMICPreparingQuoteLoader__wmic-loader-div"
};
export default ___CSS_LOADER_EXPORT___;
