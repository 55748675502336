import React from 'react';
import { useTranslator } from '@jutro/locale';
import { WMICLoader } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';

import metadata from './WMICPreparingQuoteLoader.metadata.json5';
import messages from './WMICPreparingQuoteLoader.messages';
import styles from './WMICPreparingQuoteLoader.module.scss';


const WMICPreparingQuoteLoader = ({ hideCard }) => {
    const translator = useTranslator();

    const loaderContent = (
        <div className={styles['wmic-loading-quote-container']}>
            <h2 className={styles['wmic-loading-quote-title']}>
                {translator(messages.pleaseWait)}
            </h2>
            <div className={styles['wmic-loader-div']}>
                <WMICLoader isInline />
            </div>
            <p>
                { translator(messages.weRePreparingYourQuote) }
                <br />
                { translator(messages.pleaseWaitAndDontCloseYourBrowserOrLeaveThisPage) }
            </p>
        </div>
    )
    
    if (hideCard) {
        return loaderContent;
    }

    const overrideProps = {
        loadingQuoteCardContent: { content: loaderContent }
    }
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICPreparingQuoteLoader.propTypes = {
    hideCard: PropTypes.bool
};

export default WMICPreparingQuoteLoader;
