import { defineMessages } from 'react-intl';

export default defineMessages({
    AdjusterInformation: {
        id: 'wmic.component.adjuster-info-box-component.Adjuster Information',
        defaultMessage: 'Adjuster Information'
    },
    Name: {
        id: 'wmic.component.adjuster-info-box-component.Name',
        defaultMessage: 'Name'
    },
    NameAria: {
        id: 'wmic.component.adjuster-info-box-component.Name Aria',
        defaultMessage: 'Name {name}'
    },
    PhoneNumber: {
        id: 'wmic.component.adjuster-info-box-component.Phone Number',
        defaultMessage: 'Phone Number'
    },
    PhoneNumberAria: {
        id: 'wmic.component.adjuster-info-box-component.Phone Number Aria',
        defaultMessage: 'Phone Number {phoneNumber}'
    },
    EmailAddress: {
        id: 'wmic.component.adjuster-info-box-component.Email Address',
        defaultMessage: 'Email Address'
    },
    EmailAddressAria: {
        id: 'wmic.component.adjuster-info-box-component.Email Address Aria',
        defaultMessage: 'Email Address {email}'
    },
    ContactAbout: {
        id: 'wmic.component.adjuster-info-box-component.Contact About',
        defaultMessage: 'Contact About'
    },
    ContactAboutAria: {
        id: 'wmic.component.adjuster-info-box-component.Contact About Aria',
        defaultMessage: 'Contact About {adjusters}'
    },
});
