import React, { useState, useEffect, useContext, useCallback } from 'react';

import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICCard, WMICAccordionCard } from 'wmic-pe-components-platform-react';
import { formatNumber, formatCurrency } from '@jutro/components';
import { InputField } from '@jutro/legacy/components';
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import { DeviceBreakpoint, Position } from 'wmic-pe-portals-utils-js';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import _ from "lodash";

import messages from './WMICIterableFlexRiskItem.messages';
import metadata from './WMICIterableFlexRiskItem.metadata.json5';
import styles from './WMICIterableFlexRiskItem.module.scss';

const  WMICIterableFlexRiskItem = (props) => {

    const {
        id,
        flexVM,
        updateFlexVM,
        riskHeader,
        riskType,
        onValidate,
        showErrors,
        viewModelService,
        readOnly
    } = props

    const translator = useContext(TranslatorContext);
    const intl = useContext(IntlContext);
    const isFlexEligible = _.get(flexVM, 'isFlexEligible.value');
    const labelPosition = DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);

    const [ targetDollarDifferenceValue, setTargetDollarDifferenceValue ] = useState(0);
    const [ targetPercentDifferenceValue, setTargetPercentDifferenceValue ] = useState(0);

    const updateTargetDifferenceValues = useCallback(() => {
        const eligiblePremium = _.get(flexVM, 'flexEligiblePremium.value');
        const targetPremium = _.get(flexVM, 'flexTargetPremium.value');

        const dollarDiff = targetPremium - eligiblePremium;
        const percentDiff = dollarDiff / eligiblePremium;

        setTargetDollarDifferenceValue(formatCurrency(intl, {value: dollarDiff, showFractions: 2}));
        setTargetPercentDifferenceValue(formatNumber(intl, {value: percentDiff, format: 'percent'}));

    }, [flexVM, intl]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, flexVM, id]);

    useEffect(() => {
        updateTargetDifferenceValues();
    }, [updateTargetDifferenceValues])

    const updateFlexData = (newFlexDTO) => {
        updateFlexVM(viewModelService.clone(newFlexDTO));
        updateTargetDifferenceValues();
    };

    const eligiblePremiumValue = isFlexEligible?
        formatCurrency(intl, {
            value: _.get(flexVM, 'flexEligiblePremium.value', 0),
            showFractions: 2}
        ):
        translator(messages.inEligibleFlexPremium);

    const maxReductionAllowedValue = isFlexEligible?
        formatNumber(intl, {
            value: _.get(flexVM, 'maxFlexPercentageAllowed_WMIC.value', 0) * 0.01,
            format: 'percent'}
        ):
        translator(messages.flexNotApplicable)

    const overrideProps = {
        '@field': {
            parentNode: flexVM,
        },
        flexFormContainer: {
            visible: isFlexEligible,
            onValidate: onValidateAccordion,
        },
        eligiblePremiumField: {
            value: eligiblePremiumValue
        },
        maxReductionField: {
            value: maxReductionAllowedValue
        },
        targetDollarDifference: {
            value: targetDollarDifferenceValue
        },
        targetPercentDifference: {
            value: targetPercentDifferenceValue
        },
        flexErrorsContainer: {
            visible: !isFlexEligible
        },
        flexErrorsMessage: {
            content: _.get(flexVM, 'flexErrors.children').value ? translator(messages.flexErrorsMessageYes, {riskType}) :  translator(messages.flexErrorsMessageNo, {riskType})
        },
        flexErrorsList: {
            data: _.get(flexVM, 'flexErrors.children')
        },
        flexReason: {
            readOnly,
            labelPosition
        },
        flexReasonDescription: {
            readOnly,
            labelPosition,
            visible: _.get(flexVM, 'flexReasonDescription.aspects.ocular', false)
        },
        flexTargetPremium: {
            readOnly,
            labelPosition,
            visible: _.get(flexVM, 'flexTargetPremium.aspects.ocular', false)
        }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveClassNameMap: styles,
        resolveComponentMap: {
            InputField
        }
    }

    return (
        <WMICCard className={styles.flexRiskCard}>
            <WMICAccordionCard
                id={id}
                chevron
                className={styles.flexAccordionHeader}
                title={riskHeader}
                isValid={isComponentValid}
                showErrors={showErrors}
            >
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    model={flexVM}
                    onModelChange={updateFlexData}
                    onValidationChange={setComponentValidation}
                    showErrors={showErrors}
                />
            </WMICAccordionCard>
        </WMICCard>
    )
}

export default WMICIterableFlexRiskItem;
