import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { Flex, FlexItem } from '@jutro/layout';
import { useModal } from '@jutro/components';
import { PolicyService } from 'gw-capability-policy';
import { LobIconUtil } from 'wmic-portals-utils-js';
import styles from './WMICRcn.module.scss';
import messages from './WMICRcn.messages';
import WMICRcnModal from './WMICRcnModal';

const WMICRcn = ({ policyPeriodNumber, policyTermNumber, openModalOnRender, onClose, hideRcnCta}) => {
    const modalApi = useModal();
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();

    const triggerModal = useCallback(async () => {
        let policyDetails;
        if(policyPeriodNumber){
            policyDetails = await PolicyService.getAccountPolicyDetails_WMIC(policyPeriodNumber, policyTermNumber, authHeader, authUserData.firstName, authUserData.lastName);
            await modalApi.showModal(
                <WMICRcnModal
                    onClose={onClose}
                    policyDetails={policyDetails}
                    hideRcnCta={hideRcnCta}
                />
            );
        }
    }, [onClose]);

    useEffect(() => {
        if (openModalOnRender) {
            triggerModal();
        }
    }, [openModalOnRender, triggerModal]);


    const getIconClass = () => {
        const icon = LobIconUtil.getWmicIcon("PersonalAutoLine");
        return `fa ${icon} titleIconOrange`;
    }

    return (
        <section role='alert'>
            <button
                role='link'
                className={cx('ww-link-button', styles.card)}
                onClick={() => triggerModal()}
            >
                <Flex alignItems="center">
                    <FlexItem>
                        <i className={`${styles.icon} ${getIconClass()}`} />
                    </FlexItem>
                    <FlexItem textAlign="left">
                        <h2>
                            {translator(messages.yourAutoInsuranceIncreased)}
                        </h2>
                        {<div>
                            {translator(messages.policy, { policyNumber: policyPeriodNumber })}
                        </div>}
                    </FlexItem>
                </Flex>
            </button>
        </section>
    );
};

WMICRcn.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default WMICRcn;