import messages from './Models.messages';

export default class AddressInfoModel {
    constructor({
        street, unit, city, state, zip
    }) {
        this.street = street;
        this.unit = unit || '';
        this.city = city;
        this.state = state;
        this.zip = zip;
    }

    toHTML() {
        return `<span>
        Street: ${this.street} </br>
        Apt/Unit: ${this.unit} </br>
        City: ${this.city} - State: ${this.state} </br>
        Zip: ${this.zip} </br>
        </span>`;
    }

    toString(translator) {
        if (!this.valid()) {
            throw new Error(translator(messages.streetStateZipRequired));
        }

        return `Street: ${this.street}
Apt/Unit: ${this.unit}
City: ${this.city}
State: ${this.state}
Zip: ${this.zip}`;
    }

    valid() {
        const required = [this.street, this.city, this.state, this.zip];
        return required.every((v) => v);
    }

    static getMessageTitle(translator) {
        return translator(messages.ampChangeAddressRequest);
    }
}