// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__FileUploadViewerComponent__fileUpload{margin-bottom:var(--GW-LAYOUT-6)}.app__FileUploadViewerComponent__fileUpload [class*=OldFieldMessage]{display:none}.app__FileUploadViewerComponent__policyDocumentsTableNoPadding{padding:0}.app__FileUploadViewerComponent__fileTypeIcon{position:relative;top:var(--GW-SPACING-1)}.app__FileUploadViewerComponent__columnHeader{justify-content:center}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICDocuments/FileUploadViewerComponent/FileUploadViewerComponent.module.scss"],"names":[],"mappings":"AACA,4CACI,gCAAA,CAKA,qEACI,YAAA,CAIR,+DACI,SAAA,CAGJ,8CACI,iBAAA,CACA,uBAAA,CAGJ,8CACI,sBAAA","sourcesContent":["\n.fileUpload {\n    margin-bottom: var(--GW-LAYOUT-6);\n    \n    /* File upload is currently rendering errors twice, one of which is */\n    /* an \"old field message\" which is likely being deprecated. This hides the old message*/\n    /* and results in one copy of the errors being shown.*/\n    [class*=\"OldFieldMessage\"] {\n        display: none;\n    }\n}\n\n.policyDocumentsTableNoPadding {\n    padding: 0;\n}\n\n.fileTypeIcon {\n    position: relative;\n    top: var(--GW-SPACING-1);\n}\n\n.columnHeader {\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUpload": "app__FileUploadViewerComponent__fileUpload",
	"policyDocumentsTableNoPadding": "app__FileUploadViewerComponent__policyDocumentsTableNoPadding",
	"fileTypeIcon": "app__FileUploadViewerComponent__fileTypeIcon",
	"columnHeader": "app__FileUploadViewerComponent__columnHeader"
};
export default ___CSS_LOADER_EXPORT___;
