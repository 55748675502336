// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__CoverableIterableComponent__gwCoverageWrapper{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);margin-bottom:var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policy-common-react/components/PolicyCommonCoverables/CoverableIterableComponent/CoverableIterableComponent.module.scss"],"names":[],"mappings":"AAAA,oDAEI,oEAAA,CADA,gCACA","sourcesContent":[".gwCoverageWrapper {\n    margin-bottom: var(--GW-LAYOUT-4);\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gwCoverageWrapper": "app__CoverableIterableComponent__gwCoverageWrapper"
};
export default ___CSS_LOADER_EXPORT___;
