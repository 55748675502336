// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICLogoutConfirmationPage__wwCard66w{width:66%}p.app__WMICLogoutConfirmationPage__header{font-size:1.3rem;font-weight:700;margin-bottom:0}", "",{"version":3,"sources":["webpack://./../common/modules-react/wmic-components-amp-common-react/WMICLogoutConfirmationPage/WMICLogoutConfirmationPage.module.scss"],"names":[],"mappings":"AAAA,4CACE,SAAA,CAGF,0CAGE,gBAAA,CAFA,eAAA,CACA,eACA","sourcesContent":[".wwCard66w {\n  width: 66%;\n}\n\np.header {\n  font-weight: 700;\n  margin-bottom: 0;\n  font-size: 1.3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wwCard66w": "app__WMICLogoutConfirmationPage__wwCard66w",
	"header": "app__WMICLogoutConfirmationPage__header"
};
export default ___CSS_LOADER_EXPORT___;
