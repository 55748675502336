import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, ModalNext, ModalFooter } from '@jutro/components';
import {WMICPolicyDetailsUtil} from 'gw-capability-policy-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { DocumentService } from 'gw-capability-policydocument';
import styles from './WMICRcnModal.module.scss';
import messages from './WMICRcn.messages';
import { WMICDocumentDownloadButton } from 'gw-capability-document-react';
import { WMICTooltipCookieUtil, LobIconUtil } from 'wmic-portals-utils-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useCookies } from 'react-cookie';

import { Link } from '@jutro/router';

const WMICRcnModal = ({isOpen, onResolve, policyDetails, hideRcnCta}) => {
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const [cookies, setCookie] = useCookies([]);

    const onSetCookies = useCallback(
        (params) => {
            setCookie(params);
        }, [setCookie]
    );

    const policyNumber = policyDetails.currentPeriod.policyNumber;
    let rcnDocument = WMICPolicyDetailsUtil.getRcnDocument(policyDetails.currentPeriod.documentDTOs);
    if (!rcnDocument) {
        rcnDocument = WMICPolicyDetailsUtil.getRcnDocument(policyDetails.renewedPeriod?.documentDTOs);
    }
    if (rcnDocument) {
        rcnDocument.id = rcnDocument.publicID;
        rcnDocument.friendlyName = "Price Increase Notice (PDF)";
        rcnDocument.type = "pc";
    }

    const getIconClass = () => {
        const icon = LobIconUtil.getWmicIcon("PersonalAutoLine");
        return `fa ${icon} titleIconOrange`;
    };

    const handleDontShowAgain = useCallback(() => {
        onResolve();
        WMICTooltipCookieUtil.setDontShowAgainCookie(authUserData, onSetCookies, policyNumber);
        hideRcnCta(true);
        }, [authUserData, onSetCookies]
    );
    
    const padecDocument = (policyDetails.renewedPeriod !== undefined) ? WMICPolicyDetailsUtil.getPadecDocument(policyDetails.renewedPeriod.documentDTOs) : WMICPolicyDetailsUtil.getPadecDocument(policyDetails.currentPeriod.documentDTOs);
    const translator = useTranslator();
    const handleClose = () => {
        onResolve();
        localStorage.setItem('rcnContainerNotShown', JSON.stringify(false));
    };

    const handlePadecDownload = () => {
        return DocumentService.downloadPolicyDocument(
            padecDocument.publicID,
            padecDocument.sessionID
        );
    }

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose}
            className={styles.modal}
            overlayClassName={styles.modalOverlay}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            contentLayout={{
                component: 'Grid',
                componentProps: {
                    rows: ['auto'],
                },
            }}
        >
            
            <h3 className='modal'>
                <i className={`${styles.icon} ${getIconClass()}`}/>
                {translator(messages.yourAutoInsuranceIncreased)}
            </h3>
            <div className={styles.modalBody}>
                {
                    <div className={styles.policy}>
                        <div>
                            <p className={styles.header}>
                                {translator(messages.reviewRenewal, { policyNumber: policyNumber })}
                            </p>
                            <p>
                                {translator(messages.renewalMessage)}
                            </p>
                        </div>
                    </div>
                }
                <div>
                    <WMICDocumentDownloadButton
                        type='filled'
                        targetDocument={rcnDocument}
                    >
                        {translator(messages.priceIncreaseNotice)}
                    </WMICDocumentDownloadButton>
                </div>
                <div>
                    <Link
                        href={handlePadecDownload()}
                        target='_blank'
                    >
                        {translator(messages.offerToRenew)}
                    </Link>
                </div>
            </div>
            <ModalFooter>
                <WMICButton
                    type='outlined'
                    onClick={handleDontShowAgain}
                >
                    {translator(messages.dontShowAgain)}
                </WMICButton>
                <WMICButton
                    type='primary'
                    onClick={handleClose}
                >
                    {translator(messages.close)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICRcnModal.propTypes = {
    policies: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            details: PropTypes.object
        })
    ).isRequired,
};

export default WMICRcnModal;