// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICHOPolicyDwellingPropertyDetails__ww-margin-bottom{margin-bottom:var(--GW-LAYOUT-3)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-policy-common-react/WMICPolicyWizard/components/WMICHOPolicyDwellingPropertyDetails/WMICHOPolicyDwellingPropertyDetails.module.scss"],"names":[],"mappings":"AAAA,4DACI,gCAAA","sourcesContent":[".ww-margin-bottom {\n    margin-bottom: var(--GW-LAYOUT-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ww-margin-bottom": "app__WMICHOPolicyDwellingPropertyDetails__ww-margin-bottom"
};
export default ___CSS_LOADER_EXPORT___;
