import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';

import messages from './WMICManagePaymentPlan.messages';

// this component is used as ModalBody
const WMICChangePaymentPlanResultModal = (props) => {
    const {
        newPaymentPlan,
        changeResult
    } = props;
    const translator = useTranslator();

    const getPaymentPlanUpdatedMessage = () => {
        const planUpdatedMessage = changeResult ? messages.planChangeSuccess : messages.failedChangeYourPlan;
        const updatedDateMessageString = translator(planUpdatedMessage, {
            newPaymentPlan: newPaymentPlan
        });
        return updatedDateMessageString;
    };

    return (
        <h3>
            {getPaymentPlanUpdatedMessage()}
        </h3>
    );
};

WMICChangePaymentPlanResultModal.propTypes = {
    newPaymentPlan: PropTypes.string.isRequired,
    changeResult: PropTypes.bool.isRequired
};

WMICChangePaymentPlanResultModal.defaultProps = {
};

export default WMICChangePaymentPlanResultModal;