import React, { useContext, useCallback, useMemo, useState , useEffect } from 'react';
import _ from 'lodash';
import { Flex } from '@jutro/layout';
import PropTypes from 'prop-types';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelServiceContext, ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { LobIconUtil } from '@xengage/gw-portals-util-js'
import { TranslatorContext } from '@jutro/locale';
import { useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICRichTextUtil, ICON_NAMES, LOBConstants, PUP_LOB_CODES, CONSTANTS, WMICUserAccessUtil, WMICValidationUtil, WMICLogger, FlowStepId, WMICVariousUtil } from 'wmic-pe-portals-utils-js'
import { WMICIcon } from 'wmic-pe-components-platform-react';
import { WMICPUPUnderlyingPoliciesComponent } from 'wmic-pe-capability-gateway-common-pup-react'
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICPUPUnderlyingPoliciesPage.metadata.json5';

import messages from './WMICPUPUnderlyingPoliciesPage.messages.js'
import { DropdownMenuLink } from '@jutro/router';


const UNDERLYING_POLICY_PATH = 'lobData.personalUmbrella.coverables.underlyingPolicies';
const UNDERLYING_POLICY_COVERAGES_PATH = 'lobData.personalUmbrella.coverables.underlyingPolicyCoveragesPerPolicyType.value'

function WMICPUPUnderlyingPoliciesPage(props) {
    const { wizardData: jobVM, updateWizardData } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const { LoadSaveService } = useDependencies('LoadSaveService');
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation,
    } = useValidation('UnderlyingPoliciesPage');
    const { authHeader, authUserData } = useAuthentication();
    const { setWizardLoading, showConfirm } = useWizardModals();
    const { refreshData } = useDataRefresh()

    const [selectedPolicy, setSelectedPolicy] = useState();
    const [selectedPolicyIndex, setSelectedPolicyIndex] = useState();
    const [watercraftInsureList, setWatercraftInsureList] = useState([])
    const [coverageByPolicyType, setCoverageByPolicyType] = useState([])
    const [isExistingPolicy, setIsExistingPolicy] = useState(false)
    const [backupPolicy, setBackupPolicy] = useState()
    const [autoAddedWatercraft, setAutoAddedWatercraft] = useState(false)
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const [carrierList, updateCarrierList] = useState([]);

    // TODO - BPN-3577
    const createWatercraftInsurerList = useCallback((carriers,newJobVM) => {
        const watercraftInsureListLocal = [];
        const underlyingPolicies = _.get(newJobVM, `${UNDERLYING_POLICY_PATH}.value`)

        for (let i = 0; i < underlyingPolicies.length; i++) {
            if (underlyingPolicies[i].policyType === PUP_LOB_CODES.HO_CODE) {
                watercraftInsureListLocal.push({
                    name: `${underlyingPolicies[i].carrierNameDisplay  } - ${  underlyingPolicies[i].policyNumber}`,
                    code: underlyingPolicies[i].policyNumber,
                    groupBy: 'homeownersPolicyLabel',
                    underlyingPolicy: underlyingPolicies[i]
                });
            }
        }

        for (let i = 0; i < carriers.length; i++) {
            watercraftInsureListLocal.push({
                name: carriers[i].carrier,
                code: carriers[i].code,
                groupBy: 'insurerLabel',
                carrier: carriers[i]
            });
        }

        setWatercraftInsureList(watercraftInsureListLocal);
    }, [jobVM]);

    useEffect(() => {
        UISupportingInfoLookupService.retrieveInsuranceCarriersList(
            [_.get(jobVM, 'baseData.baseState.value.code'),
                _.get(jobVM, 'baseData.periodStartDate.value')],
            false,
            authHeader).then((carriers) => {
            carriers.forEach((item) => {
                item.name = item.carrier
                item.code = item.carrier
            })
            updateCarrierList(carriers);
            createWatercraftInsurerList(carriers,jobVM);
        }).catch((error) => {
            WMICLogger.error('Retrieving Underlying Policy Lookup Data failed', error);
        });
    }, [])

    const validateTableForm = useCallback(() =>
        WMICValidationUtil.validatePageEntities(jobVM, [UNDERLYING_POLICY_PATH, UNDERLYING_POLICY_COVERAGES_PATH])
            && !WMICValidationUtil.hasDtoValidationErrors(jobVM, FlowStepId.PUP_UNDERLYINGPOLICIES)
    , [jobVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateTableForm);
    }, [registerInitialComponentValidation, validateTableForm]);

    useEffect(() => {
        jobVM.value.flowStepId_WMIC = FlowStepId.PUP_UNDERLYINGPOLICIES
    }, [jobVM.value]);

    const formatLOBCode = (code) => {
        if (code === PUP_LOB_CODES.PA_CODE) { return LOBConstants.PA };
        if (code === PUP_LOB_CODES.HO_CODE) { return LOBConstants.PP };
        return code;
    };

    const getPolicyCell = (item) => {
        const icon =
            item.policyType === PUP_LOB_CODES.PWL_CODE ? ICON_NAMES.ICON_SHIP :
                LobIconUtil.getMaterialIcon(formatLOBCode(item.policyType));

        return <Flex
        direction="row"
        alignItems="center"
        gap="none"
    >
        <WMICIcon icon={icon}/>
        {item.policyNumber}
    </Flex>
    };

    const getRiskCell = (item) => {
        switch (item.policyType){
            case PUP_LOB_CODES.PA_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesPassengerVehicleRisk)
                }
                if (item.isExcessPolicy) {
                    return translator(messages.wmicUnderlyingPoliciesExcessPolicy)
                }
                return translator(messages.wmicUnderlyingPoliciesPassengerVehicleRisks, { number: item.exposures.length} )
            case PUP_LOB_CODES.HO_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesPersonalPropertyRisk)
                }
                return translator(messages.wmicUnderlyingPoliciesPersonalPropertyRisks, { number: item.exposures.length})
            case PUP_LOB_CODES.PWL_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesWatercraftRisk)
                }
                return translator(messages.wmicUnderlyingPoliciesWatercraftRisks, { number: item.exposures.length})
        }
    };

    const checkPersonalPropertyOnRemoval = useCallback((underlyingPolicyToBeRemoved) => {
        const sameAsWatercraftUnderlyingPolicyIndex = _.findIndex(_.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`, []),
            (item) => {
                return item.policyNumber === underlyingPolicyToBeRemoved.policyNumber
                    && item.policyType === PUP_LOB_CODES.PWL_CODE;
            });

        if(sameAsWatercraftUnderlyingPolicyIndex > -1){
            _.set(jobVM, `${UNDERLYING_POLICY_PATH}.value[${sameAsWatercraftUnderlyingPolicyIndex}].isSameAs`, false);
        }
        createWatercraftInsurerList(carrierList,jobVM);
    }, [jobVM, createWatercraftInsurerList, carrierList]);

    const checkPersonalAutoOnRemoval = useCallback((underlyingPolicyToBeRemoved) => {
        const checkExposure = (underlyingPolicyIndex, compareIndex) => {
            const exposure = _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value[${underlyingPolicyIndex}].exposures[${compareIndex}]`)

            if(exposure.underlyingPolicyExcessPublicID === underlyingPolicyToBeRemoved.publicID) {
                _.set(exposure, 'underlyingPolicyExcessPublicID', undefined);
            }
        };

        const checkExposures = (underlyingPolicyIndex) => {
            for(let i = 0, j = _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value[${underlyingPolicyIndex}].exposures`, []).length - 1; i <= (j - i); i++) {
                checkExposure(underlyingPolicyIndex, i);
                checkExposure(underlyingPolicyIndex, j - i);
            }
        };

        const checkUnderlyingPolicy = (compareIndex) => {
            const underlyingPolicy = _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value[${compareIndex}]`)

            if(underlyingPolicy.policyType === PUP_LOB_CODES.PA_CODE
                && !underlyingPolicy.isExcessPolicy
                && underlyingPolicy.publicID !== underlyingPolicyToBeRemoved.publicID) {
                checkExposures(compareIndex);
            }
        };

        for(let i = 0, j = _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`, []).length - 1; i <= (j - i); i++) {
            checkUnderlyingPolicy(i);
            checkUnderlyingPolicy(j - i);
        }
    }, [jobVM]);

    const removeUnderlyingPolicy = useCallback(async (policy, index) => {
        const response = await showConfirm({
            title: translator(messages.wmicUnderlyingPoliciesRemoveUnderlyingPolicy),
            message: translator(messages.wmicUnderlyingPoliciesRemoveUnderlyingPolicyConfirmationMsg,
                {policyNumber: _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value[${index}].policyNumber`)})
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const policyAux = policy.value
            setSelectedPolicy(undefined);
            const underlyingPoliciesList = _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`);
            underlyingPoliciesList.splice(index, 1);

            switch(policyAux.policyType) {
                case PUP_LOB_CODES.HO_CODE:
                    checkPersonalPropertyOnRemoval(policyAux);
                    break;
                case PUP_LOB_CODES.PA_CODE:
                    checkPersonalAutoOnRemoval(policyAux);
                    break;
                default:
                    break;
            }

            try {
                setWizardLoading(true, translator(messages.wmicUnderlyingPoliciesRemovingLiabilityCoverage));
                jobVM.value = await LoadSaveService.saveWithNoValidationRuleCheck(
                    jobVM.value,
                    authHeader
                );

                updateWizardData(jobVM);

                if (WMICValidationUtil.hasDtoValidationErrors(jobVM, FlowStepId.PUP_UNDERLYINGPOLICIES)) {
                    WMICVariousUtil.scrollToTop();
                }

                return true
            } catch (error) {
                WMICLogger.error('Remove underlyingpolicy failed', error);
                return false;
            } finally {
                setWizardLoading(false);
            }


        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, checkPersonalAutoOnRemoval, checkPersonalPropertyOnRemoval, setWizardLoading, showConfirm, jobVM, translator])

    const selectUnderlyingPolicy = useCallback((index, edit, autoAddedWatercraftParam) => {
        const policy = _.get(jobVM, `${UNDERLYING_POLICY_PATH}.children[${index}]`);
        setAutoAddedWatercraft(autoAddedWatercraftParam);

        let isUnderEditing = false;

        if (edit) {
            isUnderEditing = true;
            _.set(jobVM, 'isEditingPage.value', true);
        } else if(autoAddedWatercraftParam) {
            isUnderEditing = true;
        } else {
            isUnderEditing = false;
        }

        _.set(policy, 'isUnderEditing', isUnderEditing)

        for(let i = 0; i < _.get(policy, 'coverages.value', []).length; i++) {
            if(policy.coverages.value[i].terms.length === 0) {
                policy.coverages.value[i].terms = _.clone(coverageByPolicyType[policy.policyType.value.code][i].terms);
            }
        }

        if(policy.policyType.value.code === PUP_LOB_CODES.PWL_CODE) {
            if(policy.isSameAs.value) {
                policy.selectedInsurer = _.find(watercraftInsureList,
                    (item) => {
                        return item.code === policy.policyNumber.value;
                    });
            }
            else {
                policy.selectedInsurer = _.find(watercraftInsureList,
                    (item) => {
                        return item.name === policy.carrierName.value;
                    });
            }
        }

        if(_.get(policy, 'isUnderEditing')) {
            setBackupPolicy(_.cloneDeep(policy.value));
        }

        setSelectedPolicy(policy);
        setSelectedPolicyIndex(index);
        updateWizardData(jobVM);

        return policy;
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverageByPolicyType, watercraftInsureList, jobVM]);

    const createUnderlyingPolicy = useCallback((policyType, internalPolicy) => {
        let underlyingPolicy;
        let coverages;
        setAutoAddedWatercraft(false);
        _.set(jobVM, 'isEditingPage.value', true);

        if(internalPolicy) {
            underlyingPolicy = _.cloneDeep(internalPolicy.underlyingPolicy);
            setIsExistingPolicy(true);
        } else {
            coverages = _.cloneDeep(coverageByPolicyType[policyType]);
            setIsExistingPolicy(false);
        }

        const newUnderlyingPolicy = viewModelService.create(
            underlyingPolicy || { policyType: policyType, isExcessPolicy: false, coverages: coverages, exposures: [] },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.policy.UnderlyingPolicyDTO_WMIC',
            jobVM.aspects.context()
        );

        if(policyType === PUP_LOB_CODES.PWL_CODE) {
            _.set(newUnderlyingPolicy, 'selectedInsurer', undefined);
        }

        _.set(newUnderlyingPolicy, 'isUnderEditing', true);
        _.set(newUnderlyingPolicy, 'isBeingAdded', true);
        _.set(newUnderlyingPolicy, 'hasWatercraft', internalPolicy && internalPolicy.hasWatercraft);

        if(internalPolicy) {
            for(let i = 0; i < newUnderlyingPolicy.coverages.length; i++) {
                for(let j = 0; j < newUnderlyingPolicy.coverages.value[i].terms.length; j++) {
                    newUnderlyingPolicy.coverages.value[i].terms[j].updated = true;
                }
            }
        }

        setSelectedPolicy(newUnderlyingPolicy);
    }, [coverageByPolicyType, jobVM, viewModelService]);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));

        for(let i = 0; i < _.get(jobVM, `${UNDERLYING_POLICY_COVERAGES_PATH}`, []).length; i++) {
            coverageByPolicyType[_.get(jobVM, `${UNDERLYING_POLICY_COVERAGES_PATH}.[${i}].policyType`)] = _.get(jobVM, `${UNDERLYING_POLICY_COVERAGES_PATH}.[${i}].coverages`)
        }
        setCoverageByPolicyType(coverageByPolicyType);
    }, [])

    const onNext = useCallback(async () => {
        if(!isReadOnlyUser) {
            try {
                setWizardLoading(true);
                const newSubmissionVM = viewModelService.clone(jobVM);

                jobVM.value = await LoadSaveService.updateDraftSubmission(
                    newSubmissionVM.value,
                    authHeader
                );

                updateWizardData(jobVM);

                if (WMICValidationUtil.hasDtoValidationErrors(jobVM, FlowStepId.PUP_UNDERLYINGPOLICIES)) {
                    WMICVariousUtil.scrollToTop();
                    return false;
                }


                return jobVM;
            } finally {
                setWizardLoading(false);
            }
        } else {
            return false
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService, jobVM, LoadSaveService, authHeader, setWizardLoading, isReadOnlyUser]);

    const homeownersPolicyDoesNotExists = () => {
        return _.findIndex(_.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`),
            (item) => {
                return item.policyType === PUP_LOB_CODES.HO_CODE;
            }) === -1;
    };

    const updateSelectedUnderlyingPolicy = (underlyingPolicyVM) => {
        refreshData();
        setSelectedPolicy(underlyingPolicyVM);
        updateWizardData(jobVM);
    };

    const copyCoverageValues = (copyer, copyee) => {
        let copyerChoosenTermValue;
        const matchTermValue = (option) => {
            return option.name === copyerChoosenTermValue;
        };

        for(let i = 0; i < copyer.coverages.length; i++) {
            for(let j = 0; j < copyer.coverages[i].terms.length; j++){
                copyerChoosenTermValue = copyer.coverages[i].terms[j].chosenTermValue;
                const termOption = _.find(copyee.coverages[i].terms[j].options, matchTermValue);

                copyee.coverages[i].terms[j].chosenTermValue = termOption.name;
                copyee.coverages[i].terms[j].chosenTerm = termOption.code;
                copyee.coverages[i].terms[j].updated = true;
            }
        }
    };

    const createWatercraftUnderlyingPolicyIfNeeded = () => {
        if(selectedPolicy.hasWatercraft) {
            const watercraftUnderlyingPolicy = _.cloneDeep(selectedPolicy.value);
            _.set(watercraftUnderlyingPolicy, 'policyType', PUP_LOB_CODES.PWL_CODE)
            _.set(watercraftUnderlyingPolicy, 'isSameAs', true)
            _.set(watercraftUnderlyingPolicy, 'selectedInsurer', _.find(watercraftInsureList,
                (item) => {
                    return item.code === watercraftUnderlyingPolicy.policyNumber;
                }))
            _.set(watercraftUnderlyingPolicy, 'coverages', _.cloneDeep(coverageByPolicyType[PUP_LOB_CODES.PWL_CODE]))
            _.set(watercraftUnderlyingPolicy, 'exposures', [])
            _.set(watercraftUnderlyingPolicy, 'carrierName', selectedPolicy.value.policyNumber);
            _.set(watercraftUnderlyingPolicy, 'carrierNameOther', selectedPolicy.value.carrierNameOther);

            watercraftUnderlyingPolicy.exposures = [];
            copyCoverageValues(selectedPolicy.value, watercraftUnderlyingPolicy);

            _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`).push(watercraftUnderlyingPolicy);
            const policy = selectUnderlyingPolicy(_.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`, []).length - 1, null, true);
            return policy;
        }
    };

    const copyHomeownerToWatercraft = (originalUnderlyingPolicy, selectedUnderlyingPolicy) => {
        const originalPublicId = originalUnderlyingPolicy.publicID.value;
        const originalPolicyType = originalUnderlyingPolicy.policyType.value;
        const originalCoverages = originalUnderlyingPolicy.coverages.value;
        const originalExposures = originalUnderlyingPolicy.exposures.value;

        originalUnderlyingPolicy.value = _.cloneDeep(selectedUnderlyingPolicy);

        originalUnderlyingPolicy.publicID.value = originalPublicId;
        originalUnderlyingPolicy.policyType.value = originalPolicyType;
        originalUnderlyingPolicy.coverages.value = originalCoverages;
        originalUnderlyingPolicy.exposures.value = originalExposures;
    };

    const cancelUnderlyingPolicy = () => {
        if(selectedPolicy.isBeingAdded) {
            setSelectedPolicy(undefined);
            setSelectedPolicyIndex(-1);
        } else {
            jobVM.lobData.personalUmbrella.coverables.underlyingPolicies.setElement(selectedPolicyIndex, backupPolicy);
            selectUnderlyingPolicy(selectedPolicyIndex);
        }
        setShowErrors(false);
        _.set(jobVM, 'isEditingPage.value', false);
    }

    const isExternal = useCallback((carrierName) => {
        return carrierName !== translator(messages.wmicUnderlyingPoliciesInternalPolicyName);
    }, [translator]);

    const addHouseHoldMembers = useCallback((underlyingPolicy) => {
        const internalPolicy = _.find(_.get(jobVM, 'lobData.personalUmbrella.coverables.internalPolicies.value'),
            (item) => {
                return item.underlyingPolicy.policyNumber === underlyingPolicy.policyNumber.value;
            });

        if(internalPolicy) {
            for(let i = 0; i < internalPolicy.householdMembers.length; i++) {
                const newHouseholdMember = internalPolicy.householdMembers[i];
                const householdMember = _.find(_.get(jobVM, 'lobData.personalUmbrella.coverables.householdMembers.value'),
                    (item) => {
                        return item.person.publicID === newHouseholdMember.person.publicID;
                    });

                if(!householdMember) {
                    _.get(jobVM, 'lobData.personalUmbrella.coverables.householdMembers.value').push(newHouseholdMember);
                }
            }
        }
    }, [jobVM]);

    const saveUnderlyingPolicy = useCallback(() => {
        let saved = false;

        if(isComponentValid
                && (selectedPolicy.exposures.value.length > 0
                || selectedPolicy.isExcessPolicy.value)
        ) {
            if(isExternal(selectedPolicy.carrierName)) {
                _.set(selectedPolicy, 'carrierNameDisplay',
                    selectedPolicy.carrierName.value === CONSTANTS.REASON.OTHER ?
                        selectedPolicy.carrierNameOther :
                        selectedPolicy.carrierName
                )
            }

            _.set(selectedPolicy, 'isUnderEditing', false);

            if(_.get(selectedPolicy, 'policyType.value.code') === PUP_LOB_CODES.HO_CODE) {
                const sameAsWatercraftUnderlyingPolicyIndex = _.findIndex(_.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`),
                    (item) => {
                        return item.policyNumber === selectedPolicy.policyNumber
                            && item.policyType === PUP_LOB_CODES.PWL_CODE;
                    });

                if(sameAsWatercraftUnderlyingPolicyIndex > -1){
                    copyHomeownerToWatercraft(
                        _.get(jobVM, `${UNDERLYING_POLICY_PATH}`).getElement(sameAsWatercraftUnderlyingPolicyIndex),
                        selectedPolicy.value
                    );
                }
            }

            if(!selectedPolicy.publicID.value && !autoAddedWatercraft) {
                _.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`).push(selectedPolicy.value);

                if(!isExternal(selectedPolicy.carrierName)) {
                    addHouseHoldMembers(selectedPolicy);
                }
            }

            if (!selectedPolicy.hasCoverageRestrictions.value) {
                _.set(selectedPolicy, 'coverageRestrictionsDetails.value', null)
            }
            setSelectedPolicy(selectedPolicy)
            setBackupPolicy(undefined);
            saved = true;
        }
        return saved;
    }, [selectedPolicy, autoAddedWatercraft, isComponentValid, jobVM, carrierList, createWatercraftInsurerList, isExternal, addHouseHoldMembers]);

    const saveLiabilityCoverage = async () => {
        if (isComponentValid && saveUnderlyingPolicy()) {
            try {
                setWizardLoading(true, translator(messages.wmicUnderlyingPoliciesSavingLiabilityCoverage));
                jobVM.value = await LoadSaveService.saveWithNoValidationRuleCheck(
                    jobVM.value,
                    authHeader
                );

                const newPolicy = createWatercraftUnderlyingPolicyIfNeeded();

                if(selectedPolicy.policyType.value.code === PUP_LOB_CODES.HO_CODE){
                    createWatercraftInsurerList(carrierList,jobVM);
                }

                updateWizardData(jobVM);
                setSelectedPolicy(newPolicy);
                setShowErrors(false);

                if (WMICValidationUtil.hasDtoValidationErrors(jobVM, FlowStepId.PUP_UNDERLYINGPOLICIES)) {
                    WMICVariousUtil.scrollToTop();
                }

                return true;
            } catch (error) {
                WMICLogger.error('Save underlyingpolicy failed', error);
                return false
            } finally {
                setWizardLoading(false);
            }
        } else {
            setShowErrors(true);
        }
    }

    const isUnderlyingPolicyAlreadyAdded = useCallback((policyNumber) => {
        return _.findIndex(_.get(jobVM, `${UNDERLYING_POLICY_PATH}.value`),
            (item) => {
                return item.policyNumber === policyNumber;
            }) > -1;
    }, [jobVM]);

    const getPolicyTypeDescription = (item) => {
        switch (item.policyType){
            case PUP_LOB_CODES.PA_CODE:
                return translator(messages.wmicUnderlyingPoliciesAuto);
            case PUP_LOB_CODES.HO_CODE:
                return translator(messages.wmicUnderlyingPoliciesPersonalProperty);
            case PUP_LOB_CODES.PWL_CODE:
                return translator(messages.wmicUnderlyingPoliciesWatercraft);
        }
    };

    const getInternalPolicyContent = (item) => {
        const icon =
            item.policyType === PUP_LOB_CODES.PWL_CODE ? ICON_NAMES.ICON_SHIP :
                LobIconUtil.getMaterialIcon(formatLOBCode(item.policyType));
        const policyText = !item.policyNumber ? '' : translator(messages.wmicUnderlyingPoliciesInternalPolicy,
            { policyNumber: item.policyNumber, carrierName: item.carrierNameDisplay});

        return <DropdownMenuLink key={item.id} id={item.id} onClick={() => item.onClick} >
             { icon && <WMICIcon icon={icon}/> }
             <span>{`${translator(getPolicyTypeDescription(item))} ${policyText}`}</span>
        </DropdownMenuLink>

    };

    const internalPolicies = useMemo(() => {
        const internalPoliciesValue = _.get(jobVM, 'lobData.personalUmbrella.coverables.internalPolicies.value', []);
        const result = [];

        result.push({id: 'addUnderlyingHO', content: getInternalPolicyContent({policyType: PUP_LOB_CODES.HO_CODE}),
            onClick: () => createUnderlyingPolicy(PUP_LOB_CODES.HO_CODE)});
        result.push({id: 'addUnderlyingPA', content: getInternalPolicyContent({policyType: PUP_LOB_CODES.PA_CODE}),
            onClick: () => createUnderlyingPolicy(PUP_LOB_CODES.PA_CODE)});
        result.push({id: 'addUnderlyingWC', content: getInternalPolicyContent({policyType: PUP_LOB_CODES.PWL_CODE}),
            onClick: () => createUnderlyingPolicy(PUP_LOB_CODES.PWL_CODE)});
        result.push(...internalPoliciesValue.map((policy) => ({
                id: policy.underlyingPolicy.policyNumber,
                content: getInternalPolicyContent(policy.underlyingPolicy),
                onClick: () => createUnderlyingPolicy(policy.underlyingPolicy.policyType, policy),
                visible: !isUnderlyingPolicyAlreadyAdded(policy.underlyingPolicy.policyNumber)
            })));

        return result;
    });

    const internalPoliciesOverrides = useMemo(() => {
        const overrides = internalPolicies.map((policy, index) => ({
                [`dropdownLink${index}`]: { ...policy }
            }));

        return Object.assign({}, ...overrides);
    }, [internalPolicies]);

    const overrideProps = {
        ...internalPoliciesOverrides,
        underlyingPoliciesRequiredMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.wmicUnderlyingPoliciesAtLeastOneHOPolicy))
        },
        underlyingPoliciesDataList: {
            VMList: _.get(jobVM, `${UNDERLYING_POLICY_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesPolicy),
                    getData: (item) => getPolicyCell(item.value)
                },
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesInsurer),
                    path: 'carrierNameDisplay'
                },
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesRisks),
                    getData: (item) => getRiskCell(item.value)
                }
            ],
            onEditAction: (policy, index) => selectUnderlyingPolicy(index, true),
            onRemoveAction: removeUnderlyingPolicy,
            updateSelectedCardIndex: (index) => selectUnderlyingPolicy(index),
            selectedCardIndex: selectedPolicyIndex,
            isEditing: _.get(selectedPolicy, 'isUnderEditing')
        },
        underlyingPoliciesCardContainer: {
            visible: !_.isUndefined(selectedPolicy)
        },
        underlyingPoliciesForm: {
            underlyingPolicyVM: selectedPolicy,
            updateUnderlyingPolicy: updateSelectedUnderlyingPolicy,
            jobVM,
            saveLiabilityCoverage,
            cancelUnderlyingPolicy,
            isReadOnlyUser,
            isExistingPolicy,
            onValidate,
            showErrors,
            copyHomeownerToWatercraft,
            copyCoverageValues,
            watercraftInsureList,
            carrierList,
            autoAddedWatercraft,
        },
        requiredMsgCardContainer: {
            visible: homeownersPolicyDoesNotExists()
        },
        underlyingPoliciesDropdown: {
            visible: !isReadOnlyUser,
            disabled: _.get(selectedPolicy, 'value') && _.get(selectedPolicy, 'isUnderEditing')
        },
        internalPoliciesDropdownLinks: {
            data: internalPolicies
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICPUPUnderlyingPoliciesComponent
        }
    }

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            disableNext={!isComponentValid || homeownersPolicyDoesNotExists()}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
            flowStepId={FlowStepId.PUP_UNDERLYINGPOLICIES}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={jobVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPUPUnderlyingPoliciesPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    ...wizardProps,
};

export default WMICPUPUnderlyingPoliciesPage;
