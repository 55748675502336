import { defineMessages } from 'react-intl';

export default defineMessages({
    EstimateOrRepairFacilityInformation: {
        id: 'wmic.component.estimate-and-repair-facility-info-box.Estimate or Repair Facility Information',
        defaultMessage: 'Estimate or Repair Facility Information'
    },
    AppraisalMessage: {
        id: 'wmic.component.estimate-and-repair-facility-info-box.Your vehicle is currently out for appraisal. For more information, please contact your adjuster.',
        defaultMessage: 'Your vehicle is currently out for appraisal. For more information, please contact your adjuster.'
    },
    Facility: {
        id: 'wmic.component.estimate-and-repair-facility-info-box.Facility',
        defaultMessage: 'Facility'
    },
    Location: {
        id: 'wmic.component.estimate-and-repair-facility-info-box.Location',
        defaultMessage: 'Location'
    },
    PhoneNumber: {
        id: 'wmic.component.estimate-and-repair-facility-info-box.Phone Number',
        defaultMessage: 'Phone Number'
    },
    EstimateOrRepairFacilityInformationAria: {
        id: 'wmic.component.estimate-and-repair-facility-info-box.Estimate or Repair Facility Information Aria',
        defaultMessage: 'Facility {facility} location {location} phone number {phoneNumber}'
    },
});
