import { SplitFactory } from '@splitsoftware/splitio';
import _ from 'lodash';
import appConfig from 'app-config';
import { FEATURE_FLAG_DEFAULT_VALUES, FEATURE_FLAG_NAMES } from '../StringConstants';

const {
    splitIoKey
} = appConfig;

export default class WMICSplitIoFeatureFlags {
    constructor(authData) {
        this.authData = authData;
        this.init();
    }

    static getWMICSplitIoFeatureFlags(authData) {
        if (!this.singleton){
            this.singleton = new WMICSplitIoFeatureFlags(authData);
        }
        return this.singleton;
    }

    init() {
        const userId =  _.get(_.get(this.authData, 'user_id'), 'authUserData') ? this.authData.userData.user_id : `${Math.random()}`;
        const factory = SplitFactory({
            core: {
                authorizationKey: splitIoKey,
                // the key can be the logged in
                // user id, or the account id that 
                // the logged in user belongs to. 
                // The type of customer (user, account, custom)
                // is chosen during Split's sign-up process.
                key: userId
            },
            startup: {
                readyTimeout: 1.5 // 1.5 sec
            }
        });

        this.featureFlags =  this.getFlags(factory.client())
    };

    wmicUseFlags() {
        const result = this.featureFlags.then((resultFlags) => {return resultFlags});
        return result;
    };

    getFlags(client) {
        return client.ready().then(() => {
            const flagsAux = {};
            Object.keys(FEATURE_FLAG_NAMES).forEach((fKey) => {
                const fName = FEATURE_FLAG_NAMES[fKey];
                const treatmentResult = client.getTreatmentWithConfig(fName);
                const configs = JSON.parse(treatmentResult.config);
                const treatment = treatmentResult.treatment;
                let result = {};

                /**
                 * Case 1: treatment = 'control' -> flag not present on the system, default value will be taken
                 * Case 2: treatment configs are null -> set the flag availability to its default treatment (on or off)
                 * Case 3: treatment returns proper config -> return configs which should contain the availability flag
                 */
                if (treatment === 'control') {
                    result = {
                        isAvailable: FEATURE_FLAG_DEFAULT_VALUES[fKey] === 'on'
                    };
                } else if (configs === null || configs === undefined) {
                    result = {
                        isAvailable: treatment === 'on'
                    };
                } else {
                    result = configs;
                }
                
                flagsAux[fName] = result;
            });
            return flagsAux;
        }).catch((err) => {
            console.error(err);
            const flagsAux = {};
            
            Object.keys(FEATURE_FLAG_NAMES).forEach((fKey) => {
                flagsAux[FEATURE_FLAG_NAMES[fKey]] = {
                    isAvailable: FEATURE_FLAG_DEFAULT_VALUES[fKey] === 'on'
                };
            });
            return flagsAux;
        });
    };
}