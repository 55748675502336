import { defineMessages } from 'react-intl';

export default defineMessages({
    returnLaterButtonLabel: {
        id: 'wmic.qb.gw-portals-wizard-react.Wizard.WMICDigitalWizardPageTemplateQnB.Return Later',
        defaultMessage: 'Return Later'
    },
    rentersQuoteDisclaimer: {
        id: 'quoteandbind.common.controllers.QuoteDetails.Wawanesa renters quote disclaimer',
        defaultMessage: '\n All policies underwritten by Wawanesa General Insurance Company are subject to certain terms and conditions. The premium provided is an estimate and is based on initial responses. This quote is subject to underwriting approval and may change. "Wawanesa Insurance" is a trademark of Wawanesa General Insurance Company. Use of the term “member” does not confer any additional rights or benefits beyond those afforded under your policy with Wawanesa.'
    },
    californiaLawRequires: {
        id: 'quoteandbind.common.views.quote-details.California law requires us to inform you of the following:',
        defaultMessage: 'California law requires us to inform you of the following:'
    },
    californiaLawDisclaimer: {
        id: 'quoteandbind.common.views.quote-details.Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.',
        defaultMessage: 'Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.'
    },
    bottomCreditCardFee: {
        id: 'platform.paymentdetails.credit-card-details.For future installments using',
        defaultMessage: '* A convenience fee of $4.99 will be charged for each future installment paid with a credit or debit card.'
    }
});
