/* eslint-disable max-len */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import { CONSTANTS, WMICRichTextUtil } from 'wmic-portals-utils-js';
import htmlParser from 'html-react-parser';
import cx from 'classnames';
import messages from './WMICPolicyChangeReviewVehicleCoverages.messages';
import styles from './WMICPolicyChangeReviewVehicleCoverages.module.scss';

const WMICPolicyChangeReviewVehicleCoverages = (props) => {
    const { vehicle } = props;
    const translator = useTranslator();

    const getCoverageTooltip = (coverageItem) => {
        switch (coverageItem.coverageTermPatternCode.toLowerCase()) {
            case CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVDED.toLowerCase(): {
                return WMICRichTextUtil.translateRichText(translator(messages.comprehensiveCovDedTooltip));
            }
            case CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVDED.toLowerCase(): {
                return WMICRichTextUtil.translateRichText(translator(messages.collisionCovDedTooltip));
            }
            default: {
                return undefined;
            }
        }
    };

    const getCoverageTermValueDisplay = (termPatternCode, coverageItemValue) => {
        switch (termPatternCode.toLowerCase()) {
            case CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVDED.toLowerCase():
            case CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVDED.toLowerCase(): {
                return `$${coverageItemValue}`;
            }
            case CONSTANTS.COVERAGE_TERM_NAMES.PA_RENTALEXPCOVLIMIT.toLowerCase(): {
                const splitValue = coverageItemValue.split('/');
                const limitPerDay = splitValue[0].trim();
                const limitPerOccurrence = splitValue[1].trim();
                return htmlParser(`$${limitPerDay} ${translator(messages.perDay)} <br/> $${limitPerOccurrence} ${translator(messages.perOccurrence)}`);
            }
            default: {
                return '';
            }
        }
    };

    const getCoveragePreviousTermValueDisplay = (coverageItem) => {
        return getCoverageTermValueDisplay(coverageItem.coverageTermPatternCode, coverageItem.originalCoverageTermValue);
    };

    const getCoverageNewTermValueDisplay = (coverageItem) => {
        return getCoverageTermValueDisplay(coverageItem.coverageTermPatternCode, coverageItem.newCoverageTermValue);
    };

    const getCoverageContent = (vehicleData) => {
        const content = vehicleData.coverageComparisons?.sort((a, b) => a.coverageName.localeCompare(b.coverageName)).map((coverage) => {
            return (
                <div className="ww-edit-grid-row">
                    <div className="ww-edit-grid-cell ww-edit-grid-cell-1">
                        <div className={cx(styles.wmicTooltipSpace, "ww-grid-title")}>
                            <h4>
                                {coverage.coverageName}
                            </h4>
                            { getCoverageTooltip(coverage) !== undefined && (
                                <WMICClickableIconTooltipComponent tooltipMessage={getCoverageTooltip(coverage)} />
                            )}
                        </div>
                        <div className="ww-grid-prev-coverage">
                            {translator(messages.previousAmount)}
                            <br />
                            <strong className={styles['wmic-amount']}>
                                {getCoveragePreviousTermValueDisplay(coverage)}
                            </strong>
                        </div>
                        <div className="ww-grid-new-coverage">
                            {translator(messages.newAmount)}
                            <br />
                            <strong className={styles['wmic-amount']}>
                                {getCoverageNewTermValueDisplay(coverage)}
                            </strong>
                        </div>
                    </div>
                    <div className="ww-edit-grid-cell ww-edit-grid-cell-2">
                        <strong className={styles['wmic-amount']}>
                            {getCoveragePreviousTermValueDisplay(coverage)}
                        </strong>
                    </div>
                    <div className="ww-edit-grid-cell ww-edit-grid-cell-3">
                        <strong className={styles['wmic-amount']}>
                            {getCoverageNewTermValueDisplay(coverage)}
                        </strong>
                    </div>
                </div>
            );
        });

        return (
            <Fragment>
                { content }
            </Fragment>
        );
    };

    return (
        <div className="ww-card ww-card-flat ww-card-footer">
            <div className="ww-card-header ww-card__content">
                <h4 className={styles['wmic-vehicle-title']}>
                    {vehicle.displayName}
                </h4>
            </div>
            <div className="ww-card__content">
                <p className={styles['wmic-paragraph-grid']}>
                    {translator(messages.belowAreTheChangesYouVeSubmitted)}
                </p>
                <div className="ww-edit-coverage-grid">
                    <div className="ww-edit-grid-row">
                        <div className="ww-edit-grid-cell ww-edit-grid-cell-1" />
                        <div className="ww-edit-grid-cell ww-edit-grid-cell-2">
                            {translator(messages.previousAmount)}
                        </div>
                        <div className="ww-edit-grid-cell ww-edit-grid-cell-3">
                            {translator(messages.newAmount)}
                        </div>
                    </div>
                    {getCoverageContent(vehicle)}
                </div>
            </div>
        </div>
    );
};

WMICPolicyChangeReviewVehicleCoverages.propTypes = {
    vehicle: PropTypes.shape({
        displayName: PropTypes.string.isRequired
    }).isRequired
};

export default WMICPolicyChangeReviewVehicleCoverages;