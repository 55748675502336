// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PolicyCommonContacts__contactTablePadding{padding:0}h2[class=policyContactTitle]{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:0}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policy-common-react/components/PolicyCommonContacts/PolicyCommonContacts.module.scss"],"names":[],"mappings":"AAAA,gDACI,SAAA,CAGJ,6BACI,iEAAA,CACA,eAAA","sourcesContent":[".contactTablePadding {\n    padding: 0;\n}\n\nh2[class='policyContactTitle'] {\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactTablePadding": "app__PolicyCommonContacts__contactTablePadding"
};
export default ___CSS_LOADER_EXPORT___;
