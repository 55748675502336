import { defineMessages } from 'react-intl';

export default defineMessages({
    coverages: {
        id: 'endorsement.common.views.coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    mainCoverages: {
        id: 'endorsement.common.views.coverages.Main Coverages Premium',
        defaultMessage: 'Main Coverages Premium'
    },
    additionalCoverages: {
        id: 'endorsement.views.selectAction.cart.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    schedules: {
        id: 'endorsement.views.selectAction.cart.Schedules',
        defaultMessage: 'Schedules'
    }
});
