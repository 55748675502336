import React from 'react';
import {useDocumentTitle, WMICWizardViewPage} from "wmic-pe-portals-wizard-components-ui";
import { Accordion } from '@jutro/legacy/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';

import metadata from './WMICPolicyHoCoveragesPage.metadata.json5'
import _ from "lodash";

import {
    WMICHOPolicyCoverages,
    WMICHODwellingCoverages,
    WMICHOWatercraftCoverages
} from 'wmic-pe-capability-gateway-common-ho-react';

const DWELLINGS_PATH = 'lobs.homeOwners.dwellingsDTO_WMIC';
const WATERCRAFT_PATH = 'lobs.homeOwners.watercraftsDTO_WMIC';

const generateColumnData = (policyVM) => {
    const homeOwnersData = _.get(policyVM, 'lobs.homeOwners.value');

    return [{
        code: 'policyView',
        lob: {
            data: {
                coverages: {
                    lineCoverages: _.get(homeOwnersData, 'hoLineCoverages'),
                    dwellingCoverages:  _.get(policyVM, `${DWELLINGS_PATH}.children`).map(dwelling => dwelling.dwellingCoverages)
                }
            },
            path: 'lobs.homeOwners.hoLineCoverages'
        }
    }]
};

const getCoveragesForRisk = (coverageList, riskPublicID) => {
    const riskCoverages = coverageList.children.find(
        (riskCoverage) => {
            return riskCoverage.value.publicID === riskPublicID;
        }
    );

    return riskCoverages ? riskCoverages : [];
}

function WMICPolicyHoCoveragesPage(props) {

    const {
        wizardData: { policyVM },
    } = props;

    useDocumentTitle('Coverages', policyVM);

    const columnData = generateColumnData(policyVM);

    const commonOverrides = {
        'readOnly': true,
        'coverageColumnData': columnData,
    }

    const _iterableProps = () => {
        const dwellingOverrides = _.get(policyVM, `${DWELLINGS_PATH}.children`).map((dwelling, index) => {
            const dwellingCoverages = getCoveragesForRisk(_.get(policyVM, 'lobs.homeOwners.dwellingCoverages'), dwelling.publicID.value);
            const dwellingOverride = {
                [`dwellingCoveragesIterableComponent${index}`]: {
                    'dwellingVM': dwelling,
                    'dwellingCoverages': dwellingCoverages,
                    'dwellingIndex': index,
                    policyVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, dwellingOverride);
        })

        const watercraftOverrides = _.get(policyVM, `${WATERCRAFT_PATH}.children`).map((watercraft, index) => {
            const watercraftCoverages = getCoveragesForRisk(_.get(policyVM, 'lobs.homeOwners.watercraftCoverages'), watercraft.publicID.value);
            const watercraftOverride = {
                [`watercraftCoveragesIterableComponent${index}`]: {
                    'watercraftVM': watercraft,
                    'watercraftCoverages': watercraftCoverages,
                    'watercraftIndex': index,
                    ...commonOverrides
                }
            }
            return Object.assign({}, watercraftOverride);
        })

        return Object.assign({}, ...dwellingOverrides, ...watercraftOverrides);
    }

    const overrideProps = {
        '@field': {
            readOnly: true
        },
        policyCoveragesContainer: {
            submissionVM: policyVM,
            lineCoverages: _.get(policyVM, 'lobs.homeOwners.hoLineCoverages'),
            ...commonOverrides
        },
        dwellingCoveragesIterableAccordionContainer: {
            data: _.get(policyVM, `${DWELLINGS_PATH}.children`)
        },
        watercraftCoveragesIterableAccordionContainer: {
            visible: (_.get(policyVM, `${WATERCRAFT_PATH}.children`, []).length > 0),
            data: _.get(policyVM, `${WATERCRAFT_PATH}.children`)
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHOPolicyCoverages,
            WMICHODwellingCoverages,
            WMICHOWatercraftCoverages,
            Accordion
        }
    };
    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}
WMICPolicyHoCoveragesPage.propTypes = wizardProps;
export default WMICPolicyHoCoveragesPage;
