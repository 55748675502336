// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICIterableVehicleComponent__vehicleSummaryContainer{display:flex;flex-direction:row;justify-content:space-between;margin:19px 20px}.app__WMICIterableVehicleComponent__iterableVehicleContainer{border:1px solid #ccc;border-radius:6px}.app__WMICIterableVehicleComponent__removed{background-color:var(--wmic-error-alert-fill);border:1px solid var(--wmic-error-alert-color)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/components/PA/WMICIterableVehicleComponent/WMICIterableVehicleComponent.module.scss"],"names":[],"mappings":"AAEA,4DAEI,YAAA,CACA,kBAAA,CACA,6BAAA,CAHA,gBAGA,CAGJ,6DACI,qBAAA,CACA,iBAAA,CAGJ,4CAEE,6CAAA,CADA,8CACA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n\n.vehicleSummaryContainer {\n    margin: 19px 20px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.iterableVehicleContainer{\n    border: 1px solid $filter-type-section-color;\n    border-radius: 6px;\n}\n\n.removed {\n  border: 1px solid var(--wmic-error-alert-color);\n  background-color: var(--wmic-error-alert-fill);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vehicleSummaryContainer": "app__WMICIterableVehicleComponent__vehicleSummaryContainer",
	"iterableVehicleContainer": "app__WMICIterableVehicleComponent__iterableVehicleContainer",
	"removed": "app__WMICIterableVehicleComponent__removed"
};
export default ___CSS_LOADER_EXPORT___;
