// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__AdditionalInterestTable__additionalInterestTableCard{margin-bottom:var(--GW-LAYOUT-8);padding:0}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/components/AdditionalInterestTable/AdditionalInterestTable.module.scss"],"names":[],"mappings":"AAIA,2DAEE,gCAAA,CADA,SACA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/helpers\";\n@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n@import '~producer-engage/public/styles/wmicPEDefaultTheme/sass/_customer-pre-set.scss';\n\n.additionalInterestTableCard {\n  padding: 0;\n  margin-bottom: var(--GW-LAYOUT-8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalInterestTableCard": "app__AdditionalInterestTable__additionalInterestTableCard"
};
export default ___CSS_LOADER_EXPORT___;
