import { defineMessages } from 'react-intl';

export default defineMessages({
    propertyCoverageAndLimits: {
        id: 'wmic.component.location-info-box-component.propertyCoverageAndLimits',
        defaultMessage: 'PROPERTY COVERAGE & LIMITS'
    },
    liabilityCoverageAndLimits: {
        id: 'wmic.component.location-info-box-component.liabilityCoverageAndLimits',
        defaultMessage: 'LIABILITY COVERAGE & LIMITS'
    },
    scheduledPropertyItemsAndLimits: {
        id: 'wmic.component.location-info-box-component.scheduledPropertyItemsAndLimits',
        defaultMessage: 'SCHEDULED PROPERTY ITEMS & LIMITS'
    },
    additionalCoverageAndLimits: {
        id: 'wmic.component.location-info-box-component.additionalCoverageAndLimits',
        defaultMessage: 'ADDITIONAL COVERAGE & LIMITS'
    },
    increasedSpecialLimitsOfLiability: {
        id: 'wmic.component.location-info-box-component.increasedSpecialLimitsOfLiability',
        defaultMessage: 'INCREASED SPECIAL LIMITS OF LIABILITY'
    },
    otherStructuresEndorsement: {
        id: 'wmic.component.location-info-box-component.otherStructuresEndorsement',
        defaultMessage: 'OTHER STRUCTURES ENDORSEMENT'
    },
    deductible: {
        id: 'wmic.component.location-info-box-component.deductible',
        defaultMessage: 'Deductible'
    },
    coverageType: {
        id: 'wmic.component.location-info-box-component.coverageType',
        defaultMessage: 'Coverage Type'
    },
    special: {
        id: 'wmic.component.location-info-box-component.special',
        defaultMessage: 'Special'
    },
    replacementCost: {
        id: 'wmic.component.location-info-box-component.replacementCost',
        defaultMessage: 'Replacement Cost'
    },
    actualCashValue: {
        id: 'wmic.component.location-info-box-component.actualCashValue',
        defaultMessage: 'Actual Cash Value'
    },
    basisOfSettlement: {
        id: 'wmic.component.location-info-box-component.basisOfSettlement',
        defaultMessage: 'Basis of Settlement'
    },
    dwelling: {
        id: 'wmic.component.location-info-box-component.dwelling',
        defaultMessage: 'Dwelling'
    },
    dwellingTooltip: {
        id: 'wmic.component.location-info-box-component.dwellingTooltip',
        defaultMessage: 'This affords insurance coverage for your home on an extended replacement cost basis. Your home is protected against risks of direct physical loss, except as otherwise excluded.'
    },
    otherStructures: {
        id: 'wmic.component.location-info-box-component.otherStructures',
        defaultMessage: 'Other Structures'
    },
    otherStructuresTooltip: {
        id: 'wmic.component.location-info-box-component.otherStructuresTooltip',
        defaultMessage: 'Coverage against risks of direct physical loss to your detached buildings.'
    },
    personalProperty: {
        id: 'wmic.component.location-info-box-component.personalProperty',
        defaultMessage: 'Personal Property'
    },
    personalPropertyTooltip: {
        id: 'wmic.component.location-info-box-component.personalPropertyTooltip',
        defaultMessage: 'Your belongings are covered up to your policy limit. Special limits for certain items may apply.'
    },
    lossOfUse: {
        id: 'wmic.component.location-info-box-component.lossOfUse',
        defaultMessage: 'Loss of Use'
    },
    lossOfUseTooltip: {
        id: 'wmic.component.location-info-box-component.lossOfUseTooltip',
        defaultMessage: 'Coverage for additional living expenses should a covered loss cause your home to be uninhabitable, so that you can maintain your household at its normal standard of living for up to 12 months.'
    },
    included: {
        id: 'wmic.component.location-info-box-component.included',
        defaultMessage: 'Included'
    },
    personalLiability: {
        id: 'wmic.component.location-info-box-component.personalLiability',
        defaultMessage: 'Personal Liability'
    },
    perOccurrence: {
        id: 'policy.common.directives.property-coverage.per occurrence',
        defaultMessage: 'per occurrence'
    },
    personalLiabilityAria: {
        id: "wmic.component.location-info-box-component.personalLiabilityAria",
        defaultMessage: "Personal Liability per occurence"
    },
    personalLiabilityTooltip: {
        id: 'wmic.component.location-info-box-component.personalLiabilityTooltip',
        defaultMessage: 'Coverage for bodily injury and property damage for which the Insured is legally liable.'
    },
    medicalPaymentsToOthers: {
        id: 'wmic.component.location-info-box-component.medicalPaymentsToOthers',
        defaultMessage: 'Medical Payments to Others'
    },
    perPerson: {
        id: 'policy.common.directives.property-coverage.per person',
        defaultMessage: 'per person'
    },
    medicalPaymentsToOthersAria: {
        id: "wmic.component.location-info-box-component.medicalPaymentsToOthersAria",
        defaultMessage: "Medical Payments to Others per person"
    },
    medicalPaymentsToOthersTooltip: {
        id: 'wmic.component.location-info-box-component.medicalPaymentsToOthersTooltip',
        defaultMessage: 'Coverage for medical expenses incurred by guests injured on the insured premises.'
    },
    businessPursuitsClerical: {
        id: 'wmic.component.location-info-box-component.businessPursuitsClerical',
        defaultMessage: 'Business Pursuits - Clerical'
    },
    businessPursuitsTooltip: {
        id: 'wmic.component.location-info-box-component.businessPursuitsTooltip',
        defaultMessage: 'Coverage provided for the personal liability and medical payments of the Insured arising out of business activities.'
    },
    businessPursuitsSalesPerson: {
        id: 'wmic.component.location-info-box-component.businessPursuitsSalesPerson',
        defaultMessage: 'Business Pursuits - Sales, Collector/Messenger, Installation/Servicing'
    },
    businessPursuitsTeacher: {
        id: 'wmic.component.location-info-box-component.businessPursuitsTeacher',
        defaultMessage: 'Business Pursuits - Teacher'
    },
    businessPursuitsOther: {
        id: 'wmic.component.location-info-box-component.businessPursuitsOther',
        defaultMessage: 'Business Pursuits - Other'
    },
    increasedCreditCardLimit: {
        id: 'wmic.component.location-info-box-component.increasedCreditCardLimit',
        defaultMessage: 'Increased Credit Card Limit'
    },
    increasedCreditCardLimitTooltip: {
        id: 'wmic.component.location-info-box-component.increasedCreditCardLimitTooltip',
        defaultMessage: 'Increased limit for losses from Insured\'s legal obligation to pay due to theft/unauthorized use of credit or fund transfer cards, check forgery, and good faith acceptance of counterfeit money.'
    },
    earthquake: {
        id: 'wmic.component.location-info-box-component.earthquake',
        defaultMessage: 'Earthquake'
    },
    earthquakeTooltip: {
        id: 'wmic.component.location-info-box-component.earthquakeTooltip',
        defaultMessage: 'The basic policy does not automatically provide coverage against the peril of Earthquake. Limited coverage, on an actual cash value settlement basis, is offered on every homeowners policy.'
    },
    deductibleOfDwelling: {
        id: 'wmic.component.location-info-box-component.deductibleOfDwelling',
        defaultMessage: 'Deductible % of Dwelling'
    },
    homeCyberProtection: {
        id: 'wmic.component.location-info-box-component.homeCyberProtection',
        defaultMessage: 'Home Cyber Protection'
    },
    homeCyberProtectionTooltip: {
        id: 'wmic.component.location-info-box-component.homeCyberProtectionTooltip',
        defaultMessage: 'Provides coverage for direct loss, damage, or expense related to Cyber Attack, Cyber Extortion, and Online Fraud.'
    },
    specialLimitsEnhancementEndorsement: {
        id: 'wmic.component.location-info-box-component.specialLimitsEnhancementEndorsement',
        defaultMessage: 'Special Limits Enhancement Endorsement'
    },
    specialLimitsEnhancementEndorsementTooltip: {
        id: 'wmic.component.location-info-box-component.specialLimitsEnhancementEndorsementTooltip',
        defaultMessage: 'Increases limits specified under Additional Coverage & Limits and Increased Special Limits of Liability.'
    },
    homeSystemsProtection: {
        id: 'wmic.component.location-info-box-component.homeSystemsProtection',
        defaultMessage: 'Home Systems Protection'
    },
    homeSystemsProtectionTooltip: {
        id: 'wmic.component.location-info-box-component.homeSystemsProtectionTooltip',
        defaultMessage: 'Coverage for sudden and accidental breakdown that causes direct physical damage to home infrastructure systems and personal property.'
    },
    identityFraudExpense: {
        id: 'wmic.component.location-info-box-component.identityFraudExpense',
        defaultMessage: 'Identity Fraud Expense'
    },
    identityFraudExpenseTooltip: {
        id: 'wmic.component.location-info-box-component.identityFraudExpenseTooltip',
        defaultMessage: 'Coverage up to the limit for expenses incurred by an Insured as the direct result of any one identity fraud during the policy period.'
    },
    increasedLimitsOnBusinessProperty: {
        id: 'wmic.component.location-info-box-component.increasedLimitsOnBusinessProperty',
        defaultMessage: 'Increased Limits on Business Property'
    },
    increasedLimitsOnBusinessPropertyTooltip: {
        id: 'wmic.component.location-info-box-component.increasedLimitsOnBusinessPropertyTooltip',
        defaultMessage: 'Increases the limit on items included under Personal Property coverage and Additional coverage that have special limits applied.'
    },
    lossAssessment: {
        id: 'wmic.component.location-info-box-component.lossAssessment',
        defaultMessage: 'Loss Assessment'
    },
    lossAssessmentTooltip: {
        id: 'wmic.component.location-info-box-component.lossAssessmentTooltip',
        defaultMessage: 'The basic policy provides up to $1,000 of coverage. This does not cover loss assessments from earthquakes, even if Limited Earthquake Coverage is provided on the policy.'
    },
    serviceLine: {
        id: 'wmic.component.location-info-box-component.serviceLine',
        defaultMessage: 'Service Line'
    },
    serviceLineTooltip: {
        id: 'wmic.component.location-info-box-component.serviceLineTooltip',
        defaultMessage: 'Service Line coverage includes repairs to underground pipes and wiring that bring services to the home and other structures when damage occurs on the homeowner\'s property.'
    },
    wildfireResponseProgram: {
        id: 'wmic.component.location-info-box-component.wildfireResponseProgram',
        defaultMessage: 'Wildfire Response Program'
    },
    wildfireResponseProgramTooltip: {
        id: 'wmic.component.location-info-box-component.wildfireResponseProgramTooltip',
        defaultMessage: 'In the event of a Wildfire, this endorsement authorizes certified private firefighting personnel to access your property to take proactive measures to mitigate your home’s exposure to the wildfire. To opt-out of this service, please contact our Customer Service Agents at 1-800-640-2920.'
    },
    permittedIncidentalOccupancesOnTheResudencePremises: {
        id: 'wmic.component.location-info-box-component.permittedIncidentalOccupancesOnTheResudencePremises',
        defaultMessage: 'Permitted Incidental Occupancies - On the Residence Premises'
    },
    permittedIncidentalOccupancesOnTheResudencePremisesTooltip: {
        id: 'wmic.component.location-info-box-component.permittedIncidentalOccupancesOnTheResudencePremisesTooltip',
        defaultMessage: 'Coverage for a permitted incidental occupancy in the dwelling or in another structure on the residence premises.'
    }
});
