/* eslint-disable max-len */
import React, {
    Fragment,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import {
    CONSTANTS,
    LocalDateUtil,
    WMICRichTextUtil
} from 'wmic-portals-utils-js';
import { WMICModal } from 'gw-capability-policy-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { EndorsementService } from 'gw-capability-policychange';
import { PolicyService } from 'gw-capability-policy';
import { useAuthentication } from 'wmic-digital-auth-react';
import {
    WMICControllerUtil,
    WMICPolicyChangeStateService,
} from 'wmic-capability-policychange';
import { WMICButton, WMICDelinquencyModal } from 'wmic-components-platform-react';
import metadata from './WMICCoverageTypeSelectorPAComponent.metadata.json5';
import messages from './WMICCoverageTypeSelectorPAComponent.messages';
import styles from './WMICCoverageTypeSelectorPAComponent.module.scss';

function WMICCoverageTypeSelectorPAComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        match,
        history,
        paymentModal,
        loadingState,
        setLoadingState
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const [showWarningMessage, updateShowWarningMessage] = useState(false);
    const [hasNotification, updateHasNotification] = useState(false);
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const [selectedValue, updateSelectedValue] = useState('');
    const [submitPath, updateSubmitPath] = useState(undefined);
    const [policyTransactionData, updatePolicyTransactionData] = useState({});
    const [usState, updateUSState] = useState(CONSTANTS.STATE.CA);
    const [billingData, updateBillingData] = useState({});
    const [policyChangeCanBeStarted, updatePolicyChangeCanBeStarted] = useState(true);
    const [hasBillingDelinquencyNOITC, updateHasBillingDelinquencyNOITC] = useState(false);
    const [policyHasRenewal, updatePolicyHasRenewal] = useState(false);
    const [hasFutureBoundPolicyChange, updateHasFutureBoundPolicyChange] = useState(false);
    const [accountPolicyTransactions, updateAccountPolicyTransactions] = useState({});
    const [isPolicyChangeAvailable, setIsPolicyChangeAvailable] = useState(true);
    const [periodData, updatePeriodData] = useState(policyVM.currentPeriod);
    const [policyChangeStatus, updatePolicyChangeStatus] = useState(true);
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const currentDate = new Date();

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('coverageTypeSelectorHeader');

        if (headerTag && !loadingState && policyChangeStatus) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState, policyChangeStatus])

    const isRenewal = (polData) => (polData.renewedPeriod !== undefined && polData.renewedPeriod.termNumber_WMIC !== undefined
            && polData.renewedPeriod.termNumber_WMIC.toString() === termNumber);

    const getAccountHolder = (polData) => {
        const contacts = isRenewal(polData) ? _.get(polData, 'renewedPeriod.contacts') : _.get(polData, 'currentPeriod.contacts');
        
        return contacts.filter((contact) => contact.contact.accountHolder === true)[0];
    };

    const availableOptions = useMemo(() => {
        const options = {
            CA: [
                {
                    code: CONSTANTS.POLICY_CHANGE_TYPE.EDIT_POLICY_SELF_SERVICE,
                    displayName: translator(messages.editPolicySelfService),
                },
                {
                    code: CONSTANTS.POLICY_CHANGE_TYPE.EDIT_UNINSURED,
                    displayName: translator(messages.editUninsured),
                },
                {
                    code: CONSTANTS.POLICY_CHANGE_TYPE.BODILY_INJURY,
                    displayName: translator(messages.bodilyInjury),
                },
            ],
            OR: [
                {
                    code: CONSTANTS.POLICY_CHANGE_TYPE.EDIT_POLICY_SELF_SERVICE,
                    displayName: translator(messages.editPolicySelfService),
                },
                {
                    code: CONSTANTS.POLICY_CHANGE_TYPE.EDIT_UNINSURED,
                    displayName: translator(messages.editUninsuredOR),
                },
                {
                    code: CONSTANTS.POLICY_CHANGE_TYPE.BODILY_INJURY,
                    displayName: translator(messages.bodilyInjuryOR),
                },
            ],
        };

        for (const state in options) {
            options[state].forEach((opt) => {
                opt.name = (
                    <div className={styles.optionTextContainer}>{opt.displayName}</div>
                );
            });
        }

        return options;
    }, [translator]);

    const policyChangeNotices = [];


    const getPolicyChangeNotices = (policyChangeOutage) => {
        // TODO - to be finished when Availability functionality is done (availability functionality US)
        // const pcNotices = FeatureAvailabilityUtil.getPolicyChangeAvailabilityInfo(policyChangeOutage, currentDate);
        const pcNotices = [];

        if (pcNotices) {
            policyChangeNotices.push(pcNotices);
            updateHasNotification(true);
        }
        return pcNotices;
    };

    const getFutureRenewalTransactions = (polTransData) => {
        return _.chain(polTransData.policyTransactions)
            .filter((transaction) => {
                return transaction.type.toLowerCase().trim() === CONSTANTS.RENEWAL                    
                    && new Date(transaction.completionDate) > currentDate;
            })
            .sortBy((transaction) => { return transaction.completionDate; })
            .reverse()
            .value();
    };

    const getPolicyTransactionData = (polTransData) => {
        const sortedTransactions = _.chain(polTransData.policyTransactions)
            .filter((transaction) => {
                return transaction.type.toLowerCase().trim() === CONSTANTS.POLICY_CHANGE
                    && transaction.completionDate > transaction.termEffectiveDate;
            })
            .sortBy((transaction) => { return transaction.completionDate; })
            .reverse()
            .value();
        updatePolicyTransactionData(polTransData);
        return sortedTransactions;
    };

    const getCoverageTypeSelectorTitle = () => {
        const title = translator(messages.selectAPolicyChange);

        return (
            <Fragment>
                <i className={`fa fa-file-text ${styles.titleIconBlue}`} />
                <h2 className={styles['wmic-policy-title']}>{title}</h2>
            </Fragment>
        );
    };

    useEffect(() => {
        setLoadingState(true);
        const controllerUtilProps = {
            history: history,
            policyNumber: policyNumber,
            termNumber: termNumber,
            previousPage: _.get(history, 'location.pathname'),
            policyType: policyType,
            accountInfo: getAccountHolder(policyVM),
            authHeader: authHeader,
            authUserData: authUserData
        };

        WMICControllerUtil.getPolicyData(controllerUtilProps).then(() => {
            if (isRenewal(policyVM)) {
                updatePeriodData(policyVM.renewedPeriod);
            }
            WMICPolicyChangeStateService.clearChangeRequest();
        });
        // eslint-disable-next-line max-len
        AccountBillingDetailsService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader)
            .then((billData) => {
                updateBillingData(billData);

                if (billData.accountSummary.state === CONSTANTS.STATE.OR) {
                    updateUSState(CONSTANTS.STATE.OR);
                } else {
                    updateUSState(CONSTANTS.STATE.CA);
                }
            }).finally(() => {
                setLoadingState(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canStartPolicyChange = (policy) => {
        // TODO - fix availability condition (availability functionality US)
        const { polStatus } = policy.currentPeriod.lobs.personalAuto;
        
        return isPolicyChangeAvailable
            && policy.currentPeriod
            && !(polStatus === CONSTANTS.LOB_STATUSES.SCHEDULED
                || polStatus === CONSTANTS.LOB_STATUSES.CANCELLED
                || polStatus === CONSTANTS.LOB_STATUSES.EXPIRED);
    };

    const getPolicyChangesAfterPolicyEffDate = (polTransData) => _.chain(polTransData.policyTransactions)
            .filter((transaction) => transaction.type.toLowerCase() === CONSTANTS.POLICY_CHANGE
                    && transaction.completionDate > transaction.termEffectiveDate)
            .sortBy((transaction) => transaction.completionDate)
            .reverse()
            .value();

    const editPolicy = () => {
        // TODO - to be finished when Availability functionality is done (availability functionality US)
        setLoadingState(true);
        Promise.all([
            // AvailabilityService.getAvailability(CONSTANTS.NOTICE_TYPE.POLICYCHANGE, authHeader).then(setAvailability),
            AccountBillingDetailsService.policyChangeCheckHasDelinquency_WMIC(policyNumber, termNumber, authHeader),
            EndorsementService.policyChangeCanBeStarted_WMIC(policyNumber, authHeader),
            EndorsementService.policyHasRenewal_WMIC(policyNumber, authHeader),
            EndorsementService.policyPeriodHasFutureBoundPolicyChange_WMIC(policyNumber, authHeader),
            PolicyService.getAccountPolicyTransactions_WMIC(policyNumber, authHeader)
        ]).then(([billingDelinqNotice, polChCanBeStarted, polHasRenewal, hasFutureBoundPolCh, acctPolTrans]) => {
            // TODO - if setAssistedAvailability.result.isAvailable (availability functionality US)
            updateHasBillingDelinquencyNOITC(billingDelinqNotice);
            updatePolicyChangeCanBeStarted(polChCanBeStarted);
            updatePolicyHasRenewal(polHasRenewal);
            updateHasFutureBoundPolicyChange(hasFutureBoundPolCh);
            updateAccountPolicyTransactions(acctPolTrans);

            let cannotEditPolicyMessage = '';

            const polTransactionData = getPolicyTransactionData(acctPolTrans);
            // const polChangeNots = getPolicyChangeNotices(availability);

            let policyCanBeChanged = canStartPolicyChange(policyVM) && polChCanBeStarted;
            const sortedFutureRenewalTransactions = getFutureRenewalTransactions(acctPolTrans);

            if (hasFutureBoundPolCh) {
                policyCanBeChanged = false;

                const polChAfterPolEffDate = getPolicyChangesAfterPolicyEffDate(acctPolTrans);

                if (polChAfterPolEffDate && polChAfterPolEffDate.length > 0) {
                    const compDate = LocalDateUtil.toMidnightDate(polChAfterPolEffDate[0].localCompletionDate);
                    const compDateFormatted = intl.formatDate(compDate, { month: 'long', day: 'numeric' });
                    const nextDayFormatted = intl.formatDate(compDate.setDate(compDate.getDate() + 1), { month: 'long', day: 'numeric' });

                    cannotEditPolicyMessage = translator(messages.pendingPolicyChange, {date: compDateFormatted, nextDay: nextDayFormatted});
                } else {
                    cannotEditPolicyMessage = translator(messages.pendingPolicyChangeTempUnavailable);
                }
            } else if (billingDelinqNotice) {
                policyCanBeChanged = false;
            } else if (polHasRenewal && sortedFutureRenewalTransactions && sortedFutureRenewalTransactions.length > 0) {
                policyCanBeChanged = false;

                const compDate = intl.formatDate(LocalDateUtil.toMidnightDate(sortedFutureRenewalTransactions[0].localCompletionDate), { month: 'long', day: 'numeric' });

                cannotEditPolicyMessage = translator(messages.policyAboutToRenewTempUnavailable, {date: compDate});

            } else if (!policyCanBeChanged) {
                policyCanBeChanged = false;
                cannotEditPolicyMessage = translator(messages.policyChangeCannotBeStarted);
            }

            cannotEditPolicyMessage = cannotEditPolicyMessage.concat('<br/><br/>'.concat(WMICRichTextUtil.safeUnescape(translator(messages.youCanReachUsAt))));
            updatePolicyChangeStatus(policyCanBeChanged);

            if (policyCanBeChanged) {
                history.push(`/${CONSTANTS.POLICY_CHANGE_TYPE.EDIT_POLICY_SELF_SERVICE}/${policyNumber}`);
            } else if (billingDelinqNotice) {
                AccountBillingDetailsService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader)
                    .then((billData) => {
                        updateBillingData(billData);
                        modalApi.showModal(
                            <WMICDelinquencyModal
                                termNumber={termNumber}
                                billingData={billData}
                                policyData={policyVM}
                                onPayNowClick={paymentModal}
                            />
                        ).catch(() => {
                            _.noop();
                        });
                    });
            } else {
                modalApi.showModal(
                    <WMICModal
                        id="baseModal"
                        modalHeader={translator(messages.delinquencyModalTitle)}
                        modalBody={cannotEditPolicyMessage}
                        onConfirmCallback={() => _.noop()}
                        confirmButtonText={translator(messages.close)}
                    />
                );
            }
        })
            .finally(() => {
                setLoadingState(false);
            });
    };

    const handleSelectOption = useCallback((value) => {
        updateSelectedValue(value);

        if (value === CONSTANTS.POLICY_CHANGE_TYPE.EDIT_POLICY_SELF_SERVICE) {
            updateSubmitPath(`/${value}/${policyNumber}`);
        } else {
            updateSubmitPath(`/account-policy-change/${policyNumber}/${termNumber}/auto/${value}`);
        }
      
        // TODO - AMPDM-902, 903
        // const showWarning = WMICPolicyChangeUtilsService.hasSubmittedChange(policyNumber, AssistedPolicyChangeModel.getChangeType(value));
        // updateShowWarningMessage(showWarning);
    }, [policyNumber, termNumber]);

    const onSubmit = (selectedOption) => {
        if (selectedOption === CONSTANTS.POLICY_CHANGE_TYPE.EDIT_POLICY_SELF_SERVICE) {
            editPolicy();
        } else {
            history.push(submitPath);
        }

        return undefined;
    };

    const getSubmitButtonTrackButtonIdentifier = (value) => {
        let returnValue = '';
        const selectedPolicyChange = availableOptions[usState].find((option) => option.code === value);

        if (selectedPolicyChange) {
            returnValue = translator(messages.policyChangeSelectTrackButtonIdentifier, {selectedChange: selectedPolicyChange.displayName})
        }

        return returnValue;
    }

    const overrideProps = {
        coverageTypeSelectorContainer: {
            visible: !loadingState
        },
        coverageTypeSelectorTitle: {
            content: getCoverageTypeSelectorTitle()
        },
        warningMessageContainer: {
            visible: showWarningMessage
        },
        radioButtonGroup: {
            labelClassName: styles.radioButtonLabelGroup,
            availableValues: availableOptions[usState],
            onValueChange: (value) => handleSelectOption(value),
            value: selectedValue
        },
        cancelButton: {
            onClick: () => history.goBack()
        },
        submitButton: {
            disabled: !selectedValue,
            onClick: () => onSubmit(selectedValue),
            trackButtonIdentifier: getSubmitButtonTrackButtonIdentifier(selectedValue)
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICButton
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICCoverageTypeSelectorPAComponent.propTypes = {
    policyVM: PropTypes.shape({
        currentPeriod: PropTypes.shape({}),
        renewedPeriod: PropTypes.shape({})
    }).isRequired,
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func,
        push: PropTypes.func
    }).isRequired,
    paymentModal: PropTypes.func.isRequired
};

export default WMICCoverageTypeSelectorPAComponent;
